import React, { useEffect, useState } from 'react';
import {
    Col,
    Button,
    CardBody,
    Input,
    Row,
    FormGroup,
    Label
} from 'reactstrap';
import useResize from '../../../../../Util/Hooks/useResize';
import AppState from '../../../../../store/storeType';
import useShallowEqualSelector from '../../../../../Util/Hooks/useShallowEqualSelector';
import { IUsuarioState } from '../../../../../store/reducers/Cadastro/Usuario/types';
import * as actions from '../../../../../store/reducers/Cadastro/Usuario/actions';
import ArrowTooltip from '../../../../../components/Tooltip/ArrowTooltip';
import { InputCpfCnpj } from '../../../../../components/ErnComponets/ErnComponets';
import { maskCPF } from '../../../../../Util/mascaras';
import ReactTable from 'react-table';
import { useSelector } from 'react-redux';
import * as controllerUsuarios from "../../../../../controller/controllerUsuarios";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import useModal from '../../../../../Util/Hooks/useModal';
import { PopUpInativar } from '../../../Moldals/PopUp';

const Pesquisar = function () {
    return (
        <Col lg='15'>
            <InputPesquisa />
            <ReactTablePesquisa />
        </Col>
    )
}

function InputPesquisa() {
    const { width } = useResize();
    const pesquisa = useShallowEqualSelector<AppState, IUsuarioState['pesquisa']>(state => state.usuario.pesquisa)
    //@ts-ignore
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);

    const onKeyPressPesquisa = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            controllerUsuarios.Pesquisar(codEmpresa)
        }
    }

    return (
        <>
            <Row className="padding-padrao-row">
                <Col className="padding-padrao-col" md="4">
                    <label>Nome</label>
                    <Input
                        id="nome"
                        type="text"
                        autoComplete="off"
                        value={pesquisa.nome}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => actions.setPesquisa({ ...pesquisa, nome: event.currentTarget.value })}
                        onKeyPress={(e: React.KeyboardEvent) => { onKeyPressPesquisa(e) }}
                        autoFocus={true}
                        maxLength={30}
                    />
                </Col>
                <Col className="padding-padrao-col" md="3">
                    <label>E-mail</label>
                    <Input
                        id="email"
                        type="text"
                        autoComplete="off"
                        value={pesquisa.email}
                        onChange={(event: React.FormEvent<HTMLInputElement>) => actions.setPesquisa({ ...pesquisa, email: event.currentTarget.value })}
                        onKeyPress={(e: React.KeyboardEvent) => { onKeyPressPesquisa(e) }}
                        maxLength={60}
                    />
                </Col>
                <Col className="padding-padrao-col" md="3">
                    <label>CPF</label>
                    <InputCpfCnpj
                        id="cpf"
                        tipo="cpf"
                        autoComplete="off"
                        value={pesquisa.cpf}
                        onChange={(event: React.FormEvent<HTMLInputElement>) => actions.setPesquisa({ ...pesquisa, cpf: event.currentTarget.value })}
                        onKeyPress={(e: React.KeyboardEvent) => { onKeyPressPesquisa(e) }}
                        maxLength="14"
                    />
                </Col>

                <Col md="1" className="text-center padding-padrao-col" style={{ paddingTop: '13px' }}>
                    <Col className={width > 768 ? "text-left" : ''}>
                        <FormGroup check className="form-check-radio" Style={"margin-top:-12px;"}>
                            <Label check style={{ paddingRight: '5px' }}>
                                <Input type="radio"
                                    onChange={() => (actions.setPesquisa({ ...pesquisa, ativos: '' }), controllerUsuarios.Pesquisar(codEmpresa))}
                                    checked={pesquisa.ativos === '' ? true : false}
                                />
                                <span className="form-check-sign" />Todos
                                                </Label>
                            <Label check style={{ paddingRight: '5px' }}>
                                <Input type="radio"
                                    onChange={() => (actions.setPesquisa({ ...pesquisa, ativos: '0' }), controllerUsuarios.Pesquisar(codEmpresa))}
                                    checked={pesquisa.ativos === '0' ? true : false}
                                />
                                <span className="form-check-sign" />Ativos
                                                </Label>
                            <Label check style={{ paddingRight: '5px' }}>
                                <Input type="radio"
                                    onChange={() => (actions.setPesquisa({ ...pesquisa, ativos: '1' }), controllerUsuarios.Pesquisar(codEmpresa))}
                                    checked={pesquisa.ativos === '1' ? true : false}
                                />
                                <span className="form-check-sign" />Inativos
                                                </Label>
                        </FormGroup>
                    </Col>
                </Col>
            </Row>
            <Row>
                <Col style={{ width: '100%' }}>
                    <CardBody>
                        <ArrowTooltip title='Shift+L'>
                            <Button
                                color='info'
                                style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                onClick={() => actions.cleanPesquisa()}
                            >Limpar</Button>
                        </ArrowTooltip>
                        <ArrowTooltip title='Shift+P'>
                            <Button
                                color='info'
                                style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                onClick={() => controllerUsuarios.Pesquisar(codEmpresa)}
                            >Pesquisar</Button>
                        </ArrowTooltip>
                    </CardBody>
                </Col>
            </Row>
        </>)

}

const ReactTablePesquisa = () => {
    const usuarios = useShallowEqualSelector<AppState, IUsuarioState['usuarios']>(state => state.usuario.usuarios)
    //@ts-ignore
    const { codEmpresa, codEmpresaFilial } = useSelector(state => state.login.usuario.empresa);
    const [isOpen, togglePopUp] = useModal(false)
    const [user, setUser] = useState<any>({})
    const [tableVisible, setTableVisible] = useState(false);



    useEffect(() => {
        if (usuarios.length > 0) {
            setTableVisible(true)
        } else {
            setTableVisible(false)
        }

    }, [usuarios])


    const handleInativar = () => {
        if (controllerUsuarios.InativarUsuario(user, codEmpresa)) {
            controllerUsuarios.Pesquisar(codEmpresa)
        }
    }
    return (
        <>
            <div style={tableVisible ? { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
                <ReactTable
                    data={usuarios}
                    NoDataComponent={() => (<></>)}
                    filterable={false}
                    columns={[
                        {
                            Header: "Nome",
                            accessor: "nome"
                        },
                        {
                            Header: "E-mail",
                            accessor: "email"
                        },
                        {
                            Header: "CPF",
                            accessor: "cpf",
                            Cell: (props: any) => maskCPF(props.value)
                        },
                        {
                            Header: "Data de cadastro",
                            accessor: "dtCadastro"
                        },
                        {
                            Header: "STATUS",
                            accessor: "status",
                            Cell: (props: any) => props.value === 1 || props.value === 6 ? "INATIVOS" : "ATIVOS"
                        },
                        {
                            Header: "",
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                            Cell: (props) => (
                                <>
                                    <ArrowTooltip title="Visualizar">
                                        <Button
                                            onClick={() => {
                                                controllerUsuarios.GetUsuarioCompleto(codEmpresa, codEmpresaFilial, props.original.codUser, true);
                                                ;
                                            }}
                                            size="sm"
                                            className={"btn-icon btn-link"}
                                            style={{ color: "#483D8B" }}

                                        >
                                            <Icon icon="eye" className="fa-lg" />
                                        </Button>
                                    </ArrowTooltip>
                                    <ArrowTooltip title="Editar">
                                        <Button
                                            onClick={() => {
                                                controllerUsuarios.GetUsuarioCompleto(codEmpresa, codEmpresaFilial, props.original.codUser, false);
                                                actions.setEditar(true);
                                            }}
                                            size="sm"
                                            className={"btn-icon btn-link"}
                                            color="success"
                                        >
                                            <Icon icon="edit" className="fa-lg" />
                                        </Button>
                                    </ArrowTooltip>

                                    <ArrowTooltip title="Inativar">
                                        <Button
                                            onClick={() => { setUser(props.original), togglePopUp() }}
                                            className="btn-icon btn-link"
                                            color="info"
                                        >
                                            {props ? props.original.status === 0 || props.original.status === 7 ?
                                                <Icon icon="ban" className="fa-lg" />
                                                :
                                                <Icon icon="redo" className="fa-lg" />
                                                :
                                                null
                                            }
                                        </Button>
                                    </ArrowTooltip>

                                </>
                            )
                        }
                    ]}
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    noDataText=""
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando"
                    pageText="Página"
                    ofText="de"
                    rowsText='linhas'
                />
                {
                    <PopUpInativar
                        isOpen={isOpen}
                        callback={() => { handleInativar(), togglePopUp() }}
                        toggle={togglePopUp}
                        information={{ status: user.status }} />
                }
            </div>
        </>
    )

}

export default Pesquisar;