//State da tela de Pesquisa e cadastro de CFOPs
const INITIAL_STATE = {
    pesquisa: {
        cfop: '',
        descricao: '',
        tipoCfop: '0',
        estadual: '0',
        ativos: 0,
    },
    cfop: {
        codCfop: 0,
        cfop: '',
        descricao: '',
        tipoCfop: '0',
        estadual: 0,
        status: 0,
    },
    invalido: {
        cfop: false,
        descricao: false,
        tipoCfop: false,
        estadual: false,
    },
    cfops: [],
    inicioCadastro: false,
    verCadastro: false,
    cfopCopia: {},
};

export default function cfops(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'CFOPS':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'CFOPS':
                    return { ...state, cfops: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: INITIAL_STATE.pesquisa,
                        cfops: INITIAL_STATE.cfops
                    }
                case 'CFOP':
                    return { ...state, cfop: { ...state.cfop, [action.campo]: action.value } }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value } 
                case 'CFOP_COPIA':
                    return { ...state, cfopCopia: state.cfop }
                case 'CLEAN_CFOP':
                    return {
                        ...state,
                        cfop : INITIAL_STATE.cfop
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: INITIAL_STATE.invalido
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}