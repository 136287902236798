//State da tela de Pesquisa e cadastro de servicos
var INITIAL_STATE = {
    pesquisa: {
        codConta: '0',
        descricao: '',
        status: '',
        flPedido: 0,
        flLancTitulo: 0,
        flBaixaTitulo: 0,
        flEntradaMerc: 0
    },
    conta: {
        codConta: '0',
        descricao: '',
        status: 0
    },
    contaCopia: {

    },
    flags: {
        flPedido: 0,
        flLancTitulo: 0,
        flBaixaTitulo: 0,
        flEntradaMerc: 0
    },
    flagsCopia: {

    },
    invalido: {
        descricao: false,
    },
    contas: [],
    inicioCadastro: false,
    verCadastro: false,
};

export default function contasStore(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'CONTAS':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'CONTAS':
                    return { ...state, contas: action.value }
                case 'CONTA':
                    return { ...state, conta: { ...state.conta, [action.campo]: action.value } }
                case 'CONTA_COPIA':
                    return { ...state, contaCopia: { ...state.conta } }
                case 'FLAGS':
                    return { ...state, flags: { ...state.flags, [action.campo]: action.value } }
                case 'FLAGS_COPIA':
                    return { ...state, flagsCopia: { ...state.flags } }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: {
                            codConta: '0',
                            descricao: '',
                            status: '',
                            flPedido: 0,
                            flLancTitulo: 0,
                            flBaixaTitulo: 0,
                            flEntradaMerc: 0
                        },
                        contas: []
                    }
                case 'CLEAN_CONTA':
                    return {
                        ...state,
                        conta: {
                            codConta: '0',
                            descricao: '',
                            status: 0,
                        },
                        flags: {
                            flPedido: 0,
                            flLancTitulo: 0,
                            flBaixaTitulo: 0,
                            flEntradaMerc: 0
                        }
                    }
                case 'CLEAN_FLAGS':
                    return {
                        ...state,
                        flags: {
                            flPedido: 0,
                            flLancTitulo: 0,
                            flBaixaTitulo: 0,
                            flEntradaMerc: 0
                        }
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: {
                            descricao: false,
                        },
                    }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value } 
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}