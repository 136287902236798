import React, { useEffect, useRef, useState } from "react";
import {
    Col,
    Input,
    Row,
    FormGroup,
    Label,
    CardBody,
    Button,
    CardHeader,
    CardTitle,
    CardFooter,
    ButtonGroup,
} from "reactstrap";
import { Card } from "@material-ui/core";
import { Icon, InlineIcon } from '@iconify/react';
import circledeleteIcon from '@iconify/icons-whh/circledelete';



import AppState from "../../../../store/storeType";
import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector';
import { IConfiguracaoState } from "../../../../store/reducers/Configuracao/types";
import * as actions from "../../../../store/reducers/Configuracao/actions"
import * as controllerConfiguracao from '../../../../controller/controllerConfiguracao';
import * as controllerDashboard from '../../../../controller/controllerDashboard';
import '../../style.css'

const Shortcut = () => {

    const telas = useShallowEqualSelector<AppState, IConfiguracaoState['telas']>(state => state.configuracao.telas)
    const fiveTelas = useShallowEqualSelector<AppState, IConfiguracaoState['fiveTelas']>(state => state.configuracao.fiveTelas)

    useEffect(() => {

        getTelas()

    }, [])

    useEffect(() => {
        if (fiveTelas.length >= 5) {

            disableAll(true)

        } else {
            disableAll(false)
        }

    }, [fiveTelas])

    async function getTelas() {

        await controllerConfiguracao.getViews()

    }

    const checkedView = (codTela: Number) => {
        let strView = JSON.stringify(telas)
        let arrView = JSON.parse(strView)


        //@ts-ignore
        arrView.forEach(tela => {
            if ((codTela === tela.codTela)) {
                if (tela.status == "1") {
                    tela.status = "0"
                    addAtalhos(tela)
                } else {
                    tela.status = "1"
                    deleteShortCut(tela)
                }

            }
        });


        if (arrView !== telas) {
            actions.setTelas(arrView)
        }

    }

    const addAtalhos = (view: any) => {
        let strViewFive = JSON.stringify(fiveTelas)
        let arrViewFive = JSON.parse(strViewFive)

        if (arrViewFive.length === 0) {
            arrViewFive.push(view)
        } else {

            //@ts-ignore
            let check: any = arrViewFive.find(tela => (tela.codTela === view.codTela))

            if (!check) {
                arrViewFive.push(view)
            }
        }
        actions.setFiveTela(arrViewFive)
    }

    const cleanAll = async function () {

        actions.cleanAll()
        getTelas()
    }

    const disableAll = (value: boolean) => {
        let strView = JSON.stringify(telas)
        let arrView = JSON.parse(strView)


        //@ts-ignore
        arrView.forEach(tela => {
            if (tela.status !== "0") {
                tela.disable = value
            }

        });
        actions.setTelas(arrView)

    }

    const deleteShortCut = (value: any) => {
        let strViewFive = JSON.stringify(fiveTelas)
        let arrViewFive = JSON.parse(strViewFive)

        let strView = JSON.stringify(telas)
        let arrView = JSON.parse(strView)

        let index: any = arrViewFive.findIndex((tela: any) => tela.codTela === value.codTela)

        //@ts-ignore
        arrView.forEach(tela => {
            if (arrViewFive[index].codTela === tela.codTela) {
                tela.status = "1"
            }
        });
        actions.setTelas(arrView)

        //deletando no array de telas de atalhos
        arrViewFive.splice(index, 1)
        actions.setFiveTela(arrViewFive)


    }


    const getShortcuts = async function () {



        const retorno: any = await controllerDashboard.getFiveViews()

        retorno.forEach(async function (tela: any) {

            var reTela = await controllerDashboard.getCount(tela)
            if ((reTela.codAtalho === tela.codAtalho) && (reTela.codTela === tela.codTela)) {
                tela.qtdRegister = reTela.count
            }

        });

        setTimeout(() => { window.localStorage.setItem('TOPFIVE', JSON.stringify(retorno)) }, 500)

    }

    const handleSalvar = (() => {

        controllerConfiguracao.addShortCuts()

        setTimeout(() => { getShortcuts() }, 300)

    })

    return <>
        <div style={{ paddingTop: "0em !important" }}>

            <Card style={{ backgroundColor: "transparent" }}>
                <CardHeader>
                    <CardTitle tag='h3' > Selecione as telas que deseja aparecer como atalho no dashboard </CardTitle>
                </CardHeader>

                <CardBody>
                    <Row>
                        <Col lg="6">
                            <fieldset className="viewSet views">

                                <legend className='lengendViews' >
                                    Telas do Sistema
                                </legend>
                                <Col className='scrollBar'>

                                    {
                                        telas.map((view: any, keyViews: Number) => (
                                            <Col style={{ marginLeft: "1em" }}>
                                                <FormGroup check
                                                    //@ts-ignore
                                                    key={keyViews}>
                                                    <Row>
                                                        <Label>
                                                            <Input type="checkbox"
                                                                onClick={() => checkedView(view.codTela)}
                                                                checked={view.status === "0" ? true : false}
                                                                disabled={view.disable}
                                                            />
                                                            <span className="form-check-sign" />
                                                        </Label>
                                                        <Label onClick={() => { }} style={{ marginBottom: '0em', marginLeft: '4em' }}>{view.label}</Label>
                                                    </Row>
                                                </FormGroup>
                                            </Col>
                                        ))
                                    }
                                </Col>

                            </fieldset>

                        </Col>
                        <Col lg="6">
                            <fieldset className="viewSet shortcut">
                                <legend className='lengendViews ' >
                                    Suas telas de atalho
                                </legend>
                                {
                                    fiveTelas.map((view: any, keyViewsFive: Number) => (
                                        <Col >
                                            <FormGroup check
                                                //@ts-ignore
                                                key={keyViewsFive}>
                                                <Row style={{ marginLeft: "2em" }}>
                                                    <Label style={{ cursor: 'pointer' }}
                                                        onClick={() => deleteShortCut(view)}>
                                                        <Icon icon={circledeleteIcon} style={{ color: 'red', fontSize: "1.5em" }} />
                                                    </Label>
                                                    <Label onClick={() => { }} style={{ marginBottom: '0em', marginLeft: '1em' }}>{view.label}</Label>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    ))
                                }
                            </fieldset>

                        </Col>


                    </Row>

                </CardBody>
                <CardFooter>
                    <div className="d-flex justify-content-center">
                        <ButtonGroup align="center">

                            <Button
                                className="btn-padrao"
                                color="default"
                                onClick={() => { cleanAll() }}>
                                Limpar
                            </Button>


                            <Button className="btn-padrao active"
                                color="info"
                                onClick={() => { handleSalvar() }}
                            >
                                Salvar
                             </Button>

                        </ButtonGroup>
                    </div>
                </CardFooter>
            </Card>
        </div>
    </>

}
export default Shortcut