import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import * as controllerSubgrupoContas from "~/controller/controllerSubgrupoContas";
import { Select, Switch } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';
import {
    Input,
    Row,
    Col,
    CardBody,
    Button,
    CardFooter,
    FormGroup,
    ModalBody,
    Modal
} from "reactstrap";

const idStore = "SUBGRUPO_CONTAS";


const SubgrupoConta = () => {
    const { subgrupoConta, subgrupoContaCopia, invalido, grupoContas, verCadastro } = useSelector(state => state.subgrupoConta, shallowEqual);
    const [modalInativar, setModalInativar] = useState(false);
    const dispatch = useDispatch();

    function onChange(value, stateName) {
        dispatch({ idStore, type: 'SUBGRUPO_CONTA', campo: stateName, value: value });
        dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
    };

    async function onChangeAtivo(e) {
        toggleModalInativar()
    };

    function OnChangeGrupoConta(value, campo) {
        dispatch({ idStore, type: 'SUBGRUPO_CONTA', campo, value });
    }

    function inativar() {
        if (subgrupoConta.codSubgrupoConta !== '') {
            controllerSubgrupoContas.InativaSubgrupoConta(subgrupoConta);
        }
        dispatch({ idStore, type: 'SUBGRUPO_CONTA', campo: 'status', value: subgrupoConta.status === 1 ? 0 : 1 });
    }

    async function Salvar() {

        if (subgrupoConta.codSubgrupoConta === 0) {
            controllerSubgrupoContas.CreateSubgrupoConta(subgrupoConta);
        } else {
            controllerSubgrupoContas.UpdateSubgrupoConta(subgrupoConta, subgrupoContaCopia);
        }
    };

    function Limpar() {
        dispatch({ idStore, type: 'CLEAN_SUBGRUPO_CONTA' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        document.getElementById('descricao').focus()
    };

    function toggleModalInativar() {
        setModalInativar(!modalInativar);
    }

    return (
        <>
            <Row className="justify-content-center">
            <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

            </div>
                <Col>
                    <h4 className="info-text">Preencha com as informações do subgrupo de conta</h4>
                    <CardBody>

                        <Row className="justify-content-center">

                            <Col md="12" className="text-right">
                                <Switch
                                    componentleft={<label className={subgrupoConta.status === 0 ? `text-success text-center` : "text-warning text-center"}>{subgrupoConta.status === 0 ? "Ativo" : "Inativo"}</label>}
                                    checked={subgrupoConta.status === 0}
                                    onChange={e => onChangeAtivo(e)}
                                    disabled={subgrupoConta.codSubgrupoConta === 0}
                                />
                            </Col>

                            <Col md="12">

                                <Row className="padding-padrao-row">

                                    <Col className="padding-padrao-col" lg="3" md="3">
                                        <label>Grupos<span className="error"> *</span></label>
                                        <Select
                                            first="Selecione"
                                            value={{ value: subgrupoConta.codGrupoConta }}
                                            select={{ value: "codGrupoConta", label: 'descricao' }}
                                            options={grupoContas}
                                            onChange={value => OnChangeGrupoConta(value.value, 'codGrupoConta')}                                           
                                        />
                                    </Col>

                                    <Col className="padding-padrao-col" lg md sm="7">
                                        <label>Descrição<span className="error"> *</span></label>
                                        <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                                            <Input
                                                id="descricao"
                                                type="text"
                                                value={subgrupoConta.descricao}
                                                onChange={e => onChange(e.target.value, 'descricao')}
                                                autoComplete="off"
                                                maxLength="100"                                          
                                            />
                                            <br></br>
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row className="padding-padrao-row">
                                    <Col className="padding-padrao-col">
                                            <label>Observação</label>
                                                <Input
                                                    type="text"
                                                    value={subgrupoConta.observacao}
                                                    onChange={e => onChange(e.target.value, 'observacao')}
                                                    autoComplete="off"
                                                    maxLength="150"                                           
                                                />
                                                <br></br>

                                        </Col>
                                </Row>

                            </Col>

                        </Row>

                    </CardBody>
                    <CardFooter className="text-center">
                        <Row>
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                <ArrowTooltip title='Shift+N'>
                                    <Button className="btn-padrao" color="default" onClick={() => Limpar()}>Novo</Button>
                                </ArrowTooltip>
                                <ArrowTooltip title='Shift+S'>
                                    <Button className="btn-padrao" color="info" onClick={() => Salvar()}>Salvar</Button>
                                </ArrowTooltip>
                            </Col>
                        </Row>
                    </CardFooter>
                </Col>
            </Row>

            {/* Modal Inativar */}
            <Modal
                isOpen={modalInativar}
                toggle={toggleModalInativar}
            >
                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalInativar}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">{subgrupoConta.status === 0 ? "Inativar" : "Ativar"} subgrupo de conta</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>
                            {subgrupoConta.status === 0 ?
                                <>Tem certeza que deseja <b>inativar</b> esse subgrupo de conta da sua empresa?</>
                                : <>Tem certeza que deseja <b>ativar</b> esse subgrupo de conta em sua empresa?</>
                            }
                        </label>
                    </Col>

                </ModalBody>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { toggleModalInativar() }}
                                style={{ width: '150px' }}
                            >NÃO
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { toggleModalInativar(); inativar() }}
                                style={{ width: '150px' }}
                            >SIM
                                            </Button>
                        </Col>
                    </Row>
                </CardBody>

            </Modal>
            {/* Modal Inativar*/}

        </>
    )
};

class Wizard extends React.Component {
    focusInput = () => {
        setTimeout(() => {
            document.getElementById('descricao').focus()
        }, 100)
    }

    render() {
        return (
            <SubgrupoConta />
        )
    }
}

export default Wizard;