import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertar } from "~/Util/alertar";
import * as controllerClientes from "~/controller/controllerClientes";
import ReactTable from "react-table";
import classNames from "classnames";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { Select, InputTelefone } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from "~/components/Tooltip/ArrowTooltip";

import {
  Input,
  Row,
  Col,
  CardBody,
  FormGroup,
  Label,
  CardFooter,
  Button,
} from "reactstrap";

const idStore = "CLIENTES";

const tiposEndereco = [
  { value: "R", label: "Residencial" },
  { value: "C", label: "Comercial" },
  { value: "E", label: "Entrega" },
];

const Endereco = () => {
  const dispatch = useDispatch();
  const { cliente, endereco, invalido, enderecos, enderecoCopia, verCadastro, paises, initPaises } = useSelector(state => state.clientes);
  const notificacao = useSelector(state => state.notificacao);
  const [dataTable, setDataTable] = useState([]);
  const [table, setTable] = useState(renderTable([]));


  useEffect(() => {
    setDataTable(tableData(enderecos));
  }, [enderecos, endereco.codEnd]);

  useEffect(() => {
    setTable(renderTable());
  }, [dataTable]);

  useEffect(() => {
    dispatch({ idStore, type: "ENDERECO_COPIA" });
  }, []);

  useEffect(() => {
    controllerClientes.GetPaises()
  }, []);

  function tableData(data) {
    return data.map((prop, key) => {
      return {
        id: key,
        codEnd: prop["codEnd"],
        cep: prop["cep"],
        logradouro: prop["logradouro"],
        numero: prop["numero"],
        tipoEnd: prop["tipoEnd"] === "R" ? "Residencial" : prop["tipoEnd"] === "C" ? "Comercial" : "Entrega",
        flPrincipal: prop["flPrincipal"],
        status: prop["status"],
        idPais: prop["idPais"]
      };
    });
  }
  const findPais = (prop) => {
    if (prop && initPaises.length !== 0) {
      let pais = initPaises.find(pais => pais.idPais === prop)
      let label
      if (pais) {
        label = pais.pais
      }
      return label
    } else {
      return ""
    }

  }

  function renderTable() {
    if (verCadastro === false) {
      return dataTable.map((obj, key) => {
        return {
          ...dataTable[key],
          actions: (
            <div className="actions-right">
              <Label
                className="text-success"
                style={obj.flPrincipal !== "S" ? { opacity: "0" } : {}}
              >
                Principal
              </Label>
              <ArrowTooltip title="Editar">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    editarEnderero(obj);
                  }}
                  size="sm"
                  className={classNames("btn-icon btn-link")}
                  color="success"
                >
                  <Icon icon="edit" className="fa-lg" />
                </Button>
              </ArrowTooltip>

              <ArrowTooltip title="Excluir">
                <Button
                  onClick={() => {
                    excluirEndereco(obj);
                  }}
                  className="btn-icon btn-link"
                  color="danger"
                >
                  <Icon icon="trash-alt" className="fa-lg" />
                </Button>
              </ArrowTooltip>
            </div>
          ),
        };
      });
    } else {
      return dataTable.map((obj, key) => {
        return dataTable[key];
      });
    }
  }

  function onChange(event, stateName) {
    dispatch({
      idStore,
      type: "ENDERECO",
      campo: stateName,
      value: event.target.value,
    });
    dispatch({ idStore, type: "INVALIDO", campo: stateName, value: false });
  }

  function onChangeTipoEnd(value) {
    dispatch({ idStore, type: "ENDERECO", campo: "tipoEnd", value: value });
  }

  function onChangePais(value) {
    dispatch({ idStore, type: "ENDERECO", campo: "idPais", value: value.value });
  }
  function onChangeCheckFlPrincipal() {
    dispatch({
      idStore,
      type: "ENDERECO",
      campo: "flPrincipal",
      value: endereco.flPrincipal === "S" ? "" : "S",
    });
  }

  function editarEnderero(end) {
    dispatch({ idStore, type: "CLEAN_INVALIDO" });
    controllerClientes.GetEndereco(
      notificacao,
      dispatch,
      end.codEnd,
      enderecos
    );
    if (cliente.tipoCli !== "E") {
      document.getElementById("cep").focus();
    }
  }

  function excluirEndereco(end) {
    if (end.cep === endereco.cep) {
      dispatch({ idStore, type: "CLEAN_ENDERECO" });
    }
    controllerClientes.ExcluirEndereco(
      notificacao,
      dispatch,
      cliente.codCli,
      end.codEnd
    );
  }

  async function handleKeyPress(event) {
    if (event.key === "Enter") {
      const valido = await controllerClientes.BuscarCep(
        notificacao,
        dispatch,
        endereco.cep
      );
      if (valido) {
        document.getElementById("numero").focus();
        dispatch({ idStore, type: "INVALIDO", campo: "uf", value: false });
        dispatch({ idStore, type: "INVALIDO", campo: "cidade", value: false });
        dispatch({ idStore, type: "INVALIDO", campo: "bairro", value: false });
        dispatch({
          idStore,
          type: "INVALIDO",
          campo: "logradouro",
          value: false,
        });
      }
    }
  }

  async function Salvar() {
    let err;
    if (endereco.codEnd === "") {
      err = await controllerClientes.CreateEndereco(
        notificacao,
        dispatch,
        cliente.codCli,
        endereco,
        enderecoCopia
      );
      if (err) {
        dispatch({ idStore, type: "CLEAN_ENDERECO" });
        dispatch({ idStore, type: "CLEAN_INVALIDO" });
        dispatch({ idStore, type: "ENDERECO_COPIA" });
      }
    } else {
      err = controllerClientes.UpdateEndereco(
        notificacao,
        dispatch,
        cliente.codCli,
        endereco,
        enderecoCopia
      );
      if (err) {
        dispatch({ idStore, type: "CLEAN_ENDERECO" });
        dispatch({ idStore, type: "CLEAN_INVALIDO" });
        dispatch({ idStore, type: "ENDERECO_COPIA" });
      }
    }
  }

  function Novo() {
    dispatch({ idStore, type: "CLEAN_ENDERECO" });
    dispatch({ idStore, type: "CLEAN_INVALIDO" });
    dispatch({ idStore, type: "ENDERECO_COPIA" });
  }

  return (
    <>
      <Row className="justify-content-center">
        <div className={verCadastro ? "ver-cadastro" : ""}></div>
        <Col>
          <h4 className="info-text">
            Preencha com as informações do endereço do cliente ou edite um
            existente
          </h4>
          <CardBody>
            {cliente.tipoCli !== "E" ?
              <Row className="justify-content-center">
                <Col md="10">
                  <Row>
                    <Col lg="3" md="5" sm style={{ fontSize: "10pt" }}>
                      <label>Tipo de Endereço</label>
                      <Select
                        value={{ value: endereco.tipoEnd }}
                        name="tipoEndSelect"
                        onChange={(value) => onChangeTipoEnd(value.value)}
                        options={tiposEndereco}
                        isSearchable={false}
                      />
                      <br></br>
                    </Col>

                    <Col lg="2" md="9" sm="4">
                      <label>CEP<span className="error"> *</span></label>
                      <FormGroup className={invalido.cep ? "has-danger" : ''}>
                        <Input
                          id="cep"
                          type="text"
                          value={endereco.cep}
                          onKeyPress={handleKeyPress}
                          onChange={(e) => onChange(e, "cep")}
                          autoComplete="off"
                          maxLength="9"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col lg md="9">
                      <label>Endereço<span className="error"> *</span></label>
                      <FormGroup className={invalido.logradouro ? "has-danger" : ''}>
                        <Input
                          id="logradouro"
                          type="text"
                          value={endereco.logradouro}
                          onChange={(e) => onChange(e, "logradouro")}
                          autoComplete="off"
                          maxLength="100"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col lg="2" md="3">
                      <label>Número<span className="error"> *</span></label>
                      <FormGroup className={invalido.numero ? "has-danger" : ''}>
                        <Input
                          id="numero"
                          type="text"
                          value={endereco.numero}
                          onChange={(e) => onChange(e, "numero")}
                          autoComplete="off"
                          maxLength="6"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="auto" md="2">
                      <label>UF<span className="error"> *</span></label>
                      <FormGroup className={invalido.uf ? "has-danger" : ''} style={{ width: '50px' }}>
                        <Input
                          style={{ paddingLeft: "15px", paddingRight: "15px" }}
                          id="uf"
                          type="text"
                          value={endereco.uf}
                          onChange={(e) => onChange(e, "uf")}
                          autoComplete="off"
                          maxLength="2"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col lg md="6">
                      <label>Cidade<span className="error"> *</span></label>
                      <FormGroup className={invalido.cidade ? "has-danger" : ''}>
                        <Input
                          id="cidade"
                          type="text"
                          value={endereco.cidade}
                          onChange={(e) => onChange(e, "cidade")}
                          autoComplete="off"
                          maxLength="100"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col lg="3" md>
                      <label>Bairro<span className="error"> *</span></label>
                      <FormGroup className={invalido.bairro ? "has-danger" : ''}>
                        <Input
                          id="bairro"
                          type="text"
                          value={endereco.bairro}
                          onChange={(e) => onChange(e, "bairro")}
                          autoComplete="off"
                          maxLength="100"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col lg md>
                      <label>Complemento </label>
                      <FormGroup>
                        <Input
                          id="complemento"
                          type="text"
                          value={endereco.complemento}
                          onChange={(e) => onChange(e, "complemento")}
                          autoComplete="off"
                          maxLength="50"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md>
                      <label>Referência </label>
                      <FormGroup>
                        <Input
                          id="referencia"
                          type="text"
                          value={endereco.referencia}
                          onChange={(e) => onChange(e, "referencia")}
                          autoComplete="off"
                          maxLength="70"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col lg="3" md="4">
                      <label>Telefone </label>
                      <FormGroup>
                        <InputTelefone
                          id="foneRes"
                          type="text"
                          value={endereco.foneRes}
                          onChange={(e) => onChange(e, "foneRes")}
                          autoComplete="off"
                          maxLength="14"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>
                    <Col lg="3" md="4">
                      <label>Celular </label>
                      <FormGroup>
                        <InputTelefone
                          id="foneCel"
                          type="text"
                          value={endereco.foneCel}
                          onChange={(e) => onChange(e, "foneCel")}
                          autoComplete="off"
                          maxLength="15"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col lg="auto" md="3" sm className="text-center">
                      <Label> </Label>
                      <FormGroup check className="form-check">
                        <Label check style={{ paddingRight: "5px" }}>
                          <Input
                            type="checkbox"
                            onChange={(e) => onChangeCheckFlPrincipal(e)}
                            checked={endereco.flPrincipal === "S" ? true : false}
                          />
                          <span className="form-check-sign" />
                          <label>Principal</label>
                        </Label>
                      </FormGroup>
                      <br></br>
                    </Col>
                  </Row>
                </Col>
              </Row>
              :
              <Row className="justify-content-center">
                <Col md="10">
                  <Row>
                    <Col lg="3" md="5" sm style={{ fontSize: "10pt" }}>
                      <label>Tipo de Endereço</label>
                      <Select
                        value={{ value: endereco.tipoEnd }}
                        name="tipoEndSelect"
                        onChange={(value) => onChangeTipoEnd(value.value)}
                        options={tiposEndereco}
                        isSearchable={false}
                      />
                      <br></br>
                    </Col>

                    <Col lg="9" md="10" sm="10">
                      <label>País <span className="error"> *</span></label>
                      <FormGroup className={invalido.idPais ? "has-danger" : ""}>
                        <Select
                          value={{ value: endereco.idPais }}
                          name="idPaisSelect"
                          onChange={(value) => onChangePais(value)}
                          options={paises}
                          isSearchable={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="2" md="6" sm="2">
                      <label>Código Postal</label>
                      <FormGroup >
                        <Input
                          id="codPostal"
                          type="text"
                          value={endereco.codPostal}
                          onChange={(e) => onChange(e, "codPostal")}
                          autoComplete="off"
                          maxLength="9"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>
                    <Col lg md="2">
                      <label>Endereço<span className="error"> *</span></label>
                      <FormGroup className={invalido.logradouro ? "has-danger" : ''}>
                        <Input
                          id="logradouro"
                          type="text"
                          value={endereco.logradouro}
                          onChange={(e) => onChange(e, "logradouro")}
                          autoComplete="off"
                          maxLength="100"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col lg="2" md="3">
                      <label>Número</label>
                      <FormGroup >
                        <Input
                          id="numero"
                          type="text"
                          value={endereco.numero}
                          onChange={(e) => onChange(e, "numero")}
                          autoComplete="off"
                          maxLength="6"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="2" md="3">
                      <label>Estado<span className="error"> *</span></label>
                      <FormGroup className={invalido.uf ? "has-danger" : ''}>
                        <Input
                          style={{ paddingLeft: "15px", paddingRight: "15px" }}
                          id="estado"
                          type="text"
                          value={endereco.uf}
                          onChange={(e) => onChange(e, "uf")}
                          autoComplete="off"
                          maxLength="15"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col lg md="6">
                      <label>Cidade<span className="error"> *</span></label>
                      <FormGroup className={invalido.cidade ? "has-danger" : ''}>
                        <Input
                          id="cidade"
                          type="text"
                          value={endereco.cidade}
                          onChange={(e) => onChange(e, "cidade")}
                          autoComplete="off"
                          maxLength="100"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col lg="3" md>
                      <label>Bairro<span className="error"> *</span></label>
                      <FormGroup className={invalido.bairro ? "has-danger" : ''}>
                        <Input
                          id="bairro"
                          type="text"
                          value={endereco.bairro}
                          onChange={(e) => onChange(e, "bairro")}
                          autoComplete="off"
                          maxLength="100"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col lg md>
                      <label>Complemento </label>
                      <FormGroup>
                        <Input
                          id="complemento"
                          type="text"
                          value={endereco.complemento}
                          onChange={(e) => onChange(e, "complemento")}
                          autoComplete="off"
                          maxLength="50"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md>
                      <label>Referência </label>
                      <FormGroup>
                        <Input
                          id="referencia"
                          type="text"
                          value={endereco.referencia}
                          onChange={(e) => onChange(e, "referencia")}
                          autoComplete="off"
                          maxLength="70"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col lg="3" md="4">
                      <label>Telefone </label>
                      <FormGroup>
                        <InputTelefone
                          id="foneRes"
                          type="text"
                          value={endereco.foneRes}
                          onChange={(e) => onChange(e, "foneRes")}
                          autoComplete="off"
                          maxLength="14"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col lg="3" md="4">
                      <label>Celular </label>
                      <FormGroup>
                        <InputTelefone
                          id="foneCel"
                          type="text"
                          value={endereco.foneCel}
                          onChange={(e) => onChange(e, "foneCel")}
                          autoComplete="off"
                          maxLength="15"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col lg md sm className="text-center">
                      <Label> </Label>
                      <FormGroup check className="form-check">
                        <Label check style={{ paddingRight: "5px" }}>
                          <Input
                            type="checkbox"
                            onChange={(e) => onChangeCheckFlPrincipal(e)}
                            checked={endereco.flPrincipal === "S" ? true : false}
                          />
                          <span className="form-check-sign" />
                          <label>Principal</label>
                        </Label>
                      </FormGroup>
                      <br></br>
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          </CardBody>

          <CardFooter className="text-center">
            <Col style={{ display: "flex", textAlign: "center" }}>
              <ArrowTooltip title="Shift+N">
                <Button
                  className="btn-padrao"
                  style={{ left: "33%", position: "relative" }}
                  color="default"
                  onClick={() => Novo()}
                >
                  Novo
                </Button>
              </ArrowTooltip>
              <ArrowTooltip title="Shift+S">
                <Button
                  className="btn-padrao"
                  style={{ left: "33%", position: "relative" }}
                  color="info"
                  onClick={() => Salvar()}
                >
                  {" "}
                  Salvar
                </Button>
              </ArrowTooltip>
            </Col>
          </CardFooter>

          <CardBody>
            <Row className="justify-content-center">
              <Col md="10">
                <div
                  style={{ borderRadius: "10px", border: "solid 1px #363B4A" }}
                >
                  {verCadastro ? (
                    <ReactTable
                      data={table}
                      filterable={false}
                      columns={[
                        {
                          Header: "CEP",
                          accessor: "cep",
                        },
                        {
                          Header: "Lodradouro",
                          accessor: "logradouro",
                        },
                        {
                          Header: "Número",
                          accessor: "numero",
                        },
                        {
                          Header: "Tipo",
                          accessor: "tipoEnd",
                        },
                      ]}
                      minRows={1}
                      showPaginationTop={false}
                      showPaginationBottom={false}
                      className="-striped -highlight"
                      noDataText="Nenhum endereço cadastrado"
                    />
                  ) : (
                      <ReactTable
                        data={table}
                        filterable={false}
                        columns={[
                          {
                            Header: cliente.tipoCli === "E" ? "Código Postal" : "CEP",
                            accessor: "cep",
                          },
                          {
                            Header: "Lodradouro",
                            accessor: "logradouro",
                          },
                          {
                            Header: "Número",
                            accessor: "numero",
                          },
                          {
                            Header: "Tipo",
                            accessor: "tipoEnd",
                          }, {
                            Header: "País",
                            accessor: "pais",
                            Cell: (prop) => findPais(prop.original.idPais)
                          },
                          {
                            Header: "Ações",
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        minRows={1}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        className="-striped -highlight"
                        noDataText="Nenhum endereço cadastrado"
                      />
                    )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Col>
      </Row>
    </>
  );
};

class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      if (this.props.cliente.tipoCli !== "E") {
        document.getElementById("cep").focus();
      }
    }, 1000);
  };

  isValidated = () => {
    if (this.props.cliente.codEnd !== "") {
      return true;
    }
    alertar(
      this.props.notificacao,
      "br",
      "Efetue o cadastro do cliente para continuar",
      "info",
      "icon-bulb-63",
      2
    );
    return false;
  };
  render() {
    return <Endereco />;
  }
}

export default Wizard;
