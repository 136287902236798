import moment from 'moment'
import { Reducer } from 'redux'
import lancamentoTitulos from '../../lancamentoTitulosStore'
import { IFaturamentoState, FaturamentoTypes, FaturamentoAction } from './types'

const INITIAL_STATE: IFaturamentoState = {
    idLancamento: 0,
    numeroPedido: 0,
    codigoCliente: 0,
    codigoConta: 0,
    codigoGrupoConta: 0,
    codigoSubGrupoConta: 0,
    codigoFuncionario: 0,
    lancamento: {
        parcela: "1",
        valor: '',
        firstVencimento: moment(new Date(), 'DD-MM-YYYY').add('days', 30).format('YYYY-MM-DD'),
    },
    totais: {
        totalLancado: '00,00',
        totalRestante: '00,00',
        subTotal: '00,00',
        descontos: '00,00',
        total: '00,00',
        troco: '00,00'
    },
    pagamentoSelecionado: {
        codForma: 0,
        codTipoPgto: 0,
        descricao: '',
        descricaoTipo: '',
        qtdDiasEntreParcelas: 0,
        diasPrazo: 0,
        percentualTarifa: 0,
        flAbreCaixa: 0,
        flretirada: 0,
        flaVista: 0,
        flBaixaAutomatica: 0,
        status: 0,
    },
    pagamentos: [],
    lancamentos: [],
    teclado: false
}

const pedidoVenda: Reducer<IFaturamentoState, FaturamentoAction> = (state = INITIAL_STATE, action: FaturamentoAction) => {
    switch (action.idStore) {
        case 'FATURAMENTO':
            switch (action.type) {
                case FaturamentoTypes.CLEAN_ALL:
                    return INITIAL_STATE
                case FaturamentoTypes.CLEAN:
                    return {
                        ...INITIAL_STATE,
                        totais: { ...INITIAL_STATE.totais, subTotal: state.totais.subTotal, totalRestante: state.totais.subTotal },
                        pagamentos: state.pagamentos
                    }
                case FaturamentoTypes.LANCAMENTO:
                    if (action.lancamento)
                        return { ...state, lancamento: action.lancamento }
                    else return state
                case FaturamentoTypes.LANCAMENTOS:
                    if (action.lancamentos)
                        return { ...state, lancamentos: action.lancamentos }
                    else return state
                case FaturamentoTypes.PAGAMENTOS:
                    if (action.pagamentos)
                        return { ...state, pagamentos: action.pagamentos }
                    else return state
                case FaturamentoTypes.TOTAIS:
                    if (action.totais)
                        return { ...state, totais: action.totais }
                    else return state
                case FaturamentoTypes.TECLADO:
                    if (action.teclado)
                        return { ...state, teclado: action.teclado }
                    else return state
                case FaturamentoTypes.PAGAMENTO_SELECIONADO:
                    if (action.pagamentoSelecionado)
                        return { ...state, pagamentoSelecionado: action.pagamentoSelecionado }
                    else return state
                case FaturamentoTypes.NUMERO_PEDIDO:
                    if (action.numeroPedido)
                        return { ...state, numeroPedido: action.numeroPedido }
                    else return state

                case FaturamentoTypes.INFORMACOES:
                    if (action.informacoes)
                        return {
                            ...state,
                            numeroPedido: action.informacoes.numeroPedido,
                            codigoCliente: action.informacoes.codigoCliente,
                            codigoConta: action.informacoes.codigoConta,
                            codigoGrupoConta: action.informacoes.codigoGrupoConta,
                            codigoSubGrupoConta: action.informacoes.codigoSubGrupoConta,
                            codigoFuncionario: action.informacoes.codigoFuncionario
                        }
                    else return state

                case FaturamentoTypes.ID_FINANCEIRO:
                    if (action.value)
                        return {
                            ...state,
                            idLancamento: action.value
                        }
                    else return state
                default:
                    return state
            }
        default:
            return state
    }
}

export default pedidoVenda