import React from 'react'

import { Col, Row, Input, Button } from 'reactstrap'
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from '../../../components/ModalBasic/ModalBasic'

import * as controller from '../../../controller/controllerProdutos'
import useShallowEqualSelector from '../../../Util/Hooks/useShallowEqualSelector'

import AppState from '../../../store/storeType'
import { Fornecedor, Pesquisa } from '../../../store/reducers/Cadastro/Produto/types'
import * as actions from '../../../store/reducers/Cadastro/Produto/actions'

interface ModalPesquisaProps {
    isOpen: boolean
    toggle: () => void
    forneSelecionados: (number | string)[]
    setforneSelecionados: (value: React.SetStateAction<(number | string)[]>) => void
    handleKeyPressModal: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const ModalPesquisa: React.FC<ModalPesquisaProps> = ({ isOpen, toggle, forneSelecionados, setforneSelecionados, handleKeyPressModal }) => {

    //@ts-ignore
    const { codEmpresa } = useShallowEqualSelector(state => state.login.usuario.empresa)

    const pesquisa = useShallowEqualSelector<AppState, Pesquisa>(state => state.produtos.pesquisa)
    const fornecedoresProd = useShallowEqualSelector<AppState, Fornecedor[]>(state => state.produtos.fornecedoresProd)

    function handlePesquisarFornecedor() {
        controller.GetFornecedorProduto(codEmpresa, pesquisa)
    }

    function addCod(codFor: number | string) {
        let arr: (number | string)[] = JSON.parse(JSON.stringify(forneSelecionados))
        let aux = -1
        arr.forEach((value: number | string, key: number) => { if (codFor === value) aux = key })
        if (aux !== -1) {
            arr.splice(aux, 1)
        } else {
            arr.push(codFor)
        }

        setforneSelecionados(arr)
    }

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title={'Pesquisa de Fornecedores'}
        >
            <ModalBasicBody>
                <Col>
                    <Row>
                        <Col lg md sm>
                            <label>Fornecedor</label>
                            <Input
                                type="text"
                                autoComplete="off"
                                value={pesquisa.nomeRazao}
                                onChange={e => actions.setPesquisa({ ...pesquisa, nomeRazao: e.target.value })}
                                onKeyPress={e => handleKeyPressModal(e)}
                                disabled={false}
                            />
                        </Col>
                        <Col lg="auto" md="auto" sm="auto">
                            <Button
                                style={{ marginTop: '34px', width: '40px' }}
                                type='button'
                                color="info"
                                onClick={handlePesquisarFornecedor}
                            >
                                <Icon icon="search" className="fa-lg" />
                            </Button>
                        </Col>

                    </Row>

                    <Row>
                        <Col lg md sm>
                            {fornecedoresProd.length > 0 ?
                                <div className="list-forne" >
                                    {fornecedoresProd.map((forne: any, key: number) => (
                                        <div
                                            className="row-forne"
                                            key={key}
                                            onClick={() => addCod(forne.codFor)}
                                        >
                                            <label className="nome-forne">
                                                {forne.nomeRazao}
                                            </label >
                                            <div className={`checkbox ${forneSelecionados.find((itemForne: number | string) => itemForne === forne.codFor) ? "selected" : ""}`} >
                                                {forneSelecionados.find((itemForne: number | string) => itemForne === forne.codFor) && (
                                                    <Icon color="white" icon="check" className="fa-lg check" />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                : null}
                        </Col>
                    </Row>
                </Col>
            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <Button
                            color="success"
                            type="button"
                            onClick={() => toggle()}
                            style={{ width: '100px' }}
                        >OK
                        </Button>
                    </Col>
                </Row>
            </ModalBasicFooter>

        </ModalBasic >
    )
}

export default ModalPesquisa