import React, { useState, useLayoutEffect, useEffect } from 'react'
import {
    Card,
    Input,
    CardHeader,
    Row,
    Col,
    CardBody,
    ButtonGroup,
    TabContent,
    TabPane,
    Label,
    FormGroup,
    CardFooter
} from 'reactstrap'
import { useSelector } from 'react-redux';


import useShallowEqualSelector from '../../../../../../../Util/Hooks/useShallowEqualSelector';
import AppState from '../../../../../../../store/storeType';
import { IUsuarioState } from '../../../../../../../store/reducers/Cadastro/Usuario/types';
import { Select } from '../../../../../../../components/ErnComponets/ErnComponets';
import * as actions from '../../../../../../../store/reducers/Cadastro/Usuario/actions';
import * as controllerUsuarios from "../../../../../../../controller/controllerUsuarios";
import { HelpButton } from '../../../../../../../components/HelpPlugin/HelpPlugin';
import { ButtonGrupos, FormGrupoTelas } from './components';
import ArrowTooltip from '../../../../../../../components/Tooltip/ArrowTooltip';
import { PopUpViculacaoGrupoAcesso, PopUpSalvarGrupo } from "../../../../../Moldals/PopUp";
import useModal from '../../../../../../../Util/Hooks/useModal';
import { alertar } from '../../../../../../../Util/alertar';
import store from '../../../../../../../store/stores';
import { useHotkeys } from 'react-hotkeys-hook';


const Acesso = () => {

    //@ts-ignore
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);

    const empresas = useShallowEqualSelector<AppState, IUsuarioState['empresas']>(state => state.usuario.empresas)
    const usuario = useShallowEqualSelector<AppState, IUsuarioState['usuario']>(state => state.usuario.usuario)
    const grupos = useShallowEqualSelector<AppState, IUsuarioState['grupos']>(state => state.usuario.grupos)
    const acesso = useShallowEqualSelector<AppState, IUsuarioState['acesso']>(state => state.usuario.acesso)
    const verCadastro = useShallowEqualSelector<AppState, IUsuarioState['verCadastro']>(state => state.usuario.verCadastro)
    const empresasUser = useShallowEqualSelector<AppState, IUsuarioState['empresasUser']>(state => state.usuario.empresasUser)
    const gruposAcessoUsuario = useShallowEqualSelector<AppState, IUsuarioState['gruposAcessoUsuario']>(state => state.usuario.gruposAcessoUsuario)

    const [loadingGrupos, setLoadingGrupos] = useState<boolean>(false)
    const [horizontalTabs, setHorizontalTabs] = useState<any>({ codGrupoAcesso: 0, descricao: '' });

    const [modalVicularGrupoAcesso, toggleModalVicularGrupoAcesso] = useModal(false)
    const [modalSalvarGrupo, toggleModalSalvarGrupo] = useModal(false)

    const [selectEmpresaFilial, setSelectEmpresaFilial] = useState<any>('0')

    useLayoutEffect(() => {

        if (usuario.codUser !== '0') {

            setLoadingGrupos(true)
            controllerUsuarios.GetGrupos(codEmpresa)
            if (gruposAcessoUsuario !== null) {
                if (gruposAcessoUsuario.length > 0) {
                    actions.setAcesso({
                        ...acesso,
                        codEmpresaFilial: gruposAcessoUsuario[0].codEmpresaFilial,
                        codGrupoAcesso: gruposAcessoUsuario[0].codGrupoAcesso === '6' ? grupos[0].codGrupoAcesso : gruposAcessoUsuario[0].codGrupoAcesso,
                        nomeGrupo: gruposAcessoUsuario[0].nomeGrupo
                    })
                    setHorizontalTabs({
                        ...horizontalTabs,
                        codGrupoAcesso: Number(gruposAcessoUsuario[0].codGrupoAcesso === '6' ? grupos[0].codGrupoAcesso : gruposAcessoUsuario[0].codGrupoAcesso),
                        descricao: gruposAcessoUsuario[0].nomeGrupo === 'PADRÃO' ? grupos[0].descricao : gruposAcessoUsuario[0].nomeGrupo
                    })
                }
            }

        }

    }, [empresasUser, usuario.codUser])

    useLayoutEffect(() => {

        if (empresasUser.length > 0) {
            setSelectEmpresaFilial(empresasUser[0].codFilial)
        }

    }, [empresasUser, usuario])

    function checkAll(keyGrupo: number) {

        let gruposStr = JSON.stringify(grupos)
        let gruposArr = JSON.parse(gruposStr)
        let grupoSeleciona = gruposArr[keyGrupo]
        if (!grupoSeleciona.checkedAll) {
            grupoSeleciona.checkedAll = !grupoSeleciona.checkedAll
            grupoSeleciona.telas.forEach((tela: any) => {
                tela.status = 0
                tela.changed = !tela.changed
            })
            gruposArr[keyGrupo] = grupoSeleciona
            actions.setGrupos(gruposArr)
        } else {
            grupoSeleciona.checkedAll = !grupoSeleciona.checkedAll
            grupoSeleciona.telas.forEach((tela: any) => {
                tela.status = 1
                tela.changed = !tela.changed
            })
            gruposArr[keyGrupo] = grupoSeleciona
            actions.setGrupos(gruposArr)
        }

    }

    const handleCheckTelas = (keyTela: number, keyGrupo: number) => {
        let gruposStr = JSON.stringify(grupos)
        let gruposArr = JSON.parse(gruposStr)
        gruposArr[keyGrupo].telas[keyTela].status = gruposArr[keyGrupo].telas[keyTela].status === 0 ? 1 : 0
        gruposArr[keyGrupo].telas[keyTela].changed = !gruposArr[keyGrupo].telas[keyTela].changed

        actions.onChangeTelaAcesso(gruposArr)
    }

    const VincularGrupo = async function () {

        if (await controllerUsuarios.SalvarUsuarioGrupo(codEmpresa)) {
            actions.setAcesso(
                {
                    ...acesso,
                    codGrupoAcesso: horizontalTabs.codGrupoAcesso,
                    nomeGrupo: horizontalTabs.descricao
                })
        }
    }

    const handleVincularGrupo = () => {

        if (acesso.codGrupoAcesso !== '') {

            toggleModalVicularGrupoAcesso()

        } else {
            //@ts-ignore
            const { notificacao } = store.getState()

            alertar(notificacao, 'br', 'Necessário selecionar um grupo de acesso', 'warning', 'icon-alert-circle-exc', 2);

        }
    }
    useHotkeys('Shift+V', () => {

        handleVincularGrupo()

    })
    const handleOnChangeEmpresaFilial = (value: any) => {
        let strAcesso = JSON.stringify(acesso)
        let arrAcesso = JSON.parse(strAcesso)

        setSelectEmpresaFilial(value)

        gruposAcessoUsuario.forEach((grupo: any) => {

            if (grupo.codEmpresaFilial === value) {

                arrAcesso.nomeGrupo = grupo.nomeGrupo
                setHorizontalTabs({
                    ...horizontalTabs,
                    codGrupoAcesso: grupo.codGrupoAcesso === '6' ? grupos[0].codGrupoAcesso : grupo.codGrupoAcesso,
                    descricao: grupo.nomeGrupo === 'PADRÃO' ? grupos[0].descricao : grupo.nomeGrupo
                })
            }

        })
        actions.setAcesso(arrAcesso)
    }

    const SalvarGrupo = () => {

        if (controllerUsuarios.UpdateGrupo(acesso.codEmpresaFilial, acesso.codGrupoAcesso)) {
            grupos.forEach((grupo: any) => {
                grupo.telas.forEach((tela: any) => {
                    tela.changed = !tela.changed
                });
            });
        }

    }

    const handleEditarGrupo = () => {

        toggleModalSalvarGrupo()

    }

    const handleOnChangeGrupo = (grupo: any) => {

        actions.setAcesso({
            ...acesso,
            codEmpresaFilial: selectEmpresaFilial,
            codGrupoAcesso: grupo.codGrupoAcesso
        })

        setHorizontalTabs({
            ...horizontalTabs,
            codGrupoAcesso: grupo.codGrupoAcesso,
            descricao: grupo.descricao
        });

    }

    return (
        <>
            <div>
                <Card>
                    <div className={verCadastro === true ? 'ver-cadastro' : ''}>

                    </div>
                    <CardHeader>
                        <Row>
                            <Col className="padding-padrao-col" lg='6'>
                                <div style={{ cursor: 'not-allowed' }}>
                                    <Select
                                        id="empresa"
                                        first="Selecione"
                                        value={{ value: codEmpresa }}
                                        select={{ value: 'codEmpresa', label: 'descricao' }}
                                        onChange={(event: any) => actions.setUsuario({ ...usuario, codEmpresa: event.value })}
                                        //@ts-ignore
                                        options={empresas}
                                        disabled={true}
                                    />
                                </div>
                                <br></br>
                            </Col>
                            <Col className="padding-padrao-col" lg='6'>
                                <Select
                                    id="filial"
                                    first="Selecione"
                                    value={{ value: selectEmpresaFilial }}
                                    select={{ value: 'codFilial', label: 'filial' }}
                                    onChange={(event: any) => { handleOnChangeEmpresaFilial(event.value), setLoadingGrupos(event.value != "0" ? true : false) }}
                                    //@ts-ignore
                                    options={empresasUser}
                                />
                                <br></br>
                            </Col>
                        </Row>
                    </CardHeader>
                    {loadingGrupos ?
                        <>
                            <CardBody>
                                <Row style={{ alignItems: 'center' }}>
                                    <h4>Selecione um grupo de acesso</h4>
                                    <Col style={{ marginLeft: '-10px', marginTop: '-14px' }}>
                                        <HelpButton id="20" />
                                    </Col>
                                </Row>
                                <Row style={{ justifyContent: "center" }}>
                                    <div >
                                        <ButtonGroup>
                                            {
                                                grupos.map((grupo: any) => (
                                                    <ButtonGrupos
                                                        key={grupo.codGrupoAcesso}
                                                        color={acesso.nomeGrupo === grupo.descricao ? "success" :
                                                            horizontalTabs.descricao === grupo.descricao
                                                                ? "info"
                                                                : ""}
                                                        className={horizontalTabs.descricao === grupo.descricao
                                                            ? "active"
                                                            : ""}
                                                        type={'button'}
                                                        dataToggle={'tab'}
                                                        label={grupo.descricao}
                                                        onClick={() => { handleOnChangeGrupo(grupo) }}
                                                    />
                                                ))
                                            }

                                        </ButtonGroup>
                                    </div>
                                </Row>
                                <TabContent
                                    className="tab-space"
                                    activeTab={horizontalTabs.descricao}
                                    style={{ marginBottom: '-50px', minHeight: '200px' }}
                                >
                                    {
                                        grupos.map((grupo: any, keyGrupo: number) => (
                                            <TabPane key={keyGrupo} tabId={grupo.descricao}>
                                                <Row>
                                                    <Col md="6">
                                                        <fieldset className="viewSet views" >
                                                            <legend className='lengendViews' >Telas do Sistema</legend>
                                                            <Col className='scrollBar' style={{ marginTop: '0px', minHeight: '450px' }}>
                                                                <Col>
                                                                    <FormGroup check>
                                                                        <Row>
                                                                            <Label check>
                                                                                <Input type="checkbox"
                                                                                    onChange={() => { checkAll(keyGrupo) }}
                                                                                    checked={grupo.checkedAll}
                                                                                />
                                                                                <span className="form-check-sign" />TODAS </Label>
                                                                        </Row>
                                                                    </FormGroup>
                                                                </Col>
                                                                {
                                                                    grupo.telas.map((tela: any, keyTela: number) => (
                                                                        <Col>
                                                                            <FormGrupoTelas
                                                                                key={keyTela}
                                                                                style={tela.checked ? { backgroundColor: 'rgba(71, 179, 255, 0.41)' } : {}}
                                                                                checked={tela.status === 0}
                                                                                onClick={() => { controllerUsuarios.checkTela(keyGrupo, keyTela) }}
                                                                                onChange={() => { handleCheckTelas(keyTela, keyGrupo) }}
                                                                                type={'checkbox'}
                                                                                label={tela.nomeTela}
                                                                            />
                                                                        </Col>
                                                                    ))
                                                                }
                                                            </Col>

                                                        </fieldset>
                                                    </Col>
                                                    <Col md='6'>
                                                        <fieldset className="viewSet views" >
                                                            <legend className='lengendViews' >Telas do Sistema</legend>
                                                            <Col className='scrollBar' style={{ marginTop: '0px', minHeight: '450px' }}>
                                                                <Col>
                                                                    <FormGroup check key={keyGrupo}>
                                                                        <FormGrupoTelas
                                                                            key={0}
                                                                            style={{}}
                                                                            checked={true}
                                                                            onClick={() => { }}
                                                                            onChange={() => { }}
                                                                            type={'checkbox'}
                                                                            label={'CANCELAR PEDIDO DE VENDAS'}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                            </Col>
                                                        </fieldset>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        ))
                                    }
                                </TabContent>
                            </CardBody>
                            <CardFooter>
                                <CardFooter />
                                <Col>
                                    <Row style={{ justifyContent: "center" }}>
                                        <ButtonGroup>
                                            <ButtonGrupos
                                                color='active'
                                                style={{ width: '130px' }}
                                                onClick={() => { handleEditarGrupo() }}
                                                label="Salvar Grupo"
                                            />
                                            <ArrowTooltip title='Shift+V'>
                                                <ButtonGrupos
                                                    style={{ width: '130px' }}
                                                    color="info"
                                                    className="active"
                                                    onClick={() => handleVincularGrupo()}
                                                    label="Vincular Usuário"
                                                />
                                            </ArrowTooltip>
                                        </ButtonGroup>
                                    </Row>
                                </Col>
                            </CardFooter>

                        </>
                        :
                        null
                    }
                </Card>
                {modalVicularGrupoAcesso ?
                    <PopUpViculacaoGrupoAcesso
                        isOpen={modalVicularGrupoAcesso}
                        toggle={toggleModalVicularGrupoAcesso}
                        information={{ label: usuario.apelido, horizontalTabs: horizontalTabs.descricao }}
                        callback={() => { VincularGrupo(), toggleModalVicularGrupoAcesso() }} />
                    :
                    null}

                {modalSalvarGrupo ?
                    <PopUpSalvarGrupo
                        isOpen={modalSalvarGrupo}
                        toggle={toggleModalSalvarGrupo}
                        callback={() => { SalvarGrupo(), toggleModalSalvarGrupo() }} />
                    :
                    null
                }
            </div>

        </>
    )

}

class Wizard extends React.Component {

    isValidated = () => {
        const { gruposAcessoUsuario } = store.getState().usuario
        //@ts-ignore
        const { notificacao } = store.getState()
        let validated = gruposAcessoUsuario.filter((grupo: any) => {
            if (grupo.codGrupoAcesso === '6') {
                return true

            } else {
                return false

            }
        })
        if (validated.length <= 0) {
            return true
        }
        alertar(notificacao, 'br', "Necessário vincular usuário no grupo de acesso em todas as empresas para continuar", 'info', 'icon-bulb-63', 2);
        return false
    }
    render() {

        return (
            <Acesso />
        )
    }
}

export default Wizard;