import store from "../../../store/stores";
import { Sum, Subtract, ToNumber } from "../../../Util/numbers";
import * as action from "../../../store/reducers/Modals/Faturamento/actions";
import * as actions from "../../../store/reducers/Movimentacao/SaidaDiversa/actions";
import api from "../../../Util/api";
import Axios from "axios";
import { alertar } from "../../../Util/alertar";

import { EventEmitter } from "events";
import { maskMoeda } from "../../../Util/mascaras";
import { removeMaskMoney } from "../../../Util/removeMascaras";

export function addItemLancamento() {
    const { lancamentos, pagamentoSelecionado, lancamento } = store.getState().modalFaturamento;

    //@ts-ignore
    const { notificacao } = store.getState()

    let novosLancamentos: any[] = []
    let qtdParcelas = parseInt(lancamento.parcela);
    var newDateFormat = ''

    if (lancamento.firstVencimento === '') {
        return alertar(notificacao, 'br', 'Total Restante possui valores não lançados ', 'warning', 'icon-alert-circle-exc', 2)
    }
    if(lancamento.valor !== '0,00' || !lancamento.valor){
        let date = pagamentoSelecionado.flaVista === 1 ? new Date().toISOString().substr(0, 10).split('-') : lancamento.firstVencimento.split('-')
    let ultimaData = new Date(ToNumber(date[0]), ToNumber(date[1]) - 1, ToNumber(date[2]));
    for (let i = 0; i < qtdParcelas; i++) {
        var newDate = ultimaData.toISOString().substr(0, 10).split('-')

        if (pagamentoSelecionado.qtdDiasEntreParcelas === 30) {
            ultimaData.setMonth(ultimaData.getMonth() + 1)

        } else {
            if (pagamentoSelecionado.flaVista === 1) {
                ultimaData.setDate(ultimaData.getDate() + pagamentoSelecionado.qtdDiasEntreParcelas)
                ultimaData.setMonth(ultimaData.getMonth() + 1)

            } else {
                ultimaData.setDate(ultimaData.getDate() + pagamentoSelecionado.qtdDiasEntreParcelas)
            }
        }

        newDateFormat = `${newDate[2]}-${newDate[1]}-${newDate[0]}`

        novosLancamentos.push({
            codFormaPagamento: pagamentoSelecionado.codForma,
            formaPagamento: pagamentoSelecionado.descricao,
            flaVista: pagamentoSelecionado.flaVista,
            valor: lancamento.valor,
            dataInclusao: newDateFormat,
            mesReferencia: newDateFormat.substring(3),
            parcela: i+1
        })
    }
        action.setLancamentos([...lancamentos, ...novosLancamentos])

        action.setLancamento({ parcela: '1', valor: '00,00', firstVencimento: lancamento.firstVencimento })

        calculaTotais()
    }
}

export function calculaTotais() {
    const { lancamentos, totais } = store.getState().modalFaturamento

    let restante = Number(removeMaskMoney(totais.subTotal)) - Number(removeMaskMoney(totais.descontos))
    let total = 0;
    let troco = 0;

    if (lancamentos.length > 0) {
        lancamentos.forEach((lancamento, i) => {
            restante = Number(restante.toString().replace(',','.'))
            let lanc = lancamento.valor
            lanc = lanc.toString().replace('.','')
            lanc = lanc.toString().replace(',','.')
            restante = Subtract([restante, ToNumber(lanc)]);
            total = Sum([total, ToNumber(lanc)])
        });
    }

    var totalLancado = total
    total = Number(removeMaskMoney(totais.subTotal)) - Number(removeMaskMoney(totais.descontos))
    
    troco = totalLancado - Number(removeMaskMoney(totais.subTotal))

    if (troco <= 0){
        troco = 0
    }
    if (restante < 0) {
        restante = 0
    }
    if (totalLancado < 0){
        totalLancado = 0
    }
    
    let restanteParcela = Number(restante.toFixed(2)) === 0.01 || Number(restante.toFixed(2)) === 0.02 || Number(restante.toFixed(2)) === 0.03 || Number(restante.toFixed(2)) === 0.04 || Number(restante.toFixed(2)) === 0.05
    if(lancamentos.length > 0) {
        if(totalLancado < total && restanteParcela ) {
            const valueRes =  Number(total.toFixed(2)) - Number(totalLancado)
            const lastLanc = lancamentos.length - 1
            lancamentos.forEach((lancamento, i) => {
                if(lastLanc === i) {
                    lancamento.valor = Number(removeMaskMoney(lancamento.valor)) + Number(valueRes.toFixed(2))
                    lancamento.valor = maskMoeda(Number(lancamento.valor).toFixed(2))
                }
            });
            totalLancado += Number(valueRes.toFixed(2))
            if(restanteParcela) {
                restante = 0
            }
        } else if (totalLancado > total) {
            const valueRes =  Number(totalLancado) - Number(total.toFixed(2))
            const lastLanc = lancamentos.length - 1
            lancamentos.forEach((lancamento, i) => {
                if(lastLanc === i) {
                    lancamento.valor = Number(removeMaskMoney(lancamento.valor)) - Number(valueRes.toFixed(2))
                    lancamento.valor = maskMoeda(Number(lancamento.valor).toFixed(2))
                }
            });
            totalLancado -= Number(valueRes.toFixed(2))
            troco = 0
        }
    }

    action.setTotais({
        ...totais,
        totalLancado: maskMoeda(totalLancado.toFixed(2)),
        totalRestante: maskMoeda(restante.toFixed(2)),
        total: maskMoeda(total.toFixed(2)),
        troco: maskMoeda(troco.toFixed(2)),
    })
}

export function getFormasPagamento() {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        Axios.get(api.urlCadastro + `api/v1/formaPgto/formasPgto?status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length > 0) {
                let pagamento = response.data.records[0]
                action.setPagamentoSelecionado(pagamento)
            }

            action.setPagamentos(response.data.records)
        })
    } catch (e) {
        return false
    }
}

export function setValorTotalInicial(totalRestante: string) {
    try {
        if (ToNumber(totalRestante) === 0)
            return "Valor Total Inválido"

        const { totais } = store.getState().modalFaturamento

        action.setTotais({ ...totais, totalRestante })


    } catch (e) {
        return false
    }
}

export function deleteLancamento(index: number) {
    const { lancamentos } = store.getState().modalFaturamento

    let listString = JSON.stringify(lancamentos)
    let list: any[] = JSON.parse(listString)

    list.splice(index, 1)

    action.setLancamentos(list)

    calculaTotais()
}

export function cleanModal() {
    action.clean();
}

export function cancelarFaturamento() {
    action.cleanAll();
}

export function finalizarFaturamento(toogleModal: Function) {
    //@ts-ignore
    const { notificacao, modalFaturamento, saidaDiversa: { saida }, entradaMercadoria: { cadastro: {dadosIniciais}}, pedidoVenda: {informacoes}} = store.getState();
    try {
        const event = new EventEmitter();
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

        if (ToNumber(modalFaturamento.totais.totalRestante) > 0)
            return alertar(notificacao, 'br', 'Total Restante possui valores não lançados ', 'warning', 'icon-alert-circle-exc', 2)

        let listLancamento: any[] = [];
        let totalPago = 0;
        if(dadosIniciais.numEntrada){
        modalFaturamento.lancamentos.forEach((lancamento, index) => {
            if (lancamento.flaVista)
                totalPago = totalPago + ToNumber(lancamento.valor)
                lancamento.mesReferencia = lancamento.mesReferencia.replace('-','/')
            listLancamento.push({
                forma: lancamento.codFormaPagamento,
                vencimento: lancamento.dataInclusao,
                mesReferencia: lancamento.mesReferencia,
                valor: Number(lancamento.valor.replace(",",".")),
                parcela: lancamento.parcela,
            })
        })

        Axios.put(api.urlMovimento + `api/v1/Entrada/Finalizar/${dadosIniciais.numEntrada}`, listLancamento, {
            headers: { Authorization: bearerAuth }
        }).then(response => {
            const { data } = response.data
            alertar(notificacao, 'br', 'Faturado com sucesso', 'success', 'icon-alert-circle-exc', 2)
            toogleModal()
            store.dispatch({idStore: 'ENTRADA_MERCADORIA', type: "CLEAN_CADASTRO"})
            store.dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'TELA', value: 1 })
        }).catch(e => {
            alertar(notificacao, 'br', 'Não foi possível realizar o Faturamento', 'warning', 'icon-alert-circle-exc', 2, e)
        });
        }else if (saida.idSaida){
            modalFaturamento.lancamentos.forEach((lancamento, index) => {
                if (lancamento.flaVista)
                    totalPago = totalPago + ToNumber(lancamento.valor)
                    lancamento.mesReferencia = lancamento.mesReferencia.replace('-','/')
                listLancamento.push({
                    forma: lancamento.codFormaPagamento,
                    vencimento: lancamento.dataInclusao,
                    mesReferencia: lancamento.mesReferencia,
                    valor: Number(lancamento.valor.replace(",",".")),
                    parcela: lancamento.parcela,
                })
            })
    
            Axios.put(api.urlMovimento + `api/v1/Movimento/finalizarSaidaDiversas/${saida.idSaida}`, listLancamento, {
                headers: { Authorization: bearerAuth }
            }).then(response => {
                const { data } = response.data
                alertar(notificacao, 'br', 'Faturado com sucesso', 'success', 'icon-alert-circle-exc', 2)
                toogleModal()
                actions.cleanCadastro()
            }).catch(e => {
                alertar(notificacao, 'br', 'Não foi possível realizar o Faturamento', 'warning', 'icon-alert-circle-exc', 2, e)
            });
        }else if(informacoes.numPedido){
            modalFaturamento.lancamentos.forEach((lancamento, index) => {
                if (lancamento.flaVista)
                    totalPago = totalPago + ToNumber(lancamento.valor)
                    lancamento.mesReferencia = lancamento.mesReferencia.replace('-','/')
                listLancamento.push({
                    forma: lancamento.codFormaPagamento,
                    vencimento: lancamento.dataInclusao,
                    mesReferencia: lancamento.mesReferencia,
                    valor: Number(removeMaskMoney(lancamento.valor)),
                    parcela: lancamento.parcela,
                })
            })
    
            Axios.put(api.urlMovimento + `api/v1/Movimento/finalizarPedidoVendas/${informacoes.numPedido}`, listLancamento, {
                headers: { Authorization: bearerAuth }
            }).then(response => {
                const { data } = response.data
                alertar(notificacao, 'br', 'Faturado com sucesso', 'success', 'icon-alert-circle-exc', 2)
                toogleModal()
                store.dispatch({idStore: 'PEDIDO_VENDA', type: "CLEAN"})
            }).catch(e => {
                alertar(notificacao, 'br', 'Não foi possível realizar o Faturamento', 'warning', 'icon-alert-circle-exc', 2, e)
            });
        }else{
        modalFaturamento.lancamentos.forEach((lancamento, index) => {
            if (lancamento.flaVista)
                totalPago = totalPago + ToNumber(lancamento.valor)
                lancamento.dataInclusao
            listLancamento.push({
                CodigoForma: lancamento.codFormaPagamento,
                Parcela: index + 1,
                DataInclusao: lancamento.dataInclusao,
                DataBaixa: lancamento.flaVista ? lancamento.dataInclusao : null,
                MesReferencia: lancamento.dataInclusao,
                ValorTitulo: ToNumber(lancamento.valor),
                ValorTotalPago: 0,
                FlPago: lancamento.flaVista,
                FlEnvioComprovante: false,
            })
        })

        let objFinanceiro = {
            CodigoCaixa: 5,
            CodigoConta: modalFaturamento.codigoConta === 0 ? null : modalFaturamento.codigoConta,
            CodigoGrupoConta: modalFaturamento.codigoGrupoConta === 0 ? null : modalFaturamento.codigoGrupoConta,
            CodigoSubGrupoConta: modalFaturamento.codigoSubGrupoConta === 0 ? null : modalFaturamento.codigoSubGrupoConta,
            NumeroPedido: modalFaturamento.numeroPedido,
            CodigoCliente: modalFaturamento.codigoCliente,
            CodigoFuncionario: modalFaturamento.codigoFuncionario,
            QuantidadeParcelas: modalFaturamento.lancamentos.length,
            ValorTotalTitulos: ToNumber(modalFaturamento.totais.total),
            ValorTotalPago: totalPago,
            Tipo: 'C',
            FlPago: totalPago === ToNumber(modalFaturamento.totais.total) ? 1 : 0,
            Titulos: listLancamento,
        }

        Axios.post(api.urlMovimento + `api/v1/CaixaFaturamento`, objFinanceiro, {
            headers: { Authorization: bearerAuth }
        
        }).then(response => {
            const { data } = response.data
            action.setIdLancamento(data.codigoTitulo)

            alertar(notificacao, 'br', 'Faturado com sucesso', 'success', 'icon-alert-circle-exc', 2)
            toogleModal()
        
        }).catch(e => {
            alertar(notificacao, 'br', 'Não foi possível realizar o Faturamento', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    }
    } catch (error) {
        alertar(notificacao, 'br', "Não foi possível realizar o Faturamento", 'warning', 'icon-alert-circle-exc', 2)
    }

}