import { IAberturaCaixaState, AberturaCaixaStore, AberturaCaixaTypes, AberturaCaixaAction } from "./types";

var INITIAL_STATE: IAberturaCaixaState = {
    pesquisa: {
        codigoFuncionario: 0,
        nomeFuncionario: '',
        dataInicio: '',
        dataFim: '',
        tipo: null
    },
    lancamento: {
        dataLancamento: '',
        codFormaPagamento: 0,
        descricaoFormaPagamento: '',
        observacao: '',
        valor: ''
    },
    abertura: {
        codCaixa: 0,
        codFuncionario: 0,
        nomeFuncionario: '',
        dataAbertura: new Date().toISOString().split('T')[0],
        dataFechamento: '',
        valorTotalLancado: '00,00',
        status: ''
    },
    lancamentos: [],
    funcionarios: [],
    caixas: [],
    tipoLancamentos: [],
    screen: 0,
    block: false
};

export default function caixasStore(state = INITIAL_STATE, action: AberturaCaixaAction) {
    switch (action.idStore) {
        case AberturaCaixaStore.ID_STORE:
            switch (action.type) {

                case AberturaCaixaTypes.CLEAN_ALL:
                    return INITIAL_STATE

                case AberturaCaixaTypes.CLEAN_PESQUISA:
                    return { ...state, pesquisa: INITIAL_STATE.pesquisa, caixas: INITIAL_STATE.caixas }

                case AberturaCaixaTypes.CLEAN_LANCAMENTO:
                    return { ...state, lancamento: INITIAL_STATE.lancamento }

                case AberturaCaixaTypes.CLEAN_ABERTURA:
                    return {
                        ...state,
                        lancamento: INITIAL_STATE.lancamento,
                        lancamentos: INITIAL_STATE.lancamentos,
                        abertura: {
                            ...INITIAL_STATE.abertura,
                            codFuncionario: state.abertura.codFuncionario,
                            nomeFuncionario: state.abertura.nomeFuncionario,
                        }
                    }

                case AberturaCaixaTypes.PESQUISA:
                    if (action.pesquisa)
                        return { ...state, pesquisa: action.pesquisa }
                    else
                        return state

                case AberturaCaixaTypes.FUNCIONARIOS:
                    if (action.funcionarios)
                        return { ...state, funcionarios: action.funcionarios }
                    else
                        return state

                case AberturaCaixaTypes.LANCAMENTO:
                    if (action.lancamento)
                        return { ...state, lancamento: action.lancamento }
                    else
                        return state

                case AberturaCaixaTypes.LANCAMENTOS:
                    if (action.lancamentos)
                        return { ...state, lancamentos: action.lancamentos }
                    else
                        return state

                case AberturaCaixaTypes.ABERTURA:
                    if (action.abertura)
                        return { ...state, abertura: action.abertura }
                    else
                        return state

                case AberturaCaixaTypes.ABERTURA_RETORNO:
                    if (action.value)
                        return {
                            ...state,
                            abertura: { ...state.abertura, codCaixa: action.value.codCaixa },
                            lancamentos: action.value.lancamentos
                        }
                    else
                        return state

                case AberturaCaixaTypes.CAIXAS:
                    if (action.caixas)
                        return { ...state, caixas: action.caixas }
                    else
                        return state

                case AberturaCaixaTypes.TIPO_LANCAMENTO:
                    if (action.tipoLancamentos)
                        return { ...state, tipoLancamentos: action.tipoLancamentos }
                    else
                        return state

                case AberturaCaixaTypes.SCREEN:
                    if (action.value !== null || action.value !== undefined)
                        return { ...state, screen: action.value }
                    else
                        return state

                case AberturaCaixaTypes.BLOCK:
                    if (action.value !== null || action.value !== undefined)
                        return { ...state, block: action.value }
                    else
                        return state

                default:
                    return state
            }
        default:
            return state
    }
}
