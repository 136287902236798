import React, { useState } from 'react'
import { Col } from 'reactstrap'
import { IServicoState } from '../../../../../store/reducers/Cadastro/Servico/types'
import AppState from '../../../../../store/storeType'
import useShallowEqualSelector from '../../../../../Util/Hooks/useShallowEqualSelector'
import classNames from "classnames";
import { useHotkeys } from 'react-hotkeys-hook'
import ReactWizard from '../../../../../components/Wizard/Wizard'
import Step1 from '../Cadastro/CadastroSteps/Servico'
import * as actions from '../../../../../store/reducers/Cadastro/Servico/actions'
import * as controllerServicos from '../../../../../controller/controllerServicos'

var Steps = [
    {
        stepName: "Serviço",
        stepIcon: "tim-icons icon-notes",
        component: Step1,
        stepProps: {}
    },

];

const Cadastro: React.FC = () => {
    const [width, setWidht] = useState(window.innerWidth);
    const currentStep = useShallowEqualSelector<AppState, IServicoState['currentStep']>(state => state.servico.currentStep)
    const servico = useShallowEqualSelector<AppState, IServicoState['servico']>(state => state.servico.servico)

    useHotkeys('Shift+S', () => {
        if (servico.codServico === '') {
            controllerServicos.CreateServico()
        } else {
            controllerServicos.UpdateServico()
        }
    }, [currentStep])

    useHotkeys('Shift+N', () => {

        handleLimparAll()

    })

    const handleLimparAll = () => {
        actions.cleanCadSubGrupos()
        actions.cleanServico()
    }
    const finishButtonClick = async function () {

        if (servico.codServico === '') {
            let err = await controllerServicos.CreateServico();
            if (err) {
                handleLimparAll()
            }
            return err
        } else {
            let err = await controllerServicos.UpdateServico();
            if (err) {
                handleLimparAll()
            }
            return err
        }
    }

    return (
        <Col lg="12">
            <div style={{ marginTop: '-20px' }}>
                <ReactWizard
                    steps={Steps}
                    navSteps={true}
                    title=""
                    description=""
                    validate
                    resizable={true}
                    //@ts-ignore
                    changeStep={() => { }}
                    previousButtonText="Voltar"
                    finishButtonText="Finalizar"
                    nextButtonText="Avançar"
                    newCurrentStep={currentStep}
                    setNewCurrentStep={(nextStep: number) => { actions.setCurrentStep(nextStep) }}
                    finishButtonClick={finishButtonClick}
                    finishButtonClasses={classNames("btn-wd btn-info",
                        {
                            finishButtonMin:
                                width < 550
                        },
                        {
                            finishButtonRight:
                                width > 550
                        }
                    )}
                    nextButtonClasses={classNames("btn-wd btn-info",
                        {
                            nextButtonMin:
                                width < 550

                        },
                        {
                            nextButtonRight:
                                width > 550
                        }
                    )}
                    previousButtonClasses={classNames("btn-wd",
                        {
                            previousButtonMin:
                                width < 550
                        },
                        {
                            previousButtonRight:
                                width > 550
                        }
                    )}
                    progressbar
                    color="blue"
                />
            </div>


        </Col>

    )

}

export default Cadastro