import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import { ValidaCNPJ, ValidaCPF, ValidaCep, ValidaEmail } from "~/Util/validacao";

const idStore = 'FORNECEDORES';

export function Pesquisar(notificacao, dispatch, pesquisa) {
    try {
        dispatch({ idStore, type: 'FORNECEDORES', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/fornecedor/fornecedores?status=${pesquisa.ativos}&tipoFor=${pesquisa.tipo}&nomeRazao=${pesquisa.nomeRazao}&apelidoFantasia=${pesquisa.apelidoFantasia}&cpfCnpj=${pesquisa.cpfCnpj}&email=${pesquisa.email}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum fornecedor encontrado", 'info', 'icon-bulb-63', 2)
            }

            response.data.records.forEach((fornecedor, index) => {
                response.data.records[index].dtCadastro = new Date(fornecedor.dtCadastro).toLocaleString();
            });

            dispatch({ idStore, type: 'FORNECEDORES', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os fornecedores", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetFornecedor(notificacao, dispatch, codFor, thenFunction) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/fornecedor/fornecedor?codFor=${codFor}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            dispatch({ idStore, type: 'FORNECEDOR_COMPLETO', value: response.data.records })
            dispatch({ idStore, type: 'FORNECEDOR_COPIA' })
            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2);
    }
}

export async function CreateFornecedor(notificacao, dispatch, fornecedor) {
    try {
        //Retorna se houve erro ou nao
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;

        if (fornecedor.nomeRazao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'nomeRazao', value: true })
            invalido = true
        }
        if ((!ValidaCNPJ(fornecedor.cpfCnpj) && !ValidaCPF(fornecedor.cpfCnpj)) || fornecedor.cpfCnpj === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'cpfCnpj', value: true })
            invalido = true
        }
        if (fornecedor.apelidoFantasia === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'apelidoFantasia', value: true })
            invalido = true
        }
        if (fornecedor.email === '' || !ValidaEmail(fornecedor.email)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'email', value: true })
            invalido = true
        }
        if (fornecedor.email2 !== '' && !ValidaEmail(fornecedor.email2)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'email2', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/fornecedor/createFornecedor`,
            headers: { Authorization: basicAuth },
            data: {
                TipoFor: fornecedor.tipoFor,
                NomeRazao: fornecedor.nomeRazao,
                ApelidoFantasia: fornecedor.apelidoFantasia,
                Contato: fornecedor.contato,
                CpfCnpj: fornecedor.cpfCnpj,
                Email: fornecedor.email,
                Email2: fornecedor.email2,
                RgInscest: fornecedor.rgInscest,
                Observacao: fornecedor.observacao,
                Telefone1: fornecedor.telefone1,
                Telefone2: fornecedor.telefone2,
                Telefone3: fornecedor.telefone3,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'FORNECEDOR', campo: 'codFor', value: response.data.records })
            dispatch({ idStore, type: 'FORNECEDOR_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function UpdateFornecedor(notificacao, dispatch, fornecedor, fornecedorCopia) {
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(fornecedor) === JSON.stringify(fornecedorCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        var invalido = false;

        if (fornecedor.email === '' || !ValidaEmail(fornecedor.email)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'email', value: true })
            invalido = true
        }
        if (fornecedor.email2 !== '' && !ValidaEmail(fornecedor.email2)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'email2', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }
        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/fornecedor/updateFornecedor?codFor=${fornecedor.codFor}`,
            headers: { Authorization: basicAuth },
            data: {
                Contato: fornecedor.contato,
                Email: fornecedor.email,
                Email2: fornecedor.email2,
                RgInscest: fornecedor.rgInscest,
                Observacao: fornecedor.observacao,
                Telefone1: fornecedor.telefone1,
                Telefone2: fornecedor.telefone2,
                Telefone3: fornecedor.telefone3,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'FORNECEDOR', campo: 'codFor', value: response.data.records })
            dispatch({ idStore, type: 'FORNECEDOR_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function UpdateEndereco(notificacao, dispatch, fornecedor, fornecedorCopia) {
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(fornecedor) === JSON.stringify(fornecedorCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        var invalido = false;

        if (fornecedor.cep === '' || !ValidaCep(fornecedor.cep)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'cep', value: true });
            invalido = true
        }
        if (fornecedor.uf === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'uf', value: true });
            invalido = true
        }
        if (fornecedor.cidade === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'cidade', value: true });
            invalido = true
        }
        if (fornecedor.bairro === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'bairro', value: true });
            invalido = true
        }
        if (fornecedor.logradouro === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'logradouro', value: true });
            invalido = true
        }
        if (fornecedor.numero === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'numero', value: true });
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/fornecedor/updateEndereco?codFor=${fornecedor.codFor}`,
            headers: { Authorization: basicAuth },
            data: {
                Cep: fornecedor.cep,
                Uf: fornecedor.uf,
                Cidade: fornecedor.cidade,
                Bairro: fornecedor.bairro,
                Logradouro: fornecedor.logradouro,
                Numero: fornecedor.numero,
                Complemento: fornecedor.complemento,
                Referencia: fornecedor.referencia,
                Ibge: fornecedor.ibge,
                Gia: fornecedor.gia,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'FORNECEDOR_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export function Inativar(notificacao, dispatch, fornecedor, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/fornecedor/inativarFornecedor?codFor=${fornecedor.codFor}&status=${fornecedor.status}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'FORNECEDOR_COPIA_STATUS', value: fornecedor.status === 1 ? 0 : 1 })
            Pesquisar(notificacao, dispatch, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function Excluir(notificacao, dispatch, fornecedor, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/fornecedor/excluirFornecedor?codFor=${fornecedor.codFor}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar(notificacao, dispatch, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function BuscarCep(notificacao, dispatch, cep) {
    try {
        if (cep.trim() !== '' && cep.trim().length >= 8) {
            var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

            const base = await axios.get(api.urlCadastro + `api/v1/buscaCep/${cep}`, { headers: { Authorization: basicAuth } }
            ).then(response => {
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'uf', value: response.data.records.uf })
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'cidade', value: response.data.records.cidade })
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'logradouro', value: response.data.records.logradouro })
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'bairro', value: response.data.records.bairro })
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'ibge', value: response.data.records.ibge })
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'gia', value: response.data.records.gia })
                return true
            }).catch(e => {
                return false
            });

            if (base) {
                return true
            }

            const viacep = await axios.get(`http://viacep.com.br/ws/${cep}/json/`
            ).then(response => {
                if (response.data.erro) {
                    return false
                }
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'uf', value: response.data.uf })
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'cidade', value: response.data.localidade })
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'logradouro', value: response.data.logradouro })
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'bairro', value: response.data.bairro })
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'ibge', value: response.data.ibge })
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'gia', value: response.data.gia })
                return true
            }).catch(e => {
                return false
            });

            if (viacep) {
                return true
            }

            const postomon = await axios.get(`http://api.postmon.com.br/v1/cep/${cep}`
            ).then(response => {
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'uf', value: response.data.estado })
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'cidade', value: response.data.cidade })
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'logradouro', value: response.data.logradouro })
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'bairro', value: response.data.bairro })
                dispatch({ idStore, type: 'FORNECEDOR', campo: 'ibge', value: response.data.cidade_info.codigo_ibge })
                return true
            }).catch(e => {
                return false
            });

            if (postomon) {
                return true
            }
            alertar(notificacao, 'br', "CEP não encontrado", 'warning', 'icon-alert-circle-exc', 2)

        } else {
            alertar(notificacao, 'br', "CEP inválido", 'warning', 'icon-alert-circle-exc', 2)
        }
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter o endereço", 'warning', 'icon-alert-circle-exc')
        return false
    }
}