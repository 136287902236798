import React from "react";
import { Route } from "react-router-dom";
import AdminLayout from "layouts/Admin/Admin.jsx";
import { isAuth } from "Util/auth"


class PrivateRoute extends React.Component {

    componentWillMount() {
        isAuth();
    }

    render() {
        return (
            <>
                <Route {...this.props} render={(props) => (
                    <AdminLayout {...props} />
                )} />
            </>
        );
    }
}

export default PrivateRoute;