import { Reducer } from 'redux'

import {
    DashboardAction,
    IDashboardState,
    DashboardTypes,
    DashboardStore
} from "./types";



const INITIAL_STATE: IDashboardState = {

    bigChartData: 0,
    topFive: [],
    highGrafic: [],
}

const dashboard: Reducer<IDashboardState, DashboardAction> = (state = INITIAL_STATE, action: DashboardAction) => {
    switch (action.idStore) {
        case DashboardStore.ID_STORE:
            switch (action.type) {

                case DashboardTypes.TOPFIVE:
                    return { ...state, topFive: action.topFive }

                case DashboardTypes.BIGCHARTDATA:
                    return { ...state, bigChartData: action.bigChartData }

                case DashboardTypes.HIGHGRAFIC:
                    return { ...state, highGrafic: action.highGrafic }

                default:
                    return state
            }

        default:
            return state

    }

}

export default dashboard