import React, { useEffect } from 'react';

import './style.css'

import {
    Button,
    Col,
    Row
} from 'reactstrap';

import {
    ModalBasic,
    ModalBasicBody,
    ModalBasicFooter
} from "../../ModalBasic/ModalBasic";

import FormaPagamento from './FormaPagamento'
import Parcelas from './Parcelas'
import Totais from './Totais'
import * as functions from './functions';
import * as actions from '../../../store/reducers/Modals/Faturamento/actions';

interface IProps {
    isOpen: boolean
    toogle: Function
}

export default function ModalOperacao({ isOpen = false, toogle }: IProps) {

    useEffect(() => {
        functions.getFormasPagamento()

        return () => {
            actions.cleanAll()
        }
    }, [])

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toogle()}
            title={'FATURAMENTO'}
            size={"lg"}
            id="modalFaturamento"
        >
            <ModalBasicBody>

                <Row>

                    <FormaPagamento />

                    <Parcelas />

                    <div className="totaisActions-content" >
                        <Totais />
                        <Footer toogle={toogle} />
                    </div>

                </Row>

            </ModalBasicBody>

          

        </ModalBasic>
    )
}

interface IPropsFooter {
    toogle: Function
}

const Footer = ({ toogle }: IPropsFooter) => {

    return (
        <ModalBasicFooter id="faturamentoActions-container">
            <Row>
                <Col md={3} className="text-center faturamentoActions-itens">
                    <Button
                        className="btn-faturamento"
                        onClick={() => functions.cleanModal()}
                        color={"default"}
                    >
                        LIMPAR
                    </Button>
                </Col>
                <Col md={3} className="text-center faturamentoActions-itens">
                    <Button
                        className="btn-faturamento"
                        onClick={() => { functions.cancelarFaturamento(); toogle() }}
                        color={"danger"}
                    >
                        CANCELAR
                    </Button>
                </Col>
                <Col md={3} className="text-center faturamentoActions-itens">
                    <Button
                        className="btn-faturamento"
                        onClick={() => functions.finalizarFaturamento(toogle)}
                        color={"info"}
                    >
                        FINALIZAR
                    </Button>
                </Col>
            </Row>

        </ModalBasicFooter>
    )
}