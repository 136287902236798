import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import classNames from "classnames";
import * as controllerClassificacoes from "~/controller/controllerClassificacoesTributarias";

import './styles.css'

import Cadastro from "~/views/ClassificacoesTributarias/Cadastro/Cadastro.jsx";
import Pesquisa from "~/views/ClassificacoesTributarias/Pesquisa/Pesquisa.jsx";

import {
    Card,
    Col,
    Button,
    ButtonGroup,
    CardHeader,
    CardBody,
    CardTitle,
    TabContent,
    TabPane,
} from "reactstrap";

const idStore = 'CLASSIFICACOES_TRIBUTARIAS';

const Classificacoes = () => {
    const dispatch = useDispatch();
    const notificacao = useSelector(state => state.notificacao);
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
    const { verCadastro } = useSelector(state => state.classificacoesTributarias);
    const [horizontalTabs, setHorizontalTabs] = useState('pesquisar');
    const [width, setWidht] = useState(window.innerWidth);
    const [TribTabs, setTribTabs] = useState('tributacoes');

    useEffect(() => {
        window.addEventListener('resize', updateWidth);
    }, [])

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', updateWidth);
        }
    }, [])

    useEffect(() => {
        if (verCadastro === false)
            changeActiveTab('pesquisar')
    }, [verCadastro])

    function updateWidth() {
        setWidht(window.innerWidth);
    }

    useEffect(() => {
        controllerClassificacoes.GetCFOPs(notificacao, dispatch, codEmpresa);
        controllerClassificacoes.GetICMSs(notificacao, dispatch, codEmpresa);
        controllerClassificacoes.GetIPIs(notificacao, dispatch, codEmpresa);
        controllerClassificacoes.GetPISCOFINSs(notificacao, dispatch, codEmpresa);
        controllerClassificacoes.EnquadramentosIPI(notificacao, dispatch, codEmpresa);
    }, [])

    function changeActiveTab(tabName) {
        setHorizontalTabs(tabName);
        if (tabName === "pesquisar") {
            setTimeout(() => {
                dispatch({ idStore, type: 'VER_CADASTRO', value: false })
                document.getElementById('pesqdescricao').focus()
            }, 100)
        } else {
            setTimeout(() => {
                document.getElementById('caddescricao').focus()
            }, 500)
            setInicioCadastro(true)
        }
    }

    function changeTribTab(tabName) {
        setTribTabs(tabName);
        if (tabName !== "tributacoes") {
            setInicioCadastro(true)
        }
    }

    function setInicioCadastro(valor) {
        dispatch({ idStore, type: 'INICIO_CADASTRO', value: valor });
    }
    async function novoCadatro() {
        setInicioCadastro(true);
        dispatch({ idStore, type: 'CLEAN_CLASSIFICACAO' });
        dispatch({ idStore, type: 'CLEAN_TRIBUTACAO' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    }
    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="12">
                    <Card>

                        <CardHeader className="text-center">
                            <CardTitle tag="h3">Classificações Tributárias</CardTitle>
                            <br />
                            <TabContent
                                className="tab-space"
                                activeTab={horizontalTabs}
                                style={{ marginTop: '-30px', marginBottom: '-70px' }}
                            >
                            </TabContent>
                        </CardHeader>

                        <CardBody>
                            <ButtonGroup style={{ width: '100%' }}>

                                <Button
                                    color={
                                        horizontalTabs === "pesquisar"
                                            ? "info"
                                            : ""
                                    }
                                    type="button"
                                    data-toggle="tab"
                                    className={classNames("btn-gp-padrao",
                                        horizontalTabs === "pesquisar"
                                            ? "active"
                                            : ""
                                    )
                                    }
                                    onClick={() => changeActiveTab("pesquisar")}
                                    style={{ width: '50%' }}
                                >
                                    PESQUISAR
                                    </Button>

                                <Button
                                    color={
                                        horizontalTabs === "cadastrar"
                                            ? "info"
                                            : ""
                                    }
                                    type="button"
                                    data-toggle="tab"
                                    className={classNames("btn-gp-padrao",
                                        horizontalTabs === "cadastrar"
                                            ? "active"
                                            : ""
                                    )
                                    }
                                    onClick={() => {
                                        novoCadatro();
                                        changeActiveTab("cadastrar");
                                    }}
                                    style={{ width: '50%' }}
                                >
                                    CADASTRAR
                                    </Button>

                            </ButtonGroup>
                            <TabContent
                                className="tab-space"
                                activeTab={horizontalTabs}
                                style={{ paddingBottom: '0px' }}
                            >
                                <TabPane tabId="pesquisar">

                                    <Pesquisa
                                        changeActiveTab={changeActiveTab}
                                        width={width}
                                        changeTribTab={changeTribTab}
                                    />

                                </TabPane>

                                <TabPane tabId="cadastrar">

                                    <Cadastro
                                        width={width}
                                        TribTabs={TribTabs}
                                        changeTribTab={changeTribTab}
                                    />

                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </div>
        </>
    );
}

export default Classificacoes;
