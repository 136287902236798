import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';

import {
  Row,
  Col,
  Button,
  Collapse
} from "reactstrap";

import useModal from "../../../../../Util/Hooks/useModal";
import useResize from "../../../../../Util/Hooks/useResize";

import ModalOperacao from '../../Modals/ModalOperacao'
import ModalFaturamento from '../../../../../components/Modals/Faturamento'

import { OperacaoBox } from "../../Components/index";

import * as controllerSaida from '../../../../../controller/Movimentacao/controllerSaidaDiversa'

import DadosDestinatario from './DadosDestinatario';
import MaisInformacoes from './MaisInformacoes';
import DadosAdicionais from './DadosAdicionais';

import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import AppState from "../../../../../store/storeType";

import { PopUp } from '../../Modals/PopUps'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import * as action from '../../../../../store/reducers/Movimentacao/SaidaDiversa/actions'
import { Operacao } from "../../../../../store/reducers/Movimentacao/SaidaDiversa/types";

const idStore = "SAIDA_DIVERSA";

const DadosIniciais = () => {
  const operacao = useShallowEqualSelector<AppState, Operacao>(state => state.saidaDiversa.saida.cabecaSaida);
  const idFatura = useShallowEqualSelector<AppState, number>(state => state.saidaDiversa.saida.idFatura);
  const modalOperacao = useShallowEqualSelector<AppState, boolean>(state => state.saidaDiversa.modalOperacao);
  const popUpFaturamento = useShallowEqualSelector<AppState, boolean>(state => state.saidaDiversa.popUpFaturamento)

  const dispatch = useDispatch()

  const [moreInfo, setMoreInfo] = useState(false)
  const [modalFaturamento, toogleFaturamento] = useModal(false)

  const { width } = useResize();

  useEffect(() => {
    if (operacao.codigoOperacao === 0)
      action.setModalOperacao(true);

    dispatch({ idStore, type: 'OPERACOES', value: [] })
  }, [])

  function pesquisaOperacao( numeroOperacao: any, descricaoOperacao: any) {
    controllerSaida.pesquisaOperacao(numeroOperacao, descricaoOperacao)
  }

  function toggleModalOperacao() {
    action.setModalOperacao(!modalOperacao);

    dispatch({ idStore, type: 'OPERACOES', value: [] })
  }

  function confirmModal(operacao: any) {
    dispatch({ idStore, type: 'OPERACAO', value: operacao })
    action.setModalOperacao(false);
    dispatch({ idStore, type: 'OPERACOES', value: [] })
  }

  function limparDadosIniciais() {
    controllerSaida.handleNovo();
  }

  async function Salvar() {
    action.setPopUp(false);

    controllerSaida.Salvar('Dados Iniciais')
  }

  return (
    <>
      <Row className="justify-content-center">
        <Col>

          <h4 style={{ textAlign: 'center' }}>Informe o dados do destinatário e informações adicionais se necessário</h4>

          <Row>
            <Col lg={width >= 1660 ? 7 : 12} style={width <= 1660 ? { display: 'flex', justifyContent: 'center' } : {}} >
              <OperacaoBox onIconPress={() => action.setModalOperacao(true)} />
            </Col>

            <Col lg={width >= 1660 ? 5 : 12} >

              <Row style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%', marginTop: 4 }} >
                <Button
                  type={"button"}
                  color={"info"}
                  onClick={() => limparDadosIniciais()}
                  style={{minWidth: 100}}
                >
                  <Icon icon="plus-circle" className="fa-lg" color="#FFF" pull="left" />{" "}
                  NOVO
                  </Button>
                <Button
                  type={"button"}
                  color={"yellow"}
                  onClick={() => Salvar()}
                  disabled={!idFatura ? false : true}
                  style={{minWidth: 100}}
                >
                  <Icon icon="save" className="fa-lg" color="#FFF" pull="left" />
                  Salvar
                </Button>
                <Button
                  type={"button"}
                  color={"green"}
                  onClick={() => controllerSaida.Finalizar(toogleFaturamento)}
                  disabled={!idFatura ? false : true}
                  style={{minWidth: 100}}
                >
                  <Icon icon="dollar-sign" className="fa-lg" color="#FFF" pull="left" />{" "}
                  FINALIZAR
                </Button>
              </Row>

            </Col>
          </Row>

          <DadosDestinatario />

          <Row>
            <Col>
              <div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button color="info" onClick={() => setMoreInfo(!moreInfo)} style={{ marginBottom: '1rem' }}>+ Informações</Button>
                </div>

                <Collapse isOpen={moreInfo}>
                  <MaisInformacoes />

                  <DadosAdicionais />
                </Collapse>


              </div>
            </Col>
          </Row>

        </Col>
      </Row>

      {
        modalOperacao ? <ModalOperacao isOpen={true} toggle={toggleModalOperacao} getOperacao={pesquisaOperacao} confirmModal={confirmModal} /> : null
      }

      {
        modalFaturamento ? <ModalFaturamento isOpen={true} toogle={toogleFaturamento} /> : null
      }

      <PopUp isOpen={popUpFaturamento} callback={() => Salvar()} />

    </>
  )
}

class Wizard extends React.Component {
  render() {
    return (
      <DadosIniciais />
    )
  }
}

export default Wizard;