import React from 'react'
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from '../../../components/ModalBasic/ModalBasic'
import { Col, Row, Button } from 'reactstrap'

import * as controller from '../../../controller/controllerProdutos'
import useShallowEqualSelector from '../../../Util/Hooks/useShallowEqualSelector'

interface ModalInativarProps {
    isOpen: boolean,
    toggle: () => void,
    produto: any,
    fornecedores: any
}

const ModalInativar: React.FC<ModalInativarProps> = ({ isOpen, toggle, produto, fornecedores }) => {
    //@ts-ignore
    const { codEmpresa } = useShallowEqualSelector(state => state.login.usuario.empresa)
    //@ts-ignore
    const { pesquisa } = useShallowEqualSelector(state => state.produtos)

    function inativar() {
        controller.Inativar(codEmpresa, produto, pesquisa, fornecedores)
    }


    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title={produto.status === 0 ? "Inativar" : "Ativar" + " produto"}
        >
            <ModalBasicBody>

                <Col className="text-center">
                    {produto.status === 0 ?
                        <label>Tem certeza que deseja <b>inativar</b> esse produto da sua empresa?</label>
                        : <label>Tem certeza que deseja <b>ativar</b> esse produto em sua empresa?</label>
                    }
                </Col>

            </ModalBasicBody>
            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <Button
                            color="danger"
                            type="button"
                            onClick={() => { toggle() }}
                            style={{ width: '150px' }}
                        >NÃO
                            </Button>
                    </Col>
                    <Col className="text-center">
                        <Button
                            color="success"
                            type="button"
                            onClick={() => {
                                toggle()
                                inativar()
                            }}
                            style={{ width: '150px' }}
                        >SIM
                            </Button>
                    </Col>
                </Row>
            </ModalBasicFooter>

        </ModalBasic >
    )
}
export default ModalInativar