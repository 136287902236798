import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerNCMs from "~/controller/controllerNCMs";
import { maskNCM } from "~/Util/mascaras";

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
} from "reactstrap";

const idStore = "NCMS";


const NCM = () => {
  const notificacao = useSelector(state => state.notificacao);
  const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
  const { ncm, ncmCopia, invalido, verCadastro } = useSelector(state => state.ncms);
  const dispatch = useDispatch();

  function onChange(value, stateName) {
    dispatch({ idStore, type: 'NCM', campo: stateName, value: value });
    dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
  };

  async function Salvar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    if (!ncm.update) {
      controllerNCMs.CreateNCM(notificacao, dispatch, codEmpresa, ncm);
    } else {
      controllerNCMs.UpdateNCM(notificacao, dispatch, codEmpresa, ncm, ncmCopia);
    }
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_NCM' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('cadncm').focus()
  };

  return (
    <>
      <Row className="justify-content-center">
      <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

      </div>
        <Col>
          <h4 className="info-text">Preencha com as informações do NCM</h4>
          <CardBody>

            <Row className="justify-content-center">

              <Col md="10">

                <Row>

                  <Col lg md sm="7">
                    <label>NCM <span className="error">*</span></label>
                    <FormGroup className={invalido.ncm ? "has-danger" : ''}>
                      <Input
                        id="cadncm"
                        type="text"
                        value={ncm.ncm}
                        onChange={e => onChange(maskNCM(e.target.value), 'ncm')}
                        autoComplete="off"
                        maxLength="12"
                        disabled={ncm.update}
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

                <Row>

                  <Col lg md sm="7">
                    <label>Descrição <span className="error">*</span></label>
                    <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                      <Input
                        id="caddescricao"
                        type="text"
                        value={ncm.descricao}
                        onChange={e => onChange(e.target.value, 'descricao')}
                        autoComplete="off"
                        maxLength="500"                     
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>
                <Row>

                  <Col lg md sm="7">
                    <label>Data de Descontinuação<span className="error"> *</span></label>
                    <FormGroup className={invalido.ncm ? "has-danger" : ''}>
                      <Input
                        type="date"
                        value={ncm.dataDescontinuado}
                        onChange={e => onChange(e.target.value, 'dataDescontinuado')}                    
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button  className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button>
                  <Button  className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button>
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>

    </>
  )
};

class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      if (!this.props.ncm.update) {
        document.getElementById('cadncm').focus()
      } else {
        document.getElementById('caddescricao').focus()
      }
    }, 100)
  }

  render() {
    return (
      <NCM />
    )
  }
}

export default Wizard;
