import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import classNames from "classnames";
import * as controllerLancamento from '../../../../controller/Financeiro/controllerLancamentoTitulos'
import Fornecedor from "./Fornecedor"
import MetodoPgnt from "./MetodoPagamento"
import DadosConta from "./DadosConta";
import { HelpButton } from "~/components/HelpPlugin/HelpPlugin";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';
import {
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  ButtonGroup,
} from "reactstrap";

const idStore = "LANCAMENTO_TITULOS";

const Cadastro = (props) => {
  const dispatch = useDispatch();
  const tipoLancamento = useSelector(state => state.lancamentoTitulos.cadastro.tipoLancamento, shallowEqual)
  const update = useSelector(state => state.lancamentoTitulos.cadastro.update, shallowEqual)

  useEffect(() => {
    controllerLancamento.getFormasPgms();
  }, [])

  useEffect(() => {
    if (props.salvar === true) {
      salvar(false)
    }
  }, [props.salvar])

  function onChangeTipoLancamento(e) {
    e.preventDefault()
    controllerLancamento.changeTipoLancamento(e.target.value)
    dispatch({ idStore, type: 'CLEAN_CADASTRO' });
    dispatch({ idStore, type: 'CLEAN_FORMULARIO' });
    dispatch({ idStore, type: 'CADASTRO', campo: "tipoLancamento", value: e.target.value });
  }

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_CADASTRO' });
    dispatch({ idStore, type: 'CLEAN_FORMULARIO' });

  };

  function salvar(salvar) {
    setProximoCadastro(true);
    controllerLancamento.salvar(salvar)
    setTimeout(() => { setProximoCadastro(false); }, 100)
  }


  function setProximoCadastro(valor) {
    dispatch({ idStore, type: 'PROXIMO_STEP', value: valor })
  }


  return (
    <>
      <Row className="justify-content-center">
        <Col>
          <CardBody>
            <Row>
              <Col style={{ left: "1%" }} md="10">
                <Row >
                  <ButtonGroup >
                    <Button
                      color={tipoLancamento === "R" ? "info" : ""}
                      type="button"
                      className={classNames("btn-padrao", tipoLancamento === "R" ? "active" : "")}
                      value="R"
                      onClick={e => onChangeTipoLancamento(e)}
                      disabled={update !== null ? true : false}
                    >
                      CRÉDITO
                    </Button>
                    <Button
                      color={tipoLancamento === "D" ? "info" : ""}
                      type="button"
                      className={classNames("btn-padrao", tipoLancamento === "D" ? "active" : "")}
                      value="D"
                      onClick={e => onChangeTipoLancamento(e)}
                      disabled={update !== null ? true : false}
                    >
                      DÉBITO
                     </Button>
                  </ButtonGroup>
                  <HelpButton id="13" />
                </Row>
              </Col>
            </Row>
            <br></br>
            <br></br>


            <Row className="justify-content-center">

              <Col>

                <Fornecedor />

                <br />
                <Row>
                  <DadosConta />
                  <MetodoPgnt />
                </Row>
              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Col>
              <ArrowTooltip title='shift+L'>
                <Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button>
              </ArrowTooltip>
              <ArrowTooltip title='shift+S'>
                <Button className="btn-padrao" tipe="button" color="info" onClick={() => salvar(true)}> Salvar</Button>
              </ArrowTooltip>
            </Col>
          </CardFooter>
        </Col>
      </Row>
    </>
  )
};


class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salvar: false
    };
  }
  isValidated = (step = 0, finish = false) => {
    if (step > 0) {
      return true
    }
    if (!finish) {
      this.setState({ salvar: true }, () => {
        this.setState({ salvar: false })
      })
    }
    return false
  }

  render() {
    return (
      <Cadastro salvar={this.state.salvar} />
    )
  }
}

export default Wizard;
