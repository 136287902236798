import React from 'react';

import { Button, Col, Row } from "reactstrap";

import { Card, Input} from '../Components';
import useModal from "../../../../Util/Hooks/useModal"
import ModalCliente from '../Modals/ModalClientes'
import ModalOperacao from '../Modals/ModalOperacao';
import ModalVendedor from '../Modals/ModalVendedor';
import * as controllerPDV from '../../..//../controller/Movimentacao/controllerPedidoVenda'
import * as actions from '../../../../store/reducers/Movimentacao/PedidoVenda/actions'
import store from '../../../../store/stores';

import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector';

export default function TotaisComponent(){
    const [modalCliente, toggleModalCliente] = useModal(false)
    const [modalOperacao, toggleOperacao] = useModal(false)
    const [modalVendedor, toggleVendedor] = useModal(false)
    const dispatch = store.dispatch
    //@ts-ignore
    const pedido = useShallowEqualSelector(state => state.pedidoVenda.pedido)
    const operacao = useShallowEqualSelector(state => state.pedidoVenda.operacao)
    const cliente = useShallowEqualSelector(state => state.pedidoVenda.cliente)
    const vendedor = useShallowEqualSelector(state => state.pedidoVenda.informacoes.vendedor)

    function pesquisaClientes(pesquisaClientes) {  
        controllerPDV.GetClientes(pesquisaClientes.cpfCnpj, pesquisaClientes.nomeRazao);
    }

    function confirmarCliente(codCliente) {
        controllerPDV.GetCliente(codCliente);
        toggleModalCliente()
    }

    function cleanClientes() {
        dispatch({ idStore: 'PEDIDO_VENDA', type: 'CLEAN_CLIENTES' });
    }

    function toggleModalOperacao() {
        toggleOperacao()
        actions.setOperacoes([])
    }

    function confirmModal(operacao) {
       controllerPDV.GetOperacao(operacao.codigo)
        toggleOperacao()
    }

    function pesquisaOperacao(operacao) {
        controllerPDV.GetOperacoes(operacao.numero, operacao.descricao)
    }
    function toggleModalVendedor() {
        toggleVendedor()
        actions.setVendedores([])
    }

    function confirmModalVendedor(vendedor) {
       actions.setInformacoes('vendedor', vendedor)
        toggleVendedor()
    }

    function pesquisaVendedor(vendedor) {
        controllerPDV.GetVendedores(vendedor.nomeVendedor, vendedor.cpf)
    }
    return (
        <>
        <Col style={{width: '80%'}}>
            <Row>
                        <Card style={{ paddingBottom: 0, paddingTop: 6 }}>
                            <Row style={{marginTop: '-10px'}}>
                                <Col lg= {2} style= {{marginBottom: '10px', marginTop: '5px'}}>
                                    <Button
                                        onClick={() => toggleModalCliente()}
                                        style={{paddingLeft: '21px', paddingRight:'21px', width: '100%'}}
                                        className={pedido.status !== 'ABERTO' && pedido.status !== '' ? 'disabledButton' : ''}
                                    >
                                        CLIENTE
                                    </Button>
                                </Col>
                                <Col lg= {10}>                                  
                                    <Input
                                        type="text"
                                        autoComplete="off"
                                        value={cliente.nomeRazao}
                                        disabled={true}
                                        style={{marginTop: '9px'}}
                                    />
                                </Col>
                            </Row>
                            <Row style={{marginTop: '-15px'}}>
                                <Col lg={2}>
                                    <Button
                                        style={{width: '100%'}}
                                        onClick={() => toggleModalOperacao()}
                                        className={pedido.status !== 'ABERTO' && pedido.status !== '' ? 'disabledButton' : ''}
                                    >
                                        OPERAÇÃO
                                    </Button>
                                </Col>
                                 <Col lg= {10}>                                  
                                    <Input
                                        type="text"
                                        autoComplete="off"
                                        value={operacao.descricao}
                                        disabled={true}
                                        style={{marginTop: '4px'}}
                                    />
                                </Col>
                            </Row>
                            <Row style={{marginTop: '-5px'}}>
                                <Col lg={2}>
                                    <Button
                                        style={{width: '100%'}}
                                        onClick={() => toggleModalVendedor()}
                                        className={pedido.status !== 'ABERTO' && pedido.status !== '' ? 'disabledButton' : ''}
                                    >
                                        VENDEDOR
                                    </Button>
                                </Col>
                                 <Col lg= {10}>                                  
                                    <Input
                                        type="text"
                                        autoComplete="off"
                                        value={vendedor.nomeVendedor}
                                        disabled={true}
                                        style={{marginTop: '4px'}}
                                    />
                                </Col>
                            </Row>
                        </Card>              
            </Row>
        </Col>
        {modalCliente ?
                <ModalCliente
                    isOpen={true}
                    toggle={toggleModalCliente}
                    getCliente={pesquisaClientes}
                    confirmModal={confirmarCliente}
                    clean={cleanClientes} /> :
                null
        }

        {modalOperacao ? 
            <ModalOperacao 
            isOpen={true} 
            toggle={toggleModalOperacao} 
            getOperacao={pesquisaOperacao} 
            confirmModal={confirmModal} /> : null
        
        }
        {modalVendedor ? 
            <ModalVendedor 
            isOpen={true} 
            toggle={toggleModalVendedor} 
            getVendedores={pesquisaVendedor} 
            confirmModal={confirmModalVendedor} /> : null
            }

           
        </>
    );
}