export enum FechamentoCaixaStore {
    ID_STORE = 'FECHAMENTO',
}

export enum FechamentoCaixaTypes {
    CLEAN_ALL = 'CLEAN_ALL',
    CLEAN_PESQUISA = "CLEAN_PESQUISA",
    PESQUISA = 'PESQUISA',
    FECHAMENTO = 'FECHAMENTO',
    PAGAMENTO = 'PAGAMENTO',
    DETALHAMENTO = 'DETALHAMENTO',
    FUNCIONARIOS = 'FUNCIONARIOS',
    CAIXAS = 'CAIXAS',
    FECHAMENTO_RETORNO = 'FECHAMENTO_RETORNO',
    SCREEN = 'SCREEN',
    BLOCK = 'BLOCK'
}

export type FechamentoCaixaAction = {
    idStore: FechamentoCaixaStore.ID_STORE
    type: FechamentoCaixaTypes
    value?: any,
    pesquisa?: IFechamentoCaixaState['pesquisa'],
    fechamento?: IFechamentoCaixaState['fechamento'],
    pagamento?: IFechamentoCaixaState['pagamento'],
    detalhamento?: IFechamentoCaixaState['detalhamento'],
    funcionarios?: any[]
    caixas?: any[]
    tipoLancamentos?: any[]
}

export interface IFechamentoCaixaState {
    pesquisa: {
        codigoFuncionario: number,
        nomeFuncionario: string,
        dataInicio: string,
        dataFim: string,
        tipo: number | null
    },
    fechamento: {
        codCaixa: number,
        codigoFuncionario: number,
        nomeFuncionario: string,
        dataAbertura: string,
        dataFechamento: string,
        valorTotalLancado: string,
        status: string
    },
    pagamento:{
        name: string,
        valorTotal: number,
        tipo: any[],
        open: boolean
    },
    detalhamento:any[],
    funcionarios: any[],
    caixas: any[]
    screen: number,
    block: boolean
}