import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Row, FormGroup, Label } from 'reactstrap';
import ReactTable from "react-table";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import { Input, Info, InputCpfCnpj } from '../Components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import useScrollBar from '../../../../Util/Hooks/useScrollBar';

import { useSelector, shallowEqual } from 'react-redux';
import { maskCPF, maskCNPJ } from '../../../../Util/mascaras';

export default function ModalDestinatario({ isOpen, toggle, getDestinatario, confirmModal, clean }) {
    const tableRef = useScrollBar([isOpen])
    const destinatarios = useSelector(state => state.saidaDiversa.destinatarios, shallowEqual)
    const inputDocumento = useRef()

    const [pesquisaDestinatario, setPesquisaDestinatario] = useState({ codigo: null, documento: '', nomeRazao: '', tipo: '' })

    useEffect(() => {
        inputDocumento.current.focus()
    }, [])

    function onClickOperacao(rowIndex) {
        let operacaoSelected = destinatarios[rowIndex]
        setPesquisaDestinatario({ ...pesquisaDestinatario, codigo: operacaoSelected.codigo, documento: operacaoSelected.cpfCnpj, nomeRazao: operacaoSelected.nomeRazao, tipo: operacaoSelected.tipo })
    }

    function onDoubleClickOperacao() {
        confirmModal(pesquisaDestinatario)
    }

    function changeTipoDestinatario(tipo) {
        switch(tipo) {
            case 'CLI':
                return 'Cliente'
            case 'FOR':
                return 'Fornecedor'
            case 'FIL':
                return 'Filial'
            default:
                break
        } 
    }

    function limparModal() {
        setPesquisaDestinatario({ codigo: null, documento: '', nomeRazao: '', tipo: '' })
        clean()
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            getDestinatario(pesquisaDestinatario)
        }
    }

    function getTrProps(state, row, column) {
        return {
            style: {
                height: '50px',
                paddingTop: '0px',
                paddingBottom: '5px',
                backgroundColor: destinatarios[row.index].cpfCnpj === pesquisaDestinatario.documento ? 'rgba(71, 179, 255, 0.41)' : ''
            },
            onClick: (e, handleOriginal) => {
                onClickOperacao(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            },
            onDoubleClick: (e, handleOriginal) => {
                onDoubleClickOperacao(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            }
        }
    }

    function getTdProps(state, rowInfo, column) {
        if (column.id === 'actions') {
            return { style: { padding: 0 } }
        } else {
            return {}
        }
    }

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title='Pesquisa de Destinatário'
            size="lg"
        >
            <ModalBasicBody>
                <h4 style={{ textAlign: 'center' }}>Informe os dados abaixo para prosseguir</h4>

                <Row>
                    <Col md="3">
                        <label>CPF / CNPJ</label>
                        <InputCpfCnpj
                            innerRef={inputDocumento}
                            type="text"
                            tipo={pesquisaDestinatario.documento.length <= 14 ? 'cpf' : 'cnpj'}
                            value={pesquisaDestinatario.documento}
                            onChange={e => setPesquisaDestinatario({ ...pesquisaDestinatario, documento: e.target.value })}
                            autoComplete="off"
                            maxLength="18"
                            onKeyPress={e => handleKeyPress(e)}
                        />
                        <br></br>
                    </Col>
                    <Col md="5">
                        <label>Nome / Razão Social</label>
                        <Input
                            type="text"
                            value={pesquisaDestinatario.nomeRazao}
                            onChange={e => setPesquisaDestinatario({ ...pesquisaDestinatario, nomeRazao: e.target.value })}
                            autoComplete="off"
                            onKeyPress={e => handleKeyPress(e)}
                        />
                        <br></br>
                    </Col>
                    <Col lg={1} md={1} sm={1}>
                        <Button
                            type="button"
                            style={{ marginTop: 34, width: 40, marginBottom: 0 }}
                            color="info"
                            onClick={() => getDestinatario(pesquisaDestinatario)}
                        >
                            <Icon icon="search" className="fa-lg" />
                        </Button>
                    </Col>
                    <Col lg="1" md="4" className="text-center" style={{ paddingTop: '12px' }}>
                        <Col>
                            <FormGroup check className="form-check-radio" >
                                <Label check style={{ paddingRight: '5px' }}>
                                    <Input type="radio"
                                        checked={pesquisaDestinatario.tipo === '' ? true : false}
                                        onChange={() => { setPesquisaDestinatario({ ...pesquisaDestinatario, tipo: '' }); getDestinatario({ ...pesquisaDestinatario, tipo: '' }) }}
                                    />
                                    <span className="form-check-sign" />Todos
                                </Label>
                                <Label check style={{ paddingRight: '5px' }}>
                                    <Input type="radio"
                                        checked={pesquisaDestinatario.tipo === 'CLI' ? true : false}
                                        onChange={() => { setPesquisaDestinatario({ ...pesquisaDestinatario, tipo: 'CLI' }); getDestinatario({ ...pesquisaDestinatario, tipo: 'CLI' }) }}
                                    />
                                    <span className="form-check-sign" />Cliente
                                </Label>
                                <Label check style={{ paddingRight: '5px' }}>
                                    <Input type="radio"
                                        checked={pesquisaDestinatario.tipo === 'FOR' ? true : false}
                                        onChange={() => { setPesquisaDestinatario({ ...pesquisaDestinatario, tipo: 'FOR' }); getDestinatario({ ...pesquisaDestinatario, tipo: 'FOR' }) }}
                                    />
                                    <span className="form-check-sign" />Fornecedor
                                </Label>
                                <Label check style={{ paddingRight: '5px' }}>
                                    <Input type="radio"
                                        checked={pesquisaDestinatario.tipo === 'FIL' ? true : false}
                                        onChange={() => { setPesquisaDestinatario({ ...pesquisaDestinatario, tipo: 'FIL' }); getDestinatario({ ...pesquisaDestinatario, tipo: 'FIL' }) }}
                                    />
                                    <span className="form-check-sign" />Filial
                                </Label>
                            </FormGroup>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <fieldset className="fieldset-operacao">
                            <legend>Resultados</legend>
                            <div
                                ref={tableRef}
                                style={destinatarios.length !== 0 ?
                                    { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 350 } :
                                    { visibility: 'hidden', opacity: '0' }} >
                                <ReactTable
                                    data={destinatarios}
                                    NoDataComponent={() => (<></>)}
                                    columns={[
                                        {
                                            Header: 'CPF / CNPJ',
                                            accessor: 'cpfCnpj',
                                            minWidth: 40,
                                            resizable: false,
                                            sortable: false,
                                            Cell: (props) => props.original.cpfCnpj = props.original.tipoPessoa === 'F' ? maskCPF(props.original.cpfCnpj) : maskCNPJ(props.original.cpfCnpj)
                                        },
                                        {
                                            Header: 'Nome / Razão Social',
                                            accessor: 'nomeRazao',
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Tipo Pessoa',
                                            accessor: 'tipoPessoa',
                                            minWidth: 25,
                                            resizable: false,
                                            sortable: false,
                                            Cell: (props) => props.original.tipoPessoa === 'F' ? 'Física' : 'Jurídica'
                                        },
                                        {
                                            Header: 'Tipo',
                                            accessor: 'tipo',
                                            minWidth: 25,
                                            Cell: (props) => changeTipoDestinatario(props.original.tipo),
                                            resizable: false,
                                            sortable: false
                                        }
                                    ]}
                                    getTrProps={getTrProps}
                                    getTdProps={getTdProps}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight header-fix"
                                    noDataText=""
                                    pageSize={destinatarios.length}
                                />
                            </div>

                            <br />
                        </fieldset>
                    </Col>

                </Row>

                <Col className="text-right">
                    <Info>
                        {`${destinatarios.length} destinatarios encontrados`}
                    </Info>
                </Col>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        onClick={() => confirmModal(pesquisaDestinatario)}
                        color={"info"}
                    >CONCLUÍDO
                    </Button>
                    <Button
                        className="btn-padrao"
                        onClick={() => limparModal()}
                        color={"info"}
                    >LIMPAR
                    </Button>
                </Col>
            </ModalBasicFooter>

        </ModalBasic>
    )
}