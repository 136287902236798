import React, { useState, useEffect, memo } from 'react';

import { Select, Switch, InputNumber } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'
import '../styles.css'

import {
    Row,
    Col,
    CardBody,
    CardFooter,
    Button,
} from "reactstrap";

import ModalExisteAtivoGrade from './Modals/ModalExisteAtivoGrade';
import ModalSalvarGrade from './Modals/ModalSalvarGrade';
import ModalTrocarTipoGrade from './Modals/ModalTrocarTipoGrade';

import useShallowEqualSelector from '../../../Util/Hooks/useShallowEqualSelector';
import useModal from '../../../Util/Hooks/useModal';

import * as controllerProdutos from '~/controller/controllerProdutos';
import * as actions from '../../../store/reducers/Cadastro/Produto/actions';

const Grade = memo(function Grade(props) {

    const { codEmpresa } = useShallowEqualSelector(state => state.login.usuario.empresa);
    const codProduto = useShallowEqualSelector(state => state.produtos.produto.codProduto);
    const grades = useShallowEqualSelector(state => state.produtos.produto.grades);
    const gradesSelected = useShallowEqualSelector(state => state.produtos.produto.gradesSelected);
    const tiposGrade = useShallowEqualSelector(state => state.produtos.tiposGrade);
    const gradesCopia = useShallowEqualSelector(state => state.produtos.produtoCopia.grades);
    const verCadastro = useShallowEqualSelector(state => state.produtos.verCadastro);

    const [loading, setloading] = useState(false)
    const [modalTrocarTipo, toggleModalTrocarTipo] = useModal(false)
    const [modalSalvar, toggleModalSalvar] = useModal(false)
    const [modalExisteAtivo, toggleModalExisteAtivo] = useModal(false)
    const [valueSelect, setValueSelect] = useState({ codTipoGrade: '0', tipos: [] });

    useEffect(() => {
        if (grades.codTipoGrade === "0") {
            controllerProdutos.GetTipoGrades(codEmpresa)
        }
    }, [grades])

     useEffect(() => {
        setValueSelect({ codTipoGrade: grades.codTipoGrade, tipos: tiposGrade })
    }, [grades, tiposGrade]) 
    useEffect(() => {
        if (props.salvar) {
            Salvar()
        }
    }, [props.salvar])

    async function onChangeTipoGrade(value) {
        if (value !== "0") {
            setloading(true)
            await controllerProdutos.GetProdutoGrades(codEmpresa, codProduto, value)
            setloading(false)
        } else {
            actions.setProdutoGrades({ ...grades, codTipoGrade: value, grades: [] })
        }
    }

    function onChangeQtdgrade(value, index) {
        var newGrade = []
        grades.grades.forEach((grade, key) => {
            if (index === key) {
                var gradeMod = { ...grade, qtdEstoqueGrade: value }
                newGrade.push(gradeMod)
            } else {
                newGrade.push(grade)
            }
        })
        actions.setProdutoGrades({ ...grades, grades: newGrade })
    }

    function onChangeStatusgrade(index) {
        var newGrade = []
        var gradeSelected = JSON.stringify(gradesSelected)
        var listGrades = JSON.parse(gradeSelected)

        grades.grades.forEach((grade, key) => {
            if (index === key) {
                var gradeMod = { ...grade, status: grade.status === 1 ? 0 : 1 }
                newGrade.push(gradeMod)
                listGrades.push(gradeMod)
            } else {
                newGrade.push(grade)
            }
        });
        actions.setProdutoGrades({ ...grades, grades: newGrade })

        actions.setProdutoGradesSelected( listGrades )
    }

    function handleSalvar() {
        var perdaDeEstoque = false;
        var existeAtivo = false;
        grades.grades.forEach(grade => {
            if (grade.status === 1 && parseFloat(grade.qtdEstoqueGrade) > 1) {
                perdaDeEstoque = true
            }
            if (grade.status === 0) {
                existeAtivo = true
            }
            if (perdaDeEstoque && existeAtivo) {
                return
            }
        })
        if (!existeAtivo) {
            toggleModalExisteAtivo()
        } else if (perdaDeEstoque) {
            toggleModalSalvar()
        } else {
            Salvar()
        }
    }

    async function Salvar() {
        setloading(true)
        if (codProduto) {
            if (gradesCopia.codTipoGrade !== '0' && gradesCopia.codTipoGrade !== grades.codTipoGrade) {
                toggleModalTrocarTipo()
            } else {
                await controllerProdutos.VincularProdutoGrade(codEmpresa, codProduto, grades, gradesSelected, gradesCopia)
            }
        } else {
            await controllerProdutos.VincularProdutoGrade(codEmpresa, codProduto, grades, gradesSelected, gradesCopia)
        }
        setloading(false)
    }

    async function confirmaTroca() {
        setloading(true)
        await controllerProdutos.VincularProdutoGrade(codEmpresa, codProduto, grades, gradesCopia)
        setloading(false)
    }

    return (
        <>
            <Row className="justify-content-center">
            <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

            </div>
                <Col>
                    <h4 className="info-text">Grade do produto</h4>

                    <CardBody>

                        <Row className="justify-content-center">

                            <Col md="10">

                                <Row>
                                    <Col lg="3">
                                        <label>Tipo da grade</label>
                                        <Select
                                            id="tipoGradeSelect"
                                            first="Selecione"
                                            value={{ value: valueSelect.codTipoGrade }}
                                            select={{ value: "codigo", label: "descricao" }}
                                            onChange={value => onChangeTipoGrade(value.value)}
                                            options={valueSelect.tipos}
                                            isSearchable={true}
                                            autoComplete="off"
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg md sm>
                                        <div className="box" style={{ width: '100%', padding: '0px' }}>

                                            {
                                                grades.codTipoGrade === '0' ?

                                                    <Col className="text-center" style={{ marginTop: '15px' }}>
                                                        <h5>Selecione uma grade</h5>
                                                    </Col>

                                                    :

                                                    <table className="tbGrades">
                                                        <thead>
                                                            <tr>
                                                                <th>Descrição</th>
                                                                <th>Sigla</th>
                                                                <th>Pertence</th>
                                                                <th>Quantidade em estoque</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {grades.grades.length > 0 ?
                                                                grades.grades.map((grade, key) => (
                                                                    <tr key={grade.codGrade}>
                                                                        <td>{grade.descricao}</td>
                                                                        <td>{grade.sigla}</td>
                                                                        <td>
                                                                            <Switch
                                                                                checked={grade.status === 0}
                                                                                onChange={e => onChangeStatusgrade(key)}                       
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <InputNumber
                                                                                style={{ textAlign: 'center' }}
                                                                                type="text"
                                                                                format="10,4"
                                                                                value={grade.qtdEstoqueGrade}
                                                                                onChange={e => onChangeQtdgrade(e.target.value, key)}
                                                                                disabled={true}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                : null
                                                            }
                                                        </tbody>
                                                    </table>

                                            }

                                            {
                                                grades.grades.length > 0 || grades.codTipoGrade === '0' ?
                                                    null
                                                    :
                                                    <Col className="text-center" style={{ marginTop: '15px' }}>
                                                        <h5>{loading ? 'Carregando...' : 'Sem informações'}</h5>
                                                    </Col>
                                            }
                                        </div>
                                    </Col>
                                </Row>

                            </Col>

                        </Row>

                    </CardBody>

                    <CardFooter className="text-center">
                        <Row className="justify-content-center">
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                <ArrowTooltip title='Shift+S'>
                                    <Button  className="btn-padrao" color="info" disabled={loading || grades.codTipoGrade === '0' || verCadastro === true} onClick={handleSalvar}>Salvar</Button>
                                </ArrowTooltip>

                            </Col>
                        </Row>
                    </CardFooter>

                </Col>
            </Row>

            {modalTrocarTipo ?
                <ModalTrocarTipoGrade
                    isOpen={modalTrocarTipo}
                    toggle={toggleModalTrocarTipo}
                    confirmaTroca={confirmaTroca}
                />
                : null}


            {modalSalvar ?
                <ModalSalvarGrade
                    isOpen={modalSalvar}
                    toggle={toggleModalSalvar}
                    salvar={Salvar}
                />
                : null}

            {modalSalvar ?
                <ModalSalvarGrade
                    isOpen={modalSalvar}
                    toggle={toggleModalSalvar}
                    salvar={Salvar}
                />
                : null}

            {modalExisteAtivo ?
                <ModalExisteAtivoGrade
                    isOpen={modalExisteAtivo}
                    toggle={toggleModalExisteAtivo}
                />
                : null}
        </>
    )
})

class Wizard extends React.Component {
    constructor() {
        super()
        this.state = {
            salvar: false
        };
    }

    focusInput = () => {
        setTimeout(() => {
            document.getElementById("tipoGradeSelect").focus()
        }, 100)
    }


    isValidated = (step = 0, finish = false) => {
        if (step > 0) {
            return true
        }
        if (!finish) {
            this.setState({ salvar: true }, () => {
                this.setState({ salvar: false })
            })
        }
        return false
    }

    render() {
        return (
            <Grade salvar={this.state.salvar} />
        )
    }
}

export default Wizard;
