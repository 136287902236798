import React from "react";
import ReactTable from "react-table";

import {
    Col,
    Row,
} from "reactstrap";

import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";

function Duplicata() {
    const duplicatas = useShallowEqualSelector(state => state.entradaMercadoria.duplicatas)
    const verCadastro = useShallowEqualSelector(state => state.entradaMercadoria.visualizarEntrada);

    return (
        <>
            <Row>
                <div className= {verCadastro ? 'ver-cadastro' : ''}></div>
                <Col lg="12">
                    <fieldset style={{ border: 'solid 1px #5E6680', borderRadius: 5 }}>
                        <legend style={{ fontSize: '13px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
                            Fatura e Duplicata
                            </legend>
                        <div style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' }} >
                            <Col>
                                <ReactTable
                                    data={duplicatas}
                                    filterable={false}
                                    columns={
                                        [
                                            {
                                                Header: "Código Fatura",
                                                accessor: "codFatura"
                                            },
                                            {
                                                Header: "Número Duplicata",
                                                accessor: "numeroDuplicata"
                                            },
                                            {
                                                Header: "Data de Vencimento",
                                                accessor: "dataVencimento"
                                            },
                                            {
                                                Header: "Valor Duplicata",
                                                accessor: "valor"
                                            },
                                            {
                                                Header: "",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false
                                            }
                                        ]
                                    }
                                    minRows={1}
                                    showPaginationTop={false}
                                    className="-striped -highlight"
                                    noDataText=""
                                    showPaginationBottom={false}
                                    showPageSizeOptions={false}
                                    previousText="Anterior"
                                    nextText="Próximo"
                                    loadingText="Carregando"
                                    pageText="Página"
                                    ofText="de"
                                    rowsText='linhas'
                                    NoDataComponent={() => (null)}
                                />
                            </Col>
                        </div>
                    </fieldset>
                </Col>
            </Row>
        </>
    )
}

export default Duplicata;