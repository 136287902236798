import React from 'react';

import { 
    Button, 
    Col, 
    Row, 
    Modal,
    ModalBody, 
    ModalFooter
} from 'reactstrap';

export const PopUpDelete = ({ isOpen, callback, toggle }) => {
    return (
      <Modal
      isOpen={isOpen}
      toggle={() => toggle && toggle() }
      centered={true}
      backdropClassName={'backdropTeste'}
    >
      <div className="modal-header justify-content-center">
        <button
          aria-hidden={true}
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle && toggle() }
        >
          <i className="tim-icons icon-simple-remove" />
        </button>
        <h6 className="title title-up">Excluir Entrada</h6>
      </div>
      <ModalBody>
  
        <Col className="text-center">
          <label>Ao <b>excluir</b> esta entrada, será excluído <b>todos</b> os Dados relacionados à mesma. Deseja continuar?</label>
        </Col>
  
      </ModalBody>
      <ModalFooter>
        <Row style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
            <Button
              color="danger"
              type="button"
              onClick={() => toggle && toggle() }
              style={{ width: '150px' }}
            >
                CANCELAR
            </Button>
            <Button
              color="info"
              type="button"
              onClick={() => callback()}
              style={{ width: '150px' }}
            >
                CONTINUAR
            </Button>
        </Row>
      </ModalFooter>
    </Modal>
    )
  }