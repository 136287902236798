export const statusSelect = [
    {
        codStatus: "1",
        statusDesc: "Pendente de Faturamento"
    },
    {
        codStatus: "2",
        statusDesc: "Pendente de emissão NF-e"
    },
    {
        codStatus: "3",
        statusDesc: "Finalizado"
    },
]