import React from 'react'
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from '../../../components/ModalBasic/ModalBasic'
import { Col, Row, Button } from 'reactstrap'

import * as controller from '../../../controller/controllerProdutos'
import useShallowEqualSelector from '../../../Util/Hooks/useShallowEqualSelector'

interface ModalExcluirProps {
    isOpen: boolean,
    toggle: () => void,
    produto: any,
    fornecedores: any
}

const ModalExcluir: React.FC<ModalExcluirProps> = ({ isOpen, toggle, produto, fornecedores }) => {
    //@ts-ignore
    const { pesquisa } = useShallowEqualSelector(state => state.produtos)
    //@ts-ignore
    const { codEmpresa } = useShallowEqualSelector(state => state.login.usuario.empresa)


    function excluir() {
        controller.Excluir(codEmpresa, produto, pesquisa, fornecedores)
    }

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title={'Excluir produto'}
        >
            <ModalBasicBody>

                <Col className="text-center">
                    <label>Tem certeza que deseja <b>excluir</b> esse produto?</label>
                </Col>
            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <Button
                            color="danger"
                            type="button"
                            onClick={() => { toggle() }}
                            style={{ width: '150px' }}
                        >NÃO
                                    </Button>
                    </Col>
                    <Col className="text-center">
                        <Button
                            color="success"
                            type="button"
                            onClick={() => {
                                toggle()
                                excluir()
                            }}
                            style={{ width: '150px' }}
                        >SIM
                                    </Button>
                    </Col>
                </Row>
            </ModalBasicFooter>

        </ModalBasic>
    )
}
export default ModalExcluir