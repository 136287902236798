import { Reducer } from 'redux'
import { ProdutoState, ProdutoTypes, ProdutoAction, ProdutoStore } from './types'
import { Produto } from './types'

const produtoINITIAL: Produto = {
    codProduto: '',
    status: 0,

    basico: {
        codGrupo: '0',
        grupo: '',
        codSubgrupo: '0',
        subgrupo: '0',
        codBarras: '',
        numeroProduto: '0',
        nome: '',
        descricao: '',

        especificacoes: {
            unidade: 1,
            largura: '0',
            altura: '0',
            profundidade: '0',
            peso: '0',
            voltagem: '',
            garantiaFor: 0,
            codFornecedor: [],
            fornecedor: '',
            precoCusto: 0,
            precoVenda: '0',
            markup: '0',
            codOrigem: '0'
        },

        comissao: {
            flControlaComissao: 0,
            percentualComissao: '0',
        },

        estoque: {
            flControlaEstoque: 1,
            qtdMinima: '0',
            qtdAtual: '0',
        },

        caracteristicas: {
            flControlaNumSerie: 0,
            flControlaGrade: 0,
            flTributado: 0,
            flItemVenda: 1,
        },
    },
    grades: {
        codTipoGrade: '0',
        grades: []
    },
    gradesSelected: [],
    dadosTributarios: {
        codSeguimento: '0',
        cest: '',
        ncm: '',
        descricao: '',
        codClassificacao: '',
        aliquotaMVA: ''
    }
}

export const INITIAL_STATE: ProdutoState = {

    pesquisa: {
        numeroProduto: '',
        codGrupo: '0',
        codSubgrupo: '0',
        nome: '',
        ativos: 2,
        dtCadastro: '',
        ncm: '',
        cest: '',
        nomeRazao: '',
        fornecedores: [],
        codForne: '',
        flags: [],
        flControlaEstoque: '0',
        flControlaComissao: '0',
        flControlaNumSerie: '0',
        flControlaGrade: '0',
        flTributado: '0',
        flItemVenda: '0',
    },

    produtos: [],
    grupos: [],
    pesqsubgrupos: [],
    cadsubgrupos: [],
    fornecedores: [],
    fornecedoresProd: [],
    inicioCadastro: false,
    proximoCadastro: false,
    verCadastro: false,

    invalido: {
        codBarras: false,
        nome: false,
        descricao: false,
        descricaoImagem: false,
    },


    imagens: [],
    imagem: {
        codImagem: '',
        descricao: '',
        imagem: '',
        flPrincipal: 0,
    },
    tiposGrade: [],
    seguimentos: [],
    cests: [],
    cestsSugestoes: [],
    ncms: [],
    ncmsSugestoes: [],
    classificacoes: [],

    produto: produtoINITIAL,
    produtoCopia: produtoINITIAL,
    origensProduto: []
}

const produtosStore: Reducer<ProdutoState, ProdutoAction> = (state = INITIAL_STATE, action: ProdutoAction) => {
    switch (action.idStore) {
        case ProdutoStore.ID_STORE:
            switch (action.type) {
                case ProdutoTypes.PESQUISA:
                    if (action.pesquisa)
                        return { ...state, pesquisa: action.pesquisa }
                case ProdutoTypes.PRODUTO:
                    if (action.produto)
                        return { ...state, produto: action.produto }
                case ProdutoTypes.PRODUTO_COPIA:
                    if (action.produto)
                        return { ...state, produtoCopia: action.produto }
                case ProdutoTypes.IMAGEM:
                    if (action.imagem)
                        return { ...state, imagem: action.imagem }
                case ProdutoTypes.PRODUTOS:
                    if (action.produtos)
                        return { ...state, produtos: action.produtos }
                case ProdutoTypes.GRUPOS:
                    if (action.grupos)
                        return { ...state, grupos: action.grupos }
                case ProdutoTypes.SUBGRUPOS_PESQ:
                    if (action.pesqsubgrupos)
                        return { ...state, pesqsubgrupos: action.pesqsubgrupos }
                case ProdutoTypes.SUBGRUPOS_CAD:
                    if (action.cadsubgrupos)
                        return { ...state, cadsubgrupos: action.cadsubgrupos }
                case ProdutoTypes.FORNECEDORES:
                    if (action.fornecedores)
                        return { ...state, fornecedores: action.fornecedores }
                case ProdutoTypes.FORNECEDORES_PRODUTO:
                    if (action.fornecedoresProd)
                        return { ...state, fornecedoresProd: action.fornecedoresProd }
                case ProdutoTypes.IMAGENS:
                    if (action.imagens)
                        return { ...state, imagens: action.imagens }
                case ProdutoTypes.TIPOS_GRADE:
                    if (action.tiposGrade)
                        return { ...state, tiposGrade: action.tiposGrade }
                case ProdutoTypes.SEGUIMENTOS:
                    if (action.seguimentos)
                        return { ...state, seguimentos: action.seguimentos }
                case ProdutoTypes.CESTS:
                    if (action.cests)
                        return { ...state, cests: action.cests }
                case ProdutoTypes.SUGESTOES_CESTS:
                    if (action.cestsSugestoes)
                        return { ...state, cestsSugestoes: action.cestsSugestoes }
                case ProdutoTypes.NCMS:
                    if (action.ncms)
                        return { ...state, ncms: action.ncms }
                case ProdutoTypes.SUGESTOES_NCMS:
                    if (action.ncmsSugestoes)
                        return { ...state, ncmsSugestoes: action.ncmsSugestoes }
                case ProdutoTypes.CLASSIFICACOES:
                    if (action.classificacoes)
                        return { ...state, classificacoes: action.classificacoes }
                case ProdutoTypes.INVALIDO:
                    if (action.invalido)
                        return { ...state, invalido: action.invalido }
                case ProdutoTypes.INICIO_CADASTRO:
                    if (action.inicioCadastro !== undefined)
                        return { ...state, inicioCadastro: action.inicioCadastro }
                case ProdutoTypes.PROXIMO_CADASTRO:
                    if (action.proximoCadastro !== undefined)
                        return { ...state, proximoCadastro: action.proximoCadastro }
                case ProdutoTypes.VER_CADASTRO:
                    if (action.verCadastro)
                        return { ...state, verCadastro: action.verCadastro }
                case ProdutoTypes.CLEAN:
                    if (action.newState)
                        return { ...action.newState }
                case ProdutoTypes.ORIGENSPRODUTO:
                    if (action.origensProduto) {
                        return { ...state, origensProduto: action.origensProduto }
                    }
                default:
                    return state
            }
        default:
            return state
    }
}

export default produtosStore