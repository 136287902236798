import React, { useState, useEffect, useLayoutEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// react plugin for creating vector maps
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Label,
} from "reactstrap";

// core components
import {
  chartExample2,
  chartExample3,
  chartExample4,

} from "../../variables/charts.jsx";

import ArrowTooltip from '../../components/Tooltip/ArrowTooltip';

import history from "../../Util/history";
import * as dashboardController from "../../controller/controllerDashboard"

import AppState from "../../store/storeType.js";
import { IDashboardState } from "../../store/reducers/DashboardStore/types.js";
import useShallowEqualSelector from "../../Util/Hooks/useShallowEqualSelector";
import * as actions from "../../store/reducers/DashboardStore/actions";
const Dashboard = () => {

  const bigChartData = useShallowEqualSelector<AppState, IDashboardState['bigChartData']>(state => state.dashboard.bigChartData)
  const highGrafic = useShallowEqualSelector<AppState, IDashboardState['highGrafic']>(state => state.dashboard.highGrafic)
  const [topFive, setTopFive] = useState<any[]>([])
  const [dataPdv, setDataPdv] = useState<any>([]);

  const optionsHighGrafic = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent"
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ]
    }
  }

  useEffect(() => {

    if (!window.localStorage.getItem('TOPFIVE') || window.localStorage.getItem('TOPFIVE') == "[]" || window.localStorage.getItem('TOPFIVE') == "undefined") {

      getFive()


    } else {

      if (window.localStorage.getItem('TOPFIVE')) {

        let strTelas: any = window.localStorage.getItem('TOPFIVE');
        let arrTela: any[] = JSON.parse(strTelas);
        setTopFive(arrTela)

      }

    }

    if (!window.localStorage.getItem('DATA_PDV') || window.localStorage.getItem('DATA_PDV') == "[]" || window.localStorage.getItem('DATA_PDV') == "undefined") {
      getDataPdv()
    } else {

      if (window.localStorage.getItem('DATA_PDV')) {

        let strDataPdv: any = window.localStorage.getItem('DATA_PDV');
        let arrDataPdv: JSON = JSON.parse(strDataPdv);
        setDataPdv(arrDataPdv)
      }

    }

    getValueGrafic()

  }, []);

  useLayoutEffect(() => {

    getValueGrafic()

  }, [bigChartData])

  async function getFive() {

    const retorno: any = await dashboardController.getFiveViews()

    if (retorno) {

      retorno.forEach(async function (tela: any) {
        var reTela = await dashboardController.getCount(tela)
        if ((reTela.codAtalho === tela.codAtalho) && (reTela.codTela === tela.codTela)) {
          tela.qtdRegister = reTela.count
        }

      });

    }

    setTimeout(() => { window.localStorage.setItem('TOPFIVE', JSON.stringify(retorno)), getTopFive() }, 500)

  }

  const getTopFive = () => {

    if (!window.localStorage.getItem('TOPFIVE') || window.localStorage.getItem('TOPFIVE') == "[]") {

      getFive();

      getCountTopFive()

      window.localStorage.setItem('TOPFIVE', JSON.stringify(topFive));

    } else {

      if (window.localStorage.getItem('TOPFIVE')) {

        let strTelas: any = window.localStorage.getItem('TOPFIVE');
        let arrTela: any[] = JSON.parse(strTelas);
        setTopFive(arrTela)

      }

    }

  }

  const switchSytle = (index: number) => {

    var style: string = ''
    switch (index) {
      case 0:
        style = 'background-image: linear-gradient(to bottom left, #13874F, #22D092, #13874F) !important'
        return style

      case 1:

        style = 'background-image: linear-gradient(to bottom left, #FAC20A, #997802, #FAC20A) !important'
        return style

      case 2:
        style = 'background-image: linear-gradient(to bottom left, #2169C4, #22D092, #2169C4) !important'
        return style

      case 3:
        style = 'background-image: linear-gradient(to bottom left,#E14ECA, #E17ACA, #E14ECA) !important'
        return style

      case 4:
        style = 'background-image: linear-gradient(to bottom left, #FF2603,#FA550A,#FF2603) !important'
        return style

      default:
        style = ''
        return style

    }

  }

  const getCountOnly = async function (check: boolean, tela: any) {
    var reTela: any

    if (check) {

      reTela = await dashboardController.getCount(tela)

    }

    topFive.forEach(tela => {
      if ((reTela.codAtalho === tela.codAtalho) && (reTela.codTela === tela.codTela)) {
        tela.qtdRegister = reTela.count
      }

    });

    window.localStorage.setItem('TOPFIVE', JSON.stringify(topFive));

    setTimeout(() => { getTopFive() }, 500)

  }

  const getCountTopFive = () => {

    topFive.forEach(async function (tela) {

      var reTela = await dashboardController.getCount(tela)
      if ((reTela.codAtalho === tela.codAtalho) && (reTela.codTela === tela.codTela)) {
        tela.qtdRegister = reTela.count
      }

    });

    window.localStorage.setItem('TOPFIVE', JSON.stringify(topFive));

    setTimeout(() => { getTopFive(), setTopFive(topFive) }, 500)

  }

  const getDataPdv = async function () {
    var mensal, diario, semanal: any
    let aux: any[]

    mensal = await dashboardController.getGraficMonthly()
    semanal = await dashboardController.getGraficWeekly()
    diario = await dashboardController.getGraficDiary()
    aux = [mensal,semanal,diario];
    let auxJson: string
    if(typeof dataPdv !== 'string'){
      auxJson = JSON.stringify(aux)
      setDataPdv(aux)
    }else{
      auxJson = JSON.stringify(aux)
      setDataPdv(JSON.parse(auxJson))
    }
    window.localStorage.setItem('DATA_PDV', auxJson)
  }

  const getValueGrafic = async function () {

    var mensal, diario, semanal: any
    var arrAuxiliar = []

    arrAuxiliar.push(mensal, semanal, diario)

    actions.setHighGrafic(arrAuxiliar)

  }

  return (
    <>
      <div className="content">

        <Row>

          {
            topFive.map((tela: any, index: number) =>
              <Col >
                <Card className="card-stats" Style={'margin-bottom: 0em !important'}>
                  <CardBody>
                    <Row Style={""}>
                      <Col lg="2">
                        <div className="info-icon text-center"

                          //@ts-ignore
                          Style={switchSytle(index)}>
                          <i className={tela.icon} />
                        </div>
                      </Col>
                      <Col lg='10'>
                        <div className="numbers"
                        >
                          <CardTitle className="card-category" >{tela.nomeTela}</CardTitle>
                          <Label tag="h3">{tela.qtdRegister}</Label>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats">
                      <Row>
                        <Col
                          //@ts-ignore
                          Style={""}>
                          <a
                            onClick={() => { history.push(`${tela.path}`) }}
                            style={{ color: "#1d8cf8", cursor: "pointer" }}>
                            <i className="tim-icons icon-user-run" /> Ir para tela
                      </a>
                        </Col>
                        <Col lg="1"
                          //@ts-ignore
                          Style={" cursor: pointer; margin-right: 2em"}>
                          <ArrowTooltip title="Atualizar quantidade de registro">
                            <a
                              //@ts-ignore
                              onClick={() => getCountOnly(true, tela)}

                            >
                              <i className="fas fa-sync-alt"
                                //@ts-ignore
                                Style={'font-size: 1.1em;color: #1d8cf8; '}></i>
                            </a>
                          </ArrowTooltip>
                        </Col>
                      </Row>

                    </div>
                  </CardFooter>
                </Card>
              </Col>

            )
          }
          <ArrowTooltip title="Atualizar todas as quantidades de registro">
            <a
              onClick={() => { getCountTopFive() }}

              //@ts-ignore
              Style={'margin-left: 98%;color: #1d8cf8; cursor: pointer; margin-bottom: 1em;'}
            >
              <i className="fas fa-sync-alt" />
            </a>
          </ArrowTooltip>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Pedidos de Vendas</h5>
                    <CardTitle tag="h2">Total</CardTitle>
                  </Col>
                  <Col sm="6">

                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >

                      <Button
                        color="info"
                        id="0"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === 0
                        })}
                        onClick={() => actions.setBigChartData(0)}
                      >
                        <input defaultChecked name="options" type="radio" />
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Mensal
                      </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>

                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === 1
                        })}
                        onClick={() => actions.setBigChartData(1)}
                      >
                        <input name="options" type="radio" />
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Semanal
                      </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>

                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === 2
                        })}
                        onClick={() => actions.setBigChartData(2)}

                      >
                        <input name="options" type="radio" />
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Diário
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                      <i className="fas fa-sync-alt"
                        onClick={() => {getDataPdv()}}
                        style={{
                          padding: '8px',
                          fontSize: '1.1em',
                          color:'#1d8cf8',
                          cursor: 'pointer',
                          }}>
                      </i>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    //@ts-ignore
                    data={dataPdv[bigChartData]}
                    options={optionsHighGrafic}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Total Shipments</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-bell-55 text-primary" />{" "}
                763,215
              </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample2.data}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Daily Sales</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-delivery-fast text-info" />{" "}
                3,500€
              </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Bar
                    data={chartExample3.data}
                    options={chartExample3.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Completed Tasks</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-send text-success" /> 12,100K
              </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample4.data}
                    options={chartExample4.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="5">

          </Col>

        </Row>
      </div>
    </>
  )
}


export default Dashboard;
