import React, { useState, useRef, useEffect } from 'react';


import {Row, CardBody} from "reactstrap";

import { Card } from '../Components';

import * as controllerProdutos from '../../../../controller/controllerProdutos'
import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector';

import * as actions from '../../../../store/reducers/Movimentacao/PedidoVenda/actions';
import AppState from '../../../../store/storeType';
import { SubGrupoProdutos } from '../../../../store/reducers/Movimentacao/PedidoVenda/types';
import MiniCard from '../Components/miniCard';



const ListSubGrupoProdutos: React.FC = () => {
    //@ts-ignore
    const { codEmpresa } = useShallowEqualSelector(state => state.login.usuario.empresa)

    const subGrupos= useShallowEqualSelector<AppState, SubGrupoProdutos[]>(state => state.pedidoVenda.subGrupoProdutos)


    useEffect(() => {
        controllerProdutos.GetSubgrupos(codEmpresa, "", (subgrupos: SubGrupoProdutos[]) => {
            actions.setSubGrupoProdutos(subgrupos)
          })
    },[])
  


    return (
        <Card>
            <div style={{height: "100%"}}>
                <Card className="scrollBar" style={{height: "100%", maxHeight: "217px",  overflow: 'auto'}}>
                    <CardBody>
                        <Row style={{height: "100%" , alignContent: "flex-start"}}>
                            {subGrupos.map(subgrupo => (<MiniCard className="subGrupo-miniCard" changeOnClick={true} cor={"info"} valor={""} descricao={subgrupo.nome} index={Number(subgrupo.codSubgrupo)} />))}
                        </Row>
                    </CardBody>
                </Card>

            </div>
        </Card>
    );
}

export default ListSubGrupoProdutos;