//State da tela de Pesquisa e cadastro de grades
var INITIAL_STATE = {
    pesquisa: {
        codTipoGrade: '0',
        codGrade: '',
        descricao: '',
        sigla:'',
        ativos: 2,
    },
    grade: {
        codTipoGrade: '',
        codGrade: '',
        descricao: '',
        sigla:'',
        status: 0,
    },
    invalido: {
        codTipoGrade: false,
        descricao: false,
        sigla: false
    },
    grades: [],
    tipoGrade:[],
    verCadastro: false,
    gradeCopia: {}
};

export default function grades(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'GRADES':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'GRADES':
                    return { ...state, grades: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: INITIAL_STATE.pesquisa,
                        grades: INITIAL_STATE.grades
                    }
                case 'GRADE': 
                    return { ...state, grade: { ...state.grade, [action.campo]: action.value } }
                case 'TIPOGRADE':
                    return { ...state, tipoGrade: action.value }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'VER_CADASTRO':
                        return { ...state, verCadastro: action.value }
                case 'GRADE_COPIA':
                    return { ...state, gradeCopia: state.grade }
                case 'CLEAN_GRADE':
                    return {
                        ...state,
                        grade: {
                            codTipoGrade: '0',
                            codGrade: '',
                            descricao: '',
                            sigla:'',
                            status: 0,
                        },
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: {
                            codTipoGrade: false,
                            descricao: false,
                            sigla: false
                        },
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}