export const tipoPesquisa = [
  {
    codDescricaoLancamento: "DESCLAN",
    lancamentoDesc: "Descrição do Lançamento"
  },
  {
    codDescricaoLancamento: "DESCLANPARTE",
    lancamentoDesc: "Descrição do Lançamento (Qualquer parte)"
  },
  {
    codDescricaoLancamento: "CIDADE",
    lancamentoDesc: "Cidade"
  },
]