import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerCESTs from "~/controller/controllerCESTs";
import { maskCEST } from "~/Util/mascaras";
import { Select } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';


import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
} from "reactstrap";

const idStore = "CESTS";


const CEST = () => {
  const notificacao = useSelector(state => state.notificacao);
  const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
  const { cest, cestCopia, invalido, seguimentos, verCadastro } = useSelector(state => state.cests);
  const dispatch = useDispatch();

  function onChange(value, stateName) {
    dispatch({ idStore, type: 'CEST', campo: stateName, value: value });
    dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
    if (stateName === 'cest') {
      if (value.length === 2) {
        if (seguimentos.findIndex(item => item.codigo === parseInt(value)) !== -1) {
          onChange(parseInt(value), 'codSeguimento')
        } else {
          onChange('0', 'codSeguimento')
        }
      } else if (value.length === 0) {
        onChange('0', 'codSeguimento')
      }
    }
  };

  async function Salvar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    if (!cest.update) {
      controllerCESTs.CreateCEST(notificacao, dispatch, codEmpresa, cest);
    } else {
      controllerCESTs.UpdateCEST(notificacao, dispatch, codEmpresa, cest, cestCopia);
    }
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_CEST' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('cadcest').focus()
  };

  return (
    <>
      <Row className="justify-content-center">
      <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

      </div>
        <Col>
          <h4 className="info-text">Preencha com as informações do CEST</h4>
          <CardBody>

            <Row className="justify-content-center">

              <Col md="12">

                <Row>

                  <Col lg md sm="7">
                    <label>Seguimento <span className="error">*</span></label>
                    {!cest.update ?
                      <Select
                        first={'Selecione'}
                        value={{ value: cest.codSeguimento }}
                        select={{ value: 'codigo', label: 'descricao' }}
                        options={seguimentos}
                        onChange={value => onChange(value.value, 'codSeguimento')}
                        searcheable={false}
                      />
                      :
                      <FormGroup>
                        <Input
                          type="text"
                          value={seguimentos.find(item => item.codigo === cest.codSeguimento).descricao}
                          disabled
                        />
                      </FormGroup>
                    }
                    <br></br>
                  </Col>
                </Row>

                <Row>

                  <Col lg md sm="7">
                    <label>CEST <span className="error">*</span></label>
                    <FormGroup className={invalido.cest ? "has-danger" : ''}>
                      <Input
                        id="cadcest"
                        type="text"
                        value={cest.cest}
                        onChange={e => onChange(maskCEST(e.target.value), 'cest')}
                        autoComplete="off"
                        maxLength="12"
                        disabled={cest.update}
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

                <Row>

                  <Col lg md sm="7">
                    <label>Descrição <span className="error">*</span></label>
                    <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                      <Input
                        id="caddescricao"
                        type="text"
                        value={cest.descricao}
                        onChange={e => onChange(e.target.value, 'descricao')}
                        autoComplete="off"
                        maxLength="500"
                      
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'center' }}>
                  <ArrowTooltip title='Shift+N'>
                    <Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Novo</Button>
                  </ArrowTooltip>
                  <ArrowTooltip title='Shift+S'>
                    <Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button>
                  </ArrowTooltip>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>

    </>
  )
};

class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      if (!this.props.cest.update) {
        document.getElementById('cadcest').focus()
      } else {
        document.getElementById('caddescricao').focus()
      }
    }, 100)
  }

  render() {
    return (
      <CEST />
    )
  }
}

export default Wizard;
