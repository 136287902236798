//State da tela de Pesquisa e cadastro de CESTs
var INITIAL_STATE = {
    pesquisa: {
        codSeguimento: '0',
        cest: '',
        descricao: '',
        ativos: 0,
    },
    cest: {
        codSeguimento: '0',
        cest: '',
        descricao: '',
        descricaoSeguimento: '',
        update: false,
    },
    invalido: {
        codSeguimento: false,
        cest: false,
        descricao: false,
    },
    verCadastro: false,
    seguimentos: [],
    cests: [],
    cestCopia: {}
};

export default function cests(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'CESTS':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'CESTS':
                    return { ...state, cests: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: INITIAL_STATE.pesquisa,
                        cests: INITIAL_STATE.cests
                    }
                case 'CEST':
                    return { ...state, cest: { ...state.cest, [action.campo]: action.value } }
                case 'SEGUIMENTOS':
                    return { ...state, seguimentos: action.value }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value } 
                case 'CEST_COPIA':
                    return { ...state, cestCopia: state.cest }
                case 'CLEAN_CEST':
                    return {
                        ...state,
                        cest: INITIAL_STATE.cest,
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: INITIAL_STATE.invalido,
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}