import React, { useState, useLayoutEffect } from 'react'
import ReactWizard from '../../../../../components/Wizard/Wizard';
import useShallowEqualSelector from '../../../../../Util/Hooks/useShallowEqualSelector';
import AppState from '../../../../../store/storeType';
import { IUsuarioState } from '../../../../../store/reducers/Cadastro/Usuario/types';
import classNames from "classnames";
import { Col } from 'reactstrap';
import * as actions from "../../../../../store/reducers/Cadastro/Usuario/actions";

import * as controllerUsuarios from "../../../../../controller/controllerUsuarios";

import Step1 from './Steps/Usuario';
import Step2 from './Steps/Acesso';
import Step3 from './Steps/Funcionario';

import { useSelector } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';

var Steps = [
    {
        stepName: "Usuário",
        stepIcon: "tim-icons icon-single-02",
        component: Step1,
        stepProps: {}
    },
    {
        stepName: "Acesso",
        stepIcon: "tim-icons icon-settings-gear-63",
        component: Step2,
        stepProps: {}
    },
    {
        stepName: "Funcionário",
        stepIcon: "tim-icons icon-settings-gear-63",
        component: Step3,
        stepProps: {}
    },
];
const Cadastro = () => {

    const [width, setWidht] = useState(window.innerWidth);

    const inicioCadastro = useShallowEqualSelector<AppState, IUsuarioState['inicioCadastro']>(state => state.usuario.inicioCadastro)
    const currentStep = useShallowEqualSelector<AppState, IUsuarioState['currentStep']>(state => state.usuario.currentStep)
    const proximoCadastro = useShallowEqualSelector<AppState, IUsuarioState['proximoCadastro']>(state => state.usuario.proximoCadastro)
    //@ts-ignore
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);

    useHotkeys('Shift+S', () => {
        if (currentStep === 0) {
            controllerUsuarios.SalvarUsuario(codEmpresa)
        } else if (currentStep === 2) {
            controllerUsuarios.SalvarFuncionario()
        }
    }, [currentStep])

    useHotkeys('Shift+N', () => {

        handleLimparAll()

    })

    useLayoutEffect(() => {
        controllerUsuarios.GetEmpresas()
    })
    const handleLimparAll = () => {

        actions.cleanUsuario()
        actions.cleanAcesso()
        actions.cleanFuncionario()
        actions.setCurrentStep(0)
    }
    const finishButtonClick = async function () {

        let err = await controllerUsuarios.SalvarFuncionario();
        if (!err) {
            handleLimparAll()
        }
        return err
    }

    return (
        <Col lg="12">
            <div style={{ marginTop: '-20px' }}>
                <ReactWizard
                    steps={Steps}
                    navSteps={true}
                    title=""
                    description=""
                    validate
                    resizable={true}
                    //@ts-ignore
                    changeStep={() => { }}
                    previousButtonText="Voltar"
                    finishButtonText="Finalizar"
                    nextButtonText="Avançar"
                    goToFirstStep={inicioCadastro}
                    goToNextStep={proximoCadastro}
                    newCurrentStep={currentStep}
                    setNewCurrentStep={(nextStep: number) => { actions.setCurrentStep(nextStep) }}
                    finishButtonClick={finishButtonClick}
                    finishButtonClasses={classNames("btn-wd btn-info",
                        {
                            finishButtonMin:
                                width < 550
                        },
                        {
                            finishButtonRight:
                                width > 550
                        }
                    )}
                    nextButtonClasses={classNames("btn-wd btn-info",
                        {
                            nextButtonMin:
                                width < 550

                        },
                        {
                            nextButtonRight:
                                width > 550
                        }
                    )}
                    previousButtonClasses={classNames("btn-wd",
                        {
                            previousButtonMin:
                                width < 550
                        },
                        {
                            previousButtonRight:
                                width > 550
                        }
                    )}
                    progressbar
                    color="blue"
                />
            </div>
        </Col>

    )

}

export default Cadastro 