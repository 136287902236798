import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import * as controllerLogin from "~/controller/controllerLogin.js";
import ListEmpresa from "~/components/ListView/ListView";
import classnames from "classnames";
import "react-circular-progressbar/dist/styles.css";
import "react-notification-alert/dist/animate.css";
import useResize from "~/Util/Hooks/useResize";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";

const avatar = "http://admin.ernetwork.com.br/imagens/easyx/face-0.jpg";
const idStore = 'LOGIN';


const Login = () => {
  const login = useSelector(state => state.login);
  const notificacao = useSelector(state => state.notificacao);
  const [loginFocus, setLoginFocus] = useState(false);
  const [senhaFocus, setSenhaFocus] = useState(false);
  const [novaSenhaFocus, setNovaSenhaFocus] = useState(false);
  const [confirmarSenhaFocus, setConfirmarSenhaFocus] = useState(false);
  const dispatch = useDispatch();
  const {width} = useResize();

  useEffect(() => {
    document.body.classList.toggle("login-page");
  }, []);

  function handleKeyPress(event, type) {
    if (event.key === 'Enter') {
      switch (type) {
        case 'login':
          controllerLogin.login(login, notificacao, dispatch);
          break;
        case 'novaSenha':
          controllerLogin.salvarSenha(login, notificacao, dispatch);
          break;
        default:
      }
    }
  }

  function handleOnChange(id, type, campo) {
    var value = document.getElementById(id).value;
    dispatch({ idStore, type, campo, value });
    dispatch({ idStore, type: 'OBJETOS', campo: 'loginInvalido', value: false });
    dispatch({ idStore, type: 'OBJETOS', campo: 'senhaInvalida', value: false });
    dispatch({ idStore, type: 'OBJETOS', campo: 'novaSenhaInvalida', value: false });
    dispatch({ idStore, type: 'OBJETOS', campo: 'confirmarSenhaInvalida', value: false });
  }

  function handleEntrar(e) {
    e.preventDefault();
    controllerLogin.login(login, notificacao, dispatch);
  }

  function handleSalvarSenha(e) {
    e.preventDefault();
    controllerLogin.salvarSenha(login, notificacao, dispatch);
  }

  function handleInicioLogin() {
    controllerLogin.logout(dispatch);
    setTimeout(() => { document.getElementById("login").focus() }, 300);
  }

  return (
    <>
      <div style={width <= 550 ?{position:"relative",top:"55px"}: width <= 1100? { position:"relative",top:"180px", left:"-40px"} :{}} className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form">

              <Card className="card-lock card-white text-center">
                <div id="conteudo" >
                  <div className="bloco">
                    <div id="sombra">
                    </div>
                  </div>
                  <div id="circle">
                    <div className="bloco">
                      <div id="foto1">
                        <img alt="..." className="foto" id="avatar" src={avatar}
                          style={login.usuario.usuarioExiste && login.usuario.foto !== "" ? { marginLeft: '-150px' } : null}
                        />
                      </div>
                    </div>

                    <div className="bloco">
                      <div id="foto2">
                        <img alt="..." className="foto" id="foto" src={login.usuario.foto}
                          style={login.usuario.usuarioExiste && login.usuario.foto !== "" ? { marginLeft: '-300px' } : null}
                        />
                      </div>
                      <div className="bloco">
                        <div id="borda">
                        </div>
                      </div>
                    </div>
                    <div className="bloco">
                      <CircularProgressbar
                        value={login.objetos.percentage1}
                        strokeWidth={4}
                        styles={buildStyles({
                          rotation: 0,
                          strokeLinecap: 'round',
                          pathTransitionDuration: 0.5,
                          pathColor: 'rgba(0, 180, 0, 1)',
                          trailColor: 'rgba(0,0,0,0)',
                        })}
                      />
                    </div>
                    <div className="bloco">
                      <CircularProgressbar
                        value={login.objetos.percentage2}
                        strokeWidth={4}
                        styles={buildStyles({
                          rotation: 0,
                          strokeLinecap: 'round',
                          pathTransitionDuration: 0.5,
                          pathColor: 'rgba(0,117,193,1)',
                          trailColor: 'rgba(0,0,0,0)',
                        })}
                      />
                    </div>
                    <div className="bloco">
                      <CircularProgressbar
                        value={login.objetos.percentage3}
                        strokeWidth={4}
                        styles={buildStyles({
                          rotation: 0,
                          strokeLinecap: 'round',
                          pathTransitionDuration: 0.5,
                          pathColor: 'rgba(0, 180, 0, 1)',
                          trailColor: 'rgba(0,0,0,0)',
                        })}
                      />
                    </div>
                  </div>

                  {login.usuario.usuarioExiste ?
                    <Button className="btn-icon btn-simple btn-round btn-voltar-login group-btn-login" onClick={() => handleInicioLogin()}>
                      <i className={"tim-icons icon-double-left"} />
                    </Button>
                    : null}

                </div>
                <CardBody>
                  <CardTitle tag="h4">
                    {!login.usuario.loginSucesso ?
                      <>{login.usuario.apelido !== "" ? login.usuario.apelido : "Usuário"}</>
                      :
                      <>Olá, {login.usuario.apelido !== "" ? login.usuario.apelido : "Usuário"}</>
                    }
                  </CardTitle>

                  <div style={{ position: 'relative', minHeight: '50px', marginTop: '50px' }}>

                    <InputGroup
                      className={classnames(
                        { "input-group-focus": loginFocus },
                        !login.usuario.usuarioExiste ? 'transicaoLadoLadoIn' : 'transicaoLadoLadoOut',
                        login.objetos.loginInvalido ? 'has-danger' : ''
                      )}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="login"
                        placeholder="Email/CPF"
                        type="text"
                        value={login.usuario.login}
                        onChange={() => handleOnChange('login', 'USUARIO', 'login')}
                        autoFocus={true}
                        onKeyPress={(e) => handleKeyPress(e, 'login')}
                        disabled={!login.usuario.usuarioExiste ? false : true}
                        onFocus={() => setLoginFocus(true)}
                        onBlur={() => setLoginFocus(false)}
                        autoComplete="off"
                      />
                      <label style={{paddingTop: 3}}>Não tem uma conta no easy? 
                        <NavLink to="/" style={{color: '#189ffa', textDecoration: 'underline'}} >
                          Registre-se
                        </NavLink>
                      </label>
                    </InputGroup>

                    <InputGroup
                      className={classnames(
                        { "input-group-focus": senhaFocus },
                        login.usuario.usuarioExiste && !login.usuario.loginSucesso && !(login.objetos.primeiroAcesso || login.objetos.alterarSenha) ? 'transicaoLadoLadoIn' : 'transicaoLadoLadoOut',
                        login.objetos.senhaInvalida ? 'has-danger' : ''
                      )}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="senha"
                        placeholder="Senha"
                        type="password"
                        value={login.usuario.senha}
                        onChange={() => handleOnChange('senha', 'USUARIO', 'senha')}
                        onKeyPress={(e) => handleKeyPress(e, 'login')}
                        onFocus={() => setSenhaFocus(true)}
                        onBlur={() => setSenhaFocus(false)}
                      />
                    </InputGroup>

                    <CardTitle tag="h5" className="tituloNovaSenha justify-content-center" style={login.usuario.usuarioExiste && !login.usuario.loginSucesso && (login.objetos.primeiroAcesso || login.objetos.alterarSenha) ? { opacity: '1' } : null}>
                      {login.objetos.primeiroAcesso ? "Parece que é o seu primeiro acesso. Altere sua senha para começar." : "Você está usando uma senha temporária. Altere para começar."}
                    </CardTitle>

                    <InputGroup
                      className={classnames(
                        { "input-group-focus": novaSenhaFocus },
                        login.usuario.usuarioExiste && !login.usuario.loginSucesso && (login.objetos.primeiroAcesso || login.objetos.alterarSenha) ? 'transicaoLadoLadoIn' : 'transicaoLadoLadoOut',
                        login.objetos.novaSenhaInvalida ? 'has-danger' : ''
                      )}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="novaSenha"
                        placeholder="nova senha"
                        type="password"
                        value={login.objetos.novaSenha}
                        onChange={() => handleOnChange('novaSenha', 'OBJETOS', 'novaSenha')}
                        onKeyPress={(e) => handleKeyPress(e, 'novaSenha')}
                        onFocus={() => setNovaSenhaFocus(true)}
                        onBlur={() => setNovaSenhaFocus(false)}
                      />
                    </InputGroup>
                    <InputGroup
                      className={classnames(
                        { "input-group-focus": confirmarSenhaFocus },
                        login.usuario.usuarioExiste && !login.usuario.loginSucesso && (login.objetos.primeiroAcesso || login.objetos.alterarSenha) ? 'transicaoLadoLadoIn' : 'transicaoLadoLadoOut',
                        login.objetos.confirmarSenhaInvalida ? 'has-danger' : ''
                      )}
                      style={{ marginTop: '45px' }}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="confirmarSenha"
                        placeholder="confirme sua senha"
                        type="password"
                        value={login.objetos.confirmarSenha}
                        onChange={() => handleOnChange('confirmarSenha', 'OBJETOS', 'confirmarSenha')}
                        onKeyPress={(e) => handleKeyPress(e, 'novaSenha')}
                        onFocus={() => setConfirmarSenhaFocus(true)}
                        onBlur={() => setConfirmarSenhaFocus(false)}
                      />
                    </InputGroup>

                    <div
                      className={login.usuario.usuarioExiste && login.usuario.loginSucesso ? 'transicaoLadoLadoIn divEmpresa' : 'transicaoLadoLadoOut divEmpresa'}
                    >
                      <CardTitle tag="h4" style={login.usuario.usuarioExiste && login.usuario.loginSucesso ? { opacity: '1' } : null}>
                        Em qual empresa vamos atuar?
                      </CardTitle>

                      <ListEmpresa
                        list={login.usuario.empresas}
                        element="empresa"
                        text="descEmpresa"
                        onClick={value => {
                          controllerLogin.login(login, notificacao, dispatch, value.codEmpresa, value.codEmpresaFilial)
                          dispatch({ idStore, type: 'USUARIO', campo: 'empresa', value })
                        }}
                      />
                    </div>

                  </div>

                </CardBody>

                <CardFooter style={{ marginTop: '20px' }}>
                  <Row className="justify-content-center">
                    <Button
                      className="btn-round btn-padrao group-btn-login"
                      color="default"
                      onClick={(e) => handleEntrar(e)}
                      style={!login.usuario.loginSucesso && !(login.objetos.primeiroAcesso || login.objetos.alterarSenha) ?
                        {} : { display: 'none' }
                      }
                    >
                      Próximo
                  </Button>
                  </Row>
                  <Row className="justify-content-center">
                    <Button
                      className="btn-round btn-padrao group-btn-login"
                      color="default"
                      onClick={(e) => handleSalvarSenha(e)}
                      style={!login.usuario.loginSucesso && (login.objetos.primeiroAcesso || login.objetos.alterarSenha) ?
                        {} : { display: 'none' }
                      }
                    >
                      Salvar
                    </Button>
                  </Row>
                </CardFooter>

              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  );
}

export default Login;
