export enum RecebimentoPagamentoStore {
    ID_STORE = 'RECEBIMENTO_PAGAMENTO'
}

export enum RecebimentoPagamentoTypes {
    RECEBIMENTOS_PAGAMENTOS = 'RECEBIMENTOS_PAGAMENTOS',
    PESQUISA = 'PESQUISA',
    FORNECEDOR = 'FORNECEDOR',
    CLEAN_FORNECEDOR = 'CLEAN_FORNECEDOR',
    CLEAN_PESQUISA = 'CLEAN_PESQUISA',
    EMPRESAS = 'EMPRESA',
    FORMAS_PAGAMENTO = 'FORMAS_PAGAMENTO',
    COD_EMPRESA_FILIAL = 'COD_EMPRESA_FILIAL',
    CONTAS = "CONTAS",
    LANCAMENTOS = "LANCAMENTOS",
    REGISTRO = "REGISTRO",
    SET_INFORMACAO = "SET_INFORMACAO",
    SET_LANCAMENTO_RAPIDO = "SET_LANCAMENTO_RAPIDO",
    CLEAN_LANCAMENTO_RAPIDO = "CLEAN_LANCAMENTO_RAPIDO",
    SET_INFORMACOES_RECEBIMENTOS_PAGAMENTOS = "SET_INFORMACOES_RECEBIMENTOS_PAGAMENTOS",
    CLEAN_INFORMACOES_RECEBIMENTOS_PAGAMENTOS = "CLEAN_INFORMACOES_RECEBIMENTOS_PAGAMENTOS",
    SET_GRUPOS = "SET_GRUPOS",
    SET_SUB_GRUPOS = "SET_SUB_GRUPOS"
}

export type RecebimentoPagamentoAction = {
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes,
    field?: IRecebimentoPagamentoState['field'],
    pesquisa?: IRecebimentoPagamentoState['pesquisa'],
    recebimentosPagamentos?: IRecebimentoPagamentoState['recebimentosPagamentos'],
    empresas?: IRecebimentoPagamentoState['empresas'],
    fornecedores?: IRecebimentoPagamentoState['fornecedores'],
    formasPagamentos?: IRecebimentoPagamentoState['formasPagamentos'],
    codEmpresaFilial?: IRecebimentoPagamentoState['codEmpresaFilial'],
    contas?: IRecebimentoPagamentoState['contas'],
    lancamentos?: IRecebimentoPagamentoState['lancamentos'],
    registro?: IRecebimentoPagamentoState['registro'],
    informacoes?: IRecebimentoPagamentoState['informacoes'],
    lancamentoRapido?: IRecebimentoPagamentoState['lancamentoRapido'],
    informacoesRecebimentosPagamentos?: IRecebimentoPagamentoState['informacoesRecebimentosPagamentos'],
    grupos?: IRecebimentoPagamentoState['grupos'],
    subGrupos?: IRecebimentoPagamentoState['subGrupos']
}

export interface IRecebimentoPagamentoState {
    pesquisa: IPesquisa | any,
    recebimentosPagamentos: IRecebimentoPagamento[] | any,
    empresas: IEmpresa[] | any,
    fornecedores: IFornecedor[] | any,
    formasPagamentos: IFormasPagamento[] | undefined,
    codEmpresaFilial: ICodEmpresaFilial[] | undefined,
    contas: IContas[] | undefined,
    lancamentos: any[],
    registro: IRegistro | undefined,
    informacoes?: any, // TODO: Tipar as informações
    lancamentoRapido: ILancamentoRapido,
    field?: string,
    informacoesRecebimentosPagamentos?: IInformacoesRecebimentosPagamentos | any,
    grupos?: IGrupo[],
    subGrupos?: ISubGrupo[]
}

interface IRecebimentoPagamento {
    nDoc: string,
    nome: string,
    grupo: string,
    subgrupo: string,
    parcela: string,
    valor: string,
    valorPg: string,
    formaPagamento: string,
    referencia: string,
    vencimento: string,
    pagamento: string,
    status: string,
    tipo: string
}

interface IPesquisa {
    nomeFor: string,
    cpfCnpj: string,
    dataInicio: string,
    dataFim: string,
    codEmpresaFilial: number,
    codCliente: number,
    cliente: string,
    tipoPesq: string,
    textPesq: string,
    status: string,
    titulo: string,
    tipoData: string,
    codGrupoConta: number,
    codSubGrupoConta: number,
}

interface IEmpresa {
    codEmpresaFilial: number,
    descricaoEmpresa: string,
}

interface IFornecedor {
    cpfCnpj: string,
    nomeRazao: string,
    tipoPessoa: string,
    tipo: string,
}

interface IFormasPagamento {
    codForma: number,
    codTipoPgto: number,
    descricao: string,
    descricaoTipo: string,
    diasPrazo: number,
    flAbreCaixa: number,
    flBaixaAutomatica: number,
    flaVista: number,
    flretirada: number,
    percentualTarifa: number,
    qtdDiasEntreParcelas: number,
    status: number,
}

interface ICodEmpresaFilial {
    codEmpresaFilial: number,
}

interface IContas {
    codConta: number,
    descricao: string,
    flBaixaTitulo: number,
    flEntradaMerc: number,
    flLancTitulo: number,
    flPedido: number,
    status: number,
}

interface IRegistro {

}

interface ILancamentoRapido {
    codigoForma: number,
    codigoCaixa: number,
    codigoConta: number,
    numeroLancamento?: number,
    dataLancamento?: string,
    valorLancamento: number,
    valorJuros?: number,
    valorMulta?: number,
    valorDesconto?: number,
    valorTarifa?: number,
    valorTotal: number,
    imagemDocumento?: string
}
export interface ILancamento {
    codigoTituloMovimento: number,
    titulosLancamentos: ILancamentoRapido[]
}

export interface ILancamentoCompleto {
    codigoLancamento: number,
    formaPagamento: string,
    dataLancamento: string,
    valorTitulo: number,
    juros: number,
    multa: number,
    desconto: number,
    totalPago: number
}
export interface IInformacoesRecebimentosPagamentos {
    typeAction: string,
    codigoConta: number,
    codigoCaixa: number,
    valorLancar: number,
    valorTitulo: number,
    valorMulta: number,
    valorJuros: number,
    valorDesconto: number,
    valorTarifa: number,
    dataLancamento: string,
    totalRestante: number,
    lancamentos: ILancamentoCompleto[]
    valorReais: {
        valorMultaReais: number,
        valorJurosReais: number
    }
}

interface IGrupo {
    codGrupo: string,
    nome: string
}

interface ISubGrupo {
    codSubgrupo: string,
    nome: string
}