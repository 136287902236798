import React from 'react'

import {
    Col,
    Row,
    FormGroup,
} from "reactstrap";
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";

function Observacoes() {
    const verCadastro = useShallowEqualSelector(state => state.entradaMercadoria.visualizarEntrada);
    return (
        <>
            <Row>
            <div className= {verCadastro ? 'ver-cadastro' : ''}></div>
                <Col>
                    <label>Informções Adicionais</label>
                    <FormGroup>
                        <textarea class="form-control" style={{width: "100%", height: "250%"}}></textarea>
                    </FormGroup>
                </Col>
            </Row>
        </>
    )
}

export default Observacoes;