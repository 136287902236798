import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import * as controllerPerfilUsuario from "~/controller/controllerPerfilUsuario";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import { InputCpfCnpj } from "~/components/ErnComponets/ErnComponets";
import { MiniMenu } from "~/components/ErnComponets/ErnComponets"

import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'

import {
    Card,
    Col,
    Button,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Row,
    Modal,
    ModalBody,
    FormGroup,
} from "reactstrap";

// wizard steps
import Step1 from "~/views/GrupoContas/GrupoContaSteps/GrupoConta";
import useResize from "~/Util/Hooks/useResize";
import useShallowEqualSelector from "../../Util/Hooks/useShallowEqualSelector";

var steps = [
    {
        stepName: "Grupo de Contas",
        stepIcon: "tim-icons icon-notes",
        component: Step1,
        stepProps: {}
    },
];
const idStore = 'PERFIL_USUARIO';

const PerfilUsuario = () => {
    const notificacao = useShallowEqualSelector(state => state.notificacao);
    const { pesquisa, cadastro, senha, invalido } = useShallowEqualSelector(state => state.perfilUsuario);
    const dispatch = useDispatch();
    const { width } = useResize()
    const [modalSalvarPerfil, setModalSalvarPerfil] = useState(false);

    useEffect(() => {
        steps.forEach(step => {
            step.stepProps = { ...step.stepProps, notificacao }
        });
    }, [notificacao])

    useEffect(() => {
        controllerPerfilUsuario.pesquisar(dispatch)
    }, [])

    function onChangeApelido(e, stateName) {
        dispatch({ idStore, type: 'CADASTRO_APELIDO', campo: stateName, value: e })
    }

    function onChangeSenha(e, stateName) {
        dispatch({ idStore, type: 'CLEAN_INVALIDO', campo: 'senha', value: false })
        dispatch({ idStore, type: 'SENHA', campo: stateName, value: e })
    }

    function SetImgPerfil(value) {
        dispatch({ idStore, type: 'CADASTRO_IMAGEM', campo: 'imagem', value: value })
    }

    function handleLimpar() {
        dispatch({ idStore, type: 'CLEAN_SEARCH' })
        dispatch({ idStore, type: 'CLEAN_SENHA' })
        dispatch({ idStore, type: 'CLEAN_INVALIDO' })
    }

    function salvar() {
        controllerPerfilUsuario.update(dispatch, cadastro, pesquisa, senha)
        
    }

    function handleSalvar() {


        setModalSalvarPerfil(!modalSalvarPerfil);

    }

    return (

        <>
            <div className="content" >
                <Col className="mr-auto ml-auto" md="11">
                    <Card style={{ minHeight: 600 }}>
                        <CardHeader>
                            <CardTitle style={{marginBottom:"0"}} tag="h3">Perfil</CardTitle>
                        </CardHeader>

                        <CardBody>

                            <Row className="padding-padrao-row" style={{ alignItems: 'center', justifyContent: 'center' }}>

                                <Col md="4"></Col>

                                <Col md="4">

                                    <Row style={{ justifyContent: 'center' }}>
                                        <ImageUpload
                                            changeBtnClasses="btn-simple btn-padrao"
                                            addBtnClasses="btn-simple"
                                            removeBtnClasses="btn-simple btn-padrao"
                                            changeBtnColor="info"
                                            addBtnColor="info"
                                            avatar
                                            onChange={SetImgPerfil}
                                            imagem={cadastro.imagem}
                                        />
                                    </Row>

                                </Col>


                                <Col md="4" >

                                    <Row style={{ justifyContent: 'flex-start' }}>
                                        <MiniMenu></MiniMenu>
                                    </Row>

                                </Col>

                            </Row>

                            <Row className="padding-padrao-row" style={{ display: 'flex', justifyContent: 'center' }}>
                                <Col className="padding-padrao-col" md="7">
                                    <Row className="padding-padrao-row" style={{ display: 'flex', justifyContent: 'center' }}>

                                        <Col className="padding-padrao-col" md="7">
                                            <label>Nome <span className="error">*</span></label>
                                            <Input
                                                name="Nome"
                                                type="text"
                                                value={pesquisa.nome}
                                                disabled={true}
                                                maxLength="90"
                                            />
                                        </Col>
                                        <Col className="padding-padrao-col" md="5">
                                            <label>CPF <span className="error">*</span></label>
                                            <FormGroup>
                                                <InputCpfCnpj
                                                    name="CPF"
                                                    tipo="cpf"
                                                    value={pesquisa.cpf}
                                                    disabled={true}
                                                    maxLength="80"
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    <Row className="padding-padrao-row" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Col className="padding-padrao-col" md="5">
                                            <label>Apelido</label>
                                            <Input
                                                name="Apelido"
                                                type="text"
                                                maxLenght="15"
                                                value={cadastro.apelido}
                                                onChange={e => onChangeApelido(e.target.value, "apelido")}
                                            />
                                        </Col>
                                        <Col className="padding-padrao-col" md="7">
                                            <label>E-mail pessoal<span className="error">*</span></label>
                                            <FormGroup>
                                                <Input
                                                    name="email"
                                                    type="text"
                                                    value={pesquisa.email}
                                                    disabled={true}
                                                    maxLength="60"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                    <Row className="padding-padrao-row" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Col className="padding-padrao-col" md="4">
                                            <label>Senha atual</label>
                                            <FormGroup className={invalido.senha ? "has-danger" : ''}>
                                                <Input
                                                    name="senhaAtual"
                                                    type="password"
                                                    value={senha.senhaAtual}
                                                    onChange={e => onChangeSenha(e.target.value, "senhaAtual")}
                                                    maxLength="60"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="padding-padrao-col" md="4">
                                            <label>Nova senha<span className={senha.senhaAtual !== "" ? "error" : ''}>*</span></label>
                                            <FormGroup className={invalido.novaSenha ? "has-danger" : ''}>
                                                <Input
                                                    name="novaSenha"
                                                    type="password"
                                                    value={senha.senhaAtual === "" ? "" : senha.novaSenha}
                                                    onChange={e => onChangeSenha(e.target.value, "novaSenha")}
                                                    disabled={senha.senhaAtual === "" ? true : false}
                                                    maxLength="60"
                                                />
                                                <Col style={{ justifyContent: 'center' }}>
                                                    <Row style={{ justifyContent: 'flex-start' }}>
                                                        <span>
                                                        </span>
                                                    </Row>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col className="padding-padrao-col" md="4">
                                            <label>Confirmar senha<span className={senha.novaSenha !== "" ? "error" : ''}>*</span></label>
                                            <FormGroup className={invalido.confirmaSenha ? "has-danger" : ''}>
                                                <Input
                                                    name="confirmarSenha"
                                                    type="password"
                                                    value={senha.novaSenha === "" ? "" : senha.confirmaSenha}
                                                    onChange={e => onChangeSenha(e.target.value, "confirmaSenha")}
                                                    disabled={senha.novaSenha === "" ? true : false}
                                                    maxLength="60"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>



                            <Row>
                                <CardBody >
                                    <Col style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <ArrowTooltip title='Shift+L'>
                                            <Button
                                                color='info'
                                                style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                                onClick={() => handleLimpar()}
                                            >Limpar</Button>
                                        </ArrowTooltip>
                                        <ArrowTooltip title='Shift+P'>
                                            <Button
                                                color='info'
                                                style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                                onClick={() => handleSalvar()}
                                            >Salvar</Button>
                                        </ArrowTooltip>
                                    </Col>
                                </CardBody>
                            </Row>

                        </CardBody>
                    </Card>
                </Col>
            </div >


            {/* Modal Salvar*/}
            <Modal
                isOpen={modalSalvarPerfil}
            >
                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={handleSalvar}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Editar perfil</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>Tem certeza que deseja <b>editar</b> o perfil?</label>
                    </Col>

                </ModalBody>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { handleSalvar() }}
                                style={{ width: '150px' }}
                            >NÃO
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { handleSalvar(); salvar() }}
                                style={{ width: '150px' }}
                            >SIM
                                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Modal>
            {/* Modal Salvar */}

        </>
    );


}

export default PerfilUsuario;
