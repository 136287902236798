import axios from 'axios';
import api from "../Util/api.js";
import { alertar } from "../Util/alertar";
import { Round } from '../Util/numbers';

import store from '../store/stores'

import * as actions from "../store/reducers/Cadastro/Servico/actions";

export function Pesquisar() {
    //@ts-ignore
    const { notificacao } = store.getState()
    const { pesquisa } = store.getState().servico
    try {
        actions.cleanServicos()

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/servico/servicos?nome=${pesquisa.nome}&numeroServico=${pesquisa.numeroServico}&codGrupo=${pesquisa.codGrupo}&codSubgrupo=${pesquisa.codSubgrupo}&status=${pesquisa.ativos}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum serviço encontrado", 'info', 'icon-bulb-63', 2)
            }

            actions.setServicos(response.data.records)

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'danger', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os serviço", 'danger', 'icon-alert-circle-exc', 2)
    }
}


export async function GetServico(codServico: any, verCadastro: boolean) {
    //@ts-ignore
    const { notificacao } = store.getState()
    const { servico } = store.getState().servico
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios.get(api.urlCadastro + `api/v1/servico/servico?codServico=${codServico}`, {

            headers: { Authorization: basicAuth }

        }).then(async function (response: any) {

            actions.setServico({
                ...servico,
                numeroServico: response.data.records.numeroServico,
                codServico: response.data.records.codServico,
                codGrupo: response.data.records.codGrupo,
                grupo: response.data.records.grupo,
                codSubgrupo: response.data.records.codSubgrupo,
                subgrupo: response.data.records.subgrupo,
                descricao: response.data.records.descricao,
                nome: response.data.records.nome,
                obs: response.data.records.obs,
                status: response.data.records.status,
                valor: response.data.records.valor.toFixed(2)
            })

            actions.setServicoCopia()

            actions.setVerCadastro(verCadastro)

            await GetSubgrupos(response.data.records.codGrupo, false)

            actions.setScreen(1)

        }).catch(e => {

            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'danger', 'icon-alert-circle-exc', 2, e)

        })

    } catch (e) {

        alertar(notificacao, 'br', "Não foi possível obter o serviço", 'danger', 'icon-alert-circle-exc', 2)

    }

}

export async function CreateServico() {

    //@ts-ignore
    const { notificacao } = store.getState()
    const { servico, invalido } = store.getState().servico
    try {
        //Retorna se houve erro ou nao
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        if (servico.nome === '') {
            actions.setInvalido({ ...invalido, nome: true })
            alertar(notificacao, 'br', "Nome inválido", 'warning', 'icon-alert-circle-exc', 2);
            return false
        }

        if (servico.codGrupo === "0") {
            alertar(notificacao, 'br', "Selecione o grupo", 'warning', 'icon-alert-circle-exc', 2);
            return false
        }

        if (servico.codSubgrupo === "0") {
            alertar(notificacao, 'br', "Selecione o subgrupo", 'warning', 'icon-alert-circle-exc', 2);
            return false
        }

        if (servico.descricao === '') {
            actions.setInvalido({ ...invalido, descricao: true })
            alertar(notificacao, 'br', "Descrição inválida", 'warning', 'icon-alert-circle-exc', 2);
            return false
        }

        let valor = parseFloat(Round(servico.valor, 2).replace(',', '.'));

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/servico/createServico`,
            headers: { Authorization: basicAuth },
            data: {
                NumeroServico: servico.numeroServico,
                CodGrupo: servico.codGrupo,
                CodSubgrupo: servico.codSubgrupo,
                Nome: servico.nome,
                Descricao: servico.descricao,
                Valor: valor,
                Obs: servico.obs
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)

            actions.setServico({
                ...servico, codServico: response.data.records.codServico,
                numeroServico: response.data.records.numeroServico
            })
            actions.setServicoCopia()

            return true

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)
            return false
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return false
    }
}

export async function UpdateServico() {
    //@ts-ignore
    const { notificacao } = store.getState()
    const { servico, servicoCopia, invalido } = store.getState().servico
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(servico) === JSON.stringify(servicoCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return true
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        if (servico.nome === '') {
            actions.setInvalido({ ...invalido, nome: true })
            return false


        }
        if (servico.descricao === '') {
            actions.setInvalido({ ...invalido, descricao: true })
            return false

        }

        if (servico.codGrupo === "0") {
            alertar(notificacao, 'br', "Selecione o grupo", 'warning', 'icon-alert-circle-exc', 2);
            return false

        }

        if (servico.codSubgrupo === "0") {
            alertar(notificacao, 'br', "Selecione o subgrupo", 'warning', 'icon-alert-circle-exc', 2);
            return false

        }

        let valor = parseFloat(Round(servico.valor, 2).replace(',', '.'));

        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/servico/updateServico?codServico=${servico.codServico}`,
            headers: { Authorization: basicAuth },
            data: {
                NumeroServico: servico.numeroServico,
                CodGrupo: servico.codGrupo,
                CodSubgrupo: servico.codSubgrupo,
                Nome: servico.nome,
                Descricao: servico.descricao,
                Valor: valor,
                Obs: servico.obs,
                status: servico.status
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            actions.setServico({ ...servico, codServico: response.data.records })
            actions.setServicoCopia()
            return true
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)
            return false
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return false
    }
}

export function Inativar(produto: any) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/servico/inativarServico?codProduto=${produto.codProduto}&status=${produto.status}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)

        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)

    }
}

export function Excluir(servico: any) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/produto/excluirProduto?codProduto=${servico.codProduto}&tipo=S`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetGrupos(thenFunction: Function) {
    //@ts-ignore
    const { notificacao } = store.getState()

    try {
        actions.cleanGrupos()

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios.get(api.urlCadastro + `api/v1/grupoProduto/grupos?status=0&tipo=S`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum grupo encontrado", 'info', 'icon-bulb-63', 2)
                thenFunction()
            }
            actions.setGrupos(response.data.records)
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os grupos", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetSubgrupos(codGrupo: any, pesquisa: boolean) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {

        actions.cleanCadSubGrupos()

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios.get(api.urlCadastro + `api/v1/subgrupoProduto/subgrupos?codGrupo=${codGrupo}&status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum subgrupo encontrado", 'info', 'icon-bulb-63', 2)
            }
            if (pesquisa) {
                actions.setPesqSubGrupos(response.data.records)
            } else {
                actions.setCadSubGrupos(response.data.records)
            }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os grupos", 'danger', 'icon-alert-circle-exc', 2)
    }
}