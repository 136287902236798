import { Reducer } from 'redux'
import { InventarioAction, IInventarioState, InventarioTypes } from "./types";

//State da tela de Pesquisa e cadastro de INVENTARIOs
const INITIAL_STATE: IInventarioState = {
    pesquisa: {
        codigoDescricao: '',
        codigoGrupo: '0',
        descricaoGrupo: '',
        codigoSubGrupo: '0',
        descricaoSubGrupo: '',
        quantidade: '',
        codFilial: null,
        dataInicio: new Date().toISOString().substr(0, 10),
        dataFinal: new Date().toISOString().substr(0, 10),
        codigoUser: null,
        nome: '',
        cpf: ''
    },
    gradeSelecionada: {
        codigoProduto: 0,
        codigoTipoGrade: 0,
        quantidadeAtualizar: 0
    },
    modalFuncionarios: {
        cpf: '',
        nomeEmail: '',
        codUser: ''
    },
    listGrupo: [],
    listSubGrupo: [],
    listItens: [],
    listItensPesquisa: [],
    listAtualizacao: [],
    listGrade: [],
    listGradeCopia: [],
    screen: 0,
    empresas: [],
    funcionarios: [],
    listItensInventario: [],
    listGradePesquisa: []
};

const inventario: Reducer<IInventarioState, InventarioAction> = (state = INITIAL_STATE, action: InventarioAction) => {
    switch (action.idStore) {
        case 'INVENTARIO':
            switch (action.type) {
                case InventarioTypes.CLEAN_ALL:
                    return { ...INITIAL_STATE }

                case InventarioTypes.CLEAN_PESQUISA:
                    return {
                        ...state, pesquisa: {
                            ...state.pesquisa,
                            codigoDescricao: '',
                            codigoGrupo: '0',
                            descricaoGrupo: '',
                            codigoSubGrupo: '0',
                            descricaoSubGrupo: '',
                            quantidade: '',
                            dataInicio: new Date().toISOString().substr(0, 10),
                            dataFinal: new Date().toISOString().substr(0, 10),
                            codigoUser: null,
                            nome: '',
                            cpf: ''
                        }, listItensPesquisa: [], listItensInventario: []
                    }

                case InventarioTypes.LIST_ITENS_INVENTARIO:
                    if (action.listItensInventario)
                        return { ...state, listItensInventario: action.listItensInventario }
                    else
                        return { ...state }

                case InventarioTypes.PESQUISA:
                    if (action.pesquisa)
                        return { ...state, pesquisa: action.pesquisa }
                    else
                        return { ...state }

                case InventarioTypes.LIST_ITENS:
                    if (action.listItens)
                        return { ...state, listItens: action.listItens }
                    else
                        return { ...state }

                case InventarioTypes.LIST_ITENS_PESQUISA:
                    if (action.listItensPesquisa)
                        return { ...state, listItensPesquisa: action.listItensPesquisa }
                    else
                        return { ...state }

                case InventarioTypes.LIST_ATUALIZAR:
                    if (action.listAtualizacao)
                        return { ...state, listAtualizacao: action.listAtualizacao }
                    else
                        return { ...state }

                case InventarioTypes.LIST_GRUPO:
                    if (action.value)
                        return { ...state, listGrupo: action.value }
                    else
                        return { ...state }

                case InventarioTypes.LIST_SUBGRUPO:
                    if (action.value)
                        return { ...state, listSubGrupo: action.value }
                    else
                        return { ...state }

                case InventarioTypes.LIST_GRADE:
                    if (action.listGrade)
                        return { ...state, listGrade: action.listGrade }
                    else
                        return { ...state }

                case InventarioTypes.LIST_GRADE_PESQUISA:
                    if (action.listGradePesquisa)
                        return { ...state, listGradePesquisa: action.listGradePesquisa }
                    else
                        return { ...state }

                case InventarioTypes.LIST_GRADE_COPIA:
                    if (action.listGradeCopia)
                        return { ...state, listGradeCopia: action.listGradeCopia }
                    else
                        return { ...state }

                case InventarioTypes.GRADE_SELECIONADA:
                    if (action.gradeSelecionada)
                        return { ...state, gradeSelecionada: action.gradeSelecionada }
                    else
                        return { ...state }

                case InventarioTypes.SCREEN:
                    return { ...state, screen: action.screen }

                case InventarioTypes.SET_EMPRESAS:
                    return { ...state, empresas: action.empresas }

                case InventarioTypes.FUNCIONARIOS:
                    return { ...state, funcionarios: action.funcionarios }

                case InventarioTypes.MODAL_FUNCIONARIOS:
                    return { ...state, modalFuncionarios: action.modalFuncionarios }

                case InventarioTypes.CLEAN_MODAL_FUNCIONARIO:
                    return {
                        ...state, modalFuncionarios: INITIAL_STATE.modalFuncionarios,
                        funcionarios: INITIAL_STATE.funcionarios
                    }
            }
        default:
            return { ...state }
    }
}

export default inventario;