import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { useDispatch } from 'react-redux';
import {
    Col,
    Button,
    CardBody,
    Input,
    Row,
    FormGroup,
    Label
} from "reactstrap";
import useResize from "~/Util/Hooks/useResize";
import store from '~/store/stores';
import { useSelector, shallowEqual } from 'react-redux';
import * as controllerEntrada from '../../../../../controller/Movimentacao/controllerEntradaMercadoria';
import MultiSelect from '../../../../../components/MultiSelect/MultiSelect';
import ArrowTooltip from '../../../../../components/Tooltip/ArrowTooltip';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import useModal from "../../../../../Util/Hooks/useModal";
import ModalFornecedor from "../../Modals/ModalPessoaFornecedor"
import { PopUpDelete } from '../../Modals/PopUps'
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import { maskMoeda } from "../../../../../Util/mascaras";

const idStore = "ENTRADA_MERCADORIA";

const Pesquisar = function () {
    return (
        <Col lg="15">
            <Filtros />
            <DataTable />
        </Col>
    );
}

function Filtros() {
    const { width } = useResize()
    const pesquisa = useSelector(state => state.entradaMercadoria.pesquisa, shallowEqual)
    const empresas = useShallowEqualSelector(state => state.entradaMercadoria.empresas)
    const { codEmpresaFilial } = useSelector(state => state.login.usuario.empresa);
    const [modalPesquisa, toggleModalFornecedor] = useModal(false);

    useEffect(() => {
        controllerEntrada.GetEmpresasUser()
        var data = new Date()
        var mes = data.getMonth() + 1 < 10 ? `0${data.getMonth() + 1}` : data.getMonth() + 1
        var dia = data.getDate() < 10 ? `0${data.getDate() + 1}` : data.getDate()

        store.dispatch({ idStore, type: 'PESQUISA', campo: 'dataInicio', value: `${data.getFullYear()}-${mes}-${dia}` })
        store.dispatch({ idStore, type: 'PESQUISA', campo: 'dataFim', value: `${data.getFullYear()}-${mes}-${dia}` })
    }, [])
    useEffect(() => {
        let filial = []
        empresas.forEach((empresa) => {
            if (empresa.codEmpresaFilial === codEmpresaFilial) {
                filial.push(empresa.codEmpresaFilial)
            }
        })
        store.dispatch({ idStore, type: 'PESQUISA', campo: 'codFilial', value: filial })
    }, [empresas])
    function pesquisaFornecedores(pesquisaPessoaFornecedor) {
        controllerEntrada.GetFornecedores(pesquisaPessoaFornecedor);
    }

    function confirmarFornecedor(codigoTransportadora) {
        controllerEntrada.GetPessoaFornecedor(codigoTransportadora, toggleModalFornecedor);

    }

    function cleanFornecedores() {
        const dispatch = useDispatch()
        dispatch({ idStore, type: 'CLEAN_FORNECEDORES' });
    }

    function handleOnChange(value, stateName) {
        store.dispatch({ idStore, type: 'PESQUISA', campo: stateName, value })
    }
    function handleOnChangeEmpresas(value) {
        store.dispatch({ idStore, type: 'PESQUISA', campo: 'codFilial', value: value })
    }

    function handleOnChangeTipoRegistro(value) {
        store.dispatch({ idStore, type: 'PESQUISA', campo: 'tipoData', value })
    }

    function handlePesquisar() {
        controllerEntrada.GetEntradas(pesquisa)
    }

    function handleLimpar() {
        store.dispatch({ idStore, type: 'CLEAN_SEARCH' })
        store.dispatch({ idStore, type: 'PESQUISA', campo: 'codFilial', value: [codEmpresaFilial] })
    }

    return (
        <>
            <Row className="padding-padrao-row">
                <Col className="padding-padrao-col" lg={7}>
                    <Row className="padding-padrao-row">
                        <Col className="padding-padrao-col" lg="6">
                            <label>Empresa / Filial</label>
                            <MultiSelect
                                values={pesquisa.codFilial}
                                select={{ value: 'codEmpresaFilial', label: 'descricaoEmpresa' }}
                                onChange={(values) => handleOnChangeEmpresas(values)}
                                options={empresas}
                                disabled={empresas.length > 1 ? false : true}
                            />
                        </Col>
                        <Col className="padding-padrao-col" lg="6" >
                            <label>Num. Nota Fiscal</label>
                            <Input
                                id="numDocumento"
                                type="text"
                                autoComplete="off"
                                value={pesquisa.numDocumento}
                                onChange={e => handleOnChange(e.target.value, 'numDocumento')}
                                maxLength="11"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col className="padding-padrao-col" >
                    <label>Data Início</label>
                    <Input
                        id="dataInicio"
                        type="date"
                        value={pesquisa.dataInicio}
                        onChange={e => handleOnChange(e.target.value, 'dataInicio')}
                    />
                </Col>
                <Col className="padding-padrao-col">
                    <label>Data Fim</label>
                    <Input
                        id="dataFim"
                        type="date"
                        value={pesquisa.dataFim}
                        onChange={e => handleOnChange(e.target.value, 'dataFim')}
                    />
                </Col>
            </Row>
            <Row className="padding-padrao-row">
                <Col className="padding-padrao-col" lg={3}>
                    <label>CPF / CNPJ</label>
                    <Input
                        type="text"
                        autoComplete="off"
                        value={pesquisa.cpfCnpj}
                        onChange={e => { }}
                        onKeyPress={e => { }}
                    />
                </Col>

                <Button
                    style={{ marginTop: '25px', width: '40px', marginBottom: '20px' }}
                    type='button'
                    color="info"
                    onClick={toggleModalFornecedor}
                >
                    <Icon icon="search" className="fa-lg" />
                </Button>

                <Col className="padding-padrao-col" lg={6}>
                    <label>Nome / Razão Social</label>
                    <Input
                        type="text"
                        autoComplete="off"
                        value={pesquisa.fornecedor}
                        disabled={true}
                    />
                </Col>
                <Col className={width > 768 ? "text-left" : ''}>
                    <FormGroup check className="form-check-radio" >
                        <Label check style={{ paddingRight: '5px' }}>
                            <Input type="radio"
                                onChange={() => handleOnChangeTipoRegistro(0)}
                                checked={pesquisa.tipoData === 0 ? true : false}
                            />
                            <span className="form-check-sign" />Cadastro
                                                </Label>
                        <Label check style={{ paddingRight: '5px' }}>
                            <Input type="radio"
                                onChange={() => handleOnChangeTipoRegistro(1)}
                                checked={pesquisa.tipoData === 1 ? true : false}
                            />
                            <span className="form-check-sign" />Nota Fiscal
                                                </Label>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <CardBody>
                    <Col style={{ width: '100%' }}>
                        <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={e => handleLimpar()}
                        >Limpar</Button>
                        <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={() => handlePesquisar()}
                        >Pesquisar</Button>
                    </Col>
                </CardBody>
            </Row>

            {modalPesquisa ?
                <ModalFornecedor
                    isOpen={true}
                    toggle={toggleModalFornecedor}
                    getPessoaFornecedor={pesquisaFornecedores}
                    confirmModal={confirmarFornecedor}
                    clean={cleanFornecedores} /> :
                null
            }

        </>
    );
}

const DataTable = () => {
    const entradas = useSelector(state => state.entradaMercadoria.entradas)
    const [numeroEntrada, setnumeroEntrada] = useState(0);
    const [isOpen, togglePopUp] = useModal(false);

    function handleEditar(entrada) {
        controllerEntrada.GetEntrada(entrada.numeroEntrada)
        store.dispatch({ idStore, type: 'VISUALIZAR', value: entrada.status === 2 ? true : false })
    }
    function excluir(id) {
        setnumeroEntrada(id)
        togglePopUp()
    }

    return (
        <>
            {
                entradas && entradas.length > 0 ?
                    <>
                        {entradas.forEach(entrada => {
                            entrada.valorTotal = Number(entrada.valorTotal).toFixed(2)
                        })}
                        <div style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' }} >
                            <ReactTable
                                data={entradas}
                                filterable={false}
                                columns={
                                    [
                                        {
                                            Header: "Num. Entrada",
                                            accessor: "numeroEntrada"
                                        },
                                        {
                                            Header: "Data Cadastro",
                                            accessor: "dataCadastro"
                                        },
                                        {
                                            Header: "Pessoa/Fornecedor",
                                            accessor: "nomeFornecedor"
                                        },
                                        {
                                            Header: "Num. do Documento",
                                            accessor: "numeroDocumento"
                                        },
                                        {
                                            Header: "Data do Documento",
                                            accessor: "dataDocumento",
                                        },
                                        {
                                            Header: "Total",
                                            accessor: "valorTotal",
                                            Cell: (props) => maskMoeda(props.original.valorTotal)
                                        },
                                        {
                                            Header: "",
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false
                                        },
                                        {
                                            Header: "",
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false,
                                            Cell: (props) => (
                                                <>
                                                    <ArrowTooltip title={props.original.status === 2 ? "Visualizar" : "Editar"}>
                                                        <Button
                                                            onClick={() => handleEditar(props.original)}
                                                            size="sm"
                                                            className={"btn-icon btn-link"}
                                                            color={props.original.status === 2 ? "#483D8B" : "success"}
                                                        >
                                                            <Icon icon={props.original.status === 2 ? "eye" : "edit"} className="fa-lg" />
                                                        </Button>
                                                    </ArrowTooltip>

                                                    <ArrowTooltip title="Excluir">
                                                        <Button
                                                            onClick={() => excluir(props.original.numeroEntrada)}
                                                            className="btn-icon btn-link"
                                                            color="danger"
                                                        >
                                                            <Icon icon="trash-alt" className="fa-lg" />
                                                        </Button>
                                                    </ArrowTooltip>
                                                </>
                                            )
                                        }
                                    ]
                                }
                                minRows={1}
                                showPaginationTop={false}
                                className="-striped -highlight"
                                noDataText=""
                                showPaginationBottom={true}
                                showPageSizeOptions={true}
                                previousText="Anterior"
                                nextText="Próximo"
                                loadingText="Carregando"
                                pageText="Página"
                                ofText="de"
                                rowsText='linhas'
                            />
                        </div>
                        <PopUpDelete isOpen={isOpen} callback={() => controllerEntrada.ExcluirEntrada(numeroEntrada, togglePopUp)} toggle={togglePopUp} />
                    </>
                    :
                    null
            }
        </>
    )
}

export default Pesquisar;