//State da tela de Pesquisa e cadastro de grades
var INITIAL_STATE = {
    pesquisa: {
        descricao: '',
        cfopNfe: '',
        cfopCf: '',
        uf: [],
        ativos: '',
    },
    classificacao: {
        codClassificacao: 0,
        descricao: '',
        status: 0,
    },
    invalido: {
        descricao: false,
    },
    classificacoes: [],
    classificacaoCopia: {},

    tributacao: {
        codTributacao: 0,
        uf: [],
        codCFOPNFE: '0',
        codCFOPCF: '0',
        codICMS: '0',
        codPISCOFINS: '0',
        codIPI: '0',
        codEnquadramentoIPI: '0',
        percICMS: '0,00',
        percICMSST: '0,00',
        percIPI: '0,00',
        percPIS: '0,00',
        percPISST: '0,00',
        percCOFINS: '0,00',
        percCOFINSST: '0,00',
        percICMSEfetivo: '0,00',
        percICMSEfetivoST: '0,00', 
        percICMSRetidoST: '0,00', 
        percICMSCredito: '0,00', 
        percICMSReducao: '0,00',
        percICMSReducaoST: '0,00',
        percICMSDiferimento: '0,00',
        percFundPobreza: '0,00',
        ICMSEfetivo: '0,00',
        status: 0,
    },
    tributacoes: [],
    tributacaoCopia: {},
    CFOPs: [],
    ICMSs: [],
    IPIs: [],
    PISCOFINSs: [],
    enquadramentos: [],
    inicioCadastro: false,
    verCadastro: false,
};

export default function classificacoesTributarias(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'CLASSIFICACOES_TRIBUTARIAS':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'CLASSIFICACOES':
                    return { ...state, classificacoes: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: INITIAL_STATE.pesquisa,
                        classificacoes: INITIAL_STATE.classificacoes
                    }
                case 'CLASSIFICACAO':
                    return { ...state, classificacao: { ...state.classificacao, [action.campo]: action.value } }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'CLASSIFICACAO_COPIA':
                    return { ...state, classificacaoCopia: state.classificacao }
                case 'CLASSIFICACAO_COPIA_STATUS':
                    return { ...state, classificacaoCopia: { ...state.classificacao, status: action.value } }
                case 'TRIBUTACOES':
                    return { ...state, tributacoes: action.value }
                case 'TRIBUTACAO':
                    return { ...state, tributacao: { ...state.tributacao, [action.campo]: action.value } }
                case 'TRIBUTACAO_COPIA':
                    return { ...state, tributacaoCopia: state.tributacao }
                case 'CFOP':
                    return { ...state, CFOPs: action.value }
                case 'ICMS':
                    return { ...state, ICMSs: action.value }
                case 'IPI':
                    return { ...state, IPIs: action.value }
                case 'PISCOFINS':
                    return { ...state, PISCOFINSs: action.value }
                case 'ENQUADRAMENTOS':
                    return { ...state, enquadramentos: action.value }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value }
                case 'CLEAN_CLASSIFICACAO':
                    return {
                        ...state,
                        classificacao: INITIAL_STATE.classificacao,
                        tributacao: INITIAL_STATE.tributacao,
                        tributacoes: INITIAL_STATE.tributacoes
                    }
                case 'CLEAN_TRIBUTACAO':
                    return {
                        ...state,
                        tributacao: {
                            ...state.tributacao,
                            codTributacao: 0,
                            uf: [],
                            codCFOPNFE: '0',
                            codCFOPCF: '0',
                            codICMS: '0',
                            codPISCOFINS: '0',
                            percICMS: '0,00',
                            percICMSST: '0,00',
                            percIPI: '0,00',
                            percPIS: '0,00',
                            percPISST: '0,00',
                            percCOFINS: '0,00',
                            percCOFINSST: '0,00',
                            status: 0,
                        },
                        inicioCadastro: true
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: INITIAL_STATE.classificacaoCopia
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}