import React, { useEffect } from "react";
import store from '../../../../../store/stores'
import useResize from "~/Util/Hooks/useResize";
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import {
    Row,
    Col,
    Input,
    FormGroup
} from "reactstrap";
function DadosNota() {
    const dispatch = store.dispatch
    const dadosNota = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.dadosIniciais);
    const verCadastro = useShallowEqualSelector(state => state.entradaMercadoria.visualizarEntrada);
    const { width } = useResize();
    useEffect(() => {
        if (dadosNota.numEntrada === '') {
            const data = new Date()
            var mes = data.getMonth()
            mes < 10 ? mes = `0${mes + 1}` : mes = mes + 1
            dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'DADOS_INICIAIS', campo: 'dataEntrada', value: `${data.getFullYear()}-${mes}-${data.getDate()}` })
        }
    }, [])
    function handleOnChange(value, stateName) {
        dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'DADOS_INICIAIS', campo: stateName, value: value })
        dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'INVALIDO', campo: stateName, value: false });
    }
    return (
        <>
            <Col lg="9">
                <div className={verCadastro ? 'ver-cadastro' : ''}></div>
                <fieldset style={{ border: 'solid 1px #5E6680', borderRadius: 5 }}>
                    <legend style={{ fontSize: '13px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
                        DADOS
                        </legend>
                    <Row  className="padding-padrao-row">
                        <Col lg="3" className="padding-padrao-col">
                            <label>Num. Entrada</label>
                            <Input
                                value={dadosNota.numEntrada}
                                disabled={true}
                            />
                        </Col>
                        <Col lg="3" className="padding-padrao-col">
                            <label>Num. Nota Fiscal</label>
                            <FormGroup>
                                <Input
                                    type="text"
                                    autoComplete="off"
                                    value={dadosNota.numDoc}
                                    onChange={e => handleOnChange(e.target.value, 'numDoc')}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="3" className="padding-padrao-col">
                            <label>{width < 1404 ? "Data da Nota" : "Data da Nota Fiscal"}</label>
                            <FormGroup>
                                <Input
                                    type="date"
                                    value={dadosNota.dataDocumento}
                                    onChange={e => handleOnChange(e.target.value, 'dataDocumento')}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="3" className="padding-padrao-col">
                            <label>Data de Entrada</label>
                            <Input
                                type="date"
                                value={dadosNota.dataEntrada}
                                onChange={e => handleOnChange(e.target.value, 'dataEntrada')}
                            />
                        </Col>
                    </Row>
                </fieldset>
            </Col>
        </>
    )
}
export default DadosNota