import React from 'react'
import { Col, Row, Button } from 'reactstrap'

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from '../../../../components/ModalBasic/ModalBasic'

interface ModalSalvarGradeProps {
    isOpen: boolean
    toggle: () => void
    salvar: () => void
}

const ModalSalvarGrade: React.FC<ModalSalvarGradeProps> = ({ isOpen, toggle, salvar }) => {

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title={'Estoque'}
        >

            <ModalBasicBody>

                <Col className="text-center">
                    <label>Se você aceitar continuar as grades das quais o produto será desvinculado terão seus estoques zerados.</label>
                </Col>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <Button
                            color="danger"
                            type="button"
                            onClick={() => toggle()}
                            style={{ width: '150px' }}
                        >NÃO
                            </Button>
                    </Col>
                    <Col className="text-center">
                        <Button
                            color="success"
                            type="button"
                            onClick={() => {
                                toggle()
                                salvar()
                            }}
                            style={{ width: '150px' }}
                        >SIM
                            </Button>
                    </Col>
                </Row>
            </ModalBasicFooter>
        </ModalBasic >
    )
}
export default ModalSalvarGrade