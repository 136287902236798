import React, { useState, useEffect } from "react";
import classNames from "classnames";

import ReactWizard from "../../../../../components/Wizard/Wizard";

import {
    Col,
} from "reactstrap";

// wizard steps
import Step1 from "../../Steps/DadosIniciais/index";
import Step2 from "../../Steps/NFeReferencia/index";
import Step3 from "../../Steps/Produtos/index";
import Step4 from "../../Steps/ResumoEnvio";

import AppState from '../../../../../store/storeType';
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import { useDispatch } from "react-redux";

import * as action from "../../../../../store/reducers/Movimentacao/SaidaDiversa/actions";
import store from "../../../../../store/stores";
import * as controllerSaida from "../../../../../controller/Movimentacao/controllerSaidaDiversa"

var modelStep = [
    {
        stepName: "Dados Iniciais",
        stepIcon: "tim-icons icon-badge",
        component: Step1,
        stepProps: {}
    },
    {
        stepName: "Produtos",
        stepIcon: "tim-icons icon-bag-16",
        component: Step3,
        stepProps: {}
    },
    {
        stepName: "Resumo / Envio",
        stepIcon: "tim-icons icon-notes",
        component: Step4,
        stepProps: {}
    },
];

const idStore = "SAIDA_DIVERSA";
const GerarSaida = function () {
    const [steps, setSteps] = useState(modelStep);
    const dispatch = useDispatch();
    const inicioCadastro = useShallowEqualSelector<AppState, Boolean>(state => state.saidaDiversa.inicioCadastro)
    const currentStep = useShallowEqualSelector<AppState, number | null>(state => state.saidaDiversa.currentStep)
    const validaNFeReferencia = useShallowEqualSelector<AppState, Boolean>(state => state.saidaDiversa.validaNFeReferencia)

    const idLancamento = useShallowEqualSelector<AppState, number>(state => state.modalFaturamento.idLancamento)

    const [width] = useState(window.innerWidth);

    useEffect(() => {
        action.updatePedidoFatura(idLancamento)
    }, [idLancamento])

    useEffect(() => {

        return () => {
            const produto = {
                numeroPedidoItem: 0,
                item: 0,
                codProduto: 0,
                descricao: '',
                numero: '',
                quantidade: '',
                valorTotal: '',
                valorUnitario: '',
                dataLancamento: '',
                status: 0,
                flControlaGrade: 0,
                grades: []
            }

            action.setProduto(produto);
            action.cleanAll()
            action.addProduto([])
        }
    }, [])

    function changeFatura() {
        const { idLancamento } = store.getState().modalFaturamento
        action.updatePedidoFatura(idLancamento)
    }

    function finalizar() {
        var error = !controllerSaida.Salvar('Resumo / Envio')
        return error
    }

    useEffect(() => {
        if (validaNFeReferencia)
            setSteps([
                {
                    stepName: "Dados Iniciais",
                    stepIcon: "tim-icons icon-badge",
                    component: Step1,
                    stepProps: {}
                },
                {
                    stepName: "NFe Referência",
                    stepIcon: "tim-icons icon-bank",
                    component: Step2,
                    stepProps: {}
                },
                {
                    stepName: "Produtos",
                    stepIcon: "tim-icons icon-bag-16",
                    component: Step3,
                    stepProps: {}
                },
                {
                    stepName: "Resumo / Envio",
                    stepIcon: "tim-icons icon-notes",
                    component: Step4,
                    stepProps: {}
                },
            ])

    }, [validaNFeReferencia])

    return (
        <Col lg="12">
            <div style={{ marginTop: '-20px' }}>
                <ReactWizard
                    steps={steps}
                    navSteps={true}
                    title=""
                    description=""
                    validate
                    resizable={true}
                    //@ts-ignore
                    changeStep={() => { }}
                    previousButtonText="Voltar"
                    finishButtonText="Finalizar"
                    nextButtonText="Avançar"
                    goToFirstStep={inicioCadastro}
                    newCurrentStep={currentStep}
                    setNewCurrentStep={(nextStep: number) => { dispatch({ idStore, type: 'WIZARD_STEP', value: nextStep }) }}
                    finishButtonClick={finalizar}
                    finishButtonClasses={classNames("btn-wd btn-info",
                        {
                            finishButtonMin:
                                width < 550
                        },
                        {
                            finishButtonRight:
                                width > 550
                        }
                    )}
                    nextButtonClasses={classNames("btn-wd btn-info",
                        {
                            nextButtonMin:
                                width < 550

                        },
                        {
                            nextButtonRight:
                                width > 550
                        }
                    )}
                    previousButtonClasses={classNames("btn-wd",
                        {
                            previousButtonMin:
                                width < 550
                        },
                        {
                            previousButtonRight:
                                width > 550
                        }
                    )}
                    progressbar
                    color="blue"
                />
            </div>


        </Col>
    );
}

export default GerarSaida;