import { hot } from 'react-hot-loader/root';
import React, { useEffect, createRef } from "react";
import history from "Util/history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/Auth/Auth.jsx";
import NotificationAlert from "react-notification-alert";
import PrivateRoute from "~/components/PrivateRoute/PrivateRoute.jsx"
import LoadingBar from "~/components/LoadingBar/LoadingBar"
import { useDispatch } from "react-redux"
import { setConfig } from 'react-hot-loader';
 
setConfig({
  reloadHooks: false,
});
const App = () => {
    const notification = createRef();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ idStore: 'NOTIFICATION', type: 'SET', value: notification.current });
    }, []);
    return (
        <>
            <NotificationAlert ref={notification} />
            <LoadingBar />
            <Router history={history}>
                <Switch>
                    <Route path="/auth" render={props => <AuthLayout {...props} />} />
                    <PrivateRoute/>
                    <Redirect from="/" to="/dashboard" />
                </Switch>
            </Router>
        </>
    );
}

export default hot(App);
