import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { maskEnquadramentoIPI } from '~/Util/mascaras';

import * as controllerEnquadramentosIPI from "~/controller/controllerEnquadramentosIPI";

import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';

import {
    Input,
    Row,
    Col,
    CardBody,
    Button,
    CardFooter,
    FormGroup,
} from "reactstrap";

const idStore = "ENQUADRAMENTOS_IPI";


const EnquadramentoIPI = () => {
    const notificacao = useSelector(state => state.notificacao);
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
    const { enquadramento, enquadramentoCopia, invalido, verCadastro } = useSelector(state => state.enquadramentosIPI);
    const dispatch = useDispatch();

    function onChange(value, stateName) {
        dispatch({ idStore, type: 'ENQUADRAMENTO', campo: stateName, value: value });
        dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
    };

    async function Salvar() {

        if (enquadramento.codEnquadramento === 0) {
            controllerEnquadramentosIPI.Create(notificacao, dispatch, codEmpresa, enquadramento);
        } else {
            controllerEnquadramentosIPI.Update(notificacao, dispatch, codEmpresa, enquadramento, enquadramentoCopia);
        }
    };

    function Limpar() {
        dispatch({ idStore, type: 'CLEAN_ENQUADRAMENTO' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        document.getElementById('cadenquadramento').focus()
    };

    return (
        <>
            <Row className="justify-content-center">
                <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

                </div>
                <Col>
                    <h4 className="info-text">Preencha com as informações do Enquadramento de IPI</h4>
                    <CardBody>

                        <Row className="justify-content-center">

                            <Col md="12">

                                <Row  className="padding-padrao-row">

                                    <Col  className="padding-padrao-col" lg="3" md="3" sm="3">
                                        <label>Enquadramento <span className="error">*</span></label>
                                        <FormGroup className={invalido.enquadramento ? "has-danger" : ''}>
                                            <Input
                                                id="cadenquadramento"
                                                type="number"
                                                value={enquadramento.enquadramento}
                                                onChange={e => onChange(maskEnquadramentoIPI(e.target.value), 'enquadramento')}
                                                autoComplete="off"
                                            />
                                            <br></br>
                                        </FormGroup>
                                    </Col>

                                    <Col className="padding-padrao-col" lg md sm>
                                        <label>Descrição <span className="error">*</span></label>
                                        <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                                            <Input
                                                id="descricao"
                                                type="text"
                                                value={enquadramento.descricao}
                                                onChange={e => onChange(e.target.value, 'descricao')}
                                                autoComplete="off"
                                                maxLength="200"
                                            />
                                            <br></br>
                                        </FormGroup>
                                    </Col>

                                </Row>

                            </Col>

                        </Row>

                    </CardBody>
                    <CardFooter className="text-center">
                        <Row>
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                <ArrowTooltip title='Shift+N'>
                                    <Button className="btn-padrao" color="default" onClick={() => Limpar()}>Novo</Button>
                                </ArrowTooltip>
                                <ArrowTooltip title='Shift+S'>
                                    <Button className="btn-padrao" color="info" onClick={() => Salvar()}>Salvar</Button>
                                </ArrowTooltip>
                            </Col>
                        </Row>
                    </CardFooter>
                </Col>
            </Row>
        </>
    )
};

class Wizard extends React.Component {
    focusInput = () => {
        setTimeout(() => {
            document.getElementById('cadenquadramento').focus()
        }, 100)
    }

    render() {
        return (
            <EnquadramentoIPI />
        )
    }
}

export default Wizard;
