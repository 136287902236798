import React from 'react';
import { isEmptyObject } from '~/Util/validacao';

export function alertar(state, position, mensagem, type, icon, seconds, error = null) {
    // var level = 'success'; // 'success', 'warning', 'danger' or 'info'
    var icone = "tim-icons icon-check-2"
    if (error !== null) {
        if (error.message === "Network Error") {
            mensagem = "Falha na conexão";
            type = "danger";
            icon = "icon-simple-remove";
            seconds = 5
        }
    }

    if (icon !== undefined) icone = "tim-icons " + icon

    if (!isEmptyObject(state)) {
        state.notificationAlert({
            icon: icone,
            message: (
                <div >
                    {mensagem}
                </div>
            ),
            type: type,
            place: position,
            autoDismiss: seconds,
        });
    }

}