export enum AberturaCaixaStore {
    ID_STORE = 'PEDIDO_VENDA',
}

export enum AberturaCaixaTypes {
    CLEAN_ALL = 'CLEAN_ALL',
    CLEAN_PESQUISA = "CLEAN_PESQUISA",
    CLEAN_LANCAMENTO = "CLEAN_LANCAMENTO",
    CLEAN_ABERTURA = 'CLEAN_ABERTURA',
    PESQUISA = 'PESQUISA',
    ABERTURA = 'ABERTURA',
    LANCAMENTO = 'LANCAMENTO',
    LANCAMENTOS = 'LANCAMENTOS',
    FUNCIONARIOS = 'FUNCIONARIOS',
    CAIXAS = 'CAIXAS',
    TIPO_LANCAMENTO = 'TIPO_LANCAMENTO',
    ABERTURA_RETORNO = 'ABERTURA_RETORNO',
    SCREEN = 'SCREEN',
    BLOCK = 'BLOCK'
}

export type AberturaCaixaAction = {
    idStore: AberturaCaixaStore.ID_STORE
    type: AberturaCaixaTypes
    value?: any,
    pesquisa?: IAberturaCaixaState['pesquisa'],
    abertura?: IAberturaCaixaState['abertura'],
    lancamento?: IAberturaCaixaState['lancamento'],
    lancamentos?: IAberturaCaixaState['lancamentos']
    funcionarios?: any[]
    caixas?: any[]
    tipoLancamentos?: any[]
}

export interface IAberturaCaixaState {
    pesquisa: {
        codigoFuncionario: number,
        nomeFuncionario: string,
        dataInicio: string,
        dataFim: string,
        tipo: number | null
    },
    abertura: {
        codCaixa: number,
        codFuncionario: number,
        nomeFuncionario: string,
        dataAbertura: string,
        dataFechamento: string,
        valorTotalLancado: string,
        status: string
    },
    lancamento: ILancamento,
    lancamentos: ILancamento[]
    funcionarios: any[],
    caixas: any[]
    tipoLancamentos: any[]
    screen: number,
    block: boolean
}

interface ILancamento {
    codFormaPagamento: number,
    descricaoFormaPagamento: string,
    valor: string,
    observacao: string,
    dataLancamento: string
}