import React from "react";
import {
    Input,
    Row,
    Col,
    Button,
    FormGroup,
} from "reactstrap";


import useShallowEqualSelector from "../../../../../../../../Util/Hooks/useShallowEqualSelector";

import * as actions from '../../../../../../../../store/reducers/Cadastro/Operacao/actions';
import { maskNumbers } from "../../../../../../../../Util/mascaras";

import AppState from "../../../../../../../../store/storeType";
import { Select, Switch } from "../../../../../../../../components/ErnComponets/ErnComponets";

import { IOperacaoState } from '../../../../../../../../store/reducers/Cadastro/Operacao/types'
import * as controllerOperacoes from '../../../../../../../../controller/controllerOperacoes';
import ArrowTooltip from "../../../../../../../../components/Tooltip/ArrowTooltip";

export function FirstRow() {

    const invalido = useShallowEqualSelector<AppState, IOperacaoState['invalido']>(state => state.operacao.invalido)
    const operacao = useShallowEqualSelector<AppState, IOperacaoState['operacao']>(state => state.operacao.operacao)
    const verCadastro = useShallowEqualSelector<AppState, IOperacaoState['verCadastro']>(state => state.operacao.verCadastro)

    return (

        <>
            <Row className="justify-content-center">
            <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

            </div>
                        <Col className="padding-padrao-col" lg="4" md="3">
                            <label>Número <span className="error">*</span></label>
                            <FormGroup className={invalido.numero ? "has-danger" : ''}>
                                <Input
                                    id="cadnumero"
                                    type="text"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => actions.setOperacao({ ...operacao, numero: maskNumbers(event.currentTarget.value) })}
                                    value={operacao.numero}
                                    autoFocus={true}
                                    disabled={operacao.codigo !== 0 ? true : false}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="padding-padrao-col" lg="7" md="7">
                            <label>Descrição <span className="error">*</span></label>
                            <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                                <Input
                                    id="caddescricao"
                                    type="text"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => actions.setOperacao({ ...operacao, descricao: event.currentTarget.value })}
                                    value={operacao.descricao}
                                    autoComplete="off"
                                    maxLength={150}
                                />
                            </FormGroup>
                            <Col lg="2" md="2">
                            </Col>
                        </Col>
                        <Col lg="1" md="1">
                            <Switch
                                componenttop={<label>Emite NFE</label>}
                                checked={operacao.nfe === 1}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { actions.setOperacao({ ...operacao, nfe: operacao.nfe === 1 ? 0 : 1 }) }}
                            />
                        </Col>
                    </Row>
        </>

    )
}

export function SecondRow() {
    const operacao = useShallowEqualSelector<AppState, IOperacaoState['operacao']>(state => state.operacao.operacao)
    const caracteristica = useShallowEqualSelector<AppState, IOperacaoState['caracteristica']>(state => state.operacao.caracteristica)
    const verCadastro = useShallowEqualSelector<AppState, IOperacaoState['verCadastro']>(state => state.operacao.verCadastro)

    return (
        <>
            <Row className="justify-content-left">
            <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

            </div>
                <Col className="padding-padrao-col" lg="3" md="3">
                    <label>Tipo <span className="error">*</span></label>
                    <Select
                        first={'Nenhum'}
                        //@ts-ignore
                        options={[{ value: 'E', label: 'Entrada' }, { value: 'S', label: 'Saída' }]}
                        onChange={
                            //@ts-ignore
                            (event: React.ChangeEvent<HTMLInputElement>) => { actions.setOperacao({ ...operacao, tipo: event.value }) }}
                        value={{ value: operacao.tipo }}                      
                    />

                </Col>
                <Col className="padding-padrao-col" lg="6" md="6">
                    <label>Caracteristicas da Operação <span className="error">*</span></label>
                    <Select
                        //@ts-ignore
                        first={'Nenhum'}
                        select={{ value: 'codigo', label: 'descricao' }}
                        onChange={
                            //@ts-ignore
                            (event: React.ChangeEvent<HTMLInputElement>) => { actions.setOperacao({ ...operacao, caracteristicas: event.value }) }}
                        value={{ value: operacao.caracteristicas }}
                        //@ts-ignore
                        options={caracteristica}                       
                    />
                </Col>
                <Col className="padding-padrao-col">
                    <Switch
                        componenttop={<label>Movimentar Estoque</label>}
                        checked={operacao.estoque === 1}
                        onChange={() => actions.setOperacao({ ...operacao, estoque: operacao.estoque === 1 ? 0 : 1 })}                 
                    />
                </Col>
                <Col className="padding-padrao-col">
                    <Switch
                        componenttop={<label>Gerar Conta</label>}
                        checked={operacao.conta === 1}
                        onChange={() => actions.setOperacao({ ...operacao, conta: operacao.conta === 1 ? 0 : 1 })}                  
                    />
                </Col>
            </Row>
        </>
    )
}

export function ButtonGroupCadastro() {
    const operacao = useShallowEqualSelector<AppState, IOperacaoState['operacao']>(state => state.operacao.operacao)
    const verCadastro = useShallowEqualSelector<AppState, IOperacaoState['verCadastro']>(state => state.operacao.verCadastro)

    async function CreateUpdate() {
        if (operacao.codigo === 0) {
            await controllerOperacoes.createTipoOperacao(operacao);
        }
        else {
            await controllerOperacoes.UpdateOperacao(operacao);
        }
    }

    return (
        <>
            <Row className="justify-content-center">
            <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

            </div>
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    <ArrowTooltip title="shift+N" >
                        <Button className="btn-padrao" color="default" onClick={() => (actions.New(), actions.cleanInvalido())}>Novo</Button>
                    </ArrowTooltip>
                    <ArrowTooltip title="shift+S" >
                        <Button className="btn-padrao" color="info" onClick={() => CreateUpdate()}>Salvar</Button>
                    </ArrowTooltip>
                </Col>
            </Row>

        </>
    )

}