import React, { useState, useEffect, memo } from 'react'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { Button } from "reactstrap"
import ReactTable from "react-table"
import ArrowTooltip from '../../../../components/Tooltip/ArrowTooltip'

import { Card, Sub, Input } from '../Components'

import useResize from '../../../../Util/Hooks/useResize'
import useScrollBar from '../../../../Util/Hooks/useScrollBar'
import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector'
import usePrevious from '../../../../Util/Hooks/usePrevious'
import { Round, Divide, Multiply } from '../../../../Util/numbers'


import AppState from '../../../../store/storeType'
import { Item } from '../../../../store/reducers/Movimentacao/PedidoVenda/types'

import { formats } from '../variables'
import { setItens, setTotais } from '../../../../store/reducers/Movimentacao/PedidoVenda/actions'
import { maskMoeda } from '../../../../Util/mascaras'
import { removeMaskMoney } from '../../../../Util/removeMascaras'

interface IItens {
    toogleModalGrade: any;
    setCodProduto: any;
    setTypeAction: any;
    setIndexGrade: any;
}

const Itens: React.FC<IItens> = ({toogleModalGrade, setCodProduto, setTypeAction, setIndexGrade}) => {
    const tableItens = useScrollBar()
    const size = useResize()
    const [descontoPercent, setDescontoPercent] = useState(true)
    const [data, setData] = useState<Item[]>([])
    const prevData = usePrevious<Item[]>(data)
    const itens = useShallowEqualSelector<AppState, Item[]>(state => state.pedidoVenda.itens)
    //@ts-ignore
    const totais = useShallowEqualSelector(state => state.pedidoVenda.totais)
    //@ts-ignore
    const pedido = useShallowEqualSelector(state => state.pedidoVenda.pedido)

    useEffect(() => {
        if (prevData) {
            if (prevData < data) {
                if (tableItens.current)
                    tableItens.current.scrollTop = tableItens.current.scrollHeight
            }
        }
    }, [data])

    useEffect(() => {
        setData(itens)
    }, [itens])

    function changeItem(index: number, field: keyof Item, value: string) {
        let products: Item[] = JSON.parse(JSON.stringify(itens))

        let product = products[index]
        
        //@ts-ignore
        if(field === 'quantidade' && (product.grade && product.grade.length > 0)) {
            console.log('entrou')
            setCodProduto(product.codProduto)
            setTypeAction('editar')
            toogleModalGrade()
        }

        if(field === 'quantidade' && (value === '' || Number(value) <= 0 )) {
            value = '1'
        } else if ( field === 'precoVendaAtual' && (value === '' || Number(removeMaskMoney(value)) <= 0 )) {
            value = product.precoVendaAtual.toString()
        } else if (field === 'descontoPercent' && (value === '' || Number(value) < 0 || Number(value) > 100)) {
            value = '0'
        } else if (field === 'descontoReais' && (value === '' || Number(removeMaskMoney(value)) < 0 || Number(removeMaskMoney(value)) > Number(removeMaskMoney(product.subtotal)) )) {
            value = '0'
        } 

        switch (field) {
            case 'quantidade':
                let subtotalQuantidade = Multiply([Number(removeMaskMoney(product.precoVendaAtual)), Number(value)])
                var descontoReaisQuant =  (subtotalQuantidade * Number(product.descontoPercent) / 100).toFixed(2)

                var desconto = Divide([
                    Multiply([100, Number(removeMaskMoney(value))]),
                    Number(removeMaskMoney(subtotalQuantidade))
                ])

                product = {
                    ...product,
                    quantidade: value === '' ? 1 : Number(value),
                    subtotal: maskMoeda(subtotalQuantidade.toFixed(2)),
                    descontoReais: descontoReaisQuant,
                    total: maskMoeda((subtotalQuantidade - Number(descontoReaisQuant)).toFixed(2))
                }
                break
            case 'precoVendaAtual':
                let subtotalPrecoVendaAtual = Multiply([Number(product.quantidade), Number(removeMaskMoney(value))])
                var descontoReaisPreco = (subtotalPrecoVendaAtual * Number(product.descontoPercent) / 100).toFixed(2)
                product = {
                    ...product,
                    precoVendaAtual: maskMoeda(value),
                    subtotal: maskMoeda(subtotalPrecoVendaAtual.toFixed(2)),
                    descontoReais: descontoReaisPreco,
                    total: maskMoeda((subtotalPrecoVendaAtual - Number(descontoReaisPreco)).toFixed(2))
                }
                break
            case 'descontoReais':
                var subtotal = Number(removeMaskMoney(product.subtotal))
                    var desconto = Divide([
                        Multiply([100, Number(removeMaskMoney(value))]),
                        Number(removeMaskMoney(subtotal))
                    ])

                    var descontoReais = ((Number(removeMaskMoney(value)) * 100) / Number(subtotal.toString().replace(',', '.'))).toFixed(2)

                    product = {
                        ...product,
                        descontoReais: value,
                        descontoPercent: descontoReais,
                        total: maskMoeda((Number(subtotal) - Number(removeMaskMoney(value))).toFixed(2))
                    }
              
                break
            case 'descontoPercent':
                    var subtotal = Number(removeMaskMoney(product.subtotal))
                    //@ts-ignore
                    desconto = ((Number(value) / 100) * subtotal).toFixed(2)
                    product = {
                        ...product,
                        descontoPercent: value,
                        descontoReais: desconto,
                        total: maskMoeda((Number(subtotal) - Number(desconto)).toFixed(2)),
                    }
                break
            default:
                product = { ...product, [field]: value }
                break
        }
        products[index] = product
        setItens(products)
    }

    function excluirItem(index: number) {
        let temp: Item[] = JSON.parse(JSON.stringify(itens))
        var total = (temp[index].total).toString().replace('.', '')
        total = total.replace(',', '.')
        var subtotal = (temp[index].subtotal).toString().replace('.', '')
        subtotal = subtotal.replace(',', '.')
        var descontoReais = (temp[index].descontoReais)
        var quantidade = (temp[index].quantidade).toString().replace('.', '')
        quantidade = quantidade.replace(',', '.')


        var totaisTotal = (totais.total).toString().replace('.', '')
        totaisTotal = totaisTotal.replace(',', '.')
        var totaisSubtotal = (totais.subtotal).toString().replace('.', '')
        totaisSubtotal = totaisSubtotal.replace(',', '.')
        var totaisDescontoReais = (totais.desconto).toString().replace('.', '')
        totaisDescontoReais = totaisDescontoReais.replace(',', '.')
        var totaisQuantidade = (totais.quantidadeTotal).toString().replace('.', '')
        totaisQuantidade = totaisQuantidade.replace(',', '.')

        totaisTotal = maskMoeda(Number(totaisTotal) - Number(total))
        totaisSubtotal = maskMoeda(Number(totaisSubtotal) - Number(subtotal))
        totaisDescontoReais = maskMoeda(Number(totaisDescontoReais) - Number(descontoReais))
        totaisQuantidade = Number(totaisQuantidade) - Number(quantidade)
        totais.quantidadeItens = Number(totais.quantidadeItens) - 1
        var qtdItens = totais.quantidadeItens

        temp.splice(index, 1)
        setItens(temp)

        setTotais({ ...totais, subtotal: totaisSubtotal, desconto: totaisDescontoReais, total: totaisTotal, quantidadeTotal: totaisQuantidade, quantidadeItens: qtdItens })

    }
    return (
        <Card style={{height:"calc(100% + 50px)", paddingTop: 0, width: '100%'}} >
            <div
                ref={tableItens}
                style={{ position: 'relative', height: '100%' }}
            >
                {data.forEach(data => {
                    //@ts-ignore
                    data.descricao = data.nome
                    //@ts-ignore
                    data.descricao = `${data.numProduto}- ${data.descricao}`
                })}
                <ReactTable
                    data={data}
                    filterable={false}
                    style={{ textAlign: 'center' }}
                    columns={
                        [
                            {
                                Header: () => (<div style={{ textAlign: 'left', fontSize: '10px', padding: '0px' }}>ITEM</div>),
                                Cell: props => (
                                    <div >
                                        <Sub style={{ fontSize: '10px', fontWeight: 'normal' }}>{props.index + 1}</Sub>
                                    </div>
                                ),
                                minWidth: 30,
                                resizable: false,
                                style: { fontSize: '10px' }
                            },
                            {
                                Header: () => (<div style={{ textAlign: 'left', fontSize: '10px' }}>DESCRIÇÃO</div>),
                                accessor: 'descricao',
                                Cell: props => (
                                    <div>
                                        <Sub style={{ fontSize: '10px', fontWeight: 'normal' }}>{props.value}</Sub>
                                    </div>
                                ),
                                minWidth: 200,
                            },
                            {
                                Header: () => (<div style={{ textAlign: 'center', fontSize: '10px' }}>QUANT.</div>),
                                accessor: 'quantidade',
                                Cell: props => (
                                    <div>
                                        <ColumnVariable
                                            format={`${formats.quantidade.places},${formats.quantidade.decimal}`}
                                            value={props.value}
                                            field={'quantidade'}
                                            index={props.index}
                                            updateData={changeItem}
                                        />
                                    </div>),
                                minWidth: 40,
                                resizable: false,
                                sortable: false,
                            },
                            {
                                Header: () => (<div style={{ textAlign: 'right', fontSize: '10px' }}>VL UNIT.</div>),
                                accessor: 'precoVendaAtual',
                                Cell: props => (
                                    <div style={{ fontSize: '10px', textAlign: 'right' }}>
                                        <ColumnVariable
                                            format={`${formats.precoVendaAtual.places},${formats.precoVendaAtual.decimal}`}
                                            value={maskMoeda(props.value)}
                                            field={'precoVendaAtual'}
                                            index={props.index}
                                            updateData={changeItem}
                                        />
                                    </div>),
                                minWidth: 80,
                                resizable: false,
                                sortable: false
                            },
                            {
                                Header: () => (<div style={{ textAlign: 'right', fontSize: '10px' }}>SUBTOTAL</div>),
                                accessor: 'subtotal',
                                Cell: props => (
                                    <div style={{ fontSize: '10px' }}>
                                        <ArrowTooltip title={props.value} placement={'right'}>
                                            <div>
                                                <Sub style={{ textAlign: 'right', fontSize: '10px', fontWeight: 'normal' }}>{props.value}</Sub>
                                            </div>
                                        </ArrowTooltip>
                                    </div>
                                ),
                                minWidth: 100,
                                resizable: false,
                                sortable: false
                            },
                            {
                                Header: () => (
                                    <div style={{ textAlign: 'right', fontSize: '10px' }}>DESCONTO{" "}
                                        <DescontoButton value={descontoPercent} onChange={() => { setDescontoPercent(!descontoPercent) }} />
                                    </div>),
                                accessor: descontoPercent ? 'descontoPercent' : 'descontoReais',
                                Cell: props => (
                                    <ColumnVariable
                                        format={``}
                                        value={descontoPercent ? Number(props.value)  : maskMoeda(props.value)}
                                        field={descontoPercent ? 'descontoPercent' : 'descontoReais'}
                                        index={props.index}
                                        updateData={changeItem}
                                    />),
                                minWidth: 100,
                                resizable: false,
                                sortable: false
                            },
                            {
                                Header: () => (<div style={{ textAlign: 'right', fontSize: '10px' }}>TOTAL</div>),
                                accessor: 'total',
                                Cell: props => (
                                    <ArrowTooltip title={props.value} placement={'right'}>
                                        <div>
                                            <Sub style={{ textAlign: 'right', fontSize: '10px' }}>{props.value}</Sub>
                                        </div>
                                    </ArrowTooltip>
                                ),
                                minWidth: 100,
                                resizable: false,
                                sortable: false
                            },
                            {
                                Cell: ({ index }) => (
                                    <div className="action-right">
                                        <ArrowTooltip title="Excluir">
                                            <Button
                                                onClick={() => { excluirItem(index) }}
                                                className={pedido.status !== 'ABERTO' && pedido.status !== '' ? 'disabledButton btn-icon btn-link text-center"' : 'btn-icon btn-link text-center"'}
                                                color="danger"
                                            >
                                                <Icon icon="trash-alt" className="fa-lg" />
                                            </Button>
                                        </ArrowTooltip>
                                    </div >
                                ),
                                sortable: false,
                                filterable: false,
                                minWidth: 50,
                                style: { padding: 0 }
                            },
                            {
                                Cell: props => (
                                    props.original.flControlaGrade === 1 || (props.original.grades && props.original.grades.length > 0) && (
                                        <div className="action-right">
                                            <ArrowTooltip title="Grade">
                                                <Button
                                                    onClick={() => { toogleModalGrade(); setCodProduto(props.original.codProduto); setTypeAction('editar'); setIndexGrade(props.index)  }}
                                                    className={pedido.status !== 'ABERTO' && pedido.status !== '' ? 'disabledButton btn-icon btn-link text-center"' : 'btn-icon btn-link text-center"'}
                                                    color="info"
                                                >
                                                    <Icon icon="th" className="fa-lg"  />
                                                </Button>
                                            </ArrowTooltip>
                                        </div >
                                    ) 
                                ),
                                minWidth: 50,
                            }
                        ]
                    }
                    getTrProps={() => {
                        return {
                            style: {
                                paddingTop: '0px',
                                paddingBottom: '5px',
                            }
                        }
                    }}
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    NoDataComponent={() => (null)}
                    pageSize={data.length}
                />
            </div>
        </Card>
    )
}

export default Itens

interface ColumnVariableProps { format: string, index: number, field: keyof Item, value: string, updateData: (index: number, field: keyof Item, value: string) => void }

const ColumnVariable: React.FC<ColumnVariableProps> = memo(({ format, index, field, value: initialValue, updateData }) => {
    //@ts-ignore
    const pedido = useShallowEqualSelector(state => state.pedidoVenda.pedido)
    const itens = useShallowEqualSelector<AppState, Item[]>(state => state.pedidoVenda.itens)
    const [inputVisible, setinputVisible] = useState(false)
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    const changeColumn = () => {
        setinputVisible(!inputVisible)
    }

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (field === 'quantidade') {
            setValue((e.currentTarget.value.toString().replace(/\D/g, '')))
        } else if (field === 'descontoPercent') {
            if (Number(value) < 0 || Number(value) > 100) {
                setValue(initialValue)
            } else {
                setValue(e.currentTarget.value)
            }
        } else {
            setValue(maskMoeda(e.currentTarget.value))
        }
    }

    const onBlur = async () => {
        if (value === '') {
            await setValue(initialValue)
        } else if (field === 'descontoPercent') {
            if (Number(value) < 0 || Number(value) > 100) {
                setValue(initialValue)
            }
        } else if (field === 'precoVendaAtual') {
            if (Number(removeMaskMoney(value)) <= 0) {
                setValue(initialValue)
            }
        } else if (field === 'descontoReais') {
            itens.forEach(item => {
                if (Number(removeMaskMoney(value)) > Number(removeMaskMoney(item.subtotal)) || Number(removeMaskMoney(value)) < 0) {
                    setValue(initialValue)
                }
            })
        } else if (field === 'quantidade') {
            if(Number(value) <= 0 ) {
                setValue(initialValue)
            }
        }
        updateData(index, field, value)
        changeColumn()
    }

    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (value === '') {
                setValue(initialValue)
            } else if (field === 'descontoPercent') {
                if (Number(value) < 0 || Number(value) > 100) {
                    setValue(initialValue)
                }
            } else if (field === 'precoVendaAtual') {
                if (Number(value) <= 0) {
                    setValue(maskMoeda(initialValue))
                }
            } else if (field === 'descontoReais') {
                itens.forEach(item => {
                    if (Number(removeMaskMoney(value)) > Number(removeMaskMoney(item.subtotal)) || Number(removeMaskMoney(value)) < 0) {
                        setValue(initialValue)
                    }
                })
            } else if (field === 'quantidade') {
                if(Number(value) <= 0 ) {
                    setValue(initialValue)
                }
            }
            updateData(index, field, value)
            changeColumn()
        }
    }

    return (
        pedido.status !== 'ABERTO' && pedido.status !== '' ? (
            <div>
                <div className={(pedido && pedido.status !== 'ABERTO' && pedido.status !== '' ) ? 'disabledColumn-variable' : ''}>
                    <Sub style={{ textAlign: 'right', fontSize: '10px', fontWeight: 'normal' }} >{value}</Sub>
                </div>
            </div>
        ) : (
            <ArrowTooltip title={!inputVisible ? 'Toque para editar' : 'Edite'}>
            <div>
                {!inputVisible ?
                    <ArrowTooltip title={value} placement={'right'}>
                        <div>
                            <Sub style={{ textAlign: 'right', fontSize: '10px', fontWeight: 'normal' }}  onClick={changeColumn}>{value}</Sub>
                        </div>
                    </ArrowTooltip>
                    :
                    <Input
                        className="inputChange-value"
                        format={format}
                        style={{ marginTop: -7, textAlign: 'center', fontSize: '10px' }}
                        type={field === 'descontoPercent' ? 'number' : 'text'}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                        autoFocus
                        onFocus={(e: React.FormEvent<HTMLInputElement>) => e.currentTarget.select()}
                    />
                }
            </div>
        </ArrowTooltip>
        )
        
    )
})

interface DescontoButtonProps { value: boolean, onChange: (event: React.MouseEvent<HTMLDivElement>) => void }

const DescontoButton: React.FC<DescontoButtonProps> = memo(({ value, onChange }) => {
    return (
        <ArrowTooltip title={value ? 'Trocar para R$' : 'Trocar para %'} placement="top">
            <div
                color={'green'}
                className={'desconto-button'}
                onClick={onChange}
            >
                {value ? '%' : 'R$'}
            </div>
        </ArrowTooltip>
    )
})