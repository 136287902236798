import React, { useEffect } from 'react';
import { Button, CardBody, Col, Input, Row } from 'reactstrap';
import { Select } from '../../../../../components/ErnComponets/ErnComponets'
import { HelpButton } from '../../../../../components/HelpPlugin/HelpPlugin';
import { IRecebimentoPagamentoState } from '../../../../../store/reducers/Financeiro/RecebimentoPagamento/types';
import AppState from '../../../../../store/storeType';
import useShallowEqualSelector from '../../../../../Util/Hooks/useShallowEqualSelector';
import * as controllerRecebimentosPagamentos from '../../../../../controller/Financeiro/controllerRecebimentosPagamentos';
import { MiniCard } from '../../Components/MiniCard';

const RecebimentosPagamentos = (props: any) => {

  const formasPagamentos = useShallowEqualSelector<AppState, IRecebimentoPagamentoState['formasPagamentos']>(state => state.recebimentoPagamento.formasPagamentos);

  useEffect(() => {
    controllerRecebimentosPagamentos.getFormasPgto()
  }, [])

  return (
    <>
      <Row>
        <div style={{ width: '25%' }}>
          <Col>
            <CardBody style={{ marginTop: '0' }}>
              <label>FORMAS DE LANÇAMENTO<HelpButton id={2} /></label>
              <fieldset style={{ border: 'solid 1px #5E6680', borderRadius: 5 }}>
                <Col className="p-4">
                  <Row>
                    <MiniCard />
                    <MiniCard />
                    <MiniCard />
                    <MiniCard />
                    <MiniCard />
                  </Row>
                </Col>
              </fieldset>
            </CardBody>
          </Col>
        </div>

        <div style={{ width: '55%' }}>
          <Col>
            <CardBody style={{ marginTop: '0' }}>
              <Col>
                <Row style={{ marginBottom: '20px' }}>
                  <Col style={{ padding: "2px" }}>
                    <label>VALOR(%)</label>
                    <Input
                      style={{ cursor: 'default', fontSize: '20px' }}
                      disabled={true}
                      value={'0,00'}
                    />
                  </Col>
                  <Col style={{ padding: "2px" }}>
                    <label>MULTA(%)</label>
                    <Input
                      style={{ cursor: 'default', fontSize: '20px' }}
                      disabled={true}
                      value={'0,00'}
                    />
                  </Col>
                  <Col style={{ padding: "2px" }}>
                    <label>JUROS(%)</label>
                    <Input
                      style={{ cursor: 'default', fontSize: '20px' }}
                      disabled={true}
                      value={'0,00'}
                    />
                  </Col>
                  <Col style={{ padding: "2px" }}>
                    <label>DESCONTO(R$){/*<HelpButton id={2}/>*/}</label>
                    <Input
                      style={{ cursor: 'default', fontSize: '20px' }}
                      disabled={true}
                      value={'0,00'}
                    />
                  </Col>
                </Row>
                <Button
                  color="green"
                  type="button"
                  style={{ width: '100%' }}
                >
                  + LANÇAR VALOR
                </Button>
                <Row>
                  <Col style={{ padding: "2px" }}>
                    <label>CONTA</label>
                    <Select
                      first={'Todos'}
                      value={{}}
                      //@ts-ignore
                      options={[{}, {}]}
                      onChange={() => { }}

                    />
                  </Col>
                  <Col style={{ padding: "2px" }}>
                    <label>LANÇAMENTO</label>
                    <Input type="date" />
                  </Col>
                </Row>
                <Row>
                  <fieldset style={{ border: 'solid 1px #5E6680', borderRadius: 5, width: '100%' }}>
                    <legend style={{ fontSize: '13px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
                      LANÇAMENTOS
                  </legend>
                    <Row>
                      <Col style={{ textAlign: 'center', padding: '2px' }}>ITEM</Col>
                      <Col style={{ textAlign: 'center', padding: '2px' }}>FORMA PGTO</Col>
                      <Col style={{ textAlign: 'center', padding: '2px' }}>VALOR</Col>
                      <Col style={{ textAlign: 'center', padding: '2px' }}>DATA</Col>
                      <Col style={{ textAlign: 'center', padding: '2px' }}>AÇÃO</Col>
                    </Row>
                  </fieldset>
                </Row>
              </Col>
            </CardBody>
          </Col>
        </div>

        <div style={{ width: '20%' }}>
          <Col>
            <CardBody style={{ marginTop: '0' }}>
              <Col>
                <label>TOTAL TÍTULO</label>
                <Input
                  style={{ cursor: 'default', fontSize: '20px' }}
                  disabled={true}
                  value={'0,00'}
                />
                <label>TOTAL LANÇADO</label>
                <Input
                  style={{ cursor: 'default', fontSize: '20px' }}
                  disabled={true}
                  value={'0,00'}
                />
                <label>TOTAL RESTANTE</label>
                <Input
                  style={{ cursor: 'default', fontSize: '20px' }}
                  disabled={true}
                  value={'0,00'}
                />
                <label>TOTAL</label>
                <Input
                  style={{ cursor: 'default', fontSize: '20px' }}
                  disabled={true}
                  value={'0,00'}
                />
                <label>TROCO</label>
                <Input
                  style={{ cursor: 'default', fontSize: '20px', color: 'red' }}
                  disabled={true}
                  value={'0,00'}
                />
              </Col>
            </CardBody>
          </Col>
        </div>
      </Row>
    </>
  )
};


class Wizard extends React.Component {
  render() {
    return (
      <RecebimentosPagamentos />
    )
  }
}

export default Wizard;
