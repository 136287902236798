import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import * as controllerClientes from "~/controller/controllerClientes"
import classNames from "classnames";
import { Switch, InputCpfCnpj } from "~/components/ErnComponets/ErnComponets";
import { HelpButton } from "~/components/HelpPlugin/HelpPlugin";

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  ButtonGroup,
  Label,
} from "reactstrap";

import Select from "react-select";
import ArrowTooltip from "~/components/Tooltip/ArrowTooltip";

const idStore = "CLIENTES";
const tiposContribuinte = [
  { value: "0", label: "Não contribuinte" },
  { value: "1", label: "Contribuinte ICMS" },
  { value: "2", label: "Contribuinte isento" },
];
const generos = [
  { value: "M", label: "Masculino" },
  { value: "F", label: "Feminino" },
  { value: "O", label: "Outro" },
];


const Cliente = (props) => {
  const notificacao = useSelector(state => state.notificacao);
  const { pesquisa, cliente, clienteCopia, invalido, verCadastro } = useSelector(state => state.clientes);
  const dispatch = useDispatch();
  const [tipoContribuinteSelected, settipoContribuinteSelected] = useState(tiposContribuinte[0]);
  const [generoSelected, setgeneroSelected] = useState(generos[2]);

  useEffect(() => {
    if (props.salvar)
      Salvar();
  }, [props.salvar])

  useEffect(() => {
    settipoContribuinteSelected(tiposContribuinte.find((tipoContribuinte) => tipoContribuinte.value === cliente.tipoContribuinte));
  }, [cliente.tipoContribuinte])

  useEffect(() => {
    setgeneroSelected(generos.find((genero) => genero.value === cliente.sexo));
  }, [cliente.sexo])


  function onChange(value, stateName) {
    dispatch({ idStore, type: 'CLIENTE', campo: stateName, value: value });
    dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
  };

  function onChangeTipoCli(e) {
    e.preventDefault();
    if (cliente.codCli === "") {
      dispatch({ idStore, type: 'CLEAN_CLIENTE' });
      dispatch({ idStore, type: 'CLEAN_INVALIDO' });
      dispatch({ idStore, type: 'CLIENTE', campo: 'tipoCli', value: e.target.value });
    }

    if (e.target.value === 'F') {
      setTimeout(() => {
        if (cliente.codCli === '') {
          document.getElementById('nomeRazaoF').focus()
        } else {
          document.getElementById('dtNascimentoCliente').focus()
        }
      }, 100)
    } else if (e.target.value === 'J') {
      setTimeout(() => {
        if (cliente.codCli === '') {
          document.getElementById('nomeRazaoJ').focus()
        } else {
          document.getElementById('inscestCliente').focus()
        }
      }, 100)
    } else {
      setTimeout(() => {
        if (cliente.codCli === '') {
          document.getElementById('nomeEs').focus()
        } else {
          // document.getElementById('').focus()
        }
      }, 100)
    }
  }

  function onChangeTipoContribuinte(value) {
    dispatch({ idStore, type: 'CLIENTE', campo: 'tipoContribuinte', value: value.value });
  }

  function onChangeGenero(value) {
    dispatch({ idStore, type: 'CLIENTE', campo: 'sexo', value: value.value });
  }

  function onChangeAtivo(e) {
    if (cliente.codCli !== '') {
      controllerClientes.Inativar(notificacao, dispatch, cliente, pesquisa);
    }
    dispatch({ idStore, type: 'CLIENTE', campo: 'status', value: e.target.checked ? 0 : 1 });
  };

  async function Salvar() {
    var retorno = false;
    if (cliente.codCli === '') {
      retorno = await controllerClientes.CreateCliente(notificacao, dispatch, cliente);
    } else {
      retorno = await controllerClientes.UpdateCliente(notificacao, dispatch, cliente, clienteCopia);
    }

    if (retorno) {
      setProximoCadastro(true);
      setTimeout(() => {
        setProximoCadastro(false);
      }, 1000)
    }
  };

  function SelecionaTextoEmail(id) {
    document.getElementById(id).select()
  }


  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_CLIENTE' });
    dispatch({ idStore, type: 'CLEAN_ENDERECO' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });

    if (document.querySelector("#dtNascimentoCliente")) {
      document.querySelector("#dtNascimentoCliente").value = ''
    }
    if (document.querySelector("#dtEmissao")) {
      document.querySelector("#dtEmissao").value = ''
    }
  };

  function setProximoCadastro(valor) {
    dispatch({ idStore, type: 'PROXIMO_CADASTRO', value: valor });
  }
  return (
    <>
      <Row className="justify-content-center">
        <div className={verCadastro === true ? 'ver-cadastro' : ''}>

        </div>
        <Col>
          <h4 className="info-text">Preencha com as informações do cliente</h4>
          <CardBody>

            <Row className="justify-content-center">

              <Col md="10" className="text-right">
                <Switch
                  componentleft={<label className={cliente.status === 0 ? `text-success text-center` : "text-warning text-center"}>{cliente.status === 0 ? "Ativo" : "Inativo"}</label>}
                  checked={cliente.status === 0}
                  onChange={e => onChangeAtivo(e)}
                  disabled={cliente.codCli === ''}
                />
              </Col>

              <Col md="10">
                <Row>
                  <ButtonGroup>
                    <Button
                      color={cliente.tipoCli === "F" ? "info" : ""}
                      type="button"
                      className={classNames("btn-gp-padrao", cliente.tipoCli === "F" ? "active" : "")}
                      value="F"
                      onClick={e => onChangeTipoCli(e)}
                      disabled={cliente.codCli !== '' && cliente.tipoCli !== 'F' ? true : false}
                    >
                      Pessoa Física
                    </Button>
                    <Button
                      color={cliente.tipoCli === "J" ? "info" : ""}
                      type="button"
                      className={classNames("btn-gp-padrao", cliente.tipoCli === "J" ? "active" : "")}
                      value="J"
                      onClick={e => onChangeTipoCli(e)}
                      disabled={cliente.codCli !== '' && cliente.tipoCli !== 'J' ? true : false}
                    >
                      Pessoa Jurídica
                     </Button>
                    <Button
                      color={cliente.tipoCli === "E" ? "info" : ""}
                      type="button"
                      className={classNames("btn-gp-padrao", cliente.tipoCli === "E" ? "active" : "")}
                      value="E"
                      onClick={e => onChangeTipoCli(e)}
                      disabled={cliente.codCli !== '' && cliente.tipoCli !== 'E' ? true : false}
                    >
                      Pessoa Estrangeira
                     </Button>
                  </ButtonGroup>
                </Row>
              </Col>
            </Row>
            
            


            <Row className="justify-content-center">
              {cliente.tipoCli === 'F' ?
                <>

                  <Col md="10">

                    <Row>

                      <Col className="padding-padrao-col" lg md sm="7">
                        <label>Nome<span className="error"> *</span></label>
                        <FormGroup className={invalido.nomeRazao ? "has-danger" : ''}>
                          <Input
                            id="nomeRazaoF"
                            type="text"
                            value={cliente.nomeRazao}
                            onChange={e => onChange(e.target.value, "nomeRazao")}
                            autoComplete="off"
                            disabled={cliente.codCli !== '' ? true : false}
                            maxLength="100"
                          />
                          
                        </FormGroup>
                      </Col>

                      <Col className="padding-padrao-col" lg="3" md="5" sm="4">
                        <label>Data de Nascimento</label>
                        <FormGroup className={invalido.dtNascimento ? "has-danger" : ''}>
                          <Input
                            id="dtNascimentoCliente"
                            type="date"
                            value={cliente.dtNascimento}
                            onChange={e => onChange(e.target.value, "dtNascimento")}
                            autoComplete="off"
                            maxLength="10"
                          />
                          
                        </FormGroup>
                      </Col>

                      <Col className="padding-padrao-col" lg="3" md="7" sm>
                        <label>Gênero</label>
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          id="tipoContribuinteSelect"
                          value={generoSelected}
                          isSearchable={false}
                          onChange={value => onChangeGenero(value)}
                          options={generos}
                          autoComplete="off"
                          isDisabled={verCadastro === true ? true : false}
                        />
                        
                      </Col>

                    </Row>

                    <Row>

                      <Col className="padding-padrao-col" md="">
                        <label>CPF<span className="error"> *</span></label>
                        <FormGroup className={invalido.cpfCnpj ? "has-danger" : ''}>
                          <InputCpfCnpj
                            id="cpfCnpj"
                            tipo="cpf"
                            value={cliente.cpfCnpj}
                            onChange={e => onChange(e.target.value, "cpfCnpj")}
                            autoComplete="off"
                            disabled={cliente.codCli !== '' ? true : false}
                            maxLength="14"
                          />
                          
                        </FormGroup>
                      </Col>

                      <Col className="padding-padrao-col" md="">
                        <label>RG</label>
                        <FormGroup>
                          <Input
                            id="rgInscMunic"
                            type="text"
                            value={cliente.rgInscMunic}
                            onChange={e => onChange(e.target.value, "rgInscMunic")}
                            autoComplete="off"
                            maxLength="30"
                          />
                          
                        </FormGroup>
                      </Col>

                      <Col className="padding-padrao-col" md="2">
                        <label style={{ width: '100px' }}>Orgão Emissor</label>
                        <FormGroup>
                          <Input
                            id="orgaoEmissor"
                            type="text"
                            value={cliente.orgaoEmissor}
                            onChange={e => onChange(e.target.value, "orgaoEmissor")}
                            autoComplete="off"
                            maxLength="5"
                          />
                          
                        </FormGroup>
                      </Col>

                      <Col className="padding-padrao-col" lg="3" md="3">
                        <label>Data de Emissão</label>
                        <FormGroup className={invalido.dtEmissao ? "has-danger" : ''}>
                          <Input
                            id="dtEmissao"
                            type="date"
                            value={cliente.dtEmissao}
                            onChange={e => onChange(e.target.value, "dtEmissao")}
                            autoComplete="off"
                            maxLength="10"
                          />
                          
                        </FormGroup>
                      </Col>

                    </Row>

                    <Row>

                      <Col className="padding-padrao-col" lg md="4">
                        <label>E-mail Primário<span className="error"> *</span>{" "}</label>
                        <FormGroup className={invalido.email ? "has-danger" : ''}>
                          <Input
                            id="email"
                            type="text"
                            value={cliente.email}
                            onChange={e => onChange(e.target.value, "email")}
                            autoComplete="off"
                            maxLength="70"
                          />
                          
                        </FormGroup>
                      </Col>

                      <Col className="padding-padrao-col" lg md="4">
                        <label>E-mail Secundário{" "}</label>
                        <FormGroup className={invalido.email2 ? "has-danger" : ''}>
                          <Input
                            id="email2"
                            type="text"
                            value={cliente.email2}
                            onChange={e => onChange(e.target.value, "email2")}
                            onFocus={e => SelecionaTextoEmail(e.target.id)}
                            autoComplete="off"
                            maxLength="70"
                          />
                          
                        </FormGroup>
                      </Col>

                      <Col md="3" sm className="text-center padding-padrao-col">
                        <label>Receber Ofertas</label>
                        <FormGroup check className="form-check-radio">

                          <Label check style={{ paddingRight: '5px' }}>
                            <Input type="radio"
                              value="S"
                              onChange={e => onChange(e.target.value, "flReceberEmail")}
                              checked={cliente.flReceberEmail === 'S' ? true : false}

                            />
                            <span className="form-check-sign" />Sim
                          </Label>

                          <Label check style={{ paddingRight: '5px' }}>
                            <Input type="radio"
                              value="N"
                              onChange={e => onChange(e.target.value, "flReceberEmail")}
                              checked={cliente.flReceberEmail === 'N' ? true : false}

                            />
                            <span className="form-check-sign" />Não
                          </Label>

                        </FormGroup>
                        
                      </Col>

                    </Row>

                    <Row>

                      <Col className="padding-padrao-col" lg="3" md="5">
                        <div className={"left-help-button"}>
                          <label className="contribuinte">Tipo Contribuinte</label>
                          <HelpButton id="5" />
                        </div>
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          id="tipoContribuinteSelect"
                          value={tipoContribuinteSelected}
                          isSearchable={false}
                          onChange={value => onChangeTipoContribuinte(value)}
                          options={tiposContribuinte}

                        />
                      </Col>

                    </Row>

                  </Col>
                </>
                :
                cliente.tipoCli === "J" ?
                  <>
                    <Col md="10">

                      <Row>

                        <Col className="padding-padrao-col" lg md sm>
                          <label>Razão Social<span className="error">*</span></label>
                          <FormGroup className={invalido.nomeRazao ? "has-danger" : ''}>
                            <Input
                              id="nomeRazaoJ"
                              type="text"
                              value={cliente.nomeRazao}
                              onChange={e => onChange(e.target.value, "nomeRazao")}
                              autoComplete="off"
                              disabled={cliente.codCli !== '' ? true : false}
                              maxLength="100"
                            />
                          </FormGroup>
                        </Col>


                        <Col className="padding-padrao-col" lg md sm>
                          <label>Nome Fantasia<span className="error">*</span></label>
                          <FormGroup className={invalido.fantasia ? "has-danger" : ''}>
                            <Input
                              id="fantasia"
                              type="text"
                              value={cliente.fantasia}
                              onChange={e => onChange(e.target.value, "fantasia")}
                              autoComplete="off"
                              disabled={cliente.codCli !== '' ? true : false}
                              maxLength="50"
                            />
                          </FormGroup>
                        </Col>


                        <Col className="padding-padrao-col" md="3" sm="5">
                          <label>CNPJ<span className="error">*</span></label>
                          <FormGroup className={invalido.cpfCnpj ? "has-danger" : ''}>
                            <InputCpfCnpj
                              id="cpfCnpj"
                              tipo="cnpj"
                              value={cliente.cpfCnpj}
                              onChange={e => onChange(e.target.value, "cpfCnpj")}
                              autoComplete="off"
                              disabled={cliente.codCli !== '' ? true : false}
                              maxLength="18"
                            />
                          </FormGroup>
                        </Col>

                      </Row>

                      <Row>

                        <Col className="padding-padrao-col" lg md sm>
                          <FormGroup>
                            <label>Inscrição Estadual{" "}</label>
                            <Input
                              id="inscestCliente"
                              type="text"
                              value={cliente.inscest}
                              onChange={e => onChange(e.target.value, "inscest")}
                              autoComplete="off"
                              maxLength="20"
                            />
                          </FormGroup>
                        </Col>

                        <Col className="padding-padrao-col" lg md sm>
                          <FormGroup>
                            <label>Inscrição Municipal</label>
                            <Input
                              id="rgInscMunic"
                              type="text"
                              value={cliente.rgInscMunic}
                              onChange={e => onChange(e.target.value, "rgInscMunic")}
                              autoComplete="off"
                              maxLength="30"
                            />
                          </FormGroup>
                        </Col>

                        <Col className="padding-padrao-col" md sm >
                          <label>Tipo Contribuinte</label>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            id="tipoContribuinteSelect"
                            value={tipoContribuinteSelected}
                            isSearchable={false}
                            onChange={value => onChangeTipoContribuinte(value)}
                            options={tiposContribuinte}

                          />
                        </Col>


                      </Row>

                      <Row>

                        <Col className="padding-padrao-col" lg md="auto" sm>
                          <label>E-mail Primário<span className="error">*</span>{" "}</label>
                          <FormGroup className={invalido.email ? "has-danger" : ''}>
                            <Input
                              id="email"
                              type="text"
                              value={cliente.email}
                              onChange={e => onChange(e.target.value, "email")}
                              autoComplete="off"
                              maxLength="70"
                            />
                          </FormGroup>
                        </Col>

                        <Col className="padding-padrao-col" lg md="auto" sm>
                          <label>E-mail Secundário{" "}</label>
                          <FormGroup className={invalido.email2 ? "has-danger" : ''}>
                            <Input
                              id="email2"
                              type="text"
                              value={cliente.email2}
                              onChange={e => onChange(e.target.value, "email2")}
                              onFocus={e => SelecionaTextoEmail(e.target.id)}
                              autoComplete="off"
                              maxLength="70"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="3" sm className="text-center padding-padrao-col">
                          <label>Receber Ofertas</label>
                          <FormGroup check className="form-check-radio">

                            <Label check style={{ paddingRight: '5px' }}>
                              <Input type="radio"
                                value="S"
                                onChange={e => onChange(e.target.value, "flReceberEmail")}
                                checked={cliente.flReceberEmail === 'S' ? true : false}

                              />
                              <span className="form-check-sign" />Sim
                          </Label>

                            <Label check style={{ paddingRight: '5px' }}>
                              <Input type="radio"
                                value="N"
                                onChange={e => onChange(e.target.value, "flReceberEmail")}
                                checked={cliente.flReceberEmail === 'N' ? true : false}

                              />
                              <span className="form-check-sign" />Não
                          </Label>

                          </FormGroup>
                          
                        </Col>

                      </Row>

                    </Col>

                  </>
                  :
                  <>
                    <Col md='10'>
                      <Row className="justify-content-center" >

                        <Col className="padding-padrao-col" lg md sm>
                          <label>Nome<span className="error">*</span></label>
                          <FormGroup className={invalido.nomeEstrangeiro ? "has-danger" : ''}>
                            <Input
                              id="nomeEs"
                              type="text"
                              value={cliente.nomeEstrangeiro}
                              onChange={e => onChange(e.target.value, "nomeEstrangeiro")}
                              autoComplete="off"
                              disabled={cliente.codCli !== '' ? true : false}
                              maxLength="100"
                            />
                          </FormGroup>
                        </Col>


                        <Col className="padding-padrao-col" lg md sm>
                          <label>Documento<span className="error">*</span></label>
                          <FormGroup className={invalido.documentoEstrangeiro ? "has-danger" : ''}>
                            <Input
                              id="documentoEs"
                              type="text"
                              value={cliente.documentoEstrangeiro}
                              onChange={e => onChange(e.target.value, "documentoEstrangeiro")}
                              autoComplete="off"
                              disabled={cliente.codCli !== '' ? true : false}
                              maxLength="50"
                            />
                          </FormGroup>
                        </Col>


                      </Row>
                      <Row>
                        <Col className="padding-padrao-col" lg md sm>
                          <label>E-mail<span className="error">*</span></label>
                          <FormGroup className={invalido.emailEstrangeiro ? "has-danger" : ''}>
                            <Input
                              id="emailEs"
                              type="text"
                              value={cliente.emailEstrangeiro}
                              onChange={e => onChange(e.target.value, "emailEstrangeiro")}
                              autoComplete="off"
                              disabled={cliente.codCli !== '' ? true : false}
                              maxLength="50"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="padding-padrao-col" lg="3" md="7" sm>
                          <label>Gênero</label>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            id="tipoContribuinteSelect"
                            value={generoSelected}
                            isSearchable={false}
                            onChange={value => onChangeGenero(value)}
                            options={generos}
                            autoComplete="off"
                            isDisabled={verCadastro === true ? true : false}
                          />
                          
                        </Col>
                      </Row>
                    </Col>
                  </>
              }

            </Row>

          </CardBody>
          <CardFooter >
            <Col style={{ display: "flex", textAlign: 'center' }}>
              <ArrowTooltip title='Shift+L'>
                <Button disabled={verCadastro} style={{ left: "33%", position: "relative" }} className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button>
              </ArrowTooltip>
              <ArrowTooltip title='Shift+S'>
                <Button disabled={verCadastro} style={{ left: "33%", position: "relative" }} className="btn-padrao" color="info" onClick={(e) => { Salvar(); e.preventDefault(); }}>Salvar</Button>
              </ArrowTooltip>
            </Col>
          </CardFooter>
        </Col>
      </Row>
    </>
  )
};

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salvar: false
    };
  }

  focusInput = () => {
    setTimeout(() => {
      if (this.props.cliente.tipoCli === 'F') {
        if (document.getElementById('nomeRazaoF')) {
          if (this.props.cliente.codCli !== '') {
            document.getElementById('dtNascimentoCliente').focus()
          } else {
            document.getElementById('nomeRazaoF').focus()
          }
        }
      } else {
        if (document.getElementById('nomeRazaoJ')) {
          if (this.props.cliente.codCli !== '') {
            document.getElementById('inscestCliente').focus()
          } else {
            document.getElementById('nomeRazaoJ').focus()
          }
        }
      }
    }, 500)
  }

  isValidated = (step = 0, finish = false) => {
    if (step > 0) {
      return true
    }
    if (!finish) {
      this.setState({ salvar: true }, () => {
        this.setState({ salvar: false })
      })
    }
    return false
  }

  render() {
    return (
      <Cliente salvar={this.state.salvar} />
    )
  }
}

export default Wizard;
