//State da tela de Pesquisa e cadastro de NCMs
var INITIAL_STATE = {
    pesquisa: {
        ncm: '',
        descricao: '',
        dataDescontinuado:'',
        ativos: 0,
    },
    ncm: {
        ncm: '',
        descricao: '',
        dataDescontinuado:'',
        update: false,
    },
    invalido: {
        codSeguimento: false,
        ncm: false,
        descricao: false,
    },
    ncms: [],
    ncmCopia: {},
    inicioCadastro: false,
    verCadastro: false,
};

export default function ncms(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'NCMS':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'NCMS':
                    return { ...state, ncms: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: INITIAL_STATE.pesquisa,
                        ncms: INITIAL_STATE.ncms
                    }
                case 'NCM':
                    return { ...state, ncm: { ...state.ncm, [action.campo]: action.value } }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value } 
                case 'NCM_COPIA':
                    return { ...state, ncmCopia: state.ncm }
                case 'CLEAN_NCM':
                    return {
                        ...state,
                        ncm: INITIAL_STATE.ncm,
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: INITIAL_STATE.invalido,
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}