//State da tela de Pesquisa e cadastro de subgrupo de contas
var INITIAL_STATE = {
    pesquisa: {
        codGrupoConta: '0',
        descricao: '',
        descGrupoConta: '',
        tipoGrupo: '',
        ativos: '',
    },
    subgrupoConta: {
        codSubgrupoConta: 0,
        codGrupoConta: '0',
        descricao: '',
        observacao: '',
        status: 0,
    },
    invalido: {
        codGrupoConta: false,
        descricao: false,
    },
    subgrupoContas: [],
    grupoContas: [],
    inicioCadastro: false,
    verCadastro: false,
    subgrupoContaCopia: {}
};

export default function subgrupoContasStore(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'SUBGRUPO_CONTAS':
            switch (action.type) {
                case 'PESQUISA':
                    return {...state, pesquisa: {...state.pesquisa, [action.campo]: action.value } }
                case 'SUBGRUPO_CONTAS':
                    return {...state, subgrupoContas: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: INITIAL_STATE.pesquisa,
                        subgrupoContas: []
                    }
                case 'SUBGRUPO_CONTA':
                    return {...state, subgrupoConta: {...state.subgrupoConta, [action.campo]: action.value } }
                case 'SUBGRUPOS_CONTA':
                    return {...state, subgrupoConta: action.value }
                case 'GRUPO_CONTAS':
                    return {...state, grupoContas: action.value }
                case 'INVALIDO':
                    return {...state, invalido: {...state.invalido, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return {...state, inicioCadastro: action.value }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value } 
                case 'SUBGRUPO_CONTA_COPIA':
                    return {...state, subgrupoContaCopia: state.subgrupoConta }
                case 'CLEAN_SUBGRUPO_CONTA':
                    return {
                        ...state,
                        subgrupoConta: INITIAL_STATE.subgrupoConta
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: INITIAL_STATE.invalido
                    }
                default:
                    return {...state }
            }
        default:
            return {...state }
    }
}