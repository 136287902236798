import store from '../../../stores';
import {
    Destinatario,
    SaidaDiversaAction,
    SaidaDiversaStore,
    SaidaDiversaTypes,
    Pesquisa,
    Transportadora,
    Produto,
    ProdutoCopia,
    Saida,
    SaidaDiversaState,
    Operacao,
    Empresas,
} from './types';

export const setPesquisa = (pesquisa: Pesquisa) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.PESQUISA,
    pesquisa
});

export const setDadosDestinatario = (destinatario: Destinatario) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.DESTINATARIO,
    destinatario
});

export const setDadosDestinatarioCopia = (destinatario: Destinatario) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.DESTINATARIO_COPIA,
    destinatario
});

export const setDestinatariosList = (list: any[]) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.DESTINATARIOS,
    list
});

export const setDadosTransportadora = (transportadora: Transportadora) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.TRANSPORTADORA,
    transportadora
});

export const setDadosTransportadoraCopia = (transportadora: Transportadora) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.TRANSPORTADORA_COPIA,
    transportadora
});

export const setTransportadoraList = (list: any[]) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.TRANSPORTADORAS,
    list
})

export const setDadosAdicionais = (value: string) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.ADICIONAL,
    value
})

export const setDadosAdicionaisCopia = (value: string) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.ADICIONAL_COPIA,
    value
})

export const setProduto = (produto: Produto) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.PRODUTO,
    produto
});

export const setProdutoGrades = (value: any) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.PRODUTO_GRADES,
    value
});

export const setValorTotal = (campo: string, value: any) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.VALOR_TOTAL,
    campo,
    value
});

export const addProduto = (list: Produto[]) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.PRODUTO_LIST,
    list
});

export const addProdutoCopia = (list: ProdutoCopia[]) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.PRODUTO_LIST_COPIA,
    list
});

export const cleanAll = () => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.CLEAN_ALL
});

export const cleanPesquisa = () => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.CLEAN_PESQUISA
});

export const cleanCadastro = () => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.CLEAN_CADASTRO
});

export const cleanReferencia = () => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.CLEAN_REFERENCIA
});

export const cleanDadosIniciais = () => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.CLEAN_DADOS_INICIAIS
});
export const cleanProduto = () => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.CLEAN_PRODUTO
});

export const updatePedido = (idPedido: number) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.UPDATE_SAIDA,
    value: idPedido
});

export const updatePedidoFatura = (idFatura: number) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.UPDATE_FATURA,
    value: idFatura
});

export const setPopUp = (value: boolean) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.POP_UP,
    value
});

export const setInvalidos = (invalido: SaidaDiversaState['invalido']) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.INVALIDO,
    invalido
});

export const setScreen = (screen: number) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.SCREEN,
    value: screen
})

export const setOperacao = (operacao: Operacao) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.SET_OPERACAO,
    operacao
});

export const setOperacaoCopia = (operacao: Operacao) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.SET_OPERACAO_COPIA,
    operacao
});

export const setModalOperacao = (value: boolean) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.SET_MODAL_OPERACAO,
    value
});

export const setModalProdutoGrades = (value: boolean) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.SET_MODAL_PRODUTO_GRADES,
    value
});

export const setGradeSelecionada = (value: any) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.SET_GRADE_SELECIONADA,
    value,
})

export const setEmpresas = (value: Empresas[]) => store.dispatch<SaidaDiversaAction>({
    idStore: SaidaDiversaStore.ID_STORE,
    type: SaidaDiversaTypes.SET_EMPRESAS,
    value
})
