import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";

const idStore = 'GRUPOS_PRODUTO';

export function Pesquisar(notificacao, dispatch, codEmpresa, pesquisa) {
    try {
        dispatch({ idStore, type: 'GRUPOS', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/grupoProduto/grupos?codEmpresa=${codEmpresa}&status=${pesquisa.ativos}&nome=${pesquisa.nome}&descricao=${pesquisa.descricao}&tipo=${pesquisa.tipo}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum grupo encontrado", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'GRUPOS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os grupos", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetGrupo(notificacao, dispatch, codEmpresa, codGrupo, thenFunction) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/grupoProduto/grupo?codEmpresa=${codEmpresa}&codGrupo=${codGrupo}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            let type = 'GRUPO';
            dispatch({ idStore, type, campo: 'codGrupo', value: response.data.records.codGrupo })
            dispatch({ idStore, type, campo: 'nome', value: response.data.records.nome })
            dispatch({ idStore, type, campo: 'descricao', value: response.data.records.descricao })
            dispatch({ idStore, type, campo: 'tipo', value: response.data.records.tipo })
            dispatch({ idStore, type, campo: 'status', value: response.data.records.status })
            dispatch({ idStore, type: 'GRUPO_COPIA' })
            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export async function CreateGrupo(notificacao, dispatch, codEmpresa, grupo) {
    try {
        //Retorna se houve erro ou nao
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;
        if (grupo.tipo === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'tipo', value: true })
            alertar(notificacao, 'br', "Selecione o tipo", 'info', 'icon-bulb-63', 2);
            return true
        }
        if (grupo.nome === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'nome', value: true })
            invalido = true
        }
        if (grupo.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/grupoProduto/createGrupo?codEmpresa=${codEmpresa}`,
            headers: { Authorization: basicAuth },
            data: {
                Nome: grupo.nome,
                Descricao: grupo.descricao,
                Tipo: grupo.tipo,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'GRUPO', campo: 'codGrupo', value: response.data.records })
            dispatch({ idStore, type: 'GRUPO_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function UpdateGrupo(notificacao, dispatch, codEmpresa, grupo, grupoCopia) {
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(grupo) === JSON.stringify(grupoCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;
        if (grupo.tipo === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'tipo', value: true })
            alertar(notificacao, 'br', "Selecione o tipo", 'info', 'icon-bulb-63', 2);
            return true
        }
        if (grupo.nome === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'nome', value: true })
            invalido = true
        }
        if (grupo.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/grupoProduto/updateGrupo?codEmpresa=${codEmpresa}&codGrupo=${grupo.codGrupo}`,
            headers: { Authorization: basicAuth },
            data: {
                Nome: grupo.nome,
                Descricao: grupo.descricao,
                Tipo: grupo.tipo
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'GRUPO', campo: 'codGrupo', value: response.data.records })
            dispatch({ idStore, type: 'GRUPO_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export function Inativar(notificacao, dispatch, codEmpresa, grupo, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/grupoProduto/inativarGrupo?codEmpresa=${codEmpresa}&codGrupo=${grupo.codGrupo}&status=${grupo.status}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'GRUPO_COPIA_STATUS', value: grupo.status === 1 ? 0 : 1 })
            Pesquisar(notificacao, dispatch, codEmpresa, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function Excluir(notificacao, dispatch, codEmpresa, grupo, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/grupoProduto/excluirGrupo?codEmpresa=${codEmpresa}&codGrupo=${grupo.codGrupo}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar(notificacao, dispatch, codEmpresa, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function ExisteVinculo(notificacao, dispatch, codEmpresa, grupo) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios.get(api.urlCadastro + `api/v1/subgrupoProduto/subgrupos?codEmpresa=${codEmpresa}&codGrupo=${grupo.codGrupo}&status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length !== 0) {
                dispatch({ idStore, type: 'VINCULOS', value: response.data.records.length })
                return
            }
            dispatch({ idStore, type: 'VINCULOS', value: 0 })
        }).catch(e => {
            alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
            dispatch({ idStore, type: 'VINCULOS', value: false })
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        dispatch({ idStore, type: 'VINCULOS', value: false })

    }
}