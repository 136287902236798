import React from "react"
import {
    Button,
    FormGroup,
    Input
} from "reactstrap"
import ArrowTooltip from "../../../../../../../../components/Tooltip/ArrowTooltip"
import { IUsuarioState } from "../../../../../../../../store/reducers/Cadastro/Usuario/types"
import AppState from "../../../../../../../../store/storeType"
import useShallowEqualSelector from "../../../../../../../../Util/Hooks/useShallowEqualSelector"

interface InputProps {
    state: any
    name: any,
    value: any,
    className?: any,
    id?: string,
    isDisabled?: boolean,
    onChange?: Function | any,
    length?: number,
    type?: any,
}

interface ButtonProps {

    onClick: Function | any,
    color: string,
    className: string,
    label: string,
    arrowTooltip?: boolean,
    arrowTooltipTitle?: any
}

export const InputFuncionario = (Props: InputProps) => {

    const funcionario = useShallowEqualSelector<AppState, IUsuarioState['funcionario']>(state => state.usuario.funcionario)

    return (
        <>
            <FormGroup className={Props.className}>

                <Input
                    id={Props.id}
                    name={Props.name}
                    value={Props.value}
                    type={Props.type}
                    disabled={Props.isDisabled}
                    onChange={Props.onChange}
                    maxLength={Props.length}
                />

            </FormGroup>
        </>
    )

}


export const ButtonFuncionario = (props: ButtonProps) => {
    return (
        <>

            {props.arrowTooltip ?

                <ArrowTooltip title={props.arrowTooltipTitle}>
                    <Button
                        className={props.className}
                        color={props.color}
                        onClick={() => props.onClick()}
                    >{props.label}
                    </Button>
                </ArrowTooltip>

                :

                <Button
                    className={props.className}
                    color={props.color}
                    onClick={() => props.onClick()}
                >{props.label}
                </Button>

            }
        </>
    )
}

