import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
const idStore = 'CONTAS';

export function Pesquisar(notificacao, dispatch, pesquisa) {
    try {
        dispatch({ idStore, type: 'PESQUISA', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/conta/Contas?descricao=${pesquisa.descricao}&status=${pesquisa.status}&flPedido=${pesquisa.flPedido}&flLancTitulo=${pesquisa.flLancTitulo}&flBaixaTitulo=${pesquisa.flBaixaTitulo}&flEntradaMerc=${pesquisa.flEntradaMerc}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma conta encontrada", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'CONTAS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as contas", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetConta(notificacao, dispatch, codConta) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios.get(api.urlCadastro + `api/v1/conta/Conta?codConta=${codConta}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {

            dispatch({ idStore, type: 'CONTA', campo: 'codConta', value: response.data.records.codConta })
            dispatch({ idStore, type: 'CONTA', campo: 'descricao', value: response.data.records.descricao })
            dispatch({ idStore, type: 'CONTA', campo: 'status', value: response.data.records.status })

            dispatch({ idStore, type: 'FLAGS', campo: 'flPedido', value: response.data.records.flPedido })
            dispatch({ idStore, type: 'FLAGS', campo: 'flLancTitulo', value: response.data.records.flLancTitulo })
            dispatch({ idStore, type: 'FLAGS', campo: 'flBaixaTitulo', value: response.data.records.flBaixaTitulo })
            dispatch({ idStore, type: 'FLAGS', campo: 'flEntradaMerc', value: response.data.records.flEntradaMerc })

            dispatch({ idStore, type: 'CONTA_COPIA' })
            dispatch({ idStore, type: 'FLAGS_COPIA' })

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter a conta", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function CreateConta(notificacao, dispatch, conta, flags) {
    try {
        //Retorna se houve erro ou nao
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;

        if (conta.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }
        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/conta/Create`,
            headers: { Authorization: basicAuth },
            data: {
                Descricao: conta.descricao,
                FlPedido: flags.flPedido,
                FlLancTitulo: flags.flLancTitulo,
                FlBaixaTitulo: flags.flBaixaTitulo,
                FlEntradaMerc: flags.flEntradaMerc
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'CONTA', campo: 'codConta', value: response.data.records })
            dispatch({ idStore, type: 'CONTA_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível criar a conta", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function UpdateConta(notificacao, dispatch, conta, contaCopia, flags, flagsCopia) {
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(conta) === JSON.stringify(contaCopia) && JSON.stringify(flags) === JSON.stringify(flagsCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;

        if (conta.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/conta/Update?codConta=${conta.codConta}`,
            headers: { Authorization: basicAuth },
            data: {
                Descricao: conta.descricao,
                FlPedido: flags.flPedido,
                FlLancTitulo: flags.flLancTitulo,
                FlBaixaTitulo: flags.flBaixaTitulo,
                FlEntradaMerc: flags.flEntradaMerc

            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'CONTA', campo: 'codConta', value: response.data.records })
            dispatch({ idStore, type: 'CONTA_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível atualizar a conta", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export function Inativar(notificacao, dispatch, conta, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/conta/Inativar?codConta=${conta.codConta}&status=${conta.status}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'CONTA', campo: 'status', value: parseInt(response.data.records) })
            Pesquisar(notificacao, dispatch, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function Excluir(notificacao, dispatch, conta, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/conta/Excluir?&codConta=${conta.codConta}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar(notificacao, dispatch, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

