import React, { useState } from "react";
import classNames from "classnames";

import ReactWizard from "~/components/Wizard/Wizard";
import store from '../../../../../store/stores'

import {
    Col
} from "reactstrap";

import Step1 from "../../Steps/DadosIniciais/index"
import Step2 from "../../Steps/Item/index"
import Step3 from "../../Steps/Totais/index"
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";

var steps = [
    {
        stepName: "Dados Iniciais",
        stepIcon: "tim-icons icon-notes",
        component: Step1,
        stepProps: {}
    },
    {
        stepName: "Itens",
        stepIcon: "tim-icons icon-notes",
        component: Step2,
        stepProps: {}
    },
    {
        stepName: "Totais",
        stepIcon: "tim-icons icon-notes",
        component: Step3,
        stepProps: {}
    },

];

const CadastroEntrada = function () {
    const { inicioCadastro, currentStep } = useShallowEqualSelector(state => state.entradaMercadoria)
    const [width] = useState(window.innerWidth);

    return (
        <Col lg="12">
            <div style={{ marginTop: '-20px' }}>
                <ReactWizard
                    steps={steps}
                    navSteps={true}
                    title=""
                    description=""
                    validate
                    resizable={true}
                    previousButtonText="Voltar"
                    finishButtonText="Finalizar"
                    nextButtonText="Avançar"
                    goToFirstStep={inicioCadastro}
                    newCurrentStep={currentStep}
                    setNewCurrentStep={(nextStep) => { store.dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'WIZARD_STEP', value: nextStep }) }}
                    finishButtonClick={() => { }}
                    finishButtonClasses={classNames("btn-wd btn-info",
                        {
                            finishButtonMin:
                                width < 550
                        },
                        {
                            finishButtonRight:
                                !width < 550
                        }
                    )}
                    nextButtonClasses={classNames("btn-wd btn-info",
                        {
                            nextButtonMin:
                                width < 550
                        },
                        {
                            nextButtonRight:
                                !width < 550
                        }
                    )}
                    previousButtonClasses={classNames("btn-wd",
                        {
                            previousButtonMin:
                                width < 550
                        },
                        {
                            previousButtonRight:
                                !width < 550
                        }
                    )}
                    progressbar
                    color="blue"
                />
            </div>

        </Col>
    );
}

export default CadastroEntrada;