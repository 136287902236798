import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import classNames from "classnames";

import './styles.css'

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";

import useScrollBar from '../../../../Util/Hooks/useScrollBar';
import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector';

import * as controllerProdutos from '~/controller/controllerProdutos';
import * as actions from '../../../../store/reducers/Cadastro/Produto/actions';

const Classificacao = ({ modalClassificacoes, toggleModalClassificacoes, classificacoes, shouldClean }) => {
    const tabelaClassificacoes = useScrollBar([modalClassificacoes])

    const { codEmpresa } = useShallowEqualSelector(state => state.login.usuario.empresa)
    const dadosTributarios = useShallowEqualSelector(state => state.produtos.produto.dadosTributarios)

    const [pesquisa, setpesquisa] = useState({ descricao: '' })
    const [classificacoesSelected, setclassificacoesSelected] = useState({ codClassificacao: 0 })
    const [listClassificacoes, setlistClassificacoes] = useState([])


    useEffect(() => {
        setlistClassificacoes(validaList(classificacoes))
    }, [classificacoes])

    useEffect(() => {
        if (shouldClean) {
            actions.setClassificacoes([])
            setpesquisa({ ...pesquisa, descricao: '' })
        }
    }, [shouldClean])

    function handleOnChangeDescricao(value) {
        setpesquisa({ ...pesquisa, descricao: value })
    }

    function onClickClassificacao(classificacao) {
        setclassificacoesSelected({ ...classificacoesSelected, codClassificacao: classificacao.codClassificacao })

        setpesquisa({ ...pesquisa, descricao: classificacao.descricao })
        actions.setProdutoDadosTributarios({ ...dadosTributarios, codClassificacao: classificacao.codClassificacao })
    }

    function handlePesquisar() {
        controllerProdutos.GetClassificacoes(codEmpresa, pesquisa, 0)
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            handlePesquisar()
        }
    }

    function handleLimpar() {
        actions.setProdutoDadosTributarios({ ...dadosTributarios, codClassificacao: '' })
        actions.setClassificacoes([])
        setpesquisa({ ...pesquisa, descricao: '' })
        setclassificacoesSelected({ ...classificacoesSelected, codClassificacao: '' })
    }

    function validaList(list) {
        let arr = []

        list.forEach((value) => {
            if (!arr.find(item => value.codClassificacao === item.codClassificacao)) {
                arr.push(value)
            }
        })

        return arr
    }

    return (
        <ModalBasic
            isOpen={modalClassificacoes}
            toggle={() => toggleModalClassificacoes()}
            title={'Classificação Tributária'}
            size="lg"
        >
            <ModalBasicBody>
                <Col>

                    <Row>
                        <Col>
                            <label>Descrição</label>
                            <Input
                                value={pesquisa.descricao}
                                onChange={(e) => handleOnChangeDescricao(e.target.value)}
                                onKeyPress={handleKeyPress}
                                maxLength={'500'}
                            />
                        </Col>

                        <Col lg="auto">
                            <Button
                                style={{ marginTop: '34px', width: '40px', marginBottom: '0px' }}
                                type='button'
                                color="info"
                                onClick={() => handlePesquisar()}
                            >
                                <Icon icon="search" className="fa-lg" />
                            </Button>
                        </Col>
                    </Row>

                    <fieldset className="fieldList">
                        <legend>Resultados da pesquisa</legend>
                        <div id="tabelaClassificacoes" className="tabelaList" ref={tabelaClassificacoes}>

                            {listClassificacoes.map((classificacao, key) => (
                                <label
                                    key={key}
                                    className={classNames("labelList", {
                                        labelListSelected: classificacao.codClassificacao === classificacoesSelected.codClassificacao
                                    })}
                                    onClick={() => onClickClassificacao(classificacao)}
                                >
                                    {classificacao.descricao}
                                </label>
                            ))}
                        </div>
                    </fieldset>
                </Col>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                color="info"
                                type="button"
                                onClick={handleLimpar}
                                style={{ width: '100px' }}
                            >Limpar
                            </Button>

                            <Button
                                color="success"
                                type="button"
                                onClick={() => toggleModalClassificacoes()}
                                style={{ width: '100px' }}
                            >OK
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>

        </ModalBasic>
    )
}

export default Classificacao