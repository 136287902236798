import React, { useState, useRef, useEffect } from 'react';

import { 
    Button, 
    Col, 
    Row, 
    Modal,
    ModalBody, 
    ModalFooter
} from 'reactstrap';
import * as action from '../../../../store/reducers/Movimentacao/SaidaDiversa/actions';

interface Props {
  isOpen: boolean,
  callback: Function
  toggle?: Function
}

export const PopUp = ({ isOpen, callback }: Props) => {
    return (
      <Modal
      isOpen={isOpen}
      toggle={() => action.setPopUp(false) }
      centered={true}
      backdropClassName={'backdropTeste'}
    >
      <div className="modal-header justify-content-center">
        <button
          aria-hidden={true}
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => action.setPopUp(false) }
        >
          <i className="tim-icons icon-simple-remove" />
        </button>
        <h6 className="title title-up">Não foi possível prosseguir com o faturamento</h6>
      </div>
      <ModalBody>

        <Col className="text-center">
          <label>Esta Sáida ainda não foi salva. Clique em <b>'SALVAR'</b> para prosseguir com o faturamento.</label>
        </Col>

      </ModalBody>
      <ModalFooter>
        <Row style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
            <Button
              color="danger"
              type="button"
              onClick={() => action.setPopUp(false) }
              style={{ width: '150px' }}
            >
                CANCELAR
            </Button>
            <Button
              color="info"
              type="button"
              onClick={() => callback()}
              style={{ width: '150px' }}
            >
                SALVAR
            </Button>
        </Row>
      </ModalFooter>
    </Modal>
    )
}

export const PopUpDelete = ({ isOpen, callback, toggle }: Props) => {
  return (
    <Modal
    isOpen={isOpen}
    toggle={() => toggle && toggle() }
    centered={true}
    backdropClassName={'backdropTeste'}
  >
    <div className="modal-header justify-content-center">
      <button
        aria-hidden={true}
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => toggle && toggle() }
      >
        <i className="tim-icons icon-simple-remove" />
      </button>
      <h6 className="title title-up">Excluír Sáida</h6>
    </div>
    <ModalBody>

      <Col className="text-center">
        <label>Ao <b>excluir</b> esta saída, será excluído <b>todos</b> os Titulos relacionados à mesma. Deseja continuar?</label>
      </Col>

    </ModalBody>
    <ModalFooter>
      <Row style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
          <Button
            color="danger"
            type="button"
            onClick={() => toggle && toggle() }
            style={{ width: '150px' }}
          >
              CANCELAR
          </Button>
          <Button
            color="info"
            type="button"
            onClick={() => callback()}
            style={{ width: '150px' }}
          >
              CONTINUAR
          </Button>
      </Row>
    </ModalFooter>
  </Modal>
  )
}