//State da tela de Pesquisa e cadastro de Grupo de Contas
var INITIAL_STATE = {
    pesquisa: {
        codGrupoConta: 0,
        descricao: '',
        tipo: '0',
        status: '',
    },
    cadastro: {
        codGrupoConta: 0,
        descricao: '',
        tipo: '0',
        status: 0,
    },
    invalido: {
        descricao: false,
        tipo: false,
    },
    grupoContas: [],
    cadastroCopia: {},
    inicioCadastro: false,
    verCadastro: false,
};

export default function grupoContas(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'GRUPO_CONTAS':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'GRUPO_CONTAS':
                    return { ...state, grupoContas: action.value }
                case 'CADASTRO':
                    return { ...state, cadastro: { ...state.cadastro, [action.campo]: action.value } }
                case 'CADASTROS':
                    return { ...state, cadastro: action.value}
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value } 
                case 'CADASTRO_COPIA':
                    return { ...state, cadastroCopia: state.cadastro }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: INITIAL_STATE.pesquisa,
                        grupoContas: [],
                    }
                case 'CLEAN_CADASTRAR':
                    return {
                        ...state,
                        cadastro: INITIAL_STATE.cadastro,
                    }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: INITIAL_STATE.invalido,
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}