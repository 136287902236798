import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";

const idStore = 'ENQUADRAMENTOS_IPI';

export function Pesquisar(notificacao, dispatch, codEmpresa, pesquisa) {
    try {
        dispatch({ idStore, type: 'ENQUADRAMENTOS', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/enquadramentoIPI/enquadramentos?codEmpresa=${codEmpresa}&status=${pesquisa.ativos}&descricao=${pesquisa.descricao}&enquadramento=${pesquisa.enquadramento}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum enquadramento encontrado", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'ENQUADRAMENTOS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os enquadramentos", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function Get(notificacao, dispatch, codEmpresa, codEnquadramento, thenFunction) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/enquadramentoIPI/enquadramento?codEmpresa=${codEmpresa}&codEnquadramento=${codEnquadramento}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            let type = 'ENQUADRAMENTO';
            dispatch({ idStore, type, campo: 'codEnquadramento', value: codEnquadramento })
            dispatch({ idStore, type, campo: 'enquadramento', value: response.data.records.enquadramento })
            dispatch({ idStore, type, campo: 'descricao', value: response.data.records.descricao })
            dispatch({ idStore, type, campo: 'status', value: response.data.records.status })
            dispatch({ idStore, type: 'ENQUADRAMENTO_COPIA' })
            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export async function Create(notificacao, dispatch, codEmpresa, enquadramento) {
    try {
        //Retorna se houve erro ou nao
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;
        if (enquadramento.enquadramento === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'enquadramento', value: true })
            invalido = true
        }
        if (enquadramento.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/enquadramentoIPI/create?codEmpresa=${codEmpresa}`,
            headers: { Authorization: basicAuth },
            data: {
                Enquadramento: enquadramento.enquadramento,
                Descricao: enquadramento.descricao,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'ENQUADRAMENTO_', campo: 'codEnquadramento', value: response.data.records })
            dispatch({ idStore, type: 'ENQUADRAMENTO_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function Update(notificacao, dispatch, codEmpresa, enquadramento, enquadramentoCopia) {
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(enquadramento) === JSON.stringify(enquadramentoCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;
        if (enquadramento.enquadramento === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'enquadramento', value: true })
            invalido = true
        }
        if (enquadramento.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/enquadramentoIPI/update?codEmpresa=${codEmpresa}&codEnquadramento=${enquadramento.codEnquadramento}`,
            headers: { Authorization: basicAuth },
            data: {
                Enquadramento: enquadramento.enquadramento,
                Descricao: enquadramento.descricao,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'ENQUADRAMENTO', campo: 'codEnquadramento', value: response.data.records })
            dispatch({ idStore, type: 'ENQUADRAMENTO_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export function Excluir(notificacao, dispatch, codEmpresa, enquadramento, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/enquadramentoIPI/delete?codEmpresa=${codEmpresa}&codEnquadramento=${enquadramento.codEnquadramento}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar(notificacao, dispatch, codEmpresa, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}