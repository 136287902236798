import React, { useState, useRef, useEffect } from "react";
import { maskNumbers, maskMoeda } from "../../../../../Util/mascaras";
import { Input, Row, Col, Button, FormGroup } from "reactstrap";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import AppState from '../../../../../store/storeType';
import { Item } from "../../../../../store/reducers/Movimentacao/PedidoVenda/types";
import { Produto } from '../../../../../store/reducers/Movimentacao/SaidaDiversa/types';
import useModal from "../../../../../Util/Hooks/useModal";
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import { saidaProduto as controllerSaidaProduto } from '../../../../../controller/Movimentacao/controllerSaidaDiversa'
import ArrowTooltip from '../../../../../components/Tooltip/ArrowTooltip';
import ModalProduto from '../../Modals/ModalProdutos'
import ModalProdutoGrades from '../../Modals/ModalProdutoGrades'
import { setProduto } from "../../../../../store/reducers/Movimentacao/SaidaDiversa/actions";
import * as actions from "../../../../../store/reducers/Movimentacao/SaidaDiversa/actions"
import { produtoInitial } from "../../../PedidoVenda/variables";
import store from "../../../../../store/stores";
import { cleanProduto } from "../../../../../store/reducers/Cadastro/Produto/actions";
import { Round } from "../../../../../Util/numbers";

const produtoModelo: Produto = {
  numeroPedidoItem: 0,
  item: 0,
  codProduto: 0,
  numero: '',
  descricao: '',
  valorUnitario: '',
  quantidade: '',
  valorTotal: '',
  dataLancamento: '',
  status: 0,
  flControlaGrade: 0,
  grades: [],
}

interface invalido {
  item: boolean,
  codProduto: boolean,
  numero: boolean,
  descricao: boolean,
  valorUnitario: boolean,
  quantidade: boolean,
  valorTotal: boolean
}

function DadosProdutos() {
  const produto = useShallowEqualSelector<AppState, Produto>(state => state.saidaDiversa.produto);

  const modalProdutosGrades = useShallowEqualSelector<AppState>(state => state.saidaDiversa.modalProdutoGrades);

  const [invalido, setInvalido] = useState<invalido>({ item: false, codProduto: false, numero: false, descricao: false, valorUnitario: false, quantidade: false, valorTotal: false })

  const [modalProduto, toggleProduto] = useModal(false);

  const inputsRefs = {
    numero: useRef<HTMLInputElement>(null),
    descricao: useRef<HTMLInputElement>(null),
    valorUnitario: useRef<HTMLInputElement>(null),
    quantidade: useRef<HTMLInputElement>(null),
  }

  useEffect(() => {
    let quantidade = Number(produto.quantidade)

    if (produto.valorUnitario && produto.quantidade)
      produto.valorUnitario.length > 6 ? produto.valorUnitario = maskMoeda(produto.valorUnitario).replace('.','') : produto.valorUnitario
      produto.valorUnitario = produto.valorUnitario.toString().replace(',','.')
      let valorUnitario = Number(produto.valorUnitario);
      let valorTotal = (valorUnitario * quantidade).toFixed(2);
    
    let converteValorUnitario = Number(produto.valorUnitario)
    let valorUnitarioConvertido = converteValorUnitario.toFixed(2)
  
    setProduto({ ...produto, "valorTotal": valorTotal, "valorUnitario": valorUnitarioConvertido });

  }, [produto.valorUnitario, produto.quantidade, produto.descricao])

  function handleOnChange(value: string, campo: keyof Produto) {
    //@ts-ignore
    if (invalido[campo])
      setInvalido({ ...invalido, [campo]: false })

    setProduto({ ...produto, [campo]: value })
  };

  async function handleAdicionarItem() {
    const campoInvalido = await controllerSaidaProduto.AdicionarItem(produto);

    if (campoInvalido) {
      const objInvalido = { item: false, codProduto: false, numero: false, descricao: false, valorUnitario: false, quantidade: false, valorTotal: false }
      setInvalido({ ...objInvalido, [campoInvalido]: true })

      if (inputsRefs[campoInvalido].current && campoInvalido) {
        //@ts-ignore
        inputsRefs[campoInvalido].current.focus();
      }

    } else {
      actions.cleanProduto()
      if (inputsRefs.numero.current)
        inputsRefs.numero.current.focus()
    }
  }

  async function getProduto(codProduto: number) {
    const produto = await controllerSaidaProduto.GetProduto(codProduto);
    var produtoGrades = []

    if (produto) {
      if (produto.flControlaGrade === 1) {
        produtoGrades = await controllerSaidaProduto.GetProdutoGrades(codProduto)
      }

      const objProduto = produtoModelo

      const valorTotal = parseFloat(produto.precoVendaAtual.toString()) * 1

      objProduto.codProduto = produto.codProduto;
      objProduto.numero = produto.codBarras.toString() ? produto.codBarras.toString() : produto.numProduto.toString();
      objProduto.descricao = produto.nome;
      objProduto.valorUnitario = produto.precoVendaAtual.toString();
      objProduto.quantidade = "1";
      objProduto.valorTotal = valorTotal.toString()
      objProduto.flControlaGrade = produto.flControlaGrade
      objProduto.grades = produtoGrades

      setProduto({ ...objProduto })

      if (inputsRefs.quantidade.current) {
        inputsRefs.quantidade.current.select();
      }
    }

    return produto
  }

  async function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      let retorno: Item[] = [];
      if (produto.numero)
        retorno = await controllerSaidaProduto.GetProdutos(produto.numero, "");

      if (retorno.length === 0)
        toggleProduto()
      else {
        getProduto(retorno[0].codProduto)

        if (inputsRefs.quantidade.current) {
          inputsRefs.quantidade.current.select();
        }

      }
    }
  }

  function toggleModalProdutoGrades() {
    actions.setModalProdutoGrades(!modalProdutosGrades)
    actions.setGradeSelecionada({})
  }

  return (

    <Col md="12">

      <Row className="padding-padrao-row">

        <Col className="padding-padrao-col" md="2" sm="4">
          <label>Número / Cód. Barras <span className="error">*</span></label>
          <FormGroup className={invalido.numero ? "has-danger" : ''}>
            <Input
              innerRef={inputsRefs.numero}
              type="text"
              value={produto.numero.toString()}
              onChange={(event: React.FormEvent<HTMLInputElement>) => handleOnChange(maskNumbers(event.currentTarget.value), 'numero')}
              autoComplete="off"
              onKeyPress={handleKeyPress}
            />
            <br></br>
          </FormGroup>
        </Col>

        <Col className="padding-padrao-col">
          <ArrowTooltip title="Pesquisar produto">
            <Button color="info" onClick={() => toggleProduto()} style={{ width: "100%", height: 40, marginTop: 25 }}>
              <Icon icon="search" className="fa-lg" />
            </Button>
          </ArrowTooltip>
        </Col>

        <Col className="padding-padrao-col" md="3" sm="7">
          <label>Descrição <span className="error">*</span></label>
          <FormGroup className={invalido.descricao ? "has-danger" : ''}>
            <Input
              innerRef={inputsRefs.descricao}
              type="text"
              value={produto.descricao}
              onChange={(event: React.FormEvent<HTMLInputElement>) => handleOnChange(event.currentTarget.value, 'descricao')}
              autoComplete="off"
              disabled={true}
            />
            <br></br>
          </FormGroup>
        </Col>

        <Col className="padding-padrao-col" md="2" sm="3">
          <label>Valor Unitário <span className="error">*</span></label>
          <FormGroup className={invalido.valorUnitario ? "has-danger" : ''}>
            <Input
              type="text"
              innerRef={inputsRefs.valorUnitario}
              value={maskMoeda(produto.valorUnitario)}
              onChange={(event: React.FormEvent<HTMLInputElement>) => handleOnChange(maskMoeda(event.currentTarget.value), 'valorUnitario')}
              onFocus={(event: React.FormEvent<HTMLInputElement>) => event.currentTarget.select()}
              autoComplete="off"
              maxLength={10}
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => event.key === "Enter" && handleAdicionarItem()}
            />
            <br></br>
          </FormGroup>
        </Col>

        <Col className="padding-padrao-col" md="2" sm="3">
          <label>Quantidade <span className="error">*</span></label>
          <FormGroup className={invalido.quantidade ? "has-danger" : ''}>
            <Input
              innerRef={inputsRefs.quantidade}
              type="text"
              value={produto.quantidade}
              onChange={(event: React.FormEvent<HTMLInputElement>) => handleOnChange(maskNumbers(event.currentTarget.value), 'quantidade')}
              onFocus={(event: React.FormEvent<HTMLInputElement>) => event.currentTarget.select()}
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => event.key === "Enter" && handleAdicionarItem()}
              autoComplete="off"
              maxLength={10}
            />
            <br></br>
          </FormGroup>
        </Col>

        <Col className="padding-padrao-col" md="2" sm="3">
          <label>Valor Total <span className="error">*</span></label>
          <FormGroup className={invalido.valorTotal ? "has-danger" : ''}>
            <Input
              type="text"
              value={maskMoeda(produto.valorTotal)}
              onChange={(event: React.FormEvent<HTMLInputElement>) => handleOnChange(event.currentTarget.value, 'valorTotal')}
              autoComplete="off"
              maxLength={10}
              disabled={true}
            />
            <br></br>
          </FormGroup>
        </Col>

        <ArrowTooltip title="Adicionar produto">
          <Button color="info" onClick={() => handleAdicionarItem()} style={{ width: 40, height: 40, marginTop: 25 }}>
            <Icon icon="plus" className="fa-lg" />
          </Button>
        </ArrowTooltip>

      </Row>

      {
        modalProduto ? <ModalProduto isOpen={true} getProduto={getProduto} toggle={toggleProduto} produto={produtoModelo} produtosList={[]} /> : null
      }
      {
        modalProdutosGrades ? <ModalProdutoGrades isOpen={true} toggle={toggleModalProdutoGrades} /> : null
      }

    </Col>
  )
}

export default DadosProdutos;