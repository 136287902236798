import React, { useState, memo } from 'react';

import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';

import '../styles.css'

import {
    Input,
    Row,
    Col,
    CardBody,
    Button,
    FormGroup,
} from "reactstrap";

import useShallowEqualSelector from '../../../Util/Hooks/useShallowEqualSelector';
import useModal from '../../../Util/Hooks/useModal';

import ModalImagem from './Modals/ModalImagem';

import * as controllerProdutos from '~/controller/controllerProdutos';
import * as actions from '../../../store/reducers/Cadastro/Produto/actions';


const Imagens = memo(function Imagens() {
    const { codEmpresa } = useShallowEqualSelector(state => state.login.usuario.empresa);

    const codProduto = useShallowEqualSelector(state => state.produtos.produto.codProduto);
    const imagens = useShallowEqualSelector(state => state.produtos.imagens);
    const imagem = useShallowEqualSelector(state => state.produtos.imagem);
    const invalido = useShallowEqualSelector(state => state.produtos.invalido);
    const verCadastro = useShallowEqualSelector(state => state.produtos.verCadastro);

    const [modalImagem, toggleModalImagem] = useModal(false);
    const [imageSelected, setimageSelected] = useState({ codImagem: '', imagem: '', descricao: '', editing: false });
    const [saving, setsaving] = useState(false)

    function onChange(value, stateName) {
        actions.setImagem({ ...imagem, [stateName]: value })
        actions.setInvalido({ ...invalido, descricaoImagem: false })
    };

    function handleDeleteImage(img) {
        if (codProduto !== '') {
            controllerProdutos.ExcluirImagem(codEmpresa, codProduto, img.codImagem);
        }
    }

    async function updateImage(img) {
        if (img.descricao !== imageSelected.descricao)
            await controllerProdutos.UpdateImagem(codEmpresa, codProduto, imageSelected);
        setimageSelected({ ...imageSelected, editing: false })
    }

    async function handleKeyPressEditImage(event, img) {
        if (event.key === 'Enter') {
            if (img.descricao !== imageSelected.descricao)
                await controllerProdutos.UpdateImagem(codEmpresa, codProduto, imageSelected);
            setimageSelected({ ...imageSelected, editing: false })
        }
    }

    function handleViewImage(img) {
        setimageSelected({ ...img, editing: false })
        toggleModalImagem()
    }

    async function Salvar() {
        setsaving(true)
        await controllerProdutos.CreateImagem(codEmpresa, codProduto, imagem);
        setsaving(false)
    };

    function changeNewImg(value) {
        actions.setImagem({ ...imagem, imagem: value })
    }

    function handleEditDescription(img) {
        setimageSelected({ ...img, editing: true })
    }
    function handlePrincipal(img) {
        const imagem = {
            codImagem: img.codImagem,
            imagem: img.imagem,
            descricao: img.descricao,
            editing: false,
            flPrincipal: 1
        }
        if (img.flPrincipal !== 1) {
            controllerProdutos.UpdateImagem(codEmpresa, codProduto, imagem)
        }
    }

    return (
        <>
            <Row className="justify-content-center">
                <Col>
                    <h4 className="info-text">Imagens do Produto</h4>

                    <CardBody>

                        <Row className="justify-content-center">

                            <Col md="10">
                                <Row>
                                    {!verCadastro && (
                                        <Col lg="4" className="text-center" style={{ minWidth: '270px' }}>
                                            <label>Descrição da nova imagem</label>
                                            <FormGroup className={invalido.descricaoImagem ? "has-danger" : ''}>
                                                <Input
                                                    style={{ marginBottom: '15px', textAlign: 'center' }}
                                                    id="descricaoImg"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={imagem.descricao}
                                                    onChange={e => onChange(e.target.value, 'descricao')}
                                                />
                                            </FormGroup>

                                            <ImageUpload
                                                changeBtnClasses="btn-simple btn-padrao"
                                                addBtnClasses="btn-simple"
                                                removeBtnClasses="btn-simple btn-padrao"
                                                changeBtnColor="info"
                                                addBtnColor="info"
                                                imagem={imagem.imagem}
                                                onChange={changeNewImg}
                                                tamanhoMax={'2MB'}
                                                addBtnText={'Selecionar'}
                                            />
                                            <Row className="justify-content-center">
                                                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Button className="btn-padrao" color="info" disabled={saving} onClick={Salvar}>Adicionar</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )}
                                    <Col lg md sm>
                                        <div className="box" style={{ width: '100%', marginTop: '25px', paddingTop: '0px' }} >
                                            <Row style={{ justifyContent: 'space-between' }}>
                                                {imagens.length > 0 ?
                                                    <table className="tbImagens">
                                                        <tbody>
                                                            {
                                                                imagens.map((imagem) => (
                                                                    <tr key={imagem.codImagem} className="trImagem">
                                                                        <td className="tdImagem">
                                                                            <ArrowTooltip title="Vizualizar">
                                                                                <img
                                                                                    src={"data:image/jpg;base64," + imagem.imagem}
                                                                                    alt="Imagem de produto"
                                                                                    onClick={() => handleViewImage(imagem)}
                                                                                />
                                                                            </ArrowTooltip>
                                                                        </td>
                                                                        <td className="tdDescricao">
                                                                            <div className={`edit-row${window.innerWidth < 600 ? '-sm' : ''}`}>
                                                                                {imageSelected.codImagem !== imagem.codImagem || !imageSelected.editing ?
                                                                                    <ArrowTooltip title="Clique para editar">

                                                                                        <label
                                                                                            onClick={() => handleEditDescription(imagem)}
                                                                                            className={verCadastro && 'modeView-desc'}
                                                                                        >
                                                                                            {imagem.descricao}
                                                                                        </label >
                                                                                    </ArrowTooltip>
                                                                                    :
                                                                                    <>
                                                                                        <ArrowTooltip title="Enter para salvar">
                                                                                            <Input
                                                                                                autoFocus={true}
                                                                                                value={imageSelected.descricao}
                                                                                                onChange={e => setimageSelected({ ...imageSelected, descricao: e.target.value })}
                                                                                                onKeyPress={(e) => handleKeyPressEditImage(e, imagem)}

                                                                                            />
                                                                                        </ArrowTooltip>
                                                                                        <Button
                                                                                            className="save-btn"
                                                                                            color="info"
                                                                                            type="button"
                                                                                            onClick={() => updateImage(imagem)}

                                                                                        >
                                                                                            Salvar
                                                                                        </Button>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="tdAction">
                                                                            <ArrowTooltip title="Principal">
                                                                                <Button
                                                                                    className="btn-link modeView-fav"
                                                                                >
                                                                                    <div onClick={() => handlePrincipal(imagem)} className={imagem.flPrincipal ? "icon-principal-active" : "icon-principal"}>
                                                                                        <i style={{ marginRight: '6px' }} className={imagem.flPrincipal ? "fas fa-star" : "far fa-star"}></i>
                                                                                        <a href
                                                                                            style={imageSelected.editing === true && imagem.codImagem === imageSelected.codImagem ?
                                                                                                { display: 'none' } : { fontFamily: 'Poppins', textTransform: 'none' }}>Principal</a>

                                                                                    </div>

                                                                                </Button>
                                                                            </ArrowTooltip>
                                                                            {verCadastro === true ?
                                                                                <ArrowTooltip title="Visualizar">
                                                                                    <Button
                                                                                        onClick={() => handleViewImage(imagem)}
                                                                                        className="btn-icon btn-link"
                                                                                        color="info"
                                                                                    >
                                                                                        <Icon icon="external-link-alt" className="fa-lg" />
                                                                                    </Button>
                                                                                </ArrowTooltip>
                                                                                :
                                                                                <>
                                                                                    <ArrowTooltip title="Visualizar">
                                                                                        <Button
                                                                                            onClick={() => handleViewImage(imagem)}
                                                                                            className="btn-icon btn-link"
                                                                                            color="info"
                                                                                        >
                                                                                            <Icon icon="external-link-alt" className="fa-lg" />
                                                                                        </Button>
                                                                                    </ArrowTooltip>
                                                                                    <ArrowTooltip title="Excluir">
                                                                                        <Button
                                                                                            onClick={() => handleDeleteImage(imagem)}
                                                                                            className="btn-icon btn-link"
                                                                                            color="danger"
                                                                                        >
                                                                                            <Icon icon="trash-alt" className="fa-lg" />
                                                                                        </Button>
                                                                                    </ArrowTooltip>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <Col className="text-center" style={{ marginTop: '15px' }}>
                                                        <h5>Nenhuma imagem foi cadastrada ainda</h5>
                                                    </Col>
                                                }
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>

                    </CardBody>

                </Col>
            </Row>

            {modalImagem ?
                <ModalImagem
                    isOpen={modalImagem}
                    toggle={toggleModalImagem}
                    imagem={imageSelected}
                />
                : null}
        </>
    )
})

class Wizard extends React.Component {
    focusInput = () => {
        setTimeout(() => {
            //eslint-disable-next-line no-unused-vars
            var descImg = document.getElementById("descricaoImg")
            descImg = descImg && descImg.focus()
        }, 100)
    }

    render() {
        return (
            <Imagens />
        )
    }
}

export default Wizard;
