import React, { useState, useEffect } from "react";
import classNames from "classnames";
import store from '../../../../store/stores'

import useShallowEqualSelector from "../../../../Util/Hooks/useShallowEqualSelector";

import {
    Button,
    ButtonGroup,
} from "reactstrap";

const TabContent = (props) => {
    const screen = useShallowEqualSelector(state => state.entradaMercadoria.screen)
    const [stateScreen, setScreen] = useState(0)

    useEffect(() => {
        setScreen(screen)
    }, [screen])

    function onChange(index) {
        store.dispatch({idStore: 'ENTRADA_MERCADORIA', type: 'TELA', value: index})
        store.dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'WIZARD_STEP', value: 0 })
        store.dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'CLEAN_PESQUISA'})
        store.dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'CLEAN_CADASTRO'})
    }
    
    return <>
        <ButtonGroup style={{ width: '100%' }}>
            {props.tabs && props.tabs.map((item, index) => {
                return (
                    <>
                        <Button
                            color={stateScreen === index ? "info" : ""}
                            type="button"
                            data-toggle="tab"
                            className={classNames("btn-gp-padrao", stateScreen === index ? "active" : "")}
                            onClick={() => { onChange(index) }}
                            style={{ width: '50%' }}
                        >
                            {item.tabName}
                        </Button>
                    </>
                )
            })}
        </ButtonGroup>
        <br />
        <br />
        {props.tabs[stateScreen].tabScreen}
    </>
}

export default TabContent;