import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    NavLink,
} from "reactstrap";

import * as controllerLogin from "~/controller/controllerLogin";
import * as controllerPerfilUsuario from "~/controller/controllerPerfilUsuario";
import * as controllerConfiguracao from "~/controller/controllerConfiguracao"
const avatar = "http://admin.ernetwork.com.br/imagens/easyx/face-0.jpg";

export default function Options() {
    const login = useSelector(state => state.login, shallowEqual);
    const dispatch = useDispatch();

    function hanldeLock() {
        controllerLogin.lock(dispatch);
    }

    function handleLogout() {
        controllerLogin.logout(dispatch);
    }

    function handlePerfil() {
        controllerPerfilUsuario.editarPerfil(dispatch)
    }
    function handleConfiguracao() {
        controllerConfiguracao.entrySettings();
    }

    return (
        <>
            <UncontrolledDropdown nav>
                <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                    onClick={e => e.preventDefault()}
                >
                    <div className="photo">
                        <img alt="..." src={login.usuario.foto !== "" ? login.usuario.foto : avatar} />
                    </div>
                    <b className="caret d-none d-lg-block d-xl-block" />
                    <p className="d-lg-none">Opções</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li" onClick={() => handlePerfil()}>
                        <DropdownItem className="nav-item">Perfil</DropdownItem>
                    </NavLink>
                    <NavLink tag="li" onClick={() => handleConfiguracao()}>
                        <DropdownItem className="nav-item">Configurações</DropdownItem>
                    </NavLink>
                    <DropdownItem divider tag="li" />
                    <NavLink tag="li" onClick={() => hanldeLock()}>
                        <DropdownItem className="nav-item">Bloquear</DropdownItem>
                    </NavLink>
                    <NavLink tag="li" onClick={() => handleLogout()}>
                        <DropdownItem className="nav-item">Sair</DropdownItem>
                    </NavLink>
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    );
}
