import moment from 'moment';
import { string } from 'prop-types';
import { Reducer } from 'redux'
import { IRecebimentoPagamentoState, RecebimentoPagamentoAction, RecebimentoPagamentoStore, RecebimentoPagamentoTypes } from './types';

const INITIAL_STATE: IRecebimentoPagamentoState = {
    pesquisa: {
        nomeFor: '',
        cpfCnpj: '',
        dataInicio: '',
        dataFim: '',
        codEmpresaFilial: '',
        codCliente: '',
        cliente: '',
        tipoPesq: 'DESCLAN',
        textPesq: '',
        status: '',
        titulo: 'TODOS',
        tipoData: 'VEN',
        codGrupoConta: 0,
        codSubGrupoConta: 0,
    },
    grupos: [],
    subGrupos: [],
    recebimentosPagamentos: [],
    empresas: [],
    fornecedores: [],
    formasPagamentos: [],
    codEmpresaFilial: [],
    contas: [],
    lancamentos: [],
    registro: {},
    informacoes: [],
    lancamentoRapido: {
        codigoForma: 0,
        codigoCaixa: 0,
        codigoConta: 0,
        numeroLancamento: 1,
        dataLancamento: moment().format('YYYY-MM-DD'),
        valorLancamento: 0,
        valorJuros: 0,
        valorMulta: 0,
        valorDesconto: 0,
        valorTarifa: 0,
        valorTotal: 0,
        imagemDocumento: ''
    },
    informacoesRecebimentosPagamentos: {
        typeAction: '',
        codigoTituloMovimento: 0,
        codigoCaixa: 0,
        codigoForma: 0,
        codigoConta: 0,
        valorLancar: 0,
        valorTitulo: 0,
        valorMulta: 0,
        valorJuros: 0,
        valorDesconto: 0,
        valorTarifa: 0,
        dataLancamento: moment(new Date()).format('YYYY-MM-DD'),
        totalRestante: 0,
        lancamentos: [],
        valorReais: {
            valorMultaReais: 0,
            valorJurosReais: 0
        }
    },
};

const recebimentoPagamento: Reducer<IRecebimentoPagamentoState, RecebimentoPagamentoAction> = (state = INITIAL_STATE, action: RecebimentoPagamentoAction) => {
    switch (action.idStore) {
        case RecebimentoPagamentoStore.ID_STORE:

            switch (action.type) {

                case (RecebimentoPagamentoTypes.PESQUISA):
                    return {
                        ...state,
                        pesquisa: action.pesquisa
                    }

                case (RecebimentoPagamentoTypes.CLEAN_PESQUISA):
                    return {
                        ...state,
                        pesquisa: INITIAL_STATE.pesquisa,
                        recebimentosPagamentos: INITIAL_STATE.recebimentosPagamentos
                    }

                case (RecebimentoPagamentoTypes.FORNECEDOR):
                    return {
                        ...state,
                        fornecedores: action.fornecedores
                    }

                case (RecebimentoPagamentoTypes.CLEAN_FORNECEDOR):
                    return {
                        ...state,
                        fornecedores: INITIAL_STATE.fornecedores
                    }

                case (RecebimentoPagamentoTypes.RECEBIMENTOS_PAGAMENTOS):
                    return {
                        ...state,
                        recebimentosPagamentos: action.recebimentosPagamentos
                    }

                case (RecebimentoPagamentoTypes.EMPRESAS):
                    return {
                        ...state,
                        empresas: action.empresas
                    }

                case (RecebimentoPagamentoTypes.FORMAS_PAGAMENTO):
                    return {
                        ...state,
                        formasPagamentos: action.formasPagamentos
                    }
                case (RecebimentoPagamentoTypes.COD_EMPRESA_FILIAL):
                    return {
                        ...state,
                        codEmpresaFilial: action.codEmpresaFilial
                    }
                case (RecebimentoPagamentoTypes.CONTAS):
                    return {
                        ...state,
                        contas: action.contas
                    }
                case (RecebimentoPagamentoTypes.REGISTRO):
                    return {
                        ...state,
                        registro: action.registro
                    }
                case (RecebimentoPagamentoTypes.SET_INFORMACAO):
                    return {
                        ...state,
                        informacoes: action.informacoes
                    }
                case (RecebimentoPagamentoTypes.SET_LANCAMENTO_RAPIDO):
                    return {
                        ...state,
                        //@ts-ignore
                        lancamentoRapido: { ...state.lancamentoRapido, [action.field]: action.lancamentoRapido }
                    }
                case (RecebimentoPagamentoTypes.CLEAN_LANCAMENTO_RAPIDO):
                    return {
                        ...state,
                        lancamentoRapido: INITIAL_STATE.lancamentoRapido
                    }
                case (RecebimentoPagamentoTypes.SET_INFORMACOES_RECEBIMENTOS_PAGAMENTOS):
                    return {
                        ...state,
                        informacoesRecebimentosPagamentos: action.informacoesRecebimentosPagamentos
                    }
                case (RecebimentoPagamentoTypes.CLEAN_INFORMACOES_RECEBIMENTOS_PAGAMENTOS):
                    return {
                        ...state,
                        informacoesRecebimentosPagamentos: INITIAL_STATE.informacoesRecebimentosPagamentos
                    }
                case (RecebimentoPagamentoTypes.SET_GRUPOS):
                    return {
                        ...state,
                        grupos: action.grupos
                    }
                case (RecebimentoPagamentoTypes.SET_SUB_GRUPOS):
                    return {
                        ...state,
                        subGrupos: action.subGrupos
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}

export default recebimentoPagamento