import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';

import { maskNCM, maskCEST } from '~/Util/mascaras.js'
import classNames from "classnames";

import './styles.css'

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";

import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector';
import useScrollBar from '../../../../Util/Hooks/useScrollBar';

import * as controllerProdutos from '~/controller/controllerProdutos';
import * as actions from '../../../../store/reducers/Cadastro/Produto/actions';

const NCM = ({ modalNCM, dadosTributarios, toggleModalNCM, toggleModalCEST, ncms, ncmsSugestoes, shouldClean }) => {
    const tabelaNcm = useScrollBar([modalNCM])
    const tabelaSugest = useScrollBar([modalNCM])

    const { codEmpresa } = useShallowEqualSelector(state => state.login.usuario.empresa)

    const [pesquisa, setpesquisa] = useState({ ncm: '', descricao: '' })
    const [ncmSelected, setncmSelected] = useState('')
    const [mensagem, setmensagem] = useState('')
    const [listNCM, setlistNCM] = useState([])
    const [lastSearch, setlastSearch] = useState()

    useEffect(() => {
        actions.setProdutoDadosTributarios({ ...dadosTributarios, codSeguimento: dadosTributarios.codSeguimento })
    }, [modalNCM])

    useEffect(() => {
        setlistNCM(ncms)
    }, [ncms])

    useEffect(() => {
        if (shouldClean) {
            actions.setNCMs([])
            actions.setNCMsSugestoes([])
            setpesquisa({ ncm: '', descricao: '' })
            setmensagem('')
            setlastSearch('')
        }
    }, [shouldClean])

    function onChangeNcm(value) {
        let ncm = maskNCM(value)
        setpesquisa({ ...pesquisa, ncm })
        if (ncm.length === 1) {
            if (ncm !== lastSearch)
                handlePesquisar(ncm, pesquisa.descricao)
            else
                changeListNCM(filterNCM(ncm, pesquisa.descricao))
            setlastSearch(ncm)
            changeListNCM(ncms)
        } else if (ncm.length > 1) {
            tabelaNcm.current.scrollTop = 0
            changeListNCM(filterNCM(ncm, pesquisa.descricao))
        } else {
            if (!pesquisa.descricao === '') {
                changeListNCM(filterNCM('', ''))
            }
        }
    }

    function onChangeDescricao(value) {
        setpesquisa({ ncm: pesquisa.ncm, descricao: value })
        tabelaNcm.current.scrollTop = 0
        changeListNCM(filterNCM(pesquisa.ncm, value))
    }

    function changeListNCM(list) {
        setlistNCM(list)
        setmensagem(`${list.length} NCMs encontrados`)
    }

    function filterNCM(ncm, descricao) {
        ncm = ncm.replace(/\./g, '')
        let response = []

        if (ncm !== "") {
            response = ncms.filter(item => item.ncm.startsWith(ncm))
            if (descricao.trim() !== "")
                response = response.filter(item => item.descricao.toLocaleLowerCase().includes(descricao.toLocaleLowerCase()))
        }
        else if (descricao.trim() !== "") {
            response = ncms.filter(item => item.descricao.toLocaleLowerCase().includes(descricao.toLocaleLowerCase()))
        }

        return response
    }

    function onClickNcm(ncm) {
        if (ncm.ncm === ncmSelected)
            toggleModalNCM()
        else
            setncmSelected(ncm.ncm)

        actions.setProdutoDadosTributarios({ ...dadosTributarios, ncm: ncm.ncm })
        setpesquisa({ ncm: maskNCM(ncm.ncm), descricao: ncm.descricao })
    }

    async function handlePesquisar(ncm, descricao) {
        tabelaNcm.current.scrollTop = 0
        let mensagem
        mensagem = await controllerProdutos.GetNCMs(codEmpresa, ncm, descricao)
        setmensagem(mensagem)
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter')
            if (pesquisa.descricao && !pesquisa.ncm)
                handlePesquisar(pesquisa.ncm, pesquisa.descricao)
            else if (pesquisa.ncm && !pesquisa.descricao)
                handlePesquisar(pesquisa.ncm, pesquisa.descricao)
    }

    function handleLimpar() {
        actions.setProdutoDadosTributarios({ ...dadosTributarios, ncm: '' })
        actions.setNCMs([])
        actions.setNCMsSugestoes([])
        setpesquisa({ ncm: '', descricao: '' })
        setmensagem('')
        setlastSearch('')
    }

    async function handleIncluir() {
        let mensagem = await controllerProdutos.IncluirNCM(codEmpresa, pesquisa.ncm, pesquisa.descricao)
        setmensagem(mensagem)
    }

    return (
        <ModalBasic
            isOpen={modalNCM}
            toggle={() => toggleModalNCM()}
            title={'Nomenclatura Comum ao Mercosul'}
            size="lg"
        >
            <ModalBasicBody>

                <Col>
                    <Row>
                        <Col lg="2">
                            <label>NCM</label>
                            <Input
                                value={pesquisa.ncm}
                                onChange={(e) => onChangeNcm(e.target.value)}
                                onKeyPress={handleKeyPress}
                                maxLength={'10'}
                            />
                        </Col>
                        <Col lg>
                            <label>Descrição</label>
                            <Input
                                value={pesquisa.descricao}
                                onChange={(e) => onChangeDescricao(e.target.value)}
                                onKeyPress={handleKeyPress}
                                maxLength={'500'}
                            />
                        </Col>
                        <Col lg="auto">
                            <Button
                                style={{ marginTop: '34px', width: '40px', marginBottom: '0px' }}
                                type='button'
                                color="info"
                                onClick={() => handlePesquisar(pesquisa.ncm, pesquisa.descricao)}
                            >
                                <Icon icon="search" className="fa-lg" />
                            </Button>
                        </Col>
                    </Row>
                    <label style={{ marginTop: '10px', marginBottom: '0px' }}>{mensagem}</label>
                    <br></br>
                    <div style={{ display: dadosTributarios.cest !== '' && ncmsSugestoes.length > 0 ? '' : 'none' }}>
                        <fieldset className="fieldList">
                            <legend>Sugestões de NCM para o CEST {maskCEST(dadosTributarios.cest)}</legend>
                            <div className="tabelaList" style={{ marginTop: '0px', minHeight: '20px', position: 'relative' }} ref={tabelaSugest}>
                                {ncmsSugestoes.map((ncm, key) => (
                                    <label
                                        key={key}
                                        className={classNames("labelList", {
                                            labelListSelected: ncm.ncm === ncmSelected
                                        })}
                                        onClick={() => onClickNcm(ncm)}
                                    >
                                        {ncm.descricaoFormatada}
                                    </label>
                                ))}
                            </div>
                        </fieldset>
                    </div>

                    <fieldset className="fieldList">
                        <legend>Resultados da pesquisa</legend>
                        <div id="tabelaNcm" className="tabelaList bigger" ref={tabelaNcm}>
                            {listNCM.map((ncm, key) => (
                                <label
                                    key={key}
                                    className={classNames("labelList", {
                                        labelListSelected: ncm.ncm === ncmSelected
                                    })}
                                    onClick={() => onClickNcm(ncm)}
                                >
                                    {ncm.descricaoFormatada}
                                </label>
                            ))}
                        </div>
                    </fieldset>

                </Col>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                color="info"
                                type="button"
                                onClick={handleLimpar}
                                style={{ width: '100px' }}
                            >Limpar
                            </Button>

                            <Button
                                color="success"
                                type="button"
                                onClick={() => toggleModalNCM()}
                                style={{ width: '100px' }}
                            >OK
                            </Button>

                            <ArrowTooltip title="Salva um novo NCM">
                                <Button
                                    color="info"
                                    type="button"
                                    onClick={handleIncluir}
                                    style={{ width: '100px' }}
                                >Incluir
                                </Button>
                            </ArrowTooltip>
                        </Col>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg md sm >
                        <Col lg="auto" className={window.innerWidth > 660 ? "text-left" : "text-center"}>
                            <Button
                                color="warning"
                                type="button"
                                onClick={() => { toggleModalNCM(); toggleModalCEST() }}
                                style={{ width: '100px' }}
                            ><Icon icon="caret-left" className="fa-lg" /> CEST
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>

        </ModalBasic >
    )
}

export default NCM