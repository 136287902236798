import {
    CardBody,
    Row
} from "reactstrap";

import React from "react";

import { useHotkeys } from 'react-hotkeys-hook';
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import AppState from '../../../../../store/storeType';
import { IOperacaoState } from '../../../../../store/reducers/Cadastro/Operacao/types'

import { ButtonPesquisa, InputPesquisa, ReactTablePesquisa } from './Components'
import * as controllerOperacoes from '../../../../../controller/controllerOperacoes'
import * as actions from '../../../../../store/reducers/Cadastro/Operacao/actions'


const pesquisa: any = () => {

    const pesquisa = useShallowEqualSelector<AppState, IOperacaoState['pesquisa']>(state => state.operacao.pesquisa)

    useHotkeys('shift+p', () => {
            controllerOperacoes.Pesquisar(pesquisa); 
    }, [])

    useHotkeys('shift+l', () => {
        actions.cleanSearch()
        actions.cleanOperacoes()
    }, [])

    return (
        <>

            <Row>
                <CardBody>
                    <InputPesquisa />
                    <ButtonPesquisa />
                </CardBody>
            </Row>
            <ReactTablePesquisa />

        </>
    )
}

export default pesquisa