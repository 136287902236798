import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'

import { Button, Col, Row } from "reactstrap";
import { Line } from '../Components';

import { Card, Sub } from '../Components';

import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector';
import useModal from "../../../../Util/Hooks/useModal";
import * as controllerPDV from '../../../../controller/Movimentacao/controllerPedidoVenda'

import AppState from '../../../../store/storeType';
import { Totais, Item } from '../../../../store/reducers/Movimentacao/PedidoVenda/types';
import { setTotais } from '../../../../store/reducers/Movimentacao/PedidoVenda/actions';
import ModalFaturamento from '../../../../components/Modals/Faturamento'

import { maskMoeda } from '../../../../Util/mascaras';
import { removeMaskMoney } from '../../../../Util/removeMascaras';
import { PopUpAbrirCaixa } from '../Modals/ModalAbrirCaixa';

const TotaisComponent: React.FC = () => {
    //@ts-ignore
    const pedido = useShallowEqualSelector(state => state.pedidoVenda.pedido)
    const totais = useShallowEqualSelector<AppState, Totais>(state => state.pedidoVenda.totais)
    const itens = useShallowEqualSelector<AppState, Item[]>(state => state.pedidoVenda.itens)
    const numPedido = useShallowEqualSelector<AppState, number>(state => state.pedidoVenda.informacoes.numPedido)
    const [modalFaturamento, toogleFaturamento] = useModal(false)
    const [modalAbrirCaixa, toogleAbrirCaixa] = useModal(false)

    useEffect(() => {
        if (itens.length > 0) {
            //@ts-ignore
            let subtotal = 0
            //@ts-ignore
            let desconto = 0
            let total = 0
            var quantidadeTotal = 0
            var quantidadeItens = itens.length

            itens.forEach(item => {
                var precoVenda = maskMoeda(item.precoVendaAtual)
                precoVenda = removeMaskMoney(precoVenda)
                var descontoItem = maskMoeda(item.descontoReais)
                descontoItem = removeMaskMoney(descontoItem)
                var preco = maskMoeda((Number(precoVenda) * Number(item.quantidade)).toFixed(2))
                preco = removeMaskMoney(preco)
                //@ts-ignore
                subtotal = (Number(subtotal) + Number(preco))
                //@ts-ignore
                desconto = (desconto + Number(descontoItem))
                quantidadeTotal += Number(item.quantidade)
            })
            total = maskMoeda((subtotal - Number(desconto)).toFixed(2))
            desconto = maskMoeda(Number(desconto).toFixed(2))
            subtotal = maskMoeda(Number(subtotal).toFixed(2))

            setTotais({ ...totais, subtotal, desconto, total, quantidadeTotal, quantidadeItens })
        }
    }, [itens])

    const handleFaturar = () => {
        if(pedido.status === 'ABERTO') {
            controllerPDV.FinalizarPedido(pedido.numPedido, toogleFaturamento, toogleAbrirCaixa)
        } else {
            controllerPDV.FinalizarPedido(numPedido, toogleFaturamento, toogleAbrirCaixa)
        }
    }
    return (
        <>
            <Col style={{ width: '80%' }}>
                <Row>
                    <Col lg={3} md={5}>
                        <Row>
                            <Card style={{ paddingBottom: 0, paddingTop: 6 }}>
                                <Row>
                                    <Col lg className="text-center">
                                        <Sub style={{ marginBottom: 0, fontSize: 14 }}>Qtd.</Sub>
                                    </Col>
                                    <Col className="text-center">
                                        <Sub style={{ marginBottom: 0, fontSize: 14 }}>ITENS</Sub>
                                    </Col>
                                </Row>
                                <Line />
                                <Row>
                                    <Col lg className="text-center">
                                        <Sub style={{ marginBottom: 0, fontSize: 14 }}>{totais.quantidadeTotal}</Sub>
                                    </Col>
                                    <Col className="text-center">
                                        <Sub style={{ marginBottom: 0, fontSize: 14 }}>{totais.quantidadeItens}</Sub>
                                    </Col>
                                </Row>
                            </Card >
                        </Row>
                    </Col>
                    <Col lg={6} style={{ paddingRight: 13 }}>
                        <Card style={{ paddingBottom: 0, paddingTop: 6 }}>
                            <Row>
                                <Col lg={4} className="text-center">
                                    <Sub style={{ marginBottom: 0, fontSize: 14 }}>SUBTOTAL</Sub>
                                </Col>
                                <Col lg={4} className="text-center">
                                    <Sub style={{ marginBottom: 0, fontSize: 14 }}>DESCONTO</Sub>
                                </Col>
                                <Col lg={4} className="text-center">
                                    <Sub style={{ marginBottom: 0, fontSize: 14 }}>TOTAL</Sub>
                                </Col>
                            </Row>
                            <Line />
                            <Row>
                                <Col lg className="text-center">
                                    <Sub style={{ marginBottom: 0, fontSize: 15 }}>{maskMoeda(totais.subtotal)}</Sub>
                                </Col>
                                <Col className="text-center">
                                    <Sub style={{ marginBottom: 0, fontSize: 15 }}>{maskMoeda(totais.desconto)}</Sub>
                                </Col>
                                <Col className="text-center">
                                    <Sub style={{ marginBottom: 0, fontSize: 15 }}>{maskMoeda(totais.total)}</Sub>
                                </Col>
                            </Row>
                        </Card >
                    </Col>
                    <Col style={{ marginLeft: "-1rem" }}>

                        <Button
                            onClick={() => handleFaturar()}
                            Style="width: 104%;height: 60px, height: 85px !important;text-align: center;background-image: linear-gradient(to left bottom, rgb(92, 184, 92), rgb(92, 184, 92), rgb(92, 184, 92)) !important;"
                            className={pedido.status !== 'ABERTO' && pedido.status !== '' ? 'disabledButton' : ''}
                        >
                            $ Faturar
                        </Button>
                    </Col>
                </Row>
            </Col>

            {modalFaturamento ?
                <ModalFaturamento
                    isOpen={true}
                    toogle={toogleFaturamento} /> : null
            }
            {modalAbrirCaixa &&
                <PopUpAbrirCaixa
                    isOpen={modalAbrirCaixa}
                    callback={() => { controllerPDV.CreateCaixa(); toogleAbrirCaixa() }}
                    toggle={toogleAbrirCaixa}
                />
            }

        </>
    );
}

export default TotaisComponent