import React, { useState, useEffect } from 'react';

import './style.css'

import {
    Col,
    NavItem,
    NavLink,
    Nav,
    Row
} from 'reactstrap';
import * as action from '../../../store/reducers/Modals/Faturamento/actions'
import useShallowEqualSelector from '../../../Util/Hooks/useShallowEqualSelector';
import AppState from '../../../store/storeType';
import { IFaturamentoState } from '../../../store/reducers/Modals/Faturamento/types';

export default function FormaPagamento() {
    const pagamentos = useShallowEqualSelector<AppState, IFaturamentoState['pagamentos']>(state => state.modalFaturamento.pagamentos)
    const lancamento = useShallowEqualSelector<AppState, IFaturamentoState['lancamento']>(state => state.modalFaturamento.lancamento)
    const [IndexPaySelected, setIndexPaySelected] = useState<number>(0);

    function setPagamento(index: number, item: any) {
        if (item.flaVista === 1) {
            action.setLancamento({ ...lancamento, valor: lancamento.valor, parcela: lancamento.parcela, firstVencimento: lancamento.firstVencimento })
        }
        
        setIndexPaySelected(index)
        action.setPagamentoSelecionado(item)

    }
    return (
        <Col md={3} id="formaPagamento-container" >
            <Row>
                <Col className="colluns-buttons">
                    <Nav className="flex-column">
                        {
                            pagamentos.map((item, index: number) =>
                                <NavItem key={index} style={{ padding: 2, minWidth: 150 }}>
                                    <NavLink
                                        data-toggle="tab"
                                        className={IndexPaySelected === index ? "buttons-selected" : "buttons"}
                                        onClick={() => setPagamento(index, item)}
                                    >
                                        {item.descricao}
                                    </NavLink>
                                </NavItem>
                            )
                        }
                    </Nav>
                </Col>
            </Row>
        </Col>
    )
}