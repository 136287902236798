import React, { memo } from 'react'
import ReactTable from 'react-table'
import useShallowEqualSelector from '../../Util/Hooks/useShallowEqualSelector'

import AppState from '../../store/storeType'
import { Produtos } from '../../store/reducers/Cadastro/Produto/types'


const TableProdutos: React.FC<{ actions: (obj: Produtos) => JSX.Element }> = ({ actions }) => {

    const produtos = useShallowEqualSelector<AppState, Produtos[]>(state => state.produtos.produtos)

    return (

        <div style={produtos.length > 0 ? { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
            <ReactTable
                data={produtos}
                filterable={false}
                columns={
                    [
                        {
                            Header: "Numero Produto",
                            accessor: "numProduto"
                        },
                        {
                            Header: "Código de barras",
                            accessor: "codBarras"
                        },
                        {
                            Header: "Grupo",
                            accessor: "grupo"
                        },
                        {
                            Header: "Subgrupo",
                            accessor: "subgrupo"
                        },
                        {
                            Header: "Nome",
                            accessor: "nome"
                        },
                        {
                            Header: "Data de cadastro",
                            accessor: "dtCadastro"
                        },
                        {
                            Header: "Status",
                            accessor: "status",
                            Cell: ({ original }: { original: Produtos }) => original.status === 0 ? "Ativo" : "Inativo"
                        },
                        {
                            sortable: false,
                            filterable: false,
                            Cell: ({ original }: { original: Produtos }) => actions(original)
                        }
                    ]
                }
                minRows={1}
                showPaginationTop={false}
                className="-striped -highlight"
                noDataText=""
                showPaginationBottom={true}
                showPageSizeOptions={true}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando"
                pageText="Página"
                ofText="de"
                rowsText='linhas'
            />
        </div>

    )
}

export default memo(TableProdutos)