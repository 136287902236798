import React, { useEffect, useState } from "react";
import useModal from "../../../../../Util/Hooks/useModal"
import { Select } from "../../../../../components/ErnComponets/ErnComponets";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import useResize from "../../../../../Util/Hooks/useResize";
import ReactTable from "react-table";
import store from '../../../../../store/stores';
import classNames from "classnames";
import ArrowTooltip from '../../../../../components/Tooltip/ArrowTooltip';
import {
    Col,
    Row,
    CardBody,
    Button,
    Input,
    Collapse,
}
    from "reactstrap";
import { HelpButton } from "../../../../../components/HelpPlugin/HelpPlugin";
import ModalPesquisa from "../../Modals/PesquisaFornecedores";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ModalRecebimentoPagamentoRapido from "../../Modals/ReceberPagarRapido";
import ModalEnviarRecibo from "../../../../../components/Modals/Envios";
import { TableResultado } from "../../Components";
import * as controllerRecebimentosPagamentos from '../../../../../controller/Financeiro/controllerRecebimentosPagamentos';
import * as action from '../../../../../store/reducers/Financeiro/RecebimentoPagamento/actions'
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import AppState from "../../../../../store/storeType";
import { IRecebimentoPagamentoState } from "../../../../../store/reducers/Financeiro/RecebimentoPagamento/types";
import moment from "moment";
import { removeMaskMoney } from "../../../../../Util/removeMascaras";
import { tipoPesquisa } from '../../variables'

import * as actions from '../../../../../store/reducers/Movimentacao/SaidaDiversa/actions'

function Pesquisar() {

    const idStore = 'RECEBIMENTO_PAGAMENTO';

    const dispatch = useDispatch()

    const pesquisa = useShallowEqualSelector<AppState, IRecebimentoPagamentoState['pesquisa']>(state => state.recebimentoPagamento.pesquisa);
    const recebimentosPagamentos = useShallowEqualSelector<AppState, IRecebimentoPagamentoState['recebimentosPagamentos']>(state => state.recebimentoPagamento.recebimentosPagamentos);
    const empresas = useShallowEqualSelector<AppState, IRecebimentoPagamentoState['empresas']>(state => state.recebimentoPagamento.empresas);
    const informacoes = useShallowEqualSelector<AppState, IRecebimentoPagamentoState['informacoes']>(state => state.recebimentoPagamento.informacoes);
    const informacoesRecebimentosPagamentos = useShallowEqualSelector<AppState, IRecebimentoPagamentoState['informacoesRecebimentosPagamentos']>(state => state.recebimentoPagamento.informacoesRecebimentosPagamentos);
    const grupos = useShallowEqualSelector<AppState, IRecebimentoPagamentoState['grupos']>(state => state.recebimentoPagamento.grupos);
    const subGrupos = useShallowEqualSelector<AppState, IRecebimentoPagamentoState['subGrupos']>(state => state.recebimentoPagamento.subGrupos);
    //@ts-ignore
    const { codEmpresaFilial } = useSelector(state => state.login.usuario.empresa);

    const [modalPesquisa, toggleModalPesquisar] = useModal(false);
    const [modalReceberPagarRapido, toggleModalReceberPagarRapido] = useModal(false);
    const [modalEnviarRecibo, toggleModalEnviarRecibo] = useModal(false);

    const { width } = useResize();
    const [tipoEnvio, setTipoEnvio] = useState('')
    const [maisFiltros, setMaisFiltros] = useState(false);
    const [codigoTituloMovimento, setCodigoTituloMovimento] = useState(0)
    const [somas, setSoma] = useState({
        credito: {
            previsao: 0,
            baixado: 0,
            restante: 0

        },
        debito: {
            previsao: 0,
            baixado: 0,
            restante: 0

        },
        total: {
            previsao: 0,
            baixado: 0,
            restante: 0

        }
    })

    useEffect(() => {
        var somaDados = {
            credito: {
                previsao: 0,
                baixado: 0,
                restante: 0

            },
            debito: {
                previsao: 0,
                baixado: 0,
                restante: 0

            },
            total: {
                previsao: 0,
                baixado: 0,
                restante: 0

            }
        }
        recebimentosPagamentos.forEach((data: { tipoTitulo: string; status: string; valor: any; }) => {
            if (data.tipoTitulo === 'C') {
                if (data.status === 'PAGO') {
                    somaDados.credito.baixado += Number(removeMaskMoney(data.valor))
                }
                somaDados.credito.previsao += Number(removeMaskMoney(data.valor))
            } else {
                if (data.status === 'PAGO') {
                    somaDados.debito.baixado += Number(removeMaskMoney(data.valor))
                }
                somaDados.debito.previsao += Number(removeMaskMoney(data.valor))
            }

        });
        somaDados.debito.restante = somaDados.debito.previsao - somaDados.debito.baixado
        somaDados.credito.restante = somaDados.credito.previsao - somaDados.credito.baixado
        somaDados.total.previsao = somaDados.credito.previsao - somaDados.debito.previsao
        somaDados.total.baixado = somaDados.credito.baixado - somaDados.debito.baixado
        somaDados.total.restante = somaDados.credito.restante - somaDados.debito.restante
        setSoma(somaDados)
    }, [recebimentosPagamentos])

    useEffect(() => {
        controllerRecebimentosPagamentos.getGrupos()
        controllerRecebimentosPagamentos.GetEmpresasUser()
        action.setPesquisa({ ...pesquisa, dataInicio: moment().format('YYYY-MM-DD'), dataFim: moment().format('YYYY-MM-DD') })
        controllerRecebimentosPagamentos.getCaixaAberto()

        if (pesquisa.codGrupoConta) {
            controllerRecebimentosPagamentos.getSubGrupos(pesquisa.codGrupoConta)
        }
    }, [])

    useEffect(() => {
        let filial: any = []
        empresas.forEach((empresa: any) => {
            if (empresa.codEmpresaFilial === codEmpresaFilial) {
                filial.push(empresa.codEmpresaFilial)
            }
        })
        action.setCodEmpresaFilial(filial)
    }, [empresas])

    function confirmModalFornecedor(value: any) {
        controllerRecebimentosPagamentos.getFornecedores(value.documento, value.nomeRazao, value.tipo)
        action.setPesquisa({ ...pesquisa, nomeFor: value.nomeRazao, cpfCnpj: value.documento, codCliente: value.codigo })
        toggleModalPesquisar()
        dispatch({ idStore, type: 'CLEAR_FORNECEDOR' })
    }

    function cleanFornecedor() {
        dispatch({ idStore, type: "CLEAN_FORNECEDOR" })
    }

    function limpar(e: React.MouseEvent) {
        e.preventDefault();
        dispatch({ idStore, type: "CLEAN_PESQUISA" })
    }

    function pesquisar() {
        controllerRecebimentosPagamentos.getRecebimentosPagamentos(pesquisa)
    }

    function handleRecebimentosPagamentos(titulo: any) {
        actions.setScreen(1)
        action.setInformacoesRecebimentosPagamentos({ ...informacoesRecebimentosPagamentos, typeAction: 'recebimentos', valorTitulo: titulo.valor, codigoTituloMovimento: titulo.codigoTituloMovimento })
    }

    const handleEnviarRecibo = (codigoTituloMovimento: number) => {
        setCodigoTituloMovimento(codigoTituloMovimento)
        setTipoEnvio('recibimentosPagamentos')
        toggleModalEnviarRecibo()
    }

    useEffect(() => {
        controllerRecebimentosPagamentos.getSubGrupos(pesquisa.codGrupoConta)
    }, [pesquisa.codGrupoConta])

    return (
        <>
            <Col lg="12">
                <div className="content">

                    <Row className="padding-padrao-row">
                        <Col className="padding-padrao-col" lg={4}>
                            <label>Empresas / Filial</label>
                            <Select
                                //@ts-ignore
                                first={"Selecione"}
                                options={empresas}
                                value={{ value: codEmpresaFilial }}
                                select={{ value: 'codEmpresaFilial', label: 'descricaoEmpresa' }}
                                //@ts-ignore
                                onChange={(e: number) => { action.setPesquisa({ ...pesquisa, codEmpresaFilial: e.value }) }}
                            />
                        </Col>

                        <Col className="padding-padrao-col" lg={2}>
                            <label>Cliente/Fornecedor</label>
                            <Input
                                type="text"
                                //@ts-ignore
                                value={pesquisa.cpfCnpj}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { action.setPesquisa({ ...pesquisa, cpfCnpj: event.currentTarget }) }}
                            />
                        </Col>

                        <Col className="padding-padrao-col" lg={0.5}>
                            <Button
                                style={{ marginTop: '25px', width: '40px', marginBottom: '20px' }}
                                type="button"
                                color="info"
                                onClick={() => toggleModalPesquisar()}
                            >
                                <Icon icon="search" className="fa-lg" />
                            </Button>
                        </Col>

                        <Col className="padding-padrao-col">
                            <Input
                                style={{ marginTop: 25, cursor: 'default' }}
                                type="text"
                                //@ts-ignore
                                value={pesquisa.nomeFor}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { action.setPesquisa({ ...pesquisa, nomeFor: event.currentTarget, cliente: event.currentTarget }) }}
                                disabled={true}
                            />
                        </Col>
                    </Row>

                    <Row className="padding-padrao-row">
                        <Col className="padding-padrao-col" md="2">
                            <label>Início</label>
                            <Input
                                type="date"
                                id="dataInicio"
                                value={pesquisa.dataInicio}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    action.setPesquisa({
                                        ...pesquisa,
                                        dataInicio: event.currentTarget.value,
                                    });
                                }}
                            />
                        </Col>

                        <Col className="padding-padrao-col" md="2">
                            <Row lg={1}>
                                <Col>
                                    <div style={{ display: "inline-flex" }}>
                                        <label style={{ whiteSpace: "nowrap" }}>
                                            Final
                                        </label>
                                        <HelpButton id="24" />
                                    </div>
                                </Col>
                            </Row>
                            <Input
                                type="date"
                                id="dataFim"
                                value={pesquisa.dataFim}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    action.setPesquisa({
                                        ...pesquisa,
                                        dataFim: event.currentTarget.value,
                                    });
                                }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="pt-2">
                                <Button color="info" onClick={() => setMaisFiltros(!maisFiltros)} >{maisFiltros ? '-' : '+'} Filtros</Button>
                                <Collapse isOpen={maisFiltros}>
                                    <Col className="padding-padrao-col" style={{ border: '1px solid #333' }} >
                                        <Row md="12" className="padding-padrao-row" >
                                            <Col md="4" className="padding-padrao-col" >
                                                <label htmlFor="">Tipo de Pesquisa</label>
                                                <Select
                                                    //@ts-ignore
                                                    options={tipoPesquisa}
                                                    select={{ value: 'codDescricaoLancamento', label: 'lancamentoDesc' }}
                                                    onChange={(e: any) => action.setPesquisa({ ...pesquisa, tipoPesq: e.value })}
                                                    value={{ value: pesquisa.tipoPesq }}
                                                />
                                            </Col>
                                            <Col md="4" className="padding-padrao-col" >
                                                <label htmlFor="">Selecione/Digite</label>
                                                <Input type="text" onChange={(e: React.ChangeEvent<HTMLInputElement>) => action.setPesquisa({ ...pesquisa, textPesq: e.target.value })} />
                                            </Col>
                                            <Col md="4" className="padding-padrao-col" >
                                                <Row md="12" className="padding-padrao-row">
                                                    <Col md="4" className="padding-padrao-col" >
                                                        <label htmlFor="">Título:</label>
                                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                                            <input
                                                                type="radio"
                                                                name="titulo"
                                                                onChange={() => action.setPesquisa({ ...pesquisa, titulo: 'TODOS' })}
                                                                checked={pesquisa.titulo === 'TODOS'}
                                                            />
                                                            <label htmlFor="" style={{ margin: '0 0 0 2px' }} >Todos</label>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                                            <input
                                                                type="radio"
                                                                name="titulo"
                                                                onChange={() => action.setPesquisa({ ...pesquisa, titulo: 'CREDITO' })}
                                                                checked={pesquisa.status === 'CREDITO'}
                                                            />
                                                            <label htmlFor="" style={{ margin: '0 0 0 2px' }} >Crédito</label>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                                            <input
                                                                type="radio"
                                                                name="titulo"
                                                                onChange={() => action.setPesquisa({ ...pesquisa, titulo: 'DEBITO' })}
                                                                checked={pesquisa.status === 'DEBITO'}
                                                            />
                                                            <label htmlFor="" style={{ margin: '0 0 0 2px' }} >Débito</label>
                                                        </div>
                                                    </Col>
                                                    <Col md="4" className="padding-padrao-col" >
                                                        <label htmlFor="">Status:</label>
                                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                                            <input
                                                                type="radio"
                                                                name="status"
                                                                onChange={() => action.setPesquisa({ ...pesquisa, status: '' })}
                                                                checked={pesquisa.status === ''}
                                                            />
                                                            <label htmlFor="" style={{ margin: '0 0 0 2px' }} >Todos</label>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                                            <input
                                                                type="radio"
                                                                name="status"
                                                                onChange={() => action.setPesquisa({ ...pesquisa, status: 'PAGO' })}
                                                                checked={pesquisa.status === 'PAGO'}
                                                            />
                                                            <label htmlFor="" style={{ margin: '0 0 0 2px' }} >Pago</label>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                                            <input
                                                                type="radio"
                                                                name="status"
                                                                onChange={() => action.setPesquisa({ ...pesquisa, status: 'ABERTO' })}
                                                                checked={pesquisa.status === 'ABERTO'}
                                                            />
                                                            <label htmlFor="" style={{ margin: '0 0 0 2px' }} >Não Pago</label>
                                                        </div>
                                                    </Col>
                                                    <Col md="4" className="padding-padrao-col" >
                                                        <label htmlFor="">Data por:</label>
                                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                                            <input
                                                                type="radio"
                                                                name="datas"
                                                                onChange={() => action.setPesquisa({ ...pesquisa, tipoData: 'VEN' })}
                                                                checked={pesquisa.tipoData === 'VEN'}
                                                            />
                                                            <label htmlFor="" style={{ margin: '0 0 0 2px' }} >Vencimento</label>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                                            <input
                                                                type="radio"
                                                                name="datas"
                                                                onChange={() => action.setPesquisa({ ...pesquisa, tipoData: 'LAN' })}
                                                                checked={pesquisa.status === 'LAN'}
                                                            />
                                                            <label htmlFor="" style={{ margin: '0 0 0 2px' }} >Lançamento</label>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                                            <input
                                                                type="radio"
                                                                name="datas"
                                                                onChange={() => action.setPesquisa({ ...pesquisa, tipoData: 'PAG' })}
                                                                checked={pesquisa.status === 'PAG'}
                                                            />
                                                            <label htmlFor="" style={{ margin: '0 0 0 2px' }} >Pagamento</label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row md="12" className="padding-padrao-row">
                                            <Col md="6" className="padding-padrao-col" >
                                                <label htmlFor="">Grupo de Contas</label>
                                                <Select
                                                    first="Todos"
                                                    //@ts-ignore
                                                    options={grupos}
                                                    select={{ value: 'codGrupoConta', label: 'descricao' }}
                                                    onChange={(e: any) => action.setPesquisa({ ...pesquisa, codGrupoConta: e.value })}
                                                    value={{ value: pesquisa.codGrupoConta }}
                                                />
                                            </Col>
                                            <Col md="6" className="padding-padrao-col" >
                                                <label htmlFor="">SubGrupo de Contas</label>
                                                <Select
                                                    first="Todos"
                                                    //@ts-ignore
                                                    options={subGrupos}
                                                    select={{ value: 'codSubgrupoConta', label: 'descricao' }}
                                                    onChange={(e: any) => action.setPesquisa({ ...pesquisa, codSubGrupoConta: e.value })}
                                                    value={{ value: pesquisa.codSubGrupoConta }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Collapse>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <CardBody style={{ paddingTop: "0" }}>
                            <Col style={{ width: '100%' }}>
                                <Button
                                    color='info'
                                    style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                    onClick={() => {
                                        pesquisar()
                                    }}
                                >
                                    Pesquisar
                                </Button>
                                <Button
                                    color='info'
                                    style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                    //@ts-ignore
                                    onClick={(e: React.MouseEvent<any, MouseEvent>) => {
                                        limpar(e)
                                    }}
                                >
                                    Limpar
                                </Button>
                            </Col>
                        </CardBody>
                    </Row>
                    {recebimentosPagamentos.length === 0
                        ? null :
                        <Col className="table-recibemento" >
                            <ReactTable
                                data={recebimentosPagamentos}
                                filterable={false}
                                NoDataComponent={() => (<></>)}
                                columns={
                                    [
                                        {
                                            Header: "CLIENTE/FORNECEDOR",
                                            accessor: "nome",
                                            width: 350
                                        },
                                        {
                                            Header: "GRUPO",
                                            accessor: "descricaoGrupoConta",
                                            width: 150
                                        },
                                        {
                                            Header: "SUBGRUPO",
                                            accessor: "descricaoSubGrupoConta",
                                            width: 200
                                        },
                                        {
                                            Header: "DESCRIÇÃO TÍTULO",
                                            accessor: "descricaoTitulo",
                                            width: 350
                                        },
                                        {
                                            Header: "PARCELA",
                                            accessor: "parcela",
                                            width: 150
                                        },
                                        {
                                            Header: "VALOR",
                                            accessor: "valor",
                                            width: 150
                                        },
                                        {
                                            Header: "DESCONTO",
                                            accessor: "valorDesconto",
                                            width: 150
                                        },
                                        {
                                            Header: "JUROS",
                                            accessor: "valorJuros",
                                            width: 150
                                        },
                                        {
                                            Header: "TARIFA",
                                            accessor: "valorTarifa",
                                            width: 150
                                        },
                                        {
                                            Header: "VLR. PAGO",
                                            accessor: "valorTotalPago",
                                            width: 150
                                        },
                                        {
                                            Header: "FORMA PAGAMENTO/TIPO",
                                            accessor: "formaPagamento",
                                            width: 250
                                        },
                                        {
                                            Header: "REFERÊNCIA",
                                            accessor: "referencia",
                                            width: 100
                                        },
                                        {
                                            Header: "VENCIMENTO",
                                            accessor: "vencimento",
                                            width: 150
                                        },
                                        {
                                            Header: "PAGAMENTO",
                                            accessor: "pagamento",
                                            width: 150
                                        },
                                        {
                                            Header: "STATUS",
                                            accessor: "status",
                                            width: 150,
                                            Cell: (props) => props.original.status === "ABERTO" ? <span style={{ color: 'red' }}>ABERTO</span> : <span style={{ color: 'green' }}>PAGO</span>
                                        },
                                        {
                                            Header: "TIPO",
                                            accessor: "tipoTitulo",
                                            width: 60,
                                            Cell: (props) => props.original.tipoTitulo === 'C' ? <span><strong style={{ color: 'DeepSkyBlue' }}>C</strong></span> : <span><strong style={{ color: 'red' }}>D</strong></span>
                                        },
                                        {
                                            Header: "OPERAÇÕES",
                                            sortable: false,
                                            filterable: false,
                                            width: 200,
                                            Cell: (props) => (
                                                <>
                                                    <ArrowTooltip title="Receber/Pagar Rápido">
                                                        <Button
                                                            onClick={() => {
                                                                action.setLancamentoRapido('valorTotal', props.original.valor);
                                                                action.setLancamentoRapido('valorLancamento', props.original.valor);
                                                                setCodigoTituloMovimento(props.original.codigoTituloMovimento);
                                                                toggleModalReceberPagarRapido();
                                                            }}
                                                            size="sm"
                                                            className={"btn-icon btn-link"}
                                                            style={{ color: "#FFF" }}

                                                        >
                                                            <i className="tim-icons icon-money-coins"></i>
                                                        </Button>
                                                    </ArrowTooltip>

                                                    <ArrowTooltip title="Receber/Pagar">
                                                        <Button
                                                            size="sm"
                                                            className={"btn-icon btn-link"}
                                                            style={{ color: "#FFF" }}
                                                            onClick={() => { handleRecebimentosPagamentos(props.original); }}
                                                        >
                                                            <i className="tim-icons icon-wallet-43"></i>
                                                        </Button>
                                                    </ArrowTooltip>

                                                    <ArrowTooltip title="Enviar Recibo">
                                                        <Button
                                                            onClick={() => {
                                                                handleEnviarRecibo(props.original.codigoTituloMovimento)
                                                            }}
                                                            size="sm"
                                                            className={"btn-icon btn-link"}
                                                            style={{ color: "#FFF" }}
                                                            disabled={props.original.status === 'PAGO' ? false : true}
                                                        >
                                                            <i className="tim-icons icon-paper"></i>
                                                        </Button>
                                                    </ArrowTooltip>

                                                    <ArrowTooltip title="Editar">
                                                        <Button
                                                            size="sm"
                                                            className={"btn-icon btn-link"}
                                                            style={{ color: "#49Bf9A" }}
                                                        >
                                                            <Icon icon="edit" className="fa-lg" />
                                                        </Button>
                                                    </ArrowTooltip>

                                                    <ArrowTooltip title="Excluir">
                                                        <Button
                                                            size="sm"
                                                            className={"btn-icon btn-link"}
                                                            style={{ color: "#C3527B" }}
                                                        >
                                                            <Icon icon="trash-alt" className="fa-lg" />
                                                        </Button>
                                                    </ArrowTooltip>
                                                </>
                                            )
                                        },
                                    ]
                                }
                                minRows={1}
                                defaultPageSize={5}
                                previousText="Anterior"
                                nextText="Próximo"
                                loadingText="Carregando"
                                pageText="Página"
                                ofText="de"
                                rowsText='linhas'
                            />

                            {<p style={{ color: "grey" }}><i>Arraste para mais informações*</i></p>}

                            <TableResultado
                                creditoPrevisao={somas.credito.previsao.toFixed(2)}
                                creditoBaixados={somas.credito.baixado.toFixed(2)}
                                creditoRestantes={somas.credito.restante.toFixed(2)}
                                debitoPrevisao={somas.debito.previsao.toFixed(2)}
                                debitoBaixados={somas.debito.baixado.toFixed(2)}
                                debitoRestantes={somas.debito.restante.toFixed(2)}
                                totalPrevisao={somas.total.previsao.toFixed(2)}
                                totalBaixados={somas.total.baixado.toFixed(2)}
                                totalRestantes={somas.total.restante.toFixed(2)}
                            />

                        </Col>}


                    {modalPesquisa &&
                        <ModalPesquisa
                            isOpen={modalPesquisa}
                            toggle={toggleModalPesquisar}
                            confirmModal={confirmModalFornecedor}
                            clean={cleanFornecedor}
                        />
                    }

                    {modalReceberPagarRapido &&
                        <ModalRecebimentoPagamentoRapido
                            isOpen={modalReceberPagarRapido}
                            toggle={toggleModalReceberPagarRapido}
                            codigoTituloMovimento={codigoTituloMovimento}
                        />
                    }

                    {modalEnviarRecibo && <ModalEnviarRecibo
                        tipoEnvio={tipoEnvio}
                        isOpen={modalEnviarRecibo}
                        callback={() => { }}
                        toggle={toggleModalEnviarRecibo}
                        codigoTituloMovimento={codigoTituloMovimento}
                    />}
                </div>
            </Col>
        </>

    )
}

export default Pesquisar