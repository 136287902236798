import React from 'react'

import {
    Modal,
    Col,
    ModalBody,
    Row,
    Button,
    CardFooter
} from 'reactstrap'



export function PopUpAbrirCaixa({ isOpen, callback, toggle }) {
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => toggle && toggle()}
            >
                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggle && toggle()}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Atenção</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>Não é possível salvar um pedido sem um caixa aberto. Deseja abrir?</label>
                    </Col>

                </ModalBody>
                <CardFooter style={{ backgroundColor: 'transparent' }}>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => toggle && toggle()}
                                style={{ width: '150px' }}
                            >NÃO </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { callback() }}
                                style={{ width: '150px' }}
                            >SIM </Button>
                        </Col>
                    </Row>

                </CardFooter>
            </Modal>
        </>
    )
}