import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import ReactTable from "react-table";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from '../../../components/ModalBasic/ModalBasic.jsx';
import useScrollBar from '../../../Util/Hooks/useScrollBar';
import { HelpButton } from '../../../components/HelpPlugin/HelpPlugin'
import useShallowEqualSelector from '../../../Util/Hooks/useShallowEqualSelector';
import AppState from '../../../store/storeType.js';
import { IInventarioState } from '../../../store/reducers/Movimentacao/Inventario/types';
import * as action from '../../../store/reducers/Movimentacao/Inventario/actions'

type Toggle = (newState?: boolean, callback?: (state?: boolean) => void) => void

interface Props {
    isOpen: boolean
    toggle: Toggle
}

export default function ModalPesquisa({ isOpen, toggle }: Props) {
    const listGradePesquisa = useShallowEqualSelector<AppState, IInventarioState['listGradePesquisa']>(state => state.inventario.listGradePesquisa)

    const tableRef = useScrollBar()

    function closeModal() {
        action.setGradePesquisa([])
        toggle()
    }

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => closeModal()}
            title={'GRADE DE PRODUTOS'}
            size="lg"
        >
            <ModalBasicBody>
                <h4 style={{ textAlign: 'center' }}>Quantidade de estoques do invetário da grade de produtos</h4>

                <Row style={{ justifyContent: 'flex-end', paddingRight: 20, paddingBottom: 10 }}>
                    <HelpButton id={19} />
                </Row>
                <Row>
                    <Col>
                        <fieldset className="fieldset-operacao">
                            <div
                                ref={tableRef}
                            >
                                <ReactTable
                                    data={listGradePesquisa}
                                    columns={[
                                        {
                                            Header: 'Descrição',
                                            accessor: 'descricao',
                                            resizable: false,
                                            sortable: false,
                                            width: 150
                                        },
                                        {
                                            Header: 'Sigla',
                                            accessor: 'sigla',
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Estoque Anterior',
                                            accessor: 'estoqueAnterior',
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Estoque Inventário',
                                            accessor: 'estoqueInventario',
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Estoque Atual',
                                            accessor: 'estoqueAtual',
                                            resizable: false,
                                            sortable: false
                                        },
                                    ]}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                    noDataText=""
                                    minRows={listGradePesquisa.length}
                                />
                            </div>
                        </fieldset>
                    </Col>
                </Row>
            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        onClick={() => closeModal()}
                        color={"info"}
                    >Fechar
                    </Button>
                </Col>
            </ModalBasicFooter>
        </ModalBasic>
    )
}