import {
    Col,
    Button,
    Input,
    Row,
} from "reactstrap";

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import React, { useState } from "react";

import useShallowEqualSelector from "../../../../../../Util/Hooks/useShallowEqualSelector";
import AppState from '../../../../../../store/storeType';
import { IOperacaoState } from '../../../../../../store/reducers/Cadastro/Operacao/types'

import { Select } from "../../../../../../components/ErnComponets/ErnComponets";

import * as actions from '../../../../../../store/reducers/Cadastro/Operacao/actions';


import { maskNumbers } from "../../../../../../Util/mascaras";
import ArrowTooltip from '../../../../../../components/Tooltip/ArrowTooltip';
import ReactTable from "react-table";
import * as controllerOperacoes from '../../../../../../controller/controllerOperacoes';
import { PopUpDelete } from '../../../../Modals/PopUps';
import useModal from "../../../../../../Util/Hooks/useModal";

export function InputPesquisa() {



    const pesquisa = useShallowEqualSelector<AppState, IOperacaoState['pesquisa']>(state => state.operacao.pesquisa)

    return (<>
        <Row className="padding-padrao-row">
            <Col className="padding-padrao-col" lg="2" md="2">
                <label>Número</label>
                <Input
                    id="numero"
                    type="text"
                    autoComplete="off"
                    value={pesquisa.numero}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => (actions.setPesquisa({ ...pesquisa, numero: maskNumbers(event.currentTarget.value) }))}
                    maxLength={10}
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => { e.key === 'Enter' ? controllerOperacoes.Pesquisar(pesquisa) : null }}
                    autoFocus={true}
                />
            </Col>

            <Col className="padding-padrao-col" lg="7" md="7">
                <label>Descrição</label>
                <Input
                    id="descricao"
                    type="text"
                    autoComplete="off"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => (actions.setPesquisa({ ...pesquisa, descricao: event.currentTarget.value }))}
                    value={pesquisa.descricao}
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => { e.key === 'Enter' ? controllerOperacoes.Pesquisar(pesquisa) : null }}

                />
            </Col>
            <Col className="padding-padrao-col" lg="3" md="3">
                <label>Tipo</label>
                <Select
                    id="tipo"
                    first={'Todos'}
                    //@ts-ignore
                    options={[{ value: 'E', label: 'Entrada' }, { value: 'S', label: 'Saída' }]}
                    value={{ value: pesquisa.tipo }}
                    //@ts-ignore
                    onChange={(event: React.onChange<HTMLSelectElement>) => (actions.setPesquisa({ ...pesquisa, tipo: event.value }))}
                />
            </Col>

        </Row>
    </>)
}


export function ButtonPesquisa() {

    const pesquisa = useShallowEqualSelector<AppState, IOperacaoState['pesquisa']>(state => state.operacao.pesquisa)

    function handleLimpar(e: any) {
        actions.cleanSearch()

        e.preventDefault();
    }

    function handlePesquisar(e: any) {
        e.preventDefault();
        controllerOperacoes.Pesquisar(pesquisa);
    }
    const [width] = useState(window.innerWidth);

    return (<>

        <Col style={{ width: '100%' }}>
            <ArrowTooltip title='Shift+L'>
                <Button
                    color='info'
                    style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                    onClick={e => { handleLimpar(e) }}
                >Limpar</Button>
            </ArrowTooltip>
            <ArrowTooltip title='Shift+P'>

                <Button
                    color='info'
                    style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                    onClick={e => { handlePesquisar(e) }}
                >Pesquisar</Button>
            </ArrowTooltip>
        </Col>    </>)
}

export function ReactTablePesquisa() {

    const operacoes = useShallowEqualSelector<AppState, IOperacaoState['operacoes']>(state => state.operacao.operacoes)
    const [isOpen, togglePopUp] = useModal(false)
    const [codigoOperacao, setCodigoOperacao] = useState(0);

    async function handleVisualizarCadastro(cod: Number) {
        actions.setVerCadastro(true)
        controllerOperacoes.GetOperacao(cod)
    }

    function excluir(id: number) {
        setCodigoOperacao(id)
        togglePopUp()
    }
    return (
        <div >

            <ReactTable
                data={operacoes}
                filterable={false}
                NoDataComponent={() => (<></>)}
                columns={
                    [
                        {
                            Header: "Número",
                            accessor: "numero"
                        },
                        {
                            Header: "Descrição",
                            accessor: "descricao"
                        },
                        {
                            Header: "Tipo",
                            accessor: "tipo",
                            Cell: (props) => props.original.tipo === 'E' ? 'ENTRADA' : "SAÍDA"
                        },
                        {
                            Header: "",
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                            Cell: (props) => (
                                <>

                                    <ArrowTooltip title="Visualizar">
                                        <Button
                                            onClick={() => {
                                                handleVisualizarCadastro(props.original.codigo);
                                            }}
                                            size="sm"
                                            className={"btn-icon btn-link"}
                                            style={{ color: "#483D8B" }}

                                        >
                                            <Icon icon="eye" className="fa-lg" />
                                        </Button>
                                    </ArrowTooltip>
                                    <ArrowTooltip title="Editar">
                                        <Button
                                            onClick={() => controllerOperacoes.GetOperacao(props.original.codigo)}
                                            size="sm"
                                            className={"btn-icon btn-link"}
                                            color="success"
                                        >
                                            <Icon icon="edit" className="fa-lg" />
                                        </Button>
                                    </ArrowTooltip>


                                    <ArrowTooltip title="Excluir">
                                        <Button
                                            onClick={() => excluir(props.original.codigo)}
                                            className="btn-icon btn-link"
                                            color="danger"
                                        >
                                            <Icon icon="trash-alt" className="fa-lg" />
                                        </Button>
                                    </ArrowTooltip>
                                </>
                            )
                        }
                    ]
                }
                minRows={1}
                showPaginationTop={false}
                className="-striped -highlight"
                noDataText=""
                showPaginationBottom={true}
                showPageSizeOptions={true}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando"
                pageText="Página"
                ofText="de"
                rowsText='linhas'
            />
            {
                <PopUpDelete isOpen={isOpen} callback={() => controllerOperacoes.Excluir(codigoOperacao, togglePopUp)} toggle={togglePopUp} />
            }
        </div>
    )
}