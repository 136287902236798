import axios from 'axios';
import { alertar } from "../../Util/alertar"
import store from '../../store/stores';
import api from '../../Util/api';
import * as action from '../../store/reducers/Financeiro/RecebimentoPagamento/actions'
import * as actionPDV from '../../store/reducers/Movimentacao/PedidoVenda/actions'
import { maskMoeda } from '../../Util/mascaras';
import { SweetAlert } from '../../Util/sweetAlert';
import { ILancamento } from '../../store/reducers/Financeiro/RecebimentoPagamento/types';
import { removeMaskMoney } from '../../Util/removeMascaras';
import moment from 'moment';

const idStore = 'RECEBIMENTO_PAGAMENTO';

interface IPesquisa {
    codEmpresaFilial: Number | null
    codCliente: Number | null
    nomeFor: String
    dataInicio: String
    dataFim: String,
    tipoPesq: string,
    textPesq: string,
    status: string,
    titulo: string,
    tipoData: string,
    codGrupoConta: number,
    codSubGrupoConta: number,
}

export async function getRecebimentosPagamentos(pesquisa: IPesquisa) {

    interface ITitulo {
        codigoTituloMovimento: Number
        formaPagamento: String
        nome: String
        parcela: String
        quantidadeParcelas: Number
        referencia: String
        status: String
        tipo: String
        tipoTitulo: String
        valor: Number
        valorDesconto: Number
        valorJuros: Number
        valorMulta: Number
        valorTarifa: Number
        valorTotalPago: Number
        vencimento: String
    }

    const dispatch = store.dispatch
    //@ts-ignore
    const { notificacao } = store.getState();

    try {
        dispatch({ idStore, type: 'RECEBIMENTO_PAGAMENTO', value: [] })
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        await axios.get(api.urlFinanceiro + `api/v1/titulos/buscaTitulos?CodigoFilial=${pesquisa.codEmpresaFilial}&CodigoCliente=${pesquisa.codCliente}&Cliente=${pesquisa.nomeFor}&DataInicio=${pesquisa.dataInicio}&DataFim=${pesquisa.dataFim}&TipoData=${pesquisa.tipoData}&Status=${pesquisa.status}&TipoTitulo=${pesquisa.titulo}&Grupo=${pesquisa.codGrupoConta === 0 ? '' : pesquisa.codGrupoConta}&SubGrupo=${pesquisa.codSubGrupoConta === 0 ? '' : pesquisa.codSubGrupoConta}&TipoPesquisa=${pesquisa.tipoPesq}&ParametroTipoPesquisa=${pesquisa.textPesq}`, {
            headers: { Authorization: bearerAuth }
        }).then((response) => {
            if (response.data.length === 0) {
                return alertar(notificacao, 'br', "Nenhum Recebimento/Pagamento encontrado", 'info', 'icon-bulb-63', 2)
            }
            response.data.map((titulo: ITitulo) => {
                //@ts-ignore
                titulo.valor = maskMoeda(titulo.valor.toFixed(2))
                titulo.valorDesconto = maskMoeda(titulo.valorDesconto.toFixed(2))
                titulo.valorTotalPago = maskMoeda(titulo.valorTotalPago.toFixed(2))
            });
            action.setRecebimentosPagamentos(response.data)
        })
    } catch {
        return alertar(notificacao, 'br', "Não foi possível realizar pesquisa de Recebimentos / Pagamentos", 'warning', 'icon-alert-circle-exc', 2)
    }

}

export async function getByIdRecebimentosPagamentos(id: Number, typeAction: string) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        await axios.get(api.urlFinanceiro + `api/v1/titulos/buscaTitulos/${id}`, {
            headers: { Authorization: bearerAuth }
        }).then((response) => {
            if (response.data.length === 0) {
                return alertar(notificacao, 'br', "Nenhum Recebimento/Pagamento encontrado", 'info', 'icon-bulb-63', 2)
            }
            if (typeAction === 'recebimentos') {
                const { recebimentoPagamento: { informacoesRecebimentosPagamentos } } = store.getState()
                const { valor, codigoConta, lancamentos } = response.data
                action.setInformacoesRecebimentosPagamentos({ ...informacoesRecebimentosPagamentos, valorTitulo: maskMoeda(Number(valor).toFixed(2)), codigoConta, lancamentos })
            }
        })
    } catch (e) {

    }
}

export async function getFornecedores(documento: any, nomeRazao: any, tipo: any) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        return await axios.get(api.urlCadastro + `api/v1/destinario/destinarios?tipo=${tipo}&cpfCnpj=${documento}&nome=${nomeRazao}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma Pessoa ou fornecedor encontrado", 'info', 'icon-bulb-63', 2)
            }
            action.setFornecedores(response.data.records)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os fornecedores", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetEmpresasUser() {
    //@ts-ignore
    const { notificacao } = store.getState()

    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/empresa/empresasUsuario`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            action.setEmpresas(response.data.records)
        }).catch(e => {
            return
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as Empresas/Filiais", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export async function getFormasPgto() {
    //@ts-ignore
    const { notificacao } = store.getState()

    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/formaPgto/formasPgto?status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            action.setFormasPagamentos(response.data.records)
        }).catch(e => {
            return
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as Formas de Pagamento", 'warning', 'icon-alert-circle-exc', 2, e)
    }

}

export async function getContas() {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios.get(api.urlCadastro + `api/v1/conta/Contas`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma conta encontrada", 'info', 'icon-bulb-63', 2)
            }
            action.setContas(response.data.records)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as contas", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function getCaixaAberto() {
    let funcionario: any
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/funcionario/funcionario`, {
            headers: { Authorization: basicAuth }
        }).then(async response => {
            funcionario = response.data.records.codFunc
            try {
                const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
                await axios.get(api.urlCadastro + `api/v1/caixa/caixaAberto?codFuncionario=${funcionario}`, {
                    headers: { Authorization: basicAuth }
                }).then(response => {
                    //@ts-ignore
                    return action.setInformacoes(response.data.records)
                }).catch(error => {
                    if (error.response)
                        SweetAlert('', error.response.data.message, 'error', false)
                    else
                        SweetAlert('', "Não foi possivel obter o Caixa", 'error', false)
                })
            } catch (e) {
                SweetAlert('Ops...', "Não foi possivel obter o Caixa", 'error', false)
            }
        }).catch(() => {
            SweetAlert('', "Não foi possivel obter o Funcionario", 'error', false)
        })
    } catch (e) {
        SweetAlert('Ops...', "Não foi possivel obter o Funcionario", 'error', false)
    }
}



export async function lancarPagamentosRecebimentos(lancamento: ILancamento, toggle: Function) {
    //@ts-ignore
    const { notificacao } = store.getState();
    const { recebimentoPagamento: { pesquisa } } = store.getState()
    if (lancamento.titulosLancamentos[0].codigoForma === 0) {
        return alertar(notificacao, 'br', "Escolha a forma de pagamento", 'warning', 'icon-bulb-63', 2)
    }
    if (lancamento.titulosLancamentos[0].codigoConta === 0) {
        return alertar(notificacao, 'br', "Escolha a conta para o pagamento.", 'warning', 'icon-bulb-63', 2)
    }
    try {
        lancamento.titulosLancamentos.forEach((titulo) => {
            titulo.valorTotal = Number(removeMaskMoney(titulo.valorTotal))
            titulo.valorLancamento = Number(removeMaskMoney(titulo.valorLancamento))
        })
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        await axios.post(api.urlFinanceiro + `api/v1/tituloLancamentos/lancamentos`, lancamento, {
            headers: { Authorization: bearerAuth }
        }).then((response) => {
            getRecebimentosPagamentos(pesquisa)
            toggle()
            return alertar(notificacao, 'br', "Lançamento realizado com sucesso!", 'success', 'icon-bulb-63', 2)
        })
    } catch (e) {
        return alertar(notificacao, 'br', "Não foi possivel realizar o Lançamento.", 'warning', 'icon-bulb-63', 2)
    }
}

export async function lancarRecebimentosPagamentosCompletos(lancamento: any) {
    //@ts-ignore
    const { notificacao, recebimentoPagamento: { informacoesRecebimentosPagamentos, informacoes } } = store.getState()
    if (lancamento.descricaoForma === undefined) {
        return alertar(notificacao, 'br', "Porfavor selecione uma Forma de Pagamento!!.", 'warning', 'icon-bulb-63', 2)
    }
    if (lancamento.codigoConta === 0) {
        return alertar(notificacao, 'br', "Porfavor selecione uma Conta!!.", 'warning', 'icon-bulb-63', 2)
    }
    if (lancamento.valorLancar === '' || Number(removeMaskMoney(lancamento.valorLancar)) === 0) {
        return alertar(notificacao, 'br', "Porfavor informe um valor para fazer um Lançamento!!.", 'warning', 'icon-bulb-63', 2)
    }

    let objLancamento = {
        codigoLancamento: 0,
        codigoForma: lancamento.codigoForma,
        codigoCaixa: informacoes.codCaixa,
        imagemDocumento: '',
        numeroLancamento: informacoesRecebimentosPagamentos.lancamentos.length + 1,
        codigoConta: lancamento.codigoConta,
        formaPagamento: lancamento.descricaoForma,
        dataLancamento: lancamento.dataLancamento,
        valorTarifa: 0,
        valorLancamento: Number(removeMaskMoney(lancamento.valorLancar)),
        jurosLancamento: lancamento.valorReais.valorJurosReais,
        multaLancamento: lancamento.valorReais.valorMultaReais,
        descontoLancamento: Number(removeMaskMoney(lancamento.valorDesconto)),
        totalPagoLancamento: (Number(removeMaskMoney(lancamento.valorLancar)) + Number(lancamento.valorReais.valorJurosReais) + Number(lancamento.valorReais.valorMultaReais)) - Number(removeMaskMoney(lancamento.valorDesconto))
    }

    await informacoesRecebimentosPagamentos.lancamentos.push(objLancamento)
    //@ts-ignore
    let arrFormEqual = informacoesRecebimentosPagamentos.lancamentos.filter((l: any[]) => (l.formaPagamento === lancamento.descricaoForma && l.codigoLancamento === 0) && l)
    if (arrFormEqual.length > 1) {
        let sumValorLancamento = arrFormEqual.map((l: any) => l.valorLancamento)
        sumValorLancamento = await sumValorLancamento.reduce((itemValue: any, value: any) => {
            return Number(itemValue) + Number(value)
        }, 0)
        let sumValorMulta = arrFormEqual.map((l: any) => l.multaLancamento)
        sumValorMulta = await sumValorMulta.reduce((itemValue: any, value: any) => {
            return Number(itemValue) + Number(value)
        }, 0)
        let sumValorJuros = arrFormEqual.map((l: any) => l.jurosLancamento)
        sumValorJuros = await sumValorJuros.reduce((itemValue: any, value: any) => {
            return Number(itemValue) + Number(value)
        }, 0)
        let sumValorDescontos = arrFormEqual.map((l: any) => l.descontoLancamento)
        sumValorDescontos = await sumValorDescontos.reduce((itemValue: any, value: any) => {
            return Number(itemValue) + Number(value)
        }, 0)
        let sumTotalPago = (Number(sumValorLancamento) + Number(sumValorJuros) + Number(sumValorMulta)) - Number(sumValorDescontos)
        arrFormEqual.splice(arrFormEqual.length - 1, 1)
        informacoesRecebimentosPagamentos.lancamentos.splice(informacoesRecebimentosPagamentos.lancamentos.length - 1, 1)
        //@ts-ignore
        let newArr = await informacoesRecebimentosPagamentos.lancamentos.map((l: any[]) => l.formaPagamento === lancamento.descricaoForma && l.codigoLancamento === 0 ? { ...l, valorLancamento: sumValorLancamento, multaLancamento: sumValorMulta, jurosLancamento: sumValorJuros, descontoLancamento: sumValorDescontos, totalPagoLancamento: sumTotalPago } : l)
        return action.setInformacoesRecebimentosPagamentos({ ...informacoesRecebimentosPagamentos, lancamentos: newArr })
    }

    let newArr = [...informacoesRecebimentosPagamentos.lancamentos]
    await action.setInformacoesRecebimentosPagamentos({ ...informacoesRecebimentosPagamentos, lancamentos: newArr })
}

export async function salvarRecibimentosPagamentos() {
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
    //@ts-ignore
    const { notificacao, recebimentoPagamento: { informacoesRecebimentosPagamentos } } = store.getState()
    try {
        let newArr: any[] = informacoesRecebimentosPagamentos.lancamentos.filter((l: any) => l.codigoLancamento === 0 && l)
        let titulosLancamentos: any[] = newArr.map((i: any) => ({
            codigoCaixa: i.codigoCaixa,
            codigoConta: i.codigoConta,
            codigoForma: i.codigoForma,
            dataLancamento: i.dataLancamento,
            imagemDocumento: i.imagemDocumento,
            numeroLancamento: i.numeroLancamento,
            valorDesconto: i.descontoLancamento,
            valorJuros: i.jurosLancamento,
            valorLancamento: i.valorLancamento,
            valorMulta: i.multaLancamento,
            valorTarifa: i.valorTarifa,
            valorTotal: i.totalPagoLancamento
        }))
        const lancamentos = {
            codigoTituloMovimento: informacoesRecebimentosPagamentos.codigoTituloMovimento,
            titulosLancamentos,
        }
        if (lancamentos.titulosLancamentos.length === 0) {
            return alertar(notificacao, 'br', "Não foi encontrado nenhum novo lançamento.", 'warning', 'icon-bulb-63', 2)
        }
        await axios.post(api.urlFinanceiro + 'api/v1/tituloLancamentos/lancamentos', lancamentos, {
            headers: { Authorization: bearerAuth }
        }).then(async response => {
            await axios.get(api.urlFinanceiro + `api/v1/titulos/buscaTitulos/${lancamentos.codigoTituloMovimento}`, {
                headers: { Authorization: bearerAuth }
            }).then(async response => {
                const { lancamentos } = response.data
                action.setInformacoesRecebimentosPagamentos({ ...informacoesRecebimentosPagamentos, lancamentos })
            })
            return alertar(notificacao, 'br', "Lançamento realizado com sucesso!.", 'success', 'icon-bulb-63', 2)
        })
    } catch (e) {
        return alertar(notificacao, 'br', "Não foi possivel realizar o Lançamento.", 'warning', 'icon-bulb-63', 2)
    }
}

export async function excluirRecebimentos(index?: any, codigoLancamento?: any) {
    //@ts-ignore
    const { notificacao, recebimentoPagamento: { informacoesRecebimentosPagamentos } } = store.getState()
    try {
        if (codigoLancamento) {
            // Chamar endpoint
        }

        let listString = JSON.stringify(informacoesRecebimentosPagamentos.lancamentos)
        let list: any[] = JSON.parse(listString)

        list.splice(index, 1)
        list = list.map((l: any, index) => l && { ...l, numeroLancamento: index + 1 })

        action.setInformacoesRecebimentosPagamentos({ ...informacoesRecebimentosPagamentos, lancamentos: list })
        return alertar(notificacao, 'br', "Lançamento excluido com sucesso!", 'success', 'icon-bulb-63', 2)
    } catch (e) {
        return alertar(notificacao, 'br', "Não foi possivel excluir o Lançamento.", 'warning', 'icon-bulb-63', 2)
    }
}

export async function enviarRecibimentos() {
    const { pedidoVenda: { informacaoEnvioPedido } } = store.getState()

    const fone = informacaoEnvioPedido.whatsapp.telefone
    const message = encodeURIComponent(`Seu pdf do pedido está disponível: `)

    if (fone !== '') {
        window.open(`https://wa.me/${fone}?text=${message}`)
        actionPDV.cleanInformacaoEnvioPedido()
    }

}

export async function getGrupos() {
    //@ts-ignore
    const { notificacao } = store.getState()
    const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
    await axios.get(api.urlCadastro + `api/v1/grupoConta/grupoContas`, {
        headers: { Authorization: basicAuth }
    }).then(response => {
        action.setGrupos(response.data.records)
    }).catch(e => {
        return alertar(notificacao, 'br', "Não foi encontrado nenhum Grupo Contas.", 'warning', 'icon-bulb-63', 2)
    })
}

export async function getSubGrupos(codGrupoConta: number) {
    //@ts-ignore
    const { notificacao } = store.getState()
    const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
    await axios.get(api.urlCadastro + `api/v1/subGrupoConta/subGrupoContas?codGrupoConta=${codGrupoConta}`, {
        headers: { Authorization: basicAuth }
    }).then(response => {
        action.setSubGrupos(response.data.records)
    }).catch(e => {
        return alertar(notificacao, 'br', "Não foi encontrado nenhum Sub Grupo Contas.", 'warning', 'icon-bulb-63', 2)
    })
}