import { IFechamentoCaixaState, FechamentoCaixaStore, FechamentoCaixaTypes, FechamentoCaixaAction } from "./types";

var INITIAL_STATE: IFechamentoCaixaState = {
    pesquisa: {
        codigoFuncionario: 0,
        nomeFuncionario: '',
        dataInicio: '',
        dataFim: '',
        tipo: null
    },
    fechamento: {
        codCaixa: 0,
        codigoFuncionario: 0,
        nomeFuncionario: '',
        dataAbertura: new Date().toISOString().split('T')[0],
        dataFechamento: '',
        valorTotalLancado: '00,00',
        status: ''
    },
    pagamento: {
        name: '',
        valorTotal: 0,
        tipo: [],
        open: false
    },
    detalhamento: [],
    funcionarios: [],
    caixas: [],
    screen: 0,
    block: false
};

export default function caixasStore(state = INITIAL_STATE, action: FechamentoCaixaAction) {
    switch (action.idStore) {
        case FechamentoCaixaStore.ID_STORE:
            switch (action.type) {

                case FechamentoCaixaTypes.CLEAN_ALL:
                    return INITIAL_STATE

                case FechamentoCaixaTypes.CLEAN_PESQUISA:
                    return { ...state, pesquisa: INITIAL_STATE.pesquisa, caixas: INITIAL_STATE.caixas }


                case FechamentoCaixaTypes.PESQUISA:
                    if (action.pesquisa)
                        return { ...state, pesquisa: action.pesquisa }
                    else
                        return state

                case FechamentoCaixaTypes.FUNCIONARIOS:
                    if (action.funcionarios)
                        return { ...state, funcionarios: action.funcionarios }
                    else
                        return state


                case FechamentoCaixaTypes.PAGAMENTO:
                    if (action.pagamento)
                        return { ...state, pagamento: action.pagamento }
                    else
                        return state
                case FechamentoCaixaTypes.DETALHAMENTO:
                    if (action.detalhamento)
                        return { ...state, detalhamento: action.detalhamento }
                    else
                        return state

                case FechamentoCaixaTypes.FECHAMENTO:
                    if (action.fechamento)
                        return {
                            ...state,
                            fechamento: action.fechamento

                        }
                    else
                        return state

                case FechamentoCaixaTypes.CAIXAS:
                    if (action.caixas)
                        return { ...state, caixas: action.caixas }
                    else
                        return state


                case FechamentoCaixaTypes.SCREEN:
                    if (action.value !== null || action.value !== undefined)
                        return { ...state, screen: action.value }
                    else
                        return state

                case FechamentoCaixaTypes.BLOCK:
                    if (action.value !== null || action.value !== undefined)
                        return { ...state, block: action.value }
                    else
                        return state

                default:
                    return state
            }
        default:
            return state
    }
}
