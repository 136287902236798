import React from "react";
import { useSelector, useDispatch } from 'react-redux'

import { Select } from "~/components/ErnComponets/ErnComponets";
import { InputNumber } from "~/components/ErnComponets/ErnComponets";
import { ActionsStep } from "../Cadastro"

import {
    Row,
    Col,
    CardBody,
    CardFooter,
} from "reactstrap";

import  { maskClassificacoes } from '../../../../Util/mascaras'

const idStore = "CLASSIFICACOES_TRIBUTARIAS";

const PISCOFINS = () => {
    const dispatch = useDispatch();
    const { tributacao, verCadastro } = useSelector(state => state.classificacoesTributarias);
    const { PISCOFINSs } = useSelector(state => state.classificacoesTributarias);

    function handleOnChange(value, campo) {
        dispatch({ idStore, type: 'TRIBUTACAO', campo, value });
    }

    return (
        <Row className="justify-content-center" style={{ marginTop: '-70px' }}>
                  <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

                  </div>
            <Col>
                <CardBody>

                    <Row className="justify-content-center">

                        <Col>
                            <h5>CST</h5>
                            <Select
                                first="Selecione"
                                value={{ value: tributacao.codPISCOFINS }}
                                select={{ value: "codPisCofins", label: "descricaoFormatada" }}
                                onChange={value => handleOnChange(value.value, 'codPISCOFINS')}
                                options={PISCOFINSs}
                                isSearchable={true}
                                autoComplete="off"
                            />
                        </Col>
                    </Row>

                    <Row className="justify-content-center">

                        <Col lg>
                            <div className="box box-padding-bottom">
                                <h5>PIS</h5>
                                <label>Percentual PIS %</label>
                                <InputNumber
                                    style={{ width: '200px' }}
                                    type="text"
                                    format="5,2"
                                    value={tributacao.percPIS}
                                    onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'percPIS')}
                                />
                                <br />
                                <h5>PIS Substituição</h5>
                                <label>Percentual PIS Substituição %</label>
                                <InputNumber
                                    style={{ width: '200px' }}
                                    type="text"
                                    format="5,2"
                                    value={tributacao.percPISST}
                                    onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'percPISST')}
                                />
                            </div>
                        </Col>
                        <Col lg>
                            <div className="box box-padding-bottom">
                                <h5>COFINS</h5>
                                <label>Percentual COFINS %</label>
                                <InputNumber
                                    style={{ width: '200px' }}
                                    type="text"
                                    format="5,2"
                                    value={tributacao.percCOFINS}
                                    onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'percCOFINS')}
                                />
                                <br />
                                <h5>COFINS Substituição</h5>
                                <label>Percentual COFINS Substituição %</label>
                                <InputNumber
                                    style={{ width: '200px' }}
                                    type="text"
                                    format="5,2"
                                    value={tributacao.percCOFINSST}
                                    onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'percCOFINSST')}
                                />
                            </div>
                        </Col>


                    </Row>

                </CardBody>
                <CardFooter className="text-center">
                    <ActionsStep />
                </CardFooter>
            </Col>
        </Row>
    )
};

class Wizard extends React.Component {
    focusInput = () => {
    }

    render() {
        return (
            <PISCOFINS />
        )
    }
}

export default Wizard;
