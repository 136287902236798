import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import { maskNCM } from '~/Util/mascaras';

const idStore = 'NCMS';

export function Pesquisar(notificacao, dispatch, codEmpresa, pesquisa) {
    try {   

        dispatch({ idStore, type: 'NCMS', value: [] })
        
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/ncm/ncms?codEmpresa=${codEmpresa}&ncm=${pesquisa.ncm.replace(/\./g, '')}&descricao=${pesquisa.descricao}&dataDescontinuado=${pesquisa.dataDescontinuado}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum NCM encontrado", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'NCMS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os NCMs", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetNCM(notificacao, dispatch, codEmpresa, ncm, thenFunction) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/ncm/ncm?codEmpresa=${codEmpresa}&ncm=${ncm.replace(/\./g, '')}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            let type = 'NCM';
            dispatch({ idStore, type, campo: 'ncm', value: maskNCM(response.data.records.ncm) })
            dispatch({ idStore, type, campo: 'descricao', value: response.data.records.descricao })
            dispatch({ idStore, type, campo: 'dataDescontinuado', value: response.data.records.dataDescontinuado })
            dispatch({ idStore, type, campo: 'update', value: true })
            dispatch({ idStore, type: 'NCM_COPIA' })
            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export async function CreateNCM(notificacao, dispatch, codEmpresa, ncm) {
    try {
        //Retorna se houve erro ou nao
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;
        if (ncm.ncm === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'ncm', value: true })
            invalido = true
        }
        if (ncm.ncm.replace(/\./g, '').length < 8) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'ncm', value: true })
            alertar(notificacao, 'br', "O NCM precisa ter 8 dígitos", 'info', 'icon-bulb-63', 2);
            return true
        }
        if (ncm.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/ncm/createNcm?codEmpresa=${codEmpresa}`,
            headers: { Authorization: basicAuth },
            data: {
                NCM: ncm.ncm,
                Descricao: ncm.descricao,
                DataDescontinuado: ncm.dataDescontinuado
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'NCM', campo: 'update', value: true })
            dispatch({ idStore, type: 'NCM_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function UpdateNCM(notificacao, dispatch, codEmpresa, ncm, ncmCopia) {
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(ncm) === JSON.stringify(ncmCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        if (ncm.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            return true
        }

        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/ncm/updateNcm?codEmpresa=${codEmpresa}&ncm=${ncm.ncm.replace(/\./g, '')}`,
            headers: { Authorization: basicAuth },
            data: {
                Descricao: ncm.descricao,
                DataDescontinuado: ncm.dataDescontinuado,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'NCM_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export function Excluir(notificacao, dispatch, codEmpresa, ncm, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/ncm/excluirNcm?codEmpresa=${codEmpresa}&ncm=${ncm.ncm.replace(/\./g, '')}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar(notificacao, dispatch, codEmpresa, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}