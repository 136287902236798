import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Label } from 'reactstrap';
import ReactTable from "react-table";

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from '../../../../components/ModalBasic/ModalBasic.jsx';

import useScrollBar from '../../../../Util/Hooks/useScrollBar';

import { HelpButton } from '../../../../components/HelpPlugin/HelpPlugin'

import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector';
import AppState from '../../../../store/storeType.js';

import * as action from '../../../../store/reducers/Movimentacao/SaidaDiversa/actions'
import { ToNumber } from '../../../../Util/numbers';


import ArrowTooltip from '../../../../components/Tooltip/ArrowTooltip';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import history from "../../../../Util/history"
import { Info } from '../Components';
import { Produto } from "../../../../store/reducers/Movimentacao/SaidaDiversa/types"

type Toggle = (newState?: boolean, callback?: (state?: boolean) => void) => void

interface Props {
    isOpen: boolean
    toggle: Toggle
}

export default function ModalProdutoGrades({ isOpen, toggle }: Props) {
    const listProdutos = useShallowEqualSelector<AppState, any[]>(state => state.saidaDiversa.saida.produtos)
    const gradeSelecionada = useShallowEqualSelector<AppState, any>(state => state.saidaDiversa.gradeSelecionada)

    const [invalido, setInvalido] = useState(false);

    const msgInvalido = "Verifique a quantidade a atualizar"

    const tableRef = useScrollBar()

    useEffect(() => {
        gradeSelecionada.produto.grades.forEach((element: any) => {
            element.qtdAtualizar === "" ? element.qtdAtualizar = "0" : element.qtdAtualizar === undefined ? element.qtdAtualizar = "0" : null
        })
    }, [])

    function closeModal() {
        toggle()
    }

    function onChangeEstoque(value: string, index: number) {
        gradeSelecionada.produto.grades[index].qtdAtualizar = value
        var gradeStr = JSON.stringify(gradeSelecionada)
        var gradeArr = JSON.parse(gradeStr)

        action.setGradeSelecionada({ ...gradeArr })
    }

    function validar() {
        var soma = 0
        gradeSelecionada.produto.grades.forEach((element: any) => {
            soma = soma + ToNumber(element.qtdAtualizar)
        })

        if (ToNumber(gradeSelecionada.produto.quantidade) === soma) {
            return true
        } else {
            setInvalido(true)
        }
    }

    function concluir() {
        const newProduto: Produto = {
            numeroPedidoItem: 0,
            item: 0,
            codProduto: 0,
            descricao: '',
            numero: '',
            quantidade: '',
            valorTotal: '',
            valorUnitario: '',
            dataLancamento: '',
            status: 0,
            flControlaGrade: 0,
            grades: [],
        }

        const valido = validar()
        if (valido) {
            var listStr = JSON.stringify(listProdutos)
            var listArr = JSON.parse(listStr)

            if (gradeSelecionada.produto.item === 0) {
                action.addProduto([...listArr, { ...gradeSelecionada.produto, item: listArr.length + 1, dataLancamento: new Date().toISOString() }])

            } else {
                let index = listProdutos.findIndex(p => p.item === gradeSelecionada.produto.item)

                if (index < 0) {
                    action.addProduto([...listArr, { ...gradeSelecionada.produto, item: listArr.length + 1, dataLancamento: new Date().toISOString() }])

                } else {
                    listArr[index] = gradeSelecionada.produto
                    action.addProduto(listArr)
                }
            }

            action.setProduto(newProduto);
            action.setModalProdutoGrades(false)
        }
    }

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => closeModal()}
            title={'GRADE DE PRODUTOS'}
            size="lg"
        >
            <ModalBasicBody>
                <h4 style={{ textAlign: 'center' }}>Informe a quantidade para cada item da grade</h4>

                <Row style={{ justifyContent: 'flex-end', paddingRight: 20, paddingBottom: 10 }}>
                    <HelpButton id={19} />
                </Row>
                <Row>
                    <Col>
                        <fieldset className="fieldset-operacao">
                            <div
                                ref={tableRef}
                            >
                                {gradeSelecionada.produto.grades.length === 0 ?
                                    <div>
                                        <h4 className="mt-4 mb-4" style={{ textAlign: 'center' }}>Este produto não possui nenhuma grade vinculada a ele. Para vincular uma grade,
                                        acesse a tela de cadastro de produtos clicando <b style={{ cursor: 'pointer', color: '#1d8cf8' }} onClick={() => history.push('/produtos')}>aqui</b></h4>
                                    </div>
                                    :
                                    <ReactTable
                                        data={gradeSelecionada.produto.grades}
                                        columns={[
                                            {
                                                Header: 'Descrição',
                                                accessor: 'descricao',
                                                resizable: false,
                                                sortable: false
                                            },
                                            {
                                                Header: 'Sigla',
                                                accessor: 'sigla',
                                                resizable: false,
                                                sortable: false
                                            },
                                            {
                                                Header: 'Quantidade em Estoque',
                                                accessor: 'qtdEstoqueGrade',
                                                resizable: false,
                                                sortable: false
                                            },
                                            {
                                                Header: 'Quantidade a atualizar',
                                                resizable: false,
                                                sortable: false,
                                                Cell: (prop) =>
                                                    <>
                                                        <input
                                                            type="text"
                                                            className={prop.original.invalido ? 'inputRedBorder' : 'form-control'}
                                                            style={{ textAlign: 'center' }}
                                                            value={prop.original.qtdAtualizar}
                                                            maxLength={10}
                                                            onChange={(element) => { onChangeEstoque(element.currentTarget.value, prop.index) }}
                                                            onFocus={(element) => { element.currentTarget.select() }}
                                                        />
                                                        {
                                                            prop.original.invalido &&
                                                            <ArrowTooltip title="O valor de Estoque Atualizar foi ultrapassado">
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <Label style={{ fontSize: 12, margin: 0 }}>Valor Inválido
                                                                    <span className="error" style={{ fontSize: 14 }}>*</span>
                                                                    </Label>
                                                                </div>
                                                            </ArrowTooltip>
                                                        }
                                                    </>
                                            },
                                        ]}
                                        showPaginationTop={false}
                                        showPaginationBottom={false}
                                        className="-striped -highlight"
                                        noDataText=""
                                        minRows={gradeSelecionada.produto.grades.length}
                                    />
                                }

                            </div>
                        </fieldset>

                        <Col className="text-right">
                            <Info>
                                {`Quantidade a atualizar: ${gradeSelecionada.produto.quantidade}`}
                            </Info>
                        </Col>

                        <br />

                        <div style={invalido ? { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
                            <Row style={{ justifyContent: 'flex-end', paddingRight: 15 }}>
                                <span style={{ fontSize: 14, color: '#FFF', backgroundColor: '#FFB600', borderRadius: 5, paddingTop: 2.5, paddingBottom: 2.5, paddingLeft: 10, paddingRight: 10 }}>
                                    <Icon icon="exclamation-triangle" className="fa-lg" />
                                    {"  "}
                                    {msgInvalido}
                                </span>
                            </Row>
                        </div>
                    </Col>

                </Row>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        onClick={() => concluir()}
                        color={"info"}
                    >Salvar
                    </Button>
                </Col>
            </ModalBasicFooter>

        </ModalBasic>
    )
}