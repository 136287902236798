import React from "react";

import {Col,Input,Row,Button,} from "reactstrap";


import useShallowEqualSelector from "../../../../Util/Hooks/useShallowEqualSelector";
import AppState from "../../../../store/storeType";
import { IFechamentoCaixaState } from "../../../../store/reducers/Cadastro/FechamentoCaixa/types";
import ListPagamento from '../Lists/ListPagamentos'
import ListDetalhamento from '../Lists/ListDetalhamento'
import * as controllerFechamento from '../../../../controller/controllerFechamentoCaixas'


const Pesquisar: React.FC = function () {
    return (
        <Col lg="12">
            <Headers />
            <Row>
                <Col>
                    <fieldset className="pedido-venda-fieldList" style={{ padding: 8 }}>
                        <legend>Forma de Pagamento</legend>
                        <ListPagamento/>
                    </fieldset>
                </Col>
                <Col>
                    <fieldset className="pedido-venda-fieldList" style={{ padding: 8 }}>
                        <legend>Detalhamento de Caixa</legend>
                        <ListDetalhamento/>
                    </fieldset></Col>
            </Row>
            <br />
            <Footer />
        </Col>
    );
}
function Headers() {
    const fechamento = useShallowEqualSelector<AppState, IFechamentoCaixaState['fechamento']>(state => state.fechamentoCaixa.fechamento);
    return (
        <>
            <Row className="padding-padrao-row">
                <Col className="padding-padrao-col" lg={6}>
                    <label>Funcionário</label>
                    <Input
                        type="text"
                        value={fechamento.nomeFuncionario}
                        label={fechamento.nomeFuncionario}
                        disabled={true}
                    />
                </Col>

                <Col className="padding-padrao-col" lg={3}>
                    <label>Data Abertura</label>
                    <Input
                        type="date"
                        value={fechamento.dataAbertura}
                        disabled={true}
                    />
                </Col>
                <Col className="padding-padrao-col" lg={3}>
                    <label>Data Fechamento</label>
                    <Input
                        type="date"
                        value={fechamento.dataFechamento}
                        disabled={true}
                    />
                </Col>
            </Row>
        </>
    );
}
const Footer: React.FC = () => {
    const fechamento = useShallowEqualSelector<AppState, IFechamentoCaixaState['fechamento']>(state => state.fechamentoCaixa.fechamento)
    return (
        
            <Row>
                    <Col>
                        <Button
                            color='info'
                            style={{position:'relative', float:'right'}}
                            onClick={() => {controllerFechamento.fecharCaixa(fechamento)}}
                             disabled={fechamento.status === "Fechado" ? true : false}
                        >
                            FECHAR CAIXA
                </Button>
                    </Col>
            </Row>
        
    )
}

export default Pesquisar;