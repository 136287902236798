import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerGrades from "~/controller/controllerGrade";
import { Select, Switch } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';

import {
  Col,
  Button,
  CardBody,
  CardFooter,
  Input,
  Row,
  Modal,
  ModalBody,
  FormGroup,
} from "reactstrap";

const idStore = 'GRADES';

const Grade = () => {
  const notificacao = useSelector(state => state.notificacao);
  const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
  const { grade, tipoGrade, gradeCopia, invalido, pesquisa,verCadastro } = useSelector(state => state.grades);
  const [modalInativar, setModalInativar] = useState(false);
  const [gradeSelected, setgradeSelected] = useState({ status: 0 });
  const dispatch = useDispatch();

  function onChange(value, stateName) {
    dispatch({ idStore, type: 'GRADE', campo: stateName, value: value });
    dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
  };

  function handleInativar(obj) {
    setgradeSelected(obj);
    toggleModalInativar()
  }

  function toggleModalInativar() {
    setModalInativar(!modalInativar);
  }

  function inativar() {
    if (grade.codGrade !== '') {
      controllerGrades.inativarGrade(notificacao, dispatch, codEmpresa, gradeSelected);
    }
    dispatch({ idStore, type: 'GRADE', campo: 'status', value: grade.status === 1 ? 0 : 1 });
  }

  async function Salvar(e) {
    e.preventDefault();
    if (grade.codGrade === '') {
      controllerGrades.CreateGrade(notificacao, dispatch, codEmpresa, grade);
    } else {
      controllerGrades.UpdateGrade(notificacao, dispatch, codEmpresa, grade, gradeCopia);
    }
    controllerGrades.Pesquisar(notificacao, dispatch, codEmpresa, pesquisa);
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_GRADE' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('cadDescricao').focus()
  };

  return (
    <>
      <Row className="justify-content-center">
      <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

      </div>
        <Col>
          <h4 className="info-text">Preencha com as informações da grade</h4>
          <CardBody>
            <Row className="justify-content-center">
              <Col md="10" className="text-right">
                <Switch
                  componentleft={<label className={grade.status === 0 ? `text-success text-center` : "text-warning text-center"}>{grade.status === 0 ? "Ativo" : "Inativo"}</label>}
                  checked={grade.status === 0}
                  onChange={() => handleInativar(grade)}
                  disabled={grade.codGrade === "" || verCadastro}
                />
              </Col>

              <Col md="10">
                <Row>
                  <Col className="padding-padrao-col" lg="3" md sm="7">
                    <label>Tipo da Grade <span className="error">*</span></label>
                    <Select
                      first={'Todos'}
                      value={{ value: grade.codTipoGrade }}
                      select={{ value: "codigo", label: "descricao" }}
                      onChange={value => onChange(value.value, 'codTipoGrade')}
                      options={tipoGrade}
                      
                    />
                  </Col >

                  <Col className="padding-padrao-col" lg md sm="7">
                    <label>Descrição <span className="error">*</span></label>
                    <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                      <Input
                        id='cadDescricao'
                        type="text"
                        value={grade.descricao}
                        onChange={e => onChange(e.target.value, 'descricao')}
                        autoComplete="off"
                        maxLength="70"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="padding-padrao-col" lg="3" md sm="7">
                    <label>Sigla <span className="error">*</span></label>
                    <FormGroup className={invalido.sigla ? "has-danger" : ''}>
                      <Input
                        id='cadSigla'
                        type="text"
                        value={grade.sigla}
                        onChange={e => onChange(e.target.value, 'sigla')}
                        autoComplete="off"
                        maxLength="10"
                    
                      />
                    </FormGroup>
                  </Col>

                </Row>
              </Col>
            </Row>
          </CardBody>

          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                  <ArrowTooltip title='Shift+N'>
                    <Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Novo</Button>
                 </ArrowTooltip>
                  <ArrowTooltip title='Shift+S'>
                    <Button className="btn-padrao" color="info" onClick={(e) => { Salvar(e); }}>Salvar</Button>
                 </ArrowTooltip>
                </Col>
              </Col>
            </Row>
          </CardFooter>

        </Col>
      </Row>

      <Modal
        isOpen={modalInativar}
        toggle={toggleModalInativar}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalInativar}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">{gradeSelected.status === 0 ? "Inativar" : "Ativar"} grade</h6>
        </div>
        <ModalBody>

          <Col className="text-center">
            <label>
              {gradeSelected.status === 0 ?
                <>Tem certeza que deseja <b>inativar</b> essa grade da sua empresa?</>
                : <>Tem certeza que deseja <b>ativar</b> essa grade em sua empresa?</>
              }
            </label>
          </Col>

        </ModalBody>
        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => { toggleModalInativar() }}
                style={{ width: '150px' }}
              >NÃO
                                                        </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => { toggleModalInativar(); inativar() }}
                style={{ width: '150px' }}
              >SIM
                                                        </Button>
            </Col>
          </Row>
        </CardBody>

      </Modal>
    </>
  )
}

class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('cadDescricao').focus()
    }, 100)
  }

  render() {
    return (
      <Grade />
    )
  }
}

export default Wizard;