import store from "../../../stores";
import { PedidoVendaTypes, PedidoVendaStore, PedidoVendaAction, Cliente, Operacao, Item, Totais, Informacoes, SubGrupoProdutos, Produto, miniCardSelected, Vendedor, IPesquisa, IPedido, ITotaisPesquisa, ITotaisDetalhesPesquisa, IItensGradePDV, IPagination, IDetalhesPedidoItens, IDetalhesPedidoPagamento, ISegurancaPedidoCredenciais, IDetalhesFormaPagamento, IRetiradasCaixa } from "./types";


export const setCliente = (cliente: Cliente) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.CLIENTE,
    cliente,
})
export const setOperacao = (operacao: Operacao) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.OPERACAO,
    operacao,
})

export const setItens = (itens: Item[]) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.ITEMS,
    itens,
})
export const setClientes = (clientes: Cliente[]) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.CLIENTES,
    clientes,
})
export const setOperacoes = (operacoes: Operacao[]) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.OPERACOES,
    operacoes,
})
export const setVendedores = (vendedores: Vendedor[]) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.VENDEDORES,
    vendedores,
})
export const addItem = (item: Item) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.ADD_ITEM,
    item,
})

export const setTotais = (totais: Totais) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.TOTAIS,
    totais,
})

export const setInformacoes = (campo: string, value: any) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.INFORMACOES,
    campo,
    value
})

export const cleanPedido = () => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.CLEAN,
})
export const cleanClientes = () => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.CLEAN_CLIENTES,
})
export const cleanCliente = () => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.CLEAN_CLIENTE,
})


export const setProduto = (produto: Produto) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.PRODUTO,
    produto,
})

export const setProdutos = (produtos: Produto[]) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.PRODUTOS,
    produtos,
})

export const setMiniCardSelected = (miniCardSelected: miniCardSelected) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.MINI_CARD_SELECTED,
    miniCardSelected,
})

export const setSubGrupoProdutos = (subGrupoProdutos: SubGrupoProdutos[]) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.SUB_GRUPO_PRODUTOS,
    subGrupoProdutos,
})

export const setPedidos = (pedidos: IPedido[]) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.PEDIDOS,
    pedidos
})

export const setPedido = (pedido: IPedido) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.PEDIDO,
    pedido
})

export const setPesquisa = (pesquisa: IPesquisa) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.PESQUISA,
    pesquisa,
})

export const setPagination = (pagination: IPagination) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.PAGINATION,
    pagination,
})

export const setTotaisPesquisa = (totaisPesquisa: ITotaisPesquisa) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.TOTAIS_PESQUISA,
    totaisPesquisa
})

export const cleanPesquisa = () => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.CLEAN_PESQUISA,
})

export const setTotaisDetalhesPesquisa = (totaisDetalhesPesquisa: ITotaisDetalhesPesquisa) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.TOTAIS_DETALHES_PESQUISA,
    totaisDetalhesPesquisa
})

export const setItensGradePDV = (itensGrade: IItensGradePDV) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.ITENS_GRADE_PDV,
    itensGrade
})

export const setDetalhesPedidoItens = (detalhesPedidoItens: IDetalhesPedidoItens[]) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.DETALHES_PEDIDO_ITENS,
    detalhesPedidoItens
})

export const setDetalhesPedidoPagemento = (detalhesPedidoPagamento: IDetalhesPedidoPagamento[]) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.DETALHES_PEDIDO_PAGAMENTO,
    detalhesPedidoPagamento
})

export const setSegurancaPedidoCredenciais = (segurancaPedidoCredenciais: ISegurancaPedidoCredenciais) => store.dispatch({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.SEGURANCA_PEDIDO_CREDENCIAIS,
    segurancaPedidoCredenciais
})

export const cleanSegurancaPedidoCredenciais = () => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.CLEAN_SEGURANCA_PEDIDO_CREDENCIAIS,
})

export const setInformacaoEnvioPedido = (campo: string, value: any) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.INFORMACAO_ENVIO_PEDIDO,
    campo,
    value
})

export const cleanInformacaoEnvioPedido = () => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.CLEAN_INFORMACAO_ENVIO_PEDIDO,
})

export const setDetalhesFormaPagamento = (detalhesFormaPagamento: IDetalhesFormaPagamento[]) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.DETALHES_FORMA_PAGAMENTO,
    detalhesFormaPagamento
})

export const cleanDetalhesPedido = () => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.CLEAN_DETALHES_PEDIDO,
})

export const setRetiradasCaixa = (retiradasCaixa: IRetiradasCaixa[]) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.RETIRADAS_CAIXA,
    retiradasCaixa
})

export const addRetiradasCaixa = (retiradaCaixa: IRetiradasCaixa) => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.ADD_RETIRADA_CAIXA,
    retiradaCaixa
})

export const cleanRetiradaCaixa = () => store.dispatch<PedidoVendaAction>({
    idStore: PedidoVendaStore.ID_STORE,
    type: PedidoVendaTypes.CLEAN_RETIRADA_CAIXA,
})