import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { Button } from "reactstrap";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import {GetGrade} from '../../../../../controller/Movimentacao/controllerEntradaMercadoria'
import ArrowTooltip from '../../../../../components/Tooltip/ArrowTooltip';
import store from '../../../../../store/stores';
import { Round, Subtract } from '../../../../../Util/numbers';
import {maskMoeda, maskValor} from '../../../../../Util/mascaras'

function DataTable() {
    const produtosSelected = useShallowEqualSelector(state => state.entradaMercadoria.produtosSelected)
    const itensEditar = useShallowEqualSelector(state => state.entradaMercadoria.itensEditar)
    const itensInserir = useShallowEqualSelector(state => state.entradaMercadoria.itensInserir)
    const itensExcluir = useShallowEqualSelector(state => state.entradaMercadoria.itensExcluir)
    const numEntrada = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.dadosIniciais.numEntrada)
    const verCadastro = useShallowEqualSelector(state => state.entradaMercadoria.visualizarEntrada);
    const [listProdutos, setListProdutos] = useState([])

    useEffect(() => {
        setListProdutos(produtosSelected)
    }, [produtosSelected])

    useEffect(() => {
        const dispatch = store.dispatch
        dispatch({
            idStore: 'ENTRADA_MERCADORIA',
            type: 'PRODUTOS_INSERIDOS',
            value: listProdutos
        })
    }, [listProdutos])

    function editarItem(index) {
        const dispatch = store.dispatch
        const produto = listProdutos[index]
        produto.valorUnitario = maskValor(produto.valorUnitario)
        if(numEntrada && produto.controlaGrade !== 0)
         GetGrade(produto)
        dispatch({
            idStore: 'ENTRADA_MERCADORIA',
            type: 'PRODUTO',
            value: produto
        })
        dispatch({
            idStore: 'ENTRADA_MERCADORIA',
            type: 'GRADE_COPIA',
            value: produto
        })
        dispatch({idStore: 'ENTRADA_MERCADORIA', type: 'EDITAR', value: true})
    }
    function excluirItem(index) {
        const dispatch = store.dispatch
        const { valoresTotais } = store.getState().entradaMercadoria.cadastro.itens

        let stringList = JSON.stringify(listProdutos);
        let list = JSON.parse(stringList);

        let valorTotalString = JSON.stringify(valoresTotais);
        let valorTotalJSON = JSON.parse(valorTotalString)

        valorTotalJSON.subTotal = Round(Subtract([valorTotalJSON.subTotal, list[index].valorTotal]), 2)

        dispatch({
            idStore: 'ENTRADA_MERCADORIA',
            type: 'VALORES_TOTAIS',
            value: valorTotalJSON
        })

        itensEditar.forEach((item , i)=>{
            if (item.codProduto === list[index].codProduto){
                itensEditar.splice(i, 1);
            }
        })

        itensInserir.forEach((item , i)=>{
            if (item.codProduto === list[index].codProduto){
                itensInserir.splice(i, 1);
            }
        })

        let excluirList = JSON.stringify(itensExcluir);
        let Excluir = JSON.parse(excluirList);
        let gradeExcluir = []
        if (list[index].gradesSelected)
            list[index].gradesSelected.forEach((list) => {
                gradeExcluir.push({
                    codGrade: list.codGrade,
                    qtdEstoqueGrade: list.qtdEstoqueGrade,
                    flagOperacao: "D"
                })
            })
        list[index].gradesSelected = gradeExcluir
       
        Excluir.push(list[index])
        dispatch({
            idStore: 'ENTRADA_MERCADORIA',
            type: 'PRODUTOS_UPDATE',
            campo: 'itensExcluir',
            value: Excluir
        })


        list.splice(index, 1);

        dispatch({
            idStore: 'ENTRADA_MERCADORIA',
            type: 'PRODUTO_SELECIONADO',
            value: list
        })
        let newProduto = {
            codProdutoItem: '',
            codProduto: '',
            numProduto: '',
            codBarras: '',
            nome: '',
            quantidade: '',
            valorUnitario: '',
            valorTotal: '',
            unidade: '',
            gradesSelected: [],
            editarProduto: false,
        }
    
        dispatch({
            idStore: 'ENTRADA_MERCADORIA',
            type: 'PRODUTO',
            value: newProduto
        })
    }

    return (
        <>

            <div style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' }} >
                <ReactTable
                    data={listProdutos}
                    filterable={false}
                    NoDataComponent={() => (<></>)}
                    columns={
                        [
                            {
                                Header: <div className="text-center">Item</div>,
                                accessor: "numero",
                                width: 100,
                                className: "text-center",
                                Cell: (props) => props.index + 1
                            },
                            {
                                Header: "Cód. Produto",
                                accessor: "codProduto"
                            },
                            {
                                Header: "Cód. Barras",
                                accessor: "codBarras"
                            },
                            {
                                Header: "Descrição",
                                accessor: "nome"
                            },
                            {
                                Header: "Ncm",
                                accessor: "ncm"
                            },
                            {
                                Header: "Cfop",
                                accessor: "cfop"
                            },
                            {
                                Header: "Cst",
                                accessor: "cst"
                            },
                            {
                                Header: "Quantidade",
                                accessor: "quantidade"
                            },
                            {
                                Header: "Valor Unitário",
                                accessor: "valorUnitario"
                            },
                            {
                                Header: "Valor Total",
                                accessor: "valorTotal",
                                Cell: (props) => (maskMoeda(props.original.valorTotal))
                            },
                            {
                                Header: "",
                                accessor: "actions",
                                sortable: false,
                                filterable: false
                            },
                            {
                                sortable: false,
                                filterable: false,
                                width: 120,
                                Cell: (props) => (
                                    <>
                                    <ArrowTooltip title="Editar">
                                            <Button
                                                onClick={() => editarItem(props.index)}
                                                size="sm"
                                                className={"btn-icon btn-link"}
                                                color="success"
                                                style= {verCadastro ? {pointerEvents: "none"} : null}
                                            >
                                                <Icon icon="edit" className="fa-lg" />
                                            </Button>
                                        </ArrowTooltip>
                                        <ArrowTooltip title="Excluir">
                                            <Button
                                                onClick={() => excluirItem(props.index)}
                                                className="btn-icon btn-link"
                                                color="danger"
                                                style= {verCadastro ? {pointerEvents: "none"} : null}
                                            >
                                                <Icon icon="trash-alt" className="fa-lg" />
                                            </Button>
                                        </ArrowTooltip>
                                    </>
                                        
                                )
                            }
                        ]
                    }
                    minRows={1}
                    showPaginationTop={false}
                    className="-striped -highlight"
                    noDataText=""
                    showPaginationBottom={false}
                    showPageSizeOptions={true}
                    pageSize={listProdutos.length}
                />
            </div>

        </>
    )
}

export default DataTable