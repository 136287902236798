import React from 'react'
import {
    CardBody,
    Modal,
    ModalBody,
} from "reactstrap";


export function ModalBasic({ children, isOpen, toggle, title, size = "" , id = ""}) {
    return (
        < Modal
            id={id}
            isOpen={isOpen}
            toggle={toggle}
            size={size ? size : false}
        >

            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggle}
                >
                    <i className="tim-icons icon-simple-remove" />
                </button>
                <h3 className="title title-up">{title}</h3>
            </div>
            {children}
        </Modal >
    )
}

export function ModalBasicBody({ children }) {
    return (
        <ModalBody>
            {children}
        </ModalBody>
    )
}

export function ModalBasicFooter({ children, id = "" }) {
    return (
        <CardBody id={id}>
            {children}
        </CardBody>
    )
}