import React, { FormEvent } from "react";
import { useDispatch } from 'react-redux';
import { maskNumbers, maskString } from "../../../../../Util/mascaras";

import {
    Input,
    Row,
    Col,
    CardBody,
    Button,
    CardFooter,
    FormGroup,
} from "reactstrap";
import { useState } from "react";

import { InputCpfCnpj } from "../../Components/index";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import AppState from "../../../../../store/storeType";
import { NFERefercia, CamposNFeReferencia } from "../../../../../store/reducers/Movimentacao/SaidaDiversa/types";

import useModal from '../../../../../Util/Hooks/useModal'
import useResize from "../../../../../Util/Hooks/useResize";
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";

import ModalSaidas from '../../Modals/ModalSaidas'

const idStore = "SAIDA_DIVERSA";

const DadosReferencia = () => {
    const dispatch = useDispatch();
    const nfeReferencia = useShallowEqualSelector<AppState, NFERefercia>(state => state.saidaDiversa.saida.nfeReferencia);
    const invalido = useShallowEqualSelector<AppState, CamposNFeReferencia>(state => state.saidaDiversa.invalido.nfeReferencia);
    const [modalSaidas, toggleSaidas] = useModal(false);
    const [dadosManuais, setDadosManuais] = useState(true);
    const { width } = useResize()

    function handleOnChange(value: string, campo: string) {
        dispatch({ idStore, type: 'SAIDA_REFERENCIA', campo, value })
    }

    return (
        <>
            <CardBody>
                <Row className="justify-content-center">
                    <fieldset style={{ border: 'solid 2px #5E6680', borderRadius: 5, width: '100%' }}>
                        <legend style={{ fontSize: '14px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
                            DADOS NF-E REFERÊNCIA
                  </legend>

                        <Col md="12">

                            <Row className="justify-content-center">
                                <Col lg={width < 1375 ? "4" : "2"} md="4">
                                    <label>Número da Nota</label>
                                    <FormGroup className={invalido.numero ? "has-danger" : ''}>
                                        <Input
                                            id="numeroReferencia"
                                            type="text"
                                            value={nfeReferencia.numero}
                                            onChange={e => handleOnChange(maskNumbers(e.target.value), 'numero')}

                                        />
                                        <br></br>
                                    </FormGroup>
                                </Col>

                                <Button color="info" onClick={() => toggleSaidas()} style={{ width: 40, height: 40, marginTop: 25 }}>
                                    <Icon icon="search" className="fa-lg" />
                                </Button>

                                <Col lg={width < 1375 ? "3" : "2"} md="3">
                                    <label>Modelo da Nota</label>
                                    <FormGroup className={invalido.modelo ? "has-danger" : ''}>
                                        <Input
                                            type="text"
                                            value={nfeReferencia.modelo}
                                            onChange={e => handleOnChange(maskNumbers(e.target.value), 'modelo')}
                                            disabled={dadosManuais}
                                            maxLength={2}

                                        />
                                        <br></br>
                                    </FormGroup>
                                </Col>

                                <Col lg={width < 1375 ? "4" : "2"} md="4">
                                    <label>Chave da Nota</label>
                                    <FormGroup className={invalido.chave ? "has-danger" : ''}>
                                        <Input
                                            type="text"
                                            value={nfeReferencia.chave}
                                            onChange={e => handleOnChange(e.target.value, 'chave')}
                                            disabled={dadosManuais}

                                        />
                                        <br></br>
                                    </FormGroup>
                                </Col>

                                <Col lg={width < 1375 ? "4" : "2"} md="4">
                                    <label>CNPJ do Emissor</label>
                                    <FormGroup className={invalido.documentoEmissor ? "has-danger" : ''}>
                                        <InputCpfCnpj
                                            type="text"
                                            tipo="cnpj"
                                            value={nfeReferencia.cnpjEmissor}
                                            onChange={(event: FormEvent<HTMLInputElement>) => handleOnChange(event.currentTarget.value, 'cnpjEmissor')}
                                            autoComplete="off"
                                            disabled={dadosManuais}
                                            maxLength="14"

                                        />
                                        <br></br>
                                    </FormGroup>
                                </Col>

                                <Col lg={width < 1375 ? "3" : "2"} md="3">
                                    <label>Mês / Ano</label>
                                    <FormGroup className={invalido.uf ? "has-danger" : ''}>
                                        <Input
                                            type="text"
                                            value={nfeReferencia.anoMes}
                                            onChange={e => handleOnChange(e.target.value, 'anoMes')}
                                            disabled={dadosManuais}

                                        />
                                        <br></br>
                                    </FormGroup>
                                </Col>

                                <Col lg={width < 1375 ? "3" : "1"} md="3">
                                    <label>UF</label>
                                    <FormGroup className={invalido.uf ? "has-danger" : ''}>
                                        <Input
                                            type="text"
                                            value={nfeReferencia.uf}
                                            onChange={e => handleOnChange(maskString(e.target.value), 'uf')}
                                            disabled={dadosManuais}
                                            maxLength={2}
                                        />
                                        <br></br>
                                    </FormGroup>
                                </Col>

                            </Row>

                        </Col>
                    </fieldset>

                </Row>

            </CardBody>

            <CardFooter >
                <Row className="justify-content-center">
                    <Col md="10" >
                        <Col className="text-center">
                            <Button className="btn-padrao" color="info" onClick={() => setDadosManuais(!dadosManuais)}>Quero informar os dados manualmente.</Button>
                        </Col>
                    </Col>
                </Row>
            </CardFooter>

            {
                modalSaidas ? <ModalSaidas isOpen={true} toggle={toggleSaidas} /> : null
            }
        </>
    )
}

export default DadosReferencia;