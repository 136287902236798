import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import ReactTable from "react-table";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import { Input, Info, InputCpfCnpj } from '../Components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useSelector, shallowEqual } from 'react-redux';
import { maskCPF } from '../../../Util/mascaras';
import * as actions from '../../../store/reducers/Movimentacao/Inventario/actions'
import useScrollBar from '../../../Util/Hooks/useScrollBar';

export default function ModalFuncionario({ isOpen, toggle, getFuncionario, confirmModal, clean }) {
    const tableRef = useScrollBar([isOpen])
    const inputCpf = useRef()
    const { modalFuncionarios } = useSelector(state => state.inventario, shallowEqual)
    const funcionarios = useSelector(state => state.inventario.funcionarios, shallowEqual)
    const [pesquisaFuncionario] = useState({ nomeEmail: '', cpf: '' })

    useEffect(() => {
        inputCpf.current.focus()
    }, [])

    function onClickFuncionario(rowIndex) {
        let funcionarioSelected = funcionarios[rowIndex]
        actions.setModalFuncionario({ ...modalFuncionarios, cpf: funcionarioSelected.cpf, nomeEmail: funcionarioSelected.nomeFunc, codUser: funcionarioSelected.codUser })
    }

    function onDoubleClickFuncionario() {
        confirmModal(modalFuncionarios)
    }

    function limparModal() {
        actions.cleanModal()
        clean()
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            getFuncionario(modalFuncionarios)
        }
    }

    function getTrProps(state, row, column) {
        return {
            style: {
                height: '50px',
                paddingTop: '0px',
                paddingBottom: '5px',
                backgroundColor: funcionarios[row.index].cpf === modalFuncionarios.cpf ? 'rgba(71, 179, 255, 0.41)' : ''
            },
            onClick: (e, handleOriginal) => {
                onClickFuncionario(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            },
            onDoubleClick: (e, handleOriginal) => {
                onDoubleClickFuncionario(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            }
        }
    }

    function getTdProps(state, rowInfo, column) {
        if (column.id === 'actions') {
            return { style: { padding: 0 } }
        } else {
            return {}
        }
    }

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title='Pesquisa de Funcionários'
            size="lg"
        >
            <ModalBasicBody>
                <h4 style={{ textAlign: 'center' }}>Informe os dados abaixo para prosseguir</h4>

                <Row>
                    <Col md="3">
                        <label>CPF</label>
                        <InputCpfCnpj
                            innerRef={inputCpf}
                            type="text"
                            tipo={'cpf'}
                            value={modalFuncionarios.cpf}
                            onChange={e => actions.setModalFuncionario({ ...modalFuncionarios, cpf: e.target.value })}
                            autoComplete="off"
                            maxLength="14"
                            onKeyPress={e => handleKeyPress(e)}
                        />
                        <br></br>
                    </Col>
                    <Col md="8" >
                        <label>Nome / E-mail</label>
                        <Input
                            type="text"
                            value={modalFuncionarios.nomeEmail}
                            onChange={e => actions.setModalFuncionario({ ...modalFuncionarios, nomeEmail: e.target.value })}
                            autoComplete="off"
                            onKeyPress={e => handleKeyPress(e)}
                        />
                        <br></br>
                    </Col>
                    <Col lg={1} md={1} sm={1}>
                        <Button
                            type="button"
                            style={{ marginTop: 34, width: 40, marginBottom: 0 }}
                            color="info"
                            onClick={() => getFuncionario(pesquisaFuncionario)}
                        >
                            <Icon icon="search" className="fa-lg" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <fieldset className="fieldset-operacao">
                            <legend>Resultados</legend>
                            <div
                                ref={tableRef}
                                style={funcionarios.length !== 0 ?
                                    { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 350 } :
                                    { visibility: 'hidden', opacity: '0' }}>
                                <ReactTable
                                    data={funcionarios}
                                    NoDataComponent={() => (<></>)}
                                    columns={[
                                        {
                                            Header: 'CPF',
                                            accessor: 'cpf',
                                            width: 125,
                                            resizable: false,
                                            sortable: false,
                                            Cell: (props) => props.original.cpf = maskCPF(props.original.cpf)
                                        },
                                        {
                                            Header: 'Nome',
                                            accessor: 'nomeFunc',
                                            resizable: false,
                                            sortable: false,
                                            width: 220
                                        },
                                        {
                                            Header: 'E-mail',
                                            accessor: 'email',
                                            resizable: false,
                                            sortable: false
                                        }
                                    ]}
                                    getTrProps={getTrProps}
                                    getTdProps={getTdProps}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight header-fix"
                                    noDataText=""
                                    pageSize={funcionarios.length}
                                />
                            </div>
                            <br />
                        </fieldset>
                    </Col>

                </Row>

                <Col className="text-right">
                    <Info>
                        {`${funcionarios.length} Funcionários encontrados`}
                    </Info>
                </Col>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        onClick={() => confirmModal(pesquisaFuncionario)}
                        color={"info"}
                    >CONCLUÍDO
                    </Button>
                    <Button
                        className="btn-padrao"
                        onClick={() => limparModal()}
                        color={"info"}
                    >LIMPAR
                    </Button>
                </Col>
            </ModalBasicFooter>

        </ModalBasic>
    )
}