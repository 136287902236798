export enum ConfiguracaoStore {
    ID_STORE = 'CONFIGURACAO',
}

export enum ConfiguracaoTypes {

    USER = 'USER',

    CLEAN_ALL = 'CLEAN_ALL',
    CLEAN_TELAS = 'CLEAN_TELAS',
    CLEAN_FIVETELAS = "CLEAN_FIVETELAS",

    TELAS = 'TELAS',
    FIVETELAS = 'FIVETELAS',
    FIVETELAS_COPIA = 'FIVETELAS_COPIA',

    SCREEN = 'SCREEN'
}
export type ConfiguracaoAction = {

    idStore: ConfiguracaoStore.ID_STORE
    type: ConfiguracaoTypes
    value?: any
    user?: IConfiguracaoState['user']
    telas?: IConfiguracaoState['telas']
    fiveTelas?: IConfiguracaoState['fiveTelas']
    fiveTelasCopia?: IConfiguracaoState['fiveTelasCopia']

    screen?: IConfiguracaoState['screen']

}

export interface IConfiguracaoState {
    user: {
        nome: string,
        cpf: string,
        grupo: string

    },

    telas: ITela[],
    fiveTelas: ITela[],
    fiveTelasCopia: ITela[],

    screen: number,

}

interface ITela {

    codTela: any,
    label: string,
    status: string,
    disable: boolean

}

