import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import { maskCEST } from '~/Util/mascaras';

const idStore = 'CESTS';

export function Pesquisar(notificacao, dispatch, codEmpresa, pesquisa) {
    try {

        if (pesquisa.codSeguimento === "0" && !pesquisa.cest && !pesquisa.descricao) {
            return alertar(notificacao, 'br', "Informe ao menos um dos campos", 'warning', 'icon-alert-circle-exc', 2)
        }

        dispatch({ idStore, type: 'CESTS', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/cest/cests?codEmpresa=${codEmpresa}&cest=${pesquisa.cest.replace(/\./g, '')}&descricao=${pesquisa.descricao}&codSeguimento=${pesquisa.codSeguimento}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum CEST encontrado", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'CESTS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os CESTs", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetCEST(notificacao, dispatch, codEmpresa, cest, thenFunction) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/cest/cest?codEmpresa=${codEmpresa}&cest=${cest.replace(/\./g, '')}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            let type = 'CEST';
            let seguimento = parseInt(cest.substring(0, 2))
            dispatch({ idStore, type, campo: 'codSeguimento', value: seguimento })
            dispatch({ idStore, type, campo: 'cest', value: maskCEST(response.data.records.cest) })
            dispatch({ idStore, type, campo: 'descricao', value: response.data.records.descricao })
            dispatch({ idStore, type, campo: 'update', value: true })
            dispatch({ idStore, type: 'CEST_COPIA' })
            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export async function CreateCEST(notificacao, dispatch, codEmpresa, cest) {
    try {
        //Retorna se houve erro ou nao
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;
        if (cest.codSeguimento === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codSeguimento', value: true })
            alertar(notificacao, 'br', "Selecione um seguimento", 'info', 'icon-bulb-63', 2);
            return true
        }
        if (cest.cest === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'cest', value: true })
            invalido = true
        }
        if (cest.cest.replace(/\./g, '').length < 7) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'cest', value: true })
            alertar(notificacao, 'br', "O CEST precisa ter 7 dígitos", 'info', 'icon-bulb-63', 2);
            return true
        }
        if (cest.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (parseInt(cest.cest.substring(0, 2)) !== cest.codSeguimento) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'cest', value: true })
            alertar(notificacao, 'br', "Seguimento não corresponde com o CEST", 'warning', 'icon-bulb-63', 2);
            return true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/cest/createCest?codEmpresa=${codEmpresa}&codSeguimento=${cest.codSeguimento}`,
            headers: { Authorization: basicAuth },
            data: {
                CEST: cest.cest,
                Descricao: cest.descricao,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'CEST', campo: 'update', value: true })
            dispatch({ idStore, type: 'CEST_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function UpdateCEST(notificacao, dispatch, codEmpresa, cest, cestCopia) {
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(cest) === JSON.stringify(cestCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        if (cest.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            return true
        }

        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/cest/updateCest?codEmpresa=${codEmpresa}&cest=${cest.cest.replace(/\./g, '')}`,
            headers: { Authorization: basicAuth },
            data: {
                Descricao: cest.descricao,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'CEST_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export function Excluir(notificacao, dispatch, codEmpresa, cest, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/cest/excluirCest?codEmpresa=${codEmpresa}&cest=${cest.cest.replace(/\./g, '')}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar(notificacao, dispatch, codEmpresa, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function GetSeguimentos(dispatch) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/cest/seguimentos`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            var records = []
            records = response.data.records.map(record => {
                record.descricao = `${record.codigo < 10 ? '0' + record.codigo : record.codigo} - ${record.descricao}`
                return record
            });
            dispatch({ idStore, type: 'SEGUIMENTOS', value: records })
        })
    } catch (e) {
        return
    }
}