import React from "react";

import {
    Col,
    Button,
    Row,
} from "reactstrap";

import store from '../../../../../store/stores'
import * as controllerEntrada from '../../../../../controller/Movimentacao/controllerEntradaMercadoria'
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import FIltrosTotais from './Totais'
import Duplicata from './Duplicata'
import Observacoes from './Observações'

const Totais = function () {
    const dispatch = store.dispatch
    const entrada = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.dadosIniciais);
    const verCadastro = useShallowEqualSelector(state => state.entradaMercadoria.visualizarEntrada);

    function limparDados(){
        dispatch({ idStore:'ENTRADA_MERCADORIA', type:'CLEAN_CADASTRO'})
        dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'WIZARD_STEP', value: 0 })
    }

    return (
        <>
            <Col lg="12">
            <Col lg={12} >

                <Row style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%', marginTop: 4 }} >
                    <Button
                        type={"button"}
                        color={"info"}
                        style= {verCadastro ? {pointerEvents: "none"} : null}
                    >
                        <Icon icon="download" className="fa-md" color="#FFF" pull="left" />{" "}
                        IMPORTAR XML
                    </Button>
                    <Button
                        type={"button"}
                        color={"info"}
                        onClick={() => limparDados()}
                        style= {verCadastro ? {pointerEvents: "none"} : null}
                    >
                        <Icon icon="plus-circle" className="fa-lg" color="#FFF" pull="left" />{" "}
                        NOVO
                    </Button>
                    <Button
                        type={"button"}
                        color={"info"}
                        onClick={() => !entrada.numEntrada? controllerEntrada.SalvarEntrada() : controllerEntrada.UpdateEntrada()}
                        style= {verCadastro ? {pointerEvents: "none"} : null}
                    >
                        <Icon icon="save" className="fa-lg" color="#FFF" pull="left" />
                        Salvar
                    </Button>
                    <Button
                        type={"button"}
                        color={"green"}
                        style= {verCadastro ? {pointerEvents: "none"} : null}
                    >
                        <Icon icon="dollar-sign" className="fa-lg" color="#FFF" pull="left" />{" "}
                        Finalizar
                    </Button>
                </Row>
                </Col>
                <br></br>
                <Col>
                    <Row>
                        <Col>
                            <FIltrosTotais />
                        </Col>
                    </Row>
                </Col>
                <Col style={{ paddingTop: "20px" }}>
                    <Row>
                        <Col>
                            <Duplicata />
                        </Col>
                    </Row>
                </Col>
                <Col style={{ paddingTop: "20px" }}>
                    <Row>
                        <Col>
                            <Observacoes />
                        </Col>
                    </Row>
                </Col>
            </Col>
        </>
    )
}

class Wizard extends React.Component {
    render() {
        return (
            <Totais />
        )
    }
}

export default Wizard