import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerFormasPagamentos from "~/controller/controllerFormasPagamentos";
import { Select, Switch } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';
import { HelpButton } from "~/components/HelpPlugin/HelpPlugin";
import {
    Input,
    Row,
    Col,
    CardBody,
    Button,
    CardFooter,
    FormGroup,
    ModalBody,
    Modal,
    Label
} from "reactstrap";

const idStore = "FORMAS_PAGAMENTOS";


const FormaPagamento = () => {
    const notificacao = useSelector(state => state.notificacao);
    const { formaPgto, tiposPgtos, formasPgtoCopia, invalido, verCadastro } = useSelector(state => state.formasPagamentos);
    const [modalInativar, setModalInativar] = useState(false);
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false)

    useEffect(() => {
        if (formaPgto.codTipoPgto === 1) {
            setDisable(true)
            dispatch({ idStore, type: 'CLEAN_AVISTA' })
        } else {
            setDisable(false)
        }
    }, [formaPgto.codTipoPgto])

    function onChange(value, stateName) {
        dispatch({ idStore, type: 'FORMA_PGTO', campo: stateName, value: value });
        dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
    };

    async function onChangeAtivo(e) {
        toggleModalInativar()
    };

    async function OnChangeFormaPagamento(value, campo) {
        dispatch({ idStore, type: 'FORMA_PGTO', campo, value });
        let valor
        if (value !== '0') {
            valor = await controllerFormasPagamentos.GetTipoPgto(notificacao, dispatch, value)
        }
        if (valor === 1) {
            setDisable(true)
            dispatch({ idStore, type: 'CLEAN_FLAG' });
            dispatch({ idStore, type: 'FORMA_PGTO', campo: "flaVista", value: 1 })
            dispatch({ idStore, type: 'FORMA_PGTO', campo: "flBaixaAutomatica", value: 1 })

        } else if (valor === 2) {
            if (formaPgto.codForma !== 0) {
                dispatch({ idStore, type: 'FORMA_PGTO', campo: "diasPrazo", value: 30 })
                setDisable(false)
            } else {
                dispatch({ idStore, type: 'CLEAN_FLAG' })
                dispatch({ idStore, type: 'FORMA_PGTO', campo: "diasPrazo", value: 30 })
                setDisable(false)
            }
        } else {
            if (formaPgto.codForma !== 0) {
                setDisable(false)
            } else {
                dispatch({ idStore, type: 'CLEAN_FLAG' })
                setDisable(false)
            }
        }
    }

    function handleCheck(value, campo) {

        if (value === 0) {
            dispatch({ idStore, type: 'FORMA_PGTO', campo, value: 1 })
        } else {
            dispatch({ idStore, type: 'FORMA_PGTO', campo, value: 0 })
        }
    }

    function inativar() {
        if (formaPgto.codForma !== '') {
            controllerFormasPagamentos.InativarFormaPgto(notificacao, dispatch, formaPgto);
        }
        dispatch({ idStore, type: 'FORMA_PGTO', campo: 'status', value: formaPgto.status === 1 ? 0 : 1 });
    }

    async function Salvar() {

        if (formaPgto.codForma === 0) {
            controllerFormasPagamentos.CreateFormaPgto(notificacao, dispatch, formaPgto);
        } else {
            controllerFormasPagamentos.UpdateFormaPgto(notificacao, dispatch, formaPgto, formasPgtoCopia);
        }
    };

    function Limpar() {
        dispatch({ idStore, type: 'CLEAN_FORMA_PGTO' });
        dispatch({ idStore, type: 'CLEAN_FLAG' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        setDisable(false)
        document.getElementById('descricao').focus()
    };

    function toggleModalInativar() {
        setModalInativar(!modalInativar);
    }

    return (
        <>
            <Row className="justify-content-center">
            <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

            </div>
                <Col>
                    <h4 className="info-text">Preencha com as informações da forma de pagamento</h4>
                    <CardBody>

                        <Row className="justify-content-center">

                            <Col md="12" className="text-right">
                                <Switch
                                    componentleft={<label className={formaPgto.status === 0 ? `text-success text-center` : "text-warning text-center"}>{formaPgto.status === 0 ? "Ativo" : "Inativo"}</label>}
                                    checked={formaPgto.status === 0}
                                    onChange={e => onChangeAtivo(e)}
                                    disabled={formaPgto.codForma === 0}
                                />
                            </Col>

                            <Col md="12">
                                <Row>
                                    <Col md="10">

                                        <Row className="padding-padrao-row">

                                            <Col className="padding-padrao-col" lg="3" md="5" sm="4">
                                                <label>Tipos de Pagamento<span className="error"> *</span></label>
                                                <Select
                                                    id="tipoPgto"
                                                    first="Selecione"
                                                    value={{ value: formaPgto.codTipoPgto }}
                                                    select={{ value: "codTipoPgto", label: "descricao" }}
                                                    options={tiposPgtos}
                                                    onChange={value => OnChangeFormaPagamento(value.value, 'codTipoPgto')}                                                  
                                                />
                                            </Col>

                                            <Col className="padding-padrao-col">
                                                <label>Descrição<span className="error"> *</span></label>
                                                <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                                                    <Input
                                                        id="descricao"
                                                        type="text"
                                                        value={formaPgto.descricao}
                                                        onChange={e => onChange(e.target.value, 'descricao')}
                                                        autoComplete="off"
                                                        maxLength="100"
                                                    
                                                    />
                                                    <br></br>
                                                </FormGroup>
                                            </Col>


                                        </Row>
                                        <br />
                                        <Row className="padding-padrao-row">

                                            <Col className="padding-padrao-col" lg md="5" sm="5">
                                                <Col>
                                                    <Row>
                                                        <label>Quantidade dias entre parcelas</label>
                                                        <Col style={{ marginLeft: '-15px', marginTop: '3px' }}>
                                                            <HelpButton id="7"></HelpButton>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Input
                                                    id="qtdDiasEntreParcelas"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={formaPgto.qtdDiasEntreParcelas}
                                                    onChange={e => onChange(e.target.value, 'qtdDiasEntreParcelas')}
                                                    maxLength="2"
                                                    disabled={disable}
                                                />

                                            </Col>

                                            <Col className="padding-padrao-col" lg md="3" sm="3">
                                                <Col>
                                                    <Row>
                                                        <label>Prazo dias p/ repasse</label>
                                                        <Col style={{ marginLeft: '-15px', marginTop: '3px' }}>
                                                            <HelpButton id="8"></HelpButton>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Input
                                                    id="diasPrazo"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={formaPgto.diasPrazo}
                                                    onChange={e => onChange(e.target.value, 'diasPrazo')}
                                                    maxLength="2"
                                                    disabled={disable}
                                                />
                                            </Col>

                                            <Col className="padding-padrao-col" lg md="3" sm="4">
                                                <Col>
                                                    <Row>
                                                        <label>Percentual Tarifa</label>
                                                        <Col style={{ marginLeft: '-15px', marginTop: '3px' }}>
                                                            <HelpButton id="9"></HelpButton>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Input
                                                    id="PercentualTarifa"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={formaPgto.percentualTarifa}
                                                    onChange={e => onChange(e.target.value, 'percentualTarifa')}
                                                    maxLength="10"
                                                    disabled={disable}
                                                />

                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col md="2">
                                        <Row>
                                            <Col md="12">
                                                <Row>
                                                    <Col md="12" sm="3" className={disable ? "disabled-pointer" : ""}>
                                                        <FormGroup check>
                                                            <Col style={{ width: '200px' }}>
                                                                <br></br>
                                                                <Row>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            onChange={() => handleCheck(formaPgto.flaVista, 'flaVista')}
                                                                            checked={formaPgto.flaVista}
                                                                            disabled={disable}
                                                                        />
                                                                        <span className="form-check-sign" />À vista
                                                                    </Label>
                                                                </Row>
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="12" sm="3" className={disable ? "disabled-pointer" : ""}>
                                                        <FormGroup check>
                                                            <Col style={{ width: '200px' }}>
                                                                <br></br>
                                                                <Row>
                                                                    <Label check>
                                                                        <Input type="checkbox"
                                                                            onChange={() => handleCheck(formaPgto.flRetirada, 'flRetirada')}
                                                                            checked={formaPgto.flRetirada}
                                                                            disabled={disable}
                                                                        />
                                                                        <span className="form-check-sign" />Retirada
                                                                    </Label>
                                                                </Row>
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="12" sm="3" className={disable ? "disabled-pointer" : ""}>
                                                        <FormGroup check>
                                                            <Col style={{ width: '200px' }}>
                                                                <br></br>
                                                                <Row>
                                                                    <Label check>
                                                                        <Input type="checkbox"
                                                                            onChange={() => handleCheck(formaPgto.flAbreCaixa, 'flAbreCaixa')}
                                                                            checked={formaPgto.flAbreCaixa}
                                                                            disabled={disable}
                                                                        />
                                                                        <span className="form-check-sign" />Abre Caixa
                                                                    </Label>
                                                                </Row>
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="12" sm="3" className={disable ? "disabled-pointer" : ""}>
                                                        <FormGroup check>
                                                            <Col style={{ width: '200px' }}>
                                                                <br></br>
                                                                <Row>
                                                                    <Label check>
                                                                        <Input type="checkbox"
                                                                            onChange={() => handleCheck(formaPgto.flBaixaAutomatica, 'flBaixaAutomatica')}
                                                                            checked={formaPgto.flBaixaAutomatica}
                                                                            disabled={disable}
                                                                        />
                                                                        <span className="form-check-sign" />Baixa Automática
                                                                    </Label>
                                                                </Row>
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col style={{ marginLeft: '100px', marginTop: '-95px' }}>
                                                        <HelpButton id="10"></HelpButton>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>

                            </Col>

                        </Row>

                    </CardBody>
                    <CardFooter className="text-center">
                        <Row>                      
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                <ArrowTooltip title='Shift+N'>
                                    <Button className="btn-padrao" color="default" onClick={() => Limpar()}>Novo</Button>
                                </ArrowTooltip>
                                <ArrowTooltip title='Shift+S'>
                                    <Button className="btn-padrao" color="info" onClick={() => Salvar()}>Salvar</Button>
                                </ArrowTooltip>
                            </Col>
                        </Row>
                    </CardFooter>
                </Col>
            </Row>

            {/* Modal Inativar */}
            <Modal
                isOpen={modalInativar}
                toggle={toggleModalInativar}
            >
                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalInativar}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">{formaPgto.status === 0 ? "Inativar" : "Ativar"} forma de pagamento</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>
                            {formaPgto.status === 0 ?
                                <>Tem certeza que deseja <b>inativar</b> esse forma de pagamento da sua empresa?</>
                                : <>Tem certeza que deseja <b>ativar</b> esse forma de pagamento em sua empresa?</>
                            }
                        </label>
                    </Col>

                </ModalBody>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { toggleModalInativar() }}
                                style={{ width: '150px' }}
                            >NÃO
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { toggleModalInativar(); inativar() }}
                                style={{ width: '150px' }}
                            >SIM
                                            </Button>
                        </Col>
                    </Row>
                </CardBody>

            </Modal>
            {/* Modal Inativar*/}

        </>
    )
};

class Wizard extends React.Component {
    focusInput = () => {
        setTimeout(() => {
            document.getElementById('descricao').focus()
        }, 100)
    }

    render() {
        return (
            <FormaPagamento />
        )
    }
}

export default Wizard;