import { ConfiguracaoAction, ConfiguracaoStore, ConfiguracaoTypes, IConfiguracaoState } from './types'
import store from "../../stores";

export const setTelas = (telas: IConfiguracaoState['telas']) => store.dispatch<ConfiguracaoAction>({
    idStore: ConfiguracaoStore.ID_STORE,
    type: ConfiguracaoTypes.TELAS,
    value: telas

})

export const setFiveTela = (fiveTela: IConfiguracaoState['fiveTelas']) => store.dispatch<ConfiguracaoAction>({
    idStore: ConfiguracaoStore.ID_STORE,
    type: ConfiguracaoTypes.FIVETELAS,
    value: fiveTela

})

export const setFiveTelaCopia = (fiveTelaCopia: IConfiguracaoState['fiveTelasCopia']) => store.dispatch<ConfiguracaoAction>({
    idStore: ConfiguracaoStore.ID_STORE,
    type: ConfiguracaoTypes.FIVETELAS_COPIA,
    value: fiveTelaCopia

})


export const setScreen = (screen: IConfiguracaoState['screen']) => store.dispatch<ConfiguracaoAction>({
    idStore: ConfiguracaoStore.ID_STORE,
    type: ConfiguracaoTypes.SCREEN,
    value: screen

})

export const setUser = (user: IConfiguracaoState['user']) => store.dispatch<ConfiguracaoAction>({
    idStore: ConfiguracaoStore.ID_STORE,
    type: ConfiguracaoTypes.USER,
    value: user

})

export const cleanAll = () => store.dispatch<ConfiguracaoAction>({
    idStore: ConfiguracaoStore.ID_STORE,
    type: ConfiguracaoTypes.CLEAN_ALL
});

