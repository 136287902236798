
import React from 'react'

import {
    Modal,
    Col,
    ModalBody,
    ModalFooter,
    Row,
    Button,
    CardBody

} from 'reactstrap'


type Toggle = (newState?: boolean, callback?: (state?: boolean) => void) => void


interface Props {
    isOpen: boolean,
    callback: Function
    toggle?: Function
    information?: any
    functionSecond?: Function | any
}


export const PopUpInativar = ({ isOpen, callback, toggle, information }: Props) => {

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => toggle && toggle()}
                centered={true}
                backdropClassName={'backdropTeste'}
            >
                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggle && toggle()}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Atenção</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        {
                            information.status === 0 ?
                                <label>Tem certeza que deseja <b>inativar</b> esse serviço?</label>
                                :
                                <label>Tem certeza que deseja <b>Ativar</b> esse serviço?</label>
                        }
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Row style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                        <Button
                            color="danger"
                            type="button"
                            onClick={() => toggle && toggle()}
                            style={{ width: '150px' }}
                        >
                            NÃO
            </Button>
                        <Button
                            color="info"
                            type="button"
                            onClick={() => { callback() }}
                            style={{ width: '150px' }}
                        >
                            SIM
            </Button>
                    </Row>
                </ModalFooter>

            </Modal>


        </>)
}

export const PopUpExcluir = ({ isOpen, callback, toggle }: Props) => {
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => toggle && toggle()}
            >

                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggle && toggle()}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Excluir serviço</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>Tem certeza que deseja <b>excluir</b> esse serviço?</label>
                    </Col>

                </ModalBody>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => toggle && toggle()}
                                style={{ width: '150px' }}
                            >NÃO
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { callback() }}
                                style={{ width: '150px' }}
                            >SIM
                                            </Button>
                        </Col>
                    </Row>
                </CardBody>

            </Modal>

        </>
    )
}

export const PopUpNaoExisteGrupo = ({ isOpen, callback, toggle, functionSecond }: Props) => {
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => toggle && toggle()}
            >

                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggle && toggle()}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h5 className="title title-up">É <b>necessário</b> grupo de produto</h5>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>Deseja <b>cadastrar</b> algum grupo de produto ?</label>
                    </Col>

                </ModalBody>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => callback()}
                                style={{ width: '150px' }}
                            >Voltar
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { functionSecond() }}
                                style={{ width: '150px' }}
                            >Cadastrar
                                                                        </Button>
                        </Col>
                    </Row>
                </CardBody>

            </Modal>

        </>
    )
}