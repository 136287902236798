import React, { useState } from 'react'
import { Input, Button, ButtonGroup, CardFooter, Col, Modal, ModalBody, Row } from 'reactstrap'
import { Cliente, IInformacaoEnvioPedido, Informacoes } from '../../../store/reducers/Movimentacao/PedidoVenda/types'
import moment from 'moment'
import classNames from 'classnames'
import AppState from '../../../store/storeType'
import useShallowEqualSelector from '../../../Util/Hooks/useShallowEqualSelector'
import { numPedidoPDF, PedidoPDF } from '../../../views/Movimentacao/PedidoVenda/PDF/PedidoPDF'
import { tituloMov, ReciboPDF, ReciboCupom } from '../../../views/Financeiro/Recebimento/PDF/ReciboPDF'
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer'
import { HelpButton } from '../../../components/HelpPlugin/HelpPlugin'

import * as actionsPDV from '../../../store/reducers/Movimentacao/PedidoVenda/actions'
import * as controllerPDV from '../../../controller/Movimentacao/controllerPedidoVenda'

import * as controllerRecibimentos from '../../../controller/Financeiro/controllerRecebimentosPagamentos'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import whatsappIcon from '../../../assets/img/iconWhatsapp.svg'

interface IStepWhatsappProps {
  telefoneWpp: any;
}

const StepWhatsapp: React.FC<IStepWhatsappProps> = ({ telefoneWpp }) => {
  return (
    <>
      <Row>
        <Col>
          <label htmlFor="">Celular:</label>
          <Input
            className="inputChange-value"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => actionsPDV.setInformacaoEnvioPedido('whatsapp', { telefone: e.target.value })}
            placeholder="Ex: 1699997070"
            value={telefoneWpp && telefoneWpp}
            type="number"
          />
        </Col>

      </Row>
      <div className={"right-help-button"} style={{ right: '5px', position: 'absolute' }} >
        <HelpButton id="5" />
      </div>
    </>
  )
}

const StepEmail: React.FC = () => {
  return (
    <Row>
      <Col>
        <label htmlFor="">Email:</label>
        <Input
          style={{ width: '250px' }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => actionsPDV.setInformacaoEnvioPedido('email', { endereco: e.target.value })}
          placeholder="Ex: email@domain.com"
        />
      </Col>
    </Row>
  )
}

interface IPopUpEnvioPedidoProps {
  isOpen: any;
  toggle: any;
  callback: any;
  tipoEnvio: string;
  numPedido?: any;
  codigoTituloMovimento?: any;
}

const PopUpEnvio: React.FC<IPopUpEnvioPedidoProps> = ({ isOpen, toggle, callback, numPedido, codigoTituloMovimento, tipoEnvio }) => {
  const informacoesEnvioPedido = useShallowEqualSelector<AppState, IInformacaoEnvioPedido>(state => state.pedidoVenda.informacaoEnvioPedido)
  const cliente = useShallowEqualSelector<AppState, Cliente>(state => state.pedidoVenda.cliente)
  const informacoes = useShallowEqualSelector<AppState, Informacoes>(state => state.pedidoVenda.informacoes)
  const [typeTab, setTypeTab] = useState('whatsapp')
  const [metodoEnvio, setMetodoEnvio] = useState('A4')

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => toggle && toggle()}
        id="modalEnvioPedido"
      >
        <div className="modal-header justify-content-center">
          <button
            className="close"
            type="button"
            onClick={() => toggle && toggle()}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">Envio do Pedido</h6>
        </div>
        <ModalBody>
          <Row>
            <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
              <Row>
                <Col className="text-center">
                  <label>Escolha qual forma do Envio do Pedido:</label>
                </Col>
              </Row>
              <Row md="12" style={{ width: '100%' }} >
                <Col md="12">
                  <ButtonGroup style={{ width: '100%' }} >
                    <Button
                      style={{ width: '50%' }}
                      className={classNames(" btn-envio-pedido btn-gp-padrao",
                        typeTab === "whatsapp"
                          ? "active"
                          : ""
                      )}
                      onClick={() => setTypeTab('whatsapp')}
                    >
                      <img src={whatsappIcon} alt="" />
                      Whatsapp
                    </Button>
                    <Button
                      style={{ width: '50%' }}
                      className={classNames(" btn-envio-pedido btn-gp-padrao",
                        typeTab === "email"
                          ? "active"
                          : ""
                      )}
                      onClick={() => setTypeTab('email')}
                    >
                      <Icon icon="envelope" className="fa-lg" />
                      E-Mail
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              {tipoEnvio === 'recibimentosPagamentos' && (
                <Row md="12" style={{ margin: '5px 0' }}>
                  <Col md="12" style={{ textAlign: 'center' }} >
                    <h6>Modelo do Recibo</h6>
                  </Col>
                  <Col style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                    <input type="radio" name="metodoEnvio" value={'A4'} checked={metodoEnvio === 'A4'} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMetodoEnvio(e.target.value)} />
                    <label style={{ margin: 0, marginLeft: '2px' }}>A4</label>
                  </Col>
                  <Col style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <input type="radio" name="metodoEnvio" value={'Cupom'} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMetodoEnvio(e.target.value)} />
                    <label style={{ margin: 0, marginLeft: '2px' }}>Cupom</label>
                  </Col>
                </Row>
              )}
              <Row>
                {typeTab === 'whatsapp' ? (
                  <StepWhatsapp telefoneWpp={informacoesEnvioPedido.whatsapp.telefone && informacoesEnvioPedido.whatsapp.telefone} />
                ) : (
                  <StepEmail />
                )}
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <CardFooter style={{ backgroundColor: 'transparent' }}>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => toggle && toggle()}
                style={{ width: '150px' }}
              >VOLTAR</Button>
            </Col>
            <Col className="text-center">
              {typeTab === 'whatsapp' ? (
                tipoEnvio === 'pedidoVenda' ? (
                  <PDFDownloadLink document={PedidoPDF} fileName={`Nº do Pedido - ${informacoes.numPedido} de ${cliente.nomeRazao} - ${moment(new Date()).format('DD-MM-YYYY')}`}  >
                    {({ blob, url }) => (
                      //@ts-ignore
                      <Button
                        style={{ width: '150px' }}
                        color="success"
                        type="button"
                        onClick={() => { controllerPDV.enviarPedido() }}
                      >ENVIAR</Button>
                    )}
                  </PDFDownloadLink>
                ) : (
                  //@ts-ignore
                  <PDFDownloadLink document={metodoEnvio === 'A4' ? ReciboPDF : ReciboCupom} >
                    {({ blob, url }) => (
                      //@ts-ignore
                      <Button
                        style={{ width: '150px' }}
                        color="success"
                        type="button"
                        onClick={() => { controllerRecibimentos.enviarRecibimentos() }}
                      >ENVIAR {metodoEnvio === 'A4' ? 'A4' : 'Cupom'}</Button>
                    )}
                  </PDFDownloadLink>
                )
              ) : (
                <Button
                  style={{ width: '150px' }}
                  color="success"
                  type="button"
                  onClick={() => { }}
                >ENVIAR</Button>
              )}
            </Col>
          </Row>
        </CardFooter>
      </Modal>
      {(isOpen && tipoEnvio === 'pedidoVenda') && numPedidoPDF(numPedido)}
      {(isOpen && tipoEnvio === 'recibimentosPagamentos') && tituloMov(codigoTituloMovimento)}
    </>
  )
}

export default PopUpEnvio