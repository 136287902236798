import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import {
    Card,
    Col,
    CardBody,
    Input,
    Row,
    Button,
    FormGroup,
    Label
} from "reactstrap";
import { Select } from "../../../Components";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import useResize from "../../../../../Util/Hooks/useResize";
import ArrowTooltip from '../../../../../components/Tooltip/ArrowTooltip';
import classNames from "classnames";
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import AppState from "../../../../../store/storeType";
import { IInventarioState } from "../../../../../store/reducers/Movimentacao/Inventario/types";
import { HelpButton } from "../../../../../components/HelpPlugin/HelpPlugin";
import * as action from '../../../../../store/reducers/Movimentacao/Inventario/actions'
import * as controllerInventario from '../../../../../controller/controllerInventario'
import { maskNumbers } from "../../../../../Util/mascaras";
import Grade from '../../../Modals/Grade'
import { PopUp } from '../../../Modals/PopUp'
import useModal from "../../../../../Util/Hooks/useModal";

const Inventario = () => {
    const { width } = useResize();
    const [modalGrade, toogleModalGrade] = useModal(false)
    const [modalPopUp, toogleModalPopUp] = useModal(false)

    useEffect(() => {
        controllerInventario.initScreen()
    }, [])

    return (
        <>
            <Col className="mr-auto ml-auto" md="12">
                <Card>
                    <CardBody>
                        <Filtros />
                        <TableItensPesquisados />
                        <br />
                        <TableProdutosAtualizacao toogleModalGrade={toogleModalGrade} />

                        <Row>
                            <CardBody>
                                <Col style={{ width: '100%' }}>
                                    <Button
                                        color='info'
                                        style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                        onClick={() => controllerInventario.verificaProdutosAtualizacao(toogleModalPopUp)}
                                    >
                                        Atualizar
                                        </Button>
                                </Col>
                            </CardBody>
                        </Row>

                        {
                            modalGrade && <Grade isOpen={true} toggle={toogleModalGrade} />
                        }

                        {
                            modalPopUp && <PopUp isOpen={true} toggle={toogleModalPopUp} />
                        }

                    </CardBody>
                </Card>
            </Col>
        </>
    );
}

const Filtros = () => {
    const refInputCodigo = useRef<HTMLInputElement>(null);
    const refInputQuantidade = useRef<HTMLInputElement>(null);

    const pesquisa = useShallowEqualSelector<AppState, IInventarioState['pesquisa']>(state => state.inventario.pesquisa)
    const listGrupo = useShallowEqualSelector<AppState, IInventarioState['listGrupo']>(state => state.inventario.listGrupo)
    const listSubGrupo = useShallowEqualSelector<AppState, IInventarioState['listSubGrupo']>(state => state.inventario.listSubGrupo)

    const { width } = useResize();

    useEffect(() => {
        if (refInputCodigo.current)
            refInputCodigo.current.focus();
    }, [])

    function changeGrupo(value: any) {
        if (value.value !== "0") {
            action.setPesquisa({
                ...pesquisa,
                codigoGrupo: value.value,
                descricaoGrupo: value.label
            })
            controllerInventario.GetSubGrupos(value.value)
        } else {
            action.setListSubGrupo([])
            action.setPesquisa({
                ...pesquisa,
                codigoGrupo: value.value,
                descricaoGrupo: value.label,
                codigoSubGrupo: '0',
                descricaoSubGrupo: ''
            })
        }

        controllerInventario.GetProdutoInventario()
    }

    function cleanAll() {
        action.cleanAll();
        controllerInventario.GetGrupos()
    }

    function handleKeyPress() {
        controllerInventario.GetProdutoInventario()

        if (refInputQuantidade.current)
            refInputQuantidade.current.focus()

    }

    function focusQuantidade() {
        if (refInputQuantidade.current)
            refInputQuantidade.current.focus()
    }

    return (
        <>
            <Row className="padding-padrao-row">
                <Col className="padding-padrao-col" lg={3}>
                    <label>
                        <Row style={{ marginLeft: 0 }}>
                            Cód. Barras / Descrição
                            <HelpButton id="17" />
                        </Row>
                    </label>
                    <Input
                        type="text"
                        value={pesquisa.codigoDescricao}
                        innerRef={refInputCodigo}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { action.setPesquisa({ ...pesquisa, codigoDescricao: event.currentTarget.value }) }}
                        onKeyPress={(event) => event.key === 'Enter' && handleKeyPress()}
                    />
                </Col>

                <Col className="padding-padrao-col" lg={4}>
                    <label>Grupo</label>
                    <Select
                        //@ts-ignore
                        first={"Todos"}
                        value={{ value: pesquisa.codigoGrupo }}
                        select={{ value: "codGrupo", label: "descricao" }}
                        onChange={(value: any) => changeGrupo(value)}
                        options={listGrupo}
                        isSearchable={true}
                        autoComplete="off"
                    />
                </Col>
                <Col className="padding-padrao-col" lg={3}>
                    <label>SubGrupo</label>
                    <Select
                        //@ts-ignore
                        first={"Todos"}
                        value={{ value: pesquisa.codigoSubGrupo }}
                        select={{ value: "codSubgrupo", label: "descricao" }}
                        onChange={(value: any) => { action.setPesquisa({ ...pesquisa, codigoSubGrupo: value.value, descricaoSubGrupo: value.label }); controllerInventario.GetProdutoInventario() }}
                        options={listSubGrupo}
                        isSearchable={true}
                        autoComplete="off"
                    />
                </Col>
                <Col className="padding-padrao-col" lg={2}>
                    <label>Quantidade Atualizar</label>
                    <Input
                        innerRef={refInputQuantidade}
                        type="text"
                        style={{ textAlign: 'right' }}
                        value={pesquisa.quantidade}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { action.setPesquisa({ ...pesquisa, quantidade: maskNumbers(event.currentTarget.value) }) }}
                        onFocus={(event) => { event.currentTarget.select() }}
                        onKeyPress={(event) => event.key === 'Enter' && controllerInventario.AddItemAtualizar(focusQuantidade)}
                        maxLength={10}
                    />
                </Col>
            </Row>

            <Row>
                <CardBody>
                    <Col style={{ width: '100%' }}>
                        <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={() => cleanAll()}
                        >
                            Limpar
                        </Button>
                        <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={() => controllerInventario.GetProdutoInventario()}
                        >
                            Pesquisar
                        </Button>
                        <Button
                            color='green'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={() => { controllerInventario.AddItemAtualizar(focusQuantidade) }}
                        >
                            Adicionar
                        </Button>
                    </Col>
                </CardBody>
            </Row>
        </>
    )
}

function TableItensPesquisados() {
    const listItens = useShallowEqualSelector<AppState, IInventarioState['listItens']>(state => state.inventario.listItens)

    const [pageSize, setPageSize] = useState(5);
    const [pageAtual, setPageAtual] = useState(1);

    const [checkAll, setCheckAll] = useState(false);

    function onChangeCheck(index: number) {
        let newListStr = JSON.stringify(listItens);
        let newListArr = JSON.parse(newListStr);

        const checked = !newListArr[index].checked;

        newListArr[index].checked = checked;

        if (!checked)
            setCheckAll(false)

        action.setListItens(newListArr);

    }

    function onCheckAll() {
        const checked = !checkAll;

        setCheckAll(checked)

        let newListStr = JSON.stringify(listItens);
        let newListArr = JSON.parse(newListStr);

        newListArr.forEach((item: any) => { item.checked = checked })
        action.setListItens(newListArr);

    }

    return (
        <fieldset className='fieldset-style'>
            <legend style={{ fontSize: '14px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
                Itens Pesquisados
            </legend>

            <div
                style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative' }}>
                <ReactTable
                    data={listItens}
                    filterable={false}
                    NoDataComponent={() => (<></>)}
                    columns={
                        [
                            {
                                Header:

                                    <ArrowTooltip title="Marca todos os itens">
                                        <div>
                                            <Col>
                                                <FormGroup check style={{ textAlign: 'center' }}>
                                                    <Label check style={{ height: '15px' }}>
                                                        <Input type="checkbox"
                                                            onChange={() => onCheckAll()}
                                                            checked={checkAll}
                                                        />
                                                        <span className="form-check-sign" />
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </div>

                                    </ArrowTooltip>,
                                width: 100,
                                resizable: false,
                                sortable: false,
                                Cell: (prop) =>
                                    <div>
                                        <Col>
                                            <FormGroup check key={prop.index} style={{ textAlign: 'center' }}>
                                                <Label check style={{ height: '15px' }}>
                                                    <Input type="checkbox"
                                                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => { onChangeCheck(prop.index) }}
                                                        checked={prop.original.checked}
                                                    />
                                                    <span className="form-check-sign" />
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </div>
                            },
                            {
                                Header: "Numero",
                                accessor: "numeroProduto",
                                width: 150
                            },
                            {
                                Header: "Código de Barras",
                                accessor: "codigoBarras"
                            },
                            {
                                Header: "Grupo",
                                accessor: "descricaoGrupo"
                            },
                            {
                                Header: "SubGrupo",
                                accessor: "descricaoSubGrupo"
                            },
                            {
                                Header: "Descricao",
                                accessor: "descricaoProduto"
                            },
                            {
                                Header: "Estoque Atual",
                                accessor: "estoqueAtual",
                                width: 150
                            }
                        ]
                    }
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando"
                    pageText="Página"
                    ofText="de"
                    rowsText='linhas'
                    defaultPageSize={pageSize}
                    onPageChange={(page) => { setPageAtual(page) }}
                    onPageSizeChange={(size) => { setPageSize(size) }}
                />
            </div>

        </fieldset>

    )
}

function TableProdutosAtualizacao(props: any) {
    const listAtualizacao = useShallowEqualSelector<AppState, IInventarioState["listAtualizacao"]>(state => state.inventario.listAtualizacao)

    function setGradeProduto(grades: any[], codigoProduto: number, codigoTipoGrade: number, quantidadeAtualizar: number) {
        action.setGradeSelecionada({ codigoProduto, codigoTipoGrade, quantidadeAtualizar })
        action.setGrade(grades)
    }

    return (
        <fieldset className='fieldset-style'>
            <legend style={{ fontSize: '14px', width: 'auto', margin: '0 15px 0 15px', padding: '0 25px 0 25px' }} >
                <Row>
                    Produtos para atualização de inventário
                    <HelpButton id="18" />
                </Row>
            </legend>
            <div
                style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative' }}>
                <ReactTable
                    data={listAtualizacao}
                    filterable={false}
                    NoDataComponent={() => (<></>)}
                    columns={
                        [
                            {
                                Header: "Numero",
                                accessor: "numeroProduto",
                                width: 150
                            },
                            {
                                Header: "Cód. Barras",
                                accessor: "codigoBarras"
                            },
                            {
                                Header: "Grupo",
                                accessor: "descricaoGrupo"
                            },
                            {
                                Header: "SubGrupo",
                                accessor: "descricaoSubGrupo"
                            },
                            {
                                Header: "Descricao",
                                accessor: "descricaoProduto"
                            },
                            {
                                Header: "Estoque",
                                accessor: "estoqueAtual"
                            },
                            {
                                Header: "Estoque Atualizar",
                                accessor: "estoqueAtualizar"
                            },
                            {
                                Header: "Operações",
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                width: 100,
                                Cell: (prop) =>
                                    <>
                                        <ArrowTooltip title="Grade">
                                            <Button
                                                onClick={() => { props.toogleModalGrade(); setGradeProduto(prop.original.grades, prop.original.codigoProduto, prop.original.codigoTipoGrade, prop.original.estoqueAtualizar) }}
                                                size="sm"
                                                className={classNames("btn-icon btn-link")}
                                                color="info"
                                                disabled={!prop.original.flControlaGrade}
                                            >
                                                <Icon icon="th" className="fa-lg" />
                                            </Button>
                                        </ArrowTooltip>

                                        <ArrowTooltip title="Remover">
                                            <Button
                                                onClick={() => controllerInventario.RemoveItemAtualizar(prop.index)}
                                                size="sm"
                                                className={classNames("btn-icon btn-link")}
                                                color="danger"
                                            >
                                                <Icon icon="trash-alt" className="fa-lg" />
                                            </Button>
                                        </ArrowTooltip>
                                    </>
                            },
                            {
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                width: 50,
                                Header:
                                    <ArrowTooltip title="Remover Todos">
                                        <Button
                                            onClick={() => action.setListAtualizar([])}
                                            size="sm"
                                            className={classNames("btn-icon btn-link")}
                                            color="danger"
                                        >
                                            <Icon icon="minus-square" className="fa-lg" />
                                        </Button>
                                    </ArrowTooltip>,
                                Cell: (prop) =>
                                    prop.original.gradeConfirmada === true ?
                                        <ArrowTooltip title="Grade Atualizada">
                                            <Button
                                                size="sm"
                                                className={classNames("btn-icon btn-link")}
                                                color="success"
                                            >
                                                <Icon icon="check" className="fa-lg" />
                                            </Button>
                                        </ArrowTooltip>
                                        :
                                        prop.original.gradeConfirmada === false ?
                                            <ArrowTooltip title="Grade Desatualizada">
                                                <Button
                                                    size="sm"
                                                    style={{ color: '#FF0000' }}
                                                    className={classNames("btn-icon btn-link")}
                                                >
                                                    <Icon icon="times" className="fa-lg" />
                                                </Button>
                                            </ArrowTooltip>
                                            :
                                            null
                            }
                        ]
                    }
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando"
                    pageText="Página"
                    ofText="de"
                    rowsText='linhas'
                    pageSize={listAtualizacao.length}
                />
            </div>
        </fieldset>
    )
}

export default Inventario;
