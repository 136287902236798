import React from "react";

import {
    Col,
    Button,
    Row,
} from "reactstrap";

import store from '../../../../../store/stores'

import FiltrosItens from "./Item"
import DataTable from "./DataTable"

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {SalvarEntrada, UpdateEntrada} from '../../../../../controller/Movimentacao/controllerEntradaMercadoria'
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import useModal from "../../../../../Util/Hooks/useModal";
import ModalFaturamento from '../../../../../components/Modals/Faturamento'

import * as controllerEntrada from "../../../../../controller/Movimentacao/controllerEntradaMercadoria"


const Item = function () {
    const dispatch = store.dispatch
    const entrada = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.dadosIniciais);
    const verCadastro = useShallowEqualSelector(state => state.entradaMercadoria.visualizarEntrada);

    const [modalFaturamento, toogleFaturamento] = useModal(false)

    function limparDados() {
        dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'CLEAN_CADASTRO' })
        dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'WIZARD_STEP', value: 0 })
    }
   
    return (
        <>
            <Col lg="12">
            <Col lg="12" >
                <Row style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%', marginTop: 4 }} >
                    <Button
                        type={"button"}
                        color={"info"}
                        style= {verCadastro ? {pointerEvents: "none"} : null}
                    >
                        <Icon icon="download" className="fa-md" color="#FFF" pull="left" />{" "}
                        IMPORTAR XML
                    </Button>
                    <Button
                        type={"button"}
                        color={"info"}
                        onClick={() => limparDados()}
                        style= {verCadastro ? {pointerEvents: "none"} : null}
                    >
                        <Icon icon="plus-circle" className="fa-lg" color="#FFF" pull="left" />{" "}
                        NOVO
                        </Button>
                    <Button
                        type={"button"}
                        color={"info"}
                        onClick={() => !entrada.numEntrada? SalvarEntrada() : UpdateEntrada()}
                        style= {verCadastro ? {pointerEvents: "none"} : null}
                    >
                        <Icon icon="save" className="fa-lg" color="#FFF" pull="left" />
                        Salvar
                        </Button>
                    <Button
                        type={"button"}
                        color={"green"}
                        onClick={() => controllerEntrada.FinalizarEntrada(toogleFaturamento)}

                        style= {verCadastro ? {pointerEvents: "none"} : null}
                    >
                        <Icon icon="dollar-sign" className="fa-lg" color="#FFF" pull="left" />{" "}
                        Finalizar
                        </Button>
                </Row>
                </Col>

                <fieldset style={{ border: 'solid 1px #5E6680', borderRadius: 5 }}>
                    <legend style={{ fontSize: '13px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
                        ITENS
                    </legend>

                    <Col>
                        <Row>
                            <Col>
                                <FiltrosItens />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <DataTable />
                            </Col>
                        </Row>
                    </Col>
                </fieldset>
                <br />
            </Col>
           { modalFaturamento ? <ModalFaturamento isOpen={true} toogle={toogleFaturamento} /> : null}
        </>
    )
}

class Wizard extends React.Component {
    render() {
        return (
            <Item />
        )
    }
}

export default Wizard;