import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import ModalImpostos from "../../Modals/ModalImpostos";
import {maskMoeda} from "../../../../../Util/mascaras";
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import useModal from "../../../../../Util/Hooks/useModal";
import { Row, Col, Input, Button } from "reactstrap";
import { alertar } from "~/Util/alertar";
import store from '../../../../../store/stores';

const idStore = "ENTRADA_MERCADORIA"

function FIltrosTotais() {
    const valoresTotais = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.itens.valoresTotais);
    const verCadastro = useShallowEqualSelector(state => state.entradaMercadoria.visualizarEntrada);
    const [modalTotais, toggleModalTotais] = useModal(false);
    const dispatch = useDispatch();
    const { notificacao } = store.getState()

    useEffect(() => {
        let valoresString = JSON.stringify(valoresTotais);
        let valoresJSON = JSON.parse(valoresString)

        let acrescimo = ''
        let desconto = ''
        let valor = Number(valoresJSON.subTotal.toString().replace('.',''))
        valor = Number(valoresJSON.subTotal.toString().replace(',','.'))

        let subTotal = valor

        if (valoresJSON.acrescimo)
            acrescimo = valoresJSON.acrescimo
            acrescimo = acrescimo.toString().replace('.','');
            acrescimo = Number(acrescimo.toString().replace(',','.'));
            valor += acrescimo

        if (valoresJSON.desconto)
            desconto = valoresJSON.desconto
            desconto = desconto.toString().replace('.','')
            desconto = Number(desconto.toString().replace(',','.'))
            if (desconto <= valor){
                valor -= desconto
            } else 
                if ((subTotal + acrescimo) < desconto){
                alertar(notificacao, 'br', "Desconto não pode ser maior que o valor total", 'warning', 'icon-alert-circle-exc', 2)
            }

        dispatch({ 
            idStore, 
            type: 'VALORES_TOTAIS', 
            value: { ...valoresTotais, valorTotal: valor.toFixed(2) } })

    }, [valoresTotais.subTotal, valoresTotais.acrescimo, valoresTotais.desconto])

    
    function onChange(value, stateName) {
        dispatch({
            idStore,
            type: 'VALORES_TOTAIS',
            value: {...valoresTotais, [stateName]: value}
        })
    }

    return (
        <>
            <Row>
            <div className= {verCadastro ? 'ver-cadastro' : ''}></div>
                <Col lg="12">
                    <Row className="padding-padrao-row">
                        <Col className="padding-padrao-col">
                            <label>Sub Total</label>
                            <Input
                                type="text"
                                autoComplete="off"
                                value={maskMoeda(valoresTotais.subTotal)}
                                disabled="true"
                            />
                        </Col>
                        <Col className="padding-padrao-col">
                            <label>Acréscimo (R$)</label>
                            <Input
                                type="text"
                                autoComplete="off"
                                value={valoresTotais.acrescimo}
                                onChange={e => onChange(maskMoeda(e.target.value), "acrescimo")}
                            />
                        </Col>
                        <Col className="padding-padrao-col">
                            <label>Desconto (R$)</label>
                            <Input
                                type="text"
                                autoComplete="off"
                                value={valoresTotais.desconto}
                                onChange={e => onChange(maskMoeda(e.target.value), "desconto")}
                            />
                        </Col>
                        <Col className="padding-padrao-col">
                            <label>Valor total da nota</label>
                            <Input
                                type="text"
                                autoComplete="off"
                                value={maskMoeda(valoresTotais.valorTotal)}
                                disabled="true"
                            />
                        </Col>

                        <Col className="padding-padrao-col" style={{ paddingTop: "20px" }}>
                            <Button
                                className="btn-padrao"
                                color="info"
                                style={{ fontSize: "90%" }}
                                onClick={toggleModalTotais}
                            >Ver Impostos Totais</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {modalTotais ?
                <ModalImpostos
                    isOpen={true}
                    toggle={toggleModalTotais}
                /> :
                null
            }
        </>
    )
}

export default FIltrosTotais;