import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import classNames from "classnames";
import { maskCEST } from "~/Util/mascaras"
import * as controllerCESTs from "~/controller/controllerCESTs";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import ReactTable from "react-table";
import ReactWizard from "~/components/Wizard/Wizard";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';
import { Select } from "~/components/ErnComponets/ErnComponets";
import { useHotkeys } from 'react-hotkeys-hook';
import {
    Card,
    Col,
    Button,
    ButtonGroup,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Row,
    TabContent,
    TabPane,
    Modal,
    ModalBody,
} from "reactstrap";

// wizard steps
import Step1 from "~/views/CESTs/CadastroSteps/CEST.jsx";

var steps = [
    {
        stepName: "CEST",
        stepIcon: "tim-icons icon-notes",
        component: Step1,
        stepProps: {}
    },
];
const idStore = 'CESTS';

const CESTs = () => {
    const notificacao = useSelector(state => state.notificacao);
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
    const { cest, cestCopia, pesquisa, cests, inicioCadastro, seguimentos } = useSelector(state => state.cests);
    const dispatch = useDispatch();
    const [dataTable, setDataTable] = useState([]);
    const [table, setTable] = useState(renderTable([]));
    const [horizontalTabs, setHorizontalTabs] = useState('pesquisar');
    const [tableVisible, setTableVisible] = useState(false);
    const [modalExcluir, setModalExcluir] = useState(false);
    const [cestSelected, setcestSelected] = useState({ status: 0 });
    const [width, setWidht] = useState(window.innerWidth);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', updateWidth);
    }, [])

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', updateWidth);
        }
    }, [])

    function updateWidth() {
        setWidht(window.innerWidth);
    }

    useEffect(() => {
        steps.forEach(step => {
            step.stepProps = { ...step.stepProps, notificacao }
        });
    }, [notificacao])

    useEffect(() => {
        setDataTable(tableData(cests));
        if (cests.length > 0) {
            setTableVisible(true);
        } else {
            setTableVisible(false);
        }
    }, [cests])

    useEffect(() => {
        setTable(renderTable());
    }, [dataTable, loading])

    useEffect(() => {
        steps.forEach(step => {
            step.stepProps = { ...step.stepProps, cest: cest }
        });
        setInicioCadastro(false)
    }, [cest])

    useEffect(() => {
        if (inicioCadastro)
            setInicioCadastro(false)
    }, [inicioCadastro])

    useEffect(() => {
        controllerCESTs.GetSeguimentos(dispatch)
    }, [])

    useHotkeys('shift+1', () => {
        changeActiveTab('pesquisar');
    }, [])

    useHotkeys('shift+2 ', () => {
        changeActiveTab("cadastrar");
        novoCadatro();
    }, [])

    useHotkeys('shift+p', () => {
        if (horizontalTabs === 'pesquisar') {
            handlePesquisar()
        }
    }, [codEmpresa, pesquisa])

    useHotkeys('shift+l', () => {
        if (horizontalTabs === 'pesquisar') {
            handleLimpar();
        } else {
            dispatch({ idStore, type: 'CLEAN_CEST' });
            dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        }
    }, [horizontalTabs])

    useHotkeys('shift+N', () => {
        dispatch({ idStore, type: 'CLEAN_CEST' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    }, [horizontalTabs])

    useHotkeys('shift+s', () => {
        if (horizontalTabs === "cadastrar") {
            dispatch({ idStore, type: 'CLEAN_INVALIDO' });
            if (!cest.update) {
                controllerCESTs.CreateCEST(notificacao, dispatch, codEmpresa, cest);
            } else {
                controllerCESTs.UpdateCEST(notificacao, dispatch, codEmpresa, cest, cestCopia);
            }
        }
    }, [horizontalTabs, codEmpresa, cest, cestCopia])

    function tableData(data) {
        return data.map((prop, key) => {
            return {
                id: key,
                cest: maskCEST(prop['cest']),
                descricao: prop['descricao'],
                principal: prop['principal'],
                descricaoSeguimento: prop['descricaoSeguimento'],
            }
        })
    }

    function renderTable() {
        return dataTable.map((obj, key) => {
            return {
                ...dataTable[key],
                actions: (
                    <div className="actions-right">

                        <ArrowTooltip title="Visualizar">
                            <Button
                                onClick={() => {
                                    handleVisualizarCadastro(obj);
                                }}
                                size="sm"
                                className={classNames("btn-icon btn-link")}
                                style={{ color: "#483D8B" }}

                            >
                                <Icon icon="eye" className="fa-lg" />
                            </Button>
                        </ArrowTooltip>
                        {
                            !loading ?
                                <ArrowTooltip title="Editar">
                                    <Button
                                        onClick={() => {
                                            handleEditarCadastro(obj);
                                        }}
                                        size="sm"
                                        className={classNames("btn-icon btn-link")}
                                        color="success"

                                    >

                                        <Icon icon="edit" className="fa-lg" />

                                    </Button>

                                </ArrowTooltip>
                                :
                                <Button
                                    onClick={() => {
                                        handleEditarCadastro(obj);
                                    }}
                                    size="sm"
                                    className={classNames("btn-icon btn-link")}
                                    color="success"
                                    disabled={true}
                                >
                                    <Icon icon="edit" className="fa-lg" />
                                </Button>
                        }
                        <ArrowTooltip title={obj.principal ? 'Não é possível excluir um CEST padrão do sistema' : 'Excluir'}>
                            <span>
                                <Button
                                    onClick={() => { handleExcluir(obj) }}
                                    className="btn-icon btn-link"
                                    color="danger"
                                    disabled={obj.principal}
                                >
                                    <Icon icon="trash-alt" className="fa-lg" />
                                </Button>
                            </span>
                        </ArrowTooltip>
                    </div >
                )
            };
        })
    }

    function changeActiveTab(tabName) {

        setHorizontalTabs(tabName);
        if (tabName === "pesquisar") {
            setTimeout(() => {
                dispatch({ idStore, type: 'VER_CADASTRO', value: false })
                document.getElementById('cest').focus()
            }, 100)
        }
    };

    function onChangeSeguimento(value) {
        dispatch({ idStore, type: 'PESQUISA', campo: 'codSeguimento', value });
        controllerCESTs.Pesquisar(notificacao, dispatch, codEmpresa, { ...pesquisa, codSeguimento: value })
    }

    function handleOnChange(value, campo) {
        dispatch({ idStore, type: 'PESQUISA', campo, value });
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            controllerCESTs.Pesquisar(notificacao, dispatch, codEmpresa, pesquisa);
        }
    }

    function handlePesquisar() {

        controllerCESTs.Pesquisar(notificacao, dispatch, codEmpresa, pesquisa);
    }

    function handleLimpar() {
        dispatch({ idStore, type: 'CLEAN_SEARCH' })

    }

    async function handleVisualizarCadastro(obj) {
        setLoading(true);

        if (!loading) {
            await controllerCESTs.GetCEST(notificacao, dispatch, codEmpresa, obj.cest, () => {
                setHorizontalTabs("cadastrar")
                setInicioCadastro(true);
                dispatch({ idStore, type: 'CLEAN_INVALIDO' });
            });
        }
        setLoading(false);
        dispatch({ idStore, type: 'VER_CADASTRO', value: true })


    }

    async function handleEditarCadastro(obj) {

        setLoading(true);

        if (!loading) {
            await controllerCESTs.GetCEST(notificacao, dispatch, codEmpresa, obj.cest, () => {
                setHorizontalTabs("cadastrar")
                setInicioCadastro(true);
                dispatch({ idStore, type: 'CLEAN_INVALIDO' });
            });
        }
        setLoading(false);
    }

    async function handleExcluir(obj) {
        setcestSelected(obj);
        toggleModalExcluir();
    }

    async function novoCadatro() {
        setInicioCadastro(true);
        dispatch({ idStore, type: 'CLEAN_CEST' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    }

    function excluir() {
        controllerCESTs.Excluir(notificacao, dispatch, codEmpresa, cestSelected, pesquisa);
    }

    function setInicioCadastro(valor) {
        dispatch({ idStore, type: 'INICIO_CADASTRO', value: valor });
    }

    async function finishButtonClick() {

        var error;
        if (cest.cest === '') {
            error = await controllerCESTs.CreateCEST(notificacao, dispatch, codEmpresa, cest);
            handleError(error)
        } else {
            error = await controllerCESTs.UpdateCEST(notificacao, dispatch, codEmpresa, cest, cestCopia);
            handleError(error)
        }

        function handleError(error) {
            if (!error) {
                dispatch({ idStore, type: 'CLEAN_CEST' });
                setInicioCadastro(true);
            }
        }
        return error
    }

    function toggleModalExcluir() {
        setModalExcluir(!modalExcluir);
    }

    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="12">
                    <Card>

                        <CardHeader className="text-center">
                            <CardTitle tag="h3">Código Especificador da Substituição Tributária</CardTitle>
                            <br />
                            <TabContent
                                className="tab-space"
                                activeTab={horizontalTabs}
                                style={{ marginTop: '-30px', marginBottom: '-70px' }}
                            >
                            </TabContent>
                        </CardHeader>

                        <CardBody>
                            <ButtonGroup style={{ width: '100%' }}>
                                <ArrowTooltip title='Shift+1'>
                                    <Button
                                        color={
                                            horizontalTabs === "pesquisar"
                                                ? "info"
                                                : ""
                                        }
                                        type="button"
                                        data-toggle="tab"
                                        className={classNames("btn-gp-padrao",
                                            horizontalTabs === "pesquisar"
                                                ? "active"
                                                : ""
                                        )
                                        }
                                        onClick={() => {
                                            changeActiveTab("pesquisar");
                                        }
                                        }
                                        style={{ width: '50%' }}
                                    >
                                        PESQUISAR
                                    </Button>
                                </ArrowTooltip>
                                <ArrowTooltip title='Shift+2'>
                                    <Button
                                        color={
                                            horizontalTabs === "cadastrar"
                                                ? "info"
                                                : ""
                                        }
                                        type="button"
                                        data-toggle="tab"
                                        className={classNames("btn-gp-padrao",
                                            horizontalTabs === "cadastrar"
                                                ? "active"
                                                : ""
                                        )
                                        }
                                        onClick={() => {
                                            changeActiveTab("cadastrar");
                                            novoCadatro();
                                        }
                                        }
                                        style={{ width: '50%' }}
                                    >
                                        CADASTRAR
                                    </Button>
                                </ArrowTooltip>
                            </ButtonGroup>
                            <TabContent
                                className="tab-space"
                                activeTab={horizontalTabs}
                                style={{ paddingBottom: '0px' }}
                            >
                                <TabPane tabId="pesquisar">
                                    <Row  className="padding-padrao-row">
                                        <Col className="padding-padrao-col" lg="4" md="3">
                                            <label>Seguimento</label>
                                            <Select
                                                first={'Selecione'}
                                                value={{ value: pesquisa.codSeguimento }}
                                                select={{ value: 'codigo', label: 'descricao' }}
                                                options={seguimentos}
                                                onChange={value => onChangeSeguimento(value.value)}
                                            />
                                        </Col>
                                        <Col className="padding-padrao-col" lg="3" md="3">
                                            <label>CEST</label>
                                            <Input
                                                id="cest"
                                                type="text"
                                                autoComplete="off"
                                                value={pesquisa.cest}
                                                onChange={e => handleOnChange(maskCEST(e.target.value), 'cest')}
                                                maxLength="9"
                                                onKeyPress={e => handleKeyPress(e)}
                                                autoFocus={true}
                                            />
                                        </Col>

                                        <Col className="padding-padrao-col" lg="5" md="3">
                                            <label>Descrição</label>
                                            <Input
                                                id="descricao"
                                                type="text"
                                                autoComplete="off"
                                                value={pesquisa.descricao}
                                                onChange={e => handleOnChange(e.target.value, 'descricao')}
                                                onKeyPress={e => handleKeyPress(e)}
                                            />
                                        </Col>

                                    </Row>

                                    <Row>
                                        <CardBody>
                                            <Col style={{ width: '100%' }}>
                                                <ArrowTooltip title='Shift+L'>
                                                    <Button
                                                        color='info'
                                                        style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                                        onClick={() => handleLimpar()}
                                                    >Limpar</Button>
                                                </ArrowTooltip>
                                                <ArrowTooltip title='Shift+P'>
                                                    <Button
                                                        color='info'
                                                        style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                                        onClick={() => handlePesquisar()}
                                                    >Pesquisar</Button>
                                                </ArrowTooltip>
                                            </Col>
                                        </CardBody>
                                    </Row>
                                    <div style={tableVisible ? { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
                                        <ReactTable
                                            data={table}
                                            filterable={false}
                                            columns={
                                                [
                                                    {
                                                        Header: "CEST",
                                                        accessor: "cest"
                                                    },
                                                    {
                                                        Header: "Descrição",
                                                        accessor: "descricao"
                                                    },
                                                    {
                                                        Header: "Seguimento",
                                                        accessor: "descricaoSeguimento"
                                                    },
                                                    {
                                                        Header: "",
                                                        accessor: "actions",
                                                        sortable: false,
                                                        filterable: false
                                                    }
                                                ]
                                            }
                                            minRows={1}
                                            showPaginationTop={false}
                                            showPaginationBottom={true}
                                            className="-striped -highlight"
                                            noDataText=""
                                            previousText="Anterior"
                                            nextText="Próximo"
                                            loadingText="Carregando"
                                            pageText="Página"
                                            ofText="de"
                                            rowsText='linhas'
                                        />
                                    </div>
                                </TabPane>

                                <TabPane tabId="cadastrar">
                                    <div style={{ marginTop: '-20px' }}>
                                        <ReactWizard
                                            steps={steps}
                                            navSteps={true}
                                            title=""
                                            description=""
                                            validate
                                            resizable={true}
                                            previousButtonText="Voltar"
                                            finishButtonText="Finalizar"
                                            nextButtonText="Avançar"
                                            goToFirstStep={inicioCadastro}
                                            finishButtonClick={() => finishButtonClick()}
                                            finishButtonClasses={classNames("btn-wd btn-info",
                                                {
                                                    finishButtonMin:
                                                        width < 550
                                                },
                                                {
                                                    finishButtonRight:
                                                        !width < 550
                                                }
                                            )}
                                            nextButtonClasses={classNames("btn-wd btn-info",
                                                {
                                                    nextButtonMin:
                                                        width < 550

                                                },
                                                {
                                                    nextButtonRight:
                                                        !width < 550
                                                }
                                            )}
                                            previousButtonClasses={classNames("btn-wd",
                                                {
                                                    previousButtonMin:
                                                        width < 550
                                                },
                                                {
                                                    previousButtonRight:
                                                        !width < 550
                                                }
                                            )}
                                            progressbar
                                            color="blue"
                                        />
                                    </div>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </div>

            {/* Modal Excluir */}
            <Modal
                isOpen={modalExcluir}
                toggle={toggleModalExcluir}
            >

                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalExcluir}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Excluir CEST</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>
                            Tem certeza que deseja <b>excluir</b> esse CEST?
                        </label>
                    </Col>

                </ModalBody>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { toggleModalExcluir() }}
                                style={{ width: '150px' }}
                            >NÃO
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { toggleModalExcluir(); excluir() }}
                                style={{ width: '150px' }}
                            >SIM
                                            </Button>
                        </Col>
                    </Row>
                </CardBody>

            </Modal>
            {/* Modal Excluir*/}
        </>
    );
}

export default CESTs;
