import React from 'react'
import { Col, Row, Button } from 'reactstrap'

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from '../../../../components/ModalBasic/ModalBasic'

interface ModalExisteAtivoGradeProps {
    isOpen: boolean
    toggle: () => void
}

const ModalExisteAtivoGrade: React.FC<ModalExisteAtivoGradeProps> = ({ isOpen, toggle }) => {

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title={'Nenhuma grade selecionada'}
        >
            <ModalBasicBody>

                <Col className="text-center">
                    <label>Não é possivel salvar sem nenhum vínculo ativo. Desative a opção de controlar a grade nos dados do produto ao invés disso.</label>
                </Col>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <Button
                            color="success"
                            type="button"
                            onClick={() => toggle()}
                            style={{ width: '100px' }}
                        >OK
                            </Button>
                    </Col>
                </Row>
            </ModalBasicFooter>
        </ModalBasic >
    )
}
export default ModalExisteAtivoGrade