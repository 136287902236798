import axios from 'axios';
import api from "~/Util/api.js";
import history from "~/Util/history";
import { alertar } from "~/Util/alertar";
import store from '~/store/stores';


var idStore = 'PERFIL_USUARIO';

export function editarPerfil() {
    history.push('/perfil');
}

export function pesquisar(dispatch) {
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/perfilUsuario/perfilUsuario`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma informação encontrada", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'PESQUISA', value: response.data.records })
            dispatch({ idStore, type: 'CADASTRO', value: response.data.records })
        }).catch(e => {
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as informações do usuário", 'warning', 'icon-alert-circle-exc', 2, e)
        return true
    }
}

export async function update(dispatch, cadastro, pesquisa, senha) {
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        
        //Retorna se houve erro ou nao
        if (JSON.stringify(cadastro) === JSON.stringify(pesquisa) && senha.novaSenha === "") {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        if (senha.novaSenha !== "") {

            if (senha.novaSenha === senha.senhaAtual) {
                alertar(notificacao, 'br', "A nova senha é correspondente a senha atual", 'warning', 'icon-alert-circle-exc', 2);
                dispatch({ idStore, type: 'INVALIDO', campo: 'novaSenha', value: true})
                return false
            }
            if (senha.novaSenha !== senha.confirmaSenha) {
                alertar(notificacao, 'br', "Nova Senha diferente da Senha Confirmada", 'warning', 'icon-alert-circle-exc', 2)
                dispatch({ idStore, type: 'INVALIDO', campo: 'confirmaSenha', value: true })
                return false
            }
        }
        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/perfilUsuario/update?senha=${senha.senhaAtual}`,
            headers: { Authorization: basicAuth },
            data: {
                apelido: cadastro.apelido,
                senha: senha.confirmaSenha,
                imagem: cadastro.imagem
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'CLEAN_SENHA' })
            pesquisar(dispatch)
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)
            dispatch({ idStore, type: 'INVALIDO', campo: 'senha', value: true })
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível atualizar o perfil do usuário", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}