import React, { useState, useLayoutEffect, useEffect } from 'react'
import { InputText, CheckInativar, LabelRequired, InputCpf, ButtonBase } from "./components";
import { Card, CardHeader, CardTitle, CardBody, Row, Col, CardFooter, Button, FormGroup, Label } from 'reactstrap';
import ReactTable from 'react-table';
import { useSelector } from 'react-redux';

import useShallowEqualSelector from '../../../../../../../Util/Hooks/useShallowEqualSelector';
import AppState from '../../../../../../../store/storeType';
import { IUsuarioState } from '../../../../../../../store/reducers/Cadastro/Usuario/types';
import * as actions from '../../../../../../../store/reducers/Cadastro/Usuario/actions';
import { Select } from '../../../../../../../components/ErnComponets/ErnComponets';
import * as controllerUsuarios from "../../../../../../../controller/controllerUsuarios";
import ArrowTooltip from '../../../../../../../components/Tooltip/ArrowTooltip';
import ImageUpload from '../../../../../../../components/CustomUpload/ImageUpload';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { PopUpExcluirViculacao, PopUpUsuarioExiste } from '../../../../../Moldals/PopUp';
import useModal from '../../../../../../../Util/Hooks/useModal';
import store from '../../../../../../../store/stores';
import { alertar } from '../../../../../../../Util/alertar';
import { ValidaEmail } from '../../../../../../../Util/validacao'

const Usuario = () => {

    const invalido = useShallowEqualSelector<AppState, IUsuarioState['invalido']>(state => state.usuario.invalido)
    const usuario = useShallowEqualSelector<AppState, IUsuarioState['usuario']>(state => state.usuario.usuario)
    const empresas = useShallowEqualSelector<AppState, IUsuarioState['empresas']>(state => state.usuario.empresas)
    const filiais = useShallowEqualSelector<AppState, IUsuarioState['filiais']>(state => state.usuario.filiais)
    const empresasUser = useShallowEqualSelector<AppState, IUsuarioState['empresasUser']>(state => state.usuario.empresasUser)

    const verificationUser = useShallowEqualSelector<AppState, IUsuarioState['verificationUser']>(state => state.usuario.verificationUser)
    const verCadastro = useShallowEqualSelector<AppState, IUsuarioState['verCadastro']>(state => state.usuario.verCadastro)
    const verificationSalvar = useShallowEqualSelector<AppState, IUsuarioState['verificationSalvar']>(state => state.usuario.verificationSalvar)

    const editar = useShallowEqualSelector<AppState, IUsuarioState['editar']>(state => state.usuario.editar)

    const [moldalVicularEmpresa, toogleVicularEmpresa] = useModal(false)
    const [modalUsuarioExiste, toogleModalUsuarioExiste] = useModal(false)

    const [empresaSelected, setEmpresaSelected] = useState<any>([])

    //@ts-ignore
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);

    const Limpar = () => {
        actions.cleanAcesso()
        actions.cleanUsuario()
        actions.cleanFuncionario()
        actions.cleanInvalido()
        actions.setCurrentStep(0)
        actions.setVerificationSalvar(false)
        actions.setEditar(false)
    }
    function handleExcluirEmpresa(value: any) {
        setEmpresaSelected(value)

        toogleVicularEmpresa()
    }

    useLayoutEffect(() => {
        controllerUsuarios.GetFiliais(codEmpresa)
    }, [codEmpresa])

    useLayoutEffect(() => {
        if (usuario.codUser !== '') {
            controllerUsuarios.GetAcessoUsuario(codEmpresa, usuario.codUser)
        }
    }, [empresasUser, codEmpresa, usuario.codUser])

    const Salvar = async function () {

        if (!verificationUser) {
            let retorno = await controllerUsuarios.SalvarUsuario(codEmpresa);

            if (retorno) {
                actions.setProximoCadastro(true)
            }

            setTimeout(() => { actions.setProximoCadastro(false); }, 100)

            actions.setVerificationUser(true)

        } else {

            let retorno = await controllerUsuarios.createFuncionario(codEmpresa);

            if (retorno) {
                actions.setProximoCadastro(true)
            }

            setTimeout(() => { actions.setProximoCadastro(false); }, 100)

        }

        actions.setVerificationSalvar(false)
        actions.setVerificationUser(false)

    }


    const handleSalvar = async function () {


        if ((!verificationSalvar && usuario.email !== '' && !verificationUser)) {

            let retorno: any = await controllerUsuarios.verificarUser()

            if (retorno) {

                retorno = await controllerUsuarios.GetUsuarioVerificado({ codEmpresa: codEmpresa, email: usuario.email })

                if (retorno) {
                    toogleModalUsuarioExiste()

                } else {

                    actions.setVerificationSalvar(false)
                    actions.setVerificationUser(false)

                }

            } else {
                Salvar()
            }

        } else {
            actions.setVerificationUser(true)
            setTimeout(() => {
                Salvar()
            }, 100);
        }

    }

    const handleSalvarModalUsuarioExiste = async function () {
        Salvar()
    }

    const handleVerificacaoUser = async function () {

        let retorno = await controllerUsuarios.GetUsuarioVerificado({ codEmpresa: codEmpresa, email: usuario.email })
        if (!retorno) {
            actions.setVerificationSalvar(false)
            actions.setVerificationUser(false)

        }

    }

    return (
        <>
            <Card>
                <div className={verCadastro === true ? 'ver-cadastro' : ''}>

                </div>
                <CardTitle align='center'>Preencha com as informações do usuário</CardTitle>
                <CardHeader>
                    <CheckInativar state={usuario} />
                </CardHeader>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col md='5'>
                            <Row>
                                <Col>
                                    <h5>Informações Básicas</h5>
                                </Col>
                            </Row>

                            <Row>

                                <Row>
                                    <Col className="padding-padrao-col" md={usuario.codUser !== '' ? "12" : "9"}>
                                        <LabelRequired labelProps={{ text: 'E-mail Pessoal ' }} />
                                        <FormGroup className={invalido.email ? "has-danger" : ''}>
                                            <InputText state={usuario}
                                                value={usuario.email}
                                                name='email'
                                                inputProps={{
                                                    type: 'email',
                                                    maxLength: 60,
                                                    disable: usuario.codUser !== '' ? true : false
                                                }}
                                                onChange={
                                                    (event: React.ChangeEvent<HTMLInputElement>) => {
                                                        actions.setUsuario({ ...usuario, email: event.currentTarget.value }),
                                                            actions.setInvalido({ ...invalido, email: false })
                                                    }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="padding-padrao-col">
                                        {ValidaEmail(usuario.email) && usuario.codUser === "" ?
                                            <ArrowTooltip title='Verificar se o usuário cadastrado no sistema'>
                                                <Button lg='2'
                                                    style={{ fontSize: '2em', minWidth: '2em', marginTop: '22px' }}
                                                    onClick={() => handleVerificacaoUser()}
                                                    className="btn-icon btn-link"
                                                    color="success"
                                                >
                                                    <Icon icon="user-check" />
                                                </Button>
                                            </ArrowTooltip>
                                            :
                                            null
                                        }
                                    </Col>
                                    <Col className="padding-padrao-col" md='12'>
                                        <LabelRequired labelProps={{ text: 'Nome ' }} />
                                        <FormGroup className={invalido.nome ? "has-danger" : ''}>
                                            <InputText state={usuario}
                                                value={usuario.nome}
                                                name='nome'
                                                inputProps={{
                                                    type: 'text',
                                                    maxLength: 55,
                                                    disable: usuario.codUser !== '' ? true : false,

                                                }}
                                                onChange={
                                                    (event: React.ChangeEvent<HTMLInputElement>) => {
                                                        actions.setUsuario({ ...usuario, nome: event.currentTarget.value }),
                                                            actions.setInvalido({ ...invalido, nome: false })
                                                    }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="padding-padrao-col">
                                        <LabelRequired labelProps={{ text: 'CPF ' }} />
                                        <FormGroup className={invalido.cpf ? "has-danger" : ''}>

                                            <InputCpf
                                                state={usuario}
                                                value={usuario.cpf}
                                                name='cpf'

                                                inputProps={{
                                                    type: 'cpf',
                                                    maxLength: 14,
                                                    disable: usuario.codUser !== '' ? true : false
                                                }}

                                                onChange={
                                                    (event: React.ChangeEvent<HTMLInputElement>) => {
                                                        actions.setUsuario({ ...usuario, cpf: event.currentTarget.value }),
                                                            actions.setInvalido({ ...invalido, cpf: false })
                                                    }}

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="padding-padrao-col">
                                        <Label>Apelido</Label>
                                        <FormGroup className={''}>
                                            <InputText state={usuario}
                                                value={usuario.apelido}
                                                name='apelido'
                                                inputProps={{
                                                    type: 'text',
                                                    maxLength: 15,
                                                    disable: usuario.codUser !== '' ? true : false
                                                }}
                                                onChange={
                                                    (event: React.ChangeEvent<HTMLInputElement>) => actions.setUsuario({ ...usuario, apelido: event.currentTarget.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                        <Col md='4' style={{ marginTop: '-0px' }}>
                            <h5>Foto de Perfil</h5>

                            <ImageUpload
                                changeBtnClasses="btn-simple btn-padrao"
                                addBtnClasses="btn-simple"
                                removeBtnClasses="btn-simple btn-padrao"
                                changeBtnColor="info"
                                addBtnColor="info"
                                avatar
                                imagem={usuario.foto}
                                onChange={(e: any) => actions.setUsuario({ ...usuario, foto: e })}
                                disabled={verCadastro}
                            />
                        </Col>
                    </Row>
                    <Row className="padding-padrao-row">
                        <Col className="padding-padrao-col" lg='5'>
                            <Select
                                id="empresa"
                                first="Selecione"
                                value={{ value: codEmpresa }}
                                select={{ value: 'codEmpresa', label: 'descricao' }}
                                onChange={(event: any) => { actions.setUsuario({ ...usuario, codEmpresa: event.value }) }}
                                //@ts-ignore
                                options={empresas}
                                disabled={true}

                            />
                            <br></br>
                        </Col>
                        <Col className="padding-padrao-col" lg='5'>
                            <Select
                                id="filial"
                                first="Selecione"
                                value={{ value: usuario.codFilial }}
                                select={{ value: 'codEmpresaFilial', label: 'descricao' }}
                                onChange={(event: any) => { actions.setUsuario({ ...usuario, codFilial: event.value }) }}
                                //@ts-ignore
                                options={filiais}
                            />
                            <br></br>
                        </Col>
                        <Col className="padding-padrao-col" lg='2'>
                            <Button lg='2'
                                style={{ fontSize: '2em', minWidth: '2em', marginTop: '0px' }}
                                onClick={() => controllerUsuarios.addEmpresa(codEmpresa)}
                                className="btn-icon btn-link"
                                color="success"
                                disabled={(usuario.codFilial === '0')}
                            >
                                <i className="fas fa-plus"></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ position: 'relative', justifyContent: 'center' }} >
                            <div style={{ borderRadius: '10px', border: 'solid 1px #363B4A' }} >
                                <ReactTable
                                    data={empresasUser}
                                    filterable={false}
                                    NoDataComponent={() => (<></>)}
                                    columns={
                                        [
                                            {
                                                Header: "Empresa",
                                                accessor: "empresa",
                                                resizable: false,
                                            },
                                            {
                                                Header: "Filial",
                                                accessor: "filial",
                                                resizable: false,
                                            },
                                            {
                                                Header: "",
                                                accessor: "actions",
                                                sortable: false,
                                                resizable: false,
                                                filterable: false,
                                                Cell: (props) =>
                                                    <>
                                                        {verCadastro ?
                                                            null
                                                            :

                                                            <ArrowTooltip title="Excluir">
                                                                <Button
                                                                    onClick={() => { handleExcluirEmpresa(props.row._original) }}
                                                                    className="btn-icon btn-link"
                                                                    color="danger"
                                                                >
                                                                    <Icon icon="trash-alt" className="fa-lg" />
                                                                </Button>
                                                            </ArrowTooltip>
                                                        }
                                                    </>

                                            }
                                        ]
                                    }
                                    minRows={1}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                    loadingText="Carregando"
                                    ofText="de"
                                    rowsText='linhas'
                                />
                            </div>
                        </Col>
                    </Row>

                </CardBody>
                <CardFooter>
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Row>
                            <Col>
                                <ButtonBase

                                    onClick={() => { Limpar() }}
                                    buttonProps={{
                                        color: 'default',
                                        className: 'btn-padrao',
                                        label: usuario.codUser !== "" ? "Novo" : "Limpar",
                                        arrowTooltip: true,
                                        arrowTooltipTitle: 'Shift+L'
                                    }} />
                            </Col>
                            <Col>
                                <ButtonBase
                                    onClick={() => {
                                        handleSalvar();

                                    }}
                                    buttonProps={{
                                        color: 'info',
                                        className: 'btn-padrao',
                                        label: 'Salvar',
                                        arrowTooltip: true,
                                        arrowTooltipTitle: 'Shift+S',
                                        disabled: editar
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </CardFooter>
            </Card>
            {moldalVicularEmpresa ?
                <PopUpExcluirViculacao
                    isOpen={moldalVicularEmpresa}
                    callback={() => { controllerUsuarios.ExcluirEmpresa(empresaSelected, usuario), toogleVicularEmpresa() }}
                    toggle={toogleVicularEmpresa}
                />
                :
                null
            }


            {modalUsuarioExiste ?
                <PopUpUsuarioExiste
                    isOpen={modalUsuarioExiste}
                    callback={() => { handleSalvarModalUsuarioExiste(), toogleModalUsuarioExiste() }}
                    toggle={() => { toogleModalUsuarioExiste(), actions.setVerificationSalvar(false), actions.setVerificationUser(false) }}
                />
                : null
            }
        </>
    )

}

class Wizard extends React.Component {

    isValidated = () => {
        const { usuario, funcionario, empresasUser } = store.getState().usuario
        //@ts-ignore
        const { notificacao } = store.getState()

        if (usuario.codUser !== '' && empresasUser.length > 0) {
            if (funcionario.codFunc !== '') {
                return true
            } else {
                alertar(notificacao, 'br', "Salvar o usuário para continuar", 'info', 'icon-bulb-63', 2);
                return false
            }
        } else {
            alertar(notificacao, 'br', "Efetue o cadastro do usuário para continuar", 'info', 'icon-bulb-63', 2);
            return false
        }
    }
    render() {
        return (
            <Usuario />
        )
    }
}

export default Wizard;