import React from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Col
} from "reactstrap";


import TabContent from "./TabContent/TabContent"
import Pesquisar from './TabContent/Tabs/Pesquisar/Pesquisar'
import Inventario from "./TabContent/Tabs/Inventario";
const Tabs = [
    {
        tabName: "PESQUISAR",
        tabScreen: <Pesquisar />,
        ArrowToolTipTitle: 'Shift+1'
    },
    {
        tabName: "INVENTÁRIO",
        tabScreen: <Inventario />,
        ArrowToolTipTitle: 'Shift+2'

    }
]
const Inventarios: React.FC = () => {
    return (
        <>
            <div className='content'>
                <Col className="mr-auto ml-auto" md="12">
                    <Card>
                        <CardHeader className="text-center">
                            <CardTitle tag="h3" className="remove-margin-bottom"> Inventário </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <TabContent tabs={Tabs} />
                        </CardBody>
                    </Card>
                </Col>
            </div>
        </>
    )
}

export default Inventarios
