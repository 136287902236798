
import React from 'react'
import { Col, Modal, ModalBody, ModalFooter, Row, Button } from 'reactstrap'

type Toggle = (newState?: boolean, callback?: (state?: boolean) => void) => void

interface Props {
    isOpen: boolean,
    callback: Function
    toggle?: Function
}

export const PopUpDelete = ({ isOpen, callback, toggle }: Props) => {
    return (
        <Modal
            isOpen={isOpen}
            toggle={() => toggle && toggle()}
            centered={true}
            backdropClassName={'backdropTeste'}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggle && toggle()}
                >
                    <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up">Excluír Operação</h6>
            </div>
            <ModalBody>

                <Col className="text-center">
                    <label>Tem certeza que deseja <b>excluir</b> essa operação?</label>
                </Col>

            </ModalBody>
            <ModalFooter>
                <Row style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                    <Button
                        color="danger"
                        type="button"
                        onClick={() => toggle && toggle()}
                        style={{ width: '150px' }}
                    >
                        NÃO
            </Button>
                    <Button
                        color="info"
                        type="button"
                        onClick={() => callback()}
                        style={{ width: '150px' }}
                    >
                        SIM
            </Button>
                </Row>
            </ModalFooter>
        </Modal>
    )
}