//State da tela de Pesquisa e cadastro de usuários
var INITIAL_STATE = {
    grupos: [],
    newGrupo: { nome: '', telas: [] },
};

export default function grupos(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'GRUPOS_ACESSO':
            switch (action.type) {
                case 'GRUPOS':
                    return { ...state, grupos: action.value }
                case 'CHANGE':
                    state.grupos[action.grupoIndex].telas[action.telaIndex].status = state.grupos[action.grupoIndex].telas[action.telaIndex].status === 1 ? 0 : 1
                    state.grupos[action.grupoIndex].telas[action.telaIndex].changed = !state.grupos[action.grupoIndex].telas[action.telaIndex].changed
                    return { ...state }
                case 'NEW_GRUPO':
                    return { ...state, newGrupo: { nome: '', telas: [] } }
                case 'NOME_GRUPO':
                    return { ...state, newGrupo: { ...state.newGrupo, nome: action.value } }
                case 'SET_TELA':
                    state.newGrupo.telas.push(action.value)
                    return { ...state }
                case 'CLEAN':
                    return {
                        ...state,
                        grupos: []
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}