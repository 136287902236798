import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import { ValidaCNPJ, ValidaCPF, ValidaData, ValidaCep, ValidaEmail } from "~/Util/validacao";
import store from '../store/stores';


var idStore = 'CLIENTES';

export function Pesquisar(notificacao, dispatch, pesquisa) {
    try {
        dispatch({ idStore, type: 'CLIENTES', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/cliente/clientes?status=${pesquisa.ativos}&tipoCli=${pesquisa.tipo}&nomeRazao=${pesquisa.nomeRazao}&fantasia=${pesquisa.fantasia}&cpfCnpj=${pesquisa.cpfCnpj}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum cliente encontrado", 'info', 'icon-bulb-63', 2)
            }

            response.data.records.forEach((cliente, index) => {
                response.data.records[index].dtCadastro = new Date(cliente.dtCadastro).toLocaleString();
            });

            dispatch({ idStore, type: 'CLIENTES', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os clientes", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function Inativar(notificacao, dispatch, cliente, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/cliente/inativarCliente?codCli=${cliente.codCli}&status=${cliente.status}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            dispatch({ idStore, type: 'CLIENTE_COPIA_STATUS', value: cliente.status === 1 ? 0 : 1 })
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar(notificacao, dispatch, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function ExcluirCliente(notificacao, dispatch, cliente, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/cliente/excluirCliente?codCli=${cliente.codCli}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar(notificacao, dispatch, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response.data.message, 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function CreateCliente(notificacao, dispatch, cliente) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        const idStore = 'CLIENTES';
        let data
        if (cliente.tipoCli !== "E") {
            var dtEmissao = cliente.dtEmissao;
            var dtNascimento = cliente.dtNascimento;

            if (cliente.nomeRazao === '') {
                dispatch({ idStore, type: 'INVALIDO', campo: 'nomeRazao', value: true })
                alertar(notificacao, 'br', "Nome/Razão Social Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if ((cliente.tipoCli === 'J' && !ValidaCNPJ(cliente.cpfCnpj)) || (cliente.tipoCli === 'F' && !ValidaCPF(cliente.cpfCnpj)) || cliente.cpfCnpj === '') {
                dispatch({ idStore, type: 'INVALIDO', campo: 'cpfCnpj', value: true })
                alertar(notificacao, 'br', "CPF/CNPJ Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if (cliente.tipoCli === 'J' && cliente.fantasia === '') {
                dispatch({ idStore, type: 'INVALIDO', campo: 'fantasia', value: true })
                alertar(notificacao, 'br', "Nome Fantasia Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if (!cliente.email && !ValidaEmail(cliente.email)) {
                dispatch({ idStore, type: 'INVALIDO', campo: 'email', value: true })
                alertar(notificacao, 'br', "E-mail Primário Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if (cliente.email2 !== '' && !ValidaEmail(cliente.email2)) {
                dispatch({ idStore, type: 'INVALIDO', campo: 'email2', value: true })
                alertar(notificacao, 'br', "E-mail Secundário Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if (dtEmissao !== '') {
                dtEmissao = new Date(cliente.dtEmissao)
                dtEmissao.setDate(dtEmissao.getDate() + 1)
                dtEmissao = dtEmissao.toLocaleDateString()
            }

            if (dtEmissao !== '' && !ValidaData(dtEmissao)) {
                dispatch({ idStore, type: 'INVALIDO', campo: 'dtEmissao', value: true })
                alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }

            if (dtNascimento !== '') {
                dtNascimento = new Date(cliente.dtNascimento)
                dtNascimento.setDate(dtNascimento.getDate() + 1)
                dtNascimento = dtNascimento.toLocaleDateString()
            }

            if (dtNascimento !== '' && !ValidaData(dtNascimento)) {
                dispatch({ idStore, type: 'INVALIDO', campo: 'dtNascimento', value: true })
                alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            data = {
                CodCliente: cliente.codCli,
                NomeRazao: cliente.nomeRazao,
                Fantasia: cliente.tipoCli === 'J' ? cliente.fantasia : '',
                Email: cliente.email,
                Email2: cliente.email2,
                CpfCnpj: cliente.cpfCnpj,
                TipoCli: cliente.tipoCli,
                TipoContribuinte: cliente.tipoContribuinte,
                Inscest: cliente.tipoCli === 'J' ? cliente.inscest : '',
                RgInscMunic: cliente.rgInscMunic,
                OrgaoEmissor: cliente.tipoCli === 'F' ? cliente.orgaoEmissor : '',
                DtEmissao: cliente.tipoCli === 'F' ? dtEmissao : '',
                Sexo: cliente.tipoCli === 'F' ? cliente.sexo : '',
                DtNascimento: cliente.tipoCli === 'F' ? dtNascimento : '',
                FlReceberEmail: cliente.flReceberEmail,
            }
        } else {
            if (cliente.nomeEstrangeiro === '') {
                dispatch({ idStore, type: 'INVALIDO', campo: 'nomeEstrangeiro', value: true })
                alertar(notificacao, 'br', "Nome Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if (cliente.documentoEstrangeiro === '') {
                dispatch({ idStore, type: 'INVALIDO', campo: 'documentoEstrangeiro', value: true })
                alertar(notificacao, 'br', "Doocumento Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if (!cliente.emailEstrangeiro && !ValidaEmail(cliente.emailEstrangeiro)) {
                dispatch({ idStore, type: 'INVALIDO', campo: 'emailEstrangeiro', value: true })
                alertar(notificacao, 'br', "E-mail  Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }

            data = {
                CodCliente: cliente.codCli,
                NomeRazao: cliente.nomeEstrangeiro,
                Fantasia: null,
                Email: cliente.emailEstrangeiro,
                Email2: null,
                CpfCnpj: cliente.documentoEstrangeiro,
                TipoCli: cliente.tipoCli,
                TipoContribuinte: null,
                Inscest: null,
                RgInscMunic: null,
                OrgaoEmissor: null,
                DtEmissao: null,
                Sexo: cliente.sexo,
                DtNascimento: cliente.dtNascimento,
                FlReceberEmail: cliente.flReceberEmail,
            }
        }
        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/cliente/createCliente`,
            headers: { Authorization: basicAuth },
            data: data
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'CLIENTE', campo: 'codCli', value: response.data.records })
            dispatch({ idStore, type: 'CLIENTE_COPIA' })
            GetEnderecos(notificacao, dispatch, response.data.records)
            return true
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return false
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return false
    }
}

export async function UpdateCliente(notificacao, dispatch, cliente, clienteCopia) {
    try {
        if (JSON.stringify(cliente) === JSON.stringify(clienteCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return true
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        let data;

        if (cliente.tipoCli !== "E") {
            var dtEmissao = cliente.dtEmissao;
            var dtNascimento = cliente.dtNascimento;

            if (!cliente.email && !ValidaEmail(cliente.email)) {
                dispatch({ idStore, type: 'INVALIDO', campo: 'email', value: true })
                alertar(notificacao, 'br', "E-mail Primário Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if (cliente.email2 !== '' && !ValidaEmail(cliente.email2)) {
                dispatch({ idStore, type: 'INVALIDO', campo: 'email2', value: true })
                alertar(notificacao, 'br', "E-mail Secundário Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }

            if (dtEmissao !== '') {
                dtEmissao = new Date(cliente.dtEmissao)
                dtEmissao.setDate(dtEmissao.getDate() + 1)

                dtEmissao = dtEmissao.toLocaleDateString()
            }

            if (dtEmissao !== '' && !ValidaData(dtEmissao)) {
                dispatch({ idStore, type: 'INVALIDO', campo: 'dtEmissao', value: true })
                alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }

            if (dtNascimento !== '') {
                dtNascimento = new Date(cliente.dtNascimento)
                dtNascimento.setDate(dtNascimento.getDate() + 1)
                dtNascimento = dtNascimento.toLocaleDateString()
            }

            if (dtNascimento !== '' && !ValidaData(dtNascimento)) {
                dispatch({ idStore, type: 'INVALIDO', campo: 'dtNascimento', value: true })
                alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            data = {
                email: cliente.email,
                email2: cliente.email2,
                tipoContribuinte: cliente.tipoContribuinte,
                inscest: cliente.tipoCli === 'J' ? cliente.inscest : '',
                rgInscMunic: cliente.rgInscMunic,
                orgaoEmissor: cliente.tipoCli === 'F' ? cliente.orgaoEmissor : '',
                dtEmissao: cliente.tipoCli === 'F' ? dtEmissao : '',
                sexo: cliente.tipoCli === 'F' ? cliente.sexo : '',
                dtNascimento: cliente.tipoCli === 'F' ? dtNascimento : '',
                flReceberEmail: cliente.flReceberEmail
            }
        } else {
            if (!cliente.emailEstrangeiro && !ValidaEmail(cliente.emailEstrangeiro)) {
                dispatch({ idStore, type: 'INVALIDO', campo: 'email', value: true })
                alertar(notificacao, 'br', "E-mail Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }

            data = {
                email: cliente.email,
                email2: null,
                tipoContribuinte: null,
                inscest: null,
                rgInscMunic: null,
                orgaoEmissor: null,
                dtEmissao: null,
                sexo: cliente.sexo,
                dtNascimento: null,
                flReceberEmail: null,
            }
        }

        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/cliente/updateCliente?codCli=${cliente.codCli}`,
            headers: { Authorization: basicAuth },
            data: data
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore: 'CLIENTES', type: 'CLIENTE', campo: 'codCli', value: response.data.records })
            dispatch({ idStore, type: 'CLIENTE_COPIA' })
            return true
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return false
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return false
    }
}

export async function GetCliente(notificacao, dispatch, codCli, thenFunction) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/cliente/cliente?codCli=${codCli}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            var idStore = 'CLIENTES';
            let data
            if (response.data.records.tipoCli === "E") {
                data = {
                    codCli: response.data.records.codCli,
                    nomeRazao: '',
                    fantasia: '',
                    cpfCnpj: '',
                    inscest: '',
                    rgInscMunic: '',
                    orgaoEmissor: '',
                    dtEmissao: '',
                    email: response.data.records.email,
                    email2: '',
                    tipoCli: response.data.records.tipoCli,
                    tipoContribuinte: "0",
                    dtNascimento: '',
                    sexo: response.data.records.sexo,
                    flReceberEmail: 'S',
                    status: 0,
                    nomeEstrangeiro: response.data.records.nomeRazao,
                    documentoEstrangeiro: response.data.records.cpfCnpj,
                    emailEstrangeiro: response.data.records.email
                }

                dispatch({ idStore, type: 'CLIENTE_COMPLETO', value: data })
            } else {
                dispatch({ idStore, type: 'CLIENTE_COMPLETO', value: response.data.records })
            }

            dispatch({ idStore, type: 'CLIENTE_COPIA' })
            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function GetEnderecos(notificacao, dispatch, codCli) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios.get(api.urlCadastro + `api/v1/cliente/enderecos?codCli=${codCli}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            dispatch({ idStore: 'CLIENTES', type: 'ENDERECOS', value: response.data.records === null ? [] : response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e);
    }
}

export function GetEndereco(notificacao, dispatch, codEnd, enderecos) {
    try {
        var idStore = 'CLIENTES';
        var type = 'ENDERECO_COMPLETO';
        var endereco = (enderecos.find(endereco => endereco.codEnd === codEnd));
        endereco = {
            codEnd: endereco.codEnd,
            idPais: endereco.idPais,
            cep: endereco.cep,
            uf: endereco.uf,
            cidade: endereco.cidade,
            bairro: endereco.bairro,
            logradouro: endereco.logradouro,
            numero: endereco.numero,
            complemento: endereco.complemento,
            referencia: endereco.referencia,
            foneRes: endereco.foneRes,
            foneCel: endereco.foneCel,
            flPrincipal: endereco.flPrincipal,
            tipoEnd: endereco.tipoEnd,
            ibge: endereco.ibge,
            gia: endereco.gia,
            codPostal: endereco.cep
        }

        dispatch({ idStore, type, value: endereco })
        dispatch({ idStore, type: 'ENDERECO_COPIA' })

    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function CreateEndereco(notificacao, dispatch, codCli, endereco, enderecoCopia) {
    const { cliente } = store.getState().clientes
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        let data = {};

        if (cliente.tipoCli !== "E") {

            if (JSON.stringify(endereco) === JSON.stringify(enderecoCopia)) {
                alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
                return true
            }
            if ((endereco.cep === '' || !ValidaCep(endereco.cep))) {
                dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'cep', value: true });
                alertar(notificacao, 'br', "CEP Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if (endereco.uf === '') {
                dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'uf', value: true });
                alertar(notificacao, 'br', "UF Inválido ", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if (endereco.cidade === '') {
                dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'cidade', value: true });
                alertar(notificacao, 'br', "Cidade Inválida", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if (endereco.bairro === '') {
                dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'bairro', value: true });
                alertar(notificacao, 'br', "Bairro Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if (endereco.logradouro === '') {
                dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'logradouro', value: true });
                alertar(notificacao, 'br', "Logradouro Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if (endereco.numero === '') {
                dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'numero', value: true });
                alertar(notificacao, 'br', "Número Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            data = {
                Cep: endereco.cep,
                Uf: endereco.uf,
                Cidade: endereco.cidade,
                Bairro: endereco.bairro,
                Logradouro: endereco.logradouro,
                Numero: endereco.numero,
                Complemento: endereco.complemento,
                Referencia: endereco.referencia,
                FoneRes: endereco.foneRes,
                FoneCel: endereco.foneCel,
                TipoEnd: endereco.tipoEnd,
                FlPrincipal: endereco.flPrincipal,
                Ibge: endereco.ibge,
                Gia: endereco.gia,
                IDPais: endereco.idPais
            }

        } else {

            if (endereco.uf === '') {
                dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'uf', value: true });
                alertar(notificacao, 'br', "UF Inválido ", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if (endereco.logradouro === '') {
                dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'logradouro', value: true });
                alertar(notificacao, 'br', "Logradouro Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if (endereco.cidade === '') {
                dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'cidade', value: true });
                alertar(notificacao, 'br', "Cidade Inválida", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }
            if (endereco.bairro === '') {
                dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'bairro', value: true });
                alertar(notificacao, 'br', "Bairro Inválido", 'warning', 'icon-alert-circle-exc', 2);
                return false
            }




            data = {
                Cep: endereco.codPostal,
                Uf: endereco.uf,
                Cidade: endereco.cidade,
                Bairro: endereco.bairro,
                Logradouro: endereco.logradouro,
                Numero: endereco.numero,
                Complemento: endereco.complemento,
                Referencia: endereco.referencia,
                FoneRes: endereco.foneRes,
                FoneCel: endereco.foneCel,
                TipoEnd: endereco.tipoEnd,
                FlPrincipal: endereco.flPrincipal,
                Ibge: endereco.ibge,
                Gia: endereco.gia,
                IDPais: endereco.idPais
            }
        }
        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/cliente/createEndereco?codCli=${codCli}&tipoCli=${cliente.tipoCli}`,
            headers: { Authorization: basicAuth },
            data: data
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            GetEnderecos(notificacao, dispatch, codCli)
            dispatch({ idStore, type: "ENDERECO_COPIA" });
            return true
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return false
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return false
    }
}

export function UpdateEndereco(notificacao, dispatch, codCli, endereco, enderecoCopia) {
    const { cliente } = store.getState().clientes

    try {

        if (JSON.stringify(endereco) === JSON.stringify(enderecoCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return true
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        if ((endereco.cep === '' || !ValidaCep(endereco.cep)) && cliente.tipoCli !== "E") {
            dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'cep', value: true });
            alertar(notificacao, 'br', "CEP Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return false
        }
        if (endereco.uf === '') {
            dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'uf', value: true });
            alertar(notificacao, 'br', "UF Inválido ", 'warning', 'icon-alert-circle-exc', 2);
            return false
        }
        if (endereco.cidade === '') {
            dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'cidade', value: true });
            alertar(notificacao, 'br', "Cidade Inválida", 'warning', 'icon-alert-circle-exc', 2);
            return false
        }
        if (endereco.bairro === '') {
            dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'bairro', value: true });
            alertar(notificacao, 'br', "Bairro Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return false
        }
        if (endereco.logradouro === '') {
            dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'logradouro', value: true });
            alertar(notificacao, 'br', "Logradouro Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return false
        }
        if (endereco.numero === '') {
            dispatch({ idStore: 'CLIENTES', type: 'INVALIDO', campo: 'numero', value: true });
            alertar(notificacao, 'br', "Número Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return false
        }

        axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/cliente/updateEndereco?codCli=${codCli}&codEnd=${endereco.codEnd}`,
            headers: { Authorization: basicAuth },
            data: {
                Cep: cliente.tipoCli !== "E" ? endereco.cep : endereco.codPostal,
                Uf: endereco.uf,
                Cidade: endereco.cidade,
                Bairro: endereco.bairro,
                Logradouro: endereco.logradouro,
                Numero: endereco.numero,
                Complemento: endereco.complemento,
                Referencia: endereco.referencia,
                FoneRes: endereco.foneRes,
                FoneCel: endereco.foneCel,
                TipoEnd: endereco.tipoEnd,
                FlPrincipal: endereco.flPrincipal,
                Ibge: endereco.ibge,
                Gia: endereco.gia,
                IDPais: endereco.idPais
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'codEnd', value: response.data.records })
            dispatch({ idStore, type: 'ENDERECO_COPIA' })
            GetEnderecos(notificacao, dispatch, codCli)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function ExcluirEndereco(notificacao, dispatch, codCli, codEnd) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/cliente/excluirEndereco?codCli=${codCli}&codEnd=${codEnd}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            GetEnderecos(notificacao, dispatch, codCli)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function BuscarCep(notificacao, dispatch, cep) {
    try {
        if (cep.trim() !== '' && cep.trim().length >= 8) {
            var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

            const base = await axios.get(api.urlCadastro + `api/v1/buscaCep/${cep}`, { headers: { Authorization: basicAuth } }
            ).then(response => {
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'uf', value: response.data.records.uf })
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'cidade', value: response.data.records.cidade })
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'logradouro', value: response.data.records.logradouro })
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'bairro', value: response.data.records.bairro })
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'ibge', value: response.data.records.ibge })
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'gia', value: response.data.records.gia })
                return true
            }).catch(e => {
                return false
            });

            if (base) {
                return true
            }

            const viacep = await axios.get(`http://viacep.com.br/ws/${cep}/json/`
            ).then(response => {
                if (response.data.erro) {
                    return false
                }
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'uf', value: response.data.uf })
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'cidade', value: response.data.localidade })
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'logradouro', value: response.data.logradouro })
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'bairro', value: response.data.bairro })
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'ibge', value: response.data.ibge })
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'gia', value: response.data.gia })
                return true
            }).catch(e => {
                return false
            });

            if (viacep) {
                return true
            }

            const postomon = await axios.get(`http://api.postmon.com.br/v1/cep/${cep}`
            ).then(response => {
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'uf', value: response.data.estado })
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'cidade', value: response.data.cidade })
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'logradouro', value: response.data.logradouro })
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'bairro', value: response.data.bairro })
                dispatch({ idStore: 'CLIENTES', type: 'ENDERECO', campo: 'ibge', value: response.data.cidade_info.codigo_ibge })
                return true
            }).catch(e => {
                return false
            });

            if (postomon) {
                return true
            }
            alertar(notificacao, 'br', "CEP não encontrado", 'warning', 'icon-alert-circle-exc', 2)

        } else {
            alertar(notificacao, 'br', "CEP inválido", 'warning', 'icon-alert-circle-exc', 2)
        }
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter o endereço", 'warning', 'icon-alert-circle-exc')
        return false
    }
}



export const GetPaises = () => {
    const { notificacao } = store.getState()
    let dispatch = store.dispatch
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));


        axios({
            method: 'get',
            url: api.urlCadastro + `api/v1/cliente/GetPaises`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            if (response.data) {
                let paises = response.data.records

                let selectPais = paises.map((pais) => {
                    return {
                        value: pais.idPais,
                        label: `${pais.codigo} - ${pais.pais}`
                    }
                })

                dispatch({ idStore, type: 'PAISES', value: selectPais })
                dispatch({ idStore, type: 'INIT_PAISES', value: paises })



            }
            return true

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return false

        });

    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter o endereço", 'warning', 'icon-alert-circle-exc')
        return false
    }

}