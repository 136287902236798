import React, { useEffect } from "react";

import {
    Card,
    Col,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Label,
    Input
} from "reactstrap";

import Shortcut from './Tabs/Shortcut/Shortcut';
import TabContent from "./TabContent/TabContent";
import useShallowEqualSelector from '../../Util/Hooks/useShallowEqualSelector';
import * as controllerConfiguracao from '../../controller/controllerConfiguracao';
import { IConfiguracaoState } from "../../store/reducers/Configuracao/types";
import AppState from "../../store/storeType";
import { maskCPF } from '../../Util/mascaras'
import './style.css'

const Settings = () => {

    const user = useShallowEqualSelector<AppState, IConfiguracaoState['user']>(state => state.configuracao.user)

    const Tabs = [

        {
            tabName: "ATALHOS",
            tabScreen: <Shortcut />
        }

    ]
    useEffect(() => {

        controllerConfiguracao.getInformationInit()

    })
    return <>
        <div className="content">
            <Col>
                <Card>
                    <CardHeader>

                        <CardTitle tag="h2">Configurações</CardTitle>
                        <Row className='rowCenterItens padding-padrao-row' >
                            <Col className="padding-padrao-col" lg='6'>
                                <Label style={{ fontSize: '0.75rem' }}>Nome</Label>
                                <Input
                                    name="Nome"
                                    disabled={true}
                                    maxlength="90"
                                    type="text"
                                    class="form-control"
                                    value={user.nome}></Input>
                            </Col>
                            <Col className="padding-padrao-col" lg='3'>
                                <Label style={{ fontSize: '0.9rem' }}>CPF</Label>
                                <Input
                                    name="CPF"
                                    disabled={true}
                                    maxlength="30"
                                    type="text"
                                    class="form-control"
                                    value={maskCPF(user.cpf)}></Input>
                            </Col>
                            <Col className="padding-padrao-col" lg='3'>
                                <Label style={{ fontSize: '0.9rem' }}>Grupo</Label>
                                <Input
                                    name="GRUPO"
                                    disabled={true}
                                    maxlength="90"
                                    type="text"
                                    class="form-control"
                                    value={user.grupo}></Input>
                            </Col>
                        </Row>
                        <Row>

                            <CardBody>

                                <TabContent tabs={Tabs} />

                            </CardBody>
                        </Row>
                    </CardHeader>
                </Card>
            </Col>


        </div>



    </>



}


export default Settings