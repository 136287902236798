import { Cliente, Item } from "../../../store/reducers/Movimentacao/PedidoVenda/types"

export const formats = {
    total: { places: 15, decimal: 2 },
    subtotal: { places: 15, decimal: 2 },
    precoVendaAtual: { places: 15, decimal: 2 },
    descontoPercent: { places: 7, decimal: 2 },
    descontoReais: { places: 15, decimal: 2 },
    quantidade: { places: 10, decimal: 0 },
}

export const formatsTotais = {
    total: { places: 15, decimal: 2 },
    subtotal: { places: 15, decimal: 2 },
    desconto: { places: 15, decimal: 2 },
}

export const produtoInitial: Item = {
    item: 0,
    codProduto: 0,
    imagem: '',
    numProduto: 0,
    codBarras: '',
    nome: '',
    precoVendaAtual: `0,${'0'.repeat(formats.precoVendaAtual.decimal)}`,
    quantidade: `1${formats.quantidade.decimal ? ',' + '1'.repeat(formats.descontoReais.decimal) : ''}`,
    descontoReais: `0,${'0'.repeat(formats.descontoReais.decimal)}`,
    descontoPercent: `0,${'0'.repeat(formats.descontoPercent.decimal)}`,
    subtotal: `0,${'0'.repeat(formats.subtotal.decimal)}`,
    total: `0,${'0'.repeat(formats.total.decimal)}`,
    flControlaGrade: 0,
}

export const clienteInitial: Cliente = {
    codCli: 0,
    cpfCnpj: '',
    nomeRazao: '',
    tipoCli: '',
}