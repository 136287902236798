import React, { useEffect, ChangeEvent } from 'react';

import './style.css'

import {
    Col,
    Row,
    Input
} from 'reactstrap';

import useShallowEqualSelector from '../../../Util/Hooks/useShallowEqualSelector';
import AppState from '../../../store/storeType';
import { IFaturamentoState } from '../../../store/reducers/Modals/Faturamento/types';

import * as functions from './functions';
import * as action from '../../../store/reducers/Modals/Faturamento/actions';
import { maskMoeda } from '../../../Util/mascaras';

export default function Totais() {
    const totais = useShallowEqualSelector<AppState, IFaturamentoState['totais']>(state => state.modalFaturamento.totais)
    const lancamento = useShallowEqualSelector<AppState, IFaturamentoState['lancamento']>(state => state.modalFaturamento.lancamento)
    const pagamentoSelecionado = useShallowEqualSelector<AppState, IFaturamentoState['pagamentoSelecionado']>(state => state.modalFaturamento.pagamentoSelecionado)

    useEffect(() => {
        functions.calculaTotais();
    }, [totais.subTotal])

    return (
        <Col md={3} id="faturamentoTotais-container">
            <Row className="faturamentoTotais-itens" >
                <Col>
                    <label>TOTAL RESTANTE</label>
                    <Input
                        type="text"
                        disabled={true}
                        value={maskMoeda(totais.totalRestante)}
                        className={"input-faturamento"}
                    />
                </Col>
            </Row>

            <Row className="faturamentoTotais-itens">
                <Col>
                    <label>SUB TOTAL</label>
                    <Input
                        type="text"
                        disabled={true}
                        value={maskMoeda(totais.subTotal)}
                        className={"input-faturamento"}
                    />
                </Col>
            </Row>

            <Row className="faturamentoTotais-itens">
                <Col>
                    <label>DESCONTOS</label>
                    <Input
                        type="text"
                        disabled={true}
                        value={maskMoeda(totais.descontos)}
                        className={"input-faturamento"}
                    />
                </Col>
            </Row>

            <Row className="faturamentoTotais-itens">
                <Col>
                    <label>TOTAL</label>
                    <Input
                        type="text"
                        disabled={true}
                        value={maskMoeda(totais.total)}
                        className={"input-faturamento"}
                    />
                </Col>
            </Row>

            <Row className="faturamentoTotais-itens">
                <Col>
                    <label>TOTAL LANÇADO</label>
                    <Input
                        type="text"
                        disabled={true}
                        value={(totais.totalLancado)}
                        className={"input-faturamento"}
                    />
                </Col>
            </Row>

            <Row className="faturamentoTotais-itens">
                <Col>
                    <label>TROCO</label>
                    <Input
                        type="text"
                        disabled={true}
                        value={totais.troco}
                        className={"input-faturamento text-red"}
                    />
                </Col>
            </Row>

        </Col>
    )
}