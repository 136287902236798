import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'

import { Select } from "~/components/ErnComponets/ErnComponets";
import { ActionsStep } from "../Cadastro"

import {
    Row,
    Col,
    CardBody,
    CardFooter,
    FormGroup,
    Label,
    Input,
} from "reactstrap";

const estados = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']
const idStore = "CLASSIFICACOES_TRIBUTARIAS";

const Geral = () => {
    const dispatch = useDispatch();
    const { tributacao, verCadastro } = useSelector(state => state.classificacoesTributarias);
    const [todosEstados, settodosEstados] = useState(false)
    const { CFOPs } = useSelector(state => state.classificacoesTributarias);

    useEffect(() => {
        if (tributacao.uf.length === estados.length)
            settodosEstados(true)
        else
            settodosEstados(false)
    }, [tributacao.uf])

    function handleOnChange(value, campo) {
        dispatch({ idStore, type: 'TRIBUTACAO', campo, value });
    }

    function changeTodosEstados() {
        if (!todosEstados)
            dispatch({ idStore, type: 'TRIBUTACAO', campo: 'uf', value: estados });
        else
            dispatch({ idStore, type: 'TRIBUTACAO', campo: 'uf', value: [] });
        settodosEstados(!todosEstados)
    }

    function changeEstado(estado) {
        let newEstados = []
        let stringEstados = JSON.stringify(tributacao.uf)
        newEstados = [...JSON.parse(stringEstados)]
        let index = newEstados.findIndex(uf => uf === estado)
        if (index !== -1) {
            newEstados.splice(index, 1)
            dispatch({ idStore, type: 'TRIBUTACAO', campo: 'uf', value: newEstados });
        } else {
            newEstados.push(estado)
            dispatch({ idStore, type: 'TRIBUTACAO', campo: 'uf', value: newEstados });
        }
    }

    return (
        <Row style={{ marginTop: '-70px' }}>
                  <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

                </div>
            <Col>
                <CardBody>
                    <Row className="justify-content-center">

                        <Col xm="12">

                            <Row className="justify-content-center">

                                <Col lg>
                                    <label>Estados: </label>
                                    <div className="box box-estados">
                                        <FormGroup check className="box-estados-item">
                                            <Col style={{ width: '130px' }}>
                                                <Label check>
                                                    <Input type="checkbox"
                                                        onChange={changeTodosEstados}
                                                        checked={todosEstados}
                                                        disabled={ verCadastro}
                                                    />
                                                    <span className="form-check-sign" />Todos
                                        </Label>
                                            </Col>
                                        </FormGroup>
                                        {estados.map((estado, key) => (
                                            <FormGroup check key={key} className="box-estados-item">
                                                <Col style={{ width: '130px' }}>
                                                    <Label check>
                                                        <Input type="checkbox"
                                                            onChange={() => changeEstado(estado)}
                                                            checked={tributacao.uf.find(uf => uf === estado) ? true : false}
                                                            disabled={ verCadastro}
                                                        />
                                                        <span className="form-check-sign" />{estado}
                                                    </Label>
                                                </Col>
                                            </FormGroup>
                                        ))}
                                    </div>
                                </Col>

                            </Row>

                            <br />
                            <br />

                                <Row className="justify-content-center">
                                    <Col>
                                        <label>CFOP NF-e/NFC-e</label>
                                        <Select
                                            first="Selecione"
                                            value={{ value: tributacao.codCFOPNFE }}
                                            select={{ value: "codCfop", label: "descricaoFormatada" }}
                                            onChange={value => handleOnChange(value.value, 'codCFOPNFE')}
                                            options={CFOPs}
                                            isSearchable={true}
                                            autoComplete="off"
                                        />
                                    </Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col>
                                        <label>CFOP Cupom Fiscal/S@t</label>
                                        <Select
                                            first="Selecione"
                                            value={{ value: tributacao.codCFOPCF }}
                                            select={{ value: "codCfop", label: "descricaoFormatada" }}
                                            onChange={value => handleOnChange(value.value, 'codCFOPCF')}
                                            options={CFOPs}
                                            isSearchable={true}
                                            autoComplete="off"                                         
                                        />
                                    </Col>
                                </Row>

                          
                        </Col>

                    </Row>

                </CardBody>
                <CardFooter className="text-center">
                    <ActionsStep />
                </CardFooter>
            </Col>
        </Row>
    )
};

class Wizard extends React.Component {
    focusInput = () => {
    }

    render() {
        return (
            <Geral />
        )
    }
}

export default Wizard;
