import React from "react";

import ReactTable from "react-table";

import {
  Row,
  Col,
  CardBody,
  Button
} from "reactstrap";

import AppState from "../../../../store/storeType";

import ModalFaturamento from '../../../../components/Modals/Faturamento'

import useShallowEqualSelector from "../../../../Util/Hooks/useShallowEqualSelector";
import useModal from "../../../../Util/Hooks/useModal";
import useResize from "../../../../Util/Hooks/useResize";

import * as controllerSaida from '../../../../controller/Movimentacao/controllerSaidaDiversa'

import { Produto, Destinatario, Transportadora } from "../../../../store/reducers/Movimentacao/SaidaDiversa/types";
import { OperacaoBox } from "../Components/index";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

const ResumoEnvio = () => {
  const idFatura = useShallowEqualSelector<AppState, number>(state => state.saidaDiversa.saida.idFatura);
  const destinatario = useShallowEqualSelector<AppState, Destinatario>(state => state.saidaDiversa.saida.destinatario);
  const transportadora = useShallowEqualSelector<AppState, Transportadora>(state => state.saidaDiversa.saida.transportadora);
  const produtos = useShallowEqualSelector<AppState, Produto[]>(state => state.saidaDiversa.saida.produtos);
  const dadosAdicionais = useShallowEqualSelector<AppState, string>(state => state.saidaDiversa.saida.dadosAdicionais);
  const emiteNFe = useShallowEqualSelector<AppState, Boolean>(state => state.saidaDiversa.emiteNFe);

  const [modalFaturamento, toogleFaturamento] = useModal(false)

  const { width } = useResize();

  async function Salvar() {
    controllerSaida.Salvar('Resumo / Envio')
  };

  function Limpar() {

  };

  return (
    <>
      <Row className="justify-content-center">
        <Col>

          <h4 style={{ textAlign: 'center' }}>Confirme os dados informados e clique em GERAR NF-E</h4>

            <Row >
              <Col lg={width >= 1660 ? 7 : 12} style={width <= 1660 ? { display: 'flex', justifyContent: 'center' } : {}} >
                <OperacaoBox onIconPress={() => { }} disabledButton={true} />
              </Col>

              <Col lg={width >= 1660 ? 5 : 12} >

                <Row style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%', marginTop: 6 }} >
                  <Button
                    type={"button"}
                    color={"info"}
                    onClick={() => controllerSaida.handleNovo()}
                    style={{minWidth: 100}}
                  >
                    <Icon icon="plus-circle" className="fa-lg" color="#FFF" pull="left" />{" "}
                    NOVO
                  </Button>
                  <Button
                    type={"button"}
                    color={"yellow"}
                    onClick={() => Salvar()}
                    disabled={!idFatura ? false : true}
                    style={{minWidth: 100}}
                  >
                    <Icon icon="save" className="fa-lg" color="#FFF" pull="left" />
                    Salvar
                  </Button>
                  <Button
                    type={"button"}
                    color={"green"}
                    onClick={() => controllerSaida.Finalizar(toogleFaturamento)}
                    disabled={!idFatura ? false : true}
                    style={{minWidth: 100}}
                  >
                    <Icon icon="dollar-sign" className="fa-lg" color="#FFF" pull="left" />{" "}
                    FINALIZAR
                  </Button>
                </Row>
                
              </Col>

            </Row>

          <CardBody>

            <Row className="justify-content-center">

              <Col md="12">

                {emiteNFe &&
                  <Row>
                    <Col md="12">
                      <fieldset style={{ border: 'solid 2px #5E6680', borderRadius: 5, width: '100%' }}>
                        <legend
                          style={{ fontSize: '14px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }}
                        >
                          DADOS NF-E
                    </legend>
                        <Row style={{ justifyContent: 'space-around' }}>
                          <div>
                            <p>Número</p>
                            <p style={{ fontSize: 16 }}>TESTE</p>
                          </div>
                          <div>
                            <p>Chave</p>
                            <p style={{ fontSize: 16 }}>XXXXXXXXXXXXXXXXXXXXXXXXXXX</p>
                          </div>
                          <div>
                            <p>Série</p>
                            <p style={{ fontSize: 16 }}>XXX</p>
                          </div>
                          <div>
                            <p>Data Emissão</p>
                            <p style={{ fontSize: 16 }}>27/01/2020</p>
                          </div>
                        </Row>

                      </fieldset>
                    </Col>
                  </Row>
                }

                <br />

                <Row style={{ justifyContent: 'flex-end' }}>
                  <Col md="12">
                    <fieldset style={{ border: 'solid 2px #5E6680', borderRadius: 5, width: '100%' }}>
                      <legend
                        style={{ fontSize: '14px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }}
                      >
                        DESTINATÁRIO / REMETENTE
                    </legend>
                      {
                        destinatario.codigo === 0 ?
                          <Row style={{ justifyContent: 'center' }}>
                            <h4>Nenhum Destinátario Informado</h4>
                          </Row>
                          :
                          <Row style={{ justifyContent: 'space-around' }}>
                            <div>
                              <p>Nome / Razão Social</p>
                              <p style={{ fontSize: 16 }}>{destinatario.razaoSocial}</p>
                            </div>
                            <div>
                              <p>CNPJ / CPF</p>
                              <p style={{ fontSize: 16 }}>{destinatario.documento}</p>
                            </div>
                            <div>
                              <p>IE</p>
                              <p style={{ fontSize: 16 }}>{destinatario.documento}</p>
                            </div>
                          </Row>
                      }

                    </fieldset>
                  </Col>
                </Row>

                <br />

                {transportadora.razaoSocial &&
                  <>
                    <Row style={{ justifyContent: 'flex-end' }}>
                      <Col md="12">
                        <fieldset style={{ border: 'solid 2px #5E6680', borderRadius: 5, width: '100%' }}>
                          <legend
                            style={{ fontSize: '14px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }}
                          >
                            TRANSPORTADORA
                    </legend>
                          <Row style={{ justifyContent: 'space-around' }}>
                            <div>
                              <p>Nome / Razão Social</p>
                              <p style={{ fontSize: 16 }}>{transportadora.razaoSocial}</p>
                            </div>
                            <div>
                              <p>CNPJ / CPF</p>
                              <p style={{ fontSize: 16 }}>{transportadora.documento}</p>
                            </div>
                            <div>
                              <p>IE</p>
                              <p style={{ fontSize: 16 }}>{transportadora.documento}</p>
                            </div>
                          </Row>
                        </fieldset>
                      </Col>
                    </Row>

                    <br />
                  </>
                }



                <Row style={{ justifyContent: 'flex-end' }}>
                  <Col md="12">
                    <fieldset style={{ border: 'solid 2px #5E6680', borderRadius: 5, width: '100%' }}>
                      <legend
                        style={{ fontSize: '14px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }}
                      >
                        PRODUTOS
                    </legend>
                      {
                        produtos.length === 0 ?
                          <Row style={{ justifyContent: 'center' }}>
                            <h4>Nenhum Produto Informado</h4>
                          </Row>
                          :
                          <div style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', padding: 10 }} >
                            <ReactTable
                              data={produtos.filter(produto => produto.status == 0)}
                              NoDataComponent={() => (<></>)}
                              filterable={false}
                              columns={
                                [
                                  {
                                    Header: "Número / Código Produto",
                                    accessor: "numero"
                                  },
                                  {
                                    Header: "Descrição",
                                    accessor: "descricao"
                                  },
                                  {
                                    Header: "Valor Unitário",
                                    accessor: "valorUnitario"
                                  },
                                  {
                                    Header: "Quantidade",
                                    accessor: "quantidade"
                                  },
                                  {
                                    Header: "Valor Total",
                                    accessor: "valorTotal"
                                  }
                                ]
                              }
                              minRows={1}
                              showPaginationTop={false}
                              className="-striped -highlight"
                              noDataText=""
                              showPaginationBottom={false}
                              showPageSizeOptions={true}
                            />
                          </div>
                      }
                    </fieldset>
                  </Col>
                </Row>

                <br />

                {dadosAdicionais &&
                  <Row style={{ justifyContent: 'flex-end' }}>
                    <Col md="12">
                      <fieldset style={{ border: 'solid 2px #5E6680', borderRadius: 5, width: '100%' }}>
                        <legend
                          style={{ fontSize: '14px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }}
                        >
                          INFORMAÇÕES ADICIONAIS
                    </legend>
                        <div style={{ padding: 10 }}>
                          <p style={{ fontSize: 16 }}>{dadosAdicionais}</p>
                        </div>
                      </fieldset>
                    </Col>
                  </Row>
                }
              </Col>
            </Row>

          </CardBody>
        </Col>
      </Row>

      {
        modalFaturamento ? <ModalFaturamento isOpen={true} toogle={toogleFaturamento} /> : null
      }

    </>
  )
};

class Wizard extends React.Component {
  render() {
    return (
      <ResumoEnvio />
    )
  }
}

export default Wizard;
