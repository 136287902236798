import axios from 'axios';
import api from "../Util/api.js";
import { alertar } from "../Util/alertar";
import store from '../store/stores';
import * as actions from '../store/reducers/Cadastro/Operacao/actions'
import { Operacao } from '../store/reducers/Cadastro/Operacao/types'

const idStore = 'Operacao';


export function Pesquisar(pesquisa: any) {
    //@ts-ignore
    const { notificacao } = store.getState()

    try {

        actions.cleanOperacoes();

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/operacao/operacoes?&Numero=${pesquisa.numero}&Descricao=${pesquisa.descricao}&Tipo=${pesquisa.tipo === '0' ? '' : pesquisa.tipo}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma Operação encontrada", 'info', 'icon-bulb-63', 2)
            }
            actions.setOperacoes(response.data.records)

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as operações", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetOperacao(codOperacao: any) {
    //@ts-ignore
    const { notificacao } = store.getState()

    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/operacao/operacao?&codigo=${codOperacao}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            let Operacao = response.data.records

            let OperacaoCopia: Operacao = {
                codigo: Operacao.codigo,
                numero: Operacao.numero,
                descricao: Operacao.descricao,
                nfe: Operacao.nfe,
                conta: Operacao.conta,
                estoque: Operacao.estoque,
                tipo: Operacao.tipo,
                caracteristicas: Number(Operacao.caracteristicas),
                ativo: Operacao.ativo,
                update: true
            }

            actions.setOperacao(OperacaoCopia)
            actions.setOperacaoCopia(OperacaoCopia)
            actions.setScreen(1)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}


export async function createTipoOperacao(operacao: any) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        const { invalido } = store.getState().operacao

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        if (operacao.numero === '') {
            actions.setInvalido({ ...invalido, numero: true })
            return false
        }
        if (operacao.descricao === '') {
            actions.setInvalido({ ...invalido, descricao: true })
            return false

        }
        if (operacao.tipo === '0') {
            actions.setInvalido({ ...invalido, tipo: true })
            alertar(notificacao, 'br', "Selecione o Tipo", 'info', 'icon-bulb-63', 2);
            return false

        }
        if (operacao.caracteristicas.toString() === '0') {
            actions.setInvalido({ ...invalido, caracteristicas: true })
            alertar(notificacao, 'br', "Selecione a característica", 'info', 'icon-bulb-63', 2);
            return false

        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/operacao/createOperacao`,
            headers: { Authorization: basicAuth },
            data: {
                Codigo: operacao.codigo,
                Numero: operacao.numero.toString(),
                Descricao: operacao.descricao,
                Tipo: operacao.tipo,
                Nfe: operacao.nfe,
                Conta: operacao.conta,
                Estoque: operacao.estoque,
                Caracteristicas: operacao.caracteristicas.toString(),
                Status: operacao.ativo
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            actions.setOperacao({ ...operacao, codigo: response.data.records })

            actions.setOperacaoCopia({ ...operacao, codigo: response.data.records })
            return true

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return false
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return false
    }
}


export async function Excluir(codOperacao: any, theFunction: any) {
    //@ts-ignore
    const { notificacao } = store.getState()
    const { pesquisa } = store.getState().operacao
    try {

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/operacao/excluirOperacao?&codigo=${codOperacao}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            theFunction()
            Pesquisar(pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            theFunction()
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        theFunction()
    }
}

export async function UpdateOperacao(operacao: any) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        const { invalido, copiaOperacao } = store.getState().operacao

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));


        if (JSON.stringify(copiaOperacao) === JSON.stringify(operacao)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        if (operacao.numero === '') {
            actions.setInvalido({ ...invalido, numero: true })
            return false

        }
        if (operacao.tipo === '0') {
            actions.setInvalido({ ...invalido, tipo: true })
            alertar(notificacao, 'br', "Selecione o tipo", 'info', 'icon-bulb-63', 2);
            return false

        }
        if (operacao.descricao === '') {
            actions.setInvalido({ ...invalido, descricao: true })
            return false

        }
        if (operacao.caracteristicas === '0') {
            actions.setInvalido({ ...invalido, numero: true })
            alertar(notificacao, 'br', "Selecione a característica", 'info', 'icon-bulb-63', 2);
            return false

        }

        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/operacao/updateOperacao?codigo=${operacao.codigo}`,
            headers: { Authorization: basicAuth },
            data: {
                Numero: operacao.numero.toString(),
                Codigo: operacao.codigo,
                Descricao: operacao.descricao,
                Tipo: operacao.tipo,
                Caracteristicas: operacao.caracteristicas.toString(),
                NFE: operacao.nfe,
                Conta: operacao.conta,
                Estoque: operacao.estoque,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            actions.setOperacao({ ...operacao, numero: response.data.records })
            actions.setOperacaoCopia(operacao)
            return true

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return false
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return false
    }


}

export function GetCaracteristicas() {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/operacao/caracteristicas`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            var records = []
            records = response.data.records.map((record: any) => {
                record.descricao = `${record.codigo < 10 ? '0' + record.codigo : record.codigo} - ${record.descricao}`
                return record
            });
            actions.setCaracteristica(records)
        })
    } catch (e) {
        return
    }
}
