import React from 'react';
import { Button, Col, Row } from 'reactstrap';

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import { Input } from '../Components';

import useShallowEqualSelector from "../../../../Util/Hooks/useShallowEqualSelector";

export default function ModalImpostos({ isOpen, toggle }) {
    const valoresTotais = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.itens.valoresTotais.impostos);

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title='Impostos Totais'
        >
            <ModalBasicBody>

                <Row>
                        <Col>
                        <label>BC do ICMS</label>
                        <Input
                            type="text"
                            value={valoresTotais.BcICMS}
                            autoComplete="off"
                            maxLength="18"
                            disabled={true}
                        />
                        </Col>
                </Row>
                <Row>
                        <Col>
                        <label>Valor do ICMS</label>
                        <Input
                            type="text"
                            value={valoresTotais.ICMS}
                            autoComplete="off"
                            maxLength="18"
                            disabled={true}
                        />
                        </Col>
                </Row>
                <Row>
                        <Col>
                        <label>Valor do ICMS ST</label>
                        <Input
                            type="text"
                            value={valoresTotais.ICMSST}
                            autoComplete="off"
                            maxLength="18"
                            disabled={true}
                        />
                        </Col>
                </Row>
                <Row>
                        <Col>
                        <label>Valor do IPI</label>
                        <Input
                            type="text"
                            value={valoresTotais.IPI}
                            autoComplete="off"
                            maxLength="18"
                            disabled={true}
                        />
                        </Col>
                </Row>
                <Row>
                        <Col>
                        <label>Valor do PIS</label>
                        <Input
                            type="text"
                            value={valoresTotais.PIS}
                            autoComplete="off"
                            maxLength="18"
                            disabled={true}
                        />
                        </Col>
                </Row>
                <Row>
                        <Col>
                        <label>Valor do COFINS</label>
                        <Input
                            type="text"
                            value={valoresTotais.COFINS}
                            autoComplete="off"
                            maxLength="18"
                            disabled={true}
                        />
                        </Col>
                </Row>
                <Row>
                        <Col>
                        <label>Valor do COFINS ST</label>
                        <Input
                            type="text"
                            value={valoresTotais.COFINSST}
                            autoComplete="off"
                            maxLength="18"
                            disabled={true}
                        />
                        </Col>
                </Row>
                
            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        onClick={() => toggle()}
                        color={"info"}
                    >VOLTAR
                    </Button>
                </Col>
            </ModalBasicFooter>

        </ModalBasic>
    )
}