import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as controllerProdutosDescontinuados from "~/controller/controllerProdutosDescontinuados";

import ReactTable from "react-table";
import ModalDescontinuado from './Modals/ModalDescontinuado'
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import {
    Card,
    Row,
    Col,
    Button,
    CardHeader,
    CardBody,
    CardTitle
} from "reactstrap";

import useModal from "../../Util/Hooks/useModal";

const idStore = 'PRODUTOS_DESCONTINUADOS';

const produtosDescontinuados = () => {
    const descontinuados = useSelector(state => state.produtosDescontinuados.descontinuados, shallowEqual);
    const produtosSelecionados = useSelector(state => state.produtosDescontinuados.produtosSelecionados, shallowEqual)
    const produtoSelecionado = useSelector(state => state.produtosDescontinuados.produtoSelecionado, shallowEqual)
    const [modalDescontinuado, toogleModal] = useModal(false)
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            controllerProdutosDescontinuados.Pesquisar(produtosSelecionados);
        }, 200);

    }, [!produtosSelecionados])

    function seleciona(props) {
        dispatch({ idStore, type: 'PRODUTO_SELECIONADO', value: props })
        toogleModal()
        return
    }

    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="11">
                    <Card>

                        <CardHeader className="text-center">
                            <CardTitle tag="h3">Produtos com NCM descontinuado   </CardTitle>
                        </CardHeader>


                        <Row>
                            <CardBody>
                                <Col style={{ width: '100%' }}>
                                    <Button
                                        color='info'
                                        style={{ float: 'right', width: '150px' }}
                                        onClick={e => controllerProdutosDescontinuados.Pesquisar(produtosSelecionados)}
                                    >Pesquisar</Button>
                                </Col>
                            </CardBody>
                        </Row>

                        <CardBody>
                            <div style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', border: '1px solid', borderRadius: 5 }}>
                                {
                                    descontinuados.length === 0 ?
                                        <>
                                            <br />
                                            <h4 style={{ textAlign: 'center' }}>
                                                Nenhum Produto descontinuado encontrado
                                            </h4>
                                        </>
                                        :
                                        <ReactTable
                                            data={descontinuados}
                                            NoDataComponent={() => (<></>)}
                                            columns={[
                                                {
                                                    Header: "Número Produto",
                                                    accessor: "numProduto"
                                                },
                                                {
                                                    Header: "CÓDIGO DE BARRAS",
                                                    accessor: "codBarra"
                                                },
                                                {
                                                    Header: "DESCRIÇÃO",
                                                    accessor: "descricao"
                                                },
                                                {
                                                    Header: "NCM",
                                                    accessor: "ncm"
                                                }, {
                                                    sortable: false,
                                                    filterable: false,
                                                    width: 120,
                                                    Cell: (props) => (
                                                        <>
                                                            <ArrowTooltip title="Editar">
                                                                <Button
                                                                    onClick={() => seleciona(props.original)}
                                                                    size="sm"
                                                                    className={"btn-icon btn-link"}
                                                                    color="success"
                                                                >
                                                                    <Icon icon="edit" className="fa-lg" />
                                                                </Button>
                                                            </ArrowTooltip>
                                                        </>
                                                    )
                                                }]}
                                            showPaginationTop={false}
                                            showPaginationBottom={false}
                                            className="-striped -highlight"
                                            noDataText=""
                                            pageSize={descontinuados.length}
                                        />
                                }

                            </div>
                        </CardBody>
                    </Card>
                </Col>
                {/* verifica se tem algum produto descontinuado selecionado antes de chamar a modal*/}
                {
                    modalDescontinuado && produtoSelecionado ?
                        <ModalDescontinuado isOpen={modalDescontinuado} toggle={toogleModal} />
                        :
                        null
                }
            </div>
        </>
    );

}

export default produtosDescontinuados;