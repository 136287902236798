import React, { useEffect } from "react";
import ReactTable from "react-table";

import { Button } from "reactstrap";

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import AppState from '../../../../../store/storeType';

import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";

import ArrowTooltip from '../../../../../components/Tooltip/ArrowTooltip';
import { setProduto, addProduto } from "../../../../../store/reducers/Movimentacao/SaidaDiversa/actions";
import { Produto } from "../../../../../store/reducers/Movimentacao/SaidaDiversa/types";
import classNames from "classnames";
import * as actions from "../../../../../store/reducers/Movimentacao/SaidaDiversa/actions"
import { ToNumber, Sum, Round, Subtract } from "../../../../../Util/numbers";

const RenderTable = function () {
    const listProdutos = useShallowEqualSelector<AppState, any[]>(state => state.saidaDiversa.saida.produtos)
    const idFatura = useShallowEqualSelector<AppState, number>(state => state.saidaDiversa.saida.idFatura);
    const valorTotal = useShallowEqualSelector<AppState, string>(state => state.saidaDiversa.saida.valorTotal);

    function excluirItem(index: number) {
        let stringList = JSON.stringify(listProdutos);

        let list: Produto[] = JSON.parse(stringList);

        let total = Subtract([ToNumber(valorTotal), ToNumber(list[index].valorTotal)])

        actions.setValorTotal('valorSubtotal', Round(total, 2))
        actions.setValorTotal('valorTotal', Round(total, 2))

        if (list[index].numeroPedidoItem === 0)
            list.splice(index, 1);
        else
            list[index].status = 1

        addProduto(list)
    }

    function editarItem(index: number) {
        const produto = listProdutos[index]

        setProduto(produto);
    }

    function editarGrades(index: number) {
        actions.setGradeSelecionada({ produto: listProdutos[index], index })
        toggleModalProdutoGrades()
    }

    function toggleModalProdutoGrades() {
        actions.setModalProdutoGrades(true)
    }

    return (
        <>
            <div style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' }} >
                <ReactTable
                    data={listProdutos.filter(produto => produto.status === 0)}
                    filterable={false}
                    NoDataComponent={() => (<></>)}
                    columns={
                        [
                            {
                                Header: <div className="text-center">Item</div>,
                                accessor: "numero",
                                width: 100,
                                className: "text-center",
                                Cell: (props) => props.index + 1
                            },
                            {
                                Header: "Número / Cód. Barras",
                                accessor: "numero",
                                width: 185
                            },
                            {
                                Header: "Descrição",
                                accessor: "descricao"
                            },
                            {
                                Header: "Valor Unitário",
                                accessor: "valorUnitario",
                                width: 130
                            },
                            {
                                Header: "Quantidade",
                                accessor: "quantidade",
                                width: 110
                            },
                            {
                                Header: "Valor Total",
                                accessor: "valorTotal",
                                width: 130
                            },
                            {
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                width: 110,
                                Cell: (props) => (
                                    <div style={{ display: 'flex' }}>
                                        <ArrowTooltip title="Grade">
                                            <Button
                                                onClick={() => editarGrades(props.index)}
                                                size="sm"
                                                className={classNames("btn-icon btn-link")}
                                                color="info"
                                                disabled={props.original.flControlaGrade === 0 ? true : false}
                                            >
                                                <Icon icon="th" className="fa-lg" />
                                            </Button>
                                        </ArrowTooltip>

                                        <ArrowTooltip title="Editar">
                                            <Button
                                                onClick={() => editarItem(props.index)}
                                                size="sm"
                                                className={"btn-icon btn-link"}
                                                color="success"
                                                disabled={idFatura === 0 ? false : true}
                                            >
                                                <Icon icon="edit" className="fa-lg" />
                                            </Button>
                                        </ArrowTooltip>

                                        <ArrowTooltip title="Excluir">
                                            <Button
                                                onClick={() => excluirItem(props.index)}
                                                className="btn-icon btn-link"
                                                color="danger"
                                                disabled={idFatura === 0 ? false : true}
                                                style={{marginTop: -1}}
                                            >
                                                <Icon icon="trash-alt" className="fa-lg" />
                                            </Button>
                                        </ArrowTooltip>
                                    </div>
                                )
                            }
                        ]
                    }
                    minRows={1}
                    showPaginationTop={false}
                    className="-striped -highlight"
                    noDataText=""
                    showPaginationBottom={false}
                    showPageSizeOptions={true}
                    pageSize={listProdutos.length}
                />
            </div>
        </>
    )
}

export default RenderTable