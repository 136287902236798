import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'


import { ActionsStep } from "../Cadastro"

import {
    Row,
    Col,
    CardBody,
    CardFooter,
} from "reactstrap";

const Resumo = () => {
    const { tributacao, CFOPs, ICMSs, PISCOFINSs, IPIs, enquadramentos } = useSelector(state => state.classificacoesTributarias);
    const [IPI, setIPI] = useState('-')
    const [CFOPNFE, setCFOPNFE] = useState('-')
    const [CFOPCF, setCFOPCF] = useState('-')
    const [PISCOFINS, setPISCOFINS] = useState('-')
    const [ICMS, setICMS] = useState('-')
    const [EnquadramentoIPI, setEnquadramentoIPI] = useState('-')

    useEffect(() => {
        if (tributacao.codCFOPNFE !== '0') {
            let cfop = CFOPs.find(cfop => cfop.codCfop === tributacao.codCFOPNFE)
            if (cfop)
                setCFOPNFE(cfop.descricaoFormatada)
            else
                setCFOPNFE('-')
        } else {
            setCFOPNFE('-')
        }
    }, [tributacao.codCFOPNFE])

    useEffect(() => {
        if (tributacao.codCFOPCF !== '0') {
            let cfop = CFOPs.find(cfop => cfop.codCfop === tributacao.codCFOPCF)
            if (cfop)
                setCFOPCF(cfop.descricaoFormatada)
            else
                setCFOPCF('-')
        } else {
            setCFOPCF('-')
        }
    }, [tributacao.codCFOPCF])

    useEffect(() => {
        if (tributacao.codICMS !== '0') {
            let icms = ICMSs.find(icms => icms.codIcms === tributacao.codICMS)
            if (icms)
                setICMS(icms.descricaoFormatada)
            else
                setICMS('-')
        } else {
            setICMS('-')
        }
    }, [tributacao.codICMS])

    useEffect(() => {
        if (tributacao.codIPI !== '0') {
            let ipi = IPIs.find(ipi => ipi.codIpi === tributacao.codIPI)
            if (ipi)
                setIPI(ipi.descricaoFormatada)
            else
                setIPI('-')
        } else {
            setIPI('-')
        }
    }, [tributacao.codIPI])

    useEffect(() => {
        if (tributacao.codPISCOFINS !== '0') {
            let pisCofins = PISCOFINSs.find(pisCofins => pisCofins.codPisCofins === tributacao.codPISCOFINS)
            if (pisCofins)
                setPISCOFINS(pisCofins.descricaoFormatada)
            else
                setPISCOFINS('-')
        } else {
            setPISCOFINS('-')
        }
    }, [tributacao.codPISCOFINS])

    useEffect(() => {
        if (tributacao.codEnquadramentoIPI !== '0') {
            let enquadramentoIPI = enquadramentos.find(enquadramento => enquadramento.codEnquadramento === tributacao.codEnquadramentoIPI)
            if (enquadramentoIPI)
                setEnquadramentoIPI(enquadramentoIPI.descricaoFormatada)
            else
                setEnquadramentoIPI('-')
        } else {
            setEnquadramentoIPI('-')
        }
    }, [tributacao.codEnquadramentoIPI])


    return (
        <Row style={{ marginTop: '-70px' }}>
            <Col>
                <CardBody>

                    <Row className="justify-content-center">

                        <Col>
                            <div className="box resumo-tributacao">

                                <Row className="justify-content-center">

                                    <Col lg>
                                        <h5>Estados</h5>
                                        <label>{
                                            tributacao.uf.length === 0 ? '-'
                                                :
                                                tributacao.uf.map((estado, key) => {
                                                    if (key > 0) {
                                                        return ', ' + estado
                                                    }
                                                    else {
                                                        return estado
                                                    }
                                                })
                                        }</label>
                                    </Col>

                                </Row>


                                <Row className="justify-content-center resumo-border-top">

                                    <Col lg>
                                        <h5>CFOP NF-e/NFC-e</h5>
                                        <label>{CFOPNFE}</label>
                                    </Col>

                                    <Col lg>
                                    </Col>

                                </Row>

                                <Row className="justify-content-center resumo-border-top">

                                    <Col lg>
                                        <h5>CFOP Cupom Fiscal/S@t</h5>
                                        <label>{CFOPCF}</label>
                                    </Col>
                                    <Col lg>
                                    </Col>
                                </Row>

                                <Row className="justify-content-center resumo-border-top">

                                    <Col lg>
                                        <h5>ICMS</h5>
                                        <label>{ICMS}</label>
                                    </Col>

                                </Row>

                                <Row className="justify-content-center resumo-border-top">

                                    <Col lg>
                                        <h5>ICMS %</h5>
                                        <label>{tributacao.percICMS}</label>
                                    </Col>

                                    <Col lg>
                                        <h5>ICMS Substituição %</h5>
                                        <label>{tributacao.percICMSST}</label>
                                    </Col>

                                </Row>

                                <Row className="justify-content-center resumo-border-top">

                                    <Col lg>
                                        <h5>Enquadramento IPI</h5>
                                        <label>{EnquadramentoIPI}</label>
                                    </Col>

                                </Row>

                                <Row className="justify-content-center resumo-border-top">

                                    <Col lg>
                                        <h5>IPI (CST)</h5>
                                        <label>{IPI}</label>
                                    </Col>

                                    <Col lg>
                                        <h5>IPI %</h5>
                                        <label>{tributacao.percIPI}</label>
                                    </Col>

                                </Row>

                                <Row className="justify-content-center resumo-border-top">

                                    <Col lg>
                                        <h5>PIS</h5>
                                        <label>{PISCOFINS}</label>
                                    </Col>

                                </Row>

                                <Row className="justify-content-center resumo-border-top">

                                    <Col lg>
                                        <h5>PIS %</h5>
                                        <label>{tributacao.percPIS}</label>
                                    </Col>

                                    <Col lg>
                                        <h5>PIS Substituição %</h5>
                                        <label>{tributacao.percPISST}</label>
                                    </Col>

                                </Row>


                                <Row className="justify-content-center resumo-border-top">

                                    <Col lg>
                                        <h5>COFINS</h5>
                                        <label>{PISCOFINS}</label>
                                    </Col>

                                </Row>

                                <Row className="justify-content-center resumo-border-top">

                                    <Col lg>
                                        <h5>COFINS %</h5>
                                        <label>{tributacao.percCOFINS}</label>
                                    </Col>

                                    <Col lg>
                                        <h5>COFINS Substituição %</h5>
                                        <label>{tributacao.percCOFINSST}</label>
                                    </Col>

                                </Row>

                            </div>
                        </Col>

                    </Row>

                </CardBody>
                <CardFooter className="text-center">
                    <ActionsStep />
                </CardFooter>
            </Col>
        </Row>
    )
};

class Wizard extends React.Component {
    focusInput = () => {
    }

    render() {
        return (
            <Resumo />
        )
    }
}

export default Wizard;
