import React, { useState, useEffect, memo } from 'react';

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import ArrowTooltip from '../../../components/Tooltip/ArrowTooltip';

import {
    Input,
    Row,
    Col,
    CardBody,
    Button,
    CardFooter,
} from "reactstrap";

import CEST from './ModalsTributos/ModalCEST'
import NCM from './ModalsTributos/ModalNCM'
import Classificacoes from './ModalsTributos/ModalClassificacaoTributaria'

import useModal from '../../../Util/Hooks/useModal'
import useShallowEqualSelector from '../../../Util/Hooks/useShallowEqualSelector'

import * as controllerProdutos from '../../../controller/controllerProdutos'
import * as actions from '../../../store/reducers/Cadastro/Produto/actions'
import { InputNumber } from '../../../components/ErnComponets/ErnComponets';


const DadosTributarios = memo(function DadosTributarios(props) {

    const { codEmpresa } = useShallowEqualSelector(state => state.login.usuario.empresa);
    const codProduto = useShallowEqualSelector(state => state.produtos.produto.codProduto)
    const dadosTributarios = useShallowEqualSelector(state => state.produtos.produto.dadosTributarios)
    const seguimentos = useShallowEqualSelector(state => state.produtos.seguimentos)
    const cests = useShallowEqualSelector(state => state.produtos.cests)
    const ncms = useShallowEqualSelector(state => state.produtos.ncms)
    const classificacoes = useShallowEqualSelector(state => state.produtos.classificacoes)
    const ncmsSugestoes = useShallowEqualSelector(state => state.produtos.ncmsSugestoes)
    const cestsSugestoes = useShallowEqualSelector(state => state.produtos.cestsSugestoes)
    const verCadastro = useShallowEqualSelector(state => state.produtos.verCadastro)
    const dadosTributariosCopia = useShallowEqualSelector(state => state.produtos.produtoCopia.dadosTributarios)
    const [seguimentoSelected, setseguimentoSelected] = useState(findSeguimento())
    const [cestSelected, setcestSelected] = useState(findSeguimento())
    const [ncmSelected, setncmSelected] = useState(findNCM())
    const [classificacoesSelected, setclassificacoesSelected] = useState(findClassificacoes())

    const [modalCEST, toggleModalCEST] = useModal(false)
    const [modalNCM, toggleModalNCM] = useModal(false)
    const [modalClassificacoes, toggleModalClassificacoes] = useModal(false)

    const [shouldClean, setshouldClean] = useState(false)

    useEffect(() => {
        if (props.salvar) {
            Salvar()
        }
    }, [props.salvar])

    useEffect(() => {
        controllerProdutos.GetSeguimentos()
    }, [])

    useEffect(() => {
        setseguimentoSelected(findSeguimento())
    }, [dadosTributarios.codSeguimento, seguimentos])

    useEffect(() => {
        setcestSelected(findCEST())
    }, [dadosTributarios.cest, cests])

    useEffect(() => {
        setncmSelected(findNCM())
    }, [dadosTributarios.ncm, ncms])

    useEffect(() => {
        setclassificacoesSelected(findClassificacoes())
    }, [dadosTributarios.codClassificacao, classificacoes])

    useEffect(() => {
        if (dadosTributarios.cest !== '') {
            controllerProdutos.GetCESTNCMs(codEmpresa, dadosTributarios.cest)
        }
    }, [dadosTributarios.cest])

    useEffect(() => {
        if (dadosTributarios.ncm !== '') {
            controllerProdutos.GetNCMCESts(codEmpresa, dadosTributarios.ncm)
        }
    }, [dadosTributarios.ncm])

    function findSeguimento() {
        if (seguimentos.length > 0) {
            let seguimento = seguimentos.find(seguimento => seguimento.codigo === dadosTributarios.codSeguimento)
            if (seguimento)
                return seguimento.descricao
        }
        return ''
    }

    function findCEST() {
        if (cests.length > 0 || cestsSugestoes.length > 0) {
            let cest = cests.find(cest => cest.cest === dadosTributarios.cest) || cestsSugestoes.find(cest => cest.cest === dadosTributarios.cest)
            if (cest)
                return cest.descricaoFormatada
        }
        return ''
    }

    function findNCM() {
        if (ncms.length > 0 || ncmsSugestoes.length > 0) {
            let ncm = ncms.find(ncm => ncm.ncm === dadosTributarios.ncm) || ncmsSugestoes.find(ncm => ncm.ncm === dadosTributarios.ncm)
            if (ncm)
                return ncm.descricaoFormatada
        }
        return ''
    }

    function findClassificacoes() {
        if (classificacoes.length > 0 || dadosTributarios.codClassificacao !== '') {
            let classificacao = classificacoes.find(c => c.codClassificacao.toString() === dadosTributarios.codClassificacao.toString())
            if (classificacao)
                return classificacao.descricao
        }
        return ''
    }

    function Limpar() {
        actions.setProdutoDadosTributarios({ ...dadosTributarios, codSeguimento: '', cest: '', ncm: '', codClassificacao: 0 })
        setshouldClean(true)
        setTimeout(() => setshouldClean(false), 100)
    }

    async function Salvar() {
        var error = false;

        error = await controllerProdutos.SalvarCESTNCM(codEmpresa, codProduto, dadosTributarios, dadosTributariosCopia);

        if (error === false) {
            actions.setProximoCadastro(true);
            setTimeout(() => {
                actions.setProximoCadastro(false);
            }, 1000)
        }
    }
    const onChangeAliquotaMVA = (e) => {
        let aliquotaMVA = Number(e.currentTarget.value.toString().replace(',', '.'))

        if (aliquotaMVA <= 100) {
            actions.setProdutoDadosTributarios({ ...dadosTributarios, aliquotaMVA: e.currentTarget.value })
        }
    }
    return (
        <>
            <Row className="justify-content-center">
                <div className={verCadastro === true ? 'ver-cadastro' : ''}>

                </div>
                <Col>
                    <h4 className="info-text">Preencha com os dados tributários do produto</h4>

                    <CardBody>
                        <Row className="justify-content-center">
                            <Col md="10">

                                <Row>
                                    <Col lg>
                                        <label>Seguimento</label>
                                        <Input
                                            value={seguimentoSelected}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>

                                <br></br>
                                <Row>
                                    <Col lg>
                                        <label>CEST</label>
                                        <Input
                                            value={cestSelected}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col lg="auto">
                                        <Button
                                            style={{ marginTop: '24px', width: '40px', marginBottom: '0px' }}
                                            color="info"
                                            onClick={() => toggleModalCEST()}

                                        >
                                            <Icon icon="search" className="fa-lg" />
                                        </Button>
                                    </Col>
                                </Row>
                                <br></br>

                                <Row>
                                    <Col lg>
                                        <label>NCM</label>
                                        <Input
                                            value={ncmSelected}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col lg="auto">
                                        <Button
                                            style={{ marginTop: '24px', width: '40px', marginBottom: '0px' }}
                                            color="info"
                                            onClick={() => toggleModalNCM()}

                                        >
                                            <Icon icon="search" className="fa-lg" />
                                        </Button>
                                    </Col>
                                </Row>
                                <br></br>

                                <Row>
                                    <Col lg>
                                        <label>Classificações Tributárias</label>
                                        <Input
                                            value={classificacoesSelected}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col lg="auto">
                                        <Button
                                            style={{ marginTop: '24px', width: '40px', marginBottom: '0px' }}
                                            color="info"
                                            onClick={() => toggleModalClassificacoes()}
                                        >
                                            <Icon icon="search" className="fa-lg" />
                                        </Button>
                                    </Col>
                                </Row>
                                <br></br>

                                <Row>
                                    <Col sm="12" md={{ size: 2 }}>
                                        <label>Aliquota MVA (%)</label>

                                        <InputNumber
                                            style={{ textAlign: 'right' }}
                                            format="5,2"
                                            id="aliquotaMVA"
                                            value={dadosTributarios.aliquotaMVA}
                                            onChange={(e) => onChangeAliquotaMVA(e)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>

                    <CardFooter className="text-center">
                        <Row>
                            <Col style={{ display: "flex", justifyContent: 'center' }}>
                                <ArrowTooltip title='Shift+L'>
                                    <Button className="btn-padrao" type="button" color="default" onClick={Limpar}>Limpar</Button>
                                </ArrowTooltip>
                                <ArrowTooltip title='Shift+S'>
                                    <Button className="btn-padrao" type="button" color="info" onClick={Salvar}>Salvar</Button>
                                </ArrowTooltip>
                            </Col>
                        </Row>
                    </CardFooter>

                </Col>
            </Row>

            {modalCEST ?
                <CEST
                    toggleModalNCM={toggleModalNCM}
                    toggleModalCEST={toggleModalCEST}
                    modalCEST={modalCEST}
                    dadosTributarios={dadosTributarios}
                    seguimentos={seguimentos}
                    cests={cests}
                    cestsSugestoes={cestsSugestoes}
                    shouldClean={shouldClean}
                />
                : null}

            {modalNCM ?
                <NCM
                    toggleModalCEST={toggleModalCEST}
                    toggleModalNCM={toggleModalNCM}
                    modalNCM={modalNCM}
                    dadosTributarios={dadosTributarios}
                    ncms={ncms}
                    ncmsSugestoes={ncmsSugestoes}
                    shouldClean={shouldClean}
                />
                : null}

            {modalClassificacoes ?
                <Classificacoes
                    modalClassificacoes={modalClassificacoes}
                    toggleModalClassificacoes={toggleModalClassificacoes}
                    classificacoes={classificacoes}
                    shouldClean={shouldClean}
                />
                : null}
        </>
    )
})

class Wizard extends React.Component {
    constructor() {
        super()
        this.state = {
            salvar: false
        };
    }

    focusInput = () => {
        setTimeout(() => {
        }, 100)
    }


    isValidated = (step = 0, finish = false) => {
        if (step > 0) {
            return true
        }
        if (!finish) {
            this.setState({ salvar: true }, () => {
                this.setState({ salvar: false })
            })
        }
        return false
    }

    render() {
        return (
            <DadosTributarios salvar={this.state.salvar} />
        )
    }
}

export default Wizard;