import React from 'react'
import { Col, Row, Button } from 'reactstrap'

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from '../../../../components/ModalBasic/ModalBasic'

import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector';
import AppState from '../../../../store/storeType';
import { TipoGrade, Produto } from '../../../../store/reducers/Cadastro/Produto/types';

interface ModalTrocarTipoGradeProps {
    isOpen: boolean
    toggle: () => void
    confirmaTroca: () => void
}

const ModalTrocarTipoGrade: React.FC<ModalTrocarTipoGradeProps> = ({ isOpen, toggle, confirmaTroca }) => {

    const tiposGrade = useShallowEqualSelector<AppState, TipoGrade[]>(state => state.produtos.tiposGrade);
    const gradesCopia = useShallowEqualSelector<AppState, Produto['grades']>(state => state.produtos.produtoCopia.grades);
    const grades = useShallowEqualSelector<AppState, Produto['grades']>(state => state.produtos.produto.grades);

    function findGrade(list: Produto['grades']) {
        let text = ""
        if (tiposGrade) {
            const tipo = tiposGrade.find(tipo => tipo.codigo === list.codTipoGrade)
            if (tipo) text = tipo.descricao
        }
        return text
    }

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title={'Tipo de grade'}
        >
            <ModalBasicBody>

                <Col className="text-center">

                    <label>Tem certeza que deseja trocar o <b>tipo de grade</b> desse produto de{" "}<b>
                        {findGrade(gradesCopia)}</b>{" "}para{" "}<b>{findGrade(grades)}</b>? Os estoques existentes nas grades antigas serão zerados.</label>
                </Col>
            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <Button
                            color="danger"
                            type="button"
                            onClick={() => toggle()}
                            style={{ width: '150px' }}
                        >NÃO
                            </Button>
                    </Col>
                    <Col className="text-center">
                        <Button
                            color="success"
                            type="button"
                            onClick={() => {
                                toggle()
                                confirmaTroca()
                            }}
                            style={{ width: '150px' }}
                        >SIM
                            </Button>
                    </Col>
                </Row>
            </ModalBasicFooter>
        </ModalBasic >
    )
}

export default ModalTrocarTipoGrade