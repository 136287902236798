import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar"
import store from '../../store/stores';

import { maskMoeda } from '../../Util/mascaras';
import { removeMaskMoney } from '../../Util/removeMascaras';
import moment from 'moment';

const idStore = 'LANCAMENTO_TITULOS';

export async function GetLancamentos(pesquisa) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState();

    try {
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        return await axios({
            method: "GET",
            baseURL: api.urlFinanceiro + `api/v1/titulos/buscaTitulos?CodigoFilial=${pesquisa.codEmpresaFilial}&CodigoCliente=${pesquisa.codFor > 0 ? pesquisa.codFor : null}&Cliente=${pesquisa.tipoFor}&DataInicio=${pesquisa.dataInicio}&DataFim=${pesquisa.dataFim}`,
            headers: { Authorization: bearerAuth }
        }).then(response => {
            if (response.data.length === 0) {
                alertar(notificacao, 'br', "Nenhum título encontrado", 'info', 'icon-bulb-63', 2)
                return
            }
            response.data.forEach(x => {
                x.valor = maskMoeda(x.valor.toFixed(2))
            })
            dispatch({ idStore, type: "TITULOS", value: response.data })

            alertar(notificacao, 'br', 'Consulta realizada com sucesso', 'success', 'icon-alert-circle-exc', 2)
            return
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.mensagem : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return
        });
    } catch (error) {
        alertar(notificacao, 'br', "Não foi possível realizar pesquisa de títulos", 'warning', 'icon-alert-circle-exc', 2)
        return
    }

}

export async function GetLancamento(pesquisa) {
    const { notificacao } = store.getState();

    try {
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        await axios.get(api.urlFinanceiro + `api/v1/titulos/buscaTitulos/${pesquisa.codigoTituloMovimento}`, {
            headers: { Authorization: bearerAuth }
        }).then(async response => {
            await getGrupoContas(response.data.tipo === "CRÉDITO" ? "R" : "D")
            await getSubContas(response.data.codigoGrupoConta)
            dadosUpdate(response.data)
        }).catch(e => {
            alertar(notificacao, 'br', 'Não foi possível encontrar nenhum Título', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (error) {
        alertar(notificacao, 'br', "Não foi possível realizar pesquisa de títulos", 'warning', 'icon-alert-circle-exc', 2)
    }

}

export async function CreateLancamento() {
    const { notificacao } = store.getState();

    try {
        const { formulario } = store.getState().lancamentoTitulos;
        const { dadosConta, fornecedor } = store.getState().lancamentoTitulos.cadastro;

        let objetoLancamento = JSON.stringify(formulario);
        let objLancamento = JSON.parse(objetoLancamento)

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        var invalido = false;

        if (formulario.descricao === '') {
            let descricaoDefault = dadosConta.descricaoGrupo + "-" + dadosConta.descricaoSubGrupo + "-" + fornecedor.nomeFor + "- XXXXX";
            formulario.descricao = descricaoDefault
        }
        if (formulario.codForma === 0) {
            invalido = true
        }
        if (formulario.codConta === 0) {
            invalido = true
        }
        if (formulario.codFornecedor === 0) {
            invalido = true
        }
        if (formulario.codGrupoConta === 0) {
            invalido = true
        }
        if (formulario.codSubgrupoConta === 0) {
            invalido = true
        }
        if (formulario.codEmpresaFilial === 0) {
            invalido = true
        }
        if (formulario.valorFinal === 0) {
            invalido = true
        }

        if (objLancamento.tipo === "R") {
            objLancamento.tipo = "C"
        }

        if (invalido) {
            alertar(notificacao, 'br', `Campo invalido`, 'warning', 'icon-alert-circle-exc', 2);
            return true
        }
        let dia, mes, ano

        objLancamento.titulos.forEach(x => {
            x.valorTitulo = Number(removeMaskMoney(x.valorTitulo))

            dia = x.dataVencimento.substr(0, 2)
            mes = x.dataVencimento.substr(3, 2)
            ano = x.dataVencimento.substr(6, 4)
            x.dataVencimento = `${ano}-${mes}-${dia}`
        })

        let arrLancamento = {
            CodigoConta: objLancamento.codigoConta,
            CodigoSubGrupoConta: objLancamento.codigoSubGrupoConta,
            CodigoCaixa: objLancamento.codigoCaixa !== 0 ? objLancamento.codigoCaixa : null,
            CodigoDestinatario: objLancamento.codigoCliente,
            TipoDestinatario: objLancamento.tipoDestinatario,
            CodigoFuncionario: objLancamento.codigoFuncionario,
            QuantidadeParcelas: objLancamento.quantidadeParcelas,
            ValorTotalTitulos: objLancamento.valorTotalTitulos,
            ValorTotalPago: objLancamento.valorTotalPago,
            Descricao: objLancamento.descricao,
            Tipo: objLancamento.tipo,
            Operacao: "LAN",
            Parcelas: objLancamento.titulos,
        }


        await axios.post(api.urlFinanceiro + `api/v1/titulos/gravarTitulos`, arrLancamento, {
            headers: { Authorization: bearerAuth }
        }).then(response => {
            alertar(notificacao, 'br', 'Faturado com sucesso', 'success', 'icon-alert-circle-exc', 2)
            return false
        }).catch(e => {
            alertar(notificacao, 'br', 'Não foi possível realizar o Faturamento', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (error) {
        alertar(notificacao, 'br', "Não foi possível realizar o Faturamento", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export function UpdateLancamento(codTituloMovimento, thenFunction) {
    const { notificacao } = store.getState();
    const { dadosConta, metodoPgnt } = store.getState().lancamentoTitulos.cadastro;

    if (metodoPgnt.dataLancamento === '') {
        alertar(notificacao, 'br', "Data de lançamento inválida", 'warning', 'icon-alert-circle-exc', 2);
        return
    }

    if (metodoPgnt.vencimento === '') {
        alertar(notificacao, 'br', "Data de vencimento inválida", 'warning', 'icon-alert-circle-exc', 2);
        return
    }

    if (metodoPgnt.mesReferencia === '') {
        alertar(notificacao, 'br', "Mês de referência inválido", 'warning', 'icon-alert-circle-exc', 2);
        return
    }

    if (dadosConta.codConta <= 0) {
        alertar(notificacao, 'br', "Conta inválida", 'warning', 'icon-alert-circle-exc', 2);
        return
    }

    if (dadosConta.codGrupoConta <= 0) {
        alertar(notificacao, 'br', "Grupo de conta inválido", 'warning', 'icon-alert-circle-exc', 2);
        return
    }

    if (dadosConta.codSubgrupoConta <= 0) {
        alertar(notificacao, 'br', "Subgrupo de conta inválido", 'warning', 'icon-alert-circle-exc', 2);
        return
    }

    if (dadosConta.descricao === '') {
        alertar(notificacao, 'br', "Descrição inválida", 'warning', 'icon-alert-circle-exc', 2);
        return
    }

    
    //eslint-disable-next-line no-unused-vars
    let mes, ano, mesReferencia

    ano = metodoPgnt.mesReferencia.substr(0, 4)
    mes = metodoPgnt.mesReferencia.substr(5, 2)
    mesReferencia = `${mes}/${ano}`

    let objLancamentoUpdate = {
        lancamento: moment(metodoPgnt.dataLancamento, 'DD/MM/YYYY').format('YYYY/MM/DD'),
        vencimento: moment(metodoPgnt.vencimento, 'DD/MM/YYYY').format('YYYY/MM/DD'),
        mesReferencia: metodoPgnt.mesReferencia,
        conta: dadosConta.codConta,
        subGrupoConta: dadosConta.codSubgrupoConta,
        descricao: dadosConta.descricao,
        valorTitulo: Number(metodoPgnt.valorParcela.replace('.', '').replace(',', '.')), //Valor original da parcela
        juros: Number(metodoPgnt.juros.replace('.', '').replace(',', '.')),
        multa: Number(metodoPgnt.multa.replace('.', '').replace(',', '.')),
        desconto: Number(metodoPgnt.desconto.replace('.', '').replace(',', '.')),
        tarifa: Number(metodoPgnt.tarifa.replace('.', '').replace(',', '.')),
        total: Number(metodoPgnt.valorSubTotal.replace('.', '').replace(',', '.')) //Valor Subtotal
    }

    try {
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

        return axios.put(api.urlFinanceiro + `api/v1/titulos/parcelas/${codTituloMovimento}`, objLancamentoUpdate, {
            headers: { Authorization: bearerAuth }
        }).then(response => {
            alertar(notificacao, 'br', 'Título atualizado com sucesso', 'success', 'icon-alert-circle-exc', 4)
            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', 'Não foi possível atualizar esse título', 'warning', 'icon-alert-circle-exc', 2, e)
        })
    } catch {
        alertar(notificacao, 'br', 'Não foi possível atualizar esse título', 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function ExcluirLancamento(titulo) {
    const { notificacao } = store.getState();
    try {
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

        return await axios({
            method: 'put',
            url: api.urlMovimento + `api/v1/CaixaFaturamento/${titulo.numeroDocumento}/${9}`,
            headers: { Authorization: bearerAuth },
        }).then(response => {
            alertar(notificacao, 'br', "Título excluído com sucesso", 'warning', 'icon-check-2', 2)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível excluir o título", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function getEmpresa() {
    const { notificacao } = store.getState();

    try {

        store.dispatch({ idStore, type: 'EMPRESA', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/empresasFilial/empresasFilial`, {
            headers: { Authorization: basicAuth }
        }).then(response => {

            if (response.data.records.length === 0) {
                return alertar(notificacao, 'br', "Nenhuma Filial ou Empresa encontrada", 'warning', 'icon-alert-circle-exc', 2)
            }
            store.dispatch({ idStore, type: 'EMPRESA', value: response.data.records })
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as Empresa/Filiais", 'warning', 'icon-alert-circle-exc', 2)
    }
}
export async function getGrupoContas(tipo) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios.get(api.urlCadastro + `api/v1/grupoConta/grupoContas?tipo=${tipo !== undefined ? tipo : ""}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum Grupo de Conta encontrado", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'GRUPO_CONTAS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os grupos de contas", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function getContas() {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/conta/Contas`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma conta encontrada", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'CONTAS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as contas", 'warning', 'icon-alert-circle-exc', 2)
    }
}
export async function getSubContas(grupo) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios.get(api.urlCadastro + `api/v1/subGrupoConta/subGrupoContas?codGrupoConta=${grupo !== undefined ? grupo : ""}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                if (grupo !== undefined) {
                    alertar(notificacao, 'br', "Nenhum subgrupo de conta encontrado", 'info', 'icon-bulb-63', 2)
                }
            }
            dispatch({ idStore, type: 'SUB_CONTAS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os subgrupos de conta", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function getFormasPgms() {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()

    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/formaPgto/formasPgto?`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma forma de pagamento encontrado", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'FORMAS_PGTOS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as formas de pagamentos", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function getFornecedores(documento, nomeRazao, tipo) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        return await axios.get(api.urlCadastro + `api/v1/destinario/destinarios?tipo=${tipo}&cpfCnpj=${documento}&nome=${nomeRazao}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma Pessoas ou fornecedor encontrado", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'FORNCEDORES', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os fornecedores", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function getFornecedoresKeyPress(documento, tela, pesquisa) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()

    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        return await axios.get(api.urlCadastro + `api/v1/destinario/destinarios?cpfCnpj=${documento}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma Pessoas ou fornecedor encontrado", 'info', 'icon-bulb-63', 2)
                return null
            }
            let { codigo, nomeRazao, cpfCnpj, tipo } = response.data.records[0]
            if (tela === "C") {
                dispatch({
                    idStore, type: 'CADASTRO_FORNECEDOR', value: {
                        codEmpresaFilial: 0,
                        codFornecedor: codigo,
                        nomeFilial: "",
                        nomeFor: nomeRazao,
                        documento: cpfCnpj,
                        tipoDestinatario: tipo,
                    }
                })
            } else {
                dispatch({
                    idStore, type: 'PESQUISA', value: {
                        ...pesquisa,
                        tipoFor: tipo,
                        nomeFor: nomeRazao,
                        cpfCnjpf: cpfCnpj,
                        codFor: codigo
                    }
                })
            }

            return codigo
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os fornecedores", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function getFuncionario() {
    const dispatch = store.dispatch
    const { notificacao } = store.getState();
    const { funcionario } = store.getState().lancamentoTitulos.cadastro;
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/funcionario/funcionario`, {
            headers: { Authorization: basicAuth }
        }).then(response => {

            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Não foi possível obter nenhum funcionario cadastrado", 'info', 'icon-bulb-63', 2)
            }
            let stringFunc = response.data.records.codFunc
            let codFunc = Number(stringFunc)
            dispatch({ idStore, type: 'CADASTRO_FUNCIONARIO', value: { ...funcionario, codFunc: codFunc, nomeFunc: response.data.records.nomeFunc } })
            getCaixaAberto(codFunc)
        }).catch(error => {
            alertar(notificacao, 'br', "Usuário não possui funcionário vinculado", 'warning', 'icon-alert-circle-exc', 5)
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel obter Funcionário", 'warning', 'icon-alert-circle-exc', 2)
    }
}
export function getCaixaAberto(codFunc) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState();
    const { funcionario } = store.getState().lancamentoTitulos.cadastro;
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/caixa/caixaAberto?codFuncionario=${codFunc}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {

            if (response.data.records.dataAbertura)
                response.data.records.dataAbertura = response.data.records.dataAbertura.substring(0, 10)
            dispatch({ idStore, type: 'CADASTRO_FUNCIONARIO', value: { ...funcionario, dataAberturaCaixa: response.data.records.dataAbertura, codCaixa: response.data.records.codCaixa, codFunc: codFunc } })


        }).catch(error => {
            if (error.response)
                alertar(notificacao, 'br', error.response.data.message, 'warning', 'icon-alert-circle-exc', 5)
            else
                alertar(notificacao, 'br', "Não foi possivel obter o Caixa", 'warning', 'icon-alert-circle-exc', 2)
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel obter o Caixa", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function atualizaDescricao(value) {
    const dispatch = store.dispatch
    const { dadosConta } = store.getState().lancamentoTitulos.cadastro;
    dispatch({ idStore, type: "DADOS_CONTA", value: { ...dadosConta, descricao: value } })
}

export function salvar(salvar) {
    const dispatch = store.dispatch
    const { dadosConta, metodoPgnt, fornecedor, update } = store.getState().lancamentoTitulos.cadastro;
    if (update !== null) {
        let parcelas = []
        parcelas.push({
            codigoTitulo: dadosConta.codTitulo,
            codigoTituloMovimento: dadosConta.codTituloMov,
            numDoc: fornecedor.documento,
            grupo: dadosConta.descricaoGrupo,
            subGrupo: dadosConta.descricaoSubGrupo,
            dataVencimento: metodoPgnt.vencimento,
            parcela: metodoPgnt.parcela,
            formaPgnt: metodoPgnt.descricaoFormaPgnt,
            tipoPgnt: metodoPgnt.descricaoTipo,
            valorTitulo: metodoPgnt.valorParcela,
            codigoForma: metodoPgnt.codForma,
            valorJuros: 0,
            valorMulta: 0,
            valorDesconto: 0,
            valorTarifa: 0,
            valorTotalPago: metodoPgnt.valorPago,
            flPago: metodoPgnt.flaVista,
            flEnvioComprovante: false,
            status: 0
        })
        dispatch({ idStore, type: 'CADASTRO', value: parcelas, campo: "lancamentos" })
    }
    else {
        let qtdParcelas = metodoPgnt.qtdParcelas
        let parcelas = []
        let primeiroVencimento = metodoPgnt.vencimento ? new Date(metodoPgnt.vencimento) : new Date()
        let proximosVencimentos = metodoPgnt.proximosVencimentos ? new Date(metodoPgnt.proximosVencimentos) : new Date()
        let vencimento, mesReferencia = ''
        let mes, ano, dia = ''
        if (metodoPgnt.mesReferencia !== '') {
            ano = metodoPgnt.mesReferencia.substr(0, 4)
            mes = metodoPgnt.mesReferencia.substr(5, 2)

            mesReferencia = `${ano}/${mes}/01`
            mesReferencia = new Date(mesReferencia)
        } else {
            mesReferencia = new Date()
        }

        for (let i = 1; i <= qtdParcelas; i++) {

            if (i === 1) {
                vencimento = primeiroVencimento.toISOString()
            } else {
                vencimento = proximosVencimentos.toISOString()
                proximosVencimentos.setMonth(proximosVencimentos.getMonth() + 1)
            }

            ano = vencimento.substr(0, 4)
            mes = vencimento.substr(5, 2)
            dia = vencimento.substr(8, 2)
            vencimento = `${dia}/${mes}/${ano}`

            ano = mesReferencia.toISOString().substr(0, 4)
            mes = mesReferencia.toISOString().substr(5, 2)
            let mesRef = `${mes}/${ano}`
            mesReferencia.setMonth(mesReferencia.getMonth() + 1)

            parcelas.push({
                codigoTitulo: dadosConta.codTitulo,
                codigoTituloMovimento: dadosConta.codTituloMov,
                numDoc: fornecedor.documento,
                grupo: dadosConta.descricaoGrupo,
                subGrupo: dadosConta.descricaoSubGrupo,
                dataVencimento: vencimento,
                parcela: i,
                formaPgnt: metodoPgnt.descricaoFormaPgnt,
                tipoPgnt: metodoPgnt.descricaoTipo,
                valorTitulo: metodoPgnt.valorParcela,
                mesReferencia: mesRef,
                codigoForma: metodoPgnt.codForma,
                valorJuros: 0,
                valorMulta: 0,
                valorDesconto: 0,
                valorTarifa: 0,
                valorTotalPago: metodoPgnt.valorPago,
                flPago: metodoPgnt.flaVista,
                flEnvioComprovante: false,
                status: 0
            })
        }
        dispatch({ idStore, type: 'CADASTRO', value: parcelas, campo: "lancamentos" })
    }


    dispatch({ idStore, type: 'FORMULARIO' })
    if (salvar === true) {
        CreateLancamento()
    }
}

export function changeTipoLancamento(value) {
    const dispatch = store.dispatch

    dispatch({ idStore, type: 'CLEAN_TIPO' });
    dispatch({ idStore, type: 'CADASTRO', value: value, campo: "tipoLancamento" });
}

export function changeTipoGrupoContas() {
    const dispatch = store.dispatch
    const { codEmpresaFilial } = store.getState().login.usuario.empresa;
    const { fornecedor } = store.getState().lancamentoTitulos.cadastro;
    const { empresa } = store.getState().lancamentoTitulos;

    let objEmpresa = empresa.find(empresa => empresa.codEmpresaFilial === codEmpresaFilial)
    if (objEmpresa)
        dispatch({ idStore, type: 'CADASTRO_FORNECEDOR', value: { ...fornecedor, dataLancamento: new Date().toISOString().substr(0, 10), codEmpresaFilial: codEmpresaFilial, nomeFilial: objEmpresa.descricao } })
}

async function dadosUpdate(update) {
    const dispatch = store.dispatch
    const { dadosConta, fornecedor, metodoPgnt } = store.getState().lancamentoTitulos.cadastro;
    const { empresa } = store.getState().lancamentoTitulos;

    let objEmpresa = empresa.find(empresa => empresa.codEmpresaFilial === update.codigoFilial)
    dispatch({ idStore, type: "LANCAMENTOS", value: update.lancamentos })

    let valorTotalLancamento = 0
    update.lancamentos.forEach(x => {
        valorTotalLancamento = valorTotalLancamento + x.valorLancamento
        x.valorLancamento = maskMoeda(x.valorLancamento.toFixed(2))
        x.jurosLancamento = maskMoeda(x.jurosLancamento.toFixed(2))
        x.multaLancamento = maskMoeda(x.multaLancamento.toFixed(2))
        x.tarifaLancamento = maskMoeda(x.tarifaLancamento.toFixed(2))
        x.descontoLancamento = maskMoeda(x.descontoLancamento.toFixed(2))
        x.totalPagoLancamento = maskMoeda(x.totalPagoLancamento.toFixed(2))
    })

    let valorSubTotal = update.valor + update.juros - update.desconto - update.tarifa

    dispatch({
        idStore, type: "DADOS_CONTA", value: {
            ...dadosConta,
            codTitulo: update.codigoTitulo,
            codTituloMov: update.codigoTituloMovimento,
            codConta: update.codigoConta,
            codGrupoConta: update.codigoGrupoConta,
            codSubgrupoConta: update.codigoSubGrupoConta,
            descricao: update.descricao,
            descricaoGrupo: dadosConta.descricaoGrupo,
            descricaoSubGrupo: dadosConta.descricaoSubGrupo,
            conta: dadosConta.conta,
        }
    })

    dispatch({
        idStore, type: "CADASTRO_METODO", value: {
            ...metodoPgnt,
            codForma: metodoPgnt.codForma,
            descricaoFormaPgnt: metodoPgnt.descricaoFormaPgnt,
            descricaoTipo: metodoPgnt.descricaoTipo,
            dataLancamento: update.dataLancamento,
            dataCadastro: update.dataCadastro,
            vencimento: update.dataVencimento,
            mesReferencia: update.mesReferencia,
            proximosVencimentos: metodoPgnt.proximosVencimentos,
            parcela: update.parcela,
            qtdDiasEntreParcelas: metodoPgnt.qtdDiasEntreParcelas,
            qtdParcelas: metodoPgnt.qtdParcelas,
            valorParcela: maskMoeda(update.valor.toFixed(2)),
            valorFinal: metodoPgnt.valorFinal,
            valorPago: maskMoeda(valorTotalLancamento.toFixed(2)),
            valorSubTotal: maskMoeda(valorSubTotal.toFixed(2)),
            flaVista: metodoPgnt.flaVista,
            codTipoPgto: metodoPgnt.codTipoPgto,
            status: update.status,
            numeroParcela: update.numeroParcela,
            juros: maskMoeda(update.juros.toFixed(2)),
            multa: maskMoeda(update.multa.toFixed(2)),
            desconto: maskMoeda(update.desconto.toFixed(2)),
            tarifa: maskMoeda(update.tarifa.toFixed(2)),
            restante: metodoPgnt.restante,
        }
    })

    if (objEmpresa !== undefined) {
        dispatch({
            idStore, type: "CADASTRO_FORNECEDOR", value: {
                ...fornecedor,
                codEmpresaFilial: update.codigoFilial,
                codFornecedor: fornecedor.codFornecedor,
                nomeFor: update.nome,
                documento: fornecedor.documento,
                tipoDestinatario: fornecedor.tipoDestinatario,
                nomeFilial: fornecedor.nomeFilial
            }
        })
    }
    else {
        dispatch({
            idStore, type: "CADASTRO_FORNECEDOR", value: {
                ...fornecedor,
                codEmpresaFilial: update.codigoFilial,
                codFornecedor: fornecedor.codFornecedor,
                documento: fornecedor.documento,
                nomeFor: update.nome,
                tipoDestinatario: fornecedor.tipoDestinatario,
                nomeFilial: fornecedor.nomeFilial
            }
        })
    }

    dispatch({ idStore, type: "CADASTRO", value: update.tipo === "CRÉDITO" ? "R" : "D", campo: "tipoLancamento" })
}
