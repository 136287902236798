import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { maskCFOP } from "~/Util/mascaras";
import { alertar } from "~/Util/alertar";

import * as controllerClassificacoes from "~/controller/controllerClassificacoesTributarias";

import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';

const idStore = "CLASSIFICACOES_TRIBUTARIAS";

const Tributacoes = ({ changeTribTab }) => {
    const dispatch = useDispatch();
    const notificacao = useSelector(state => state.notificacao);
    const { verCadastro } = useSelector(state => state.classificacoesTributarias);
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
    const { tributacoes, classificacao } = useSelector(state => state.classificacoesTributarias);

    function onClickTributacao(e, codTributacao) {
        e.stopPropagation()
        controllerClassificacoes.GetTributacao(notificacao, dispatch, codEmpresa, classificacao.codClassificacao, codTributacao, () =>
            changeTribTab('tributacao')
        )
    }

    function handleExcluirTributacao(e, codTributacao) {
        e.stopPropagation()
        controllerClassificacoes.ExcluirTributacao(notificacao, dispatch, codEmpresa, classificacao.codClassificacao, codTributacao)
    }

    async function novaTributacao() {
        await controllerClassificacoes.GetIPIs(notificacao, dispatch, codEmpresa);
        await controllerClassificacoes.EnquadramentosIPI(notificacao, dispatch, codEmpresa);
        
        if (classificacao.codClassificacao) {
            changeTribTab('tributacao')
            dispatch({ idStore, type: 'CLEAN_TRIBUTACAO' });
            dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        } else {
            if (classificacao.descricao === '') {
                alertar(notificacao, 'br', "Informe uma descrição para a classificação", 'info', 'icon-bulb-63', 2)
            } else {
                controllerClassificacoes.CreateClassificacao(notificacao, dispatch, codEmpresa, classificacao, () => {
                    changeTribTab('tributacao')
                    dispatch({ idStore, type: 'CLEAN_TRIBUTACAO' });
                    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
                })
            }
        }
    }

    return (
        <div className="grid-tributacoes">
            {
                tributacoes.map((tributacao, key) =>
                    <ArrowTooltip title={ verCadastro === false ?  "Clique para editar" : "Clique para visualizar"}>
                        <div
                            disabled={verCadastro}
                            key={key}
                            className="grid-tributacoes-item"
                            onClick={(e) => onClickTributacao(e, tributacao.codTributacao)}>
                            <label>CFOP-NFE: {maskCFOP(tributacao.cfopNfe)}</label>
                            <label>CFOP-CF: {maskCFOP(tributacao.cfopCf)}</label>
                            <div className="close-back" />
                            <ArrowTooltip title="Excluir">
                                <button
                                    className="close-button"
                                    type="button"
                                    onClick={(e) => handleExcluirTributacao(e, tributacao.codTributacao)}
                                    disabled={verCadastro}
                                >X</button>
                            </ArrowTooltip>
                            <br />
                            <br />
                            <label>Estados:</label>
                            <label>{tributacao.uf}</label>

                        </div>
                    </ArrowTooltip>
                )
            }
            <ArrowTooltip title="Novo">
                <div
                    className="grid-tributacoes-add"
                    onClick={novaTributacao}
                >+</div>
            </ArrowTooltip>
        </div>
    )
}

export default Tributacoes;