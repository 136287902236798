import React, { useRef, useEffect } from 'react'

import * as controller from "../../../../controller/Movimentacao/controllerPedidoVenda"

import { Col, Row, Label } from "reactstrap"

import { Card, InputNumber, Input } from '../Components'

import { Multiply, Divide, Round, ToString } from '../../../../Util/numbers'


import { formats } from '../variables'

import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector';
import * as actions from '../../../../store/reducers/Movimentacao/PedidoVenda/actions';
import { maskMoeda } from '../../../../Util/mascaras'

export default function ProdutoPesquisa({ setCodProduto, toogleModalGrade, setTypeAction  }) {
    const inputQtd = useRef(null)
    //@ts-ignore
    const pedido = useShallowEqualSelector(state => state.pedidoVenda.pedido)
    const produto = useShallowEqualSelector(state => state.pedidoVenda.produto)
    const inputNumProdRef = useRef()
    var nomeProduto

    useEffect(() => {
        inputNumProdRef.current.focus()
    }, [])

    function onChangeProduto(value) {
        nomeProduto = value
    }

    function handleOnChange(campo, value) {
        value.toString()
        if (campo === 'codBarras') {
            actions.setProduto({ ...produto, codBarras: value })
            return
        }
        if (campo === 'descontoReais') {
            if (Number(value) > Number(produto.subtotal)) {
                actions.setProduto({
                    ...produto,
                    descontoReais: Round(produto.subtotal, formats.descontoReais.decimal),
                    descontoPercent: Round(
                        Divide([
                            Multiply([100, produto.subtotal]),
                            Number(produto.subtotal) ? produto.subtotal : 1
                        ])
                        , formats.descontoPercent.decimal)
                })
            } else {
                actions.setProduto({
                    ...produto,
                    descontoReais: Number(value),
                    descontoPercent: Round(
                        Divide([
                            Multiply([100, value]),
                            produto.subtotal
                        ])
                        , formats.descontoPercent.decimal)
                })
            }
            return
        }
        if (campo === 'descontoPercent') {
            if (Number(value) > 100) {
                actions.setProduto({
                    ...produto,
                    descontoPercent: Round(100, formats.descontoPercent.decimal),
                    descontoReais: Round(produto.subtotal, formats.descontoReais.decimal),
                })
            } else {
                actions.setProduto({
                    ...produto,
                    descontoPercent: ToString(value),
                    descontoReais: Round(
                        Divide([
                            Multiply([value, produto.subtotal]),
                            100
                        ])
                        , formats.descontoReais.decimal),
                })
            }
            return
        }
        actions.setProduto({ ...produto, [campo]: value })
    }

    async function handleKeyPressPesquisaProduto(event) {
        if (event.key === 'Enter') {
            var response = []
            if (isNaN(nomeProduto)) {
                response = await controller.GetProdutos({ numProduto: '', nome: nomeProduto, codSubgrupo: 0 })
                actions.setProdutos(response)
            } else {
                response = await controller.GetProdutos({ numProduto: nomeProduto, nome: '', codSubgrupo: 0 })
                actions.setProdutos(response)

            }
            if (response.length === 1) {
                const product = await controller.GetProduto(response[0].codProduto)
                if (product.flControlaGrade !== 1) {
                    //@ts-ignore
                    product.subtotal = maskMoeda((product.precoVendaAtual * produto.quantidade).toFixed(2))
                    //@ts-ignore
                    product.precoVendaAtual = maskMoeda(product.precoVendaAtual.toFixed(2))
                    product.quantidade = produto.quantidade
                    //@ts-ignore
                    product.descontoPercent = '0'
                    //@ts-ignore
                    product.descontoReais = '0'
                    //@ts-ignore
                    product.total = product.subtotal

                    actions.setProduto({
                        ...produto,
                        ...product,
                        quantidade: Round(1, formats.quantidade.decimal),
                        precoVendaAtual: Round(product.precoVendaAtual, formats.precoVendaAtual.decimal),
                        descontoPercent: Round(0, formats.descontoPercent.decimal),
                        descontoReais: Round(0, formats.descontoReais.decimal),
                        subtotal: Round(0, formats.subtotal.decimal),
                        total: Round(0, formats.total.decimal),
                    })
                    actions.addItem(product)
                } else {
                    await setCodProduto(response[0].codProduto)
                    setTypeAction('')
                    toogleModalGrade()

                }
            } else {
                nomeProduto = ''
            }
        }
    }

    async function handleKeyPressIncluir(event) {
        if (event.key === 'Enter') {
            let quantidade = Number(produto.quantidade)
            if (produto.codProduto && (quantidade > 0)) {

                let response = await controller.GetProduto(produto.codProduto)
                if (response)
                    actions.setProdutos({ response })
                actions.setProduto()
                inputNumProdRef.current.focus()
            }
            inputNumProdRef.current.focus()
        }
    }

    return (
        <Card>
            <div>
                <Col style={{ paddingLeft: 15, paddingRight: 15, textAlign: "center" }}>
                    <Row>
                        <Col lg="1">
                            <Row>
                                <Col style={{ marginLeft: "-1.2rem" }}>
                                    <Label>Qtd.</Label>
                                </Col>
                            </Row>
                            <Row>
                                <InputNumber
                                    innerRef={inputQtd}
                                    style={{ textAlign: "center" }}
                                    format={`${formats.quantidade.places},${formats.quantidade.decimal}`}
                                    value={produto.quantidade}
                                    onKeyPress={(e) => handleKeyPressIncluir(e)}
                                    onChange={(e) => handleOnChange('quantidade', e.target.value)}
                                    onFocus={e => e.target.select()}
                                    disabled={pedido.status !== 'ABERTO' && pedido.status !== '' ? true : false}
                                />
                            </Row>
                        </Col>
                        <Col>
                            <Row style={{ width: "88%", textAlign: 'left', }}>
                                <Col>
                                    <Label >Cód.Barras/Número ou Descrição</Label>
                                </Col>
                            </Row>
                            <Row style={{ width: "130%" }}>
                                <Col>
                                    <Input
                                        id='numeroDescricao'
                                        innerRef={inputNumProdRef}
                                        value={nomeProduto}
                                        onKeyPress={(e) => {
                                            handleKeyPressPesquisaProduto(e);
                                            if (e.key === 'Enter') e.target.select()
                                        }}
                                        onChange={(e) => onChangeProduto(e.target.value)}
                                        onFocus={e => e.target.select()}
                                        style={{ width: "80%" }}
                                        disabled={pedido.status !== 'ABERTO' && pedido.status !== '' ? true : false}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </div>

        </ Card >
    )
}
