import React, {useEffect, useState} from 'react'
import { useCallback } from 'react'
import ReactTable from 'react-table'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { Button, Col, Input, Row } from 'reactstrap'
import * as controllerPDV  from '../../../../../controller/Movimentacao/controllerPedidoVenda'
import { IDetalhesPedidoItens, ITotaisDetalhesPesquisa } from '../../../../../store/reducers/Movimentacao/PedidoVenda/types'
import AppState from '../../../../../store/storeType'
import useScrollBar from '../../../../../Util/Hooks/useScrollBar'
import useShallowEqualSelector from '../../../../../Util/Hooks/useShallowEqualSelector'
import { maskMoeda } from '../../../../../Util/mascaras'
import * as action from '../../../../../store/reducers/Movimentacao/PedidoVenda/actions'

interface IItensPedidoProps {
    numPedidoSelect: number;
    isOpen: any;
}

const ItensPedido: React.FC<IItensPedidoProps> = ({numPedidoSelect, isOpen}) => {
    const [gradeSelect, setGradeSelect] = useState([])
    const [rowSelected, setRowSelected] = useState('')
    const refTableDetailsProduct = useScrollBar([isOpen])
    const detalhesPedidoItens = useShallowEqualSelector<AppState, IDetalhesPedidoItens[]>(state => state.pedidoVenda.detalhesPedidoItens)
    const totaisDetalhesPesquisa = useShallowEqualSelector<AppState, ITotaisDetalhesPesquisa>(state => state.pedidoVenda.totaisDetalhesPesquisa)

    useEffect(() => {
        controllerPDV.getDetalhesPedido(numPedidoSelect)
        if(gradeSelect.length > 0) {
          setGradeSelect([])
        }
    }, [])

    const reduceSum = useCallback((data: string[], ) => {
        let itensSum = data.reduce((itemValue, value) => {
          return Number(itemValue) + Number(value)
        }, 0)
        return itensSum
      }, [])

    useEffect(() => {
        const totalPrice = detalhesPedidoItens.map(product => product.valorSubTotal)
        //@ts-ignore
        const total = reduceSum(totalPrice)
        const descontos = detalhesPedidoItens.map(product => product.valorDesconto)
        //@ts-ignore
        const desconto = reduceSum(descontos)
        const totaisVendas = detalhesPedidoItens.map(product => product.valorSubTotal)
        //@ts-ignore
        const totalVenda = reduceSum(totaisVendas)
        action.setTotaisDetalhesPesquisa({
          ...totaisDetalhesPesquisa,
          totalProd: total,
          descProd: desconto,
          totalVenda: totalVenda - desconto,
        })
        
      }, [detalhesPedidoItens])

      const getTrProps = (state: any, row: any) => {
        return {
            style: {
                height: '50px',
                paddingTop: '0px',
                paddingBottom: '5px',
                //@ts-ignore
                backgroundColor: detalhesPedidoItens[row.index].codProduto === rowSelected ? 'rgba(71, 179, 255, 0.41)' : ''
            },
            onClick: (e: any, handleOriginal: any) => {
                //@ts-ignore
                setRowSelected(detalhesPedidoItens[row.index].codProduto)
                //@ts-ignore
                setGradeSelect(detalhesPedidoItens[row.index].grades)
                if (handleOriginal) {
                    handleOriginal()
                }
            },
        }
      }

    return(
        <Row className="detailsOrder-content"  >
          <Col>
           <Row>
            <fieldset className="fieldset-style" style={{ width: '100%', border: '2px solid gray'}}>
                <legend style={{fontSize: 18}}>Itens do Pedido</legend>
                <div 
                  className="detailsTable-itens"
                  ref={refTableDetailsProduct}
                  style={{position: 'relative', maxHeight: 200}} 
                >
                  <ReactTable 
                    columns={
                      [
                        {
                            Header: 'Produto',
                            accessor: 'descricao'
                          },
                          {
                            Header: 'Quantidade',
                            accessor: 'quantidade'
                          },
                          {
                            Header: 'Valor',
                            accessor: 'valorUnitario',
                            Cell: props => maskMoeda(Number(props.value).toFixed(2))
                          },
                          {
                            Header: 'Sub Total',
                            accessor: 'valorSubTotal',
                            Cell: props => maskMoeda(Number(props.value).toFixed(2))
                          },
                          {
                            Header: 'Desconto',
                            accessor: 'valorDesconto',
                            Cell: props => maskMoeda(Number(props.value).toFixed(2))
                          },
                          {
                            Header: 'Total',
                            accessor: 'valorTotal',
                            Cell: props => maskMoeda(Number(props.value).toFixed(2))
                          },
                          {
                            Header: '',
                            accessor: '',
                            Cell: props => props.original.grades.length > 0 && (
                                <div>
                                    <Icon icon="th" className="fa-lg" color={'#fff'} />
                                </div>
                            )
                        }
                      ]
                    }
                    data={detalhesPedidoItens}
                    minRows={0}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    className="-striped -highlight header-fix table-detailsOrder"
                    NoDataComponent={() => (<></>)}
                    getTrProps={getTrProps}
                  />
                </div>
              </fieldset>
           </Row>
           <Row className="detailsOrder-inputs">
             <Col>
                <label htmlFor="">Produtos</label>
                <Input value={maskMoeda(Number(totaisDetalhesPesquisa.totalProd).toFixed(2))} disabled />
             </Col>
             <Col>
                <label htmlFor="">Descontos</label>
                <Input value={maskMoeda(Number(totaisDetalhesPesquisa.descProd).toFixed(2))} disabled />
             </Col>
             <Col>
                <label htmlFor="">Total Venda</label>
                <Input value={maskMoeda(Number(totaisDetalhesPesquisa.totalVenda).toFixed(2))} disabled />
             </Col>
           </Row>
           <Row>
           {gradeSelect.length > 0 && (
               <fieldset className="fieldset-style" style={{ width: '100%', border: '2px solid gray'}}>
               <legend style={{fontSize: 18}}>Grade</legend>
               <div 
                 className="detailsOrder-grade"
                 ref={refTableDetailsProduct}
                 style={{position: 'relative', maxHeight: 200}} 
               >
                   <ReactTable 
                       columns={
                           [
                               {
                                   Header: 'Descrição',
                                   accessor: 'descricao'
                               },
                               {
                                   Header: 'Sigla',
                                   accessor: 'sigla'
                               },
                               {
                                   Header: 'Quantidade',
                                   accessor: 'qtdAtualizar'
                               },
                           ]
                       }
                       data={gradeSelect}
                       minRows={0}
                       showPaginationTop={false}
                       showPaginationBottom={false}
                       className="-striped -highlight header-fix table-detailsOrder"
                       NoDataComponent={() => (<></>)}
                   />
              </div>
             </fieldset>
           )}
               
           </Row>
          </Col>
        </Row>
    )
}

export default ItensPedido