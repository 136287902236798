//State da tela de Pesquisa e cadastro de PIS/COFINS
const INITIAL_STATE = {
    pesquisa: {
        pisCofins: '',
        descricao: '',
        ativos: 0,
    },
    pisCofins: {
        codPisCofins: 0,
        pisCofins: '',
        descricao: '',
        status: 0,
    },
    invalido: {
        pisCofins: false,
        descricao: false,
    },
    PISCOFINSs: [],
    inicioCadastro: false,
    verCadastro: false,
    pisCofinsCopia: {},
};

export default function pisCofins(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'PISCOFINS':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'PISCOFINSs':
                    return { ...state, PISCOFINSs: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: INITIAL_STATE.pesquisa,
                        PISCOFINSs: INITIAL_STATE.PISCOFINSs
                    }
                case 'PISCOFINS':
                    return { ...state, pisCofins: { ...state.pisCofins, [action.campo]: action.value } }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value } 
                case 'PISCOFINS_COPIA':
                    return { ...state, pisCofinsCopia: state.pisCofins }
                case 'CLEAN_PISCOFINS':
                    return {
                        ...state,
                        pisCofins : INITIAL_STATE.pisCofins
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: INITIAL_STATE.invalido
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}