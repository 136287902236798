
import { IUsuarioState, UsuarioAction, UsuarioStore, UsuarioTypes } from '../../../reducers/Cadastro/Usuario/types'

//State da tela de Pesquisa e cadastro de usuários
const INITIAL_STATE: IUsuarioState = {
    pesquisa: {
        nome: '',
        email: '',
        cpf: '',
        funcao: '',
        ativos: '',
    },
    filiais: [],
    empresas: [],
    empresasUser: [],
    funcoes: [],

    usuario: {
        nome: '',
        cpf: '',
        codEmpresa: '0',
        codFilial: '0',
        codUser: '',
        foto: '',
        apelido: '',
        email: '',
    },

    funcionario: {
        codFunc: '',
        nome: '',
        emailFunc: '',
        cpf: '',
        dtNascimento: '',
        genero: 'O',
        rg: '',
        orgaoEmissor: '',
        dtEmissao: '',
        codFuncao: 0,
        descFuncao: '',
        telefone: '',
        numeroFuncionario: '0',
    },

    acesso: {
        codEmpresaFilial: 0,
        codGrupoAcesso: '',
        nomeGrupo: '',
    },

    invalido: {
        nome: false,
        nomeFunc: false,
        email: false,
        emailFunc: false,
        cpf: false,
        cpfFunc: false,
        empresas: false,
        dtNascimento: false,
        dtEmissao: false,
    },

    inicioCadastro: false,
    proximoCadastro: false,
    verificationUser: false,
    verCadastro: false,
    verificationSalvar: false,
    currentStep: 0,
    usuarios: [],
    funcionarioCopia: [],
    empresasCopia: [],
    screen: 0,
    status: 0,
    empresasUserCopia: [],
    grupos: [],
    gruposAcessoUsuario: [],
    editar: false

};

const usuario = (state = INITIAL_STATE, action: UsuarioAction) => {
    switch (action.idStore) {
        case UsuarioStore.ID_STORE:
            switch (action.type) {
                case UsuarioTypes.SCREEN:
                    return { ...state, screen: action.screen }

                case UsuarioTypes.WIZARD_STEP:
                    return { ...state, currentStep: action.currentStep }

                case UsuarioTypes.PESQUISA:
                    return { ...state, pesquisa: action.pesquisa }

                case UsuarioTypes.USUARIOS:
                    return { ...state, usuarios: action.usuarios }

                case UsuarioTypes.EMPRESAS:
                    return { ...state, empresas: action.empresas }

                case UsuarioTypes.USUARIO:
                    return { ...state, usuario: action.usuario }

                case UsuarioTypes.FUNCOES:
                    return { ...state, funcoes: action.funcoes }

                case UsuarioTypes.FILIAIS:
                    return { ...state, filiais: action.filiais }

                case UsuarioTypes.GRUPOS:
                    return { ...state, grupos: action.grupos }

                case UsuarioTypes.VERIFICATION_USER:
                    return { ...state, verificationUser: action.verificationUser }

                case UsuarioTypes.INICIO_CADASTRO:
                    return { ...state, inicioCadastro: action.inicioCadastro }

                case UsuarioTypes.PROXIMO_CADASTRO:
                    return { ...state, proximoCadastro: action.inicioCadastro }

                case UsuarioTypes.FUNCIONARIO_COPIA:
                    return { ...state, funcionarioCopia: action.funcionario }

                case UsuarioTypes.INVALIDO:
                    return { ...state, invalido: action.invalido }

                case UsuarioTypes.EMPRESASUSER:
                    return { ...state, empresasUser: action.empresasUser }

                case UsuarioTypes.ACESSO:
                    return { ...state, acesso: action.acesso }

                case UsuarioTypes.USUARIO:
                    return { ...state, usuario: action.usuario }

                case UsuarioTypes.FUNCIONARIO:
                    return { ...state, funcionario: action.funcionario }

                case UsuarioTypes.GRUPOS_ACESSO:
                    return { ...state, gruposAcessoUsuario: action.gruposAcessoUsuario }

                case UsuarioTypes.EDITAR:
                    return { ...state, editar: action.editar }

                case UsuarioTypes.EMPRESASUSER_COPIA:
                    return { ...state, empresasUserCopia: action.empresasUser }

                case UsuarioTypes.CLEAN_SEARCH:
                    return {
                        ...state,
                        pesquisa: INITIAL_STATE.pesquisa,
                        usuarios: INITIAL_STATE.usuarios
                    }

                case UsuarioTypes.CLEAN_ACESSO:
                    return {
                        ...state,
                        acesso: INITIAL_STATE.acesso,
                        gruposAcessoUsuario: INITIAL_STATE.gruposAcessoUsuario
                    }

                case UsuarioTypes.CLEAN_FUNCIONARIO:
                    return {
                        ...state,
                        funcionario: INITIAL_STATE.funcionario,
                        funcionarioCopia: INITIAL_STATE.funcionarioCopia
                    }

                case UsuarioTypes.CLEAN_USUARIO:
                    return {
                        ...state,
                        usuario: INITIAL_STATE.usuario,
                        empresasUser: INITIAL_STATE.empresasUser,
                        verificationUser: INITIAL_STATE.verificationUser,
                        empresasUserCopia: INITIAL_STATE.empresasUserCopia
                    }

                case UsuarioTypes.CLEAN_EMPRESA_USER:
                    return {
                        ...state,
                        empresasUser: INITIAL_STATE.empresasUser
                    }

                case UsuarioTypes.CLEAN_INVALIDO:
                    return { ...state, invalido: INITIAL_STATE.invalido }

                case UsuarioTypes.CHANGE_TELA:
                    return { ...state, grupos: action.value }

                case UsuarioTypes.VER_CADASTRO:
                    return { ...state, verCadastro: action.verCadastro }

                case UsuarioTypes.VERIFICATION_SALVAR:
                    return { ...state, verificationSalvar: action.verificationSalvar }

                default:
                    return { ...state }
            }

        default:
            return { ...state }
    }

}

export default usuario