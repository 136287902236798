import React, { Component } from 'react';

import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    NavLink,
} from "reactstrap";

export default class Notifications extends Component {
    render() {
        return (
            <UncontrolledDropdown nav>
                <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                >
                    <div className="notification d-none d-lg-block d-xl-block" />
                    <i className="tim-icons icon-sound-wave" />
                    <p className="d-lg-none">Notificações</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                        <DropdownItem className="nav-item">
                            Mike John responded to your 
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                        <DropdownItem className="nav-item">
                            You have 5 more tasks
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                        <DropdownItem className="nav-item">
                            Your friend Michael is in town
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                        <DropdownItem className="nav-item">
                            Another notification
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                        <DropdownItem className="nav-item">
                            Another one
                      </DropdownItem>
                    </NavLink>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
}
