/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {
  render() {
    const date = new Date()

    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
        style={{paddingBottom:'15px'}}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <div className="copyright">
            © {`${date.getFullYear()}`} {" "}
            <a href="http://www.easyxweb.com" target="_blank"
            style={{color:'#189ffa',textShadow:'0px 0px 6px black'}}
            >
              easyxweb
            </a>{" "}
            | Sua plataforma web
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
