import React, { useState, useEffect } from 'react'
import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import moment from 'moment'

import axios from 'axios'
import api from '../../../../Util/api'

import { maskMoeda } from '../../../../Util/mascaras'

import Logo from '../../../../assets/img/logoern.png'
import { styles, stylesCupom } from './styles'

var codMovTituloAux = 0

export function tituloMov(codigoMovTitulo?: any) {
  codMovTituloAux = codigoMovTitulo
  return codMovTituloAux
}

const ReciboPDFContent: React.FC = () => {
  const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
  const [recibimentos, setRecibimentos] = useState<any[]>([])
  const [lancamentos, setLancamentos] = useState<any[]>([])
  const [informacoes, setInformacoes] = useState({
    nome: '',
    descricao: ''
  })

  const loadRecibimentos = async () => {
    const response = await axios.get(api.urlFinanceiro + `api/v1/titulos/buscaTitulos/${codMovTituloAux}`, {
      headers: { Authorization: bearerAuth }
    })
    const { nome, descricao, lancamentos } = response.data
    setRecibimentos(state => [...state, response.data])
    setInformacoes({
      descricao,
      nome
    })
    setLancamentos(lancamentos)

  }

  useEffect(() => {
    if (codMovTituloAux) {
      loadRecibimentos()
    }
  }, [codMovTituloAux])

  return (
    <Page size="A4" style={styles.container} >
      <View style={styles.header} >
        <View style={[styles.headerItens]} >
          <Image src={Logo} />
        </View>
        <View style={[styles.headerItens, { width: '50%' }]} >
          <Text style={[{ fontSize: '10px', textTransform: 'uppercase', marginBottom: '2px', fontWeight: 'bold' }]} >Lucas Monteiro Reis Pereira</Text>
          <Text style={[{ fontSize: '8px', textTransform: 'uppercase', marginBottom: '2px' }]} >Rua São Paulo, 998 - Vila Aparecida </Text>
          <Text style={[{ fontSize: '8px', textTransform: 'uppercase', marginBottom: '2px' }]}>CEP: 14401-248 - Franca/SP</Text>
          <Text style={[{ fontSize: '8px', textTransform: 'uppercase', marginBottom: '2px' }]} >CNPJ: 40.211.176/0001-07 - IE: 310.880.353.119 </Text>
        </View>
        <View style={[styles.headerItens, { width: '20%' }]} >
          <View style={styles.headerRow} >
            <Text style={[{ fontSize: '8px', fontWeight: 'bold' }]} >Emissão</Text>
            <Text style={[styles.headerText, { fontSize: '8px' }]} >{moment(new Date()).format('DD-MM-YYYY')}</Text>
          </View>
          <View style={styles.headerRow} >
            <Text style={[{ fontSize: '8px', fontWeight: 'bold' }]} >Hora</Text>
            <Text style={[styles.headerText, { fontSize: '8px' }]} >{moment(new Date()).format('hh:mm:ss')}</Text>
          </View>
        </View>
      </View>
      <View style={[styles.infoRecebimento]} >
        <Text style={[styles.title, { fontSize: '12px', textAlign: 'center', paddingBottom: '2px', borderBottom: '1px solid #333' }]} >Recibo de Pagamento</Text>
        <View>
          <Text style={[styles.text, { fontWeight: 'bold', margin: '3px 0' }]} >Pessoa: {informacoes && informacoes.nome} </Text>
          <Text style={[styles.titleBackground, { fontWeight: 'bold', }]} >Título</Text>
          <View style={[{ display: 'flex', alignItems: 'center', flexDirection: 'row', margin: '3px 0' }]}>
            <View style={[{ marginRight: '20px' }]}>
              <Text style={[styles.text, { marginBottom: '2px' }]} >Número</Text>
              <Text style={[styles.text]} >323254551</Text>
            </View>
            <View>
              <Text style={[styles.text, { marginBottom: '2px' }]} >Descrição: {informacoes && informacoes.descricao}</Text>
              <Text style={[styles.text]} >PEDIDO: 1212452112</Text>
            </View>
          </View>
          <View style={[{ margin: '10px 0' }]} >
            <View style={[styles.tableRowRecibimento]}>
              <Text style={[styles.tableText, { width: '10%', marginLeft: '0' }]}>Parcela</Text>
              <Text style={[styles.tableText, { width: '12%' }]}>Vencimento</Text>
              <Text style={[styles.tableText, { width: '12%' }]}>Pagamento</Text>
              <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>Valor</Text>
              <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>Juros</Text>
              <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>Multa</Text>
              <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>Desconto</Text>
              <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>Valor Pago</Text>
              <Text style={[styles.tableText, { width: '16%' }]}>Tipo</Text>
            </View>
            {recibimentos.length > 0 && recibimentos.map(recibimento => (
              <View style={[styles.tableRowRecibimento]} >
                <Text style={[styles.tableText, { width: '10%', marginLeft: '0' }]}>
                  {
                    //@ts-ignore
                    recibimento.parcela
                  }
                </Text>
                <Text style={[styles.tableText, { width: '12%' }]}>
                  {
                    //@ts-ignore
                    recibimento.dataVencimento
                  }
                </Text>
                <Text style={[styles.tableText, { width: '12%' }]}>
                  {
                    //@ts-ignore
                    recibimento.dataVencimento
                  }
                </Text>
                <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>
                  {
                    //@ts-ignore
                    maskMoeda(Number(recibimento.valor).toFixed(2))
                  }
                </Text>
                <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>
                  {
                    //@ts-ignore
                    maskMoeda(Number(recibimento.juros).toFixed(2))
                  }
                </Text>
                <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>
                  {
                    //@ts-ignore
                    maskMoeda(Number(recibimento.multa).toFixed(2))
                  }
                </Text>
                <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>
                  {
                    //@ts-ignore
                    maskMoeda(Number(recibimento.desconto).toFixed(2))
                  }
                </Text>
                <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>
                  {
                    //@ts-ignore
                    maskMoeda(Number(recibimento.valor).toFixed(2))
                  }
                </Text>
                <Text style={[styles.tableText, { width: '16%', marginLeft: '5px' }]}>
                  {
                    //@ts-ignore
                    recibimento.tipo
                  }
                </Text>
              </View>
            ))}
          </View>
          {lancamentos.length > 0 && (
            <View style={[{ margin: '10px 0' }]} >
              <Text style={[styles.titleBackground]} >Lançamentos</Text>
              <View style={[styles.tableRowRecibimento]}>
                <Text style={[styles.tableText, { width: '7%', marginLeft: '0' }]}>Número</Text>
                <Text style={[styles.tableText, { width: '10%' }]}>Pagamento</Text>
                <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>Valor</Text>
                <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>Juros</Text>
                <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>Multa</Text>
                <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>Desconto</Text>
                <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>Valor Pago</Text>
                <Text style={[styles.tableText, { width: '30%' }]}>Tipo</Text>
              </View>
              {lancamentos.map(l => (
                <View style={[styles.tableRowRecibimento]} >
                  <Text style={[styles.tableText, { width: '7%', marginLeft: '0', textAlign: 'center' }]}>
                    {
                      //@ts-ignore
                      l.numeroLancamento
                    }
                  </Text>
                  <Text style={[styles.tableText, { width: '10%' }]}>
                    {
                      //@ts-ignore
                      l.dataLancamento
                    }
                  </Text>
                  <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>
                    {
                      //@ts-ignore
                      maskMoeda(Number(l.valorLancamento).toFixed(2))
                    }
                  </Text>
                  <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>
                    {
                      //@ts-ignore
                      maskMoeda(Number(l.jurosLancamento).toFixed(2))
                    }
                  </Text>
                  <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>
                    {
                      //@ts-ignore
                      maskMoeda(Number(l.multaLancamento).toFixed(2))
                    }
                  </Text>
                  <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>
                    {
                      //@ts-ignore
                      maskMoeda(Number(l.descontoLancamento).toFixed(2))
                    }
                  </Text>
                  <Text style={[styles.tableText, { width: '10%', textAlign: 'right' }]}>
                    {
                      //@ts-ignore
                      maskMoeda(Number(l.totalPagoLancamento).toFixed(2))
                    }
                  </Text>
                  <Text style={[styles.tableText, { width: '30%', marginLeft: '5px' }]}>
                    {
                      //@ts-ignore
                      l.formaPagamento
                    }
                  </Text>
                </View>
              ))}
            </View>
          )}
        </View>
      </View>
      <View style={[{ display: 'flex', marginTop: '80px' }]}>
        <View style={[{ alignSelf: 'flex-end', width: '250px', borderBottom: '1px solid #333', position: 'relative' }]}>
          <Text style={[{ textAlign: 'center', fontSize: '10px', position: 'absolute', bottom: '-20px' }]}>Assinatura</Text>
        </View>
      </View>
    </Page >
  )
}

export const ReciboPDF = (
  <Document>
    <ReciboPDFContent />
  </Document>
)

const ReciboCupomContent: React.FC = () => {
  const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
  const [recibimentos, setRecibimentos] = useState<any[]>([])
  const [lancamentos, setLancamentos] = useState<any[]>([])
  const [informacoes, setInformacoes] = useState({
    nome: '',
    descricao: ''
  })

  const loadRecibimentos = async () => {
    const response = await axios.get(api.urlFinanceiro + `api/v1/titulos/buscaTitulos/${codMovTituloAux}`, {
      headers: { Authorization: bearerAuth }
    })
    const { nome, descricao, lancamentos } = response.data
    setRecibimentos(state => [...state, response.data])
    setInformacoes({
      descricao,
      nome
    })
    setLancamentos(lancamentos)

  }

  useEffect(() => {
    if (codMovTituloAux) {
      loadRecibimentos()
    }
  }, [codMovTituloAux])

  return (
    <Page size="A4" style={[stylesCupom.container]} >
      <View style={[stylesCupom.headerCupom]}>
        <Image style={[{ marginBottom: '3px' }]} src={Logo} />
        <Text style={[stylesCupom.headerText, { marginBottom: '3px' }]} >ERnetwork - Soluções em T.I.</Text>
        <Text style={[stylesCupom.headerText, { marginBottom: '3px' }]} >Rua do Comércio, 1650 Sala 24</Text>
        <Text style={[stylesCupom.headerText, { marginBottom: '3px' }]} >CEP: 14400-660 - FRANCA-SP</Text>
        <Text style={[stylesCupom.headerText, { marginBottom: '3px' }]} >CNPJ: 06.051.287/0001-65 - IE: 323.287.328</Text>
        <Text style={[{ marginBottom: '7px', marginTop: '10px', fontSize: '12px', fontWeight: 'black' }]} >Recibo de Pagamento</Text>
        <View style={[stylesCupom.headerFooter, { marginBottom: '3px' }]} >
          <Text style={[{ fontSize: '8px' }]}>Emissão: {moment(new Date()).format('DD/MM/YYYY HH:mm:ss')}</Text>
        </View>
      </View>
      <View style={[{ width: '180px' }]} >
        <Text style={[styles.titleBackground, { fontWeight: 'black', margin: '3px 0' }]} >Título</Text>
        <Text style={[stylesCupom.headerText, { fontSize: '8px', marginBottom: '3px' }]} >Número: 35454212</Text>
        <Text style={[stylesCupom.headerText, { fontSize: '8px', marginBottom: '3px' }]} >Descrição: { }</Text>
        <Text style={[stylesCupom.headerText, { fontSize: '8px', marginBottom: '3px' }]} >PEDIDO: 45451212</Text>
        <View>
          {recibimentos.length > 0 && recibimentos.map(recibimento => (
            <View style={[styles.tableRowRecibimento, { flexWrap: 'wrap' }]} >
              <View style={[{ width: '15%', marginRight: '2px' }]}>
                <Text style={[styles.tableText, { fontSize: '7px', width: '15%', marginLeft: '0' }]}>Parcela</Text>
                <Text style={[styles.tableText, { width: '15%', fontSize: '7px', marginLeft: '0' }]}>
                  {
                    //@ts-ignore
                    recibimento.parcela
                  }
                </Text>
              </View>
              <View style={[{ width: '25%', marginRight: '2px' }]}>
                <Text style={[styles.tableText, { fontSize: '7px', width: '25%' }]}>Vencimento</Text>
                <Text style={[styles.tableText, { width: '25%', fontSize: '7px' }]}>
                  {
                    //@ts-ignore
                    recibimento.dataVencimento
                  }
                </Text>
              </View>
              <View style={[{ width: '25%', marginRight: '2px' }]}>
                <Text style={[styles.tableText, { fontSize: '7px', }]}>Pagamento</Text>
                <Text style={[styles.tableText, { width: '25%', fontSize: '7px' }]}>
                  {
                    //@ts-ignore
                    recibimento.dataVencimento
                  }
                </Text>
              </View>
              <View style={[{ width: '10%', marginRight: '5px' }]}>
                <Text style={[styles.tableText, { fontSize: '7px', width: '10%', textAlign: 'right', }]}>Valor</Text>
                <Text style={[styles.tableText, { width: '10%', fontSize: '7px', textAlign: 'right' }]}>
                  {
                    //@ts-ignore
                    maskMoeda(Number(recibimento.valor).toFixed(2))
                  }
                </Text>
              </View>
              <View style={[{ width: '10%', marginRight: '2px' }]}>
                <Text style={[styles.tableText, { fontSize: '7px', width: '10%', textAlign: 'right', }]}>Juros</Text>
                <Text style={[styles.tableText, { width: '10%', fontSize: '7px', textAlign: 'right' }]}>
                  {
                    //@ts-ignore
                    maskMoeda(Number(recibimento.juros).toFixed(2))
                  }
                </Text>
              </View>
              <View style={[{ width: '10%', marginRight: '2px' }]}>
                <Text style={[styles.tableText, { fontSize: '7px', width: '10%', textAlign: 'right', marginTop: '10px' }]}>Multa</Text>
                <Text style={[styles.tableText, { width: '10%', fontSize: '7px', textAlign: 'right' }]}>
                  {
                    //@ts-ignore
                    maskMoeda(Number(recibimento.multa).toFixed(2))
                  }
                </Text>
              </View>
              <View style={[{ width: '20%', marginRight: '2px' }]}>
                <Text style={[styles.tableText, { fontSize: '7px', width: '20%', textAlign: 'right', marginTop: '10px' }]}>Desconto</Text>
                <Text style={[styles.tableText, { width: '20%', fontSize: '7px', textAlign: 'right' }]}>
                  {
                    //@ts-ignore
                    maskMoeda(Number(recibimento.desconto).toFixed(2))
                  }
                </Text>
              </View>
              <View style={[{ width: '23%', marginRight: '2px' }]}>
                <Text style={[styles.tableText, { fontSize: '7px', width: '23%', textAlign: 'right', marginTop: '10px' }]}>Valor Pago</Text>
                <Text style={[styles.tableText, { width: '23%', fontSize: '7px', textAlign: 'right' }]}>
                  {
                    //@ts-ignore
                    maskMoeda(Number(recibimento.valor).toFixed(2))
                  }
                </Text>
              </View>
              <View style={[{ width: '16%', }]}>
                <Text style={[styles.tableText, { fontSize: '7px', width: '16%', marginTop: '10px' }]}>Tipo</Text>
                <Text style={[styles.tableText, { width: '16%', fontSize: '7px' }]}>
                  {
                    //@ts-ignore
                    recibimento.tipo
                  }
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      {lancamentos.length > 0 && (
        <View style={[{ width: '180px' }]} >
          <Text style={[styles.titleBackground, { fontWeight: 'black', margin: '3px 0' }]} >Lançamentos</Text>
          <View>
            {lancamentos.map(l => (
              <View style={[styles.tableRowRecibimento, { flexWrap: 'wrap', paddingBottom: '10px', marginTop: '10px', borderBottom: '1px solid #333' }]} >
                <View style={[{ width: '15%', marginRight: '2px' }]}>
                  <Text style={[styles.tableText, { fontSize: '7px', width: '15%', marginLeft: '0' }]}>Número</Text>
                  <Text style={[styles.tableText, { width: '15%', fontSize: '7px', marginLeft: '0' }]}>
                    {
                      //@ts-ignore
                      l.numeroLancamento
                    }
                  </Text>
                </View>
                <View style={[{ width: '25%', marginRight: '2px' }]}>
                  <Text style={[styles.tableText, { fontSize: '7px', }]}>Pagamento</Text>
                  <Text style={[styles.tableText, { width: '25%', fontSize: '7px' }]}>
                    {
                      //@ts-ignore
                      l.dataLancamento
                    }
                  </Text>
                </View>
                <View style={[{ width: '20%', marginRight: '5px' }]}>
                  <Text style={[styles.tableText, { fontSize: '7px', width: '20%', textAlign: 'right', }]}>Valor</Text>
                  <Text style={[styles.tableText, { width: '20%', fontSize: '7px', textAlign: 'right' }]}>
                    {
                      //@ts-ignore
                      maskMoeda(Number(l.valorLancamento).toFixed(2))
                    }
                  </Text>
                </View>
                <View style={[{ width: '20%', marginRight: '2px' }]}>
                  <Text style={[styles.tableText, { fontSize: '7px', width: '20%', textAlign: 'right', }]}>Juros</Text>
                  <Text style={[styles.tableText, { width: '20%', fontSize: '7px', textAlign: 'right' }]}>
                    {
                      //@ts-ignore
                      maskMoeda(Number(l.jurosLancamento).toFixed(2))
                    }
                  </Text>
                </View>
                <View style={[{ width: '10%', marginRight: '2px' }]}>
                  <Text style={[styles.tableText, { fontSize: '7px', width: '10%', textAlign: 'right' }]}>Multa</Text>
                  <Text style={[styles.tableText, { width: '10%', fontSize: '7px', textAlign: 'right' }]}>
                    {
                      //@ts-ignore
                      maskMoeda(Number(l.multaLancamento).toFixed(2))
                    }
                  </Text>
                </View>
                <View style={[{ width: '20%', marginRight: '2px' }]}>
                  <Text style={[styles.tableText, { fontSize: '7px', width: '20%', textAlign: 'right', marginTop: '10px' }]}>Desconto</Text>
                  <Text style={[styles.tableText, { width: '20%', fontSize: '7px', textAlign: 'right' }]}>
                    {
                      //@ts-ignore
                      maskMoeda(Number(l.descontoLancamento).toFixed(2))
                    }
                  </Text>
                </View>
                <View style={[{ width: '23%', marginRight: '2px' }]}>
                  <Text style={[styles.tableText, { fontSize: '7px', width: '23%', textAlign: 'right', marginTop: '10px' }]}>Valor Pago</Text>
                  <Text style={[styles.tableText, { width: '23%', fontSize: '7px', textAlign: 'right' }]}>
                    {
                      //@ts-ignore
                      maskMoeda(Number(l.totalPagoLancamento).toFixed(2))
                    }
                  </Text>
                </View>
                <View style={[{ width: '16%', }]}>
                  <Text style={[styles.tableText, { fontSize: '7px', width: '16%', marginTop: '10px' }]}>Tipo</Text>
                  <Text style={[styles.tableText, { width: '16%', fontSize: '7px' }]}>
                    {
                      //@ts-ignore
                      l.formaPagamento
                    }
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      )}
      <View style={[{ display: 'flex', marginTop: '40px', width: '180px' }]}>
        <View style={[{ alignSelf: 'center', width: '90px', borderBottom: '1px dotted #333', position: 'relative' }]}>
          <Text style={[{ textAlign: 'center', fontSize: '7px', position: 'absolute', bottom: '-15px' }]}>Assinatura</Text>
        </View>
      </View>
    </Page>
  )
}

export const ReciboCupom = (
  <Document>
    <ReciboCupomContent />
  </Document>
)