import React from 'react'
import { BlobProvider, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import { Button, CardFooter, Col, Modal, ModalBody, Row } from 'reactstrap'
import { PedidoPDF, numPedidoPDF, CupomPDF } from '../PDF/PedidoPDF'
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome'

interface IModalImprimirPedidoProps {
    isOpen: any;
    toggle: any;
    callback: any;
    numPedido: any;
}

const ModalImprimirPedido: React.FC<IModalImprimirPedidoProps> = ({ isOpen, toggle, callback, numPedido }) => {
    return(
       <>
            <Modal
                isOpen={isOpen}
                toggle={() => toggle && toggle()}
                id="modalImprimirPedido"
            >
                <div className="modal-header justify-content-center">
                    <button
                        className="close"
                        type="button"
                        onClick={() => toggle && toggle()}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Impressão do Pedido</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>Deseja Imprimir o Pedido de Vendas em qual modelo?</label>
                    </Col>

                </ModalBody>
                <CardFooter style={{ backgroundColor: 'transparent' }}>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => {}}
                                style={{ width: '150px' }}
                            >
                                {//@ts-ignore
                                    <BlobProvider document={CupomPDF} >
                                        {({blob, url}) => (
                                            //@ts-ignore
                                            <a href={url} target="_blank" className="btn-imprimir" >
                                                <div>
                                                    <Icon icon="receipt" />
                                                </div>
                                                <span>Cupom</span>
                                            </a>
                                        )}
                                    </BlobProvider>
                                } 
                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                style={{ width: '150px' }}
                                onClick={() => { }}
                            >
                                {//@ts-ignore
                                    <BlobProvider document={PedidoPDF} >
                                        {({blob, url}) => (
                                            //@ts-ignore
                                            <a href={url} target="_blank" className="btn-imprimir"  >
                                                <div>
                                                    <Icon icon="print" />
                                                </div>
                                                <span>A4</span>
                                            </a>
                                        )}
                                    </BlobProvider>
                                } 
                                
                            </Button>
                        </Col>
                    </Row>

                </CardFooter>
            </Modal>
            {isOpen && numPedidoPDF(numPedido)}
       </>
    )
}

export default ModalImprimirPedido