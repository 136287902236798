import React, { useState, useEffect } from "react";
import classNames from "classnames";

import {
    Button,
    ButtonGroup,
} from "reactstrap";

import { shallowEqual, useSelector } from "react-redux";
import useShallowEqualSelector from "../../../Util/Hooks/useShallowEqualSelector";
import AppState from '../../../store/storeType';
import * as actions from '../../../store/reducers/Configuracao/actions'
import { IConfiguracaoState } from "../../../store/reducers/Configuracao/types";

interface Tab {
    tabName: String,
    tabScreen: JSX.Element
}

interface Props {
    tabs: Tab[]
}

const TabsContent = function (props: Props) {

    const screen = useShallowEqualSelector<AppState, IConfiguracaoState['screen']>(state => state.configuracao.screen)
    const [stateScreen, setStateScreen] = useState(0)

    useEffect(() => {
        setStateScreen(screen)
    }, [screen])

    return (
        <>
            <ButtonGroup align="center" style={{ width: '100%' }} >
                {props.tabs && props.tabs.map((item: Tab, index: number) => {
                    return (
                        <>
                            <Button
                                color={screen === index ? "info" : ""}
                                type="button"
                                data-toggle="tab"
                                className={classNames("btn-gp-padrao", screen === index ? "active" : "")}
                                onClick={() => { actions.setScreen(index) }}
                                style={{ width: '8em' }}
                            >
                                {item.tabName}
                            </Button>
                        </>
                    )
                })}
            </ButtonGroup>
            <br />
            <br />
            {props.tabs[screen].tabScreen}
        </>
    );
}

export default TabsContent;