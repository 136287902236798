import Dashboard from "./views/Dashboard/Dashboard";
import Login from "views/Login.jsx";
import NovaSenha from "views/EsqueciSenha.jsx";
import Usuarios from "views/Usuarios/Usuarios";
import Clientes from "views/Clientes/Clientes.jsx";
import Fornecedores from "views/Fornecedores/Fornecedores.jsx";
import GruposProduto from "views/GruposProduto/Grupos.jsx";
import SubgruposProduto from "views/SubgruposProduto/Subgrupos.jsx";
import Produtos from "views/Produtos/Produtos";
import Servicos from "views/Servicos/Servicos";
import Grades from "views/Grades/Grades.jsx";
import PerfilUsuario from "views/PerfilUsuario/PerfilUsuario.jsx";
import Transportadoras from "./views/Transportadoras/Transportadoras";
import CESTs from "./views/CESTs/CESTs";
import NCMs from "./views/NCMs/NCMs";
import ICMSs from "./views/ICMSs/ICMSs";
import Classificacoes from "./views/ClassificacoesTributarias/ClassificacoesTributarias";
import CFOPs from "./views/CFOPs/CFOPs";
import IPIs from "./views/IPIs/IPIs";
import EnquadramentosIPI from "./views/EnquadramentosIPI/EnquadramentosIPI";
import PISCOFINSs from "./views/PISCOFINSs/PISCOFINSs";
import Contas from "./views/Contas/Contas";
import Operacoes from "./views/Operacao/Operacoes";
import TiposPagamentos from "./views/TiposPagamentos/TiposPagamentos";
import FormasPagamentos from "./views/FormasPagamentos/FormasPagamentos";
import PedidoVenda from "./views/Movimentacao/PedidoVenda/PedidoVenda";
import SaidasDiversas from "./views/Movimentacao/SaidasDiversas/SaidasDiversas";
import SubGrupoContas from "./views/SubgrupoConta/SubgrupoContas";
import GrupoContas from "./views/GrupoContas/GrupoContas";
import EntradaMercadoria from "./views/Movimentacao/EntradaMercadoria/EntradaMercadoria";
import AberturaCaixas from "./views/AberturaCaixa/AberturaCaixas";
import FechamentoCaixas from "./views/FechamentoCaixa/FechamentoCaixas";
import ProdutosDescontinuados from "./views/ProdutosDescontinuados/ProdutosDescontinuado";
import LancamentoTitulos from "./views/LancamentoTitulos/LacamentoTitulos";
import Inventario from "./views/Inventario/Inventario";
import Settings from "./views/Configuracao/Configuracao";
import RecebimentosPagamentos from "./views/Financeiro/Recebimento/RecebimentosPagamentos";
import PesquisaPDV from "./views/Pesquisa/PedidoVenda";
const routes = [{
        path: "/pesquisar",
        name: "Buscar",
        icon: "tim-icons icon-zoom-split",
        required: true,
        component: null,
        layout: ""
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        id: 1,
        icon: "tim-icons icon-chart-pie-36",
        component: Dashboard,
        layout: ""
    },
    {
        collapse: true,
        name: "Cadastro",
        icon: "tim-icons icon-pencil",
        state: "cadastroCollapse",
        views: [{
                collapse: true,
                name: "Pessoas",
                icon: " tim-icons icon-badge",
                state: "pessoasCollapse",
                className: "sideBarSubMenu",
                views: [

                    {
                        path: "/clientes",
                        name: "Clientes",
                        id: 2,
                        mini: "CL",
                        component: Clientes,
                        layout: ""
                    },

                    {
                        path: "/fornecedores",
                        name: "Fornecedores",
                        id: 3,
                        mini: "FO",
                        component: Fornecedores,
                        layout: ""
                    },
                    {
                        path: "/transportadoras",
                        name: "Transportadoras",
                        id: 4,
                        mini: "TR",
                        component: Transportadoras,
                        layout: ""
                    },
                    {
                        path: "/usuarios",
                        name: "Usuários",
                        id: 5,
                        mini: "US",
                        component: Usuarios,
                        layout: ""
                    },
                ]

            },
            {
                collapse: true,
                name: "Produtos/Serviços",
                icon: " tim-icons icon-bag-16",
                state: "prodServCollapse",
                className: "sideBarSubMenu",
                views: [{
                        path: "/produtos",
                        name: "Produtos",
                        id: 6,
                        mini: "PR",
                        component: Produtos,
                        layout: ""
                    },
                    {
                        path: "/servicos",
                        name: "Serviços",
                        id: 7,
                        mini: "SE",
                        component: Servicos,
                        layout: ""
                    },
                    {
                        path: "/gruposProduto",
                        name: "Grupos",
                        id: 8,
                        mini: "GR",
                        component: GruposProduto,
                        layout: ""
                    },
                    {
                        path: "/subgruposProduto",
                        name: "Subgrupos",
                        id: 9,
                        mini: "SG",
                        component: SubgruposProduto,
                        layout: ""
                    },
                    {
                        path: "/grades",
                        name: "Grades",
                        id: 10,
                        mini: "GR",
                        component: Grades,
                        layout: ""
                    },
                ]

            },
            {
                collapse: true,
                name: "Fiscal",
                icon: " tim-icons icon-coins",
                state: "FiscalCollapse",
                className: "sideBarSubMenu",
                views: [{
                        path: "/classificacoes",
                        name: "Classificações Tributárias",
                        id: 13,
                        mini: "CT",
                        component: Classificacoes,
                        layout: ""
                    },

                    {
                        path: "/operacoes",
                        name: "Operações",
                        id: 14,
                        mini: "OP",
                        component: Operacoes,
                        layout: ""
                    },
                    {
                        path: "/prodDescontinuado",
                        name: "NCM Descontinuado",
                        id: 11,
                        mini: "ND",
                        component: ProdutosDescontinuados,
                        layout: ""
                    },
                ]
            },
            {
                collapse: true,
                name: "Impostos",
                icon: " tim-icons icon-bank",
                state: "ImpostosCollapse",
                className: "sideBarSubMenu",
                views: [{
                        path: "/CFOPs",
                        name: "CFOPs",
                        id: 17,
                        mini: "CF",
                        component: CFOPs,
                        layout: ""
                    },
                    {
                        path: "/icmss",
                        name: "ICMS",
                        id: 19,
                        mini: "IM",
                        component: ICMSs,
                        layout: ""
                    },
                    {
                        path: "/IPIs",
                        name: "IPIs",
                        id: 20,
                        mini: "IP",
                        component: IPIs,
                        layout: ""
                    },
                    {
                        path: "/enquadramentosIPI",
                        name: "Enquadramentos IPI",
                        id: 18,
                        mini: "EI",
                        component: EnquadramentosIPI,
                        layout: ""
                    },
                    {
                        path: "/PISCOFINSs",
                        name: "PIS/COFINS",
                        id: 21,
                        mini: "PC",
                        component: PISCOFINSs,
                        layout: ""
                    },
                    {
                        path: "/cests",
                        name: "CESTs",
                        id: 15,
                        mini: "CE",
                        component: CESTs,
                        layout: ""
                    },
                    {
                        path: "/ncms",
                        name: "NCMs",
                        id: 16,
                        mini: "NC",
                        component: NCMs,
                        layout: ""
                    },
                ]
            },
            {
                collapse: true,
                name: "Financeiro",
                icon: " tim-icons icon-wallet-43",
                state: "outrosCollapse",
                className: "sideBarSubMenu",
                views: [{
                        path: "/tiposPagamentos",
                        name: "Tipos de Pagamentos",
                        id: 22,
                        mini: "TP",
                        component: TiposPagamentos,
                        layout: ""
                    },
                    {
                        path: "/formasPagamentos",
                        name: "Formas de Pagamentos",
                        id: 23,
                        mini: "FP",
                        component: FormasPagamentos,
                        layout: ""
                    },
                    {
                        path: "/GrupoContas",
                        name: "Grupo de Contas",
                        id: 24,
                        mini: "GC",
                        component: GrupoContas,
                        layout: ""
                    },
                    {
                        path: "/subgrupoContas",
                        name: "Subgrupo de contas",
                        id: 25,
                        mini: "SC",
                        component: SubGrupoContas,
                        layout: ""
                    },
                    {
                        path: "/contas",
                        name: "Contas",
                        id: 26,
                        mini: "CC",
                        component: Contas,
                        layout: ""
                    },
                ]
            }
        ]
    },

    {
        collapse: true,
        name: "Movimentação",
        icon: "tim-icons icon-chart-bar-32",
        state: "movimentacaoCollapse",
        views: [{
                path: "/entradaMercadorias",
                name: "Entrada de Mercadorias",
                id: 101,
                mini: "EM",
                component: EntradaMercadoria,
                layout: ""
            },
            {
                path: "/saidasDiversas",
                name: "Saídas Diversas ",
                id: 102,
                mini: "SD",
                component: SaidasDiversas,
                layout: ""
            },
            {
                path: "/pedidoVenda",
                name: "Pedido de Venda",
                id: 103,
                mini: "PV",
                component: PedidoVenda,
                layout: ""
            },
            {
                path: "/inventario",
                name: "Inventário",
                id: 104,
                mini: "IV",
                component: Inventario,
                layout: ""
            },
        ]
    },
    {
        collapse: true,
        name: "Financeiro",
        icon: "tim-icons icon-money-coins",
        state: "financeiroCollapse",
        views: [{
                collapse: true,
                name: "Caixa",
                icon: " tim-icons icon-badge",
                state: "aberturaCollapse",
                className: "sideBarSubMenu",
                views: [{
                        path: "/aberturaCaixas",
                        name: "Abertura de Caixa",
                        id: 151,
                        mini: "AC",
                        component: AberturaCaixas,
                        layout: ""
                    },
                    {
                        path: "/fechamentoCaixas",
                        name: "Fechamento de Caixa",
                        id: 152,
                        mini: "FC",
                        component: FechamentoCaixas,
                        layout: ""
                    },
                ]
            },
            {
                collapse: true,
                name: "Financeiro",
                icon: "tim-icons icon-money-coins",
                state: "financeiroCollapse",
                className: "sideBarSubMenu",
                views: [{
                    path: "/recebimentos",
                    name: "Recebimentos",
                    mini: "RE",
                    component: RecebimentosPagamentos,
                    layout: ""
                }, ]
            },
            {
                path: "/recebimentos",
                name: "Receber/Pagar Títulos",
                id: 153,
                mini: "RE",
                component: null,
                layout: ""
            },
            {
                path: "/lancamentoTitulos",
                name: "Lançamento de Títulos",
                id: 155,
                mini: "LT",
                component: LancamentoTitulos,
                layout: ""
            },
            {
                path: "/mapaFinanceiro",
                name: "Mapa Financeiro",
                id: 156,
                mini: "MP",
                component: null,
                layout: ""
            },
        ]
    },
    {
        collapse: true,
        name: "Pesquisa",
        icon: "tim-icons icon-notes",
        state: "pesquisaCollapse",
        views: [{
                path: "/pesqPedidoVendas",
                name: "Pedido de Venda",
                id: 201,
                mini: "PV",
                component: PesquisaPDV,
                layout: ""
            },
        ]
    },
    {
        collapse: true,
        name: "Relatórios",
        icon: "tim-icons icon-notes",
        state: "relatioriosCollapse",
        views: [{
            path: "/vendas",
            name: "Vendas",
            id: '',
            mini: "VE",
            component: null,
            layout: ""
        }, ]
    },
    {
        path: "/login",
        required: true,
        component: Login,
        layout: "/auth",
        redirect: true
    },
    {
        path: "/novaSenha",
        component: NovaSenha,
        required: true,
        layout: "/auth",
        redirect: true
    },
    {
        path: "/perfil",
        component: PerfilUsuario,
        required: true,
        layout: "",
        redirect: true
    },
    {
        path: "/configuracao",
        component: Settings,
        required: true,
        layout: "",
        redirect: true
    }
];

export default routes;