import store from "../../stores";

import {
    DashboardAction,
    IDashboardState,
    DashboardTypes,
    DashboardStore

} from "./types";


export const setBigChartData = (bigChartData: IDashboardState['bigChartData']) => store.dispatch<DashboardAction>({
    idStore: DashboardStore.ID_STORE,
    type: DashboardTypes.BIGCHARTDATA,
    bigChartData

});

export const setHighGrafic = (highGrafic: IDashboardState['highGrafic']) => store.dispatch<DashboardAction>({
    idStore: DashboardStore.ID_STORE,
    type: DashboardTypes.HIGHGRAFIC,
    highGrafic

})
