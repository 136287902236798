import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";

const idStore = 'TIPOS_PAGAMENTOS';

export function Pesquisar(notificacao, dispatch, pesquisa) {
    try {
        dispatch({ idStore, type: 'TIPOS_PGTOS', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/tiposPgto/tiposPgtos?status=${pesquisa.ativos}&descricao=${pesquisa.descricao}&flTipo=${pesquisa.flTipos}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum tipo de pagamento encontrado", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'TIPOS_PGTOS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os tipos de pagamentos", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetTipoPgto(notificacao, dispatch, codTipoPgto, thenFunction){
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ":" + localStorage.getItem('TOKEN_KEY'))

        await axios({
            method: 'get',
            url: api.urlCadastro + `api/v1/tiposPgto/tipoPgto?codTipoPgto=${codTipoPgto}`, 
            headers: { Authorization: basicAuth }
        }).then(function (response){ 
                let type = 'TIPO_PGTO';
                dispatch({ idStore, type, campo: 'codTipoPgto', value: response.data.records.codTipoPgto })
                dispatch({ idStore, type, campo: 'descricao', value: response.data.records.descricao })
                dispatch({ idStore, type, campo: 'flTipo', value: response.data.records.flTipo })
                dispatch({ idStore, type, campo: 'status', value: response.data.records.status })
                dispatch({ idStore, type: 'TIPOS_PGTOS_COPIA' })
                thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export async function CreateTipoPgto(notificacao, dispatch, tipoPgto){
    
    try{
        const basicAuth = 'Basic ' + btoa(api.apiUser + ":" + localStorage.getItem('TOKEN_KEY'))

        let invalido = false

        if (tipoPgto.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }
        if (tipoPgto.flTipo === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'flTipo', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/tiposPgto/create`,
            headers: { Authorization: basicAuth }, 
            data: {
                Descricao: tipoPgto.descricao,
                FlTipo: tipoPgto.flTipo
            }
        }).then( response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'TIPO_PGTO', campo: 'codTipoPgto', value: response.data.records })
            dispatch({ idStore, type: 'TIPOS_PGTOS_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function UpdateTipoPgto(notificacao, dispatch, tipoPgto, tiposPgtoCopia){
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(tipoPgto) === JSON.stringify(tiposPgtoCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ":" + localStorage.getItem('TOKEN_KEY'))

        let invalido = false

        if (tipoPgto.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }
        if (tipoPgto.flTipo === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'flTipo', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios ({
            method: 'put',
            url: api.urlCadastro + `api/v1/tiposPgto/update?codTipoPgto=${tipoPgto.codTipoPgto}`,
            headers: { Authorization: basicAuth },
            data: {
                Descricao: tipoPgto.descricao,
                FlTipo: tipoPgto.flTipo
            }
        }).then ( response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'TIPO_PGTO', campo: 'codTipoPgto', value: response.data.records })
            dispatch({ idStore, type: 'TIPOS_PGTOS_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function InativarTipoPgto(notificacao, dispatch, tipoPgtoSelected) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/tiposPgto/inativar?codTipoPgto=${tipoPgtoSelected.codTipoPgto}&status=${tipoPgtoSelected.status}`,
            headers: { Authorization: basicAuth }
        }).then(response => {
            dispatch({idStore, type: 'TIPO_PGTO', campo: 'status', value: tipoPgtoSelected.status === 0 ? 1 : 0})
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
        }).catch(e => {

            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function ExcluirTipoPgto(notificacao, tipoPgtoSelected) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/tiposPgto/excluir?codTipoPgto=${tipoPgtoSelected.codTipoPgto}`,
            headers: { Authorization: basicAuth }
        }).then(response => {
           
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}