import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import useResize from "../../../../../Util/Hooks/useResize";
import { OperacaoBox } from "../../Components/index";
import ModalOperacao from '../../Modals/ModalOperacao'
import useModal from "../../../../../Util/Hooks/useModal";
import DadosNota from "./DadosNota"
import DadosFornecedor from "./DadosFornecedor"
import DadosTransportadora from "./DadosTransportadora"
import ModalFaturamento from '../../../../../components/Modals/Faturamento'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import * as controllerEntrada from "../../../../../controller/Movimentacao/controllerEntradaMercadoria"
import { Col, Button, Row, FormGroup } from "reactstrap";

const idStore = "ENTRADA_MERCADORIA";

const DadosIniciais = function () {
    const dispatch = useDispatch()
    const [modalOperacao, toggleOperacao] = useModal(false)
    const { width } = useResize()
    const dadosNota = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.dadosIniciais);
    const operacao = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.dadosIniciais.operacao);
    const verCadastro = useShallowEqualSelector(state => state.entradaMercadoria.visualizarEntrada);
    const [modalFaturamento, toogleFaturamento] = useModal(false)

    useEffect(() => {
        if (!dadosNota.numEntrada) {
            pesquisaOperacao(operacao)
            toggleOperacao()
        }
    }, [])

    useEffect(() => {
        var data = new Date()
        var mes = data.getMonth() + 1 < 10 ? `0${data.getMonth() + 1}` : data.getMonth() + 1
        var dia = data.getDate() < 10 ? `0${data.getDate()}` : data.getDate()

        dispatch({ idStore, type: 'DADOS_INICIAIS', campo: 'dataEntrada', value: `${data.getFullYear()}-${mes}-${dia}` })
    }, [dadosNota.dataEntrada])

    function LimparDados() {
        dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'CLEAN_CADASTRO' })
    }

    function toggleModalOperacao() {
        toggleOperacao()
        dispatch({ idStore, type: 'OPERACOES', value: [] })
    }


    function confirmModal(operacao) {
        dispatch({ idStore, type: 'OPERACAO', value: { codOperacao: operacao.codOperacao, numeroOperacao: operacao.numeroOperacao, descricaoOperacao: operacao.descricaoOperacao, geraFinanceiro: operacao.geraFinanceiro } })
        toggleOperacao()
        dispatch({ idStore, type: 'OPERACOES', value: [] })
    }

    function pesquisaOperacao(operacao) {
        controllerEntrada.GetOperacoes(operacao)
    }

    return (
        <>
            <Col lg="12">
                <Col lg="12">
                    <Row style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%', marginTop: 4 }} >
                        <Button
                            type={"button"}
                            color={"info"}
                            style={verCadastro ? { pointerEvents: "none" } : null}
                        >
                            <Icon icon="download" className="fa-md" color="#FFF" pull="left" />{" "}
                            IMPORTAR XML
                        </Button>
                        <Button
                            type={"button"}
                            color={"info"}
                            onClick={() => LimparDados()}
                            style={verCadastro ? { pointerEvents: "none" } : null}
                        >
                            <Icon icon="plus-circle" className="fa-lg" color="#FFF" pull="left" />{" "}
                            NOVO
                        </Button>
                        <Button
                            type={"button"}
                            color={"info"}
                            onClick={() => !dadosNota.numEntrada ? controllerEntrada.SalvarEntrada() : controllerEntrada.UpdateEntrada()}
                            style={verCadastro ? { pointerEvents: "none" } : null}
                        >
                            <Icon icon="save" className="fa-lg" color="#FFF" pull="left" />
                            Salvar
                        </Button>
                        <Button
                            type={"button"}
                            color={"green"}
                            onClick={() => controllerEntrada.FinalizarEntrada(toogleFaturamento)}
                            style={verCadastro ? { pointerEvents: "none" } : null}
                        >
                            <Icon icon="dollar-sign" className="fa-lg" color="#FFF" pull="left" />{" "}
                            Finalizar
                        </Button>
                    </Row>
                </Col>

                <Row>

                    <DadosNota />

                    <Col lg="3">
                        <Row style={{ marginTop: width < 995 ? 50 : 0 }}>
                            <Col style={{ paddingTop: "10px" }}>
                                <FormGroup>
                                    <OperacaoBox onIconPress={() => toggleOperacao()} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <DadosFornecedor />
                </Row>
                <Row>
                    <DadosTransportadora />
                </Row>

                <br />
            </Col>

            {
                modalOperacao ? <ModalOperacao isOpen={true} toggle={toggleModalOperacao} getOperacao={pesquisaOperacao} confirmModal={confirmModal} /> : null
            }
            {
                modalFaturamento ? <ModalFaturamento isOpen={true} toogle={toogleFaturamento} /> : null
            }
        </>
    )
}

class Wizard extends React.Component {
    render() {
        return (
            <DadosIniciais />
        )
    }
}

export default Wizard