import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";

const idStore = 'CFOPS';

export function Pesquisar(notificacao, dispatch, codEmpresa, pesquisa) {
    try {
        dispatch({ idStore, type: 'CFOPS', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/cfop/cfops?codEmpresa=${codEmpresa}&status=${pesquisa.ativos}&cfop=${pesquisa.cfop}&descricao=${pesquisa.descricao}&tipoCfop=${pesquisa.tipoCfop === '0' ? '' : pesquisa.tipoCfop}&estadual=${pesquisa.estadual === '0' ? '' : pesquisa.estadual}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum CFOP encontrado", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'CFOPS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os CFOPs", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetCFOP(notificacao, dispatch, codEmpresa, codCfop, thenFunction) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/cfop/cfop?codEmpresa=${codEmpresa}&codCfop=${codCfop}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            let type = 'CFOP';
            dispatch({ idStore, type, campo: 'codCfop', value: codCfop })
            dispatch({ idStore, type, campo: 'cfop', value: response.data.records.cfop })
            dispatch({ idStore, type, campo: 'descricao', value: response.data.records.descricao })
            dispatch({ idStore, type, campo: 'tipoCfop', value: response.data.records.tipoCfop })
            dispatch({ idStore, type, campo: 'estadual', value: response.data.records.estadual })
            dispatch({ idStore, type, campo: 'status', value: response.data.records.status })
            dispatch({ idStore, type: 'CFOP_COPIA' })
            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export async function CreateCFOP(notificacao, dispatch, codEmpresa, cfop) {
    try {
        //Retorna se houve erro ou nao
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;
        if (cfop.cfop === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'cfop', value: true })
            invalido = true
        }
        if (cfop.tipoCfop === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'tipoCfop', value: true })
            alertar(notificacao, 'br', "Selecione o tipo", 'info', 'icon-bulb-63', 2);
            invalido = true
        }
        if (cfop.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/cfop/create?codEmpresa=${codEmpresa}`,
            headers: { Authorization: basicAuth },
            data: {
                Cfop: cfop.cfop.replace('.', ''),
                Descricao: cfop.descricao,
                TipoCfop: cfop.tipoCfop,
                Estadual: cfop.estadual,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'CFOP', campo: 'codCfop', value: response.data.records })
            dispatch({ idStore, type: 'CFOP_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function UpdateCFOP(notificacao, dispatch, codEmpresa, cfop, cfopCopia) {
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(cfop) === JSON.stringify(cfopCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;
        if (cfop.cfop === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'cfop', value: true })
            invalido = true
        }
        if (cfop.tipoCfop === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'tipo', value: true })
            alertar(notificacao, 'br', "Selecione o tipo", 'info', 'icon-bulb-63', 2);
            return true
        }
        if (cfop.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/cfop/update?codEmpresa=${codEmpresa}&codCfop=${cfop.codCfop}`,
            headers: { Authorization: basicAuth },
            data: {
                Cfop: cfop.cfop.replace('.', ''),
                Descricao: cfop.descricao,
                TipoCfop: cfop.tipoCfop,
                Estadual: cfop.estadual,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'CFOP', campo: 'codCfop', value: response.data.records })
            dispatch({ idStore, type: 'CFOP_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export function Excluir(notificacao, dispatch, codEmpresa, cfop, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/cfop/delete?codEmpresa=${codEmpresa}&codCfop=${cfop.codCfop}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar(notificacao, dispatch, codEmpresa, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}