import {

    ServicoTypes,
    ServicoStore,
    ServicoAction,
    IServicoState

} from "./types";

const INITIAL_STATE: IServicoState = {

    pesquisa: {
        codServico: '',
        numeroServico: '',
        codGrupo: '0',
        codSubgrupo: '0',
        nome: '',
        ativos: 2
    },
    servico: {
        codServico: '',
        numeroServico: '0',
        codGrupo: '0',
        grupo: '',
        codSubgrupo: '0',
        subgrupo: '',
        nome: '',
        descricao: '',
        valor: '',
        obs: '',
        status: 0
    },
    invalido: {
        nome: false,
        descricao: false,
        valor: false,
        obs: false,
    },
    grupos: [],
    pesqsubgrupos: [],
    cadsubgrupos: [],
    servicos: [],
    inicioCadastro: false,
    verCadastro: false,
    servicoCopia: {},
    screen: 0,
    currentStep: 0
}


const servico = (state = INITIAL_STATE, action: ServicoAction) => {
    switch (action.idStore) {
        case ServicoStore.ID_STORE:
            switch (action.type) {

                case ServicoTypes.PESQUISA:
                    return { ...state, pesquisa: action.pesquisa }

                case ServicoTypes.SERVICOS:
                    return { ...state, servicos: action.servicos }

                case ServicoTypes.GRUPOS:
                    return { ...state, grupos: action.grupos }

                case ServicoTypes.SUBGRUPOS_PESQ:
                    return { ...state, pesqsubgrupos: action.pesqsubgrupos }

                case ServicoTypes.SUBGRUPOS_CAD:
                    return { ...state, cadsubgrupos: action.cadsubgrupos }

                case ServicoTypes.SERVICO:
                    return { ...state, servico: action.servico }

                case ServicoTypes.INVALIDO:
                    return { ...state, invalido: action.invalido }

                case ServicoTypes.SCREEN:
                    return { ...state, screen: action.screen }

                case ServicoTypes.VER_CADASTRO:
                    return { ...state, verCadastro: action.verCadastro }

                case ServicoTypes.WIZARD:
                    return { ...state, currentStep: action.currentStep }

                case ServicoTypes.SERVICO_COPIA:
                    return { ...state, servicoCopia: action.servico }

                case ServicoTypes.CLEAN_SEARCH:
                    return {
                        ...state,
                        pesquisa: INITIAL_STATE.pesquisa,
                        servicos: INITIAL_STATE.servicos
                    }

                case ServicoTypes.CLEAN_SERVICO:
                    return { ...state, servico: INITIAL_STATE.servico }

                case ServicoTypes.CLEAN_INVALIDO:
                    return { ...state, invalido: INITIAL_STATE.invalido }

                case ServicoTypes.CLEAN_SERVICOS:
                    return { ...state, servicos: INITIAL_STATE.servicos }

                case ServicoTypes.CLEAN_GRUPOS:
                    return { ...state, grupos: INITIAL_STATE.grupos }

                case ServicoTypes.CLEAN_CADSUBGRUPOS:
                    return { ...state, cadsubgrupos: INITIAL_STATE.cadsubgrupos }

                default:
                    return { ...state }
            }

        default:
            return { ...state }
    }






}


export default servico
