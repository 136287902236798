import React, { useEffect } from "react";
import { Button, Col, Row, Nav, Input } from "reactstrap";
import { Select } from "../../../../../components/ErnComponets/ErnComponets";
import { IRecebimentoPagamentoState } from "../../../../../store/reducers/Financeiro/RecebimentoPagamento/types";
import AppState from "../../../../../store/storeType";
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import { MiniCard } from "../../Components/MiniCard";
import Step1 from "../../RecebimentosPagamentosSteps/RecebimentosPagamentos/index";

import * as controllerRecebimentosPagamentos from '../../../../../controller/Financeiro/controllerRecebimentosPagamentos';
import * as action from '../../../../../store/reducers/Financeiro/RecebimentoPagamento/actions'
import { maskMoeda } from "../../../../../Util/mascaras";
import ReactTable from "react-table";
import { removeMaskMoney } from "../../../../../Util/removeMascaras";
import { useCallback } from "react";
import { useState } from "react";
import store from "../../../../../store/stores";

import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome'
import useScrollBar from "../../../../../Util/Hooks/useScrollBar";
import moment from "moment";

var steps = [
  {
    stepName: "Recebimentos Pagamentos",
    stepIcon: "tim-icons icon-notes",
    component: Step1,
    stepProps: {},
  },
];

const RecebimentosPagamentos = function() {
	const contas = useShallowEqualSelector<AppState,IRecebimentoPagamentoState['contas']>(state => state.recebimentoPagamento.contas);
  const formasPagamentos = useShallowEqualSelector<AppState,IRecebimentoPagamentoState["formasPagamentos"]>((state) => state.recebimentoPagamento.formasPagamentos);
	const informacoesRecebimentosPagamentos = useShallowEqualSelector<AppState,IRecebimentoPagamentoState['informacoesRecebimentosPagamentos']>((state) => state.recebimentoPagamento.informacoesRecebimentosPagamentos);
  const [valorMultaTransformReais, setValorMultaTransformReais] = useState(0)
  const [valorJurosTransformReais, setValorJurosTransformReais] = useState(0)
  const [sumTotals, setSumTotals] = useState({
    totalTitulo: 0,
    totalJuros: 0,
    totalMulta: 0,
    totalDescontos: 0,
    totalLançado: 0,
    totalPago: 0,
    totalRestante: 0
  })
  const refTableLancamentos = useScrollBar()

	useEffect(() => {
    controllerRecebimentosPagamentos.getCaixaAberto()
		if(informacoesRecebimentosPagamentos){
      controllerRecebimentosPagamentos.getByIdRecebimentosPagamentos(informacoesRecebimentosPagamentos.codigoTituloMovimento, informacoesRecebimentosPagamentos.typeAction )
    }
	}, [])

  useEffect(() => {
    if(informacoesRecebimentosPagamentos.valorLancar) {
      let valorMultaTransformReais = (Number(informacoesRecebimentosPagamentos.valorMulta) * Number(removeMaskMoney(informacoesRecebimentosPagamentos.valorLancar))) / 100
      
      let valorJurosTransformReais = (Number(informacoesRecebimentosPagamentos.valorJuros) * Number(removeMaskMoney(informacoesRecebimentosPagamentos.valorLancar))) / 100
      setValorMultaTransformReais(valorMultaTransformReais)
      setValorJurosTransformReais(valorJurosTransformReais)
    }
  }, [informacoesRecebimentosPagamentos.valorLancar, informacoesRecebimentosPagamentos.valorMulta, informacoesRecebimentosPagamentos.valorJuros])

  const handleSetCodForma = (codForma: number, descricao: string) => {
    action.setInformacoesRecebimentosPagamentos({...informacoesRecebimentosPagamentos, codigoForma: codForma, descricaoForma: descricao})
  }

  const handleLancar = async () => {
    await action.setInformacoesRecebimentosPagamentos({...informacoesRecebimentosPagamentos, valorReais: { valorMultaReais: valorMultaTransformReais, valorJurosReais: valorJurosTransformReais } })
    const newInformacoes = store.getState().recebimentoPagamento.informacoesRecebimentosPagamentos
    controllerRecebimentosPagamentos.lancarRecebimentosPagamentosCompletos(newInformacoes)
  }

  const reduceSum = useCallback((data: any[], ) => {
    let itensSum = data.reduce((itemValue, value) => {
      return Number(itemValue) + Number(value)
    }, 0)
    return itensSum
  }, [])

  useEffect(() => {
      const sumTotalLancamento = informacoesRecebimentosPagamentos.lancamentos.map((l: any) => l.valorLancamento)
      const reduceSumTotalLancamento = reduceSum(sumTotalLancamento)
      const sumTotalJuros = informacoesRecebimentosPagamentos.lancamentos.map((l: any) => l.jurosLancamento)
      const reduceSumTotalJuros = reduceSum(sumTotalJuros)
      const sumTotalMulta = informacoesRecebimentosPagamentos.lancamentos.map((l: any) => l.multaLancamento)
      const reduceSumTotalMulta = reduceSum(sumTotalMulta)
      const sumTotalDesconto = informacoesRecebimentosPagamentos.lancamentos.map((l: any) => l.descontoLancamento)
      const reduceSumTotalDesconto = reduceSum(sumTotalDesconto)
      const sumTotalPago = informacoesRecebimentosPagamentos.lancamentos.map((l: any) => l.totalPagoLancamento)
      const reduceSumTotalPago = reduceSum(sumTotalPago)

      setSumTotals({
        ...sumTotals,
        totalLançado: reduceSumTotalLancamento,
        totalJuros: reduceSumTotalJuros,
        totalMulta: reduceSumTotalMulta,
        totalDescontos: reduceSumTotalDesconto,
        totalPago: reduceSumTotalPago,
        totalRestante: (Number(reduceSumTotalPago) - Number(removeMaskMoney(informacoesRecebimentosPagamentos.valorTitulo)))
      })
  }, [informacoesRecebimentosPagamentos.lancamentos])

  const handleSalvar = () => {
    controllerRecebimentosPagamentos.salvarRecibimentosPagamentos()
  }

  const handleExcluir = async (index: any, codigoLancamento?: any) => {
   controllerRecebimentosPagamentos.excluirRecebimentos(index, codigoLancamento)
  }

  return (
    <>
      <Col lg="12">
        <Row className="mt-5">
          <Col id="formaPagamento-container">
            <Col className="colluns-buttons">
              <Nav className="flex-column">
                {//@ts-ignore
                formasPagamentos.map((forma) => {
                  return (
                    <MiniCard
                      title={forma.descricaoTipo}
                      labelTop={forma.descricao}
                      setCodForma={() => handleSetCodForma(forma.codForma, forma.descricao)}
                      activeItem={informacoesRecebimentosPagamentos.codigoForma && (informacoesRecebimentosPagamentos.codigoForma === forma.codForma) && true}
                    />
                  );
                })}
              </Nav>
            </Col>
          </Col>
          <Col>
            <Row style={{marginTop:20}}>
              <Col md={9}>
                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col" lg={4}>
                    <label>VALOR(R$)</label>
                    <Input
                      style={{ cursor: "default", fontSize: "20px" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => action.setInformacoesRecebimentosPagamentos({...informacoesRecebimentosPagamentos, valorLancar: maskMoeda(e.target.value)}) }
                      value={maskMoeda(informacoesRecebimentosPagamentos.valorLancar)}
                      autoFocus
                    />
                  </Col>
                  <Col className="padding-padrao-col" lg={2}>
                    <label>MULTA(%)</label>
                    <Input
                      type="number"
                      className="input-number-noArrowsinput"
                      style={{ cursor: "default", fontSize: "20px" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => action.setInformacoesRecebimentosPagamentos({...informacoesRecebimentosPagamentos, valorMulta: e.target.value})}
                      value={informacoesRecebimentosPagamentos.valorMulta > 100 || informacoesRecebimentosPagamentos.valorMulta < 0 ? action.setInformacoesRecebimentosPagamentos({...informacoesRecebimentosPagamentos, valorMulta: 0}) : informacoesRecebimentosPagamentos.valorMulta }
                    />
                  </Col>
                  <Col className="padding-padrao-col" lg={2}>
                    <label>JUROS(%)</label>
                    <Input
                      type="number"
                      className="input-number-noArrowsinput"
                      style={{ cursor: "default", fontSize: "20px" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => action.setInformacoesRecebimentosPagamentos({...informacoesRecebimentosPagamentos, valorJuros: e.target.value})}
                      value={informacoesRecebimentosPagamentos.valorJuros > 100 || informacoesRecebimentosPagamentos.valorJuros < 0 ? action.setInformacoesRecebimentosPagamentos({...informacoesRecebimentosPagamentos, valorJuros: 0}): informacoesRecebimentosPagamentos.valorJuros }
                    />
                  </Col>
                  <Col className="padding-padrao-col" lg={2}>
                    <label>DESCONTO(R$)</label>
                    <Input
                      style={{ cursor: "default", fontSize: "20px" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => action.setInformacoesRecebimentosPagamentos({...informacoesRecebimentosPagamentos, valorDesconto: maskMoeda(e.target.value)})}
                      value={maskMoeda(informacoesRecebimentosPagamentos.valorDesconto)}
                    />
                  </Col>
                  <Col style={{ alignSelf: "flex-end", padding: "2px" }}>
                    <Button
                      color="green"
                      type="button"
                      style={{ width: "100%", margin: "0px" }}
                      onClick={() => handleLancar()}
                    >
                      + LANÇAR
                    </Button>
                  </Col>
                </Row>
                <Row className="padding-padrao-row">
                  <Col lg={8} className="padding-padrao-col">
                    <label>CONTA</label>
                    <Select
											select={{ value: 'codConta', label: 'descricao' }}
                      //@ts-ignore
                      value={informacoesRecebimentosPagamentos.codigoConta === 0 ? ({value: contas[0].codConta}): {value: informacoesRecebimentosPagamentos.codigoConta}}
                      //@ts-ignore
                      options={contas}
                      onChange={(e: any) => {action.setInformacoesRecebimentosPagamentos({...informacoesRecebimentosPagamentos, codigoConta: e.value})}}
                    />
                  </Col>
                  <Col lg={4} className="padding-padrao-col">
                    <label>LANÇAMENTO</label>
                    <Input 
                      type="date" 
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => action.setInformacoesRecebimentosPagamentos({...informacoesRecebimentosPagamentos, dataLancamento: e.target.value}) }
                      value={informacoesRecebimentosPagamentos.dataLancamento} />
                  </Col>
                </Row>
                <fieldset
                  style={{
                    border: "solid 1px #5E6680",
                    borderRadius: 5,
                    width: "100%",
                    minHeight: 260,
                    maxHeight: 300,
                  }}
                >
                  <legend
                    style={{
                      fontSize: "13px",
                      width: "auto",
                      margin: "0 15px 0 15px",
                      padding: "0 15px 0 15px",
                    }}
                  >
                    LANÇAMENTOS
                  </legend>
                  <div
                    ref={refTableLancamentos}
                    style={{position: 'relative', maxHeight: 240}} 
                  >
                    {informacoesRecebimentosPagamentos.lancamentos.length > 0 && (
                      <ReactTable
                        columns={[
                          {
                            Header: 'N',
                            Cell: props => ( <span>{props.index + 1}</span>)
                          },
                          {
                            Header: 'Forma de Pagamento',
                            accessor: 'formaPagamento',
                            Cell: props => props.original.formaPagamento
                          },
                          {
                            Header: 'Dt.Lançamento',
                            accessor: 'dataLancamento',
                            Cell: props => props.original.codigoLancamento !== 0 ? props.original.dataLancamento :  moment(props.original.dataLancamento).format('DD/MM/YYYY')
                          },
                          {
                            Header: 'Valor',
                            accessor: 'valorLancamento',
                            Cell: props => maskMoeda(Number(props.original.valorLancamento).toFixed(2))
                          },
                          {
                            Header: 'Juros',
                            accessor: 'jurosLancamento',
                            Cell: props => maskMoeda(Number(props.original.jurosLancamento).toFixed(2))
                          },
                          {
                            Header: 'Multa',
                            accessor: 'multaLancamento',
                            Cell: props => maskMoeda(Number(props.original.multaLancamento).toFixed(2))
                          },
                          {
                            Header: 'Desconto',
                            accessor: 'descontoLancamento',
                            Cell: props => maskMoeda(Number(props.original.descontoLancamento).toFixed(2))
                          },
                          {
                            Header: 'Total Pago',
                            accessor: 'totalPagoLancamento',
                            Cell: props => maskMoeda(Number(props.original.totalPagoLancamento).toFixed(2))
                          },
                          {
                            Header: 'Ações',
                            Cell: props => (
                              <Button 
                                className="btn-icon btn-link text-center"
                                color="danger"
                                onClick={() => handleExcluir(props.index, props.original.codigoLancamento)}
                              >
                                <Icon icon="trash-alt" />
                              </Button>
                            )
                          }
                        ]} 
                        data={informacoesRecebimentosPagamentos.lancamentos}
                        minRows={0}
                        showPagination={false}
                        NoDataComponent={() => (<></>)}
                      />
                    )}
                  </div>
                </fieldset>
              </Col>
              <Col md={3}>
                <Row>
                  <Col>
                    <label>TOTAL TÍTULO</label>
                    <Input
                      style={{ cursor: "default", fontSize: "20px", textAlign: 'right' }}
                      disabled={true}
                      value={maskMoeda(informacoesRecebimentosPagamentos.valorTitulo)}
                    />
                    <label>JUROS</label>
                    <Input
                      style={{ cursor: "default", fontSize: "20px", textAlign: 'right' }}
                      disabled={true}
                      value={maskMoeda(Number(sumTotals.totalJuros).toFixed(2))}
                    />
                    <label>MULTA</label>
                    <Input
                      style={{ cursor: "default", fontSize: "20px", textAlign: 'right' }}
                      disabled={true}
                      value={maskMoeda(Number(sumTotals.totalMulta).toFixed(2))}
                    />
                    <label>DESCONTOS</label>
                    <Input
                      style={{ cursor: "default", fontSize: "20px", textAlign: 'right' }}
                      disabled={true}
                      value={maskMoeda(Number(sumTotals.totalDescontos).toFixed(2))}
                    />
                    <label>TOTAL LANÇADO</label>
                    <Input
                      style={{ cursor: "default", fontSize: "20px", textAlign: 'right' }}
                      disabled={true}
                      value={maskMoeda(Number(sumTotals.totalLançado).toFixed(2))}
                    />
                    <label>TOTAL PAGO</label>
                    <Input
                      style={{ cursor: "default", fontSize: "20px", textAlign: 'right' }}
                      disabled={true}
                      value={maskMoeda(Number(sumTotals.totalPago).toFixed(2))}
                    />
                    <label>TOTAL RESTANTE</label>
                    <Input
                      style={{ cursor: "default", fontSize: "20px", textAlign: 'right' }}
                      disabled={true}
                      value={maskMoeda(Number(sumTotals.totalRestante).toFixed(2))}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                className="btn-padrao"
                color={"default"}
                onClick={() => action.cleanInformacoesRecebimentosPagamentos()}
              >LIMPAR</Button>
              <Button
                className="btn-padrao"
                color={"info"}
                onClick={() => handleSalvar()}
              >SALVAR</Button>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default RecebimentosPagamentos;
