import React, { useState, useRef, useEffect } from 'react';

import * as controller from "../../../../controller/Movimentacao/controllerPedidoVenda"

import { Col, Label, Button } from "reactstrap";
import ArrowTooltip from '../../../../components/Tooltip/ArrowTooltip'

import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector';

import './style.css';
import { Item } from '../../../../store/reducers/Movimentacao/PedidoVenda/types';
import * as actions from '../../../../store/reducers/Movimentacao/PedidoVenda/actions';
import { maskMoeda } from '../../../../Util/mascaras';
import { formats } from '../variables'
import { Round } from '../../../../Util/numbers'
interface CardProps {
    descricao: string;
    valor: string;
    cor: string;
    changeOnClick: boolean;
    index: number;
    className?: string;
    toogleModalGrade?: any;
    setCodProduto?: React.Dispatch<React.SetStateAction<string>>;
}

const MiniCard: React.FC<CardProps> = ({ valor, descricao, cor, changeOnClick, index, className, toogleModalGrade, setCodProduto }) => {
    //@ts-ignore
    const pedido = useShallowEqualSelector(state => state.pedidoVenda.pedido)
    //@ts-ignore
    const miniCard = useShallowEqualSelector(state => state.pedidoVenda.miniCardSelected)
    //@ts-ignore
    const produto = useShallowEqualSelector(state => state.pedidoVenda.produto)
    const [codSubGrupo, setCodSubGrupo] = useState(index)
    const [color, setColor] = useState(cor)

    useEffect(() => {
        if (index != miniCard.subGrupo && changeOnClick) {
            setColor("info")
        }
    }, [miniCard.subGrupo])

    async function handleClick(change: boolean, indexSelecionado: number) {
        if (change) {
            setColor("green")
            actions.setMiniCardSelected({ ...miniCard, subGrupo: indexSelecionado })
            //@ts-ignore
            if (index == indexSelecionado) {
                var pesquisa = {
                    numProduto: '',
                    nome: '',
                    codSubgrupo: codSubGrupo
                }
                var response = await controller.GetProdutos(pesquisa)
                //@ts-ignore
                actions.setProdutos(response)
                //@ts-ignore
                document.getElementById('numeroDescricao').value = ''
            } else (
                setColor("info")

            )
        } else {
            const response: Item | null = await controller.GetProduto(index)
            if (response && response.flControlaGrade !== 1) {
                //@ts-ignore
                response.subtotal = maskMoeda((response.precoVendaAtual * produto.quantidade).toFixed(2))
                //@ts-ignore
                response.precoVendaAtual = Round(response.precoVendaAtual, formats.precoVendaAtual.decimal),
                    response.quantidade = produto.quantidade
                //@ts-ignore
                response.descontoPercent = '0'
                //@ts-ignore
                response.descontoReais = '0'
                //@ts-ignore
                response.total = response.subtotal
                actions.addItem(response)
                actions.setProduto({
                    ...produto,
                    quantidade: Round(1, formats.quantidade.decimal),
                    descricao: ''
                })
            } else {
                if (response) {
                    //@ts-ignore
                    await setCodProduto(`${response.codProduto}`)
                    toogleModalGrade()
                }
            }
        }
    }
    return (
        <>
            <Col lg={3} id="body-miniCard" className={className}>
                <ArrowTooltip title={descricao} placement={'right'}>
                    <Button 
                        onClick={() => handleClick(changeOnClick, index)}
                        color={`${color}`}
                        className={pedido.status !== 'ABERTO' && pedido.status !== '' ? 'disabledButton' : ''}
                    >
                        <Label id="button-miniCard-labelTop"> {valor}</Label>
                        <Label id="button-miniCard-labelBottom">{descricao}</Label>
                    </Button>
                </ArrowTooltip>
            </Col>
        </>
    );
}

export default MiniCard