import axios from 'axios';
import api from "../Util/api";
import { alertar } from "../Util/alertar";
import store from '../store/stores';
import moment from 'moment';

export async function getFiveViews() {

    //@ts-ignore
    const { notificacao } = store.getState()

    try {

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        return await axios.get(api.urlCadastro + `api/v1/dashboard/GetTopTelas`, {
            headers: { Authorization: basicAuth }
        }).then(function (response: any) {

            //@ts-ignore
            let arrTopFive: any = response.data.records.map((tela) => {
                return {
                    codAtalho: tela.codAtalho,
                    codTela: tela.codTela,
                    nomeTela: tela.nomeTela,
                    path: tela.link,
                    icon: tela.icon,
                    qtdRegister: 0
                }

            })

            return arrTopFive

        }).catch((e: any) => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as telas", 'warning', 'icon-alert-circle-exc', 2)

    }
}

export async function getCount(tela: any) {

    //@ts-ignore
    const { notificacao } = store.getState()

    try {

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        return await axios.get(api.urlCadastro + `api/v1/dashboard/Count?codAtalho=${tela.codAtalho}&codTela=${tela.codTela}`, {
            headers: { Authorization: basicAuth },
        }).then(function (response: any) {

            return response.data.records

        }).catch((e: any) => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)

            return {
                'codAtalho': tela.codAtalho,
                'codTela': tela.codTela,
                'count': 0
            }
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as telas", 'warning', 'icon-alert-circle-exc', 2)
    }

}

export async function getGraficMonthly() {
    try {

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        return await axios.get(api.urlMovimento + `api/v1/venda/?tipo=M`,{
            headers: { Authorization: bearerAuth }
        }).then(
            function (response: any) {
                moment.locale('pt-br')
                let labelGrafic: string[] = []
                let dataGrafic: number[] = []
                response.data.data.map((dados: any) => {
                    labelGrafic.push(moment(dados.periodo).format('MM/YYYY'))
                    dataGrafic.push(dados.valor)
                })

                const mensal = {

                    labels: labelGrafic,
                    datasets: [{
                        label: "Valores de Pedido de Vendas",
                        borderColor: "#1f8ef1",
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: "#1f8ef1",
                        pointBorderColor: "rgba(255,255,255,0)",
                        pointHoverBackgroundColor: "#1f8ef1",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: dataGrafic
                    }]
                }

                return mensal

            })

    } catch (error) {

    }
}

export async function getGraficWeekly() {

    try {

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        return await axios.get(api.urlMovimento + `api/v1/venda/?tipo=S`,{
            headers: { Authorization: bearerAuth }
        }).then(
            function (response: any) {
                moment.locale('pt-br')
                let labelGrafic: string[] = []
                let dataGrafic: number[] = []
                response.data.data.map((dados: any) => {
                    let semana: string = dados.periodo.substr(5)
                    let ano: string = dados.periodo.substr(0,4)
                    labelGrafic.push(`${semana}/${ano}`)
                    dataGrafic.push(dados.valor)
                })

                const semanal = {

                    labels: labelGrafic,
                    datasets: [{
                        label: "Valores de Pedido de Vendas",
                        borderColor: "#1f8ef1",
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: "#1f8ef1",
                        pointBorderColor: "rgba(255,255,255,0)",
                        pointHoverBackgroundColor: "#1f8ef1",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: dataGrafic
                    }]
                }

                return semanal

            })

    } catch (error) {

    }
}

export async function getGraficDiary() {

    try {

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        return await axios.get(api.urlMovimento + `api/v1/venda/?tipo=D`,{
            headers: { Authorization: bearerAuth }
        }).then(

            function (response: any) {
                moment.locale('pt-br')
                let labelGrafic: string[] = []
                let dataGrafic: number[] = []
                response.data.data.map((dados: any) => {
                    labelGrafic.push(moment(dados.periodo).format('DD/MM/YYYY'))
                    dataGrafic.push(dados.valor)
                })

                const diario = {

                    labels: labelGrafic,
                    datasets: [
                        {
                            label: "Valores de Pedido de Vendas",
                            borderColor: "#1f8ef1",
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#1f8ef1",
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#1f8ef1",
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            pointRadius: 4,
                            data: dataGrafic
                            
                        }]
                }

                return diario

            })

    } catch (error) {

    }
}
