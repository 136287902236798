import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useDispatch } from 'react-redux';
import * as controllerLancamento from '../../../../controller/Financeiro/controllerLancamentoTitulos'

import ReactWizard from "~/components/Wizard/Wizard";

import useShallowEqualSelector from "../../../../Util/Hooks/useShallowEqualSelector";
import Step1 from "../../CadastroSteps/Cadastro/index"
import Step2 from "../../CadastroSteps/Resumo/index"
import { Col } from "reactstrap";

var steps = [
    {
        stepName: "Cadastro",
        stepIcon: "tim-icons icon-notes",
        component: Step1,
        stepProps: {}
    },
    {
        stepName: "Resumo",
        stepIcon: "tim-icons icon-notes",
        component: Step2,
        stepProps: {}
    },
];

const idStore = "LANCAMENTO_TITULOS";

const CadastroLancamento = () => {
    const dispatch = useDispatch()
    const inicioCadastro = useShallowEqualSelector(state => state.lancamentoTitulos.inicioCadastro)
    const { codEmpresa } = useShallowEqualSelector(state => state.login.usuario.empresa);
    const proximoCadastro = useShallowEqualSelector(state => state.lancamentoTitulos.proximoCadastro)
    const [width] = useState(window.innerWidth);

    useEffect(() => {
        dispatch({ idStore, type: "CADASTRO", value: codEmpresa, campo: "codEmpresa" })
    }, [])

    async function lancarTitutlo() {
        let error = await controllerLancamento.CreateLancamento()
        if (!error) {
            dispatch({ idStore, type: "CLEAN_CADASTRO" })
        }
    }

    return (
        <Col lg="12">
            <div style={{ marginTop: '-20px' }}>
                <ReactWizard
                    steps={steps}
                    navSteps={true}
                    title=""
                    description=""
                    NoDataComponent={() => (<></>)}
                    validate
                    resizable={true}
                    previousButtonText="Voltar"
                    finishButtonText="Finalizar"
                    nextButtonText="Avançar"
                    goToNextStep={proximoCadastro}
                    goToFirstStep={inicioCadastro}
                    finishButtonClick={() => lancarTitutlo()}
                    finishButtonClasses={classNames("btn-wd btn-info",
                        {
                            finishButtonMin:
                                width < 550
                        },
                        {
                            finishButtonRight:
                                !width < 550
                        }
                    )}
                    nextButtonClasses={classNames("btn-wd btn-info",
                        {
                            nextButtonMin:
                                width < 550
                        },
                        {
                            nextButtonRight:
                                !width < 550
                        }
                    )}
                    previousButtonClasses={classNames("btn-wd",
                        {
                            previousButtonMin:
                                width < 550
                        },
                        {
                            previousButtonRight:
                                !width < 550
                        }
                    )}
                    progressbar
                    color="blue"
                />
            </div>

        </Col>
    );
}

export default CadastroLancamento;
