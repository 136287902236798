import React from 'react'
import { Button, Input, Label } from 'reactstrap'
import ArrowTooltip from '../../../../../../components/Tooltip/ArrowTooltip';


interface InputProps {

    id?: any,
    type?: any,
    value?: any,
    onChange?: Function | any,
    onKeyPress?: Function | any,
    Focused?: boolean,
    length?: any,
    Searchable?: boolean,
    Completed?: any

}
interface ButtonProps {

    color?: any,
    style?: any,
    label?: any
    onClick?: Function | any
    arrowToolTip?: boolean,
    arrowToolTipTitle?: any
}

interface RadioProps {

    onChange?: Function | any,
    label?: any,
    checked?: boolean,
    style?: any,
    className?: any


}

export const InputPesquisa = (props: InputProps) => {
    return (
        <Input
            id={props.id}
            type={props.type}
            autoComplete={props.Completed}
            value={props.value}
            onChange={props.onChange}
            onKeyPress={props.onKeyPress}
            autoFocus={props.Focused}
            maxLength={props.length}
        />)

}

export const RadioPesquisa = (props: RadioProps) => {
    return (
        <Label check style={props.style}>
            <Input type="radio"
                onChange={props.onChange}
                checked={props.checked}
            />
            <span className={props.className} /> {props.label}
        </Label>

    )

}
export const ButtonPesquisa = (props: ButtonProps) => {
    return (
        <>
            {ArrowTooltip ?
                <ArrowTooltip title={props.arrowToolTipTitle}>
                    <Button
                        color={props.color}
                        style={props.style}
                        onClick={props.onClick}
                    >
                        {props.label}
                    </Button>
                </ArrowTooltip>
                :
                <Button
                    color={props.color}
                    style={props.style}
                    onClick={props.onClick}
                >
                    {props.label}
                </Button>
            }
        </>)
}

