import axios from "axios"
import api from "../Util/api.js";
import { alertar } from "../Util/alertar";
import { ValidaCPF, ValidaData, ValidaEmail } from "../Util/validacao";
import store from '../store/stores'
import * as actions from "../store/reducers/Cadastro/Usuario/actions";

interface Props {
    codEmpresaFilial?: any,
    codEmpresa?: any,
    codUser?: any,
    email?: any
    verCadastro?: boolean | any

}

//Função que recebe o usuario, os campos da pesquisa e o dispatch, para pesquisa de usuarios por empresa
export function Pesquisar(codEmpresa: any) {

    //@ts-ignore
    const { notificacao } = store.getState();
    try {
        const { pesquisa } = store.getState().usuario

        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios.get(api.urlCadastro + `api/v1/usuario/usuarios?codEmpresa=${codEmpresa}&nome=${pesquisa.nome}&email=${pesquisa.email}&cpf=${pesquisa.cpf}&status=${pesquisa.ativos}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {

            response.data.records.forEach((record: any) => {
                record.dtCadastro = new Date(record.dtCadastro).toLocaleString();
            });
            actions.setUsuarios(response.data.records)
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum usuário encontrado", 'info', 'icon-bulb-63', 2);
            }

        }).catch(e => {
            alertar(notificacao, 'br', "Não foi possível obter os grupos de acesso", 'warning', 'icon-alert-circle-exc', 2, e)
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2, e)
    }
}

export function GetFiliais(value: any) {
    //@ts-ignore
    const { notificacao } = store.getState();
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios.get(api.urlCadastro + `api/v1/empresasFilial/filiais?codEmpresa=${value}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {

            actions.setFiliais(response.data.records)

        }).catch(e => {
            return
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export function GetEmpresas() {
    //@ts-ignore
    const { notificacao } = store.getState();
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios.get(api.urlCadastro + `api/v1/empresasFilial/empresas`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            actions.setEmpresas(response.data.records)
        }).catch(e => {
            return
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2, e)
    }
}
export async function GetEmpresasU(value: any) {
    //@ts-ignore
    const { notificacao } = store.getState();
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios.get(api.urlCadastro + `api/v1/usuario/empresas?codUser=${value}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            actions.setEmpresaUser(response.data.records)
            actions.setEmpresaUserCopia()

        }).catch(e => {
            return
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2, e)
    }
}


export function GetGrupos(codEmpresa: any) {
    //@ts-ignore
    const { notificacao } = store.getState();
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/grupos/getGrupos?codEmpresa=${codEmpresa}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            let grupos = response.data.records.map((grupo: any) => { return { ...grupo, checkedAll: false } })
            grupos.forEach((grupo: any) => {
                const { telas } = grupo

                let qtdStatus = 0
                let arrTelas = telas.map((tela: any) => { return ({ ...tela, checked: false, changed: false }) })
                telas.forEach((tela: any) => {
                    if (tela.status === 0) {
                        qtdStatus++
                    }
                });
                if (qtdStatus === telas.length) {
                    grupo.checkedAll = !grupo.checkedAll
                }
                grupo.telas = arrTelas
            });
            actions.setGrupos(grupos)
        }).catch(e => {
            alertar(notificacao, 'br', "Não foi possível obter os grupos de acesso", 'warning', 'icon-alert-circle-exc', 2, e)
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2, e)
    }
}

export async function UpdateGrupo(codEmpresaFilial: any, grupoSelecionado: any) {
    //@ts-ignore
    const { notificacao } = store.getState();
    const { grupos } = store.getState().usuario
    try {
        var telas: any = [];
        var count = 0;
        grupos.forEach((grupo: any) => {
            if (grupo.codGrupoAcesso === grupoSelecionado) {
                grupo.telas.forEach((tela: any) => {
                    if (tela.changed) {
                        telas.push({ codTela: tela.codTela, status: tela.status });
                        count++;
                    }
                })
            }
        });

        if (count === 0) {
            return alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);

        }
        var grupo = { codGrupoAcesso: grupoSelecionado, telas }
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/grupos/updateGrupo?codEmpresaFilial=${codEmpresaFilial}`,
            headers: { Authorization: basicAuth },
            data: grupo
        }).then(function (response) {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);
            return true
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'danger', 'icon-alert-circle-exc', 2, e);
            return false
        });

    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2, e)
    }
}

export async function GetUsuariosGrupo(codEmpresa: any, codGrupoAcesso: any) {
    //@ts-ignore
    const { notificacao } = store.getState();
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        return await axios.get(api.urlCadastro + `api/v1/usuario/getUsuariosGrupo?codEmpresa=${codEmpresa}&codGrupoAcesso=${codGrupoAcesso}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            return response.data.records
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'danger', 'icon-alert-circle-exc', 2, e);
            return false
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2)
    }
}

export async function GetFuncao() {
    //@ts-ignore
    const { notificacao } = store.getState();
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios.get(api.urlCadastro + `api/v1/funcionario/funcao`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {

            actions.setFuncoes(response.data.records)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'danger', 'icon-alert-circle-exc', 2, e);
            return false
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as funções", 'danger', 'icon-alert-circle-exc', 2)
    }
}


export async function SalvarUsuario(codEmpresa: number) {
    //@ts-ignore
    const { notificacao } = store.getState();
    const { funcionario, invalido, usuario, empresasUser, empresasUserCopia } = store.getState().usuario

    try {

        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        var newArr: any = []

        if (usuario.codUser !== '') {

            if (JSON.stringify(empresasUser) !== JSON.stringify(empresasUserCopia)) {

                empresasUser.forEach((empresa: any, i: number) => {
                    let index = empresasUserCopia.findIndex((element: any) => JSON.stringify(element) === JSON.stringify(empresa))
                    if (index < 0) {
                        newArr.push(empresa)
                    }
                })


                if (newArr.length !== 0) {
                    newArr.forEach((empresa: any) => (
                        VinculaUsuarioEmpresa(empresa, false)
                    )
                    )
                }
                actions.cleanEmpresaUser()
            } else {
                alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
                return true
            }

            return true
        } else {

            if (usuario.email === '' || !ValidaEmail(usuario.email)) {
                alertar(notificacao, 'br', "Necessário informar um e-mail válido", 'warning', 'icon-alert-circle-exc', 2)
                actions.setInvalido({ ...invalido, email: true })
                return false

            }

            if (usuario.nome === '') {

                actions.setInvalido({ ...invalido, nome: true })
                alertar(notificacao, 'br', "Necessário informar nome", 'warning', 'icon-alert-circle-exc', 2)
                return false
            }


            if (usuario.cpf === '' || !ValidaCPF(usuario.cpf)) {

                actions.setInvalido({ ...invalido, cpf: true })
                alertar(notificacao, 'br', "Necessário informar CPF", 'warning', 'icon-alert-circle-exc', 2)
                return false

            }


            if (empresasUser.length === 0) {
                alertar(notificacao, 'br', "Necessário vincular o usuário em uma empresa", 'warning', 'icon-alert-circle-exc', 2)
                return false
            }
            return await axios({
                method: 'post',
                url: api.urlCadastro + `api/v1/usuario/createUsuario`,
                headers: { Authorization: basicAuth },
                data: {
                    CodEmpresa: Number(codEmpresa),
                    CodEmpresaFilial: usuario.codFilial === '0' ? 0 : Number(usuario.codFilial),
                    CodUser: usuario.codUser,
                    Nome: usuario.codUser !== "" ? "" : usuario.nome,
                    Apelido: usuario.codUser !== "" ? "" : usuario.apelido,
                    Email: usuario.codUser !== "" ? "" : usuario.email,
                    Cpf: usuario.codUser !== "" ? "" : usuario.cpf,
                    Foto: usuario.codUser !== "" ? "" : usuario.foto
                }
            }).then(function (response) {


                usuario.codUser = response.data.records.codUser

                actions.setFuncionario({
                    ...funcionario,
                    codFunc: response.data.records.codFunc,
                    numeroFuncionario: response.data.records.numFunc,
                    nome: usuario.nome,
                    cpf: usuario.cpf,
                    emailFunc: usuario.email
                })
                actions.setFuncionarioCopia()

                if (JSON.stringify(empresasUser) !== JSON.stringify(empresasUserCopia)) {
                    empresasUser.forEach((empresa: any, i: number) => {
                        let index = empresasUserCopia.findIndex((element: any) => JSON.stringify(element) === JSON.stringify(empresa))
                        if (index < 0) {
                            newArr.push(empresa)
                        }
                    })
                    if (newArr.length !== 0) {
                        newArr.forEach((empresa: any) => (
                            VinculaUsuarioEmpresa(empresa, false)
                        ))
                    }
                } else {

                    alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
                    return true
                }


                GetEmpresasU(response.data.records.codUser)

                actions.setUsuario({
                    ...usuario,
                    codEmpresa: '0',
                    codFilial: '0'
                })

                GetAcessoUsuario(codEmpresa, response.data.records.codUser)
                actions.setEditar(true)
                return true

            }).catch(e => {
                if (e.response.status === 409) {
                    return false
                } else {
                    alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
                    return false
                }
            });
        }
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2)
        return false
    }
}

export async function SalvarUsuarioGrupo(codEmpresa: any) {
    //@ts-ignore
    const { notificacao } = store.getState();

    const { usuario, acesso, gruposAcessoUsuario } = store.getState().usuario
    try {


        let validated = gruposAcessoUsuario.findIndex((grupo: any) => {
            if (grupo.codGrupoAcesso === acesso.codGrupoAcesso && grupo.codEmpresaFilial === acesso.codEmpresaFilial) {
                return true
            }
        })

        if (validated >= 0) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false

        }

        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        return axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/usuario/vincularUsuario`,
            headers: { Authorization: basicAuth },
            data: {
                codGrupoAcesso: acesso.codGrupoAcesso,
                codEmpresa: codEmpresa,
                codUser: usuario.codUser,
                codEmpresaFilial: Number(acesso.codEmpresaFilial)
            }
        }).then(function (response) {

            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);

            createAtalhos(acesso.codGrupoAcesso)

            GetAcessoUsuario(codEmpresa, usuario.codUser)

            return true;

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'danger', 'icon-alert-circle-exc', 2, e);
            return false;
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2);
        return false;
    }
}

export async function SalvarFuncionario() {
    //@ts-ignore
    const { notificacao } = store.getState();
    const { usuario, funcionario, funcionarioCopia, invalido } = store.getState().usuario
    try {
        if (JSON.stringify(funcionario) === JSON.stringify(funcionarioCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));


        if (funcionario.nome === '') {
            actions.setInvalido({ ...invalido, nomeFunc: true })
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return false
        }

        if (funcionario.cpf === '' || !ValidaCPF(funcionario.cpf)) {
            actions.setInvalido({ ...invalido, cpfFunc: true })
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return false
        }

        if (funcionario.emailFunc === '' || !ValidaEmail(funcionario.emailFunc)) {
            actions.setInvalido({ ...invalido, emailFunc: true })
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return false
        }

        var dataNascimento = funcionario.dtNascimento;
        var dataEmissao = funcionario.dtEmissao


        if (dataNascimento !== '') {
            let axData = new Date(dataNascimento)

            axData.setDate(axData.getDate() + 1)

            dataNascimento = axData.toLocaleDateString()
        }

        if (dataNascimento !== '' && !ValidaData(dataNascimento)) {
            actions.setInvalido({ ...invalido, dtNascimento: true })
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return false
        }

        if (dataEmissao !== '') {

            let axData = new Date(dataEmissao)


            axData.setDate(axData.getDate() + 1)

            dataEmissao = axData.toLocaleDateString()
        }

        if (dataEmissao !== '' && !ValidaData(dataEmissao)) {
            actions.setInvalido({ ...invalido, dtEmissao: true })
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return false
        }


        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/usuario/updateFuncionario`,
            headers: { Authorization: basicAuth },
            data: {
                CodFunc: funcionario.codFunc,
                CodUser: usuario.codUser,
                Email: funcionario.emailFunc,
                NumFunc: parseInt(funcionario.numeroFuncionario),
                Telefone: funcionario.telefone,
                RG: funcionario.rg,
                OrgaoEmissor: funcionario.orgaoEmissor,
                DtEmissao: dataEmissao,
                Sexo: funcionario.genero,
                DtNascimento: dataNascimento,
                CodFuncao: funcionario.codFuncao

            }
        }).then(function (response) {
            actions.setFuncionario({
                ...funcionario, codFunc: response.data.records.codFunc,
                numeroFuncionario: response.data.records.numFunc
            })
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);
            actions.setFuncionarioCopia()
            return true
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'danger', 'icon-alert-circle-exc', 2, e);
            return false
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2);
        return false
    }
}
export function GetUsuarioCompleto(codEmpresa: any, codEmpresaFilial: any, codUser: any, verCadastro: boolean) {
    GetUsuario({ codEmpresa: codEmpresa, codEmpresaFilial: codEmpresaFilial, codUser: codUser, verCadastro: verCadastro })

    GetFuncionario(codUser)
}
export async function GetFuncionario(codUser: any) {
    //@ts-ignore
    const { notificacao } = store.getState();
    const { funcionario } = store.getState().usuario

    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        return await axios.get(api.urlCadastro + `api/v1/funcionario/getFuncionarioCompleto?codUser=${codUser}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            actions.setFuncionario({
                ...funcionario,
                codFunc: response.data.records.codFunc,
                nome: response.data.records.nomeFunc,
                cpf: response.data.records.cpf,
                codFuncao: response.data.records.codFuncao,
                descFuncao: response.data.records.descFunc,
                dtEmissao: response.data.records.dtEmissao !== "" && response.data.records.dtEmissao !== "0000-00-00" ? response.data.records.dtEmissao : "",
                dtNascimento: response.data.records.dtNascimento !== "" && response.data.records.dtNascimento !== "0000-00-00" ? response.data.records.dtNascimento : "",
                emailFunc: response.data.records.email,
                genero: response.data.records.sexo === "" ? "O" : response.data.records.sexo,
                numeroFuncionario: response.data.records.numFunc,
                orgaoEmissor: response.data.records.orgaoEmissor,
                rg: response.data.records.rg,
                telefone: response.data.records.telefone,
            })
            actions.setFuncionarioCopia()

        }
        )
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2)

    }
}
export const verificarUser = async function () {

    //@ts-ignore
    const { notificacao } = store.getState();
    const { usuario, invalido, empresasUser } = store.getState().usuario

    try {
        if (usuario.email === '' || !ValidaEmail(usuario.email)) {
            alertar(notificacao, 'br', "Necessário informar um E-mail válido", 'warning', 'icon-alert-circle-exc', 2)
            actions.setInvalido({ ...invalido, email: true })
            return false

        }
        if (usuario.nome === '') {

            actions.setInvalido({ ...invalido, nome: true })
            alertar(notificacao, 'br', "Necessário informar nome", 'warning', 'icon-alert-circle-exc', 2)
            return false
        }


        if (usuario.cpf === '' || !ValidaCPF(usuario.cpf)) {

            actions.setInvalido({ ...invalido, cpf: true })
            alertar(notificacao, 'br', "Necessário informar CPF", 'warning', 'icon-alert-circle-exc', 2)
            return false

        }

        if (empresasUser.length === 0) {
            alertar(notificacao, 'br', "Necessário vincular o usuário em uma empresa", 'warning', 'icon-alert-circle-exc', 2)
            return false
        }
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        return await axios.get(api.urlCadastro + `api/v1/usuario/verificarUser?email=${usuario.email}`, {
            headers: { Authorization: basicAuth }

        }).then(response => {

            actions.setVerificationSalvar(response.data.records)
            actions.setVerificationUser(response.data.records)

            return response.data.records

        })

    } catch (e) {

        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2)
        return false
    }
}
export async function GetUsuario(props: Props) {

    //@ts-ignore
    const { notificacao } = store.getState();

    const { usuario } = store.getState().usuario

    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/usuario/getUsuarioCompleto?codEmpresa=${props.codEmpresa}&codUser=${props.codUser === undefined ? '' : props.codUser}&email=${props.email === undefined ? '' : props.email}`, {
            headers: { Authorization: basicAuth }
        }).then(async function (response) {

            actions.setUsuario({
                ...usuario,
                codUser: response.data.records.codUser,
                apelido: response.data.records.apelido,
                codEmpresa: props.codEmpresa,
                codFilial: props.codEmpresa,
                cpf: response.data.records.cpf,
                email: response.data.records.email,
                foto: response.data.records.foto,
                nome: response.data.records.nome,
            })

            GetEmpresasU(response.data.records.codUser)

            GetAcessoUsuario(props.codEmpresa, response.data.records.codUser)

            actions.setVerCadastro(props.verCadastro)

            actions.setScreen(1)

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', e.response ? 'warning' : 'danger', 'icon-alert-circle-exc', 2, e);
            return false
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2)
        return false
    }
}


export async function InativarUsuario(user: any, codEmpresa: any) {
    //@ts-ignore
    const { notificacao } = store.getState();
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        const cadastroPendente = axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/usuario/inativar`,
            headers: { Authorization: basicAuth },
            data: {
                CodEmpresa: codEmpresa,
                CodUser: user.codUser,
                Status: user.status
            }
        }).then(function (response) {
            if (response.status === 200) {
                alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);
                Pesquisar(codEmpresa);
                return true
            } else if (response.status === 201) {
                alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 30);
                Pesquisar(codEmpresa);
                return true
            }
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'danger', 'icon-alert-circle-exc', 2, e);
            return false
        });
        return cadastroPendente
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2)
        return false
    }
}

export async function VinculaUsuarioEmpresa(empresa: any, update: any, copia?: any) {
    //@ts-ignore
    const { notificacao } = store.getState();
    try {
        const { usuario } = store.getState().usuario

        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        if (usuario.codUser === '') {
            alertar(notificacao, 'br', 'Nescessário cadastrar ou editar um usuário', 'warning', 'icon-alert-circle-exc', 5);
            return false
        }

        if (update === false) {
            return await axios({
                method: 'post',
                url: api.urlCadastro + `api/v1/usuario/vincularEmpresa`,
                headers: { Authorization: basicAuth },
                data: {
                    CodEmpresaFilial: Number(empresa.codFilial),
                    CodEmpresa: Number(empresa.codEmpresa),
                    CodUser: usuario.codUser
                }
            }).then(function (response) {
                alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);
                GetEmpresasU(usuario.codUser)

                actions.setEditar(true)
                return true

            }).catch(e => {
                alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'danger', 'icon-alert-circle-exc', 2);
                return false

            });
        } else {
            return await axios({
                method: 'put',
                url: api.urlCadastro + `api/v1/usuario/updateEmpresa`,
                headers: { Authorization: basicAuth },
                data: {
                    CodEmpresaFilial: String(copia.codFilial),
                    CodEmpresa: String(copia.codEmpresa),
                    CodUser: String(empresa.codUser),
                    NewCodEmpresa: String(empresa.codEmpresa),
                    NewCodFilial: String(empresa.codFilial)
                }

            }).then(function (response) {
                alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);
                GetEmpresasU(empresa.codUser)
                return true
            }).catch(e => {
                alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'danger', 'icon-alert-circle-exc', 2);
                return false
            });
        }
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2)
        return false
    }
}

export async function ExcluirEmpresa(empresaSelected: any, cadastro: any) {
    //@ts-ignore
    const { notificacao } = store.getState();
    const { empresasUser } = store.getState().usuario
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/usuario/inativarEmpresa`,
            headers: { Authorization: basicAuth },
            data: {
                CodEmpresaFilial: Number(empresaSelected.codFilial),
                CodEmpresa: Number(empresaSelected.codEmpresa),
                CodUser: String(cadastro.codUser)
            }
        }).then(function (response) {

            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);

            let strEmpresaUser = JSON.stringify(empresasUser)
            let arrEmpresaUser = JSON.parse(strEmpresaUser)

            let EmpresasU = arrEmpresaUser.filter((empresa: any) => empresaSelected.codFilial !== empresa.codFilial)

            actions.setEmpresaUser(EmpresasU)
            actions.setEmpresaUserCopia()

            return true

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', e.response ? 'warning' : 'danger', e.response ? 'icon-alert-circle-exc' : '', 2);
            return false
        })
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2)
        return false
    }
}

export function checkTela(indexGrupo: any, indexTela: any) {
    //@ts-ignore
    const { notificacao } = store.getState();
    try {
        const { grupos } = store.getState().usuario
        let gruposStr = JSON.stringify(grupos)
        let gruposArr = JSON.parse(gruposStr)

        const checked = gruposArr[indexGrupo].telas[indexTela].checked

        let { telas } = gruposArr[indexGrupo]

        telas.forEach((tela: any, index: number) => {
            if (!checked)
                tela.checked = false

            if (indexTela === index)
                tela.checked = !tela.checked

        });
        actions.setGrupos(gruposArr)

    } catch (error) {
        alertar(notificacao, 'br', "não foi possivel selecionar Tela", 'danger', 'icon-alert-circle-exc', 2)
    }
}

export const addEmpresa = (codEmpresa: any) => {
    //@ts-ignore
    let { notificacao } = store.getState()
    let { usuario, empresasUser, empresas, filiais, editar } = store.getState().usuario
    let matriz = empresas.find((empresa: any) => empresa.codEmpresa === codEmpresa)
    let filial = filiais.find((filial: any) => filial.codEmpresaFilial === usuario.codFilial)
    let addEmpresa = {
        codUser: "",
        codEmpresa: codEmpresa.toString(),
        empresa: matriz.descricao,
        codFilial: filial.codEmpresaFilial.toString(),
        filial: filial.descricao

    }


    let valid = empresasUser.some((empresa: any) => addEmpresa.codEmpresa === empresa.codEmpresa && addEmpresa.codFilial === empresa.codFilial)

    if (!valid) {
        if (editar) {
            actions.cleanEmpresaUser()
            VinculaUsuarioEmpresa(addEmpresa, false)
        } else {
            let srtEmpresaUser = JSON.stringify(empresasUser)
            let arrEmpresaUser = JSON.parse(srtEmpresaUser)
            arrEmpresaUser.push(addEmpresa)
            actions.setEmpresaUser(arrEmpresaUser)
        }
    } else {

        alertar(notificacao, 'br', "Usuário já vinculado na empresa", 'warning', 'icon-alert-circle-exc', 2);

    }
}


export const createFuncionario = async function (codEmpresa: any) {

    //@ts-ignore
    const { notificacao } = store.getState();
    const { usuario, funcionario, empresasUser, empresasUserCopia } = store.getState().usuario
    try {
        var newArr: any = []

        if (JSON.stringify(empresasUser) !== JSON.stringify(empresasUserCopia)) {

            empresasUser.forEach((empresa: any) => {

                let index = empresasUserCopia.findIndex((element: any) => JSON.stringify(element) === JSON.stringify(empresa))
                if (index < 0) {
                    newArr.push(empresa)
                }
            })
            if (newArr.length !== 0) {
                newArr.forEach((empresa: any) => (
                    VinculaUsuarioEmpresa(empresa, false)
                ))
            }
            actions.cleanEmpresaUser()
        } else {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/usuario/createFuncionario`,
            headers: { Authorization: basicAuth },
            data: {
                codEmpresa: codEmpresa.toString(),
                codUser: usuario.codUser,
                nomeFunc: usuario.nome,
                email: usuario.email,
                cpf: usuario.cpf
            }

        }).then((response: any) => {
            actions.setFuncionario({
                ...funcionario,
                numeroFuncionario: response.data.records.numFunc,
                codFunc: response.data.records.codFunc,
                nome: response.data.records.nomeFunc,
                cpf: response.data.records.cpf,
                emailFunc: response.data.records.email
            })
            actions.setFuncionarioCopia()
            actions.setEmpresaUserCopia()
            GetAcessoUsuario(codEmpresa, usuario.codUser)
            return true
        }).catch((e: any) => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2);
            return false
        })
    } catch (e) {

        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2)
        return false

    }
}


export const createAtalhos = async function (codGrupoAcesso: any) {

    const { acesso, usuario } = store.getState().usuario
    //@ts-ignore
    const { notificacao } = store.getState();

    let atalhos: any = []
    try {

        const { grupos } = store.getState().usuario
        let grupo = grupos.find((grupo: any) => grupo.codGrupoAcesso === codGrupoAcesso)
        let srttelas = JSON.stringify(grupo.telas)
        let telas = JSON.parse(srttelas)

        telas = telas.filter((tela: any) => (tela.codTela !== 1))

        let codTelas = telas.filter((tela: any) => (tela.status === 0))

        codTelas = codTelas.map((tela: any) => tela.codTela)

        if (codTelas.length <= 5) {
            atalhos = codTelas
        } else {

            let lastIndexs: any = []

            while (atalhos.length < 5) {

                let index = Math.floor(Math.random() * codTelas.length)
                let verificacao = lastIndexs.includes(index)
                if (!verificacao) {
                    atalhos.push(codTelas[index])
                    lastIndexs.push(index)
                }
            }

        }

        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/usuario/salvarAtalho`,
            headers: { Authorization: basicAuth },
            data: {
                codEmpresaFilial: acesso.codEmpresaFilial.toString(),
                codUser: usuario.codUser,
                codGrupoAcesso: acesso.codGrupoAcesso,
                codAtalhos: atalhos

            }

        }).then(() => {

        }).catch((e: any) => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2);
            return false

        })

    } catch (e) {

        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2)
        return false

    }


}

export const GetAcessoUsuario = async function (codEmpresa: any, codUser: any) {

    //@ts-ignore
    const { notificacao } = store.getState();

    try {

        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        return await axios({
            method: 'get',
            url: api.urlCadastro + `api/v1/usuario/getGruposAcesso?codUser=${codUser}&codEmpresa=${codEmpresa}`,
            headers: { Authorization: basicAuth },

        }).then((response: any) => {

            actions.setGruposAcessoUsuario(response.data.records)

            return true

        }).catch((e: any) => {

            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2);
            return false

        })

    } catch (e) {

        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2)
        return false

    }

}

export async function GetUsuarioVerificado(props: Props) {

    //@ts-ignore
    const { notificacao } = store.getState();

    const { usuario, verificationSalvar } = store.getState().usuario

    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        return await axios.get(api.urlCadastro + `api/v1/usuario/getUsuarioCompleto?codEmpresa=${props.codEmpresa}&codUser=${props.codUser === undefined ? '' : props.codUser}&email=${props.email === undefined ? '' : props.email}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            if (!verificationSalvar) {
                if (response.data.records.codEmpresa !== props.codEmpresa) {
                    actions.setUsuario({
                        ...usuario,
                        codUser: response.data.records.codUser,
                        apelido: response.data.records.apelido,
                        codEmpresa: response.data.records.codEmpresa,
                        codFilial: response.data.records.codEmpresa,
                        cpf: response.data.records.cpf,
                        email: response.data.records.email,
                        foto: response.data.records.foto,
                        nome: response.data.records.nome,
                    })
                    actions.setScreen(1)
                    if (response.data.records) {
                        actions.setVerificationUser(true)
                    }
                    GetAcessoUsuario(props.codEmpresa, response.data.records.codUser)
                    return true
                } else {
                    alertar(notificacao, 'br', "Usuário cadastrado em sua empresa", 'warning', 'icon-alert-circle-exc', 2)
                    return false
                }
            } else {
                if (response.data.records.codEmpresa !== props.codEmpresa) {
                    actions.setUsuario({
                        ...usuario,
                        codUser: response.data.records.codUser,
                        apelido: response.data.records.apelido,
                        codEmpresa: response.data.records.codEmpresa,
                        codFilial: response.data.records.codEmpresa,
                        cpf: response.data.records.cpf,
                        email: response.data.records.email,
                        foto: response.data.records.foto,
                        nome: response.data.records.nome,
                    })
                    actions.setScreen(1)
                    GetFiliais(props.codEmpresa)
                    GetAcessoUsuario(props.codEmpresa, response.data.records.codUser)
                    return true
                } else {
                    alertar(notificacao, 'br', "Usuário cadastrado em sua empresa", 'warning', 'icon-alert-circle-exc', 2)
                    return false
                }
            }

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', e.response ? 'warning' : 'danger', 'icon-alert-circle-exc', 2, e);
            return false
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'danger', 'icon-alert-circle-exc', 2)
        return false
    }
}