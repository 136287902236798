import React, { useState } from "react";
import {
    Col
} from "reactstrap";
import classNames from "classnames";
import { useHotkeys } from 'react-hotkeys-hook';

var modelStep = [
    {
        stepName: "Operação",
        stepIcon: "tim-icons icon-notes",
        component: Step1,
        stepProps: {}
    }

];
import AppState from '../../../../../store/storeType';
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import ReactWizard from "../../../../../components/Wizard/Wizard";
import Step1 from './Steps/Operacao/Operacao'
import * as actions from '../../../../../store/reducers/Cadastro/Operacao/actions'
import * as controllerOperacoes from '../../../../../controller/controllerOperacoes';
import { IOperacaoState, OperacaoStore } from '../../../../../store/reducers/Cadastro/Operacao/types'


const Cadastro = () => {
   
    useHotkeys('shift+n', () => {
        actions.New()
    }, [])

    useHotkeys('shift+s', () => {
        CreateUpdate()
    }, [])
    
    const operacao = useShallowEqualSelector<AppState, IOperacaoState['operacao']>(state => state.operacao.operacao)
    const verCasdastro = useShallowEqualSelector<AppState, IOperacaoState['verCadastro']>(state => state.operacao.verCadastro)

    async function CreateUpdate() {
        if(verCasdastro){
           return actions.setScreen(0)
        }
       else {
            var error = false
            if (operacao.codigo === 0) {

                error = await controllerOperacoes.createTipoOperacao(operacao);
            }
            else {
                error = await controllerOperacoes.UpdateOperacao(operacao);
            }

            return error
        }

    }
    const inicioCadastro = useShallowEqualSelector<AppState, Boolean>(state => state.operacao.inicioCadastro)
    const currentStep = useShallowEqualSelector<AppState, Number | null>(state => state.operacao.currentStep)

    const [width] = useState(window.innerWidth);
    const [steps] = useState(modelStep);
    return (
        <>
            <Col lg="12">
                <div style={{ marginTop: '-20px' }}>

                    <ReactWizard
                        steps={steps}
                        navSteps={true}
                        title=""
                        description=""
                        resizable={true}
                        validate={false}
                        //@ts-ignore
                        changeStep={() => { }}
                        previousButtonText="Voltar"
                        finishButtonText="Finalizar"
                        nextButtonText="Avançar"
                        goToFirstStep={inicioCadastro}
                        newCurrentStep={currentStep}
                        finishButtonClick={() => CreateUpdate()}
                        finishButtonClasses={classNames("btn-wd btn-info",
                            {
                                finishButtonMin:
                                    width < 550
                            },
                            {
                                finishButtonRight:
                                    width > 550
                            }
                        )}
                        nextButtonClasses={classNames("btn-wd btn-info",
                            {
                                nextButtonMin:
                                    width < 550

                            },
                            {
                                nextButtonRight:
                                    width > 550
                            }
                        )}
                        previousButtonClasses={classNames("btn-wd",
                            {
                                previousButtonMin:
                                    width < 550
                            },
                            {
                                previousButtonRight:
                                    width > 550
                            }
                        )}
                        progressbar
                        color="blue"
                    />
                </div>

            </Col>
        </>
    )
}

export default Cadastro