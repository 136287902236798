import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import ReactTable from "react-table";

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import { Input, Info } from '../Components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import useScrollBar from '../../../../Util/Hooks/useScrollBar';

import { useSelector, shallowEqual } from 'react-redux';

export default function ModalProduto({ isOpen, toggle, getProduto, confirmModal, clean }) {
    const tableRef = useScrollBar([isOpen])
    const produtos = useSelector(state => state.entradaMercadoria.produtos, shallowEqual)
    const inputDocumento = useRef()

    const [pesquisaProduto, setPesquisaProduto] = useState({ codigo: null, numProduto: '', nome: ''})

    useEffect(() => {
        inputDocumento.current.focus()
    }, [])

    function onClickOperacao(rowIndex) {
        let produtoSelected = produtos[rowIndex]
        setPesquisaProduto({ codigo: produtoSelected.codProduto, numProduto: produtoSelected.numProduto, nome: produtoSelected.nome, rgInscEst: produtoSelected.rgInscEst })
    }

    function onDoubleClickOperacao() {
        confirmModal(pesquisaProduto.codigo)
    }

    function limparModal() {
        setPesquisaProduto({  codigo: null, numProduto: '', nome: ''})
        clean()
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            getProduto(pesquisaProduto)
        }
    }

    function getTrProps(state, row, column) {
        return {
            style: {
                height: '50px',
                paddingTop: '0px',
                paddingBottom: '5px',
                backgroundColor: produtos[row.index].numProduto === pesquisaProduto.numProduto ? 'rgba(71, 179, 255, 0.41)' : ''
            },
            onClick: (e, handleOriginal) => {
                onClickOperacao(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            },
            onDoubleClick: (e, handleOriginal) => {
                onDoubleClickOperacao(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            }
        }
    }

    function getTdProps(state, rowInfo, column) {
        if (column.id === 'actions') {
            return { style: { padding: 0 } }
        } else {
            return {}
        }
    }

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title='Pesquisa de Produtos'
            size="lg"
        >
            <ModalBasicBody>
                <h4 style={{ textAlign: 'center' }}>Informe os dados abaixo para prosseguir</h4>

                <Row>
                    <Col md="4">
                        <label>Número / Código de Barras</label>
                        <Input
                            innerRef={inputDocumento}
                            type="text"
                            value={pesquisaProduto.numProduto}
                            onChange={e => setPesquisaProduto({ ...pesquisaProduto, numProduto: e.target.value })}
                            autoComplete="off"
                            maxLength="18"
                            onKeyPress={e => handleKeyPress(e)}
                        />
                        <br></br>
                    </Col>
                    <Col md="7">
                        <label>Descrição</label>
                        <Input
                            type="text"
                            value={pesquisaProduto.nome}
                            onChange={e => setPesquisaProduto({ ...pesquisaProduto, nome: e.target.value })}
                            autoComplete="off"
                            onKeyPress={e => handleKeyPress(e)}
                        />
                        <br></br>
                    </Col>
                    <Col lg={1} md={1} sm={1}>
                        <Button
                            type="button"
                            style={{ marginTop: 34, width: 40, marginBottom: 0 }}
                            color="info"
                            onClick={() => getProduto(pesquisaProduto)}
                        >
                            <Icon icon="search" className="fa-lg" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <fieldset className="fieldset-operacao">
                            <legend>Resultados</legend>
                            <div
                                ref={tableRef}
                                style={produtos.length !== 0 ?
                                    { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 350 } :
                                    { visibility: 'hidden', opacity: '0' }} >
                                <ReactTable
                                    data={produtos}
                                    NoDataComponent={() => (<></>)}
                                    columns={[
                                        {
                                            Header: 'Numero Produto',
                                            accessor: 'numProduto',
                                            minWidth: 40,
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Código de Barras',
                                            accessor: 'codBarras',
                                            minWidth: 40,
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Nome',
                                            accessor: 'nome',
                                            resizable: false,
                                            sortable: false
                                        },   
                                    ]}
                                    getTrProps={getTrProps}
                                    getTdProps={getTdProps}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight header-fix"
                                    noDataText=""
                                    pageSize={produtos.length}
                                />
                            </div>
                            <br />
                        </fieldset>
                    </Col>

                </Row>

                <Col className="text-right">
                    <Info>
                        {`${produtos.length} produtos encontrados`}
                    </Info>
                </Col>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        onClick={() => confirmModal(pesquisaProduto.codigo)}
                        color={"info"}
                    >CONCLUÍDO
                    </Button>
                    <Button
                        className="btn-padrao"
                        onClick={() => limparModal()}
                        color={"info"}
                    >LIMPAR
                    </Button>
                </Col>
            </ModalBasicFooter>
        </ModalBasic>
    )
}