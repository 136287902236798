import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from 'react-redux';
import classNames from "classnames";

import store from '../../../store/stores'

import {
    Button,
    ButtonGroup,
} from "reactstrap";

const TabsContent = function (props) {
    const { update } = useSelector(state => state.lancamentoTitulos.cadastro, shallowEqual);
    const [screen, setScreen] = useState(0)
    useEffect(() => {
        if (update !== null)
            setScreen(update)
    }, [update])

    function onChange(index) {
        store.dispatch({ idStore: "LANCAMENTO_TITULOS", type: "CLEAN_PESQUISA" })
        store.dispatch({ idStore: "LANCAMENTO_TITULOS", type: "CLEAN_CADASTRO" })
        setScreen(index)
    }

    return <>
        <ButtonGroup style={{ width: '100%' }}>
            {props.tabs && props.tabs.map((item, index) => {
                return (
                    <>
                        <Button
                            color={screen === index ? "info" : ""}
                            type="button"
                            data-toggle="tab"
                            className={classNames("btn-gp-padrao", screen === index ? "active" : "")}
                            onClick={() => { onChange(index) }}
                            style={{ width: '50%' }}
                        >
                            {item.tabName}
                        </Button>
                    </>
                )
            })}
        </ButtonGroup>
        <br />
        <br />
        {props.tabs[screen].tabScreen}
    </>
}

export default TabsContent;