import React from "react";
import {
    Card,
    Col,
    CardHeader,
    CardBody,
    CardTitle
} from "reactstrap";
import './style.css'
import TabContent from "./TabContent/TabContent"
import Pesquisa from "./TabContent/Telas/Pesquisar"
import Cadastrar from "./TabContent/Telas/Cadastrar"


const Tabs = [
    {
        tabName: "PESQUISAR",
        tabScreen: <Pesquisa />
    },
    {
        tabName: "GERAR ENTRADA",
        tabScreen: <Cadastrar />
    },

]

const EntradaMercadoria = () => {
    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="12">
                    <Card>
                        <CardHeader className="text-center">
                            <CardTitle tag="h3">Entrada de Mercadorias</CardTitle>
                        </CardHeader>

                        <CardBody>
                            <TabContent tabs={Tabs} />
                        </CardBody>
                    </Card>
                </Col>
            </div>
        </>
    );
}

export default EntradaMercadoria;