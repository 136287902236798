import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import {
    Col,
    Button,
    CardBody,
    Input,
    Row,
    Collapse,
    FormGroup
} from "reactstrap";
import * as controllerSaida from '../../../../../controller/Movimentacao/controllerSaidaDiversa'
import store from '../../../../../store/stores'
import useResize from "../../../../../Util/Hooks/useResize";
import AppState from '../../../../../store/storeType';
import { Pesquisa, Empresas } from '../../../../../store/reducers/Movimentacao/SaidaDiversa/types';
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import { cleanPesquisa } from "../../../../../store/reducers/Movimentacao/SaidaDiversa/actions";
import ArrowTooltip from '../../../../../components/Tooltip/ArrowTooltip';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import * as action from '../../../../../store/reducers/Movimentacao/SaidaDiversa/actions'
import { Round } from "../../../../../Util/numbers";
import { PopUpDelete } from '../../Modals/PopUps'
import useModal from "../../../../../Util/Hooks/useModal";
import { alertar } from "../../../../../Util/alertar";
import ModalDestinatario from '../../Modals/ModalDestinatario';
import { setDestinatariosList } from "../../../../../store/reducers/Movimentacao/SaidaDiversa/actions";
import { Select } from "../../../../../components/ErnComponets/ErnComponets";
import { useSelector } from 'react-redux';

const idStore = "SAIDA_DIVERSA";
//@ts-ignore

const Pesquisar = function () {
    return (
        <Col lg="12">
            <Filtros />
            <RenderTable />
        </Col>
    );
}

function Filtros() {
    const pesquisa = useShallowEqualSelector<AppState, Pesquisa>(state => state.saidaDiversa.pesquisa)
    const empresas = useShallowEqualSelector<AppState, Empresas[]>(state => state.saidaDiversa.empresas)
    //@ts-ignore
    const { codEmpresaFilial } = useSelector(state => state.login.usuario.empresa);
    const { width } = useResize()
    const [maisFiltros, setMaisFiltros] = useState(false);
    const [modalDestinatario, toggleDestinatario] = useModal(false)

    useEffect(() => {
        controllerSaida.getEmpresasUsuario()
    }, [])

    useEffect(() => {
        let filial: any
        empresas.forEach((empresa: any) => {
            if (empresa.codEmpresaFilial === codEmpresaFilial.toString()) {
                filial = codEmpresaFilial.toString()
            }
        })
        action.setPesquisa({ ...pesquisa, codEmpresa: filial })
    }, [empresas])

    function handleOnLimpar() {
        cleanPesquisa()
        store.dispatch({ idStore, type: 'LIST_SAIDAS', valor: [] })
    }

    function handleOnPesquisar() {
        //@ts-ignore
        const { notificacao } = store.getState()

        if (pesquisa.dataInicio !== '' && pesquisa.dataFim !== '') {
            var dataI = pesquisa.dataInicio.split('-')
            var dataF = pesquisa.dataFim.split('-')

            var newDataI = new Date(Number(dataI[0]), Number(dataI[1]) - 1, Number(dataI[2]))
            var newDataF = new Date(Number(dataF[0]), Number(dataF[1]) - 1, Number(dataF[2]))

            if (newDataI > newDataF) {
                alertar(notificacao, 'br', 'O campo Data Início não pode ser maior que o campo Data Fim', 'warning', 'icon-alert-circle-exc', 2)
                return
            }
        }

        controllerSaida.handlePesquisar(pesquisa)
    }

    function pesquisaDestinatarios({ documento, nomeRazao, tipo }: any) {
        controllerSaida.getDestinatarios(documento, nomeRazao, tipo);
    }

    function confirmarDestinatario(destinatario: any) {
        action.setPesquisa({ ...pesquisa, destinatario: destinatario })
        toggleDestinatario()
    }

    function cleanDestinatario() {
        setDestinatariosList([])
    }

    return (
        <>
            <Row  className="padding-padrao-row">
                <Col  className="padding-padrao-col" lg="8">
                    <Row className="padding-padrao-row">
                        <Col  className="padding-padrao-col" lg="5">
                            <label>Empresas</label>
                            <Select
                                value={{ value: pesquisa.codEmpresa }}
                                select={{ value: "codEmpresaFilial", label: "descricaoEmpresa" }}
                                //@ts-ignore
                                options={empresas}
                                onChange={(value: any) => action.setPesquisa({ ...pesquisa, codEmpresa: value.value })}
                                isSearchable={true}
                                autoComplete="off"
                            />
                        </Col>
                        <Col  className="padding-padrao-col" lg="6">
                            <label>Nome / Razão Social do Destinatário</label>
                            <FormGroup >
                                <Input
                                    type="text"
                                    value={pesquisa.destinatario.nomeRazao}
                                    autoComplete="off"
                                    disabled={true}
                                />
                                <br></br>
                            </FormGroup>
                        </Col>

                        <Col className="padding-padrao-col" lg="1">
                            <Button color="info" onClick={() => toggleDestinatario()} style={{ width: '100%', height: 40, marginTop: 25 }}>
                                <Icon icon="search" className="fa-lg" />
                            </Button>
                        </Col>
                    </Row>

                </Col>

                <Col className="padding-padrao-col" lg="4">
                    <Row className="padding-padrao-row">
                        <Col className="padding-padrao-col" lg="6" md="4">
                            <label>Data Início</label>
                            <Input
                                id="pesqDataInicio"
                                type="date"
                                autoComplete="off"
                                value={pesquisa.dataInicio}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => action.setPesquisa({ ...pesquisa, dataInicio: event.currentTarget.value })}
                            />
                        </Col>

                        <Col className="padding-padrao-col" lg="6" md="4">
                            <label>Data Fim</label>
                            <Input
                                id="pesqDataFim"
                                type="date"
                                autoComplete="off"
                                value={pesquisa.dataFim}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => action.setPesquisa({ ...pesquisa, dataFim: event.currentTarget.value })}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div>
                        <Button color="info" onClick={() => setMaisFiltros(!maisFiltros)} style={{ marginBottom: '1rem' }}>+ Filtros</Button>
                        <Collapse isOpen={maisFiltros}>
                            <Col className="box">
                                <div >

                                </div>
                            </Col>
                        </Collapse>
                    </div>
                </Col>
            </Row>

            <Row>
                <CardBody>
                    <Col style={{ width: '100%' }}>
                        <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={() => handleOnLimpar()}
                        >Limpar</Button>
                        <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={() => handleOnPesquisar()}
                        >Pesquisar</Button>
                    </Col>
                </CardBody>
            </Row>

            {
                modalDestinatario &&
                <ModalDestinatario
                    isOpen={true}
                    toggle={toggleDestinatario}
                    getDestinatario={pesquisaDestinatarios}
                    confirmModal={confirmarDestinatario}
                    clean={cleanDestinatario}
                />
            }
        </>
    );
}

function RenderTable() {
    const [numeroPedido, setNumeroPedido] = useState(0);
    const listSaidas = useShallowEqualSelector<AppState, any[]>(state => state.saidaDiversa.listSaidas)
    const [isOpen, togglePopUp] = useModal(false);

    function excluir(id: number) {
        setNumeroPedido(id)
        togglePopUp()
    }

    return (
        <>
            {
                listSaidas && listSaidas.length > 0 ?
                    <>
                        <div style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' }} >
                            <ReactTable
                                data={listSaidas}
                                filterable={false}
                                columns={
                                    [
                                        {
                                            Header: "Num. Saída",
                                            accessor: "numeroPedido",
                                            width: 100
                                        },
                                        {
                                            Header: "Data Emissão",
                                            accessor: "dataPedido",
                                            width: 190
                                        },
                                        {
                                            Header: "Nome Destinatário",
                                            accessor: "nomeDestinatario"
                                        },
                                        {
                                            Header: "Valor",
                                            accessor: "valorTotal",
                                            Cell: (props) => props.original.valorTotal ? Round(props.original.valorTotal, 2) : "0,00",
                                            width: 150
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "status",
                                            width: 150
                                        },
                                        {
                                            Header: "",
                                            accessor: "actions",
                                            width: 120,
                                            sortable: false,
                                            filterable: false,
                                            Cell: (props) => (
                                                <div className="actions-right">
                                                    <ArrowTooltip title="Editar">
                                                        <Button
                                                            onClick={() => controllerSaida.handleEditar(props.original.numeroPedido)}
                                                            size="sm"
                                                            className={"btn-icon btn-link"}
                                                            color="success"
                                                        >
                                                            <Icon icon="edit" className="fa-lg" />
                                                        </Button>
                                                    </ArrowTooltip>

                                                    <ArrowTooltip title="Excluir">
                                                        <Button
                                                            onClick={() => excluir(props.original.numeroPedido)}
                                                            className="btn-icon btn-link"
                                                            color="danger"
                                                        >
                                                            <Icon icon="trash-alt" className="fa-lg" />
                                                        </Button>
                                                    </ArrowTooltip>
                                                </div>
                                            )
                                        }
                                    ]
                                }
                                minRows={1}
                                showPaginationTop={false}
                                className="-striped -highlight"
                                noDataText=""
                                showPaginationBottom={true}
                                showPageSizeOptions={true}
                                previousText="Anterior"
                                nextText="Próximo"
                                loadingText="Carregando"
                                pageText="Página"
                                ofText="de"
                                rowsText='linhas'
                            />
                        </div>
                        <PopUpDelete isOpen={isOpen} callback={() => controllerSaida.handleExcluir(numeroPedido, togglePopUp)} toggle={togglePopUp} />
                    </>
                    :
                    null
            }
        </>
    )
}

export default Pesquisar;