import React, { useState} from 'react';
import { Button, Col, Row, Input } from 'reactstrap';
import ReactTable from "react-table";
import * as controllerProdutosDescontinuados from "~/controller/controllerProdutosDescontinuados";

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';



import {maskNCM} from '~/Util/mascaras'
import { useSelector, shallowEqual,useDispatch } from 'react-redux';

import useScrollBar from '../../../Util/Hooks/useScrollBar';


export default function ModalOperacao({ isOpen, toggle }) {
    const tableRef = useScrollBar([isOpen])
    const ncms = useSelector(state => state.produtosDescontinuados.ncms, shallowEqual)
    const { ncm,descricao } = useSelector(state => state.produtosDescontinuados.produtoSelecionado);
    const ncmPesquisa = useSelector(state => state.produtosDescontinuados.ncmPesquisa);
    const idStore = "PRODUTOS_DESCONTINUADOS";
    const [pesquisaNCM, setPesquisaNCM] = useState({ ncm: '', dataDescontinuada:'', })
    const dispatch = useDispatch();


   //faz a pesquisa dos ncms para atualizar o produto
    async function pesquisar() {
            
            controllerProdutosDescontinuados.GetNovoNCM(ncmPesquisa);

      };

      function handleKeyPress(event) {
        if (event.key === 'Enter') {
            controllerProdutosDescontinuados.GetNovoNCM(ncmPesquisa);
        }
    }

    function alterar(){
        controllerProdutosDescontinuados.UpdateProdutoDescontinuado(pesquisaNCM);
        toggle()

    }
  //seleciona o ncm para atualizar o produto
    function seleciona(rowIndex){
        let ncmSelected = ncms[rowIndex]
        setPesquisaNCM({ ...pesquisaNCM, dataDescontinuada: ncmSelected.dataDescontinuado, ncm: ncmSelected.ncm })
    }
    
    function onChange(value, stateName) {
        dispatch({ idStore, type: 'NCM_PESQUISA', campo: stateName, value: value });
      };
    
      //seleciona o ncm da renderTable
      function getTrProps(state, row, column) {
        return {
            style: {
                height: '50px',
                paddingTop: '0px',
                paddingBottom: '5px',
                backgroundColor:  ncms[row.index].ncm === pesquisaNCM.ncm ? 'rgba(71, 179, 255, 0.41)' : ''
            },
            onClick: () => {
                seleciona(row.index)

            },
        }
    }


    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title={'ALTERAR NCM'}
            size="lg"
        >
            <ModalBasicBody>
            <h4 style={{ textAlign: 'center' }}>Selecione o novo NCM do Produto</h4>
            <h5 style={{ textAlign: 'center' }}>Alterar NCMS </h5>

                <Row>
                    <Col md="3">
                        <label>NCM ANTIGO</label>
                        <Input
                            type="text"
                            value={maskNCM(ncm)}
                            autoComplete="off"
                            disabled
                            //ncm selecionado na tela de ProdutosDescontinuados
                        />
                        <br></br>
                    </Col>
                    <Col md="8">
                        <label>Descrição</label>
                        <Input
                            type="text"
                            value={descricao}
                            autoComplete="off"
                            disabled
                             //descrição do ncm selecionado na tela de ProdutosDescontinuados
                        />
                        <br></br>
                    </Col>
                </Row>

                <Row>
                    <Col md="3">
                        <label>Código NCM</label>
                        <Input
                            type="text"
                            value={maskNCM(ncmPesquisa.ncm)}
                            autoComplete="off"
                            onChange={e => onChange(maskNCM(e.target.value), 'ncm')}
                            onKeyPress={handleKeyPress}
                        />
                        <br></br>
                    </Col>
                    <Col md="8">
                        <label>Descrição do NCM</label>
                        <Input
                            type="text"
                            value={ncmPesquisa.descricao}
                            autoComplete="off"
                            onChange={e => onChange(e.target.value, 'descricao')}
                            onKeyPress={handleKeyPress}
                        />
                        <br></br>
                    </Col>
                    <Col lg={1} md={1} sm={1}>
                        <Button
                            type="button"
                            style={{ marginTop: 34, width: 40, marginBottom: 0 }}
                            color="info"
                            onClick={() =>pesquisar()}
                        >
                            <Icon icon="search" className="fa-lg" />
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <fieldset className="fieldset-operacao">
                            <legend>Resultados</legend>
                            <div
                                ref={tableRef}
                                style={ncms.length !== 0 ?
                                    { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 350 } :
                                    { visibility: 'hidden', opacity: '0' }} >
                                <ReactTable
                                    data={ncms}
                                    columns={[
                                        {
                                            Header: 'NCM',
                                            accessor: 'ncm',
                                            minWidth: 40,
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Descrição',
                                            accessor: 'descricao',
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Data descontinuado',
                                            accessor: 'dataDescontinuado',
                                            resizable: false,
                                            sortable: false
                                        },]}
                                    getTrProps={getTrProps}    
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                    noDataText=""
                                    pageSize={ncms.length}
                                />
                            </div>
                        </fieldset>
                    </Col>

                </Row>

                <Col className="text-right">
                        {`${ncms.length} ncms encontrados`}
                </Col>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                <Button
                        className="btn-padrao"
                        onClick={() => toggle()}
                        color={"warning"}
                    >Cancelar
                    </Button>

                    <Button
                        className="btn-padrao"
                        onClick={() => alterar()}
                        color={"info"}
                    >Alterar
                    </Button>
                </Col>
            </ModalBasicFooter>

        </ModalBasic>
    )
}