import React from 'react'
import { CardBody, CardFooter, Col, FormGroup, Row } from 'reactstrap'
import { InputNumber, Select, Switch } from '../../../../../../components/ErnComponets/ErnComponets'
import { IServicoState } from '../../../../../../store/reducers/Cadastro/Servico/types'
import AppState from '../../../../../../store/storeType'
import useShallowEqualSelector from '../../../../../../Util/Hooks/useShallowEqualSelector'
import { ButtonCadastro, InputCadastro } from '../CadastroSteps/Components'
import * as actions from '../../../../../../store/reducers/Cadastro/Servico/actions'
import * as controllerServicos from "../../../../../../controller/controllerServicos";
import { maskMoeda } from "../../../../../../Util/mascaras";

const Cadastro = () => {
  const verCadastro = useShallowEqualSelector<AppState, IServicoState['verCadastro']>(state => state.servico.verCadastro)
  const servico = useShallowEqualSelector<AppState, IServicoState['servico']>(state => state.servico.servico)
  const grupos = useShallowEqualSelector<AppState, IServicoState['grupos']>(state => state.servico.grupos)
  const invalido = useShallowEqualSelector<AppState, IServicoState['invalido']>(state => state.servico.invalido)

  const cadsubgrupos = useShallowEqualSelector<AppState, IServicoState['cadsubgrupos']>(state => state.servico.cadsubgrupos)

  const OnchangeGrupo = (value: any) => {

    if (value !== '0') {
      actions.setServico({ ...servico, codGrupo: value, codSubgrupo : '0' })
      controllerServicos.GetSubgrupos(value, false)
    } else {
      actions.setServico({ ...servico, codSubgrupo: '0', codGrupo : '0' })
      actions.cleanCadSubGrupos()

    }
  }


  const handleSalvar = () => {

    if (servico.codServico === '') {
      controllerServicos.CreateServico()

    } else {
      controllerServicos.UpdateServico()
    }

  }

  const CleanAll = () => {

    actions.cleanServico()
    actions.cleanInvalido()
    actions.cleanCadSubGrupos()

  }

  return (
    <>
      <Row className="justify-content-center">
        <div className={verCadastro ? 'ver-cadastro' : ''}>

        </div>
        <Col>

          <h4 className="info-text">Preencha com as informações do serviço</h4>
          <CardBody>

            <Row className="justify-content-center">

              <Col md="10" className="text-right">
                <Switch
                  componentleft={<label className={servico.status === 0 ? `text-success text-center` : "text-warning text-center"}>{servico.status === 0 ? "Ativo" : "Inativo"}</label>}
                  checked={servico.status === 0}
                  onChange={() => { actions.setServico({ ...servico, status: servico.status === 0 ? 1 : 0 }) }}
                  disabled={servico.codServico === ""}
                />
              </Col>
              <Col className="padding-padrao-col" md="10">
                <Row>
                  <Col className="padding-padrao-col" lg="2" sm="7">
                    <InputCadastro
                      label={'Código'}
                      value={servico.codServico}
                      onChange={() => { }}
                      disabled={true}
                      type="text"
                      autoCompleted='off'
                    />
                  </Col>
                  <Col className="padding-padrao-col" lg="3" sm="7">
                    <InputCadastro
                      label='Número do Serviço'
                      value={servico.numeroServico}
                      onChange={() => { }}
                      disabled={true}
                      type='text'
                      length='30'
                      autoCompleted='off'
                    />
                  </Col>
                  <Col className="padding-padrao-col" lg md sm="7">
                    <InputCadastro
                      label='Nome'
                      value={servico.nome}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        actions.setServico({ ...servico, nome: event.currentTarget.value }),
                          actions.setInvalido({ ...invalido, nome: false })
                      }}
                      type='text'
                      length='30'
                      span={true}
                      autoCompleted='off'
                      className={invalido.nome ? "has-danger" : ''}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="padding-padrao-col" md='5'>
                    <label>Grupo <span className="error">*</span></label>
                    <Select
                      id="cadGruposSelect"
                      first="Selecione"
                      value={{ value: servico.codGrupo, label: servico.grupo }}
                      select={{ value: "codGrupo", label: "nome" }}
                      onChange={(event: any) => { OnchangeGrupo(event.value) }}
                      //@ts-ignore
                      options={grupos}
                      isSearchable={true}
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="padding-padrao-col" md='4'>
                    <label>Subgrupo <span className="error">*</span></label>
                    <Select
                      id="cadSubgruposSelect"
                      first="Selecione"
                      value={{ value: servico.codSubgrupo, label: servico.subgrupo }}
                      select={{ value: "codSubgrupo", label: "nome" }}
                      //@ts-ignore
                      options={cadsubgrupos}
                      onChange={(event: any) => { actions.setServico({ ...servico, codSubgrupo: event.value }) }}
                      isSearchable={true}
                      autoComplete="off"

                    />
                  </Col>
                  <Col className="padding-padrao-col" md='3'>
                    <label>Valor</label>
                    <InputCadastro
                      className={invalido.valor ? "has-danger" : ''}
                      id="valor"
                      value={servico.valor}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        actions.setServico({ ...servico, valor: maskMoeda(event.currentTarget.value) })

                      }}
                      length='10'
                      style={{ MarginTop: '-20px' }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="padding-padrao-col" lg md sm="7">
                    <InputCadastro
                      label='Descrição'
                      value={servico.descricao}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        actions.setServico({ ...servico, descricao: event.currentTarget.value }),
                          actions.setInvalido({ ...invalido, descricao: false })
                      }}
                      type='text'
                      length='30'
                      span={true}
                      autoCompleted='off'
                      className={invalido.descricao ? "has-danger" : ''}

                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="padding-padrao-col" lg md sm="7">
                    <InputCadastro
                      label='Observação'
                      value={servico.obs}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        actions.setServico({ ...servico, obs: event.currentTarget.value })
                      }}
                      type='text'
                      length='30'
                      autoCompleted='off'
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>

            <Row>
              <Col className="padding-padrao-col" style={{ display: "flex", justifyContent: 'center' }}>
                <ButtonCadastro
                  arrowToolTip={true}
                  arrowToolTipTitle='Shift+N'
                  onClick={() => { CleanAll() }}
                  clasName='btn-padrao'
                  color='btn-padrao'
                  label='Novo'
                />
                <ButtonCadastro
                  arrowToolTip={true}
                  arrowToolTipTitle='Shift+S'
                  onClick={() => { handleSalvar() }}
                  clasName='btn-padrao'
                  color='info'
                  label='Salvar'
                />
              </Col>
            </Row>
          </CardFooter>

        </Col>
      </Row>
    </>)

}

class Wizard extends React.Component {

  render() {
    return (
      <Cadastro />
    )
  }
}

export default Wizard;