import Swal from 'sweetalert2'

export function SweetAlert(title, text, icon, cancelButton){
  cancelButton === true ? 
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      })
      :
      Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: false,
        confirmButtonText: 'OK'
      })
}