import { combineReducers } from 'redux'

import sistema from './sistemaStore'
import notificacao from './notificacaoStore'
import login from './loginStore'
import grupos from './gruposAcessoStore'
import usuario from './Cadastro/Usuario'
import clientes from './clientesStore'
import fornecedores from './fornecedoresStore'
import transportadoras from './transportadorasStores'
import gruposProduto from './gruposProdutoStore'
import subgruposProduto from './subgruposProdutoStore'
import produtos from './Cadastro/Produto'
import servico from './Cadastro/Servico'
import grades from './gradesStore'
import cests from './cestsStore'
import ncms from './ncmsStore'
import ICMSs from './icmsStore'
import classificacoesTributarias from './classificacoesTributariasStore'
import cfops from './cfopsStore'
import ipis from './ipisStore'
import enquadramentosIPI from './enquadramentosIPIStore'
import pisCofins from './pisCofinsStore'
import operacao from './Cadastro/Operacao'
import tiposPagamentos from './tiposPagamentosStore'
import contas from './contasStore'
import formasPagamentos from './formasPagamentosStore'
import saidaDiversa from './Movimentacao/SaidaDiversa'
import pedidoVenda from './Movimentacao/PedidoVenda'
import subgrupoConta from "./subgruposContasStore"
import grupoContas from './grupoContasStore'
import perfilUsuario from './perfilUsuarioStore'
import entradaMercadoria from './Movimentacao/entradaMercadoria'
import aberturaCaixa from './Cadastro/AberturaCaixa'
import fechamentoCaixa from './Cadastro/FechamentoCaixa'
import modalFaturamento from './Modals/Faturamento'
import produtosDescontinuados from './produtosDescontinuadoStore'
import inventario from './Movimentacao/Inventario'
import lancamentoTitulos from './lancamentoTitulosStore'
import configuracao from './Configuracao'
import dashboard from './DashboardStore'
import recebimentoPagamento from './Financeiro/RecebimentoPagamento'

export default combineReducers({

    notificacao,
    login,
    sistema,
    grupos,
    usuario,
    clientes,
    fornecedores,
    transportadoras,
    gruposProduto,
    subgruposProduto,
    produtos,
    servico,
    grades,
    cests,
    ncms,
    ICMSs,
    classificacoesTributarias,
    cfops,
    ipis,
    enquadramentosIPI,
    pisCofins,
    operacao,
    tiposPagamentos,
    contas,
    formasPagamentos,
    saidaDiversa,
    pedidoVenda,
    subgrupoConta,
    grupoContas,
    perfilUsuario,
    entradaMercadoria,
    aberturaCaixa,
    fechamentoCaixa,
    modalFaturamento,
    produtosDescontinuados,
    inventario,
    lancamentoTitulos,
    configuracao,
    dashboard,
    recebimentoPagamento
})