import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import classNames from "classnames";
import { maskCFOP } from "~/Util/mascaras";
import * as controllerClassificacoes from "~/controller/controllerClassificacoesTributarias";

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import ReactTable from "react-table";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import MultiSelect from '~/components/MultiSelect/MultiSelect';


import {
    Col,
    Button,
    CardBody,
    Input,
    Row,
    FormGroup,
    Label,
} from "reactstrap";

const idStore = 'CLASSIFICACOES_TRIBUTARIAS';

const Pesquisa = ({ changeActiveTab, changeTribTab, width }) => {
    const dispatch = useDispatch();
    const notificacao = useSelector(state => state.notificacao);
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
    const { pesquisa, classificacoes, tributacao } = useSelector(state => state.classificacoesTributarias);
    const [dataTable, setDataTable] = useState([]);
    const [table, setTable] = useState(renderTable([]));
    const [tableVisible, setTableVisible] = useState(false);
    const [modalExcluir, setModalExcluir] = useState(false);
    const [modalInativar, setModalInativar] = useState(false);
    const [classificacaoSelected, setclassificacaoSelected] = useState({ status: 0 });
    const [loading, setLoading] = useState(false);
    const [nonEditar, setNonEditar] = useState(false);

    useEffect(() => {
        setDataTable(tableData(classificacoes));
        if (classificacoes.length > 0) {
            setTableVisible(true);
        } else {
            setTableVisible(false);
        }
    }, [classificacoes])

    useEffect(() => {
        setTable(renderTable());
    }, [dataTable, loading])

    useEffect(() => {
        if (tributacao.codTributacao === 0) {
            setNonEditar(true)
        }
    }, [tributacao])
    
    function tableData(data) {
        return data.map((prop, key) => {
            return {
                id: key,
                codClassificacao: prop['codClassificacao'],
                codTributacao: prop['codTributacao'],
                descricao: prop['descricao'],
                cfopNfe: maskCFOP(prop['cfopNfe']),
                cfopCf: maskCFOP(prop['cfopCf']),
                uf: prop['uf'],
                status: prop['status'],
            }
        })
    }

    function renderTable() {
        return dataTable.map((obj, key) => {
            return {
                ...dataTable[key],
                actions: (
                    <div className="actions-right">

                        {
                            !loading ?
                            <ArrowTooltip title="Visualizar">
                                <Button
                                    onClick={() => {
                                        handleVisualizarCadastro(obj);
                                    }}
                                    size="sm"
                                    className={classNames("btn-icon btn-link")}
                                    style={{color:"#483D8B"}}

                                >
                                    <Icon icon="eye" className="fa-lg" />
                                </Button>
                             </ArrowTooltip>
                                :
                                <Button
                                    className="btn-icon btn-link"
                                    color="info"
                                    disabled={true}
                                >
                                    <Icon icon="eye" className="fa-lg" />
                                </Button>
                        }
                        {
                            !loading ?
                                <ArrowTooltip title="Editar">
                                    <Button
                                        onClick={() => {
                                            !nonEditar ?
                                                handleEditarTributacao(obj)
                                                :
                                                handleEditarCadastro(obj)
                                        }}
                                        size="sm"
                                        className={classNames("btn-icon btn-link")}
                                        color="success"

                                    >
                                        <Icon icon="edit" className="fa-lg" />
                                    </Button>

                                </ArrowTooltip>
                                :
                                <Button
                                    onClick={() => {
                                        handleEditarCadastro(obj);
                                    }}
                                    size="sm"
                                    className={classNames("btn-icon btn-link")}
                                    color="success"
                                    disabled={true}
                                >
                                    <Icon icon="edit" className="fa-lg" />
                                </Button>
                        }
                        <ArrowTooltip title={obj.status === 0 ? "Inativar" : "Reativar"}>

                            <Button
                                onClick={() => {
                                    handleInativar(obj);
                                }}
                                className="btn-icon btn-link"
                                color="info"
                            >
                                {obj ? obj.status === 0 ?
                                    <Icon icon="ban" className="fa-lg" />
                                    :
                                    <Icon icon="redo" className="fa-lg" />
                                    :
                                    null
                                }
                            </Button>
                        </ArrowTooltip>
                        <ArrowTooltip title="Excluir">

                            <Button
                                onClick={() => { handleExcluir(obj) }}
                                className="btn-icon btn-link"
                                color="danger"
                            >
                                <Icon icon="trash-alt" className="fa-lg" />
                            </Button>
                        </ArrowTooltip>
                    </div >
                )
            };
        })
    }

    function handleOnChange(value, campo) {
        dispatch({ idStore, type: 'PESQUISA', campo, value });
    }

    function handleOnChangeAtivo(value) {
        dispatch({ idStore, type: 'PESQUISA', campo: 'ativos', value });
        controllerClassificacoes.Pesquisar(notificacao, dispatch, codEmpresa, { ...pesquisa, ativos: value });
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            controllerClassificacoes.Pesquisar(notificacao, dispatch, codEmpresa, pesquisa);
        }
    }

    function handlePesquisar(e) {
        e.preventDefault();
        controllerClassificacoes.Pesquisar(notificacao, dispatch, codEmpresa, pesquisa);
    }

    function handleLimpar(e) {
        dispatch({ idStore, type: 'CLEAN_SEARCH' })
        e.preventDefault();
    }

    async function handleVisualizarCadastro(obj){

        setLoading(true);

        if (!loading) {
            await controllerClassificacoes.GetClassificacao(notificacao, dispatch, codEmpresa, obj.codClassificacao, () => {
                controllerClassificacoes.GetTributacoes(notificacao, dispatch, codEmpresa, obj.codClassificacao);
                changeActiveTab("cadastrar")
                changeTribTab('tributacoes')
                dispatch({ idStore, type: 'CLEAN_INVALIDO' });
            });
        }
        setLoading(false);
        dispatch({idStore, type:'VER_CADASTRO', value: true})

    }

    async function handleEditarCadastro(obj) {

        setLoading(true);

        if (!loading) {
            await controllerClassificacoes.GetClassificacao(notificacao, dispatch, codEmpresa, obj.codClassificacao, () => {
                controllerClassificacoes.GetTributacoes(notificacao, dispatch, codEmpresa, obj.codClassificacao);
                changeActiveTab("cadastrar")
                changeTribTab('tributacoes')
                dispatch({ idStore, type: 'CLEAN_INVALIDO' });
            });
        }
        setLoading(false);
    }

    async function handleEditarTributacao(obj) {

        setLoading(true);

        if (!loading) {
            await controllerClassificacoes.GetClassificacao(notificacao, dispatch, codEmpresa, obj.codClassificacao, () => {
                controllerClassificacoes.GetTributacoes(notificacao, dispatch, codEmpresa, obj.codClassificacao);
                controllerClassificacoes.GetTributacao(notificacao, dispatch, codEmpresa, obj.codClassificacao, obj.codTributacao, () => {
                    changeTribTab('tributacao')
                    changeActiveTab("cadastrar")
                    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
                });
            });
        }
        setLoading(false);
    }

    async function handleInativar(obj) {
        setclassificacaoSelected(obj);
        toggleModalInativar();
    }

    async function handleExcluir(obj) {
        setclassificacaoSelected(obj);
        toggleModalExcluir();
    }

    function inativar() {
        controllerClassificacoes.Inativar(notificacao, dispatch, codEmpresa, classificacaoSelected, pesquisa);
    }

    function excluir() {
        controllerClassificacoes.Excluir(notificacao, dispatch, codEmpresa, classificacaoSelected, pesquisa);
    }

    function toggleModalInativar() {
        setModalInativar(!modalInativar);
    }

    function toggleModalExcluir() {
        setModalExcluir(!modalExcluir);
    }
    return (
        <>
            <Row className="padding-padrao-row">

                <Col className="padding-padrao-col" lg="4">
                    <label>Descrição</label>
                    <Input
                        id="pesqdescricao"
                        type="text"
                        autoComplete="off"
                        value={pesquisa.descricao}
                        onChange={e => handleOnChange(e.target.value, 'descricao')}
                        onKeyPress={e => handleKeyPress(e)}
                    />
                </Col>

                <Col className="padding-padrao-col" lg="2">
                    <label>CFOP-NFE</label>
                    <Input
                        type="text"
                        autoComplete="off"
                        value={pesquisa.cfopNfe}
                        onChange={e => handleOnChange(maskCFOP(e.target.value), 'cfopNfe')}
                        onKeyPress={e => handleKeyPress(e)}
                        maxLength="5"
                    />
                </Col>

                <Col className="padding-padrao-col" lg="2">
                    <label>CFOP-CF</label>
                    <Input
                        type="text"
                        autoComplete="off"
                        value={pesquisa.cfopCf}
                        onChange={e => handleOnChange(maskCFOP(e.target.value), 'cfopCf')}
                        onKeyPress={e => handleKeyPress(e)}
                        maxLength="5"
                    />
                </Col>

                <Col className="padding-padrao-col" lg="2">
                    <label>Estados</label>
                    <MultiSelect
                        values={pesquisa.uf}
                        onChange={values => handleOnChange(values, 'uf')}
                        options={estados}
                    />
                </Col>

                <Col lg="1" md="4" className="text-center padding-padrao-col" style={{ paddingTop: '13px' }}>
                    <Col className={width > 768 ? "text-left" : ''}>
                        <FormGroup check className="form-check-radio" Style={"margin-top:-12px;"}>
                            <Label check style={{ paddingRight: '5px' }}>
                                <Input type="radio"
                                    onChange={() => handleOnChangeAtivo('')}
                                    checked={pesquisa.ativos === '' ? true : false}
                                />
                                <span className="form-check-sign" />Todos
                            </Label>
                            <Label check style={{ paddingRight: '5px' }}>
                                <Input type="radio"
                                    onChange={() => handleOnChangeAtivo('0')}
                                    checked={pesquisa.ativos === '0' ? true : false}
                                />
                                <span className="form-check-sign" />Ativos
                            </Label>
                            <Label check style={{ paddingRight: '5px' }}>
                                <Input type="radio"
                                    onChange={() => handleOnChangeAtivo('1')}
                                    checked={pesquisa.ativos === '1' ? true : false}
                                />
                                <span className="form-check-sign" />Inativos
                            </Label>
                        </FormGroup>
                    </Col>
                </Col>



            </Row>

            <Row>
                <CardBody>
                    <Col style={{ width: '100%' }}>
                        <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={e => handleLimpar(e)}
                        >Limpar</Button>
                        <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={e => handlePesquisar(e)}
                        >Pesquisar</Button>
                    </Col>
                </CardBody>
            </Row>

            <div style={tableVisible ? { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
                <ReactTable
                    data={table}
                    filterable={false}
                    columns={
                        [
                            {
                                Header: "Descrição",
                                accessor: "descricao"
                            },
                            {
                                Header: "CFOP-NFE",
                                accessor: "cfopNfe"
                            },
                            {
                                Header: "CFOP-CF",
                                accessor: "cfopCf"
                            },
                            {
                                Header: "Estados",
                                accessor: "uf"
                            },
                            {
                                Header: "Status",
                                accessor: "status",
                                Cell: (props) => props.value === 0 ? "Ativo" : "Inativo"
                            },
                            {
                                Header: "",
                                accessor: "actions",
                                sortable: false,
                                filterable: false
                            }
                        ]
                    }
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    noDataText=""
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando"
                    pageText="Página"
                    ofText="de"
                    rowsText='linhas'
                />
            </div>


            {/* Modal Inativar */}
            <ModalBasic
                isOpen={modalInativar}
                toggle={toggleModalInativar}
                title={`${classificacaoSelected.status === 0 ? "Inativar" : "Ativar"} classificação tributária`}
            >
                <ModalBasicBody>

                    <Col className="text-center">
                        <label>
                            {classificacaoSelected.status === 0 ?
                                <>Tem certeza que deseja <b>inativar</b> essa classificação tributária da sua empresa?</>
                                : <>Tem certeza que deseja <b>ativar</b> essa classificação tributária em sua empresa?</>
                            }
                        </label>
                    </Col>

                </ModalBasicBody>

                <ModalBasicFooter>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { toggleModalInativar() }}
                                style={{ width: '150px' }}
                            >NÃO
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { toggleModalInativar(); inativar() }}
                                style={{ width: '150px' }}
                            >SIM
                            </Button>
                        </Col>
                    </Row>
                </ModalBasicFooter>

            </ModalBasic>
            {/* Modal Inativar*/}

            {/* Modal Excluir */}
            <ModalBasic
                isOpen={modalExcluir}
                toggle={toggleModalExcluir}
                title={'Excluir classificação tributária'}
            >
                <ModalBasicBody>

                    <Col className="text-center">
                        <label>Tem certeza que deseja <b>excluir</b> essa classificação tributária?</label>
                    </Col>

                </ModalBasicBody>

                <ModalBasicFooter>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { toggleModalExcluir() }}
                                style={{ width: '150px' }}
                            >NÃO
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { toggleModalExcluir(); excluir() }}
                                style={{ width: '150px' }}
                            >SIM
                            </Button>
                        </Col>
                    </Row>
                </ModalBasicFooter>

            </ModalBasic>
            {/* Modal Excluir*/}
        </>
    )
}

export default Pesquisa;

const estados = [
    { value: "AC", label: "AC" },
    { value: "AL", label: "AL" },
    { value: "AP", label: "AP" },
    { value: "AM", label: "AM" },
    { value: "BA", label: "BA" },
    { value: "CE", label: "CE" },
    { value: "DF", label: "DF" },
    { value: "ES", label: "ES" },
    { value: "GO", label: "GO" },
    { value: "MA", label: "MA" },
    { value: "MT", label: "MT" },
    { value: "MS", label: "MS" },
    { value: "MG", label: "MG" },
    { value: "PA", label: "PA" },
    { value: "PB", label: "PB" },
    { value: "PR", label: "PR" },
    { value: "PE", label: "PE" },
    { value: "PI", label: "PI" },
    { value: "RJ", label: "RJ" },
    { value: "RN", label: "RN" },
    { value: "RS", label: "RS" },
    { value: "RO", label: "RO" },
    { value: "RR", label: "RR" },
    { value: "SC", label: "SC" },
    { value: "SP", label: "SP" },
    { value: "SE", label: "SE" },
    { value: "TO", label: "TO" }
]