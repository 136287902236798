import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerGruposProduto from "~/controller/controllerGruposProduto";
import history from "~/Util/history";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Select, Switch } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';
import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  ModalBody,
  Modal
} from "reactstrap";

const idStore = "GRUPOS_PRODUTO";


const Grupo = () => {
  const notificacao = useSelector(state => state.notificacao);
  const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
  const { pesquisa, grupo, grupoCopia, invalido, vinculos, verCadastro } = useSelector(state => state.gruposProduto);
  const [modalInativar, setModalInativar] = useState(false);
  const dispatch = useDispatch();

  function onChange(value, stateName) {
    dispatch({ idStore, type: 'GRUPO', campo: stateName, value: value });
    dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
  };

  async function onChangeAtivo(e) {
    await controllerGruposProduto.ExisteVinculo(notificacao, dispatch, codEmpresa, grupo)
    toggleModalInativar()
  };

  function inativar() {
    if (grupo.codGrupo !== '') {
      controllerGruposProduto.Inativar(notificacao, dispatch, codEmpresa, grupo, pesquisa);
    }
    dispatch({ idStore, type: 'GRUPO', campo: 'status', value: grupo.status === 1 ? 0 : 1 });
  }

  async function Salvar() {

    if (grupo.codGrupo === '') {
      controllerGruposProduto.CreateGrupo(notificacao, dispatch, codEmpresa, grupo);
    } else {
      controllerGruposProduto.UpdateGrupo(notificacao, dispatch, codEmpresa, grupo, grupoCopia);
    }
  };

  function Limpar() {
    dispatch({ idStore, type: 'CLEAN_GRUPO' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('cadnome').focus()
  };

  function TelaSubgrupos(e) {
    e.preventDefault();
    history.push('/subgruposProduto');
  };

  function toggleModalInativar() {
    setModalInativar(!modalInativar);
  }

  return (
    <>
      <Row className="justify-content-center">
      <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

       </div>
        <Col>
          <h4 className="info-text">Preencha com as informações do grupo</h4>
          <CardBody>

            <Row className="justify-content-center">

              <Col md="10" className="text-right">
                <Switch
                  componentleft={<label className={grupo.status === 0 ? `text-success text-center` : "text-warning text-center"}>{grupo.status === 0 ? "Ativo" : "Inativo"}</label>}
                  checked={grupo.status === 0}
                  onChange={e => onChangeAtivo(e)}
                  disabled={grupo.codGrupo === "" || verCadastro }
                />
              </Col>

              <Col md="10">

                <Row>

                  <Col className="padding-padrao-col" lg="3" md sm="7">
                    <label>Tipo<span className="error"> *</span></label>
                    <Select
                      first={'Selecione'}
                      value={{ value: grupo.tipo }}
                      options={[{ value: 'P', label: 'Produto' },
                      { value: 'S', label: 'Serviço' }]}
                      onChange={value => onChange(value.value, 'tipo')}
                      
                    />
                  </Col>

                  <Col className="padding-padrao-col" lg md sm="7">
                    <label>Nome<span className="error"> *</span></label>
                    <FormGroup className={invalido.nome ? "has-danger" : ''}>
                      <Input
                        id="cadnome"
                        type="text"
                        value={grupo.nome}
                        onChange={e => onChange(e.target.value, 'nome')}
                        autoComplete="off"
                        maxLength="30"
                    
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

                <Row>

                  <Col className="padding-padrao-col" lg md sm="7">
                    <label>Descrição<span className="error"> *</span></label>
                    <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                      <Input
                        id="descricao"
                        type="text"
                        value={grupo.descricao}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="50"
                    
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                  <ArrowTooltip title='Shift+N'>
                    <Button className="btn-padrao" color="default" onClick={() => Limpar()}>Novo</Button>
                 </ArrowTooltip>
                  <ArrowTooltip title='Shift+S'>
                    <Button className="btn-padrao" color="info" onClick={() => Salvar()}>Salvar</Button>
                 </ArrowTooltip>
                </Col>
                <Col lg="auto" className={window.innerWidth > 660 ? "text-left" : "text-center"} style={window.innerWidth > 660 ? { paddingLeft: '15px' } : {}}>
                  <Button className="btn-padrao" color="warning" onClick={(e) => TelaSubgrupos(e)}><Icon icon="caret-left" className="fa-lg" /> Subgrupos</Button>
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>

      {/* Modal Inativar */}
      <Modal
        isOpen={modalInativar}
        toggle={toggleModalInativar}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalInativar}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">{grupo.status === 0 ? "Inativar" : "Ativar"} grupo</h6>
        </div>
        <ModalBody>

          <Col className="text-center">
            <label>
              {vinculos > 0 ?
                <>O grupo que você está tentando inativar possui subgrupos vinculados a ele, se continuar os subgrupos não estarão mais disponíveis e então consequentemente os produtos serão inativados.<br /></>
                : null
              }
              {grupo.status === 0 ?
                <>Tem certeza que deseja <b>inativar</b> esse grupo de produtos da sua empresa?</>
                : <>Tem certeza que deseja <b>ativar</b> esse grupo de produtos em sua empresa?</>
              }
            </label>
          </Col>

        </ModalBody>
        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => { toggleModalInativar() }}
                style={{ width: '150px' }}
              >NÃO
                                            </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => { toggleModalInativar(); inativar() }}
                style={{ width: '150px' }}
              >SIM
                                            </Button>
            </Col>
          </Row>
        </CardBody>

      </Modal>
      {/* Modal Inativar*/}

    </>
  )
};

class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('cadnome').focus()
    }, 100)
  }

  render() {
    return (
      <Grupo />
    )
  }
}

export default Wizard;
