import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";

const idStore = 'SUBGRUPOS_PRODUTO';

export function Pesquisar(notificacao, dispatch, codEmpresa, pesquisa) {
    try {
        dispatch({ idStore, type: 'SUBGRUPOS', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/subgrupoProduto/subgrupos?codEmpresa=${codEmpresa}&codGrupo=${pesquisa.codGrupo}&status=${pesquisa.ativos}&nome=${pesquisa.nome}&descricao=${pesquisa.descricao}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum subgrupo encontrado", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'SUBGRUPOS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os subgrupos", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetSubgrupo(notificacao, dispatch, codEmpresa, subgrupo, thenFunction) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/subgrupoProduto/subgrupo?codEmpresa=${codEmpresa}&codGrupo=${subgrupo.codGrupo}&codSubgrupo=${subgrupo.codSubgrupo}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            let type = 'SUBGRUPO';
            dispatch({ idStore, type, campo: 'codGrupo', value: response.data.records.codGrupo })
            dispatch({ idStore, type, campo: 'grupo', value: response.data.records.grupo })
            dispatch({ idStore, type, campo: 'codSubgrupo', value: response.data.records.codSubgrupo })
            dispatch({ idStore, type, campo: 'nome', value: response.data.records.nome })
            dispatch({ idStore, type, campo: 'descricao', value: response.data.records.descricao })
            dispatch({ idStore, type, campo: 'status', value: response.data.records.status })
            dispatch({ idStore, type: 'SUBGRUPO_COPIA' })
            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter o subgrupo", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function CreateSubgrupo(notificacao, dispatch, codEmpresa, subgrupo) {
    try {
        //Retorna se houve erro ou nao
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;

        if (subgrupo.codGrupo === "0") {
            alertar(notificacao, 'br', "Selecione o grupo", 'warning', 'icon-alert-circle-exc', 2);
            //document.getElementById("cadGruposSelect").focus()
            return true
        }

        if (subgrupo.nome === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'nome', value: true })
            invalido = true
        }
        if (subgrupo.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/subgrupoProduto/createSubgrupo?codEmpresa=${codEmpresa}&codGrupo=${subgrupo.codGrupo}`,
            headers: { Authorization: basicAuth },
            data: {
                Nome: subgrupo.nome,
                Descricao: subgrupo.descricao,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'SUBGRUPO', campo: 'codSubgrupo', value: response.data.records })
            dispatch({ idStore, type: 'SUBGRUPO_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function UpdateSubgrupo(notificacao, dispatch, codEmpresa, subgrupo, subgrupoCopia) {
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(subgrupo) === JSON.stringify(subgrupoCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;

        if (subgrupo.codGrupo === "0") {
            alertar(notificacao, 'br', "Selecione o grupo", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }
        if (subgrupo.nome === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'nome', value: true })
            invalido = true
        }
        if (subgrupo.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/subgrupoProduto/updateSubgrupo?codEmpresa=${codEmpresa}&codSubgrupo=${subgrupo.codSubgrupo}`,
            headers: { Authorization: basicAuth },
            data: {
                CodGrupo: subgrupo.codGrupo,
                Nome: subgrupo.nome,
                Descricao: subgrupo.descricao,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'SUBGRUPO', campo: 'codSubgrupo', value: response.data.records })
            dispatch({ idStore, type: 'SUBGRUPO_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export function Inativar(notificacao, dispatch, codEmpresa, subgrupo, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/subgrupoProduto/inativarSubgrupo?codEmpresa=${codEmpresa}&codGrupo=${subgrupo.codGrupo}&codSubgrupo=${subgrupo.codSubgrupo}&status=${subgrupo.status}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'SUBGRUPO_COPIA_STATUS', value: subgrupo.status === 1 ? 0 : 1 })
            Pesquisar(notificacao, dispatch, codEmpresa, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function Excluir(notificacao, dispatch, codEmpresa, subgrupo, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/subgrupoProduto/excluirSubgrupo?codEmpresa=${codEmpresa}&codGrupo=${subgrupo.codGrupo}&codSubgrupo=${subgrupo.codSubgrupo}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar(notificacao, dispatch, codEmpresa, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function GetGrupos(notificacao, dispatch, codEmpresa) {
    try {
        dispatch({ idStore, type: 'GRUPOS', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/grupoProduto/grupos?codEmpresa=${codEmpresa}&status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum grupo encontrado", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'GRUPOS', value: response.data.records })
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os grupos", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function ExisteVinculo(notificacao, dispatch, codEmpresa, subgrupo) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios.get(api.urlCadastro + `api/v1/produto/produtos?codEmpresa=${codEmpresa}&codGrupo=${subgrupo.codGrupo}&codSubgrupo=${subgrupo.codSubgrupo}&status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length !== 0) {
                dispatch({ idStore, type: 'VINCULOS', value: response.data.records.length })
                return
            }
            dispatch({ idStore, type: 'VINCULOS', value: 0 })
        }).catch(e => {
            alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
            dispatch({ idStore, type: 'VINCULOS', value: false })
        });
        dispatch({ idStore, type: 'VINCULOS', value: 4 })

    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        dispatch({ idStore, type: 'VINCULOS', value: false })

    }
}