import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";

const idStore = 'GRADES';

export function Pesquisar(notificacao, dispatch, codEmpresa, pesquisa) {
    try {
        dispatch({ idStore, type: 'GRADES', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/grade/grades?codEmpresa=${codEmpresa}&status=${pesquisa.ativos}&codTipoGrade=${pesquisa.codTipoGrade}&descricao=${pesquisa.descricao}&sigla=${pesquisa.sigla}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma grade encontrada", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'GRADES', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as grades", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetGrade(notificacao, dispatch, codEmpresa, codTipoGrade, codGrade, thenFunction){
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ":" + localStorage.getItem('TOKEN_KEY'))

        await axios({
            method: 'get',
            url: api.urlCadastro + `api/v1/grade/grade?codEmpresa=${codEmpresa}&codTipoGrade=${codTipoGrade}&codGrade=${codGrade}`, 
            headers: { Authorization: basicAuth }
        }).then(function (response){ 
                let type = 'GRADE';
                dispatch({ idStore, type, campo: 'codGrade', value: response.data.records.codGrade })
                dispatch({ idStore, type, campo: 'codTipoGrade', value: response.data.records.codTipoGrade })
                dispatch({ idStore, type, campo: 'descricao', value: response.data.records.descricao })
                dispatch({ idStore, type, campo: 'sigla', value: response.data.records.sigla })
                dispatch({ idStore, type, campo: 'status', value: response.data.records.status })
                dispatch({ idStore, type: 'GRADE_COPIA' })
                thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export function GetTipoGrade(notificacao, dispatch, codEmpresa, pesquisa) {
    try {
        dispatch({ idStore, type: 'TIPOGRADE', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/generico?codEmpresa=${codEmpresa}&entidade=TIPO_GRADE`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma grade encontrada", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'TIPOGRADE', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as grades", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function CreateGrade(notificacao, dispatch, codEmpresa, grade){
    
    try{
        const basicAuth = 'Basic ' + btoa(api.apiUser + ":" + localStorage.getItem('TOKEN_KEY'))

        let invalido = false

        if(grade.codTipoGrade === ''){
            dispatch({ idStore, type: 'INVALIDO', campo: 'codTipoGrade', value: true})
            alertar(notificacao, 'br', "Selecione o tipo da grade", 'info', 'icon-bulb-63', 2);
            return true;
        }
        if (grade.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }
        if (grade.sigla === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'sigla', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/grade/create?codEmpresa=${codEmpresa}`,
            headers: { Authorization: basicAuth }, 
            data: {

                CodTipoGrade: grade.codTipoGrade,
                Descricao: grade.descricao,
                Sigla: grade.sigla
            }
        }).then( response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'GRADE', campo: 'codGrade', value: response.data.records })
            dispatch({ idStore, type: 'GRADE_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function UpdateGrade(notificacao, dispatch, codEmpresa, grade, gradeCopia){
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(grade) === JSON.stringify(gradeCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ":" + localStorage.getItem('TOKEN_KEY'))

        let invalido = false

        if(grade.codTipoGrade === ''){
            dispatch({ idStore, type: 'INVALIDO', campo: 'codTipoGrade', value: true})
            alertar(notificacao, 'br', "Selecione o tipo da grade", 'info', 'icon-bulb-63', 2);
            return true;
        }
        if (grade.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }
        if (grade.sigla === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'sigla', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios ({
            method: 'put',
            url: api.urlCadastro + `api/v1/grade/update?codEmpresa=${codEmpresa}&codTipoGrade=${grade.codTipoGrade}&codGrade=${grade.codGrade}`,
            headers: { Authorization: basicAuth },
            data: {
                CodTipoGrade: grade.codTipoGrade,
                Descricao: grade.descricao,
                Sigla: grade.sigla
            }
        }).then ( response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'GRADE', campo: 'codGrade', value: response.data.records })
            dispatch({ idStore, type: 'GRADE_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function inativarGrade(notificacao, dispatch, codEmpresa, gradeSelected) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/grade/inativa?codEmpresa=${codEmpresa}&codTipoGrade=${gradeSelected.codTipoGrade}&codGrade=${gradeSelected.codGrade}&status=${gradeSelected.status}`,
            headers: { Authorization: basicAuth }
        }).then(response => {
            dispatch({idStore, type: 'GRADE', campo: 'status', value: gradeSelected.status === 0 ? 1 : 0})
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
        }).catch(e => {

            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as grades", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function excluirGrade(notificacao, codEmpresa, gradeSelected) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/grade/delete?codEmpresa=${codEmpresa}&codTipoGrade=${gradeSelected.codTipoGrade}&codGrade=${gradeSelected.codGrade}`,
            headers: { Authorization: basicAuth }
        }).then(response => {
           
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as grades", 'warning', 'icon-alert-circle-exc', 2)
    }
}