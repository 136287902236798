import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ imagePreviewUrl: nextProps.imagem !== "" ? 'data:image/jpg;base64,' + nextProps.imagem : this.props.avatar ? defaultAvatar : defaultImage })
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) return
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
      this.handleSubmit(reader.result)
    };
  }
  handleSubmit(img) {
    img = img.split(',')[1]
    this.props.onChange(img)
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.refs.fileInput.value = null;
    this.props.onChange('')
    this.setState({ imagePreviewUrl: defaultImage })
  }
  render() {
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")} style={{ width: '150px', height: '150px' }}>
          <img src={this.state.imagePreviewUrl} alt="..." style={{ width: '100%', height: '100%' }} />
        </div>
        <div>
          <div>
            <label>Tipo: .png .jpg .gif <br /> Tamanho: até {this.props.tamanhoMax ? this.props.tamanhoMax : '500KB'}<span className="error"> *</span></label>
          </div>
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              color={this.props.changeBtnColor}
              className={this.props.changeBtnClasses}
              onClick={() => this.handleClick()}
              disabled={this.props.disabled}
            >
              {this.props.addBtnText}
            </Button>
            <Button
              color={this.props.removeBtnColor}
              className={this.props.removeBtnClasses}
              onClick={() => this.handleRemove()}
              disabled={this.props.disabled}
            >
              <i className="fa fa-times" /> Remover
              </Button>
          </span>
        </div>
      </div >
    );
  }
}

ImageUpload.defaultProps = {
  avatar: false,
  removeBtnClasses: "btn-round",
  removeBtnColor: "danger",
  addBtnClasses: "btn-round",
  addBtnColor: "primary",
  changeBtnClasses: "btn-round",
  changeBtnColor: "primary",
  disabled: false,
  addBtnText: "Adicionar"
};

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  removeBtnClasses: PropTypes.string,
  removeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  addBtnClasses: PropTypes.string,
  addBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  changeBtnClasses: PropTypes.string,
  changeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  disabled: PropTypes.bool,
  addBtnText: PropTypes.string,
};

export default ImageUpload;
