import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import * as controllerLogin from "~/controller/controllerLogin.js";
import classnames from "classnames";
import "react-circular-progressbar/dist/styles.css";
import "react-notification-alert/dist/animate.css";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  CardHeader
} from "reactstrap";


const EsqueciSenha = () => {
  const { novaSenha } = useSelector(state => state.login);
  const notificacao = useSelector(state => state.notificacao);
  const [loginFocus, setLoginFocus] = useState(false);
  const dispatch = useDispatch();
  const idStore = 'LOGIN';

  useEffect(() => {
    document.body.classList.toggle("login-page");
  }, []);

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      controllerLogin.NovaSenha(notificacao, dispatch, novaSenha);
    }
  }

  function handleOnChange(e, campo, type) {
    dispatch({ idStore, type, campo, value: e.target.value });
    dispatch({ idStore, type: 'NOVA_SENHA', campo: 'loginInvalido', value: false });
  }

  function handleEnviar(e) {
    e.preventDefault();
    controllerLogin.NovaSenha(notificacao, dispatch, novaSenha);
  }

  return (
    <>
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form">

              <Card className="card-lock card-white text-center">
                <CardBody>
                  <CardHeader>
                  </CardHeader>
                  <CardTitle tag="h4">

                    Esqueceu sua senha?
                  </CardTitle>

                  <div style={{ position: 'relative', minHeight: '50px', marginTop: '50px' }}>

                    <CardTitle tag="h5"
                    >
                      Qual o e-mail ou CPF da sua conta?
                    </CardTitle>

                    <InputGroup
                      className={classnames(
                        { "input-group-focus": loginFocus },
                        novaSenha.loginInvalido ? 'has-danger' : ''
                      )}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="loginEsqueciSenha"
                        placeholder="Email/CPF"
                        type="text"
                        value={novaSenha.login}
                        onChange={e => handleOnChange(e, 'login', 'NOVA_SENHA')}
                        autoFocus={true}
                        onKeyPress={e => handleKeyPress(e, 'login')}
                        onFocus={() => setLoginFocus(true)}
                        onBlur={() => setLoginFocus(false)}
                        autoComplete="off"
                      />
                    </InputGroup>

                  </div>

                </CardBody>

                <CardFooter style={{ marginTop: '20px' }}>
                  <Row className="justify-content-center">
                    <Button
                      className="btn-round btn-padrao"
                      color="default"
                      onClick={(e) => handleEnviar(e)}
                    >
                      Solicitar nova Senha
                  </Button>
                  </Row>
                </CardFooter>

              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  );
}

export default EsqueciSenha;
