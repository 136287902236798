import React,{ useEffect, useCallback } from 'react'
import ReactTable from 'react-table'
import { Col, Input, Row } from 'reactstrap'
import * as controllerPDV  from '../../../../../controller/Movimentacao/controllerPedidoVenda'
import { IDetalhesFormaPagamento, IDetalhesPedidoPagamento, ITotaisDetalhesPesquisa } from '../../../../../store/reducers/Movimentacao/PedidoVenda/types'
import AppState from '../../../../../store/storeType'
import useScrollBar from '../../../../../Util/Hooks/useScrollBar'
import useShallowEqualSelector from '../../../../../Util/Hooks/useShallowEqualSelector'
import { maskMoeda } from '../../../../../Util/mascaras'
import * as action from '../../../../../store/reducers/Movimentacao/PedidoVenda/actions'
import moment from 'moment'
import { useState } from 'react'

interface IFormaPagamentoProps {
    numPedidoSelect: number;
    isOpen: any;
}

const FormaPagamento: React.FC<IFormaPagamentoProps> = ({numPedidoSelect, isOpen}) => {
    const refTableDetailsPag = useScrollBar([isOpen])
    const detalhesPedidoPagamento = useShallowEqualSelector<AppState, IDetalhesPedidoPagamento[]>(state => state.pedidoVenda.detalhesPedidoPagamento)
    const detalhesFormaPagamento = useShallowEqualSelector<AppState, IDetalhesFormaPagamento[]>(state => state.pedidoVenda.detalhesFormaPagamento)
    const totaisDetalhesPesquisa = useShallowEqualSelector<AppState, ITotaisDetalhesPesquisa>(state => state.pedidoVenda.totaisDetalhesPesquisa)
    const [sumTotal, setSumTotal] = useState({
      totalTitulos: 0,
      totalMultas: 0,
      totalJuros: 0,
      totalDescontos: 0,
      totalPagos: 0
    }) 
    
    useEffect(() => {
        controllerPDV.getDetalhesPedidoPagamento(numPedidoSelect)
    }, [])

    const reduceSum = useCallback((data: string[], ) => {
        let itensSum = data.reduce((itemValue, value) => {
          return Number(itemValue) + Number(value)
        }, 0)
        return itensSum
      }, [])

    useEffect(() => {
        const titulos = detalhesPedidoPagamento.map(formPag => formPag.valorTitulo)
        //@ts-ignore
        const totalTitulo = reduceSum(titulos)
        const juros = detalhesPedidoPagamento.map(formPag => formPag.valorJuros)
        //@ts-ignore
        const totalJuros = reduceSum(juros)
        const multas = detalhesPedidoPagamento.map(formPag => formPag.valorMulta)
        //@ts-ignore
        const totalMultas = reduceSum(multas)
        const descontosPag = detalhesPedidoPagamento.map(formPag => formPag.valorDesconto)
        //@ts-ignore
        const totalDescontos = reduceSum(descontosPag)
        const pagoPag = detalhesPedidoPagamento.map(formPag => formPag.valorTotalPago)
        //@ts-ignore
        const totalPagos = reduceSum(pagoPag)
        action.setTotaisDetalhesPesquisa({
            ...totaisDetalhesPesquisa,
            titulo: totalTitulo,
            juros: totalJuros,
            multa: totalMultas,
            descPag: totalDescontos,
            pago:  totalPagos
          })
    }, [detalhesPedidoPagamento])

    useEffect(() => {
      if(detalhesFormaPagamento.length > 0) {
        const titulos = detalhesFormaPagamento.map(formPag => formPag.valorLancamento)
        //@ts-ignore
        const totalTitulo = reduceSum(titulos)
        const juros = detalhesFormaPagamento.map(formPag => formPag.jurosLancamento)
        //@ts-ignore
        const totalJuros = reduceSum(juros)
        const multas = detalhesFormaPagamento.map(formPag => formPag.multaLancamento)
        //@ts-ignore
        const totalMultas = reduceSum(multas)
        const descontosPag = detalhesFormaPagamento.map(formPag => formPag.descontoLancamento)
        //@ts-ignore
        const totalDescontos = reduceSum(descontosPag)
        const pagoPag = detalhesFormaPagamento.map(formPag => formPag.totalPagoLancamento)
        //@ts-ignore
        const totalPagos = reduceSum(pagoPag)

        setSumTotal({
          totalTitulos: totalTitulo,
          totalDescontos,
          totalJuros,
          totalMultas,
          totalPagos
        })

      }
    }, [detalhesFormaPagamento])

    const getTdProps = (state: any, row: any) => {
      return {
        className: detalhesPedidoPagamento[row.index].valorTotalPago === null ? 'value-notPag' : '' ,
        onClick: () => {
          controllerPDV.getDetalhesFormaPagamento(row.original.codigoTituloMovimento)
        }
      }
    }

    return(
        <Row className="detailsOrder-content" >
          <Col>
           <Row>
            <fieldset className="fieldset-style" style={{ width: '100%', border: '2px solid gray'}}>
                <legend style={{fontSize: 18}}>Forma de Pagamento</legend>
                <div 
                  className="detailsTable-pagamento"
                  ref={refTableDetailsPag}
                  style={{position: 'relative', maxHeight: 200}} 
                >
                  <ReactTable 
                    columns={
                      [
                        {
                            Header: 'Tipo',
                            accessor: 'descricaoForma'
                          },
                          {
                            Header: 'Parcela',
                            accessor: 'parcela',
                            Cell: props => `${Number(props.value)} / ${Number(props.original.quantidadeParcelas)} `
                          },
                          {
                            Header: 'Vencto',
                            accessor: 'dataVencimento',
                            Cell: props => moment(props.value).format('DD-MM-YYYY')
                          },
                          {
                            Header: 'DT.Baixa',
                            accessor: 'dataPagamento',
                            Cell: props => props.value && moment(props.value).format('DD-MM-YYYY')
                          },
                          {
                            Header: 'Título',
                            accessor: 'valorTitulo',
                            Cell: props => maskMoeda(Number(props.value).toFixed(2))
                          },
                          {
                            Header: 'Juros',
                            accessor: 'valorJuros',
                            Cell: props => maskMoeda(Number(props.value).toFixed(2))
                          },
                          {
                            Header: 'Multa',
                            accessor: 'valorMulta',
                            Cell: props => maskMoeda(Number(props.value).toFixed(2))
                          },
                          {
                            Header: 'Desc.',
                            accessor: 'valorDesconto',
                            Cell: props => maskMoeda(Number(props.value).toFixed(2))
                          },
                          {
                            Header: 'Pago',
                            accessor: 'valorTotalPago',
                            Cell: props => maskMoeda(Number(props.value).toFixed(2))
                          }
                      ]
                    }
                    data={detalhesPedidoPagamento}
                    minRows={0}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    className="-striped -highlight header-fix table-detailsOrder"
                    getTdProps={getTdProps}
                    NoDataComponent={() => (<></>)}
                  />
                </div>
              </fieldset>
           </Row>
           <Row className="detailsOrder-inputs">
             <Col>
                <label htmlFor="">Títulos</label>
                <Input value={maskMoeda(Number(totaisDetalhesPesquisa.titulo).toFixed(2))} disabled />
             </Col>
             <Col>
                <label htmlFor="">Multa</label>
                <Input value={maskMoeda(Number(totaisDetalhesPesquisa.multa).toFixed(2))} disabled />
             </Col>
             <Col>
                <label htmlFor="">Juros</label>
                <Input value={maskMoeda(Number(totaisDetalhesPesquisa.juros).toFixed(2))}  disabled  />
             </Col>
             <Col>
                <label htmlFor="">Descontos</label>
                <Input value={maskMoeda(Number(totaisDetalhesPesquisa.descPag).toFixed(2))}  disabled />
             </Col>
             <Col>
                <label htmlFor="">Valor Pago</label>
                <Input
                  className={totaisDetalhesPesquisa.pago <= 0 ? 'value-pag' : ''} 
                  value={maskMoeda(Number(totaisDetalhesPesquisa.pago).toFixed(2))}
                  disabled
                />
             </Col>
           </Row>
           {detalhesFormaPagamento.length > 0 && (
            <>
                <Row>
                  <fieldset className="fieldset-style" style={{ width: '100%', border: '2px solid gray'}}>
                    <legend style={{fontSize: 18}}>Lançamentos</legend>
                    <div 
                      className="detailsTable-lancamentos"
                      ref={refTableDetailsPag}
                      style={{position: 'relative', maxHeight: 200}} 
                    >
                      <ReactTable 
                        columns={
                          [
                            {
                                Header: 'N',
                                accessor: 'numeroLancamento'
                              },
                              {
                                Header: 'Forma de Pagamento',
                                accessor: 'formaPagamento',
                              },
                              {
                                Header: 'Dt Lançamento',
                                accessor: 'dataLancamento',
                              },
                              {
                                Header: 'Valor',
                                accessor: 'valorLancamento',
                                Cell: props => maskMoeda(Number(props.original.valorLancamento).toFixed(2))
                              },
                              {
                                Header: 'Multa',
                                accessor: 'multaLancamento',
                                Cell: props => maskMoeda(Number(props.original.multaLancamento).toFixed(2))
                              },
                              {
                                Header: 'Juros',
                                accessor: 'jurosLancamento',
                                Cell: props => maskMoeda(Number(props.original.jurosLancamento).toFixed(2))
                              },
                              {
                                Header: 'Desconto',
                                accessor: 'descontoLancamento',
                                Cell: props => maskMoeda(Number(props.original.descontoLancamento).toFixed(2))
                              },
                              {
                                Header: 'Total Pago',
                                accessor: 'totalPagoLancamento',
                                Cell: props => maskMoeda(Number(props.original.totalPagoLancamento).toFixed(2))
                              },
                          ]
                        }
                        data={detalhesFormaPagamento}
                        minRows={0}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        className="-striped -highlight header-fix table-detailsOrder"
                        NoDataComponent={() => (<></>)}
                      />
                    </div>
                  </fieldset>
              </Row>
              <Row className="detailsOrder-inputs">
                <Col>
                  <label htmlFor="">Títulos</label>
                  <Input value={maskMoeda(Number(sumTotal.totalTitulos).toFixed(2))} disabled />
                </Col>
                <Col>
                  <label htmlFor="">Multa</label>
                  <Input value={maskMoeda(Number(sumTotal.totalMultas).toFixed(2))} disabled />
                </Col>
                <Col>
                  <label htmlFor="">Juros</label>
                  <Input value={maskMoeda(Number(sumTotal.totalJuros).toFixed(2))}  disabled  />
                </Col>
                <Col>
                  <label htmlFor="">Descontos</label>
                  <Input value={maskMoeda(Number(sumTotal.totalDescontos).toFixed(2))}  disabled />
                </Col>
                <Col>
                  <label htmlFor="">Valor Pago</label>
                  <Input
                    className={sumTotal.totalPagos <= 0 ? 'value-pag' : ''} 
                    value={maskMoeda(Number(sumTotal.totalPagos).toFixed(2))}
                    disabled
                  />
                </Col>
              </Row>
            </>
           )}
           
          </Col>
        </Row>
    )
}

export default FormaPagamento