import React from 'react';

import { Card as CardStrap, Input as InputStrap } from "reactstrap";

import { InputNumber as InputNumberERN } from '~/components/ErnComponets/ErnComponets';
import { InputCpfCnpj as InputCpfCnpjERN } from '~/components/ErnComponets/ErnComponets';

export function Card(props) {
    return (
        <CardStrap
            {...props}
            style={{ marginBottom: 10, padding: 8, ...props.style }}
        >
            {props.children}
        </CardStrap>
    )
}

export function Sub(props) {
    return (
        <h4
            {...props}
            style={{ fontWeight: 600, marginBottom: 5, ...props.style }}
        >
            {props.children}
        </h4>
    )
}

export function Info(props) {
    return (
        <div
            {...props}
            style={{ fontWeight: 600, marginBottom: 5, color: '#8F8F8F', fontSize: 15, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', ...props.style }}
        >
            {props.children}
        </div>
    )
}

export function Line(props) {
    return (
        <div style={{ borderTop: 1, borderTopStyle: 'solid', borderTopColor: '#707070', width: '100%', ...props.style }} />
    )
}

export function Input(props) {
    return (
        <InputStrap
            {...props}
            style={{ padding: 5, fontSize: 16, ...props.style }}
        />
    )
}

export function InputNumber(props) {
    return (
        <InputNumberERN
            {...props}
            style={{ padding: 5, fontSize: 16, ...props.style }}
            onChange={(e) => { props.onChange && props.onChange(e) }}
        />
    )
}

export function InputCpfCnpj(props) {
    return (
        <InputCpfCnpjERN
            {...props}
            style={{ padding: 5, fontSize: 16, ...props.style }}
            onChange={(e) => { props.onChange && props.onChange(e) }}
            value={props.value || ""}
        />
    )
}
