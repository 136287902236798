
import { ConfiguracaoAction, ConfiguracaoStore, ConfiguracaoTypes, IConfiguracaoState } from './types'
import { Reducer } from 'redux'

const INITIAL_STATE: IConfiguracaoState = {
    user: {
        nome: '',
        cpf: '',
        grupo: ''

    },
    telas: [],
    fiveTelas: [],
    fiveTelasCopia: [],
    screen: 0

}
const configuracao: Reducer<IConfiguracaoState, ConfiguracaoAction> = (state = INITIAL_STATE, action: ConfiguracaoAction) => {

    switch (ConfiguracaoStore.ID_STORE) {
        case "CONFIGURACAO":
            switch (action.type) {

                case ConfiguracaoTypes.TELAS:
                    return { ...state, telas: action.value }

                case ConfiguracaoTypes.FIVETELAS:
                    return { ...state, fiveTelas: action.value }

                case ConfiguracaoTypes.USER:
                    return { ...state, user: action.value }

                case ConfiguracaoTypes.FIVETELAS_COPIA:
                    return { ...state, fiveTelasCopia: action.value }
                case ConfiguracaoTypes.CLEAN_ALL:
                    return INITIAL_STATE

                case ConfiguracaoTypes.SCREEN:
                    return { ...state, screen: action.value }

                default:
                    return state
            }

        default:
            return state
    }


}

export default configuracao;