
export enum ServicoStore {

    ID_STORE = 'SERVICOS'

}


export enum ServicoTypes {
    PESQUISA = 'PESQUISA',
    SERVICOS = 'SERVICOS',
    SCREEN = 'SCREEN',
    GRUPOS = 'GRUPOS',

    SUBGRUPOS_PESQ = 'SUBGRUPOS_PESQ',
    SUBGRUPOS_CAD = 'SUBGRUPOS_CAD',
    SERVICO = 'SERVICO',
    INVALIDO = 'INVALIDO',

    SERVICO_COPIA = 'SERVICO_COPIA',
    SERVICO_COPIA_STATUS = 'SERVICO_COPIA_STATUS',

    CLEAN_SEARCH = 'CLEAN_SEARCH',
    CLEAN_SERVICO = 'CLEAN_SERVICO',
    CLEAN_INVALIDO = 'CLEAN_INVALIDO',
    CLEAN_SERVICOS = 'CLEAN_SERVICOS',
    CLEAN_GRUPOS = 'CLEAN_GRUPOS',
    CLEAN_CADSUBGRUPOS = 'CLEAN_CADSUBGRUPOS',
    WIZARD = 'WIZARD',


    INICIO_CADASTRO = 'INICIO_CADASTRO',
    VER_CADASTRO = 'VER_CADASTRO'
}

export type ServicoAction = {

    idStore: ServicoStore.ID_STORE
    type: ServicoTypes

    pesquisa?: IServicoState['pesquisa']
    servicos?: IServicoState['servicos']
    grupos?: IServicoState['grupos']
    pesqsubgrupos?: IServicoState['pesqsubgrupos']
    cadsubgrupos?: IServicoState['cadsubgrupos']
    servico?: IServicoState['servico']
    invalido?: IServicoState['invalido']
    inicioCadastro?: IServicoState['inicioCadastro']
    verCadastro?: IServicoState['verCadastro']
    screen?: IServicoState['screen'] | any
    currentStep?: IServicoState['currentStep']

}

export interface IServicoState {

    pesquisa: Pesquisa,
    servico: Servico,
    invalido: Invalido,

    grupos: any[],
    pesqsubgrupos: any[],
    cadsubgrupos: any[],
    servicos: any[],

    inicioCadastro: boolean,
    verCadastro: boolean,

    servicoCopia: any,
    screen: number,

    currentStep: number

}


export interface Pesquisa {

    codServico: string,
    numeroServico: string,
    codGrupo: string,
    codSubgrupo: string,
    nome: string,
    ativos: number

}

export interface Servico {

    codServico: string,
    numeroServico: string,
    codGrupo: string,
    grupo: string,
    codSubgrupo: string,
    subgrupo: string,
    nome: string,
    descricao: string,
    valor: string,
    obs: string,
    status: number

}


export interface Invalido {
    nome: boolean,
    descricao: boolean,
    valor: boolean,
    obs: boolean
}