import axios from 'axios';
import api from "~/Util/api.js";
import history from "~/Util/history";
import { alertar } from "~/Util/alertar";
import { campoValido } from '~/Util/campoValido';
import store from '~/store/stores';
import routes from '../routes'

var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + api.apiPass);
var idStore = 'LOGIN';

export function login(login, notificacao, dispatch, codEmpresa, codEmpresaFilial) {
    try {
        if (!login.usuario.usuarioExiste) {
            //Busca o usuario
            if (!campoValido(login.usuario.login)) {
                document.getElementById("login").focus()
                alertar(notificacao, 'br', "Digite um CPF ou e-mail", 'info', 'icon-bulb-63', 2)
                return
            }

            getUser(login, notificacao, dispatch);

        } else if (login.usuario.usuarioExiste && !login.usuario.loginSucesso) {
            //Valida o usuario
            if (!campoValido(login.usuario.senha)) {
                document.getElementById("senha").focus()
                alertar(notificacao, 'br', "Digite uma senha", 'info', 'icon-bulb-63', 2)
                return
            }

            autenticar(login, notificacao, dispatch);

        }
        //Quando uma das empresas for selecionada
        if (login.usuario.loginSucesso) {
            axios({
                method: 'post',
                url: api.urlCadastro + 'api/v1/usuario/sessao',
                headers: { Authorization: 'Basic ' + btoa(api.apiUser + ':' + login.usuario.token) },
                data: {
                    CodEmpresa: codEmpresa,
                    CodEmpresaFilial: codEmpresaFilial,
                }
            }).then(response => {
                alertar(notificacao, 'br', "Bem vindo!", 'success', 'icon-check-2', 2)
                dispatch({ idStore, type: 'OBJETOS', campo: 'percentage3', value: 100 })
                dispatch({ idStore, type: 'SAVE' })
                window.localStorage.setItem("TOKEN_KEY", response.data.records)
                setTimeout(() => {
                    history.push('/Dashboard');
                    dispatch({ idStore, type: 'SUCESSO', value: false })
                    dispatch({ idStore, type: 'USUARIO', campo: 'loginSucesso', value: false })
                }, 1000)

            }).catch((erro) => {
                alertar(notificacao, 'br', "Não foi possível iniciar a sessão", 'warning', 'icon-alert-circle-exc', 2, erro)
            });
        }
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

//Desloga o usuario e volta para a tela inicial
export function logout(dispatch) {
    dispatch({ idStore, type: 'LOGOUT' });
    dispatch({ idStore: 'USUARIOS', type: 'CLEAN_CADASTRO' });
    dispatch({ idStore: 'USUARIOS', type: 'CLEAN_SEARCH' });
    window.localStorage.removeItem('USUARIO');
    window.localStorage.removeItem('TOKEN_KEY');
    window.localStorage.removeItem('TOPFIVE')
    history.push('/auth/login');
}

export function lock(dispatch) {
    dispatch({ idStore: 'USUARIOS', type: 'CLEAN_CADASTRO' });
    dispatch({ idStore: 'USUARIOS', type: 'CLEAN_SEARCH' });
    window.localStorage.removeItem('USUARIO');
    window.localStorage.removeItem('TOKEN_KEY');
    window.localStorage.removeItem('TOPFIVE')
    history.push('/auth/login');
}

//Consulta se o usuario existe
function getUser(login, notificacao, dispatch) {
    try {
        dispatch({ idStore, type: 'OBJETOS', campo: 'percentage1', value: 25 });
        axios.get(api.urlCadastro + 'api/v1/usuario/usuario?login=' + login.usuario.login, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            var user = response.data.records
            dispatch({ idStore, type: 'USUARIO', campo: 'usuarioExiste', value: true })
            dispatch({ idStore, type: 'USUARIO', campo: 'apelido', value: user.apelido })
            dispatch({ idStore, type: 'USUARIO', campo: 'codUser', value: user.codUser })
            if (user.foto !== "") {
                dispatch({ idStore, type: 'USUARIO', campo: 'foto', value: 'data:image/jpg;base64,' + user.foto })
            }
            dispatch({ idStore, type: 'OBJETOS', campo: 'loginInvalido', value: false });
            dispatch({ idStore, type: 'OBJETOS', campo: 'percentage1', value: 100 });
            setTimeout(() => { document.getElementById("senha").focus() }, 300)

        }).catch(erro => {
            alertar(notificacao, 'br', "Usuário não encontrado", 'warning', 'icon-alert-circle-exc', 2, erro)
            dispatch({ idStore, type: 'OBJETOS', campo: 'loginInvalido', value: true });
            document.getElementById("login").focus();
            dispatch({ idStore, type: 'OBJETOS', campo: 'percentage1', value: 100 })
            setTimeout(() => { dispatch({ idStore, type: 'OBJETOS', campo: 'percentage1', value: 0 }) }, 500)

        });
    } catch (e) {
        alertar(notificacao, 'br', "Usuário não encontrado", 'warning', 'icon-alert-circle-exc', 2)
        dispatch({ idStore, type: 'OBJETOS', campo: 'loginInvalido', value: true });
        document.getElementById("login").focus();
        dispatch({ idStore, type: 'OBJETOS', campo: 'percentage1', value: 100 })
        setTimeout(() => { dispatch({ idStore, type: 'OBJETOS', campo: 'percentage1', value: 0 }) }, 500)

    }
}

//Autentica o usuário
function autenticar(login, notificacao, dispatch) {
    try {
        dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 25 });
        axios({
            method: 'post',
            url: api.urlCadastro + 'api/v1/usuario/login',
            headers: { Authorization: basicAuth },
            data: {
                login: login.usuario.login,
                senha: login.usuario.senha,
            }
        }).then(response => {

            dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 100 });
            var user = response.data.records;
            dispatch({ idStore, type: 'USUARIO', campo: 'senha', value: '' })
            dispatch({ idStore, type: 'USUARIO', campo: 'empresas', value: user.grupos == null ? [] : user.grupos })
            dispatch({ idStore, type: 'USUARIO', campo: 'token', value: user.token })
            //Se for o primeiro acesso
            if (response.data.records.primeiroAcesso === 0) {
                dispatch({ idStore, type: 'OBJETOS', campo: 'primeiroAcesso', value: true })
                setTimeout(() => { document.getElementById("novaSenha").focus() }, 300)
                return
            }
            if (response.data.records.recSenha === 0) {
                dispatch({ idStore, type: 'OBJETOS', campo: 'alterarSenha', value: true })
                setTimeout(() => { document.getElementById("novaSenha").focus() }, 300)
                return
            }
            selectEmpresa(user.grupos == null ? [] : user.grupos, notificacao, dispatch, user.token)

        }).catch((erro) => {

            alertar(notificacao, 'br', erro.response ? erro.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, erro)
            dispatch({ idStore, type: 'OBJETOS', campo: 'senhaInvalida', value: true });
            document.getElementById("senha").focus()
            setTimeout(() => { dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 0 }); }, 500)

        });

    } catch (e) {

        alertar(notificacao, 'br', "Falha no login", 'warning', 'icon-alert-circle-exc', 2)
        dispatch({ idStore, type: 'OBJETOS', campo: 'senhaInvalida', value: true });
        document.getElementById("senha").focus()
        setTimeout(() => { dispatch({ idStore, type: 'OBJETOS', campo: 'percentage2', value: 0 }); }, 500)

    }
}

function selectEmpresa(empresas, notificacao, dispatch, token) {
    //Caso exista só uma empresa
    dispatch({ idStore, type: 'OBJETOS', campo: 'primeiroAcesso', value: false })
    if (empresas.length === 0) {
        alertar(notificacao, 'br', "Você não está vinculado a uma empresa", 'warning', 'icon-alert-circle-exc', 2)
    } else if (empresas.length === 1) {

        axios({
            method: 'post',
            url: api.urlCadastro + 'api/v1/usuario/sessao',
            headers: { Authorization: 'Basic ' + btoa(api.apiUser + ':' + token) },
            data: {
                CodEmpresa: empresas[0].codEmpresa,
                CodEmpresaFilial: empresas[0].codEmpresaFilial
            }
        }).then(response => {

            dispatch({ idStore, type: 'USUARIO', campo: 'loginSucesso', value: true })
            dispatch({ idStore, type: 'USUARIO', campo: 'empresa', value: empresas[0] })
            dispatch({ idStore, type: 'SAVE' })
            window.localStorage.setItem("TOKEN_KEY", response.data.records)
            alertar(notificacao, 'br', "Bem vindo!", 'success', 'icon-check-2', 2)
            setTimeout(() => { history.push('/Dashboard') }, 1000)

        }).catch((erro) => {
            alertar(notificacao, 'br', "Não foi possível iniciar a sessão", 'warning', 'icon-alert-circle-exc', 2, erro)
        });

    } else if (empresas.length > 1) {
        dispatch({ idStore, type: 'USUARIO', campo: 'loginSucesso', value: true })
        setTimeout(() => { document.getElementById('empresa1').focus() }, 500)
    }
}

export function salvarSenha(login, notificacao, dispatch) {
    function camposInvalidos() {
        dispatch({ idStore, type: 'OBJETOS', campo: 'novaSenhaInvalida', value: true });
        dispatch({ idStore, type: 'OBJETOS', campo: 'confirmarSenhaInvalida', value: true });
    }
    try {
        if (login.objetos.novaSenha === "" && login.objetos.confirmarSenha === "") {
            camposInvalidos();
            return alertar(notificacao, 'br', "Preencha os dois campos", 'info', 'icon-bulb-63', 2);
        }
        if (login.objetos.novaSenha !== login.objetos.confirmarSenha) {
            camposInvalidos();
            return alertar(notificacao, 'br', "As senhas digitadas devem ser iguais", 'warning', 'icon-alert-circle-exc', 2);
        }
        axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/usuario/${login.usuario.primeiroAcesso ? 'primeiroAcesso' : 'alterarSenha'}`,
            headers: { Authorization: basicAuth },
            data: {
                CodUser: login.usuario.codUser,
                Senha: login.objetos.novaSenha
            }
        }).then(function (response) {

            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);

            //Verifica a quantidade de empresas na qual o usuário está viculado
            selectEmpresa(login.usuario.empresas, notificacao, dispatch, login.usuario.token)

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function NovaSenha(notificacao, dispatch, novaSenha) {
    try {
        if (novaSenha.login.trim(" ") === "") {
            dispatch({ idStore, type: 'NOVA_SENHA', campo: 'login', value: "" });
            document.getElementById('loginEsqueciSenha').focus();
            return alertar(notificacao, 'br', "Preencha o campo", 'info', 'icon-bulb-63', 2);
        }
        axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/usuario/novaSenha?login=${novaSenha.login}`,
            headers: { Authorization: basicAuth },
        }).then(function (response) {

            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 5);
            setTimeout(() => {
                history.push('/auth/login');
            }, 1000);

        }).catch(e => {
            if (e.response.data.message.match(/inválido/)) {
                dispatch({ idStore, type: 'NOVA_SENHA', campo: 'loginInvalido', value: true });
            }
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e);
    }
}


function getAcesso(codTelas, newRoutes) {

    newRoutes = JSON.stringify(routes)
    newRoutes = JSON.parse(newRoutes)

    var indexElement = 0
    routes.forEach(element => {

        if (element.required !== true) {
            if (element.views) {

                var indexSubtela = 0

                element.views.forEach(subTela => {

                    if (subTela.views) {

                        subTela.views.forEach(subTela2 => {

                            let index = codTelas.findIndex(item => item === subTela2.id)

                            if (index < 0) {

                                let index2 = newRoutes[indexElement].views[indexSubtela].views.findIndex(item => item.name === subTela2.name)
                                newRoutes[indexElement].views[indexSubtela].views.splice(index2, 1)
                            }
                        })

                        indexSubtela++

                    } else {

                        let index = codTelas.findIndex(item => item === subTela.id)

                        if (index < 0) {

                            let index2 = newRoutes[indexElement].views.findIndex(item => item.name === subTela.name)
                            newRoutes[indexElement].views.splice(index2, 1)

                        } else {
                            indexSubtela++
                        }
                    }
                })
                indexElement++
            } else {

                let index = codTelas.findIndex(item => item === element.id)

                if (index < 0) {

                    let index2 = newRoutes.findIndex(item => item.name === element.name)
                    newRoutes.splice(index2, 1)

                } else {
                    indexElement++
                }
            }
        } else {
            indexElement++
        }
    })

    return newRoutes
}

function clearAcesso(newRoutes) {

    let size = newRoutes.length
    let cont = 0

    for (let i = 0; i < size; i++) {

        if (newRoutes[cont].required !== true) {
            if (newRoutes[cont].views) {

                if (newRoutes[cont].views.length > 0) {

                    let size2 = newRoutes[cont].views.length
                    let cont2 = 0
                    for (let x = 0; x < size2; x++) {

                        if (newRoutes[cont].views[cont2].views) {

                            if (newRoutes[cont].views[cont2].views.length === 0) {

                                newRoutes[cont].views.splice(cont2, 1)

                            } else {
                                cont2++
                            }
                        }else {
                            cont2++
                        }
                    }
                    cont++
                } else {
                    newRoutes.splice(cont, 1)
                }
            } else {
                cont++
            }
        } else {
            cont++
        }
    }

    size = newRoutes.length
    cont = 0

    for (let i = 0; i < size; i++) {

        if (newRoutes[cont].views) {

            if (newRoutes[cont].views.length === 0) {

                newRoutes.splice(cont, 1)

            } else {
                cont++
            }
        } else {
            cont++
        }
    }

    return newRoutes
}

function getAcessoDefault() {
    var route = []
    routes.forEach(element => {

        if (element.required === true) {

            if (element.name) {

                let index = routes.findIndex(item => item.name === element.name)
                if (index >= 0) {
                    route.push(routes[index])
                }

            } else {
                let index = routes.findIndex(item => item.component === element.component)
                if (index >= 0) {
                    route.push(routes[index])
                }
            }
        }
    })

    return route
}
export async function acesso() {

    const { notificacao } = store.getState()
    var newRoutes = []

    if(localStorage.getItem('TOKEN_KEY'))
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        return await axios.get(api.urlCadastro + `api/v1/usuario/acesso`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma informação encontrada", 'info', 'icon-bulb-63', 2)
            }
            if (response.data.records[0].CodTela !== null) {

                newRoutes = getAcesso(response.data.records[0].CodTela, newRoutes)
                newRoutes = clearAcesso(newRoutes)

            } else {
                newRoutes = getAcessoDefault()
            }

            store.dispatch({ idStore, type: 'USUARIO_ACESSO', value: newRoutes })

            return

        }).catch(e => {
            alertar(notificacao, 'br', "Não foi possível obter as informações de acesso", 'warning', 'icon-alert-circle-exc', 2, e)
            return []
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as informações de acesso", 'warning', 'icon-alert-circle-exc', 2, e)
        return []
    }
}