import store from "../../../stores";
import {
    InventarioTypes,
    InventarioStore,
    InventarioAction,
    IInventarioState,

} from "./types";

export const cleanAll = () => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.CLEAN_ALL
})

export const cleanPesquisa = () => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.CLEAN_PESQUISA
})

export const setPesquisa = (pesquisa: IInventarioState['pesquisa']) => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.PESQUISA,
    pesquisa
})

export const setListAtualizar = (listAtualizacao: IInventarioState['listAtualizacao']) => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.LIST_ATUALIZAR,
    listAtualizacao
})

export const setListItens = (listItens: IInventarioState['listItens']) => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.LIST_ITENS,
    listItens
})

export const setListGrupos = (listGrupo: IInventarioState['listGrupo']) => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.LIST_GRUPO,
    value: listGrupo
})

export const setListSubGrupo = (listSubGrupo: IInventarioState['listSubGrupo']) => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.LIST_SUBGRUPO,
    value: listSubGrupo
})

export const setGrade = (listGrade: IInventarioState['listGrade']) => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.LIST_GRADE,
    listGrade
})

export const setGradePesquisa = (listGradePesquisa: IInventarioState['listGradePesquisa']) => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.LIST_GRADE_PESQUISA,
    listGradePesquisa
})

export const setGradeCopia = (listGradeCopia: IInventarioState['listGradeCopia']) => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.LIST_GRADE_COPIA,
    listGradeCopia
})

export const setGradeSelecionada = (gradeSelecionada: IInventarioState['gradeSelecionada']) => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.GRADE_SELECIONADA,
    gradeSelecionada
})

export const setScreen = (screen: IInventarioState['screen']) => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.SCREEN,
    screen
})

export const setEmpresas = (empresas: IInventarioState['empresas']) => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.SET_EMPRESAS,
    empresas
})

export const setFuncionarios = (funcionarios: IInventarioState['funcionarios']) => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.FUNCIONARIOS,
    funcionarios
});

export const setModalFuncionario = (modalFuncionarios: IInventarioState['modalFuncionarios']) => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.MODAL_FUNCIONARIOS,
    modalFuncionarios
})
export const cleanModal = () => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.CLEAN_MODAL_FUNCIONARIO
})

export const setListItensPesquisa = (listItensPesquisa: IInventarioState['listItensPesquisa']) => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.LIST_ITENS_PESQUISA,
    listItensPesquisa
})

export const setListItensInventario = (listItensInventario: IInventarioState['listItensInventario']) => store.dispatch<InventarioAction>({
    idStore: InventarioStore.ID_STORE,
    type: InventarioTypes.LIST_ITENS_INVENTARIO,
    listItensInventario
})

