//State da tela de Pesquisa e cadastro de fornecedores
var INITIAL_STATE = {
    pesquisa: {
        nomeRazao: '',
        apelidoFantasia: '',
        cpfCnpj: '',
        email: '',
        tipo: '',
        ativos: '',
    },
    fornecedor: {
        codFor: '',
        tipoFor: 'J',
        nomeRazao: '',
        apelidoFantasia: '',
        contato: '',
        cpfCnpj: '',
        email: '',
        email2: '',
        rgInscest: '',
        observacao: '',
        telefone1: '',
        telefone2: '',
        telefone3: '',
        cep: '',
        uf: '',
        cidade: '',
        bairro: '',
        logradouro: '',
        numero: '',
        complemento: '',
        referencia: '',
        dtCadastro: '',
        status: '',
        ibge: '',
        gia: '',

    },

    invalido: {
        nomeRazao: false,
        fantasia: false,
        cpfCnpj: false,
        email: false,
        email2: false,
        cep: false,
    },
    fornecedores: [],
    inicioCadastro: false,
    verCadastro: false,
    proximoCadastro: false,
    fornecedorCopia: {},
};

export default function fornecedores(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'FORNECEDORES':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'FORNECEDORES':
                    return { ...state, fornecedores: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: {
                            nomeRazao: '',
                            apelidoFantasia: '',
                            cpfCnpj: '',
                            email: '',
                            tipo: '',
                            ativos: '',
                        },
                        fornecedores: []
                    }
                case 'FORNECEDOR_COMPLETO':
                    return { ...state, fornecedor: action.value }
                case 'FORNECEDOR':
                    return { ...state, fornecedor: { ...state.fornecedor, [action.campo]: action.value } }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value } 
                case 'PROXIMO_CADASTRO':
                    return { ...state, proximoCadastro: action.value }
                case 'FORNECEDOR_COPIA':
                    return { ...state, fornecedorCopia: state.fornecedor }
                case 'FORNECEDOR_COPIA_STATUS':
                    return { ...state, fornecedorCopia: { ...state.fornecedor, status: action.value } }
                case 'CLEAN_FORNECEDOR':
                    return {
                        ...state,
                        fornecedor: {
                            ...state.fornecedor,
                            codFor: '',
                            nomeRazao: '',
                            apelidoFantasia: '',
                            contato: '',
                            cpfCnpj: '',
                            email: '',
                            email2: '',
                            rgInscest: '',
                            observacao: '',
                            telefone1: '',
                            telefone2: '',
                            telefone3: '',
                            cep: '',
                            uf: '',
                            cidade: '',
                            bairro: '',
                            logradouro: '',
                            numero: '',
                            complemento: '',
                            referencia: '',
                            ibge: '',
                            gia: '',
                            status: 0,
                        },
                    }
                case 'CLEAN_ENDERECO':
                    return {
                        ...state,
                        fornecedor: {
                            ...state.fornecedor,
                            cep: '',
                            uf: '',
                            cidade: '',
                            bairro: '',
                            logradouro: '',
                            numero: '',
                            complemento: '',
                            referencia: '',
                            ibge: '',
                            gia: '',
                        },
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: {
                            nomeRazao: false,
                            fantasia: false,
                            cpfCnpj: false,
                            email: false,
                            email2: false,
                            cep: false,
                        },
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}