import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerContas from "~/controller/controllerContas";
import { Switch } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';
import { HelpButton } from "~/components/HelpPlugin/HelpPlugin";

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  Modal,
  ModalBody,
  Label
} from "reactstrap";

const idStore = "CONTAS";

const Conta = () => {
  const notificacao = useSelector(state => state.notificacao);
  const { pesquisa, pesquisaFlags, conta, flags, flagsCopia, contaCopia, invalido,verCadastro } = useSelector(state => state.contas);

  const [modalInativar, setModalInativar] = useState(false);
  const dispatch = useDispatch();

  function onChangeCheck(campo, value) {
    if (value === 0) {
      dispatch({ idStore, type: 'FLAGS', campo, value: 1 })
    } else {
      dispatch({ idStore, type: 'FLAGS', campo, value: 0 })
    }
  }

  function onChange(value, stateName) {
    dispatch({ idStore, type: 'CONTA', campo: stateName, value: value });
    dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
  };

  function onChangeAtivo(e) {
    toggleModalInativar()
  };

  function inativar() {
    controllerContas.Inativar(notificacao, dispatch, conta, pesquisa, pesquisaFlags);

  }

  async function Salvar(e) {
    e.preventDefault();
    if (conta.codConta === '0') {
      controllerContas.CreateConta(notificacao, dispatch, conta, flags);
    } else {
      controllerContas.UpdateConta(notificacao, dispatch, conta, contaCopia, flags, flagsCopia);
    }
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_CONTA' });
    dispatch({ idStore, type: 'CLEAN_FLAGS' })
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('caddescricao').focus()
  };

  function toggleModalInativar() {
    setModalInativar(!modalInativar);
  }

  return (
    <>

      <Row className="justify-content-center">
      <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

      </div>
        <Col>
          <h4 className="info-text">Preencha com as informações da conta</h4>
          <CardBody>

            <Row className="justify-content-center">

              <Col md="12" className="text-right">
                <Switch
                  componentleft={<label className={conta.status === 0 ? `text-success text-center` : "text-warning text-center"}>{conta.status === 0 ? "Ativo" : "Inativo"}</label>}
                  checked={conta.status === 0}
                  onChange={e => onChangeAtivo(e)}
                  disabled={conta.codConta === "0"}
                />
              </Col>

              <Col md="12">

                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col" lg="2" md sm="2">
                    <label>Código</label>
                    <FormGroup>
                      <Input
                        id="cadCodigoconta"
                        type="text"
                        value={conta.codConta}
                        onChange={e => onChange(e.target.value, 'codConta')}
                        disabled={true}
                      />
                      <br></br>
                    </FormGroup>
                  </Col>
                  <Col className="padding-padrao-col" lg md sm="7">
                    <label>Descrição <span className="error">*</span></label>
                    <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                      <Input
                        id="caddescricao"
                        type="text"
                        value={conta.descricao}
                        onChange={e => onChange(e.target.value, 'descricao')}
                        autoComplete="off"
                        maxLength="100"
                    
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                    <Col>
                      <FormGroup check>
                        <Row>
                          <Label check>
                            <Input
                              type="checkbox"
                              onChange={() => onChangeCheck('flPedido', flags.flPedido)}
                              checked={flags.flPedido}
                          
                            />
                            <span className="form-check-sign" />Utilizar no faturamento de pedidos
                          </Label>
                        </Row>
                      </FormGroup>

                      <FormGroup check>
                        <Row>
                          <Label check>
                            <Input
                              type="checkbox"
                              onChange={() => onChangeCheck('flLancTitulo', flags.flLancTitulo)}
                              checked={flags.flLancTitulo}                       
                            />
                            <span className="form-check-sign" />Utilizar no lançamento de títulos (Crédito/Débito)
                        </Label>
                        </Row>
                      </FormGroup>

                      <FormGroup check>
                        <Row>
                          <Label check>
                            <Input
                              type="checkbox"
                              onChange={() => onChangeCheck('flBaixaTitulo', flags.flBaixaTitulo)}
                              checked={flags.flBaixaTitulo}                        
                            />
                            <span className="form-check-sign" />Utilizar em baixa de títulos (Crédito/Débito)
                        </Label>
                        </Row>
                      </FormGroup>

                      <FormGroup check>
                        <Row>
                          <Label check>
                            <Input
                              type="checkbox"
                              onChange={() => onChangeCheck('flEntradaMerc', flags.flEntradaMerc)}
                              checked={flags.flEntradaMerc}
                          
                            />
                            <span className="form-check-sign" />Utilizar em entrada de mercadorias
                        </Label>
                        </Row>
                      </FormGroup>

                    </Col>

                    <div style={{ marginLeft: -2, marginTop: "0.5rem" }}>
                      <HelpButton id="6" />
                    </div>

                  </Col>

                </Row>

              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'center' }}>
                <ArrowTooltip title='Shift+N'>
                  <Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Novo</Button>
                </ArrowTooltip>
                <ArrowTooltip title='Shift+S'>
                  <Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button>
                </ArrowTooltip>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>

      {/* Modal Inativar */}
      <Modal
        isOpen={modalInativar}
        toggle={toggleModalInativar}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalInativar}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">{conta.status === 0 ? "Inativar" : "Ativar"} serviço</h6>
        </div>
        <ModalBody>

          <Col className="text-center">
            <label>
              {conta.status === 0 ?
                <>Tem certeza que deseja <b>inativar</b> esse serviço da sua empresa?</>
                : <>Tem certeza que deseja <b>ativar</b> esse serviço em sua empresa?</>
              }
            </label>
          </Col>

        </ModalBody>
        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => { toggleModalInativar() }}
                style={{ width: '150px' }}
              >NÃO
                                            </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => { toggleModalInativar(); inativar() }}
                style={{ width: '150px' }}
              >SIM
                                            </Button>
            </Col>
          </Row>
        </CardBody>

      </Modal>
      {/* Modal Inativar*/}
    </>
  )
};

class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById("descricaoContas").focus()
    }, 100)
  }

  render() {
    return (
      <Conta />
    )
  }
}

export default Wizard;
