import axios from 'axios';
import api from "~/Util/api.js";
import history from "~/Util/history";


export async function isAuth() {
    var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
    var usuario = localStorage.getItem('USUARIO');
    try {
        const response = await axios.get(api.urlCadastro + 'api/v1/usuario/token', {
            headers: { Authorization: basicAuth }
        });
        if (response.status === 200 && usuario) {
            return;
        }
        else {
            history.push('/auth/login');
            return;
        }
    }
    catch (e) {
        history.push('/auth/login');
        return;
    }
};