import "./styles.css"
import React, { useState, useEffect } from "react";
import {
  Input,
  Row
} from "reactstrap";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import useShallowEqualSelector from "../../Util/Hooks/useShallowEqualSelector";

export function InputCpfCnpj(props) {
  const [valueInput, setValue] = useState('');
  const tipo = props.tipo === 'cnpj' ? props.tipo : 'cpf';

  useEffect(() => {
    setValue(props.value);
    setMask(props.value);
  }, [props.value])

  function setMask(value) {
    let stringFormated = "";

    if (value.length < valueInput.length) {
      setValue(value);
      return
    }

    value = value.replace(/\D/g, '')
    if (tipo === 'cpf') {

      if (value.length > 3 && value.length <= 6) {
        stringFormated = value.replace(/(\d{3})(\d{1})/, "$1.$2");
        setValue(stringFormated)
        return
      }

      if (value.length === 7) {
        stringFormated = value.replace(/(\d{3})(\d{3})/, "$1.$2.");
        setValue(stringFormated)
        return
      }

      if (value.length <= 9) {
        stringFormated = value.replace(/(\d{3})(\d{3})(\d{1})/, "$1.$2.$3");
        setValue(stringFormated)
        return
      }

      if (value.length > 9) {
        stringFormated = value.replace(/(\d{3})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3-$4");
        setValue(stringFormated)
        return
      }
    } else if (tipo === 'cnpj') {

      if (value.length > 2 && value.length <= 5) {
        stringFormated = value.replace(/(\d{2})(\d{1})/, "$1.$2");
        setValue(stringFormated)
        return
      }

      if (value.length > 5 && value.length <= 8) {
        stringFormated = value.replace(/(\d{2})(\d{3})(\d{1})/, "$1.$2.$3");
        setValue(stringFormated)
        return
      }

      if (value.length > 8 && value.length <= 12) {
        stringFormated = value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3/$4");
        setValue(stringFormated)
        return
      }

      if (value.length === 13) {
        stringFormated = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/, "$1.$2.$3/$4-$5");
        setValue(stringFormated)
        return
      }

      if (value.length === 14) {
        stringFormated = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
        setValue(stringFormated)
        return
      }
    }

    setValue(value);
  }

  return (
    <Input
      {...props}
      type="text"
      onChange={e => { props.onChange(e) }}
      value={valueInput}
      maxLength={props.maxLength ? props.maxLength : props.tipo === 'cnpj' ? 18 : 14}
    />
  )
}

export function InputTelefone(props) {
  const [valueInput, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
    setMask(props.value);
  }, [props.value])

  function setMask(value) {
    let stringFormated = "";


    if (value.length < valueInput.length) {
      if (value.length === 14) {
        stringFormated = value.replace(/[()-\s]/g, "");
        stringFormated = stringFormated.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")
        setValue(stringFormated)
        return
      }

      setValue(value);
      return
    }

    value = value.replace(/\D/g, '')
    if (value.length === 1) {
      stringFormated = value.replace(/(\d{1})/, "($1");
      setValue(stringFormated)
      return
    }

    if (value.length === 2) {
      stringFormated = value.replace(/(\d{2})/, "($1) ");
      setValue(stringFormated)
      return
    }

    if (value.length > 2 && value.length <= 6) {
      stringFormated = value.replace(/(\d{2})(\d{1})/, "($1) $2");
      setValue(stringFormated)
      return
    }

    if (value.length > 6 && value.length <= 9) {
      stringFormated = value.replace(/(\d{2})(\d{4})(\d{1})/, "($1) $2-$3");
      setValue(stringFormated)
      return
    }

    if (value.length === 10) {
      stringFormated = value.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
      setValue(stringFormated)
      return
    }

    if (value.length === 11) {
      stringFormated = value.replace(/[()-\s]/g, "");
      stringFormated = stringFormated.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")
      setValue(stringFormated)
      return
    }

    setValue(value);
  }

  return (
    <Input
      {...props}
      type="text"
      onChange={e => { props.onChange(e) }}
      value={valueInput}
      maxLength={15}
    />
  )
}

export function Select(props = { options: [] }) {
  //props
  //value={value:'valor atual',label:'texto atual'} opcionais
  //select={value:'nome do campo que será o valor',label:'nome do campo que será o texto'}opcional
  //first=texto do primeiro campo com valor 0 opcional
  const [selected, setselected] = useState({ value: "0", label: props.first });
  const [options, setoptions] = useState([]);

  useEffect(() => {
    let obj = [];
    if (props.first) {
      obj.push({ value: "0", label: props.first })
    }
    props.options.forEach(option => {
      if (props.select) {
        obj.push({ value: option[props.select.value], label: option[props.select.label] })
      } else {
        obj.push({ value: option.value, label: option.label })
      }
    });
    setoptions(obj)
    setselected(props.options.find(option => option.value === "0"))
  }, [props.options])

  useEffect(() => {
    options.length > 0 &&
      setselected(options.find(option => option.value === props.value.value || option.label === props.value.label))
  }, [props.value])

  return (
    <ReactSelect
      {...props}
      className="react-select info"
      classNamePrefix="react-select"
      value={selected}
      options={options}
      onChange={value => props.onChange(value)}
      noOptionsMessage={() => "Não encontrado"}
      isDisabled={props.disabled}
    />
  )
}

export function Switch(props) {
  const ComponentTop = () => (props.componenttop || <></>)
  const ComponentLeft = () => (props.componentleft || <></>)
  const ComponentRight = () => (props.componentright || <></>)
  return (
    <div className="switch-body">
      <div className="switch-container">
        {props.componenttop && <ComponentTop className="switch-text-top" />}

        <div className="switch-row">

          {props.componentleft &&
            <div className="switch-text-left" >
              <ComponentLeft />
            </div>
          }

          <label className="switch" >
            <input
              {...props}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>

          {props.componentright &&
            <div className="switch-text-right" >
              <ComponentRight />
            </div>
          }

        </div>
      </div>
    </div>
  )
}

export function MiniMenu() {

  const dispatch = useDispatch(0)
  const { cores } = useShallowEqualSelector(state => state.perfilUsuario)

  useEffect(() => {
    var sidebarMini = localStorage.getItem('sidebarMini')
    dispatch({ idStore: 'PERFIL_USUARIO', type: 'CORES', campo: 'sidebarMini', value: sidebarMini === "true" ? true : false })

    if (sidebarMini === "false") {
      document.body.classList.remove('sidebar-mini')
    }
  }, [])

  useEffect(() => {
    const cor = localStorage.getItem('color')
    dispatch({ idStore: 'PERFIL_USUARIO', type: 'CORES', campo: 'sidebar', value: cor })
  }, [])

  useEffect(() => {
    const tema = localStorage.getItem('tema')
    dispatch({ idStore: 'PERFIL_USUARIO', type: 'CORES', campo: 'mode', value: tema === "true" ? true : false })
  }, [])

  function changeColor(cor) {
    dispatch({ idStore: 'PERFIL_USUARIO', type: 'CORES', campo: 'sidebar', value: cor })
    localStorage.setItem('color', cor)
  }

  function changeSidebarMini() {
    dispatch({ idStore: 'PERFIL_USUARIO', type: 'CORES', campo: 'sidebarMini', value: !(cores.sidebarMini) })
    localStorage.setItem('sidebarMini', !(cores.sidebarMini))

    if (cores.sidebarMini === '') {
      document.body.classList.remove('sidebar-mini')
    } else if (cores.sidebarMini === false) {
      document.body.classList.toggle('sidebar-mini')

    }
  }

  function changeMode(mode) {

    dispatch({ idStore: 'PERFIL_USUARIO', type: 'CORES', campo: 'mode', value: mode })
    localStorage.setItem('TEMA', mode)

    switch (mode) {
      case "white-content":

        if (document.body.classList.contains(mode)) {

          document.body.classList.remove(mode)

        } else {

          document.body.classList.forEach((element) => {

            if (element !== 'sidebar-mini') {

              document.body.classList.remove(element)

            }
          });

          document.body.classList.add(mode)

        }

        break;

      case "yellow-content":

        if (document.body.classList.contains(mode)) {

          document.body.classList.remove(mode)

        } else {

          document.body.classList.forEach((element) => {

            if (element !== 'sidebar-mini') {

              document.body.classList.remove(element)

            }
          });

          document.body.classList.add(mode)

        }

        break;

        case "gray-content":

        if (document.body.classList.contains(mode)) {

          document.body.classList.remove(mode)

        } else {

          document.body.classList.forEach((element) => {

            if (element !== 'sidebar-mini') {

              document.body.classList.remove(element)

            }
          });

          document.body.classList.add(mode)

        }

        break;

      case "blue-content":

        if (document.body.classList.contains(mode)) {

          document.body.classList.remove(mode)

        } else {

          document.body.classList.forEach((element) => {

            if (element !== 'sidebar-mini') {

              document.body.classList.remove(element)

            }
          });

          document.body.classList.add(mode)

        }

        break;

      default:

        document.body.classList.forEach((element) => {

          if (element !== 'sidebar-mini') {

            document.body.classList.remove(element)

          }
        });

        break;
    }



  }

  return (

    <Row style={{ marginLeft: 50, position: 'top', alignItems: 'center' }}>
      <div style={{ alignItems: 'center', textAlign: 'center' }}>
        <label >SIDEBAR BACKGROUND</label>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

          <span
            style={{ backgroundColor: '#e14eca' }}
            className={cores.sidebar === "primary" ? cores.mode === '' ? "spn spn-active" : " spn-lightMode spn-lightMode-active" : cores.mode === '' ? "spn" : "spn-lightMode"}
            data-color="primary"
            onClick={() => {
              changeColor("primary")
            }}>

          </span>
          <span
            style={{ backgroundColor: '#1d8cf8' }}
            className={cores.sidebar === "blue" ? cores.mode === '' ? "spn spn-active" : "spn-lightMode spn-lightMode-active" : cores.mode === '' ? "spn" : "spn-lightMode"}
            data-color="ye"
            onClick={() => {
              changeColor("blue")
            }}>

          </span>
          <span
            style={{ backgroundColor: '#00bf9a' }}
            className={cores.sidebar === "green" ? cores.mode === '' ? "spn spn-active" : "spn-lightMode spn-lightMode-active" : cores.mode === '' ? "spn" : "spn-lightMode"}
            data-color="green"
            onClick={() => {
              changeColor("green")
            }}>

          </span>
          <span
            style={{ backgroundColor: '#ff8d72' }}
            className={cores.sidebar === "orange" ? cores.mode === '' ? "spn spn-active" : "spn-lightMode spn-lightMode-active" : cores.mode === '' ? "spn" : "spn-lightMode"}
            data-color="orange"
            onClick={() => {
              changeColor("orange")
            }}>

          </span>
          <span
            style={{ background: '#fd5d93' }}
            className={cores.sidebar === "red" ? cores.mode === '' ? "spn spn-active" : "spn-lightMode spn-lightMode-active" : cores.mode === '' ? "spn" : "spn-lightMode"}
            data-color="red"
            onClick={() => {
              changeColor("red")
            }}>
          </span>

        </div>

        <br />
        <label >COLOR MODE</label>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

          <span
            style={{ backgroundColor: '#ffffff' }}
            className={cores.sidebar === "primary" ? cores.mode !== "white-content" ? "spn spn-active" : " spn-lightMode spn-lightMode-active" : cores.mode === '' ? "spn" : "spn-lightMode"}
            data-color="primary"
            onClick={() => {
              changeMode("white-content")
            }}>

          </span>
          <span
            style={{ backgroundColor: '#DEDE61' }}
            className={cores.sidebar === "primary" ? cores.mode === 'yellow-content' ? "spn spn-active" : " spn-lightMode spn-lightMode-active" : cores.mode === '' ? "spn" : "spn-lightMode"}
            data-color="yellow"
            onClick={() => {
              changeMode("yellow-content")
            }}>

          </span>
          <span
            style={{ backgroundColor: '#C4C4C4' }}
            className={cores.sidebar === "primary" ? cores.mode === 'gray-content' ? "spn spn-active" : " spn-lightMode spn-lightMode-active" : cores.mode === '' ? "spn" : "spn-lightMode"}
            data-color="gray"
            onClick={() => {
              changeMode("gray-content")
            }}>

          </span>
          <span
            style={{ backgroundColor: '#79BAE4' }}
            className={cores.sidebar === "primary" ? cores.mode === 'blue-content' ? "spn spn-active" : " spn-lightMode spn-lightMode-active" : cores.mode === '' ? "spn" : "spn-lightMode"}
            data-color="primary"
            onClick={() => {
              changeMode("blue-content")
            }}>

          </span>
          <span
            style={{ backgroundColor: '#000000' }}
            className={cores.sidebar === "primary" ? cores.mode === '' ? "spn spn-active" : " spn-lightMode spn-lightMode-active" : cores.mode === '' ? "spn" : "spn-lightMode"}
            data-color="black"
            onClick={() => {
              changeMode("")
            }}>

          </span>
        </div>
        <br />
        <label >SIDEBAR MINI</label>
        <Row style={{ justifyContent: 'center' }}>
          <Row md="10" className="text-right" style={{ alignItems: "center", textAlign: 'center' }}>
            <label  >OFF</label>
            <Switch
              onChange={() => { changeSidebarMini() }}
              checked={cores.sidebarMini}
            />
            <label >ON</label>
          </Row>

        </Row>

      </div>

    </Row>
  );
}

export function InputNumber(props) {

  function setMask(value) {
    const format = props.format ? props.format.split(',') : ['10', '0']
    const digitos = parseInt(format[0])
    const decimais = parseInt(format[1])

    if (decimais === 0) {
      return String(value).replace(/[^0-9]/g, '')
    }
    value = String(value).replace('.', ',').replace(/[^0-9,]/g, '')

    if (value.substring(0, 1) === ",") {
      value = value.replace(value.substring(0, 1), '0,')
    }

    let virgula = false

    for (var i = 0; i < value.length; i++) {
      if (value.substring(i, i + 1) === ",") {
        if (virgula) {
          value = value.substring(0, i) + value.substring(i + 1, value.length)
        }
        virgula = true
      }
    }

    if (!virgula) {
      if (value.length === (digitos - decimais + 1)) {
        let re = new RegExp(`(\\d{${digitos - decimais}})(\\d{1})`);
        value = value.replace(re, "$1,$2");

        return value
      }
    } else {
      let [valueDigitos, valueDecimais] = value.split(',')

      if (valueDecimais.length > decimais) {
        return valueDigitos + ',' + valueDecimais.slice(0, decimais)
      }
    }
    return value
  }

  return (
    <Input
      {...props}
      type="text"
      value={setMask(props.value)}
      maxLength={`${parseInt(props.format ? props.format.split(',')[0] : ['10', '0']) + (parseInt(props.format ? props.format.split(',')[0] : ['10', '0']) === 0 ? 0 : 1)}`}
      onChange={(e) => props.onChange(e)}
      onBlur={() => props.onBlur ? props.onBlur() : () => { }}
    />
  )

}