import React from "react";
import { useSelector, shallowEqual } from 'react-redux';


import {
    Input,
    Row,
    Col
} from "reactstrap";


const formulario = () => {
    const funcionario = useSelector(state => state.lancamentoTitulos.cadastro.funcionario, shallowEqual)
    const fornecedor = useSelector(state => state.lancamentoTitulos.cadastro.fornecedor, shallowEqual)
    const dadosConta = useSelector(state => state.lancamentoTitulos.cadastro.dadosConta, shallowEqual)

    return (
        <>
            <fieldset style={{ border: 'solid 1px #5E6680', borderRadius: 5 }}>
                <Row className="padding-padrao-row">
                    <Col className="padding-padrao-col" lg="2">
                        <label>Data Lançamento</label>
                        <Input
                            type="date"
                            value={fornecedor.dataLancamento}
                            disabled={true}
                        />
                    </Col>
                    <Col className="padding-padrao-col">
                        <label>Empresa/Filial</label>
                        <Input
                            type="text"
                            value={fornecedor.nomeFilial}
                            disabled={true}
                        />
                    </Col>
                    <Col className="padding-padrao-col">
                        <label>Pessoa/Fornecedor</label>
                        <Input
                            type="text"
                            value={fornecedor.nomeFor}
                            disabled={true}
                        />
                    </Col>
                </Row>

                <Row className="padding-padrao-row">
                    <Col className="padding-padrao-col" sm="12" lg="4">
                        <label>Conta</label>
                        <Input
                            type="text"
                            value={dadosConta.conta}
                            disabled={true}
                        />
                    </Col>
                    <Col className="padding-padrao-col">
                        <label>Caixa</label>
                        <Input
                            type="number"
                            value={funcionario.codCaixa}
                            disabled={true}
                        />
                    </Col>
                    <Col className="padding-padrao-col">
                        <label>Data/Abertura</label>
                        <Input
                            type="date"
                            value={funcionario.dataAberturaCaixa}
                            disabled={true}
                        />

                    </Col>
                    <Col className="padding-padrao-col" lg="3" sm="12">
                        <label>usuario</label>
                        <Input
                            type="text"
                            value={funcionario.nomeFunc}
                            disabled={true}
                        />
                    </Col>
                </Row>
            </fieldset>
        </>)
}

export default formulario