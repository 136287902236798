import store from "../../../stores";
import {
    ServicoStore,
    ServicoAction,
    IServicoState,
    ServicoTypes
} from "./types";


export const setPesquisa = (pesquisa: IServicoState['pesquisa']) => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.PESQUISA,
    pesquisa

})

export const setServicos = (servicos: IServicoState['servicos']) => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.SERVICOS,
    servicos

})

export const setGrupos = (grupos: IServicoState['grupos']) => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.GRUPOS,
    grupos

})

export const setPesqSubGrupos = (pesqsubgrupos: IServicoState['pesqsubgrupos']) => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.SUBGRUPOS_PESQ,
    pesqsubgrupos

})
export const setCadSubGrupos = (cadsubgrupos: IServicoState['cadsubgrupos']) => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.SUBGRUPOS_CAD,
    cadsubgrupos

})

export const cleanCadSubGrupos = () => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.CLEAN_CADSUBGRUPOS

})

export const setServico = (servico: IServicoState['servico']) => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.SERVICO,
    servico

})

export const setInvalido = (invalido: IServicoState['invalido']) => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.INVALIDO,
    invalido

})

export const setScreen = (screen: IServicoState['screen']) => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.SCREEN,
    screen

})
export const setVerCadastro = (verCadastro: IServicoState['verCadastro']) => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.VER_CADASTRO,
    verCadastro

})

export const setCurrentStep = (currentStep: IServicoState['currentStep']) => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.WIZARD,
    currentStep

})

export const setServicoCopia = () => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.SERVICO_COPIA,
    servico: store.getState().servico.servico

})

export const cleanSearch = () => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.CLEAN_SEARCH

})

export const cleanServico = () => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.CLEAN_SERVICO

})

export const cleanInvalido = () => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.CLEAN_INVALIDO

})

export const cleanServicos = () => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.CLEAN_SERVICOS

})
export const cleanGrupos = () => store.dispatch<ServicoAction>({

    idStore: ServicoStore.ID_STORE,
    type: ServicoTypes.CLEAN_GRUPOS

})

