import { IUsuarioState, UsuarioAction, UsuarioStore, UsuarioTypes } from "./types";
import store from "../../../stores";

export const cleanPesquisa = () => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.CLEAN_SEARCH
})

export const setEmpresaUserCopia = () => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.EMPRESASUSER_COPIA,
    empresasUser: store.getState().usuario.empresasUser
})

export const setFuncionarioCopia = () => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.FUNCIONARIO_COPIA,
    funcionario: store.getState().usuario.funcionario
})

export const cleanUsuario = () => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.CLEAN_USUARIO

})
export const cleanFuncionario = () => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.CLEAN_FUNCIONARIO

})

export const cleanAcesso = () => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.CLEAN_ACESSO

})

export const cleanInvalido = () => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.CLEAN_INVALIDO
})

export const cleanEmpresaUser = () => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.CLEAN_EMPRESA_USER
})

export const setVerificationUser = (verificationUser: IUsuarioState['verificationUser']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.VERIFICATION_USER,
    verificationUser
})

export const setPesquisa = (pesquisa: IUsuarioState['pesquisa']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.PESQUISA,
    pesquisa
})

export const setFuncoes = (funcoes: IUsuarioState['funcoes']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.FUNCOES,
    funcoes
})

export const setScreen = (screen: IUsuarioState['screen']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.SCREEN,
    screen
})

export const setCurrentStep = (currentStep: IUsuarioState['currentStep']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.WIZARD_STEP,
    currentStep
})

export const setInvalido = (invalido: IUsuarioState['invalido']) => store.dispatch<UsuarioAction>({

    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.INVALIDO,
    invalido

})

export const setUsuarios = (usuarios: IUsuarioState['usuarios']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.USUARIOS,
    usuarios
})


export const setInicioCadastro = (inicioCadastro: boolean) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.INICIO_CADASTRO,
    inicioCadastro
})

export const setProximoCadastro = (proximoCadastro: boolean) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.PROXIMO_CADASTRO,
    proximoCadastro
})

export const setEmpresas = (empresas: IUsuarioState['empresas']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.EMPRESAS,
    empresas
})

export const setFiliais = (filiais: IUsuarioState['filiais']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.FILIAIS,
    filiais
})

export const setEmpresaUser = (empresasUser: IUsuarioState['empresasUser']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.EMPRESASUSER,
    empresasUser
})

export const setGrupos = (grupos: IUsuarioState['grupos']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.GRUPOS,
    grupos
})

export const setAcesso = (acesso: IUsuarioState['acesso']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.ACESSO,
    acesso
})

export const setUsuario = (usuario: IUsuarioState['usuario']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.USUARIO,
    usuario
})

export const setFuncionario = (funcionario: IUsuarioState['funcionario']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.FUNCIONARIO,
    funcionario
})

export const onChangeTelaAcesso = (value: any) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.CHANGE_TELA,
    value
})

export const setVerCadastro = (verCadastro: IUsuarioState['verCadastro']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.VER_CADASTRO,
    verCadastro
})
export const setGruposAcessoUsuario = (gruposAcessoUsuario: IUsuarioState['gruposAcessoUsuario']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.GRUPOS_ACESSO,
    gruposAcessoUsuario
})

export const setVerificationSalvar = (verificationSalvar: IUsuarioState['verificationSalvar']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.VERIFICATION_SALVAR,
    verificationSalvar
})

export const setEditar = (editar: IUsuarioState['editar']) => store.dispatch<UsuarioAction>({
    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes.EDITAR,
    editar
})
