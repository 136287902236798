import React, { useCallback, useState } from 'react'
import { Col, Row,  Button, ButtonGroup, TabContent } from 'reactstrap'
import classNames from 'classnames'
import { ModalBasic, ModalBasicBody } from '../../../../components/ModalBasic/ModalBasic'
import * as action from '../../../../store/reducers/Movimentacao/PedidoVenda/actions'

import ItensPedido from './StepModal/ItensPedido'
import FormaPagamento from './StepModal/FomaPagamento'
import { IDetalhesFormaPagamento, IDetalhesPedidoItens } from '../../../../store/reducers/Movimentacao/PedidoVenda/types'
import AppState from '../../../../store/storeType'
import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector'
import { useEffect } from 'react'

interface ModalInfoPedidos {
  isOpen: Boolean;
  toggle: any;
  numPedidoSelect: any;
  statusPedidoSelect: string;
}


const ModalInfoPedidos: React.FC<ModalInfoPedidos> = ({isOpen, toggle, numPedidoSelect, statusPedidoSelect }) => {
  const [horizontalTabs, setHorizontalTabs] = useState('itensPedido');
  const detalhesFormaPagamento = useShallowEqualSelector<AppState, IDetalhesFormaPagamento[]>(state => state.pedidoVenda.detalhesFormaPagamento)

  const changeActiveTab = useCallback((tabName: string) => {
    setHorizontalTabs(tabName)
  }, [horizontalTabs])

  useEffect(() => {
    if(detalhesFormaPagamento.length > 0) {
      action.cleanDetalhesPedido()
    }
  }, [isOpen])

  return(
    <ModalBasic
      isOpen={isOpen}
      toggle={() => toggle()}
      title='Detalhes do Pedido'
      size="lg"
      id="modalInfoOrder"
    >
      <ModalBasicBody >
        <Row className="detailsOrder-content">
          <Col>
              <ButtonGroup style={{ width: '100%'}}>
                <Button
                  color={
                      horizontalTabs === "itensPedido"
                          ? "info"
                          : ""
                  }
                  type="button"
                  data-toggle="tab"
                  className={classNames("btn-gp-padrao",
                      horizontalTabs === "itensPedido"
                          ? "active"
                          : ""
                  )}
                  onClick={() => changeActiveTab('itensPedido')}
                  style={{ width: '50%' }}
                >Itens do Pedido</Button>
                <Button 
                  color={
                      horizontalTabs === "formaPagamento"
                          ? "info"
                          : ""
                  }
                  type="button"
                  data-toggle="tab"
                  className={classNames("btn-gp-padrao",
                      horizontalTabs === "formaPagamento"
                          ? "active"
                          : ""
                  )}
                  onClick={() => changeActiveTab('formaPagamento')}
                  style={{ width: '50%' }}
                  disabled={statusPedidoSelect === 'ABERTO'}
                >Forma de Pagamento</Button>
              </ButtonGroup>
              <TabContent className="tab-space" activeTab={horizontalTabs}>
                  {
                    horizontalTabs === 'itensPedido' ? (
                      <ItensPedido numPedidoSelect={numPedidoSelect} isOpen={isOpen} />
                    ) : (
                      <FormaPagamento numPedidoSelect={numPedidoSelect} isOpen={isOpen} />
                    )
                  }
              </TabContent>
            </Col>
        </Row>
      </ModalBasicBody>
    </ModalBasic>
  )
}

export default ModalInfoPedidos