import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";

const idStore = 'CLASSIFICACOES_TRIBUTARIAS';

export function Pesquisar(notificacao, dispatch, codEmpresa, pesquisa) {
    try {
        dispatch({ idStore, type: 'CLASSIFICACOES', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/classificacao/classificacoes?codEmpresa=${codEmpresa}&status=${pesquisa.ativos}&descricao=${pesquisa.descricao}&uf=${pesquisa.uf}&cfopNfe=${pesquisa.cfopNfe.replace(/\./g, '')}&cfopCf=${pesquisa.cfopCf.replace(/\./g, '')}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma classificação encontrada", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'CLASSIFICACOES', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as classificações", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetClassificacao(notificacao, dispatch, codEmpresa, codClassificacao, thenFunction) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/classificacao/classificacao?codEmpresa=${codEmpresa}&codClassificacao=${codClassificacao}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            let type = 'CLASSIFICACAO';
            dispatch({ idStore, type, campo: 'codClassificacao', value: response.data.records.codClassificacao })
            dispatch({ idStore, type, campo: 'descricao', value: response.data.records.descricao })
            dispatch({ idStore, type, campo: 'status', value: response.data.records.status })
            dispatch({ idStore, type: 'CLASSIFICACAO_COPIA' })
            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export async function CreateClassificacao(notificacao, dispatch, codEmpresa, classificacao, thenFunction = () => { }) {
    try {
        //Retorna se houve erro ou nao
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;
        if (classificacao.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/classificacao/createClassificacao?codEmpresa=${codEmpresa}`,
            headers: { Authorization: basicAuth },
            data: {
                Descricao: classificacao.descricao,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'CLASSIFICACAO', campo: 'codClassificacao', value: response.data.records })
            dispatch({ idStore, type: 'CLASSIFICACAO_COPIA' })
            thenFunction()
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function UpdateClassificacao(notificacao, dispatch, codEmpresa, classificacao, classificacaoCopia) {
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(classificacao) === JSON.stringify(classificacaoCopia)) {
            alertar(notificacao, 'br', "Classificação não alterada", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;
        if (classificacao.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/classificacao/updateClassificacao?codEmpresa=${codEmpresa}&codClassificacao=${classificacao.codClassificacao}`,
            headers: { Authorization: basicAuth },
            data: {
                Descricao: classificacao.descricao,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'CLASSIFICACAO', campo: 'codClassificacao', value: response.data.records })
            dispatch({ idStore, type: 'CLASSIFICACAO_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export function Inativar(notificacao, dispatch, codEmpresa, classificacao, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/classificacao/inativarClassificacao?codEmpresa=${codEmpresa}&codClassificacao=${classificacao.codClassificacao}&status=${classificacao.status}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'CLASSIFICACAO_COPIA_STATUS', value: classificacao.status === 1 ? 0 : 1 })
            Pesquisar(notificacao, dispatch, codEmpresa, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function Excluir(notificacao, dispatch, codEmpresa, classificacao, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/classificacao/excluirClassificacao?codEmpresa=${codEmpresa}&codClassificacao=${classificacao.codClassificacao}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar(notificacao, dispatch, codEmpresa, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function GetTributacoes(notificacao, dispatch, codEmpresa, codClassificacao) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/tributacao/tributacoes?codEmpresa=${codEmpresa}&codClassificacao=${codClassificacao}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma tributação encontrada", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'TRIBUTACOES', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as tributações", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetTributacao(notificacao, dispatch, codEmpresa, codClassificacao, codTributacao, thenFunction) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/tributacao/tributacao?codEmpresa=${codEmpresa}&codClassificacao=${codClassificacao}&codTributacao=${codTributacao}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            let type = 'TRIBUTACAO';
            dispatch({ idStore, type, campo: 'codTributacao', value: response.data.records.codTributacao })
            dispatch({ idStore, type, campo: 'uf', value: response.data.records.uf.split(',') })
            dispatch({ idStore, type, campo: 'codCFOPNFE', value: response.data.records.codCFOPNFE })
            dispatch({ idStore, type, campo: 'codCFOPCF', value: response.data.records.codCFOPCF })
            dispatch({ idStore, type, campo: 'codPISCOFINS', value: response.data.records.codPISCOFINS })
            dispatch({ idStore, type, campo: 'codIPI', value: response.data.records.codIPI })
            dispatch({ idStore, type, campo: 'codICMS', value: response.data.records.codICMS })
            dispatch({ idStore, type, campo: 'codEnquadramentoIPI', value: response.data.records.codEnquadramentoIPI })
            dispatch({ idStore, type, campo: 'percICMS', value: response.data.records.percICMS.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'percICMSST', value: response.data.records.percICMSST.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'percPIS', value: response.data.records.percPIS.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'percPISST', value: response.data.records.percPISST.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'percCOFINS', value: response.data.records.percCOFINS.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'percCOFINSST', value: response.data.records.percCOFINSST.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'percIPI', value: response.data.records.percIPI.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'percICMSEfetivo', value: response.data.records.percICMSEfetivo.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'percICMSEfetivoST', value: response.data.records.percICMSEfetivoST.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'percICMSRetidoST', value: response.data.records.percICMSRetidoST.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'percICMSCredito', value: response.data.records.percICMSCredito.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'percICMSReducao', value: response.data.records.percICMSReducao.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'percICMSReducaoST', value: response.data.records.percICMSReducaoST.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'percICMSDiferimento', value: response.data.records.percICMSDiferimento.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'percFundPobreza', value: response.data.records.percFundPobreza.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'ICMSEfetivo', value: response.data.records.ICMSEfetivo.toFixed(2).replace('.', ',') })
            dispatch({ idStore, type, campo: 'status', value: response.data.records.status })
            dispatch({ idStore, type: 'TRIBUTACAO_COPIA' })
            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export async function CreateTributacao(notificacao, dispatch, codEmpresa, codClassificacao, tributacao, pesquisa) {
    try {
        //Retorna se houve erro ou nao
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;
        if (tributacao.uf.length === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'uf', value: true })
            alertar(notificacao, 'br', "Informe ao menos um estado", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codCFOPNFE === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codCFOPNFE', value: true })
            alertar(notificacao, 'br', "Informe o CFOP NF-e/NFC-e", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codCFOPCF === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codCFOPCF', value: true })
            alertar(notificacao, 'br', "Informe o CFOP Cupom Fiscal/S@t", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codIPI === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codIPI', value: true })
            alertar(notificacao, 'br', "Informe o IPI", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codPISCOFINS === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codPISCOFINS', value: true })
            alertar(notificacao, 'br', "Informe o PIS/COFINS", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codEnquadramentoIPI === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codEnquadramentoIPI', value: true })
            alertar(notificacao, 'br', "Informe o Esquadramento IPI", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }

        if (invalido) {
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/tributacao/createTributacao?codEmpresa=${codEmpresa}&codClassificacao=${codClassificacao}`,
            headers: { Authorization: basicAuth },
            data: {
                uf: tributacao.uf.length > 0 ? tributacao.uf.reduce((str, estado) => {
                    if (str)
                        return `${str},${estado}`
                    else
                        return estado
                }) : '',
                codCFOPNFE: parseInt(tributacao.codCFOPNFE),
                codCFOPCF: parseInt(tributacao.codCFOPCF),
                codICMS: parseInt(tributacao.codICMS),
                codPISCOFINS: parseInt(tributacao.codPISCOFINS),
                codIPI: parseInt(tributacao.codIPI),
                codEnquadramentoIPI: parseInt(tributacao.codEnquadramentoIPI),
                percICMS: parseFloat(String(tributacao.percICMS).replace(',', '.')),
                percICMSST: parseFloat(String(tributacao.percICMSST).replace(',', '.')),
                percIPI: parseFloat(String(tributacao.percIPI).replace(',', '.')),
                percPIS: parseFloat(String(tributacao.percPIS).replace(',', '.')),
                percPISST: parseFloat(String(tributacao.percPISST).replace(',', '.')),
                percCOFINS: parseFloat(String(tributacao.percCOFINS).replace(',', '.')),
                percCOFINSST: parseFloat(String(tributacao.percCOFINSST).replace(',', '.')),
                percICMSEfetivo: parseFloat(String(tributacao.percICMSEfetivo).replace(',', '.')),
                percICMSEfetivoST: parseFloat(String(tributacao.percICMSEfetivoST).replace(',', '.')),
                percICMSRetidoST: parseFloat(String(tributacao.percICMSRetidoST).replace(',', '.')),
                percICMSCredito: parseFloat(String(tributacao.percICMSCredito).replace(',', '.')),
                percICMSReducao: parseFloat(String(tributacao.percICMSReducao).replace(',', '.')),
                percICMSReducaoST: parseFloat(String(tributacao.percICMSReducaoST).replace(',', '.')),
                percICMSDiferimento: parseFloat(String(tributacao.percICMSDiferimento).replace(',', '.')),
                percFundPobreza: parseFloat(String(tributacao.percFundPobreza).replace(',', '.')),
                ICMSEfetivo: parseFloat(String(tributacao.ICMSEfetivo).replace(',', '.'))
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'TRIBUTACAO', campo: 'codTributacao', value: response.data.records })
            dispatch({ idStore, type: 'TRIBUTACAO_COPIA' })
            Pesquisar(notificacao, dispatch, codEmpresa, pesquisa)
            GetTributacoes(notificacao, dispatch, codEmpresa, codClassificacao)
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function UpdateTributacao(notificacao, dispatch, codEmpresa, codClassificacao, tributacao, tributacaoCopia, pesquisa) {
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(tributacao) === JSON.stringify(tributacaoCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;
        if (tributacao.uf.length === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'uf', value: true })
            alertar(notificacao, 'br', "Informe ao menos um estado", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codCFOPNFE === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codCFOPNFE', value: true })
            alertar(notificacao, 'br', "Informe o CFOP NF-e/NFC-e", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codCFOPCF === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codCFOPCF', value: true })
            alertar(notificacao, 'br', "Informe o CFOP Cupom Fiscal/S@t", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codICMS === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codICMS', value: true })
            alertar(notificacao, 'br', "Informe o IMCS", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codIPI === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codIPI', value: true })
            alertar(notificacao, 'br', "Informe o IPI", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codPISCOFINS === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codPISCOFINS', value: true })
            alertar(notificacao, 'br', "Informe o PIS/COFINS", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codEnquadramentoIPI === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codEnquadramentoIPI', value: true })
            alertar(notificacao, 'br', "Informe o Esquadramento IPI", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }

        if (invalido) {
            return true
        }

        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/tributacao/updateTributacao?codEmpresa=${codEmpresa}&codClassificacao=${codClassificacao}&codTributacao=${tributacao.codTributacao}`,
            headers: { Authorization: basicAuth },
            data: {
                uf: tributacao.uf.length > 0 ? tributacao.uf.reduce((str, estado) => {
                    if (str)
                        return `${str},${estado}`
                    else
                        return estado
                }) : '',
                codCFOPNFE: parseInt(tributacao.codCFOPNFE),
                codCFOPCF: parseInt(tributacao.codCFOPCF),
                codICMS: parseInt(tributacao.codICMS),
                codPISCOFINS: parseInt(tributacao.codPISCOFINS),
                codIPI: parseInt(tributacao.codIPI),
                codEnquadramentoIPI: parseInt(tributacao.codEnquadramentoIPI),
                percICMS: parseFloat(String(tributacao.percICMS).replace(',', '.')),
                percICMSST: parseFloat(String(tributacao.percICMSST).replace(',', '.')),
                percIPI: parseFloat(String(tributacao.percIPI).replace(',', '.')),
                percPIS: parseFloat(String(tributacao.percPIS).replace(',', '.')),
                percPISST: parseFloat(String(tributacao.percPISST).replace(',', '.')),
                percCOFINS: parseFloat(String(tributacao.percCOFINS).replace(',', '.')),
                percCOFINSST: parseFloat(String(tributacao.percCOFINSST).replace(',', '.')),
                percICMSEfetivo:  parseFloat(String(tributacao.percICMSEfetivo).replace(',', '.')),
                percICMSEfetivoST: parseFloat(String(tributacao.percICMSEfetivoST).replace(',', '.')),
                percICMSRetidoST: parseFloat(String(tributacao.percICMSRetidoST).replace(',', '.')),
                percICMSCredito: parseFloat(String(tributacao.percICMSCredito).replace(',', '.')),
                percICMSReducao: parseFloat(String(tributacao.percICMSReducao).replace(',', '.')),
                percICMSReducaoST: parseFloat(String(tributacao.percICMSReducaoST).replace(',', '.')),
                percICMSDiferimento: parseFloat(String(tributacao.percICMSDiferimento).replace(',', '.')),
                percFundPobreza: parseFloat(String(tributacao.percFundPobreza).replace(',', '.')),
                ICMSEfetivo: parseFloat(String(tributacao.ICMSEfetivo).replace(',', '.'))
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'TRIBUTACAO', campo: 'codTributacao', value: response.data.records })
            dispatch({ idStore, type: 'TRIBUTACAO_COPIA' })
            Pesquisar(notificacao, dispatch, codEmpresa, pesquisa)
            GetTributacoes(notificacao, dispatch, codEmpresa, codClassificacao)
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function FinishTributacao(notificacao, dispatch, codEmpresa, codClassificacao, tributacao, tributacaoCopia) {
    try {
        var rota, metodo;
        if (tributacao.codTributacao === 0) {
            rota = "createTributacao"
            metodo = "post"
        } else {
            rota = "updateTributacao"
            metodo = "put"
        }

        if (tributacao.codTributacao !== 0) {
            //Retorna se houve erro ou nao
            if (JSON.stringify(tributacao) === JSON.stringify(tributacaoCopia)) {
                alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
                return false
            }
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;
        if (tributacao.uf.length === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'uf', value: true })
            alertar(notificacao, 'br', "Informe ao menos um estado", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codCFOPNFE === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codCFOPNFE', value: true })
            alertar(notificacao, 'br', "Informe o CFOP NF-e/NFC-e", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codCFOPCF === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codCFOPCF', value: true })
            alertar(notificacao, 'br', "Informe o CFOP Cupom Fiscal/S@t", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codICMS === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codICMS', value: true })
            alertar(notificacao, 'br', "Informe o IMCS", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codIPI === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codIPI', value: true })
            alertar(notificacao, 'br', "Informe o IPI", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codPISCOFINS === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codPISCOFINS', value: true })
            alertar(notificacao, 'br', "Informe o PIS/COFINS", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }
        if (tributacao.codEnquadramentoIPI === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codEnquadramentoIPI', value: true })
            alertar(notificacao, 'br', "Informe o Esquadramento IPI", 'warning', 'icon-alert-circle-exc', 5);
            invalido = true
        }

        if (invalido) {
            return true
        }

        return await axios({
            method: metodo,
            url: api.urlCadastro + `api/v1/tributacao/${rota}?codEmpresa=${codEmpresa}&codClassificacao=${codClassificacao}&codTributacao=${tributacao.codTributacao}`,
            headers: { Authorization: basicAuth },
            data: {
                uf: tributacao.uf.length > 0 ? tributacao.uf.reduce((str, estado) => {
                    if (str)
                        return `${str},${estado}`
                    else
                        return estado
                }) : '',
                codCFOPNFE: parseInt(tributacao.codCFOPNFE),
                codCFOPCF: parseInt(tributacao.codCFOPCF),
                codICMS: parseInt(tributacao.codICMS),
                codPISCOFINS: parseInt(tributacao.codPISCOFINS),
                codIPI: parseInt(tributacao.codIPI),
                codEnquadramentoIPI: parseInt(tributacao.codEnquadramentoIPI),
                percICMS: parseFloat(String(tributacao.percICMS).replace(',', '.')),
                percICMSST: parseFloat(String(tributacao.percICMSST).replace(',', '.')),
                percIPI: parseFloat(String(tributacao.percIPI).replace(',', '.')),
                percPIS: parseFloat(String(tributacao.percPIS).replace(',', '.')),
                percPISST: parseFloat(String(tributacao.percPISST).replace(',', '.')),
                percCOFINS: parseFloat(String(tributacao.percCOFINS).replace(',', '.')),
                percCOFINSST: parseFloat(String(tributacao.percCOFINSST).replace(',', '.')),
                percICMSEfetivo:  parseFloat(String(tributacao.percICMSEfetivo).replace(',', '.')),
                percICMSEfetivoST: parseFloat(String(tributacao.percICMSEfetivoST).replace(',', '.')),
                percICMSRetidoST: parseFloat(String(tributacao.percICMSRetidoST).replace(',', '.')),
                percICMSCredito: parseFloat(String(tributacao.percICMSCredito).replace(',', '.')),
                percICMSReducao: parseFloat(String(tributacao.percICMSReducao).replace(',', '.')),
                percICMSReducaoST: parseFloat(String(tributacao.percICMSReducaoST).replace(',', '.')),
                percICMSDiferimento: parseFloat(String(tributacao.percICMSDiferimento).replace(',', '.')),
                percFundPobreza: parseFloat(String(tributacao.percFundPobreza).replace(',', '.')),
                ICMSEfetivo: parseFloat(String(tributacao.ICMSEfetivo).replace(',', '.'))
            }
        }).then(response => {
            dispatch({ idStore, type: 'TRIBUTACAO', campo: 'codTributacao', value: response.data.records })
            dispatch({ idStore, type: 'TRIBUTACAO_COPIA' })
            GetTributacoes(notificacao, dispatch, codEmpresa, codClassificacao)
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export function ExcluirTributacao(notificacao, dispatch, codEmpresa, codClassificacao, codTributacao) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/tributacao/excluirTributacao?codEmpresa=${codEmpresa}&codClassificacao=${codClassificacao}&codTributacao=${codTributacao}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            GetTributacoes(notificacao, dispatch, codEmpresa, codClassificacao)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function GetICMSs(notificacao, dispatch, codEmpresa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/icms/ICMSs?codEmpresa=${codEmpresa}&status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            var records = []
            records = response.data.records.map(record => {
                record.descricaoFormatada = `${record.icms} - ${record.descricao}`
                return record
            });
            dispatch({ idStore, type: 'ICMS', value: records })
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os ICMSs", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function GetIPIs(notificacao, dispatch, codEmpresa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/IPI/IPIs?codEmpresa=${codEmpresa}&status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            var records = []
            records = response.data.records.map(record => {
                record.descricaoFormatada = `${record.ipi} - ${record.descricao}`
                if (record.ipi === "99") {
                    dispatch({ idStore, type: 'TRIBUTACAO', campo: 'codIPI', value: record.codIpi })
                }
                return record
            });
            dispatch({ idStore, type: 'IPI', value: records })
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os IPIs", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function GetPISCOFINSs(notificacao, dispatch, codEmpresa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/pisCofins/PISCOFINSs?codEmpresa=${codEmpresa}&status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            var records = []
            records = response.data.records.map(record => {
                record.descricaoFormatada = `${record.pisCofins} - ${record.descricao}`
                return record
            });
            dispatch({ idStore, type: 'PISCOFINS', value: records })
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os PISCOFINSs", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function GetCFOPs(notificacao, dispatch, codEmpresa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/cfop/cfops?codEmpresa=${codEmpresa}&status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            var records = []
            records = response.data.records.map(record => {
                record.descricaoFormatada = `${record.cfop} - ${record.descricao}`
                return record
            });
            dispatch({ idStore, type: 'CFOP', value: records })
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os CFOPs", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function EnquadramentosIPI(notificacao, dispatch, codEmpresa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/enquadramentoIPI/enquadramentos?codEmpresa=${codEmpresa}&status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            var records = []
            records = response.data.records.map(record => {
                record.descricaoFormatada = `${record.enquadramento} - ${record.descricao}`
                if (record.enquadramento === "999") {
                    dispatch({ idStore, type: 'TRIBUTACAO', campo: 'codEnquadramentoIPI', value: record.codEnquadramento })
                }
                return record
            });
            dispatch({ idStore, type: 'ENQUADRAMENTOS', value: records })
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os Enquadramentos de IPI", 'warning', 'icon-alert-circle-exc', 2)
    }
}