import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import { InputCpfCnpj as InputCpfCnpjERN } from '../../../components/ErnComponets/ErnComponets';

export function Info(props) {
  return (
    <div
      {...props}
      style={{ fontWeight: 600, marginBottom: 5, color: '#8F8F8F', fontSize: 15, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', ...props.style }}
    >
      {props.children}
    </div>
  )
}

export function InputCpfCnpj(props) {
  return (
    <InputCpfCnpjERN
      {...props}
      style={{ ...props.style }}
      onChange={(event) => { props.onChange && props.onChange(event) }}
      value={props.value || ""}
    />
  )
}

export const Select = React.forwardRef((props = { options: [] }, ref) => {
  const [selected, setselected] = useState({ value: "0", label: props.first });
  const [options, setoptions] = useState([]);

  useEffect(() => {
    let obj = [];
    if (props.first) {
      obj.push({ value: "0", label: props.first })
    }
    props.options.forEach(option => {
      if (props.select) {
        obj.push({ value: option[props.select.value], label: option[props.select.label] })
      } else {
        obj.push({ value: option.value, label: option.label })
      }
    });
    
    setoptions(obj)
    setselected(props.options.find(option => option.value === "0"))
  }, [props.options])

  useEffect(() => {
    options.length > 0 &&
      setselected(options.find(option => option.value === props.value.value || option.label === props.value.label))
  }, [props.value])

  return (
    <ReactSelect
      {...props}
      className="react-select info"
      classNamePrefix="react-select"
      value={selected}
      options={options}
      onChange={value => props.onChange(value)}
      noOptionsMessage={() => "Não encontrado"}
      ref={ref}
    />
  )
})