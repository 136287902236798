import React, { useEffect, useState } from 'react'

import {
    Button,
    CardBody,
    Col,
    FormGroup,
    Row
} from 'reactstrap'

import { Select } from '../../../../../components/ErnComponets/ErnComponets';

import { IServicoState } from '../../../../../store/reducers/Cadastro/Servico/types';
import AppState from '../../../../../store/storeType';
import useShallowEqualSelector from '../../../../../Util/Hooks/useShallowEqualSelector';
import * as actions from "../../../../../store/reducers/Cadastro/Servico/actions";
import { maskNumbers } from '../../../../../Util/mascaras';
import * as controllerServicos from "../../../../../controller/controllerServicos";
import history from "../../../../../Util/history";

import {
    ButtonPesquisa,
    InputPesquisa,
    RadioPesquisa
} from "./components/Index";

import ArrowTooltip from '../../../../../components/Tooltip/ArrowTooltip';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import ReactTable from 'react-table';

import {
    PopUpInativar,
    PopUpExcluir,
    PopUpNaoExisteGrupo
} from "../../../Modals/PopUp";

import useModal from '../../../../../Util/Hooks/useModal';
import { KeywordTypeNode } from 'typescript';
import { useHotkeys } from 'react-hotkeys-hook';

const Pesquisa = () => {

    const pesquisa = useShallowEqualSelector<AppState, IServicoState['pesquisa']>(state => state.servico.pesquisa)
    const grupos = useShallowEqualSelector<AppState, IServicoState['grupos']>(state => state.servico.grupos)
    const pesqsubgrupos = useShallowEqualSelector<AppState, IServicoState['grupos']>(state => state.servico.pesqsubgrupos)
    const servicos = useShallowEqualSelector<AppState, IServicoState['servicos']>(state => state.servico.servicos)

    const [inativaServico, toggleInativaServico] = useModal(false)
    const [deleteServico, toogleDeleteServico] = useModal(false)

    const [width, setWidht] = useState(window.innerWidth);
    const [tableVisible, setTableVisible] = useState<boolean>(false);
    const [servico, setServicoSelected] = useState<any>([])
    const [popUpNaoExisteGrupo, togglePopUpNaoExisteGrupo] = useModal(false)

    useEffect(() => {

        window.addEventListener('resize', updateWidth);
        controllerServicos.GetGrupos(togglePopUpNaoExisteGrupo)

    }, [])

    useEffect(() => {
        if (servicos.length > 0) {
            setTableVisible(true);
        } else {
            setTableVisible(false);
        }
    }, [servicos])

    function updateWidth() {
        setWidht(window.innerWidth);
    }

    const handleChangeStatus = (status: number) => {
        actions.setPesquisa({ ...pesquisa, ativos: status })
        controllerServicos.Pesquisar()


    }
    const handleChangeGrupo = (value: any) => {
        actions.setPesquisa({ ...pesquisa, codGrupo: value })
        controllerServicos.GetSubgrupos(value, true)
    }

    const handleInativar = async function () {
        controllerServicos.Inativar(servico)
    }

    const handleDelete = async function () {

        controllerServicos.Excluir(servico)

    }

    const handleEditar = (codServico: any) => {

        controllerServicos.GetServico(codServico, false)


    }
    const handleVisualizar = (codServico: any) => {

        controllerServicos.GetServico(codServico, true)

    }
    const cleanAll = () => {
        actions.cleanSearch()
        actions.cleanServicos()
    }

    const onKeyPressOnClick = (key: any) => {
        if (key === 'Enter') {
            controllerServicos.Pesquisar()
        }
    }
    useHotkeys('Shift+P', () => {

        controllerServicos.Pesquisar()

    })

    useHotkeys('Shift+L', () => {

        cleanAll()

    })

    return (
        <>

            <Row className="padding-padrao-row">
                <Col className="padding-padrao-col" lg="2" md="3">
                    <label>Número do Serviço</label>
                    <InputPesquisa
                        type="text"
                        Completed="off"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => actions.setPesquisa({ ...pesquisa, numeroServico: maskNumbers(event.target.value) })}
                        value={pesquisa.numeroServico}
                        onKeyPress={(event: React.KeyboardEvent<KeywordTypeNode>) => { onKeyPressOnClick(event.key) }}
                        Focused={true}
                        length="7"
                    />

                </Col>
                <Col className="padding-padrao-col" lg="2">
                    <label>Grupo</label>
                    <Select
                        id="pesqGruposSelect"
                        first="Todos"
                        value={{ value: pesquisa.codGrupo }}
                        select={{ value: "codGrupo", label: "nome" }}
                        onChange={(event: any) => {
                            handleChangeGrupo(event.value)
                        }}
                        //@ts-ignore
                        options={grupos}
                        isSearchable={true}
                        autoComplete="off"
                    />
                </Col>
                <Col className="padding-padrao-col" lg="2">
                    <label>Subgrupo</label>
                    <Select
                        id="pesqSubgruposSelect"
                        first="Todos"
                        value={{ value: pesquisa.codSubgrupo }}
                        select={{ value: "codSubgrupo", label: "nome" }}
                        onChange={(event: any) => {
                            actions.setPesquisa({ ...pesquisa, codSubgrupo: event.value })
                        }}
                        //@ts-ignore
                        options={pesqsubgrupos}
                        isSearchable={true}
                        autoComplete="off"
                    />
                </Col>
                <Col className="padding-padrao-col" lg="4" md="3">
                    <label>Nome</label>
                    <InputPesquisa
                        type="text"
                        Completed="off"
                        value={pesquisa.nome}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => actions.setPesquisa({ ...pesquisa, nome: event.target.value })}
                        onKeyPress={(event: React.KeyboardEvent<KeywordTypeNode>) => { onKeyPressOnClick(event.key) }}
                        Focused={true}
                        length="50"
                    />
                </Col>
                <Col md="1" className="text-center padding-padrao-col" style={{ paddingTop: '13px' }}>
                    <Col className={width > 768 ? "text-left" : ''}>
                        <FormGroup check className="form-check-radio" Style={"margin-top:-12px;"}>
                            <RadioPesquisa
                                onChange={() => handleChangeStatus(2)}
                                checked={pesquisa.ativos === 2}
                                style={{ paddingRight: '5px' }}
                                label='Todos'
                                className='form-check-sign'
                            />
                            <RadioPesquisa
                                onChange={() => handleChangeStatus(0)}
                                checked={pesquisa.ativos === 0}
                                style={{ paddingRight: '5px' }}
                                label='Ativos'
                                className='form-check-sign'
                            />
                            <RadioPesquisa
                                onChange={() => handleChangeStatus(1)}
                                checked={pesquisa.ativos === 1}
                                style={{ paddingRight: '5px' }}
                                label='Inativos'
                                className='form-check-sign'
                            />
                        </FormGroup>
                    </Col>

                </Col>
            </Row>
            <Row>
                <CardBody>

                    <ButtonPesquisa
                        color='info'
                        style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                        label={'Limpar'}
                        onClick={() => cleanAll()}
                        arrowToolTip={true}
                        arrowToolTipTitle='Shift+L'
                    />
                    <ButtonPesquisa
                        color='info'
                        style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                        label={'Pesquisa'}
                        onClick={() => controllerServicos.Pesquisar()}
                        arrowToolTip={true}
                        arrowToolTipTitle='Shift+P'
                    />

                </CardBody>
            </Row>
            <div style={tableVisible ? { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>

                <ReactTable
                    data={servicos}
                    filterable={false}
                    columns={
                        [
                            {
                                Header: "Número Serviço",
                                accessor: "numProduto",
                            },
                            {
                                Header: "Grupo",
                                accessor: "grupo"
                            },
                            {
                                Header: "Subgrupo",
                                accessor: "subgrupo"
                            },
                            {
                                Header: "Nome",
                                accessor: "nome"
                            },
                            {
                                Header: "Data de cadastro",
                                accessor: "dtCadastro"
                            },
                            {
                                Header: "Status",
                                accessor: "status",
                                Cell: (props: any) => (props.original.status === 0 ? "Ativo" : "Inativo")
                            },
                            {
                                Header: "",
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                                Cell: (props) => (
                                    <>
                                        <ArrowTooltip title="Visualizar">
                                            <Button
                                                onClick={() => handleVisualizar(props.original.codProduto)}
                                                size="sm"
                                                className={"btn-icon btn-link"}
                                                style={{ color: "#483D8B" }}

                                            >
                                                <Icon icon="eye" className="fa-lg" />
                                            </Button>
                                        </ArrowTooltip>
                                        <ArrowTooltip title="Editar">
                                            <Button
                                                onClick={() => handleEditar(props.original.codProduto)}
                                                size="sm"
                                                className={"btn-icon btn-link"}
                                                color="success"
                                            >
                                                <Icon icon="edit" className="fa-lg" />
                                            </Button>
                                        </ArrowTooltip>

                                        <ArrowTooltip title="Inativar">
                                            <Button
                                                onClick={() => {
                                                    setServicoSelected(props.original),
                                                        toggleInativaServico()
                                                }}
                                                className="btn-icon btn-link"
                                                color="info"
                                            >
                                                {props ? props.original.status === 0 || props.original.status === 7 ?
                                                    <Icon icon="ban" className="fa-lg" />
                                                    :
                                                    <Icon icon="redo" className="fa-lg" />
                                                    :
                                                    null
                                                }
                                            </Button>
                                        </ArrowTooltip>
                                        <ArrowTooltip title="Excluir">

                                            <Button
                                                onClick={() => {
                                                    setServicoSelected(props.original),
                                                        toogleDeleteServico()
                                                }}
                                                className="btn-icon btn-link"
                                                color="danger"
                                            >
                                                <Icon icon="trash-alt" className="fa-lg" />
                                            </Button>
                                        </ArrowTooltip>

                                    </>
                                )
                            }
                        ]
                    }
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    noDataText=""
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando"
                    pageText="Página"
                    ofText="de"
                    rowsText='linhas'
                />

            </div>
            {inativaServico ?
                <PopUpInativar
                    isOpen={inativaServico}
                    callback={() => { handleInativar(), toggleInativaServico() }}
                    toggle={toggleInativaServico}
                    information={{ status: servico.status }}
                />
                :
                null}
            {deleteServico ?
                <PopUpExcluir
                    isOpen={deleteServico}
                    callback={() => { handleDelete(), toogleDeleteServico() }}
                    toggle={toogleDeleteServico}
                    information={{ status: servico.status }}
                />
                :
                null}
            {popUpNaoExisteGrupo ?

                < PopUpNaoExisteGrupo isOpen={popUpNaoExisteGrupo}
                    callback={() => { history.push(`/dashboard`) }}
                    functionSecond={() => { history.push(`/gruposProduto`) }}
                    toggle={togglePopUpNaoExisteGrupo} />

                :
                null
            }
        </>
    )

}

export default Pesquisa