import React from 'react';
import { Input as InputStrap } from "reactstrap";
import { InputNumber as InputNumberERN } from '~/components/ErnComponets/ErnComponets';
import { InputCpfCnpj as InputCpfCnpjERN } from '~/components/ErnComponets/ErnComponets';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { Col } from "reactstrap";
import ArrowTooltip from '../../../../components/Tooltip/ArrowTooltip';
import useShallowEqualSelector from "../../../../Util/Hooks/useShallowEqualSelector";

export function Info(props) {
    return (
        <div
            {...props}
            style={{ fontWeight: 600, marginBottom: 5, color: '#8F8F8F', fontSize: 15, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', ...props.style }}
        >
            {props.children}
        </div>
    )
}

export function Input(props) {
    return (
        <InputStrap
            {...props}
            style={{ ...props.style }}
        />
    )
}

export function InputNumber(props) {
    return (
        <InputNumberERN
            {...props}
            style={{ ...props.style }}
            onChange={(e) => { props.onChange && props.onChange(e) }}
        />
    )
}

export function InputCpfCnpj(props) {
    return (
        <InputCpfCnpjERN
            {...props}
            style={{ ...props.style }}
            onChange={(e) => { props.onChange && props.onChange(e) }}
            value={props.value || ""}
        />
    )
}

export function OperacaoBox({ onIconPress }) {
    const { numeroOperacao, descricaoOperacao } = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.dadosIniciais.operacao)
    const verCadastro = useShallowEqualSelector(state => state.entradaMercadoria.visualizarEntrada);

    return (
        <Col style={{ border: '1px solid #707070', borderRadius: 5, paddingTop: 6, paddingBottom: 6 }} className="operacao-box" >
            <div>
                <strong>Operação:</strong><span className="error"> *</span>
                <div style={{ overflow: 'hidden', height: '60px', paddingRight: '30px' }}>
                    <br/>
                    {numeroOperacao} - {descricaoOperacao}
                </div>
            </div>
            <ArrowTooltip title="Alterar Operação">
                <div style={{ position: 'absolute', bottom: 20, right: 10 }}>
                    <Icon icon="edit" className="fa-lg" onClick={() => onIconPress()} style= {verCadastro ? {pointerEvents: "none"} : null} />
                </div>
            </ArrowTooltip>
        </Col>
    )
}