import BigNumber from 'bignumber.js'

export function ToNumber(value: string | number): number {
    value = value.toString()

    const num = new BigNumber(value.replace(',', '.')).toNumber()

    return num ? num : 0
}

export function ToString(value: number | string): string {
    const str = value.toString()
    return str.replace('.', ',')
}

export function Multiply(numbers: string[] | number[]): number {
    let result = 1

    numbers.forEach((num: string | number) => {
        let value: number
        if (typeof num === "string") {
            value = ToNumber(num)
        } else {
            value = num
        }
        result = new BigNumber(result).times(value).toNumber()
    })

    return result
}

export function Divide(numbers: string[] | number[]): number {
    let result = 0

    numbers.forEach((num: string | number, index: number) => {
        let value: number

        if (typeof num === "string") {
            value = ToNumber(num)
        } else {
            value = num
        }

        if (index === 0) {
            result = new BigNumber(value).toNumber()
            return
        }

        result = new BigNumber(result).dividedBy(value).toNumber()
    })
    return result
}

export function Subtract(numbers: string[] | number[]): number {
    let result = 0

    numbers.forEach((num: string | number, index: number) => {
        let value: number

        if (typeof num === "string") {
            value = ToNumber(num)
        } else {
            value = num
        }

        if (index === 0) {
            result = new BigNumber(value).toNumber()
            return
        }

        result = new BigNumber(result).minus(value).toNumber()
    })
    return result
}

export function Sum(numbers: string[] | number[]): number {
    let result = 0

    numbers.forEach((num: string | number, index: number) => {
        let value: number

        if (typeof num === "string") {
            value = ToNumber(num)
        } else {
            value = num
        }

        result = new BigNumber(result).plus(value).toNumber()
    })
    return result
}

export function Round(value: string | number, precision: number): string {

    let num: number

    if (typeof value === "string") {
        num = ToNumber(value)
    } else {
        num = value
    }

    let rounded = new BigNumber(num).toFixed(precision)

    return rounded.replace('.', ',')
}