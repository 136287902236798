import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import ReactTable from "react-table";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from '../../../components/ModalBasic/ModalBasic.jsx';
import useScrollBar from '../../../Util/Hooks/useScrollBar';
import { HelpButton } from '../../../components/HelpPlugin/HelpPlugin'
import { Switch } from "../../../components/ErnComponets/ErnComponets"
import useShallowEqualSelector from '../../../Util/Hooks/useShallowEqualSelector';
import AppState from '../../../store/storeType.js';
import { IInventarioState } from '../../../store/reducers/Movimentacao/Inventario/types';
import * as action from '../../../store/reducers/Movimentacao/Inventario/actions'
import { ToNumber } from '../../../Util/numbers';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { maskNumbers } from '../../../Util/mascaras.js';

type Toggle = (newState?: boolean, callback?: (state?: boolean) => void) => void
interface Props {
    isOpen: boolean
    toggle: Toggle
}

export default function ModalSaidas({ isOpen, toggle }: Props) {
    const listGrade = useShallowEqualSelector<AppState, IInventarioState['listGrade']>(state => state.inventario.listGrade)
    const gradeSelecionada = useShallowEqualSelector<AppState, IInventarioState['gradeSelecionada']>(state => state.inventario.gradeSelecionada)
    const listAtualizacao = useShallowEqualSelector<AppState, IInventarioState["listAtualizacao"]>(state => state.inventario.listAtualizacao)
    const [invalido, setInvalido] = useState(false);
    const [msgInvalido, setMessage] = useState("")
    const [restante, setRestante] = useState(gradeSelecionada.quantidadeAtualizar)
    const tableRef = useScrollBar()

    useEffect(() => {
        calcularRestante()
    }, [])

    function closeModal() {
        action.setGrade([])
        toggle()
    }

    async function onChangeSwitch(index: number) {
        let gradesStr = JSON.stringify(listGrade);
        let gradesArr = JSON.parse(gradesStr);
        let atualizar = gradesArr[index].atualizar

        atualizar ? gradesArr[index].atualizar = !gradesArr[index].atualizar : gradesArr[index].atualizar = !gradesArr[index].atualizar

        action.setGrade(gradesArr);
        calcularRestante(gradesArr)
    }

    function onChangeEstoque(index: number, quantidade: string) {
        let gradesStr = JSON.stringify(listGrade);
        let gradesArr: any[] = JSON.parse(gradesStr);


        if (quantidade === "")
            quantidade = ""

        if (ToNumber(quantidade) < 0)
            quantidade = "0"

        gradesArr[index].estoqueAtualizar = maskNumbers(quantidade)

        action.setGrade(gradesArr);
        calcularRestante(gradesArr)
        setInvalido(false)
    }

    function calcularRestante(listPar?: any[]) {
        var total = 0
        let restante = 0
        let list = listPar ? listPar : listGrade

        list.forEach((grade: any) => {
            if (grade.atualizar) {
                total += ToNumber(grade.estoqueAtualizar)
            }
        })

        restante = gradeSelecionada.quantidadeAtualizar - total

        if (restante >= 0) {
            setRestante(restante)
        }

    }

    function updateGrade() {
        let listStr = JSON.stringify(listGrade);
        let listArr = JSON.parse(listStr);
        let totalAtualizar = 0

        listArr.forEach((element: any) => {
            if (element.atualizar === true) {
                totalAtualizar += ToNumber(element.estoqueAtualizar)
            }
        })

        if (totalAtualizar !== ToNumber(gradeSelecionada.quantidadeAtualizar)) {
            setMessage("Verifique a quantidade à atualizar")
            setInvalido(true)
            return
        }

        let index = listAtualizacao.findIndex(p => p.codigoProduto === gradeSelecionada.codigoProduto)

        if (index >= 0) {
            listAtualizacao[index].grades = listArr
            listAtualizacao[index].gradeConfirmada = true
        }

        action.setListAtualizar([...listAtualizacao])
        closeModal()
    }

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => closeModal()}
            title={'GRADE DE PRODUTOS'}
            size="lg"
        >
            <ModalBasicBody>
                <h4 style={{ textAlign: 'center' }}>Informe a quantidade para cada item da grade</h4>

                <Row style={{ justifyContent: 'flex-end', paddingRight: 20, paddingBottom: 10 }}>
                    <HelpButton id={19} />
                </Row>
                <Row>
                    <Col>
                        <fieldset className="fieldset-operacao">
                            <div
                                ref={tableRef}
                            >
                                <ReactTable
                                    data={listGrade}
                                    columns={[
                                        {
                                            Header: 'Descrição',
                                            accessor: 'descricao',
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Sigla',
                                            accessor: 'sigla',
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Atualizar',
                                            accessor: 'atualizar',
                                            resizable: false,
                                            sortable: false,
                                            Cell: (prop) =>
                                                <Switch
                                                    checked={prop.original.atualizar}
                                                    onChange={() => onChangeSwitch(prop.index)}
                                                />
                                        },
                                        {
                                            Header: 'Quantidade em estoque',
                                            resizable: false,
                                            sortable: false,
                                            Cell: (prop) =>
                                                <>
                                                    <input
                                                        type="text"
                                                        className={'form-control'}
                                                        style={{ textAlign: 'center' }}
                                                        value={prop.original.estoqueAtualizar}
                                                        maxLength={10}
                                                        onChange={(element) => { onChangeEstoque(prop.index, element.currentTarget.value) }}
                                                        onFocus={(element) => { element.currentTarget.select() }}
                                                        disabled={!prop.original.atualizar}
                                                    />
                                                </>
                                        },
                                    ]}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                    noDataText=""
                                    minRows={listGrade.length}
                                />
                            </div>
                        </fieldset>

                        <h4>Restante: {restante}</h4>
                        <br />
                        <div style={invalido ? { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
                            <Row style={{ justifyContent: 'flex-end', paddingRight: 15 }}>
                                <span style={{ fontSize: 14, color: '#FFF', backgroundColor: '#FFB600', borderRadius: 5, paddingTop: 2.5, paddingBottom: 2.5, paddingLeft: 10, paddingRight: 10 }}>
                                    <Icon icon="exclamation-triangle" className="fa-lg" />
                                    {"  "}
                                    {msgInvalido}
                                </span>
                            </Row>
                        </div>
                    </Col>

                </Row>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        onClick={() => updateGrade()}
                        color={"info"}
                    >SALVAR
                    </Button>
                </Col>
            </ModalBasicFooter>

        </ModalBasic>
    )
}