import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import { ValidaCNPJ, ValidaCPF, ValidaCep, ValidaEmail } from "~/Util/validacao";
import { maskCNPJ, maskCPF } from "~/Util/mascaras";

const idStore = 'TRANSPORTADORAS';

export function Pesquisar(notificacao, dispatch, pesquisa) {
    try {
        dispatch({ idStore, type: 'TRANSPORTADORAS', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/transportadora/transportadoras?status=${pesquisa.ativos}&tipo=${pesquisa.tipo}&nomeRazao=${pesquisa.nomeRazao}&apelidoFantasia=${pesquisa.apelidoFantasia}&cpfCnpj=${pesquisa.cpfCnpj}&email=${pesquisa.email}`, {
            headers: { Authorization: basicAuth }

        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma transportadora encontrada", 'info', 'icon-bulb-63', 2)
            }

            response.data.records.forEach((transportadora, index) => {
                response.data.records[index].dataCadastro = new Date(transportadora.dataCadastro).toLocaleString();
            });

            dispatch({ idStore, type: 'TRANSPORTADORAS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as transportadoras", 'warning', 'icon-alert-circle-exc', 2)
    }
}
export async function GetTransportadora(notificacao, dispatch, codTrans, thenFunction) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/transportadora/transportadora?codTransportadora=${codTrans}`,
            {
                headers: { Authorization: basicAuth }

            }).then(function (response) {
                let type = 'TRANSPORTADORA';
                dispatch({ idStore, type, campo: 'codTrans', value: response.data.records.codTransportadora })
                dispatch({ idStore, type, campo: 'tipoTrans', value: response.data.records.tipo })
                dispatch({ idStore, type, campo: 'nomeRazao', value: response.data.records.nomeRazao })
                dispatch({ idStore, type, campo: 'apelidoFantasia', value: response.data.records.apelidoFantasia })
                dispatch({ idStore, type, campo: 'cpfCnpj', value: response.data.records.cpfCnpj.length === 11 ? maskCPF(response.data.records.cpfCnpj) : maskCNPJ(response.data.records.cpfCnpj) })
                dispatch({ idStore, type, campo: 'email', value: response.data.records.email })
                dispatch({ idStore, type, campo: 'email2', value: response.data.records.email2 })
                dispatch({ idStore, type, campo: 'rgIncest', value: response.data.records.rgInscEst })
                dispatch({ idStore, type, campo: 'telefone1', value: response.data.records.telefone })
                dispatch({ idStore, type, campo: 'telefone2', value: response.data.records.telefone2 })
                dispatch({ idStore, type, campo: 'celular', value: response.data.records.celular })
                dispatch({ idStore, type, campo: 'nomeContato', value: response.data.records.contatoNome })
                dispatch({ idStore, type, campo: 'telefoneContato', value: response.data.records.contatoTelefone })
                dispatch({ idStore, type, campo: 'emailContato', value: response.data.records.contatoEmail })
                dispatch({ idStore, type, campo: 'cep', value: response.data.records.cep })
                dispatch({ idStore, type, campo: 'uf', value: response.data.records.uf })
                dispatch({ idStore, type, campo: 'cidade', value: response.data.records.cidade })
                dispatch({ idStore, type, campo: 'bairro', value: response.data.records.bairro })
                dispatch({ idStore, type, campo: 'logradouro', value: response.data.records.logradouro })
                dispatch({ idStore, type, campo: 'numero', value: response.data.records.numero })
                dispatch({ idStore, type, campo: 'complemento', value: response.data.records.complemento })
                dispatch({ idStore, type, campo: 'referencia', value: response.data.records.referencia })
                dispatch({ idStore, type, campo: 'status', value: response.data.records.status })
                dispatch({ idStore, type: 'TRANSPORTADORA_COPIA' })

                thenFunction()
            }).catch(e => {
                alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
            });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2);
    }
}
export async function CreateTransportadora(notificacao, dispatch, transportadora) {
    try {
        //Retorna se houve erro ou nao
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;

        if (transportadora.nomeRazao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'nomeRazao', value: true })
            invalido = true
        }
        if ((!ValidaCNPJ(transportadora.cpfCnpj) && !ValidaCPF(transportadora.cpfCnpj)) || transportadora.cpfCnpj === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'cpfCnpj', value: true })
            invalido = true
        }
        if (transportadora.apelidoFantasia === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'apelidoFantasia', value: true })
            invalido = true
        }
        if (transportadora.email === '' || !ValidaEmail(transportadora.email)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'email', value: true })
            invalido = true
        }
        if (transportadora.email2 !== '' && !ValidaEmail(transportadora.email2)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'email2', value: true })
            invalido = true
        }
        if (transportadora.emailContato !== '' && !ValidaEmail(transportadora.emailContato)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'emailContato', value: true })
            invalido = true
        }
        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/transportadora/createTransportadora`,
            headers: { Authorization: basicAuth },
            data: {
                tipo: transportadora.tipoTrans,
                NomeRazao: transportadora.nomeRazao,
                CpfCnpj: transportadora.cpfCnpj,
                ApelidoFantasia: transportadora.apelidoFantasia,
                contatoNome: transportadora.nomeContato,
                contatoTelefone: transportadora.telefoneContato,
                contatoEmail: transportadora.emailContato,
                email: transportadora.email,
                email2: transportadora.email2,
                celular: transportadora.celular,
                rgInscEst: transportadora.rgIncest,
                telefone: transportadora.telefone1,
                telefone2: transportadora.telefone2,

            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'codTrans', value: response.data.records })
            dispatch({ idStore, type: 'TRANSPORTADORA_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}
export async function UpdateTransportadora(notificacao, dispatch, transportadora, transportadoraCopia) {
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(transportadora) === JSON.stringify(transportadoraCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        var invalido = false;

        if (transportadora.email === '' || !ValidaEmail(transportadora.email)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'email', value: true })
            invalido = true
        }
        if (transportadora.email2 !== '' && !ValidaEmail(transportadora.email2)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'email2', value: true })
            invalido = true
        }
        if (transportadora.emailContato !== '' && !ValidaEmail(transportadora.emailContato)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'emailContato', value: true })
            invalido = true
        }
        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }
        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/transportadora/updateTransportadora?codTransportadora=${transportadora.codTrans}`,
            headers: { Authorization: basicAuth },
            data: {
                contatoNome: transportadora.nomeContato,
                ContatoTelefone: transportadora.telefoneContato,
                ContatoEmail: transportadora.emailContato,
                Email: transportadora.email,
                Email2: transportadora.email2,
                RgInscEst: transportadora.rgIncest,
                Telefone: transportadora.telefone1,
                Telefone2: transportadora.telefone2,
                celular: transportadora.celular,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'codTrans', value: response.data.records })
            dispatch({ idStore, type: 'TRANSPORTADORA_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}
export async function UpdateEndereco(notificacao, dispatch, transportadora, transportadoraCopia) {
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(transportadora) === JSON.stringify(transportadoraCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        var invalido = false;

        if (transportadora.cep === '' || !ValidaCep(transportadora.cep)) {
            dispatch({ idStore, type: 'INVALIDO', campo: 'cep', value: true });
            invalido = true
        }
        if (transportadora.uf === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'uf', value: true });
            invalido = true
        }
        if (transportadora.cidade === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'cidade', value: true });
            invalido = true
        }
        if (transportadora.bairro === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'bairro', value: true });
            invalido = true
        }
        if (transportadora.logradouro === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'logradouro', value: true });
            invalido = true
        }
        if (transportadora.numero === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'numero', value: true });
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/transportadora/updateEndereco?codTransportadora=${transportadora.codTrans}`,
            headers: { Authorization: basicAuth },
            data: {
                Cep: transportadora.cep,
                Uf: transportadora.uf,
                Cidade: transportadora.cidade,
                Bairro: transportadora.bairro,
                Logradouro: transportadora.logradouro,
                Numero: transportadora.numero,
                Complemento: transportadora.complemento,
                referencia: transportadora.referencia,
                Ibge: transportadora.ibge,
                Gia: transportadora.gia,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'TRANSPORTADORA_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}
export function Inativar(notificacao, dispatch, transportadora, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/transportadora/inativarTransportadora?codTransportadora=${transportadora.codTrans}&status=${transportadora.status}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'TRANSPORTADORA_COPIA_STATUS', value: transportadora.status === 1 ? 0 : 1 })
            Pesquisar(notificacao, dispatch, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function Excluir(notificacao, dispatch, transportadora, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/transportadora/excluirTransportadora?codTransportadora=${transportadora.codTrans}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar(notificacao, dispatch, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function BuscarCep(notificacao, dispatch, cep) {
    try {
        if (cep.trim() !== '' && cep.trim().length >= 8) {
            var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

            const base = await axios.get(api.urlCadastro + `api/v1/buscaCep/${cep}`, { headers: { Authorization: basicAuth } }
            ).then(response => {
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'uf', value: response.data.records.uf })
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'cidade', value: response.data.records.cidade })
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'logradouro', value: response.data.records.logradouro })
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'bairro', value: response.data.records.bairro })
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'ibge', value: response.data.records.ibge })
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'gia', value: response.data.records.gia })
                return true
            }).catch(e => {
                return false
            });

            if (base) {
                return true
            }

            const viacep = await axios.get(`http://viacep.com.br/ws/${cep}/json/`
            ).then(response => {
                if (response.data.erro) {
                    return false
                }
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'uf', value: response.data.uf })
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'cidade', value: response.data.localidade })
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'logradouro', value: response.data.logradouro })
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'bairro', value: response.data.bairro })
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'ibge', value: response.data.ibge })
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'gia', value: response.data.gia })
                return true
            }).catch(e => {
                return false
            });

            if (viacep) {
                return true
            }

            const postomon = await axios.get(`http://api.postmon.com.br/v1/cep/${cep}`
            ).then(response => {
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'uf', value: response.data.estado })
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'cidade', value: response.data.cidade })
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'logradouro', value: response.data.logradouro })
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'bairro', value: response.data.bairro })
                dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'ibge', value: response.data.cidade_info.codigo_ibge })
                return true
            }).catch(e => {
                return false
            });

            if (postomon) {
                return true
            }
            alertar(notificacao, 'br', "CEP não encontrado", 'warning', 'icon-alert-circle-exc', 2)

        } else {
            alertar(notificacao, 'br', "CEP inválido", 'warning', 'icon-alert-circle-exc', 2)
        }
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter o endereço", 'warning', 'icon-alert-circle-exc')
        return false
    }
}