var INITIAL_STATE = {
    pesquisa: {
        numEntrada: '',
        numDocumento: '',
        codFilial: [],
        codForne: 0,
        cpfCnpj: '',
        fornecedor: '',
        dataInicio: new Date().toISOString().substr(0, 10),
        dataFim: new Date().toISOString().substr(0, 10),
        dataCadastro: '',
        tipoDocumento: '',
        tipoData: 0,
        dataDocumento: '',
        total: 0,
    },
    cadastro: {
        dadosIniciais: {
            numEntrada: '',
            numDoc: '',
            dataEntrada: '',
            dataDocumento: '',

            operacao: {
                codOperacao: 0,
                numeroOperacao: 0,
                descricaoOperacao: '',
                geraFinanceiro: 0,
            },

            fornecedor: {
                codFor: 0,
                nomeRazao: '',
                cpfCnpj: '',
                rgInscest: ''
            },

            transportadora: {
                codTransp: 0,
                nomeRazao: '',
                cpfCnpj: '',
                rgInscest: ''
            },
        },
        itens: {
            produto: {
                codProdutoItem: '',
                gradesSelected: [],
                codProduto: '',
                sequencialItem: '',
                numProduto: '',
                codBarras: '',
                nome: '',
                quantidade: '',
                valorUnitario: '',
                valorTotal: '',
                unidade: '',
                codGrade: '',
                controlaGrade: '',
                editarProduto: false
            },
            valoresTotais: {
                subTotal: 0,
                acrescimo: 0,
                desconto: 0,
                valorTotal: 0,
                impostos: {
                    BcICMS: 0,
                    ICMS: 0,
                    ICMSST: 0,
                    IPI: 0,
                    PIS: 0,
                    COFINS: 0,
                    COFINSST: 0,
                },
            },
            listGrade: [],
            listGradeCopia: [],
        }
    },
    invalido: {
        numDoc: false,
        dataDocumento: false,
        codFor: false,
        codTransp: false,
        codOperacao: false,
        codBarras: false,
        nome: false,
        quantidade: false,
        valorUnitario: false
    },
    entradas: [],
    entradaCopia: [],
    empresas: [],
    fornecedores: [],
    transportadoras: [],
    operacoes: [],
    produtos: [],
    produtosInseridos: [],
    produtosSelected: [],
    produtosSelectedCopia: [],
    itensInserir: [],
    itensEditar: [],
    itensExcluir: [],
    duplicatas: [],
    visualizarEntrada: false,
    inicioCadastro: false,
    screen: 0,
    currentStep: 0,
    totalInventario: 0
};

export default function entradaMercadoria(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'ENTRADA_MERCADORIA':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'GRADE':
                    return { ...state, cadastro: { ...state.cadastro, itens: { ...state.cadastro.itens, listGrade: action.value } } }
                case 'GRADE_COPIA':
                    return { ...state, cadastro: { ...state.cadastro, itens: { ...state.cadastro.itens, listGradeCopia: action.value } } }
                case 'GRADES_SELECTED':
                    return { ...state, cadastro: { ...state.cadastro, itens: { ...state.cadastro.itens, produto: { ...state.cadastro.itens.produto, gradesSelected: action.value } } } }
                case 'EDITAR':
                    return { ...state, cadastro: { ...state.cadastro, itens: { ...state.cadastro.itens, produto: { ...state.cadastro.itens.produto, editarProduto: action.value } } } }
                case 'ENTRADAS':
                    return { ...state, entradas: action.value }
                case 'ENTRADA':
                    return { ...state, cadastro: action.value }
                case 'VISUALIZAR':
                    return { ...state, visualizarEntrada: action.value }
                case 'ENTRADA_COPIA':
                    return { ...state, entradaCopia: { ...state.cadastro } }
                case 'ENTRADA_SALVAR':
                    return { ...state, cadastro: { ...state.cadastro, dadosIniciais: { ...state.cadastro.dadosIniciais, numEntrada: action.value } } }
                case 'DADOS_INICIAIS':
                    return { ...state, cadastro: { ...state.cadastro, dadosIniciais: { ...state.cadastro.dadosIniciais, [action.campo]: action.value } } }
                case 'FORNECEDOR':
                    return {
                        ...state, cadastro: {
                            ...state.cadastro, dadosIniciais: {
                                ...state.cadastro.dadosIniciais, fornecedor: action.value
                            }
                        }
                    }
                case 'FORNECEDORES':
                    return { ...state, fornecedores: action.value }
                case 'TRANSPORTADORA':
                    return {
                        ...state, cadastro: {
                            ...state.cadastro, dadosIniciais: {
                                ...state.cadastro.dadosIniciais, transportadora: action.value
                            }
                        }
                    }
                case 'TRANSPORTADORAS':
                    return { ...state, transportadoras: action.value }
                case 'PRODUTO':
                    return {
                        ...state, cadastro: {
                            ...state.cadastro, itens: {
                                ...state.cadastro.itens, produto: action.value
                            }
                        }
                    }
                case 'PRODUTOS':
                    return { ...state, produtos: action.value }
                case 'PRODUTOS_COPIA':
                    return { ...state, produtos: action.value }
                case 'PRODUTOS_UPDATE':
                    return { ...state, [action.campo]: action.value }
                case 'PRODUTO_SELECIONADO':
                    return { ...state, produtosSelected: action.value }
                case 'PRODUTOS_INSERIDOS':
                    return { ...state, produtosInseridos: action.value }
                case 'OPERACOES':
                    return { ...state, operacoes: action.value }
                case 'OPERACAO':
                    return {
                        ...state, cadastro: {
                            ...state.cadastro, dadosIniciais: {
                                ...state.cadastro.dadosIniciais, operacao: action.value
                            }
                        }
                    }
                case 'VALORES_TOTAIS':
                    return {
                        ...state, cadastro: {
                            ...state.cadastro, itens: {
                                ...state.cadastro.itens, valoresTotais: action.value
                            }
                        }
                    }
                case 'EMPRESAS':
                    return { ...state, empresas: action.value }
                case 'DUPLICATAS':
                    return { ...state, duplicatas: action.value }
                case 'TELA':
                    return { ...state, screen: action.value }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'WIZARD_STEP':
                    return { ...state, currentStep: action.value }
                case 'CLEAN_CADASTRO':
                    return {
                        ...state,
                        cadastro: INITIAL_STATE.cadastro,
                        fornecedores: [],
                        transportadoras: [],
                        operacoes: [],
                        produtos: [],
                        produtosSelected: [],
                        duplicatas: [],
                        produtosSelectedCopia: [],
                        itensInserir: [],
                        itensEditar: [],
                        itensExcluir: [],
                        visualizarEntrada: false
                    }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: {
                            numEntrada: '',
                            numDocumento: '',
                            codForne: 0,
                            cpfCnpj: '',
                            fornecedor: '',
                            dataInicio: new Date().toISOString().substr(0, 10),
                            dataFim: new Date().toISOString().substr(0, 10),
                            dataCadastro: '',
                            tipoDocumento: '',
                            tipoData: 0,
                            dataDocumento: '',
                            total: 0
                        },
                        entradas: [],
                    }
                case 'CLEAN_FORNECEDORES':
                    return {
                        ...state, cadastro: {
                            ...state.cadastro, dadosIniciais: {
                                ...state.cadastro.dadosIniciais, fornecedor: INITIAL_STATE.cadastro.dadosIniciais.fornecedor
                            }
                        },
                        fornecedores: [],
                    }
                case 'CLEAN_TRANSPORTADORA':
                    return {
                        ...state, cadastro: {
                            ...state.cadastro, dadosIniciais: {
                                ...state.cadastro.dadosIniciais, transportadora: INITIAL_STATE.cadastro.dadosIniciais.transportadora
                            }
                        },
                        transportadoras: [],
                    }
                case 'CLEAN_PRODUTO':
                    return {
                        ...state, cadastro: {
                            ...state.cadastro, itens: {
                                ...state.cadastro.itens, produto: INITIAL_STATE.cadastro.itens.produto
                            }
                        },
                        produtos: [],
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state, invalido: {
                            ...state.invalido, [action.campo]: INITIAL_STATE.action.value
                        }
                    }
                case 'TOTAL_INVENTARIO':
                    return { ...state, totalInventario: action.value }
                    
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}