import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import store from '~/store/stores';

const idStore = 'GRUPO_CONTAS';


export function Pesquisar(pesquisa) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        const tipo = pesquisa.tipo === "0" ? "" : pesquisa.tipo

        axios.get(api.urlCadastro + `api/v1/grupoConta/grupoContas?descricao=${pesquisa.descricao}&tipo=${tipo}&status=${pesquisa.status}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum Grupo de Conta encontrado", 'info', 'icon-bulb-63', 2)
            }  
                dispatch({ idStore, type: 'GRUPO_CONTAS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os grupos de contas", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetGrupoConta(codGrupoConta, thenFunction){
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ":" + localStorage.getItem('TOKEN_KEY'))

        await axios({
            method: 'get',
            url: api.urlCadastro + `api/v1/grupoConta/grupoConta?codGrupoConta=${codGrupoConta}`, 
            headers: { Authorization: basicAuth }
        }).then(function (response){ 
                dispatch({ idStore, type: 'CADASTROS', value: response.data.records})
                thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export async function CreateGrupoConta(cadastro) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        var invalido = false;

        if (cadastro.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (cadastro.tipo === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'tipo', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', `Campo Inválido`, 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/grupoConta/create`,
            headers: { Authorization: basicAuth },
            data: {
                Descricao: cadastro.descricao,
                Tipo: cadastro.tipo
            }
        }).then(function (response) {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2);
            dispatch({ idStore, type: 'CADASTRO', campo: "codGrupoConta", value: response.data.records })
            dispatch({ idStore, type: 'CADASTRO_COPIA' })
            return false

        }).catch(e => {
            if (e.response.status === 409) {
                alertar(notificacao, 'br', e.response.data.message, 'warning', 'icon-alert-circle-exc', 2)
            } else {
                alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
            }
            return true
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}



export async function UpdateGrupoConta(pesquisa, cadastroCopia) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        // Retorna se houve erro ou nao
        if (JSON.stringify(pesquisa) === JSON.stringify(cadastroCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        if (pesquisa.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            return true
        }

        if (pesquisa.tipo === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'tipo', value: true })
            return true
        }


        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/grupoConta/update?descricao=${pesquisa.descricao}&tipo=${pesquisa.tipo}&codGrupoConta=${pesquisa.codGrupoConta}`,
            headers: { Authorization: basicAuth },
            data: {
                Descricao: pesquisa.descricao,
                Tipo: pesquisa.tipo,

            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'CADASTRO_COPIA', value: pesquisa })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function inativarGrupoConta(grupoContaSelected) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/grupoConta/inativar?codGrupoConta=${grupoContaSelected.codGrupoConta}&status=${grupoContaSelected.status}`,
            headers: { Authorization: basicAuth }
        }).then(response => {
            dispatch({ idStore, type: 'CADASTRO', campo: 'status', value: grupoContaSelected.status === 0 ? 1 : 0 })
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
        }).catch(e => {

            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os grupos de contas", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function ExcluirGrupoConta(pesquisa, grupoContaSelected) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1//grupoConta/delete?codGrupoConta=${grupoContaSelected.codGrupoConta}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar(notificacao, dispatch, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}