import React, { useEffect, useRef } from "react";
import ReactTable from "react-table";

import { Select } from "../../Components";

import {
    Col,
    Input,
    Row,
    FormGroup,
    Label,
    CardBody,
    Button
} from "reactstrap";

import useResize from "../../../../Util/Hooks/useResize";
import useShallowEqualSelector from "../../../../Util/Hooks/useShallowEqualSelector";
import AppState from "../../../../store/storeType";
import { IAberturaCaixaState } from "../../../../store/reducers/Cadastro/AberturaCaixa/types";

import * as controllerAbertura from '../../../../controller/controllerAberturaCaixas'
import * as action from "../../../../store/reducers/Cadastro/AberturaCaixa/actions";
import { Round } from "../../../../Util/numbers";

import ArrowTooltip from '../../../../components/Tooltip/ArrowTooltip';
import classNames from "classnames";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { maskMoeda } from "../../../../Util/mascaras";

const Pesquisar = function () {
    return (
        <Col lg="12">

            <Filtros />

            <RenderTable />

        </Col>
    );
}

function Filtros() {
    const refDataInicio = useRef<HTMLInputElement>(null);
    const refSelect = useRef<HTMLInputElement>(null);

    const pesquisa = useShallowEqualSelector<AppState, IAberturaCaixaState['pesquisa']>(state => state.aberturaCaixa.pesquisa);
    const funcionarios = useShallowEqualSelector<AppState, IAberturaCaixaState['funcionarios']>(state => state.aberturaCaixa.funcionarios);
    const block = useShallowEqualSelector<AppState, IAberturaCaixaState['block']>(state => state.aberturaCaixa.block);

    const { width } = useResize()

    useEffect(() => {
        autoFocus()

        initScren()
    }, [])

    async function initScren() {
        await controllerAbertura.getFuncionarios();
        controllerAbertura.getFuncionario();

    }

    function handlePesquisar() {
        controllerAbertura.getCaixas()
    }

    function handleChange(value: number | null) {
        action.setPesquisa({ ...pesquisa, tipo: value })
        controllerAbertura.getCaixas()
    }

    function autoFocus() {
        if (refSelect.current) {
            //@ts-ignore
            const { isDisabled } = refSelect.current.props

            if (isDisabled) {
                if (refDataInicio.current)
                    refDataInicio.current.focus();
            }
            else {
                refSelect.current.focus();
            }
        }
    }

    return (
        <>
            <Row className="padding-padrao-row">
                <Col className="padding-padrao-col" lg={4} md={4}>
                    <label>Funcionário</label>
                    <Select
                        //@ts-ignore
                        first={"Selecione"}
                        value={{
                            value: pesquisa.codigoFuncionario,
                            label: pesquisa.nomeFuncionario
                        }}
                        select={{ value: "codFunc", label: "nomeFunc" }}
                        options={funcionarios as never}
                        onChange={(value: any) => action.setPesquisa({ ...pesquisa, codigoFuncionario: value.value, nomeFuncionario: value.label })}
                        isSearchable={true}
                        autoComplete="off"
                        ref={refSelect}
                        isDisabled={true}
                    />
                </Col>

                <Col className="padding-padrao-col" lg={3} md={3}>
                    <label>Data Início</label>
                    <Input
                        type="date"
                        value={pesquisa.dataInicio}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { action.setPesquisa({ ...pesquisa, dataInicio: event.currentTarget.value }) }}
                        innerRef={refDataInicio}
                    />
                </Col>
                <Col className="padding-padrao-col" lg={3} md={3}>
                    <label>Data Fim</label>
                    <Input
                        type="date"
                        value={pesquisa.dataFim}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { action.setPesquisa({ ...pesquisa, dataFim: event.currentTarget.value }) }}
                    />
                </Col>

                <Col lg="2" md="4" className="text-center padding-padrao-col" style={{ paddingTop: '12px' }}>
                    <Col className={width > 768 ? "text-left" : ''}>
                        <FormGroup check className="form-check-radio" Style={"margin-top:-12px;"} >
                            <Label check style={{ paddingRight: '5px' }}>
                                <Input type="radio"
                                    checked={pesquisa.tipo === null ? true : false}
                                    onChange={() => handleChange(null)}
                                />
                                <span className="form-check-sign" />Todos
                            </Label>
                            <Label check style={{ paddingRight: '5px' }}>
                                <Input type="radio"
                                    checked={pesquisa.tipo === 0 ? true : false}
                                    onChange={() => handleChange(0)}
                                />
                                <span className="form-check-sign" />Abertos
                            </Label>
                            <Label check style={{ paddingRight: '5px' }}>
                                <Input type="radio"
                                    checked={pesquisa.tipo === 1 ? true : false}
                                    onChange={() => handleChange(1)}
                                />
                                <span className="form-check-sign" />Fechados
                            </Label>
                        </FormGroup>
                    </Col>
                </Col>
            </Row>

            <Row>
                <CardBody>
                    <Col style={{ width: '100%' }}>
                        <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={() => { action.cleanPesquisa(); controllerAbertura.getFuncionario() }}
                            disabled={block}
                        >
                            Limpar
                        </Button>
                        <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={() => handlePesquisar()}
                            disabled={block}
                        >
                            Pesquisar
                        </Button>
                    </Col>
                </CardBody>
            </Row>
        </>
    );
}

function RenderTable() {
    const caixas = useShallowEqualSelector<AppState, IAberturaCaixaState['caixas']>(state => state.aberturaCaixa.caixas);

    return (
        <div
            style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative' }}>
            <ReactTable
                data={caixas}
                filterable={false}
                NoDataComponent={() => (<></>)}
                columns={
                    [
                        {
                            Header: "Nome Funcionário",
                            accessor: "nomeFuncionario"
                        },
                        {
                            Header: "Data Abertura",
                            accessor: "dataAbertura"
                        },
                        {
                            Header: "Data Fechamento",
                            accessor: "dataFechamento"
                        },
                        {
                            Header: "Valor",
                            accessor: "valorTotalLancado",
                            Cell: (props) => maskMoeda(props.original.valorTotalLancado.toString())
                        },
                        {
                            Header: "Status",
                            accessor: "status",
                            Cell: (props) => props.original.status === 0 ? "Aberto" : "Fechado"
                        },
                        {
                            Header: "",
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                            Cell: (props) =>
                                <ArrowTooltip title="Editar">
                                    <Button
                                        onClick={() => {
                                            controllerAbertura.getCaixa(props.original.codCaixa, props.original.status)
                                        }}
                                        size="sm"
                                        className={classNames("btn-icon btn-link")}
                                        color="success"

                                    >
                                        <Icon icon="edit" className="fa-lg" />
                                    </Button>
                                </ArrowTooltip>
                        }
                    ]
                }
                minRows={1}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando"
                pageText="Página"
                ofText="de"
                rowsText='linhas'
                pageSize={caixas.length}
            />
        </div>
    )
}

export default Pesquisar;