import React, { useEffect } from "react";
import classNames from "classnames";
import { useHotkeys } from 'react-hotkeys-hook'
import {
    Button,
    ButtonGroup,
    CardTitle,
} from "reactstrap";

import * as actions from '../../../store/reducers/Movimentacao/Inventario/actions'
import { shallowEqual, useSelector } from "react-redux";
import AppState from "../../../store/storeType";
import ArrowTooltip from "../../../components/Tooltip/ArrowTooltip";
import { IInventarioState } from "../../../store/reducers/Movimentacao/Inventario/types";

interface Tab {
    tabName: String,
    tabScreen: JSX.Element,
    subTitle?: String,
    ArrowToolTipTitle: String
}

interface Props {
    tabs: Tab[]
}

const TabsContent = function (props: Props) {

    useEffect(() => {
        actions.setScreen(0)
    }, [])

    const screen = useSelector<AppState, IInventarioState['screen']>(state => state.inventario.screen, shallowEqual);

    const cleanAll = () => {

    }

    useEffect(() => {

        if (screen === 0) {
            cleanAll();
        }

    }, [screen])

    useHotkeys('shift+1', () => {

        actions.setScreen(0)

    }, [])

    useHotkeys('shift+2', () => {

        actions.setScreen(1)

    }, [])

    return (
        <>
            <ButtonGroup style={{ width: '100%' }}>

                {props.tabs && props.tabs.map((item: Tab, index: number) => {
                    return (
                        <>
                            <ArrowTooltip title={item.ArrowToolTipTitle}>
                                <Button
                                    color={screen === index ? "info" : ""}
                                    type="button"
                                    data-toggle="tab"
                                    className={classNames("btn-gp-padrao", screen === index ? "active" : "")}
                                    onClick={() => { actions.setScreen(index) }}
                                    style={{ width: '50%' }}
                                >
                                    {item.tabName}
                                </Button>
                            </ArrowTooltip>
                        </>
                    )
                })
                }
            </ButtonGroup>
            <br />
            <br />
            {props.tabs[screen].tabScreen}
        </>
    );
}

export default TabsContent;