import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerTransportadoras from "~/controller/controllerTransportadoras";
import classNames from "classnames";
import { Switch, InputTelefone, InputCpfCnpj } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  ButtonGroup,
} from "reactstrap";

const idStore = "TRANSPORTADORAS";
const Transportadora = (props) => {
  const notificacao = useSelector(state => state.notificacao);
  const { pesquisa, transportadora, transportadoraCopia, invalido, verCadastro } = useSelector(state => state.transportadoras);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.salvar)
      Salvar();
  }, [props.salvar])
  function onChange(value, stateName) {
    dispatch({ idStore, type: 'TRANSPORTADORA', campo: stateName, value: value });
    dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });

  };

  function onChangeAtivo(e) {
    if (transportadora.codTrans !== '') {
      controllerTransportadoras.Inativar(notificacao, dispatch, transportadora, pesquisa);
    }
    dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'status', value: e.target.checked ? 0 : 1 });
  };

  function onChangeTipoTrans(e) {
    e.preventDefault();
    if (transportadora.codTrans === '') {
      dispatch({ idStore, type: 'CLEAN_TRANSPORTADORA' });
      dispatch({ idStore, type: 'CLEAN_INVALIDO' });
      dispatch({ idStore, type: 'TRANSPORTADORA', campo: 'tipoTrans', value: e.target.value });
    }

    setTimeout(() => {
      if (transportadora.codTrans === '') {
        document.getElementById('nomeRazaoTransp').focus()
      } else {
        document.getElementById('rgIncestTransp').focus()
      }
    }, 100)
  }

  async function Salvar(e) {
    var error
    if (transportadora.codTrans === '') {
      error = await controllerTransportadoras.CreateTransportadora(notificacao, dispatch, transportadora);
    } else {
      error = await controllerTransportadoras.UpdateTransportadora(notificacao, dispatch, transportadora, transportadoraCopia);
    }

    if (!error) {
      setProximoCadastro(true);
      setTimeout(() => { setProximoCadastro(false); }, 100)
    }
  };

  function Novo(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_TRANSPORTADORA' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
  };

  function setProximoCadastro(valor) {
    dispatch({ idStore, type: 'PROXIMO_CADASTRO', value: valor });
  }

  return (
    <>
      <Row className="justify-content-center">
      <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

      </div>
        <Col>
          <h4 className="info-text">Preencha com as informações da transportadora</h4>
          <CardBody>

            <Row className="justify-content-center">

              <Col md="10" className="text-right">
                <Switch
                  componentleft={<label className={transportadora.status === 0 ? `text-success text-center` : "text-warning text-center"}>{transportadora.status === 0 ? "Ativo" : "Inativo"}</label>}
                  checked={transportadora.status === 0}
                  onChange={e => onChangeAtivo(e)}
                  disabled={transportadora.codTrans === ''}
                />
              </Col>

              <Col md="10">
                <Row>
                  <ButtonGroup>
                    <Button
                      color={transportadora.tipoTrans === "F" ? "info" : ""}
                      type="button"
                      className={classNames("btn-gp-padrao", transportadora.tipoTrans === "F" ? "active" : "")}
                      value="F"
                      onClick={e => onChangeTipoTrans(e)}
                      disabled={transportadora.codTrans !== '' && transportadora.tipoTrans === 'J' ? true : false}
                    >
                      Pessoa física
                    </Button>
                    <Button
                      color={transportadora.tipoTrans === "J" ? "info" : ""}
                      type="button"
                      className={classNames("btn-gp-padrao", transportadora.tipoTrans === "J" ? "active" : "")}
                      value="J"
                      onClick={e => onChangeTipoTrans(e)}
                      disabled={transportadora.codTrans !== '' && transportadora.tipoTrans === 'F' ? true : false}
                    >
                      Pessoa jurídica
                     </Button>
                  </ButtonGroup>
                </Row>
              </Col>
            </Row>
            <br></br>
            <br></br>

            <Row className="justify-content-center">

              <Col md="10">

                <Row>

                  <Col className="padding-padrao-col" lg md sm="7">
                    <label>{transportadora.tipoTrans === 'F' ? "Nome" : "Razão Social"}<span className="error"> *</span></label>
                    <FormGroup className={invalido.nomeRazao ? "has-danger" : ''}>
                      <Input
                        id="nomeRazaoTransp"
                        type="text"
                        value={transportadora.nomeRazao}
                        onChange={e => onChange(e.target.value, 'nomeRazao')}
                        autoComplete="off"
                        disabled={transportadora.codTrans !== '' ? true : false}
                        maxLength="100"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg md sm="7">
                    <label>{transportadora.tipoTrans === 'F' ? "Apelido" : "Nome Fantasia"}<span className="error"> *</span></label>
                    <FormGroup className={invalido.apelidoFantasia ? "has-danger" : ''}>
                      <Input
                        id="apelidoFantasia"
                        type="text"
                        value={transportadora.apelidoFantasia}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        disabled={transportadora.codTrans !== '' ? true : false}
                        maxLength="50"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

                <Row>

                  <Col className="padding-padrao-col" lg="4">
                    <label>{transportadora.tipoTrans === 'F' ? "CPF" : "CNPJ"}<span className="error"> *</span></label>
                    <FormGroup className={invalido.cpfCnpj ? "has-danger" : ''}>
                      <InputCpfCnpj
                        id="cpfCnpj"
                        tipo={transportadora.tipoTrans === 'F' ? "cpf" : "cnpj"}
                        value={transportadora.cpfCnpj}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        disabled={transportadora.codTrans !== '' ? true : false}
                        maxLength="18"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg="3" md="4">
                    <label>{transportadora.tipoTrans === 'F' ? "RG" : "Inscrição Estadual"}</label>
                    <FormGroup>
                      <Input
                        id="rgIncestTransp"
                        type="text"
                        value={transportadora.rgIncest}
                        onChange={e => onChange(e.target.value, 'rgIncest')}
                        autoComplete="off"
                        maxLength="20"
                      
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg md="4">
                    <label>Contato{" "}</label>
                    <FormGroup>
                      <Input
                        id="nomeContato"
                        type="text"
                        value={transportadora.nomeContato}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="50"                    
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>
                <Row>
                  <Col className="padding-padrao-col" lg md="4">
                    <label>Telefone de Contato{" "}</label>
                    <FormGroup>
                      <InputTelefone
                        id="telefoneContato"
                        type="fone"
                        value={transportadora.telefoneContato}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="16"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>
                  <Col className="padding-padrao-col" lg md="4">
                    <label>E-mail de Contato{" "}</label>
                    <FormGroup className={invalido.emailContato ? "has-danger" : ''}>
                      <Input
                        id="emailContato"
                        type="text"
                        value={transportadora.emailContato}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="70"                  
                      />
                      <br></br>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>

                  <Col className="padding-padrao-col" lg md="4">
                    <label>E-mail Primário<span className="error"> *</span></label>
                    <FormGroup className={invalido.email ? "has-danger" : ''}>
                      <Input
                        id="email"
                        type="text"
                        value={transportadora.email}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="  "
                        maxLength="70"                      
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg md="4">
                    <label>E-mail Secundário{" "}</label>
                    <FormGroup className={invalido.email2 ? "has-danger" : ''}>
                      <Input
                        id="email2"
                        type="text"
                        value={transportadora.email2}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="70"                     
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

                <Row>

                  <Col className="padding-padrao-col" lg md="4">
                    <label>Telefone{" "}</label>
                    <FormGroup>
                      <InputTelefone
                        id="telefone1"
                        type="fone"
                        value={transportadora.telefone1}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="16"                     
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg md="4">
                    <label>Telefone Secundário{" "}</label>
                    <FormGroup>
                      <InputTelefone
                        id="telefone2"
                        type="fone"
                        value={transportadora.telefone2}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="16"                   
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg md="4">
                    <label>Celular{" "}</label>
                    <FormGroup>
                      <InputTelefone
                        id="celular"
                        type="fone"
                        value={transportadora.celular}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="16"                   
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>



              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Col style={{display:"flex", textAlign:'center' }}>
              <ArrowTooltip title='Shift+N'>
                <Button style={{ left:"33%", position:"relative" }} className="btn-padrao" color="default" onClick={(e) => Novo(e)}>Novo</Button>
              </ArrowTooltip>
              <ArrowTooltip title='Shift+S'>
                <Button style={{ left:"33%", position:"relative" }} className="btn-padrao" color="info" onClick={(e) => { Salvar(); e.preventDefault(); }}>Salvar</Button>
              </ArrowTooltip>
            </Col>
          </CardFooter>
        </Col>
      </Row>
    </>
  )
}
class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salvar: false
    };
  }

  focusInput = () => {
    setTimeout(() => {
      if (this.props.transportadora.codTrans === '') {
        document.getElementById('nomeRazaoTransp').focus()
      } else {
        document.getElementById('rgIncestTransp').focus()
      }
    }, 100)
  }

  isValidated = (step = 0, finish = false) => {
    if (step > 0) {
      return true
    }
    if (!finish) {
      this.setState({ salvar: true }, () => {
        this.setState({ salvar: false })
      })
    }
    return false
  }

  render() {
    return (
      <Transportadora salvar={this.state.salvar} />
    )
  }
}

export default Wizard;