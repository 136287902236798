import React, { useEffect, useState } from 'react';
import { Input, Button, Col, Row, Label } from 'reactstrap';

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "../../../../components/ModalBasic/ModalBasic";


import { Select } from '../../../../components/ErnComponets/ErnComponets';
import { maskMoeda } from '../../../../Util/mascaras';
import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector';
import AppState from '../../../../store/storeType';
import { ILancamento, IRecebimentoPagamentoState } from '../../../../store/reducers/Financeiro/RecebimentoPagamento/types';
import * as controller from '../../../../controller/Financeiro/controllerRecebimentosPagamentos'
import * as action from '../../../../store/reducers/Financeiro/RecebimentoPagamento/actions'
import moment from 'moment';

interface Props {
    isOpen: boolean,
    toggle: Function,
    codigoTituloMovimento: number
}

export default function ModalRecebimentoPagamentoRapido(props: Props) {
    const contas = useShallowEqualSelector<AppState, IRecebimentoPagamentoState['contas']>(state => state.recebimentoPagamento.contas)
    const informacoes = useShallowEqualSelector<AppState, IRecebimentoPagamentoState['informacoes']>(state => state.recebimentoPagamento.informacoes)
    const formasPagamentos = useShallowEqualSelector<AppState, IRecebimentoPagamentoState['formasPagamentos']>(state => state.recebimentoPagamento.formasPagamentos)
    const lancamentoRapido = useShallowEqualSelector<AppState, IRecebimentoPagamentoState['lancamentoRapido']>(state => state.recebimentoPagamento.lancamentoRapido)

    const [jsonPagamento, setJsonPagamento] = useState<ILancamento>({
        codigoTituloMovimento: 0,
        titulosLancamentos: [{...lancamentoRapido, codigoCaixa: informacoes.codCaixa }]
    })

    useEffect(() => {
        controller.getContas()
        controller.getFormasPgto()
    }, [])

    useEffect(() => {
        setJsonPagamento({
            codigoTituloMovimento: props.codigoTituloMovimento,
            titulosLancamentos: [{...lancamentoRapido, codigoCaixa: informacoes.codCaixa }]
        })
    },[lancamentoRapido])

    function onChangeLancamento(field: string, value: any) {
        action.setLancamentoRapido(field, value)
    }

    return (
        <ModalBasic
            isOpen={props.isOpen}
            toggle={() => props.toggle()}
            title='CONFIRMAÇÃO'
            size="lg"
        >
            <ModalBasicBody>
                <h4 style={{ textAlign: 'center' }}>Confirmação de Recebimento/Pagamento <strong>Rápido</strong></h4>

                <Col>
                    <Label>
                        Forma de Pagamento
                    </Label>
                    <Select
                        first={'Selecione'}
                        select={{ value: 'codForma', label: 'descricao' }}
                        value={lancamentoRapido.codigoForma}
                        //@ts-ignore
                        options={formasPagamentos}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { 
                            //@ts-ignore
                            onChangeLancamento('codigoForma', e.value)
                        }}
                    />
                    <Label>
                        Conta
                    </Label>
                    <Select
                        first={'Selecione'}
                        select={{ value: 'codConta', label: 'descricao' }}
                        value={lancamentoRapido.codigoConta}
                        //@ts-ignore
                        options={contas}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            //@ts-ignore
                            onChangeLancamento('codigoConta', e.value)
                        }}

                    />
                    <Row>
                        <Col>
                            <Label>
                                TOTAL TÍTULO
                            </Label>
                            <Input
                                style={{ cursor: 'default', fontSize: '20px' }}
                                disabled
                                value={maskMoeda(lancamentoRapido!.valorTotal)}
                            />
                        </Col>
                        <Col>
                            <Label>
                                TOTAL À LANÇAR
                            </Label>
                            <Input
                                style={{ cursor: 'default', fontSize: '20px' }}
                                disabled
                                value={maskMoeda(lancamentoRapido!.valorTotal)}
                            />
                        </Col>
                    </Row>
                </Col>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        color={"default"}
                    >VOLTAR
                    </Button>
                    <Button
                        className="btn-padrao"
                        color={"info"}
                        onClick={() => controller.lancarPagamentosRecebimentos(jsonPagamento, props.toggle)}
                    >CONFIRMAR
                    </Button>
                </Col>
            </ModalBasicFooter>

        </ModalBasic>
    )
}
