import React from "react"
import ReactTable from "react-table";
import { Col, Input, Label, Row } from "reactstrap";
import { InputCpfCnpj as InputCpfCnpjERN } from '../../../../components/ErnComponets/ErnComponets';
import { maskMoeda } from "../../../../Util/mascaras";


interface Props {
  creditoPrevisao: String,
  creditoBaixados: String,
  creditoRestantes: String,
  debitoPrevisao: String,
  debitoBaixados: String,
  debitoRestantes: String,
  totalPrevisao: String,
  totalBaixados: String,
  totalRestantes: String
}

export function InputCpfCnpj(props: any) {
  return (
    <InputCpfCnpjERN
      {...props}
      style={{ ...props.style }}
      onChange={(event: any) => { props.onChange && props.onChange(event) }}
      value={props.value || ""}
    />
  )
}



export function TableResultado(props: Props) {
  return (
    <>
      <Col style={{ width: '70%', float: 'right' }}>
        <Row>
          <Col style={{paddingRight:'0'}}>
            	<br style={{ height: '25%' }}/>
              <br/>
              <Label style={{ height: '25%' }}>PREVISÃO</Label>
              <br/>
              <Label style={{ height: '25%' }}>BAIXADOS</Label>
              <br/>
              <Label style={{ height: '25%' }}>RESTANTES</Label>
          </Col>
          <Col>
            <Label>CRÉDITO</Label>
            <Input
              style={{ cursor: 'default', fontSize: '20px' }}
              disabled={true}
              //@ts-ignore
              value={props.creditoPrevisao !== undefined ? maskMoeda(props.creditoPrevisao) : '0,00'}
            />
            <br/>
            <Input
              style={{ cursor: 'default', fontSize: '20px' }}
              disabled={true}
              //@ts-ignore
              value={props.creditoBaixados !== undefined ? maskMoeda(props.creditoBaixados) : '0,00'}
            />
            <br/>
            <Input
              style={{ cursor: 'default', fontSize: '20px' }}
              disabled={true}
              //@ts-ignore
              value={props.creditoRestantes !== undefined ? maskMoeda(props.creditoRestantes) : '0,00'}
            />
          </Col>
          <Col>
            <Label>DÉBITO</Label>
            <Input
              style={{ cursor: 'default', fontSize: '20px' }}
              disabled={true}
              //@ts-ignore
              value={props.debitoPrevisao !== undefined ? maskMoeda(props.debitoPrevisao) : '0,00'}
            />
            <br/>
            <Input
              style={{ cursor: 'default', fontSize: '20px' }}
              disabled={true}
              //@ts-ignore
              value={props.debitoBaixados !== undefined ? maskMoeda(props.debitoBaixados) : '0,00'}
            />
            <br/>
            <Input
              style={{ cursor: 'default', fontSize: '20px' }}
              disabled={true}
              //@ts-ignore
              value={props.creditoRestantes !== undefined ? maskMoeda(props.debitoRestantes) : '0,00'}
            />
          </Col>
          <Col>
            <Label>TOTAL</Label>
            <Input
              style={{ cursor: 'default', fontSize: '20px' }}
              disabled={true}
              //@ts-ignore
              value={props.totalPrevisao !== undefined ? maskMoeda(props.totalPrevisao) : '0,00'}
            />
            <br/>
            <Input
              style={{ cursor: 'default', fontSize: '20px' }}
              disabled={true}
              //@ts-ignore
              value={props.totalBaixados !== undefined ? maskMoeda(props.totalBaixados) : '0,00'}
            />
            <br/>
            <Input
              style={{ cursor: 'default', fontSize: '20px' }}
              disabled={true}
              //@ts-ignore
              value={props.totalRestantes !== undefined ? maskMoeda(props.totalRestantes) : '0,00'}
            />
          </Col>
        </Row>
      </Col>
    </>
  )
}