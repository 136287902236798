import React, { useState, useEffect, FormEvent } from "react";
import { useDispatch } from 'react-redux';

import {
    Input,
    Row,
    Col,
    Button,
    FormGroup,
} from "reactstrap";

import store from '../../../../../store/stores'
import { InputCpfCnpj, InputNumber } from '../../Components/index'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import useModal from "../../../../../Util/Hooks/useModal";
import ModalTransportadora from '../../Modals/ModalTransportadora';
import { Select } from "../../../../../components/ErnComponets/ErnComponets";

import * as controllerSaida from '../../../../../controller/Movimentacao/controllerSaidaDiversa'

import { maskNumbers } from '../../../../../Util/mascaras'
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import AppState from "../../../../../store/storeType";
import { Transportadora, CamposTransportadora } from "../../../../../store/reducers/Movimentacao/SaidaDiversa/types";
import { setTransportadoraList, setDadosTransportadora } from "../../../../../store/reducers/Movimentacao/SaidaDiversa/actions";

const idStore = "SAIDA_DIVERSA";

const estados = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' }
  ]

const modalidades = [
    { value: 0, label: '0-Contratação do Frete por conta do Remetente (CIF)' },
    { value: 1, label: '1-Contratação do Frete por conta do Destinatário (FOB)' },
    { value: 2, label: '2-Contratação do Frete por conta de Terceiros' },
    { value: 3, label: '3-Transporte Próprio por conta do Remetente' },
    { value: 4, label: '4-Transporte Próprio por conta do Destinatário' },
    { value: 9, label: '9-Sem Ocorrência de Transporte' },
  ]

const MaisInformacoes = function () {
    const dispatch = useDispatch();
    const transportadora = useShallowEqualSelector<AppState, Transportadora>(state => state.saidaDiversa.saida.transportadora);
    const invalido = useShallowEqualSelector<AppState, CamposTransportadora>(state => state.saidaDiversa.invalido.dadosIniciais.transportadora);
    const [modalTransportadora, toogleTransportadora] = useModal(false);

    const [desabilitarTransporte, setDesabilitarTrasporte] = useState(true)

    useEffect(() => {
        if (transportadora.modalidade === 9)
            setDesabilitarTrasporte(true)
        else
            setDesabilitarTrasporte(false)

    }, [transportadora.modalidade])

    function handleOnChange(value: string, campo: string) {
        setDadosTransportadora({ ...transportadora, [campo]: value })
    }

    function getTransportadoras({ documento, nomeRazao }: any) {
        controllerSaida.getTransportadoras({...transportadora, documento, razaoSocial: nomeRazao});
    }

    function cleanTransportadora() {
        setTransportadoraList([])
    }

    function confirmarTransportadora(codigoTransportadora: number) {
        controllerSaida.getTransportadora(codigoTransportadora, toogleTransportadora);
    }

    async function handleKeyPress(event: any) {
        if (event.key === 'Enter') {
            const codigoTransportadora = await controllerSaida.getTransportadoras({...transportadora, razaoSocial: ''});

            if (codigoTransportadora)
                controllerSaida.getTransportadora(codigoTransportadora, () => { });
            else
                toogleTransportadora();
        }
    }

    return (
        <fieldset className='fieldset-style'>
            <legend style={{ fontSize: '14px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
                DADOS DA TRANSPORTADORA
        </legend>
            <Col>
                <div >
                    <Row>

                        <Col md="10">
                            <label>Modalidade do Frete</label>
                            <FormGroup >
                                <Select
                                    value={{ value: transportadora.modalidade }}
                                    //@ts-ignore
                                    options={modalidades}
                                    onChange={(value: any) => handleOnChange(value.value, 'modalidade')}
                                />
                                <br></br>
                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <label>Valor Frete</label>
                            <FormGroup className={invalido.valorFrete ? "has-danger" : ''} >
                                <InputNumber
                                    type="text"
                                    value={transportadora.valorFrete}
                                    onChange={(event: FormEvent<HTMLInputElement>) => handleOnChange(event.currentTarget.value, 'valorFrete')}
                                    autoComplete="off"
                                    disabled={desabilitarTransporte}
                                />
                                <br></br>
                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <label>CPF / CNPJ</label>
                            <FormGroup className={invalido.documento ? "has-danger" : ''} >
                                <InputCpfCnpj
                                    type="text"
                                    tipo={transportadora.documento.length <= 14 ? 'cpf' : 'cnpj'}
                                    value={transportadora.documento}
                                    onChange={(event: FormEvent<HTMLInputElement>) => handleOnChange(event.currentTarget.value, 'documento')}
                                    autoComplete="off"
                                    maxLength="18"
                                    disabled={desabilitarTransporte}
                                    onKeyPress={(event: FormEvent<HTMLInputElement>) => handleKeyPress(event)}
                                />
                                <br></br>
                            </FormGroup>
                        </Col>

                        <Button color="info" onClick={() => toogleTransportadora()} style={{ width: 40, height: 40, marginTop: 25 }} disabled={desabilitarTransporte}>
                            <Icon icon="search" className="fa-lg" />
                        </Button>

                        <Col md="5">
                            <label>Nome / Razão Social</label>
                            <FormGroup >
                                <Input
                                    type="text"
                                    value={transportadora.razaoSocial}
                                    onChange={(event: FormEvent<HTMLInputElement>) => handleOnChange(event.currentTarget.value, 'razaoSocial')}
                                    autoComplete="off"
                                    disabled={true}
                                />
                                <br></br>
                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <label>Placa Veículo</label>
                            <FormGroup className={invalido.placaVeiculo ? "has-danger" : ''} >
                                <Input
                                    type="text"
                                    value={transportadora.placaVeiculo}
                                    onChange={e => handleOnChange(e.target.value, 'placaVeiculo')}
                                    autoComplete="off"
                                    disabled={desabilitarTransporte}
                                    maxLength={8}
                                />
                                <br></br>
                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <label>UF</label>
                            <FormGroup className={invalido.uf ? "has-danger" : ''} >
                                {
                                    desabilitarTransporte ?
                                        <Input disabled={desabilitarTransporte} />
                                        :
                                        <Select
                                            first="Nenhum"
                                            value={{ value: transportadora.uf }}
                                            //@ts-ignore
                                            options={estados}
                                            onChange={(value: any) => handleOnChange(value.value, 'uf')}
                                            disabled={desabilitarTransporte}
                                        />
                                }
                                <br></br>
                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <label>Quantidade Volume</label>
                            <FormGroup className={invalido.quantidadeVolume ? "has-danger" : ''} >
                                <Input
                                    type="text"
                                    value={transportadora.quantidadeVolume}
                                    onChange={e => handleOnChange(maskNumbers(e.target.value), 'quantidadeVolume')}
                                    autoComplete="off"
                                    disabled={desabilitarTransporte}
                                    maxLength={5}
                                />
                                <br></br>
                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <label>Espécie</label>
                            <FormGroup className={invalido.especie ? "has-danger" : ''} >
                                <Input
                                    type="text"
                                    value={transportadora.especie}
                                    onChange={e => handleOnChange(e.target.value, 'especie')}
                                    autoComplete="off"
                                    disabled={desabilitarTransporte}
                                />
                                <br></br>
                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <label>Marca</label>
                            <FormGroup className={invalido.marca ? "has-danger" : ''} >
                                <Input
                                    type="text"
                                    value={transportadora.marca}
                                    onChange={e => handleOnChange(e.target.value, 'marca')}
                                    autoComplete="off"
                                    disabled={desabilitarTransporte}
                                />
                                <br></br>
                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <label>Número Volume</label>
                            <FormGroup className={invalido.numeroVolume ? "has-danger" : ''} >
                                <Input
                                    type="text"
                                    value={transportadora.numeroVolume}
                                    onChange={(event: FormEvent<HTMLInputElement>) => handleOnChange(event.currentTarget.value, 'numeroVolume')}
                                    autoComplete="off"
                                    disabled={desabilitarTransporte}
                                    maxLength={8}
                                />
                                <br></br>
                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <label>Peso Líquido</label>
                            <FormGroup className={invalido.pesoLiquido ? "has-danger" : ''} >
                                <InputNumber
                                    type="text"
                                    value={transportadora.pesoLiquido}
                                    onChange={(event: FormEvent<HTMLInputElement>) => handleOnChange(event.currentTarget.value, 'pesoLiquido')}
                                    autoComplete="off"
                                    disabled={desabilitarTransporte}
                                />
                                <br></br>
                            </FormGroup>
                        </Col>

                        <Col md="2">
                            <label>Peso Bruto</label>
                            <FormGroup className={invalido.pesoBruto ? "has-danger" : ''} >
                                <InputNumber
                                    type="text"
                                    value={transportadora.pesoBruto}
                                    onChange={(event: FormEvent<HTMLInputElement>) => handleOnChange(event.currentTarget.value, 'pesoBruto')}
                                    autoComplete="off"
                                    disabled={desabilitarTransporte}
                                />
                                <br></br>
                            </FormGroup>
                        </Col>

                    </Row>
                </div>
            </Col>

            {modalTransportadora ?
                <ModalTransportadora
                    isOpen={true}
                    toggle={toogleTransportadora}
                    getTransportadoras={getTransportadoras}
                    clean={cleanTransportadora}
                    confirmModal={confirmarTransportadora}
                />
                :
                null
            }
        </fieldset>
    )
}

export default MaisInformacoes;