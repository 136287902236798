import React from "react";
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import useModal from "../../../../../Util/Hooks/useModal";
import ModalFornecedor from "../../Modals/ModalFornecedor"
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import * as controllerFornecedor from "../../../../../controller/Movimentacao/controllerEntradaMercadoria"
import { Row, Col, Input, Button, FormGroup } from "reactstrap";
import { InputCpfCnpj } from "~/components/ErnComponets/ErnComponets";

const idStore = "ENTRADA_MERCADORIA";

function DadosFornecedor() {
    const dispatch = useDispatch();
    const fornecedor = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.dadosIniciais.fornecedor);
    const verCadastro = useShallowEqualSelector(state => state.entradaMercadoria.visualizarEntrada);
    const invalido = useShallowEqualSelector(state => state.entradaMercadoria.invalido);
    const [modalPesquisa, toggleModalFornecedor] = useModal(false);

    function pesquisaFornecedores(pesquisaFornecedor) {
        controllerFornecedor.GetFornecedores(pesquisaFornecedor);
    }

    function confirmarFornecedor(codigoTransportadora) {
        controllerFornecedor.GetFornecedor(codigoTransportadora, toggleModalFornecedor);

    }

    function cleanFornecedores() {
        dispatch({ idStore, type: 'CLEAN_FORNECEDORES' });
    }

    async function handleKeyPress(event) {
        if (event.key === 'Enter') {

            if (fornecedor.cpfCnpj) {
                let codForne = await controllerFornecedor.GetFornecedoreKeyPress(fornecedor);
                if (!codForne) {
                    dispatch({ idStore, type: 'FORNECEDOR', value: { codFor: 0, nomeRazao: '', cpfCnpj: fornecedor.cpfCnpj, rgInscest: '' } })
                    toggleModalFornecedor();
                }
            }
            else {
                dispatch({ idStore, type: 'FORNECEDOR', value: { codFor: 0, nomeRazao: '', cpfCnpj: fornecedor.cpfCnpj, rgInscest: '' } })
                toggleModalFornecedor();
            }
        }
    }

    function onChange(value, campo, invalido) {
        dispatch({idStore, type: 'FORNECEDOR', value: {...fornecedor, [campo]: value} })
        dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'INVALIDO', campo: invalido, value: false })
    }

    return (
        <>
            <Col lg="12">
                <Row>
                    <div className={verCadastro ? 'ver-cadastro' : ''}></div>
                    <Col style={{ paddingTop: '10px' }}>
                        <fieldset style={{ border: 'solid 1px #5E6680', borderRadius: 5 }}>
                            <legend style={{ fontSize: '13px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
                                FORNECEDOR
                            </legend>
                            <Row  className="padding-padrao-row">
                                <Col lg="3"  className="padding-padrao-col">
                                    <label>CPF / CNPJ<span className="error"> *</span></label>
                                    <FormGroup className={invalido.codFor ? "has-danger" : ''}>
                                        <InputCpfCnpj
                                            type="text"
                                            autoComplete="off"
                                            value={fornecedor.cpfCnpj}
                                            onChange={e => onChange(e.target.value, "cpfCnpj", "codFor")}
                                            onKeyPress={e => handleKeyPress(e)}
                                            maxLength="18"
                                            tipo={fornecedor.cpfCnpj.length > 14 ? 'cnpj' : 'cpf'}
                                        />
                                    </FormGroup>
                                </Col>

                                <Button
                                    style={{ marginTop: '25px', width: '40px', marginBottom: '20px' }}
                                    type='button'
                                    color="info"
                                    onClick={toggleModalFornecedor}
                                >
                                    <Icon icon="search" className="fa-lg" />
                                </Button>

                                <Col className="padding-padrao-col" lg="5">
                                    <label>Nome / Razão Social</label>
                                    <Input
                                        type="text"
                                        autoComplete="off"
                                        value={fornecedor.nomeRazao}
                                        disabled={true}
                                    />
                                </Col>

                                <Col className="padding-padrao-col">
                                    <label>RG / IE</label>
                                    <Input
                                        type="text"
                                        autoComplete="off"
                                        value={fornecedor.rgInscest}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>
                </Row>
            </Col>
            {modalPesquisa ?
                <ModalFornecedor
                    isOpen={true}
                    toggle={toggleModalFornecedor}
                    getFornecedor={pesquisaFornecedores}
                    confirmModal={confirmarFornecedor}
                    clean={cleanFornecedores} /> :
                null
            }

        </>
    )
}

export default DadosFornecedor;