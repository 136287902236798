//State da tela de Pesquisa e cadastro de grupos
var INITIAL_STATE = {
    pesquisa: {
        nome: '',
        descricao: '',
        ativos: 0,
        tipo: '0',
    },
    grupo: {
        codGrupo: '',
        nome: '',
        descricao: '',
        status: 0,
        tipo: '0',
    },
    invalido: {
        nome: false,
        descricao: false,
    },
    vinculos: false,
    grupos: [],
    inicioCadastro: false,
    verCadastro: false,
    grupoCopia: {},
};

export default function gruposProduto(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'GRUPOS_PRODUTO':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'GRUPOS':
                    return { ...state, grupos: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: {
                            nome: '',
                            descricao: '',
                            ativos: 0,
                            tipo: '0',
                        },
                        grupos: []
                    }
                case 'GRUPO':
                    return { ...state, grupo: { ...state.grupo, [action.campo]: action.value } }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'VINCULOS':
                    return { ...state, vinculos: action.value }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value } 
                case 'GRUPO_COPIA':
                    return { ...state, grupoCopia: state.grupo }
                case 'GRUPO_COPIA_STATUS':
                    return { ...state, grupoCopia: { ...state.grupo, status: action.value } }
                case 'CLEAN_GRUPO':
                    return {
                        ...state,
                        grupo: {
                            codGrupo: '',
                            nome: '',
                            descricao: '',
                            status: 0,
                            tipo: '0',
                        },
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: {
                            nome: false,
                            descricao: false,
                        },
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}