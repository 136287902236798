import React, { useEffect, useState, useRef } from "react";
import ReactTable from "react-table";

import {
    Col,
    Input,
    Row,
    Button,
    FormGroup
} from "reactstrap";

import { Select } from "../../Components/index";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import useShallowEqualSelector from "../../../../Util/Hooks/useShallowEqualSelector";
import AppState from "../../../../store/storeType";
import { IAberturaCaixaState } from "../../../../store/reducers/Cadastro/AberturaCaixa/types";

import { InputNumber } from '../../../../components/ErnComponets/ErnComponets'
import ArrowTooltip from '../../../../components/Tooltip/ArrowTooltip';

import * as action from '../../../../store/reducers/Cadastro/AberturaCaixa/actions'
import * as controllerAbertura from '../../../../controller/controllerAberturaCaixas'
import { Round } from "../../../../Util/numbers";
import { maskMoeda } from "../../../../Util/mascaras";

const Pesquisar: React.FC = function () {

    useEffect(() => {

        return () => {
            action.cleanAbertura()
        }

    }, [])

    return (
        <Col lg="12">
            <Headers />

            <Row>
                <Col>
                    <fieldset className="pedido-venda-fieldList" style={{ padding: 16 }}>
                        <legend>Lançamentos</legend>

                        <FielsetCaixa />

                        <br />

                        <RenderTable />

                    </fieldset>
                </Col>
            </Row>

            <br />

            <Footer />

        </Col>
    );
}

function Headers() {
    const abertura = useShallowEqualSelector<AppState, IAberturaCaixaState['abertura']>(state => state.aberturaCaixa.abertura);

    return (
        <>
            <div>
                <p style={{ textAlign: 'right' }}>CAIXA: {" "}
                    <span style={abertura.status === "Fechado" ? { color: 'red' } : { color: '#00f2c3' }}>{abertura.status.toUpperCase()}</span>
                </p>
            </div>
            <Row className="padding-padrao-row">
                <Col className="padding-padrao-col" lg={6} md={4}>
                    <label>Funcionário</label>
                    <Input
                        type="text"
                        value={abertura.nomeFuncionario}
                        disabled={true}
                    />
                </Col>

                <Col className="padding-padrao-col" lg={2} md={3}>
                    <label>Data Abertura</label>
                    <Input
                        type="date"
                        value={abertura.dataAbertura}
                        disabled={true}
                    />
                </Col>
                <Col className="padding-padrao-col" lg={2} md={3}>
                    <label>Data Fechamento</label>
                    <Input
                        type="date"
                        value={abertura.dataFechamento}
                        disabled={true}
                    />
                </Col>
                <Col className="padding-padrao-col" lg={2} md={3}>
                    <label>Valor total lançado</label>
                    <Input
                        type="text"
                        style={{ textAlign: 'right' }}
                        value={abertura.valorTotalLancado ? Round(abertura.valorTotalLancado, 2) : "0,00"}
                        disabled={true}
                    />
                </Col>
            </Row>
        </>
    );
}

function FielsetCaixa() {
    const refInputValor = useRef<HTMLInputElement>(null);
    const refSelect = useRef<HTMLInputElement>(null);


    const lancamento = useShallowEqualSelector<AppState, IAberturaCaixaState['lancamento']>(state => state.aberturaCaixa.lancamento)
    const tipoLancamentos = useShallowEqualSelector<AppState, IAberturaCaixaState['tipoLancamentos']>(state => state.aberturaCaixa.tipoLancamentos)

    const [lancamentoSelecionado, setLancamentoSelecionado] = useState({ value: '', label: '' })
    const [invalido, setInvalido] = useState({ codFormaPagamento: false, valor: false });

    useEffect(() => {
        if (refSelect.current)
            refSelect.current.focus();

        controllerAbertura.getTiposLancamento();
        controllerAbertura.getFuncionario();
    }, [])

    useEffect(() => {
        setLancamentoSelecionado({
            value: lancamento.codFormaPagamento.toString(),
            label: lancamento.descricaoFormaPagamento
        })
    }, [lancamento.codFormaPagamento, lancamento.descricaoFormaPagamento])

    async function addLancamento() {
        const campoInvalido = await controllerAbertura.addLancamento();

        if (campoInvalido) {
            setInvalido({ ...invalido, [campoInvalido]: true })

            if (campoInvalido === 'valor')
                if (refInputValor.current)
                    refInputValor.current.focus();

            if (campoInvalido === 'codFormaPagamento')
                if (refSelect.current)
                    refSelect.current.focus();

            return;
        }

        action.cleanLancamento();

        if (refSelect.current)
            refSelect.current.focus();
    }

    function desabilidaCampoInvalido(campoInvalido: 'codFormaPagamento' | 'valor') {
        setInvalido({ ...invalido, [campoInvalido]: false })
    }

    return (
        <Row className="padding-padrao-row" style={{ display: 'flex', justifyContent: 'center' }}>
            <Col className="padding-padrao-col" lg={4} md={3}>
                <label>Tipo Lançamento <span className="error">*</span></label>
                <Select
                    //@ts-ignore
                    first={"Selecione"}
                    value={lancamentoSelecionado}
                    select={{ value: "codForma", label: "descricao" }}
                    options={tipoLancamentos as never}
                    onChange={(value: any) => action.setLancamento({
                        ...lancamento,
                        codFormaPagamento: value.value,
                        descricaoFormaPagamento: value.label
                    })}
                    isSearchable={true}
                    autoComplete="off"
                    ref={refSelect}
                />
            </Col>

            <Col className="padding-padrao-col" lg={2} md={3}>
                <label>Valor <span className="error">*</span></label>
                <FormGroup className={invalido.valor ? "has-danger" : ''}>
                    <Input
                        innerRef={refInputValor}
                        type="text"
                        value={maskMoeda(lancamento.valor)}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { action.setLancamento({ ...lancamento, valor: maskMoeda(event.currentTarget.value) }); desabilidaCampoInvalido('valor') }}
                        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => event.key === "Enter" && addLancamento()}
                        onFocus={(event: React.FocusEvent<HTMLInputElement>) => { event.currentTarget.select() }}
                    />
                </FormGroup>
            </Col>
            <Col className="padding-padrao-col" lg={4} md={3}>
                <label>Observação</label>
                <Input
                    type="text"
                    value={lancamento.observacao}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => action.setLancamento({ ...lancamento, observacao: event.currentTarget.value })}
                    onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => event.key === "Enter" && addLancamento()}
                />
            </Col>

            <Col className="padding-padrao-col">
                <Button color="info" onClick={() => addLancamento()} style={{ width: "100%", height: 40, marginTop: 25 }}>
                    <Icon icon="plus" className="fa-lg" />
                </Button>
            </Col>

        </Row>
    );
}


function RenderTable() {
    const lancamentos = useShallowEqualSelector<AppState, IAberturaCaixaState['lancamentos']>(state => state.aberturaCaixa.lancamentos)

    return (
        <>
            <div style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' }} >
                <ReactTable
                    data={lancamentos}
                    filterable={false}
                    NoDataComponent={() => (<></>)}
                    columns={
                        [
                            {
                                Header: "Data Lançamento",
                                accessor: "dataLancamento",
                            },
                            {
                                Header: "Tipo Lançamento",
                                accessor: "descricaoFormaPagamento"
                            },
                            {
                                Header: "Valor",
                                accessor: "valor",
                                width: 150,
                                Cell: (props) => maskMoeda(props.original.valor)
                            },
                            {
                                Header: "Observação",
                                accessor: "observacao"
                            },
                            {
                                Header: "Ação",
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                                width: 100,
                                Cell: (props) =>
                                    props.original.codCaixaLancamento ?

                                        <Button
                                            onClick={() => controllerAbertura.removeLancamento(props.index)}
                                            className="btn-icon btn-link"
                                            color="danger"
                                            disabled={props.original.codCaixaLancamento}
                                        >
                                            <Icon icon="trash-alt" className="fa-lg" />
                                        </Button>

                                        :

                                        <ArrowTooltip title="Excluir">
                                            <Button
                                                onClick={() => controllerAbertura.removeLancamento(props.index)}
                                                className="btn-icon btn-link"
                                                color="danger"
                                                disabled={props.original.codCaixaLancamento}
                                            >
                                                <Icon icon="trash-alt" className="fa-lg" />
                                            </Button>
                                        </ArrowTooltip>

                            }
                        ]
                    }
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    noDataText=""
                    pageSize={lancamentos.length}
                />
            </div>
        </>
    )
}

const Footer: React.FC = () => {
    const abertura = useShallowEqualSelector<AppState, IAberturaCaixaState['abertura']>(state => state.aberturaCaixa.abertura)

    return (
        <Row>
            <Col style={{ width: '100%' }}>
                <Button
                    color='info'
                    style={{ float: 'right' }}
                    onClick={() => controllerAbertura.createCaixa()}
                    disabled={abertura.status === "Fechado" ? true : false}
                >
                    {abertura.codCaixa > 0 || abertura.status === 'Fechado' ? 'SALVAR' : 'ABRIR CAIXA'}
                </Button>
            </Col>
        </Row>
    )
}

export default Pesquisar;