import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import store from '../store/stores';

const idStore = 'PRODUTOS_DESCONTINUADOS';

export function Pesquisar() {
    const { notificacao, produtosDescontinuados: { produtosSelecionados } } = store.getState()

    try {
    

        store.dispatch({ idStore, type: 'PRODUTO_DESCONTINUADO', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/produtoDescontinuado/produtosDescontinuados?ncm=${produtosSelecionados.ncm.replace(/\./g, '')}&descricao=${produtosSelecionados.descricao}&dataDescontinuado=${produtosSelecionados.dataDescontinuado}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum Produto descontinuado encontrado", 'info', 'icon-bulb-63', 2)
                return
            }
            store.dispatch({ idStore, type: 'PRODUTO_DESCONTINUADO', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
           
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os Produtos descontinuados", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetNovoNCM() {
    const { notificacao, produtosDescontinuados: { ncmPesquisa } } = store.getState()

    try {
        if (!ncmPesquisa.ncm && !ncmPesquisa.descricao) {
            return alertar(notificacao, 'br', "Informe ao menos um dos campos", 'warning', 'icon-alert-circle-exc', 2)
        }
        if (!ncmPesquisa.ncm && ncmPesquisa.descricao.length< 4) {
            return alertar(notificacao, 'br', "Informe ao menos 4 caracteres para a descrição", 'warning', 'icon-alert-circle-exc', 2)
        }

        store.dispatch({ idStore, type: 'NCMS', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/produtoDescontinuado/getNovoNCM?ncm=${ncmPesquisa.ncm.replace(/\./g, '')}&descricao=${ncmPesquisa.descricao}&dataDescontinuado=${ncmPesquisa.dataDescontinuado}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum ncm encontrado", 'info', 'icon-bulb-63', 2)
                return
            }
            store.dispatch({ idStore, type: 'NCMS', value: response.data.records })

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os Produtos descontinuados", 'warning', 'icon-alert-circle-exc', 2)
    }
}


export async function UpdateProdutoDescontinuado(pesquisaNCM) {
    const { notificacao, produtosDescontinuados: { produtoSelecionado } } = store.getState()

    try {

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/produtoDescontinuado/updateProdutoDescontinuado?codProduto=${produtoSelecionado.codProduto}`,
            headers: { Authorization: basicAuth },
            data: {
                NCM: pesquisaNCM.ncm.replace(/\./g, '')
            }

        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            store.dispatch({ idStore, type: 'CLEAN_DESCONTINUADO'})
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os Produtos descontinuados", 'warning', 'icon-alert-circle-exc', 2)
    }
}
