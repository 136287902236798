import FormasPagamentos from "../../../../views/FormasPagamentos/FormasPagamentos";
import store from "../../../stores";
import {
    IRecebimentoPagamentoState,
    RecebimentoPagamentoAction,
    RecebimentoPagamentoStore,
    RecebimentoPagamentoTypes
} from "./types";

export const setPesquisa = (pesquisa: IRecebimentoPagamentoState['pesquisa']) => store.dispatch<RecebimentoPagamentoAction>({
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes.PESQUISA,
    pesquisa
})

export const setRecebimentosPagamentos = (recebimentosPagamentos: IRecebimentoPagamentoState['recebimentosPagamentos']) => store.dispatch<RecebimentoPagamentoAction>({
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes.RECEBIMENTOS_PAGAMENTOS,
    recebimentosPagamentos
})

export const setEmpresas = (empresas: IRecebimentoPagamentoState['empresas']) => store.dispatch<RecebimentoPagamentoAction>({
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes.EMPRESAS,
    empresas
})

export const setFornecedores = (fornecedores: IRecebimentoPagamentoState['fornecedores']) => store.dispatch<RecebimentoPagamentoAction>({
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes.FORNECEDOR,
    fornecedores
})

export const setFormasPagamentos = (formasPagamentos: IRecebimentoPagamentoState['formasPagamentos']) => store.dispatch<RecebimentoPagamentoAction>({
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes.FORMAS_PAGAMENTO,
    formasPagamentos
})

export const setCodEmpresaFilial = (codEmpresaFilial: IRecebimentoPagamentoState['codEmpresaFilial']) => store.dispatch<RecebimentoPagamentoAction>({
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes.COD_EMPRESA_FILIAL,
    codEmpresaFilial
})

export const setContas = (contas: IRecebimentoPagamentoState['contas']) => store.dispatch<RecebimentoPagamentoAction>({
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes.CONTAS,
    contas
})

export const cleanPesquisa = () => store.dispatch<RecebimentoPagamentoAction>({
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes.CLEAN_PESQUISA
})

export const setInformacoes = (informacoes: IRecebimentoPagamentoState['informacoes']) => store.dispatch<RecebimentoPagamentoAction>({
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes.SET_INFORMACAO,
    informacoes
})

export const setLancamentoRapido = (field: string, lancamentoRapido: any) => store.dispatch<RecebimentoPagamentoAction>({
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes.SET_LANCAMENTO_RAPIDO,
    lancamentoRapido,
    field
})

export const cleanLancamentoRapido = () => store.dispatch<RecebimentoPagamentoAction>({
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes.SET_LANCAMENTO_RAPIDO,
})

export const setInformacoesRecebimentosPagamentos = (informacoesRecebimentosPagamentos: IRecebimentoPagamentoState['informacoesRecebimentosPagamentos']) => store.dispatch<RecebimentoPagamentoAction>({
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes.SET_INFORMACOES_RECEBIMENTOS_PAGAMENTOS,
    informacoesRecebimentosPagamentos
})

export const cleanInformacoesRecebimentosPagamentos = () => store.dispatch<RecebimentoPagamentoAction>({
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes.CLEAN_INFORMACOES_RECEBIMENTOS_PAGAMENTOS,
})

export const setGrupos = (grupos: IRecebimentoPagamentoState['grupos']) => store.dispatch<RecebimentoPagamentoAction>({
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes.SET_GRUPOS,
    grupos
})

export const setSubGrupos = (subGrupos: IRecebimentoPagamentoState['subGrupos']) => store.dispatch<RecebimentoPagamentoAction>({
    idStore: RecebimentoPagamentoStore.ID_STORE,
    type: RecebimentoPagamentoTypes.SET_SUB_GRUPOS,
    subGrupos
})