import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerFornecedores from "~/controller/controllerFornecedores";
import classNames from "classnames";
import { Switch } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';
import { InputCpfCnpj, InputTelefone } from "~/components/ErnComponets/ErnComponets";

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  ButtonGroup,
} from "reactstrap";



const idStore = "FORNECEDORES";


const Fornecedor = (props) => {
  const notificacao = useSelector(state => state.notificacao);
  const { pesquisa, fornecedor, fornecedorCopia, invalido, verCadastro } = useSelector(state => state.fornecedores);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.salvar)
      Salvar();
  }, [props.salvar])


  function onChange(value, stateName) {
    dispatch({ idStore, type: 'FORNECEDOR', campo: stateName, value: value });
    dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
  };

  function onChangeAtivo(e) {
    if (fornecedor.codFor !== '') {
      controllerFornecedores.Inativar(notificacao, dispatch, fornecedor, pesquisa);
    }
    dispatch({ idStore, type: 'FORNECEDOR', campo: 'status', value: e.target.checked ? "0" : "1" });
  };


  function onChangeTipoFor(e) {
    e.preventDefault();
    if (fornecedor.codFor === '') {
      dispatch({ idStore, type: 'CLEAN_FORNECEDOR' });
      dispatch({ idStore, type: 'CLEAN_INVALIDO' });
      dispatch({ idStore, type: 'FORNECEDOR', campo: 'tipoFor', value: e.target.value });
    }

    setTimeout(() => {
      if (fornecedor.codFor === '') {
        document.getElementById('nomeRazaoForne').focus()
      } else {
        document.getElementById('rgInscestForne').focus()
      }
    }, 100)
  }

  async function Salvar() {
    var error
    if (fornecedor.codFor === '') {
      error = await controllerFornecedores.CreateFornecedor(notificacao, dispatch, fornecedor);
    } else {
      error = await controllerFornecedores.UpdateFornecedor(notificacao, dispatch, fornecedor, fornecedorCopia);
    }

    if (!error) {
      setProximoCadastro(true);
      setTimeout(() => { setProximoCadastro(false); }, 100)
    }
  };

  function Novo(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_FORNECEDOR' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById("nomeRazaoForne").focus()
  };

  function setProximoCadastro(valor) {
    dispatch({ idStore, type: 'PROXIMO_CADASTRO', value: valor });
  }


  return (
    <>
      <Row className="justify-content-center">
      <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

      </div>
        <Col>
          <h4 className="info-text">Preencha com as informações do fornecedor</h4>
          <CardBody>

            <Row className="justify-content-center">

              <Col md="10" className="text-right">
                <Switch
                  componentleft={<label className={fornecedor.status === "0" ? `text-success text-center` : "text-warning text-center"}>{fornecedor.status === "0" ? "Ativo" : "Inativo"}</label>}
                  checked={fornecedor.status === "0"}
                  onChange={e => onChangeAtivo(e)}
                  disabled={fornecedor.codFor === ''}
                />
              </Col>

              <Col md="10">
                <Row>
                  <ButtonGroup>
                    <Button
                      color={fornecedor.tipoFor === "F" ? "info" : ""}
                      type="button"
                      className={classNames("btn-gp-padrao", fornecedor.tipoFor === "F" ? "active" : "")}
                      value="F"
                      onClick={e => onChangeTipoFor(e)}
                      disabled={fornecedor.codFor !== '' && fornecedor.tipoFor === 'J' ? true : false}
                    >
                      Pessoa física
                    </Button>
                    <Button
                      color={fornecedor.tipoFor === "J" ? "info" : ""}
                      type="button"
                      className={classNames("btn-gp-padrao", fornecedor.tipoFor === "J" ? "active" : "")}
                      value="J"
                      onClick={e => onChangeTipoFor(e)}
                      disabled={fornecedor.codFor !== '' && fornecedor.tipoFor === 'F' ? true : false}
                    >
                      Pessoa jurídica
                     </Button>
                  </ButtonGroup>
                </Row>
              </Col>
            </Row>
            <br></br>
            <br></br>


            <Row className="justify-content-center">

              <Col md="10">

                <Row>

                  <Col className="padding-padrao-col" lg md sm="7">
                    <label>{fornecedor.tipoFor === 'F' ? "Nome" : "Razão Social"}<span className="error"> *</span></label>
                    <FormGroup className={invalido.nomeRazao ? "has-danger" : ''}>
                      <Input
                        id="nomeRazaoForne"
                        type="text"
                        value={fornecedor.nomeRazao}
                        onChange={e => onChange(e.target.value, 'nomeRazao')}
                        autoComplete="off"
                        disabled={fornecedor.codFor !== '' ? true : false}
                        maxLength="100"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg md sm="7">
                    <label>{fornecedor.tipoFor === 'F' ? "Apelido" : "Nome Fantasia"}<span className="error"> *</span></label>
                    <FormGroup className={invalido.apelidoFantasia ? "has-danger" : ''}>
                      <Input
                        id="apelidoFantasia"
                        type="text"
                        value={fornecedor.apelidoFantasia}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        disabled={fornecedor.codFor !== '' ? true : false}
                        maxLength="50"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>



                </Row>

                <Row>

                  <Col className="padding-padrao-col" lg="4">
                    <label>{fornecedor.tipoFor === 'F' ? "CPF" : "CNPJ"}<span className="error"> *</span></label>
                    <FormGroup className={invalido.cpfCnpj ? "has-danger" : ''}>
                      <InputCpfCnpj
                        id="cpfCnpj"
                        type="text"
                        value={fornecedor.cpfCnpj}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        disabled={fornecedor.codFor !== '' ? true : false}
                        maxLength="18"
                        tipo={fornecedor.tipoFor === 'J' ? 'cnpj' : 'cpf'}
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg="3" md="4">
                    <label>{fornecedor.tipoFor === 'F' ? "RG" : "Inscrição Estadual"}</label>
                    <FormGroup>
                      <Input
                        id="rgInscestForne"
                        type="text"
                        value={fornecedor.rgInscest}
                        onChange={e => onChange(e.target.value, 'rgInscest')}
                        autoComplete="off"
                        maxLength="20"                     
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg md="4">
                    <label>Contato{" "}</label>
                    <FormGroup>
                      <Input
                        id="contato"
                        type="text"
                        value={fornecedor.contato}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="50"                      
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

                <Row>

                  <Col className="padding-padrao-col" lg md="4">
                    <label>E-mail Primário<span className="error"> *</span></label>
                    <FormGroup className={invalido.email ? "has-danger" : ''}>
                      <Input
                        id="email"
                        type="text"
                        value={fornecedor.email}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="70"                      
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg md="4">
                    <label>E-mail Secundário{" "}</label>
                    <FormGroup className={invalido.email2 ? "has-danger" : ''}>
                      <Input
                        id="email2"
                        type="text"
                        value={fornecedor.email2}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="70"                    
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

                <Row>

                  <Col className="padding-padrao-col" lg md="4">
                    <label>Telefone{" "}</label>
                    <FormGroup>
                      <InputTelefone
                        id="telefone1"
                        type="text"
                        value={fornecedor.telefone1}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="16"                   
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg md="4">
                    <label>Telefone Secundário{" "}</label>
                    <FormGroup>
                      <InputTelefone
                        id="telefone2"
                        type="text"
                        value={fornecedor.telefone2}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="16"                      
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg md="4">
                    <label>Celular{" "}</label>
                    <FormGroup>
                      <InputTelefone
                        id="telefone3"
                        type="text"
                        value={fornecedor.telefone3}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="16"                     
                      />
                      <br></br>
                    </FormGroup>
                  </Col>


                </Row>

                <Row>

                  <Col className="padding-padrao-col" lg md="4">
                    <label>Observação{" "}</label>
                    <FormGroup>
                      <Input
                        id="observacao"
                        type="text"
                        value={fornecedor.observacao}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="70"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

              </Col>

            </Row>

          </CardBody>
          <CardFooter>
            <Col style={{display:"flex", textAlign:'center' }}>
              <ArrowTooltip title='shift+N'>
                <Button  style={{ left:"33%", position:"relative" }} className="btn-padrao" color="default" onClick={(e) => Novo(e)}>Novo</Button>
              </ArrowTooltip>
              <ArrowTooltip title='shift+S'>
                <Button  style={{ left:"33%", position:"relative" }} className="btn-padrao" tipe="button" color="info" onClick={() => { Salvar() }}>Salvar</Button>
              </ArrowTooltip>
            </Col>
          </CardFooter>
        </Col>
      </Row>
    </>
  )
};


class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salvar: false
    };
  }

  focusInput = () => {
    setTimeout(() => {
      if (this.props.fornecedor.codFor === '') {
        document.getElementById('nomeRazaoForne').focus()
      } else {
        document.getElementById('rgInscestForne').focus()
      }
    }, 100)
  }

  isValidated = (step = 0, finish = false) => {
    if (step > 0) {
      return true
    }
    if (!finish) {
      this.setState({ salvar: true }, () => {
        this.setState({ salvar: false })
      })
    }
    return false
  }

  render() {
    return (
      <Fornecedor salvar={this.state.salvar} />
    )
  }
}

export default Wizard;
