import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import store from '../../store/stores';
import { Sum, Round, ToNumber } from '../../Util/numbers';
import { maskMoeda, maskValor } from '../../Util/mascaras';
import * as actionFaturamento from '../../store/reducers/Modals/Faturamento/actions'
import { removeMaskMoney } from '../../Util/removeMascaras';

const idStore = 'ENTRADA_MERCADORIA';

export async function GetEntradas(pesquisa) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()

    try {
        dispatch({ idStore, type: "ENTRADAS", value: [] })

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let numeroDocumento = Number(pesquisa.numDocumento)
        let tipoData = Number(pesquisa.tipoData)


        if (!pesquisa.dataInicio) {
            return alertar(notificacao, 'br', "Data de Inicio não informada", 'warning', 'icon-alert-circle-exc', 5);
        }
        if (!pesquisa.dataFim) {
            return alertar(notificacao, 'br', "Data Fim não informada", 'warning', 'icon-alert-circle-exc', 5);
        }

        if (pesquisa.codFilial.length === 0) {
            return alertar(notificacao, 'br', "Empresa/Filial não informada", 'warning', 'icon-alert-circle-exc', 5);
        }

        await axios.get(api.urlMovimento + `api/v1/Entrada?CodigoEmpresaFilial=${pesquisa.codFilial}&NumeroDocumento=${numeroDocumento}&CodigoFornecedor=${pesquisa.codForne}&DataInicio=${pesquisa.dataInicio}&DataFim=${pesquisa.dataFim}&tipoData=${tipoData}&Status=0`, {
            headers: { Authorization: bearerAuth }
        }).then(response => {
            if (response.data.data.length === 0) {
                alertar(notificacao, 'br', "Nenhuma entrada encontrada", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: "ENTRADAS", value: response.data.data })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as entradas", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetEntrada(numeroEntrada) {
    const { notificacao } = store.getState()
    const dispatch = store.dispatch

    try {
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

        await axios.get(api.urlMovimento + `api/v1/Entrada/${numeroEntrada}`, {
            headers: { Authorization: bearerAuth }
        }).then(response => {
            const { data } = response.data;
            dispatch({
                idStore,
                type: "ENTRADA",
                value: {
                    dadosIniciais: {
                        numEntrada: data.numeroEntrada,
                        numDoc: data.numeroDocumento,
                        dataEntrada: data.dataEntrada,
                        dataDocumento: data.dataDocumento,
                        operacao: {
                            codOperacao: data.codigoOperacao,
                            numeroOperacao: data.numeroOperacao,
                            descricaoOperacao: data.descricaoOperacao,
                            geraFinanceiro: data.flGerarFinanceiro
                        },
                        fornecedor: {
                            codFor: data.codigoFornecedor,
                            nomeRazao: data.nomeFornecedor,
                            cpfCnpj: data.cpfCnpjFornecedor,
                            rgInscest: data.rgInscEstFornecedor
                        },
                        transportadora: {
                            codTransp: data.codigoTransportadora,
                            nomeRazao: data.nomeTransportadora,
                            cpfCnpj: data.cpfCnpjTransportadora,
                            rgInscest: data.rgInscEstTransportadora
                        },
                    },
                    itens: {
                        produto: {
                            codProdutoItem: '',
                            gradesSelected: [],
                            codProduto: '',
                            numProduto: '',
                            codBarras: '',
                            nome: '',
                            quantidade: '',
                            valorUnitario: '',
                            valorTotal: 0,
                            unidade: '',
                            codGrade: '',
                            controlaGrade: '',
                        }
                    },
                    valoresTotais: {
                        subTotal: data.valorSubTotal,
                        acrescimo: data.valorAcrescimo,
                        desconto: data.valorDesconto,
                        valorTotal: data.valorTotal,
                        impostos: {
                            BcICMS: 0,
                            ICMS: 0,
                            ICMSST: 0,
                            IPI: 0,
                            PIS: 0,
                            COFINS: 0,
                            COFINSST: 0,
                        },
                    },
                    listGradeCopia: []
                }
            })

            let produtos = []
            let subTotal = 0
            let codItem = 0
            data.itens.forEach((item) => {
                codItem += 1
                produtos.push({
                    codProdutoItem: codItem,
                    codProduto: item.codigoProduto,
                    codBarras: item.codigoBarras,
                    sequencialItem: item.sequencialItem,
                    numProduto: item.numeroProduto,
                    nome: item.descricao,
                    quantidade: Number(item.quantidade),
                    controlaGrade: item.tipoGrade,
                    tipoGrade: item.tipoGrade,
                    valorUnitario: maskMoeda(Number(item.valorUnitario).toFixed(2)),
                    valorTotal: maskMoeda(Number(item.valorTotal).toFixed(2)),
                    unidade: item.unidade,
                    gradesSelected: item.itemGrade
                })
                if (item.valorTotal) {
                    let valor = Number(item.valorTotal.toString().replace('.', ''))
                    valor = Number(item.valorTotal.toString().replace(',', '.'))
                    subTotal = Round(Sum([subTotal, valor]), 2)
                }
            })

            dispatch({ idStore, type: "PRODUTO_SELECIONADO", value: produtos })
            dispatch({ idStore, type: "PRODUTOS_COPIA", value: produtos })

            dispatch({
                idStore, type: "VALORES_TOTAIS", value: {
                    subTotal: subTotal,
                    acrescimo: maskMoeda(data.valorAcrescimo),
                    desconto: maskMoeda(data.valorDesconto),
                    valorTotal: 0,
                    impostos: {
                        BcICMS: 0,
                        ICMS: 0,
                        ICMSST: 0,
                        IPI: 0,
                        PIS: 0,
                        COFINS: 0,
                        COFINSST: 0,
                    }
                }
            })

            if (data.Duplicata) {
                let duplicatas = []

                data.duplicata.forEach((duplicata) => {
                    duplicatas.push({
                        dataVencimento: duplicata.dataVencimento,
                        numeroDuplicata: duplicata.numeroDuplicata,
                        numeroEntrada: duplicata.numeroEntrada,
                        numeroParcela: duplicata.numeroParcela,
                        valor: duplicata.valor
                    })
                })

                dispatch({ idStore, type: "DUPLICATAS", value: duplicatas })
            }
            dispatch({ idStore, type: 'TELA', value: 1 })
            dispatch({ idStore, type: "ENTRADA_COPIA" })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 4, e)
        });
    } catch (error) {
        alertar(notificacao, 'br', "Não foi possível editar Entrada de Mercadoria", 'warning', 'icon-alert-circle-exc', 4)
    }
}

export function GetGrade(produto) {
    const { notificacao } = store.getState()
    const dispatch = store.dispatch
    try {

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios.get(api.urlCadastro + `api/v1/produto/grades?codProduto=${produto.codProduto}&codTipoGrade=${produto.tipoGrade}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            let listGrade = []
            let soma = 0

            if (response.data.records.grades) {
                listGrade = response.data.records.grades.map((grade) => { return { ...grade, invalido: false } })

            }

            dispatch({ idStore, type: "GRADE", value: listGrade })
            listGrade.forEach((grade => {
                if (grade.status === 0) {
                    soma += grade.qtdEstoqueGrade
                }
            }))
            dispatch({ idStore, type: 'TOTAL_INVENTARIO', value: soma + Number(produto.quantidade) })

        })

    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel obter as grades", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function SalvarEntrada() {
    const dispatch = store.dispatch
    const { notificacao, entradaMercadoria: { produtosSelected, cadastro: { dadosIniciais, itens } } } = store.getState()
    if (!dadosIniciais.dataEntrada) {
        dispatch({ idStore, type: 'WIZARD_STEP', value: 0 })
        return alertar(notificacao, 'br', "Data da Entrada não informada", 'warning', 'icon-alert-circle-exc', 5);
    }
    if (!dadosIniciais.operacao.codOperacao) {
        dispatch({ idStore, type: 'WIZARD_STEP', value: 0 })
        return alertar(notificacao, 'br', "Operação não informada", 'warning', 'icon-alert-circle-exc', 5);
    }
    if (!dadosIniciais.fornecedor.cpfCnpj) {
        dispatch({ idStore, type: 'WIZARD_STEP', value: 0 })
        dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'INVALIDO', campo: 'codFor', value: true })
        return alertar(notificacao, 'br', "CPF / CNPJ fornecedor inválido", 'warning', 'icon-alert-circle-exc', 5);
    }
    if (produtosSelected === null) {
        dispatch({ idStore, type: 'WIZARD_STEP', value: 1 })
        return alertar(notificacao, 'br', "Nenhum produto selecionado", 'warning', 'icon-alert-circle-exc', 5);
    }
    let listProdutos = []
    let duplicata = []
    let produtosString = JSON.stringify(produtosSelected);
    let produtosJSON = JSON.parse(produtosString)
    produtosJSON.forEach(produto => {
        var ListGrade = []
        produto.gradesSelected.forEach(grade => {
            ListGrade.push({
                SequencialItemGrade: grade.sequencialItemGrade,
                CodigoGrade: grade.codGrade,
                QuantidadeGrade: grade.qtdEstoqueGrade
            })
        })
        produto.valorUnitario = produto.valorUnitario.replace('.', '')
        produto.valorUnitario = produto.valorUnitario.replace(',', '.')
        produto.valorTotal = produto.valorTotal.replace('.', '')
        produto.valorTotal = produto.valorTotal.replace(',', '.')
        listProdutos.push({
            numeroEntrada: ToNumber(dadosIniciais.numEntrada),
            CodigoProduto: ToNumber(produto.codProduto),
            quantidade: ToNumber(produto.quantidade),
            valorUnitario: Number(produto.valorUnitario),
            valorTotal: Number(produto.valorTotal),
            unidade: '',
            listGrade: '',
            itemGrade: ListGrade
        });
    })
    itens.valoresTotais.subTotal = itens.valoresTotais.subTotal.toString().replace('.', '')
    itens.valoresTotais.subTotal = itens.valoresTotais.subTotal.toString().replace(',', '.')
    let objEntrada = {
        NumeroDocumento: dadosIniciais.numDoc,
        DataDocumento: dadosIniciais.dataDocumento,
        DataEntrada: dadosIniciais.dataEntrada,
        CodigoOperacao: parseInt(dadosIniciais.operacao.codOperacao, 10),
        CodigoFornecedor: parseInt(dadosIniciais.fornecedor.codFor, 10),
        CodigoTransportadora: parseInt(dadosIniciais.transportadora.codTransp, 10),
        Item: listProdutos,
        ValorAcrescimo: itens.valoresTotais.acrescimo,
        ValorDesconto: itens.valoresTotais.desconto,
        ValorSubTotal: parseFloat(itens.valoresTotais.subTotal),
        ValorTotal: parseFloat(itens.valoresTotais.valorTotal),
        Duplicata: duplicata,
        InformacoesAdicionais: dadosIniciais.informacoesAdicionais,
    }

    try {
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        await axios.post(api.urlMovimento + `api/v1/Entrada`, objEntrada, {
            headers: { Authorization: bearerAuth }
        }).then(response => {
            alertar(notificacao, 'br', "Entrada realizada com sucesso", 'success', 'icon-alert-circle-exc', 2)
            dispatch({ idStore, type: "ENTRADA_SALVAR", value: response.data.data.numeroEntrada })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 4, e)
        });
    } catch (error) {
        alertar(notificacao, 'br', "Não foi possível cadastrar a Entrada de Mercadoria", 'warning', 'icon-alert-circle-exc', 4)
        return "false"
    }
}

export function FinalizarEntrada(toggleFaturar) {
    const dispatch = store.dispatch
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
    const { notificacao, entradaMercadoria: { produtosInseridos, entradaCopia, cadastro, cadastro: { itens: { valoresTotais }, dadosIniciais } }, modalFaturamento: { totais } } = store.getState()
    if (!dadosIniciais.numEntrada) {
        return alertar(notificacao, 'br', "Não é possível finalizar uma entrada sem antes salva-la", 'warning', 'icon-alert-circle-exc', 4)
    }
    if (entradaCopia.length > 0 && JSON.stringify(cadastro.dadosIniciais) !== JSON.stringify(entradaCopia.dadosIniciais) && JSON.stringify(cadastro.itens.produto) !== JSON.stringify(entradaCopia.itens.produto)) {
        let retorno = SalvarEntrada()
        if (!retorno) return
    }

    if (cadastro.dadosIniciais.operacao.geraFinanceiro === 0 || cadastro.dadosIniciais.operacao.geraFinanceiro === false) {
        return axios.put(`${api.urlMovimento}api/v1/Entrada/Finalizar/${dadosIniciais.numEntrada}`, {}, {
            headers: { Authorization: bearerAuth }
        }).then(response => {
            alertar(notificacao, 'br', "Entrada Faturada com sucesso", 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: "CLEAN_CADASTRO" })
            dispatch({ idStore, type: 'TELA', value: 1 })
        }).catch(e => {
            if (e.response) {
                return alertar(notificacao, 'br', e.response.data.message, 'warning', 'icon-alert-circle-exc', 2)
            }
            alertar(notificacao, 'br', 'Não foi possível finalizar a entrada', 'warning', 'icon-alert-circle-exc', 2)
        })
    } else {
        if(produtosInseridos.length > 0) {
            let editSubTotal = produtosInseridos.map(i => Number(removeMaskMoney(i.valorUnitario)) * Number(i.quantidade))
            let reduceEditSubTotal = editSubTotal.reduce((itemValue, value) => {
                return Number(itemValue) + Number(value)
            }, 0)
            let editTotal = produtosInseridos.map(i => Number(i.valorTotal))
            let reduceEditTotal = editTotal.reduce((itemValue, value) => {
                return Number(itemValue) + Number(value)
            }, 0)
            actionFaturamento.setTotais({ ...totais, subTotal: maskMoeda(Number(reduceEditSubTotal).toFixed(2)), total: reduceEditTotal })
        } else {
            actionFaturamento.setTotais({ ...totais, subTotal: maskMoeda(valoresTotais.subTotal), descontos: maskMoeda(valoresTotais.desconto), total: maskMoeda(valoresTotais.valorTotal) })
            actionFaturamento.setInformacoes({
                numeroPedido: ToNumber(dadosIniciais.numEntrada),
                codigoConta: 0,
                codigoGrupoConta: 0,
            })
        }

        toggleFaturar()
        return
    }
}
export function UpdateEntrada() {
    const dispatch = store.dispatch
    const { notificacao, entradaMercadoria: { cadastro, entradaCopia, produtosSelected, itensEditar, itensExcluir, itensInserir, cadastro: { dadosIniciais, itens } } } = store.getState()
    if (!dadosIniciais.dataEntrada) {
        dispatch({ idStore, type: 'WIZARD_STEP', value: 0 })
        return alertar(notificacao, 'br', "Data da Entrada não informada", 'warning', 'icon-alert-circle-exc', 5);
    }
    if (!dadosIniciais.operacao.codOperacao) {
        dispatch({ idStore, type: 'WIZARD_STEP', value: 0 })
        return alertar(notificacao, 'br', "Operação não informada", 'warning', 'icon-alert-circle-exc', 5);
    }
    if (!dadosIniciais.fornecedor.cpfCnpj) {
        dispatch({ idStore, type: 'WIZARD_STEP', value: 0 })
        dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'INVALIDO', campo: 'codFor', value: true })
        return alertar(notificacao, 'br', "CPF / CNPJ fornecedor inválido", 'warning', 'icon-alert-circle-exc', 5);
    }
    if (!dadosIniciais.transportadora.cpfCnpj) {
        dispatch({ idStore, type: 'WIZARD_STEP', value: 0 })
        dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'INVALIDO', campo: 'codTransp', value: true })
        return alertar(notificacao, 'br', "CPF / CNPJ transportadora inválido", 'warning', 'icon-alert-circle-exc', 5);
    }
    if (produtosSelected === null) {
        dispatch({ idStore, type: 'WIZARD_STEP', value: 1 })
        return alertar(notificacao, 'br', "Nenhum produto selecionado", 'warning', 'icon-alert-circle-exc', 5);
    }
    if (JSON.stringify(cadastro.dadosIniciais) === JSON.stringify(entradaCopia.dadosIniciais) && JSON.stringify(cadastro.itens.produto) === JSON.stringify(entradaCopia.itens.produto)) {
        alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
        return true
    }
    let duplicata = []

    itensEditar.forEach((item) => {
        let strvalorTotal = item.valorTotal.toString()

        strvalorTotal = strvalorTotal.replace('.', '')
        strvalorTotal = item.valorTotal.toString().replace(',', '.')

        item.valorTotal = Number(strvalorTotal)

        let strvalorUnitario = item.valorUnitario.toString()

        strvalorUnitario = strvalorUnitario.replace('.', '')
        strvalorUnitario = strvalorUnitario.replace(',', '.')

        item.valorUnitario = Number(strvalorUnitario)
    })
    itensExcluir.forEach((item) => {
        let strvalorTotal = item.valorTotal.toString()

        strvalorTotal = strvalorTotal.replace('.', '')
        strvalorTotal = item.valorTotal.toString().replace(',', '.')

        item.valorTotal = Number(strvalorTotal)

        let strvalorUnitario = item.valorUnitario.toString()

        strvalorUnitario = strvalorUnitario.replace('.', '')
        strvalorUnitario = strvalorUnitario.replace(',', '.')

        item.valorUnitario = Number(strvalorUnitario)
    })
    itensInserir.forEach((item) => {
        let strvalorTotal = item.valorTotal.toString()

        strvalorTotal = strvalorTotal.replace('.', '')
        strvalorTotal = item.valorTotal.toString().replace(',', '.')

        item.valorTotal = Number(strvalorTotal)

        let strvalorUnitario = item.valorUnitario.toString()

        strvalorUnitario = strvalorUnitario.replace('.', '')
        strvalorUnitario = strvalorUnitario.replace(',', '.')

        item.valorUnitario = Number(strvalorUnitario)

    })

    let acrescimo = itens.valoresTotais.acrescimo.toString().replace('.', '')
    let desconto = itens.valoresTotais.desconto.toString().replace('.', '')
    let subTotal = itens.valoresTotais.subTotal.toString().replace('.', '')
    let valorTotal = itens.valoresTotais.valorTotal.toString().replace(',', '.')
    acrescimo = itens.valoresTotais.acrescimo.toString().replace(',', '.')
    desconto = itens.valoresTotais.desconto.toString().replace(',', '.')
    subTotal = itens.valoresTotais.subTotal.toString().replace(',', '.')
    valorTotal = itens.valoresTotais.valorTotal.toString().replace(',', '.')
    let objEntrada = {
        NumeroDocumento: dadosIniciais.numDoc,
        NumeroEntrada: dadosIniciais.numEntrada,
        DataDocumento: dadosIniciais.dataDocumento,
        DataEntrada: dadosIniciais.dataEntrada,
        CodigoOperacao: parseInt(dadosIniciais.operacao.codOperacao, 10),
        CodigoFornecedor: parseInt(dadosIniciais.fornecedor.codFor, 10),
        CodigoTransportadora: parseInt(dadosIniciais.transportadora.codTransp, 10),
        ItensEditar: itensEditar,
        ItensInserir: itensInserir,
        ItensExcluir: itensExcluir,
        ValorAcrescimo: Number(acrescimo),
        ValorDesconto: Number(desconto),
        ValorSubTotal: Number(subTotal),
        ValorTotal: Number(valorTotal),
        Duplicata: duplicata,
        InformacoesAdicionais: dadosIniciais.informacoesAdicionais,
    }
    try {
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        axios.put(api.urlMovimento + `api/v1/Entrada`, objEntrada, {
            headers: { Authorization: bearerAuth }
        }).then(() => {
            alertar(notificacao, 'br', "Entrada atualizada com sucesso", 'success', 'icon-alert-circle-exc', 2)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 4, e)
        });
    } catch (error) {
        alertar(notificacao, 'br', "Não foi possível atualizar a Entrada de Mercadoria", 'warning', 'icon-alert-circle-exc', 4)
    }
}
export function ExcluirEntrada(numeroEntrada, thenFunction) {
    const { notificacao } = store.getState()
    try {
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

        axios.delete(api.urlMovimento + `api/v1/Entrada/${numeroEntrada}`, {
            headers: { Authorization: bearerAuth }
        }).then(() => {
            alertar(notificacao, 'br', 'Entrada excluída com sucesso', 'success', 'icon-check-2', 4)
            thenFunction()

            const { pesquisa } = store.getState().entradaMercadoria
            GetEntradas(pesquisa)

        }).catch(e => {
            thenFunction()
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 4, e)
        });
    } catch (error) {
        thenFunction()
        alertar(notificacao, 'br', "Não foi possível excluir Sáida Diversa", 'warning', 'icon-alert-circle-exc', 4)
    }
}

export function GetFornecedores(pesquisaFornecedor) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        dispatch({ idStore, type: "FORNECEDORES", value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/produto/fornecedorProduto?nomeRazao=${pesquisaFornecedor.nomeRazao}&cpfCnjj=${pesquisaFornecedor.cpfCnpj}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum fornecedor encontrado", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: "FORNECEDORES", value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os fornecedores ", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetFornecedor(codFor, theFunction) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/fornecedor/fornecedor?codFor=${codFor}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            let { codFor, nomeRazao, cpfCnpj, rgInscest } = response.data.records
            dispatch({
                idStore,
                type: 'FORNECEDOR',
                value: {
                    codFor: codFor,
                    nomeRazao: nomeRazao,
                    cpfCnpj: cpfCnpj,
                    rgInscest: rgInscest
                }
            })

            theFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2);
    }
}

export async function GetPessoaFornecedor(codFor, theFunction) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/fornecedor/fornecedor?codFor=${codFor}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            dispatch({ idStore, type: 'PESQUISA', campo: 'codForne', value: response.data.records.codFor })
            dispatch({ idStore, type: 'PESQUISA', campo: 'fornecedor', value: response.data.records.nomeRazao })
            dispatch({ idStore, type: 'PESQUISA', campo: 'cpfCnpj', value: response.data.records.cpfCnpj })
            theFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2);
    }
}

export async function GetFornecedoreKeyPress(pesquisaFornecedor) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        dispatch({ idStore, type: "FORNECEDORES", value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        return await axios.get(api.urlCadastro + `api/v1/produto/fornecedorProduto?nomeRazao=${pesquisaFornecedor.nomeRazao}&cpfCnpj=${pesquisaFornecedor.cpfCnpj}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {

            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum fornecedor encontrado", 'info', 'icon-bulb-63', 2)
                return null
            }

            let { codFor, nomeRazao, cpfCnpj, rgInscEst } = response.data.records[0]
            dispatch({
                idStore,
                type: 'FORNECEDOR',
                value: {
                    codFor: codFor,
                    nomeRazao: nomeRazao,
                    cpfCnpj: cpfCnpj,
                    rgInscest: rgInscEst
                }
            })

            return codFor

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return null
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return null
    }
}

export function GetTransportadoras(pesquisa) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        dispatch({ idStore, type: 'TRANSPORTADORAS', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/transportadora/transportadoras?nomeRazao=${pesquisa.nomeRazao}&cpfCnpj=${pesquisa.cpfCnpj}`, {
            headers: { Authorization: basicAuth }

        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma transportadora encontrada", 'info', 'icon-bulb-63', 2)
            }

            dispatch({ idStore, type: 'TRANSPORTADORAS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as transportadoras", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetTransportadora(codTrans, thenFunction) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/transportadora/transportadora?codTransportadora=${codTrans}`,
            {
                headers: { Authorization: basicAuth }

            }).then(function (response) {
                let { codTransportadora, nomeRazao, cpfCnpj, rgInscEst } = response.data.records

                dispatch({
                    idStore,
                    type: 'TRANSPORTADORA',
                    value: {
                        codTransp: codTransportadora,
                        nomeRazao: nomeRazao,
                        cpfCnpj: cpfCnpj,
                        rgInscest: rgInscEst
                    }
                })

                thenFunction()
            }).catch(e => {
                alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
            });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2);
    }
}

export async function GetTransportadoraKeyPress(transportadora) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        return await axios.get(api.urlCadastro + `api/v1/transportadora/transportadoras?cpfCnpj=${transportadora.cpfCnpj}`,
            {
                headers: { Authorization: basicAuth }

            }).then(function (response) {

                if (response.data.records.length === 0) {
                    alertar(notificacao, 'br', "Nenhuma transportadora encontrado", 'info', 'icon-bulb-63', 2)
                    return null
                }

                let { codTransportadora, nomeRazao, cpfCnpj, rgInscEst } = response.data.records[0]

                dispatch({
                    idStore,
                    type: 'TRANSPORTADORA',
                    value: {
                        codTransp: codTransportadora,
                        nomeRazao: nomeRazao,
                        cpfCnpj: cpfCnpj,
                        rgInscest: rgInscEst
                    }
                })

                return codTransportadora

            }).catch(e => {
                alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
            });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2);
    }
}

export function GetProdutos(pesquisa) {

    const { notificacao } = store.getState()
    const dispatch = store.dispatch
    try {

        dispatch({ idStore, type: 'PRODUTOS', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        pesquisa.numProduto = pesquisa.numProduto.trim();
        axios.get(api.urlCadastro + `api/v1/produto/produtos?numProduto=${pesquisa.numProduto}&nome=${pesquisa.nome}&tipo=P`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum produto encontrado", 'info', 'icon-bulb-63', 2)
            }

            response.data.records.forEach((produto, index) => {
                response.data.records[index].dtCadastro = new Date(produto.dtCadastro).toLocaleString();
            });

            dispatch({ idStore, type: 'PRODUTOS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os produtos", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetProduto(codProduto, thenFunction) {

    const { notificacao } = store.getState()
    const dispatch = store.dispatch
    try {

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios.get(api.urlCadastro + `api/v1/produto/produto?codProduto=${codProduto}`, {
            headers: { Authorization: basicAuth }
        }).then(async response => {
            let { codBarras, nome, precoVendaAtual, unidade, flControlaGrade, codTipoGrade, numProduto } = response.data.records
            dispatch({
                idStore,
                type: 'PRODUTO',
                value: {
                    codProdutoItem: '',
                    codProduto: codProduto,
                    codBarras: codBarras,
                    numProduto: numProduto,
                    nome: nome,
                    valorUnitario: maskValor(precoVendaAtual.toFixed(2)),
                    unidade: unidade,
                    quantidade: 1,
                    controlaGrade: flControlaGrade,
                    tipoGrade: codTipoGrade,
                    valorTotal: precoVendaAtual,
                    gradesSelected: [],
                    editarProduto: false
                }
            })
            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : null, 'warning', 'icon-alert-circle-exc', 2, e)
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetProdutosKeyPress(produto) {

    const { notificacao } = store.getState()
    const dispatch = store.dispatch
    try {

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        produto.codBarras = produto.codBarras.trim();
        return await axios.get(api.urlCadastro + `api/v1/produto/produtos?numProduto=${produto.codBarras}&tipo=P`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum produto encontrado", 'info', 'icon-bulb-63', 2)
                return null
            }
            response.data.records.forEach((produto, index) => {
                response.data.records[index].dtCadastro = new Date(produto.dtCadastro).toLocaleString();
            });

            let { codProduto, codBarras, nome, unidade, precoVendaAtual, numProduto, flControlaGrade, codTipoGrade } = response.data.records[0]

            dispatch({
                idStore,
                type: 'PRODUTO',
                value: {
                    codProdutoItem: '',
                    codProduto: codProduto,
                    codBarras: codBarras,
                    numProduto: numProduto,
                    nome: nome,
                    valorUnitario: precoVendaAtual.toFixed(2),
                    unidade: unidade,
                    quantidade: 1,
                    controlaGrade: flControlaGrade === 0 ? "" : flControlaGrade,
                    tipoGrade: codTipoGrade === 0 ? "" : codTipoGrade,
                    valorTotal: precoVendaAtual,
                    gradesSelected: [],
                    editarProduto: false
                }
            })
            return codProduto

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function addProdutos(produto) {
    const { notificacao } = store.getState()
    const { entradaMercadoria: { produtosSelected, itensEditar, itensExcluir, itensInserir } } = store.getState()
    const { valoresTotais } = store.getState().entradaMercadoria.cadastro.itens
    const dispatch = store.dispatch

    let newProduto = {
        codProdutoItem: '',
        codProduto: '',
        numProduto: '',
        codBarras: '',
        nome: '',
        quantidade: '',
        valorUnitario: '',
        valorTotal: '',
        unidade: '',
        gradesSelected: [],
        editarProduto: false,
    }

    if (!produto.codBarras && !produto.numProduto) {
        dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'INVALIDO', campo: 'codBarras', value: true })
        alertar(notificacao, 'br', "Número / Código de Barras não informado", 'warning', 'icon-alert-circle-exc', 2)
        return 'codBarras'
    }

    if (!produto.nome) {
        alertar(notificacao, 'br', "Produto não informado", 'warning', 'icon-alert-circle-exc', 2);
        return 'nome'
    }

    if (Number(produto.quantidade) < 1) {
        if (Number(produto.quantidade) === "0") {
            alertar(notificacao, 'br', "Quantidade com valor zerado", 'warning', 'icon-alert-circle-exc', 2)
            return 'quantidade'
        }
        else {
            alertar(notificacao, 'br', "Quantidade não informado", 'warning', 'icon-alert-circle-exc', 2)
            return 'quantidade'
        }
    }

    if (!produto.valorUnitario) {
        alertar(notificacao, 'br', "Valor Unitário não informado", 'warning', 'icon-alert-circle-exc', 2)
        return 'valorUnitario'
    }
    if (produtosSelected.length === 0) {
        if (produto.controlaGrade === 1 && produto.tipoGrade) {
            GetGrade(produto)
        } else if (produto.controlaGrade === 1 && !produto.tipoGrade) {
            alertar(notificacao, 'br', "Tipo da Grade Não cadastrada", 'warning', 'icon-alert-circle-exc', 2)
            return 'grade'
        } else {
            InsereProduto(produto)
        }
    } else if (produtosSelected.length > 0 && (produto.controlaGrade === "" || produto.controlaGrade === 0)) {
        let produtosSelectedString = JSON.stringify(produtosSelected);
        let produtosJSON = JSON.parse(produtosSelectedString)
        var arrProdutos = []
        var agrupa = 0
        produtosJSON.forEach((produtoSelected) => {
            if (produtoSelected.codProduto === Number(produto.codProduto)) {

                let valorTotalString = JSON.stringify(valoresTotais);
                let valorTotalJSON = JSON.parse(valorTotalString)

                let valorProduto = Number(produto.valorTotal.replace(",", "."))
                let valorProdutoSelected = Number(produtoSelected.valorTotal.replace(",", "."))
                let valorSubtotal = 0
                if (produto.editarProduto) {
                    if (valorProduto > valorProdutoSelected) {

                        valorSubtotal = Number(valorTotalJSON.subTotal.replace(",", ".")) - valorProdutoSelected
                        valorTotalJSON.subTotal = Sum([valorSubtotal, valorProduto]).toFixed(2)

                    } else if (valorProdutoSelected > valorProduto) {

                        valorSubtotal = Number(valorTotalJSON.subTotal.replace(",", ".")) - valorProdutoSelected
                        valorSubtotal = valorSubtotal + valorProduto
                        valorTotalJSON.subTotal = valorSubtotal.toFixed(2)

                    }
                } else {
                    valorTotalJSON.subTotal = Sum([Number(valorTotalJSON.subTotal.replace(",", ".")), valorProduto]).toFixed(2)
                }

                dispatch({
                    idStore,
                    type: 'VALORES_TOTAIS',
                    value: valorTotalJSON
                })
            }
            if (produtoSelected.codProduto === Number(produto.codProduto) && produtoSelected.valorUnitario === produto.valorUnitario && produto.editarProduto === false) {
                agrupa = 1
                produtoSelected.valorTotal = produtoSelected.valorTotal.toString().replace(',', '.')
                produto.valorTotal = produto.valorTotal.toString().replace(',', '.')
                produtoSelected.valorTotal = Number(produtoSelected.valorTotal)
                produtoSelected.valorTotal += Number(produto.valorTotal)
                produtoSelected.valorTotal = maskMoeda(produtoSelected.valorTotal.toFixed(2))

                produtoSelected.quantidade = Number(produtoSelected.quantidade)
                produtoSelected.quantidade += Number(produto.quantidade)

            }
            arrProdutos.push(produtoSelected)
            if (agrupa === 1 || produto.editarProduto === true) {
                itensExcluir.forEach((item, i) => {
                    if (item.codProduto === produto.codProduto) {
                        itensExcluir.splice(i, 1);
                    }
                })

                let editarList = JSON.stringify(itensEditar);
                let Editar = JSON.parse(editarList);
                let contem = Editar.findIndex((() => produto.codProduto))
                contem === -1 ? Editar.push(produto) : Editar[contem] = produto

                let editarObj = []
                Editar.forEach((item) => {
                    editarObj.push({
                        codigoProduto: item.codProduto,
                        itemGrade: item.gradesSelected,
                        quantidade: item.quantidade,
                        sequencialItem: item.sequencialItem,
                        valorUnitario: item.valorUnitario,
                        valorTotal: item.valorTotal
                    })
                })

                dispatch({
                    idStore: 'ENTRADA_MERCADORIA',
                    type: 'PRODUTOS_UPDATE',
                    campo: 'itensEditar',
                    value: editarObj
                })
            } else {
                itensExcluir.forEach((item, i) => {
                    if (item.codProduto === produto.codProduto) {
                        itensExcluir.splice(i, 1);
                    }
                })
                let inserirList = JSON.stringify(itensInserir);
                let Inserir = JSON.parse(inserirList);
                let contem = Inserir.findIndex(() => produto.codProduto)
                contem === -1 ? Inserir.push(produto) : Inserir[contem] = produto


                let inserirObj = []
                Inserir.forEach((item) => {
                    inserirObj.push({
                        codigoProduto: item.codProduto,
                        itemGrade: item.gradesSelected,
                        quantidade: item.quantidade,
                        sequencialItem: item.sequencialItem,
                        valorUnitario: item.valorUnitario,
                        valorTotal: item.valorTotal
                    })
                })

                dispatch({
                    idStore: 'ENTRADA_MERCADORIA',
                    type: 'PRODUTOS_UPDATE',
                    campo: 'itensInserir',
                    value: inserirObj
                })
            }

        })
        if (agrupa === 1) {
            dispatch({ idStore, type: 'PRODUTO_SELECIONADO', value: arrProdutos })
            dispatch({ idStore, type: 'PRODUTO', value: newProduto })
        } else {
            InsereProduto(produto)
        }
    } else if (produtosSelected.length > 0 && produto.controlaGrade === 1) {
        GetGrade(produto)
    }
}

export function InsereProduto(produto) {
    const dispatch = store.dispatch
    const { valoresTotais } = store.getState().entradaMercadoria.cadastro.itens
    const { entradaMercadoria: { produtosSelected } } = store.getState()

    let produtosSelectedString = JSON.stringify(produtosSelected);
    let produtosJSON = JSON.parse(produtosSelectedString)

    let valorTotalString = JSON.stringify(valoresTotais);
    let valorTotalJSON = JSON.parse(valorTotalString)

    if (produto.codProdutoItem === "") {
        produto.codProdutoItem = produtosSelected.length + 1

        dispatch({
            idStore,
            type: 'PRODUTO_SELECIONADO',
            value: [...produtosSelected, produto]
        })

        valorTotalJSON.subTotal = Round(Sum([valorTotalJSON.subTotal, produto.valorTotal.replace(",", ".")]), 2)

        dispatch({
            idStore,
            type: 'VALORES_TOTAIS',
            value: valorTotalJSON
        })

    } else {

        let index = produtosJSON.findIndex(p => p.codProdutoItem === produto.codProdutoItem)

        if (index < 0) {
            produto.codProdutoItem = produtosSelected.length + 1
            produto.valorUnitario = Number(produto.valorUnitario)

            dispatch({
                idStore,
                type: 'PRODUTO_SELECIONADO',
                value: [...produtosSelected, produto]
            })

            valorTotalJSON.subTotal = Round(Sum([valorTotalJSON.subTotal, produto.valorTotal.replace(",", ".")]), 2)

            dispatch({
                idStore,
                type: 'VALORES_TOTAIS',
                value: valorTotalJSON
            })

        } else {

            produtosJSON[index].codProduto = produto.codProduto
            produtosJSON[index].codBarras = produto.codBarras
            produtosJSON[index].nome = produto.nome
            produtosJSON[index].quantidade = produto.quantidade
            produtosJSON[index].valorUnitario = produto.valorUnitario
            produtosJSON[index].valorTotal = produto.valorTotal
            produtosJSON[index].unidade = produto.unidade
            produtosJSON[index].gradesSelected = produto.gradesSelected

            dispatch({
                idStore,
                type: 'PRODUTO_SELECIONADO',
                value: produtosJSON
            })
        }
    }
    let newProduto = {
        codProdutoItem: '',
        codProduto: '',
        numProduto: '',
        codBarras: '',
        nome: '',
        quantidade: '',
        valorUnitario: '',
        valorTotal: '',
        unidade: '',
        gradesSelected: [],
        editarProduto: false,
    }

    dispatch({
        idStore,
        type: 'PRODUTO',
        value: newProduto
    })

}
export async function GetOperacoes(operacao) {

    const { notificacao } = store.getState()
    const dispatch = store.dispatch
    try {
        dispatch({ idStore, type: 'OPERACOES', value: [] })
        let numOperacao = operacao.numeroOperacao === 0 ? '' : operacao.numeroOperacao

        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios.get(api.urlCadastro + `api/v1/operacao/operacoes?Numero=${numOperacao}&Descricao=${operacao.descricaoOperacao}&Tipo=E&TipoOperacao=ENT`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma Operação Encontrada", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'OPERACOES', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetEmpresasUser() {
    const { notificacao } = store.getState()
    const dispatch = store.dispatch

    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios.get(api.urlCadastro + `api/v1/empresa/empresasUsuario`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'EMPRESAS', value: response.data.records })
        }).catch(e => {
            return
        });
    }
    catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}
