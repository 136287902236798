import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import store from '~/store/stores';


const idStore = 'SUBGRUPO_CONTAS';

export function Pesquisar(pesquisa) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        dispatch({ idStore, type: 'SUBGRUPO_CONTAS', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/subGrupoConta/subGrupoContas?status=${pesquisa.ativos}&descricao=${pesquisa.descricao}&codGrupoConta=${pesquisa.codGrupoConta}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum subgrupo de conta encontrado", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'SUBGRUPO_CONTAS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os subgrupos de conta", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetSubgrupoConta(codSubGrupoConta, thenFunction){
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ":" + localStorage.getItem('TOKEN_KEY'))

        await axios({
            method: 'get',
            url: api.urlCadastro + `api/v1/subGrupoConta/subGrupoConta?codSubgrupoConta=${codSubGrupoConta}`, 
            headers: { Authorization: basicAuth }
        }).then(function (response){ 
                dispatch({ idStore, type: 'SUBGRUPOS_CONTA', value: response.data.records})
                dispatch({ idStore, type: 'SUBGRUPO_CONTA_COPIA' })
                thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export async function CreateSubgrupoConta(subgrupoConta){
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try{
        const basicAuth = 'Basic ' + btoa(api.apiUser + ":" + localStorage.getItem('TOKEN_KEY'))

        let invalido = false

        if (subgrupoConta.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }
        if (subgrupoConta.codGrupoConta === '' || subgrupoConta.codGrupoConta === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codGrupoConta', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/subGrupoConta/create`,
            headers: { Authorization: basicAuth }, 
            data: {
                Descricao: subgrupoConta.descricao,
                CodGrupoConta: subgrupoConta.codGrupoConta,
                Observacao: subgrupoConta.observacao
            }
        }).then( response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'SUBGRUPO_CONTA', campo: 'codSubgrupoConta', value: response.data.records })
            dispatch({ idStore, type: 'SUBGRUPO_CONTA_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function UpdateSubgrupoConta(subgrupoConta, subgrupoContaCopia){
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(subgrupoConta) === JSON.stringify(subgrupoContaCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ":" + localStorage.getItem('TOKEN_KEY'))

        let invalido = false

        if (subgrupoConta.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }
        if (subgrupoConta.codgrupoConta === '' || subgrupoConta.codgrupoConta === '0') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codGrupoConta', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios ({
            method: 'put',
            url: api.urlCadastro + `api/v1/subGrupoConta/update?codSubgrupoConta=${subgrupoConta.codSubgrupoConta}`,
            headers: { Authorization: basicAuth },
            data: {
                Descricao: subgrupoConta.descricao,
                CodGrupoConta: subgrupoConta.codGrupoConta,
                Observacao: subgrupoConta.observacao
            }
        }).then ( response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'SUBGRUPO_CONTA', campo: 'codSubgrupoConta', value: response.data.records })
            dispatch({ idStore, type: 'SUBGRUPO_CONTA_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function InativaSubgrupoConta(subgrupoContaSelected) {
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/subGrupoConta/inativar?codSubgrupoConta=${subgrupoContaSelected.codSubgrupoConta}&status=${subgrupoContaSelected.status}`,
            headers: { Authorization: basicAuth }
        }).then(response => {
            dispatch({idStore, type: 'SUBGRUPO_CONTA', campo: 'status', value: subgrupoContaSelected.status === 0 ? 1 : 0})
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
        }).catch(e => {

            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function DeleteSubgrupoConta(subgrupoContaSelected) {
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/subGrupoConta/delete?codSubgrupoConta=${subgrupoContaSelected.codSubgrupoConta}`,
            headers: { Authorization: basicAuth }
        }).then(response => {
           
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetGrupoContas(){
    const dispatch = store.dispatch
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ":" + localStorage.getItem('TOKEN_KEY'))

        await axios({
            method: 'get',
            url: api.urlCadastro + `api/v1/grupoConta/grupoContas?status=0`, 
            headers: { Authorization: basicAuth }
        }).then(function (response){ 
                dispatch({ idStore, type: 'GRUPO_CONTAS', value: response.data.records})
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os grupos de contas", 'warning', 'icon-alert-circle-exc', 2)
    }
}