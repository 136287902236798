import React, { useState, useEffect } from "react"
import classnames from "classnames"
import ArrowTooltip from '../../components/Tooltip/ArrowTooltip'
import {
    Card,
    Col,
    Button,
    ButtonGroup,
    CardHeader,
    CardBody,
    CardTitle,
    TabContent,
    TabPane,
} from "reactstrap"

import Pesquisa from "./Pesquisa"
import Cadastro from "./Cadastro"
import useShallowEqualSelector from '../../Util/Hooks/useShallowEqualSelector'

import './styles.css'

import * as actions from '../../store/reducers/Cadastro/Produto/actions'
import { atalhoPesquisar } from "./atalhos"
import AppState from '../../store/storeType'

const Produtos = () => {
    const [horizontalTabs, setHorizontalTabs] = useState<'pesquisar' | 'cadastrar'>('pesquisar')
    const verCadastro = useShallowEqualSelector<AppState, boolean>(state => state.produtos.verCadastro)

    useEffect(() => {
        if (verCadastro === false)
            changeActiveTab('pesquisar')
    }, [verCadastro])

    atalhoPesquisar(horizontalTabs)

    function changeActiveTab(tabName: 'pesquisar' | 'cadastrar') {
        setHorizontalTabs(tabName)
        actions.cleanInvalido()
        if (tabName === "pesquisar") {
            setTimeout(() => {
                //@ts-ignore
                document.getElementById('codigoProduto').focus()
                actions.setVerCadastro(false)
                actions.cleanProduto()
            }, 100)
        }
    }

    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="12">
                    <Card>
                        <CardHeader className="text-center">
                            <CardTitle tag="h3">Produtos</CardTitle>
                            <br />
                            <TabContent
                                className="tab-space"
                                activeTab={horizontalTabs}
                                style={{ marginTop: '-30px', marginBottom: '-70px' }}
                            >
                            </TabContent>
                        </CardHeader>

                        <CardBody>
                            <ButtonGroup style={{ width: '100%' }}>
                                <ArrowTooltip title='Shift+1'>
                                    <Button
                                        style={{ width: '50%' }}
                                        color={horizontalTabs === "pesquisar" ? "info" : ""}
                                        type="button"
                                        data-toggle="tab"
                                        className={classnames("btn-gp-padrao", horizontalTabs === "pesquisar" ? "active" : "")}
                                        onClick={() => { changeActiveTab("pesquisar") }}
                                    >
                                        PESQUISAR
                                    </Button>
                                </ArrowTooltip>
                                <ArrowTooltip title='Shift+2'>
                                    <Button
                                        style={{ width: '50%' }}
                                        color={horizontalTabs === "cadastrar" ? "info" : ""}
                                        type="button"
                                        data-toggle="tab"
                                        className={classnames("btn-gp-padrao", horizontalTabs === "cadastrar" ? "active" : "")}
                                        onClick={() => {
                                            changeActiveTab("cadastrar")
                                            actions.setInicioCadastro(true)
                                        }}
                                    >
                                        CADASTRAR
                                    </Button>
                                </ArrowTooltip>
                            </ButtonGroup>
                            <TabContent
                                className="tab-space"
                                activeTab={horizontalTabs}
                                style={{ paddingBottom: '0px' }}
                            >
                                <TabPane tabId="pesquisar">

                                    <Pesquisa
                                        setHorizontalTabs={setHorizontalTabs}
                                    />

                                </TabPane>

                                <TabPane tabId="cadastrar">


                                    <Cadastro
                                        horizontalTabs={horizontalTabs}
                                    />

                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </div>


        </>
    )
}

export default Produtos
