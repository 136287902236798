import React, { memo, useEffect, useState } from 'react'
import classnames from "classnames"

import { alertar } from '../../Util/alertar'

import useResize from '../../Util/Hooks/useResize'
import useShallowEqualSelector from '../../Util/Hooks/useShallowEqualSelector'

import ReactWizard from '../../components/Wizard/Wizard'

import * as controller from '../../controller/controllerProdutos'
import AppState from '../../store/storeType'
import { Produto } from '../../store/reducers/Cadastro/Produto/types'

// wizard steps
import Step1 from "./CadastroSteps/DadosBasicos"
import Step2 from "./CadastroSteps/Imagens"
import Step3 from "./CadastroSteps/Grade"
import Step4 from "./CadastroSteps/DadosTributarios"

import * as actions from '../../store/reducers/Cadastro/Produto/actions'
import { atalhoLimpar, atalhoNovo, atalhoSalvar } from './atalhos'

var stepGrade = {
    stepName: "Grade",
    stepIcon: "tim-icons icon-components",
    component: Step3,
    stepProps: {}
}

var stepDadosBasicos = {
    stepName: "Dados Básicos",
    stepIcon: "tim-icons icon-notes",
    component: Step1,
    stepProps: {
    }
}

var stepImagens = {
    stepName: "Imagens",
    stepIcon: "tim-icons icon-image-02",
    component: Step2,
    stepProps: {}
}

var stepDadosTributarios = {
    stepName: "Dados Tributários",
    stepIcon: "tim-icons icon-money-coins",
    component: Step4,
    stepProps: {}
}

interface CadastroProps {
    horizontalTabs: 'pesquisar' | 'cadastrar'
}

const Cadastro: React.FC<CadastroProps> = ({ horizontalTabs }) => {
    const { width } = useResize()
    //@ts-ignore
    const { codEmpresa } = useShallowEqualSelector(state => state.login.usuario.empresa)
    //@ts-ignore
    const notificacao = useShallowEqualSelector(state => state.notificacao)

    const codProduto = useShallowEqualSelector<AppState, number | string>(state => state.produtos.produto.codProduto)
    const grades = useShallowEqualSelector<AppState, Produto['grades']>(state => state.produtos.produto.grades)
    const gradesSelected = useShallowEqualSelector<AppState, Produto['gradesSelected']>(state => state.produtos.produto.gradesSelected);
    const flTributado = useShallowEqualSelector<AppState, string | number>(state => state.produtos.produto.basico.caracteristicas.flTributado)
    const flControlaGrade = useShallowEqualSelector<AppState, string | number>(state => state.produtos.produto.basico.caracteristicas.flControlaGrade)
    const inicioCadastro = useShallowEqualSelector<AppState, boolean>(state => state.produtos.inicioCadastro)
    const proximoCadastro = useShallowEqualSelector<AppState, boolean>(state => state.produtos.proximoCadastro)
    const verCadastro = useShallowEqualSelector<AppState, boolean>(state => state.produtos.verCadastro)
    const { basico: { caracteristicas: { flControlaGrade: flControlaGradeCopia, flTributado: flTributadoCopia } }, grades: gradesCopia } = useShallowEqualSelector<AppState, Produto>(state => state.produtos.produtoCopia)

    const [steps, setsteps] = useState([stepDadosBasicos, stepImagens])
    const [stepActive, setStepActive] = useState(0)

    atalhoLimpar(horizontalTabs, stepActive)
    atalhoNovo(horizontalTabs, stepActive)
    atalhoSalvar(horizontalTabs, stepActive)

    function changeActiveStep(valor: number) {
        setStepActive(valor)
    }

    useEffect(() => {
        if (flTributado === 1 && flControlaGrade === 1) {

            if (flTributadoCopia === 1 && flControlaGradeCopia === 1) {
                setsteps([stepDadosBasicos, stepImagens, stepDadosTributarios, stepGrade])
                actions.setInicioCadastro(true)
            } else if (flTributadoCopia === 1) {
                setsteps([stepDadosBasicos, stepImagens, stepDadosTributarios])
                actions.setInicioCadastro(true)
            } else if (flControlaGradeCopia === 1) {
                setsteps([stepDadosBasicos, stepImagens, stepGrade])
                actions.setInicioCadastro(true)
            }
        } else if (flTributado === 1) {
            if (flTributadoCopia === 1) {
                setsteps([stepDadosBasicos, stepImagens, stepDadosTributarios])
                actions.setInicioCadastro(true)
            }
        } else if (flControlaGrade === 1) {
            if (flControlaGradeCopia === 1) {
                setsteps([stepDadosBasicos, stepImagens, stepGrade])
                actions.setInicioCadastro(true)
            }
        } else {
            setsteps([stepDadosBasicos, stepImagens])
            actions.setInicioCadastro(true)
        }

    }, [flTributado, flTributadoCopia, flControlaGrade, flControlaGradeCopia])

    useEffect(() => {
        if (inicioCadastro)
            actions.setInicioCadastro(false)
    }, [inicioCadastro])

    async function finishButtonClick(){    
        if(verCadastro){
            actions.setVerCadastro(!verCadastro);
            return true
        }
        else{
            const error = await controller.VincularProdutoGrade(codEmpresa, codProduto, grades, gradesSelected, gradesCopia)

            if (!error) {
                actions.cleanProduto()
                actions.setInicioCadastro(true)
                alertar(notificacao, 'br', "Cadastro concluído", 'success', 'icon-check-2', 2)
            }
            return error
        }
    }   

    return (
        <div style={{ marginTop: '-20px' }}>
            <ReactWizard
                steps={steps}
                navSteps={true}
                title=""
                description=""
                validate
                resizable={true}
                changeStep={changeActiveStep}
                previousButtonText="Voltar"
                finishButtonText="Finalizar"
                nextButtonText="Avançar"
                goToFirstStep={inicioCadastro}
                goToNextStep={proximoCadastro}
                finishButtonClick={finishButtonClick}
                finishButtonClasses={classnames("btn-wd btn-info", { finishButtonMin: width < 550 }, { finishButtonRight: width > 550 })}
                nextButtonClasses={classnames("btn-wd btn-info", { nextButtonMin: width < 550 }, { nextButtonRight: width > 550 })}
                previousButtonClasses={classnames("btn-wd", { previousButtonMin: width < 550 }, { previousButtonRight: width > 550 })}
                progressbar
                color="blue"
            />
        </div>
    )
}

export default memo(Cadastro)