import React from "react";
import { useSelector, useDispatch } from 'react-redux'

import { Select } from "~/components/ErnComponets/ErnComponets";
import { InputNumber } from "~/components/ErnComponets/ErnComponets";
import { ActionsStep } from "../Cadastro"

import {
    Row,
    Col,
    CardBody,
    CardFooter,
} from "reactstrap";

import  { maskClassificacoes } from '../../../../Util/mascaras'

const idStore = "CLASSIFICACOES_TRIBUTARIAS";

const IPI = () => {
    const dispatch = useDispatch();
    const { tributacao, verCadastro } = useSelector(state => state.classificacoesTributarias);
    const { IPIs, enquadramentos } = useSelector(state => state.classificacoesTributarias);

    function handleOnChange(value, campo) {
        dispatch({ idStore, type: 'TRIBUTACAO', campo, value });
    }

    return (
        <Row className="justify-content-center" style={{ marginTop: '-70px' }}>
                  <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

                </div>
            <Col>
                <CardBody>

                    <Row className="justify-content-center">

                        <Col>
                            <h5>CST</h5>
                            <Select
                                first="Selecione"
                                value={{ value: tributacao.codIPI }}
                                select={{ value: "codIpi", label: "descricaoFormatada" }}
                                onChange={value => handleOnChange(value.value, 'codIPI')}
                                options={IPIs}
                                isSearchable={true}
                                autoComplete="off"
                            />
                        </Col>
                    </Row>

                    <div className="box">
                        <Row >
                            <Col>
                                <h5>IPI</h5>
                            </Col>
                        </Row>
                        <Row className="justify-content-center padding-padrao-row">

                            <Col className="padding-padrao-col" lg>
                                <label>Enquadramento IPI</label>
                                <Select
                                    first="Selecione"
                                    value={{ value: tributacao.codEnquadramentoIPI }}
                                    select={{ value: "codEnquadramento", label: "descricaoFormatada" }}
                                    onChange={value => handleOnChange(value.value, 'codEnquadramentoIPI')}
                                    options={enquadramentos}
                                    isSearchable={true}
                                    autoComplete="off"    
                                />
                            </Col>

                            <Col className="padding-padrao-col" lg="auto">
                                <label>Percentual IPI %</label>
                                <InputNumber
                                    style={{ width: '200px' }}
                                    type="text"
                                    format="5,2"
                                    value={tributacao.percIPI}
                                    onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'percIPI')}
                                />
                            </Col>

                        </Row>
                    </div>


                </CardBody>
                <CardFooter className="text-center">
                    <ActionsStep />
                </CardFooter>
            </Col>
        </Row>
    )
};

class Wizard extends React.Component {
    focusInput = () => {
    }

    render() {
        return (
            <IPI />
        )
    }
}

export default Wizard;
