import { useState, useCallback } from 'react'

type Toggle = (newState?: boolean, callback?: (state?: boolean) => void) => void

export default function useModal(initialState: boolean): [boolean, Toggle] {
    const [state, setState] = useState(initialState)

    const toggle: Toggle = useCallback(
        (newState?: boolean, callback?: (state?: boolean) => void) => {
            if (newState !== undefined) {
                setState(newState)
            } else {
                setState(!state)
            }
            if (callback) callback(newState)
        },
        [state],
    );

    return [state, toggle]
}