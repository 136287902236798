import React, { useState, useEffect } from 'react';
import { Input, Button, Col, Row, Label } from 'reactstrap';
import ReactTable from "react-table";

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";

import * as controllerLancamento from '../../../controller/Financeiro/controllerLancamentoTitulos'

import { useDispatch } from 'react-redux';

import './styles.css'
import { Select } from "../../../components/ErnComponets/ErnComponets";
import { maskMoeda } from '../../../Util/mascaras';
import useShallowEqualSelector from "../../../Util/Hooks/useShallowEqualSelector";
import { reformatDate } from '../../../Util/removeMascaras';

const idStore = "LANCAMENTO_TITULOS";

export default function ModalPesquisa({ isOpen, toggle, confirmModal }) {
    const dispatch = useDispatch();
    const { titulos, lancamentos, contas, grupoContas, subContas } = useShallowEqualSelector(state => state.lancamentoTitulos)
    const { tipoLancamento, fornecedor, dadosConta, metodoPgnt } = useShallowEqualSelector(state => state.lancamentoTitulos.cadastro)
    const [valorRestante, setValorRestante] = useState()
    const [valorSubTotal, setValorSubtotal] = useState(metodoPgnt.valorSubTotal)

    useEffect(() => {
        controllerLancamento.getContas();
        controllerLancamento.getFormasPgms();
    }, [])

    useEffect(() => {
        let restante = Number(valorSubTotal.replace(".", "").replace(",", ".")) - Number(metodoPgnt.valorPago.replace(".", "").replace(",", "."))
        if (restante > 0) {
            setValorRestante(maskMoeda(restante.toFixed(2)))
        } else {
            restante = 0
            setValorRestante(maskMoeda(restante.toFixed(2)))
        }
    }, [valorSubTotal, metodoPgnt.valorPago])

    useEffect(() => {
        let sum = Number(metodoPgnt.juros.replace(".", "").replace(",", ".")) + Number(metodoPgnt.multa.replace(".", "").replace(",", ".")) + Number(metodoPgnt.tarifa.replace(".", "").replace(",", "."))
        let subTotal = Number(metodoPgnt.valorParcela.replace(".", "").replace(",", ".")) + sum - Number(metodoPgnt.desconto.replace(".", "").replace(",", "."))
        setValorSubtotal(maskMoeda(subTotal.toFixed(2)))
        dispatch({ idStore, type: "CADASTRO_METODO", value: { ...metodoPgnt, valorSubTotal: maskMoeda(subTotal.toFixed(2)) } })
    }, [metodoPgnt.juros, metodoPgnt.multa, metodoPgnt.desconto, metodoPgnt.tarifa])

    function handleEditarValorTitulo(valor, campo) {
        if (valor < 0) {
            dispatch({ idStore, type: "CADASTRO_METODO", value: { ...metodoPgnt } })
        }
        else {
            dispatch({ idStore, type: "CADASTRO_METODO", value: { ...metodoPgnt, [campo]: valor } })
        }
    }

    function handleEditarDataTitulo(valor, campo) {
        dispatch({ idStore, type: "CADASTRO_METODO", value: { ...metodoPgnt,  [campo]: campo !== 'mesReferencia' ? reformatDate('formatDate', valor) : valor } })
    }


    function onChangeGrupo(value, campo) {

        if (campo === "codGrupoConta") {
            controllerLancamento.getSubContas(value);
            dispatch({ idStore, type: "DADOS_CONTA", value: { ...dadosConta, [campo]: value, codSubgrupoConta: '0' } })
        }
        else if (campo === "codSubgrupoConta") {
            if (value >= 1) {
                let objSubGrupo = subContas.find(subGrupo => subGrupo.codSubGrupoConta = value)
                dispatch({ idStore, type: "DADOS_CONTA", value: { ...dadosConta, descricaoSubGrupo: objSubGrupo.descricao, descricaoGrupo: objSubGrupo.descGrupoConta, [campo]: value } })
            }
        } else if (campo === "codConta") {
            let objconta = contas.find(conta => conta.codConta = value)
            dispatch({ idStore, type: "DADOS_CONTA", value: { ...dadosConta, conta: objconta.descricao, [campo]: value } })
        }

    }

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title='Lançamento de Títulos (Crédito/Débito)'
            size="lg"
            id="modal-lancamentoTitulos"
        >
            <ModalBasicBody>
                <Row >
                    <Row id="header-modal-editar">
                        <Col lg={2} style={{ maxWidth: '10%' }} className="padding-padrao-col" >
                            <Row>
                                <Label>
                                    Tipo
                                </Label>
                            </Row>
                            <Row>
                                <Button
                                    id="button-editar"
                                    color={tipoLancamento === "R" ? "red" : "info"}
                                    style={{ width: "100%", bottom: "4px" }}
                                >
                                    {tipoLancamento === "R" ? "CRÉDITO" : "DÉBITO"}
                                </Button>
                            </Row>
                        </Col>
                        <Col lg="2" style={{ maxWidth: '10%' }} className="padding-padrao-col" >
                            <Row>
                                <label
                                    style={{ right: "-4.5px", position: "relative" }}
                                >
                                    Pago
                                 </label>
                            </Row>

                            <Row>
                                <Button
                                    id="button-editar"
                                    style={{ width: "100%", top: "-4px" }}
                                    color={metodoPgnt.status === "SIM" ? "green" : "yellow"}
                                >
                                    {metodoPgnt.status === "SIM" ? "PAGO" : "ABERTO"}
                                </Button>
                            </Row>
                        </Col>
                        <Col lg={6} style={{ maxWidth: '40%' }} className="padding-padrao-col" >
                            <Row>
                                <Label>
                                    Nome
                                </Label>
                            </Row>
                            <Row>
                                <Input
                                    value={fornecedor.nomeFor}
                                    disabled
                                />
                            </Row>
                        </Col>
                        <Col lg={2} style={{ maxWidth: '20%' }} className="padding-padrao-col" >
                            <Row>
                                <label>Título</label>
                            </Row>
                            <Row>
                                <Input
                                    value={dadosConta.codTituloMov}
                                    disabled
                                />
                            </Row>
                        </Col>
                        <Col style={{ maxWidth: '14%' }} className="padding-padrao-col"  >
                            <Row>
                                <label>Cadastro</label>
                            </Row>
                            <Row>
                                <Input
                                    type="text"
                                    value={metodoPgnt.dataCadastro}
                                    disabled
                                />
                            </Row>
                        </Col>
                        <Col lg="0.5" style={{ maxWidth: '10%' }} className="padding-padrao-col" >
                            <Row>
                                <label>Parcela</label>
                            </Row>
                            <Row>
                                <Input
                                    type="text"
                                    style={{ textAlign: "center" }}
                                    value={metodoPgnt.numeroParcela}
                                    disabled
                                />
                            </Row>
                        </Col>
                    </Row>
                    <Row id="header-modal-editar">
                        <Col className="padding-padrao-col" style={{ maxWidth: '23%' }} >
                            <label>Conta</label>
                            <Select
                                id="select"
                                first="Selecione"
                                value={{ value: dadosConta.codConta }}
                                select={{ value: "codConta", label: "descricao" }}
                                options={contas}
                                onChange={(props) => onChangeGrupo(props.value, "codConta")}
                                isSearchable={true}
                                autoComplete="off"
                            />
                        </Col>
                        <Col className="padding-padrao-col" style={{ maxWidth: '23%' }} >
                            <label>Grupo de Conta</label>
                            <Select
                                id="select"
                                first="Selecione"
                                value={{ value: dadosConta.codGrupoConta }}
                                select={{ value: "codGrupoConta", label: "descricao" }}
                                options={grupoContas}
                                onChange={(props) => onChangeGrupo(props.value, "codGrupoConta")}
                                isSearchable={true}
                                autoComplete="off"
                            />
                        </Col>
                        <Col className="padding-padrao-col" style={{ maxWidth: '22%' }} >
                            <label>SubGrupo de Conta</label>
                            <Select
                                id="select"
                                first="Selecione"
                                value={{ value: dadosConta.codSubgrupoConta }}
                                select={{ value: "codSubgrupoConta", label: "descricao" }}
                                options={subContas}
                                onChange={(props) => onChangeGrupo(props.value, "codSubgrupoConta")}
                                isSearchable={true}
                                autoComplete="off"
                            />
                        </Col>
                        <Col className="padding-padrao-col" style={{ maxWidth: '13%' }}>
                            <Row>
                                <label>Lançamento</label>
                            </Row>
                            <Row>
                                <Input
                                    value={reformatDate('reformatDate', metodoPgnt.dataLancamento)}
                                    type="date"
                                    onChange={e => handleEditarDataTitulo(e.target.value, "dataLancamento")}
                                />
                            </Row>
                        </Col>
                        <Col className="padding-padrao-col" style={{ maxWidth: '13%' }} >
                            <Row>
                                <label>Vencimento</label>
                            </Row>
                            <Row>
                                <Input
                                    value={reformatDate('reformatDate', metodoPgnt.vencimento)}
                                    type="date"
                                    onChange={e => handleEditarDataTitulo(e.target.value, "vencimento")}
                                />
                            </Row>
                        </Col>
                        <Col  className="padding-padrao-col" style={{ maxWidth: '8%' }} >
                            <Row>
                                <label>Mês Ref.</label>
                            </Row>
                            <Row>
                                <Input
                                    value={metodoPgnt.mesReferencia}
                                    type="text"
                                    onChange={e => handleEditarDataTitulo(e.target.value, "mesReferencia")}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Row>

                <Row style={{ marginLeft: "0rem" }}>
                    <Col lg="6" md="12" style={{ maxWidth: '65%', padding: 0, paddingRight: '5px' }} >
                        <Row>
                            <Col>
                                <label>Descrição do Título</label>
                                <textarea
                                    class="form-control" style={{ width: "100%", height: "100px", paddingLeft: '10px' }}
                                    value={dadosConta.descricao}
                                    onChange={(props) => controllerLancamento.atualizaDescricao(props.currentTarget.value)}
                                >
                                </textarea>
                            </Col>
                            <Col style={{ marginTop: '5px' }} >
                                <fieldset className="fieldset-operacao" style={{ height: '183px', padding: 0 }} >
                                    <legend>Lançamentos</legend>
                                    <div
                                        style={lancamentos.length !== 0 ?
                                            { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 150 } :
                                            { visibility: 'hidden', opacity: '0' }} >
                                        <ReactTable
                                            data={lancamentos}
                                            NoDataComponent={() => (<></>)}
                                            columns={[
                                                {
                                                    Header: "N",
                                                    accessor: "codigoLancamento"
                                                },
                                                {
                                                    Header: "FORMA PAGAMENTO",
                                                    accessor: "formaPagamento"
                                                },
                                                {
                                                    Header: "PAGAMENTO",
                                                    accessor: "dataLancamento"
                                                },
                                                {
                                                    Header: "N. LANÇAMENTO",
                                                    accessor: "numeroLancamento"
                                                },
                                                {
                                                    Header: "VALOR",
                                                    accessor: "valorLancamento"
                                                },
                                                {
                                                    Header: "JUROS",
                                                    accessor: "jurosLancamento"
                                                },
                                                {
                                                    Header: "MULTA",
                                                    accessor: "multaLancamento"
                                                },
                                                {
                                                    Header: "DESCONTO",
                                                    accessor: "descontoLancamento"
                                                },
                                                {
                                                    Header: "TARIFA",
                                                    accessor: "tarifaLancamento"
                                                },
                                                {
                                                    Header: "TOTAL PAGO",
                                                    accessor: "totalPagoLancamento"
                                                },
                                            ]}
                                            minRows={1}
                                            showPaginationTop={false}
                                            showPaginationBottom={false}
                                            filterable={false}
                                            className="-striped -highlight header-fix"
                                            noDataText=""
                                            pageSize={titulos.length}
                                        />
                                    </div>
                                </fieldset>
                            </Col>
                        </Row>
                    </Col>
                    <Col id="dados-pagamento" style={{ maxWidth: '35%' }} >
                        <fieldset style={{ border: 'solid 1px rgba(192, 192, 192, 0.596)', borderRadius: 5 }}>
                            <Row>
                                <Col>
                                    <Input
                                        value="Valor (R$)"
                                        style={{ pointerEvents: "none" }}
                                    />

                                </Col>
                                <Col>
                                    <Input
                                        id="input-total"
                                        style={{ textAlign: "right", pointerEvents: "none" }}
                                        value={metodoPgnt.valorParcela}

                                    />

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Input
                                        value="Juros (+)"
                                        style={{ pointerEvents: "none" }}
                                    />

                                </Col>
                                <Col>
                                    <Input
                                        className={metodoPgnt.status === 'SIM' && 'inputEdit-disabled'}
                                        style={{ textAlign: "right" }}
                                        value={metodoPgnt.juros}
                                        onChange={(e) => handleEditarValorTitulo(maskMoeda(e.target.value), "juros")}
                                        disabled={metodoPgnt.status === 'SIM' ? true : false}
                                    />

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Input
                                        value="Multa (+)"
                                        style={{ pointerEvents: "none" }}
                                    />

                                </Col>
                                <Col>
                                    <Input
                                        className={metodoPgnt.status === 'SIM' && 'inputEdit-disabled'}
                                        style={{ textAlign: "right" }}
                                        value={metodoPgnt.multa}
                                        onChange={(e) => handleEditarValorTitulo(maskMoeda(e.target.value), "multa")}
                                        disabled={metodoPgnt.status === 'SIM' ? true : false}
                                    />

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Input
                                        value="Tarifa(+)"
                                        style={{ pointerEvents: "none" }}
                                    />

                                </Col>
                                <Col>
                                    <Input
                                        className={metodoPgnt.status === 'SIM' && 'inputEdit-disabled'}
                                        style={{ textAlign: "right" }}
                                        value={metodoPgnt.tarifa}
                                        onChange={(e) => handleEditarValorTitulo(maskMoeda(e.target.value), "tarifa")}
                                        disabled={metodoPgnt.status === 'SIM' ? true : false}
                                    />

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Input
                                        value="Desconto (-)"
                                        style={{ pointerEvents: "none" }}
                                    />

                                </Col>
                                <Col>
                                    <Input
                                        className={metodoPgnt.status === 'SIM' && 'inputEdit-disabled'}
                                        style={{ textAlign: "right" }}
                                        value={metodoPgnt.desconto}
                                        onChange={(e) => handleEditarValorTitulo(maskMoeda(e.target.value), "desconto")}
                                        disabled={metodoPgnt.status === 'SIM' ? true : false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Input
                                        value="Valor Subtotal (R$)"
                                        style={{ pointerEvents: "none" }}
                                    />

                                </Col>
                                <Col>
                                    <Input
                                        id="input-total"
                                        autoComplete="off"
                                        style={{ textAlign: "right", pointerEvents: "none" }}
                                        value={valorSubTotal}
                                        disabled
                                    />

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Input
                                        value="Total Pago (R$)"
                                        style={{ pointerEvents: "none" }}
                                    />

                                </Col>
                                <Col>
                                    <Input
                                        id="input-total"
                                        autoComplete="off"
                                        style={{ textAlign: "right", pointerEvents: "none" }}
                                        value={metodoPgnt.valorPago}
                                        disabled
                                    />

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Input
                                        value="Restante (R$)"
                                        style={{ pointerEvents: "none" }}
                                    />

                                </Col>
                                <Col>
                                    <Input
                                        id="input-restante"
                                        style={{ textAlign: "right", pointerEvents: "none" }}
                                        value={valorRestante}
                                    />

                                </Col>
                            </Row>
                        </fieldset>
                    </Col>
                </Row>
            </ModalBasicBody>

            <ModalBasicFooter >
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        onClick={() => toggle()}
                        color={"info"}
                    >VOLTAR
                    </Button>
                    <Button
                        className="btn-padrao"
                        onClick={() => confirmModal()}
                        color={"info"}
                    >SALVAR
                    </Button>
                </Col>
            </ModalBasicFooter>

        </ModalBasic>
    )
}
