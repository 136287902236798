require('dotenv/config');

let ambient = process.env.NODE_ENV

var api = {
    /*-----------------------------------*/
    /*PRODUCAO*/
    /*-----------------------------------*/
    // urlCadastro: ambient === 'production' && 'http://desenv.ernetwork.com.br:5004/',
    // urlMovimento: ambient === 'production' && 'http://app.easyxweb.com:5555/',
    // urlFinanceiro: ambient === 'production' && 'http://app.easyxweb.com:5556/',
    
    /*-----------------------------------*/
    /*HOMOLOGAÇÃO/DESENVOLVIMENTO*/
    /*-----------------------------------*/
    urlCadastro: ambient === 'production' ? 'http://desenv.ernetwork.com.br:5004/' : 'http://192.168.6.27:5004/',
    urlMovimento: ambient === 'production' ? 'http://desenv.ernetwork.com.br:5999/' : 'http://192.168.6.27:5444/',
    urlFinanceiro: ambient === 'production' ? 'http://desenv.ernetwork.com.br:5998/' : 'http://192.168.6.27:5448/',

    apiUser: 'xX0F_etyDoMilkhen26ayc91bnz9vz3LQyyABU8=',
    apiPass: 'JIYw4iTX74GsllxV9aSQloK7JPUGiQCK1IDzJUXvfjLXdY8=',
    urlErnAdm: 'http://admin.ernetwork.com.br:5001/',
    apiUserAdmin: 'z7iWqEN-SrnsKUUqG5UoQGqeCiurlgQ8_VECO6exE_EgWA==',
    apiPassAdmin: 'YxGp6DU9Vus3D9Yv1IZw_Uur3_JzQZzXmEAQ30-QLgGnWA=='
}

export default api;
