import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import ReactTable from "react-table";

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import { Input, Info, InputCpfCnpj } from '../Components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import useScrollBar from '../../../../Util/Hooks/useScrollBar';

import { useSelector, shallowEqual } from 'react-redux';
import { maskCNPJ, maskCPF } from '../../../../Util/mascaras';

export default function ModalCliente({ isOpen, toggle, getCliente, confirmModal, clean }) {
    const tableRef = useScrollBar([isOpen])
    const clientes = useSelector(state => state.pedidoVenda.clientes, shallowEqual)
    const inputDocumento = useRef()

    const [pesquisaCliente, setpesquisaCliente] = useState({ codigo: null, cpfCnpj: '', nomeRazao: ''})

    useEffect(() => {
        inputDocumento.current.focus()
    }, [])

    function onClickCliente(rowIndex) {
        let clienteSelected = clientes[rowIndex]
        setpesquisaCliente({ codigo: clienteSelected.codCli, cpfCnpj: clienteSelected.cpfCnpj, nomeRazao: clienteSelected.nomeRazao})
    }

    function onDoubleClickCliente() {
        confirmModal(pesquisaCliente.codigo)
    }

    function limparModal() {
        setpesquisaCliente({  codigo: null, cpfCnpj: '', nomeRazao: ''})
        clean()
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            getCliente(pesquisaCliente)
        }
    }

    function getTrProps(state, row, column) {
        return {
            style: {
                height: '50px',
                paddingTop: '0px',
                paddingBottom: '5px',
                backgroundColor: clientes[row.index].cpfCnpj === pesquisaCliente.cpfCnpj ? 'rgba(71, 179, 255, 0.41)' : ''
            },
            onClick: (e, handleOriginal) => {
                onClickCliente(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            },
            onDoubleClick: (e, handleOriginal) => {
                onDoubleClickCliente(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            }
        }
    }

    function getTdProps(state, rowInfo, column) {
        if (column.id === 'actions') {
            return { style: { padding: 0 } }
        } else {
            return {}
        }
    }

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title='Pesquisa de Cliente'
            size="lg"
            id="modalClientes"
        >
            <ModalBasicBody>
                <Row>
                    <Col md="4" className="modalClientes-inputs">
                        <label>CPF / CNPJ</label>
                        <InputCpfCnpj
                            innerRef={inputDocumento}
                            type="text"
                            tipo={pesquisaCliente.cpfCnpj.length <= 14 ? 'cpf' : 'cnpj'}
                            value={pesquisaCliente.cpfCnpj.length === 14 ? maskCNPJ(pesquisaCliente.cpfCnpj) : maskCPF(pesquisaCliente.cpfCnpj)}
                            onChange={e => setpesquisaCliente({ ...pesquisaCliente, cpfCnpj: e.target.value })}
                            autoComplete="off"
                            maxLength="18"
                            onKeyPress={e => handleKeyPress(e)}
                        />
                        <br></br>
                    </Col>
                    <Col md="7" className="modalClientes-inputs">
                        <label>Nome / Razão Social</label>
                        <Input
                            type="text"
                            value={pesquisaCliente.nomeRazao}
                            onChange={e => setpesquisaCliente({ ...pesquisaCliente, nomeRazao: e.target.value })}
                            autoComplete="off"
                            onKeyPress={e => handleKeyPress(e)}
                        />
                        <br></br>
                    </Col>
                    <Col lg={1} md={1} sm={1} className="modalClientes-inputs" >
                        <Button
                            type="button"
                            style={{ marginTop: 34, width: 40, marginBottom: 0 }}
                            color="info"
                            onClick={() => getCliente(pesquisaCliente)}
                        >
                            <Icon icon="search" className="fa-lg" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <fieldset className="fieldset-operacao">
                            <legend>Resultados</legend>
                            <div
                                ref={tableRef}
                                style={clientes.length !== 0 ?
                                    { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 250 } :
                                    { visibility: 'hidden', opacity: '0' }} >
                                <ReactTable
                                    data={clientes}
                                    NoDataComponent={() => (<></>)}
                                    columns={[
                                        {
                                            Header: 'CPF / CNPJ',
                                            accessor: 'cpfCnpj',
                                            minWidth: 40,
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Nome / Razão Social',
                                            accessor: 'nomeRazao',
                                            resizable: false,
                                            sortable: false
                                        },                                     
                                    ]}
                                    getTrProps={getTrProps}
                                    getTdProps={getTdProps}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight header-fix"
                                    noDataText=""
                                    pageSize={clientes.length}
                                />
                            </div>

                            <br />
                        </fieldset>
                    </Col>

                </Row>

                <Col className="text-right">
                    <Info>
                        {`${clientes.length} clientes encontrados`}
                    </Info>
                </Col>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        onClick={() => confirmModal(pesquisaCliente.codigo)}
                        color={"info"}
                    >CONCLUÍDO
                    </Button>
                    <Button
                        className="btn-padrao"
                        onClick={() => limparModal()}
                        color={"info"}
                    >LIMPAR
                    </Button>
                </Col>
            </ModalBasicFooter>

        </ModalBasic>
    )
}