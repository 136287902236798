import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as controllerFornecedores from "~/controller/controllerFornecedores";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';

import {
  Input,
  Row,
  Col,
  CardBody,
  FormGroup,
  CardFooter,
  Button,
} from "reactstrap";

const idStore = "FORNECEDORES"

const Endereco = () => {
  const dispatch = useDispatch();
  const notificacao = useSelector(state => state.notificacao);
  const { fornecedor, invalido, fornecedorCopia, verCadastro } = useSelector(state => state.fornecedores);

  function onChange(value, stateName) {
    dispatch({ idStore, type: 'FORNECEDOR', campo: stateName, value: value });
    dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
  }

  async function handleKeyPressCep(event) {
    if (event.key === 'Enter') {
      const valido = await controllerFornecedores.BuscarCep(notificacao, dispatch, fornecedor.cep)
      if (valido) {
        document.getElementById('numero').focus();
        dispatch({ idStore, type: 'INVALIDO', campo: 'uf', value: false });
        dispatch({ idStore, type: 'INVALIDO', campo: 'cidade', value: false });
        dispatch({ idStore, type: 'INVALIDO', campo: 'bairro', value: false });
        dispatch({ idStore, type: 'INVALIDO', campo: 'logradouro', value: false });
      }
    }
  }

  async function Salvar() {
    await controllerFornecedores.UpdateEndereco(notificacao, dispatch, fornecedor, fornecedorCopia);
  }

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_ENDERECO' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
  }


  return (
    <>
      <Row className="justify-content-center">
      <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

      </div>
        <Col>
          <h4 className="info-text">Preencha com as informações do endereço do fornecedor</h4>
          <CardBody>

            <Row className="justify-content-center">

              <Col md="10">
                <Row>

                  <Col className="padding-padrao-col" lg="2" md="9" sm="4">
                    <label>CEP<span className="error"> *</span></label>
                    <FormGroup className={invalido.cep ? "has-danger" : ''}>
                      <Input
                        id="cepForne"
                        type="text"
                        value={fornecedor.cep}
                        onKeyPress={handleKeyPressCep}
                        onChange={e => onChange(e.target.value, "cep")}
                        autoComplete="off"
                        maxLength="9"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg md="9">
                    <label>Logradouro<span className="error"> *</span></label>
                    <FormGroup className={invalido.logradouro ? "has-danger" : ''}>
                      <Input
                        id="logradouro"
                        type="text"
                        value={fornecedor.logradouro}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="100"                   
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg="2" md="3">
                    <label>Número<span className="error"> *</span></label>
                    <FormGroup className={invalido.numero ? "has-danger" : ''}>
                      <Input
                        id="numero"
                        type="text"
                        value={fornecedor.numero}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="6"                    
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

                <Row>

                  <Col className="padding-padrao-col" lg="auto" md="2">
                    <label>UF<span className="error"> *</span></label>
                    <FormGroup className={invalido.uf ? "has-danger" : ''} style={{ width: '50px' }}>
                      <Input
                        style={{ paddingLeft: '15px', paddingRight: '15px' }}
                        id="uf"
                        type="text"
                        value={fornecedor.uf}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="2"                  
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg md="6">
                    <label>Cidade<span className="error"> *</span></label>
                    <FormGroup className={invalido.cidade ? "has-danger" : ''}>
                      <Input
                        id="cidade"
                        type="text"
                        value={fornecedor.cidade}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="100"                    
                      />
                      <br></br>
                    </FormGroup>
                  </Col>


                  <Col className="padding-padrao-col" lg md>
                    <label>Bairro<span className="error"> *</span></label>
                    <FormGroup className={invalido.bairro ? "has-danger" : ''}>
                      <Input
                        id="bairro"
                        type="text"
                        value={fornecedor.bairro}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="100"                   
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

                <Row>

                  <Col className="padding-padrao-col" lg md>
                    <label>Complemento</label>
                    <FormGroup>
                      <Input
                        id="complemento"
                        type="text"
                        value={fornecedor.complemento}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="50"                    
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" md>
                    <label>Referência</label>
                    <FormGroup>
                      <Input
                        id="referencia"
                        type="text"
                        value={fornecedor.referencia}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="70"               
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

              </Col>

            </Row>

          </CardBody>

          <CardFooter className="text-center">
            <Col style={{display:"flex", textAlign:'center' }}>
              <ArrowTooltip title='shift+L'>
                <Button className="btn-padrao" style={{ left:"33%", position:"relative" }} color="default" onClick={(e) => Limpar(e)}>Limpar</Button>
              </ArrowTooltip>
              <ArrowTooltip title='shift+S'>
                <Button className="btn-padrao" style={{ left:"33%", position:"relative" }} type="button" color="info" onClick={() => Salvar()}>Salvar</Button>
              </ArrowTooltip>
            </Col>
          </CardFooter>

        </Col>
      </Row>
    </>
  );
}

class Wizard extends React.Component {

  focusInput = () => {
    setTimeout(() => {
      document.getElementById('cepForne').focus()
    }, 100)
  }

  render() {
    return (
      <Endereco />
    );
  }
}

export default Wizard;
