import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '20px',
    border: '1px solid #333'
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    margin: '5px 0',
    borderTop: '1px solid #333',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    border: '1px solid #333',
    padding: '5px',
    margin: '5px 0'
  },
  headerItens: {
    width: '20%',
    margin: '0 5px'
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '3px 0'
  },
  headerText: {
    fontSize: '10px',
    width: '100%',
    textAlign: 'center'
  },
  infoHeader: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  viewInfoAbsolute: {
    display: 'flex',
    width: '50%',
    position: 'absolute',
    left: '50%'
  },
  infoRecebimento: {
    display: 'flex',
  },
  tableRowRecibimento: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  tableContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '50px'
  },
  title: {
    fontSize: '10px',
  },
  titleBackground: {
    fontSize: '10px',
    textTransform: 'uppercase',
    padding: '3px',
    backgroundColor: '#acacac'
  },
  text: {
    fontSize: '10px'
  },
  table: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: '2px 0',
    padding: '3px',
  },
  tableText: {
    fontSize: '10px',
    margin: '0 2px'
  },
  lancamentosContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '3px 0',
  },
  lancamentosItem: {
    display: 'flex',
    width: '50%'
  },
  lancamentosTotais: {
    alignSelf: 'flex-end',
    width: '60%',
    padding: '5px',
    border: '1px solid #333'
  },
  lancamentosTotaisRow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '3px 0'
  },
  lancamentosTotaisText: {
    fontSize: '10px',
    marginLeft: '5px',
    width: '100%',
    textAlign: 'right'
  }
})

export const stylesCupom = StyleSheet.create({
  container: {
    width: '50px',
    padding: '20px'
  },
  cupomContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '3px 0'
  },
  headerCupom: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '180px'
  },
  headerImage: {
    width: '50px',
    height: '50px',
    borderRadius: '50%'
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: '8px',
    textTransform: 'uppercase',
    padding: '3px 0'
  },
  headerFooter: {
    alignSelf: 'flex-end',

  },
  infoPedido: {
    padding: '3px 0',
    margin: '3px 0',
    borderTop: '1px solid #333',
    borderBottom: '1px solid #333',
  },
  titlesBoldCupom: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '8px'
  },
  textCupom: {
    fontSize: '8px'
  }
})