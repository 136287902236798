import React, { useEffect } from "react";

import {
    Card,
    Col,
    CardHeader,
    CardBody,
    CardTitle
} from "reactstrap";

import TabContent from "./TabContent/TabContent"
import TelaPesquisar from './TabContent/Tabs/Pesquisar';
import TelaAbrirCaixa from './TabContent/Tabs/AbrirCaixa';

import * as action from '../../store/reducers/Cadastro/AberturaCaixa/actions'

const Tabs = [
    {
        tabName: "PESQUISAR",
        tabScreen: <TelaPesquisar />
    },
    {
        tabName: "CAIXA",
        tabScreen:  <TelaAbrirCaixa />
    },
]

const AberturaCaixas = () => {

    useEffect(() => {

        return () => {
            action.cleanAll()
        }

    }, [])

    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="12">
                    <Card>

                        <CardHeader className="text-center">
                            <CardTitle tag="h3">Abertura de Caixa</CardTitle>
                        </CardHeader>

                        <CardBody>
                            <TabContent tabs={Tabs} />
                        </CardBody>
                    </Card>
                </Col>
            </div>
        </>
    );
}

export default AberturaCaixas;
