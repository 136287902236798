import React from 'react'
import {
    Button,
    FormGroup,
    Row,
    Label,
    Input
} from 'reactstrap';

interface ButtonP {
    key?: any,
    label?: any,
    color?: string,
    type?: any,
    dataToggle?: any,
    className?: string,
    onClick?: Function | any,
    style?: any
}

interface FormGroupC {
    key: any,
    style?: any,
    type: any,
    onChange: Function,
    checked: boolean
    onClick?: Function | any,
    label: string
}

export const ButtonGrupos = (props: ButtonP) => {
    return (
        <Button
            key={props.key}
            color={props.color}
            type={props.type}
            data-toggle={props.dataToggle}
            className={props.className}
            onClick={() => { props.onClick() }}
        >
            {props.label}
        </Button>
    )
}

export const FormGrupoTelas = (props: FormGroupC) => {
    return (
        <FormGroup check key={props.key}>
            <Row style={props.style}>
                <Label check>
                    <Input type={props.type}
                        onChange={() => props.onChange()}
                        checked={props.checked}
                    />
                    <span className='form-check-sign' />
                    {props.label}
                </Label>
                <Label onClick={() => props.onClick()}></Label>
            </Row>

        </FormGroup>

    )
}
