import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import classNames from "classnames";
import * as controllerGrupoContas from "~/controller/controllerGrupoContas";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import ReactTable from "react-table";
import ReactWizard from "~/components/Wizard/Wizard";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'
import { Select } from "~/components/ErnComponets/ErnComponets";

import {
    Card,
    Col,
    Button,
    ButtonGroup,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Row,
    TabContent,
    TabPane,
    Modal,
    ModalBody,
    FormGroup,
    Label,
} from "reactstrap";

// wizard steps
import Step1 from "~/views/GrupoContas/GrupoContaSteps/GrupoConta";
import useResize from "~/Util/Hooks/useResize";

var steps = [
    {
        stepName: "Grupo de Contas",
        stepIcon: "tim-icons icon-notes",
        component: Step1,
        stepProps: {}
    },
];
const idStore = 'GRUPO_CONTAS';

const GrupoDeContas = () => {
    const notificacao = useSelector(state => state.notificacao, shallowEqual);
    const { pesquisa, cadastro, cadastroCopia, grupoContas, inicioCadastro } = useSelector(state => state.grupoContas, shallowEqual);
    const dispatch = useDispatch();
    const [dataTable, setDataTable] = useState([]);
    const [table, setTable] = useState(renderTable([]));
    const [horizontalTabs, setHorizontalTabs] = useState('pesquisar');
    const [tableVisible, setTableVisible] = useState(false);
    const [modalInativar, setModalInativar] = useState(false);
    const [modalExcluir, setModalExcluir] = useState(false);
    const [grupoContaSelected, setGrupoContaSelected] = useState({ status: 0 });
    const { width } = useResize()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        steps.forEach(step => {
            step.stepProps = { ...step.stepProps, notificacao }
        });
    }, [notificacao])

    useEffect(() => {
        setDataTable(tableData(grupoContas));
        if (grupoContas.length > 0) {
            setTableVisible(true);
        } else {
            setTableVisible(false);
        }
    }, [grupoContas])

    useEffect(() => {
        setTable(renderTable());
    }, [dataTable, loading])


    useEffect(() => {
        if (inicioCadastro)
            setInicioCadastro(false)
    }, [inicioCadastro])

    function tableData(data) {
        return data.map((prop, key) => {
            return {
                codGrupoConta: prop['codGrupoConta'],
                descricao: prop['descricao'],
                tipo: prop['tipo'],
                status: prop['status']
            }
        })
    }

    function renderTable() {
        return dataTable.map((obj, key) => {
            return {
                ...dataTable[key],

                actions: (
                    <div className="actions-right">
                        <ArrowTooltip title="Visualizar">
                            <Button
                                onClick={() => {
                                    handleVisualizarCadastro(obj);
                                }}
                                size="sm"
                                className={classNames("btn-icon btn-link")}
                                style={{ color: "#483D8B" }}

                            >
                                <Icon icon="eye" className="fa-lg" />
                            </Button>
                        </ArrowTooltip>
                        {
                            !loading ?
                                <ArrowTooltip title="Editar">
                                    <Button
                                        onClick={() => {
                                            handleEditarCadastro(obj);
                                        }}
                                        size="sm"
                                        className={classNames("btn-icon btn-link")}
                                        color="success"
                                    >
                                        <Icon icon="edit" className="fa-lg" />

                                    </Button>

                                </ArrowTooltip>
                                :
                                <Button
                                    onClick={() => {
                                        handleEditarCadastro(obj);
                                    }}
                                    size="sm"
                                    className={classNames("btn-icon btn-link")}
                                    color="success"
                                    disabled={true}
                                >
                                    <Icon icon="edit" className="fa-lg" />
                                </Button>
                        }
                        <ArrowTooltip title='Inativar'>
                            <Button
                                onClick={() => {
                                    handleInativar(obj);
                                }}
                                className="btn-icon btn-link"
                                color="info"
                            >
                                {obj ? obj.status === 0 ?
                                    <Icon icon="ban" className="fa-lg" />
                                    :
                                    <Icon icon="redo" className="fa-lg" />
                                    :
                                    null
                                }
                            </Button>
                        </ArrowTooltip>
                        <ArrowTooltip title={'Excluir'}>
                            <span>
                                <Button
                                    onClick={() => { handleExcluir(obj) }}
                                    className="btn-icon btn-link"
                                    color="danger"
                                >
                                    <Icon icon="trash-alt" className="fa-lg" />
                                </Button>
                            </span>
                        </ArrowTooltip>
                    </div >
                )
            };
        })
    }

    function changeActiveTab(tabName) {
        setHorizontalTabs(tabName);
        if (tabName === "pesquisar") {
            setTimeout(() => {
                dispatch({ idStore, type: 'VER_CADASTRO', value: false })
                document.getElementById('descricao').focus()
            }, 100)
        }
    };

    function onChangeTipoGrupoContas(value) {
        dispatch({ idStore, type: 'PESQUISA', campo: 'tipo', value });
        controllerGrupoContas.Pesquisar({ ...pesquisa, tipo: value });
    }

    function handleOnChangeAtivo(value) {
        dispatch({ idStore, type: 'PESQUISA', campo: 'status', value: value });
        controllerGrupoContas.Pesquisar({ ...pesquisa, status: value });
    }

    function handleOnChange(value, campo) {
        dispatch({ idStore, type: 'PESQUISA', campo, value });
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            controllerGrupoContas.Pesquisar(pesquisa);
        }
    }

    function handlePesquisar(e) {
        e.preventDefault();
        controllerGrupoContas.Pesquisar(pesquisa);
    }

    function handleLimpar(e) {
        dispatch({ idStore, type: 'CLEAN_SEARCH' })
        e.preventDefault();
    }

    async function handleVisualizarCadastro(obj) {
        setLoading(true);

        await controllerGrupoContas.GetGrupoConta(obj.codGrupoConta, () => {
            dispatch({ idStore, type: 'CLEAN_INVALIDO' });
            setHorizontalTabs("cadastrar")
            setInicioCadastro(true);
        });
        setLoading(false);
        dispatch({ idStore, type: 'VER_CADASTRO', value: true })

    }

    async function handleEditarCadastro(obj) {
        setLoading(true);

        await controllerGrupoContas.GetGrupoConta(obj.codGrupoConta, () => {
            dispatch({ idStore, type: 'CLEAN_INVALIDO' });
            setHorizontalTabs("cadastrar")
            setInicioCadastro(true);
        });
        setLoading(false);
    }

    function handleInativar(obj) {
        setGrupoContaSelected(obj);
        toggleModalInativar()
    }

    async function handleExcluir(obj) {
        setGrupoContaSelected(obj);
        toggleModalExcluir();
    }

    async function novoCadatro() {
        setInicioCadastro(true);
        dispatch({ idStore, type: 'CLEAN_CADASTRAR' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    }

    async function inativar() {
        await controllerGrupoContas.inativarGrupoConta(grupoContaSelected)
        controllerGrupoContas.Pesquisar(pesquisa);
    }
    function excluir() {
        controllerGrupoContas.ExcluirGrupoConta(pesquisa, grupoContaSelected);
    }

    function setInicioCadastro(valor) {
        dispatch({ idStore, type: 'INICIO_CADASTRO', value: valor });
    }

    async function finishButtonClick() {

        let error = false;

        if (cadastro.codGrupoConta === 0) {
            error = await controllerGrupoContas.CreateGrupoConta(cadastro);
            handleError(error)
        } else {
            error = await controllerGrupoContas.UpdateGrupoConta(cadastro, cadastroCopia);
            handleError(error)
        }

        return error
    }

    function handleError(error) {
        if (!error) {
            dispatch({ idStore, type: 'CLEAN_CADASTRAR' });
            setInicioCadastro(true);
        }
    }


    function toggleModalInativar() {
        setModalInativar(!modalInativar);
    }

    function toggleModalExcluir() {
        setModalExcluir(!modalExcluir);
    }

    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="12">
                    <Card>

                        <CardHeader className="text-center">
                            <CardTitle tag="h3">Grupo de Contas</CardTitle>
                            <br />
                            <TabContent
                                className="tab-space"
                                activeTab={horizontalTabs}
                                style={{ marginTop: '-30px', marginBottom: '-70px' }}
                            >
                            </TabContent>
                        </CardHeader>

                        <CardBody>
                            <ButtonGroup style={{ width: '100%' }}>

                                <Button
                                    color={
                                        horizontalTabs === "pesquisar"
                                            ? "info"
                                            : ""
                                    }
                                    type="button"
                                    data-toggle="tab"
                                    className={classNames("btn-gp-padrao",
                                        horizontalTabs === "pesquisar"
                                            ? "active"
                                            : ""
                                    )
                                    }
                                    onClick={e => {
                                        changeActiveTab("pesquisar");
                                    }
                                    }
                                    style={{ width: '50%' }}
                                >
                                    PESQUISAR
                                    </Button>

                                <Button
                                    color={
                                        horizontalTabs === "cadastrar"
                                            ? "info"
                                            : ""
                                    }
                                    type="button"
                                    data-toggle="tab"
                                    className={classNames("btn-gp-padrao",
                                        horizontalTabs === "cadastrar"
                                            ? "active"
                                            : ""
                                    )
                                    }
                                    onClick={e => {
                                        changeActiveTab("cadastrar");
                                        novoCadatro();
                                    }
                                    }
                                    style={{ width: '50%' }}
                                >
                                    CADASTRAR
                                    </Button>

                            </ButtonGroup>
                            <TabContent
                                className="tab-space"
                                activeTab={horizontalTabs}
                                style={{ paddingBottom: '0px' }}
                            >
                                <TabPane tabId="pesquisar">
                                    <Row className="padding-padrao-row">
                                        <Col className="padding-padrao-col" lg="8" md="3">
                                            <label>Descrição</label>
                                            <Input
                                                id="descricao"
                                                type="text"
                                                autoComplete="off"
                                                value={pesquisa.descricao}
                                                onChange={e => handleOnChange(e.target.value, 'descricao')}
                                                onKeyPress={e => handleKeyPress(e)}
                                            />
                                        </Col>
                                        <Col className="padding-padrao-col" lg="2" md="3">
                                            <label>Tipo</label>
                                            <Select
                                                first={'Todos'}
                                                value={{ value: pesquisa.tipo }}
                                                options={[{ value: 'R', label: 'Receitas' },
                                                { value: 'D', label: 'Despesas' }]}
                                                onChange={value => onChangeTipoGrupoContas(value.value)}
                                            />
                                        </Col>

                                        <Col lg="1" md="4" className="text-center padding-padrao-col" style={{ paddingTop: '13px' }}>
                                            <Col className={width > 768 ? "text-left" : ''}>
                                                <Col>
                                                    <FormGroup check className="form-check-radio" Style={"margin-top:-12px;"} >
                                                        <Label check style={{ paddingRight: '5px' }}>
                                                            <Input type="radio"
                                                                onChange={() => handleOnChangeAtivo('')}
                                                                checked={pesquisa.status === '' ? true : false}
                                                            />
                                                            <span className="form-check-sign" />Todos
                                                        </Label>
                                                        <Label check style={{ paddingRight: '5px' }}>
                                                            <Input type="radio"
                                                                onChange={() => handleOnChangeAtivo(0)}
                                                                checked={pesquisa.status === 0 ? true : false}
                                                            />
                                                            <span className="form-check-sign" />Ativos
                                                        </Label>
                                                        <Label check style={{ paddingRight: '5px' }}>
                                                            <Input type="radio"
                                                                onChange={() => handleOnChangeAtivo(1)}
                                                                checked={pesquisa.status === 1 ? true : false}
                                                            />
                                                            <span className="form-check-sign" />Inativos
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </Col>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <CardBody>
                                            <Col style={{ width: '100%' }}>
                                                <Button
                                                    color='info'
                                                    style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                                    onClick={e => handleLimpar(e)}
                                                >Limpar</Button>
                                                <Button
                                                    color='info'
                                                    style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                                    onClick={e => handlePesquisar(e)}
                                                >Pesquisar</Button>

                                            </Col>
                                        </CardBody>
                                    </Row>


                                    <div style={tableVisible ? { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
                                        <ReactTable
                                            data={table}
                                            filterable={false}
                                            columns={
                                                [

                                                    {
                                                        Header: "Descrição",
                                                        accessor: "descricao"
                                                    },

                                                    {
                                                        Header: "Tipo",
                                                        accessor: "tipo",
                                                        Cell: props => props.value === 'R' ? 'Receitas' : 'Despesas',
                                                    },
                                                    {
                                                        Header: "",
                                                        accessor: "actions",
                                                        sortable: false,
                                                        filterable: false
                                                    }
                                                ]
                                            }
                                            minRows={1}
                                            showPaginationTop={false}
                                            className="-striped -highlight"
                                            noDataText=""
                                            showPaginationBottom={true}
                                            showPageSizeOptions={true}
                                            previousText="Anterior"
                                            nextText="Próximo"
                                            loadingText="Carregando"
                                            pageText="Página"
                                            ofText="de"
                                            rowsText='linhas'
                                        />
                                    </div>
                                </TabPane>

                                <TabPane tabId="cadastrar">
                                    <div style={{ marginTop: '-20px' }}>
                                        <ReactWizard
                                            steps={steps}
                                            navSteps={true}
                                            title=""
                                            description=""
                                            validate
                                            resizable={true}
                                            finishButtonText="Finalizar"
                                            goToFirstStep={inicioCadastro}
                                            finishButtonClick={() => finishButtonClick()}
                                            finishButtonClasses={classNames("btn-wd btn-info",
                                                {
                                                    finishButtonMin:
                                                        width < 550
                                                },
                                                {
                                                    finishButtonRight:
                                                        !width < 550
                                                }
                                            )}
                                            nextButtonClasses={classNames("btn-wd btn-info",
                                                {
                                                    nextButtonMin:
                                                        width < 550

                                                },
                                                {
                                                    nextButtonRight:
                                                        !width < 550
                                                }
                                            )}
                                            previousButtonClasses={classNames("btn-wd",
                                                {
                                                    previousButtonMin:
                                                        width < 550
                                                },
                                                {
                                                    previousButtonRight:
                                                        !width < 550
                                                }
                                            )}
                                            progressbar
                                            color="blue"
                                        />
                                    </div>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </div>

            {/* Modal Inativar */}
            <Modal
                isOpen={modalInativar}
                toggle={toggleModalInativar}
            >
                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalInativar}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">{grupoContaSelected.status === 0 ? "Inativar" : "Ativar"} grupo de conta</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>
                            {grupoContaSelected.status === 0 ?
                                <>Tem certeza que deseja <b>inativar</b> esse grupo de conta da sua empresa?</>
                                : <>Tem certeza que deseja <b>ativar</b> esse grupo de conta da sua empresa?</>
                            }
                        </label>
                    </Col>

                </ModalBody>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { toggleModalInativar() }}
                                style={{ width: '150px' }}
                            >NÃO
                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { toggleModalInativar(); inativar() }}
                                style={{ width: '150px' }}
                            >SIM
                                            </Button>
                        </Col>
                    </Row>
                </CardBody>

            </Modal>
            {/* Modal Inativar*/}

            {/* Modal Excluir */}
            <Modal
                isOpen={modalExcluir}
                toggle={toggleModalExcluir}
            >

                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalExcluir}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Excluir Grupo de Conta</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>
                            Tem certeza que deseja <b>excluir</b> esse Grupo de Conta?
                        </label>
                    </Col>

                </ModalBody>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { toggleModalExcluir() }}
                                style={{ width: '150px' }}
                            >NÃO
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { toggleModalExcluir(); excluir() }}
                                style={{ width: '150px' }}
                            >SIM
                                            </Button>
                        </Col>
                    </Row>
                </CardBody>

            </Modal>
            {/* Modal Excluir*/}
        </>
    );

}

export default GrupoDeContas;
