import store from "../../../stores";

import { 
    FechamentoCaixaTypes, 
    FechamentoCaixaStore, 
    FechamentoCaixaAction, 
    IFechamentoCaixaState 
} from "./types";

export const cleanAll = () => store.dispatch<FechamentoCaixaAction>({
    idStore: FechamentoCaixaStore.ID_STORE,
    type: FechamentoCaixaTypes.CLEAN_ALL
})

export const cleanPesquisa = () => store.dispatch<FechamentoCaixaAction>({
    idStore: FechamentoCaixaStore.ID_STORE,
    type: FechamentoCaixaTypes.CLEAN_PESQUISA
})

export const setFuncionarios = (funcionarios: IFechamentoCaixaState['funcionarios']) => store.dispatch<FechamentoCaixaAction>({
    idStore: FechamentoCaixaStore.ID_STORE,
    type: FechamentoCaixaTypes.FUNCIONARIOS,
    funcionarios,
})

export const setPesquisa = (pesquisa: IFechamentoCaixaState['pesquisa']) => store.dispatch<FechamentoCaixaAction>({
    idStore: FechamentoCaixaStore.ID_STORE,
    type: FechamentoCaixaTypes.PESQUISA,
    pesquisa,
})

export const setFechamento = (fechamento: IFechamentoCaixaState['fechamento']) => store.dispatch<FechamentoCaixaAction>({
    idStore: FechamentoCaixaStore.ID_STORE,
    type: FechamentoCaixaTypes.FECHAMENTO,
    fechamento,
})

export const setPagamento = (pagamento: IFechamentoCaixaState['pagamento']) => store.dispatch<FechamentoCaixaAction>({
    idStore: FechamentoCaixaStore.ID_STORE,
    type: FechamentoCaixaTypes.PAGAMENTO,
    pagamento
})
export const setDetalhamento = (detalhamento: IFechamentoCaixaState['detalhamento']) => store.dispatch<FechamentoCaixaAction>({
    idStore: FechamentoCaixaStore.ID_STORE,
    type: FechamentoCaixaTypes.DETALHAMENTO,
    detalhamento,
})

export const setFechamentoRetorno = (fechamentoRetorno: any) => store.dispatch<FechamentoCaixaAction>({
    idStore: FechamentoCaixaStore.ID_STORE,
    type: FechamentoCaixaTypes.FECHAMENTO_RETORNO,
    value: fechamentoRetorno,
})

export const setCaixas = (caixas: IFechamentoCaixaState['caixas']) => store.dispatch<FechamentoCaixaAction>({
    idStore: FechamentoCaixaStore.ID_STORE,
    type: FechamentoCaixaTypes.CAIXAS,
    caixas
})

export const setScreen = (screen: number) => store.dispatch<FechamentoCaixaAction>({
    idStore: FechamentoCaixaStore.ID_STORE,
    type: FechamentoCaixaTypes.SCREEN,
    value: screen
})

export const setBlock = (value: boolean) => store.dispatch<FechamentoCaixaAction>({
    idStore: FechamentoCaixaStore.ID_STORE,
    type: FechamentoCaixaTypes.BLOCK,
    value
})