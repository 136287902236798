import React, { useEffect } from "react"
import {
    CardBody,
    CardFooter,
    Col,
    FormGroup,
    Row
} from "reactstrap";


import { InputTelefone, Select } from "../../../../../../../components/ErnComponets/ErnComponets";

import { IUsuarioState } from "../../../../../../../store/reducers/Cadastro/Usuario/types";
import AppState from "../../../../../../../store/storeType";
import useShallowEqualSelector from "../../../../../../../Util/Hooks/useShallowEqualSelector";

import { InputCpfCnpj } from "../../../../../../Movimentacao/PedidoVenda/Components";
import { ButtonFuncionario, InputFuncionario } from "./Components";
import { generos } from './values'
import * as controllerUsuarios from '../../../../../../../controller/controllerUsuarios'
import * as actions from '../../../../../../../store/reducers/Cadastro/Usuario/actions'
import ArrowTooltip from "../../../../../../../components/Tooltip/ArrowTooltip";

const Funcionario = () => {
    const funcionario = useShallowEqualSelector<AppState, IUsuarioState['funcionario']>(state => state.usuario.funcionario)
    const funcoes = useShallowEqualSelector<AppState, IUsuarioState['funcoes']>(state => state.usuario.funcoes)
    const invalido = useShallowEqualSelector<AppState, IUsuarioState['invalido']>(state => state.usuario.invalido)
    const verCadastro = useShallowEqualSelector<AppState, IUsuarioState['verCadastro']>(state => state.usuario.verCadastro)

    useEffect(() => {
        controllerUsuarios.GetFuncao()

    }, [])
    const handleLimparAll = () => {

        actions.cleanAcesso()
        actions.cleanUsuario()
        actions.cleanFuncionario()
        actions.cleanInvalido()
        actions.setCurrentStep(0)
        actions.setVerificationSalvar(false)
        actions.setEditar(false)

    }
    
    return (
        <>
            <Row className="justify-content-center">
                <div className={verCadastro === true ? 'ver-cadastro' : ''}>

                </div>
                <Col>
                    <h4 className="info-text">Preencha com as informações do funcionário</h4>
                    <CardBody>
                        <Row className="justify-content-center">
                            <Col md="6">

                                <Row>
                                    <Col>
                                        <h5>Informações básicas do funcionário</h5>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="padding-padrao-col" md="3">
                                        <label>Número<span className="error"> *</span></label>
                                        <InputFuncionario
                                            state={funcionario}
                                            id="numeroFuncionario"
                                            name="numeroFuncionario"
                                            type="text"
                                            value={funcionario.numeroFuncionario}
                                            isDisabled={true}
                                            length={55}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { actions.setFuncionario({ ...funcionario, numeroFuncionario: event.currentTarget.value }) }}
                                        />
                                    </Col>

                                    <br></br>

                                    <Col className="padding-padrao-col" md="9">
                                        <label>Nome<span className="error"> *</span></label>
                                        <InputFuncionario
                                            state={funcionario}
                                            id="nomeFuncionario"
                                            name="nome"
                                            type="text"
                                            value={funcionario.nome}
                                            isDisabled={false}
                                            length={55}
                                            className={invalido.nomeFunc ? "has-danger" : ''}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                actions.setFuncionario({ ...funcionario, nome: event.currentTarget.value }),
                                                    actions.setInvalido({ ...invalido, nomeFunc: false })
                                            }}

                                        />
                                    </Col>

                                    <br></br>

                                    <Col className="padding-padrao-col" md="6">
                                        <label>CPF<span className="error"> *</span></label>
                                        <FormGroup className={invalido.cpfFunc ? "has-danger" : ''}>
                                            <InputCpfCnpj
                                                Style={{ fontSize: '12px' }}
                                                state={funcionario}
                                                name="cpf"
                                                type="cpf"
                                                value={funcionario.cpf}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    actions.setFuncionario({ ...funcionario, cpf: event.currentTarget.value }),
                                                        actions.setInvalido({ ...invalido, cpfFunc: false })
                                                }}
                                                isDisabled={false}
                                                length={55}

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="padding-padrao-col" md="6">
                                        <label>Data de Nascimento<span className="error"> *</span></label>
                                        <InputFuncionario
                                            state={funcionario}
                                            name={'dtNascimento'}
                                            id="dtNascimento"
                                            type="date"
                                            value={funcionario.dtNascimento}
                                            isDisabled={false}
                                            length={10}
                                            className={invalido.dtNascimento ? "has-danger" : ''}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                actions.setFuncionario({ ...funcionario, dtNascimento: event.currentTarget.value }),
                                                    actions.setInvalido({ ...invalido, dtNascimento: false })
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="padding-padrao-col" md="6">
                                        <label>Sexo</label>
                                        <Select
                                            //@ts-ignore
                                            id="tipoContribuinteSelect"
                                            value={{ value: funcionario.genero }}
                                            isSearchable={false}
                                            onChange={(event: any) => { actions.setFuncionario({ ...funcionario, genero: event.value }) }}
                                            options={generos}
                                            autoComplete="off"
                                        />
                                    </Col>
                                    <Col className="padding-padrao-col" md="6">
                                        <label>Telefone</label>
                                        <FormGroup >
                                            <InputTelefone
                                                name="telefone"
                                                type="fone"
                                                value={funcionario.telefone}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { actions.setFuncionario({ ...funcionario, telefone: event.currentTarget.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="padding-padrao-col">
                                        <label>E-mail do Funcionário<span className="error"> *</span></label>
                                        <InputFuncionario
                                            state={funcionario}
                                            name={'emailFunc'}
                                            id="emailFuncionario"
                                            type="text"
                                            value={funcionario.emailFunc}
                                            isDisabled={false}
                                            length={60}
                                            className={invalido.emailFunc ? "has-danger" : ''}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                actions.setFuncionario({ ...funcionario, emailFunc: event.currentTarget.value }),
                                                    actions.setInvalido({ ...invalido, emailFunc: false })
                                            }}
                                        />

                                    </Col>
                                </Row>

                                <br></br>
                                <br></br>
                            </Col>

                            <Col md="3">

                                <Row>
                                    <Col>
                                        <h5>Informações Adicionais</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="padding-padrao-col">
                                        <label>RG</label>
                                        <InputFuncionario
                                            state={funcionario}
                                            name={'rg'}
                                            id="rg"
                                            type="rg"
                                            value={funcionario.rg}
                                            isDisabled={false}
                                            length={15}
                                            className={{}}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { actions.setFuncionario({ ...funcionario, rg: event.currentTarget.value }) }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="padding-padrao-col">
                                        <label>Orgão Emissor</label>
                                        <InputFuncionario
                                            state={funcionario}
                                            name={'orgaoEmissor'}
                                            id="orgaoEmissor"
                                            type="text"
                                            value={funcionario.orgaoEmissor}
                                            isDisabled={false}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { actions.setFuncionario({ ...funcionario, orgaoEmissor: event.currentTarget.value }) }}
                                            length={5}
                                            className={{}}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="padding-padrao-col">
                                        <label>Data de Emissão</label>
                                        <InputFuncionario
                                            state={funcionario}
                                            name={'dtEmissao'}
                                            id="dtEmissao"
                                            type="date"
                                            value={funcionario.dtEmissao}
                                            isDisabled={false}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { actions.setFuncionario({ ...funcionario, dtEmissao: event.currentTarget.value }) }}
                                            length={10}
                                            className={invalido.dtEmissao ? "has-danger" : ''}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="padding-padrao-col">
                                        <label>Função</label>
                                        <FormGroup >
                                            <Select
                                                //@ts-ignore
                                                id="tipoFuncao"
                                                first="Selecione"
                                                value={{ value: funcionario.codFuncao, label: funcionario.descFuncao }}
                                                select={{ value: 'codFunc', label: 'nomeFunc' }}
                                                onChange={(event: any) => { actions.setFuncionario({ ...funcionario, codFuncao: event.value }) }}
                                                //@ts-ignore
                                                options={funcoes}
                                            />
                                        </FormGroup >
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                        <Col>
                            <ArrowTooltip title="Shift+L">
                                <ButtonFuncionario
                                    className={'btn-padrao'}
                                    color={'default'}
                                    onClick={() => handleLimparAll()}
                                    label={'Limpar'}
                                    arrowTooltip={true}
                                    arrowTooltipTitle={'Shift+N'}
                                />
                            </ArrowTooltip>
                            <ArrowTooltip title="Shift+S">

                                <ButtonFuncionario
                                    className={'btn-padrao'}
                                    color={'info'}
                                    onClick={() => controllerUsuarios.SalvarFuncionario()}
                                    label={'Salvar'}
                                    arrowTooltip={true}
                                    arrowTooltipTitle={'Shift+S'}
                                />
                            </ArrowTooltip>
                        </Col>
                    </CardFooter>
                </Col>


            </Row>


        </>
    )



}

class Wizard extends React.Component {
    render() {
        return (
            <Funcionario />
        )
    }
}

export default Wizard;