import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";

import * as action from '../store/reducers/Cadastro/FechamentoCaixa/actions'
import store from '../store/stores';


export function getFuncionario() {
    const { notificacao} = store.getState();
    try {
        const { fechamento, pesquisa } = store.getState().fechamentoCaixa;
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        action.setBlock(false)
        axios.get(api.urlCadastro + `api/v1/funcionario/funcionario`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            action.setFechamento({
                ...fechamento,
                codigoFuncionario: response.data.records.codFunc,
                nomeFuncionario: response.data.records.nomeFunc
            });

            action.setPesquisa({
                ...pesquisa,
                codigoFuncionario: response.data.records.codFunc,
                nomeFuncionario: response.data.records.nomeFunc
            })

        }).catch(error => {
            alertar(notificacao, 'br', "Usuário não possui funcionário vinculado", 'warning', 'icon-alert-circle-exc', 5)    
            action.setBlock(true)
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel obter Funcionário", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function getFuncionarios() {
    const { notificacao } = store.getState();
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios.get(api.urlCadastro + `api/v1/funcionario/funcionarios`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0)
                return alertar(notificacao, 'br', "Nenhum funcionário encontrado", 'warning', 'icon-alert-circle-exc', 2)

            action.setFuncionarios(response.data.records);

        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel obter os Funcionários", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function fecharCaixa(fechamento){
    const { notificacao} = store.getState();
    try{
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/caixa/fecharCaixa`,            
            headers: { Authorization: basicAuth },
            data : {
                CodFuncionario: fechamento.codFuncionario,
                CodCaixa: fechamento.codCaixa
            }
        }).then(response => {
            if (response.data.records.dataFechamento !== '')
               alertar(notificacao, 'br', "Caixa Fechado com Sucesso", 'success', 'icon-alert-circle-exc', 2)
            action.setFechamentoRetorno(response.data.records);
        }).catch(error => {
            if (error.response)
                alertar(notificacao, 'br', error.response.data.message, 'warning', 'icon-alert-circle-exc', 5)
        })                       
    } catch (e) {
                alertar(notificacao, 'br', "Não foi possível fechar o caixa", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function getCaixas() {
    const { notificacao } = store.getState();
    try {
        const { pesquisa } = store.getState().fechamentoCaixa;
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/caixa/caixas?codFuncionario=${pesquisa.codigoFuncionario}&dataInicio=${pesquisa.dataInicio}&dataFim=${pesquisa.dataFim}&tipo=${pesquisa.tipo === null ? "" : pesquisa.tipo}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {

            if (response.data.records.length === 0) {
                action.setCaixas([])
                alertar(notificacao, 'br', "Nenhum Caixa encontrado", 'success', 'icon-alert-circle-exc', 2)
                return 
            }

            action.setCaixas(response.data.records);

        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel obter os caixas", 'warning', 'icon-alert-circle-exc', 2)
    }
}



export function getCaixa(codCaixa, status) {
    const { notificacao} = store.getState();
    try {
        const { pesquisa } = store.getState().fechamentoCaixa;
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/caixa/caixa?codCaixa=${codCaixa}&codFuncionario=${pesquisa.codigoFuncionario}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.dataAbertura)
                response.data.records.dataAbertura = response.data.records.dataAbertura.substring(0, 10)

            if (response.data.records.dataFechamento)
                response.data.records.dataFechamento = response.data.records.dataFechamento.substring(0, 10)

            action.setFechamento({
                ...response.data.records, 
                status: status === 0 ? "Aberto" : "Fechado"
            });
        })
    }catch(e){
            alertar(notificacao, 'br', "Não foi possivel obter caixa", 'warning', 'icon-alert-circle-exc', 2)
        }
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
    
        axios.get(api.urlCadastro + `api/v1/caixa/pagamentos?codCaixa=${codCaixa}`, {
            headers: { Authorization: basicAuth }
         }).then(response => {
                action.setPagamento(response.data.records);
            })
        }catch(e){
                alertar(notificacao, 'br', "Não foi possivel obter caixa", 'warning', 'icon-alert-circle-exc', 2)
            }
            try {
                const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
            
                axios.get(api.urlCadastro + `api/v1/caixa/detalhamento?codCaixa=${codCaixa}`, {
                    headers: { Authorization: basicAuth }
                 }).then(response => {
                        action.setDetalhamento(response.data.records);
                        action.setScreen(1)
                    })
                }catch(e){
                        alertar(notificacao, 'br', "Não foi possivel obter caixa", 'warning', 'icon-alert-circle-exc', 2)
                    }
   
}

export function getCaixaAberto() {
    const { notificacao } = store.getState();
    try {
        const { pesquisa, abertura } = store.getState().fechamentoCaixa;
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/caixa/caixaAberto?codFuncionario=${pesquisa.codigoFuncionario}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {

            if (response.data.records.dataAbertura)
                response.data.records.dataAbertura = response.data.records.dataAbertura.substring(0, 10)

            if (response.data.records.dataFechamento)
                response.data.records.dataFechamento = response.data.records.dataFechamento.substring(0, 10)

            action.setFechamento({
                ...response.data.records,
                status: "Aberto",
                codFuncionario: abertura.codFuncionario,
                nomeFuncionario: abertura.nomeFuncionario
            });


            action.setScreen(1)

        }).catch(error => {
            if (error.response)
                alertar(notificacao, 'br', error.response.data.message, 'warning', 'icon-alert-circle-exc', 5)
            else
                alertar(notificacao, 'br', "Não foi possivel obter o Caixa", 'warning', 'icon-alert-circle-exc', 2)
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel obter o Caixa", 'warning', 'icon-alert-circle-exc', 2)
    }
}