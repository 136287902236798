import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Select } from "../../../../components/ErnComponets/ErnComponets";
import * as controllerLancamento from '../../../../controller/Financeiro/controllerLancamentoTitulos'

import {
    Row,
    Col,
    FormGroup
} from "reactstrap";

const idStore = "LANCAMENTO_TITULOS";

const DadosConta = () => {
    const dispatch = useDispatch();
    const [descricoes, setDescricao] = useState({ fornecedor: "", subGrupoConta: "", grupoConta: "" })
    const contas = useSelector(state => state.lancamentoTitulos.contas, shallowEqual)
    const grupoContas = useSelector(state => state.lancamentoTitulos.grupoContas, shallowEqual)
    const subContas = useSelector(state => state.lancamentoTitulos.subContas, shallowEqual)

    const nomeFor = useSelector(state => state.lancamentoTitulos.cadastro.fornecedor.nomeFor, shallowEqual)
    const tipoLancamento = useSelector(state => state.lancamentoTitulos.cadastro.tipoLancamento, shallowEqual)

    const dadosConta = useSelector(state => state.lancamentoTitulos.cadastro.dadosConta, shallowEqual)

    useEffect(() => {
        if (dadosConta.codSubgrupoConta !== '0') {
            setDescricao({ ...descricoes, fornecedor: nomeFor, grupoConta: dadosConta.descricaoGrupo, subGrupoConta: dadosConta.descricaoSubGrupo })
        } else {
            setDescricao({ ...descricoes, fornecedor: "", subGrupoConta: "", grupoConta: "" })
        }
    }, [dadosConta.codSubgrupoConta, dadosConta.codFornecedor, nomeFor])

    useEffect(() => {
            controllerLancamento.getContas()
            controllerLancamento.getGrupoContas(tipoLancamento)
    }, [tipoLancamento])

    function onChangeGrupo(value, campo) {

        if (campo === "codGrupoConta") {
            controllerLancamento.getSubContas(value);
            dispatch({ idStore, type: "DADOS_CONTA", value: { ...dadosConta, [campo]: value, codSubgrupoConta: '0' } })
        }
        if (campo === "codSubgrupoConta") {
            if (value >= 1) {
                let objSubGrupo = subContas.find(subGrupo => subGrupo.codSubgrupoConta === value)
                dispatch({ idStore, type: "DADOS_CONTA", value: { ...dadosConta, descricaoSubGrupo: objSubGrupo.descricao, descricaoGrupo: objSubGrupo.descGrupoConta, descricao: `${objSubGrupo.descGrupoConta} - ${objSubGrupo.descricao} - ${nomeFor}` , [campo]: value } })
            }
        }
        if (campo === "codConta") {
            let objconta = contas.find(conta => conta.codConta = value)
            dispatch({ idStore, type: "DADOS_CONTA", value: { ...dadosConta, conta: objconta.descricao, [campo]: value } })
        }

    }

    return (
        <>
            <Col lg="7">
                <fieldset style={{ border: 'solid 1px #5E6680', borderRadius: 5 }}>
                    <legend style={{ fontSize: '13px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
                        Dados da Conta
                    </legend>

                    <Row className="padding-padrao-row">
                        <Col className="padding-padrao-col">
                            <Row className="padding-padrao-row">
                                <Col className="padding-padrao-col">
                                    <label>{tipoLancamento === 'D' ? "Conta" : "Conta"}<span className="error"> *</span></label>
                                    <Select
                                        first="Selecione"
                                        value={{ value: dadosConta.codConta }}
                                        select={{ value: "codConta", label: "descricao" }}
                                        options={contas}
                                        onChange={(props) => onChangeGrupo(props.value, "codConta")}
                                        isSearchable={true}
                                        autoComplete="off"
                                    />
                                </Col>
                            </Row>
                            <Row className="padding-padrao-row">
                                <Col className="padding-padrao-col">
                                    <label>{tipoLancamento === 'D' ? "Grupo de Conta" : "Grupo de Conta"}<span className="error"> *</span></label>
                                    <Select
                                        first="Selecione"
                                        value={{ value: dadosConta.codGrupoConta }}
                                        select={{ value: "codGrupoConta", label: "descricao" }}
                                        options={grupoContas}
                                        onChange={(props) => onChangeGrupo(props.value, "codGrupoConta")}
                                        isSearchable={true}
                                        autoComplete="off"
                                    />
                                </Col>
                            </Row>
                            <Row className="padding-padrao-row">
                                <Col className="padding-padrao-col">
                                    <label>{tipoLancamento === 'D' ? "SubGrupo de Conta" : "SubGrupo de Conta"}<span className="error"> *</span></label>
                                    <Select
                                        first="Selecione"
                                        value={{ value: dadosConta.codSubgrupoConta }}
                                        select={{ value: "codSubgrupoConta", label: "descricao" }}
                                        options={subContas}
                                        onChange={(props) => onChangeGrupo(props.value, "codSubgrupoConta")}
                                        isSearchable={true}
                                        autoComplete="off"
                                    />
                                </Col>
                            </Row>
                            <Row className="padding-padrao-row" style={{ height: "143px" }}>
                                <Col className="padding-padrao-col">
                                    <FormGroup>
                                        <label>{tipoLancamento === 'D' ? "Descrição" : "Descrição"}<span className="error"> *</span></label>
                                        <textarea
                                            class="form-control" style={{ width: "100%", height: "250%" }}
                                            value={dadosConta.descricao}
                                            onChange={(props) => controllerLancamento.atualizaDescricao(props.currentTarget.value)}
                                            placeholder={descricoes.grupoConta !== "" ? `${descricoes.grupoConta} - ${descricoes.subGrupoConta} - ${descricoes.fornecedor} - XXXXX` : ""}
                                        >
                                        </textarea>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    
                </fieldset>
            </Col>
        </>
    )
}
export default DadosConta

