import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import ReactTable from "react-table";

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from '../../../../components/ModalBasic/ModalBasic.jsx';
import { Input, Info } from '../Components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import useScrollBar from '../../../../Util/Hooks/useScrollBar';

import { maskNumbers } from '../../../../Util/mascaras'

import { InputCpfCnpj } from '../../../../components/ErnComponets/ErnComponets'

type Toggle = (newState?: boolean, callback?: (state?: boolean) => void) => void

interface Props {
    isOpen: boolean
    toggle: Toggle
}

export default function ModalSaidas({ isOpen, toggle }: Props) {
    const tableRef = useScrollBar()

    const [pesquisaSaida, setPesquisaSaida] = useState({ cnpjEmissor: '', nomeEmissor: '', dataEmissao: '' })

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title={'Pesquisa de Saídas'}
            size="lg"
        >
            <ModalBasicBody>
                <h4 style={{ textAlign: 'center' }}>Informe os dados abaixo para prosseguir</h4>
                <Row>
                    <Col md="3">
                        <label>CPF/CNPJ</label>
                        <InputCpfCnpj
                            type="text"
                            value={pesquisaSaida.cnpjEmissor}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => setPesquisaSaida({ ...pesquisaSaida, cnpjEmissor: maskNumbers(e.currentTarget.value) })}
                            autoComplete="off"
                            maxLength="14"

                        />

                        <br></br>
                    </Col>
                    <Col md="5">
                        <label>Nome / Razão Social</label>
                        <Input
                            type="text"
                            value={pesquisaSaida.nomeEmissor}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => setPesquisaSaida({ ...pesquisaSaida, nomeEmissor: e.currentTarget.value })}
                            autoComplete="off"
                        />
                        <br></br>
                    </Col>
                    <Col md="3">
                        <label>Data Emissão</label>
                        <Input
                            type="date"
                            value={pesquisaSaida.dataEmissao}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => setPesquisaSaida({ ...pesquisaSaida, dataEmissao: e.currentTarget.value })}
                            autoComplete="off"
                        />
                        <br></br>
                    </Col>
                    <Col lg={1} md={1} sm={1}>
                        <Button
                            type="button"
                            style={{ marginTop: 34, width: 40, marginBottom: 0 }}
                            color="info"
                        >
                            <Icon icon="search" className="fa-lg" />
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <fieldset className="fieldset-operacao">
                            <legend>Resultados</legend>
                            <div
                                ref={tableRef}
                            >
                                <ReactTable
                                    data={[]}
                                    columns={[
                                        {
                                            Header: 'Número',
                                            accessor: 'numero',
                                            minWidth: 40,
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Descrição',
                                            accessor: 'descricao',
                                            resizable: false,
                                            sortable: false
                                        }]}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                    noDataText=""
                                />
                            </div>
                        </fieldset>
                    </Col>

                </Row>

                <Col className="text-right">
                    <Info>
                        {`${0} saídas encontradas`}
                    </Info>
                </Col>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        // onClick={() => concluir()}
                        color={"info"}
                    >CONCLUÍDO
                    </Button>
                </Col>
            </ModalBasicFooter>

        </ModalBasic>
    )
}