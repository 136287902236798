import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import classNames from "classnames";
import { alertar } from "~/Util/alertar";
import * as controllerClassificacoes from "~/controller/controllerClassificacoesTributarias";

import ReactWizard from "~/components/Wizard/Wizard";

// wizard steps
import StepGeral from './CadastroSteps/Geral';
import StepICMS from './CadastroSteps/ICMS';
import StepPISCOFINS from './CadastroSteps/PISCOFINS';
import StepIPI from './CadastroSteps/IPI';
import StepResumo from './CadastroSteps/Resumo';


var steps = [
    {
        stepName: "Geral",
        stepIcon: "tim-icons icon-notes",
        component: StepGeral,
        stepProps: {}
    },
    {
        stepName: "ICMS",
        stepIcon: "tim-icons icon-notes",
        component: StepICMS,
        stepProps: {}
    },
    {
        stepName: "IPI",
        stepIcon: "tim-icons icon-notes",
        component: StepIPI,
        stepProps: {}
    },
    {
        stepName: "PIS/COFINS",
        stepIcon: "tim-icons icon-notes",
        component: StepPISCOFINS,
        stepProps: {}
    },
    {
        stepName: "Resumo",
        stepIcon: "tim-icons icon-notes",
        component: StepResumo,
        stepProps: {}
    },
];

const idStore = "CLASSIFICACOES_TRIBUTARIAS";


const Tributacao = ({ width, closeTributacao }) => {
    const dispatch = useDispatch();
    const notificacao = useSelector(state => state.notificacao);
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
    const { classificacao: { codClassificacao }, tributacao, tributacaoCopia, inicioCadastro,verCadastro } = useSelector(state => state.classificacoesTributarias);
    const [activeStep, setActiveStep] = useState(0)

    useEffect(() => {
        steps.forEach(step => {
            step.stepProps = { ...step.stepProps, tributacao: tributacao }
        });
        setInicioCadastro(false)
    }, [tributacao])

    useEffect(() => {
        steps.forEach(step => {
            step.stepProps = { ...step.stepProps, notificacao }
        });
    }, [notificacao])

    useEffect(() => {
        if (inicioCadastro)
            setInicioCadastro(false)
    }, [inicioCadastro])

    function setInicioCadastro(valor) {
        dispatch({ idStore, type: 'INICIO_CADASTRO', value: valor });
    }

    async function finishButtonClick() {
        if(verCadastro){
           return dispatch({idStore, type:'VER_CADASTRO', value: false})
        }
        else {  
            var error;
            error = await controllerClassificacoes.FinishTributacao(notificacao, dispatch, codEmpresa, codClassificacao, tributacao, tributacaoCopia)
            handleError(error)

            function handleError(error) {
                if (!error) {
                    if (tributacao.codTributacao === 0) {
                        alertar(notificacao, 'br', "Tributação cadastrada com sucesso", 'success', 'icon-check-2', 2);
                    } else {
                        alertar(notificacao, 'br', "Tributação atualizada com sucesso", 'success', 'icon-check-2', 2);
                    }
                    closeTributacao();
                }
            }
            return error}
    }

    function changeActiveStep(value) {
        setActiveStep(value)
    }

    return (
        <div>
            <div style={{ marginTop: '-20px' }}>
                {activeStep === 4 ? <h4 className="justfy-text-center" style={{ textAlign: "center" }}>Classificações Tributárias</h4> : ""}
                <ReactWizard
                    steps={steps}
                    navSteps={true}
                    title=""
                    description=""
                    validate
                    resizable={true}
                    changeStep={changeActiveStep}
                    previousButtonText="Voltar"
                    finishButtonText="Finalizar"
                    nextButtonText="Avançar"
                    goToFirstStep={inicioCadastro}
                    finishButtonClick={() => finishButtonClick()}
                    finishButtonClasses={classNames("btn-wd btn-info", { finishButtonMin: width < 550 }, { finishButtonRight: !width < 550 })}
                    nextButtonClasses={classNames("btn-wd btn-info", { nextButtonMin: width < 550 }, { nextButtonRight: !width < 550 })}
                    previousButtonClasses={classNames("btn-wd", { previousButtonMin: width < 550 }, { previousButtonRight: !width < 550 })}
                    progressbar
                    color="blue"
                />
            </div>
        </div>
    )
}

export default Tributacao;