import React from "react";

var selected = 1;

function itemFocus(event, props) {
        var element;
        if (event.keyCode === 38) {
            //seta cima
            if (selected === 1) {
            } else {
                selected = selected - 1
                element = (props.element + selected)
                document.getElementById(element).focus();
            }
        } else if (event.keyCode === 40) {
            //seta baixo
            if (props.list.length !== selected) {
                selected += 1
                element = (props.element + selected)
                document.getElementById(element).focus();
            }
        }
    
}

const ListView = (props) => {

    function handleSelectItem(list, e) {
        e.preventDefault();
        props.onClick(list)
    }
    function handleKeyPress(event) {
        itemFocus(event, props)
    }

    let i = 0;
    return (
        <div className="list">
            <ul>
                {props.list.map((item) => (
                    <li key={i}> <button id={props.element + (i += 1)}
                        type="button"
                        style={
                            props.list[props.list.length - 1][props.text] === item[props.text] ?
                                props.list.length === 1 ?
                                    {
                                        borderBottomRightRadius: '4px',
                                        borderBottomLeftRadius: '4px',

                                    } :
                                    {
                                        borderTop: '1px solid rgba(29, 37, 59, 0.2)!important',
                                        borderBottomRightRadius: '4px',
                                        borderBottomLeftRadius: '4px',
                                        borderTopRightRadius: '0px',
                                        borderTopLeftRadius: '0px',
                                    }
                                : {
                                    borderBottom: '1px solid rgba(29, 37, 59, 0.2)',
                                }
                        }
                        onClick={(e) => handleSelectItem(item, e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                    >
                        {item[props.text]}</button></li>))
                }
            </ul>
        </div>
    );
}

export default ListView;
