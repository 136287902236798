export function maskCEST(value) {
    let newValue = value.replace(/[\D]/g, '')
    let length = newValue.length
    if (length > 2 && length <= 5) {
        newValue = newValue.replace(/(\d{2})/, "$1.");
        return newValue
    }
    if (length > 5) {
        newValue = newValue.replace(/(\d{2})(\d{3})/, "$1.$2.");
        return newValue
    }
    return newValue
}

export function maskNCM(value) {
    let newValue = value.replace(/[\D]/g, '')
    let length = newValue.length
    if (length > 4 && length <= 6) {
        newValue = newValue.replace(/(\d{4})/, "$1.");
        return newValue
    }
    if (length > 6) {
        newValue = newValue.replace(/(\d{4})(\d{2})/, "$1.$2.");
        return newValue
    }
    return newValue
}

export function maskICMS(value) {
    value = value.toString()
    let newValue = value.replace(/[\D]/g, '')
    return newValue
}

export function maskCFOP(value) {
    let newValue = value.replace(/[\D]/g, '')
    let length = newValue.length
    if (length > 1) {
        newValue = newValue.replace(/(\d{1})/, "$1.");
        return newValue
    }
    return newValue
}

export function maskNumbers(value) {
    let newValue = value.replace(/[\D]/g, '')
    return newValue
}

export function maskIPI(value) {
    let newValue = value.replace(/[\D]/g, '')
    return newValue
}

export function maskEnquadramentoIPI(value) {
    let newValue = value.replace(/[\D]/g, '')
    return newValue
}

export function maskString(value) {
    let newValue = value.replace(/[^a-zA-Z ]/, '')
    return newValue.toUpperCase()
}

export function maskCNPJ(value) {
    let newValue = value.toString().replace(/\D/g, '')
        .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
    return newValue
}

export function maskCPF(value) {
    let newValue = value.toString().replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    return newValue
}

export function maskValor(value) {
    var newValue = value.toString().replace(/\D+/g, '')
    newValue = newValue.toString().replace(/[^\d]+/g, '')
    newValue = newValue.replace(/(\d{1})(\d{2})$/, "$1,$2") // coloca ponto antes dos ultimos digitos 
    return newValue
}

export function maskMoeda(value) {
    var newValue = value.toString().replace(/\D/g, '');
    newValue = newValue.replace(/\D/g, "") // permite digitar apenas numero
    newValue = newValue.replace(/(\d{1})(\d{14})$/, "$1.$2") // coloca ponto antes dos ultimos digitos
    newValue = newValue.replace(/(\d{1})(\d{11})$/, "$1.$2") // coloca ponto antes dos ultimos 11 digitos
    newValue = newValue.replace(/(\d{1})(\d{8})$/, "$1.$2") // coloca ponto antes dos ultimos 8 digitos
    newValue = newValue.replace(/(\d{1})(\d{5})$/, "$1.$2") // coloca ponto antes dos ultimos 5 digitos
    newValue = newValue.replace(/(\d{1})(\d{1,2})$/, "$1,$2") // coloca virgula antes dos ultimos 2 digitos
    return newValue
}

export function maskMarkup(value) {
    var newValue = value.toString().replace(/\D/g, '');
    newValue = (newValue / 100).toFixed(2) + '';
    newValue = newValue.replace(".", ",");
    newValue = newValue.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    newValue = newValue.replace(/(\d)(\d{3}),/g, "$1.$2,");
    return newValue
}

export function maskClassificacoes(value) {
    var newValue = value.toString().replace(/\D+/g, '')
    newValue = newValue.toString().replace(/[^\d]+/g, '')
    newValue = newValue.replace(/(\d{1})(\d{2})$/, "$1.$2") // coloca ponto antes dos ultimos digitos 
    newValue = newValue.replace('.', ',') // troca pontos por virgulas
    return newValue
}
