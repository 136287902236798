import React, { useEffect, Dispatch } from "react";
import { useDispatch } from "react-redux";

import {
    Card,
    Col,
    CardHeader,
    CardBody,
    CardTitle
} from "reactstrap";

import TabContent from "./TabContent/TabContent"
import TelaPesquisar from './TabContent/Tabs/Pesquisar';
import TelaGerarSaida from './TabContent/Tabs/GerarSaida';

import { Produto } from '../../../store/reducers/Movimentacao/SaidaDiversa/types'

import './style.css'
import { setProduto, addProduto, cleanAll } from "../../../store/reducers/Movimentacao/SaidaDiversa/actions";

const Tabs = [
    {
        tabName: "PESQUISAR",
        tabScreen: <TelaPesquisar />
    },
    {
        tabName: "GERAR SAÍDA",
        tabScreen: <TelaGerarSaida />
    },
]

const SaidasDiversas: React.FC = () => {
    useEffect(() => {
        return () => {
            const produto: Produto = {
                numeroPedidoItem: 0,
                item: 0,
                codProduto: 0,
                descricao: '',
                numero: '',
                quantidade: '',
                valorTotal: '',
                valorUnitario: '',
                dataLancamento: '',
                status: 0,
                flControlaGrade: 0,
                grades: [],
            }

            setProduto(produto);
            addProduto([])

            cleanAll()
        }
    }, [])

    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="12">
                    <Card>

                        <CardHeader className="text-center">
                            <CardTitle tag="h3">Saídas Diversas</CardTitle>
                        </CardHeader>

                        <CardBody>
                            <TabContent tabs={Tabs} />
                        </CardBody>
                    </Card>
                </Col>
            </div>

        </>
    );
}

export default SaidasDiversas;
