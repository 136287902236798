import React from 'react'
import { Button, FormGroup, Input } from 'reactstrap'
import ArrowTooltip from '../../../../../../../components/Tooltip/ArrowTooltip'


interface InputProps {
    id?: any,
    type?: any,
    value: any,
    onChange: Function | any
    autoCompleted?: any,
    length?: any,
    className?: any,
    label?: any,
    disabled?: any,
    span?: boolean,
    style?: any
}

interface ButtonProps {
    clasName?: any,
    arrowToolTip?: boolean,
    arrowToolTipTitle?: any,
    color?: any,
    onClick: Function | any
    label?: any
}

export const InputCadastro = (props: InputProps) => {
    return (
        <>
            <label>{props.label} {props.span ? <span className="error">*</span> : null}</label>

            <FormGroup className={props.className}>

                <Input
                    id={props.id}
                    type={props.type}
                    value={props.value}
                    onChange={props.onChange}
                    autoComplete={props.autoCompleted}
                    maxLength={props.length}
                    disabled={props.disabled}
                    Style={props.style}
                />

                <br></br>

            </FormGroup>
        </>)
}

export const ButtonCadastro = (props: ButtonProps) => {
    return (
        <>
            { props.arrowToolTip ?

                <ArrowTooltip title={props.arrowToolTipTitle}>
                    <Button className={props.clasName} color={props.color} onClick={props.onClick}>{props.label}</Button>
                </ArrowTooltip>
                :
                <Button className={props.clasName} color={props.color} onClick={props.onClick}>{props.label}</Button>

            }
        </>

    )
}