import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import ReactTable from "react-table";

import { saidaProduto as controllerSaidaProduto } from "../../../../controller/Movimentacao/controllerSaidaDiversa";

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "../../../../components/ModalBasic/ModalBasic";
import { Input, InputNumber, Info } from '../Components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import useScrollBar from '../../../../Util/Hooks/useScrollBar';
import { Produto } from '../../../../store/reducers/Movimentacao/SaidaDiversa/types';
import { Item } from '../../../../store/reducers/Movimentacao/PedidoVenda/types';

interface ModalProdutosProps {
    isOpen: boolean,
    toggle: () => void,
    getProduto: (codProduto: number) => void,
    produto: Produto,
    produtosList: Item[]
}

const produtoModelo: Produto = {
    numeroPedidoItem: 0,
    item: 0,
    codProduto: 0,
    numero: '',
    descricao: '',
    valorUnitario: '',
    quantidade: '',
    valorTotal: '',
    dataLancamento: '',
    status: 0,
    flControlaGrade: 0,
    grades: []
}

const ModalProdutos: React.FC<ModalProdutosProps> = ({ isOpen, toggle, getProduto, produto, produtosList }) => {
    const tableRef = useScrollBar([isOpen])
    const inputNumCod = useRef<HTMLInputElement>(null)

    const [pesquisa, setPesquisa] = useState<Produto>(produtoModelo)
    const [produtos, setProdutos] = useState(produtosList)

    useEffect(() => {
        if (inputNumCod.current)
            inputNumCod.current.focus()
    }, [])

    async function getProdutos() {
        const response = await controllerSaidaProduto.GetProdutos(pesquisa.numero, pesquisa.descricao)
        setProdutos(response)
    }

    function onClickProduto(rowIndex: number) {
        let produtoSelected = produtos[rowIndex]
        setPesquisa({
            numeroPedidoItem: 0,
            item: 0,
            codProduto: produtoSelected.codProduto,
            numero: produtoSelected.numProduto.toString(),
            descricao: produtoSelected.nome,
            valorUnitario: "",
            quantidade: "",
            valorTotal: "",
            dataLancamento: "",
            status: 0,
            flControlaGrade: 0,
            grades: []
        })
    }

    function onDoubleClickProduto(rowIndex: number) {
        let produtoSelected = produtos[rowIndex]
        setPesquisa({
            numeroPedidoItem: 0,
            item: 0,
            codProduto: produtoSelected.codProduto,
            numero: produtoSelected.numProduto.toString(),
            descricao: produtoSelected.nome,
            valorUnitario: "",
            quantidade: "",
            valorTotal: "",
            dataLancamento: "",
            status: 0,
            flControlaGrade: 0,
            grades: []
        })
        getProduto(produtoSelected.codProduto)
        toggle()
    }

    function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            getProdutos()
        }
    }

    function limpar() {
        setPesquisa(produtoModelo)
        setProdutos([])
    }

    function getTrProps(_state: any, row: any, column: any) {
        return {
            style: {
                height: '50px',
                paddingTop: '0px',
                paddingBottom: '5px',
                backgroundColor: produtos[row.index].codProduto === pesquisa.codProduto ? 'rgba(71, 179, 255, 0.41)' : ''
            },
            onClick: (e: React.MouseEvent<HTMLDivElement>, handleOriginal: () => void) => {
                onClickProduto(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            },
            onDoubleClick: (e: React.MouseEvent<HTMLDivElement>, handleOriginal: () => void) => {
                onDoubleClickProduto(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            }
        }
    }

    function getTdProps(_state: any, _rowInfo: any, column: any) {
        if (column.id === 'actions') {
            return { style: { padding: 0 } }
        } else {
            return {}
        }
    }

    function concluir() {
        if (pesquisa.codProduto) {
            getProduto(pesquisa.codProduto)
        }
        toggle()
    }

    return (
        <ModalBasic
            isOpen={true}
            toggle={() => toggle()}
            title={'Produtos'}
            size="lg"
        >
            <ModalBasicBody>
                <Row>
                    <Col lg md sm>
                        <label>Número / Código de Barras</label>
                        <InputNumber
                            innerRef={inputNumCod}
                            format={'20,0'}
                            value={pesquisa.numero}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => setPesquisa({ ...pesquisa, numero: e.currentTarget.value })}
                            onKeyPress={handleKeyPress}
                            onFocus={(e: React.FormEvent<HTMLInputElement>) => e.currentTarget.select()}
                        />
                    </Col>
                    <Col lg md sm>
                        <label>Descrição</label>
                        <Input
                            value={pesquisa.descricao}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => setPesquisa({ ...pesquisa, descricao: e.currentTarget.value })}
                            onKeyPress={handleKeyPress}
                        />
                    </Col>
                    <Col lg={1} md={1} sm={1}>
                        <Button
                            type="button"
                            style={{ marginTop: 34, width: 40, marginBottom: 0 }}
                            color="info"
                            onClick={getProdutos}
                        >
                            <Icon icon="search" className="fa-lg" />
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <fieldset className="fieldset-operacao">
                            <legend>Resultados</legend>
                            <div
                                ref={tableRef}
                                style={produtos.length !== 0 ?
                                    { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative' } :
                                    { visibility: 'hidden', opacity: '0' }}
                            >
                                <ReactTable
                                    data={produtos}
                                    columns={[
                                        {
                                            Header: 'Nº Produto',
                                            accessor: 'numProduto',
                                            minWidth: 30,
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Código de barras',
                                            accessor: 'codBarras',
                                            minWidth: 40,
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Nome',
                                            accessor: 'nome',
                                            resizable: false,
                                            sortable: false
                                        }]}
                                    getTrProps={getTrProps}
                                    getTdProps={getTdProps}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                    noDataText=""
                                    pageSize={produtos.length}
                                />
                            </div>
                        </fieldset>
                    </Col>

                </Row>

                <Col className="text-right">
                    <Info>
                        {`${produtos.length} produtos encontrados`}
                    </Info>
                </Col>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        onClick={() => concluir()}
                        color={"info"}
                    >CONCLUÍDO
                    </Button>
                    <Button
                        className="btn-padrao"
                        onClick={() => limpar()}
                        color={"info"}
                    >LIMPAR
                    </Button>
                </Col>
            </ModalBasicFooter>

        </ModalBasic>
    )
}

export default ModalProdutos