import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios';
import api from "~/Util/api.js";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import './style.css';

function useOutsideClose(ref, toggle) {

    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            toggle()
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    })
}

export function HelpPlugin() {
    const helpPluginRef = useRef(null)
    const dispatch = useDispatch()
    const { help } = useSelector(state => state.sistema)
    const [isOpen, setisOpen] = useState(false)
    const [img, setimg] = useState('')
    const [video, setvideo] = useState('')
    const [html, sethtml] = useState('')
    const [title, setTitle] = useState('')

    useOutsideClose(helpPluginRef, toggle)

    useEffect(() => {
        if (help !== '') {
            const basicAuth = 'Basic ' + btoa(api.apiUserAdmin + ':' + api.apiPassAdmin);
            axios.get(api.urlErnAdm + help, {
                headers: { Authorization: basicAuth }
            }).then(response => {
                setimg(response.data.records.imagem)
                setvideo(response.data.records.pathVideo)
                sethtml(response.data.records.descricaoHtml)
                setTitle(response.data.records.campoSistema)
                setisOpen(true)
            }).catch(err => {
                setimg('')
                setvideo('')
                sethtml('')
                setTitle('')
                setisOpen(true)
            })
            dispatch({ idStore: 'SISTEMA', type: 'HELP', value: '' })
        }
    }, [help])

    function toggle() {
        setisOpen(false)
    }

    return (
        <div ref={helpPluginRef}>
            <div className={`help-plugin${isOpen ? ' help-opened' : ' help-closed'}`}>
                <div className={`help`}>
                    <div className="container">
                        <div>
                            <h2 style={{ textAlign: "center", marginBottom: 5 }}>{title}</h2>
                        </div>
                        <button className="btn-close" onClick={toggle}>X</button>
                        <div className="content">

                            {html || img || video ?
                                <>
                                    {img ?
                                        <>
                                            <div className="left">
                                                < img src={img} alt="imagem de ajuda" />
                                                {video ?
                                                    <div className="video">
                                                        <p> Veja este vídeo se ainda tiver alguma dúvida:{" "}
                                                            <a href={video} target="_blank" rel="noopener  noreferrer">Ajude-me</a>
                                                        </p>
                                                    </div>
                                                    : null}
                                            </div>
                                            <div dangerouslySetInnerHTML={{ __html: html }} />
                                        </>
                                        :
                                        <div className="withoutimg">
                                            <div dangerouslySetInnerHTML={{ __html: html }} />
                                            {video ?
                                                <div className="video">
                                                    <p> Veja este vídeo se ainda tiver alguma dúvida:{" "}
                                                        <a href={video} target="_blank" rel="noopener  noreferrer">Ajude-me</a>
                                                    </p>
                                                </div>
                                                : null}
                                        </div>
                                    }
                                </>
                                :
                                <div className="not-found"><p>Nenhuma dica encontrada</p></div>
                            }
                        </div>
                    </div>
                    <span className="arrow" />
                </div>
            </div>
        </div >
    )
}

export function HelpButton({ id }) {
    const dispatch = useDispatch()

    function openHelpPlugin() {
        dispatch({ idStore: 'SISTEMA', type: 'HELP', value: "api/v1/ajudaSistema/" + id })
    }

    return (
        <div className="help-button" onClick={openHelpPlugin}>
            <div className="backgroud" />
            <Icon icon="question-circle" className="fa-md help-icon" color="#2196F3" />
        </div>
    )
}