import store from "../../../stores";

import { 
    AberturaCaixaTypes, 
    AberturaCaixaStore, 
    AberturaCaixaAction, 
    IAberturaCaixaState 
} from "./types";

export const cleanAll = () => store.dispatch<AberturaCaixaAction>({
    idStore: AberturaCaixaStore.ID_STORE,
    type: AberturaCaixaTypes.CLEAN_ALL
})

export const cleanPesquisa = () => store.dispatch<AberturaCaixaAction>({
    idStore: AberturaCaixaStore.ID_STORE,
    type: AberturaCaixaTypes.CLEAN_PESQUISA
})

export const cleanLancamento = () => store.dispatch<AberturaCaixaAction>({
    idStore: AberturaCaixaStore.ID_STORE,
    type: AberturaCaixaTypes.CLEAN_LANCAMENTO
})

export const cleanAbertura = () => store.dispatch<AberturaCaixaAction>({
    idStore: AberturaCaixaStore.ID_STORE,
    type: AberturaCaixaTypes.CLEAN_ABERTURA
})

export const setFuncionarios = (funcionarios: IAberturaCaixaState['funcionarios']) => store.dispatch<AberturaCaixaAction>({
    idStore: AberturaCaixaStore.ID_STORE,
    type: AberturaCaixaTypes.FUNCIONARIOS,
    funcionarios,
})

export const setPesquisa = (pesquisa: IAberturaCaixaState['pesquisa']) => store.dispatch<AberturaCaixaAction>({
    idStore: AberturaCaixaStore.ID_STORE,
    type: AberturaCaixaTypes.PESQUISA,
    pesquisa,
})

export const setLancamento = (lancamento: IAberturaCaixaState['lancamento']) => store.dispatch<AberturaCaixaAction>({
    idStore: AberturaCaixaStore.ID_STORE,
    type: AberturaCaixaTypes.LANCAMENTO,
    lancamento,
})

export const setLancamentos = (lancamentos: IAberturaCaixaState['lancamentos']) => store.dispatch<AberturaCaixaAction>({
    idStore: AberturaCaixaStore.ID_STORE,
    type: AberturaCaixaTypes.LANCAMENTOS,
    lancamentos,
})

export const setAbertura = (abertura: IAberturaCaixaState['abertura']) => store.dispatch<AberturaCaixaAction>({
    idStore: AberturaCaixaStore.ID_STORE,
    type: AberturaCaixaTypes.ABERTURA,
    abertura,
})

export const setTiposLancamento = (tipoLancamentos: IAberturaCaixaState['tipoLancamentos']) => store.dispatch<AberturaCaixaAction>({
    idStore: AberturaCaixaStore.ID_STORE,
    type: AberturaCaixaTypes.TIPO_LANCAMENTO,
    tipoLancamentos,
})

export const setAberturaRetorno = (aberturaRetorno: any) => store.dispatch<AberturaCaixaAction>({
    idStore: AberturaCaixaStore.ID_STORE,
    type: AberturaCaixaTypes.ABERTURA_RETORNO,
    value: aberturaRetorno,
})

export const setCaixas = (caixas: IAberturaCaixaState['caixas']) => store.dispatch<AberturaCaixaAction>({
    idStore: AberturaCaixaStore.ID_STORE,
    type: AberturaCaixaTypes.CAIXAS,
    caixas
})

export const setScreen = (screen: number) => store.dispatch<AberturaCaixaAction>({
    idStore: AberturaCaixaStore.ID_STORE,
    type: AberturaCaixaTypes.SCREEN,
    value: screen
})

export const setBlock = (value: boolean) => store.dispatch<AberturaCaixaAction>({
    idStore: AberturaCaixaStore.ID_STORE,
    type: AberturaCaixaTypes.BLOCK,
    value
})