import React, { useCallback, useEffect, useState } from 'react'
import ReactTable from 'react-table'
import moment from 'moment'
import { Button, CardFooter, Col, Input, Modal, ModalBody, Row } from 'reactstrap'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { Select } from '../../../../components/ErnComponets/ErnComponets'
import AppState from '../../../../store/storeType'
import { IFaturamentoState } from '../../../../store/reducers/Modals/Faturamento/types'
import { IRetiradasCaixa } from '../../../../store/reducers/Movimentacao/PedidoVenda/types'
import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector'

import * as functions from '../../../../components/Modals//Faturamento/functions'
import * as action from '../../../../store/reducers/Movimentacao/PedidoVenda/actions'
import * as controllerPDV from '../../../../controller/Movimentacao/controllerPedidoVenda'
import { maskMoeda } from '../../../../Util/mascaras'
import useScrollBar from '../../../../Util/Hooks/useScrollBar'




interface PopUpRetiradaCaixaProps {
  isOpen: any;
  toggle: any;
  callback: any;

}

const PopUpRetiradaCaixa: React.FC<PopUpRetiradaCaixaProps> = ({ isOpen, toggle, callback }) => {
  const pagamentos = useShallowEqualSelector<AppState, IFaturamentoState['pagamentos']>(state => state.modalFaturamento.pagamentos)
  const retiradaCaixa = useShallowEqualSelector<AppState, IRetiradasCaixa>(state => state.pedidoVenda.retiradaCaixa)
  const retiradasCaixa = useShallowEqualSelector<AppState, IRetiradasCaixa[]>(state => state.pedidoVenda.retiradasCaixa)

  const refTableRetiradaCaixa = useScrollBar()
  const [total, setTotal] = useState(0)

  useEffect(() => {
    functions.getFormasPagamento()
  }, [])

  const reduceSum = useCallback((data: any[], ) => {
    let itensSum = data.reduce((itemValue, value) => {
      return Number(itemValue) + Number(value)
    }, 0)
    return itensSum
  }, [])

  useEffect(() => {
    if(retiradasCaixa.length > 0){
      const sumTotal = retiradasCaixa.map(retirada => retirada.valorLancamento)
      const total = reduceSum(sumTotal)

      setTotal(total)
    }
  }, [retiradasCaixa])

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle && toggle()}
      id="modalRetiradaCaixa"
    >
      <div className="modal-header justify-content-center">
        <button
          aria-hidden={true}
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle && toggle()}
        >
          <i className="tim-icons icon-simple-remove" />
        </button>
        <h4 className="title title-up">Retirada de Caixa</h4>
      </div>
      <ModalBody>
        <Col className="padding-padrao-col">
          <Row md="12" className="padding-padrao-row">
            <Col md="8" className="padding-padrao-col">
              <label htmlFor="">Forma de Retirada</label>
              <Select
                first="Selecione"
                //@ts-ignore
                options={pagamentos}
                autoComplete="off"
                isSearchable={false}
                select={{ value: 'codForma', label: 'descricao' }}
                onChange={(e: any) => action.addRetiradasCaixa({ ...retiradaCaixa, codigoFormaPagamento: e.value })}
                value={{ value: retiradaCaixa.codigoFormaPagamento }}
              />
            </Col>
            <Col md="4" className="padding-padrao-col" >
              <label htmlFor="">Valor à Retirar</label>
              <Input
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => action.addRetiradasCaixa({ ...retiradaCaixa, valorLancamento: maskMoeda(e.target.value) })}
                value={maskMoeda(retiradaCaixa.valorLancamento)}
              />
            </Col>
          </Row>
          <Row md="12" className="padding-padrao-row">
            <Col className="padding-padrao-col">
              <label htmlFor="">Autorizador</label>
              <Input
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => action.addRetiradasCaixa({ ...retiradaCaixa, autorizador: e.target.value })}
                value={retiradaCaixa.autorizador}
              />
            </Col>
          </Row>
          <Row md="12" className="padding-padrao-row">
            <Col className="padding-padrao-col">
              <label htmlFor="">Observação</label>
              <textarea
                className="form-control"
                style={{ padding: '5px' }}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => action.addRetiradasCaixa({ ...retiradaCaixa, observacao: e.target.value })}
                value={retiradaCaixa.observacao}
              ></textarea>
            </Col>
          </Row>
          {retiradasCaixa.length > 0 && (
            <>
              <Row md="12" className="padding-padrao-row" >
                <Col className="padding-padrao-col">
                  <fieldset className="fieldset-operacao" >
                    <legend>Retiradas</legend>
                    <div
                      ref={refTableRetiradaCaixa}
                      style={{position: 'relative', maxHeight: 167}} 
                    >
                      <ReactTable
                        columns={[
                          {
                            Header: 'Data',
                            accessor: 'dataLancamento',
                            Cell: props => moment(props.original.dataLancamento).format('DD/MM/YYYY')
                          },
                          {
                            Header: 'Valor',
                            accessor: 'valorLancamento',
                            Cell: props => maskMoeda(Number(props.original.valorLancamento).toFixed(2))
                          },
                          {
                            Header: 'Forma Pgto',
                            accessor: 'descricaoForma'
                          },
                          {
                            Header: 'Descrição',
                            accessor: 'observacao'
                          },
                          {
                            Header: 'Ação',
                            Cell: props => (
                              <Button
                                size="sm"
                                className={"btn-icon btn-link"}
                                style={{ color: "#C3527B" }}
                                onClick={() => controllerPDV.excluirRetiradas(props.original.codigoCaixaLancto)}
                              >
                                <Icon icon="trash-alt" className="fa-lg" />
                              </Button>
                            )
                          }
                        ]}
                        data={retiradasCaixa}
                        minRows={1}
                        showPagination={false}
                        NoDataComponent={() => (<></>)}
                      />
                    </div>
                  </fieldset>
                </Col>
              </Row>
              <Row className="padding-padrao-row" style={{ display: 'flex', justifyContent: 'flex-end' }} >
                <Col style={{ width: '33%', textAlign: 'right' }} className="padding-padrao-col" md="3">
                  <label htmlFor="">Total</label>
                  <Input value={maskMoeda(Number(total).toFixed(2))} disabled />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </ModalBody>
      <CardFooter style={{ backgroundColor: 'transparent' }}>
        <Row className="justify-content-center">
          <Col className="text-center">
            <Button
              color="danger"
              type="button"
              onClick={() => toggle && toggle()}
              style={{ width: '150px' }}
            >VOLTAR</Button>
          </Col>
          <Col className="text-center">
            <Button
              color="success"
              type="button"
              onClick={() => callback()}
              style={{ width: '150px' }}
            >SALVAR</Button>
          </Col>
        </Row>

      </CardFooter>

    </Modal>
  )
}

export default PopUpRetiradaCaixa