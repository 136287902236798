//State da tela de Pesquisa e cadastro de transportadoras
var INITIAL_STATE = {
    pesquisa: {
        nomeRazao: '',
        apelidoFantasia: '',
        cpfCnpj: '',
        email: '',
        tipo: '',
        ativos: '',
    },
    transportadora: {
        codTrans: '',
        tipoTrans: 'J',
        nomeRazao: '',
        cpfCnpj: '',
        apelidoFantasia: '',
        email: '',
        email2: '',
        rgIncest: '',
        telefone1: '',
        telefone2: '',
        celular: '',
        nomeContato: '',
        telefoneContato: '',
        emailContato: '',
        cep: '',
        uf: '',
        cidade: '',
        bairro: '',
        logradouro: '',
        numero: '',
        complemento: '',
        referencia: '',
        ibge: '',
        gia: '',
        status: 0,
    },
    invalido: {
        nomeRazao: false,
        apelidoFantasia: false,
        cpfCnpj: false,
        email: false,
        email2: false,
        emailContato: false,
        cep: false,
    },
    transportadoras: [],
    inicioCadastro: false,
    verCadastro: false,
    proximoCadastro: false,
    transportadoraCopia: {},

};

export default function transportadoras(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'TRANSPORTADORAS':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'TRANSPORTADORAS':
                    return { ...state, transportadoras: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: {
                            nomeRazao: '',
                            apelidoFantasia: '',
                            cpfCnpj: '',
                            email: '',
                            tipo: '',
                            ativos: '',
                        },
                        transportadoras: []
                    }
                case 'TRANSPORTADORA':
                    return { ...state, transportadora: { ...state.transportadora, [action.campo]: action.value } }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'PROXIMO_CADASTRO':
                    return { ...state, proximoCadastro: action.value }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value } 
                case 'TRANSPORTADORA_COPIA':
                    return { ...state, transportadoraCopia: state.transportadora }
                case 'TRANSPORTADORA_COPIA_STATUS':
                    return { ...state, transportadoraCopia: { ...state.transportadora, status: action.value } }

                case 'CLEAN_TRANSPORTADORA':
                    return {
                        ...state,
                        transportadora: {
                            ...state.transportadora,
                            codTrans: '',
                            nomeRazao: '',
                            apelidoFantasia: '',
                            cpfCnpj: '',
                            nomeContato: '',
                            telefoneContato: '',
                            emailContato: '',
                            email: '',
                            email2: '',
                            rgIncest: '',
                            observacao: '',
                            telefone1: '',
                            telefone2: '',
                            celular: '',
                            cep: '',
                            uf: '',
                            cidade: '',
                            bairro: '',
                            logradouro: '',
                            numero: '',
                            complemento: '',
                            referencia: '',
                            ibge: '',
                            gia: '',
                            status: 0,
                            dtCadastro: '',
                        },
                    }
                case 'CLEAN_ENDERECO':
                    return {
                        ...state,
                        transportadora: {
                            ...state.transportadora,
                            cep: '',
                            uf: '',
                            cidade: '',
                            bairro: '',
                            logradouro: '',
                            numero: '',
                            complemento: '',
                            referencia: '',
                            ibge: '',
                            gia: '',
                        },
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: {
                            nomeRazao: false,
                            fantasia: false,
                            cpfCnpj: false,
                            email: false,
                            email2: false,
                            emailContato: false,
                            cep: false,
                        },
                    }

                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}
