import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import ReactWizard from "~/components/Wizard/Wizard";
import ReactTable from "react-table";
import * as controllerContas from "~/controller/controllerContas";
import classNames from "classnames";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';
import { useHotkeys } from 'react-hotkeys-hook';
import {
    Card,
    Col,
    Button,
    ButtonGroup,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Row,
    TabContent,
    TabPane,
    Modal,
    ModalBody,
    FormGroup,
    Label,
} from "reactstrap";

// wizard steps
import Step1 from "~/views/Contas/ContaSteps/Conta.jsx";

var steps = [
    {
        stepName: "Conta",
        stepIcon: "tim-icons icon-notes",
        component: Step1,
        stepProps: {}
    },
];

const idStore = 'CONTAS';

const Contas = () => {
    const notificacao = useSelector(state => state.notificacao);
    const { pesquisa, pesquisaFlags, flags, flagsCopia, contas, conta, contaCopia, inicioCadastro } = useSelector(state => state.contas);
    const dispatch = useDispatch();
    const [horizontalTabs, setHorizontalTabs] = useState('pesquisar');
    const [tableVisible, setTableVisible] = useState(false);
    const [modalExcluir, setModalExcluir] = useState(false);
    const [modalInativar, setModalInativar] = useState(false);
    const [contaSelected, setcontaSelected] = useState({ status: 0 });
    const [width, setWidht] = useState(window.innerWidth);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', updateWidth);
    }, [])

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', updateWidth);
        }
    }, [])

    function updateWidth() {
        setWidht(window.innerWidth);
    }

    useEffect(() => {
        steps.forEach(step => {
            step.stepProps = { ...step.stepProps, notificacao }
        });
    }, [notificacao])

    useEffect(() => {
        if (contas.length > 0) {
            setTableVisible(true);
        } else {
            setTableVisible(false);
        }
    }, [contas])


    useEffect(() => {
        steps.forEach(step => {
            step.stepProps = { ...step.stepProps, conta: conta }
        });
        setInicioCadastro(false)
    }, [contas])

    useEffect(() => {
        if (inicioCadastro)
            setInicioCadastro(false)
    }, [inicioCadastro])

    useHotkeys('shift+1', () => {
        changeActiveTab('pesquisar');
    }, [])

    useHotkeys('shift+2 ', () => {
        changeActiveTab("cadastrar");
        novoCadatro();
    }, [])

    useHotkeys('shift+p', () => {
        if (horizontalTabs === 'pesquisar') {
            handlePesquisar()
        }
    }, [pesquisa])

    useHotkeys('shift+l', () => {
        if (horizontalTabs === 'pesquisar') {
            handleLimpar();
        } else {
            dispatch({ idStore, type: 'CLEAN_CONTA' });
            dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        }
    }, [horizontalTabs])

    useHotkeys('shift+N', () => {
        dispatch({ idStore, type: 'CLEAN_CONTA' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    }, [])

    useHotkeys('shift+s', () => {
        if (horizontalTabs === "cadastrar") {
            if (conta.codConta === '') {
                controllerContas.CreateConta(notificacao, dispatch, conta);
            } else {
                controllerContas.UpdateConta(notificacao, dispatch, conta, contaCopia);
            }
        }
    }, [horizontalTabs, conta, contaCopia])

    function changeActiveTab(tabName) {

        setHorizontalTabs(tabName);
        if (tabName === "pesquisar") {
            setTimeout(() => {
                dispatch({ idStore, type: 'VER_CADASTRO', value: false });
                document.getElementById('descricaoContas').focus()
            }, 100)
        }
    };

    function handleOnChange(value, campo) {
        dispatch({ idStore, type: 'PESQUISA', campo, value });
    }

    function handleOnChangeAtivo(value) {
        dispatch({ idStore, type: 'PESQUISA', campo: 'status', value });
        controllerContas.Pesquisar(notificacao, dispatch, { ...pesquisa, status: value }, pesquisaFlags);
    }

    function handleOnChangeCheck(campo, value) {
        if (value === 0) {
            dispatch({ idStore, type: 'PESQUISA', campo, value: 1 })
        } else {
            dispatch({ idStore, type: 'PESQUISA', campo, value: 0 })
        }
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            controllerContas.Pesquisar(notificacao, dispatch, pesquisa);
        }
    }

    function handlePesquisar() {
        controllerContas.Pesquisar(notificacao, dispatch, pesquisa, pesquisaFlags);
    }

    function handleLimpar() {
        dispatch({ idStore, type: 'CLEAN_SEARCH' })
        dispatch({ idStore, type: 'CLEAN_PESQUISA_FLAGS' })
    }

    async function handleVisualizarCadastro(obj) {

        controllerContas.GetConta(notificacao, dispatch, obj.codConta)
        setHorizontalTabs("cadastrar")
        setInicioCadastro(true);
        setLoading(false)
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        dispatch({ idStore, type: 'VER_CADASTRO', value: true });


    }


    async function handleEditarCadastro(obj) {
        controllerContas.GetConta(notificacao, dispatch, obj.codConta)
        setHorizontalTabs("cadastrar")
        setInicioCadastro(true);
        setLoading(false)
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    };

    function handleInativar(obj) {
        setcontaSelected(obj);
        toggleModalInativar();
    }

    function handleExcluir(obj) {
        setcontaSelected(obj);
        toggleModalExcluir();
    }

    async function novoCadatro() {
        setInicioCadastro(true);
        dispatch({ idStore, type: 'CLEAN_CONTA' });
        dispatch({ idStore, type: 'CLEAN_FLAGS' })
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    }

    function inativar() {
        controllerContas.Inativar(notificacao, dispatch, contaSelected, pesquisa);
    }

    function excluir() {
        controllerContas.Excluir(notificacao, dispatch, contaSelected, pesquisa);
    }

    function setInicioCadastro(valor) {
        dispatch({ idStore, type: 'INICIO_CADASTRO', value: valor });
    }

    async function finishButtonClick() {
        var error = true

        if (conta.codConta === "0") {
            await controllerContas.CreateConta(notificacao, dispatch, conta, contaCopia, flags);
        } else {
            error = await controllerContas.UpdateConta(notificacao, dispatch, conta, contaCopia, flags, flagsCopia);
        }

        if (!error) {
            dispatch({ idStore, type: 'CLEAN_CONTA' });
            dispatch({ idStore, type: 'CLEAN_FLAGS' })
            setInicioCadastro(true);
        }
        return error
    }

    function toggleModalInativar() {
        setModalInativar(!modalInativar);
    }

    function toggleModalExcluir() {
        setModalExcluir(!modalExcluir);
    }

    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="12">
                    <Card>

                        <CardHeader className="text-center">
                            <CardTitle tag="h3">Contas</CardTitle>
                            <br />
                            <TabContent
                                className="tab-space"
                                activeTab={horizontalTabs}
                                style={{ marginTop: '-30px', marginBottom: '-70px' }}
                            >
                            </TabContent>
                        </CardHeader>

                        <CardBody>
                            <ButtonGroup style={{ width: '100%' }}>
                                <ArrowTooltip title='Shift+1'>
                                    <Button
                                        color={
                                            horizontalTabs === "pesquisar"
                                                ? "info"
                                                : ""
                                        }
                                        type="button"
                                        data-toggle="tab"
                                        className={classNames("btn-gp-padrao",
                                            horizontalTabs === "pesquisar"
                                                ? "active"
                                                : ""
                                        )
                                        }
                                        onClick={e => {
                                            changeActiveTab("pesquisar");
                                        }
                                        }
                                        style={{ width: '50%' }}
                                    >
                                        PESQUISAR
                                    </Button>
                                </ArrowTooltip>
                                <ArrowTooltip title='Shift+2'>
                                    <Button
                                        color={
                                            horizontalTabs === "cadastrar"
                                                ? "info"
                                                : ""
                                        }
                                        type="button"
                                        data-toggle="tab"
                                        className={classNames("btn-gp-padrao",
                                            horizontalTabs === "cadastrar"
                                                ? "active"
                                                : ""
                                        )
                                        }
                                        onClick={() => {
                                            changeActiveTab("cadastrar");
                                            novoCadatro();
                                        }
                                        }
                                        style={{ width: '50%' }}
                                    >
                                        CADASTRAR
                                    </Button>
                                </ArrowTooltip>
                            </ButtonGroup>
                            <TabContent
                                className="tab-space"
                                activeTab={horizontalTabs}
                                style={{ paddingBottom: '0px' }}
                            >
                                <TabPane tabId="pesquisar">
                                    <Row className="padding-padrao-row">
                                        <Col className="padding-padrao-col" lg="6" md="12" sm="12">
                                            <label>Descrição</label>
                                            <Input
                                                id="descricaoContas"
                                                type="text"
                                                autoComplete="off"
                                                value={pesquisa.descricao}
                                                onChange={e => handleOnChange(e.target.value, 'descricao')}
                                                onKeyPress={e => handleKeyPress(e)}
                                                maxLength="50"
                                            />
                                        </Col>

                                        <Col md="6" sm="12">
                                            <Row>
                                                <Col lg="4" md="4" sm="12" style={width < 1100 && width > 768 ? { position: "absolute", right: "17rem", paddingTop: "15px" } : width <= 768 ? { paddingLeft: "2.1rem" } : { position: "absolute", right: "19rem", paddingTop: "15px" }} >
                                                    <FormGroup check>
                                                        <Row>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    onChange={() => handleOnChangeCheck('flPedido', pesquisa.flPedido)}
                                                                    checked={pesquisa.flPedido === 0 ? false : true}
                                                                    disabled={false}
                                                                />
                                                                <span className="form-check-sign" />Pedidos de Venda
                                                        </Label>
                                                        </Row>
                                                    </FormGroup>

                                                    <FormGroup check>
                                                        <Row>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    onChange={() => handleOnChangeCheck('flLancTitulo', pesquisa.flLancTitulo)}
                                                                    checked={pesquisa.flLancTitulo}
                                                                    disabled={false}
                                                                />
                                                                <span className="form-check-sign" />Lançamentos de Títulos
                                                        </Label>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>

                                                <Col lg="4" style={width < 1100 && width > 768 ? { position: "absolute", right: "7.2rem", paddingTop: "15px" } : width <= 768 ? { paddingLeft: "2.1rem" } : { position: "absolute", right: "7.7rem", paddingTop: "15px" }}>
                                                    <FormGroup check>
                                                        <Row>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    onChange={() => handleOnChangeCheck('flBaixaTitulo', pesquisa.flBaixaTitulo)}
                                                                    checked={pesquisa.flBaixaTitulo}
                                                                    disabled={false}
                                                                />
                                                                <span className="form-check-sign" />Baixas de Títulos
                                                        </Label>
                                                        </Row>
                                                    </FormGroup>

                                                    <FormGroup check>
                                                        <Row>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    onChange={() => handleOnChangeCheck('flEntradaMerc', pesquisa.flEntradaMerc)}
                                                                    checked={pesquisa.flEntradaMerc}
                                                                    disabled={false}
                                                                />
                                                                <span className="form-check-sign" />Entrada Mercadoria
                                                        </Label>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>

                                                <Col lg="2" style={width < 1100 && width > 768 ? { paddingTop: '28px', position: "absolute", right: "3.4rem" } : width <= 768 ? { paddingTop: "2rem" } : { paddingTop: '21px', position: "absolute", right: "3.4rem" }}>
                                                    <FormGroup check className="form-check-radio" Style={"margin-top: -12px"}>
                                                        <Label check style={{ paddingRight: '5px' }} >
                                                            <Input type="radio"
                                                                onChange={() => handleOnChangeAtivo('')}
                                                                checked={pesquisa.status === '' ? true : false}
                                                            />
                                                            <span className="form-check-sign" />Todos
                                                        </Label>
                                                        <Label check style={{ paddingRight: '5px' }}>
                                                            <Input type="radio"
                                                                onChange={() => handleOnChangeAtivo(0)}
                                                                checked={pesquisa.status === 0 ? true : false}
                                                            />
                                                            <span className="form-check-sign" />Ativos
                                                        </Label>
                                                        <Label check style={{ paddingRight: '5px' }}>
                                                            <Input type="radio"
                                                                onChange={() => handleOnChangeAtivo(1)}
                                                                checked={pesquisa.status === 1 ? true : false}
                                                            />
                                                            <span className="form-check-sign" />Inativos
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <CardBody>
                                            <Col style={{ width: '100%', paddingTop: "15px" }}>
                                                <ArrowTooltip title='Shift+L'>
                                                    <Button
                                                        color='info'
                                                        style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                                        onClick={() => handleLimpar()}
                                                    >Limpar</Button>
                                                </ArrowTooltip>
                                                <ArrowTooltip title='Shift+P'>
                                                    <Button
                                                        color='info'
                                                        style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                                        onClick={() => handlePesquisar()}
                                                    >Pesquisar</Button>
                                                </ArrowTooltip>
                                            </Col>
                                        </CardBody>
                                    </Row>
                                    <div style={tableVisible ? { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
                                        <ReactTable
                                            data={contas}
                                            filterable={false}
                                            columns={
                                                [
                                                    {
                                                        Header: "Descrição",
                                                        accessor: "descricao"
                                                    },
                                                    {
                                                        Header: "Status",
                                                        accessor: "status",
                                                        Cell: (props) => props.original.status === 0 ? 'Ativo' : 'Inativo'
                                                    },
                                                    {
                                                        Header: "",
                                                        accessor: "actions",
                                                        sortable: false,
                                                        filterable: false,
                                                        Cell: (props) => (
                                                            <div className="actions-right">
                                                                <ArrowTooltip title="Visualizar">
                                                                    <Button
                                                                        id={`editarUsuario-${props.index}`}
                                                                        onClick={() => {
                                                                            handleVisualizarCadastro(props.original);
                                                                        }}
                                                                        size="sm"
                                                                        className={classNames("btn-icon btn-link")}
                                                                        style={{ color: "#483D8B" }}

                                                                    >
                                                                        <Icon icon="eye" className="fa-lg" />
                                                                    </Button>
                                                                </ArrowTooltip>
                                                                {
                                                                    !loading ?
                                                                        <ArrowTooltip title="Editar">
                                                                            <Button
                                                                                id={`editarUsuario-${props.index}`}
                                                                                onClick={() => {
                                                                                    handleEditarCadastro(props.original);
                                                                                }}
                                                                                size="sm"
                                                                                className={classNames("btn-icon btn-link")}
                                                                                color="success"
                                                                            >
                                                                                <Icon icon="edit" className="fa-lg" />
                                                                            </Button>
                                                                        </ArrowTooltip >
                                                                        :
                                                                        <Button
                                                                            id={`editarUsuario-${props.index}`}
                                                                            onClick={() => {
                                                                                handleEditarCadastro(props.original);
                                                                            }}
                                                                            size="sm"
                                                                            className={classNames("btn-icon btn-link")}
                                                                            color="success"
                                                                            disabled={true}

                                                                        >
                                                                            <Icon icon="edit" className="fa-lg" />
                                                                        </Button>
                                                                }
                                                                <ArrowTooltip title={props.original.status === 0 ? "Inativar" : "Reativar"}>
                                                                    <Button
                                                                        onClick={() => {
                                                                            handleInativar(props.original);
                                                                        }}
                                                                        className="btn-icon btn-link"
                                                                        color="info"
                                                                    >
                                                                        {props ? props.original.status === 0 ?
                                                                            <Icon icon="ban" className="fa-lg" />
                                                                            :
                                                                            <Icon icon="redo" className="fa-lg" />
                                                                            :
                                                                            null
                                                                        }
                                                                    </Button>
                                                                </ArrowTooltip>
                                                                <ArrowTooltip title="Excluir">

                                                                    <Button
                                                                        onClick={() => { handleExcluir(props.original) }}
                                                                        className="btn-icon btn-link"
                                                                        color="danger"
                                                                    >
                                                                        <Icon icon="trash-alt" className="fa-lg" />
                                                                    </Button>
                                                                </ArrowTooltip>

                                                            </div >
                                                        )
                                                    }
                                                ]
                                            }
                                            minRows={1}
                                            showPaginationTop={false}
                                            showPaginationBottom={true}
                                            className="-striped -highlight"
                                            noDataText=""
                                            previousText="Anterior"
                                            nextText="Próximo"
                                            loadingText="Carregando"
                                            pageText="Página"
                                            ofText="de"
                                            rowsText='linhas'
                                        />
                                    </div>
                                </TabPane>

                                <TabPane tabId="cadastrar">
                                    <div style={{ marginTop: '-20px' }}>
                                        <ReactWizard
                                            steps={steps}
                                            navSteps={true}
                                            title=""
                                            description=""
                                            validate
                                            resizable={true}
                                            previousButtonText="Voltar"
                                            finishButtonText="Finalizar"
                                            nextButtonText="Avançar"
                                            goToFirstStep={inicioCadastro}
                                            finishButtonClick={() => finishButtonClick()}
                                            finishButtonClasses={classNames("btn-wd btn-info",
                                                {
                                                    finishButtonMin:
                                                        width < 550
                                                },
                                                {
                                                    finishButtonRight:
                                                        !width < 550
                                                }
                                            )}
                                            nextButtonClasses={classNames("btn-wd btn-info",
                                                {
                                                    nextButtonMin:
                                                        width < 550

                                                },
                                                {
                                                    nextButtonRight:
                                                        !width < 550
                                                }
                                            )}
                                            previousButtonClasses={classNames("btn-wd",
                                                {
                                                    previousButtonMin:
                                                        width < 550
                                                },
                                                {
                                                    previousButtonRight:
                                                        !width < 550
                                                }
                                            )}
                                            progressbar
                                            color="blue"
                                        />
                                    </div>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </div>

            {/* Modal Inativar */}
            <Modal
                isOpen={modalInativar}
                toggle={toggleModalInativar}
            >
                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalInativar}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">{contaSelected.status === 0 ? "Inativar" : "Ativar"} conta</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        {contaSelected.status === 0 ?
                            <label>Tem certeza que deseja <b>inativar</b> essa conta da sua empresa?</label>
                            : <label>Tem certeza que deseja <b>ativar</b> essa conta em sua empresa?</label>
                        }
                    </Col>

                </ModalBody>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { toggleModalInativar() }}
                                style={{ width: '150px' }}
                            >NÃO
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { toggleModalInativar(); inativar() }}
                                style={{ width: '150px' }}
                            >SIM
                                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Modal>
            {/* Modal Inativar*/}

            {/* Modal Excluir */}
            <Modal
                isOpen={modalExcluir}
                toggle={toggleModalExcluir}
            >

                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalExcluir}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Excluir conta</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>Tem certeza que deseja <b>excluir</b> essa conta?</label>
                    </Col>

                </ModalBody>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { toggleModalExcluir() }}
                                style={{ width: '150px' }}
                            >NÃO
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { toggleModalExcluir(); excluir() }}
                                style={{ width: '150px' }}
                            >SIM
                                            </Button>
                        </Col>
                    </Row>
                </CardBody>

            </Modal>
            {/* Modal Excluir*/}
        </>
    );
}

export default Contas;
