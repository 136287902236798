import {
    IOperacaoState,
    OperacaoAction,
    OperacaoStore,
    OperacaoTypes,
    Pesquisa,
    Operacao,
    Invalido
} from '../Operacao/types'

import store from "../../../stores";


export const cleanAll = () => store.dispatch<OperacaoAction>({
    idStore: OperacaoStore.ID_STORE,
    type: OperacaoTypes.CLEAN
})
export const cleanSearch = () => store.dispatch<OperacaoAction>({
    idStore: OperacaoStore.ID_STORE,
    type: OperacaoTypes.CLEAN_SEARCH
})
export const cleanInvalido = () => store.dispatch<OperacaoAction>({
    idStore: OperacaoStore.ID_STORE,
    type: OperacaoTypes.CLEAN_INVALIDO
})

export const cleanOperacoes = () => store.dispatch<OperacaoAction>({
    idStore: OperacaoStore.ID_STORE,
    type: OperacaoTypes.CLEAN_OPERACOES
})
export const New = () => store.dispatch<OperacaoAction>({
    idStore: OperacaoStore.ID_STORE,
    type: OperacaoTypes.NEW
})

export const setPesquisa = (pesquisa: Pesquisa) => store.dispatch<OperacaoAction>({
    idStore: OperacaoStore.ID_STORE,
    type: OperacaoTypes.PESQUISA,
    pesquisa
})

export const setOperacoes = (operacoes: IOperacaoState['operacoes']) => store.dispatch<OperacaoAction>({
    idStore: OperacaoStore.ID_STORE,
    type: OperacaoTypes.OPERACOES,
    operacoes
})

export const setOperacao = (operacao: Operacao) => store.dispatch<OperacaoAction>({
    idStore: OperacaoStore.ID_STORE,
    type: OperacaoTypes.OPERACAO,
    operacao
})

export const setOperacaoCopia = (operacao: Operacao) => store.dispatch<OperacaoAction>({
    idStore: OperacaoStore.ID_STORE,
    type: OperacaoTypes.COPIA_OPERACAO,
    operacao
})
export const setInvalido = (invalido: Invalido) => store.dispatch<OperacaoAction>({
    idStore: OperacaoStore.ID_STORE,
    type: OperacaoTypes.INVALIDO,
    invalido
})

export const setScreen = (screen: IOperacaoState['screen']) => store.dispatch<OperacaoAction>({
    idStore: OperacaoStore.ID_STORE,
    type: OperacaoTypes.SCREEN,
    screen
})

export const setCaracteristica = (caracteristica: IOperacaoState['caracteristica']) => store.dispatch<OperacaoAction>({
    idStore: OperacaoStore.ID_STORE,
    type: OperacaoTypes.CARACTERISTICAS,
    caracteristica
})

export const setinicioCadastro = (inicioCadastro: IOperacaoState['inicioCadastro']) => store.dispatch<OperacaoAction>({

    idStore: OperacaoStore.ID_STORE,
    type: OperacaoTypes.INICIO_CADASTRO,
    inicioCadastro

})

export const setCurrentStep = (currentStep: IOperacaoState['currentStep']) => store.dispatch<OperacaoAction>({

    idStore: OperacaoStore.ID_STORE,
    type: OperacaoTypes.WIZARD_STEP,
    currentStep

})

export const setVerCadastro = (verCadastro: IOperacaoState['verCadastro']) => store.dispatch<OperacaoAction>({

    idStore: OperacaoStore.ID_STORE,
    type: OperacaoTypes.VER_CADASTRO,
    verCadastro

})

