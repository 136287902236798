import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Label } from 'reactstrap';
import ReactTable from "react-table";

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from '../../../../components/ModalBasic/ModalBasic.jsx';
import * as controllerEntrada from "../../../../controller/Movimentacao/controllerEntradaMercadoria"

import useScrollBar from '../../../../Util/Hooks/useScrollBar';

import { HelpButton } from '../../../../components/HelpPlugin/HelpPlugin'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Switch } from "../../../../components/ErnComponets/ErnComponets"
import useShallowEqualSelector from "../../../../Util/Hooks/useShallowEqualSelector";

import store from '../../../../store/stores'

import { Sum, Subtract } from '../../../../Util/numbers';


import ArrowTooltip from '../../../../components/Tooltip/ArrowTooltip';
import { maskMoeda } from '../../../../Util/mascaras.js';
import Axios from 'axios';
import api from '../../../../Util/api.js';

const idStore = 'ENTRADA_MERCADORIA';

export default function ModalSaidas({ isOpen, toggle, codProduto }) {

    const gradesSelected = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.itens.produto.gradesSelected);
    const listGrade = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.itens.listGrade);
    const produto = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.itens.produto);
    const totalCompleto = useShallowEqualSelector(state => state.entradaMercadoria.totalInventario);
    const editarProduto = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.itens.produto.editarProduto);
    let listGradeCopia = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.itens.listGradeCopia);
    const dispatch = store.dispatch;

    const [invalido, setInvalido] = useState(false);
    const [gradeUnmodified, setGradeUnmodified] = useState()

    const [msgInvalido, setMessage] = useState("A quantididade de grades é maior que a de produtos.")
    
    const loadGrade = async() => {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await Axios.get(api.urlCadastro + `api/v1/produto/produto?codProduto=${codProduto}`, {
            headers: { Authorization: basicAuth }
        }).then (async response => {
            const {codProduto, codTipoGrade} = response.data.records
            await Axios.get(api.urlCadastro + `api/v1/produto/grades?codProduto=${codProduto}&codTipoGrade=${codTipoGrade}`, {
                headers: { Authorization: basicAuth }
            }).then(async response => {
                const {grades} = response.data.records
                setGradeUnmodified(grades)
            })
        })
    }

    useEffect(() => {
        loadGrade()
    }, [])

    const tableRef = useScrollBar()

    useEffect(() => {
        if (listGrade) {
            if (editarProduto) {
                listGrade.forEach((grade) => {
                    gradesSelected.forEach((gradeSelecionada) => {
                        if (Number(grade.codGrade) === Number(gradeSelecionada.codGrade)) {
                            grade.qtdEstoqueGrade = gradeSelecionada.qtdEstoqueGrade
                            grade.sequencialItemGrade = gradeSelecionada.sequencialItemGrade
                        }
                    })
                })
            }
        }
    })
    function closeModal() {
        dispatch({ idStore, type: 'GRADE', value: [] })
        toggle()
    }

    function onChangeSwitch(index) {
        let gradesStr = JSON.stringify(listGrade);
        let gradesArr = JSON.parse(gradesStr);

        let status = gradesArr[index].status

        if (status === 0) {
            gradesArr[index].status = 1
        } else {
            gradesArr[index].status = 0
        }

        dispatch({ idStore, type: 'GRADE', value: gradesArr })
    }

    function onChangeValue(index, quantidade) {
        let gradesStr = JSON.stringify(listGrade);
        let gradesArr = JSON.parse(gradesStr);

        if(gradeUnmodified[index].qtdEstoqueGrade < gradesArr[index].qtdEstoqueGrade) {
            if(quantidade > 0) {
                gradesArr[index].qtdEstoqueGrade = Number(gradeUnmodified[index].qtdEstoqueGrade) + Number(quantidade)
                return dispatch({ idStore, type: 'GRADE', value: [...gradesArr] })
            }
            const newQtd = Number(gradesArr[index].qtdEstoqueGrade)  - Number(gradeUnmodified[index].qtdEstoqueGrade)
            gradesArr[index].qtdEstoqueGrade = Number(gradesArr[index].qtdEstoqueGrade) - Number(newQtd)
            
            dispatch({ idStore, type: 'GRADE', value: [...gradesArr] })
        } else {
            gradesArr[index].qtdEstoqueGrade = Number(gradesArr[index].qtdEstoqueGrade) + Number(quantidade)
            dispatch({ idStore, type: 'GRADE', value: [...gradesArr] })
        }

        let selectedStr = JSON.stringify(gradesSelected);
        let selectedArr = JSON.parse(selectedStr);

        if (quantidade !== "0" && !produto.editarProduto) {
            selectedArr.push(gradesArr[index])
            dispatch({ idStore, type: 'GRADES_SELECTED', value: selectedArr })
        } else if (quantidade !== "0" && produto.editarProduto) {
            gradesArr[index].codGrade = Number(gradesArr[index].codGrade)
            gradesArr[index].qtdEstoqueGrade = Number(gradesArr[index].qtdEstoqueGrade)
            selectedArr[index] = gradesArr[index]
            dispatch({ idStore, type: 'GRADES_SELECTED', value: selectedArr })
        } else {
            selectedArr.splice(index, 1)
            dispatch({ idStore, type: 'GRADES_SELECTED', value: selectedArr })
        }
    }

    function calculaValorTotal(gradesArr) {
        return gradesArr.reduce((total, grade) => { return Sum([total, grade.qtdEstoqueGrade]) }, 0)
    }
    function updateGrade(produto) {

        let listGrade2 = []
        const { entradaMercadoria: { produtosSelected, itensEditar, itensExcluir, itensInserir } } = store.getState()
        const { valoresTotais } = store.getState().entradaMercadoria.cadastro.itens
        let newProduto = {
            codProdutoItem: '',
            codProduto: '',
            numProduto: '',
            codBarras: '',
            nome: '',
            quantidade: '',
            valorUnitario: '',
            valorTotal: '',
            unidade: '',
            gradesSelected: [],
            editarProduto: false,
        }
        listGrade.map((_, index) => {
            if (!listGradeCopia) listGradeCopia = []
            if (listGradeCopia && JSON.stringify(listGrade[index]) !== JSON.stringify(listGradeCopia[index]) && listGrade[index].status === 0) {
                listGrade2.push(listGrade[index])
            }
            return true
        })
        const valorTotal = calculaValorTotal(listGrade2)
        const resto = Subtract([valorTotal, totalCompleto]);

        if (resto !== 0) {
            if (resto < 0)
                setMessage('A quantidade de produtos foi ultrapassada')
            else
                setMessage('Soma das quantidades de grades é menor que a quantidade de produtos')

            setInvalido(false)
            setTimeout(() => { setInvalido(true) }, 50)
        } else {
            setInvalido(false)
            let produtosSelectedString = JSON.stringify(produtosSelected);
            let produtosJSON = JSON.parse(produtosSelectedString)
            var arrProdutos = []
            var agrupa = 0
            produtosJSON.forEach((produtoSelected) => {
                if (produtoSelected.codProduto === Number(produto.codProduto)) {

                    let valorTotalString = JSON.stringify(valoresTotais);
                    let valorTotalJSON = JSON.parse(valorTotalString)

                    let valorProduto = Number(produto.valorTotal.replace(",", "."))
                    let valorProdutoSelected = Number(produtoSelected.valorTotal.replace(",", "."))
                    let valorSubtotal = 0
                    if (produto.editarProduto) {
                        if (valorProduto > valorProdutoSelected) {

                            valorSubtotal = Number(valorTotalJSON.subTotal.replace(",", ".")) - valorProdutoSelected
                            valorTotalJSON.subTotal = Sum([valorSubtotal, valorProduto]).toFixed(2)

                        } else if (valorProdutoSelected > valorProduto) {

                            valorSubtotal = Number(valorTotalJSON.subTotal.replace(",", ".")) - valorProdutoSelected
                            valorSubtotal = valorSubtotal + valorProduto
                            valorTotalJSON.subTotal = valorSubtotal.toFixed(2)

                        }
                    } else {
                        valorTotalJSON.subTotal = Sum([Number(valorTotalJSON.subTotal.replace(",", ".")), valorProduto]).toFixed(2)
                    }

                    dispatch({
                        idStore,
                        type: 'VALORES_TOTAIS',
                        value: valorTotalJSON
                    })
                }
                if (produtoSelected.codProduto === Number(produto.codProduto) && produtoSelected.valorUnitario === produto.valorUnitario && !produto.editarProduto) {
                    agrupa = 1
                    produtoSelected.valorTotal = produtoSelected.valorTotal.toString().replace(',', '.')
                    produto.valorTotal = produto.valorTotal.toString().replace(',', '.')
                    produtoSelected.valorTotal = Number(produtoSelected.valorTotal)
                    produtoSelected.valorTotal += Number(produto.valorTotal)
                    produtoSelected.valorTotal = maskMoeda(produtoSelected.valorTotal.toFixed(2))

                    produtoSelected.quantidade = Number(produtoSelected.quantidade)
                    produtoSelected.quantidade += Number(produto.quantidade)
                }
                gradesSelected.forEach((grade) => {
                    produtoSelected.gradesSelected.forEach((gradeSelec) => {
                        if (grade.codGrade === gradeSelec.codGrade) {
                            gradeSelec.qtdEstoqueGrade = Number(gradeSelec.qtdEstoqueGrade)
                            gradeSelec.qtdEstoqueGrade += Number(grade.qtdEstoqueGrade)
                        }
                    })
                })
                arrProdutos.push(produtoSelected)

                if (agrupa === 1 || produto.editarProduto) {
                    itensExcluir.forEach((item, i) => {
                        if (item.codProduto === produto.codProduto) {
                            itensExcluir.splice(i, 1);
                        }
                    })
                    let editarList = JSON.stringify(itensEditar);
                    let Editar = JSON.parse(editarList);
                    let gradeExcluir = []
                    if (produto.gradesSelected)
                        produto.gradesSelected.forEach((list) => {
                            gradeExcluir.push({
                                sequencialItemGrade: list.sequencialItemGrade,
                                codGrade: list.codGrade,
                                qtdEstoqueGrade: list.qtdEstoqueGrade,
                                flagOperacao: "U"
                            })
                        })
                    produto.gradesSelected = gradeExcluir

                    let contem = Editar.findIndex((() => produto.codProduto))
                    contem === -1 ? Editar.push(produto) : Editar[contem] = produto

                    let editarObj = []
                    Editar.forEach((item) => {
                        editarObj.push({
                            codigoProduto: item.codProduto,
                            itemGrade: item.gradesSelected,
                            quantidade: item.quantidade,
                            sequencialItem: item.sequencialItem,
                            valorUnitario: item.valorUnitario,
                            valorTotal: item.valorTotal
                        })
                    })

                    dispatch({
                        idStore: 'ENTRADA_MERCADORIA',
                        type: 'PRODUTOS_UPDATE',
                        campo: 'itensEditar',
                        value: editarObj
                    })
                } else {
                    itensExcluir.forEach((item, i) => {
                        if (item.codProduto === produto.codProduto) {
                            itensExcluir.splice(i, 1);
                        }
                    })
                    let inserirList = JSON.stringify(itensInserir);
                    let Inserir = JSON.parse(inserirList);
                    let gradeExcluir = []
                    if (produto.gradesSelected)
                        produto.gradesSelected.forEach((list) => {
                            gradeExcluir.push({
                                sequencialItemGrade: list.sequencialItemGrade,
                                codGrade: list.codGrade,
                                qtdEstoqueGrade: list.qtdEstoqueGrade,
                                flagOperacao: "I"
                            })
                        })
                    produto.gradesSelected = gradeExcluir

                    let contem = Inserir.findIndex(() => produto.codProduto)
                    contem === -1 ? Inserir.push(produto) : Inserir[contem] = produto

                    let inserirObj = []
                    Inserir.forEach((item) => {
                        inserirObj.push({
                            codigoProduto: item.codProduto,
                            itemGrade: item.gradesSelected,
                            quantidade: item.quantidade,
                            sequencialItem: item.sequencialItem,
                            valorUnitario: item.valorUnitario,
                            valorTotal: item.valorTotal
                        })
                    })

                    dispatch({
                        idStore: 'ENTRADA_MERCADORIA',
                        type: 'PRODUTOS_UPDATE',
                        campo: 'itensInserir',
                        value: inserirObj
                    })
                }
            })
            if (agrupa === 1) {
                dispatch({ idStore, type: 'PRODUTO_SELECIONADO', value: arrProdutos })
                dispatch({ idStore, type: 'PRODUTO', value: newProduto })
            } else {
                controllerEntrada.InsereProduto(produto)
            }


            closeModal()
        }
    }
    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => closeModal()}
            title={'GRADE DE PRODUTOS'}
            size="lg"
        >
            <ModalBasicBody>
                <h4 style={{ textAlign: 'center' }}>Informe a quantidade para cada item da grade</h4>

                <Row style={{ justifyContent: 'flex-end', paddingRight: 20, paddingBottom: 10 }}>
                    <HelpButton id={19} />
                </Row>
                <Row>
                    <Col>
                        <fieldset className="fieldset-operacao">
                            <div
                                ref={tableRef}
                                style={{position: 'relative', maxHeight: 250}} 
                            >
                                <ReactTable
                                    data={listGrade}
                                    columns={[
                                        {
                                            Header: 'Descrição',
                                            accessor: 'descricao',
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Sigla',
                                            accessor: 'sigla',
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Pertence',
                                            accessor: 'pertence',
                                            resizable: false,
                                            sortable: false,
                                            Cell: (prop) =>
                                                <Switch
                                                    checked={prop.original.status === 0}
                                                    onChange={() => onChangeSwitch(prop.index)}
                                                />
                                        },
                                        {
                                            Header: 'Estoque Atual',
                                            resizable: false,
                                            sortable: false,
                                            Cell: (prop) =>
                                                <>
                                                    <input
                                                        type="text"
                                                        className={prop.original.invalido ? 'inputRedBorder' : 'form-control'}
                                                        style={{ textAlign: 'center' }}
                                                        value={prop.original.qtdEstoqueGrade}
                                                        maxLength={10}
                                                        onFocus={(element) => { element.currentTarget.select() }}
                                                        disabled
                                                    />
                                                </>
                                        },
                                        {
                                            Header: 'Quantidade',
                                            resizable: false,
                                            sortable: false,
                                            Cell: (prop) =>
                                                <>
                                                    <input
                                                        type="text"
                                                        className={prop.original.invalido ? 'inputRedBorder' : 'form-control'}
                                                        style={{ textAlign: 'center' }}
                                                        maxLength={10}
                                                        onChange={(element) => { onChangeValue(prop.index, element.currentTarget.value) }}
                                                        onFocus={(element) => { element.currentTarget.select() }}
                                                        disabled={prop.original.status === 0 ? false : true}
                                                    />
                                                    {
                                                        prop.original.invalido &&
                                                        <ArrowTooltip title="A quantidade de produtos informados foi ultrapassada">
                                                            <div style={{ textAlign: 'center' }}>
                                                                <Label style={{ fontSize: 12, margin: 0 }}>Valor Inválido
                                                                    <span className="error" style={{ fontSize: 14 }}>*</span>
                                                                </Label>
                                                            </div>
                                                        </ArrowTooltip>
                                                    }
                                                </>
                                        },
                                    ]}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                    noDataText=""
                                    minRows={listGrade}
                                />
                            </div>
                        </fieldset>

                        <br />

                        <div style={invalido ? { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
                            <Row style={{ justifyContent: 'flex-end', paddingRight: 15 }}>
                                <span style={{ fontSize: 14, color: '#FFF', backgroundColor: '#FFB600', borderRadius: 5, paddingTop: 2.5, paddingBottom: 2.5, paddingLeft: 10, paddingRight: 10 }}>
                                    <Icon icon="exclamation-triangle" className="fa-lg" />
                                    {"  "}
                                    {msgInvalido}
                                </span>
                            </Row>
                        </div>
                    </Col>

                </Row>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        onClick={() => updateGrade(produto)}
                        color={"info"}
                    >SALVAR
                    </Button>
                </Col>
            </ModalBasicFooter>

        </ModalBasic>
    )
}