import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import * as controllerLancamento from '../../../../controller/Financeiro/controllerLancamentoTitulos'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import useModal from "../../../../Util/Hooks/useModal";
import useResize from "~/Util/Hooks/useResize";
import ModalPesquisa from "../../Modals/PesquisaFornecedores";
import { InputCpfCnpj } from '../../Components/index';

import {
  Input,
  Row,
  Col,
  Button,
} from "reactstrap";

const idStore = "LANCAMENTO_TITULOS";

const Fornecedor = () => {
  const dispatch = useDispatch();
  const inputDocumento = useRef()
  const fornecedor = useSelector(state => state.lancamentoTitulos.cadastro.fornecedor, shallowEqual)
  const empresa = useSelector(state => state.lancamentoTitulos.empresa, shallowEqual)
  const { width } = useResize();

  const [data, setData] = useState({ dataAtual: new Date().toISOString().substr(0, 10) })
  const [modalPesquisa, toggleModal] = useModal(false)

  useEffect(() => {
    controllerLancamento.changeTipoGrupoContas()
    inputDocumento.current.focus()
  }, [fornecedor])

  function confirmModal(value) {
    dispatch({ idStore, type: 'CADASTRO_FORNECEDOR', value: { ...fornecedor, codFornecedor: value.codigo, nomeFor: value.nomeRazao, documento: value.documento, tipoDestinatario: value.tipo } })
    toggleModal()
    dispatch({ idStore, type: 'CLEAR_FORNECEDOR' })
  }

  function onChangeData(value, id, cod) {
    if (id === "dataLancamento") {
      setData({ ...data, dataAtual: value })
      dispatch({ idStore, type: 'CADASTRO_FORNECEDOR', value: { ...fornecedor, dataLancamento: value } })
    }
    else {
      if (cod >= 2) {
        let venci = new Date()
        venci.setDate(venci.getDate() + value)
        let proximo = new Date()
        proximo.setDate(proximo.getDate() + 2 * value)
        setData({ ...data, vencimento: venci.toISOString().substr(0, 10), proximoVenci: proximo.toISOString().substr(0, 10) })
      }
    }
  };

  async function handleKeyPress(event) {
    if (event.key === 'Enter') {

      if (fornecedor.documento) {
        let codForne = await controllerLancamento.getFornecedoresKeyPress(fornecedor.documento, "C");
        if (!codForne) {
          dispatch({ idStore, type: 'CADASTRO_FORNECEDOR', value: { ...fornecedor, documento: fornecedor.documento } })
          toggleModal();
        }
      }
      else {
        dispatch({ idStore, type: 'CADASTRO_FORNECEDOR', value: { ...fornecedor, documento: fornecedor.documento } })
        toggleModal();
      }
    }
  }

  function onChangeFornecedor(value) {
    dispatch({ idStore, type: 'CADASTRO_FORNECEDOR', value: { ...fornecedor, documento: value } })
  }

  function cleanFornecedor() {
    dispatch({ idStore, type: "CLEAN_FORNECEDOR" })
  }


  return (
    <>
      <Row>
        <Col>
          <fieldset style={{ border: 'solid 1px #5E6680', borderRadius: 5 }}>
            <legend style={{ fontSize: '13px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
              Empresa
            </legend>
            <Row className="padding-padrao-row">

              <Col className="padding-padrao-col" lg md>
                <label>Empresa / Filial</label>
                <Input
                  type="text"
                  value={fornecedor.nomeFilial}
                  disabled={true}
                />
              </Col>

            </Row>
          </fieldset>
        </Col>

        <Col lg="3" >
          <fieldset style={{ border: 'solid 1px #5E6680', borderRadius: 5 }}>
            <legend style={{ fontSize: '13px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
              Data de Inclusão
            </legend>

            <Row className="padding-padrao-row">
              <Col className="padding-padrao-col">
                <label>Data</label>
                <Input
                  type="date"
                  value={data.dataAtual}
                  onChange={e => onChangeData(e.target.value, "dataLancamento")}
                  autoComplete="off"
                  disabled
                />
              </Col>
            </Row>

          </fieldset>
        </Col>
      </Row>

      <Row style={{ paddingTop: "10px" }}>
        <Col style={width <= 550 ? { height: "35%" } : {}} lg="12">
          <fieldset style={{ border: 'solid 1px #5E6680', borderRadius: 5 }}>
            <legend style={{ fontSize: '13px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
              Pessoa
            </legend>

            <Row className="padding-padrao-row">
              <Col className="padding-padrao-col" lg="3">
                <label>Pessoa / Fornecedor<span className="error"> *</span></label>
                < InputCpfCnpj
                  innerRef={inputDocumento}
                  type="number"
                  tipo={fornecedor.documento.length <= 14 ? 'cpf' : 'cnpj'}
                  value={fornecedor.documento}
                  onChange={e => onChangeFornecedor(e.target.value)}
                  onKeyPress={e => handleKeyPress(e)}
                  autoComplete="off"
                  maxLength="18"
                />
              </Col>
              <div style={{ display: 'flex', justifyContent:'flex-end', flexDirection: 'column'}}>
                <Button
                  type="button"
                  color="info"
                  onClick={() => toggleModal()}
                >
                  <Icon icon="search" className="fa-lg" />
                </Button>
              </div>
              <Col className="padding-padrao-col">
                <label>Nome / Razão Social</label>
                <Input
                  type="text"
                  value={fornecedor.nomeFor}
                  autoComplete="off"
                  disabled={fornecedor.nomeLancamento !== 'D' ? true : false}
                  maxLength="50"
                />
              </Col>
            </Row>

          </fieldset>
          {
            modalPesquisa && empresa ?
              <ModalPesquisa isOpen={modalPesquisa} toggle={toggleModal} confirmModal={confirmModal} clean={cleanFornecedor} />
              :
              null
          }
        </Col>
      </Row>

    </>
  )
}
export default Fornecedor