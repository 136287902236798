import axios from 'axios';
import api from "../Util/api.js";
import history from "../Util/history";
import { alertar } from "../Util/alertar";
import store from '../store/stores';
import * as actions from "../store/reducers/Configuracao/actions"


export function entrySettings() {
    history.push('/configuracao');
}

export function getInformationInit() {
    //@ts-ignore
    const { notificacao } = store.getState()

    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios({
            method: 'get',
            url: api.urlCadastro + `api/v1/cofiguracao/getInformationInit`,
            headers: { Authorization: basicAuth },

        }).then((response) => {

            actions.setUser(response.data.records)

        })

    } catch (e) {
        alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)

    }
}

export async function getViews() {

    //@ts-ignore
    const { notificacao } = store.getState()

    try {

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        return await axios.get(api.urlCadastro + `api/v1/cofiguracao/Views`, {
            headers: { Authorization: basicAuth }

        }).then(response => {
            //@ts-ignore
            var arr: any = response.data.records.map((tela: any) => {

                return {

                    codTela: tela.codTela,
                    label: tela.label,
                    status: tela.status,
                    disable: false

                }

            });

            actions.setTelas(arr)
            //@ts-ignore
            let arrShortCut = arr.filter((tela) => tela.status === "0")
            actions.setFiveTela(arrShortCut)
            actions.setFiveTelaCopia(arrShortCut)

        }).catch(() => {

            alertar(notificacao, 'br', "Não foi possível obter as telas", 'warning', 'icon-alert-circle-exc', 2)

        })

    } catch (e) {

        alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)

    }

}

export function addShortCuts() {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        const { fiveTelas, fiveTelasCopia, telas } = store.getState().configuracao

        let strFiveTela = JSON.stringify(fiveTelas)

        if (strFiveTela === JSON.stringify(fiveTelasCopia)) {

            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return
        }
        if (fiveTelas.length !== 5 && fiveTelas.length !== telas.length) {
            alertar(notificacao, 'br', 'É necessário ter cinco telas', 'warning', 'icon-alert-circle-exc', 2)
            return
        }
        let arrFiveTelas = JSON.parse(strFiveTela)

        actions.setFiveTelaCopia(arrFiveTelas)

        const data = arrFiveTelas.map((tela: any) => { return { codTela: tela.codTela, status: tela.status } })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/cofiguracao/salveViewsFive`,
            headers: { Authorization: basicAuth },
            data: data
        }).then(() => {

            alertar(notificacao, 'br', 'Seus atalhos foram salvos com sucesso', 'success', 'icon-check-2', 2)

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        })

    } catch (e) {
        alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
    }
}