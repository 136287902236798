//State da tela de Pesquisa e cadastro de tipos de pagamentos
var INITIAL_STATE = {
    pesquisa: {
        descricao: '',
        ativos: 2,
        flTipos: 0,
    },
    tipoPgto: {
        codTipoPgto: '',
        descricao: '',
        status: 0,
        flTipo: 0,
    },
    invalido: {
        flTipo: false,
        descricao: false,
    },
    tiposPgtos: [],
    inicioCadastro: false,
    verCadastro: false,
    tiposPgtoCopia: {}
};

export default function tiposPagamentosStore(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'TIPOS_PAGAMENTOS':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'TIPOS_PGTOS':
                    return { ...state, tiposPgtos: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: {
                            descricao: '',
                            ativos: 0,
                            flTipos: 0,
                        },
                        tiposPgtos: []
                    }
                case 'TIPO_PGTO':
                    return { ...state, tipoPgto: { ...state.tipoPgto, [action.campo]: action.value } }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value } 
                case 'TIPOS_PGTOS_COPIA':
                    return { ...state, tiposPgtoCopia: state.tipoPgto }
                case 'CLEAN_TIPO_PGTO':
                    return {
                        ...state,
                        tipoPgto: {
                            codTipoPgto: '',
                            descricao: '',
                            status: 0,
                            flTipo: 0,
                        },
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: {
                            flTipo: false,
                            descricao: false,
                        },
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}