import React from 'react';

import {
  Button,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import * as controllerInventario from '../../../controller/controllerInventario'

interface Props {
  isOpen: boolean,
  toggle: Function
}

export const PopUp = ({ isOpen, toggle }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle()}
      centered={true}
      backdropClassName={'backdropTeste'}
    >
      <div className="modal-header justify-content-center">
        <button
          aria-hidden={true}
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle()}
        >
          <i className="tim-icons icon-simple-remove" />
        </button>
      </div>

      <Row style={{ justifyContent: 'center' }}>
        <Icon icon='question-circle' size="10x" color="#237EF7" />
      </Row>

      <ModalBody>

        <Col className="text-center">
          <label>Deseja atualizar o estoque dos produtos?</label>
        </Col>

      </ModalBody>

      <ModalFooter>

        <Row style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
          <Button
            color="info"
            type="button"
            onClick={() => controllerInventario.Atualizar(toggle)}
            style={{ width: '150px' }}
          >
            SIM
            </Button>
          <Button
            color="danger"
            type="button"
            onClick={() => toggle()}
            style={{ width: '150px' }}
          >
            NÃO
            </Button>
        </Row>
        
      </ModalFooter>
    </Modal>
  )
}