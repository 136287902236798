import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import classNames from "classnames";
import {
    Col,
    Button,
    CardBody,
    Input,
    Row,
} from "reactstrap";
import useResize from "../../../../../Util/Hooks/useResize";
import * as controllerInventario from '../../../../../controller/controllerInventario';
import ArrowTooltip from '../../../../../components/Tooltip/ArrowTooltip';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import useModal from "../../../../../Util/Hooks/useModal";
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import AppState from "../../../../../store/storeType";
import { IInventarioState } from "../../../../../store/reducers/Movimentacao/Inventario/types";
import * as actions from "../../../../../store/reducers/Movimentacao/Inventario/actions";
import { Select } from "../../../../../components/ErnComponets/ErnComponets";
import { useSelector } from "react-redux";
import ModalFuncionario from "../../../Modals/Funcionario.jsx";
import { setFuncionarios } from "../../../../../store/reducers/Movimentacao/Inventario/actions";
import { alertar } from "../../../../../Util/alertar";
import store from "../../../../../store/stores";
import GradePesquisa from '../../../Modals/GradePesquisa';
import notificacao from "../../../../../store/reducers/notificacaoStore";

const idStore = "INVENTARIO";

const Pesquisar = function () {
    const [modalGrade, toogleModalGrade] = useModal(false)

    return (
        <Col lg="15">
            <Filtros />
            <TableItensPesquisados />
            <br />
            <TableProdutosVisualizacao toogleModalGrade={toogleModalGrade} />
            {
                modalGrade && <GradePesquisa isOpen={true} toggle={toogleModalGrade} />
            }
        </Col>
    );

}

function Filtros() {
    const { width } = useResize()
    const pesquisa = useSelector<AppState, IInventarioState['pesquisa']>(state => state.inventario.pesquisa)
    const empresas = useShallowEqualSelector<AppState, IInventarioState['empresas']>(state => state.inventario.empresas)
    const modalFuncionarios = useShallowEqualSelector<AppState, IInventarioState['modalFuncionarios']>(state => state.inventario.modalFuncionarios)
    //@ts-ignore
    const { codEmpresaFilial } = useSelector(state => state.login.usuario.empresa);
    const [modalFuncionario, toggleFuncionario] = useModal(false)

    useEffect(() => {
        controllerInventario.getEmpresasUsuario()
    }, [])

    function getEmpresa() {
        let filial: any
        empresas.forEach((empresa: any) => {
            if (empresa.codEmpresaFilial === codEmpresaFilial.toString()) {
                filial = codEmpresaFilial.toString()
            }
        })
        actions.setPesquisa({ ...pesquisa, codFilial: filial })
    }

    useEffect(() => {
        getEmpresa()
    }, [empresas])

    function pesquisaFuncionario() {
        controllerInventario.getFuncionarios();
    }

    function confirmarFuncionario() {
        actions.setPesquisa({ ...pesquisa, nome: modalFuncionarios.nomeEmail, codigoUser: modalFuncionarios.codUser })
        toggleFuncionario()
    }

    function cleanFuncionario() {
        setFuncionarios([])
    }

    function handleOnPesquisar() {
        //@ts-ignore
        const { notificacao } = store.getState()

        if (pesquisa.dataInicio !== '' && pesquisa.dataFinal !== '') {
            var dataI = pesquisa.dataInicio.split('-')
            var dataF = pesquisa.dataFinal.split('-')

            var newDataI = new Date(Number(dataI[0]), Number(dataI[1]) - 1, Number(dataI[2]))
            var newDataF = new Date(Number(dataF[0]), Number(dataF[1]) - 1, Number(dataF[2]))

            if (newDataI > newDataF) {
                alertar(notificacao, 'br', 'O campo Data Início não pode ser maior que o campo Data Fim', 'warning', 'icon-alert-circle-exc', 2)
                return
            }
        }

        controllerInventario.handlePesquisar()
    }

    function handleLimpar() {
        actions.cleanPesquisa()
    }

    useEffect(() => {
    }, [pesquisa])

    return (
        <>
            <Row className="padding-padrao-row">
                <Col className="padding-padrao-col">
                    <label>Empresa / Filial</label>
                    <Select
                        //@ts-ignore
                        value={{ value: pesquisa.codFilial }}
                        select={{ value: 'codEmpresaFilial', label: 'descricaoEmpresa' }}
                        options={empresas}
                        onChange={(value: any) => actions.setPesquisa({ ...pesquisa, codFilial: value.value })}
                        isSearchable={true}
                        autoComplete="off"
                    />
                </Col>
                <Col className="padding-padrao-col">
                    <label>Nome Funcionário</label>
                    <Input
                        type="text"
                        autoComplete="off"
                        value={pesquisa.nome}
                        disabled={true}
                    />
                </Col>
                <Button
                    style={{ marginTop: '25px', width: '40px', marginBottom: '20px' }}
                    type='button'
                    color="info"
                    onClick={() => toggleFuncionario()}
                >
                    <Icon icon="search" className="fa-lg" />
                </Button>
                <Col className="padding-padrao-col" lg={4}>
                    <Row className="padding-padrao-row">
                        <Col className="padding-padrao-col" lg={6}>
                            <label>Data Início</label>
                            <Input
                                id="dataInicio"
                                type="date"
                                value={pesquisa.dataInicio}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => actions.setPesquisa({ ...pesquisa, dataInicio: e.currentTarget.value })}
                            />
                        </Col>
                        <Col className="padding-padrao-col" lg={6}>
                            <label>Data Fim</label>
                            <Input
                                id="dataFim"
                                type="date"
                                value={pesquisa.dataFinal}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => actions.setPesquisa({ ...pesquisa, dataFinal: e.currentTarget.value })}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <CardBody>
                    <Col style={{ width: '100%' }}>
                        <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={() => handleLimpar()}
                        >Limpar</Button>
                        <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={() => handleOnPesquisar()}
                        >Pesquisar</Button>
                    </Col>
                </CardBody>
            </Row>

            {
                modalFuncionario &&
                <ModalFuncionario
                    isOpen={true}
                    toggle={toggleFuncionario}
                    getFuncionario={pesquisaFuncionario}
                    confirmModal={confirmarFuncionario}
                    clean={cleanFuncionario}
                />
            }
        </>
    );
}

function TableItensPesquisados() {
    const listItensPesquisa = useShallowEqualSelector<AppState, IInventarioState['listItensPesquisa']>(state => state.inventario.listItensPesquisa)

    const [pageSize, setPageSize] = useState(5);
    const [pageAtual, setPageAtual] = useState(1);

    const [inventarioSelecionado, setInventarioSelecionado] = useState(null);

    const [checkAll, setCheckAll] = useState(false);

    function onChangeCheck(index: number) {
        let newListStr = JSON.stringify(listItensPesquisa);
        let newListArr = JSON.parse(newListStr);

        const checked = !newListArr[index].checked;

        newListArr[index].checked = checked;

        if (!checked)
            setCheckAll(false)

        actions.setListItens(newListArr);

    }

    function getItensPesquisaInventario(object: any) {
        let arr: any[] = []
        listItensPesquisa.forEach((element: any) => {
            if (object.codigo === element.codigo) {
                arr = element.itens
            }
        })

        if (arr.length > 0) {
            actions.setListItensInventario(arr)
        } else {
            alertar(notificacao, 'br', 'Nenhum produto encontrado', 'warning', 'icon-alert-circle-exc', 2)
            return
        }
    }

    return (
        <fieldset className='fieldset-style'>
            <legend style={{ fontSize: '14px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
                Inventários
            </legend>
            <div
                style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative' }}>
                <ReactTable
                    data={listItensPesquisa}
                    filterable={false}
                    getTrProps={(e: any, rowInfo: any) => {
                        return {
                            style: {
                                cursor: 'pointer',
                                height: '50px',
                                paddingTop: '0px',
                                paddingBottom: '5px',
                                backgroundColor: rowInfo && rowInfo.index === inventarioSelecionado ? 'rgba(71, 179, 255, 0.41)' : ''
                            },
                            onClick: () => {
                                getItensPesquisaInventario(rowInfo.original)
                                setInventarioSelecionado(rowInfo.index)
                            }
                        }
                    }}
                    NoDataComponent={() => (<></>)}
                    columns={
                        [
                            {
                                Header: "Inventário",
                                accessor: "codigo"
                            },
                            {
                                Header: "Empresa",
                                accessor: "empresa"
                            },
                            {
                                Header: "Usuário",
                                accessor: "usuario"
                            },
                            {
                                Header: "Data Inventário",
                                accessor: "dataInventario"
                            },

                        ]

                    }
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando"
                    pageText="Página"
                    ofText="de"
                    rowsText='linhas'
                    defaultPageSize={pageSize}
                    onPageChange={(page) => { setPageAtual(page) }}
                    onPageSizeChange={(size) => { setPageSize(size) }}
                />
            </div>
        </fieldset>
    );
}

function TableProdutosVisualizacao(props: any) {
    const listItensInventario = useShallowEqualSelector<AppState, IInventarioState['listItensInventario']>(state => state.inventario.listItensInventario)

    function setGradeProduto(grades: any[], codigoProduto: number, codigoTipoGrade: number, quantidadeAtualizar: number) {
        actions.setGradeSelecionada({ codigoProduto, codigoTipoGrade, quantidadeAtualizar })
        actions.setGradePesquisa(grades)
    }

    return (
        <fieldset className='fieldset-style'>
            <legend style={{ fontSize: '14px', width: 'auto', margin: '0 15px 0 15px', padding: '0 25px 0 25px' }} >
                <Row>
                    Produtos do Inventário
                </Row>
            </legend>
            <div
                style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative' }}>
                <ReactTable
                    data={listItensInventario}
                    filterable={false}
                    NoDataComponent={() => (<></>)}
                    columns={
                        [
                            {
                                Header: "Número",
                                accessor: "numeroProduto",
                                width: 85
                            },
                            {
                                Header: "Cód. Barras",
                                accessor: "codigoBarras"
                            },
                            {
                                Header: "Grupo",
                                accessor: "descricaoGrupo"
                            },
                            {
                                Header: "SubGrupo",
                                accessor: "descricaoSubGrupo"
                            },
                            {
                                Header: "Descrição",
                                accessor: "descricaoProduto"
                            },
                            {
                                Header: "Estoque Anterior",
                                accessor: "estoqueAnterior"
                            },
                            {
                                Header: "Inventário",
                                accessor: "estoqueInventario"
                            },
                            {
                                Header: "Estoque Atual",
                                accessor: "estoqueAtual"
                            },
                            {
                                width: 35,
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                Cell: (prop) =>
                                    <>
                                        <ArrowTooltip title="Grade">
                                            <Button
                                                onClick={() => { props.toogleModalGrade(); setGradeProduto(prop.original.grades, prop.original.codigoProduto, prop.original.codigoTipoGrade, prop.original.estoqueAtualizar) }}
                                                size="sm"
                                                className={classNames("btn-icon btn-link")}
                                                color="info"
                                                disabled={!prop.original.flControlaGrade}
                                            >
                                                <Icon icon="th" className="fa-lg" />
                                            </Button>
                                        </ArrowTooltip>

                                    </>
                            }
                        ]
                    }
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando"
                    pageText="Página"
                    ofText="de"
                    rowsText='linhas'
                    pageSize={listItensInventario.length}
                />
            </div>
        </fieldset>
    )
}

export default Pesquisar;