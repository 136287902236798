
import React from "react";

import {
    Card,
    Col,
    CardHeader,
    CardBody,
    CardTitle
} from "reactstrap";

import TabsContent from "./TabContent/TabContent"
import TelaPesquisar from './TabContent/Tabs/Pesquisar';
import TelaGerarSaida from './TabContent/Tabs/Cadastrar';




const Tabs = [
    {
        tabName: "PESQUISAR",
        tabScreen: <TelaPesquisar />
    },
    {
        tabName: "CADASTRAR",
        tabScreen: <TelaGerarSaida />
    },
]

const LancamentoTitulos = () => {

    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="12">
                    <Card>

                        <CardHeader className="text-center">
                            <CardTitle tag="h3">Lançamento de Títulos</CardTitle>
                        </CardHeader>

                        <CardBody>
                            <TabsContent tabs={Tabs} />
                        </CardBody>
                    </Card>
                </Col>
            </div>

        </>
    );
}

export default LancamentoTitulos;
