import React from "react";
import { useSelector, useDispatch } from 'react-redux'

import { Select } from "~/components/ErnComponets/ErnComponets";
import { InputNumber } from "~/components/ErnComponets/ErnComponets";
import { ActionsStep } from "../Cadastro"

import {
    Row,
    Col,
    CardBody,
    CardFooter,
} from "reactstrap";

import { maskClassificacoes } from '../../../../Util/mascaras'

const idStore = "CLASSIFICACOES_TRIBUTARIAS";

const ICMS = () => {
    const dispatch = useDispatch();
    const { tributacao, verCadastro } = useSelector(state => state.classificacoesTributarias);
    const { ICMSs } = useSelector(state => state.classificacoesTributarias);

    function handleOnChange(value, campo) {
        dispatch({ idStore, type: 'TRIBUTACAO', campo, value });
    }

    return (
        <Row className="justify-content-center" style={{ marginTop: '-70px' }}>
            <div className={verCadastro === true ? 'ver-cadastro' : ''}>

            </div>
            <Col>
                <CardBody>

                    <Row className="justify-content-center">

                        <Col>
                            <h5>CST/CSOSN</h5>
                            <Select
                                first="Selecione"
                                value={{ value: tributacao.codICMS }}
                                select={{ value: "codIcms", label: "descricaoFormatada" }}
                                onChange={value => handleOnChange(value.value, 'codICMS')}
                                options={ICMSs}
                                isSearchable={true}
                                autoComplete="off"
                            />
                        </Col>
                    </Row>

                    <Row className="justify-content-center">

                        <Col lg>
                            <div className="box box-padding-bottom">
                                <h5>ICMS</h5>
                                <Row className="padding-padrao-row">
                                    <Col className="padding-padrao-col" lg="6">
                                        <label>Percentual ICMS %</label>
                                        <InputNumber
                                            type="text"
                                            format="5,2"
                                            value={tributacao.percICMS}
                                            onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'percICMS')}
                                        />
                                    </Col>
                                    <Col className="padding-padrao-col" lg="6">
                                        <label>Alíquota Efetiva do ICMS %</label>
                                        <InputNumber
                                            type="text"
                                            format="5,2"
                                            value={tributacao.percICMSEfetivo}
                                            onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'percICMSEfetivo')}
                                        />
                                    </Col>
                                </Row>
                                <Row className="padding-padrao-row">
                                    <Col className="padding-padrao-col" lg="6">
                                        <label>Alíquota de Crédito %</label>
                                        <InputNumber
                                            type="text"
                                            format="5,2"
                                            value={tributacao.percICMSCredito}
                                            onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'percICMSCredito')}
                                        />
                                    </Col>
                                    <Col className="padding-padrao-col" lg="6">
                                        <label>Alíquota de Redução %</label>
                                        <InputNumber
                                            type="text"
                                            format="5,2"
                                            value={tributacao.percICMSReducao}
                                            onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'percICMSReducao')}
                                        />
                                    </Col>
                                </Row>
                                <Row className="padding-padrao-row">
                                    <Col className="padding-padrao-col" lg="6">
                                        <label>Alíquota de Diferimento %</label>
                                        <InputNumber
                                            type="text"
                                            format="5,2"
                                            value={tributacao.percICMSDiferimento}
                                            onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'percICMSDiferimento')}
                                        />
                                    </Col>
                                    <Col className="padding-padrao-col" lg="6">
                                        <label>Fundo de Combate a Pobreza %</label>
                                        <InputNumber
                                            type="text"
                                            format="5,2"
                                            value={tributacao.percFundPobreza}
                                            onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'percFundPobreza')}
                                        />
                                    </Col>
                                </Row>
                                <Row className="padding-padrao-row">
                                    <Col className="padding-padrao-col" lg="6">
                                        <label>ICMS Efetivo (Cupom Fiscal)</label>
                                        <InputNumber
                                            type="text"
                                            format="5,2"
                                            value={tributacao.ICMSEfetivo}
                                            onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'ICMSEfetivo')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg>
                            <div className="box box-padding-bottom">
                                <h5>ICMS Substituição</h5>
                                <Row className="padding-padrao-row">
                                    <Col className="padding-padrao-col" lg="6">
                                        <label>Percentual ICMS Substituição %</label>
                                        <InputNumber
                                            type="text"
                                            format="5,2"
                                            value={tributacao.percICMSST}
                                            onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'percICMSST')}
                                        />
                                    </Col>
                                    <Col className="padding-padrao-col" lg="6">
                                        <label>Alíquota Efetiva do ICMS ST %</label>
                                        <InputNumber
                                            type="text"
                                            format="5,2"
                                            value={tributacao.percICMSEfetivoST}
                                            onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'percICMSEfetivoST')}
                                        />
                                    </Col>
                                </Row>
                                <Row className="padding-padrao-row">
                                    <Col className="padding-padrao-col" lg="6">
                                        <label>Alíquota ST Retido %</label>
                                        <InputNumber
                                            type="text"
                                            format="5,2"
                                            value={tributacao.percICMSRetidoST}
                                            onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'percICMSRetidoST')}
                                        />
                                    </Col>
                                    <Col className="padding-padrao-col" lg="6">
                                        <label>Alíquota de Redução ST %</label>
                                        <InputNumber
                                            type="text"
                                            format="5,2"
                                            value={tributacao.percICMSReducaoST}
                                            onChange={e => handleOnChange(maskClassificacoes(e.target.value), 'percICMSReducaoST')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                    </Row>
                </CardBody>
                <CardFooter className="text-center">
                    <ActionsStep />
                </CardFooter>
            </Col>
        </Row>
    )
};

class Wizard extends React.Component {
    focusInput = () => {
    }

    render() {
        return (
            <ICMS />
        )
    }
}

export default Wizard;
