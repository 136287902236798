import React from "react";
import { Button, Col, Label, Row } from "reactstrap";
import ArrowTooltip from "../../../../components/Tooltip/ArrowTooltip";

interface Props {
    title?: string,
    labelTop?: string,
    labelBottom?: string,
    setCodForma?: Function,
    activeItem?: boolean
}
/**
 * @param title?: string | Texto para o Tooltip
 * @param labelTop?: string | Texto para o Topo do card
 * @param labelBottom?: string | Texto para a Base do card
 */

export function MiniCard(props: Props) {

    return (
        <Col style={{ padding: "2px", minHeight: "70px", minWidth:"150px" }} id="body-miniCard">
            <ArrowTooltip title={props.title} placement={'right'}>
                <Button
                    className={props.activeItem ? 'activeBtn' : ''}
                    style={{backgroundImage: "linear-gradient(to bottom left, #1d8cf8, #3358f4, #1d8cf8)", textAlign: 'center', whiteSpace: 'normal' }}   
                    //@ts-ignore
                    onClick={() => props.setCodForma()}
                >
                    {props.labelTop}
                </Button>
            </ArrowTooltip>
        </Col>
    )
}