import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerClassificacoes from "~/controller/controllerClassificacoesTributarias";

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import { Switch } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';

import Tributacoes from "./Tributacoes";
import Tributacao from "./Tributacao";


import {
    Input,
    Row,
    Col,
    CardBody,
    Button,
    CardFooter,
    FormGroup,
    TabContent,
    TabPane,
} from "reactstrap";

const idStore = "CLASSIFICACOES_TRIBUTARIAS";


const Cadastro = ({ TribTabs, changeTribTab }) => {
    const notificacao = useSelector(state => state.notificacao);
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
    const { pesquisa, classificacao, classificacaoCopia, invalido, verCadastro } = useSelector(state => state.classificacoesTributarias);
    const [modalInativar, setModalInativar] = useState(false);
    const dispatch = useDispatch();

    function onChange(value, stateName) {
        dispatch({ idStore, type: 'CLASSIFICACAO', campo: stateName, value: value });
        dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
    }

    async function onChangeAtivo() {
        toggleModalInativar()
    }

    function inativar() {
        if (classificacao.codClassificacao !== '') {
            controllerClassificacoes.Inativar(notificacao, dispatch, codEmpresa, classificacao, pesquisa);
        }
        dispatch({ idStore, type: 'CLASSIFICACAO', campo: 'status', value: classificacao.status === 1 ? 0 : 1 });
    }

    async function Salvar() {
        if (classificacao.codClassificacao === 0) {
            controllerClassificacoes.CreateClassificacao(notificacao, dispatch, codEmpresa, classificacao);
        } else {
            controllerClassificacoes.UpdateClassificacao(notificacao, dispatch, codEmpresa, classificacao, classificacaoCopia);
        }
    }

    function Nova() {
        dispatch({ idStore, type: 'CLEAN_CLASSIFICACAO' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        document.getElementById('caddescricao').focus()
    }

    function toggleModalInativar() {
        setModalInativar(!modalInativar);
    }

    function closeTributacao() {
        dispatch({ idStore, type: 'CLEAN_TRIBUTACAO' });
        changeTribTab('tributacoes')
    }

    return (
        <>
            <Row className="justify-content-center">
                <Col>
                    <Row className="justify-content-center">
                        <h4 className="info-text">Preencha com as informações da classificação tributária</h4>
                    </Row>
                    <CardBody>

                        <Row className="justify-content-center">

                            <Col md="10">

                                <Row>

                                    <Col lg>

                                        <label>Descrição da classificação tributária<span className="error"> *</span></label>
                                        <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                                            <Input
                                                id="caddescricao"
                                                type="text"
                                                value={classificacao.descricao}
                                                onChange={e => onChange(e.target.value, 'descricao')}
                                                autoComplete="off"
                                                maxLength="100"
                                                disabled={ verCadastro}
                                            />
                                            <br></br>
                                        </FormGroup>

                                    </Col>

                                    <Col lg="2" className="text-right" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Switch
                                            componentleft={<label className={classificacao.status === 0 ? `text-success text-center` : "text-warning text-center"}>{classificacao.status === 0 ? "Ativo" : "Inativo"}</label>}
                                            checked={classificacao.status === 0}
                                            onChange={e => onChangeAtivo(e)}
                                            disabled={classificacao.codClassificacao === "" || verCadastro}                                      
                                        />
                                    </Col>

                                </Row>

                                <div className="box" style={{ position: 'relative' }}>
                                    <Row>
                                        <Col lg md sm>

                                            <TabContent
                                                className="tab-space"
                                                activeTab={TribTabs}
                                                style={{ paddingBottom: '20px' }}
                                            >
                                                <TabPane tabId="tributacoes">

                                                    <Tributacoes
                                                        changeTribTab={changeTribTab}
                                                    />

                                                </TabPane>

                                                <TabPane tabId="tributacao">

                                                    <Tributacao 
                                                        closeTributacao={closeTributacao}
                                                    />

                                                </TabPane>


                                            </TabContent>

                                        </Col>
                                    </Row>
                                    <ArrowTooltip title="Fechar">
                                        <button
                                            className={`tributacao-close-button${TribTabs === 'tributacoes' ? '-none' : ''}`}
                                            type="button"
                                            onClick={closeTributacao}
                                        >X</button>
                                    </ArrowTooltip>
                                </div>

                            </Col>

                        </Row>

                    </CardBody>
                    <CardFooter className="text-center">
                        <Row className="justify-content-center">
                            <Col md="10" >
                                { TribTabs === "tributacoes" ?
                                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button  className="btn-padrao" type="button" color="default" onClick={Nova}>NOVA</Button>
                                    <Button  className="btn-padrao" type="button" color="info" onClick={Salvar}>SALVAR</Button>
                                </Col>
                                : null
                                }
                            </Col>
                        </Row>
                    </CardFooter>
                </Col>
            </Row>

            {/* Modal Inativar */}
            <ModalBasic
                isOpen={modalInativar}
                toggle={toggleModalInativar}
                title={`${classificacao.status === 0 ? "Inativar" : "Ativar"} classificação`}
            >
                <ModalBasicBody>

                    <Col className="text-center">
                        <label>
                            {classificacao.status === 0 ?
                                <>Tem certeza que deseja <b>inativar</b> essa classificação tribuária da sua empresa?</>
                                : <>Tem certeza que deseja <b>ativar</b> essa classificação tribuária em sua empresa?</>
                            }
                        </label>
                    </Col>

                </ModalBasicBody>

                <ModalBasicFooter>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { toggleModalInativar() }}
                                style={{ width: '150px' }}
                            >NÃO
                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { toggleModalInativar(); inativar() }}
                                style={{ width: '150px' }}
                            >SIM
                            </Button>
                        </Col>
                    </Row>
                </ModalBasicFooter>

            </ModalBasic>
            {/* Modal Inativar*/}

        </>
    )
};

export default Cadastro;


export function ActionsStep() {
    const notificacao = useSelector(state => state.notificacao);
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
    const { pesquisa, classificacao: { codClassificacao }, tributacao, tributacaoCopia, verCadastro } = useSelector(state => state.classificacoesTributarias);
    const dispatch = useDispatch();

    function SalvarTributacao() {
        if (tributacao.codTributacao === 0) {
            controllerClassificacoes.CreateTributacao(notificacao, dispatch, codEmpresa, codClassificacao, tributacao, pesquisa)
        } else {
            controllerClassificacoes.UpdateTributacao(notificacao, dispatch, codEmpresa, codClassificacao, tributacao, tributacaoCopia, pesquisa)
        }
    }

    function NovaTributacao() {
        dispatch({ idStore, type: 'CLEAN_TRIBUTACAO' });
    }
    return (
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <Button disabled={ verCadastro} className="btn-padrao" color="default" type="button" onClick={NovaTributacao}>Nova</Button>
            <Button disabled={ verCadastro} className="btn-padrao" color="info" type="button" onClick={SalvarTributacao}>Salvar</Button>
        </Col>
    )
}