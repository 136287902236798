import React from "react";

import { useHotkeys } from 'react-hotkeys-hook';

import TabContent from './TabContent/TabContent'
import * as actions from '../../store/reducers/Cadastro/Operacao/actions'
import {
    Card,
    Col,
    CardHeader,
    CardBody,
    CardTitle
} from "reactstrap";


// wizard steps
import Pesquisa from './TabContent/tabs/Pesquisa/Pesquisa'
import Cadastro from './TabContent/tabs/Cadastro/Cadastro'

const Tabs = [
    {
        tabName: "PESQUISAR",
        tabScreen: <Pesquisa />,
        ArrowTooltipTitle: 'Shift+1'
    },
    {
        tabName: "CADASTRAR",
        tabScreen: <Cadastro />,
        ArrowTooltipTitle: 'Shift+2'
    }
]

const movimentacaos = () => {

    useHotkeys('shift+1', () => {
        actions.setScreen(0)
    }, [])

    useHotkeys('shift+2 ', () => {
        actions.setScreen(1)
    }, [])
    return (

        <>
            <div className='content'>
                <Col className="mr-auto ml-auto" md="12">
                    <Card >
                        <CardHeader className="text-center">
                            <CardTitle tag="h3">Operações</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <TabContent tabs={Tabs} />
                        </CardBody>
                    </Card>
                </Col>

            </div>
        </>
    )
}

export default movimentacaos;
