import React, { useEffect } from "react";

import * as controllerLancamento from '../../../../controller/Financeiro/controllerLancamentoTitulos'
import Formulario from './Formulario'
import Table from './Table'

import {
  Row,
  Col,
  CardBody,
  CardFooter,
} from "reactstrap";


const Resumo = function () {

  useEffect(() => {
    controllerLancamento.getFuncionario();
  }, [])
  return (
    <>
      <Row className="justify-content-center">
        <Col>
          <CardBody>
            <Row >
              <Col>
                <Row className="justify-content-center">
                  <Col lg="11">
                    <Formulario />
                    <br />
                    <Table />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-center">
          </CardFooter>
        </Col>
      </Row>
    </>)
}
class Wizard extends React.Component {
  render() {
    return (
      <Resumo />
    )
  }
}

export default Wizard