export const unidadesMedida = [
    { value: 1, label: "Unidade" },
    { value: 2, label: "Pacote" },
    { value: 3, label: "Metros" },
    { value: 4, label: "Quilogramas" },
];

export const voltagens = [
    { value: "", label: "Não tem" },
    { value: "110", label: "110V" },
    { value: "220", label: "220V" },
    { value: "BVT", label: "Bivolt" },
];

export const garantias = [
    { value: 0, label: "Nenhuma" },
    { value: 12, label: "12 meses" },
    { value: 24, label: "24 meses" },
    { value: 36, label: "36 meses" },
];

export const flags = [
    { value: "flControlaComissao", label: "Controla Estoque" },
    { value: "flControlaEstoque", label: "Controla Comissao" },
    { value: "flControlaNumSerie", label: "Controla N° Serie" },
    { value: "flControlaGrade", label: "Controla Grade" },
    { value: "flTributado", label: "Controla Tributado" },
    { value: "flItemVenda", label: "Controla item Venda" }
];