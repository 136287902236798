export enum SaidaDiversaStore {
    ID_STORE = 'SAIDA_DIVERSA'
}

export enum SaidaDiversaTypes {
    CLEAN_ALL = 'CLEAN_ALL',
    CLEAN_PESQUISA = 'CLEAN_PESQUISA',
    CLEAN_REFERENCIA = 'CLEAN_REFERENCIA',
    CLEAN_CADASTRO = 'CLEAN_CADASTRO',
    CLEAN_DADOS_INICIAIS = 'CLEAN_DADOS_INICIAIS',
    CLEAN_PRODUTO = 'CLEAN_PRODUTO',
    CLEAN_INVALIDO = 'CLEAN_INVALIDO',

    OPERACOES = 'OPERACOES',
    OPERACAO = 'OPERACAO',

    PESQUISA = 'PESQUISA',
    SET_EMPRESAS = 'SET_EMPRESAS',
    LIST_SAIDAS = 'LIST_SAIDAS',
    SAIDA = 'SAIDA',
    UPDATE_SAIDA = 'UPDATE_SAIDA',
    UPDATE_FATURA = 'UPDATE_FATURA',
    SAIDA_REFERENCIA = 'SAIDA_REFERENCIA',

    DESTINATARIOS = 'DESTINATARIOS',
    DESTINATARIO = 'DESTINATARIO',
    DESTINATARIO_COPIA = 'DESTINATARIO_COPIA',

    TRANSPORTADORAS = 'TRANSPORTADORAS',
    TRANSPORTADORA = 'TRANSPORTADORA',
    TRANSPORTADORA_COPIA = 'TRANSPORTADORA_COPIA',
    ADICIONAL = 'ADICIONAL',
    ADICIONAL_COPIA = 'ADICIONAL_COPIA',

    PRODUTO = 'PRODUTO',
    PRODUTO_LIST = 'PRODUTO_LIST',
    PRODUTO_LIST_COPIA = 'PRODUTO_LIST_COPIA',
    VALOR_TOTAL = 'VALOR_TOTAL',
    PRODUTO_GRADES = 'PRODUTO_GRADES',

    WIZARD_STEP = 'WIZARD_STEP',
    POP_UP = 'POP_UP',
    INVALIDO = 'INVALIDO',

    SCREEN = 'SCREEN',

    SET_OPERACAO = 'SET_OPERACAO',
    SET_OPERACAO_COPIA = 'SET_OPERACAO_COPIA',
    SET_MODAL_OPERACAO = 'SET_MODAL_OPERACAO',
    SET_MODAL_PRODUTO_GRADES = 'SET_MODAL_PRODUTO_GRADES',

    SET_GRADE_SELECIONADA = 'SET_GRADE_SELECIONADA'
}

export type SaidaDiversaAction = {
    idStore: SaidaDiversaStore.ID_STORE
    type: SaidaDiversaTypes
    destinatario?: Destinatario | undefined
    transportadora?: Transportadora | undefined
    list?: any[] | undefined
    pesquisa?: Pesquisa
    produto?: any
    campo?: any
    value?: any
    total?: string
    invalido?: Campos,
    operacao?: Operacao

}

export interface SaidaDiversaState {
    pesquisa: Pesquisa,
    empresas: Empresas[],
    saida: Saida,
    produto: Produto,
    produtoGrades: any[],
    gradeSelecionada: any,

    listSaidas: any[],
    destinatarios: any[],
    operacoes: any[],
    transportadoras: any[],
    inicioCadastro: Boolean,
    currentStep: number | null,
    isVisibleModalOperacao: Boolean,
    isVisibleModalProduto: Boolean,
    invalido: Campos,
    validaNFeReferencia: Boolean,
    emiteNFe: Boolean,
    popUpFaturamento: boolean,
    screen: number,
    modalOperacao: boolean,
    modalProdutoGrades: boolean,
}

export interface Pesquisa {
    idSaida: number,
    numeroSaida: string,
    codEmpresa?: string,
    nomeDestinatario: string,
    dataInicio: string,
    dataFim: string,
    status: number | null,
    destinatario: any
}

export interface Saida {
    idSaida: number,
    idFatura: number,
    cabecaSaida: CabecaSaida,
    cabecaSaidaCopia: CabecaSaida,
    nfeReferencia: NFERefercia,
    destinatario: Destinatario,
    destinatarioCopia: Destinatario,
    transportadora: Transportadora,
    transportadoraCopia: Transportadora,
    produtos: Produto[],
    produtosCopia: ProdutoCopia[],
    dadosAdicionais: string,
    dadosAdicionaisCopia: string,
    valorDesconto: string,
    valorSubTotal: string
    valorTotal: string

}


export interface NFERefercia {
    numero: string,
    modelo: string,
    chave: string,
    cnpjEmissor: string,
    anoMes: string,
    uf: string
}

export interface Transportadora {
    codTransportadora: string,
    modalidade: number,
    valorFrete: string,
    documento: string,
    razaoSocial: string,
    placaVeiculo: string,
    uf: string,
    quantidadeVolume: string,
    especie: string,
    marca: string,
    numeroVolume: string,
    pesoLiquido: string,
    pesoBruto: string
}

export interface Destinatario {
    codigo: number,
    documento: string,
    razaoSocial: string,
    tipoDestinatario: string
}

export interface Produto {
    numeroPedidoItem: number,
    item: number,
    codProduto: number,
    numero: string,
    descricao: string,
    valorUnitario: string,
    quantidade: string,
    valorTotal: string,
    dataLancamento: string,
    status: number,
    flControlaGrade: number,
    grades: any,
}

export interface ProdutoCopia {
    numeroPedidoItem: number,
    item: number,
    codProduto: number,
    numero: string,
    descricao: string,
    valorUnitario: string,
    quantidade: string,
    valorTotal: string,
    dataLancamento: string,
    status: number,
    flControlaGrade: number,
    grades: any,
}

export interface CabecaSaida {
    codigoOperacao: number,
    numeroOperacao: number,
    descricaoOperacao: string,
    gerarConta: number,
    presenca: string,
    descricaoPresenca: string
}

export interface Campos {
    dadosIniciais: {
        destinatario: {
            documento: Boolean
        },
        transportadora: CamposTransportadora
        dadosAdicionais: Boolean
    },
    nfeReferencia: CamposNFeReferencia
}

export interface CamposTransportadora {
    valorFrete: Boolean,
    documento: Boolean,
    placaVeiculo: Boolean,
    uf: Boolean,
    quantidadeVolume: Boolean,
    especie: Boolean,
    marca: Boolean,
    numeroVolume: Boolean,
    pesoLiquido: Boolean,
    pesoBruto: Boolean
}

export interface CamposNFeReferencia {
    numero: Boolean,
    modelo: Boolean,
    chave: Boolean,
    documentoEmissor: Boolean,
    uf: Boolean
}

export interface Operacao {
    codigoOperacao: number,
    numeroOperacao: number,
    descricaoOperacao: string,
    gerarConta: number
}

export interface Empresas {
    codEmpresaFilial: number,
    descricaoEmpresa: string
}