import store from "../../../stores";
import { FaturamentoTypes, FaturamentoStore, FaturamentoAction, IFaturamentoState } from "./types";

export const setLancamento = (lancamento: IFaturamentoState['lancamento']) => store.dispatch<FaturamentoAction>({
    idStore: FaturamentoStore.ID_STORE,
    type: FaturamentoTypes.LANCAMENTO,
    lancamento
});

export const setLancamentos = (lancamentos: IFaturamentoState['lancamentos']) => store.dispatch<FaturamentoAction>({
    idStore: FaturamentoStore.ID_STORE,
    type: FaturamentoTypes.LANCAMENTOS,
    lancamentos
});

export const setTotais = (totais: IFaturamentoState['totais']) => store.dispatch<FaturamentoAction>({
    idStore: FaturamentoStore.ID_STORE,
    type: FaturamentoTypes.TOTAIS,
    totais
});

export const setIdLancamento = (idLancamento: number) => store.dispatch<FaturamentoAction>({
    idStore: FaturamentoStore.ID_STORE,
    type: FaturamentoTypes.ID_FINANCEIRO,
    value: idLancamento
});

export const setInformacoes = (informacoes: any) => store.dispatch<FaturamentoAction>({
    idStore: FaturamentoStore.ID_STORE,
    type: FaturamentoTypes.INFORMACOES,
    informacoes
});

export const setPagamentos = (pagamentos: IFaturamentoState['pagamentos']) => store.dispatch<FaturamentoAction>({
    idStore: FaturamentoStore.ID_STORE,
    type: FaturamentoTypes.PAGAMENTOS,
    pagamentos
});

export const setTeclado = (teclado: IFaturamentoState['teclado']) => store.dispatch<FaturamentoAction>({
    idStore: FaturamentoStore.ID_STORE,
    type: FaturamentoTypes.TECLADO,
    teclado
});

export const setPagamentoSelecionado = (pagamentoSelecionado: IFaturamentoState['pagamentoSelecionado']) => store.dispatch<FaturamentoAction>({
    idStore: FaturamentoStore.ID_STORE,
    type: FaturamentoTypes.PAGAMENTO_SELECIONADO,
    pagamentoSelecionado
});

export const cleanAll = () => store.dispatch<FaturamentoAction>({
    idStore: FaturamentoStore.ID_STORE,
    type: FaturamentoTypes.CLEAN_ALL
});

export const clean = () => store.dispatch<FaturamentoAction>({
    idStore: FaturamentoStore.ID_STORE,
    type: FaturamentoTypes.CLEAN
});