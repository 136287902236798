import { useState, useEffect } from 'react'

export default function useResize(): { width: number, height: number } {
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {
        window.addEventListener('resize', () => setSize({ width: window.innerWidth, height: window.innerHeight }));
        return () => {
            window.removeEventListener('resize', () => setSize({ width: window.innerWidth, height: window.innerHeight }));
        }
    }, [])
    
    return size
}