import React, { useCallback, useEffect, useState } from 'react'
import ReactTable from 'react-table'
import { Button, Col, Row } from 'reactstrap'
import { HelpButton } from '../../../../components/HelpPlugin/HelpPlugin'
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from '../../../../components/ModalBasic/ModalBasic'
import { IItensGradePDV, Item, Produto } from '../../../../store/reducers/Movimentacao/PedidoVenda/types'
import * as controllerPDV from '../../../../controller/Movimentacao/controllerPedidoVenda'
import * as actions from '../../../../store/reducers/Movimentacao/PedidoVenda/actions';
import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector'
import AppState from '../../../../store/storeType'
import { maskMoeda } from '../../../../Util/mascaras'
import { Round } from '../../../../Util/numbers'
import { formats } from '../variables'

interface IModalGradeProps {
  isOpen: boolean;
  codProduto?: string | number;
  toggle: any;
  typeAction?: string;
  indexGrade?: number;
}

const ModalGrade: React.FC<IModalGradeProps> = ({ codProduto, toggle, isOpen, typeAction,  indexGrade }) => {
  const itensGrade = useShallowEqualSelector<AppState, IItensGradePDV>(state => state.pedidoVenda.itensGrade);
  const [item, setItem] = useState(null)
  //@ts-ignore
  const produto = useShallowEqualSelector(state => state.pedidoVenda.produto)
  const [restante, setRestante] = useState<Number>(0)
  const [quantidade, setQuantidade] = useState(0)
  
  const itens = useShallowEqualSelector<AppState, Item[]>(state => state.pedidoVenda.itens)

  useEffect(() => {
    if (itensGrade) {
      const estoqueAtual = itensGrade.grades.map(i => i.qtdEstoqueGrade)
      const totalEstoqueAtual = estoqueAtual.reduce((qtdEstoque, value) => {
        return Number(qtdEstoque) + Number(value)
      })
      setRestante(Number(totalEstoqueAtual))
    }
  }, [itensGrade])

  const loadProduct = useCallback(async () => {
    const response = controllerPDV.GetProduto(Number(codProduto))
    await response.then(response => {
      //@ts-ignore
      setItem(response)
    })
  }, [codProduto])

  useEffect(() => {
    loadProduct()
  }, [loadProduct])

  useEffect(() => {
    if (item) {
      //@ts-ignore
      controllerPDV.getProdutoGrade(Number(item.codProduto))
    }
  }, [item])

  function onChangeEstoque(index: number, quantidade: string) {
    let gradesStr = JSON.stringify(itensGrade.grades);
    let gradesArr: any[] = JSON.parse(gradesStr);

    if (Number(quantidade) > gradesArr[index].qtdEstoqueGrade || Number(quantidade) < 0) {
      gradesArr[index].estoqueAtualizar = 0
    } else {
      gradesArr[index].estoqueAtualizar = Number(quantidade)
    }

    let attArr = itensGrade
    attArr.grades = gradesArr
    calcularRestante(gradesArr)
    actions.setItensGradePDV(attArr)

  }

  function calcularRestante(listArr: any[]) {
    var total = 0
    let restanteTotal = 0

    listArr.forEach((grade: any) => {
      if (grade.estoqueAtualizar) {
        total += Number(grade.estoqueAtualizar)
      }
    })

    const estoqueAtual = itensGrade.grades.map(i => i.qtdEstoqueGrade)
    const totalEstoqueAtual = estoqueAtual.reduce((qtdEstoque, value) => {
      return Number(qtdEstoque) + Number(value)
    })

    restanteTotal = Number(totalEstoqueAtual) - total
    setQuantidade(Number(total))

    if (restanteTotal >= 0) {
      setRestante(restanteTotal)
    }
  }

  function handleSave() {
    const grade = itensGrade.grades.filter(grade => grade.estoqueAtualizar > 0 && grade)

    //@ts-ignore
    item.subtotal = maskMoeda((item.precoVendaAtual * quantidade).toFixed(2))
     //@ts-ignore
     item.precoVendaAtual = maskMoeda(Number(item.precoVendaAtual).toFixed(2)), 
    //@ts-ignore
    item.quantidade = quantidade
    //@ts-ignore
    item.descontoPercent = '0'
    //@ts-ignore
    item.descontoReais = '0'
    //@ts-ignore
    item.total = item.subtotal
    //@ts-ignore
    actions.addItem({
      //@ts-ignore
      ...item,
      grade: grade.map(grade => grade.estoqueAtualizar > 0 && ({
        codigoGrade: grade.codGrade,
        quantidade: grade.estoqueAtualizar
      })),
    })
    actions.setProduto({
      //@ts-ignore
      ...produto,
      quantidade: quantidade,
      descricao: '',
    })
    toggle()
  }

  const handleUpdate = () => {
    const grade = itensGrade.grades.filter(grade => grade.estoqueAtualizar > 0 && grade)
    //@ts-ignore
    let arrUpdate = itens.map((i, index) => indexGrade === index && itens[indexGrade].codProduto === i.codProduto  ? {
        ...i, 
        quantidade: quantidade,
        //@ts-ignore
        precoVendaAtual: maskMoeda(Number(item.precoVendaAtual).toFixed(2)), 
        //@ts-ignore
        subtotal: maskMoeda((Number(item.precoVendaAtual) * quantidade).toFixed(2)),
        //@ts-ignore
        total: maskMoeda((Number(item.precoVendaAtual) * quantidade).toFixed(2)),
        grade: grade.map(grade => grade.estoqueAtualizar > 0 && ({
          codigoGrade: grade.codGrade,
          quantidade: grade.estoqueAtualizar
        })),
      } : i)
    //@ts-ignore
    actions.setItens(arrUpdate)
    toggle()
  }

  return (
    <ModalBasic
      isOpen={isOpen}
      toggle={() => toggle()}
      title={'GRADE DE PRODUTOS'}
      size="lg"
      id="modalGrade-pdv"
    >
      <ModalBasicBody>
        <h4 style={{ textAlign: 'center' }}>Quantidade de estoques do invetário da grade de produtos</h4>
        <Row style={{ justifyContent: 'flex-end', paddingRight: 20, paddingBottom: 10 }}>
          <HelpButton id={19} />
        </Row>
        <Row>
          <Col>
            <fieldset className="fieldset-operacao">
              <div>
                <ReactTable
                  data={itensGrade.grades}
                  columns={
                    [
                      {
                        Header: 'Descrição',
                        accessor: 'descricao'
                      },
                      {
                        Header: 'Sigla',
                        accessor: 'sigla',
                      },
                      {
                        Header: 'Quantidade em Estoque',
                        Cell: props =>
                          <>
                            <input
                              className="form-control text-center"
                              value={props.original.qtdEstoqueGrade}
                              disabled
                            />
                          </>
                      },
                      {
                        Header: 'Informa a Quantidade',
                        Cell: props =>
                          <>
                            <input
                              type="number"
                              className="form-control inputGrade-estoque"
                              style={{ textAlign: 'center' }}
                              onChange={(e) => onChangeEstoque(props.index, e.currentTarget.value)}
                              value={props.original.estoqueAtualizar}
                            />
                          </>
                      }
                    ]
                  }
                  resizable={false}
                  sortable={false}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  noDataText=""
                  minRows={itensGrade.grades.length}
                />
              </div>
            </fieldset>
            <div>
              <h4>Restantes: {restante}</h4>
            </div>
          </Col>
        </Row>
      </ModalBasicBody>
      <ModalBasicFooter>
        <Col className="text-center">
          <Button className="btn-padrao" color={"info"} onClick={() => typeAction === 'editar' ? handleUpdate() : handleSave()} >Salvar</Button>
        </Col>
      </ModalBasicFooter>
    </ModalBasic>
  )
}

export default ModalGrade