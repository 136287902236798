import { createRef, useEffect } from 'react'
import PerfectScrollbar from "perfect-scrollbar";

export default function useScrollBar(observe: Array<any> = []): React.RefObject<HTMLDivElement> {
    const ref = createRef<HTMLDivElement>()

    useEffect(() => {
        if (ref.current) {
            var ps = new PerfectScrollbar(ref.current)
        }
        return () => {
            if (ps) ps.destroy()
        }
    }, [...observe])

    return ref
}