//State da tela de Pesquisa e cadastro de IPIs
const INITIAL_STATE = {
    pesquisa: {
        ipi: '',
        descricao: '',
        ativos: 0,
    },
    ipi: {
        codIpi: 0,
        ipi: '',
        descricao: '',
        status: 0,
    },
    invalido: {
        ipi: false,
        descricao: false,
    },
    ipis: [],
    inicioCadastro: false,
    verCadastro: false,
    ipiCopia: {},
};

export default function ipis(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'IPIS':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'IPIS':
                    return { ...state, ipis: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: INITIAL_STATE.pesquisa,
                        ipis: INITIAL_STATE.ipis
                    }
                case 'IPI':
                    return { ...state, ipi: { ...state.ipi, [action.campo]: action.value } }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value } 
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'IPI_COPIA':
                    return { ...state, ipiCopia: state.ipi }
                case 'CLEAN_IPI':
                    return {
                        ...state,
                        ipi : INITIAL_STATE.ipi
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: INITIAL_STATE.invalido
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}