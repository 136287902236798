import React, { useEffect, memo, useState } from 'react'
import { Select, Switch, InputNumber } from "../../../components/ErnComponets/ErnComponets"
import ArrowTooltip from '../../../components/Tooltip/ArrowTooltip'
import MultiSelect from '../../../components/MultiSelect/MultiSelect'
import { Round } from '../../../Util/numbers'
import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup
} from "reactstrap"
import useShallowEqualSelector from '../../../Util/Hooks/useShallowEqualSelector'
import useModal from '../../../Util/Hooks/useModal'
import ModalInativar from './Modals/ModalInativar'
import * as controllerProdutos from '../../../controller/controllerProdutos'
import { unidadesMedida, voltagens, garantias } from '../variables'
import * as actions from '../../../store/reducers/Cadastro/Produto/actions'
import AppState from '../../../store/storeType'
import { Grupo, Subgrupo, Produto, Fornecedor, Invalido, ProdutoState } from '../../../store/reducers/Cadastro/Produto/types'
import * as actionsStore from '../../../store/reducers/Cadastro/Produto/actions'
import { nominalTypeHack } from 'prop-types'
import { maskMarkup, maskMoeda, maskNumbers, maskValor } from '../../../Util/mascaras'

const DadosBasicos: React.FC<{ salvar: boolean }> = (props) => {
  //@ts-ignore
  const { codEmpresa } = useShallowEqualSelector(state => state.login.usuario.empresa)

  const grupos = useShallowEqualSelector<AppState, Grupo[]>(state => state.produtos.grupos)
  const cadsubgrupos = useShallowEqualSelector<AppState, Subgrupo[]>(state => state.produtos.cadsubgrupos)
  const produto = useShallowEqualSelector<AppState, Produto>(state => state.produtos.produto)
  const codProduto = useShallowEqualSelector<AppState, string | number>(state => state.produtos.produto.codProduto)
  const status = useShallowEqualSelector<AppState, number>(state => state.produtos.produto.status)
  const basico = useShallowEqualSelector<AppState, Produto['basico']>(state => state.produtos.produto.basico)
  const fornecedores = useShallowEqualSelector<AppState, Fornecedor[]>(state => state.produtos.fornecedores)
  const produtoCopia = useShallowEqualSelector<AppState, Produto>(state => state.produtos.produtoCopia)
  const invalido = useShallowEqualSelector<AppState, Invalido>(state => state.produtos.invalido)
  const origensProduto = useShallowEqualSelector<AppState, ProdutoState['origensProduto']>(state => state.produtos.origensProduto)
  const verCadastro = useShallowEqualSelector<AppState, boolean>(state => state.produtos.verCadastro)
  const [pesquisaClassifacao] = useState({ descricao: "" })
  const [modalInativar, toggleModalInativar] = useModal(false)

  const { especificacoes, comissao, estoque, caracteristicas } = basico


  useEffect(() => {
    if (props.salvar)
      Salvar()
  }, [props.salvar])

  useEffect(() => {
    controllerProdutos.GetGrupos(codEmpresa)
    actions.setCadSubgrupos([])
  }, [])
  useEffect(() => {
    controllerProdutos.GetFornecedores(codEmpresa)
    actions.setFornecedores([])
  }, [])
  useEffect(() => {
    controllerProdutos.buscarOrigensProduto()
  }, [])

  function onChangeGrupo(value: { value: string | number, label: string }) {
    if (value.value === "0") {
      actions.setCadSubgrupos([])
      actions.setProdutoBasico({ ...basico, codGrupo: value.value, grupo: value.label, codSubgrupo: "0", subgrupo: "" })
    } else {
      controllerProdutos.GetSubgrupos(codEmpresa, value.value, (subgrupos: Subgrupo[]) => {
        actions.setCadSubgrupos(subgrupos)
        actions.setProdutoBasico({ ...basico, codGrupo: value.value, grupo: value.label, codSubgrupo: "0", subgrupo: "" })
      })
    }
  }

  function onChangeSubgrupo(value: { value: string | number, label: string }) {
    actions.setProdutoBasico({ ...basico, codSubgrupo: value.value, subgrupo: value.label })
  }

  function onChangeBasico(field: keyof Produto['basico'], value: any) {
    actions.setProdutoBasico({ ...basico, [field]: value })
    actions.setInvalido({ ...invalido, [field]: false })
  }

  function onChangeEspecificacoes(field: keyof Produto['basico']['especificacoes'], value: any) {
    actions.setProdutoEspecificacoes({ ...especificacoes, [field]: maskValor(value) })
  }

  function onChangeSelect(field: keyof Produto['basico']['especificacoes'], value: any) {
    actions.setProdutoEspecificacoes({ ...especificacoes, [field]: value })
  }

  function onChangeComissao(field: keyof Produto['basico']['comissao'], value: any) {
    actions.setProdutoComissao({ ...comissao, [field]: value })
  }

  function onChangeCaracteristicas(field: keyof Produto['basico']['caracteristicas'], value: any) {
    actions.setProdutoCaracteristicas({ ...caracteristicas, [field]: value })
  }

  function onChangeEstoque(field: keyof Produto['basico']['estoque'], value: any) {
    actions.setProdutoEstoque({ ...estoque, [field]: value })
  }

  function onChangeAtivo() {
    toggleModalInativar()
  }

  async function Salvar() {
    var error = false

    if (codProduto === '') {
      error = await controllerProdutos.CreateProduto(codEmpresa, produto)
    } else {
      error = await controllerProdutos.UpdateProduto(codEmpresa, produto, produtoCopia)
    }

    if (error === false) {
      actions.setProximoCadastro(true)
      setTimeout(() => {
        actions.setProximoCadastro(false)
      }, 1000)
    }
  }

  function Limpar() {
    actions.cleanProduto()
    actions.cleanInvalido()
  }

  function markupCalculator(valor: any) {
    controllerProdutos.calculoValores(valor, especificacoes)
  }

  function handleKeyPress(event: any, campo: string) {
    if (event.key === "Enter") {
      actionsStore.cleanProduto();
      controllerProdutos.buscarProduto(codEmpresa, campo, event.target.value, (prod: any) => {

        controllerProdutos.GetClassificacoes(codEmpresa, pesquisaClassifacao, "0");
        actionsStore.setInicioCadastro(true)
        actionsStore.cleanInvalido()
        controllerProdutos.GetSubgrupos(codEmpresa, prod.codGrupo, (subgrupos: Subgrupo[]) => {
          actionsStore.setCadSubgrupos(subgrupos)
          actionsStore.setProdutoCopia()
        })
        controllerProdutos.GetImagens(codEmpresa, prod.codProduto)
        controllerProdutos.GetTipoGrades(codEmpresa)
      });
    }

  }

  return (
    <>
      <Row className="justify-content-center">
        <div className={verCadastro === true ? 'ver-cadastro' : ''}>

        </div>
        <Col>
          <h4 className="info-text">Preencha com as informações do produto</h4>

          <CardBody>

            <Row className="justify-content-center">

              <Col md="10" className="text-right">
                <Switch
                  componentleft={<label className={status === 0 ? `text-success text-center` : "text-warning text-center"}>{status === 0 ? "Ativo" : "Inativo"}</label>}
                  checked={status === 0}
                  onChange={() => onChangeAtivo()}
                  disabled={codProduto === ""}
                />
              </Col>

              <Col md="10">

                <fieldset className="fieldset-style">
                  <legend className='fieldset-legends-style' >
                    Dados Basicos
                        </legend>
                  <Row className="padding-padrao-row">

                    <Col className="padding-padrao-col" lg="2" md sm="7">
                      <label>Código</label>
                      <FormGroup>
                        <InputNumber
                          id="cadNumeroProduto"
                          type="text"
                          value={codProduto}
                          disabled={true}
                        />
                        <br></br>
                      </FormGroup>
                    </Col>
                    <Col className="padding-padrao-col" lg="3" md sm="7">
                      <label>Número Produto</label>
                      <FormGroup>
                        <InputNumber
                          id="cadNumeroProduto"
                          type="text"
                          value={basico.numeroProduto}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeBasico("numeroProduto", e.target.value)}
                          onKeyPress={(e: KeyboardEvent) => handleKeyPress(e, "numProduto")}
                          disabled={true}
                        />
                        <br></br>
                      </FormGroup>
                    </Col>
                    <Col className="padding-padrao-col" lg md>
                      <label>Código de Barras</label>
                      <FormGroup className={invalido.codBarras ? "has-danger" : ''}>
                        <Input
                          id="cadCodBarras"
                          type="text"
                          value={basico.codBarras}
                          onChange={e => onChangeBasico('codBarras', maskNumbers(e.target.value))}
                          onKeyPress={(e) => handleKeyPress(e, "codBarras")}
                          //@ts-ignore
                          maxLength="40"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="padding-padrao-row">
                    <Col className="padding-padrao-col" lg>
                      <label>Grupo<span className="error"> *</span></label>
                      <Select
                        //@ts-ignore
                        first="Selecione"
                        value={{ value: basico.codGrupo, label: basico.grupo }}
                        select={{ value: "codGrupo", label: "nome" }}
                        //@ts-ignore
                        options={grupos}
                        //@ts-ignore
                        onChange={value => onChangeGrupo(value)}
                        isSearchable={true}
                        autoComplete="off"
                      />
                      <br></br>
                    </Col>

                    <Col className="padding-padrao-col" lg>
                      <label>Subgrupo<span className="error"> *</span></label>
                      <Select
                        //@ts-ignore
                        first="Selecione"
                        value={{ value: basico.codSubgrupo, label: basico.subgrupo }}
                        select={{ value: "codSubgrupo", label: "nome" }}
                        //@ts-ignore
                        options={cadsubgrupos}
                        //@ts-ignore
                        onChange={value => onChangeSubgrupo(value)}
                        isSearchable={true}
                        autoComplete="off"
                      />
                      <br></br>
                    </Col>

                  </Row>

                  <Row className="padding-padrao-row">
                    <Col className="padding-padrao-col" lg="5" md>
                      <label>Nome<span className="error"> *</span></label>
                      <FormGroup className={invalido.nome ? "has-danger" : ''}>
                        <Input
                          id="Nomeid"
                          type="text"
                          value={basico.nome}
                          onChange={e => onChangeBasico('nome', e.target.value)}
                          //@ts-ignore
                          maxLength="50"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                    <Col className="padding-padrao-col" lg md>
                      <label>Descrição<span className="error"> *</span></label>
                      <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                        <Input
                          id="descricao"
                          type="text"
                          value={basico.descricao}
                          onChange={e => onChangeBasico('descricao', e.target.value)}
                          //@ts-ignore
                          maxLength="100"
                        />
                        <br></br>
                      </FormGroup>
                    </Col>

                  </Row>

                </fieldset>

                <Row>

                  <Col lg="7">

                    <fieldset className='fieldset-style'>
                      <legend className='fieldset-legends-style' >
                        Especificações
                        </legend>
                      <Row>
                        <Col>
                          <Row className="padding-padrao-row">
                            <Col className="padding-padrao-col" lg md>
                              <label>Unidade de Medida</label>
                              <Select
                                id="unidade"
                                value={{ value: especificacoes.unidade }}
                                //@ts-ignore
                                options={unidadesMedida}
                                //@ts-ignore
                                onChange={value => onChangeSelect('unidade', value.value)}
                                isSearchable={false}
                              />
                              <br></br>
                            </Col>


                            <Col className="padding-padrao-col" lg md>
                              <label>Peso (g)</label>
                              <FormGroup>
                                <InputNumber
                                  id="peso"
                                  format="9,4"
                                  value={especificacoes.peso}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeEspecificacoes('peso', e.target.value)}

                                />
                                <br></br>
                              </FormGroup>
                            </Col>

                          </Row>

                          <Row className="padding-padrao-row">

                            <Col className="padding-padrao-col" lg md>
                              <label>Largura (cm)</label>
                              <FormGroup>
                                <InputNumber
                                  id="largura"
                                  format="8,2"
                                  value={especificacoes.largura}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeEspecificacoes('largura', e.target.value)}

                                />
                                <br></br>
                              </FormGroup>
                            </Col>

                            <Col className="padding-padrao-col" lg md>
                              <label>Altura (cm)</label>
                              <FormGroup>
                                <InputNumber
                                  id="altura"
                                  format="8,2"
                                  value={especificacoes.altura}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeEspecificacoes('altura', e.target.value)}
                                />
                                <br></br>
                              </FormGroup>
                            </Col>

                          </Row>

                          <Row className="padding-padrao-row">

                            <Col className="padding-padrao-col" lg md>
                              <label>Profundidade (cm)</label>
                              <FormGroup>
                                <InputNumber
                                  id="profundidade"
                                  format="8,2"
                                  value={especificacoes.profundidade}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeEspecificacoes('profundidade', e.target.value)}

                                />
                                <br></br>
                              </FormGroup>
                            </Col>

                            <Col className="padding-padrao-col" lg md>
                              <label>Voltagem</label>
                              <Select
                                id="voltagem"
                                value={{ value: especificacoes.voltagem }}
                                //@ts-ignore
                                options={voltagens}
                                //@ts-ignore
                                onChange={value => onChangeSelect('voltagem', value.value)}
                                isSearchable={false}
                              />
                            </Col>

                          </Row>

                          <Row className="padding-padrao-row">

                            <Col className="padding-padrao-col" lg="6" md>
                              <label>Fornecedor</label>
                              <MultiSelect
                                id="fornecedor"
                                first="Nenhum"
                                values={especificacoes.codFornecedor}
                                select={{ value: 'codFor', label: 'nomeRazao' }}
                                //@ts-ignore
                                onChange={(values) => onChangeSelect('codFornecedor', values)}
                                options={fornecedores}

                              />
                              <br></br>
                            </Col>

                            <Col className="padding-padrao-col" lg md>
                              <label>Garantia do Fornecedor</label>
                              <Select
                                id="garantiaFor"
                                value={{ value: especificacoes.garantiaFor }}
                                //@ts-ignore
                                options={garantias}
                                //@ts-ignore
                                onChange={value => onChangeSelect('garantiaFor', value.value)}
                                isSearchable={false}

                              />
                              <br></br>
                            </Col>

                          </Row>

                          <Row className="padding-padrao-row">
                            <Col className="padding-padrao-col" lg md>
                              <label>Preço de Custo</label>
                              <FormGroup>
                                <Input
                                  id="precoCusto"
                                  value={especificacoes.precoCusto}
                                  onChange={(precoCusto: React.ChangeEvent<HTMLInputElement>) => actions.setProdutoEspecificacoes({ ...especificacoes, precoCusto: maskMoeda(precoCusto.target.value) })}
                                  onBlur={() => markupCalculator("precoCusto")}
                                />
                                <br></br>
                              </FormGroup>
                            </Col>
                            <Col className="padding-padrao-col" lg md>
                              <label>Markup (%)</label>
                              <FormGroup>
                                <Input
                                  id="markup"
                                  value={especificacoes.markup}
                                  maxlength='8'
                                  max='1.000,00'
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => actions.setProdutoEspecificacoes({ ...especificacoes, markup: maskMarkup(e.currentTarget.value) })}
                                  onBlur={() => markupCalculator("markup")}
                                />
                                <br></br>
                              </FormGroup>
                            </Col>
                            <Col className="padding-padrao-col" lg md>
                              <label>Preço de Venda</label>
                              <FormGroup>
                                <Input
                                  id="precoVenda"
                                  value={especificacoes.precoVenda}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => actions.setProdutoEspecificacoes({ ...especificacoes, precoVenda: maskMoeda(e.currentTarget.value) })}
                                  onBlur={() => markupCalculator("precoVenda")}
                                />
                                <br></br>
                              </FormGroup>
                            </Col>

                          </Row>
                          <Row className="padding-padrao-row">
                            <Col className="padding-padrao-col">
                              <label>Origem do Produto</label>
                              <Select
                                value={{ value: especificacoes.codOrigem }}
                                select={{ value: "codOrigem", label: "descricao" }}
                                //@ts-ignore
                                onChange={e => {actions.setProdutoEspecificacoes({...especificacoes,codOrigem: e.value})}}
                                //@ts-ignore
                                options={origensProduto}
                                isSearchable={true}
                                autoComplete="off"
                              />
                              <br></br>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                    </fieldset>

                  </Col>

                  <Col lg="5">


                    <fieldset className="fieldset-style">
                      <legend className='fieldset-legends-style' >
                        Comissão
                        </legend>

                      <Row>
                        <Col>
                          <Row className="padding-padrao-row">

                            <Col lg md className="text-center padding-padrao-col">
                              <Switch
                                componenttop={<label>Controlar Comissão</label>}
                                checked={comissao.flControlaComissao === 1}
                                onChange={() => onChangeComissao('flControlaComissao', comissao.flControlaComissao === 1 ? 0 : 1)}

                              />
                              <br></br>
                            </Col>

                            <Col className="padding-padrao-col" lg md>
                              <label>Comissão (%)</label>
                              <FormGroup>
                                <InputNumber
                                  id="percentualComissao"
                                  format="5,2"
                                  value={comissao.percentualComissao}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeComissao('percentualComissao', e.target.value)}

                                />
                                <br></br>
                              </FormGroup>
                            </Col>

                          </Row>

                        </Col>
                      </Row>

                    </fieldset>


                    <fieldset className="fieldset-style">
                      <legend className='fieldset-legends-style' >
                        Estoque
                        </legend>
                      <Row>
                        <Col>
                          <Row>

                            <Col lg md className="text-center">
                              <Switch
                                componenttop={<label>Controlar Estoque</label>}
                                checked={estoque.flControlaEstoque === 1}
                                onChange={() => onChangeEstoque('flControlaEstoque', estoque.flControlaEstoque === 1 ? 0 : 1)}

                              />
                              <br></br>
                            </Col>

                          </Row>

                          <Row className="padding-padrao-row">
                            <Col className="padding-padrao-col" lg md>
                              <label>Quantidade Mínima</label>
                              <FormGroup>
                                <InputNumber
                                  id="qtdMinima"
                                  format="10,4"
                                  value={estoque.qtdMinima}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeEstoque('qtdMinima', e.target.value)}

                                />
                                <br></br>
                              </FormGroup>
                            </Col>

                            <Col className="padding-padrao-col" lg md>
                              <label>Estoque Atual</label>
                              <FormGroup>
                                <Input
                                  id="qtdAtual "
                                  format="10,4"
                                  value={estoque.qtdAtual}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeEstoque('qtdAtual', e.target.value)}
                                  disabled={true}
                                />
                                <br></br>
                              </FormGroup>
                            </Col>

                          </Row>
                        </Col>
                      </Row>

                    </fieldset>


                    <fieldset className="fieldset-style">
                      <legend className='fieldset-legends-style' >
                        Características
                        </legend>

                      <Row>
                        <Col>
                          <Row>

                            <Col lg md className="text-center">
                              <Switch
                                componenttop={<label>Controlar nº de série</label>}
                                checked={caracteristicas.flControlaNumSerie === 1}
                                onChange={() => onChangeCaracteristicas('flControlaNumSerie', caracteristicas.flControlaNumSerie === 1 ? 0 : 1)}

                              />
                              <br></br>
                            </Col>

                            <Col lg md className="text-center">
                              <ArrowTooltip
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                placement="right-start"
                                open={caracteristicas.flControlaGrade === 1 && produtoCopia.basico.caracteristicas.flControlaGrade === 0}
                                title="É necessário salvar para liberar o acesso a aba de grades"
                                color="white"
                                backgroundcolor="#FF4500"
                              >
                                <div>
                                  <Switch
                                    componenttop={<label>Controlar Grade</label>}
                                    checked={caracteristicas.flControlaGrade === 1}
                                    onChange={() => onChangeCaracteristicas('flControlaGrade', caracteristicas.flControlaGrade === 1 ? 0 : 1)}

                                  />
                                  <br></br>
                                </div>
                              </ArrowTooltip>
                            </Col>


                          </Row>
                          <Row>

                            <Col lg md className="text-center">
                              <ArrowTooltip
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                placement="right-start"
                                open={caracteristicas.flTributado === 1 && produtoCopia.basico.caracteristicas.flTributado === 0}
                                title="É necessário salvar para liberar o acesso a aba de dados tributários"
                                color="white"
                                backgroundcolor="#FF4500"
                              >
                                <div>
                                  <Switch
                                    componenttop={<label>Produto Tributado</label>}
                                    checked={caracteristicas.flTributado === 1}
                                    onChange={() => onChangeCaracteristicas('flTributado', caracteristicas.flTributado === 1 ? 0 : 1)}

                                  />
                                  <br></br>
                                </div>
                              </ArrowTooltip>
                            </Col>

                            <Col lg md className="text-center">
                              <Switch
                                componenttop={<label>Item para venda</label>}
                                checked={caracteristicas.flItemVenda === 1}
                                onChange={() => onChangeCaracteristicas('flItemVenda', caracteristicas.flItemVenda === 1 ? 0 : 1)}

                              />
                              <br></br>
                            </Col>

                          </Row>

                        </Col>
                      </Row>

                    </fieldset>

                  </Col>

                </Row>

              </Col>

            </Row>


          </CardBody>

          <CardFooter className="text-center">
            <Row>
              <Col style={{ display: "flex", justifyContent: 'center' }}>
                <ArrowTooltip title='Shift+N'>
                  <Button className="btn-padrao" color="default" onClick={Limpar}>Novo</Button>
                </ArrowTooltip>
                <ArrowTooltip title='Shift+S'>
                  <Button className="btn-padrao" color="info" onClick={Salvar}>Salvar</Button>
                </ArrowTooltip>
              </Col>
            </Row>
          </CardFooter>

        </Col>
      </Row>

      {modalInativar ?
        <ModalInativar
          isOpen={modalInativar}
          toggle={toggleModalInativar}
          codProduto={codProduto}
          status={status}
        />
        : null}
    </>
  )
}

const DadosBasicosComponent = memo(DadosBasicos)

class Wizard extends React.Component<{}, { salvar: boolean }> {
  constructor(props: any) {
    super(props)
    this.state = {
      salvar: false
    }
  }

  isValidated = (step = 0, finish = false) => {
    /*  if (step > 0) {
       return true
     }
     if (!finish) {
          this.setState({ salvar: true }, () => {
            this.setState({ salvar: false })
          })
        } */
    return true
  }

  focusInput = () => {
    setTimeout(() => {
      let element = document.querySelector<HTMLInputElement>("#cadCodBarras")
      if (element)
        element.focus()
    }, 100)
  }

  render() {
    return (
      <DadosBasicosComponent salvar={this.state.salvar} />
    )
  }
}

export default Wizard
