import React, { useState, useEffect, useRef } from 'react'

import './styles.css'

function useOutsideClose(ref, toggle) {

    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            toggle()
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    })
}

export default function MultiSelect(props) {
    const multiSelectRef = useRef(null)
    const [isOpen, setisOpen] = useState(false)
    const [text, settext] = useState('')
    const [allSelected, setallSelected] = useState(false)

    useOutsideClose(multiSelectRef, toggle)

    function toggle() {
        setisOpen(false)
    }

    useEffect(() => {
        changeInputText()
    }, [props.values])

    function onChangeValues(value) {
        let newValues = []
        let index = props.values.findIndex(item => item === value)
        if (index !== -1) {
            props.values.forEach((item, key) => {
                if (key !== index) {
                    newValues.push(item)
                }
            });
            props.onChange(newValues)
        } else {
            props.onChange([...props.values, value])
        }
    }

    function changeInputText() {
        let string = ''

        if (props.values.length > 0) {
            string = props.options.reduce((str, option) => {
                if (props.values.find(value => value === option[props.select.value])) {
                    if (str)
                        return `${str}, ${option[props.select.label]}`
                    else
                        return `${option[props.select.label]}`
                } else {
                    if (str)
                        return str
                    else
                        return ''
                }
            }, '')
        }

        settext(string)
    }

    function selectAll() {
        if (!allSelected) {
            props.onChange(props.options.map(option => option[props.select.value]))
        }
        else {
            props.onChange([])
        }
        setallSelected(!allSelected)
    }

    return (
        <div className={`multi-select ${isOpen ? 'multi-select-focus' : ''}`} ref={multiSelectRef}>
            <div
                type="text"
                className="multi-select-input"
                onClick={() => setisOpen(!isOpen)}
            >
                <div className="multi-select-content">
                    {text}
                </div>
                <div className={`indicator ${isOpen ? 'indicator-open' : ''}`}>
                    <div className="dropdown">
                    </div>
                </div>

            </div>
            <div className={`multi-options ${isOpen ? 'multi-options-open' : 'multi-options-close'}`}>
                <div className="container">
                    <div
                        className={`option ${allSelected ? 'selected' : ''}`}
                        onClick={selectAll}
                    >
                        Todos
                    </div>
                    {props.options.map((option, key) => {
                        return (
                            <div
                                className={`option ${props.values.find(value => value === option[props.select.value]) ? 'selected' : ''}`}
                                key={key}
                                onClick={() => onChangeValues(option[props.select.value])}
                            >
                                {option[props.select.label]}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

MultiSelect.defaultProps = {
    onChange: () => { },
    options: [],
    select: { value: 'value', label: 'label' },
    values: []
};