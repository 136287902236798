var INITIAL_STATE = {

    pesquisa: {
        nome: '',
        cpf: '',
        apelido: '',
        email: '',
        senha: '',
        imagem: '',
    },

    cadastro: {
        nome: '',
        cpf: '',
        apelido: '',
        email: '',
        senha: '',
        imagem: '',
    },
    senha: {
        senhaAtual: '',
        novaSenha: '',
        confirmaSenha: '',
    },
    limpar: {
        senha:'',
    },
    invalido: {
        nome: false,
        apelido: false,
        cpf: false,
        email: false,
        senha: false,
        novaSenha: false,
        confirmaSenha: false
    },
    cores: {
        sidebar: '',
        sidebarMini: true,
        mode: ''
    }
}

export default function perfilUsuario(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'PERFIL_USUARIO':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: action.value }
                case 'CADASTRO':
                    return { ...state, cadastro: action.value }
                case 'CADASTRO_IMAGEM':
                    return {...state, cadastro: {...state.cadastro, [action.campo]: action.value}}
                case 'CADASTRO_APELIDO':
                    return {...state, cadastro: { ...state.cadastro, [action.campo]: action.value}}
                case 'SENHA':
                    return { ...state, senha: {...state.senha, [action.campo]: action.value}}
                case 'CLEAN_SEARCH':
                    return { ...state, cadastro: {...state.pesquisa}}
                case 'CLEAN_SENHA':
                    return { ...state, senha: INITIAL_STATE.senha}
                case 'INVALIDO':
                    return {...state, invalido: {...state.invalido, [action.campo]: action.value}}
                case 'CLEAN_INVALIDO':
                    return {...state, invalido: {...INITIAL_STATE.invalido}}
                case 'CORES':
                    return {...state, cores: {...state.cores, [action.campo]: action.value}}
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}