import React from "react";
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import useModal from "../../../../../Util/Hooks/useModal";
import ModalTransportadora from "../../Modals/ModalTransportadora"
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import * as controllerTransportadora from "../../../../../controller/Movimentacao/controllerEntradaMercadoria"
import { Row, Col, Input, Button, FormGroup } from "reactstrap";
import { InputCpfCnpj } from "~/components/ErnComponets/ErnComponets";

const idStore = "ENTRADA_MERCADORIA";

function DadosTransportadora() {
    const dispatch = useDispatch();
    const transportadora = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.dadosIniciais.transportadora);
    const verCadastro = useShallowEqualSelector(state => state.entradaMercadoria.visualizarEntrada);
    const [modalPesquisa, toggleModalTransportadora] = useModal(false);

    function pesquisaTransportadoras(pesquisaTransportadora) {
        controllerTransportadora.GetTransportadoras(pesquisaTransportadora);
    }

    function confirmarTransportadora(codigoTransportadora) {
        controllerTransportadora.GetTransportadora(codigoTransportadora, toggleModalTransportadora);

    }

    function cleanTransportadora() {
        dispatch({ idStore, type: 'CLEAN_TRANSPORTADORA' });
    }

    function onChange(value, campo, invalido) {
        dispatch({ idStore, type: 'TRANSPORTADORA', value: { ...transportadora, [campo]: value } })
        dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'INVALIDO', campo: invalido, value: false })
    }

    async function handleKeyPress(event) {
        if (event.key === 'Enter') {

            if (transportadora.cpfCnpj) {
                let codTransp = await controllerTransportadora.GetTransportadoraKeyPress(transportadora);
                if (!codTransp) {
                    dispatch({ idStore, type: 'TRANSPORTADORA', value: { codTransp: 0, nomeRazao: '', cpfCnpj: transportadora.cpfCnpj, rgInscest: '' } })
                    toggleModalTransportadora();
                }
            }
            else {
                dispatch({ idStore, type: 'TRANSPORTADORA', value: { codTransp: 0, nomeRazao: '', cpfCnpj: transportadora.cpfCnpj, rgInscest: '' } })
                toggleModalTransportadora();
            }
        }
    }

    return (
        <>
            <Col lg="12">
                <Row>
                    <div className={verCadastro ? 'ver-cadastro' : ''}></div>
                    <Col style={{ paddingTop: '10px' }}>
                        <fieldset style={{ border: 'solid 1px #5E6680', borderRadius: 5 }}>
                            <legend style={{ fontSize: '13px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
                                TRANSPORTADORA
                            </legend>
                            <Row  className="padding-padrao-row">
                                <Col lg="3"  className="padding-padrao-col">
                                    <label>CPF/ CNPJ</label>
                                    <FormGroup>
                                        <InputCpfCnpj
                                            type="text"
                                            autoComplete="off"
                                            value={transportadora.cpfCnpj}
                                            onChange={e => onChange(e.target.value, "cpfCnpj", "codTransp")}
                                            onKeyPress={e => handleKeyPress(e)}
                                            maxLength="18"
                                            tipo={transportadora.cpfCnpj.length > 14 ? 'cnpj' : 'cpf'}
                                        />
                                    </FormGroup>
                                </Col>

                                <Button
                                    style={{ marginTop: '25px', width: '40px', marginBottom: '20px' }}
                                    type='button'
                                    color="info"
                                    onClick={toggleModalTransportadora}
                                >
                                    <Icon icon="search" className="fa-lg" />
                                </Button>

                                <Col  className="padding-padrao-col" lg="5">
                                    <label>Nome / Razão Social</label>
                                    <Input
                                        type="text"
                                        autoComplete="off"
                                        value={transportadora.nomeRazao}
                                        disabled={true}
                                    />
                                </Col>

                                <Col  className="padding-padrao-col">
                                    <label>RG / IE</label>
                                    <Input
                                        type="text"
                                        autoComplete="off"
                                        value={transportadora.rgInscest}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>
                </Row>
            </Col>
            {modalPesquisa ?
                <ModalTransportadora
                    isOpen={true}
                    toggle={toggleModalTransportadora}
                    getTransportadora={pesquisaTransportadoras}
                    confirmModal={confirmarTransportadora}
                    clean={cleanTransportadora} /> :
                null
            }

        </>
    )
}

export default DadosTransportadora;