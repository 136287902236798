import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { alertar } from "~/Util/alertar";
import ReactWizard from "~/components/Wizard/Wizard";
import ReactTable from "react-table";
import * as controllerClientes from "~/controller/controllerClientes"
import classNames from "classnames";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';
import { useHotkeys } from 'react-hotkeys-hook';
import { maskCNPJ, maskCPF } from "~/Util/mascaras";
import { InputCpfCnpj } from "~/components/ErnComponets/ErnComponets";
import {
    Card,
    Col,
    Button,
    ButtonGroup,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Row,
    TabContent,
    TabPane,
    Modal,
    ModalBody,
    FormGroup,
    Label,
} from "reactstrap";

// wizard steps
import Step1 from "~/views/Clientes/CadastroSteps/Cliente.jsx"
import Step2 from "~/views/Clientes/CadastroSteps/Endereco.jsx"
import { HelpButton } from "~/components/HelpPlugin/HelpPlugin";


var steps = [
    {
        stepName: "Cliente",
        stepIcon: "tim-icons icon-notes",
        component: Step1,
        stepProps: {}
    },
    {
        stepName: "Endereço",
        stepIcon: "tim-icons icon-square-pin",
        component: Step2,
        stepProps: {},
    },
];
const idStore = 'CLIENTES';

const Clientes = () => {
    const notificacao = useSelector(state => state.notificacao);
    const { cliente, pesquisa, clientes, inicioCadastro, proximoCadastro, clienteCopia, endereco, enderecoCopia } = useSelector(state => state.clientes);
    const dispatch = useDispatch();
    const [dataTable, setDataTable] = useState([]);
    const [table, setTable] = useState(renderTable([]));
    const [horizontalTabs, setHorizontalTabs] = useState('pesquisar');
    const [tableVisible, setTableVisible] = useState(false);
    const [modalExcluir, setModalExcluir] = useState(false);
    const [modalInativar, setModalInativar] = useState(false);
    const [clienteSelected, setClienteSelected] = useState({ status: 0 });
    const [width, setWidht] = useState(window.innerWidth);
    const [loading, setLoading] = useState(false);
    const [stepActive, setStepActive] = useState(0);
    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        dispatch({ idStore, type: 'VER_CADASTRO', value: false })
    }, [])

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', updateWidth);
        }
    }, [])

    function updateWidth() {
        setWidht(window.innerWidth);
    }

    useEffect(() => {
        steps.forEach(step => {
            step.stepProps = { ...step.stepProps, notificacao }
        });
    }, [notificacao])

    useEffect(() => {
        setDataTable(tableData(clientes));
        if (clientes.length > 0) {
            setTableVisible(true);
        } else {
            setTableVisible(false);
        }
    }, [clientes])

    useEffect(() => {
        setTable(renderTable());
    }, [dataTable, loading])

    useEffect(() => {
        steps.forEach(step => {
            step.stepProps = { ...step.stepProps, cliente: cliente }
        });
        setInicioCadastro(false)
    }, [cliente])

    useEffect(() => {
        if (inicioCadastro)
            setInicioCadastro(false)
    }, [inicioCadastro])
    useHotkeys('shift+p', () => {
        controllerClientes.Pesquisar(notificacao, dispatch, pesquisa);
    }, [pesquisa])

    useHotkeys('shift+1', () => {
        changeActiveTab('pesquisar');
    }, [])

    useHotkeys('shift+2 ', () => {
        changeActiveTab("cadastrar");
        novoCadatro();
    }, [])

    useHotkeys('shift+l', () => {
        if (horizontalTabs === 'pesquisar') {
            handleLimpar();
        } else if (stepActive === 0) {
            dispatch({ idStore, type: 'CLEAN_CLIENTE' });
            dispatch({ idStore, type: 'CLEAN_ENDERECO' });
            dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        }
    }, [horizontalTabs, stepActive])

    useHotkeys('shift+s', () => {
        if ((stepActive === 0)) {
            var retorno = '';
            async function salvar() {
                if (cliente.codCli === '') {
                    retorno = await controllerClientes.CreateCliente(notificacao, dispatch, cliente);
                } else {
                    retorno = await controllerClientes.UpdateCliente(notificacao, dispatch, cliente, clienteCopia);
                }

                if (retorno === false) {
                    dispatch({ idStore, type: 'PROXIMO_CADASTRO', value: true });;
                    setTimeout(() => {
                        dispatch({ idStore, type: 'PROXIMO_CADASTRO', value: false });
                    }, 1000)
                }
            }
            salvar();
        } else if (stepActive === 1) {
            if (endereco.codEnd === '') {
                controllerClientes.CreateEndereco(notificacao, dispatch, cliente.codCli, endereco);
            } else {
                controllerClientes.UpdateEndereco(notificacao, dispatch, endereco, enderecoCopia);
            }
        }
    }, [cliente, clienteCopia, stepActive, endereco, enderecoCopia])

    useHotkeys('shift+n', () => {
        if (stepActive === 1) {
            dispatch({ idStore, type: 'CLEAN_ENDERECO' });
            dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        }

    }, [stepActive])


    function changeActiveStep(valor) {
        setStepActive(valor)
    }

    function tableData(data) {
        return data.map((prop, key) => {
            return {
                id: key,
                codCli: prop['codCli'],
                nomeRazao: prop['nomeRazao'],
                fantasia: prop['fantasia'],
                cpfCnpj: prop['cpfCnpj'],
                tipoCli: prop['tipoCli'],
                dtCadastro: prop['dtCadastro'],
                status: prop['status'],
            }
        })
    }

    function renderTable() {
        return dataTable.map((obj, key) => {
            return {
                ...dataTable[key],

                actions: (
                    <div className="actions-right">
                        <ArrowTooltip title="Visualizar">
                            <Button
                                onClick={() => {
                                    handleVisualizarCadastro(obj);
                                }}
                                size="sm"
                                className={classNames("btn-icon btn-link")}
                                style={{ color: "#483D8B" }}

                            >
                                <Icon icon="eye" className="fa-lg" />
                            </Button>
                        </ArrowTooltip>
                        {
                            !loading ?
                                <ArrowTooltip title="Editar">
                                    <Button
                                        onClick={() => {
                                            handleEditarCadastro(obj);
                                        }}
                                        size="sm"
                                        className={classNames("btn-icon btn-link")}
                                        color="success"

                                    >
                                        <Icon icon="edit" className="fa-lg" />
                                    </Button>
                                </ArrowTooltip>
                                :
                                <Button
                                    onClick={() => {
                                        handleEditarCadastro(obj);
                                    }}
                                    size="sm"
                                    className={classNames("btn-icon btn-link")}
                                    color="success"
                                    disabled={true}

                                >
                                    <Icon icon="edit" className="fa-lg" />
                                </Button>
                        }
                        <ArrowTooltip title={obj.status === 0 ? "Inativar" : "Reativar"}>
                            <Button
                                onClick={() => {
                                    handleInativarCliente(obj);
                                }}
                                className="btn-icon btn-link"
                                color="info"
                            >
                                {obj ? obj.status === 0 ?
                                    <Icon icon="ban" className="fa-lg" />
                                    :
                                    <Icon icon="redo" className="fa-lg" />
                                    :
                                    null
                                }
                            </Button>
                        </ArrowTooltip>
                        <ArrowTooltip title="Excluir">
                            <Button
                                onClick={() => { handleExcluirCliente(obj) }}
                                className="btn-icon btn-link"
                                color="danger"
                            >
                                <Icon icon="trash-alt" className="fa-lg" />
                            </Button>
                        </ArrowTooltip>
                    </div >
                )
            };
        })
    }

    function changeActiveTab(tabName) {
        dispatch({ idStore, type: 'CLEAN_CLIENTE' });
        dispatch({ idStore, type: 'CLEAN_ENDERECO' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        dispatch({ idStore, type: 'VER_CADASTRO', value: false });
        setHorizontalTabs(tabName);
        if (tabName === "pesquisar") {
            setTimeout(() => {
                document.getElementById('nomeRazao').focus()
            }, 100)
        }
    };

    function handleOnChange(e, campo) {
        dispatch({ idStore, type: 'PESQUISA', campo, value: e.target.value });
    }

    function handleOnChangeTipo(value) {
        dispatch({ idStore, type: 'PESQUISA', campo: 'tipo', value });
        controllerClientes.Pesquisar(notificacao, dispatch, { ...pesquisa, tipo: value });
    }

    function handleOnChangeAtivo(value) {
        dispatch({ idStore, type: 'PESQUISA', campo: 'ativos', value });
        controllerClientes.Pesquisar(notificacao, dispatch, { ...pesquisa, ativos: value });
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            controllerClientes.Pesquisar(notificacao, dispatch, pesquisa);
        }
    }

    function handlePesquisarClientes() {
        controllerClientes.Pesquisar(notificacao, dispatch, pesquisa);
    }

    function handleLimpar() {
        dispatch({ idStore, type: 'CLEAN_SEARCH' })
    }

    async function handleVisualizarCadastro(obj) {
        setLoading(true);
        if (!loading) {

            await controllerClientes.GetCliente(notificacao, dispatch, obj.codCli, () => {
                setHorizontalTabs("cadastrar")
                setInicioCadastro(true);
                controllerClientes.GetEnderecos(notificacao, dispatch, obj.codCli);
                dispatch({ idStore, type: 'CLEAN_INVALIDO' });
            });
            setLoading(false);
            dispatch({ idStore, type: 'VER_CADASTRO', value: true })
        }

    }

    async function handleEditarCadastro(obj) {

        setLoading(true);
        if (!loading) {

            await controllerClientes.GetCliente(notificacao, dispatch, obj.codCli, () => {
                setHorizontalTabs("cadastrar")
                setInicioCadastro(true);
                controllerClientes.GetEnderecos(notificacao, dispatch, obj.codCli);
                dispatch({ idStore, type: 'CLEAN_INVALIDO' });
            });
            setLoading(false);
        }
    }

    function handleInativarCliente(obj) {
        setClienteSelected(obj);
        toggleModalInativar();
    }

    function handleExcluirCliente(obj) {
        setClienteSelected(obj);
        toggleModalExcluir();
    }

    async function novoCadatro() {
        setInicioCadastro(true);
        dispatch({ idStore, type: 'CLEAN_CLIENTE' });
        dispatch({ idStore, type: 'CLEAN_ENDERECO' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    }

    function inativarCliente() {
        controllerClientes.Inativar(notificacao, dispatch, clienteSelected, pesquisa);
    }

    function excluirCliente() {
        controllerClientes.ExcluirCliente(notificacao, dispatch, clienteSelected, pesquisa);
    }

    function setInicioCadastro(valor) {
        dispatch({ idStore, type: 'INICIO_CADASTRO', value: valor });
    }

    const labelTipoCliente = (tipo) => {

        switch (tipo) {
            case 'F':
                return 'Pessoa Física'

            case 'J':
                return 'Pessoa Jurídica'

            case 'E':
                return 'Pessoa Estrangeira'

            default:
                break;
        }

    }
    async function finishButtonClick() {

        let error

        if (endereco.codEnd === '') {
            error = await controllerClientes.CreateEndereco(notificacao, dispatch, cliente.codCli, endereco, enderecoCopia);
        } else {
            error = await controllerClientes.UpdateEndereco(notificacao, dispatch, cliente.codCli, endereco, enderecoCopia);
        }

        if (error) {
            dispatch({ idStore, type: 'CLEAN_CLIENTE' });
            dispatch({ idStore, type: 'CLEAN_ENDERECO' });
            dispatch({ idStore, type: 'CLEAN_INVALIDO' });
            alertar(notificacao, 'br', "Cadastro concluído", 'success', 'icon-check-2', 2);
            setInicioCadastro(true);
        }

        return error
    }

    function toggleModalInativar() {
        setModalInativar(!modalInativar);
    }

    function toggleModalExcluir() {
        setModalExcluir(!modalExcluir);
    }

    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="12">
                    <Card>

                        <CardHeader className="text-center">
                            <CardTitle tag="h3">Clientes</CardTitle>
                            <br />
                            <TabContent
                                className="tab-space"
                                activeTab={horizontalTabs}
                                style={{ marginTop: '-30px', marginBottom: '-70px' }}
                            >

                            </TabContent>
                        </CardHeader>

                        <CardBody>
                            <ButtonGroup style={{ width: '100%' }}>
                                <ArrowTooltip title='Shift+1'>
                                    <Button
                                        color={
                                            horizontalTabs === "pesquisar"
                                                ? "info"
                                                : ""
                                        }
                                        type="button"
                                        data-toggle="tab"
                                        className={classNames("btn-gp-padrao",
                                            horizontalTabs === "pesquisar"
                                                ? "active"
                                                : ""
                                        )
                                        }
                                        onClick={e => {
                                            changeActiveTab("pesquisar");
                                        }
                                        }
                                        style={{ width: '50%' }}
                                    >
                                        PESQUISAR
                                    </Button>
                                </ArrowTooltip>
                                <ArrowTooltip title='Shift+2'>
                                    <Button
                                        color={
                                            horizontalTabs === "cadastrar"
                                                ? "info"
                                                : ""
                                        }
                                        type="button"
                                        data-toggle="tab"
                                        className={classNames("btn-gp-padrao",
                                            horizontalTabs === "cadastrar"
                                                ? "active"
                                                : ""
                                        )
                                        }
                                        onClick={e => {
                                            changeActiveTab("cadastrar");
                                        }
                                        }
                                        style={{ width: '50%' }}
                                    >
                                        CADASTRAR
                                    </Button>
                                </ArrowTooltip>
                            </ButtonGroup>
                            <TabContent
                                className="tab-space"
                                activeTab={horizontalTabs}
                                style={{ paddingBottom: '0px' }}
                            >
                                {
                                    horizontalTabs === "pesquisar" ?
                                        <TabPane tabId="pesquisar">
                                            <Row className="padding-padrao-row">
                                                <Col className="padding-padrao-col" md="3">
                                                    <label>Nome / Razão Social</label>
                                                    <Input
                                                        id="nomeRazao"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={pesquisa.nomeRazao}
                                                        onChange={e => handleOnChange(e, 'nomeRazao')}
                                                        onKeyPress={e => handleKeyPress(e)}
                                                        autoFocus={true}
                                                        maxLength="100"
                                                    />
                                                </Col>
                                                <Col className="padding-padrao-col" md="3">
                                                    <label>Nome Fantasia</label>
                                                    <Input
                                                        id="fantasia"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={pesquisa.fantasia}
                                                        onChange={e => handleOnChange(e, 'fantasia')}
                                                        onKeyPress={e => handleKeyPress(e)}
                                                        maxLength="50"
                                                    />
                                                </Col>
                                                <Col className="padding-padrao-col" md="3">
                                                    <Row lg={1}>
                                                        <Col>
                                                            <Col>
                                                                <div style={{ display: "inline-flex" }}>
                                                                    <label style={{ whiteSpace: "nowrap" }}>CPF / CNPJ / Documento</label>
                                                                    <HelpButton id="24" />
                                                                </div>
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <InputCpfCnpj
                                                        id="cpfCnpj"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={pesquisa.cpfCnpj}
                                                        onChange={e => handleOnChange(e, 'cpfCnpj')}
                                                        onKeyPress={e => handleKeyPress(e)}
                                                        maxLength="18"
                                                        tipo={pesquisa.cpfCnpj.length > 14 ? 'cnpj' : 'cpf'}
                                                    />
                                                </Col>
                                                <Col lg="1" className="text-center padding-padrao-col" style={{ paddingTop: '13px' }}>
                                                    <Col className={width > 768 ? "text-left" : ''}>
                                                        <FormGroup check className="form-check-radio" Style={"margin-top:-12px;"}>
                                                            <Label check style={{ paddingRight: '5px' }}>
                                                                <Input type="radio"
                                                                    onChange={() => handleOnChangeTipo('')}
                                                                    checked={pesquisa.tipo === '' ? true : false}
                                                                />
                                                                <span className="form-check-sign" />Todos
                                                </Label>
                                                            <Label check style={{ paddingRight: '5px' }}>
                                                                <Input type="radio"
                                                                    onChange={() => handleOnChangeTipo('F')}
                                                                    checked={pesquisa.tipo === 'F' ? true : false}
                                                                />
                                                                <span className="form-check-sign" />Física
                                                </Label>
                                                            <Label check style={{ paddingRight: '5px' }}>
                                                                <Input type="radio"
                                                                    onChange={() => handleOnChangeTipo('J')}
                                                                    checked={pesquisa.tipo === 'J' ? true : false}
                                                                />
                                                                <span className="form-check-sign" />Jurídica
                                                </Label>
                                                            <Label check style={{ paddingRight: '5px' }}>
                                                                <Input type="radio"
                                                                    onChange={() => handleOnChangeTipo('E')}
                                                                    checked={pesquisa.tipo === 'E' ? true : false}
                                                                />
                                                                <span className="form-check-sign" />Estrangeira
                                                </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Col>

                                                <Col md="1" className="text-center" style={{ paddingTop: '13px' }}>
                                                    <Col className={width > 768 ? "text-left" : ''}>
                                                        <FormGroup check className="form-check-radio" Style={"margin-top:-12px;"}>
                                                            <Label check style={{ paddingRight: '5px' }}>
                                                                <Input type="radio"
                                                                    onChange={() => handleOnChangeAtivo("")}
                                                                    checked={pesquisa.ativos === "" ? true : false}
                                                                />
                                                                <span className="form-check-sign" />Todos
                                                  </Label>
                                                            <Label check style={{ paddingRight: '5px' }}>
                                                                <Input type="radio"
                                                                    onChange={() => handleOnChangeAtivo("0")}
                                                                    checked={pesquisa.ativos === "0" ? true : false}
                                                                />
                                                                <span className="form-check-sign" />Ativos
                                                  </Label>
                                                            <Label check style={{ paddingRight: '5px' }}>
                                                                <Input type="radio"
                                                                    onChange={() => handleOnChangeAtivo("1")}
                                                                    checked={pesquisa.ativos === "1" ? true : false}
                                                                />
                                                                <span className="form-check-sign" />Inativos
                                                  </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <CardBody>
                                                    <Col style={{ width: '100%' }}>
                                                        <ArrowTooltip title='Shift+L'>
                                                            <Button
                                                                color='info'
                                                                style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                                                onClick={() => handleLimpar()}
                                                            >Limpar</Button>
                                                        </ArrowTooltip>
                                                        <ArrowTooltip title='Shift+P'>
                                                            <Button
                                                                color='info'
                                                                style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                                                onClick={() => handlePesquisarClientes()}
                                                            >Pesquisar</Button>
                                                        </ArrowTooltip>
                                                    </Col>
                                                </CardBody>
                                            </Row>
                                            <div style={tableVisible ? { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
                                                <ReactTable
                                                    data={table}
                                                    filterable={false}
                                                    columns={
                                                        [
                                                            {
                                                                Header: "Nome",
                                                                accessor: "nomeRazao"
                                                            },
                                                            {
                                                                Header: "CPF / CNPJ / Documento ",
                                                                accessor: "cpfCnpj",
                                                                Cell: (props) => props.value.length > 11 ? maskCNPJ(props.value) : maskCPF(props.value)

                                                            },
                                                            {
                                                                Header: "Data de cadastro",
                                                                accessor: "dtCadastro"
                                                            },
                                                            {
                                                                Header: "Nome Fantasia",
                                                                accessor: "fantasia",
                                                                Cell: (props) => props.value === "" ? "" : props.value
                                                            },
                                                            {
                                                                Header: "Tipo Cliente",
                                                                accessor: "tipoCli",
                                                                Cell: (props) => labelTipoCliente(props.value)
                                                            },
                                                            {
                                                                Header: "Status",
                                                                accessor: "status",
                                                                Cell: (props) => props.value === 0 ? "Ativo" : "Inativo"
                                                            },
                                                            {
                                                                Header: "",
                                                                accessor: "actions",
                                                                sortable: false,
                                                                filterable: false
                                                            }
                                                        ]
                                                    }
                                                    minRows={1}
                                                    showPaginationTop={false}
                                                    showPaginationBottom={true}
                                                    className="-striped -highlight"
                                                    noDataText=""
                                                    previousText="Anterior"
                                                    nextText="Próximo"
                                                    loadingText="Carregando"
                                                    pageText="Página"
                                                    ofText="de"
                                                    rowsText='linhas'
                                                />
                                            </div>
                                        </TabPane>
                                        :
                                        <TabPane tabId="cadastrar">
                                            <div style={{ marginTop: '-20px' }}>
                                                <ReactWizard
                                                    steps={steps}
                                                    navSteps={true}
                                                    title=""
                                                    description=""
                                                    validate
                                                    resizable={true}
                                                    changeStep={changeActiveStep}
                                                    previousButtonText="Voltar"
                                                    finishButtonText="Finalizar"
                                                    nextButtonText="Avançar"
                                                    goToFirstStep={inicioCadastro}
                                                    goToNextStep={proximoCadastro}
                                                    finishButtonClick={() => finishButtonClick()}
                                                    finishButtonClasses={classNames("btn-wd btn-info",
                                                        {
                                                            finishButtonMin:
                                                                width < 550
                                                        },
                                                        {
                                                            finishButtonRight:
                                                                !width < 550
                                                        }
                                                    )}
                                                    nextButtonClasses={classNames("btn-wd btn-info",
                                                        {
                                                            nextButtonMin:
                                                                width < 550

                                                        },
                                                        {
                                                            nextButtonRight:
                                                                !width < 550
                                                        }
                                                    )}
                                                    previousButtonClasses={classNames("btn-wd",
                                                        {
                                                            previousButtonMin:
                                                                width < 550
                                                        },
                                                        {
                                                            previousButtonRight:
                                                                !width < 550
                                                        }
                                                    )}
                                                    progressbar
                                                    color="blue"
                                                />
                                            </div>
                                        </TabPane>
                                }
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </div>

            {/* Modal Inativar*/}
            <Modal
                isOpen={modalInativar}
                toggle={toggleModalInativar}
            >
                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalInativar}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">{clienteSelected.status === 0 ? "Inativar" : "Ativar"} cliente</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        {clienteSelected.status === 0 ?
                            <label>Tem certeza que deseja <b>inativar</b> esse cliente da sua empresa?</label>
                            : <label>Tem certeza que deseja <b>ativar</b> esse cliente em sua empresa?</label>
                        }
                    </Col>

                </ModalBody>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { toggleModalInativar() }}
                                style={{ width: '150px' }}
                            >NÃO
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { toggleModalInativar(); inativarCliente() }}
                                style={{ width: '150px' }}
                            >SIM
                                            </Button>
                        </Col>
                    </Row>
                </CardBody>

            </Modal>
            {/* Modal Inativar*/}

            {/* Modal Excluir*/}
            <Modal
                isOpen={modalExcluir}
                toggle={toggleModalExcluir}
            >
                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalExcluir}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Excluir cliente</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>Tem certeza que deseja <b>excluir</b> esse cliente?</label>
                    </Col>

                </ModalBody>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { toggleModalExcluir() }}
                                style={{ width: '150px' }}
                            >NÃO
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { toggleModalExcluir(); excluirCliente() }}
                                style={{ width: '150px' }}
                            >SIM
                                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Modal>
            {/* Modal Excluir*/}
        </>
    );
}

export default Clientes;
