import React, { useState, useRef } from 'react';
import { Button, Col, Row } from 'reactstrap';
import ReactTable from "react-table";

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import { Input, Info } from '../Components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import useScrollBar from '../../../../Util/Hooks/useScrollBar';

import { maskNumbers } from '~/Util/mascaras'
import useShallowEqualSelector from "../../../../Util/Hooks/useShallowEqualSelector";

export default function ModalOperacao({ isOpen, toggle, getOperacao, confirmModal }) {
    const tableRef = useScrollBar([isOpen])
    const operacoes = useShallowEqualSelector(state => state.entradaMercadoria.operacoes)
    const [habilitaVendedor] = useState(false)
    const inputNumero = useRef()

    const [pesquisaOperacao, setPesquisaOperacao] = useState({ codOperacao: 0, numeroOperacao: '', descricaoOperacao: '', geraFinanceiro: 0 })
    const [pesquisaVendedor, setPesquisaVendedor] = useState({ numeroVendedor: '', descricaoVendedor: '' })

    function onClickOperacao(rowIndex) {
        let operacaoSelected = operacoes[rowIndex]
        setPesquisaOperacao({ codOperacao: operacaoSelected.codigo, numeroOperacao: operacaoSelected.numero, descricaoOperacao: operacaoSelected.descricao, geraFinanceiro: operacaoSelected.conta })
    }

    function onDoubleClickOperacao(rowIndex) {
        let operacaoSelected = operacoes[rowIndex]
        setPesquisaOperacao({ codOperacao: operacaoSelected.codigo, numeroOperacao: operacaoSelected.numero, descricaoOperacao: operacaoSelected.descricao, geraFinanceiro: operacaoSelected.conta })

        confirmModal(pesquisaOperacao)
    }

    function getTrProps(state, row, column) {
        return {
            style: {
                height: '50px',
                paddingTop: '0px',
                paddingBottom: '5px',
                backgroundColor: operacoes[row.index].numero === pesquisaOperacao.numeroOperacao ? 'rgba(71, 179, 255, 0.41)' : ''
            },
            onClick: (e, handleOriginal) => {
                onClickOperacao(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            },
            onDoubleClick: (e, handleOriginal) => {
                onDoubleClickOperacao(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            }
        }
    }

    function getTdProps(state, rowInfo, column) {
        if (column.id === 'actions') {
            return { style: { padding: 0 } }
        } else {
            return {}
        }
    }

    function concluir() {
        const { numeroOperacao, descricaoOperacao } = pesquisaOperacao

        if (!numeroOperacao)
            return

        if (!descricaoOperacao)
            return

        confirmModal(pesquisaOperacao)
    }

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title={'Informações'}
            size="lg"
        >
            <ModalBasicBody>
                <h4 style={{ textAlign: 'center' }}>Informe os dados abaixo para prosseguir</h4>

                <label>Operação</label>
                <Row>
                    <Col md="3">
                        <label>Número</label>
                        <Input
                            innerRef={inputNumero}
                            type="text"
                            value={pesquisaOperacao.numeroOperacao}
                            onChange={e => setPesquisaOperacao({ ...pesquisaOperacao, numeroOperacao: maskNumbers(e.target.value) })}
                            autoComplete="off"
                        />
                        <br></br>
                    </Col>
                    <Col md="8">
                        <label>Descrição</label>
                        <Input
                            type="text"
                            value={pesquisaOperacao.descricaoOperacao}
                            onChange={e => setPesquisaOperacao({ ...pesquisaOperacao, descricaoOperacao: e.target.value })}
                            autoComplete="off"
                        />
                        <br></br>
                    </Col>
                    <Col lg={1} md={1} sm={1}>
                        <Button
                            type="button"
                            style={{ marginTop: 34, width: 40, marginBottom: 0 }}
                            color="info"
                            onClick={() => getOperacao(pesquisaOperacao)}
                        >
                            <Icon icon="search" className="fa-lg" />
                        </Button>
                    </Col>
                </Row>

                {
                    habilitaVendedor ?
                        <>
                            <label>Vendedor</label>
                            <Row>
                                <Col md="3">
                                    <label>Número</label>
                                    <Input
                                        type="text"
                                        value={pesquisaVendedor.numeroVendedor}
                                        onChange={e => setPesquisaVendedor({ ...pesquisaVendedor, numeroVendedor: maskNumbers(e.target.value) })}
                                        autoComplete="off"
                                    />
                                    <br></br>
                                </Col>
                                <Col md="8">
                                    <label>Descrição</label>
                                    <Input
                                        type="text"
                                        value={pesquisaVendedor.descricaoVendedor}
                                        onChange={e => setPesquisaVendedor({ ...pesquisaVendedor, descricaoVendedor: e.target.value })}
                                        autoComplete="off"
                                    />
                                    <br></br>
                                </Col>
                                <Col lg={1} md={1} sm={1}>
                                    <Button
                                        type="button"
                                        style={{ marginTop: 34, width: 40, marginBottom: 0 }}
                                        color="info"
                                        onClick={() => { }}
                                    >
                                        <Icon icon="search" className="fa-lg" />
                                    </Button>
                                </Col>
                            </Row>
                        </>
                        :
                        null
                }

                <Row>
                    <Col>
                        <fieldset className="fieldset-operacao">
                            <legend>Resultados</legend>
                            <div
                                ref={tableRef}
                                style={operacoes.length !== 0 ?
                                    { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 350 } :
                                    { visibility: 'hidden', opacity: '0' }} >
                                <ReactTable
                                    data={operacoes}
                                    columns={[
                                        {
                                            Header: 'Número',
                                            accessor: 'numero',
                                            minWidth: 40,
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Descrição',
                                            accessor: 'descricao',
                                            resizable: false,
                                            sortable: false
                                        }]}
                                    getTrProps={getTrProps}
                                    getTdProps={getTdProps}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                    noDataText=""
                                    pageSize={operacoes.length}
                                />
                            </div>
                        </fieldset>
                    </Col>

                </Row>

                <Col className="text-right">
                    <Info>
                        {`${operacoes.length} operações encontradas`}
                    </Info>
                </Col>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        onClick={() => concluir()}
                        color={"info"}
                    >CONCLUÍDO
                    </Button>
                </Col>
            </ModalBasicFooter>

        </ModalBasic>
    )
}