import { Reducer } from 'redux'
import { SaidaDiversaState, SaidaDiversaTypes, SaidaDiversaAction } from './types'

const INITIAL_STATE: SaidaDiversaState = {
    pesquisa: {
        idSaida: 0,
        numeroSaida: '',
        codEmpresa: '0',
        nomeDestinatario: '',
        dataInicio: new Date().toISOString().substr(0, 10),
        dataFim: new Date().toISOString().substr(0, 10),
        status: null,
        destinatario: {
            codigo: 0,
            documento: '',
            nomeRazao: '',
            tipoDestinatario: ''
        },
    },
    empresas: [],
    saida: {
        idSaida: 0,
        idFatura: 0,
        cabecaSaida: {
            codigoOperacao: 0,
            numeroOperacao: 0,
            descricaoOperacao: '',
            gerarConta: 0,
            presenca: '9',
            descricaoPresenca: '9 - Operação não presencial, outros'
        },
        cabecaSaidaCopia: {
            codigoOperacao: 0,
            numeroOperacao: 0,
            descricaoOperacao: '',
            gerarConta: 0,
            presenca: '9',
            descricaoPresenca: '9 - Operação não presencial, outros'
        },
        nfeReferencia: {
            numero: '',
            modelo: '',
            chave: '',
            cnpjEmissor: '',
            anoMes: '',
            uf: ''
        },
        destinatario: {
            codigo: 0,
            documento: '',
            razaoSocial: '',
            tipoDestinatario: ''
        },
        destinatarioCopia: {
            codigo: 0,
            documento: '',
            razaoSocial: '',
            tipoDestinatario: ''
        },
        transportadora: {
            codTransportadora: '',
            modalidade: 9,
            valorFrete: '',
            documento: '',
            razaoSocial: '',
            placaVeiculo: '',
            uf: '0',
            quantidadeVolume: '',
            especie: '',
            marca: '',
            numeroVolume: '',
            pesoLiquido: '',
            pesoBruto: ''
        },
        transportadoraCopia: {
            codTransportadora: '',
            modalidade: 9,
            valorFrete: '',
            documento: '',
            razaoSocial: '',
            placaVeiculo: '',
            uf: '0',
            quantidadeVolume: '',
            especie: '',
            marca: '',
            numeroVolume: '',
            pesoLiquido: '',
            pesoBruto: ''
        },
        produtos: [],
        produtosCopia: [],
        dadosAdicionais: '',
        dadosAdicionaisCopia: '',
        valorDesconto: '0,00',
        valorSubTotal: '0,00',
        valorTotal: '0,00',
    },
    produto: {
        numeroPedidoItem: 0,
        item: 0,
        codProduto: 0,
        numero: '',
        descricao: '',
        valorUnitario: '',
        quantidade: '',
        valorTotal: '',
        dataLancamento: '',
        status: 0,
        flControlaGrade: 0,
        grades: []
    },
    produtoGrades: [],
    gradeSelecionada: {},
    listSaidas: [],
    destinatarios: [],
    operacoes: [],
    transportadoras: [],
    inicioCadastro: false,
    currentStep: null,
    isVisibleModalOperacao: false,
    isVisibleModalProduto: false,
    invalido: {
        dadosIniciais: {
            destinatario: {
                documento: false
            },
            transportadora: {
                valorFrete: false,
                documento: false,
                placaVeiculo: false,
                uf: false,
                quantidadeVolume: false,
                especie: false,
                marca: false,
                numeroVolume: false,
                pesoLiquido: false,
                pesoBruto: false
            },
            dadosAdicionais: false
        },
        nfeReferencia: {
            numero: false,
            modelo: false,
            chave: false,
            documentoEmissor: false,
            uf: false
        }
    },
    validaNFeReferencia: false,
    emiteNFe: false,
    popUpFaturamento: false,
    screen: 0,
    modalOperacao: false,
    modalProdutoGrades: false,
};

const saidaDiversa: Reducer<SaidaDiversaState, SaidaDiversaAction> = (state = INITIAL_STATE, action: SaidaDiversaAction) => {
    switch (action.idStore) {
        case 'SAIDA_DIVERSA':
            switch (action.type) {
                case SaidaDiversaTypes.CLEAN_ALL:
                    return INITIAL_STATE
                case SaidaDiversaTypes.CLEAN_CADASTRO:
                    return {
                        ...state,
                        saida: INITIAL_STATE.saida
                    }
                case SaidaDiversaTypes.CLEAN_PESQUISA:
                    return {
                        ...state, pesquisa: {
                            idSaida: 0,
                            numeroSaida: '',
                            nomeDestinatario: '',
                            dataInicio: new Date().toISOString().substr(0, 10),
                            dataFim: new Date().toISOString().substr(0, 10),
                            status: null,
                            destinatario: {
                                codigo: 0,
                                documento: '',
                                nomeRazao: '',
                                tipoDestinatario: ''
                            },
                        }
                    }
                case SaidaDiversaTypes.CLEAN_REFERENCIA:
                    return { ...state, saida: { ...state.saida, nfeReferencia: INITIAL_STATE.saida.nfeReferencia } }
                case SaidaDiversaTypes.CLEAN_DADOS_INICIAIS:
                    return { ...state, saida: { ...state.saida, destinatario: INITIAL_STATE.saida.destinatario, transportadora: INITIAL_STATE.saida.transportadora, dadosAdicionais: INITIAL_STATE.saida.dadosAdicionais } }

                case SaidaDiversaTypes.OPERACOES:
                    return { ...state, operacoes: action.value }
                case SaidaDiversaTypes.OPERACAO:
                    return { ...state, saida: { ...state.saida, cabecaSaida: action.value } }

                case SaidaDiversaTypes.PESQUISA:
                    if (action.pesquisa)
                        return { ...state, pesquisa: action.pesquisa }
                    else
                        return state

                case SaidaDiversaTypes.LIST_SAIDAS:
                    return { ...state, listSaidas: action.value }

                case SaidaDiversaTypes.SAIDA:
                    return { ...state, saida: action.value }
                case SaidaDiversaTypes.UPDATE_SAIDA:
                    if (action.value)
                        return { ...state, saida: { ...state.saida, idSaida: action.value } }
                    else
                        return state
                case SaidaDiversaTypes.UPDATE_FATURA:
                    if (action.value)
                        return { ...state, saida: { ...state.saida, idFatura: action.value } }
                    else
                        return state
                case SaidaDiversaTypes.SAIDA_REFERENCIA:
                    return { ...state, saida: { ...state.saida, nfeReferencia: { ...state.saida.nfeReferencia, [action.campo]: action.value } } }



                case SaidaDiversaTypes.DESTINATARIOS:
                    if (action.list)
                        return { ...state, destinatarios: action.list }
                    else return state

                case SaidaDiversaTypes.DESTINATARIO:
                    if (action.destinatario)
                        return { ...state, saida: { ...state.saida, destinatario: action.destinatario } }
                    else return state

                case SaidaDiversaTypes.DESTINATARIO_COPIA:
                    if (action.destinatario)
                        return { ...state, saida: { ...state.saida, destinatarioCopia: action.destinatario } }
                    else return state

                case SaidaDiversaTypes.TRANSPORTADORAS:
                    if (action.list)
                        return { ...state, transportadoras: action.list }
                    else return state

                case SaidaDiversaTypes.TRANSPORTADORA:
                    if (action.transportadora)
                        return { ...state, saida: { ...state.saida, transportadora: action.transportadora } }
                    else return state

                case SaidaDiversaTypes.TRANSPORTADORA_COPIA:
                    if (action.transportadora)
                        return { ...state, saida: { ...state.saida, transportadoraCopia: action.transportadora } }
                    else return state

                case SaidaDiversaTypes.ADICIONAL:
                    if (action.value || action.value === "")
                        return { ...state, saida: { ...state.saida, dadosAdicionais: action.value } }
                    else return state

                case SaidaDiversaTypes.ADICIONAL_COPIA:
                    if (action.value || action.value === "")
                        return { ...state, saida: { ...state.saida, dadosAdicionaisCopia: action.value } }
                    else return state

                case SaidaDiversaTypes.PRODUTO:
                    if (action.produto)
                        return { ...state, produto: action.produto }
                    else return state

                case SaidaDiversaTypes.CLEAN_PRODUTO:
                    return { ...state, produto: INITIAL_STATE.produto }

                case SaidaDiversaTypes.PRODUTO_GRADES:
                    if (action.value)
                        return { ...state, produtoGrades: action.value }
                    else return state

                case SaidaDiversaTypes.PRODUTO_LIST:
                    if (action.list)
                        return { ...state, saida: { ...state.saida, produtos: action.list } }
                    else return state

                case SaidaDiversaTypes.PRODUTO_LIST_COPIA:
                    if (action.list)
                        return { ...state, saida: { ...state.saida, produtosCopia: action.list } }
                    else return state

                case SaidaDiversaTypes.VALOR_TOTAL:
                    if (action.value)
                        return { ...state, saida: { ...state.saida, [action.campo]: action.value } }
                    else return state

                case SaidaDiversaTypes.WIZARD_STEP:
                    return { ...state, currentStep: action.value }
                case SaidaDiversaTypes.POP_UP:
                    return { ...state, popUpFaturamento: action.value }

                case SaidaDiversaTypes.INVALIDO:
                    if (action.invalido)
                        return { ...state, popUpFaturamento: action.value }
                    else
                        return state

                case SaidaDiversaTypes.SCREEN:
                    if (action.value != null || action.value != undefined)
                        return { ...state, screen: action.value }
                    else
                        return state

                case SaidaDiversaTypes.SET_MODAL_OPERACAO:
                    if (action.value != null || action.value != undefined)
                        return { ...state, modalOperacao: action.value }
                    else
                        return state

                case SaidaDiversaTypes.SET_OPERACAO:
                    if (action.operacao)
                        return {
                            ...state,
                            saida: {
                                ...state.saida,
                                cabecaSaida: {
                                    ...state.saida.cabecaSaida,
                                    ...action.operacao
                                }
                            }
                        }
                    else
                        return state

                case SaidaDiversaTypes.SET_OPERACAO_COPIA:
                    if (action.operacao)
                        return {
                            ...state,
                            saida: {
                                ...state.saida,
                                cabecaSaidaCopia: {
                                    ...state.saida.cabecaSaidaCopia,
                                    ...action.operacao
                                }
                            }
                        }
                    else
                        return state

                case SaidaDiversaTypes.SET_MODAL_PRODUTO_GRADES:
                    return {
                        ...state, modalProdutoGrades:
                            action.value
                    }

                case SaidaDiversaTypes.SET_GRADE_SELECIONADA:
                    return {
                        ...state, gradeSelecionada: action.value
                    }

                case SaidaDiversaTypes.SET_EMPRESAS:
                    return {
                        ...state, empresas: action.value
                    }

                default:
                    return state
            }
        default:
            return state
    }
}

export default saidaDiversa;