import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Select } from "../../Components/index";
import { HelpButton } from "~/components/HelpPlugin/HelpPlugin";

import { maskMoeda } from '../../../../Util/mascaras'

import {
    Input,
    Row,
    Col,
} from "reactstrap";


const idStore = "LANCAMENTO_TITULOS";

const MetodoPgnt = () => {
    const dispatch = useDispatch();
    const formaPgm = useSelector(state => state.lancamentoTitulos.formaPgm, shallowEqual)
    const metodoPgnt = useSelector(state => state.lancamentoTitulos.cadastro.metodoPgnt, shallowEqual)
    const update = useSelector(state => state.lancamentoTitulos.cadastro.update, shallowEqual)
    const [data, setData] = useState({ dataAtual: new Date().toISOString().substr(0, 10), proximosVencimentos: "", mesReferencia: "" })

    useEffect(() => {
        onChangeParcela(metodoPgnt.qtdParcelas, "qtdParcelas")
    }, [metodoPgnt.codTipoPgto])

    useEffect(() => {
        onChangeFormaPgm(metodoPgnt.codForma)
    }, [metodoPgnt.codForma])

    useEffect(() => {
        let valor = Number(metodoPgnt.qtdParcelas) * Number(metodoPgnt.valorParcela.replace(".", "").replace(",", "."))
        dispatch({ idStore, type: "CADASTRO_METODO", value: { ...metodoPgnt, valorFinal: valor.toFixed(2) } })
    }, [metodoPgnt.qtdParcelas])

    function onChangeCadastroData(value, campo) {
        dispatch({ idStore, type: 'CADASTRO_METODO', value: { ...metodoPgnt, [campo]: value } })
        setData({ ...data, [campo]: value })
    }

    function onChangeNumeroParcela(value, campo) {
        dispatch({ idStore, type: "CADASTRO_METODO", value: { ...metodoPgnt, valorParcela: value } })
    }

    function onChangeData(value, cod, input) {
        if (update !== null) {
            let venci = new Date(metodoPgnt.vencimento)
            let referencia = new Date(metodoPgnt.mesReferencia)

            setData({ ...data, vencimento: venci.toISOString().substr(0, 10), proximosVencimentos: "", mesReferencia: referencia.toISOString().substr(0, 7) })
        }
        else {
            if (cod >= 2) {
                if (input === "") {
                    let venci = new Date()
                    venci.setDate(venci.getDate() + value)

                    let proximo = new Date()
                    proximo.setDate(proximo.getDate() + value)
                    proximo.setMonth(proximo.getMonth() + 1)

                    setData({ ...data, vencimento: venci.toISOString().substr(0, 10), proximosVencimentos: proximo.toISOString().substr(0, 10), mesReferencia: new Date().toISOString().substr(0, 7) })
                    dispatch({ idStore, type: "CADASTRO_DATA", campo: "vencimento", value: venci.toISOString().substr(0, 10) })
                    dispatch({ idStore, type: "CADASTRO_DATA", campo: "proximosVencimentos", value: proximo.toISOString().substr(0, 10) })
                    dispatch({ idStore, type: "CADASTRO_DATA", campo: "mesReferencia", value: new Date().toISOString().substr(0, 7) })
                }

                if (input === 'vencimento' && value !== "") {
                    let venci = new Date(value)
                    let proximo = new Date(venci)

                    proximo.setMonth(proximo.getMonth() + 1)

                    setData({ ...data, vencimento: venci.toISOString().substr(0, 10), proximosVencimentos: proximo.toISOString().substr(0, 10) })
                    dispatch({ idStore, type: "CADASTRO_DATA", campo: "vencimento", value: venci.toISOString().substr(0, 10) })
                }

            }
            else {
                let venci = new Date()
                setData({ ...data, vencimento: venci.toISOString().substr(0, 10), proximosVencimentos: "", mesReferencia: new Date().toISOString().substr(0, 7) })
                dispatch({ idStore, type: "CADASTRO_DATA", campo: "vencimento", value: venci.toISOString().substr(0, 10) })
                dispatch({ idStore, type: "CADASTRO_DATA", campo: "proximosVencimentos", value: "" })
                dispatch({ idStore, type: "CADASTRO_DATA", campo: "mesReferencia", value: new Date().toISOString().substr(0, 7) })
            }

        }
    }

    function onChangeFormaPgm(value) {
        let objForma = formaPgm.find(forma => forma.codForma === value)
        if (objForma) {
            if (objForma.flBaixaAutomatica === 1) {
                objForma.flaVista = 1
            }
            if (objForma.codTipoPgto === 1) {
                dispatch({
                    idStore, type: 'CADASTRO_METODO', value: {
                        ...metodoPgnt, codTipoPgto: objForma.codTipoPgto,
                        descricaoTipo: objForma.descricaoTipo,
                        qtdDiasEntreParcelas: objForma.qtdDiasEntreParcelas,
                        descricaoFormaPgnt: objForma.descricao,
                        flaVista: objForma.flaVista,
                        codForma: value,
                        qtdParcelas: 1,
                        status: 2
                    }
                })
                onChangeData(objForma.qtdDiasEntreParcelas, objForma.codTipoPgto)
            }
            else {
                dispatch({
                    idStore, type: 'CADASTRO_METODO', value: {
                        ...metodoPgnt, codTipoPgto: objForma.codTipoPgto,
                        descricaoTipo: objForma.descricaoTipo,
                        qtdDiasEntreParcelas: objForma.qtdDiasEntreParcelas,
                        descricaoFormaPgnt: objForma.descricao,
                        flaVista: objForma.flaVista,
                        codForma: value
                    }
                })
                onChangeData(objForma.qtdDiasEntreParcelas, objForma.codTipoPgto, "")
            }
        }
    }

    function onChangeParcela(value, campo) {
        if (campo === "valorParcela") {

            let valor = value.replace(".", "")
            valor = Number(valor.replace(",", ".")) * metodoPgnt.qtdParcelas

            if (metodoPgnt.codTipoPgto !== 1) {
                dispatch({ idStore, type: "CADASTRO_METODO", value: { ...metodoPgnt, valorFinal: valor.toFixed(2), valorParcela: value, valorPago: 0 } })
            } else if (metodoPgnt.codTipoPgto === 1) {
                dispatch({ idStore, type: "CADASTRO_METODO", value: { ...metodoPgnt, valorPago: valor, valorFinal: valor.toFixed(2), valorParcela: value } })
            }
        }

        if (campo === "qtdParcelas") {
            dispatch({ idStore, type: "CADASTRO_METODO", value: { ...metodoPgnt, qtdParcelas: value } })
        }
    }


    return (
        <>
            <Col>
                <fieldset style={{ border: 'solid 1px #5E6680', borderRadius: 5 }}>
                    <legend style={{ fontSize: '13px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
                        Método de Pagamento
                    </legend>
                    <Row className="padding-padrao-row">
                        {update !== null ?
                            <Col className="padding-padrao-col">
                                <Input
                                    value={metodoPgnt.descricaoFormaPgnt}
                                    disabled={true}
                                />
                            </Col>
                            :
                            <Col className="padding-padrao-col">
                                <label>Forma do Pagamento<span className="error"> *</span></label>
                                <Select
                                    first={"Selecione"}
                                    value={{ value: metodoPgnt.codForma }}
                                    select={{ value: "codForma", label: "descricao" }}
                                    options={formaPgm}
                                    onChange={(value) => onChangeFormaPgm(value.value)}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </Col>

                        }

                    </Row>

                    <Row className="padding-padrao-row">
                        <Col className="padding-padrao-col">
                            <label>Tipo do Pagamento</label>
                            <Input
                                type="text"
                                value={metodoPgnt.descricaoTipo}
                                autoComplete="off"
                                disabled={true}
                            />
                        </Col>
                    </Row>

                    <Row className="padding-padrao-row">
                        <Col className="padding-padrao-col" lg="6">
                            <Row  className="padding-padrao-row">
                                <label>{update !== null ? "vencimento" : "1° vencimento"}</label><HelpButton id="14" />
                            </Row>
                            <Input
                                id="vencimento"
                                type="date"
                                value={metodoPgnt.vencimento}
                                onChange={e => onChangeData(e.target.value, metodoPgnt.codForma, "vencimento")}
                                autoComplete="off"
                            />
                        </Col>
                        <Col className="padding-padrao-col" lg="6">
                            <Row  className="padding-padrao-row">
                                <label>Mês de Referência</label><HelpButton id="15" />
                            </Row>
                            <Input
                                type="month"
                                value={metodoPgnt.mesReferencia}
                                onChange={e => onChangeCadastroData(e.target.value, "mesReferencia")}
                                autoComplete="off"
                                disabled={update !== null ? true : false}
                            />
                        </Col>
                    </Row>

                    <Row className="padding-padrao-row">
                        <Col className="padding-padrao-col" lg="6">
                            <Row className="padding-padrao-row">
                                <label>{update !== null ? "------------" : "Próximos Vencimentos"}</label><HelpButton style={{ zIndex: 0 }} id="16" />
                            </Row>
                            <Input
                                type="date"
                                value={metodoPgnt.proximosVencimentos}
                                onChange={e => onChangeCadastroData(e.target.value, "proximosVencimentos")}
                                autoComplete="off"
                                disabled={update !== null || metodoPgnt.codTipoPgto === 1 ? true : false}
                            />
                        </Col>
                        {update !== null ?
                            <Col className="padding-padrao-col" lg="6">
                                <label>Número da Parcela<span className="error"> *</span></label>
                                <Input
                                    type="text"
                                    value={metodoPgnt.numeroParcela}
                                    autoComplete="off"
                                    disabled={true}
                                />
                            </Col>
                            :
                            <Col className="padding-padrao-col" lg="6">
                                <label>Qtde. Parcelas<span className="error"> *</span></label>
                                <Input
                                    type="number"
                                    value={metodoPgnt.codTipoPgto !== 1 ? metodoPgnt.qtdParcelas : 1}
                                    onChange={e => onChangeParcela(e.target.value, "qtdParcelas")}
                                    autoComplete="off"
                                    disabled={metodoPgnt.codTipoPgto !== 1 ? false : true}
                                    onfocus={""}
                                />
                            </Col>
                        }

                    </Row>

                    <Row className="padding-padrao-row">
                        <Col className="padding-padrao-col" lg="6">
                            <label>Valor da Parcela<span className="error"> *</span></label>
                            <Input
                                type="text"
                                value={maskMoeda(metodoPgnt.valorParcela)}
                                onChange={update !== null ? e => onChangeNumeroParcela(maskMoeda(e.target.value)) : e => onChangeParcela(maskMoeda(e.target.value), "valorParcela")}
                                autoComplete="off"
                                maxLength="10"
                            />
                        </Col>
                        <Col className="padding-padrao-col" lg="6">
                            <label>Valor Final</label>
                            <Input
                                type="text"
                                value={maskMoeda(metodoPgnt.valorFinal)}
                                autoComplete="off"
                                disabled={true}
                            />
                        </Col>
                    </Row>
                </fieldset>
            </Col>
        </>
    )
}
export default MetodoPgnt
