import axios from 'axios';
import api from "../Util/api";
import { alertar } from "../Util/alertar";
import store from '../store/stores';
import * as action from '../store/reducers/Movimentacao/Inventario/actions'
import notificacao from '../store/reducers/notificacaoStore';
import { ValidaEmail } from '../Util/validacao';

export function initScreen() {
    GetGrupos()
}

export function GetGrupos() {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/grupoProduto/grupos?status=0&tipo=P`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            action.setListGrupos(response.data.records)
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os grupos", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function GetSubGrupos(codGrupo: number) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/subgrupoProduto/subgrupos?codGrupo=${codGrupo}&status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            action.setListSubGrupo(response.data.records)
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os subGrupos", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function GetProdutoInventario() {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        //@ts-ignore
        const { codigoDescricao, codigoGrupo, codigoSubGrupo } = store.getState().inventario.pesquisa

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

        let codigoBarras = "";
        let descricao = codigoDescricao.replace(/[\d-]/g, '');

        if (descricao.length === 0)
            codigoBarras = codigoDescricao;

        axios.get(api.urlMovimento + `api/v1/Inventario?CodigoBarras=${codigoBarras}&Descricao=${descricao}&CodigoGrupo=${codigoGrupo}&CodigoSubGrupo=${codigoSubGrupo}`, {
            headers: { Authorization: bearerAuth }
        }).then(response => {
            let listItens = []

            listItens = response.data.data.map((item: any) => {
                return {
                    ...item,
                    checked: false,
                    gradeConfirmada: item.flControlaGrade ? null : true
                }
            });

            action.setListItens(listItens)
        }).catch(e => {
            action.setListItens([])
            if (e.response) {
                alertar(notificacao, 'br', e.response.data.message, 'warning', 'icon-alert-circle-exc', 2, e)
                return
            }
            alertar(notificacao, 'br', "Nenhum produto encontrado", 'warning', 'icon-alert-circle-exc', 2, e)
        })
    } catch (e) {
        alertar(notificacao, 'br', "Nenhum produto encontrado", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function AddItemAtualizar(quantidadeFocus: Function) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        //@ts-ignore
        const { listItens, pesquisa, listAtualizacao } = store.getState().inventario

        if (!pesquisa.quantidade) {
            quantidadeFocus()
            return alertar(notificacao, 'br', "Quantidade Atualizar não foi informado", 'warning', 'icon-alert-circle-exc', 2)
        }

        if(listItens.length > 0) {
            const isItemChecked = listItens.filter((list) => list.checked) 
            if(isItemChecked.length === 0) {
                return alertar(notificacao, 'br', "Marque um ou mais produtos para Adicionar", 'warning', 'icon-alert-circle-exc', 2)
            }
        }
      
        let newListStr = JSON.stringify(listItens);
        let newListArr = JSON.parse(newListStr);

        let listAtualizar: any[] = []

        newListArr.forEach((item: any) => {
            if (item.checked) {
                let index = listAtualizacao.findIndex(a => a.numeroProduto == item.numeroProduto)

                item.grades.forEach((element: any) => {
                    element.estoqueAtualizar = element.estoqueAtual
                    element.atualizar = false
                })

                if (index < 0) {
                    listAtualizar.push({ ...item, estoqueAtualizar: pesquisa.quantidade })
                }
            }
        })

        action.setListAtualizar([...listAtualizacao, ...listAtualizar])

    } catch (e) {
        alertar(notificacao, 'br', "Nenhum produto encontrado", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function RemoveItemAtualizar(index: number) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        //@ts-ignore
        const { listAtualizacao } = store.getState().inventario

        let newListStr = JSON.stringify(listAtualizacao);
        let newListArr = JSON.parse(newListStr);

        newListArr.splice(index, 1)

        action.setListAtualizar(newListArr)

    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel remover produto", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function GetGrade(codigoProduto: number, codigoTipoGrade: number, quantidadeAtualizar: number) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        action.setGradeSelecionada({ codigoProduto, codigoTipoGrade, quantidadeAtualizar })

        axios.get(api.urlCadastro + `api/v1/produto/grades?codProduto=${codigoProduto}&codTipoGrade=${codigoTipoGrade}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            let listGrade = []

            if (response.data.records.grades) {
                listGrade = response.data.records.grades.map((grade: any) => { return { ...grade, invalido: false } })
            }
            action.setGrade(listGrade ? listGrade : [])
        })

    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel obter as grades", 'warning', 'icon-alert-circle-exc', 2)
    }
}
interface Grade {
    codigoGrade: string,
    quantidadeAnterior: number,
    quantidadeAtual: number,
    codigoTipoGrade: number
}


export function verificaProdutosAtualizacao(toogleModalPopUp: Function) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        const { listAtualizacao } = store.getState().inventario

        let attStr = JSON.stringify(listAtualizacao)
        let attArr = JSON.parse(attStr)

        let openModal = true;
        attArr.forEach((item: any) => {
            if (!item.gradeConfirmada) {
                openModal = false
                item.gradeConfirmada = false
            }
        })

        if (openModal) {
            toogleModalPopUp()
        } else {
            action.setListAtualizar(attArr)
            alertar(notificacao, 'br', "Nem todos produtos estão com suas grades atualizadas", 'warning', 'icon-alert-circle-exc', 2)
        }

    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel atualizar grade", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function Atualizar(thenFunction: Function) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        const { listAtualizacao } = store.getState().inventario

        var newListAtualizacao: any[] = JSON.parse(JSON.stringify(listAtualizacao))
        var newListGrades: any[] = []
        var arrData: any[] = []

        newListAtualizacao.forEach(element => {
            element.grades.forEach((subElement: any) => {
                if (subElement.atualizar) {
                    newListGrades.push({
                        codigoGrade: subElement.codigoGrade,
                        quantidadeAnterior: subElement.estoqueAtual,
                        quantidadeAtual: subElement.estoqueAtualizar,
                        codigoTipoGrade: subElement.codigoTipoGrade
                    })
                }
            })
            element.grades = JSON.parse(JSON.stringify(newListGrades))
        })

        newListAtualizacao.forEach(element => {
            arrData.push({
                codigoProduto: element.codigoProduto,
                quantidadeAnterior: element.estoqueAtual,
                quantidadeAtual: element.estoqueAtualizar,
                grades: element.grades
            })
        })

        var dataStr = JSON.stringify(arrData)
        var dataArr = JSON.parse(dataStr)

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

        axios({
            method: 'put',
            url: api.urlMovimento + `api/v1/inventario`,
            headers: { Authorization: bearerAuth },
            data: dataArr
        }).then(response => {
            alertar(notificacao, 'br', 'Estoque Atualizado com Sucesso', 'success', 'icon-check-2', 2)
            GetProdutoInventario()
            action.setListAtualizar([])
            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            thenFunction()
        })

    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel atualizar estoque", 'warning', 'icon-alert-circle-exc', 2)
        thenFunction()
    }
}

export async function getEmpresasUsuario() {
    try {
        //@ts-ignore
        const { notificacao, inventario: { pesquisa } } = store.getState()
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        let arrEmpresas: any = []

        return await axios.get(`${api.urlCadastro}api/v1/empresa/empresasUsuario`, {
            headers: { Authorization: basicAuth }
        }).then((response) => {
            if (response.data) {

                response.data.records.forEach((element: any) => {
                    element.codEmpresaFilial = element.codEmpresaFilial.toString()
                    arrEmpresas.push(element)
                });

                action.setEmpresas(arrEmpresas)
                return
            }
        }).catch(e => {
            if (e.response) {
                alertar(notificacao, 'br', e.response.data.message, 'warning', 'icon-alert-circle-exc', 2)
            } else {
                alertar(notificacao, 'br', "Não foi possível obter as filiais", 'warning', 'icon-alert-circle-exc', 2)
            }
        })

    } catch (error) {
        alertar(notificacao, 'br', "Não foi possível obter as filiais", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function getFuncionarios() {
    //@ts-ignore
    const { notificacao } = store.getState()
    //@ts-ignore
    const codEmpresa = store.getState().login.usuario.empresa.codEmpresa
    const modalFuncionarios = store.getState().inventario.modalFuncionarios

    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        var usuario, email = ''

        if (!ValidaEmail(modalFuncionarios.nomeEmail)) {
            usuario = modalFuncionarios.nomeEmail.trim()
            email = ''
        } else {
            email = modalFuncionarios.nomeEmail.trim()
            usuario = ''
        }

        return axios.get(api.urlCadastro + `api/v1/funcionario/funcionariosInventario?codEmpresa=${codEmpresa}&nome=${usuario}&email=${email}&cpf=${modalFuncionarios.cpf}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                action.setFuncionarios([])
                alertar(notificacao, 'br', "Nenhum funcionário encontrado", 'warning', 'icon-alert-circle-exc', 2)
                return
            }
            action.setFuncionarios(response.data.records)
            return response.data.records[0].codigo
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });

    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os funcionários", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function handlePesquisar() {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        //@ts-ignore
        const { pesquisa } = store.getState().inventario
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

        axios.get(api.urlMovimento + `api/v1/Inventario/getall?CodigoFilial=${pesquisa.codFilial}&CodigoUsuario=${pesquisa.codigoUser}&DataInicial=${pesquisa.dataInicio}&dataFinal=${pesquisa.dataFinal}`, {
            headers: { Authorization: bearerAuth }
        }).then(response => {
            let listItens = []

            listItens = response.data.data.map((item: any) => {
                return {
                    ...item,
                    checked: false,
                    gradeConfirmada: item.flControlaGrade ? null : true
                }
            });

            action.setListItensPesquisa(listItens)
        }).catch(e => {
            action.setListItens([])
            if (e.response.status !== 404) {
                alertar(notificacao, 'br', e.response.data.message, 'warning', 'icon-alert-circle-exc', 2, e)
                return
            }
            alertar(notificacao, 'br', "Nenhum funcionário encontrado", 'warning', 'icon-alert-circle-exc', 2, e)
        })
    } catch (e) {
        alertar(notificacao, 'br', "Falha na conexão", 'warning', 'icon-alert-circle-exc', 2)
    }
}
