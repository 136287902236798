import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";

const idStore = 'IPIS';

export function Pesquisar(notificacao, dispatch, codEmpresa, pesquisa) {
    try {
        dispatch({ idStore, type: 'IPIS', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/IPI/IPIs?codEmpresa=${codEmpresa}&status=${pesquisa.ativos}&descricao=${pesquisa.descricao}&ipi=${pesquisa.ipi}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhum IPI encontrado", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'IPIS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os IPIs", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetIPI(notificacao, dispatch, codEmpresa, codIpi, thenFunction) {
    try {
        var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        await axios.get(api.urlCadastro + `api/v1/IPI/IPI?codEmpresa=${codEmpresa}&codIpi=${codIpi}`, {
            headers: { Authorization: basicAuth }
        }).then(function (response) {
            let type = 'IPI';
            dispatch({ idStore, type, campo: 'codIpi', value: codIpi })
            dispatch({ idStore, type, campo: 'ipi', value: response.data.records.ipi })
            dispatch({ idStore, type, campo: 'descricao', value: response.data.records.descricao })
            dispatch({ idStore, type, campo: 'status', value: response.data.records.status })
            dispatch({ idStore, type: 'IPI_COPIA' })
            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export async function CreateIPI(notificacao, dispatch, codEmpresa, ipi) {
    try {
        //Retorna se houve erro ou nao
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;
        if (ipi.ipi === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'ipi', value: true })
            invalido = true
        }
        if (ipi.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/IPI/create?codEmpresa=${codEmpresa}`,
            headers: { Authorization: basicAuth },
            data: {
                Ipi: ipi.ipi,
                Descricao: ipi.descricao,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'IPI', campo: 'codIpi', value: response.data.records })
            dispatch({ idStore, type: 'IPI_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function UpdateIPI(notificacao, dispatch, codEmpresa, ipi, ipiCopia) {
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(ipi) === JSON.stringify(ipiCopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        var invalido = false;
        if (ipi.ipi === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'ipi', value: true })
            invalido = true
        }
        if (ipi.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/IPI/update?codEmpresa=${codEmpresa}&codIpi=${ipi.codIpi}`,
            headers: { Authorization: basicAuth },
            data: {
                Ipi: ipi.ipi,
                Descricao: ipi.descricao,
            }
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'IPI', campo: 'codIpi', value: response.data.records })
            dispatch({ idStore, type: 'IPI_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export function Excluir(notificacao, dispatch, codEmpresa, ipi, pesquisa) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/IPI/delete?codEmpresa=${codEmpresa}&codIpi=${ipi.codIpi}`,
            headers: { Authorization: basicAuth },
        }).then(response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            Pesquisar(notificacao, dispatch, codEmpresa, pesquisa)
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}