import React, { useState, useRef, useEffect } from 'react';
import { Input, Button, Col, Row, FormGroup, Label } from 'reactstrap';
import ReactTable from "react-table";

import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import { InputCpfCnpj } from '../Components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import useScrollBar from '../../../Util/Hooks/useScrollBar';
import * as controllerLancamento from '../../../controller/Financeiro/controllerLancamentoTitulos'

import { useSelector, shallowEqual } from 'react-redux';
import { maskCPF, maskCNPJ } from '../../../Util/mascaras';

export default function ModalPesquisa({ isOpen, toggle, confirmModal, clean }) {
    const tableRef = useScrollBar([isOpen])
    const fornecedores = useSelector(state => state.lancamentoTitulos.fornecedores, shallowEqual)
    const inputDocumento = useRef()
    const [pesquisaCliente, setPesquisaCliente] = useState({ codigo: null, documento: '', nomeRazao: '', tipo: '' })

    useEffect(() => {
        inputDocumento.current.focus()
    }, [])

    function pesquisar(value, id) {
        if (id === "documento") {
            setPesquisaCliente({ ...pesquisaCliente, documento: value })
        }
        if (id === "nome") {
            setPesquisaCliente({ ...pesquisaCliente, nomeRazao: value })
        }
        if (id === "tipo") {
            setPesquisaCliente({ ...pesquisaCliente, tipo: value })
            controllerLancamento.getFornecedores(pesquisaCliente.documento, pesquisaCliente.nomeRazao, value)
        }
    }

    function onChangePesquisa() {
        controllerLancamento.getFornecedores(pesquisaCliente.documento, pesquisaCliente.nomeRazao, pesquisaCliente.tipo)
    }
    function onClickFornecedor(rowIndex) {
        let operacaoSelected = fornecedores[rowIndex]
        setPesquisaCliente({ ...pesquisaCliente, codigo: operacaoSelected.codigo, documento: operacaoSelected.cpfCnpj, nomeRazao: operacaoSelected.nomeRazao, tipo: operacaoSelected.tipo })
    }

    function onDoubleClickOperacao() {
        confirmModal(pesquisaCliente)
    }

    function limparModal() {
        setPesquisaCliente({ codigo: null, documento: '', nomeRazao: '', tipo: '' })
        clean()
    }

    function getTrProps(state, row, column) {
        return {
            style: {
                height: '50px',
                paddingTop: '0px',
                paddingBottom: '5px',
                backgroundColor: fornecedores[row.index].cpfCnpj === pesquisaCliente.documento ? 'rgba(71, 179, 255, 0.41)' : ''
            },
            onClick: (e, handleOriginal) => {
                onClickFornecedor(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            },
            onDoubleClick: (e, handleOriginal) => {
                onDoubleClickOperacao(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            }
        }
    }

    function getTdProps(state, rowInfo, column) {
        if (column.id === 'actions') {
            return { style: { padding: 0 } }
        } else {
            return {}
        }
    }

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title='Pesquisa de Cliente'
            size="lg"
        >
            <ModalBasicBody>
                <h4 style={{ textAlign: 'center' }}>Informe os dados abaixo para prosseguir</h4>

                <Row className="padding-padrao-row">
                    <Col className="padding-padrao-col" md={4}>
                        <label>CPF / CNPJ</label>
                        < InputCpfCnpj
                            innerRef={inputDocumento}
                            type="text"
                            tipo={pesquisaCliente.documento.length <= 14 ? 'cpf' : 'cnpj'}
                            value={pesquisaCliente.documento}
                            onChange={e => pesquisar(e.target.value, "documento")}
                            autoComplete="off"
                            maxLength="18"
                        />
                        <br></br>
                    </Col>

                    <Col className="padding-padrao-col" md={5}>
                        <label>Nome</label>
                        <Input
                            type="text"
                            value={pesquisaCliente.nomeRazao}
                            onChange={e => pesquisar(e.target.value, "nome")}
                            autoComplete="off"
                        />
                    </Col>

                    <Col className="padding-padrao-col" md={1}>
                        <Button
                            type="button"
                            style={{ marginTop: 34, width: 40, marginBottom: 0 }}
                            color="info"
                            onClick={onChangePesquisa}
                        >
                            <Icon icon="search" className="fa-lg" />
                        </Button>
                    </Col>
                    <Col lg={1} className="text-center padding-padrao-col" style={{ paddingTop: '12px' }}>
                        <Col className="padding-padrao-col">
                            <FormGroup check className="form-check-radio" >
                                <Label check style={{ paddingRight: '5px' }}>
                                    <Input type="radio"
                                        value=""
                                        onChange={(value) => pesquisar(value.target.value, "tipo")}
                                        checked={pesquisaCliente.tipo === '' ? true : false}
                                    />
                                    <span className="form-check-sign" />Todos
                                </Label>
                                <Label check style={{ paddingRight: '5px' }}>
                                    <Input type="radio"
                                        value="CLI"
                                        onChange={(value) => pesquisar(value.target.value, "tipo")}
                                        checked={pesquisaCliente.tipo === 'CLI' ? true : false}
                                    />
                                    <span className="form-check-sign" />Cliente
                                </Label>
                                <Label check style={{ paddingRight: '5px' }}>
                                    <Input type="radio"
                                        value="FOR"
                                        onChange={(value) => pesquisar(value.target.value, "tipo")}
                                        checked={pesquisaCliente.tipo === 'FOR' ? true : false}
                                    />
                                    <span className="form-check-sign" />Fornecedor
                                </Label>
                                <Label check style={{ paddingRight: '5px' }}>
                                    <Input type="radio"
                                        value="FIL"
                                        onChange={(value) => pesquisar(value.target.value, "tipo")}
                                        checked={pesquisaCliente.tipo === 'FIL' ? true : false}
                                    />
                                    <span className="form-check-sign" />Filial
                                </Label>
                            </FormGroup>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <fieldset className="fieldset-operacao">
                            <legend>Resultados</legend>
                            <div
                                ref={tableRef}
                                style={fornecedores.length !== 0 ?
                                    { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: '25vh' } :
                                    { visibility: 'hidden', opacity: '0' }} >
                                <ReactTable
                                    data={fornecedores}
                                    NoDataComponent={() => (<></>)}
                                    columns={[
                                        {
                                            Header: 'CPF / CNPJ',
                                            accessor: 'cpfCnpj',
                                            minWidth: 40,
                                            resizable: false,
                                            sortable: false,
                                            Cell: (props) => props.original.cpfCnpj = props.original.tipoPessoa === 'F' ? maskCPF(props.original.cpfCnpj) : maskCNPJ(props.original.cpfCnpj)
                                        },
                                        {
                                            Header: 'Nome / Razão Social',
                                            accessor: 'nomeRazao',
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Tipo Pessoa',
                                            accessor: 'tipoPessoa',
                                            minWidth: 25,
                                            resizable: false,
                                            sortable: false,
                                            Cell: (props) => props.original.tipoPessoa === 'F' ? 'Física' : 'Jurídica'
                                        },
                                        {
                                            Header: 'Tipo',
                                            accessor: 'tipo',
                                            minWidth: 25,
                                            resizable: false,
                                            sortable: false
                                        }
                                    ]}
                                    getTrProps={getTrProps}
                                    getTdProps={getTdProps}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight header-fix"
                                    noDataText=""
                                    pageSize={fornecedores.length}
                                />
                            </div>

                            <br />
                        </fieldset>
                    </Col>

                </Row>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        onClick={() => limparModal()}
                        color={"info"}
                    >LIMPAR
                    </Button>
                    <Button
                        className="btn-padrao"
                        onClick={() => confirmModal(pesquisaCliente)}
                        color={"info"}
                    >CONCLUÍDO
                    </Button>
                </Col>
            </ModalBasicFooter>

        </ModalBasic>
    )
}
