import moment from 'moment'
import { Reducer } from 'redux'
import { PedidoVendaState, PedidoVendaTypes, PedidoVendaAction } from './types'

const INITIAL_STATE: PedidoVendaState = {
    itensGrade: {
        codigoProduto: 0,
        codTipoGrade: '',
        grades: [
            {
                codGrade: '',
                dtHoraAtualização: '',
                qtdEstoqueGrade: 0,
                estoqueAtualizar: 0,
                descricao: '',
                sigla: '',
                status: 0,
            }
        ]
    },
    segurancaPedidoCredenciais: {
        usuario: '',
        senha: '',
        motivo: ''
    },
    pedidos: [],
    pedido: {
        cod: '',
        numPedido: 0,
        data: '',
        docFiscal: '',
        empresa: '',
        valorDesconto: '',
        nomeFuncionario: '',
        cliente: '',
        caixa: '',
        operacao: '',
        valorSubTotal: '',
        total: '',
        status: ''
    },
    totaisPesquisa: {
        valor: 0,
        desconto: 0,
        total: 0
    },
    totaisDetalhesPesquisa: {
        totalProd: 0,
        descProd: 0,
        totalVenda: 0,
        titulo: 0,
        juros: 0,
        multa: 0,
        descPag: 0,
        pago: 0
    },
    pesquisa: {
        numPedido: '',
        docFiscal: '',
        cpfCnpj: '',
        numRazaoSocial: '',
        status: '0',
        empresa: '0',
        dateOrderInitial: moment(new Date()).format('YYYY-MM-DD'),
        dateOrderFinally: moment(new Date()).format('YYYY-MM-DD'),
        page: 1,
        pageSize: 5
    },
    informacaoEnvioPedido: {
        whatsapp: {
            telefone: ''
        },
        email: {
            endereco: ''
        }
    },
    pagination: {
        totalPage: 0,
    },
    cliente: {
        codCli: 0,
        cpfCnpj: '',
        nomeRazao: '',
        tipoCli: '',
    },
    detalhesPedidoItens: [],
    detalhesPedidoPagamento: [],
    detalhesFormaPagamento: [],
    formasPagamentos: [],
    itens: [],

    totais: {
        quantidadeTotal: 0,
        quantidadeItens: 0,
        subtotal: 0,
        desconto: 0,
        total: 0,
    },

    informacoes: {
        numPedido: 0,
        codCaixa: 0,
        presenca: {
            codigo: 9,
            descricao: '9 - Operação não presencial, outros',
        },
        usuario: {
            codUser: 0,
            descricao: '',
        },
        vendedor: {
            codFunc: 0,
            nomeVendedor: '',
            cpf: '',
            funcao: '',
        },

    },

    operacao: {
        codigo: 0,
        numero: 0,
        descricao: '',
        geraFinanceiro: 0
    },

    produto: {
        item: 0,
        codProduto: 0,
        imagem: '',
        numProduto: 0,
        codBarras: '',
        nome: '',
        precoVendaAtual: 0,
        quantidade: 1,
        descontoReais: 0,
        descontoPercent: 0,
        subtotal: 0,
        total: 0,
        flControlaGrade: 0,
    },
    miniCardSelected: {
        produto: 0,
        subGrupo: 0,
    },
    subGrupoProdutos: [],
    produtos: [],
    clientes: [],
    operacoes: [],
    vendedores: [],
    retiradasCaixa: [],
    retiradaCaixa: {
        codigoCaixaLancto: 0,
        codigoCaixa: 0,
        codigoFormaPagamento: 0,
        descricaoForma: '',
        operador: '',
        codigoTipoLancamento: 2,
        dataLancamento: '',
        valorLancamento: 0,
        observacao: '',
        status: 0,
        autorizador: ''
    }
}

const pedidoVenda: Reducer<PedidoVendaState, PedidoVendaAction> = (state = INITIAL_STATE, action: PedidoVendaAction) => {
    switch (action.idStore) {
        case 'PEDIDO_VENDA':
            switch (action.type) {
                case PedidoVendaTypes.CLIENTE:
                    if (action.cliente)
                        return { ...state, cliente: action.cliente }
                    else return state
                case PedidoVendaTypes.CLIENTES:
                    if (action.clientes)
                        return { ...state, clientes: action.clientes }
                    else return state
                case PedidoVendaTypes.OPERACOES:
                    if (action.operacoes)
                        return { ...state, operacoes: action.operacoes }
                    else return state
                case PedidoVendaTypes.VENDEDORES:
                    if (action.vendedores)
                        return { ...state, vendedores: action.vendedores }
                    else return state
                case PedidoVendaTypes.ITEMS:
                    if (action.itens)
                        return { ...state, itens: action.itens }
                    else return state
                case PedidoVendaTypes.ADD_ITEM:
                    if (action.item)
                        return { ...state, itens: [...state.itens, action.item] }
                    else return state
                case PedidoVendaTypes.TOTAIS:
                    if (action.totais)
                        return { ...state, totais: action.totais }
                    else return state
                case PedidoVendaTypes.INFORMACOES:
                    return { ...state, informacoes: { ...state.informacoes, [action.campo]: action.value } }
                case PedidoVendaTypes.PRODUTOS:
                    if (action.produtos)
                        return { ...state, produtos: action.produtos }
                    else return state
                case PedidoVendaTypes.PRODUTO:
                    if (action.produto)
                        return { ...state, produto: action.produto }
                    else return state
                case PedidoVendaTypes.SUB_GRUPO_PRODUTOS:
                    if (action.subGrupoProdutos)
                        return { ...state, subGrupoProdutos: action.subGrupoProdutos }
                    else return state
                case PedidoVendaTypes.CLEAN:
                    return {
                        ...state,
                        cliente: INITIAL_STATE.cliente,
                        itens: [],
                        totais: INITIAL_STATE.totais,
                        informacoes: {
                            ...state.informacoes, numPedido: 0,
                            presenca: {
                                codigo: 9,
                                descricao: '9 - Operação não presencial, outros',
                            },
                        },
                        produto: INITIAL_STATE.produto,
                        miniCardSelected: INITIAL_STATE.miniCardSelected,
                        produtos: [],
                        clientes: [],
                        operacoes: [],
                        vendedores: [],
                        pedido: INITIAL_STATE.pedido
                    }
                case PedidoVendaTypes.CLEAN_CLIENTES:
                    return { ...state, clientes: [] }
                case PedidoVendaTypes.CLEAN_CLIENTE:
                    return { ...state, cliente: INITIAL_STATE.cliente }
                case PedidoVendaTypes.MINI_CARD_SELECTED:
                    if (action.miniCardSelected)
                        return { ...state, miniCardSelected: action.miniCardSelected }

                case PedidoVendaTypes.OPERACAO:
                    if (action.operacao)
                        return { ...state, operacao: action.operacao }
                case PedidoVendaTypes.PEDIDO:
                    if (action.pedido)
                        return { ...state, pedido: action.pedido }
                    else return state
                case PedidoVendaTypes.PEDIDOS:
                    if (action.pedidos)
                        return { ...state, pedidos: action.pedidos }
                    else return state
                case PedidoVendaTypes.TOTAIS_PESQUISA:
                    if (action.totaisPesquisa) {
                        return { ...state, totaisPesquisa: action.totaisPesquisa }
                    }
                    else return state
                case PedidoVendaTypes.PESQUISA:
                    return { ...state, pesquisa: action.pesquisa }
                case PedidoVendaTypes.CLEAN_PESQUISA:
                    return {
                        ...state,
                        pesquisa: INITIAL_STATE.pesquisa,
                        pedidos: INITIAL_STATE.pedidos,
                        totaisPesquisa: INITIAL_STATE.totaisPesquisa,
                    }
                case PedidoVendaTypes.PAGINATION:
                    if (action.pagination) {
                        return { ...state, pagination: action.pagination }
                    }
                    else return state
                case PedidoVendaTypes.TOTAIS_DETALHES_PESQUISA:
                    if (action.totaisDetalhesPesquisa) {
                        return { ...state, totaisDetalhesPesquisa: action.totaisDetalhesPesquisa }
                    } else return state
                case PedidoVendaTypes.ITENS_GRADE_PDV:
                    if (action.itensGrade) {
                        return { ...state, itensGrade: action.itensGrade }
                    } else return state
                case PedidoVendaTypes.DETALHES_PEDIDO_ITENS:
                    if (action.detalhesPedidoItens) {
                        return { ...state, detalhesPedidoItens: action.detalhesPedidoItens }
                    } else return state
                case PedidoVendaTypes.DETALHES_PEDIDO_PAGAMENTO:
                    if (action.detalhesPedidoPagamento) {
                        return { ...state, detalhesPedidoPagamento: action.detalhesPedidoPagamento }
                    } else return state
                case PedidoVendaTypes.SEGURANCA_PEDIDO_CREDENCIAIS:
                    if (action.segurancaPedidoCredenciais) {
                        return { ...state, segurancaPedidoCredenciais: action.segurancaPedidoCredenciais }
                    } else return state
                case PedidoVendaTypes.CLEAN_SEGURANCA_PEDIDO_CREDENCIAIS:
                    return {
                        ...state,
                        segurancaPedidoCredenciais: INITIAL_STATE.segurancaPedidoCredenciais
                    }
                case PedidoVendaTypes.INFORMACAO_ENVIO_PEDIDO:
                    return { ...state, informacaoEnvioPedido: { ...state.informacaoEnvioPedido, [action.campo]: action.value } }
                case PedidoVendaTypes.CLEAN_INFORMACAO_ENVIO_PEDIDO:
                    return {
                        ...state,
                        informacaoEnvioPedido: INITIAL_STATE.informacaoEnvioPedido,
                    }
                case PedidoVendaTypes.DETALHES_FORMA_PAGAMENTO:
                    if (action.detalhesFormaPagamento) {
                        return { ...state, detalhesFormaPagamento: action.detalhesFormaPagamento }
                    } else return state
                case PedidoVendaTypes.CLEAN_DETALHES_PEDIDO:
                    return {
                        ...state,
                        detalhesFormaPagamento: INITIAL_STATE.detalhesFormaPagamento,
                    }
                case PedidoVendaTypes.RETIRADAS_CAIXA:
                    if (action.retiradasCaixa) {
                        return { ...state, retiradasCaixa: action.retiradasCaixa }
                    } else return state
                case PedidoVendaTypes.ADD_RETIRADA_CAIXA:
                    if (action.retiradaCaixa) {
                        return { ...state, retiradaCaixa: action.retiradaCaixa }
                    } else return state
                case PedidoVendaTypes.CLEAN_RETIRADA_CAIXA:
                    return {
                        ...state,
                        retiradaCaixa: INITIAL_STATE.retiradaCaixa,
                    }
                default:
                    return state
            }
        default:
            return state
    }
}

export default pedidoVenda