import { IOperacaoState, OperacaoAction, OperacaoStore, OperacaoTypes } from '../Operacao/types'
import { Reducer } from 'react';
//State da tela de Pesquisa e cadastro de Operacao
const INITIAL_STATE: IOperacaoState = {
    pesquisa: {
        numero: '',
        descricao: '',
        tipo: '0',
    },
    operacao: {
        codigo: 0,
        numero: '',
        descricao: '',
        nfe: 0,
        conta: 0,
        estoque: 0,
        tipo: '0',
        caracteristicas: 0,
        ativo: 1,
        update: false,
    },
    invalido: {
        numero: false,
        descricao: false,
        tipo: false,
        caracteristicas: false
    },
    inicioCadastro: false,
    verCadastro: false,
    caracteristica: [],
    operacoes: [],
    screen: 0,
    currentStep: null,
    copiaOperacao: []
};

const operacao = (state = INITIAL_STATE, action: OperacaoAction) => {

    switch (action.idStore) {
        case OperacaoStore.ID_STORE:
            switch (action.type) {

                case OperacaoTypes.CLEAN:
                    return { ...INITIAL_STATE }

                case OperacaoTypes.CLEAN_INVALIDO:
                    return { ...state, invalido: INITIAL_STATE.invalido }

                case OperacaoTypes.CLEAN_SEARCH:
                    return { ...state, pesquisa: INITIAL_STATE.pesquisa, operacoes: INITIAL_STATE.operacoes }

                case OperacaoTypes.CLEAN_OPERACOES:
                    return { ...state, operacoes: INITIAL_STATE.operacoes }

                case OperacaoTypes.NEW:
                    return { ...state, operacao: INITIAL_STATE.operacao , invalido: INITIAL_STATE.invalido}

                case OperacaoTypes.PESQUISA:
                    return { ...state, pesquisa: action.pesquisa }

                case OperacaoTypes.OPERACAO:
                    return { ...state, operacao: action.operacao }

                case OperacaoTypes.COPIA_OPERACAO:
                    return { ...state, copiaOperacao: action.operacao }

                case OperacaoTypes.INVALIDO:
                    return { ...state, invalido: action.invalido }

                case OperacaoTypes.OPERACOES:
                    return { ...state, operacoes: action.operacoes }

                case OperacaoTypes.CARACTERISTICAS:
                    return { ...state, caracteristica: action.caracteristica }

                case OperacaoTypes.SCREEN:
                    return { ...state, screen: action.screen }

                case OperacaoTypes.INICIO_CADASTRO:
                    return { ...state, inicioCadastro: action.inicioCadastro }

                case OperacaoTypes.VER_CADASTRO:
                    return { ...state, verCadastro: action.verCadastro }

                case OperacaoTypes.WIZARD_STEP:
                    return { ...state, currentStep: action.currentStep }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }

}

export default operacao;