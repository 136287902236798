import React, { useState, useEffect } from "react";
import classNames from "classnames";

import {
    Button,
    ButtonGroup,
} from "reactstrap";

import * as actions from '../../../store/reducers/Cadastro/Operacao/actions'
import { shallowEqual, useSelector } from "react-redux";
import AppState from "../../../store/storeType";
import ArrowTooltip from '../../../components/Tooltip/ArrowTooltip';

interface Tab {
    tabName: String,
    tabScreen: JSX.Element
    ArrowTooltipTitle: String
}

interface Props {
    tabs: Tab[]
}

const TabsContent = function (props: Props) {

    const screen = useSelector<AppState, Number>(state => state.operacao.screen, shallowEqual);


    useEffect(() => {
        if (screen === 0) {
            actions.New()
            actions.setVerCadastro(false)
        }

    }, [screen])

    return (
        <>
            <ButtonGroup style={{ width: '100%' }}>
                {props.tabs && props.tabs.map((item: Tab, index: number) => {
                    return (
                        <><ArrowTooltip title={item.ArrowTooltipTitle} >
                            <Button
                                color={screen === index ? "info" : ""}
                                type="button"
                                data-toggle="tab"
                                className={classNames("btn-gp-padrao", screen === index ? "active" : "")}
                                onClick={() => { actions.setScreen(index) }}
                                style={{ width: '50%' }}
                            >
                                {item.tabName}
                            </Button>
                        </ArrowTooltip>
                        </>
                    )
                })}
            </ButtonGroup>
            {
                //@ts-ignore
                props.tabs[screen].tabScreen}
        </>
    );
}

export default TabsContent;