//State da tela de Produtos descontinuados
var INITIAL_STATE = {
    produtosSelecionados:{
    numeroProduto: '',
    codBarra: '',
    descricao: '',
    ncm: '',
    codProduto:'',
},
    ncmPesquisa:{
        numeroProduto: '',
        codBarra: '',
        descricao: '',
        ncm: '',
        codProduto:'',
        dataDescontinuado:'',
    },
    produtoSelecionado: [],
    ncms: [],
    descontinuados: [],
    ncmUpdate: []
};

export default function produtosDescontinuados(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'PRODUTOS_DESCONTINUADOS':
            switch (action.type) {
                case 'PRODUTO_DESCONTINUADO':
                    return { ...state, descontinuados:action.value }
                case 'PRODUTO_SELECIONADO':
                    return { ...state, produtoSelecionado: action.value }
                case 'NCMS':
                    return { ...state, ncms: action.value }
                case 'NCM_PESQUISA':
                    return { ...state,  ncmPesquisa: { ...state.ncmPesquisa, [action.campo]: action.value } }
                case 'NCM_UPDATE':
                    return { ...state,  ncmUpdate: action.value  }
                    case 'CLEAN_DESCONTINUADO':
                    return {
                        ...state,
                        ncms: [],
                        descontinuados:[],
                        produtoSelecionado:[]

                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}