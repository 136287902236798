import React, { useEffect } from 'react';
import { Input as InputStrap } from "reactstrap";
import { InputNumber as InputNumberERN } from '../../../../components/ErnComponets/ErnComponets';
import { InputCpfCnpj as InputCpfCnpjERN } from '../../../../components/ErnComponets/ErnComponets';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import ArrowTooltip from '../../../../components/Tooltip/ArrowTooltip';
import AppState from '../../../../store/storeType';
import { CabecaSaida } from '../../../../store/reducers/Movimentacao/SaidaDiversa/types';
import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector';
import {
    Row,
    Col,
    Button,
} from "reactstrap";
import store from '../../../../store/stores';
import useResize from '../../../../Util/Hooks/useResize';
import { alertar } from '../../../../Util/alertar';
import * as action from '../../../../store/reducers/Movimentacao/SaidaDiversa/actions';
import * as actionFaturamento from '../../../../store/reducers/Modals/Faturamento/actions'

export function Info(props: any) {
    return (
        <div
            {...props}
            style={{ fontWeight: 600, marginBottom: 5, color: '#8F8F8F', fontSize: 15, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', ...props.style }}
        >
            {props.children}
        </div>
    )
}

export function Input(props: any) {
    return (
        <InputStrap
            {...props}
            style={{ ...props.style }}
        />
    )
}

export function InputNumber(props: any) {
    return (
        <InputNumberERN
            {...props}
            style={{ ...props.style }}
            onChange={(event: React.FormEvent<HTMLInputElement>) => { props.onChange && props.onChange(event) }}
        />
    )
}

export function InputCpfCnpj(props: any) {
    return (
        <InputCpfCnpjERN
            {...props}
            style={{ ...props.style }}
            onChange={(event: React.FormEvent<HTMLInputElement>) => { props.onChange && props.onChange(event) }}
            value={props.value || ""}
        />
    )
}

interface PropsOperacao {
    onIconPress: Function,
    disabledButton?: boolean
}

export function OperacaoBox({ onIconPress, disabledButton = false }: PropsOperacao) {
    const operacao = useShallowEqualSelector<AppState, CabecaSaida>(state => state.saidaDiversa.saida.cabecaSaida)
    const { width } = useResize();

    return (
        <Row className="padding-padrao-row" style={width <= 1660 ? { width: '100%' } : {}}>
            <Col className="padding-padrao-col" lg={!disabledButton ? 11 : 12}>
                <Row  className="padding-padrao-row">

                    <Col className="padding-padrao-col" md={6}>
                        <label>Indicador de Presença<span className="error"> *</span></label>
                        <Input
                            type="text"
                            value={operacao.descricaoPresenca}
                            autoComplete="off"
                            disabled={true}
                        />
                    </Col>

                    <Col className="padding-padrao-col" md={6}>
                        <label>Operação<span className="error"> *</span></label>
                        <Input
                            type="text"
                            value={operacao.numeroOperacao === 0 ? '' : `${operacao.numeroOperacao} - ${operacao.descricaoOperacao}`}
                            autoComplete="off"
                            disabled={true}
                        />
                    </Col>
                </Row>
            </Col>
            {!disabledButton &&
                <Col className="padding-padrao-col" lg="1">
                    <ArrowTooltip title="Alterar Operação">
                        <Button color="info" onClick={() => onIconPress()} style={{ width: "100%", height: 40, marginTop: 25 }}>
                            <Icon icon="search" className="fa-lg" />
                        </Button>
                    </ArrowTooltip>
                </Col>
            }
        </Row>
    )
}