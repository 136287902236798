var INITIAL_STATE = {
    pesquisa: {
        tipoFor: "",
        codEmpresaFilial: 0,
        nomeFor: "",
        cpfCnpj: "",
        codFor: 0,
        dataInicio: "",
        dataFim: ""
    },
    formaPgto: {
        codForma: 0,
        descricao: '',
        status: 0,
        codTipoPgto: '0',
        flTipo: 0,
        qtdDiasEntreParcelas: 0,
        diasPrazo: 0,
        percentualTarifa: 0,
        flAbreCaixa: 0,
        flRetirada: 0,
        flaVista: 0,
        flBaixaAutomatica: 0,
    },
    cadastro: {
        update: null,
        tipoLancamento: "D",
        codEmpresa: "",
        dadosConta: {
            codTituloMov: null,
            codTitulo: null,
            codConta: '0',
            codGrupoConta: '0',
            codSubgrupoConta: '0',
            descricao: "",
            descricaoGrupo: "",
            descricaoSubGrupo: "",
            conta: "",
            msgInvalido: "",
        },
        metodoPgnt: {
            codForma: '0',
            descricaoFormaPgnt: "",
            descricaoTipo: "",
            dataLancamento: "",
            dataCadastro: "",
            vencimento: "",
            mesReferencia: "",
            proximosVencimentos: "",
            parcela: 0,
            qtdDiasEntreParcelas: 0,
            qtdParcelas: 1,
            valorParcela: '0',
            valorFinal: '0',
            valorPago: '0',
            valorSubTotal: '0',
            flaVista: false,
            codTipoPgto: 0,
            status: 1,
            numeroParcela: "",
            juros: 0,
            multa: 0,
            desconto: 0,
            tarifa: 0,
            restante: 0,
        },
        fornecedor: {
            codEmpresaFilial: 0,
            codFornecedor: 0,
            nomeFilial: "",
            nomeFor: "",
            documento: "",
            tipoDestinatario: "",
        },
        funcionario: {
            codCaixa: 0,
            codFunc: 0,
            nomeFunc: "",
            dataAberturaCaixa: "",
        },
        lancamentos: []
    },
    formulario: {
        codigoEmpresa: 0,
        codigoEmpresaFilial: 0,
        codigoConta: 0,
        codigoGrupoConta: 0,
        codigoSubGrupoConta: 0,
        codigoCaixa: 0,
        codigoCliente: 0,
        codigoFuncionario: 0,
        quantidadeParcelas: 0,
        valorTotalTitulos: 0,
        valorTotalPago: 0,
        descricao: "",
        tipo: "",
        flPago: true,
        imagemDocumento: "",
        tipoDestinatario: "",
        status: 0,
        titulos: []
    },
    codTitulo: 0,
    titulos: [],
    lancamentos: [],
    empresa: [],
    fornecedores: [],
    contas: [],
    grupoContas: [],
    subContas: [],
    formaPgm: [],
    proximoCadastro: false,
    inicioCadastro: false,
};

export default function lancamentoTitulos(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'LANCAMENTO_TITULOS':
            switch (action.type) {
                case 'TIPO_LANCAMENTO':
                    return {...state, cadastro: {...state.cadastro, dadosConta: action.value } }
                case 'TITULOS':
                    return {...state, titulos: action.value }
                case 'LANCAMENTOS':
                    return {...state, lancamentos: action.value }
                case 'CONTAS':
                    return {...state, contas: action.value }
                case 'GRUPO_CONTAS':
                    return {...state, grupoContas: action.value }
                case 'SUB_CONTAS':
                    return {...state, subContas: action.value }
                case 'FORMAS_PGTOS':
                    return {...state, formaPgm: action.value }
                case 'EMPRESA':
                    return {...state, empresa: action.value }
                case 'FORNCEDORES':
                    return {...state, fornecedores: action.value }
                case 'CLEAR_FORNCEDORES':
                    return {...state, fornecedores: [] }
                case 'PROXIMO_STEP':
                    return {...state, proximoCadastro: action.value }
                case 'CADASTRO':
                    return {...state, cadastro: {...state.cadastro, [action.campo]: action.value } }
                case 'PESQUISA':
                    return {...state, pesquisa: action.value }
                case 'PESQUISA_CLIENTES':
                    return {...state, clientes: action.value }
                case 'DELETE':
                    return {...state, codTitulo: action.value }
                case 'CLEAN_TIPO':
                    return {...state, cadastro: {...state.cadastro, tipoLancamento: "" } }
                case 'CLEAN_PESQUISA':
                    return {
                        ...state,
                        pesquisa: {
                            ...state.pesquisa,
                            tipoLancamento: "D",
                            nomeFor: "",
                            cpfCnpj: "",
                            codFor: 0,
                            dataInicio: "",
                            dataFim: "",
                            tipoDestinatario: ""
                        },
                        titulos: []
                    }
                case 'CLEAN_FORNECEDOR':
                    return {
                        ...state,
                        cadastro: {
                            ...state.cadastro,
                            codFornecedor: "",
                            documento: "",
                            nomeFor: ""
                        },
                        fornecedores: []
                    }
                case 'CLEAN_CADASTRO':
                    return {
                        ...state,
                        cadastro: {
                            ...state.cadastro,
                            update: null,
                            tipoLancamento: "D",
                            codEmpresa: "",
                            dadosConta: {
                                codTituloMov: null,
                                codTitulo: null,
                                codConta: '0',
                                codGrupoConta: '0',
                                codSubgrupoConta: '0',
                                descricao: "",
                                descricaoGrupo: "",
                                descricaoSubGrupo: "",
                                conta: "",
                            },
                            metodoPgnt: {
                                codForma: '0',
                                descricaoFormaPgnt: "",
                                descricaoTipo: "",
                                dataLancamento: "",
                                dataCadastro: "",
                                vencimento: "",
                                mesReferencia: "",
                                proximosVencimentos: "",
                                parcela: 0,
                                qtdDiasEntreParcelas: 0,
                                qtdParcelas: 1,
                                valorParcela: '0',
                                valorFinal: '0',
                                valorPago: '0',
                                valorSubTotal: '0',
                                flaVista: false,
                                codTipoPgto: 0,
                                status: 2,
                                numeroParcela: "",
                                juros: 0,
                                multa: 0,
                                desconto: 0,
                                tarifa: 0,
                                restante: 0,
                            },
                            fornecedor: {
                                codEmpresaFilial: 0,
                                codFornecedor: 0,
                                nomeFilial: "",
                                nomeFor: "",
                                documento: "",
                                tipoDestinatario: "",
                            },
                            funcionario: {
                                dataAberturaCaixa: "",
                                codFunc: state.cadastro.funcionario.codFunc
                            },
                            lancamentos: []
                        }
                    }
                case 'CLEAN_FORMULARIO':
                    return {
                        ...state,
                        formulario: {
                            ...state.formulario,
                            codigoEmpresa: 0,
                            codigoEmpresaFilial: 0,
                            codigoConta: 0,
                            codigoGrupoConta: 0,
                            codigoSubGrupoConta: 0,
                            codigoCaixa: 0,
                            codigoCliente: 0,
                            quantidadeParcelas: 0,
                            valorTotalTitulos: 0,
                            valorTotalPago: 0,
                            descricao: "",
                            tipo: "",
                            flPago: true,
                            imagemDocumento: "",
                            status: 0,
                            titulos: []
                        }
                    }
                case 'FORMULARIO':
                    return {
                        ...state,
                        formulario: {
                            ...state.formulario,
                            codigoTitulo: state.cadastro.dadosConta.codTitulo,
                            codigoTituloMov: state.cadastro.dadosConta.codTituloMov,
                            codigoEmpresa: state.cadastro.codEmpresa,
                            codigoEmpresaFilial: state.cadastro.fornecedor.codEmpresaFilial,
                            codigoConta: Number(state.cadastro.dadosConta.codConta),
                            codigoGrupoConta: state.cadastro.dadosConta.codGrupoConta,
                            codigoSubGrupoConta: state.cadastro.dadosConta.codSubgrupoConta,
                            codigoCaixa: state.cadastro.funcionario.codCaixa,
                            codigoCliente: state.cadastro.fornecedor.codFornecedor,
                            codigoFuncionario: state.cadastro.funcionario.codFunc,
                            quantidadeParcelas: Number(state.cadastro.metodoPgnt.qtdParcelas),
                            valorTotalTitulos: parseFloat(state.cadastro.metodoPgnt.valorFinal),
                            valorTotalPago: state.cadastro.metodoPgnt.valorPago,
                            descricao: state.cadastro.dadosConta.descricao,
                            tipo: state.cadastro.tipoLancamento,
                            tipoDestinatario: state.cadastro.fornecedor.tipoDestinatario,
                            imagemDocumento: state.cadastro.fornecedor.documento,
                            status: state.cadastro.metodoPgnt.status,
                            titulos: state.cadastro.lancamentos
                        }
                    }

                case 'DADOS_CONTA':
                    return {...state, cadastro: {...state.cadastro, dadosConta: action.value } }
                case 'CADASTRO_METODO':
                    return {...state, cadastro: {...state.cadastro, metodoPgnt: action.value } }
                case 'CADASTRO_DATA':
                    return {...state, cadastro: {...state.cadastro, metodoPgnt: {...state.cadastro.metodoPgnt, [action.campo]: action.value } } }
                case 'CADASTRO_FORNECEDOR':
                    return {...state, cadastro: {...state.cadastro, fornecedor: action.value } }
                case 'CADASTRO_FUNCIONARIO':
                    return {...state, cadastro: {...state.cadastro, funcionario: action.value } }
                case 'DADOS_UPDATE':
                    return {...state, update: {...state.update, dadosConta: action.value } }


                default:
                    return {...state }
            }
        default:
            return {...state }
    }
}