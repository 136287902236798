import api from "../../Util/api.js";
import axios from "axios";
import store from "../../store/stores";
import { SweetAlert } from "../../Util/sweetAlert"
import { Item, Cliente, Operacao, IPesquisa, IPedido, IItensGradePDV } from "../../store/reducers/Movimentacao/PedidoVenda/types";
import * as actions from "../../store/reducers/Movimentacao/PedidoVenda/actions"
import * as actionsFaturamento from '../../store/reducers/Modals/Faturamento/actions'
import { maskMoeda } from "../../Util/mascaras.js";
import { removeMaskMoney } from "../../Util/removeMascaras";
import { alertar } from "../../Util/alertar.js";
import moment from "moment";

export async function GetProdutos(pesquisa: any): Promise<Item[]> {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        return await axios.get(api.urlCadastro + `api/v1/produto/produtos?numProduto=${pesquisa.numProduto}&nome=${pesquisa.nome}&tipo=P&codSubgrupo=${pesquisa.codSubgrupo}&ativos=1`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            return response.data.records
        }).catch(e => {
            return []
        })
    } catch (e) {
        return []
    }
}

export async function GetProduto(codProduto: number): Promise<Item | null> {
    //@ts-ignore
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        return await axios.get(api.urlCadastro + `api/v1/produto/produto?codProduto=${codProduto}&flItemVenda=1&status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            return response.data.records
        }).catch(e => {
            SweetAlert('', e.response ? e.response.data.message : 'Falha na conexão', 'error', false)
            return null
        })
    } catch (e) {
        SweetAlert('Ops...', "Não foi possível obter o produto", 'error', false)
        return null
    }
}
export async function GetClientes(cpfCnpj: string, nomeRazao: string): Promise<Cliente[]> {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        //@ts-ignore
        return await axios.get(api.urlCadastro + `api/v1/cliente/clientes?cpfCnpj=${cpfCnpj}&nomeRazao=${nomeRazao}&status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            actions.setClientes(response.data.records)
        }).catch(e => {
            return []
        })
    } catch (e) {
        return []
    }
}

export async function GetCliente(codCli: number): Promise<Cliente | null> {
    //@ts-ignore
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        //@ts-ignore
        return await axios.get(api.urlCadastro + `api/v1/cliente/cliente?codCli=${codCli}&status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            actions.setCliente(response.data.records)
        }).catch(e => {
            SweetAlert('', e.response ? e.response.data.message : 'Falha na conexão', 'error', false)
            return null
        })
    } catch (e) {
        SweetAlert('Ops...', "Não foi possível obter o cliente", 'error', false)
        return null
    }
}

export async function GetClienteDefault() {
    //@ts-ignore
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        //@ts-ignore
        return await axios.get(api.urlCadastro + `api/v1/cliente/clienteDefault`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            const data = { nomeRazao: response.data.records.nome }
            //@ts-ignore
            actions.setCliente(data)
        }).catch(e => {
            SweetAlert('', e.response ? e.response.data.message : 'Falha na conexão', 'error', false)
            return null
        })
    } catch (e) {
        SweetAlert('Ops...', "Não foi possível obter o cliente", 'error', false)
        return null
    }
}
export async function GetVendedorDefault() {
    //@ts-ignore
    await GetFuncionario()
    const { pedidoVenda: { informacoes: { usuario } } } = store.getState()

    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        //@ts-ignore
        return await axios.get(api.urlCadastro + `api/v1/funcionario/vendedorDefault?codFunc=${usuario.codUser}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            const data = {
                codFunc: response.data.records.codFunc,
                nomeVendedor: response.data.records.nomeFunc,
                cpf: response.data.records.cpf,
                funcao: response.data.records.funcao,
            }
            //@ts-ignore
            actions.setInformacoes('vendedor', data)
        }).catch(e => {
            SweetAlert('', e.response ? e.response.data.message : 'Falha na conexão', 'error', false)
            return null
        })
    } catch (e) {
        SweetAlert('', "Não foi possível obter o cliente", 'error', false)
        return null
    }
}


export async function GetVendedores(nome: string, cpf: string): Promise<Operacao[]> {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        //@ts-ignore
        return await axios.get(api.urlCadastro + `api/v1/funcionario/funcionariosVendedor?Nome=${nome}&Cpf=${cpf}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            actions.setVendedores(response.data.records)
        }).catch(e => {
            return []
        })
    } catch (e) {
        return []
    }
}
export async function GetOperacoes(numero: string, descricao: string): Promise<Operacao[]> {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        //@ts-ignore
        return await axios.get(api.urlCadastro + `api/v1/operacao/operacoes?Numero=${numero}&Descricao=${descricao}&TipoOperacao=${'VND'}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            actions.setOperacoes(response.data.records)
        }).catch(e => {
            return []
        })
    } catch (e) {
        return []
    }
}

export async function GetOperacao(codigo: number): Promise<Cliente | null> {
    //@ts-ignore
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        //@ts-ignore
        return await axios.get(api.urlCadastro + `api/v1/operacao/operacao?codigo=${codigo}&status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            actions.setOperacao(response.data.records)
        }).catch(e => {
            SweetAlert('', e.response ? e.response.data.message : 'Falha na conexão', 'error', false)
            return null
        })
    } catch (e) {
        SweetAlert('Ops...', "Não foi possível obter o cliente", 'error', false)
        return null
    }
}

export async function getCaixaAberto() {
    //@ts-ignore
    var funcionario: any
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/funcionario/funcionario`, {
            headers: { Authorization: basicAuth }
        }).then(response => {

            funcionario = response.data.records.codFunc

            try {
                const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

                axios.get(api.urlCadastro + `api/v1/caixa/caixaAberto?codFuncionario=${funcionario}`, {
                    headers: { Authorization: basicAuth }
                }).then(response => {
                    //@ts-ignore
                    actions.setInformacoes('codCaixa', response.data.records.codCaixa)
                    return (response.data.records.codCaixa)
                }).catch(error => {
                    if (error.response)
                        SweetAlert('', error.response.data.message, 'error', false)
                    else
                        SweetAlert('', "Não foi possivel obter o Caixa", 'error', false)
                })
            } catch (e) {
                SweetAlert('Ops...', "Não foi possivel obter o Caixa", 'error', false)
            }

        }).catch(() => {
            SweetAlert('', "Não foi possivel o Funcionario", 'error', false)
        })
    } catch (e) {
        SweetAlert('Ops...', "Não foi possivel obter o Funcionario", 'error', false)
    }
}

export async function GetFuncionario() {
    const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
    await axios.get(api.urlCadastro + `api/v1/funcionario/funcionario`, {
        headers: { Authorization: basicAuth }
    }).then(response => {
        actions.setInformacoes('usuario', { codUser: response.data.records.codFunc, descricao: '' })
    }).catch(() => {
        SweetAlert('Ops...', "Usuário não possui funcionário vinculado", 'error', false)
    })

}

export function CreateCaixa() {
    //@ts-ignore
    const { pedidoVenda: { informacoes: { usuario, codCaixa } } } = store.getState();

    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        axios.post(api.urlCadastro + `api/v1/caixa/create`, {
            codCaixa: codCaixa,
            codFuncionario: Number(usuario.codUser),
            dataAbertura: new Date().toISOString().substr(0, 10),
            dataFechamento: '',
            valorTotalLancado: 0.001,
            lancamentos: [{
                dataLancamento: new Date().toISOString().substr(0, 10),
                codFormaPagamento: 1,
                valor: 0.00
            }]
        }, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            actions.setInformacoes('codCaixa', response.data.records.codCaixa)
            SweetAlert('', "Caixa aberto com sucesso", 'success', false)
        }).catch(error => {
            if (error.response)
                SweetAlert('', error.response.data.message, 'error', false)
            else
                SweetAlert('', "Não foi possivel obter os Funcionários", 'error', false)
        })
    } catch (e) {
        SweetAlert('Ops...', "Não foi abrir caixa", 'error', false)
    }
}

export async function SalvarPDV(toogleAbrirCaixa?: any) {
    //@ts-ignore
    const { pedidoVenda: { totais, itens, cliente, operacao, informacoes: { codCaixa, vendedor } } } = store.getState()
    if (itens.length === 0) {
        SweetAlert('', "Não é possível salvar um Pedido de Venda sem produtos", 'error', false);
        return false
    }
    //@ts-ignore
    if (totais.subtotal == '0,00') {
        SweetAlert('', "Valor do(s) produto(s) zerado", 'error', false);
        return false
    }
    if (!cliente.codCli) {
        SweetAlert('', "Por favor informe o cliente", 'error', false);
        return false
    }
    //@ts-ignore
    if (totais.subtotal == '0,00') {
        SweetAlert('', "Valor do(s) produto(s) zerado", 'error', false);
        return false
    }
    if (!codCaixa) {
        toogleAbrirCaixa ? toogleAbrirCaixa() : SweetAlert('', "Por favor abra um caixa antes de Faturar", 'error', false);
        return false
    }
    if (!operacao.codigo) {
        SweetAlert('', "Por favor informe a operação", 'error', false);
        return false
    }
    let itensString = JSON.stringify(itens);
    let itensJSON = JSON.parse(itensString)
    //@ts-ignore
    let arrItens = []
    //@ts-ignore
    itensJSON.forEach((item) => {
        item.precoVendaAtual = maskMoeda(item.precoVendaAtual)
        arrItens.push({
            codigoProduto: item.codProduto,
            quantidade: item.quantidade,
            valorItem: item.precoVendaAtual.replace('.', '').replace(',', '.'),
            valorSubTotalItem: item.subtotal.replace('.', '').replace(',', '.'),
            valorDescontoItem: item.descontoReais,
            percentualDesconto: item.descontoPercent,
            valorTotalItem: item.total.replace('.', '').replace(',', '.'),
            grade: item.grade && item.grade.length > 0 ? item.grade : []
        })
    });
    //@ts-ignore
    let validGrade = await arrItens.filter(item => item.grade.length > 0 && item)
    validGrade = validGrade.map(item => item.grade.filter((i: any) => i.quantidade !== Number(item.quantidade) && i))
    validGrade = validGrade.filter(i => i.length > 0 && i)
    //@ts-ignore
    if (validGrade.length > 0) {
        SweetAlert('', "Quantidade informada não conferem", 'error', false)
        return false
    }
    let objPDV = {
        //@ts-ignore
        codigoFuncionario: Number(vendedor.codFunc),
        codigoDestinatario: cliente.codCli,
        tipoDestinatario: 'CLI',
        codigoOperacao: operacao.codigo,
        valorSubTotal: totais.subtotal.toString().replace('.', '').replace(',', '.'),
        valorDesconto: totais.desconto.toString().replace('.', '').replace(',', '.'),
        valorTotal: totais.total.toString().replace('.', '').replace(',', '.'),
        tipoMovimentacao: 2,
        tipoRecebimento: 'R',
        presenca: 9,
        codigoCaixa: codCaixa,
        //@ts-ignore
        itens: arrItens,
        modalidadeFrete: 9,
    }
    try {
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        await axios.post(api.urlMovimento + `api/v1/Movimento`, objPDV, {
            headers: { Authorization: bearerAuth }
        }).then(response => {
            //@ts-ignore
            actions.setInformacoes('numPedido', response.data.data.numeroPedido)
            //@ts-ignore
            actions.setPedido({ numPedido: response.data.data.numeroPedido, status: 'ABERTO' })
            SweetAlert('', "Pedido Salvo com sucesso", 'success', false)
            return true
        }).catch(e => {
            SweetAlert('', e.response ? e.response.data.message : 'Falha na conexão', 'error', false)
            return false;
        });
    } catch (error) {
        SweetAlert('', "Não foi possível salvar o Pedido", 'error', false)
        return 'success'
    }
}


export async function UpdatePDV(numPedido: number) {
    const { pedidoVenda: { totais, itens, informacoes, operacao, cliente } } = store.getState()
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
    const response = await axios.get(api.urlMovimento + `api/v1/Movimento/${numPedido}`, {
        headers: { Authorization: bearerAuth }
    })

    let objTransportadora = {
        codigoTransportadora: response.data.data.transportadora.codTransportadora,
        placa: response.data.data.transportadora.placaVeiculo,
        placaUf: response.data.data.transportadora.uf,
        valorFrete: response.data.data.transportadora.valorFrete,
        valorSeguro: 0,
        quantidade: response.data.data.transportadora.quantidadeVolume,
        volume: 0,
        pesoLiquido: response.data.data.transportadora.pesoLiquido,
        pesoBruto: response.data.data.transportadora.pesoBruto,
        especie: response.data.data.transportadora.especie,
        marca: response.data.data.transportadora.marca
    }

    let objPDVUpdate = {
        numeroPedido: numPedido,
        codigoDestinatario: Number(cliente.codCli),
        tipoDestinatario: response.data.data.destinatario.tipoDestinatario,
        codigoFuncionario: informacoes.vendedor.codFunc,
        codigoOperacao: operacao.codigo,
        codigoCaixa: informacoes.codCaixa,
        valorDesconto: Number(removeMaskMoney(totais.desconto)) === 0 ? Number(removeMaskMoney(totais.desconto)).toFixed(2) : removeMaskMoney(totais.desconto),
        valorSubTotal: Number(removeMaskMoney(totais.subtotal)),
        valorTotal: Number(removeMaskMoney(totais.total)),
        tipoMovimentacao: 1,
        tipoRecebimento: 'R',
        itens: itens.map(i => ({
            codigoProduto: i.codProduto,
            quantidade: i.quantidade,
            valorItem: Number(removeMaskMoney(i.precoVendaAtual)),
            valorSubTotalItem: Number(removeMaskMoney(i.subtotal)),
            valorDescontoItem: i.descontoReais === 0 ? Number(removeMaskMoney(i.descontoReais)).toFixed(2) : i.descontoReais.toString().replace(',', '.'),
            percentualDesconto: i.descontoPercent,
            valorTotalItem: Number(removeMaskMoney(i.total)),
            //@ts-ignore
            grade: i.grade ? i.grade : []
        })),
        transportadora: response.data.data.transportadora.modalidade !== 9 ? objTransportadora : null,
        modalidadeFrete: response.data.data.transportadora.modalidade,
        presenca: (response.data.data.presenca).toString(),
        informacoesComplementares: response.data.data.dadosAdicionais,
    }

    //@ts-ignore
    let validGrade = await itens.filter(item => item.grade && item.grade.length > 0 && item)
    //@ts-ignore
    validGrade = validGrade.map(item => item.grade.filter((i: any) => i.quantidade !== Number(item.quantidade) && i))
    //@ts-ignore
    validGrade = validGrade.filter(i => i.length > 0 && i)
    //@ts-ignore
    if (validGrade.length > 0) {
        SweetAlert('', "Quantidade informada não conferem", 'error', false)
        return false
    }

    if (response.data.data.valorDesconto !== Number(removeMaskMoney(totais.desconto)) ||
        response.data.data.valorSubTotal !== Number(removeMaskMoney(totais.subtotal)) ||
        response.data.data.valorTotal !== Number(removeMaskMoney(totais.total)) ||
        response.data.data.destinatario.codigo !== Number(cliente.codCli) ||
        response.data.data.operacao.codigoOperacao !== operacao.codigo ||
        response.data.data.codigoFuncionario !== informacoes.vendedor.codFunc) {
        await axios.put(api.urlMovimento + `api/v1/Movimento/${numPedido}`,
            objPDVUpdate
            , {
                headers: { Authorization: bearerAuth }
            })
        SweetAlert('', "Pedido Atualizado com sucesso", 'success', false)
        return true
    } else {
        return 'msgSalvarPDV'
    }
}


export async function FinalizarPedido(numPedido: number, toggleFaturar: any, toogleAbrirCaixa?: any) {
    //@ts-ignore
    const { pedidoVenda: { totais, informacoes, operacao } } = store.getState()
    if (numPedido === 0) {
        const salvarPDV = await SalvarPDV(toogleAbrirCaixa)
        if (salvarPDV === false) {
            return false
        }
    } else {
        const updatePDV = await UpdatePDV(numPedido)
        if (updatePDV === false) {
            return false
        }
    }
    //@ts-ignore
    actionsFaturamento.setTotais({ subTotal: (totais.subtotal), descontos: (totais.desconto), total: (totais.total), troco: ("0,00"), totalRestante: ("0,00") })
    //@ts-ignore
    actionsFaturamento.setInformacoes({ numeroPedido: informacoes.numPedido, codigoConta: 0, codigoGrupoConta: 0 })

    //@ts-ignore
    if (operacao.conta === 1) {
        toggleFaturar()
    } else {
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        //@ts-ignore
        const { pedidoVenda: { informacoes }, notificacao } = store.getState()
        await axios.put(api.urlMovimento + `api/v1/Movimento/finalizarPedidoVendas/${informacoes.numPedido}`, {}, {
            headers: { Authorization: bearerAuth }
        }).then(response => {
            alertar(notificacao, 'br', 'Pedido finalizado com sucesso', 'success', 'icon-alert-circle-exc', 4)
            store.dispatch({ idStore: 'PEDIDO_VENDA', type: "CLEAN" })
            return
        })
    }

}

export async function getProdutoGrade(codProduto: number) {
    const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

    await axios.get(api.urlCadastro + `api/v1/produto/grades?codProduto=${codProduto}&status=0`, {
        headers: { Authorization: basicAuth }
    }).then(async response => {
        let data = response.data.records
        const dataGrade = data.grades.map((grade: any[]) => ({
            ...grade,
            estoqueAtualizar: 0
        }))
        data.grades = dataGrade
        await actions.setItensGradePDV(data)
    })
}

export async function getPedidos(pesquisa: IPesquisa) {
    const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    await axios.get(api.urlCadastro + `api/v1/destinario/destinarios?cpfCnpj=${pesquisa.cpfCnpj}`, {
        headers: { Authorization: basicAuth }
    }).then(async response => {
        if (response.data.records.length === 0) {
            SweetAlert('', "Nenhum cliente encontrado", 'error', false)
            return
        }
        const data = response.data.records.length > 1 ? { codigo: '', nomeRazao: '', tipo: '' } : response.data.records[0]
        try {
            await axios.get(api.urlMovimento + `api/v1/Movimento?DataInicio=${pesquisa.dateOrderInitial}&DataFim=${pesquisa.dateOrderFinally}&CodigoDestinatario=${data.codigo}&NomeDestinatario=${data.nomeRazao}&TipoDestinatario=${data.tipo}&Status=${pesquisa.status}&NumeroNotaFiscal=${pesquisa.docFiscal}&NumeroPedido=${pesquisa.numPedido}&CodigoEmpresaFilial=${pesquisa.empresa}&TipoOperacao=VND&Tamanho=${pesquisa.pageSize}&Pagina=${pesquisa.page}`, {
                headers: { Authorization: bearerAuth }
            }).then(response => {
                actions.setPagination({ totalPage: response.data.data.totalPaginas })
                actions.setPedidos(response.data.data.responsePedidos)
            })
        } catch (e) {
            SweetAlert('', e.response ? 'Nenhum Pedido encontrado' : 'Falha na conexão', 'error', false)
        }
    })
}

export async function getPedido(numeroPedido: number, nomeFuncionario?: any) {
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
    await axios.get(api.urlMovimento + `api/v1/Movimento/${numeroPedido}`, {
        headers: { Authorization: bearerAuth }
    }).then(async response => {
        const { produtos, operacao, destinatario, numeroPedido, codigoFuncionario } = response.data.data
        await actions.setItens(produtos.map((p: any) => ({
            ...p,
            nome: p.descricao,
            precoVendaAtual: maskMoeda(p.valorUnitario.toFixed(2)),
            subtotal: maskMoeda(p.valorSubTotal.toFixed(2)),
            descontoPercent: ((Number(p.valorDesconto) * 100) / Number(p.valorSubTotal)).toFixed(2),
            descontoReais: maskMoeda(p.valorDesconto.toFixed(2)),
            numProduto: p.numero.toString(),
            total: maskMoeda(p.valorTotal.toFixed(2)),
            grades: p.grades.length > 0 ? p.grades : []
        })))
        await GetOperacao(operacao.codigoOperacao)
        //@ts-ignore
        await actions.setInformacoes('vendedor', { codFunc: codigoFuncionario, nomeVendedor: nomeFuncionario })
        //@ts-ignore
        await actions.setCliente({ codCli: destinatario.codigo, cpfCnpj: destinatario.documento, nomeRazao: destinatario.nomeRazao })
        await actions.setInformacoes('numPedido', numeroPedido)
    })
}

export async function getDetalhesPedido(numeroPedido: number) {
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
    await axios.get(api.urlMovimento + `api/v1/Movimento/${numeroPedido}`, {
        headers: { Authorization: bearerAuth }
    }).then(response => {
        const { produtos } = response.data.data
        actions.setDetalhesPedidoItens(produtos)
    })
}

export async function getDetalhesPedidoPagamento(numeroPedido: number) {
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
    await axios.get(api.urlFinanceiro + `api/v1/titulos/retornartitulosbypedido/${numeroPedido}`, {
        headers: { Authorization: bearerAuth }
    }).then(response => {
        actions.setDetalhesPedidoPagemento(response.data)
    })
}

export async function segurancaAcao(toogleSegurancaAcao: any, toogleAcao: any) {
    //@ts-ignore
    const { pedidoVenda: { segurancaPedidoCredenciais } } = store.getState()
    const data = {
        login: segurancaPedidoCredenciais.usuario,
        senha: segurancaPedidoCredenciais.senha
    }
    const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
    await axios.get(api.urlCadastro + 'api/v1/usuario/usuario?login=' + segurancaPedidoCredenciais.usuario, {
        headers: { Authorization: basicAuth }
    }).then(async response => {
        await axios.post(api.urlCadastro + `api/v1/usuario/login`, data, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (segurancaPedidoCredenciais.motivo === '') {
                SweetAlert('', 'Favor informe o MOTIVO da REABERTURA do Pedido', 'error', false)
                return
            } else {
                toogleAcao()
                toogleSegurancaAcao()
            }
        }).catch(error => {
            SweetAlert('', error.response ? error.response.data.message : 'Falha na conexão', 'error', false)
        })
    }).catch(error => {
        SweetAlert('', "Usuário não encontrado", 'error', false)
    })
}

export async function ReAbrirPedido() {
    const { pedidoVenda: { segurancaPedidoCredenciais } } = store.getState()
    //@ts-ignore
    const { pedidoVenda: { pedido } } = store.getState()
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
    await axios.put(api.urlMovimento + `api/v1/Movimento/reabrirMovimentacao/${Number(pedido.numPedido)}`, {
        motivo: segurancaPedidoCredenciais.motivo
    }, {
        headers: { Authorization: bearerAuth }
    }).then(response => {
        //@ts-ignore
        actions.setPedido({...pedido, status: 'ABERTO' })
        actions.cleanSegurancaPedidoCredenciais()
        SweetAlert('', "Pedido Re-aberto com sucesso", 'success', false)
    }).catch(response => {
        SweetAlert('', "Houve uma falha ao Re-abrir o pedido", 'error', false)
    })
}

export async function ExcluirPedido() {
    const { pedidoVenda: { segurancaPedidoCredenciais } } = store.getState()
    //@ts-ignore
    const { pedidoVenda: { pedido } } = store.getState()
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
    await axios.put(api.urlMovimento + `api/v1/Movimento/excluirMovimentacao/${Number(pedido.numPedido)}`, {
        motivo: segurancaPedidoCredenciais.motivo
    }, {
        headers: { Authorization: bearerAuth }
    }).then(async response => {
        actions.cleanPedido()
        await GetClienteDefault()
        actions.cleanSegurancaPedidoCredenciais()
        SweetAlert('', "Pedido Excluido com sucesso", 'success', false)
    }).catch(response => {
        SweetAlert('', "Houve uma falha ao Excluir o pedido", 'error', false)
    })
}

export async function enviarPedido() {
    const { pedidoVenda: { informacaoEnvioPedido, informacoes: {numPedido} } } = store.getState()
    let objEmpresa = localStorage.getItem('USUARIO')
    //@ts-ignore
    objEmpresa = JSON.parse(objEmpresa)
    //@ts-ignore
    const descEmpresa = objEmpresa.empresa.descEmpresa

    const fone = informacaoEnvioPedido.whatsapp.telefone
    const message = encodeURIComponent(`Olá, 
                                            \n Segue o PDF do pedido ${numPedido} no dia ${moment(new Date()).format('DD/MM/YYYY')} 
                                            \n Atenciosamente,
                                            \n ERnetwork - Soluções em T.I. - ${descEmpresa} `)

    if (fone !== '') {
        window.open(`https://wa.me/55${fone}?text=${message}`)
        actions.cleanInformacaoEnvioPedido()
    }

}

export async function getDetalhesFormaPagamento(codigoMovTitulo: number) {
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
    await axios.get(api.urlFinanceiro + `api/v1/titulos/buscaTitulos/${codigoMovTitulo}`, {
        headers: { Authorization: bearerAuth }
    }).then(async response => {
        const { lancamentos } = response.data
        actions.setDetalhesFormaPagamento(lancamentos)
    }).catch(response => {
        SweetAlert('', "Houve uma falha ao buscar os lançamentos", 'error', false)
    })
}

export async function getRetiradasCaixa() {
    const { pedidoVenda: { informacoes: { codCaixa } } } = store.getState();
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    if (!codCaixa) {
        return SweetAlert('', "Não possui nenhum caixa aberto", 'error', false)
    }

    await axios.get(api.urlFinanceiro + `api/v1/cxacaixalancamento/retornarCaixaLancamentosByIdCaixa/${codCaixa}`, {
        headers: { Authorization: bearerAuth }
    }).then(response => {
        actions.setRetiradasCaixa(response.data)
    }).catch(err => {
        return SweetAlert('', "Houve uma falha ao buscar as retiradas do Caixa", 'error', false)
    })
}

export async function salvarRetiradasCaixa() {
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
    const { pedidoVenda: { informacoes: { codCaixa }, retiradaCaixa } } = store.getState();

    if (!codCaixa) {
        return SweetAlert('', "Não possui nenhum caixa aberto", 'error', false)
    }
    if (!retiradaCaixa.autorizador) {
        return SweetAlert('', "Informe o codigo do Autorizador", 'error', false)
    }
    if (!retiradaCaixa.codigoFormaPagamento) {
        return SweetAlert('', "Forma de pagamento não informado", 'error', false)
    }
    if (!retiradaCaixa.valorLancamento) {
        return SweetAlert('', "Valor da Retirada não informado", 'error', false)
    }
    if (!retiradaCaixa.observacao) {
        return SweetAlert('', "Descrição do motivo não informado (observação)", 'error', false)
    }

    const objRetirada = {
        codigoCaixaLancamento: 0,
        codigoCaixa: codCaixa,
        codigoFormaPagamento: retiradaCaixa.codigoFormaPagamento,
        codigoTipoLancamento: retiradaCaixa.codigoTipoLancamento,
        dataLancamento: moment(new Date()).format('YYYY-MM-DD'),
        valorLancamento: Number(removeMaskMoney(retiradaCaixa.valorLancamento)),
        observacao: retiradaCaixa.observacao,
        status: 0
    }

    await axios.post(api.urlFinanceiro + `api/v1/cxacaixalancamento/incluirCxaCaixaLancamentos`, objRetirada, {
        headers: { Authorization: bearerAuth }
    }).then(async response => {
        SweetAlert('', "Valor retirado com sucesso do CAIXA", 'success', false)
        actions.cleanRetiradaCaixa()
        await axios.get(api.urlFinanceiro + `api/v1/cxacaixalancamento/retornarCaixaLancamentosByIdCaixa/${codCaixa}`, {
            headers: { Authorization: bearerAuth }
        }).then(response => {
            actions.setRetiradasCaixa(response.data)
        }).catch(err => {
            return SweetAlert('', "Houve uma falha ao buscar as Retiradas do CAIXA", 'error', false)
        })
    }).catch(err => {
        return SweetAlert('', "Houve uma falha ao Retirar ao CAIXA", 'error', false)
    })

}

export async function excluirRetiradas(codigoCaixaLancamento: number) {
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
    const { pedidoVenda: { retiradasCaixa, informacoes: { codCaixa } } } = store.getState();
    await axios.delete(api.urlFinanceiro + `api/v1/cxacaixalancamento/excluirCxaCaixaLancamentoById/${codigoCaixaLancamento}`, {
        headers: { Authorization: bearerAuth }
    }).then(async response => {
        const newList = retiradasCaixa.filter(retirada => retirada.codigoCaixaLancto !== codigoCaixaLancamento && retirada)
        actions.setRetiradasCaixa(newList)
        SweetAlert('', "Retirada do Caixa Excluido com sucesso", 'success', false)
    }).catch(err => {
        return SweetAlert('', "Houve uma falha ao excluir a Retirada do CAIXA", 'error', false)
    })
}