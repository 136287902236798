import React, { useRef, useEffect, ChangeEvent } from 'react';
import ReactTable from "react-table";

import './style.css'

import {
    Button,
    Col,
    Row,
    Input
} from 'reactstrap';

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import useScrollBar from '../../../Util/Hooks/useScrollBar';
import ArrowTooltip from '../../../components/Tooltip/ArrowTooltip';

import * as action from '../../../store/reducers/Modals/Faturamento/actions';

import useShallowEqualSelector from '../../../Util/Hooks/useShallowEqualSelector';
import AppState from '../../../store/storeType';
import { IFaturamentoState } from '../../../store/reducers/Modals/Faturamento/types';

import * as functions from './functions';
import { maskMoeda } from '../../../Util/mascaras';
import moment from 'moment';
import { removeMaskMoney } from '../../../Util/removeMascaras';

export default function Parcela() {
    const refParcela = useRef<HTMLInputElement>(null);
    const refValor = useRef<HTMLInputElement>(null);

    const lancamento = useShallowEqualSelector<AppState, IFaturamentoState['lancamento']>(state => state.modalFaturamento.lancamento)
    const totais = useShallowEqualSelector<AppState, IFaturamentoState['totais']>(state => state.modalFaturamento.totais)
    const pagamentoSelecionado = useShallowEqualSelector<AppState, IFaturamentoState['pagamentoSelecionado']>(state => state.modalFaturamento.pagamentoSelecionado)

    useEffect(() => {
        autoFocus();
    }, [pagamentoSelecionado])

    useEffect(() => {
        if (pagamentoSelecionado.flaVista === 0) {
            let total = maskMoeda(totais.totalRestante)
            total = removeMaskMoney(total)
            let valor = Number(total) / Number(lancamento.parcela)
            action.setLancamento({ ...lancamento, valor: maskMoeda(valor.toFixed(2)) })
        } else {
            let total = maskMoeda(totais.totalRestante)
            total = removeMaskMoney(total)
            action.setLancamento({ ...lancamento, valor: maskMoeda(Number(total).toFixed(2)) })
        }
    }, [totais.totalRestante, lancamento.parcela, pagamentoSelecionado.flaVista])

    function addLancamento() {
        functions.addItemLancamento();
        autoFocus();
    }

    function autoFocus() {
        if (refParcela.current) {
            if (refParcela.current.disabled) {
                setTimeout(() => {
                    if (refValor.current)
                        refValor.current.select();
                }, 50)
            } else {
                refParcela.current.focus();
                setTimeout(() => {
                    if (refParcela.current)
                        refParcela.current.select();
                }, 50)
            }
        }
    }

    return (
        <Col md={6} id="faturamentoParcelas-container">
            <Row>
                <Col md="5" className="faturamentoParcelas-itens">
                    <label>PARCELA</label>
                    <Input
                        innerRef={refParcela}
                        type="number"
                        disabled={pagamentoSelecionado.flaVista === 1 ? true : false}
                        className={"input-parcela"}
                        value={pagamentoSelecionado.flaVista === 1 ? 1 : lancamento.parcela}
                        onFocus={(event: React.FocusEvent<HTMLInputElement>) => event.currentTarget.select()}
                        onBlur={(event: React.FocusEvent<HTMLInputElement>) => (event.currentTarget.value === '' || event.currentTarget.value.includes('-')) && action.setLancamento({ ...lancamento, parcela: '1' })}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => action.setLancamento({ ...lancamento, parcela: Number(event.target.value) <= 0 ? '' : event.target.value })}
                    />
                    <br></br>
                </Col>
                <Col md="7" className="faturamentoParcelas-itens">
                    <label>VALOR</label>
                    <Input
                        innerRef={refValor}
                        type="text"
                        className={"input-faturamento"}
                        value={maskMoeda(lancamento.valor)}
                        onFocus={(event: React.FocusEvent<HTMLInputElement>) => event.currentTarget.select()}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => action.setLancamento({ ...lancamento, valor: maskMoeda(event.target.value) })}
                        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => event.key === 'Enter' && addLancamento()}
                    />
                </Col>
                <Col className="faturamentoParcelas-itens">
                    <label>1º VENCIMENTO</label>
                    {pagamentoSelecionado.flaVista === 1 ?
                        <Input
                            type="date"
                            style={{ fontSize: 16 }}
                            value={(new Date().toISOString().substr(0, 10))}
                            disabled={true}
                        />
                        :
                        <Input
                            type="date"
                            style={{ fontSize: 16 }}
                            value={lancamento.firstVencimento ? lancamento.firstVencimento : moment(new Date(), 'DD-MM-YYYY').add('days', pagamentoSelecionado.qtdDiasEntreParcelas).format('YYYY-MM-DD')}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => action.setLancamento({ ...lancamento, valor: lancamento.valor, parcela: lancamento.parcela, firstVencimento: event.target.value })}
                        />
                    }
                </Col>
                <Col className="text-center faturamentoParcelas-itens" >
                    <Button
                        className="btn-adicionar"
                        color={"green"}
                        onClick={() => addLancamento()}
                    >
                        + Adicionar
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col>
                    <fieldset style={{ border: 'solid 2px #5E6680', borderRadius: 5, minHeight: 260, maxHeight: 260, height: '100%' }}>
                        <legend style={{ fontSize: '14px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
                            Lançamentos
                        </legend>

                        <Table />

                    </fieldset>
                </Col>
            </Row>

        </Col>
    )
}

const Table: React.FC = () => {
    const tableRef = useScrollBar([true]);

    const lancamentos = useShallowEqualSelector<AppState, IFaturamentoState['lancamentos']>(state => state.modalFaturamento.lancamentos)

    function getTrProps(_state: any, row: any, column: any) {
        return {
            style: {
                height: '25px'
            }
        }
    }

    function getTdProps(_state: any, _rowInfo: any, column: any) {
        return {
            style: {
                paddingTop: 0
            }
        }
    }

    return (

        <div
            ref={tableRef}
            style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 350 }} >
            <ReactTable
                data={lancamentos}
                NoDataComponent={() => (<></>)}
                columns={[
                    {
                        Header: 'Nº',
                        accessor: 'numero',
                        resizable: true,
                        sortable: false,
                        width: 20,
                        Cell: (props) => props.index + 1,
                        style: { fontSize: 12 }
                    },
                    {
                        Header: 'FORMA DE PAGTO.',
                        accessor: 'formaPagamento',
                        resizable: true,
                        sortable: false,
                        width: 100,
                        style: { fontSize: 12 }
                    },
                    {
                        Header: 'VENCIMENTO',
                        accessor: 'dataInclusao',
                        resizable: true,
                        sortable: false,
                        width: 90,
                        style: { fontSize: 12 }
                    },
                    {
                        Header: <div style={{ textAlign: 'center' }}>VALOR</div>,
                        accessor: 'valor',
                        resizable: true,
                        sortable: false,
                        width: 80,
                        style: { textAlign: 'right', fontSize: 12 }
                    },
                    {
                        Header: <div style={{ textAlign: 'center' }}>AÇÃO</div>,
                        accessor: 'action',
                        resizable: true,
                        sortable: false,
                        width: 40,
                        style: { textAlign: 'center' },
                        Cell: (props) => (
                            <ArrowTooltip title="Excluir">
                                <Button
                                    onClick={() => functions.deleteLancamento(props.index)}
                                    className="btn-icon btn-link text-center"
                                    color="danger"
                                    style={{ marginTop: '-8px' }}
                                >
                                    <Icon icon="trash-alt" className="fa-md" />
                                </Button>
                            </ArrowTooltip>
                        )
                    }
                ]}
                getTrProps={getTrProps}
                getTdProps={getTdProps}
                showPaginationTop={false}
                showPaginationBottom={false}
                className="-striped -highlight header-fix"
                noDataText=""
                pageSize={lancamentos.length}
            />
        </div>
    )
}