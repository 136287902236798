import React, { useState, useEffect } from 'react';

import { Select } from "~/components/ErnComponets/ErnComponets";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";

import { maskCEST } from '~/Util/mascaras.js'
import classNames from "classnames";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';

import './styles.css'

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";

import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector';
import useScrollBar from '../../../../Util/Hooks/useScrollBar'

import * as controllerProdutos from '~/controller/controllerProdutos';
import * as actions from '../../../../store/reducers/Cadastro/Produto/actions';

const CEST = ({ modalCEST, dadosTributarios, seguimentos, toggleModalCEST, toggleModalNCM, cests, cestsSugestoes, shouldClean }) => {
    const tabelaCest = useScrollBar([modalCEST])
    const tabelaSugest = useScrollBar([modalCEST])

    const { codEmpresa } = useShallowEqualSelector(state => state.login.usuario.empresa)
    const [pesquisa, setpesquisa] = useState({ cest: '', descricao: '' })
    const [cestSelected, setcestSelected] = useState('')
    const [mensagem, setmensagem] = useState('')
    const [listCEST, setlistCEST] = useState([])

    useEffect(() => {
        actions.setProdutoDadosTributarios({ ...dadosTributarios, codSeguimento: dadosTributarios.codSeguimento })
    }, [modalCEST])

    useEffect(() => {
        let cest = dadosTributarios.codSeguimento
        cest = cest < 10 ? `0${cest}` : `${cest}`
        if (pesquisa.cest !== cest)
            if (cest !== '00')
                onChangeCest(cest)
            else
                onChangeCest('')
    }, [dadosTributarios.codSeguimento])

    useEffect(() => {
        setlistCEST(cests)
    }, [cests])

    useEffect(() => {
        if (shouldClean) {
            actions.setCESTs([])
            actions.setCESTsSugestoes([])
            setpesquisa({ cest: '', descricao: '' })
            setmensagem('')
        }
    }, [shouldClean])

    function onChangeSeguimentos(codSeguimento) {
        let seguimento = seguimentos.find(seguimento => seguimento.codigo === codSeguimento)
        let cest = codSeguimento < 10 ? `0${codSeguimento}` : `${codSeguimento}`
        if (seguimento && seguimento.codigo !== dadosTributarios.codSeguimento) {
            if (!pesquisa.descricao)
                handlePesquisar(codSeguimento, cest, pesquisa.descricao)
        }
        actions.setProdutoDadosTributarios({ ...dadosTributarios, codSeguimento })
    }

    function onChangeCest(value) {
        let cest = maskCEST(value)
        setpesquisa({ ...pesquisa, cest })
        if (cest.length === 2) {
            onChangeSeguimentos(parseInt(cest))
            changeListCEST(cests)
        } else if (cest.length > 2) {
            tabelaCest.current.scrollTop = 0
            changeListCEST(filterCEST(cest, pesquisa.descricao))
        } else if (cest === '') {
            onChangeSeguimentos('0')
        } else {
            changeListCEST(filterCEST(cest, pesquisa.descricao))
        }
    }

    function onChangeDescricao(value) {
        setpesquisa({ ...pesquisa, descricao: value })
        tabelaCest.current.scrollTop = 0
        changeListCEST(filterCEST(pesquisa.cest, value))
    }

    function changeListCEST(list) {
        setlistCEST(list)
        setmensagem(`${list.length} CESTs encontrados`)
    }

    function filterCEST(cest, descricao) {
        cest = cest.replace(/\./g, '')
        let response = []

        if (cest !== "") {
            response = cests.filter(item => item.cest.startsWith(cest))
            if (descricao.trim() !== "")
                response = response.filter(item => item.descricao.toLocaleLowerCase().includes(descricao.toLocaleLowerCase()))
        }
        else if (descricao.trim() !== "") {
            response = cests.filter(item => item.descricao.toLocaleLowerCase().includes(descricao.toLocaleLowerCase()))
        }

        return response
    }

    function onClickCest(cest) {
        if (cest.cest === cestSelected)
            toggleModalCEST()
        else
            setcestSelected(cest.cest)


        if (dadosTributarios.codSeguimento !== parseInt(cest.cest.substring(0, 2))) {
            onChangeSeguimentos(parseInt(cest.cest.substring(0, 2)))
            actions.setProdutoDadosTributarios({ ...dadosTributarios, cest: cest.cest, codSeguimento: parseInt(cest.cest.substring(0, 2)) })
            setpesquisa({ cest: maskCEST(cest.cest), descricao: cest.descricao })
        } else {
            actions.setProdutoDadosTributarios({ ...dadosTributarios, cest: cest.cest })
            setpesquisa({ cest: maskCEST(cest.cest), descricao: cest.descricao })
        }
    }

    async function handlePesquisar(codSeguimento, cest, descricao) {
        tabelaCest.current.scrollTop = 0
        let mensagem
        mensagem = await controllerProdutos.GetCESTs(codEmpresa, codSeguimento, cest, descricao)
        setmensagem(mensagem)
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter')
            if (pesquisa.descricao && !pesquisa.cest)
                handlePesquisar(dadosTributarios.codSeguimento, pesquisa.cest, pesquisa.descricao)
            else if (pesquisa.cest && !pesquisa.descricao)
                if (pesquisa.cest.length <= 2)
                    handlePesquisar(dadosTributarios.codSeguimento, pesquisa.cest, pesquisa.descricao)
    }

    function handleLimpar() {
        actions.setProdutoDadosTributarios({ ...dadosTributarios, codSeguimento: '', cest: '' })
        actions.setCESTs([])
        actions.setCESTsSugestoes([])
        setpesquisa({ cest: '', descricao: '' })
        setmensagem('')
    }

    async function handleIncluir() {
        let mensagem = await controllerProdutos.IncluirCEST(codEmpresa, dadosTributarios.codSeguimento, pesquisa.cest, pesquisa.descricao)
        setmensagem(mensagem)
    }

    return (
        <ModalBasic
            isOpen={modalCEST}
            toggle={() => toggleModalCEST()}
            title={'Código Especificador da Substituição Tributária'}
            size="lg"
        >
            <ModalBasicBody>

                <Col>
                    <Row>
                        <Col lg>
                            <label>Seguimento</label>
                            <Select
                                id="seguimentoSelect"
                                first="Selecione"
                                value={{ value: dadosTributarios.codSeguimento }}
                                select={{ value: "codigo", label: "descricao" }}
                                onChange={value => onChangeSeguimentos(value.value)}
                                options={seguimentos}
                                isSearchable={true}
                                autoComplete="off"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="2">
                            <label>CEST</label>
                            <Input
                                value={pesquisa.cest}
                                onChange={(e) => onChangeCest(e.target.value)}
                                onKeyPress={handleKeyPress}
                                maxLength={'9'}
                            />
                        </Col>
                        <Col lg>
                            <label>Descrição</label>
                            <Input
                                value={pesquisa.descricao}
                                onChange={(e) => onChangeDescricao(e.target.value)}
                                onKeyPress={handleKeyPress}
                                maxLength={'500'}
                            />
                        </Col>
                        <Col lg="auto">
                            <Button
                                style={{ marginTop: '34px', width: '40px', marginBottom: '0px' }}
                                type='button'
                                color="info"
                                onClick={() => handlePesquisar(dadosTributarios.codSeguimento, pesquisa.cest, pesquisa.descricao)}
                            >
                                <Icon icon="search" className="fa-lg" />
                            </Button>
                        </Col>
                    </Row>
                    <label>{mensagem}</label>
                    <br></br>
                    <div style={{ display: dadosTributarios.ncm !== '' && cestsSugestoes.length > 0 ? '' : 'none' }}>
                        <fieldset className="fieldList">
                            <legend>Sugestões de CEST para o NCM {maskCEST(dadosTributarios.ncm)}</legend>
                            <div className="tabelaList" style={{ marginTop: '0px', minHeight: '20px' }} ref={tabelaSugest}>
                                {cestsSugestoes.map((cest, key) => (
                                    <label
                                        key={key}
                                        className={classNames("labelList", {
                                            labelListSelected: cest.cest === cestSelected
                                        })}
                                        onClick={() => onClickCest(cest)}
                                    >
                                        {cest.descricaoFormatada}
                                    </label>
                                ))}
                            </div>
                        </fieldset>
                    </div>

                    <fieldset className="fieldList">
                        <legend>Resultados da pesquisa</legend>
                        <div id="tabelaCest" className="tabelaList" ref={tabelaCest}>

                            {listCEST.map((cest, key) => (
                                <label
                                    key={key}
                                    className={classNames("labelList", {
                                        labelListSelected: cest.cest === cestSelected
                                    })}
                                    onClick={() => onClickCest(cest)}
                                >
                                    {cest.descricaoFormatada}
                                </label>
                            ))}
                        </div>
                    </fieldset>
                </Col>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                color="info"
                                type="button"
                                onClick={handleLimpar}
                                style={{ width: '100px' }}
                            >Limpar
                            </Button>

                            <Button
                                color="success"
                                type="button"
                                onClick={() => toggleModalCEST()}
                                style={{ width: '100px' }}
                            >OK
                            </Button>

                            <ArrowTooltip title="Salva um novo CEST">
                                <Button
                                    color="info"
                                    type="button"
                                    onClick={handleIncluir}
                                    style={{ width: '100px' }}
                                >Incluir
                                </Button>
                            </ArrowTooltip>
                        </Col>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg md sm >
                        <Col lg="auto" className={window.innerWidth > 660 ? "text-right" : "text-center"}>
                            <Button
                                color="warning"
                                type="button"
                                onClick={() => { toggleModalCEST(); toggleModalNCM() }}
                                style={{ width: '100px' }}
                            > NCM <Icon icon="caret-right" className="fa-lg" />
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>

        </ModalBasic >
    )
}

export default CEST