import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import ReactWizard from "~/components/Wizard/Wizard";
import ReactTable from "react-table";
import * as controllerTransportadoras from "~/controller/controllerTransportadoras";
import classNames from "classnames";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';
import { InputCpfCnpj } from "~/components/ErnComponets/ErnComponets";
import { useHotkeys } from 'react-hotkeys-hook';
import {
  Card,
  Col,
  Button,
  ButtonGroup,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Row,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";

import { maskCNPJ, maskCPF } from "~/Util/mascaras";

// wizard steps
import Step1 from "~/views/Transportadoras/CadastroSteps/Transportadora.jsx";
import Step2 from "~/views/Transportadoras/CadastroSteps/Endereco.jsx";

var steps = [
  {
    stepName: "Transportadora",
    stepIcon: "tim-icons icon-notes",
    component: Step1,
    stepsProps: {}
  },
  {
    stepName: "Endereço",
    stepIcon: "tim-icons icon-square-pin",
    component: Step2,
    stepProps: {}
  }

];
const idStore = "TRANSPORTADORAS";
const Transportadoras = () => {
  const notificacao = useSelector(state => state.notificacao);
  const { transportadora, transportadoraCopia, pesquisa, transportadoras, inicioCadastro, proximoCadastro } = useSelector(state => state.transportadoras);
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState([]);
  const [table, setTable] = useState(renderTable([]));
  const [horizontalTabs, setHorizontalTabs] = useState('pesquisar');
  const [tableVisible, setTableVisible] = useState(false);
  const [modalExcluir, setModalExcluir] = useState(false);
  const [modalInativar, setModalInativar] = useState(false);
  const [transportadoraSelected, settransportadoraSelected] = useState({ status: 0 });
  const [width, setWidht] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [stepActive, setStepActive] = useState(0);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
  }, [])

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', updateWidth);
    }
  }, [])

  function updateWidth() {
    setWidht(window.innerWidth);
  }

  useEffect(() => {
    steps.forEach(step => {
      step.stepProps = { ...step.stepProps, notificacao }
    });
  }, [notificacao])

  useEffect(() => {
    setDataTable(tableData(transportadoras));
    if (transportadoras.length > 0) {
      setTableVisible(true);
    } else {
      setTableVisible(false);
    }
  }, [transportadoras])

  useEffect(() => {
    setTable(renderTable());
  }, [dataTable, loading])

  useEffect(() => {
    steps.forEach(step => {
      step.stepProps = { ...step.stepProps, transportadora: transportadora }
    });
    setInicioCadastro(false)
  }, [transportadora])

  useEffect(() => {
    if (inicioCadastro)
      setInicioCadastro(false)
  }, [inicioCadastro])

  useHotkeys('shift+p', () => {
    controllerTransportadoras.Pesquisar(notificacao, dispatch, pesquisa);
  }, [pesquisa])

  useHotkeys('shift+1', () => {
    changeActiveTab('pesquisar');
  }, [])

  useHotkeys('shift+2 ', () => {
    novoCadatro();
    changeActiveTab("cadastrar");
  }, [])

  useHotkeys('shift+l', () => {
    if (horizontalTabs === 'pesquisar') {
      handleLimpar();
    } else if (stepActive === 0) {
      dispatch({ idStore, type: 'CLEAN_TRANSPORTADORA' });
      dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    } else if (stepActive === 1) {
      dispatch({ idStore, type: 'CLEAN_ENDERECO' });
      dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    }
  }, [horizontalTabs, stepActive])

  useHotkeys('shift+N', () => {
    if (stepActive === 0) {
      dispatch({ idStore, type: 'CLEAN_TRANSPORTADORA' });
      dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    }
    if (stepActive === 1) {
      dispatch({ idStore, type: 'CLEAN_ENDERECO' });
      dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    }
  }, [horizontalTabs, stepActive])

  useHotkeys('shift+s', async () => {
    if ((stepActive === 0)) {
      var error
      if (transportadora.codTrans === '') {
        error = await controllerTransportadoras.CreateTransportadora(notificacao, dispatch, transportadora);
      } else {
        error = await controllerTransportadoras.UpdateTransportadora(notificacao, dispatch, transportadora, transportadoraCopia);
      }

      if (!error) {
        dispatch({ idStore, type: 'PROXIMO_CADASTRO', value: true });
        setTimeout(() => { dispatch({ idStore, type: 'PROXIMO_CADASTRO', value: false }); }, 100)
      }
    } else if (stepActive === 1) {
      controllerTransportadoras.UpdateEndereco(notificacao, dispatch, transportadora, transportadoraCopia);
    }
  }, [stepActive, transportadora, transportadoraCopia])

  async function novoCadatro() {
    setInicioCadastro(true);
    dispatch({ idStore, type: 'CLEAN_TRANSPORTADORA' });
    dispatch({ idStore, type: 'CLEAN_ENDERECO' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
  }
  function changeActiveStep(valor) {
    setStepActive(valor)
  }

  function tableData(data) {
    return data.map((prop, key) => {
      return {
        id: key,
        codTrans: prop['codTransportadora'],
        tipoTrans: prop['tipo'],
        nomeRazao: prop['nomeRazao'],
        apelidoFantasia: prop['apelidoFantasia'],
        cpfCnpj: prop['cpfCnpj'],
        dtCadastro: prop['dataCadastro'],
        status: prop['status'],
      }
    })
  }
  function renderTable() {
    return dataTable.map((obj, key) => {
      return {
        ...dataTable[key],
        actions: (

          <div className="actions-right">
            <ArrowTooltip title="Visualizar">
              <Button
                onClick={() => {
                  handleVisualizarCadastro(obj);
                }}
                size="sm"
                className={classNames("btn-icon btn-link")}
                style={{ color: "#483D8B" }}

              >
                <Icon icon="eye" className="fa-lg" />
              </Button>
            </ArrowTooltip>
            {
              !loading ?
                < ArrowTooltip title="Editar" >
                  <Button
                    onClick={() => {
                      handleEditarCadastro(obj);
                    }}
                    size="sm"
                    className={classNames("btn-icon btn-link")}
                    color="success"
                  >
                    <Icon icon="edit" className="fa-lg" />
                  </Button>
                </ArrowTooltip >
                :
                <Button
                  onClick={() => {
                    handleEditarCadastro(obj);
                  }}
                  size="sm"
                  className={classNames("btn-icon btn-link")}
                  color="success"
                  disabled={true}
                >
                  <Icon icon="edit" className="fa-lg" />
                </Button>
            }

            <ArrowTooltip title={obj.status === 0 ? "Inativar" : "Reativar"}>
              <Button
                onClick={() => {
                  handleInativar(obj);
                }}
                className="btn-icon btn-link"
                color="info"
              >
                {obj ? obj.status === 0 ?
                  <Icon icon="ban" className="fa-lg" />
                  :
                  <Icon icon="redo" className="fa-lg" />
                  :
                  null
                }
              </Button>
            </ArrowTooltip>

            <ArrowTooltip title="Excluir">
              <Button
                onClick={() => { handleExcluir(obj) }}
                className="btn-icon btn-link"
                color="danger"
              >
                <Icon icon="trash-alt" className="fa-lg" />
              </Button>
            </ArrowTooltip>
          </div >

        )
      };
    })
  }

  function changeActiveTab(tabName) {
    setHorizontalTabs(tabName);
    if (tabName === "pesquisar") {
      setTimeout(() => {
        dispatch({ idStore, type: 'VER_CADASTRO', value: false })
        document.getElementById('nomeRazao').focus()
      }, 100)
    }
  };

  function handleOnChange(value, campo) {
    dispatch({ idStore, type: 'PESQUISA', campo, value });
  }

  function handleOnChangeTipo(value) {
    dispatch({ idStore, type: 'PESQUISA', campo: 'tipo', value });
    controllerTransportadoras.Pesquisar(notificacao, dispatch, { ...pesquisa, tipo: value });
  }

  function handleOnChangeAtivo(value) {
    dispatch({ idStore, type: 'PESQUISA', campo: 'ativos', value });
    controllerTransportadoras.Pesquisar(notificacao, dispatch, { ...pesquisa, ativos: value });
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      controllerTransportadoras.Pesquisar(notificacao, dispatch, pesquisa);
    }
  }
  function handlePesquisar(e) {
    e.preventDefault();
    controllerTransportadoras.Pesquisar(notificacao, dispatch, pesquisa);
  }

  function handleLimpar() {
    dispatch({ idStore, type: 'CLEAN_SEARCH' })

  }

  async function handleVisualizarCadastro(obj) {
    setLoading(true);
    if (!loading) {
      await controllerTransportadoras.GetTransportadora(notificacao, dispatch, obj.codTrans, () => {
        setHorizontalTabs("cadastrar")
        setInicioCadastro(true);
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
      });
      setLoading(false);
      dispatch({ idStore, type: 'VER_CADASTRO', value: true })
    }
  }
  async function handleEditarCadastro(obj) {
    setLoading(true);
    if (!loading) {
      await controllerTransportadoras.GetTransportadora(notificacao, dispatch, obj.codTrans, () => {
        setHorizontalTabs("cadastrar")
        setInicioCadastro(true);
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
      });
      setLoading(false);
      dispatch({ idStore, type: 'VER_CADASTRO', value: false })
    }

  }

  function handleInativar(obj) {
    settransportadoraSelected(obj);
    toggleModalInativar();
  }

  function handleExcluir(obj) {
    settransportadoraSelected(obj);
    toggleModalExcluir();
  }

  function inativar() {
    controllerTransportadoras.Inativar(notificacao, dispatch, transportadoraSelected, pesquisa);
  }

  function excluir() {
    controllerTransportadoras.Excluir(notificacao, dispatch, transportadoraSelected, pesquisa);
  }

  function setInicioCadastro(valor) {
    dispatch({ idStore, type: 'INICIO_CADASTRO', value: valor });
  }

  async function finishButtonClick() {

    const error = await controllerTransportadoras.UpdateEndereco(notificacao, dispatch, transportadora, transportadoraCopia);
    if (!error) {
      dispatch({ idStore, type: 'CLEAN_TRANSPORTADORA' });
      setInicioCadastro(true);

      return error
    }
  }

  function toggleModalInativar() {
    setModalInativar(!modalInativar);
  }

  function toggleModalExcluir() {
    setModalExcluir(!modalExcluir);
  }

  return (
    <>
      <div className="content">
        <Col className="mr-auto ml-auto" md="12">
          <Card>
            <CardHeader className="text-center">
              <CardTitle tag="h3">Transportadoras</CardTitle>
              <br />
              <TabContent
                className="tab-space"
                activeTab={horizontalTabs}
                style={{ marginTop: '-30px', marginBottom: '-70px' }}
              >
              </TabContent>
            </CardHeader>

            <CardBody>
              <ButtonGroup style={{ width: '100%' }}>
                <ArrowTooltip title='Shift+1'>
                  <Button
                    color={
                      horizontalTabs === "pesquisar"
                        ? "info"
                        : ""
                    }
                    type="button"
                    data-toggle="tab"
                    className={classNames("btn-gp-padrao",
                      horizontalTabs === "pesquisar"
                        ? "active"
                        : ""
                    )
                    }

                    onClick={() => {
                      changeActiveTab("pesquisar");
                    }
                    }
                    style={{ width: '50%' }}
                  >
                    PESQUISAR
                                      </Button>
                </ArrowTooltip>
                <ArrowTooltip title='Shift+2 '>
                  <Button
                    color={
                      horizontalTabs === "cadastrar"
                        ? "info"
                        : ""
                    }
                    type="button"
                    data-toggle="tab"
                    className={classNames("btn-gp-padrao",
                      horizontalTabs === "cadastrar"
                        ? "active"
                        : ""
                    )
                    }
                    onClick={() => {
                      novoCadatro();
                      changeActiveTab("cadastrar");
                      setInicioCadastro(true);
                    }
                    }
                    style={{ width: '50%' }}
                  >
                    CADASTRAR
                                      </Button>
                </ArrowTooltip>

              </ButtonGroup>

              <TabContent
                className="tab-space"
                activeTab={horizontalTabs}
                style={{ paddingBottom: '0px' }}
              >
                <TabPane tabId="pesquisar">

                  <Row className="padding-padrao-row">
                    <Col className="padding-padrao-col" md="3">
                      <label>Nome / Razão Social</label>
                      <Input
                        id="nomeRazao"
                        type="text"
                        autoComplete="off"
                        value={pesquisa.nomeRazao}
                        onChange={e => handleOnChange(e.target.value, 'nomeRazao')}
                        onKeyPress={e => handleKeyPress(e)}
                        autoFocus={true}
                        maxLength="100"
                      />
                    </Col>

                    <Col className="padding-padrao-col" md="3">
                      <label>Apelido / Nome Fantasia</label>
                      <Input
                        id="fantasia"
                        type="text"
                        autoComplete="off"
                        value={pesquisa.apelidoFantasia}
                        onChange={e => handleOnChange(e.target.value, 'apelidoFantasia')}
                        onKeyPress={e => handleKeyPress(e)}
                        maxLength="50"
                      />
                    </Col>

                    <Col className="padding-padrao-col" md="3">
                      <label>CPF / CNPJ</label>

                      <InputCpfCnpj
                        id="cpfCnpj"
                        type="text"
                        autoComplete="off"
                        value={pesquisa.cpfCnpj}
                        onChange={e => handleOnChange(e.target.value, 'cpfCnpj')}
                        onKeyPress={e => handleKeyPress(e)}
                        tipo={pesquisa.cpfCnpj.length > 14 ? 'cnpj' : 'cpf'}
                        maxLength="18"
                      />
                    </Col>

                    <Col lg="1" className="text-center padding-padrao-col" style={{ paddingTop: '13px' }}>
                      <Col className={width > 768 ? "text-left" : ''}>
                        <FormGroup check className="form-check-radio" Style={"margin-top:-12px;"} >
                          <Label check style={{ paddingRight: '5px' }}>
                            <Input type="radio"
                              onChange={() => handleOnChangeTipo('')}
                              checked={pesquisa.tipo === '' ? true : false}

                            />
                            <span className="form-check-sign" />Todos
                                                  </Label>
                          <Label check style={{ paddingRight: '5px' }}>
                            <Input type="radio"
                              onChange={() => handleOnChangeTipo('F')}
                              checked={pesquisa.tipo === 'F' ? true : false}

                            />
                            <span className="form-check-sign" />Física
                                                  </Label>
                          <Label check style={{ paddingRight: '5px' }}>
                            <Input type="radio"
                              onChange={() => handleOnChangeTipo('J')}
                              checked={pesquisa.tipo === 'J' ? true : false}
                            />
                            <span className="form-check-sign" />Jurídica
                                                  </Label>
                        </FormGroup>

                      </Col>

                    </Col>

                    <Col md="1" className="text-center padding-padrao-col" style={{ paddingTop: '13px' }}>

                      <Col className={width > 768 ? "text-left" : ''}>
                        <FormGroup check className="form-check-radio" Style={"margin-top:-12px;"}>
                          <Label check style={{ paddingRight: '5px' }}>
                            <Input type="radio"
                              onChange={() => handleOnChangeAtivo("")}
                              checked={pesquisa.ativos === "" ? true : false}
                            />
                            <span className="form-check-sign" />Todos
                                                  </Label>
                          <Label check style={{ paddingRight: '5px' }}>
                            <Input type="radio"
                              onChange={() => handleOnChangeAtivo("0")}
                              checked={pesquisa.ativos === "0" ? true : false}
                            />
                            <span className="form-check-sign" />Ativos
                                                  </Label>
                          <Label check style={{ paddingRight: '5px' }}>
                            <Input type="radio"
                              onChange={() => handleOnChangeAtivo("1")}
                              checked={pesquisa.ativos === "1" ? true : false}
                            />
                            <span className="form-check-sign" />Inativos
                                                  </Label>
                        </FormGroup>
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    <CardBody>
                      <Col style={{ width: '100%' }}>
                        <ArrowTooltip title='Shift+L'>
                          <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={e => handleLimpar(e)}
                          >Limpar</Button>
                        </ArrowTooltip>
                        <ArrowTooltip title='Shift+P'>
                          <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={e => handlePesquisar(e)}
                          >Pesquisar</Button>
                        </ArrowTooltip>
                      </Col>
                    </CardBody>
                  </Row>
                  <div style={tableVisible ? { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
                    <ReactTable
                      data={table}
                      filterable={false}
                      columns={
                        [
                          {
                            Header: `Nome/Razão social`,
                            accessor: "nomeRazao"
                          },
                          {
                            Header: `Apelido/Nome Fantasia`,
                            accessor: "apelidoFantasia"
                          },
                          {
                            Header: "CPF/CNPJ",
                            accessor: "cpfCnpj",
                            Cell: (props) => props.value.length > 11 ? maskCNPJ(props.value) : maskCPF(props.value)
                          },
                          {
                            Header: "Tipo",
                            accessor: "tipoTrans",
                            Cell: (props) => props.value === 'F' ? "Pessoa Física" : "Pessoa Jurídica"
                          },
                          {
                            Header: "Data de cadastro",
                            accessor: "dtCadastro"
                          },
                          {
                            Header: "STATUS",
                            accessor: `status`,
                            Cell: (props) => props.value === 0 ? "ATIVO" : "INATIVO"
                          },
                          {
                            Header: "",
                            accessor: "actions",
                            sortable: false,
                            filterable: false
                          }
                        ]
                      }
                      minRows={1}
                      showPaginationTop={false}
                      showPaginationBottom={true}
                      className="-striped -highlight"
                      noDataText=""
                      previousText="Anterior"
                      nextText="Próximo"
                      loadingText="Carregando"
                      pageText="Página"
                      ofText="de"
                      rowsText='linhas'
                    />
                  </div>
                </TabPane>

                <TabPane tabId="cadastrar">
                  <div style={{ marginTop: '-20px' }}>
                    <ReactWizard
                      steps={steps}
                      navSteps={true}
                      title=""
                      description=""
                      validate
                      resizable={true}
                      changeStep={changeActiveStep}
                      previousButtonText="Voltar"
                      finishButtonText="Finalizar"
                      nextButtonText="Avançar"
                      goToFirstStep={inicioCadastro}
                      goToNextStep={proximoCadastro}
                      finishButtonClick={() => finishButtonClick()}
                      finishButtonClasses={classNames("btn-wd btn-info",
                        {
                          finishButtonMin:
                            width < 550
                        },
                        {
                          finishButtonRight:
                            !width < 550
                        }
                      )}
                      nextButtonClasses={classNames("btn-wd btn-info",
                        {
                          nextButtonMin:
                            width < 550

                        },
                        {
                          nextButtonRight:
                            !width < 550
                        }
                      )}
                      previousButtonClasses={classNames("btn-wd",
                        {
                          previousButtonMin:
                            width < 550
                        },
                        {
                          previousButtonRight:
                            !width < 550
                        }
                      )}
                      progressbar
                      color="blue"
                    />
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </div>

      {/* Modal Inativar */}
      <Modal
        isOpen={modalInativar}
        toggle={toggleModalInativar}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalInativar}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">{transportadoraSelected.status === 0 ? "Inativar" : "Ativar"} Transportadora</h6>
        </div>
        <ModalBody>

          <Col className="text-center">
            {transportadoraSelected.status === 0 ?
              <label>Tem certeza que deseja <b>inativar</b> essa transportadora da sua empresa?</label>
              : <label>Tem certeza que deseja <b>ativar</b> essa transportadora em sua empresa?</label>
            }
          </Col>

        </ModalBody>
        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => { toggleModalInativar() }}
                style={{ width: '150px' }}
              >NÃO
                                              </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => { toggleModalInativar(); inativar() }}
                style={{ width: '150px' }}
              >SIM
                                              </Button>
            </Col>
          </Row>
        </CardBody>

      </Modal>
      {/* Modal Inativar*/}

      {/* Modal Excluir */}
      <Modal
        isOpen={modalExcluir}
        toggle={toggleModalExcluir}
      >

        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalExcluir}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">Excluir Transportadora</h6>
        </div>
        <ModalBody>

          <Col className="text-center">
            <label>Tem certeza que deseja <b>excluir</b> esse Transportadora?</label>
          </Col>

        </ModalBody>
        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => { toggleModalExcluir() }}
                style={{ width: '150px' }}
              >NÃO
                                              </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => { toggleModalExcluir(); excluir() }}
                style={{ width: '150px' }}
              >SIM
                                              </Button>
            </Col>
          </Row>
        </CardBody>

      </Modal>
      {/* Modal Excluir*/}
    </>
  );
}
export default Transportadoras;
