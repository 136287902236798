import React from 'react'
import {
    Card,
    Col,
    CardHeader,
    CardBody,
    CardTitle
} from "reactstrap";

import TabContent from "./TabContent/TabContent"
import Pesquisa from "./TabContent/Tabs/Pesquisa"
import Cadastro from "./TabContent/Tabs/Cadastro/Cadastro";
const Tabs = [
    {
        tabName: "PESQUISAR",
        tabScreen: <Pesquisa />,
        ArrowToolTipTitle: 'Shift+1'
    },
    {
        tabName: "CADASTRAR",
        tabScreen: <Cadastro />,
        ArrowToolTipTitle: 'Shift+2'

    }
]
const Usuarios = () => {
    return (
        <>
            <div className='content'>
                <Col className="mr-auto ml-auto" md="12">
                    <Card>
                        <CardHeader className='text-center'>
                            <CardTitle tag="h3"> Usuários </CardTitle>
                        </CardHeader>

                        <CardBody>
                            <TabContent tabs={Tabs} />
                        </CardBody>
                    </Card>
                </Col>
            </div>
        </>
    )
}

export default Usuarios;