import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as controllerTransportadoras from "~/controller/controllerTransportadoras";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';

import {
  Input,
  Row,
  Col,
  CardBody,
  FormGroup,
  CardFooter,
  Button,
} from "reactstrap";

const idStore = "TRANSPORTADORAS"

const Endereco = () => {
  const dispatch = useDispatch();
  const notificacao = useSelector(state => state.notificacao);
  const { transportadora, invalido, transportadoraCopia, verCadastro } = useSelector(state => state.transportadoras);

  function onChange(value, stateName) {
    dispatch({ idStore, type: 'TRANSPORTADORA', campo: stateName, value: value });
    dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
  }

  async function handleKeyPressCep(event) {
    if (event.key === 'Enter') {
      const valido = await controllerTransportadoras.BuscarCep(notificacao, dispatch, transportadora.cep)
      if (valido) {
        document.getElementById('numero').focus();
        dispatch({ idStore, type: 'INVALIDO', campo: 'uf', value: false });
        dispatch({ idStore, type: 'INVALIDO', campo: 'cidade', value: false });
        dispatch({ idStore, type: 'INVALIDO', campo: 'bairro', value: false });
        dispatch({ idStore, type: 'INVALIDO', campo: 'logradouro', value: false });
      }
    }
  }

  function Salvar(e) {
    controllerTransportadoras.UpdateEndereco(notificacao, dispatch, transportadora, transportadoraCopia);
  }

  function Limpar(e) {
    dispatch({ idStore, type: 'CLEAN_ENDERECO' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
  }

  return (
    <>
      <Row className="justify-content-center">
      <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

      </div>
        <Col>
          <h4 className="info-text">Preencha com as informações do endereço da transportadora</h4>
          <CardBody>

            <Row className="justify-content-center">

              <Col md="10">
                <Row>

                  <Col className="padding-padrao-col" lg="2" md="9" sm="4">
                    <label>CEP<span className="error"> *</span></label>
                    <FormGroup className={invalido.cep ? "has-danger" : ''}>
                      <Input
                        id="cepTransp"
                        type="text"
                        value={transportadora.cep}
                        onKeyPress={handleKeyPressCep}
                        onChange={e => onChange(e.target.value, "cep")}
                        autoComplete="off"
                        maxLength="9"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg md="9">
                    <label>Logradouro<span className="error"> *</span></label>
                    <FormGroup className={invalido.logradouro ? "has-danger" : ''}>
                      <Input
                        id="logradouro"
                        type="text"
                        value={transportadora.logradouro}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="100"                      
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg="2" md="3">
                    <label>Número<span className="error"> *</span></label>
                    <FormGroup className={invalido.numero ? "has-danger" : ''}>
                      <Input
                        id="numero"
                        type="text"
                        value={transportadora.numero}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="6"                      
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

                <Row>

                  <Col className="padding-padrao-col" lg="auto" md="2">
                    <label>UF<span className="error"> *</span></label>
                    <FormGroup className={invalido.uf ? "has-danger" : ''} style={{ width: '50px' }}>
                      <Input
                        style={{ paddingLeft: '15px', paddingRight: '15px' }}
                        id="uf"
                        type="text"
                        value={transportadora.uf}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="2"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg md="6">
                    <label>Cidade<span className="error"> *</span></label>
                    <FormGroup className={invalido.cidade ? "has-danger" : ''}>
                      <Input
                        id="cidade"
                        type="text"
                        value={transportadora.cidade}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="100"                     
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg md>
                    <label>Bairro<span className="error"> *</span></label>
                    <FormGroup className={invalido.bairro ? "has-danger" : ''}>
                      <Input
                        id="bairro"
                        type="text"
                        value={transportadora.bairro}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="100"                    
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

                <Row>
                  <Col className="padding-padrao-col" lg md>
                    <label>Complemento</label>
                    <FormGroup>
                      <Input
                        id="complemento"
                        type="text"
                        value={transportadora.complemento}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="50"                     
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" md>
                    <label>Referência</label>
                    <FormGroup>
                      <Input
                        id="referencia"
                        type="text"
                        value={transportadora.referencia}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="70"
                      
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

              </Col>

            </Row>

          </CardBody>

          <CardFooter className="text-center">
            <Col style={{display:"flex", textAlign:'center' }}>
              <ArrowTooltip title='Shift+L'>
                <Button style={{ left:"33%", position:"relative" }} className="btn-padrao" color="default" onClick={() => Limpar()}>Limpar</Button>
             </ArrowTooltip>
              <ArrowTooltip title='Shift+S'>
                <Button style={{ left:"33%", position:"relative" }} className="btn-padrao" color="info" onClick={() => Salvar()}>Salvar</Button>
             </ArrowTooltip>
            </Col>
          </CardFooter>
        </Col>

      </Row>

    </>
  );
}

class Wizard extends React.Component {

  focusInput = () => {
    setTimeout(() => {
      document.getElementById('cepTransp').focus()
    }, 100)
  }

  render() {
    return (
      <Endereco />
    );
  }
}

export default Wizard;
