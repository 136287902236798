import React from 'react'

import {
    Modal,
    Col,
    ModalBody,
    ModalFooter,
    Row,
    Button,
    CardFooter
} from 'reactstrap'


type Toggle = (newState?: boolean, callback?: (state?: boolean) => void) => void


interface Props {
    isOpen: boolean,
    callback: Function
    toggle?: Function | any
    information?: any
}


export const PopUpInativar = ({ isOpen, callback, toggle, information }: Props) => {

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => toggle && toggle()}
                centered={true}
                backdropClassName={'backdropTeste'}
            >
                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggle && toggle()}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Atenção</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        {
                            information.status === 0 ?
                                <label>Tem certeza que deseja <b>inativar</b> esse usuário?</label>
                                :
                                <label>Tem certeza que deseja <b>Ativar</b> esse usuário?</label>
                        }
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Row style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                        <Button
                            color="danger"
                            type="button"
                            onClick={() => toggle && toggle()}
                            style={{ width: '150px' }}
                        >
                            NÃO
            </Button>
                        <Button
                            color="info"
                            type="button"
                            onClick={() => { callback() }}
                            style={{ width: '150px' }}
                        >
                            SIM
            </Button>
                    </Row>
                </ModalFooter>

            </Modal>


        </>)
}

export function PopUpExcluirViculacao({ isOpen, callback, toggle }: Props) {
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => toggle && toggle()}
            >
                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggle && toggle()}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Atenção</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>Deseja realmente excluir a vinculação deste usuário com esta empresa?</label>
                    </Col>

                </ModalBody>
                <CardFooter style={{ backgroundColor: 'transparent' }}>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => toggle && toggle()}
                                style={{ width: '150px' }}
                            >NÃO </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { callback() }}
                                style={{ width: '150px' }}
                            >SIM </Button>
                        </Col>
                    </Row>

                </CardFooter>
            </Modal>
        </>
    )
}

export function PopUpViculacaoGrupoAcesso({ isOpen, callback, toggle, information }: Props) {
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => toggle && toggle()}
            >
                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggle && toggle()}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Vinculação de Usuário</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>Deseja vincular <b>{information.label}</b> ao grupo <b>{information.horizontalTabs}</b>?</label>
                    </Col>

                </ModalBody>
                <CardFooter style={{ backgroundColor: 'transparent' }}>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="default"
                                type="button"
                                onClick={() => toggle && toggle()}
                                style={{ width: '150px' }}
                            >Cancelar </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="info"
                                type="button"
                                onClick={() => { callback() }}
                                style={{ width: '150px' }}
                            >SIM </Button>
                        </Col>
                    </Row>
                </CardFooter>
            </Modal>
        </>
    )
}
export function PopUpSalvarGrupo({ isOpen, callback, toggle }: Props) {

    return (<Modal
        isOpen={isOpen}
        toggle={() => toggle && toggle()}
    >
        <div className="modal-header justify-content-center">
            <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggle && toggle()}
            >
                <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Salvar alterações</h6>
        </div>
        <ModalBody>

            <Col className="text-center">
                    <label style={{whiteSpace: "normal"}}>
                        Todos os usuários vinculados a esse grupo terão seus acessos alterados.
                        <br/>
                        Tem certeza que deseja salvar o grupo?
                    </label>
            </Col>

        </ModalBody>
        <ModalBody>
            <Row className="justify-content-center">
                <Col className="text-center">
                    <Button
                        color="default"
                        type="button"
                        onClick={() => { toggle && toggle() }}
                        style={{ width: '150px' }}
                    >CANCELAR
                      </Button>
                </Col>
                <Col className="text-center">
                    <Button
                        color="info"
                        type="button"
                        onClick={() => { callback() }}
                        style={{ width: '150px' }}
                    >SALVAR
                      </Button>
                </Col>
            </Row>
        </ModalBody>
    </Modal>
    )
}

export function PopUpUsuarioExiste({ isOpen, callback, toggle }: Props) {
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggle}
                >
                    <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up">Usuário já cadastrado com esse E-mail</h6>
            </div>
            <ModalBody>

                <Col className="text-center">
                    <label> Deseja vincular esse usuário a sua empresa? </label>
                </Col>

            </ModalBody>
            <ModalBody>
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <Button
                            color="default"
                            type="button"
                            onClick={toggle}
                            style={{ width: '150px' }}
                        >NÃO
                      </Button>
                    </Col>
                    <Col className="text-center">
                        <Button
                            color="info"
                            type="button"
                            onClick={() => { callback() }}
                            style={{ width: '150px' }}
                        >SIM
                      </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}