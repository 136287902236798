import React, { useState, useEffect } from "react";
import classNames from "classnames";

import {
    Button,
    ButtonGroup,
} from "reactstrap";

import * as actions from '../../../../store/reducers/Movimentacao/SaidaDiversa/actions'
import { shallowEqual, useSelector } from "react-redux";
import AppState from "../../../../store/storeType";

interface Tab {
    tabName: String,
    tabScreen: JSX.Element
}

interface Props{
    tabs: Tab[]
}

const TabsContent = function (props: Props) {
    const screen = useSelector<AppState, number>(state => state.saidaDiversa.screen, shallowEqual);
    const [stateScreen, setStateScreen ] = useState(0)
    
    useEffect(() => {
        setStateScreen(screen)
    }, [screen])

    return (
        <>
            <ButtonGroup style={{ width: '100%' }}>
                {props.tabs && props.tabs.map((item: Tab, index: number) => {
                    return (
                        <>
                            <Button
                                color={screen === index ? "info" : ""}
                                type="button"
                                data-toggle="tab"
                                className={classNames("btn-gp-padrao", screen === index ? "active" : "")}
                                onClick={() => {  setStateScreen(index); actions.setScreen(index)  }}
                                style={{ width: '50%' }}
                            >
                                {item.tabName}
                            </Button>
                        </>
                    )
                })}
            </ButtonGroup>
            {props.tabs[screen].tabScreen}
        </>
    );
}

export default TabsContent;