import React, { useState } from "react";
import { Button } from "reactstrap";

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import useShallowEqualSelector from "../../Util/Hooks/useShallowEqualSelector";
import useModal from '../../Util/Hooks/useModal';

import { PopUpAbrirCaixa } from '../../views/Movimentacao/PedidoVenda/Modals/ModalAbrirCaixa'
import PopUpReAbrirPedido from '../../views/Movimentacao/PedidoVenda/Modals/ModalReAbrirPedido'
import PopUpSegurancaAcao from '../../views/Movimentacao/PedidoVenda/Modals/ModalSegurancaAcao'
import PopUpExcluirPedido from '../../views/Movimentacao/PedidoVenda/Modals/ModalExcluirPedido'
import PopUpImprimirPedido from '../../views/Movimentacao/PedidoVenda/Modals/ModalImprimirPedido'
import PopUpRetiradaCaixa from '../../views/Movimentacao/PedidoVenda/Modals/ModalRetiradaCaixa'
import PopUpEnvioPedido from "../../components/Modals/Envios";
import * as actionsPDV from '../../store/reducers/Movimentacao/PedidoVenda/actions'
import * as controllerPDV from '../../controller/Movimentacao/controllerPedidoVenda'
import './styles.css'
import { SweetAlert } from "../../Util/sweetAlert";


export default function PedidoVenda({ props }) {
  const pedido = useShallowEqualSelector(state => state.pedidoVenda.pedido)
  const numPedido = useShallowEqualSelector(state => state.pedidoVenda.informacoes.numPedido)
  const [classes, setClass] = useState('dropdown')
  const [numeroPedido] = useState(numPedido)
  const [modalAbrirCaixa, toogleAbrirCaixa] = useModal(false)
  const [ModalReAbrirPedido, toogleReAbrirPedido] = useModal(false)
  const [tipoAcao, setTipoAcao] = useState('')
  const [tipoEnvio, setTipoEnvio] = useState('')
  const [modalSegurancaAcao, toogleModalSegurancaAcao] = useModal(false)
  const [modalExcluirPedido, toogleExcluirPedido] = useModal(false)
  const [modalImprimirPedido, toogleImprimirPedido] = useModal(false)
  const [modalEnvioPedido, toogleEnvioPedido] = useModal(false)
  const [modalRetiradaCaixa, toogleRetiradaCaixa] = useModal(false)

  const handleNovo = () => {
    actionsPDV.cleanPedido()
    document.getElementById("numeroDescricao").value = ""
    document.getElementById("numeroDescricao").focus()
    controllerPDV.GetClienteDefault()
    controllerPDV.GetVendedorDefault()
    setClass("dropdown");
  }
  const handleSalvar = async () => {
    if (numPedido === 0) {
      await controllerPDV.SalvarPDV(toogleAbrirCaixa)
    } else {
      const response = await controllerPDV.UpdatePDV(numPedido)
      if (response === 'msgSalvarPDV') {
        SweetAlert('', "Não houve nenhuma alteração, para atualizar ou salvar o pedido!", 'error', false)
      }
    }

  }

  const handleSearch = () => {
    props.history.push('/pesqPedidoVendas')
  }


  const handleClick = () => {

    if (classes === "dropdown") {
      setClass("dropdown show");
    } else {
      setClass("dropdown");
    }
  };

  const handleReOpen = async () => {
    setTipoAcao('reAbrir')
    await toogleModalSegurancaAcao()
  }

  const handleExcluir = async () => {
    setTipoAcao('excluir')
    await toogleModalSegurancaAcao()
  }

  const handleImprimir = async () => {
    toogleImprimirPedido()
  }

  const handleEnvioPedido = async () => {
    setTipoEnvio('pedidoVenda')
    toogleEnvioPedido()
  }

  const handleSangriaCaixa = async () => {
    controllerPDV.getRetiradasCaixa()
    toogleRetiradaCaixa()
  }

  return (
    <>
      <div className="pdv-plugin" >
        <div className={classes}>
          <a
            href="#pablo"
            onClick={e => {
              e.preventDefault();
              handleClick();
            }}
          >
            <i className="fa fa-cog fa-2x" />
          </a>
          <ul className="dropdown-menu show" style={{ width: "250px", height: "607px", overflow: "auto", overflowX: 'hidden' }}>
            <li>
              <p style={{ width: '250px' }}>Operações Básicas</p>
              <Button type={"button"} color={"info"} style={{ width: '200px', left: '4px' }} onClick={() => handleNovo()}>
                <Icon icon="plus-circle" className="fa-lg" color="#FFF" style={{ marginRight: '5px' }} />
                Novo
              </Button>
              <Button type={"button"} color={"info"} style={{ width: '200px' }} onClick={handleSearch}>
                <Icon icon="search" className="fa-lg" style={{ marginRight: '5px' }} />
                Pesquisar
              </Button>
              <Button type={"button"} color={"info"} style={{ width: '200px' }} onClick={() => handleSalvar()}>
                <Icon icon="save" className="fa-lg" color="#FFF" style={{ marginRight: '5px' }} />{" "}
                Salvar
              </Button>
              <Button type={"button"} color={"info"} style={{ width: '200px' }}>
                <Icon icon="truck" className="fa-lg" color="#FFF" style={{ marginRight: '5px' }} />
                Frete
              </Button>
              <Button type={"button"} color={"info"} style={{ width: '200px' }} onClick={handleExcluir} disabled={pedido.status !== 'FINALIZADO' && pedido.status !== '' ? false : true} >
                <Icon icon="trash-alt" className="fa-lg" style={{ marginRight: '5px' }} />
                Excluir
              </Button>
            </li>
            <li className="adjustments-line" />
            <li>
              <p style={{ width: '250px' }}>Operações Pós Faturamento</p>
              <Button type={"button"} color={"info"} style={{ width: '200px', left: '4px' }} onClick={handleEnvioPedido} disabled={numeroPedido === 0 ? true : false}>
                <Icon icon="share" className="fa-lg" color="#FFF" style={{ marginRight: '5px' }} />
                Enviar Pedido
              </Button>
              <Button type={"button"} color={"info"} style={{ width: '200px' }} onClick={() => handleImprimir()} disabled={numeroPedido === 0 ? true : false}>
                <Icon icon="sticky-note" className="fa-lg" color="#FFF" style={{ marginRight: '5px' }} />
                Imprimir
              </Button>
              <Button type={"button"} color={"info"} style={{ width: '200px' }} disabled={numeroPedido === 0 ? true : false}>
                <Icon icon="file-alt" className="fa-lg" color="#FFF" style={{ marginRight: '5px' }} />
                NF-E
              </Button>
              <Button type={"button"} color={"info"} style={{ width: '200px' }} disabled={numeroPedido === 0 ? true : false}>
                <Icon icon="receipt" className="fa-lg" color="#FFF" style={{ marginRight: '5px' }} />
                Cupom Fiscal
              </Button>
              <Button type={"button"} color={"info"} style={{ width: '200px' }} onClick={handleReOpen} disabled={pedido.status !== 'ABERTO' && pedido.status !== '' ? false : true}>
                <Icon icon="lock-open" className="fa-lg" color="#FFF" style={{ marginRight: '5px' }} />
                Reabrir
              </Button>
            </li>
            <li className="adjustments-line" />
            <li>
              <p style={{ width: '250px' }}>Outras Operações</p>
              <Button type={"button"} color={"info"} style={{ width: '200px', left: '4px' }} onClick={handleSangriaCaixa} >
                <Icon icon="cash-register" className="fa-lg" color="#FFF" style={{ marginRight: '5px' }} />
                Sangria de Caixa
              </Button>
            </li>
          </ul>
        </div>
      </div>

      {modalAbrirCaixa &&
        <PopUpAbrirCaixa
          isOpen={modalAbrirCaixa}
          callback={() => { controllerPDV.CreateCaixa(); toogleAbrirCaixa() }}
          toggle={toogleAbrirCaixa}
        />
      }
      {modalSegurancaAcao &&
        <PopUpSegurancaAcao
          isOpen={modalSegurancaAcao}
          callback={() => { controllerPDV.segurancaAcao(toogleModalSegurancaAcao, tipoAcao === 'reAbrir' ? toogleReAbrirPedido : toogleExcluirPedido); }}
          toggle={toogleModalSegurancaAcao}
          tipoAcao={tipoAcao}
        />
      }
      {ModalReAbrirPedido &&
        <PopUpReAbrirPedido
          isOpen={ModalReAbrirPedido}
          callback={() => { controllerPDV.ReAbrirPedido(); toogleReAbrirPedido() }}
          toggle={toogleReAbrirPedido}
        />
      }
      {modalExcluirPedido &&
        <PopUpExcluirPedido
          isOpen={modalExcluirPedido}
          toggle={toogleExcluirPedido}
          callback={() => { controllerPDV.ExcluirPedido(); toogleExcluirPedido() }}
        />
      }
      {modalImprimirPedido &&
        <PopUpImprimirPedido
          isOpen={modalImprimirPedido}
          toggle={toogleImprimirPedido}
          callback={() => { }}
          numPedido={numPedido}
        />
      }
      {modalEnvioPedido &&
        <PopUpEnvioPedido
          isOpen={modalEnvioPedido}
          toggle={toogleEnvioPedido}
          callback={() => { }}
          numPedido={numPedido}
          tipoEnvio={tipoEnvio}
        />
      }
      {modalRetiradaCaixa &&
        <PopUpRetiradaCaixa
          isOpen={modalRetiradaCaixa}
          toggle={toogleRetiradaCaixa}
          callback={() => controllerPDV.salvarRetiradasCaixa()}
        />
      }
    </>
  );
}
