import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { maskIPI } from '~/Util/mascaras';

import * as controllerIPIs from "~/controller/controllerIPIs";

import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';

import {
    Input,
    Row,
    Col,
    CardBody,
    Button,
    CardFooter,
    FormGroup,
} from "reactstrap";

const idStore = "IPIS";


const IPI = () => {
    const notificacao = useSelector(state => state.notificacao);
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
    const { ipi, ipiCopia, invalido, verCadastro } = useSelector(state => state.ipis);
    const dispatch = useDispatch();

    function onChange(value, stateName) {
        dispatch({ idStore, type: 'IPI', campo: stateName, value: value });
        dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
    };

    async function Salvar() {

        if (ipi.codIpi === 0) {
            controllerIPIs.CreateIPI(notificacao, dispatch, codEmpresa, ipi);
        } else {
            controllerIPIs.UpdateIPI(notificacao, dispatch, codEmpresa, ipi, ipiCopia);
        }
    };

    function Limpar() {
        dispatch({ idStore, type: 'CLEAN_IPI' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        document.getElementById('cadipi').focus()
    };

    return (
        <>
            <Row className="justify-content-center">
            <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

            </div>
                <Col>
                    <h4 className="info-text">Preencha com as informações do IPI</h4>
                    <CardBody>

                        <Row className="justify-content-center">

                            <Col md="10">

                                <Row className="padding-padrao-row">

                                    <Col className="padding-padrao-col" lg="3" md="3" sm="3">
                                        <label>IPI <span className="error">*</span></label>
                                        <FormGroup className={invalido.ipi ? "has-danger" : ''}>
                                            <Input
                                                id="cadipi"
                                                type="text"
                                                value={ipi.ipi}
                                                onChange={e => onChange(maskIPI(e.target.value), 'ipi')}
                                                autoComplete="off"
                                                maxLength="3"
                                            
                                            />
                                            <br></br>
                                        </FormGroup>
                                    </Col>

                                    <Col className="padding-padrao-col" lg md sm>
                                        <label>Descrição <span className="error">*</span></label>
                                        <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                                            <Input
                                                id="descricao"
                                                type="text"
                                                value={ipi.descricao}
                                                onChange={e => onChange(e.target.value, 'descricao')}
                                                autoComplete="off"
                                                maxLength="250"
                                            
                                            />
                                            <br></br>
                                        </FormGroup>
                                    </Col>

                                </Row>

                            </Col>

                        </Row>

                    </CardBody>
                    <CardFooter className="text-center">
                        <Row className="justify-content-center">
                            <Col md="10" >
                                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                    <ArrowTooltip title='Shift+N'>
                                        <Button className="btn-padrao" color="default" onClick={() => Limpar()}>Novo</Button>
                                    </ArrowTooltip>
                                    <ArrowTooltip title='Shift+S'>
                                        <Button className="btn-padrao" color="info" onClick={() => Salvar()}>Salvar</Button>
                                    </ArrowTooltip>
                                </Col>
                            </Col>
                        </Row>
                    </CardFooter>
                </Col>
            </Row>
        </>
    )
};

class Wizard extends React.Component {
    focusInput = () => {
        setTimeout(() => {
            document.getElementById('cadipi').focus()
        }, 100)
    }

    render() {
        return (
            <IPI />
        )
    }
}

export default Wizard;
