import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerGrupoContas from "~/controller/controllerGrupoContas";
import { Select, Switch } from "~/components/ErnComponets/ErnComponets";

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  Modal,
  ModalBody,

  FormGroup,
} from "reactstrap";


const idStore = "GRUPO_CONTAS";


const GrupoConta = () => {
  const {cadastro, cadastroCopia, invalido, verCadastro } = useSelector(state => state.grupoContas);
  const [modalInativar, setModalInativar] = useState(false);
  const [grupoContaSelected, setgrupoContaSelected] = useState({ status: 0 });
  const dispatch = useDispatch();


  function onChange(value, stateName) {
    dispatch({ idStore, type: 'CADASTRO', campo: stateName, value: value });

    dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
  };

  async function Salvar(e) {
    e.preventDefault();
    if (cadastro.codGrupoConta === 0) {
      controllerGrupoContas.CreateGrupoConta(cadastro);
    } else {
      controllerGrupoContas.UpdateGrupoConta(cadastro, cadastroCopia);
    }

  };

  function handleInativar(obj) {
    setgrupoContaSelected(obj);
    toggleModalInativar()
  }

  function toggleModalInativar() {
    setModalInativar(!modalInativar);
  }

  function inativar() {
    if (cadastro.codGrupoConta !== 0) {
      controllerGrupoContas.inativarGrupoConta(grupoContaSelected);
    }
  }

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_CADASTRAR' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
  };

  return (
    <>
      <Row className="justify-content-center">
      <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

      </div>

        <Col>
          <h4 className="info-text">Preencha com as informações do grupo de conta</h4>
          <CardBody>

            <Row className="justify-content-center">

              <Col md="12">

                <Col md="12" className="text-right">
                  <Switch
                    componentleft={<label className={cadastro.status === 0 ? `text-success text-center` : "text-warning text-center"}>{cadastro.status === 0 ? "Ativo" : "Inativo"}</label>}
                    checked={cadastro.status === 0}
                    onChange={() => handleInativar(cadastro)}
                    disabled={cadastro.codGrupoConta === 0}
                  />
                </Col>

                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col" lg="3" md sm="7">
                    <label>Tipo <span className="error">*</span></label>

                    <Select
                      first={'Selecione'}
                      value={{ value: cadastro.tipo }}
                      options={[{ value: 'R', label: 'Receitas' },
                      { value: "D", label: 'Despesas' }]}
                      onChange={value => onChange(value.value, 'tipo')}
                      autoComplete="off"
                    />
                    <br></br>

                  </Col>

                  <Col className="padding-padrao-col" lg md sm="7">
                    <label>Descrição <span className="error">*</span></label>
                    <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                      <Input
                        id="descricao"
                        type="text"
                        value={cadastro.descricao}
                        onChange={e => onChange(e.target.value, 'descricao')}
                        autoComplete="off"
                        maxLength="100"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>


              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row>
                <Col style={{display:"flex", justifyContent:'center' }}>
                  <Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button>
                  <Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>
      <Modal
        isOpen={modalInativar}
        toggle={toggleModalInativar}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalInativar}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">{grupoContaSelected.status === 0 ? "Inativar" : "Ativar"} grupo de conta</h6>
        </div>
        <ModalBody>

          <Col className="text-center">
            <label>
              {grupoContaSelected.status === 0 ?
                <>Tem certeza que deseja <b>inativar</b> esse grupo de conta da sua empresa?</>
                : <>Tem certeza que deseja <b>ativar</b> esse grupo de conta da sua empresa?</>
              }
            </label>
          </Col>

        </ModalBody>
        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => { toggleModalInativar() }}
                style={{ width: '150px' }}
              >NÃO
                                                        </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => { toggleModalInativar(); inativar() }}
                style={{ width: '150px' }}
              >SIM
                                                        </Button>
            </Col>
          </Row>
        </CardBody>

      </Modal>
    </>
  )
};

class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('descricao').focus()

    }, 100)
  }

  render() {
    return (
      <GrupoConta />
    )
  }
}

export default Wizard;
