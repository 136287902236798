import { useHotkeys } from "react-hotkeys-hook"

import store from "../../store/stores"
import * as controller from "../../controller/controllerProdutos"
import * as actions from '../../store/reducers/Cadastro/Produto/actions'

export const atalhoPesquisar = (horizontalTabs: "pesquisar" | "cadastrar") => {
  useHotkeys(
    "shift+p",
    () => {
      //@ts-ignore
      const codEmpresa = store.getState().login.usuario.empresa.codEmpresa
      const state = store.getState().produtos
      if (horizontalTabs === "pesquisar") {
        controller.Pesquisar(codEmpresa, state.pesquisa)
      }
    },
    [horizontalTabs]
  )
}

export const atalhoLimpar = (horizontalTabs: "pesquisar" | "cadastrar", stepActive: number) => {
  useHotkeys(
    "shift+l",
    () => {
      if (horizontalTabs === "pesquisar") {
        actions.cleanPesquisa()
        actions.setFornecedoresProd([])
        let element = document.querySelector<HTMLInputElement>('#dataCadastro')
        if (element) {
          element.value = ''
        }
      } else if (stepActive === 0) {
        actions.cleanProduto()
        actions.cleanInvalido()
      } else if (stepActive === 2) {
      }
    },
    [horizontalTabs, stepActive]
  )
}

export const atalhoNovo = (horizontalTabs: "pesquisar" | "cadastrar", stepActive: number) => {
  useHotkeys(
    "shift+N",
    () => {
      if (horizontalTabs === "pesquisar") {
        actions.cleanPesquisa()
        actions.setFornecedoresProd([])
        let element = document.querySelector<HTMLInputElement>('#dataCadastro')
        if (element) {
          element.value = ''
        }
      } else if (stepActive === 0) {
        actions.cleanProduto()
        actions.cleanInvalido()
      } else if (stepActive === 2) {
      }
    },
    [horizontalTabs, stepActive]
  )
}

export const atalhoSalvar = (horizontalTabs: "pesquisar" | "cadastrar", stepActive: number) => {
    //@ts-ignore
  useHotkeys('shift+s', async () => {
    //@ts-ignore
    const codEmpresa = store.getState().login.usuario.empresa.codEmpresa
    const state = store.getState().produtos
    const produto = store.getState().produtos.produto
    const produtoCopia = state.produtoCopia
    if (horizontalTabs === 'cadastrar') {

      var error = false
      if (stepActive === 0) {

        if (produto.codProduto === '') {
          error = await controller.CreateProduto(codEmpresa, produto)
        } else {
          error = await controller.UpdateProduto(codEmpresa, produto, produtoCopia)
        }

        if (error === false) {
          actions.setProximoCadastro(true)
          setTimeout(() => {
            actions.setProximoCadastro(true)
          }, 1000)
        }

      } else if (stepActive === 2) {
        if (produtoCopia.basico.caracteristicas.flTributado === 1) {
          error = await controller.SalvarCESTNCM(codEmpresa, produto.codProduto, produto.dadosTributarios, produtoCopia.dadosTributarios)

          if (error === false) {
            actions.setProximoCadastro(true)
            setTimeout(() => {
              actions.setProximoCadastro(true)
            }, 1000)
          }
        }
      }
    }
  }, [horizontalTabs, stepActive])
}