import React from "react";
import { useSelector, shallowEqual } from 'react-redux';
import ReactTable from "react-table";

const Table = function () {
    const lancamentos = useSelector(state => state.lancamentoTitulos.cadastro.lancamentos, shallowEqual)

    return (
        <>
            <fieldset style={{ border: 'solid 1px #5E6680', borderRadius: 5 }}>
                <ReactTable
                    data={lancamentos}
                    filterable={false}
                    NoDataComponent={() => (<></>)}
                    columns={[
                        // {
                        //     Header: "NUM. DOC.",
                        //     accessor: "x"
                        // },
                        {
                            Header: "GRUPO",
                            accessor: "grupo"
                        },
                        {
                            Header: "SUBGRUPO",
                            accessor: "subGrupo"
                        },
                        {
                            Header: "PARCELA",
                            accessor: "parcela"
                        },
                        {
                            Header: "VALOR (R$)",
                            accessor: "valorTitulo",
                        },
                        {
                            Header: "FORMA PAGAMENTO",
                            accessor: "formaPgnt"
                        },
                        {
                            Header: "TIPO",
                            accessor: "tipoPgnt"
                        },
                        {
                            Header: "MÊS REFERENCIA",
                            accessor: "mesReferencia"
                        },
                        {
                            Header: "VENCIMENTO",
                            accessor: "dataVencimento"
                        }]}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    noDataText=""
                    pageSize={lancamentos.length}
                />
            </fieldset>

        </>)
}


export default Table