import React, { useLayoutEffect } from "react";

import { Card, CardBody, CardHeader, Label, CardFooter } from "reactstrap";
import { FirstRow, SecondRow, ButtonGroupCadastro } from "./Components/Index";
import { GetCaracteristicas } from "../../../../../../../controller/controllerOperacoes";
import { shallowEqual, useSelector } from "react-redux";
import AppState from "../../../../../../../store/storeType";

export default function Operacao() {
  useLayoutEffect(() => {
    GetCaracteristicas();
  });

  return (
    <>
      <FirstRow />
      <SecondRow />
      <ButtonGroupCadastro />
    </>
  );
}
