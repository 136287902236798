import React from "react";
import ReactTable from "react-table";

import { Col,Row,Collapse} from "reactstrap";

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import AppState from "../../../../store/storeType";
import useShallowEqualSelector from "../../../../Util/Hooks/useShallowEqualSelector";

import { Round } from "../../../../Util/numbers";

import * as action from '../../../../store/reducers/Cadastro/FechamentoCaixa/actions'


const ListPagamento = function() {
    const data =  useShallowEqualSelector<AppState, any>(state => state.fechamentoCaixa.pagamento)
    var name = data.name
    if (name == 1){
        name = 'Dinheiro'
    }
    else if (name == 2){
        name = 'Cartão'
    }
    else if (name == 3){
        name = 'Carnê'
    }
    function openToggle(index: number){
        let listStr= JSON.stringify(data)
        let listArr= JSON.parse(listStr)

        listArr[index].open = !listArr[index].open

        action.setPagamento(listArr)
        
    }

    return (
        <>
            <div style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' }} >
                <ReactTable
                    //@ts-ignore
                    data={data}
                    filterable={false}
                    NoDataComponent={() => (<></>)}
                    columns={[
                        {
                            Header: "",
                            Cell: (prop) =>
                                <Row>
                                    <Col md={7} onClick= {() => openToggle(prop.index)} style={{ cursor: 'pointer', fontSize: 15 }}>
                                        {
                                        prop.original.name == 1? "Dinheiro" : prop.original.name == 2 ? "Cartão" : "Carnê"}
                                    </Col>
                                    <Col md={3} onClick= {() => openToggle(prop.index)} style={{ cursor: 'pointer', fontSize: 14 }}>
                                        {Round(prop.original.valorTotal, 2)}
                                    </Col>
                                    <Col md={1} onClick= {() => openToggle(prop.index)} style={{ cursor: 'pointer' }}>
                                        <Icon icon='caret-down' className="fa-lg" />
                                    </Col>
                                    <Col md={12}>
                                        <Collapse isOpen={prop.original.open}>
                                            {prop.original.formasPagamento.map((item:any) =>
                                                <Row style={{marginLeft: 5, fontSize: 12, color:"#999999", borderBottom:"1px solid #606060", borderTop:"0.5px solid #606060", padding: 5}}>
                                                    <Col md={7}>
                                                        {item.name}
                                                    </Col>
                                                    <Col >
                                                        {Round(item.valor, 2)}
                                                    </Col>
                                                </Row>
                                            )}
                                        </Collapse>
                                    </Col>
                                </Row>
                        },
                    ]

                    }
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    noDataText=""
                />
            </div>
        </>
    )
}

export default ListPagamento