import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import { useSelector, useDispatch } from 'react-redux';
import { Select } from "../../Components";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';
import {
    Col,
    Input,
    Row,
    CardBody,
    Button,
    Modal,
    ModalBody,
} from "reactstrap";
import useResize from "../../../../Util/Hooks/useResize";
import useShallowEqualSelector from "../../../../Util/Hooks/useShallowEqualSelector";

import * as controllerLancamento from '../../../../controller/Financeiro/controllerLancamentoTitulos'


import useModal from "../../../../Util/Hooks/useModal";
import ModalPesquisa from "../../Modals/PesquisaFornecedores";
import ModalEditarTitulos from "../../Modals/EditarTitulos";
import { InputCpfCnpj } from "../../../../components/ErnComponets/ErnComponets";

const Pesquisar = () => {
    const idStore = 'LANCAMENTO_TITULOS';
    const dispatch = useDispatch()
    const titulos = useSelector(state => state.lancamentoTitulos.titulos,);
    const { codEmpresaFilial } = useShallowEqualSelector(state => state.login.usuario.empresa);
    const empresa = useSelector(state => state.lancamentoTitulos.empresa,)
    const pesquisa = useSelector(state => state.lancamentoTitulos.pesquisa,)
    const [tituloSelected, setTituloSelected] = useState({ status: 0 });
    const [modalExcluir, setModalExcluir] = useState(false);
    const [codigoMovimento, setCodigoMovimento] = useState(false);
    const [modalPesquisa, toggleModalPesquisar] = useModal(false)
    const [modalEditarTitulos, toggleModalEditarTitulos] = useModal(false)
    const { width } = useResize()

    useEffect(() => {
        controllerLancamento.getEmpresa();
    }, [])

    useEffect(() => {
        dispatch({ idStore, type: "PESQUISA", value: { ...pesquisa, codEmpresaFilial: codEmpresaFilial } })
        controllerLancamento.getEmpresa();
    }, [])


    function pesquisar() {
        controllerLancamento.GetLancamentos(pesquisa)
    }

    function limpar(e) {
        e.preventDefault();
        dispatch({ idStore, type: "CLEAN_PESQUISA" })
    }

    function confirmModalFornecedor(value) {
        dispatch({ idStore, type: "PESQUISA", value: { ...pesquisa, codFor: value.codigo, nomeFor: value.nomeRazao, tipoFor: value.tipo, cpfCnpj: value.documento } })
        toggleModalPesquisar()
        dispatch({ idStore, type: 'CLEAR_FORNECEDOR' })
    }

    function confirmModalEditar() {
        controllerLancamento.UpdateLancamento(codigoMovimento, () => {
            toggleModalEditarTitulos(false)
        })
    }

    function onChangePesquisa(value, campo) {
        dispatch({ idStore, type: 'PESQUISA', value: { ...pesquisa, [campo]: value } })

    }

    function toggleModalExcluir() {
        setModalExcluir(!modalExcluir);
    }

    function handleExcluir(obj) {
        setTituloSelected(obj);
        toggleModalExcluir();

    }

    async function handleEditarCadastro(obj) {
        await controllerLancamento.GetLancamento(obj)
        setCodigoMovimento(obj.codigoTituloMovimento)
        toggleModalEditarTitulos(true)
    }

    function cleanFornecedor() {
        dispatch({ idStore, type: "CLEAN_FORNECEDOR" })
    }

    function onChangeFornecedor(value) {
        dispatch({ idStore, type: 'PESQUISA', value: { ...pesquisa, cpfCnpj: value } })
    }

    async function handleKeyPress(event) {
        if (event.key === 'Enter') {

            if (pesquisa.cpfCnpj) {
                let codForne = await controllerLancamento.getFornecedoresKeyPress(pesquisa.cpfCnpj, "P", pesquisa)
                if (!codForne) {
                    dispatch({ idStore, type: 'PESQUISA', value: { ...pesquisa, cpfCnpj: "", nomeFor: "" } })
                    toggleModalPesquisar();
                }
            }
            else {
                toggleModalPesquisar();
            }
        }
    }

    return (
        <>
            <Row className="padding-padrao-row">
                <Col className="padding-padrao-col" lg={4}>
                    <label>Empresas / Filial</label>
                    <Select
                        first={"Selecione"}
                        value={{
                            value: pesquisa.codEmpresaFilial
                        }}
                        select={{ value: "codEmpresaFilial", label: "descricao" }}
                        options={empresa}
                        onChange={(props) => onChangePesquisa(props.value, "codEmpresaFilial")}
                        isSearchable={true}
                        autoComplete="off"
                    />
                </Col>

                <Col className="padding-padrao-col" lg={2}>
                    <label>CPF / CNPJ</label>
                    <InputCpfCnpj
                        type="text"
                        value={pesquisa.cpfCnpj}
                        onChange={e => onChangeFornecedor(e.target.value, 'cpfCnpj')}
                        onKeyPress={e => handleKeyPress(e)}
                        maxLength="18"
                        tipo={pesquisa.cpfCnpj.length > 14 ? 'cnpj' : 'cpf'}
                    />
                </Col>

                <Col className="padding-padrao-col" lg={0.5}>
                    <Button
                        style={{ marginTop: '25px', width: '40px', marginBottom: '20px' }}
                        type="button"
                        color="info"
                        onClick={() => toggleModalPesquisar()}
                    >
                        <Icon icon="search" className="fa-lg" />
                    </Button>
                </Col>

                <Col className="padding-padrao-col">
                    <label>Nome / Razão Social</label>
                    <Input
                        type="text"
                        value={pesquisa.nomeFor}
                        disabled={true}
                    />
                </Col>
            </Row>

            <Row className="padding-padrao-row">
                <Col className="padding-padrao-col" lg={2}>
                    <label>Data Início</label>
                    <Input
                        type="date"
                        value={pesquisa.dataInicio}
                        onChange={(value) => onChangePesquisa(value.target.value, "dataInicio")}
                    />
                </Col>
                <Col className="padding-padrao-col" lg={2}>
                    <label>Data Fim</label>
                    <Input
                        type="date"
                        value={pesquisa.dataFim}
                        onChange={(value) => onChangePesquisa(value.target.value, "dataFim")}
                    />
                </Col>
            </Row>

            <Row>
                <CardBody>
                    <Col style={{ width: '100%' }}>
                        <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={(e) => limpar(e)}
                        >
                            Limpar
                        </Button>
                        <Button
                            color='info'
                            style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                            onClick={() => pesquisar()}
                        >
                            Pesquisar
                        </Button>
                    </Col>
                </CardBody>
            </Row>
            <Row>
                <Col>
                    {
                        titulos.length === 0 ?

                            null
                            :
                            <ReactTable
                                data={titulos}
                                filterable={false}
                                NoDataComponent={() => (<></>)}
                                columns={
                                    [
                                        {
                                            Header: "N.DOC.",
                                            accessor: "codigoTituloMovimento"
                                        },
                                        {
                                            Header: "CLIENTE/FORNECEDOR",
                                            accessor: "nome"
                                        },
                                        {
                                            Header: "PARCELA",
                                            accessor: "parcela"
                                        },
                                        {
                                            Header: "VALOR (R$)",
                                            accessor: "valor"
                                        },
                                        {
                                            Header: "FORMA DE PAGAMENTO",
                                            accessor: "formaPagamento"
                                        },
                                        {
                                            Header: "TIPO",
                                            accessor: "tipo"
                                        },
                                        {
                                            Header: "REFERÊNCIA",
                                            accessor: "referencia"
                                        },
                                        {
                                            Header: "VENCIMENTO",
                                            accessor: "vencimento"
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "status",
                                            Cell: (props) => props.original.status === 'PAGO' ? <div style={{ color: "green" }}>PAGO</div> : <div style={{ color: "red" }}>ABERTO</div>
                                        },
                                        {
                                            sortable: false,
                                            Header: "OPERAÇÕES",
                                            filterable: false,
                                            width: 120,
                                            Cell: (props) => (
                                                <>
                                                    <ArrowTooltip title="Editar">
                                                        <Button
                                                            onClick={() => handleEditarCadastro(props.original)}
                                                            size="sm"
                                                            className={"btn-icon btn-link"}
                                                            color="success"
                                                        >
                                                            <Icon icon="edit" className="fa-lg" />
                                                        </Button>
                                                    </ArrowTooltip>


                                                    <ArrowTooltip title="Excluir">
                                                        <Button
                                                            onClick={() => handleExcluir(props.original)}
                                                            className="btn-icon btn-link"
                                                            color="danger"
                                                        >
                                                            <Icon icon="trash-alt" className="fa-lg" />
                                                        </Button>
                                                    </ArrowTooltip>
                                                </>
                                            )
                                        }
                                    ]
                                }
                                minRows={1}
                                defaultPageSize={5}
                                previousText="Anterior"
                                nextText="Próximo"
                                loadingText="Carregando"
                                pageText="Página"
                                ofText="de"
                                rowsText='linhas'
                            />

                    }
                </Col>
            </Row>
            {
                modalPesquisa && empresa ?
                    <ModalPesquisa isOpen={modalPesquisa} toggle={toggleModalPesquisar} confirmModal={confirmModalFornecedor} clean={cleanFornecedor} />
                    :
                    null
            }
            {
                modalEditarTitulos && empresa ?
                    <ModalEditarTitulos isOpen={ModalEditarTitulos} toggle={toggleModalEditarTitulos} confirmModal={confirmModalEditar} clean={cleanFornecedor} />
                    :
                    null
            }
            {/* Modal Excluir */}
            <Modal
                isOpen={modalExcluir}
                toggle={toggleModalExcluir}
            >

                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalExcluir}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Excluir fornecedor</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>Tem certeza que deseja <b>excluir</b> este título?</label>
                    </Col>

                </ModalBody>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { toggleModalExcluir() }}
                                style={{ width: '150px' }}
                            >NÃO
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { toggleModalExcluir(); controllerLancamento.ExcluirLancamento(tituloSelected) }}
                                style={{ width: '150px' }}
                            >SIM
                                            </Button>
                        </Col>
                    </Row>
                </CardBody>

            </Modal>
            {/* Modal Excluir*/}
        </>
    );
}


export default Pesquisar;