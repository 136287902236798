export enum OperacaoStore {

    ID_STORE = "OPERACAO"

}

export enum OperacaoTypes {

    PESQUISA = 'PESQUISA',
    OPERACAO = 'OPERACAO',
    OPERACOES = 'OPERACOES',
    INVALIDO = 'INVALIDO',
    CARACTERISTICAS = 'CARACTERISTICAS',

    CLEAN_SEARCH = 'CLEAN_SEARCH',
    CLEAN = 'CLEAN',
    CLEAN_INVALIDO = 'CLEAN_INVALIDO',
    CLEAN_OPERACOES = 'CLEAN_OPERACOES',
    NEW = 'NEW',


    INICIO_CADASTRO = 'INICIO_CADASTRO',
    VER_CADASTRO = 'VER_CADASTRO',

    SCREEN = 'SCREEN',

    WIZARD_STEP = 'WIZARD_STEP',

    ONCHANGEPESQUISA = 'ONCHANGEPESQUISA',
    ONCHANGECADASTRADO = 'ONCHANGECADASTRADO',

    COPIA_OPERACAO = 'COPIA_OPERACAO'
}

export type OperacaoAction = {

    idStore: OperacaoStore.ID_STORE
    type: OperacaoTypes
    values?: IOperacaoState['values'],
    pesquisa?: Pesquisa,
    operacao?: Operacao,
    invalido?: Invalido,
    inicioCadastro?: IOperacaoState['inicioCadastro'],
    verCadastro?: IOperacaoState['verCadastro'],
    caracteristica?: IOperacaoState['caracteristica'],
    operacoes?: IOperacaoState['operacoes'],
    screen?: IOperacaoState['screen'],
    currentStep?: IOperacaoState['currentStep'],
    campo?: IOperacaoState['campo']
}

export interface IOperacaoState {
    pesquisa: Pesquisa,
    operacao: Operacao,
    invalido: Invalido,
    inicioCadastro: boolean,
    verCadastro: boolean,
    caracteristica: any[],
    operacoes: any[],
    screen: Number,
    currentStep: Number | null
    campo?: any,
    values?: any,
    copiaOperacao: []
}

export interface Pesquisa {
    numero: string,
    descricao: string,
    tipo: string
}

export interface Operacao {
    codigo: Number,
    numero: string,
    descricao: string,
    nfe: Number,
    conta: Number,
    estoque: Number,
    tipo: string,
    caracteristicas: Number,
    ativo: Number,
    update: boolean
}

export interface Invalido {
    numero: boolean,
    descricao: boolean,
    tipo: boolean,
    caracteristicas: boolean
}