import React from 'react';
import {
    Col,
    Input,
    Button
} from 'reactstrap';

import { Switch } from '../../../../../../../../components/ErnComponets/ErnComponets';
import * as actions from '../../../../../../../../store/reducers/Cadastro/Usuario/actions';
import { InputCpfCnpj } from '../../../../../../../../components/ErnComponets/ErnComponets';
import ArrowTooltip from '../../../../../../../../components/Tooltip/ArrowTooltip';

interface Props {

    state?: any
    className?: any
    inputProps?: InputProps | any
    labelProps?: LabelProps | any
    buttonProps?: ButtonProps | any
    value?: any | string
    name?: any
    id?: string
    onClick?: Function | any
    onChange?: Function | any


}

interface InputProps {

    maxLength?: number
    type?: any
    disable?: boolean

}
interface LabelProps {
    text: string,
}

interface ButtonProps {
    className?: string
    color?: string
    label?: string
    arrowTooltip: boolean
    arrowTooltipTitle: any
    disabled: boolean
}

export function InputText(props: Props) {

    return (
        <>
            <Input
                id={props.id}
                name={props.name}
                type={props.inputProps.type}
                value={props.value}
                onChange={props.onChange}
                disabled={props.inputProps.disable}
                maxLength={props.inputProps.maxLength}
            />
            <br></br>
        </>
    )

}
export function InputCpf(props: Props) {

    return (
        <>
            <InputCpfCnpj
                id={props.id}
                name={props.name}
                type={props.inputProps.type}
                value={props.value}
                onChange={props.onChange}
                disabled={props.inputProps.disable}
                maxLength={props.inputProps.maxLength}
            />
            <br></br>
        </>
    )

}

export function CheckInativar(props: Props) {

    return (
        <>
            <Col md="10" className="text-right">
                <Switch
                    name={props.name}
                    componentleft={<label className={`text-success text-center`}></label>}
                    checked={true}
                    onChange={() => { actions.setUsuario({ ...props.state, status: props.state.status === 1 ? 0 : 1 }) }}
                    disabled={true}
                />
            </Col>
        </>
    )
}

export function LabelRequired(props: Props) {
    return (
        <>
            <label>{props.labelProps.text}<span className="error">*</span></label>
        </>
    )
}

export function ButtonBase(props: Props) {

    return (
        <>
            {props.buttonProps.arrowTooltip ?

                <ArrowTooltip title={props.buttonProps.arrowTooltipTitle}>
                    <Button

                        className={props.buttonProps.className}
                        color={props.buttonProps.color}
                        onClick={() => props.onClick()}
                        disabled={props.buttonProps.disabled}

                    >{props.buttonProps.label}
                    </Button>
                </ArrowTooltip>

                :

                <Button

                    className={props.buttonProps.className}
                    color={props.buttonProps.color}
                    onClick={() => props.onClick()}
                    disabled={props.buttonProps.disabled}

                >{props.buttonProps.label}
                </Button>

            }
        </>
    )
}
