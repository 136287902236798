//State da tela de Pesquisa e cadastro de subgrupos
var INITIAL_STATE = {
    pesquisa: {
        codGrupo: "0",
        nome: '',
        descricao: '',
        ativos: 2,
    },
    subgrupo: {
        codGrupo: "0",
        grupo: 'Selecione',
        codSubgrupo: '',
        nome: '',
        descricao: '',
        status: 0,
    },
    invalido: {
        nome: false,
        descricao: false,
    },
    grupos: [],
    subgrupos: [],
    vinculos: false,
    inicioCadastro: false,
    verCadastro: false,
    subgrupoCopia: {},
};

export default function subgruposProduto(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'SUBGRUPOS_PRODUTO':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'GRUPOS':
                    return { ...state, grupos: action.value }
                case 'SUBGRUPOS':
                    return { ...state, subgrupos: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: {
                            codGrupo: "0",
                            nome: '',
                            descricao: '',
                            ativos: 0,
                        },
                        subgrupos: []
                    }
                case 'SUBGRUPO':
                    return { ...state, subgrupo: { ...state.subgrupo, [action.campo]: action.value } }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value } 
                case 'VINCULOS':
                    return { ...state, vinculos: action.value }
                case 'SUBGRUPO_COPIA':
                    return { ...state, subgrupoCopia: state.subgrupo }
                case 'SUBGRUPO_COPIA_STATUS':
                    return { ...state, subgrupoCopia: { ...state.subgrupo, status: action.value } }
                case 'CLEAN_SUBGRUPO':
                    return {
                        ...state,
                        subgrupo: {
                            codGrupo: "0",
                            grupo: 'Selecione',
                            codSubgrupo: '',
                            nome: '',
                            descricao: '',
                            status: 0,
                        },
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: {
                            nome: false,
                            descricao: false,
                        },
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}