import React from 'react'
import { Col } from 'reactstrap'

import { ModalBasic, ModalBasicBody } from '../../../../components/ModalBasic/ModalBasic'
import { Imagem } from '../../../../store/reducers/Cadastro/Produto/types'

interface ModalImagemProps {
    isOpen: boolean
    toggle: () => void
    imagem: Imagem
}

const ModalImagem: React.FC<ModalImagemProps> = ({ isOpen, toggle, imagem }) => {

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title={imagem.descricao}
        >
            <ModalBasicBody>

                <Col className="text-center">
                    <img src={"data:image/jpg;base64," + imagem.imagem} style={{ maxHeight: '300px' }} alt="" />
                </Col>

            </ModalBasicBody>
            <br />
            <br />
        </ModalBasic >
    )
}
export default ModalImagem