import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import ReactTable from "react-table";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import { Input, Info, InputCpfCnpj } from '../Components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import useScrollBar from '../../../../Util/Hooks/useScrollBar';
import { useSelector, shallowEqual } from 'react-redux';

export default function ModalFornecedor({ isOpen, toggle, getFornecedor, confirmModal, clean }) {
    const tableRef = useScrollBar([isOpen])
    const fornecedores = useSelector(state => state.entradaMercadoria.fornecedores, shallowEqual)
    const inputDocumento = useRef()

    const [pesquisaFornecedor, setPesquisaFornecedor] = useState({ codigo: null, cpfCnpj: '', nomeRazao: '', rgInscEst: '' })

    useEffect(() => {
        inputDocumento.current.focus()
    }, [])

    function onClickOperacao(rowIndex) {
        let fornecedorSelected = fornecedores[rowIndex]
        setPesquisaFornecedor({ codigo: fornecedorSelected.codFor, cpfCnpj: fornecedorSelected.cpfCnpj, nomeRazao: fornecedorSelected.nomeRazao, rgInscEst: fornecedorSelected.rgInscEst })
    }

    function onDoubleClickOperacao() {
        confirmModal(pesquisaFornecedor.codigo)
    }

    function limparModal() {
        setPesquisaFornecedor({  codigo: null, cpfCnpj: '', nomeRazao: '', rgInscEst: '' })
        clean()
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            getFornecedor(pesquisaFornecedor)
        }
    }

    function getTrProps(state, row, column) {
        return {
            style: {
                height: '50px',
                paddingTop: '0px',
                paddingBottom: '5px',
                backgroundColor: fornecedores[row.index].cpfCnpj === pesquisaFornecedor.cpfCnpj ? 'rgba(71, 179, 255, 0.41)' : ''
            },
            onClick: (e, handleOriginal) => {
                onClickOperacao(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            },
            onDoubleClick: (e, handleOriginal) => {
                onDoubleClickOperacao(row.index)
                if (handleOriginal) {
                    handleOriginal()
                }
            }
        }
    }

    function getTdProps(state, rowInfo, column) {
        if (column.id === 'actions') {
            return { style: { padding: 0 } }
        } else {
            return {}
        }
    }

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title='Pesquisa de Fornecedor'
            size="lg"
        >
            <ModalBasicBody>
                <h4 style={{ textAlign: 'center' }}>Informe os dados abaixo para prosseguir</h4>

                <Row>
                    <Col md="4">
                        <label>CPF / CNPJ</label>
                        <InputCpfCnpj
                            innerRef={inputDocumento}
                            type="text"
                            tipo={pesquisaFornecedor.cpfCnpj.length <= 14 ? 'cpf' : 'cnpj'}
                            value={pesquisaFornecedor.cpfCnpj}
                            onChange={e => setPesquisaFornecedor({ ...pesquisaFornecedor, cpfCnpj: e.target.value })}
                            autoComplete="off"
                            maxLength="18"
                            onKeyPress={e => handleKeyPress(e)}
                        />
                        <br></br>
                    </Col>
                    <Col md="7">
                        <label>Nome / Razão Social</label>
                        <Input
                            type="text"
                            value={pesquisaFornecedor.nomeRazao}
                            onChange={e => setPesquisaFornecedor({ ...pesquisaFornecedor, nomeRazao: e.target.value })}
                            autoComplete="off"
                            onKeyPress={e => handleKeyPress(e)}
                        />
                        <br></br>
                    </Col>
                    <Col lg={1} md={1} sm={1} >
                        <Button
                            type="button"
                            style={{ marginTop: 34, width: 40, marginBottom: 0 }}
                            color="info"
                            onClick={() => getFornecedor(pesquisaFornecedor)}
                        >
                            <Icon icon="search" className="fa-lg" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <fieldset className="fieldset-operacao">
                            <legend>Resultados</legend>
                            <div
                                ref={tableRef}
                                style={fornecedores.length !== 0 ?
                                    { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 350 } :
                                    { visibility: 'hidden', opacity: '0' }} >
                                <ReactTable
                                    data={fornecedores}
                                    NoDataComponent={() => (<></>)}
                                    columns={[
                                        {
                                            Header: 'CPF / CNPJ',
                                            accessor: 'cpfCnpj',
                                            minWidth: 40,
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Nome / Razão Social',
                                            accessor: 'nomeRazao',
                                            resizable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: 'Rg / IE',
                                            accessor: 'rgInscEst',
                                            minWidth: 40,
                                            resizable: false,
                                            sortable: false
                                        }
                                    ]}
                                    getTrProps={getTrProps}
                                    getTdProps={getTdProps}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight header-fix"
                                    noDataText=""
                                    pageSize={fornecedores.length}
                                />
                            </div>

                            <br />
                        </fieldset>
                    </Col>

                </Row>

                <Col className="text-right">
                    <Info>
                        {`${fornecedores.length} fornecedores encontrados`}
                    </Info>
                </Col>

            </ModalBasicBody>

            <ModalBasicFooter>
                <Col className="text-center">
                    <Button
                        className="btn-padrao"
                        onClick={() => confirmModal(pesquisaFornecedor.codigo)}
                        color={"info"}
                    >CONCLUÍDO
                    </Button>
                    <Button
                        className="btn-padrao"
                        onClick={() => limparModal()}
                        color={"info"}
                    >LIMPAR
                    </Button>
                </Col>
            </ModalBasicFooter>

        </ModalBasic>
    )
}