import React, { useState, useCallback, memo } from 'react'
import classnames from "classnames"

import { Row, Col, Input, FormGroup, Label, Button, Collapse, CardBody } from "reactstrap"
import { Select } from "../../components/ErnComponets/ErnComponets"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import ArrowTooltip from "../../components/Tooltip/ArrowTooltip"
import MultiSelect from '../../components/MultiSelect/MultiSelect'

import useResize from '../../Util/Hooks/useResize'
import useModal from '../../Util/Hooks/useModal'
import useShallowEqualSelector from '../../Util/Hooks/useShallowEqualSelector'

import { maskNCM, maskCEST } from '../../Util/mascaras'
import * as controller from '../../controller/controllerProdutos'

import ModalInativar from './Modals/ModalInativar'
import ModalExcluir from './Modals/ModalExcluir'
import ModalPesquisa from './Modals/ModalPesquisa'
import TableProdutos from './TableProdutos'

import AppState from '../../store/storeType'
import { Pesquisa as PesquisaType, Grupo, Subgrupo, Fornecedor, Produtos, Produto } from '../../store/reducers/Cadastro/Produto/types'
import * as actionsStore from '../../store/reducers/Cadastro/Produto/actions'
import { flags } from './variables'

interface PesquisaProps {
    setHorizontalTabs: React.Dispatch<React.SetStateAction<"pesquisar" | "cadastrar">>
}

const Pesquisa: React.FC<PesquisaProps> = ({ setHorizontalTabs }) => {
    const { width } = useResize()
    //@ts-ignore
    const { codEmpresa } = useShallowEqualSelector(state => state.login.usuario.empresa)

    const pesquisa = useShallowEqualSelector<AppState, PesquisaType>(state => state.produtos.pesquisa)
    const grupos = useShallowEqualSelector<AppState, Grupo[]>(state => state.produtos.grupos)
    const pesqsubgrupos = useShallowEqualSelector<AppState, Subgrupo[]>(state => state.produtos.pesqsubgrupos)
    const fornecedoresProd = useShallowEqualSelector<AppState, Fornecedor[]>(state => state.produtos.fornecedoresProd)
    const [forneSelecionados, setforneSelecionados] = useState<(number | string)[]>([])
    const [filtroOpen, setFiltroOpen] = useState(false)

    const [modalExcluir, toggleModalExcluir] = useModal(false)
    const [modalInativar, toggleModalInativar] = useModal(false)
    const [modalPesquisa, toggleModalPesquisa] = useModal(false)

    const [produtoSelected, setprodutoSelected] = useState({ status: 0 })

    const [loading, setLoading] = useState(false)
    const [pesquisaClass] = useState({ descricao: '' })

    async function handleVisualizarCadastro(obj: Produtos) {
        setLoading(true);
        if (!loading) {

            actionsStore.cleanProduto()

            await controller.GetProduto(codEmpresa, obj.codProduto, (prod: any) => {
                controller.GetTipoGrades(codEmpresa)
                controller.GetClassificacoes(codEmpresa, pesquisaClass, "0");
                setHorizontalTabs("cadastrar")
                actionsStore.setInicioCadastro(true)
                setLoading(false)
                actionsStore.cleanInvalido()
                controller.GetSubgrupos(codEmpresa, prod.codGrupo, (subgrupos: Subgrupo[]) => {
                    actionsStore.setCadSubgrupos(subgrupos)
                    actionsStore.setProdutoCopia()
                })
                controller.GetImagens(codEmpresa, prod.codProduto)
            })
        }
        actionsStore.setVerCadastro(true)
        setLoading(false)

    }

    async function handleEditarCadastro(obj: Produtos) {

        setLoading(true)

        if (!loading) {

            actionsStore.cleanProduto()

            await controller.GetProduto(codEmpresa, obj.codProduto, (prod: any) => {
                controller.GetTipoGrades(codEmpresa)
                controller.GetClassificacoes(codEmpresa, pesquisaClass, "0");
                setHorizontalTabs("cadastrar")
                actionsStore.setInicioCadastro(true)
                setLoading(false)
                actionsStore.cleanInvalido()
                controller.GetSubgrupos(codEmpresa, prod.codGrupo, (subgrupos: Subgrupo[]) => {
                    actionsStore.setCadSubgrupos(subgrupos)
                    actionsStore.setProdutoCopia()
                })
                controller.GetImagens(codEmpresa, prod.codProduto)
            })
        }
        actionsStore.setVerCadastro(false)
        setLoading(false)
    }

    function handleInativar(obj: Produtos) {
        setprodutoSelected(obj)
        toggleModalInativar()
    }

    function handleExcluir(obj: Produtos) {
        setprodutoSelected(obj)
        toggleModalExcluir()
    }

    function handleOnChange(value: number | string | string[], field: keyof PesquisaType) {
        actionsStore.setPesquisa({ ...pesquisa, [field]: value })
    }

    function handleOnChangeStatus(value: number | string | string[], field: keyof PesquisaType) {
        actionsStore.setPesquisa({ ...pesquisa, [field]: value })
        controller.Pesquisar(codEmpresa, { ...pesquisa, ativos: value }, forneSelecionados)
    }

    function onChangeGrupo(value: string) {
        actionsStore.setPesquisa({ ...pesquisa, codGrupo: value })
        controller.Pesquisar(codEmpresa, { ...pesquisa, codGrupo: value, codSubgrupo: '0' }, forneSelecionados)

        if (value === "0") {
            actionsStore.setPesqSubgrupos([])
            actionsStore.setPesquisa({ ...pesquisa, codGrupo: value, codSubgrupo: '0' })
        } else {
            controller.GetSubgrupos(codEmpresa, value, (subgrupos: Subgrupo[]) => {
                actionsStore.setPesqSubgrupos(subgrupos)
                actionsStore.setPesquisa({ ...pesquisa, codGrupo: value, codSubgrupo: '0' })
            })
        }
    }

    function onChangeSubgrupo(value: string) {
        actionsStore.setPesquisa({ ...pesquisa, codSubgrupo: value })
        controller.Pesquisar(codEmpresa, { ...pesquisa, codSubgrupo: value }, forneSelecionados)
    }

    function hadleExluiFornecedor(forne: number | string) {
        let arr: number[] | string[] = JSON.parse(JSON.stringify(forneSelecionados))
        let aux = -1
        arr.forEach((value: number | string, key: number) => { if (forne === value) aux = key })
        if (aux !== -1)
            arr.splice(aux, 1)
        setforneSelecionados(arr)
    }

    function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            controller.Pesquisar(codEmpresa, pesquisa, forneSelecionados)
        }
    }

    function handleKeyPressModal(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            toggleModalPesquisa(false)
            controller.GetFornecedorProduto(codEmpresa, pesquisa)
        }
    }

    function handlePesquisar() {
        controller.Pesquisar(codEmpresa, pesquisa, forneSelecionados)
    }

    function handleLimpar() {
        actionsStore.cleanPesquisa()
        actionsStore.setFornecedoresProd([])
        setforneSelecionados([])

        let element = document.querySelector<HTMLInputElement>('#dataCadastro')
        if (element) {
            element.value = ''
        }

    }

    const actions = useCallback((obj: Produtos) => (
        <div className="actions-right">
            <ArrowTooltip title="Visualizar">
                <Button
                    onClick={() => {
                        handleVisualizarCadastro(obj);
                    }}
                    size="sm"
                    className={classnames("btn-icon btn-link")}
                    style={{ color: "#483D8B" }}

                >
                    <Icon icon="eye" className="fa-lg" />
                </Button>
            </ArrowTooltip>
            {
                !loading ?
                    <ArrowTooltip title="Editar">
                        <Button
                            onClick={() => handleEditarCadastro(obj)}
                            size="sm"
                            className={classnames("btn-icon btn-link")}
                            color="success"
                        >
                            <Icon icon="edit" className="fa-lg" />
                        </Button>
                    </ArrowTooltip>
                    :
                    <Button
                        onClick={() => handleEditarCadastro(obj)}
                        size="sm"
                        className={classnames("btn-icon btn-link")}
                        color="success"
                        disabled={true}
                    >
                        <Icon icon="edit" className="fa-lg" />

                    </Button>
            }
            <ArrowTooltip title={obj.status === 0 ? "Inativar" : "Reativar"} >
                <Button
                    onClick={() => handleInativar(obj)}
                    className="btn-icon btn-link"
                    color="info"
                >
                    {obj ? obj.status === 0 ?
                        <Icon icon="ban" className="fa-lg" />
                        :
                        <Icon icon="redo" className="fa-lg" />
                        :
                        null
                    }
                </Button>
            </ArrowTooltip>


            <ArrowTooltip title="Excluir">
                <Button
                    onClick={() => handleExcluir(obj)}
                    className="btn-icon btn-link"
                    color="danger"
                >
                    <Icon icon="trash-alt" className="fa-lg" />
                </Button>
            </ArrowTooltip>

        </div >
    ), [loading])

    return (
        <>
            <Row className="padding-padrao-row">
                <Col className="padding-padrao-col" lg="2" md="3">
                    <label>Código / Cód. Barras</label>
                    <Input
                        id="codigoProduto"
                        type="text"
                        autoComplete="off"
                        value={pesquisa.numeroProduto}
                        onChange={e => handleOnChange(e.target.value, 'numeroProduto')}
                        onKeyPress={e => handleKeyPress(e)}
                        autoFocus={true}
                        maxLength={40}
                    />
                </Col>

                <Col className="padding-padrao-col" lg="2">
                    <label>Grupo</label>
                    <Select
                        first="Todos"
                        value={{ value: pesquisa.codGrupo }}
                        select={{ value: "codGrupo", label: "nome" }}
                        //@ts-ignore
                        onChange={value => onChangeGrupo(value.value)}
                        //@ts-ignore
                        options={grupos}
                        isSearchable={true}
                        autoComplete="off"
                    />
                </Col>

                <Col className="padding-padrao-col" lg="2">
                    <label>Subgrupo</label>
                    <Select
                        first="Todos"
                        value={{ value: pesquisa.codSubgrupo }}
                        select={{ value: "codSubgrupo", label: "nome" }}
                        //@ts-ignore
                        onChange={value => onChangeSubgrupo(value.value)}
                        //@ts-ignore
                        options={pesqsubgrupos}
                        isSearchable={true}
                        autoComplete="off"
                    />
                </Col>

                <Col className="padding-padrao-col" lg="4" md="3">
                    <label>Nome</label>
                    <Input
                        type="text"
                        autoComplete="off"
                        value={pesquisa.nome}
                        onChange={e => handleOnChange(e.target.value, 'nome')}
                        onKeyPress={e => handleKeyPress(e)}
                        maxLength={50}
                    />
                </Col>

                <Col md="1" className="text-center padding-padrao-col" style={{ paddingTop: '13px' }}>
                    <Col className={width > 768 ? "text-left" : ''}>
                        <FormGroup check className="form-check-radio" Style={"margin-top:-12px;"} >
                            <Label check style={{ paddingRight: '5px' }}>
                                <Input type="radio"
                                    onChange={() => handleOnChangeStatus(2, 'ativos')}
                                    checked={pesquisa.ativos === 2 ? true : false}
                                />
                                <span className="form-check-sign" />Todos
                            </Label>

                            <Label check style={{ paddingRight: '5px' }}>
                                <Input type="radio"
                                    onChange={() => handleOnChangeStatus(0, 'ativos')}
                                    checked={pesquisa.ativos === 0 ? true : false}
                                />
                                <span className="form-check-sign" />Ativos
                            </Label>

                            <Label check style={{ paddingRight: '5px' }}>
                                <Input type="radio"
                                    onChange={() => handleOnChangeStatus(1, 'ativos')}
                                    checked={pesquisa.ativos === 1 ? true : false}
                                />
                                <span className="form-check-sign" />Inativos
                            </Label>

                        </FormGroup>
                    </Col>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div>
                        <Button color="info" onClick={() => setFiltroOpen(!filtroOpen)} style={{ marginBottom: '1rem' }}>{filtroOpen ? '-' : '+'} Filtros</Button>
                        <Collapse isOpen={filtroOpen}>
                            <Col className="box">
                                <div >
                                    <Row>
                                        <Col className="padding-padrao-col">
                                            <label>Data de Cadastro</label>
                                            <Input
                                                type="date"
                                                autoComplete="off"
                                                id="dataCadastro"
                                                value={pesquisa.dtCadastro}
                                                onChange={e => handleOnChange(e.target.value, 'dtCadastro')}
                                                onKeyPress={e => handleKeyPress(e)}
                                            />
                                        </Col>
                                        <Col className="padding-padrao-col">
                                            <label>NCM</label>
                                            <Input
                                                type="text"
                                                autoComplete="off"
                                                value={pesquisa.ncm}
                                                onChange={e => handleOnChange(maskNCM(e.target.value), 'ncm')}
                                                onKeyPress={e => handleKeyPress(e)}
                                                maxLength={10}
                                            />
                                        </Col>
                                        <Col className="padding-padrao-col">
                                            <label>CEST</label>
                                            <Input
                                                type="text"
                                                autoComplete="off"
                                                value={pesquisa.cest}
                                                onChange={e => handleOnChange(maskCEST(e.target.value), 'cest')}
                                                onKeyPress={e => handleKeyPress(e)}
                                                maxLength={10}
                                            />
                                        </Col>
                                    </Row>
                                    <Row lg="16">
                                        <Col className="padding-padrao-col">
                                            <label>Fornecedor</label>
                                            <Input
                                                type="text"
                                                autoComplete="off"
                                                value={pesquisa.nomeRazao}
                                                onChange={e => handleOnChange(e.target.value, 'nomeRazao')}
                                                onKeyPress={e => handleKeyPressModal(e)}
                                            />
                                        </Col>
                                        <Button
                                            style={{ marginTop: '25px', width: '40px', marginBottom: '34px' }}
                                            type='button'
                                            color="info"
                                            onClick={() => toggleModalPesquisa()}
                                        >
                                            <Icon icon="search" className="fa-lg" />
                                        </Button>
                                        <Col className="padding-padrao-col" lg="4" md="4" sm="4">
                                            <label>Características</label>
                                            <MultiSelect
                                                first="Todos"
                                                values={pesquisa.flags}
                                                onChange={(values: string[]) => handleOnChange(values, 'flags')}
                                                options={flags}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: "-20px" }}>
                                        <Col className="padding-padrao-col" lg="6">
                                            {forneSelecionados.length > 0 ?
                                                <div className="retorno-forne-principal" >
                                                    {forneSelecionados.map((forne: number | string) => (
                                                        fornecedoresProd.map((fornecedor: Fornecedor, key: number) =>
                                                            forne === fornecedor.codFor ?
                                                                <div className="retorno-forne" key={key}>
                                                                    <label>{fornecedor.nomeRazao} </label>
                                                                    <Button
                                                                        className="button-excluir"
                                                                        color='danger'
                                                                        type="button"
                                                                        onClick={() => hadleExluiFornecedor(forne)}
                                                                    >X</Button>
                                                                </div>
                                                                : null
                                                        )
                                                    ))}
                                                </div>
                                                : null}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Collapse>
                    </div>
                </Col>
            </Row>

            <Row>
                <CardBody>
                    <Col style={{ width: '100%' }}>
                        <ArrowTooltip title='Shift+L'>
                            <Button
                                color='info'
                                style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                onClick={handleLimpar}
                            >Limpar</Button>
                        </ArrowTooltip>
                        <ArrowTooltip title='Shift+P'>
                            <Button
                                color='info'
                                style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                onClick={handlePesquisar}
                            >Pesquisar</Button>
                        </ArrowTooltip>
                    </Col>
                </CardBody>
            </Row>

            <TableProdutos actions={actions} />

            {modalInativar ?
                <ModalInativar
                    isOpen={modalInativar}
                    toggle={toggleModalInativar}
                    produto={produtoSelected}
                    fornecedores={forneSelecionados}
                />
                : null}

            {modalExcluir ?
                <ModalExcluir
                    isOpen={modalExcluir}
                    toggle={toggleModalExcluir}
                    produto={produtoSelected}
                    fornecedores={forneSelecionados}
                />
                : null}

            {modalPesquisa ?
                <ModalPesquisa
                    isOpen={modalPesquisa}
                    toggle={() => {
                        toggleModalPesquisa()
                        controller.GetFornecedorProduto(codEmpresa, pesquisa)
                    }}
                    forneSelecionados={forneSelecionados}
                    setforneSelecionados={setforneSelecionados}
                    handleKeyPressModal={handleKeyPressModal}
                />
                : null}

        </>
    )
}

export default memo(Pesquisa)