import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import useModal from '../../../Util/Hooks/useModal';


import Produto from './Cards/Produto';
import Totais from './Cards/Totais';
import Modais from './Cards/Modais';
import Itens from './Cards/Itens';


import './styles.css'
import ProdutosLista from './Cards/ProdutosLista';
import SubGrupoProdutos from './Cards/SubGrupoProduto';
import ModalOperacao from './Modals/ModalOperacao'
import * as controllerPDV from '../../../controller/Movimentacao/controllerPedidoVenda'
import ModalGrade from './Modals/ModalGrade';
import useShallowEqualSelector from '../../../Util/Hooks/useShallowEqualSelector';

export default function PedidoVenda() {
    const informacoes = useShallowEqualSelector(state => state.pedidoVenda.informacoes)
    const inputNumProdRef = useRef(null)
    const operacao = useShallowEqualSelector(state => state.pedidoVenda.operacao)
    const cliente = useShallowEqualSelector(state => state.pedidoVenda.cliente)
    const [codProduto, setCodProduto] = useState('')
    const [typeAction, setTypeAction] = useState('')
    const [indexGrade, setIndexGrade] = useState(0)
    const [modalGrade, toogleModalGrade] = useModal(false)
    const [modalOperacao, toggleOperacao] = useModal(false)
    const dispatch = useDispatch()

    function toggleModalOperacao() {
        toggleOperacao()
        dispatch({ idStore: 'PEDIDO_VENDA', type: 'OPERACOES', value: [] })
    }

    function pesquisaOperacao(operacao) {
        controllerPDV.GetOperacoes(operacao.numero, operacao.descricao)
    }

    function confirmModal(operacao) {
        controllerPDV.GetOperacao(operacao.codigo)
        toggleOperacao()
    }

    useEffect(() => {
        document.body.classList.add('sidebar-mini');
        controllerPDV.getCaixaAberto()
        if(operacao.codigo === 0 && cliente.nomeRazao === '' && informacoes.vendedor.nomeVendedor === ''  ) {
            controllerPDV.GetFuncionario()
            controllerPDV.GetVendedorDefault()
            toggleModalOperacao()
            controllerPDV.GetClienteDefault()
        }
    }, [])

    return (
        <>
            <div id="content-pedidoVenda" className="content" style={{ paddingTop: '0px', height: '100%' }} >
                <div className="container__products">
                    <div>
                        <Produto inputNumProdRef={inputNumProdRef} setCodProduto={setCodProduto} toogleModalGrade={toogleModalGrade} setTypeAction={setTypeAction}  />
                        <ProdutosLista />
                        <SubGrupoProdutos />
                    </div>
                    <div >
                        <Itens toogleModalGrade={toogleModalGrade} setCodProduto={setCodProduto} setTypeAction={setTypeAction} typeAction={typeAction} setIndexGrade={setIndexGrade}   />
                        <Totais />
                        <Modais />
                    </div>
                </div>
            </div>
            {modalOperacao ?
                <ModalOperacao
                    isOpen={true}
                    toggle={toggleModalOperacao}
                    getOperacao={pesquisaOperacao}
                    confirmModal={confirmModal} /> : null
            }
            { modalGrade && <ModalGrade codProduto={codProduto} isOpen={true} toggle={toogleModalGrade} typeAction={typeAction} indexGrade={indexGrade} />}
        </>
    );
}
