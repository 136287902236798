import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { Row, Col, Input, Button } from "reactstrap";
import ModalProduto from "../../Modals/ModalProduto"
import ModalGrade from "../../Modals/ModalGrade"
import useResize from "../../../../../Util/Hooks/useResize";
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import {  maskMoeda } from "../../../../../Util/mascaras"
import useModal from "../../../../../Util/Hooks/useModal";
import * as controllerProdutos from "../../../../../controller/Movimentacao/controllerEntradaMercadoria"
import { FormGroup } from "reactstrap";
import { removeMaskMoney } from "../../../../../Util/removeMascaras";
const idStore = "ENTRADA_MERCADORIA"

function FiltrosItens() {
    const refQuantidade = useRef(null);
    const refValorUnitario = useRef(null);
    const { width } = useResize();
    const dispatch = useDispatch();
    const produto = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.itens.produto);
    const invalido = useShallowEqualSelector(state => state.entradaMercadoria.invalido);
    const editarProduto = useShallowEqualSelector(state => state.entradaMercadoria.cadastro.itens.produto.editarProduto);
    const verCadastro = useShallowEqualSelector(state => state.entradaMercadoria.visualizarEntrada);
    const [modalProduto, toggleModalProduto] = useModal(false);
    const [modalGrade, toggleModalGrade] = useModal(false);

    const [codProduto, setCodProduto] = useState(0)

    useEffect(() => {
        let valorUnitario = produto.valorUnitario
        let quantidade = produto.quantidade
        
        if (valorUnitario && quantidade){
            valorUnitario = removeMaskMoney(valorUnitario)
            let valorTotal = Number(valorUnitario) * (quantidade);
            valorTotal = valorTotal.toFixed(2)
            valorTotal = valorTotal.toString().replace('.',',')
            maskMoeda(valorTotal)
            dispatch({ idStore, type: 'PRODUTO', value: { ...produto, valorTotal: valorTotal } })
        }
    }, [produto.valorUnitario, produto.quantidade, produto.descricao])

    function pesquisaProdutos(pesquisaTransportadora) {
        controllerProdutos.GetProdutos(pesquisaTransportadora);
    }

    function confirmarProduto(codigoProduto) {
        controllerProdutos.GetProduto(codigoProduto, () => {
            toggleModalProduto();
            refQuantidade.current.focus()
        });
        setCodProduto(codigoProduto)
    }
      function adicionarProduto(){  
        let invalido =  controllerProdutos.addProdutos(produto)
        if (invalido) {

            if (invalido === 'quantidade')
                refQuantidade.current.focus()

            if (invalido === 'valorUnitario')
                refValorUnitario.current.focus()

            dispatch({ idStore, type: 'INVALIDO', value: { [invalido]: true } })
        } else if (produto.tipoGrade) {
            dispatch({ idStore, type: 'INVALIDO' }) 
            toggleModalGrade()
        }
        else{
            dispatch({ idStore, type: 'INVALIDO' }) 
        }
    }

    function cleanProduto() {
        dispatch({ idStore, type: 'CLEAN_PRODUTO' });
    }

    function onChange(value, stateName) {
        dispatch({ idStore, type: 'PRODUTO', value: { ...produto, [stateName]: value } })
        dispatch({ idStore: 'ENTRADA_MERCADORIA', type: 'INVALIDO', campo: stateName, value: false });
    }
    function onChangeValor(value) {
        value = maskMoeda(value)
        dispatch({ idStore, type: 'PRODUTO', value: { ...produto, "valorUnitario": value } })
    }

    async function onKeyPress(event) {
        if (event.key === 'Enter') {
            adicionarProduto()
        }
    }

    async function handleKeyPress(event) {
        if (event.key === 'Enter') {

            if (produto.codBarras) {
                let codProduto = await controllerProdutos.GetProdutosKeyPress(produto);
                if (!codProduto) {
                    dispatch({
                        idStore,
                        type: 'PRODUTO',
                        value: {
                            codProdutoItem: '',
                            codProduto: 0,
                            codBarras: produto.codBarras,
                            nome: '',
                            quantidade: '',
                            valorUnitario: '',
                            valorTotal: '',
                            unidade: ''
                        }
                    })
                    toggleModalProduto();
                } else {
                    refQuantidade.current.focus()
                }
            }
            else {
                dispatch({
                    idStore,
                    type: 'PRODUTO',
                    value: {
                        codProdutoItem: '',
                        codProduto: 0,
                        codBarras: produto.codBarras,
                        nome: '', quantidade: '',
                        valorUnitario: '',
                        valorTotal: '',
                        unidade: ''
                    }
                })
                toggleModalProduto();
            }
        }
    }
    return (
        <>
            <Row  className="justify-content-center padding-padrao-row">
            <div className={ verCadastro ? 'ver-cadastro' : ''}>
            </div>
                <Col className="padding-padrao-col" lg={width >= 1478 ? 3 : 4}>
                    <Row  className="padding-padrao-row">
                        <Col  className="padding-padrao-col">
                            <label>Número / Cód. barras<span className="error"> *</span></label>
                            <FormGroup className={invalido.codBarras ? "has-danger" : ''}>
                                <Input
                                    type="text"
                                    autoComplete="off"
                                    value={produto.codBarras? produto.codBarras : produto.numProduto}
                                    onChange={e => onChange(e.target.value, "codBarras")}
                                    onKeyPress={e => handleKeyPress(e)}
                                    disabled={editarProduto}
                                />
                            </FormGroup>
                        </Col>
                        <Button
                            style={{ marginTop: '25px', width: '40px', marginBottom: '20px' }}
                            type='button'
                            color="info"
                            onClick={toggleModalProduto}
                            disabled={editarProduto}
                        >
                            <Icon icon="search" className="fa-lg" />
                        </Button>
                    </Row>
                </Col>

                <Col className="padding-padrao-col" lg={width >= 1478 ? 3 : 8}>
                        <label>Descrição</label>
                        <FormGroup className={invalido.nome ? "has-danger" : ''}>
                            <Input
                                type="text"
                                autoComplete="off"
                                value={produto.nome}
                                disabled="true"
                            />
                        </FormGroup>
                </Col>

                <Col  className="padding-padrao-col" lg={width >= 1478 ? 3 : 6}>
                    <Row  className="padding-padrao-row">
                        <Col  className="padding-padrao-col" lg="6">
                            <label>Quantidade</label>
                            <FormGroup className={invalido.quantidade ? "has-danger" : ''}>
                                <Input
                                    innerRef={refQuantidade}
                                    type="number"
                                    autoComplete="off"
                                    value={produto.quantidade}
                                    min={"1"}
                                    onKeyPress={e => onKeyPress(e)}
                                    onChange={e => onChange((e.target.value), "quantidade")}
                                    onFocus={e => e.target.select()}
                                />
                            </FormGroup>
                        </Col>

                        <Col  className="padding-padrao-col" lg="6">
                            <label>Valor Unitário</label>
                            <FormGroup className={invalido.valorUnitario ? "has-danger" : ''}>
                                <Input
                                    innerRef={refValorUnitario}
                                    type="text"
                                    autoComplete="off"
                                    value={maskMoeda(produto.valorUnitario)}
                                    onKeyPress={(e) => onKeyPress(e)}
                                    onChange={e =>onChangeValor(e.target.value)}
                                    onFocus={e => e.target.select()}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>

                <Col  className="padding-padrao-col" lg={width >= 1478 ? 3 : 6}>
                    <Row  className="padding-padrao-row">
                        <Col className="padding-padrao-col" lg="5">
                            <label>Valor Total</label>
                            <Input
                                type="text"
                                autoComplete="off"
                                value={maskMoeda(produto.valorTotal)}
                                disabled="true"
                            />
                        </Col>

                        <Col className="padding-padrao-col" lg="5">
                            <label>Unidade</label>
                            <Input
                                type="text"
                                autoComplete="off"
                                value={produto.unidade}
                                disabled="true"
                            />
                        </Col>

                        <Col className="padding-padrao-col" lg="2">
                            <Button
                                style={{ marginTop: '25px', width: '100%', marginBottom: '20px', fontSize: '20px' }}
                                type='button'
                                color="info"
                                onClick={() => adicionarProduto()}
                            >
                                +
                                                </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {modalProduto ?
                <ModalProduto
                    isOpen={true}
                    toggle={toggleModalProduto}
                    getProduto={pesquisaProdutos}
                    confirmModal={confirmarProduto}
                    clean={cleanProduto} /> :
                null
            }

            {modalGrade ?
                <ModalGrade
                    isOpen={true}
                    toggle={toggleModalGrade}
                    codProduto={codProduto}
                    /> :
                null
            }
        </>
    )
}

export default FiltrosItens;