import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import ReactWizard from "~/components/Wizard/Wizard";
import ReactTable from "react-table";
import * as controllerTiposPagamentos from "~/controller/controllerTiposPagamentos";
import classNames from "classnames";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Select } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';
import { useHotkeys } from 'react-hotkeys-hook';
import {
    Card,
    Col,
    Button,
    ButtonGroup,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Row,
    TabContent,
    TabPane,
    Modal,
    ModalBody,
    FormGroup,
    Label,
} from "reactstrap";

// wizard steps
import Step1 from "~/views/TiposPagamentos/CadastroSteps/TipoPagamento.jsx";

var steps = [
    {
        stepName: "Tipo Pagamento",
        stepIcon: "tim-icons icon-notes",
        component: Step1,
        stepProps: {}
    },
];
const idStore = 'TIPOS_PAGAMENTOS';

const TiposPagamentos = () => {
    const notificacao = useSelector(state => state.notificacao);
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
    const { tiposPgtos, pesquisa, tipoPgto, inicioCadastro, tiposPgtoCopia } = useSelector(state => state.tiposPagamentos);
    const dispatch = useDispatch();
    const [dataTable, setDataTable] = useState([]);
    const [table, setTable] = useState(renderTable([]));
    const [horizontalTabs, setHorizontalTabs] = useState('pesquisar');
    const [tableVisible, setTableVisible] = useState(false);
    const [modalExcluir, setModalExcluir] = useState(false);
    const [modalInativar, setModalInativar] = useState(false);
    const [tipoPgtoSelected, setTipoPgtoSelected] = useState({ status: 0 });
    const [width, setWidht] = useState(window.innerWidth);
    const [loading, setLoading] = useState(false);
    const [stepActive, setStepActive] = useState(0);
    useEffect(() => {
        window.addEventListener('resize', updateWidth);
    }, [])

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', updateWidth);
        }
    }, [])

    function updateWidth() {
        setWidht(window.innerWidth);
    }

    useEffect(() => {
        steps.forEach(step => {
            step.stepProps = { ...step.stepProps, notificacao }
        });
    }, [notificacao])

    useEffect(() => {
        setDataTable(tableData(tiposPgtos));
        if (tiposPgtos.length > 0) {
            setTableVisible(true);
        } else {
            setTableVisible(false);
        }
    }, [tiposPgtos])

    useEffect(() => {
        setTable(renderTable());
    }, [dataTable, loading])

    useEffect(() => {
        steps.forEach(step => {
            step.stepProps = { ...step.stepProps, tipoPgto: tipoPgto }
        });
        setInicioCadastro(false)
    }, [tipoPgto])

    useEffect(() => {
        if (inicioCadastro)
            setInicioCadastro(false)
    }, [inicioCadastro])

    useHotkeys('shift+p', () => {
        controllerTiposPagamentos.Pesquisar(notificacao, dispatch, pesquisa);
    }, [codEmpresa, pesquisa])

    useHotkeys('shift+1', () => {
        changeActiveTab('pesquisar');
    }, [])

    useHotkeys('shift+2 ', () => {
        changeActiveTab("cadastrar");
        novoCadatro();
    }, [])

    useHotkeys('shift+l', () => {
        if (horizontalTabs === 'pesquisar') {
            handleLimpar();
        } else if (stepActive === 0) {
            dispatch({ idStore, type: 'CLEAN_TIPO_PGTO' });
            dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        }
    }, [horizontalTabs, stepActive])

    useHotkeys('shift+N', () => {
        dispatch({ idStore, type: 'CLEAN_TIPO_PGTO' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    }, [])

    useHotkeys('shift+s', () => {
        if ((stepActive === 0)) {
            if (tipoPgto.codTipoPgto === '') {
                controllerTiposPagamentos.CreateTipoPgto(notificacao, dispatch, tipoPgto);
            } else {
                controllerTiposPagamentos.UpdateTipoPgto(notificacao, dispatch, tipoPgto, tiposPgtoCopia);

            }
        }
    }, [tipoPgto, codEmpresa, stepActive])

    function changeActiveStep(valor) {
        setStepActive(valor)
    }


    function tableData(data) {
        return data.map((prop, key) => {
            return {
                id: key,
                codTipoPgto: prop['codTipoPgto'],
                descricao: prop['descricao'],
                status: prop['status'],
                flTipo: prop['flTipo'],
            }
        })
    }

    function renderTable() {
        return dataTable.map((obj, key) => {
            return {
                ...dataTable[key],
                actions: (
                    <div className="actions-right">

                        <ArrowTooltip title="Visualizar">
                            <Button
                                onClick={() => {
                                    handleVisualizarCadastro(obj);
                                }}
                                size="sm"
                                className={classNames("btn-icon btn-link")}
                                style={{ color: "#483D8B" }}

                            >
                                <Icon icon="eye" className="fa-lg" />
                            </Button>
                        </ArrowTooltip>
                        {
                            !loading ?
                                <ArrowTooltip title="Editar">
                                    <Button
                                        onClick={() => {
                                            handleEditarCadastro(obj);
                                        }}
                                        size="sm"
                                        className={classNames("btn-icon btn-link")}
                                        color="success"
                                    >
                                        <Icon icon="edit" className="fa-lg" />

                                    </Button>

                                </ArrowTooltip>
                                :
                                <Button
                                    onClick={() => {
                                        handleEditarCadastro(obj);
                                    }}
                                    size="sm"
                                    className={classNames("btn-icon btn-link")}
                                    color="success"
                                    disabled={true}
                                >
                                    <Icon icon="edit" className="fa-lg" />
                                </Button>
                        }
                        <ArrowTooltip title={obj.status === 0 ? "Inativar" : "Reativar"}>

                            <Button
                                onClick={() => {
                                    handleInativar(obj);
                                }}
                                className="btn-icon btn-link"
                                color="info"
                            >
                                {obj ? obj.status === 0 ?
                                    <Icon icon="ban" className="fa-lg" />
                                    :
                                    <Icon icon="redo" className="fa-lg" />
                                    :
                                    null
                                }
                            </Button>
                        </ArrowTooltip>
                        <ArrowTooltip title="Excluir">

                            <Button
                                onClick={() => { handleExcluir(obj) }}
                                className="btn-icon btn-link"
                                color="danger"
                            >
                                <Icon icon="trash-alt" className="fa-lg" />
                            </Button>
                        </ArrowTooltip>
                    </div >
                )
            };
        })
    }

    function changeActiveTab(tabName) {

        setHorizontalTabs(tabName);
        if (tabName === "pesquisar") {
            setTimeout(() => {
                dispatch({ idStore, type: 'VER_CADASTRO', value: false })
                document.getElementById('descricao').focus()
            }, 100)
        }
    };

    function OnChangeTipoPagamento(value, campo) {
        dispatch({ idStore, type: 'PESQUISA', campo, value });
        controllerTiposPagamentos.Pesquisar(notificacao, dispatch, { ...pesquisa, [campo]: value })
    }

    function handleOnChange(value, campo) {
        dispatch({ idStore, type: 'PESQUISA', campo, value });
    }

    function handleOnChangeAtivo(value) {
        dispatch({ idStore, type: 'PESQUISA', campo: 'ativos', value });
        controllerTiposPagamentos.Pesquisar(notificacao, dispatch, { ...pesquisa, ativos: value });
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            controllerTiposPagamentos.Pesquisar(notificacao, dispatch, pesquisa);
        }
    }

    function handlePesquisar(e) {
        e.preventDefault();
        controllerTiposPagamentos.Pesquisar(notificacao, dispatch, pesquisa);
    }

    function handleLimpar() {
        dispatch({ idStore, type: 'CLEAN_SEARCH' })
    }

    async function handleVisualizarCadastro(obj) {
        setLoading(true);

        if (!loading) {
            await controllerTiposPagamentos.GetTipoPgto(notificacao, dispatch, obj.codTipoPgto, () => {
                setHorizontalTabs("cadastrar")
                setInicioCadastro(true);
                dispatch({ idStore, type: 'CLEAN_INVALIDO' });
            });
        }
        setLoading(false);
        dispatch({ idStore, type: 'VER_CADASTRO', value: true })


    }

    async function handleEditarCadastro(obj) {

        setLoading(true);

        if (!loading) {
            await controllerTiposPagamentos.GetTipoPgto(notificacao, dispatch, obj.codTipoPgto, () => {
                setHorizontalTabs("cadastrar")
                setInicioCadastro(true);
                dispatch({ idStore, type: 'CLEAN_INVALIDO' });
            });
        }
        setLoading(false);
    }

    async function handleInativar(obj) {
        setTipoPgtoSelected(obj);
        toggleModalInativar();
    }

    async function handleExcluir(obj) {
        setTipoPgtoSelected(obj);
        toggleModalExcluir();
    }

    async function novoCadatro() {
        setInicioCadastro(true);
        dispatch({ idStore, type: 'CLEAN_TIPO_PGTO' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    }

    async function inativar() {
        await controllerTiposPagamentos.InativarTipoPgto(notificacao, dispatch, tipoPgtoSelected);
        controllerTiposPagamentos.Pesquisar(notificacao, dispatch, pesquisa);
    }

    async function excluir() {
        await controllerTiposPagamentos.ExcluirTipoPgto(notificacao, tipoPgtoSelected);
        controllerTiposPagamentos.Pesquisar(notificacao, dispatch, pesquisa);
    }

    function setInicioCadastro(valor) {
        dispatch({ idStore, type: 'INICIO_CADASTRO', value: valor });
    }

    async function finishButtonClick() {

        var error;
        if (tipoPgto.codTipoPgto === '') {
            error = await controllerTiposPagamentos.CreateTipoPgto(notificacao, dispatch, tipoPgto);
            handleError(error)
        } else {
            error = await controllerTiposPagamentos.UpdateTipoPgto(notificacao, dispatch, tipoPgto, tiposPgtoCopia);
            handleError(error)
        }

        function handleError(error) {
            if (!error) {
                dispatch({ idStore, type: 'CLEAN_TIPO_PGTO' });
                setInicioCadastro(true);
            }
        }
        return error

    }

    function toggleModalInativar() {
        setModalInativar(!modalInativar);
    }

    function toggleModalExcluir() {
        setModalExcluir(!modalExcluir);
    }

    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="12">
                    <Card>

                        <CardHeader className="text-center">
                            <CardTitle tag="h3">Tipos de Pagamentos</CardTitle>
                            <br />
                            <TabContent
                                className="tab-space"
                                activeTab={horizontalTabs}
                                style={{ marginTop: '-30px', marginBottom: '-70px' }}
                            >
                            </TabContent>
                        </CardHeader>

                        <CardBody>
                            <ButtonGroup style={{ width: '100%' }}>
                                <ArrowTooltip title='Shift+1'>
                                    <Button
                                        color={
                                            horizontalTabs === "pesquisar"
                                                ? "info"
                                                : ""
                                        }
                                        type="button"
                                        data-toggle="tab"
                                        className={classNames("btn-gp-padrao",
                                            horizontalTabs === "pesquisar"
                                                ? "active"
                                                : ""
                                        )
                                        }
                                        onClick={e => {
                                            changeActiveTab("pesquisar");
                                        }
                                        }
                                        style={{ width: '50%' }}
                                    >
                                        PESQUISAR
                                    </Button>
                                </ArrowTooltip>
                                <ArrowTooltip title='Shift+2'>
                                    <Button
                                        color={
                                            horizontalTabs === "cadastrar"
                                                ? "info"
                                                : ""
                                        }
                                        type="button"
                                        data-toggle="tab"
                                        className={classNames("btn-gp-padrao",
                                            horizontalTabs === "cadastrar"
                                                ? "active"
                                                : ""
                                        )
                                        }
                                        onClick={e => {
                                            changeActiveTab("cadastrar");
                                            novoCadatro();
                                        }
                                        }
                                        style={{ width: '50%' }}
                                    >
                                        CADASTRAR
                                    </Button>
                                </ArrowTooltip>
                            </ButtonGroup>
                            <TabContent
                                className="tab-space"
                                activeTab={horizontalTabs}
                                style={{ paddingBottom: '0px' }}
                            >
                                <TabPane tabId="pesquisar">
                                    <Row className="padding-padrao-row">
                                        <Col className="padding-padrao-col" lg="7" md="3">
                                            <label>Descrição</label>
                                            <Input
                                                id="descricao"
                                                type="text"
                                                autoComplete="off"
                                                value={pesquisa.descricao}
                                                onChange={e => handleOnChange(e.target.value, 'descricao')}
                                                onKeyPress={e => handleKeyPress(e)}
                                                maxLength="50"
                                            />
                                        </Col>

                                        <Col className="padding-padrao-col" lg="3" md="3">
                                            <label>Tipos</label>
                                            <Select
                                                value={{ value: pesquisa.flTipos }}
                                                options={[{ value: 0, label: 'Selecione' },
                                                { value: 1, label: 'Dinheiro' },
                                                { value: 2, label: 'Cartão' },
                                                { value: 3, label: 'Boleto' },
                                                { value: 4, label: 'Cheque' },
                                                { value: 5, label: 'Crédito' }]}
                                                onChange={value => OnChangeTipoPagamento(value.value, 'flTipos')}
                                            />
                                        </Col>

                                        <Col lg="1" md="4" className="text-center padding-padrao-col" style={{ paddingTop: '13px' }}>
                                            <Col className={width > 768 ? "text-left" : ''}>
                                                <FormGroup check className="form-check-radio" >
                                                    <Label check style={{ paddingRight: '5px' }}>
                                                        <Input type="radio"
                                                            onChange={() => handleOnChangeAtivo(2)}
                                                            checked={pesquisa.ativos === 2 ? true : false}
                                                        />
                                                        <span className="form-check-sign" />Todos
                                                </Label>

                                                    <Label check style={{ paddingRight: '5px' }}>
                                                        <Input type="radio"
                                                            onChange={() => handleOnChangeAtivo(0)}
                                                            checked={pesquisa.ativos === 0 ? true : false}
                                                        />
                                                        <span className="form-check-sign" />Ativos
                                                </Label>
                                                    <Label check style={{ paddingRight: '5px' }}>
                                                        <Input type="radio"
                                                            onChange={() => handleOnChangeAtivo(1)}
                                                            checked={pesquisa.ativos === 1 ? true : false}
                                                        />
                                                        <span className="form-check-sign" />Inativos
                                                </Label>
                                                </FormGroup>
                                            </Col>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <CardBody>
                                            <Col style={{ width: '100%' }}>
                                                <ArrowTooltip title='Shift+L'>
                                                    <Button
                                                        color='info'
                                                        style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                                        onClick={() => handleLimpar()}
                                                    >Limpar</Button>
                                                </ArrowTooltip>
                                                <ArrowTooltip title='Shift+P'>
                                                    <Button
                                                        color='info'
                                                        style={width > 550 ? { float: 'right', width: '150px' } : width < 550 ? { marginLeft: '0px', width: '100%' } : {}}
                                                        onClick={e => handlePesquisar(e)}
                                                    >Pesquisar</Button>
                                                </ArrowTooltip>
                                            </Col>
                                        </CardBody>
                                    </Row>

                                    <div style={tableVisible ? { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
                                        <ReactTable
                                            data={table}
                                            filterable={false}
                                            columns={
                                                [
                                                    {
                                                        Header: "Descrição",
                                                        accessor: "descricao"
                                                    },
                                                    {
                                                        Header: "Tipo",
                                                        accessor: "flTipo",
                                                        Cell: props => props.value === 1 ? 'DINHEIRO' : props.value === 2 ? 'CARTÂO' : props.value === 3 ? 'BOLETO' : props.value === 4 ? 'CHEQUE' : 'CRÉDITO',
                                                    },
                                                    {
                                                        Header: "Status",
                                                        accessor: "status",
                                                        Cell: ({ original }) => original.status === 0 ? "Ativo" : "Inativo"
                                                    },
                                                    {
                                                        Header: "",
                                                        accessor: "actions",
                                                        sortable: false,
                                                        filterable: false
                                                    }
                                                ]
                                            }
                                            minRows={1}
                                            showPaginationTop={false}
                                            showPaginationBottom={true}
                                            className="-striped -highlight"
                                            noDataText=""
                                            previousText="Anterior"
                                            nextText="Próximo"
                                            loadingText="Carregando"
                                            pageText="Página"
                                            ofText="de"
                                            rowsText='linhas'
                                        />
                                    </div>
                                </TabPane>

                                <TabPane tabId="cadastrar">
                                    <div style={{ marginTop: '-20px' }}>
                                        <ReactWizard
                                            steps={steps}
                                            navSteps={true}
                                            title=""
                                            description=""
                                            validate
                                            resizable={true}
                                            changeStep={changeActiveStep}
                                            previousButtonText="Voltar"
                                            finishButtonText="Finalizar"
                                            nextButtonText="Avançar"
                                            goToFirstStep={inicioCadastro}
                                            finishButtonClick={() => finishButtonClick()}
                                            finishButtonClasses={classNames("btn-wd btn-info",
                                                {
                                                    finishButtonMin:
                                                        width < 550
                                                },
                                                {
                                                    finishButtonRight:
                                                        !width < 550
                                                }
                                            )}
                                            nextButtonClasses={classNames("btn-wd btn-info",
                                                {
                                                    nextButtonMin:
                                                        width < 550

                                                },
                                                {
                                                    nextButtonRight:
                                                        !width < 550
                                                }
                                            )}
                                            previousButtonClasses={classNames("btn-wd",
                                                {
                                                    previousButtonMin:
                                                        width < 550
                                                },
                                                {
                                                    previousButtonRight:
                                                        !width < 550
                                                }
                                            )}
                                            progressbar
                                            color="blue"
                                        />
                                    </div>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </div>

            {/* Modal Inativar */}
            <Modal
                isOpen={modalInativar}
                toggle={toggleModalInativar}
            >
                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalInativar}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">{tipoPgtoSelected.status === 0 ? "Inativar" : "Ativar"} tipo de pagamento</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>
                            {tipoPgtoSelected.status === 0 ?
                                <>Tem certeza que deseja <b>inativar</b> esse tipo de pagamento da sua empresa?</>
                                : <>Tem certeza que deseja <b>ativar</b> esse tipo de pagamento em sua empresa?</>
                            }
                        </label>
                    </Col>

                </ModalBody>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { toggleModalInativar() }}
                                style={{ width: '150px' }}
                            >NÃO
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { toggleModalInativar(); inativar() }}
                                style={{ width: '150px' }}
                            >SIM
                            </Button>
                        </Col>
                    </Row>
                </CardBody>

            </Modal>
            {/* Modal Inativar*/}

            {/* Modal Excluir */}
            <Modal
                isOpen={modalExcluir}
                toggle={toggleModalExcluir}
            >

                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalExcluir}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Excluir tipo de pagamento</h6>
                </div>
                <ModalBody>

                    <Col className="text-center">
                        <label>
                            Tem certeza que deseja <b>excluir</b> esse tipo de pagamento?
                        </label>
                    </Col>

                </ModalBody>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => { toggleModalExcluir() }}
                                style={{ width: '150px' }}
                            >NÃO
                                            </Button>
                        </Col>
                        <Col className="text-center">
                            <Button
                                color="success"
                                type="button"
                                onClick={() => { toggleModalExcluir(); excluir() }}
                                style={{ width: '150px' }}
                            >SIM
                                            </Button>
                        </Col>
                    </Row>
                </CardBody>

            </Modal>
            {/* Modal Excluir*/}
        </>
    );
}

export default TiposPagamentos;
