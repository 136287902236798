export enum UsuarioStore {

    ID_STORE = 'USUARIOS'

}

export enum UsuarioTypes {

    PESQUISA = 'PESQUISA',

    FILIAIS = 'FILIAIS',
    EMPRESAS = 'EMPRESAS',
    EMPRESASUSER = 'EMPRESASUSER',

    USUARIOS = 'USUARIOS',
    GRUPOS = 'GRUPOS',
    USUARIO = 'USUARIO',

    ACESSO = 'ACESSO',
    GRUPOS_ACESSO = 'GRUPOS_ACESSO',

    FUNCIONARIO = 'FUNCIOANRIO',
    FUNCOES = 'FUNCOES',

    CADASTRO = 'CADASTRO',
    INVALIDO = 'INVALIDO',

    INICIO_CADASTRO = 'INICIO_CADASTRO',
    PROXIMO_CADASTRO = 'PROXIMO_CADASTRO',


    EMPRESASUSER_COPIA = 'EMPRESASUSER_COPIA',
    FUNCIONARIO_COPIA = 'FUNCIONARIO_COPIA',

    CLEAN_SEARCH = 'CLEAN_SEARCH',
    CLEAN_INVALIDO = 'CLEAN_INVALIDO',
    CLEAN_USUARIO = 'CLEAN_USUARIO',
    CLEAN_ACESSO = 'CLEAN_ACESSO',
    CLEAN_FUNCIONARIO = 'CLEAN_FUNCIONARIO',
    CLEAN_EMPRESA_USER = 'CLEAN_EMPRESA_USER',

    VERIFICATION_USER = 'VERIFICATION_USER',

    CHANGE_TELA = 'CHANGE_TELA',

    SCREEN = 'SCREEN',

    WIZARD_STEP = 'WIZARD_STEP',

    ADD_EMPRESA_USER = 'ADD_EMPRESA_USER',

    VER_CADASTRO = 'VER_CADASTRO',

    VERIFICATION_SALVAR = 'VERIFICATION_SALVAR',

    EDITAR = 'EDITAR'

}

export type UsuarioAction = {

    idStore: UsuarioStore.ID_STORE,
    type: UsuarioTypes,
    pesquisa?: Pesquisa,
    usuarios?: IUsuarioState['usuarios']
    usuario?: IUsuarioState['usuario']
    screen?: IUsuarioState['screen']
    currentStep?: IUsuarioState['currentStep']
    empresas?: IUsuarioState['empresas']
    filiais?: IUsuarioState['filiais']
    empresasUser?: IUsuarioState['empresasUser']
    invalido?: IUsuarioState['invalido']
    funcionario?: IUsuarioState['funcionario']
    acesso?: IUsuarioState['acesso']
    inicioCadastro?: IUsuarioState['inicioCadastro']
    proximoCadastro?: IUsuarioState['proximoCadastro']
    funcoes?: IUsuarioState['funcoes']
    grupos?: IUsuarioState['grupos']
    grupoIndex?: number | any,
    telaIndex?: number | any,
    value?: any,
    verificationUser?: IUsuarioState['verificationUser']
    verCadastro?: IUsuarioState['verCadastro']
    verificationSalvar?: IUsuarioState['verificationSalvar']
    gruposAcessoUsuario?: IUsuarioState['gruposAcessoUsuario']
    editar?: IUsuarioState['editar']

}

export interface IUsuarioState {
    pesquisa: Pesquisa,
    usuario: Usuario,
    acesso: Acesso,

    funcionario: Funcionario,
    invalido: Invalido,

    filiais: any[],
    empresas: any[],
    empresasUser: any[],
    funcoes: any[],
    gruposAcessoUsuario: any[],

    inicioCadastro: boolean,
    proximoCadastro: boolean
    usuarios: any[],

    screen: number,
    currentStep: number,
    status: number,
    verificationUser: boolean,
    verCadastro: boolean,

    funcionarioCopia: any[],
    empresasCopia: any[],
    empresasUserCopia: any[],

    grupos: any[],

    verificationSalvar: boolean,

    editar: boolean
}

interface Pesquisa {
    nome: string,
    email: string,
    cpf: string,
    funcao: string,
    ativos: string
}

export interface Funcionario {
    codFunc: string,
    nome: string,
    numeroFuncionario: string,
    emailFunc: string,
    telefone: string,
    dtNascimento: string,
    genero: string,
    cpf: string,
    rg: string,
    orgaoEmissor: string,
    dtEmissao: string,
    codFuncao: number,
    descFuncao: string
}
interface Invalido {
    nome: boolean,
    nomeFunc: boolean,
    email: boolean,
    emailFunc: boolean,
    cpf: boolean,
    cpfFunc: boolean
    empresas: boolean,
    dtNascimento: boolean,
    dtEmissao: boolean
}
export interface Usuario {
    nome: string,
    codEmpresa: string,
    codFilial: string,
    codUser: string,
    apelido: string,
    email: string,
    cpf: string,
    foto: string,
}

export interface Acesso {
    codEmpresaFilial: number,
    codGrupoAcesso: string,
    nomeGrupo: string
}
