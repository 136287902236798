import React, { useState } from "react";
import ReactTable from "react-table";

import { Col, Row } from "reactstrap";

import useShallowEqualSelector from "../../../../Util/Hooks/useShallowEqualSelector";
import AppState from "../../../../store/storeType";
import { maskMoeda } from "../../../../Util/mascaras";





const ListDetalhamento = function () {
    const data = useShallowEqualSelector<AppState, any>(state => state.fechamentoCaixa.detalhamento)

    const setmaskMoeda = (value: String | Number): String => {

        let valor: String = Number(value).toFixed(2)
        valor = valor.replace('.', '')
        valor = valor.replace(',', '.')

        return maskMoeda(valor)
    }

    return (
        <>
            <div style={{ transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1' }} >
                <ReactTable
                    data={data}
                    filterable={false}
                    NoDataComponent={() => (<></>)}
                    columns={[
                        {
                            Header: "",
                            Cell: (prop) =>
                                <>
                                    <Row style={{ borderBottom: "1px solid #606060", padding: 3 }}>
                                        <Col md={9} style={{ fontSize: 15 }}>
                                            TOTAL VENDIDO
                                    </Col>
                                        <Col md={3} style={{ fontSize: 20, textAlign: 'right' }}>
                                            <b>{setmaskMoeda(prop.original.totalVendido)}</b>
                                        </Col>
                                    </Row>
                                    <Row style={{ borderBottom: "1px solid #606060", padding: 3 }}>
                                        <Col md={9} style={{ fontSize: 15 }}>
                                            ABERTURA CAIXA
                                 </Col>
                                        <Col md={3} style={{ fontSize: 17, textAlign: 'right' }}>
                                            {setmaskMoeda(prop.original.aberturaCaixa)}
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Row style={{ borderBottom: "1px solid #606060" }}>
                                        <Col md={9} style={{ fontSize: 15 }}>TROCO</Col>
                                        <Col md={3} style={{ fontSize: 17, color: '#999999', textAlign: 'right' }}> {setmaskMoeda(prop.original.troco)}</Col>
                                    </Row>
                                    <Row style={{ borderBottom: "1px solid #606060" }}>
                                        <Col md={7} style={{ fontSize: 15 }}>TOTAL À VISTA </Col>
                                        <Col md={2} style={{ fontSize: 15, color: '#00b300' }}> <b>+R$</b></Col>
                                        <Col md={3} style={{ fontSize: 17, color: '#00b300', textAlign: 'right' }}> {setmaskMoeda(prop.original.totalAVista)}</Col>
                                    </Row>
                                    <Row style={{ borderBottom: "1px solid #606060" }}>
                                        <Col md={7} style={{ fontSize: 15 }}>TOTAL À PRAZO </Col>
                                        <Col md={2} style={{ fontSize: 15, color: '#00b300' }}><b>+R$</b></Col>
                                        <Col md={3} style={{ fontSize: 17, color: '#00b300', textAlign: 'right' }}> {setmaskMoeda(prop.original.totalPrazo)}</Col>
                                    </Row>
                                    <Row style={{ borderBottom: "1px solid #606060" }}>
                                        <Col md={7} style={{ fontSize: 15 }}>TOTAL RECEBIDO PARCELA </Col>
                                        <Col md={2} style={{ fontSize: 15, color: '#00b300' }}> <b>+R$</b></Col>
                                        <Col md={3} style={{ fontSize: 17, color: '#00b300', textAlign: 'right' }}> {setmaskMoeda(prop.original.totalParcela)}</Col>
                                    </Row>
                                    <Row style={{ borderBottom: "1px solid #606060" }}>
                                        <Col md={7} style={{ fontSize: 15 }}>TOTAL DESCONTO </Col>
                                        <Col md={2} style={{ fontSize: 15, color: '#e60000' }}> <b>-R$</b></Col>
                                        <Col md={3} style={{ fontSize: 17, color: '#e60000', textAlign: 'right' }}> {setmaskMoeda(prop.original.totalDesconto)}</Col>
                                    </Row>
                                    <Row style={{ borderBottom: "1px solid #606060", marginBottom: 5 }}>
                                        <Col md={7} style={{ fontSize: 15 }}>TOTAL RETIRADO </Col>
                                        <Col md={2} style={{ fontSize: 15, color: '#e60000' }}> <b>-R$</b></Col>
                                        <Col md={3} style={{ fontSize: 17, color: '#e60000', textAlign: 'right' }}> {setmaskMoeda(prop.original.totalRetirado)}</Col>
                                    </Row>
                                    <Row style={{ borderBottom: "1px solid #606060", padding: 7, }}>
                                        <Col md={9} style={{ fontSize: 15, paddingLeft: 59 }}> SALDO TOTAL </Col>
                                        <Col md={3} style={{ fontSize: 17, textAlign: 'right' }}> {setmaskMoeda(prop.original.saldoTotal)} </Col>
                                    </Row>
                                    <Row style={{ padding: 7 }}>
                                        <Col md={9} style={{ fontSize: 15, paddingLeft: 59 }}> SALDO CAIXA </Col>
                                        <Col md={3} style={{ fontSize: 20, textAlign: 'right' }}> <b>{setmaskMoeda(prop.original.saldoCaixa)}</b></Col>
                                    </Row>
                                </>
                        },
                    ]

                    }
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    noDataText=""
                    pageSize={data.length}
                />
            </div>
        </>
    )
}

export default ListDetalhamento