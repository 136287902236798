export enum FaturamentoStore {
    ID_STORE = 'FATURAMENTO'
}

export enum FaturamentoTypes {
    CLEAN_ALL = 'CLEAN_ALL',
    CLEAN = 'CLEAN',
    PAGAMENTOS = 'PAGAMENTOS',
    PARCELA = 'PARCELA',
    LANCAMENTOS = 'LANCAMENTOS',
    LANCAMENTO = 'LANCAMENTO',
    TOTAIS = 'TOTAIS',
    TECLADO = 'TECLADO',
    PAGAMENTO_SELECIONADO = 'PAGAMENTO_SELECIONADO',
    NUMERO_PEDIDO = 'NUMERO_PEDIDO',
    INFORMACOES = 'INFORMACOES',
    ID_FINANCEIRO = 'ID_FINANCEIRO'
}

export type FaturamentoAction = {
    idStore: FaturamentoStore.ID_STORE
    type: FaturamentoTypes,
    value? : any,
    lancamento? : IFaturamentoState['lancamento']
    lancamentos? : IFaturamentoState['lancamentos']
    pagamentos? : IFaturamentoState['pagamentos']
    totais? : IFaturamentoState['totais']
    teclado? : IFaturamentoState['teclado']
    pagamentoSelecionado? : IFaturamentoState['pagamentoSelecionado']
    numeroPedido? : IFaturamentoState['numeroPedido']
    informacoes? : any
}

export interface IFaturamentoState {
    idLancamento: number,
    numeroPedido: number,
    codigoCliente: number,
    codigoConta: number,
    codigoGrupoConta: number,
    codigoSubGrupoConta: number,
    codigoFuncionario: number,
    lancamento: {
        parcela: string,
        valor: string,
        firstVencimento: string,
    },
    totais: {
        totalLancado: string,
        totalRestante: string,
        subTotal: string,
        descontos: string,
        total: string,
        troco: string
    }
    pagamentoSelecionado: IFormaPagamento,
    pagamentos: IFormaPagamento[],
    teclado: boolean,
    lancamentos: any[]
}

export interface IFormaPagamento {
    codForma: number
    codTipoPgto: number
    descricao: string
    descricaoTipo: string
    qtdDiasEntreParcelas: number
    diasPrazo: number
    percentualTarifa: number
    flAbreCaixa: number
    flretirada: number
    flaVista: number
    flBaixaAutomatica: number
    status: number
}