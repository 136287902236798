export enum InventarioStore {
    ID_STORE = 'INVENTARIO',
}

export enum InventarioTypes {
    CLEAN_ALL = 'CLEAN_ALL',
    CLEAN_PESQUISA = 'CLEAN_PESQUISA',
    PESQUISA = 'PESQUISA',
    LIST_ITENS = 'LIST_ITENS',
    LIST_ITENS_PESQUISA = 'LIST_ITENS_PESQUISA',
    LIST_ATUALIZAR = 'LIST_ATUALIZAR',
    LIST_GRUPO = 'LIST_GRUPO',
    LIST_SUBGRUPO = 'LIST_SUBGRUPO',
    LIST_GRADE = 'LIST_GRADE',
    LIST_GRADE_COPIA = 'LIST_GRADE_COPIA',
    GRADE_SELECIONADA = 'GRADE_SELECIONADA',
    SCREEN = 'SCREEN',
    EMPRESAS = 'EMPRESAS',
    SET_EMPRESAS = 'SET_EMPRESAS',
    FUNCIONARIOS = 'FUNCIONARIOS',
    MODAL_FUNCIONARIOS = 'MODAL_FUNCIONARIOS',
    CLEAN_MODAL_FUNCIONARIO = 'CLEAN_MODAL_FUNCIONARIO',
    LIST_ITENS_INVENTARIO = 'LIST_ITENS_INVENTARIO',
    LIST_GRADE_PESQUISA = 'LIST_GRADE_PESQUISA'
}

export type InventarioAction = {
    idStore: InventarioStore.ID_STORE
    type: InventarioTypes
    value?: any,
    pesquisa?: IInventarioState['pesquisa'],
    listItens?: IInventarioState['listItens'],
    listItensPesquisa?: IInventarioState['listItensPesquisa'],
    listAtualizacao?: IInventarioState['listAtualizacao']
    listGrade?: IInventarioState['listGrade']
    listGradeCopia?: IInventarioState['listGradeCopia']
    gradeSelecionada?: IInventarioState['gradeSelecionada']
    screen?: IInventarioState['screen'],
    empresas?: IInventarioState['empresas'],
    list?: any[] | undefined,
    funcionarios?: any[],
    modalFuncionarios?: any,
    listItensInventario?: IInventarioState['listItensInventario'],
    listGradePesquisa?: IInventarioState['listGradePesquisa']
}

export interface IInventarioState {
    pesquisa: {
        codigoDescricao: string,
        codigoGrupo: string,
        descricaoGrupo: string,
        codigoSubGrupo: string,
        descricaoSubGrupo?: string,
        quantidade?: string,
        codFilial?: string | any,
        dataInicio?: Date | any,
        dataFinal?: Date | any,
        nome?: string,
        codigoUser?: any,
        cpf?: string
    },
    modalFuncionarios: {
        cpf: string,
        nomeEmail: string,
        codUser: string | null
    },
    gradeSelecionada: {
        codigoProduto: number,
        codigoTipoGrade: number,
        quantidadeAtualizar: number
    },
    listGrupo: any[],
    listSubGrupo: any[],
    listItens: any[],
    listItensPesquisa: any[],
    listAtualizacao: any[],
    listGrade: IGrade[],
    listGradeCopia: IGrade[],
    screen: number | any,
    empresas: any[] | any,
    funcionarios: any[] | undefined,
    listItensInventario: any[],
    listGradePesquisa: IGrade[]
}
interface IGrade {
    codGrade: string,
    descricao: string,
    dtHoraAtualização: string,
    estoqueAtual: number,
    estoqueAtualizar: number,
    estoqueAnterior: number,
    estoqueInventario: number,
    sigla: string,
    status: number,
    atualizar: boolean
}
