import React from "react";
import { useDispatch } from "react-redux";

import {
    Row,
    Col,
    CardBody,
    Button
} from "reactstrap";

import {
    SaidaDiversaStore,
    Produto
} from '../../../../../store/reducers/Movimentacao/SaidaDiversa/types';

import useModal from "../../../../../Util/Hooks/useModal";
import useResize from "../../../../../Util/Hooks/useResize";

import ModalOperacao from '../../Modals/ModalOperacao'
import ModalFaturamento from '../../../../../components/Modals/Faturamento'

import { OperacaoBox } from "../../Components/index";
import { pesquisaOperacao } from '../../../../../controller/Movimentacao/controllerSaidaDiversa'

import DadosProdutos from './DadosProduto';
import RenderTable from './RenderTable';

import * as controllerSaida from '../../../../../controller/Movimentacao/controllerSaidaDiversa'

import { setProduto, addProduto } from "../../../../../store/reducers/Movimentacao/SaidaDiversa/actions";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import AppState from "../../../../../store/storeType";

const ProdutosSaida = () => {
    const idFatura = useShallowEqualSelector<AppState, number>(state => state.saidaDiversa.saida.idFatura);
    const dispatch = useDispatch();
    const [modalOperacao, toggleOperacao] = useModal(false);
    const [modalFaturamento, toogleFaturamento] = useModal(false)

    const { width } = useResize();

    async function Salvar() {
        controllerSaida.Salvar('Produtos')
    };

    function Limpar() {
        const produto: Produto = {
            numeroPedidoItem: 0,
            item: 0,
            codProduto: 0,
            descricao: '',
            numero: '',
            quantidade: '',
            valorTotal: '',
            valorUnitario: '',
            dataLancamento: '',
            status: 0,
            flControlaGrade: 0,
            grades: []
        }

        setProduto(produto)
        addProduto([])

    };

    function toggleModalOperacao() {
        toggleOperacao()
        dispatch({ idStore: SaidaDiversaStore.ID_STORE, type: 'OPERACOES', value: [] })
    }

    function confirmModal(operacao: any) {
        dispatch({ idStore: SaidaDiversaStore.ID_STORE, type: 'OPERACAO', value: operacao })
        toggleOperacao()
        dispatch({ idStore: SaidaDiversaStore.ID_STORE, type: 'OPERACOES', value: [] })
    }

    return (
        <>
            <Row className="justify-content-center">
                <Col>

                    <h4 style={{ textAlign: 'center' }}>Informe os produtos que constarão na saída</h4>
                    <Row>
                        <Col lg={width >= 1660 ? 7 : 12} style={width <= 1660 ? { display: 'flex', justifyContent: 'center' } : {}} >
                            <OperacaoBox onIconPress={() => toggleOperacao()} disabledButton={true} />
                        </Col>

                        <Col lg={width >= 1660 ? 5 : 12} >

                            <Row style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%', marginTop: 6 }} >
                                <Button
                                    type={"button"}
                                    color={"info"}
                                    onClick={() => controllerSaida.handleNovo()}
                                    style={{ minWidth: 100 }}
                                >
                                    <Icon icon="plus-circle" className="fa-lg" color="#FFF" pull="left" />{" "}
                                NOVO
                                </Button>
                                <Button
                                    type={"button"}
                                    color={"yellow"}
                                    onClick={() => Salvar()}
                                    disabled={!idFatura ? false : true}
                                    style={{ minWidth: 100 }}
                                >
                                    <Icon icon="save" className="fa-lg" color="#FFF" pull="left" />
                                    Salvar
                                </Button>
                                <Button
                                    type={"button"}
                                    color={"green"}
                                    onClick={() => controllerSaida.Finalizar(toogleFaturamento)}
                                    disabled={!idFatura ? false : true}
                                    style={{ minWidth: 100 }}
                                >
                                    <Icon icon="dollar-sign" className="fa-lg" color="#FFF" pull="left" />{" "}
                                    FINALIZAR
                                </Button>
                            </Row>

                        </Col>
                    </Row>

                    <CardBody>
                        <Row className="justify-content-center">

                            <fieldset style={{ border: 'solid 2px #5E6680', borderRadius: 5, width: '100%' }}>

                                <legend style={{ fontSize: '14px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
                                    DADOS DO PRODUTO
                                </legend>

                                <DadosProdutos />
                                <RenderTable />

                            </fieldset>
                        </Row>

                    </CardBody>
                </Col>

                {
                    modalOperacao ? <ModalOperacao isOpen={true} toggle={toggleModalOperacao} getOperacao={pesquisaOperacao} confirmModal={confirmModal} /> : null
                }

                {
                    modalFaturamento ? <ModalFaturamento isOpen={true} toogle={toogleFaturamento} /> : null
                }

            </Row>

        </>
    )
};

class Wizard extends React.Component {
    render() {
        return (
            <ProdutosSaida />
        )
    }
}

export default Wizard;