import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { maskCFOP } from '~/Util/mascaras';

import * as controllerCFOPs from "~/controller/controllerCFOPs";

import { Select } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';


import {
    Input,
    Row,
    Col,
    CardBody,
    Button,
    CardFooter,
    FormGroup,
} from "reactstrap";

const idStore = "CFOPS";


const CFOP = () => {
    const notificacao = useSelector(state => state.notificacao);
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
    const { cfop, cfopCopia, invalido, verCadastro } = useSelector(state => state.cfops);
    const dispatch = useDispatch();

    function onChange(value, stateName) {
        dispatch({ idStore, type: 'CFOP', campo: stateName, value: value });
        dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
    };

    async function Salvar() {

        if (cfop.codCfop === 0) {
            controllerCFOPs.CreateCFOP(notificacao, dispatch, codEmpresa, cfop);
        } else {
            controllerCFOPs.UpdateCFOP(notificacao, dispatch, codEmpresa, cfop, cfopCopia);
        }
    };

    function Limpar() {
        dispatch({ idStore, type: 'CLEAN_CFOP' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        document.getElementById('cadcfop').focus()
    };

    return (
        <>
            <Row className="justify-content-center">
            <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

            </div>
                <Col>
                    <h4 className="info-text">Preencha com as informações do CFOP</h4>
                    <CardBody>

                        <Row className="justify-content-center">

                            <Col md="10">

                                <Row>

                                    <Col className="padding-padrao-col" lg="3" md="3" sm="3">
                                        <label>CFOP <span className="error">*</span></label>
                                        <FormGroup className={invalido.cfop ? "has-danger" : ''}>
                                            <Input
                                                id="cadcfop"
                                                type="text"
                                                value={cfop.cfop}
                                                onChange={e => onChange(maskCFOP(e.target.value), 'cfop')}
                                                autoComplete="off"
                                                maxLength="5"                                      
                                            />
                                            <br></br>
                                        </FormGroup>
                                    </Col>

                                    <Col className="padding-padrao-col">
                                        <label>Tipo <span className="error">*</span></label>
                                        <Select
                                            first={'Selecione'}
                                            value={{ value: cfop.tipoCfop }}
                                            options={[
                                                { value: 0, label: 'Saída' },
                                                { value: 1, label: 'Entrada' },
                                                { value: 2, label: 'Devolução' }
                                            ]}
                                            onChange={value => onChange(value.value, 'tipoCfop')}                                           
                                        />
                                    </Col>

                                    <Col className="padding-padrao-col">
                                        <label>Estadual <span className="error">*</span></label>
                                        <Select
                                            first={false}
                                            isSearchable={false}
                                            value={{ value: cfop.estadual }}
                                            options={[
                                                { value: 0, label: 'Não' },
                                                { value: 1, label: 'Sim' },
                                            ]}
                                            onChange={value => onChange(value.value, 'estadual')}                                           
                                        />
                                    </Col>


                                </Row>

                                <Row>

                                    <Col className="padding-padrao-col" lg md sm>
                                        <label>Descrição <span className="error">*</span></label>
                                        <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                                            <Input
                                                id="descricao"
                                                type="text"
                                                value={cfop.descricao}
                                                onChange={e => onChange(e.target.value, 'descricao')}
                                                autoComplete="off"
                                                maxLength="100"
                                        
                                            />
                                            <br></br>
                                        </FormGroup>
                                    </Col>

                                </Row>

                            </Col>

                        </Row>

                    </CardBody>
                    <CardFooter className="text-center">
                        <Row>
                            <Col  style={{ display: 'flex', justifyContent: 'center' }}>
                                <ArrowTooltip title='Shift+N'>
                                    <Button className="btn-padrao" color="default" onClick={() => Limpar()}>Novo</Button>
                                </ArrowTooltip>
                                <ArrowTooltip title='Shift+S'>
                                    <Button className="btn-padrao" color="info" onClick={() => Salvar()}>Salvar</Button>
                                </ArrowTooltip>
                           </Col>
                        </Row>
                    </CardFooter>
                </Col>
            </Row>
        </>
    )
};

class Wizard extends React.Component {
    focusInput = () => {
        setTimeout(() => {
            document.getElementById('cadcfop').focus()
        }, 100)
    }

    render() {
        return (
            <CFOP />
        )
    }
}

export default Wizard;
