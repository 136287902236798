import React from 'react'
import { useEffect } from 'react';
import { Button, CardFooter, Col, Input, Modal, ModalBody, Row } from 'reactstrap'
import * as actionPDV from '../../../../store/reducers/Movimentacao/PedidoVenda/actions'
import { ISegurancaPedidoCredenciais } from '../../../../store/reducers/Movimentacao/PedidoVenda/types';
import AppState from '../../../../store/storeType';
import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector';

interface IPopUpSegurancaAcaoProps {
    isOpen: any;
    callback: any;
    toggle: any;
    tipoAcao: string;
}

const PopUpSegurancaAcao: React.FC<IPopUpSegurancaAcaoProps> = ({ isOpen, callback, toggle, tipoAcao }) => {
    const segurancaCredenciais = useShallowEqualSelector<AppState, ISegurancaPedidoCredenciais>(state => state.pedidoVenda.segurancaPedidoCredenciais);

    useEffect(() => {
        if(segurancaCredenciais.motivo !== '' || segurancaCredenciais.senha !== '' || segurancaCredenciais.usuario !== '') {
            actionPDV.cleanSegurancaPedidoCredenciais()
        }
    }, [])

    return(
        <Modal
            isOpen={isOpen}
            toggle={() => toggle && toggle()}
            id="modalSegurancaAcao"
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggle && toggle()}
                >
                    <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up">{tipoAcao === 'reAbrir' ? 'Atenção para Reabrir o Pedido faça autenticação' : 'Atenção para Excluir o Pedido faça autenticação'}</h6>
            </div>
            <ModalBody>
                <Row className="padding-padrao-row">
                    <Col className="padding-padrao-col">
                        <label htmlFor="">Usuário</label>
                        <Input 
                            type="text"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => actionPDV.setSegurancaPedidoCredenciais({...segurancaCredenciais, usuario: e.target.value})}  
                        />
                    </Col>
                </Row>
                <Row className="padding-padrao-row">
                    <Col className="padding-padrao-col">
                        <label htmlFor="">Senha</label>
                        <Input 
                            type="password"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => actionPDV.setSegurancaPedidoCredenciais({...segurancaCredenciais, senha: e.target.value})} 
                        />
                    </Col>
                </Row>
                <Row className="padding-padrao-row">
                    <Col className="padding-padrao-col">
                        <label htmlFor="">Motivo</label>
                        <textarea 
                            className="form-control"
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => actionPDV.setSegurancaPedidoCredenciais({...segurancaCredenciais, motivo: e.target.value})}
                        ></textarea>
                    </Col>
                </Row>
            </ModalBody>
            <CardFooter style={{ backgroundColor: 'transparent' }}>
                <Row className="justify-content-center padding-padrao-row">
                    <Col className="text-center padding-padrao-col">
                        <Button 
                            color="danger"
                            type="button"
                            onClick={() => toggle && toggle()}
                            style={{ width: '150px' }}
                        >CANCELAR</Button>
                    </Col>
                    <Col className="text-center padding-padrao-col">
                        <Button
                            color="success"
                            type="button" 
                            onClick={() => callback()}
                            style={{ width: '150px' }}
                        >CONFIRMAR</Button>
                    </Col>
                </Row>
            </CardFooter>
        </Modal>
    )
}

export default PopUpSegurancaAcao