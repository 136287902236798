import moment from "moment";

export function removeMaskMoney(value: string | Number): string {

    var newValue: string = value.toString()
    newValue = newValue.replace(/\./g, "");
    newValue = newValue.replace(",", ".");
    return newValue
    
}


export function reformatDate(type: 'formatDate' | 'reformatDate'  , value: string | Number) {
    if(type === 'formatDate'){
        var newValue = moment(value.toString().replace(/\//g, '-'), 'YYYY-MM-DD').format('DD-MM-YYYY')
        return newValue
    } else {
        var newValue = moment(value.toString().replace(/\//g, '-'), 'DD-MM-YYYY').format('YYYY-MM-DD')
        return newValue
    }
}