import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerSubgruposProduto from "~/controller/controllerSubgruposProduto";
import history from "~/Util/history";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Select, Switch } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  Modal,
  ModalBody
} from "reactstrap";

const idStore = "SUBGRUPOS_PRODUTO";

const Subgrupo = () => {
  const notificacao = useSelector(state => state.notificacao);
  const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
  const { pesquisa, grupos, subgrupo, subgrupoCopia, invalido, vinculos, verCadastro } = useSelector(state => state.subgruposProduto);
  const [modalInativar, setModalInativar] = useState(false);
  const dispatch = useDispatch();

  function onChangeGrupo(value) {
    dispatch({ idStore, type: 'SUBGRUPO', campo: 'codGrupo', value: value.value });
    dispatch({ idStore, type: 'SUBGRUPO', campo: 'grupo', value: value.label });
  }

  function onChange(value, stateName) {
    dispatch({ idStore, type: 'SUBGRUPO', campo: stateName, value: value });
    dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
  };

  async function onChangeAtivo(e) {
    await controllerSubgruposProduto.ExisteVinculo(notificacao, dispatch, codEmpresa, subgrupo)
    toggleModalInativar()
  };

  function inativar() {
    if (subgrupo.codSubgrupo !== '') {
      controllerSubgruposProduto.Inativar(notificacao, dispatch, codEmpresa, subgrupo, pesquisa);
    }
    dispatch({ idStore, type: 'SUBGRUPO', campo: 'status', value: subgrupo.status === 1 ? 0 : 1 });
  }

  async function Salvar(e) {
    e.preventDefault();
    if (subgrupo.codSubgrupo === '') {
      controllerSubgruposProduto.CreateSubgrupo(notificacao, dispatch, codEmpresa, subgrupo);
    } else {
      controllerSubgruposProduto.UpdateSubgrupo(notificacao, dispatch, codEmpresa, subgrupo, subgrupoCopia);
    }
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_SUBGRUPO' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('cadnome').focus()
  };

  function TelaGrupos(e) {
    e.preventDefault();
    history.push('/gruposProduto');
  };

  function toggleModalInativar() {
    setModalInativar(!modalInativar);
  }

  return (
    <>
      <Row className="justify-content-center">
      <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

      </div>
        <Col>
          <h4 className="info-text">Preencha com as informações do subgrupo</h4>
          <CardBody>

            <Row className="justify-content-center">

              <Col md="10" className="text-right">
                <Switch
                  componentleft={<label className={subgrupo.status === 0 ? `text-success text-center` : "text-warning text-center"}>{subgrupo.status === 0 ? "Ativo" : "Inativo"}</label>}
                  checked={subgrupo.status === 0}
                  onChange={e => onChangeAtivo(e)}
                  disabled={subgrupo.codSubgrupo === "" || verCadastro }
                />
              </Col>

              <Col md="10">

                <Row className="padding-padrao-row">

                  <Col className="padding-padrao-col" lg="3">
                    <label>Grupo<span className="error"> *</span></label>
                    <Select
                      id="cadGruposSelect"
                      first="Selecione"
                      value={{ value: subgrupo.codGrupo, label: subgrupo.grupo }}
                      select={{ value: "codGrupo", label: "nome" }}
                      options={grupos}
                      onChange={value => onChangeGrupo(value)}
                      isSearchable={true}
                      autoComplete="off"
                      
                    />
                  </Col>

                  <Col className="padding-padrao-col" lg md sm="7">
                    <label>Nome<span className="error"> *</span></label>
                    <FormGroup className={invalido.nome ? "has-danger" : ''}>
                      <Input
                        id="cadnome"
                        type="text"
                        value={subgrupo.nome}
                        onChange={e => onChange(e.target.value, 'nome')}
                        autoComplete="off"
                        maxLength="30"
                    
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

                <Row className="padding-padrao-row">

                  <Col className="padding-padrao-col" lg md sm="7">
                    <label>Descrição<span className="error"> *</span></label>
                    <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                      <Input
                        id="descricao"
                        type="text"
                        value={subgrupo.descricao}
                        onChange={e => onChange(e.target.value, e.target.id)}
                        autoComplete="off"
                        maxLength="50"
                    
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                  <ArrowTooltip title='Shift+N'>
                    <Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Novo</Button>
                 </ArrowTooltip>
                  <ArrowTooltip title='Shift+S'>
                    <Button className="btn-padrao" color="info" onClick={(e) => { Salvar(e);}}>Salvar</Button>
                 </ArrowTooltip>
                </Col>
                <Col lg="auto" className={window.innerWidth > 660 ? "text-left" : "text-center"} style={window.innerWidth > 660 ? { paddingLeft: '15px' } : {}}>
                  <Button className="btn-padrao" color="warning" onClick={(e) => TelaGrupos(e)}><Icon icon="caret-left" className="fa-lg" /> Grupos</Button>
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>

      {/* Modal Inativar */}
      <Modal
        isOpen={modalInativar}
        toggle={toggleModalInativar}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalInativar}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">{subgrupo.status === 0 ? "Inativar" : "Ativar"} subgrupo</h6>
        </div>
        <ModalBody>

          <Col className="text-center">
            <label>
              {vinculos > 0 ?
                <>O subgrupo que você está tentando inativar possui produtos vinculados a ele, se continuar os produtos serão inativados.<br /></>
                : null
              }
              {subgrupo.status === 0 ?
                <>Tem certeza que deseja <b>inativar</b> esse subgrupo de produtos da sua empresa?</>
                : <>Tem certeza que deseja <b>ativar</b> esse subgrupo de produtos em sua empresa?</>
              }
            </label>
          </Col>

        </ModalBody>
        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => { toggleModalInativar() }}
                style={{ width: '150px' }}
              >NÃO
                                            </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => { toggleModalInativar(); inativar() }}
                style={{ width: '150px' }}
              >SIM
                                            </Button>
            </Col>
          </Row>
        </CardBody>

      </Modal>
      {/* Modal Inativar*/}

    </>
  )
};

class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('cadnome').focus()
    }, 100)
  }

  render() {
    return (
      <Subgrupo />
    )
  }
}

export default Wizard;
