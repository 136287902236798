import React from "react";
import { useDispatch } from 'react-redux';

import {
    Row,
    Col,
    Button
} from "reactstrap";

import { OperacaoBox } from "../../Components/index";

import useModal from '../../../../../Util/Hooks/useModal'
import useResize from "../../../../../Util/Hooks/useResize";

import ModalOperacao from '../../Modals/ModalOperacao'
import ModalFaturamento from '../../../../../components/Modals/Faturamento'

import DadosReferencia from './DadosReferencia';

import * as controllerSaida from '../../../../../controller/Movimentacao/controllerSaidaDiversa'
import * as action from "../../../../../store/reducers/Movimentacao/SaidaDiversa/actions";

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import AppState from "../../../../../store/storeType";

const idStore = "SAIDA_DIVERSA";

const NFeReferencia = () => {
    const numeroSaida = useShallowEqualSelector<AppState, number>(state => state.saidaDiversa.saida.idSaida);
    const idFatura = useShallowEqualSelector<AppState, number>(state => state.saidaDiversa.saida.idFatura);
    const dispatch = useDispatch();
    const [modalOperacao, toggleOperacao] = useModal(false);
    const [modalFaturamento, toogleFaturamento] = useModal(false);

    const { width } = useResize()

    function pesquisaOperacao({ numeroOperacao, descricaoOperacao }: any) {
        controllerSaida.pesquisaOperacao(numeroOperacao, descricaoOperacao)
    }

    function toggleModalOperacao() {
        toggleOperacao()
        dispatch({ idStore, type: 'OPERACOES', value: [] })
    }

    function confirmModal(operacao: any) {
        dispatch({ idStore, type: 'OPERACAO', value: operacao })
        toggleOperacao()
        dispatch({ idStore, type: 'OPERACOES', value: [] })
    }

    function Salvar() {
        action.setPopUp(false);
        controllerSaida.Salvar("Dados Iniciais");
    }

    return (
        <>
            <br />
            <Row className="justify-content-center">
                <Col>

                    <h4 style={{ textAlign: 'center' }}>Pesquisa pela nota na qual será referênciada</h4>
                    <Row>
                        <Col lg={width >= 1660 ? 7 : 12} style={width <= 1660 ? { display: 'flex', justifyContent: 'center' } : {}} >
                            <OperacaoBox onIconPress={() => toggleOperacao()} />
                        </Col>

                        <Col lg={width >= 1660 ? 5 : 12} >

                            <Row style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%', marginTop: 4 }} >
                                <Button
                                    type={"button"}
                                    color={"info"}
                                    onClick={() => controllerSaida.handleNovo()}
                                >
                                    <Icon icon="plus-circle" className="fa-lg" color="#FFF" pull="left" />{" "}
                                    NOVO
                                </Button>
                                <Button
                                    type={"button"}
                                    color={"info"}
                                    onClick={() => Salvar()}
                                    disabled={!idFatura ? false : true}
                                >
                                    <Icon icon="save" className="fa-lg" color="#FFF" pull="left" />
                                    Salvar
                                </Button>
                                <Button
                                    type={"button"}
                                    color={"green"}
                                    onClick={() => controllerSaida.Finalizar(toogleFaturamento)}
                                    disabled={!idFatura ? false : true}
                                >
                                    <Icon icon="dollar-sign" className="fa-lg" color="#FFF" pull="left" />{" "}
                                    FINALIZAR
                                </Button>
                            </Row>

                        </Col>
                    </Row>

                    <DadosReferencia />

                </Col>
            </Row>

            {
                modalOperacao ? <ModalOperacao isOpen={true} toggle={toggleModalOperacao} getOperacao={pesquisaOperacao} confirmModal={confirmModal} /> : null
            }

            {
                modalFaturamento ? <ModalFaturamento isOpen={true} toogle={toogleFaturamento} /> : null
            }

        </>
    )
}

class Wizard extends React.Component {
    render() {
        return (
            <NFeReferencia />
        )
    }
}

export default Wizard;