import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { maskNumbers } from '~/Util/mascaras';

import * as controllerPISCOFINSs from "~/controller/controllerPISCOFINSs";

import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';

import {
    Input,
    Row,
    Col,
    CardBody,
    Button,
    CardFooter,
    FormGroup,
} from "reactstrap";

const idStore = "PISCOFINS";

const PISCOFINS = () => {
    const notificacao = useSelector(state => state.notificacao);
    const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
    const { pisCofins, pisCofinsCopia, invalido, verCadastro } = useSelector(state => state.pisCofins);
    const dispatch = useDispatch();

    function onChange(value, stateName) {
        dispatch({ idStore, type: 'PISCOFINS', campo: stateName, value: value });
        dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
    };

    async function Salvar() {

        if (pisCofins.codPisCofins === 0) {
            controllerPISCOFINSs.Create(notificacao, dispatch, codEmpresa, pisCofins);
        } else {
            controllerPISCOFINSs.Update(notificacao, dispatch, codEmpresa, pisCofins, pisCofinsCopia);
        }
    };

    function Limpar() {
        dispatch({ idStore, type: 'CLEAN_PISCOFINS' });
        dispatch({ idStore, type: 'CLEAN_INVALIDO' });
        document.getElementById('cadpisCofins').focus()
    };

    return (
        <>
            <Row className="justify-content-center">
            <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

            </div>
                <Col>
                    <h4 className="info-text">Preencha com as informações do PIS / COFINS</h4>
                    <CardBody>

                        <Row className="justify-content-center">

                            <Col md="12">

                                <Row className="padding-padrao-row">

                                    <Col className="padding-padrao-col" lg="3" md="3" sm="3">
                                        <label>PIS / COFINS <span className="error">*</span></label>
                                        <FormGroup className={invalido.pisCofins ? "has-danger" : ''}>
                                            <Input
                                                id="cadpisCofins"
                                                type="text"
                                                value={pisCofins.pisCofins}
                                                onChange={e => onChange(maskNumbers(e.target.value), 'pisCofins')}
                                                autoComplete="off"
                                                maxLength="2"
                                            />
                                            <br></br>
                                        </FormGroup>
                                    </Col>

                                    <Col className="padding-padrao-col" lg md sm>
                                        <label>Descrição <span className="error">*</span></label>
                                        <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                                            <Input
                                                id="descricao"
                                                type="text"
                                                value={pisCofins.descricao}
                                                onChange={e => onChange(e.target.value, 'descricao')}
                                                autoComplete="off"
                                                maxLength="250"                                          
                                            />
                                            <br></br>
                                        </FormGroup>
                                    </Col>

                                </Row>

                            </Col>

                        </Row>

                    </CardBody>
                    <CardFooter className="text-center">
                        <Row>
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                <ArrowTooltip title='Shift+N'>
                                    <Button className="btn-padrao" color="default" onClick={() => Limpar()}>Novo</Button>
                                </ArrowTooltip>
                                <ArrowTooltip title='Shift+S'>
                                    <Button className="btn-padrao" color="info" onClick={() => Salvar()}>Salvar</Button>
                                </ArrowTooltip>
                            </Col>
                        </Row>
                    </CardFooter>
                </Col>
            </Row>
        </>
    )
};

class Wizard extends React.Component {
    focusInput = () => {
        setTimeout(() => {
            document.getElementById('cadpisCofins').focus()
        }, 100)
    }

    render() {
        return (
            <PISCOFINS />
        )
    }
}

export default Wizard;
