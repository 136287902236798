import React from "react";
import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
}
    from "reactstrap";
import TabContent from './TabContent/TabContent';
import TelaPesquisar from '../Recebimento/TabContent/Tabs/Pesquisar';
import TelaRecebimentosPagamentos from './TabContent/Tabs/RecebimentosPagamentos';
import './style.css'

const Tabs = [
    {
        tabName: "PESQUISAR",
        tabScreen: <TelaPesquisar />
    },
    {
        tabName: "RECEBIMENTOS / PAGAMENTOS",
        tabScreen: <TelaRecebimentosPagamentos />
    },
]

const RecebimentosPagamentos: React.FC = () => {

    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="12">
                    <Card style={{height:"100%", minHeight:"80vh"}}>
                        <CardHeader className="text-center">
                            <CardTitle tag="h3">Recebimento / Pagamentos de títulos (Créditos/Débitos)</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <TabContent tabs={Tabs} />
                        </CardBody>
                    </Card>
                </Col>
            </div>

        </>
    );
}

export default RecebimentosPagamentos