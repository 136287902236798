import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";

const idStore = 'FORMAS_PAGAMENTOS';

export function Pesquisar(notificacao, dispatch, pesquisa) {
    try {
        dispatch({ idStore, type: 'FORMAS_PGTOS', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/formaPgto/formasPgto?status=${pesquisa.ativos}&descricao=${pesquisa.descricao}&codTipoPgto=${pesquisa.codTipoPgto}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma forma de pagamento encontrado", 'info', 'icon-bulb-63', 2)
            }
            dispatch({ idStore, type: 'FORMAS_PGTOS', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as formas de pagamentos", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function GetFormaPgto(notificacao, dispatch, codForma, thenFunction){
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ":" + localStorage.getItem('TOKEN_KEY'))

        await axios({
            method: 'get',
            url: api.urlCadastro + `api/v1/formaPgto/formaPgto?codForma=${codForma}`, 
            headers: { Authorization: basicAuth }
        }).then(function (response){ 
                dispatch({ idStore, type: 'FORMA_PGTOS', value: response.data.records})
                dispatch({ idStore, type: 'FORMAS_PGTOS_COPIA' })
                thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}

export async function CreateFormaPgto(notificacao, dispatch, formaPgto){
    
    try{
        const basicAuth = 'Basic ' + btoa(api.apiUser + ":" + localStorage.getItem('TOKEN_KEY'))

        let invalido = false

        if (formaPgto.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }
        if (formaPgto.codTipoPgto === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codTipoPgto', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios({
            method: 'post',
            url: api.urlCadastro + `api/v1/formaPgto/create`,
            headers: { Authorization: basicAuth }, 
            data: {
                Descricao: String(formaPgto.descricao),
                codTipoPgto: parseInt(formaPgto.codTipoPgto),
                QtdDiasEntreParcelas:  parseInt(formaPgto.qtdDiasEntreParcelas),
                DiasPrazo: parseInt(formaPgto.diasPrazo),
                PercentualTarifa: parseFloat(formaPgto.percentualTarifa),
                FlAbreCaixa:  parseInt(formaPgto.flAbreCaixa),
                FlRetirada: parseInt(formaPgto.flRetirada),
                FlaVista: parseInt(formaPgto.flaVista),
                FlBaixaAutomatica:  parseInt(formaPgto.flBaixaAutomatica)
            }
        }).then( response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'FORMA_PGTO', campo: 'codForma', value: response.data.records })
            dispatch({ idStore, type: 'FORMAS_PGTOS_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function UpdateFormaPgto(notificacao, dispatch, formaPgto, formaPgtocopia){
    try {
        //Retorna se houve erro ou nao
        if (JSON.stringify(formaPgto) === JSON.stringify(formaPgtocopia)) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
            return false
        }

        const basicAuth = 'Basic ' + btoa(api.apiUser + ":" + localStorage.getItem('TOKEN_KEY'))

        let invalido = false

        if (formaPgto.descricao === '') {
            dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
            invalido = true
        }
        if (formaPgto.codTipoPgto === '' || formaPgto.codTipoPgto === "0") {
            dispatch({ idStore, type: 'INVALIDO', campo: 'codTipoPgto', value: true })
            invalido = true
        }

        if (invalido) {
            alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
            return true
        }

        return await axios ({
            method: 'put',
            url: api.urlCadastro + `api/v1/formaPgto/update?codForma=${formaPgto.codForma}`,
            headers: { Authorization: basicAuth },
            data: {
                Descricao: String(formaPgto.descricao),
                codTipoPgto: parseInt(formaPgto.codTipoPgto),
                QtdDiasEntreParcelas:  parseInt(formaPgto.qtdDiasEntreParcelas),
                DiasPrazo: parseInt(formaPgto.diasPrazo),
                PercentualTarifa: parseFloat(formaPgto.percentualTarifa),
                FlAbreCaixa:  parseInt(formaPgto.flAbreCaixa),
                FlRetirada: parseInt(formaPgto.flRetirada),
                FlaVista: parseInt(formaPgto.flaVista),
                FlBaixaAutomatica:  parseInt(formaPgto.flBaixaAutomatica)
            }
        }).then ( response => {
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            dispatch({ idStore, type: 'FORMA_PGTO', campo: 'codForma', value: response.data.records })
            dispatch({ idStore, type: 'FORMAS_PGTOS_COPIA' })
            return false
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return true
        });
    } catch {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
        return true
    }
}

export async function InativarFormaPgto(notificacao, dispatch, formaPgtoSelected) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios({
            method: 'put',
            url: api.urlCadastro + `api/v1/formaPgto/inativa?codForma=${formaPgtoSelected.codForma}&status=${formaPgtoSelected.status}`,
            headers: { Authorization: basicAuth }
        }).then(response => {
            dispatch({idStore, type: 'FORMA_PGTO', campo: 'status', value: formaPgtoSelected.status === 0 ? 1 : 0})
            alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
        }).catch(e => {

            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir ", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function ExcluirFormaPgto(notificacao, formaPgtoSelected) {
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios({
            method: 'delete',
            url: api.urlCadastro + `api/v1/formaPgto/delete?codForma=${formaPgtoSelected.codForma}`,
            headers: { Authorization: basicAuth }
        }).then(response => {
           
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function GetTiposPgto(notificacao, dispatch) {
    try {
        dispatch({ idStore, type: 'TIPOS_PGTOS', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/tiposPgto/tiposPgtos?status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            dispatch({ idStore, type: 'TIPOS_PGTOS', value: response.data.records })
        })
    } catch (e) {
        
    }
}

export async function GetTipoPgto(notificacao, dispatch, codTipoPgto){
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ":" + localStorage.getItem('TOKEN_KEY'))

        return await axios({
            method: 'get',
            url: api.urlCadastro + `api/v1/tiposPgto/tipoPgto?codTipoPgto=${codTipoPgto}`, 
            headers: { Authorization: basicAuth }
        }).then(function (response){ 
                let type = 'FORMA_PGTO';
                dispatch({ idStore, type, campo: 'flTipo', value: response.data.records.flTipo })
                return response.data.records.flTipo
        }).catch(e => {
            alertar(notificacao, 'br',  e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
        });
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os tipos de pagamento", 'warning', 'icon-alert-circle-exc', 2, e)
    }
}