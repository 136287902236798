import axios from "axios";
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import { maskCEST, maskNCM, maskMarkup, maskMoeda } from "~/Util/mascaras.js";
import store from "../store/stores";
import * as actions from "../store/reducers/Cadastro/Produto/actions";
import { removeMaskMoney } from "../Util/removeMascaras";

export function Pesquisar(codEmpresa, pesquisa, fornecedores) {
  const { notificacao } = store.getState();
  try {
    actions.setProdutos([]);

    let strFornecedores = "";
    if (fornecedores.length > 0) {
      fornecedores.forEach((fornecedor) => {
        if (strFornecedores) strFornecedores += `,${fornecedor}`;
        else strFornecedores = fornecedor;
      });
    }

    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));
    pesquisa.numeroProduto = pesquisa.numeroProduto.trim();
    axios
      .get(
        api.urlCadastro +
          `api/v1/produto/produtos?codEmpresa=${codEmpresa}&numProduto=${
            pesquisa.numeroProduto
          }&codGrupo=${pesquisa.codGrupo}&codSubgrupo=${
            pesquisa.codSubgrupo
          }&nome=${pesquisa.nome}&status=${pesquisa.ativos}&dtCadastro=${
            pesquisa.dtCadastro
          }&ncm=${pesquisa.ncm}&cest=${
            pesquisa.cest
          }&tipo=P&fornecedores=${strFornecedores}${pesquisa.flags.map(
            (flag) => `&${flag}=1`
          )}`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then((response) => {
        if (response.data.records.length === 0) {
          alertar(
            notificacao,
            "br",
            "Nenhum produto encontrado",
            "info",
            "icon-bulb-63",
            2
          );
        }

        response.data.records.forEach((produto, index) => {
          response.data.records[index].dtCadastro = new Date(
            produto.dtCadastro
          ).toLocaleString();
        });

        actions.setProdutos(response.data.records);
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : "Falha na conexão",
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível obter os produtos",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}

export function Inativar(codEmpresa, produto, pesquisa, fornecedores) {
  const { notificacao } = store.getState();
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));
    axios({
      method: "put",
      url:
        api.urlCadastro +
        `api/v1/produto/inativarProduto?codEmpresa=${codEmpresa}&codProduto=${produto.codProduto}&status=${produto.status}`,
      headers: { Authorization: basicAuth },
    })
      .then((response) => {
        alertar(
          notificacao,
          "br",
          response.data.message,
          "success",
          "icon-check-2",
          2
        );

        //Verificar
        actions.setProdutoCopia();
        Pesquisar(codEmpresa, pesquisa, fornecedores);
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : "Falha na conexão",
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível concluir",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}

export function Excluir(codEmpresa, produto, pesquisa, fornecedores) {
  const { notificacao } = store.getState();
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));
    axios({
      method: "delete",
      url:
        api.urlCadastro +
        `api/v1/produto/excluirProduto?codEmpresa=${codEmpresa}&codProduto=${produto.codProduto}&tipo=P`,
      headers: { Authorization: basicAuth },
    })
      .then((response) => {
        alertar(
          notificacao,
          "br",
          response.data.message,
          "success",
          "icon-check-2",
          2
        );
        Pesquisar(codEmpresa, pesquisa, fornecedores);
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : "Falha na conexão",
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível concluir",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}

export function GetGrupos(codEmpresa) {
  const { notificacao } = store.getState();
  try {
    actions.setGrupos([]);

    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    axios
      .get(
        api.urlCadastro +
          `api/v1/grupoProduto/grupos?codEmpresa=${codEmpresa}&status=0&tipo=P`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then((response) => {
        actions.setGrupos(response.data.records);
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível obter os grupos",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}

export function GetSubgrupos(codEmpresa, codGrupo, thenFunction) {
  const { notificacao } = store.getState();
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    axios
      .get(
        api.urlCadastro +
          `api/v1/subgrupoProduto/subgrupos?codEmpresa=${codEmpresa}&codGrupo=${codGrupo}&status=0`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then((response) => {
        thenFunction(response.data.records);
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível obter os grupos",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}

export function GetFornecedores(codEmpresa) {
  const { notificacao } = store.getState();
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    axios
      .get(
        api.urlCadastro +
          `api/v1/fornecedor/fornecedores?codEmpresa=${codEmpresa}&status=0`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then((response) => {
        actions.setFornecedores(response.data.records);
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível obter os fornecedores",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}

export async function GetProduto(codEmpresa, codProduto, thenFunction) {
  const { notificacao } = store.getState();
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    await axios
      .get(
        api.urlCadastro +
          `api/v1/produto/produto?codEmpresa=${codEmpresa}&codProduto=${codProduto}`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then(async (response) => {
        let produto = response.data.records;

        actions.setProduto({
          codProduto: produto.codProduto,
          status: produto.status,
          basico: {
            codGrupo: produto.codGrupo,
            codSubgrupo: produto.codSubgrupo,
            grupo: produto.grupo,
            subgrupo: produto.subgrupo,
            codBarras: produto.codBarras,
            numeroProduto: produto.numProduto,
            nome: produto.nome,
            descricao: produto.descricao,

            especificacoes: {
              unidade: produto.unidade,
              largura: produto.largura.toFixed(2),
              altura: produto.altura.toFixed(2),
              profundidade: produto.profundidade.toFixed(2),
              peso: produto.peso.toFixed(2),
              voltagem: produto.voltagem,
              garantiaFor: produto.garantiaForneMes,
              codFornecedor: produto.codForne,
              fornecedor: "",
              precoCusto: maskMoeda(produto.precoCustoAtual.toFixed(2)),
              precoVenda: maskMoeda(produto.precoVendaAtual.toFixed(2)),
              markup: maskMarkup(produto.markup.toFixed(2)),
              codOrigem: produto.codOrigem,
            },

            comissao: {
              flControlaComissao: produto.flControlaComissao,
              percentualComissao: produto.percentualComissao,
            },

            estoque: {
              flControlaEstoque: produto.flControlaEstoque,
              qtdMinima: produto.qtdEstoqueMinimo,
              qtdAtual: produto.qtdEstoqueAtual,
            },

            caracteristicas: {
              flControlaNumSerie: produto.flControlaNumSerie,
              flControlaGrade: produto.flControlaGrade,
              flTributado: produto.flTributado,
              flItemVenda: produto.flItemVenda,
            },
          },
          grades: {
            codTipoGrade: produto.codTipoGrade ? produto.codTipoGrade : "0",
            grades: [],
          },
          gradesSelected: [],
          dadosTributarios: {
            codSeguimento: produto.cest
              ? parseInt(produto.cest.substring(0, 2))
              : "0",
            cest: produto.cest,
            ncm: produto.ncm,
            descricao: "",
            codClassificacao: produto.classificacaoTrib,
            aliquotaMVA: produto.aliquotaMVA,
          },
        });
        if (produto.cest !== "") {
          await GetCEST(codEmpresa, produto.cest);
        }
        if (produto.ncm !== "") {
          await GetNCM(codEmpresa, produto.ncm);
        }

        thenFunction(response.data.records);

        if (
          response.data.records.codTipoGrade &&
          response.data.records.codTipoGrade !== "0"
        ) {
          await GetProdutoGrades(
            codEmpresa,
            codProduto,
            response.data.records.codTipoGrade
          );
          actions.setProdutoCopia();
        }
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : null,
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível obter o produto",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}

export async function CreateProduto(codEmpresa, produto) {
  const { notificacao } = store.getState();
  const camposInvalidos = store.getState().produtos.invalido;
  let newInvalidos = {};

  try {
    //Retorna se houve erro ou nao
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));
    var {
      basico,
      basico: { especificacoes, estoque, comissao, caracteristicas },
    } = produto;
    var invalido = false;

    if (basico.codGrupo === "0") {
      alertar(
        notificacao,
        "br",
        "Selecione o grupo",
        "warning",
        "icon-alert-circle-exc",
        2
      );
      return true;
    }

    if (basico.codSubgrupo === "0") {
      alertar(
        notificacao,
        "br",
        "Selecione o subgrupo",
        "warning",
        "icon-alert-circle-exc",
        2
      );
      return true;
    }

    if (basico.codBarras !== "" && basico.codBarras.trim().length < 8) {
      newInvalidos.codBarras = true;
      invalido = true;
    }
    if (basico.nome === "") {
      newInvalidos.nome = true;
      invalido = true;
    }
    if (basico.descricao === "") {
      newInvalidos.descricao = true;
      invalido = true;
    }

    if (invalido) {
      actions.setInvalido({ ...camposInvalidos, ...newInvalidos });
      alertar(
        notificacao,
        "br",
        "Campo Inválido",
        "warning",
        "icon-alert-circle-exc",
        2
      );
      return true;
    }

    return await axios({
      method: "post",
      url:
        api.urlCadastro +
        `api/v1/produto/createProduto?codEmpresa=${codEmpresa}`,
      headers: { Authorization: basicAuth },
      data: {
        CodGrupo: basico.codGrupo,
        CodSubgrupo: basico.codSubgrupo,
        CodBarras: basico.codBarras,
        Nome: basico.nome,
        NumProduto: basico.numeroProduto,
        Descricao: basico.descricao,
        Unidade: especificacoes.unidade,
        Largura: ToNumber(especificacoes.largura),
        Altura: ToNumber(especificacoes.altura),
        Profundidade: ToNumber(especificacoes.profundidade),
        Peso: ToNumber(especificacoes.peso),
        Voltagem: especificacoes.voltagem,
        GarantiaForneMes: especificacoes.garantiaFor,
        CodForne: especificacoes.codFornecedor,
        PrecoCustoAtual: ToNumber(removeMaskMoney(especificacoes.precoCusto)),
        PrecoVendaAtual: ToNumber(removeMaskMoney(especificacoes.precoVenda)),
        markup: ToNumber(especificacoes.markup),
        PercComissao: ToNumber(comissao.percentualComissao),
        QtdEstoqueAtual: ToNumber(estoque.qtdAtual),
        QtdEstoqueMinimo: ToNumber(estoque.qtdMinima),
        FlControlaEstoque: estoque.flControlaEstoque,
        FlControlaComissao: comissao.flControlaComissao,
        FlControlaNumSerie: caracteristicas.flControlaNumSerie,
        FlControlaGrade: caracteristicas.flControlaGrade,
        FlTributado: caracteristicas.flTributado,
        FlItemVenda: caracteristicas.flItemVenda,
        CodOrigem: especificacoes.codOrigem,
      },
    })
      .then((response) => {
        alertar(
          notificacao,
          "br",
          response.data.message,
          "success",
          "icon-check-2",
          2
        );
        const produto = store.getState().produtos.produto;
        actions.setProduto({
          ...produto,
          codProduto: response.data.records.codProduto,
          basico: {
            ...produto.basico,
            numeroProduto: response.data.records.numProduto,
          },
        });
        actions.setProdutoCopia();
        return false;
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : null,
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
        return true;
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível concluir",
      "warning",
      "icon-alert-circle-exc",
      2
    );
    return true;
  }
}

export async function UpdateProduto(codEmpresa, produto, produtoCopia) {
  const { notificacao } = store.getState();
  const camposInvalidos = store.getState().produtos.invalido;
  let newInvalidos = {};

  try {
    //Retorna se houve erro ou nao
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));
    var {
      basico,
      basico: { especificacoes, estoque, comissao, caracteristicas },
    } = produto;
    var invalido = false;

    if (basico.codGrupo === "0") {
      alertar(
        notificacao,
        "br",
        "Selecione o grupo",
        "warning",
        "icon-alert-circle-exc",
        2
      );
      return true;
    }

    if (basico.codSubgrupo === "0") {
      alertar(
        notificacao,
        "br",
        "Selecione o subgrupo",
        "warning",
        "icon-alert-circle-exc",
        2
      );
      return true;
    }

    if (basico.codBarras.trim() !== "" && basico.codBarras.trim().length < 8) {
      newInvalidos.codBarras = true;
      invalido = true;
    }
    if (basico.nome === "") {
      newInvalidos.nome = true;
      invalido = true;
    }
    if (basico.descricao === "") {
      newInvalidos.descricao = true;
      invalido = true;
    }

    if (invalido) {
      actions.setInvalido({ ...camposInvalidos, ...newInvalidos });
      alertar(
        notificacao,
        "br",
        "Campo Inválido",
        "warning",
        "icon-alert-circle-exc",
        2
      );
      return true;
    }

    return await axios({
      method: "put",
      url:
        api.urlCadastro +
        `api/v1/produto/updateProduto?codEmpresa=${codEmpresa}&codProduto=${produto.codProduto}`,
      headers: { Authorization: basicAuth },
      data: {
        CodGrupo: basico.codGrupo,
        CodSubgrupo: basico.codSubgrupo,
        CodBarras: basico.codBarras,
        NumProduto: basico.numeroProduto,
        Nome: basico.nome,
        Descricao: basico.descricao,
        Unidade: especificacoes.unidade,
        Largura: ToNumber(especificacoes.largura),
        Altura: ToNumber(especificacoes.altura),
        Profundidade: ToNumber(especificacoes.profundidade),
        Peso: ToNumber(especificacoes.peso),
        Voltagem: especificacoes.voltagem,
        GarantiaForneMes: especificacoes.garantiaFor,
        CodForne: especificacoes.codFornecedor,
        PrecoCustoAtual: ToNumber(removeMaskMoney(especificacoes.precoCusto)),
        PrecoVendaAtual: ToNumber(removeMaskMoney(especificacoes.precoVenda)),
        Markup: ToNumber(especificacoes.markup),
        PercComissao: ToNumber(comissao.percentualComissao),
        QtdEstoqueAtual: ToNumber(estoque.qtdAtual),
        QtdEstoqueMinimo: ToNumber(estoque.qtdMinima),
        FlControlaEstoque: estoque.flControlaEstoque,
        FlControlaComissao: comissao.flControlaComissao,
        FlControlaNumSerie: caracteristicas.flControlaNumSerie,
        FlControlaGrade: caracteristicas.flControlaGrade,
        FlTributado: caracteristicas.flTributado,
        FlItemVenda: caracteristicas.flItemVenda,
        CodOrigem: especificacoes.codOrigem,
      },
    })
      .then((response) => {
        alertar(
          notificacao,
          "br",
          response.data.message,
          "success",
          "icon-check-2",
          2
        );
        const produto = store.getState().produtos.produto;
        actions.setProduto({
          ...produto,
          codProduto: response.data.records.codProduto,
          basico: {
            ...produto.basico,
            numeroProduto: response.data.records.numProduto,
          },
        });
        actions.setProdutoCopia();
        return false;
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : null,
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
        return true;
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível concluir",
      "warning",
      "icon-alert-circle-exc",
      2
    );
    return true;
  }
}

export async function GetImagens(codEmpresa, codProduto) {
  const { notificacao } = store.getState();
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    await axios
      .get(
        api.urlCadastro +
          `api/v1/produto/imagens?codEmpresa=${codEmpresa}&codProduto=${codProduto}`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then((response) => {
        actions.setImagens(response.data.records);
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : null,
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível obter as imagens",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}

export async function CreateImagem(codEmpresa, codProduto, imagem) {
  const { notificacao } = store.getState();
  try {
    //Retorna se houve erro ou nao
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));
    var invalido = false;

    if (imagem.imagem === "") {
      alertar(
        notificacao,
        "br",
        "Selecione a imagem",
        "info",
        "icon-bulb-63",
        2
      );
      return true;
    }

    if (imagem.descricao.trim() === "") {
      actions.setInvalido({
        ...store.getState().produtos.invalido,
        descricaoImagem: true,
      });
      invalido = true;
    }

    if (invalido) {
      alertar(
        notificacao,
        "br",
        "Campo Inválido",
        "warning",
        "icon-alert-circle-exc",
        2
      );
      return true;
    }

    return await axios({
      method: "post",
      url:
        api.urlCadastro +
        `api/v1/produto/createImagem?codEmpresa=${codEmpresa}&codProduto=${codProduto}`,
      headers: { Authorization: basicAuth },
      data: {
        Descricao: imagem.descricao,
        Imagem: imagem.imagem,
        FlPrincipal: imagem.flPrincipal,
      },
    })
      .then(async (response) => {
        await GetImagens(codEmpresa, codProduto);
        actions.cleanImagem();
        alertar(
          notificacao,
          "br",
          response.data.message,
          "success",
          "icon-check-2",
          2
        );
        return false;
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : null,
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
        return true;
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível concluir",
      "warning",
      "icon-alert-circle-exc",
      2
    );
    return true;
  }
}

export async function UpdateImagem(codEmpresa, codProduto, imagem) {
  const { notificacao } = store.getState();
  try {
    //Retorna se houve erro ou nao
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    if (imagem.imagem === "") {
      alertar(
        notificacao,
        "br",
        "Selecione a imagem",
        "info",
        "icon-bulb-63",
        2
      );
      return true;
    }

    if (imagem.descricao.trim() === "") {
      actions.setInvalido({
        ...store.getState().produtos.invalido,
        descricaoImagem: true,
      });
      alertar(
        notificacao,
        "br",
        "A descrição não pode estar vazia",
        "info",
        "icon-bulb-63",
        2
      );
      return true;
    }

    return await axios({
      method: "put",
      url:
        api.urlCadastro +
        `api/v1/produto/updateImagem?codEmpresa=${codEmpresa}&codProduto=${codProduto}&codImagem=${imagem.codImagem}`,
      headers: { Authorization: basicAuth },
      data: {
        Descricao: imagem.descricao,
        Imagem: imagem.imagem,
        FlPrincipal: imagem.flPrincipal,
      },
    })
      .then(async (response) => {
        await GetImagens(codEmpresa, codProduto);
        alertar(
          notificacao,
          "br",
          response.data.message,
          "success",
          "icon-check-2",
          2
        );
        return false;
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : null,
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
        return true;
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível concluir",
      "warning",
      "icon-alert-circle-exc",
      2
    );
    return true;
  }
}

export function ExcluirImagem(codEmpresa, codProduto, codImagem) {
  const { notificacao } = store.getState();
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));
    axios({
      method: "delete",
      url:
        api.urlCadastro +
        `api/v1/produto/excluirImagem?codEmpresa=${codEmpresa}&codProduto=${codProduto}&codImagem=${codImagem}`,
      headers: { Authorization: basicAuth },
    })
      .then(async (response) => {
        await GetImagens(codEmpresa, codProduto);
        alertar(
          notificacao,
          "br",
          response.data.message,
          "success",
          "icon-check-2",
          2
        );
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : "Falha na conexão",
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível concluir",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}

export function GetTipoGrades(codEmpresa) {
  const { notificacao } = store.getState();
  try {
    actions.setTiposGrade([]);

    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    axios
      .get(
        api.urlCadastro +
          `api/v1/generico?codEmpresa=${codEmpresa}&entidade=TIPO_GRADE`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then((response) => {
        actions.setTiposGrade(
          response.data.records ? response.data.records : []
        );
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível obter os tipos de grades",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}

export async function GetProdutoGrades(
  codEmpresa,
  codProduto,
  codTipoGrade,
  descTipoGrade
) {
  const { notificacao } = store.getState();
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    await axios
      .get(
        api.urlCadastro +
          `api/v1/produto/grades?codEmpresa=${codEmpresa}&codProduto=${codProduto}&codTipoGrade=${codTipoGrade}`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then((response) => {
        actions.setProdutoGrades({
          ...store.getState().produtos.produto.grades,
          codTipoGrade,
          grades: response.data.records.grades
            ? response.data.records.grades
            : [],
        });
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível obter os tipos de grades",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}

export async function VincularProdutoGrade(
  codEmpresa,
  codProduto,
  grades,
  gradesSelected,
  gradesCopia
) {
  const { notificacao } = store.getState();
  try {
    if (JSON.stringify(grades) === JSON.stringify(gradesCopia)) {
      alertar(
        notificacao,
        "br",
        "Nada foi alterado",
        "info",
        "icon-bulb-63",
        2
      );
      return false;
    }

    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    await axios({
      method: "post",
      url:
        api.urlCadastro +
        `api/v1/produto/vincularProdutoGrade?codEmpresa=${codEmpresa}`,
      headers: { Authorization: basicAuth },
      data: {
        CodProduto: codProduto,
        CodTipoGrade: grades.codTipoGrade,
        Grades: gradesSelected,
      },
    })
      .then(async (response) => {
        alertar(
          notificacao,
          "br",
          response.data.message,
          "success",
          "icon-check-2",
          2
        );
        await GetProdutoGrades(codEmpresa, codProduto, grades.codTipoGrade);
        actions.setProdutoCopia();
        return false;
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : "Falha na conexão",
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
        return true;
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível concluir",
      "warning",
      "icon-alert-circle-exc",
      2
    );
    return true;
  }
}

export function GetFornecedorProduto(codEmpresa, pesquisa) {
  const { notificacao } = store.getState();
  try {
    actions.setFornecedoresProd([]);

    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    axios
      .get(
        api.urlCadastro +
          `api/v1/produto/fornecedorProduto?codEmpresa=${codEmpresa}&nomeRazao=${pesquisa.nomeRazao}`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then((response) => {
        if (response.data.records.length === 0) {
          alertar(
            notificacao,
            "br",
            "Nenhum fornecedor encontrado",
            "info",
            "icon-bulb-63",
            2
          );
        }

        actions.setFornecedoresProd(response.data.records);
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : "Falha na conexão",
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível obter os fornecedores ",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}

function ToNumber(value) {
  value = String(value);
  value = parseFloat(value.replace(",", "."));
  return value;
}

export function GetSeguimentos() {
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    axios
      .get(api.urlCadastro + `api/v1/cest/seguimentos`, {
        headers: { Authorization: basicAuth },
      })
      .then((response) => {
        var records = [];
        records = response.data.records.map((record) => {
          record.descricao = `${
            record.codigo < 10 ? "0" + record.codigo : record.codigo
          } - ${record.descricao}`;
          return record;
        });
        actions.setSeguimentos(records);
      });
  } catch (e) {
    return;
  }
}

export async function GetCESTs(codEmpresa, codSeguimento, cest, descricao) {
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));
    document.querySelector("#tabelaCest").scrollTop = 0;

    actions.setCESTs([]);

    if (codSeguimento === "0" && cest === "" && descricao === "") {
      return "Informe ao menos um dos campos para pesquisar";
    }

    if (
      descricao.length !== "" &&
      descricao.length < 4 &&
      codSeguimento === "0" &&
      cest === ""
    ) {
      return "Informe uma descrição com ao menos 4 caracteres";
    }

    return await axios
      .get(
        api.urlCadastro +
          `api/v1/cest/cests?codEmpresa=${codEmpresa}&codSeguimento=${codSeguimento}&cest=${cest.replace(
            /\./g,
            ""
          )}&descricao=${descricao}`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then((response) => {
        var records = [];
        records = response.data.records.map((record) => {
          record.descricaoFormatada = `${maskCEST(record.cest)} - ${
            record.descricao
          }`;
          return record;
        });
        actions.setCESTs(records);
        return `${response.data.meta.recordCount} CESTs encontrados`;
      })
      .catch((err) => {
        return "Não foi possível concluir";
      });
  } catch (e) {
    return "Não foi possível concluir";
  }
}

export async function GetNCMs(codEmpresa, ncm, descricao) {
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));
    document.querySelector("#tabelaNcm").scrollTop = 0;
    actions.setNCMs([]);

    if (ncm === "" && descricao === "") {
      return "Informe ao menos um dos campos para pesquisar";
    }

    if (descricao.length !== "" && descricao.length < 4 && ncm === "") {
      return "Informe uma descrição com ao menos 4 caracteres";
    }

    return await axios
      .get(
        api.urlCadastro +
          `api/v1/ncm/ncms?codEmpresa=${codEmpresa}&ncm=${ncm.replace(
            /\./g,
            ""
          )}&descricao=${descricao}`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then((response) => {
        var records = [];
        records = response.data.records.map((record) => {
          record.descricaoFormatada = `${maskNCM(record.ncm)} - ${
            record.descricao
          }`;
          return record;
        });
        actions.setNCMs(records);
        return `${response.data.meta.recordCount} NCMs encontrados`;
      })
      .catch((err) => {
        return "Não foi possível concluir";
      });
  } catch (e) {
    return "Não foi possível concluir";
  }
}

export async function IncluirCEST(codEmpresa, codSeguimento, cest, descricao) {
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    cest = cest.replace(/\./g, "");
    if (cest.length < 7) {
      return "O CEST precisa ter 7 caracteres";
    }

    if (!descricao) {
      return "O CEST precisa ter uma descrição";
    }

    return await axios({
      method: "post",
      url:
        api.urlCadastro +
        `api/v1/cest/createCest?codEmpresa=${codEmpresa}&codSeguimento=${codSeguimento}`,
      headers: { Authorization: basicAuth },
      data: {
        cest,
        descricao,
      },
    })
      .then((response) => {
        GetNCMs(codEmpresa, cest, descricao);
        return response.data.message;
      })
      .catch((e) => {
        return e.response ? e.response.data.message : "Falha na conexão";
      });
  } catch (e) {
    return "Não foi possível concluir";
  }
}

export async function IncluirNCM(codEmpresa, ncm, descricao) {
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    ncm = ncm.replace(/\./g, "");
    if (ncm.length < 8) {
      return "O NCM precisa ter 8 caracteres";
    }

    if (!descricao) {
      return "O NCM precisa ter uma descrição";
    }

    return await axios({
      method: "post",
      url: api.urlCadastro + `api/v1/ncm/createNcm?codEmpresa=${codEmpresa}`,
      headers: { Authorization: basicAuth },
      data: {
        NCM: ncm,
        Descricao: descricao,
      },
    })
      .then((response) => {
        GetNCMs(codEmpresa, ncm, descricao);
        return response.data.message;
      })
      .catch((e) => {
        return e.response ? e.response.data.message : "Falha na conexão";
      });
  } catch (e) {
    return "Não foi possível concluir";
  }
}

export function GetNCMCESts(codEmpresa, ncm) {
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    axios
      .get(
        api.urlCadastro +
          `api/v1/cest/ncmCests?codEmpresa=${codEmpresa}&ncm=${ncm.replace(
            /\./g,
            ""
          )}`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then((response) => {
        var records = [];
        records = response.data.records.map((record) => {
          record.descricaoFormatada = `${maskCEST(record.cest)} - ${
            record.descricao
          }`;
          return record;
        });
        actions.setCESTsSugestoes(records);
      });
  } catch (e) {}
}

export function GetCESTNCMs(codEmpresa, cest) {
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    axios
      .get(
        api.urlCadastro +
          `api/v1/ncm/cestNcms?codEmpresa=${codEmpresa}&cest=${cest.replace(
            /\./g,
            ""
          )}`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then((response) => {
        var records = [];
        records = response.data.records.map((record) => {
          record.descricaoFormatada = `${maskNCM(record.ncm)} - ${
            record.descricao
          }`;
          return record;
        });
        actions.setNCMsSugestoes(records);
      });
  } catch (e) {}
}

export async function SalvarCESTNCM(
  codEmpresa,
  codProduto,
  dadosTributarios,
  dadosTributariosCopia
) {
  const { notificacao } = store.getState();
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    if (
      JSON.stringify(dadosTributarios) === JSON.stringify(dadosTributariosCopia)
    ) {
      alertar(
        notificacao,
        "br",
        "Nada foi alterado",
        "info",
        "icon-bulb-63",
        2
      );
      return false;
    }
    let aliquotaMVA = dadosTributarios.aliquotaMVA.toString().replace(".", "");
    aliquotaMVA = aliquotaMVA.replace(",", ".");

    return await axios({
      method: "put",
      url:
        api.urlCadastro +
        `api/v1/produto/cestncm?codEmpresa=${codEmpresa}&codProduto=${codProduto}`,
      headers: { Authorization: basicAuth },
      data: {
        Cest: dadosTributarios.cest,
        Ncm: dadosTributarios.ncm,
        ClassificacaoTrib: dadosTributarios.codClassificacao.toString(),
        CodSeguimento:
          dadosTributarios.codSeguimento.toString().length < 2
            ? `0${dadosTributarios.codSeguimento.toString()}`
            : dadosTributarios.codSeguimento.toString(),
        aliquotaMVA: Number(aliquotaMVA),
      },
    })
      .then((response) => {
        alertar(
          notificacao,
          "br",
          response.data.message,
          "success",
          "icon-check-2",
          2
        );
        actions.setProdutoCopia();
        return false;
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : "Falha na conexão",
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
        return true;
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível concluir",
      "warning",
      "icon-alert-circle-exc",
      2
    );
    return true;
  }
}

export async function GetCEST(codEmpresa, cest) {
  const { notificacao } = store.getState();
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    return await axios
      .get(
        api.urlCadastro +
          `api/v1/cest/cest?codEmpresa=${codEmpresa}&cest=${cest.replace(
            /\./g,
            ""
          )}`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then((response) => {
        response.data.records.descricaoFormatada = `${maskCEST(
          response.data.records.cest
        )} - ${response.data.records.descricao}`;
        actions.setCESTs([response.data.records]);
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : "Falha na conexão",
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível concluir",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}

export async function GetNCM(codEmpresa, ncm) {
  const { notificacao } = store.getState();
  try {
    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    return await axios
      .get(
        api.urlCadastro +
          `api/v1/ncm/ncm?codEmpresa=${codEmpresa}&ncm=${ncm.replace(
            /\./g,
            ""
          )}`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then((response) => {
        response.data.records.descricaoFormatada = `${maskNCM(
          response.data.records.ncm
        )} - ${response.data.records.descricao}`;
        actions.setNCMs([response.data.records]);
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : "Falha na conexão",
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível concluir",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}

export async function GetClassificacoes(codEmpresa, pesquisa, ativos) {
  const { notificacao } = store.getState();
  try {
    actions.setClassificacoes([]);

    if (
      pesquisa.descricao === "" &&
      pesquisa.uf === "" &&
      pesquisa.cfopCf === "" &&
      pesquisa.cfopNFe === ""
    ) {
      return "Informe ao menos um dos campos para pesquisar";
    }

    const basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));

    return await axios
      .get(
        api.urlCadastro +
          `api/v1/classificacao/classificacoes?codEmpresa=${codEmpresa}&status=${ativos}&descricao=${pesquisa.descricao}`,
        {
          headers: { Authorization: basicAuth },
        }
      )
      .then((response) => {
        if (response.data.records.length === 0) {
          alertar(
            notificacao,
            "br",
            "Nenhuma classificação encontrada",
            "info",
            "icon-bulb-63",
            2
          );
        }

        actions.setClassificacoes(response.data.records);
      })
      .catch((e) => {
        alertar(
          notificacao,
          "br",
          e.response ? e.response.data.message : "Falha na conexão",
          "warning",
          "icon-alert-circle-exc",
          2,
          e
        );
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível obter as classificações",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}

//Os calculos dos custos do produto
export async function calculoValores(valor, especificacoes) {
  const { notificacao } = store.getState();
  try {
    let espec = JSON.stringify(especificacoes);
    espec = JSON.parse(espec);

    var markup = espec.markup;
    var precoCusto = espec.precoCusto;
    var precoVenda = espec.precoVenda;

    if (markup.length > 6) {
      markup = Number(
        espec.markup
          .toString()
          .replace(",", ".")
          .replace(".", "")
      );
    } else {
      markup = Number(espec.markup.toString().replace(",", "."));
    }

    if (precoCusto.length > 6) {
      precoCusto = Number(
        precoCusto
          .toString()
          .replace(",", ".")
          .replace(".", "")
      );
    } else {
      precoCusto = Number(precoCusto.toString().replace(",", "."));
    }

    if (precoVenda.length > 6) {
      precoVenda = Number(
        precoVenda
          .toString()
          .replace(",", ".")
          .replace(".", "")
      );
    } else {
      precoVenda = Number(precoVenda.toString().replace(",", "."));
    }

    switch (valor) {
      case "precoCusto":
        if (markup === 0 && precoVenda !== 0) {
          markup =
            (Number(precoVenda) - Number(precoCusto)) /
            (Number(precoCusto) / 100);
          markup = maskMarkup(markup.toFixed(2));
          actions.setProdutoEspecificacoes({ ...especificacoes, markup });
          break;
        }
        if (precoVenda === 0 && markup !== 0) {
          precoVenda =
            Number(precoCusto) * (Number(markup) / 100) + Number(precoCusto);
          precoVenda = maskMoeda(precoVenda.toFixed(2));
          actions.setProdutoEspecificacoes({ ...especificacoes, precoVenda });
        }
        if (precoVenda !== 0 && markup !== 0) {
          precoVenda =
            Number(precoCusto) * (Number(markup) / 100) + Number(precoCusto);
          precoVenda = maskMoeda(precoVenda.toFixed(2));
          actions.setProdutoEspecificacoes({ ...especificacoes, precoVenda });
        }
        break;
      case "markup":
        if (markup !== 0) {
          precoVenda =
            Number(precoCusto) * Number(markup / 100) + Number(precoCusto);
          precoVenda = maskMoeda(precoVenda.toFixed(2));
          actions.setProdutoEspecificacoes({ ...especificacoes, precoVenda });
        }
        break;
      case "precoVenda":
        if (precoCusto !== 0) {
          markup =
            (Number(precoVenda) - Number(precoCusto)) /
            (Number(precoCusto) / 100);
          markup = maskMarkup(markup.toFixed(2));
          actions.setProdutoEspecificacoes({ ...especificacoes, markup });
        }
        break;
      default:
        break;
    }
  } catch {
    alertar(
      notificacao,
      "br",
      "Não foi possível calcular os custos",
      "warning",
      "icon-alert-circle-exc",
      2
    );
  }
}
export async function buscarProduto(
  codEmpresa,
  campo,
  codBarrasNumProduto,
  thenFunction
) {
  const { notificacao } = store.getState();

  try {
    if (
      (codBarrasNumProduto !== "" && codBarrasNumProduto.length >= 8) ||
      campo === "numProduto"
    ) {
      var basicAuth =
        "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));
      axios
        .get(
          api.urlCadastro +
            `api/v1/produto/produtos?&numProduto=${codBarrasNumProduto}`,
          { headers: { Authorization: basicAuth } }
        )
        .then((response) => {
          let produto = response.data.records;
          if (produto.length === 1) {
            produto = produto[0];
            axios
              .get(
                api.urlCadastro +
                  `api/v1/produto/produto?codEmpresa=${codEmpresa}&codProduto=${produto.codProduto}`,
                {
                  headers: { Authorization: basicAuth },
                }
              )
              .then(async (response) => {
                let informationProduto = response.data.records;

                actions.setProduto({
                  codProduto: informationProduto.codProduto,
                  status: informationProduto.status,
                  basico: {
                    codGrupo: informationProduto.codGrupo,
                    codSubgrupo: informationProduto.codSubgrupo,
                    grupo: informationProduto.grupo,
                    subgrupo: informationProduto.subgrupo,
                    codBarras: informationProduto.codBarras,
                    numeroProduto: informationProduto.numProduto,
                    nome: informationProduto.nome,
                    descricao: informationProduto.descricao,
                    especificacoes: {
                      unidade: informationProduto.unidade,
                      largura: informationProduto.largura,
                      altura: informationProduto.altura,
                      profundidade: informationProduto.profundidade,
                      peso: informationProduto.peso,
                      voltagem: informationProduto.voltagem,
                      garantiaFor: informationProduto.garantiaForneMes,
                      codFornecedor: informationProduto.codForne,
                      fornecedor: "",
                      precoCusto: informationProduto.precoCustoAtual,
                      precoVenda: informationProduto.precoVendaAtual,
                      markup: informationProduto.markup,
                    },
                    comissao: {
                      flControlaComissao: informationProduto.flControlaComissao,
                      percentualComissao: informationProduto.percentualComissao,
                    },
                    estoque: {
                      flControlaEstoque: informationProduto.flControlaEstoque,
                      qtdMinima: informationProduto.qtdEstoqueMinimo,
                      qtdAtual: informationProduto.qtdEstoqueAtual,
                    },
                    caracteristicas: {
                      flControlaNumSerie: informationProduto.flControlaNumSerie,
                      flControlaGrade: informationProduto.flControlaGrade,
                      flTributado: informationProduto.flTributado,
                      flItemVenda: informationProduto.flItemVenda,
                    },
                  },
                  grades: {
                    codTipoGrade: informationProduto.codTipoGrade
                      ? informationProduto.codTipoGrade
                      : "0",
                    grades: [],
                  },
                  dadosTributarios: {
                    codSeguimento: informationProduto.cest
                      ? parseInt(informationProduto.cest.substring(0, 2))
                      : "0",
                    cest: informationProduto.cest,
                    ncm: informationProduto.ncm,
                    descricao: "",
                    codClassificacao: Number(
                      informationProduto.classificacaoTrib
                    ),
                  },
                });

                if (
                  produto.cest !== "" &&
                  informationProduto.flTributado === 1
                ) {
                  await GetCEST(codEmpresa, informationProduto.cest);
                }
                if (
                  produto.ncm !== "" &&
                  informationProduto.flTributado === 1
                ) {
                  await GetNCM(codEmpresa, informationProduto.ncm);
                }

                thenFunction(response.data.records);

                if (
                  response.data.records.codTipoGrade &&
                  response.data.records.codTipoGrade !== "0"
                ) {
                  await GetProdutoGrades(
                    codEmpresa,
                    informationProduto.codProduto,
                    response.data.records.codTipoGrade
                  );
                  actions.setProdutoCopia();
                }
              });
          } else {
            alertar(
              notificacao,
              "br",
              "Produto não cadastrado",
              "info",
              "icon-bulb-63",
              2
            );
          }
          return true;
        })
        .catch((e) => {
          return false;
        });
    }
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível obter o produto",
      "warning",
      "icon-alert-circle-exc"
    );
    return false;
  }
}

export async function buscarOrigensProduto() {
  const { notificacao } = store.getState();

  try {
    var basicAuth =
      "Basic " + btoa(api.apiUser + ":" + localStorage.getItem("TOKEN_KEY"));
    axios
      .get(api.urlCadastro + `api/v1/produto/origensProduto`, {
        headers: { Authorization: basicAuth },
      })
      .then((response) => {
        let origensProduto = response.data.records;
        if (origensProduto) {
          actions.setOrigensProduto(origensProduto);
          return true;
        }
        return false;
      })
      .catch((e) => {
        return false;
      });
  } catch (e) {
    alertar(
      notificacao,
      "br",
      "Não foi possível obter o origens do produtos",
      "warning",
      "icon-alert-circle-exc"
    );
    return false;
  }
}
