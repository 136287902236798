import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerTiposPagamentos from "~/controller/controllerTiposPagamentos";
import { Select, Switch } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip';
import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  ModalBody,
  Modal
} from "reactstrap";

const idStore = "TIPOS_PAGAMENTOS";


const TipoPagamento = () => {
  const notificacao = useSelector(state => state.notificacao);
  const { tipoPgto, tiposPgtoCopia, invalido, verCadastro } = useSelector(state => state.tiposPagamentos);
  const [modalInativar, setModalInativar] = useState(false);
  const dispatch = useDispatch();

  function onChange(value, stateName) {
    dispatch({ idStore, type: 'TIPO_PGTO', campo: stateName, value: value });
    dispatch({ idStore, type: 'INVALIDO', campo: stateName, value: false });
  };

  async function onChangeAtivo(e) {
    toggleModalInativar()
  };

  function inativar() {
    if (tipoPgto.codTipoPgto !== '') {
        controllerTiposPagamentos.InativarTipoPgto(notificacao, dispatch, tipoPgto);
    }
    dispatch({ idStore, type: 'TIPO_PGTO', campo: 'status', value: tipoPgto.status === 1 ? 0 : 1 });
  }

  async function Salvar() {

    if (tipoPgto.codTipoPgto === '') {
        controllerTiposPagamentos.CreateTipoPgto(notificacao, dispatch, tipoPgto);
    } else {
        controllerTiposPagamentos.UpdateTipoPgto(notificacao, dispatch, tipoPgto, tiposPgtoCopia);
    }
  };

  function Limpar() {
    dispatch({ idStore, type: 'CLEAN_TIPO_PGTO' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('descricao').focus()
  };

  function toggleModalInativar() {
    setModalInativar(!modalInativar);
  }

  return (
    <>
      <Row className="justify-content-center">
      <div className={ verCadastro === true ? 'ver-cadastro' : ''}>

      </div>
        <Col>
          <h4 className="info-text">Preencha com as informações do tipo de pagamento</h4>
          <CardBody>

            <Row className="justify-content-center">

              <Col md="12" className="text-right">
                <Switch
                  componentleft={<label className={tipoPgto.status === 0 ? `text-success text-center` : "text-warning text-center"}>{tipoPgto.status === 0 ? "Ativo" : "Inativo"}</label>}
                  checked={tipoPgto.status === 0}
                  onChange={e => onChangeAtivo(e)}
                  disabled={tipoPgto.codTipoPgto === ""}
                />
              </Col>

              <Col md="12">

                <Row className="padding-padrao-row">

                  <Col className="padding-padrao-col" lg="3" >
                    <label>Tipo<span className="error"> *</span></label>
                    <Select
                      first={'Selecione'}
                      value={{ value: tipoPgto.flTipo }}
                      options={[{ value: 1, label: 'Dinheiro' },
                      { value: 2, label: 'Cartão' },
                      { value: 3, label: 'Boleto'},
                      { value: 4, label: 'Cheque'},
                      { value: 5, label: 'Crédito'}]}
                      onChange={value => onChange(value.value, 'flTipo')}
                    />
                  </Col>

                  <Col className="padding-padrao-col" lg md sm="7">
                    <label>Descrição<span className="error"> *</span></label>
                    <FormGroup className={invalido.descricao ? "has-danger" : ''}>
                      <Input
                        id="descricao"
                        type="text"
                        value={tipoPgto.descricao}
                        onChange={e => onChange(e.target.value, 'descricao')}
                        autoComplete="off"
                        maxLength="50"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                </Row>

              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'center' }}>
                <ArrowTooltip title='Shift+N'>
                  <Button className="btn-padrao" color="default" onClick={() => Limpar()}>Novo</Button>
                </ArrowTooltip>
                <ArrowTooltip title='Shift+S'>
                  <Button className="btn-padrao" color="info" onClick={() => Salvar()}>Salvar</Button>
                </ArrowTooltip>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>

      {/* Modal Inativar */}
      <Modal
        isOpen={modalInativar}
        toggle={toggleModalInativar}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalInativar}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">{tipoPgto.status === 0 ? "Inativar" : "Ativar"} tipo de pagamento</h6>
        </div>
        <ModalBody>

          <Col className="text-center">
            <label>
              {tipoPgto.status === 0 ?
                <>Tem certeza que deseja <b>inativar</b> esse tipo de pagamento da sua empresa?</>
                : <>Tem certeza que deseja <b>ativar</b> esse tipo de pagamento em sua empresa?</>
              }
            </label>
          </Col>

        </ModalBody>
        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => { toggleModalInativar() }}
                style={{ width: '150px' }}
              >NÃO
                                            </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => { toggleModalInativar(); inativar() }}
                style={{ width: '150px' }}
              >SIM
                                            </Button>
            </Col>
          </Row>
        </CardBody>

      </Modal>
      {/* Modal Inativar*/}

    </>
  )
};

class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('descricao').focus()
    }, 100)
  }

  render() {
    return (
      <TipoPagamento />
    )
  }
}

export default Wizard;