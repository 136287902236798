export enum DashboardStore {

    ID_STORE = "DASHBOARD"

}

export enum DashboardTypes {
    TOPFIVE = 'TOPFIVE',
    BIGCHARTDATA = 'BIGCHARTDATA',
    DATASETS = 'DATASETS',
    HIGHGRAFIC = 'HIGHGRAFIC',
    OPTIONS_HIGH_GRAFIC = 'OPTIONS_HIGH_GRAFIC',
    GRADIENT = 'GRADIENT',
    CLEAN_HIGH_GRAFIC = 'CLEAN_HIGH_GRAFIC'

}


export type DashboardAction = {
    idStore: DashboardStore.ID_STORE,
    type: DashboardTypes,
    bigChartData?: IDashboardState['bigChartData'],
    topFive?: IDashboardState['topFive'],
    highGrafic?: IDashboardState['highGrafic']
}

export interface IDashboardState {
    bigChartData: number | undefined,
    topFive: any,
    highGrafic: any | undefined
}
