import { createStore, Store } from 'redux';

import rootReducer from './reducers'

import AppState from './storeType'

import { useDispatch } from 'react-redux'

const store: Store<AppState> = createStore(rootReducer);

export default store;