//State da tela de Pesquisa e cadastro de tipos de pagamentos
var INITIAL_STATE = {
    pesquisa: {
        descricao: '',
        ativos: 2,
        codTipoPgto: '0',
        descricaoTipo: '',
        qtdDiasEntreParcelas: 0,
        diasPrazo: 0,
        percentualTarifa: 0,
        flags: [],
        flAbreCaixa: 0,
        flRetirada: 0,
        flaVista: 0,
        flBaixaAutomatica: 0,
    },
    formaPgto: {
        codForma: 0,
        descricao: '',
        status: 0,
        codTipoPgto: '0',
        flTipo: 0,
        qtdDiasEntreParcelas: 0,
        diasPrazo: 0,
        percentualTarifa: 0,
        flAbreCaixa: 0,
        flRetirada: 0,
        flaVista: 0,
        flBaixaAutomatica: 0,
    },
    invalido: {
        codTipoPgto: false,
        descricao: false,
    },
    formasPgtos: [],
    tiposPgtos: [],
    inicioCadastro: false,
    formasPgtoCopia: {}
};

export default function formasPagamentosStore(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'FORMAS_PAGAMENTOS':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'FORMAS_PGTOS':
                    return { ...state, formasPgtos: action.value }
                case 'TIPOS_PGTOS':
                    return { ...state, tiposPgtos: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: {
                            codForma: 0,
                            descricao: '',
                            ativos: 0,
                            codTipoPgto: '0',
                            flTipo: 0,
                            qtdDiasEntreParcelas: 0,
                            diasPrazo: 0,
                            percentualTarifa: 0,
                            flAbreCaixa: 0,
                            flRetirada: 0,
                            flaVista: 0,
                            flBaixaAutomatica: 0,
                        },
                        formasPgtos: []
                    }
                case 'FORMA_PGTO':
                    return { ...state, formaPgto: { ...state.formaPgto, [action.campo]: action.value } }
                case 'FORMA_PGTOS':
                    return { ...state, formaPgto: action.value }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value } 
                case 'FORMAS_PGTOS_COPIA':
                    return { ...state, formasPgtoCopia: state.formaPgto }
                case 'CLEAN_FORMA_PGTO':
                    return {
                        ...state,
                        formaPgto: {
                            codForma: 0,
                            descricao: '',
                            status: 0,
                            codTipoPgto: '0',
                            flTipo: 0,
                            qtdDiasEntreParcelas: 0,
                            diasPrazo: 0,
                            percentualTarifa: 0,
                            flAbreCaixa: 0,
                            flRetirada: 0,
                            flaVista: 0,
                            flBaixaAutomatica: 0,
                        },
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: {
                            nome: false,
                            descricao: false,
                        },
                    }
                case 'CLEAN_FLAG':
                    return {
                        ...state,
                        formaPgto: {
                            ...state.formaPgto,
                            diasPrazo: 0,
                            flAbreCaixa: 0,
                            flRetirada: 0,
                            flaVista: 0,
                            flBaixaAutomatica: 0,
                        },
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}