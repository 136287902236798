import React, { useEffect, useState } from 'react'
import {Page, Text, View, Document, Image} from '@react-pdf/renderer'
import axios from 'axios'
import api from '../../../../Util/api'

import {styles, stylesCupom} from  './styles'
import { maskCNPJ, maskCPF, maskMoeda } from '../../../../Util/mascaras'
import moment from 'moment'
import { useCallback } from 'react'

import Logo from '../../../../assets/img/logoern.png'

var numPedidoAux = ''

export function numPedidoPDF(numPedido?: any) {
    numPedidoAux = numPedido
    return numPedidoAux
};

export const PedidoPDFContent: React.FC = () => {
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
    const [itens, setItens] = useState([])
    const [lancamentos, setLancamentos] = useState([])
    const [cliente, setCliente] = useState({})
    const [nomeFuncionario, setNomeFuncionario] = useState('')
    const [cpfCnpjFormatted, setCpfCnpjFormatted] = useState('')
    const [valorTotal, setValorTotal] = useState('')
    const [valorSubTotal, setValorSubTotal] = useState('')
    const [valorDesconto, setValorDesconto] = useState('')
    const [totaisSum, setTotaisSum] = useState({})

    const loadPedido = async () => {
        const response = await axios.get(api.urlMovimento + `api/v1/Movimento/${numPedidoAux}`, {
            headers: { Authorization: bearerAuth }
        })
        const {produtos, destinatario, nomeFuncionario, valorSubTotal, valorDesconto, valorTotal} = await response.data.data
        setItens(produtos)
        setNomeFuncionario(nomeFuncionario)
        setCliente(destinatario)
        setCpfCnpjFormatted(destinatario.documento.length > 11 ? maskCNPJ(destinatario.documento) : maskCPF(destinatario.documento))
        setValorDesconto(valorDesconto)
        setValorSubTotal(valorSubTotal)
        setValorTotal(valorTotal)

        loadLancamentos(numPedidoAux)
    }

    useEffect(() => {
            if(numPedidoAux){
                loadPedido()
            }
    }, [numPedidoAux])

    const loadLancamentos = async (numPedido: any) => {
       const response = await axios.get(api.urlFinanceiro + `api/v1/titulos/retornartitulosbypedido/${numPedido}`, {
            headers: { Authorization: bearerAuth }
        })
        setLancamentos(response.data)
    }

    useEffect(() => {
        if(lancamentos.length > 0) {
            //@ts-ignore
            const sumSubTotal = lancamentos.map(l => Number(l.valorTitulo))
            const resultSumSubTotal = reduceSum(sumSubTotal)
            //@ts-ignore
            const sumDesconto = lancamentos.map(l => Number(l.valorDesconto))
            const resultSumDesconto = reduceSum(sumDesconto)
            //@ts-ignore
            const sumJuros = lancamentos.map(l => Number(l.valorJuros))
            const resultSumJuros = reduceSum(sumJuros)
            //@ts-ignore
            const sumMulta = lancamentos.map(l => Number(l.valorMulta))
            const resultSumMulta = reduceSum(sumMulta)
            //@ts-ignore
            const sumTarifa = lancamentos.map(l => Number(l.valorTarifa))
            const resultSumTarifa = reduceSum(sumTarifa)
            //@ts-ignore
            const sumValorPago = lancamentos.map(l => Number(l.valorTotalPago))
            const resultSumValorPago = reduceSum(sumValorPago)

            setTotaisSum({
                subTotal: resultSumSubTotal,
                desconto: resultSumDesconto,
                juros: resultSumJuros,
                multa: resultSumMulta,
                tarifa: resultSumJuros,
                totalPago: resultSumValorPago,
                total: (Number(resultSumSubTotal) - Number(resultSumDesconto) + Number(resultSumJuros) + Number(resultSumMulta) + Number(resultSumTarifa) )
            })
        }
    }, [lancamentos])

    const reduceSum = useCallback((data: any[], ) => {
        let itensSum = data.reduce((itemValue, value) => {
          return Number(itemValue) + Number(value)
        }, 0)
        return itensSum
      }, [])



    return(
            <Page size="A4" style={styles.container}>
                <View style={styles.header} >
                    <View style={[styles.headerItens]} >
                        <Image src={Logo} />
                    </View>
                    <View style={[styles.headerItens, {width: '50%'}]} >
                        <Text style={[ {fontSize: '10px', textTransform: 'uppercase', fontWeight: 'bold'} ]} >Lucas Monteiro Reis Pereira</Text>
                        <Text style={[ {fontSize: '8px', textTransform: 'uppercase'} ]} >Rua São Paulo, 998 - Vila Aparecida CEP: 14401-248 - Franca/SP</Text>
                        <Text style={[ {fontSize: '8px', textTransform: 'uppercase'} ]} >CNPJ: 40.211.176/0001-07 - IE: 310.880.353.119 - Tel: (16) 3409-7039 </Text>
                    </View>
                    <View style={[styles.headerItens, { width: '20%' }]} >
                       <View style={styles.headerRow} >
                            <Text style={[{fontSize: '8px', fontWeight: 'bold'}]} >Emissão</Text>
                            <Text style={[ styles.headerText, {fontSize: '8px'} ]} >{moment(new Date()).format('DD-MM-YYYY')}</Text>
                       </View>
                       <View style={styles.headerRow} >
                            <Text style={[{fontSize: '8px', fontWeight: 'bold'}]} >Hora</Text>
                            <Text style={[ styles.headerText, {fontSize: '8px'} ]} >{moment(new Date()).format('hh:mm:ss')}</Text>
                       </View>
                    </View>
                </View>
                <View>
                    <Text style={styles.title} >PEDIDO/ORÇAMENTO: {numPedidoAux}</Text>
                </View>
                <View style={styles.infoContainer}>
                    <View style={styles.infoHeader}>
                        <View >
                            <Text style={[styles.text, { margin: '3px 0' }]} >
                                Cliente: {
                                    //@ts-ignore
                                    cliente.nomeRazao
                                }
                            </Text>
                            <Text style={[styles.text, { margin: '3px 0' }]} >CPF/CNPJ: {
                                    //@ts-ignore
                                    cliente.documento && cpfCnpjFormatted
                                }
                            </Text>
                        </View>
                        <View style={styles.viewInfoAbsolute}>
                            <Text style={[styles.text, { margin: '3px 0' }]} >Vendedor: {
                                //@ts-ignore
                                nomeFuncionario
                                }
                            </Text>
                            <Text style={[styles.text, { margin: '3px 0' }]} >Data: </Text>
                        </View>
                    </View>
                    <View>
                        <Text style={[styles.text, { margin: '3px 0' }]} >Endereço: </Text>
                        <Text style={[styles.text, { margin: '3px 0' }]} >Telefone: </Text>
                    </View>
                </View>
                <View style={{ borderBottom: '1px solid #333' }}>
                    <Text style={styles.titleBackground} >Produto(s)</Text>
                    <View style={styles.table} >
                        <View style={styles.tableRow} >
                            <Text style={[styles.tableText, {width: '10%'}]} >Num:</Text>
                            <Text style={[styles.tableText, {width: '10%'}]} >Código. I:</Text>
                            <Text style={[styles.tableText, {width: '40%'}]} >Descrição:</Text>
                            <Text style={[styles.tableText, {width: '10%'}]} >Quantidade:</Text>
                            <Text style={[styles.tableText, {width: '10%', textAlign: 'right'}]} >Valor Unit.</Text>
                            <Text style={[styles.tableText, {width: '10%', textAlign: 'right'}]} >Desconto</Text>
                            <Text style={[styles.tableText, {width: '10%', textAlign: 'right'}]} >Total</Text>
                        </View>
                            {itens.length > 0 &&
                                itens.map((i, index) => (
                                    <View style={[styles.tableRow, {backgroundColor: '#acacac'}]} >
                                        <Text style={[styles.tableText, {width: '10%'}]} >
                                            {
                                                //@ts-ignore
                                                index + 1
                                            }
                                        </Text>
                                        <Text style={[styles.tableText, {width: '10%'}]} >
                                            {
                                                //@ts-ignore
                                                i.codProduto
                                            }
                                        </Text>
                                        <Text style={[styles.tableText, {width: '40%'}]} >
                                            {
                                                //@ts-ignore
                                                i.descricao
                                            }
                                        </Text>
                                        <Text style={[styles.tableText, {width: '10%'}]} >
                                            {
                                                //@ts-ignore
                                                i.quantidade
                                            }
                                        </Text>
                                        <Text style={[styles.tableText, {width: '10%', textAlign: 'right'}]} >
                                            {
                                                //@ts-ignore
                                                maskMoeda(Number(i.valorUnitario).toFixed(2))
                                            }
                                        </Text>
                                        <Text style={[styles.tableText, {width: '10%', textAlign: 'right'}]} >
                                            {
                                                //@ts-ignore
                                                maskMoeda(Number(i.valorDesconto).toFixed(2))
                                            }
                                        </Text>
                                        <Text style={[styles.tableText, {width: '10%', textAlign: 'right'}]} >
                                            {
                                                //@ts-ignore
                                                maskMoeda(Number(i.valorTotal).toFixed(2))
                                            }
                                        </Text>
                                    </View>
                                ))
                            }
                            <Text style={styles.titleBackground} >Total Pedido</Text>
                            <View  style={[styles.tableRow, {justifyContent: 'flex-end'}]} >
                                <Text style={[styles.tableText, {width: '10%', textAlign: 'right'}]}>
                                    {
                                        //@ts-ignore
                                        maskMoeda(Number(valorSubTotal).toFixed(2))
                                    }
                                </Text>
                                <Text style={[styles.tableText, {width: '10%', textAlign: 'right'}]}>
                                    {
                                        //@ts-ignore
                                        maskMoeda(Number(valorDesconto).toFixed(2))
                                    }
                                </Text>
                                <Text style={[styles.tableText, {width: '10%', textAlign: 'right'}]}>
                                    {
                                        //@ts-ignore
                                        maskMoeda(Number(valorTotal).toFixed(2))
                                    }
                                </Text>
                            </View>
                    </View>
                </View>
               {lancamentos.length > 0 && (
                    <View style={styles.lancamentosContainer} >
                        <View style={styles.lancamentosItem} >
                            <Text style={[styles.titleBackground, {width: '100%'}]} >Lançamentos</Text>
                            <View style={styles.table} >
                                <View style={styles.tableRow} >
                                    <Text style={[styles.tableText, {width: '40%'}]} >Vencimento</Text>
                                    <Text style={[styles.tableText, {width: '30%'}]} >Parcela</Text>
                                    <Text style={[styles.tableText, {width: '30%', textAlign: 'right'}]} >Valor</Text>
                                </View>
                                {lancamentos.map(l => (
                                    <View style={styles.tableRow} >
                                        <Text style={[styles.tableText, {width: '40%'}]} >{
                                                //@ts-ignore
                                                moment(l.dataVencimento).format('DD-MM-YYYY')
                                            }
                                        </Text>
                                        <Text style={[styles.tableText, {width: '30%', textAlign: 'left'}]} >{
                                                //@ts-ignore
                                                `${l.parcela}/${l.quantidadeParcelas}`
                                            }
                                        </Text>
                                        <Text style={[styles.tableText, {width: '30%', textAlign: 'right'}]} >{
                                                //@ts-ignore
                                                maskMoeda(Number(l.valorTitulo).toFixed(2))
                                            }
                                        </Text>
                                    </View>
                                ))}
                            </View>  
                        </View>     
                        <View style={styles.lancamentosItem} >
                            <View style={[styles.lancamentosTotais]} >
                                <View style={[ styles.lancamentosTotaisRow ]} >
                                    <Text style={[{fontSize: '10px', fontWeight: 'bold'}]} >
                                        SubTotal (R$):
                                    </Text>
                                    <Text style={[styles.lancamentosTotaisText]} >
                                        {
                                            //@ts-ignore
                                            maskMoeda(Number(totaisSum.subTotal).toFixed(2))
                                        }
                                    </Text>
                                </View>
                                <View style={[ styles.lancamentosTotaisRow ]} >
                                    <Text style={[{fontSize: '10px', fontWeight: 'bold'}]} >
                                        Desconto (R$):
                                    </Text>
                                    <Text style={[styles.lancamentosTotaisText]} >
                                        {
                                            //@ts-ignore
                                            maskMoeda(Number(totaisSum.desconto).toFixed(2))
                                        }
                                    </Text>
                                </View>
                                <View style={[ styles.lancamentosTotaisRow ]} >
                                    <Text style={[{fontSize: '10px', fontWeight: 'bold'}]} >
                                        Total (R$):
                                    </Text>
                                    <Text style={[styles.lancamentosTotaisText]} >
                                        {
                                            //@ts-ignore
                                            maskMoeda(Number(totaisSum.total).toFixed(2))
                                        }
                                    </Text>
                                </View>
                            </View>  
                        </View>               
                    </View>
               )}
            </Page>
    )
}

export const PedidoPDF = (
    <Document>
        <PedidoPDFContent />
    </Document>
)

export const PedidoCupomContent: React.FC = () => {
    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
    const [itens, setItens] = useState([])
    const [lancamentos, setLancamentos] = useState([])
    const [cliente, setCliente] = useState({})
    const [cpfCnpjFormatted, setCpfCnpjFormatted] = useState('')
    const [valorTotal, setValorTotal] = useState('')
    const [valorSubTotal, setValorSubTotal] = useState('')
    const [valorDesconto, setValorDesconto] = useState('')
    const [totaisSum, setTotaisSum] = useState({})

    const loadPedido = async () => {
        const response = await axios.get(api.urlMovimento + `api/v1/Movimento/${numPedidoAux}`, {
            headers: { Authorization: bearerAuth }
        })
        const {produtos, destinatario, valorSubTotal, valorDesconto, valorTotal} = await response.data.data
        setItens(produtos)
        setCliente(destinatario)
        setCpfCnpjFormatted(destinatario.documento.length > 11 ? maskCNPJ(destinatario.documento) : maskCPF(destinatario.documento))
        setValorDesconto(valorDesconto)
        setValorSubTotal(valorSubTotal)
        setValorTotal(valorTotal)

        loadLancamentos(numPedidoAux)
    }

    useEffect(() => {
            if(numPedidoAux){
                loadPedido()
            }
    }, [numPedidoAux])

    const loadLancamentos = async (numPedido: any) => {
       const response = await axios.get(api.urlFinanceiro + `api/v1/titulos/retornartitulosbypedido/${numPedido}`, {
            headers: { Authorization: bearerAuth }
        })
        setLancamentos(response.data)
    }

    useEffect(() => {
        if(lancamentos.length > 0) {
            //@ts-ignore
            const sumSubTotal = lancamentos.map(l => Number(l.valorTitulo))
            const resultSumSubTotal = reduceSum(sumSubTotal)
            //@ts-ignore
            const sumDesconto = lancamentos.map(l => Number(l.valorDesconto))
            const resultSumDesconto = reduceSum(sumDesconto)
            //@ts-ignore
            const sumJuros = lancamentos.map(l => Number(l.valorJuros))
            const resultSumJuros = reduceSum(sumJuros)
            //@ts-ignore
            const sumMulta = lancamentos.map(l => Number(l.valorMulta))
            const resultSumMulta = reduceSum(sumMulta)
            //@ts-ignore
            const sumTarifa = lancamentos.map(l => Number(l.valorTarifa))
            const resultSumTarifa = reduceSum(sumTarifa)
            //@ts-ignore
            const sumValorPago = lancamentos.map(l => Number(l.valorTotalPago))
            const resultSumValorPago = reduceSum(sumValorPago)

            setTotaisSum({
                subTotal: resultSumSubTotal,
                desconto: resultSumDesconto,
                juros: resultSumJuros,
                multa: resultSumMulta,
                tarifa: resultSumJuros,
                totalPago: resultSumValorPago,
                total: (Number(resultSumSubTotal) - Number(resultSumDesconto) + Number(resultSumJuros) + Number(resultSumMulta) + Number(resultSumTarifa) )
            })
        }
    }, [lancamentos])

    const reduceSum = useCallback((data: any[], ) => {
        let itensSum = data.reduce((itemValue, value) => {
          return Number(itemValue) + Number(value)
        }, 0)
        return itensSum
      }, [])

    return(
            <Page size="A4" style={[stylesCupom.container, {padding: '10px'}]}>
                <View style={stylesCupom.headerCupom} >
                    <Text style={[ stylesCupom.headerText]} >Lucas Monteiro Reis Pereira</Text>
                    <Text style={[ stylesCupom.headerText]} >Rua São Paulo, 998</Text>
                    <Text style={[ stylesCupom.headerText]} >Vila Aparecida 14401-248 Franca/SP</Text>
                    <Text style={[ stylesCupom.headerText]} >40.211.176/0001-07 - 310.880.353.119</Text>
                    <Text style={[ stylesCupom.headerText, {fontSize: '6px'}]} >Não é Documento Fiscal</Text>
                </View>
                <View style={[stylesCupom.infoPedido, {width: '180px'}]} >
                    <Text style={[ stylesCupom.textCupom ]}>Pedido: {numPedidoAux}</Text>
                    <Text style={[ stylesCupom.textCupom ]} >Observação:</Text>
                    <Text style={[ stylesCupom.textCupom ]} >Impressão em
                        <Text style={[ stylesCupom.textCupom ]} > {moment(new Date()).format('DD-MM-YYYY')}</Text>  
                        <Text style={[ stylesCupom.textCupom, { marginLeft: '5px'} ]} > {moment(new Date()).format('hh:mm:ss')}</Text>
                    </Text>
                </View>
                <View style={{width: '180px'}} >
                    <Text style={[ stylesCupom.textCupom ]} >
                        Cliente: {
                            //@ts-ignore
                            cliente.nomeRazao
                        }
                    </Text>
                </View>
                <View style={[stylesCupom.infoPedido, {width: '180px'}]} >
                    <View style={[styles.table, {width: '180px'}]} >
                        <View style={styles.tableRow} >
                            <Text style={[styles.tableText, {width: '40%'}]} >Descrição:</Text>
                            <Text style={[styles.tableText, {width: '5%'}]} >Qtd:</Text>
                            <Text style={[styles.tableText, {width: '25%', textAlign: 'right'}]} >Vlr Unit.</Text>
                            <Text style={[styles.tableText, {width: '15%', textAlign: 'right'}]} >Desc</Text>
                            <Text style={[styles.tableText, {width: '15%', textAlign: 'right'}]} >Total</Text>
                        </View>
                            {itens.length > 0 &&
                                itens.map((i) => (
                                    <View style={[styles.tableRow]} >
                                        <Text style={[styles.tableText, {width: '40%', fontSize: '6px'}]} >
                                            {
                                                //@ts-ignore
                                                i.descricao
                                            }
                                        </Text>
                                        <Text style={[styles.tableText, {width: '5%', fontSize: '6px'}]} >
                                            {
                                                //@ts-ignore
                                                i.quantidade
                                            }
                                        </Text>
                                        <Text style={[styles.tableText, {width: '25%', fontSize: '6px', textAlign: 'right'}]} >
                                            {
                                                //@ts-ignore
                                                maskMoeda(Number(i.valorUnitario).toFixed(2))
                                            }
                                        </Text>
                                        <Text style={[styles.tableText, {width: '15%', fontSize: '6px', textAlign: 'right'}]} >
                                            {
                                                //@ts-ignore
                                                maskMoeda(Number(i.valorDesconto).toFixed(2))
                                            }
                                        </Text>
                                        <Text style={[styles.tableText, {width: '15%', fontSize: '6px', textAlign: 'right'}]} >
                                            {
                                                //@ts-ignore
                                                maskMoeda(Number(i.valorTotal).toFixed(2))
                                            }
                                        </Text>
                                    </View>
                                ))
                            }
                            <View style={[stylesCupom.infoPedido, stylesCupom.cupomContent]}>
                                <Text style={[ stylesCupom.titlesBoldCupom ]} >SubTotal</Text>
                                <Text style={styles.tableText} >
                                    {
                                        //@ts-ignore
                                        maskMoeda(Number(valorSubTotal).toFixed(2))
                                    }
                                </Text>
                            </View>
                            <View style={[stylesCupom.cupomContent, {borderBottom: '1px solid #333'}]}>
                                <Text style={[ styles.tableText ]} >Desconto</Text>
                                <Text style={styles.tableText} >
                                    {
                                        //@ts-ignore
                                        maskMoeda(Number(valorDesconto).toFixed(2))
                                    }
                                </Text>
                            </View>
                            <View style={[stylesCupom.cupomContent, {paddingBottom: '50px'}]}>
                                <Text style={[ stylesCupom.titlesBoldCupom, {fontSize: '10px'} ]} >Total</Text>
                                <Text style={[styles.tableText, {fontSize: '10px'}]} >
                                    {
                                        //@ts-ignore
                                        maskMoeda(Number(valorTotal).toFixed(2))
                                    }
                                </Text>
                            </View>
                    </View>
                </View>
                <View style={{width: '180px'}} >
                    <Text style={[styles.tableText, {  textAlign: 'center', marginBottom: '20px'}]} >Nome por extenso</Text>
                </View>
               {lancamentos.length > 0 && (
                    <View style={{width: '180px'}} >
                        
                        <View style={styles.table} >
                            <View style={styles.tableRow} >
                                <Text style={[styles.tableText, {width: '30%'}]} >Venc</Text>
                                <Text style={[styles.tableText, {width: '10%'}]} >Par</Text>
                                <Text style={[styles.tableText, {width: '20%', textAlign: 'right'}]} >Valor</Text>
                                <Text style={[styles.tableText, {width: '40%', marginLeft: '10px'}]} >Tipo</Text>
                            </View>
                            {lancamentos.map(l => (
                                <View style={styles.tableRow} >
                                    <Text style={[styles.tableText, {width: '30%', fontSize: '6px'}]} >{
                                            //@ts-ignore
                                            moment(l.dataVencimento).format('DD/MM/YYYY')
                                        }
                                    </Text>
                                    <Text style={[styles.tableText, {width: '10%', textAlign: 'left', fontSize: '6px'}]} >{
                                            //@ts-ignore
                                            `${l.parcela}/${l.quantidadeParcelas}`
                                        }
                                    </Text>
                                    <Text style={[styles.tableText, {width: '20%', textAlign: 'right', fontSize: '6px'}]} >{
                                            //@ts-ignore
                                            maskMoeda(Number(l.valorTitulo).toFixed(2))
                                        }
                                    </Text>
                                    <Text style={[styles.tableText, {width: '40%', marginLeft: '10px', fontSize: '5px'}]} >{
                                            //@ts-ignore
                                            l.descricaoForma
                                        }
                                    </Text>
                                </View>
                            ))}
                        </View>
                    </View>
               )}
            </Page>
    )
}

export const CupomPDF = (
    <Document>
        <PedidoCupomContent />
    </Document>
)