import React, { useEffect } from "react";

import {
    Card,
    Col,
    CardHeader,
    CardBody,
    CardTitle
} from "reactstrap";

import TabContent from "./TabContent/TabContent"
import TelaPesquisar from './/TabContent/Tabs/Pesquisar';
import TelaFecharCaixa from './TabContent/Tabs/FecharCaixa';
import * as action from '../../store/reducers/Cadastro/FechamentoCaixa/actions'

const Tabs = [
    {
        tabName: "PESQUISAR",
        tabScreen: <TelaPesquisar />
    },
    {
        tabName: "Fechar Caixa",
        tabScreen:  <TelaFecharCaixa />
    },
]

const FechamentoCaixas = () => {

    useEffect(() => {

        return () => {
            action.cleanAll()
        }

    }, [])

    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="12">
                    <Card>

                        <CardHeader className="text-center">
                            <CardTitle tag="h3">Fechamento de Caixa</CardTitle>
                        </CardHeader>

                        <CardBody>
                            <TabContent tabs={Tabs} />
                        </CardBody>
                    </Card>
                </Col>
            </div>
        </>
    );
}

export default FechamentoCaixas;
