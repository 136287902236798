import { Pesquisa } from "../../Cadastro/Produto/types";

export enum PedidoVendaStore {
    ID_STORE = 'PEDIDO_VENDA'
}

export enum PedidoVendaTypes {
    CLEAN = 'CLEAN',
    CLEAN_CLIENTES = 'CLEAN_CLIENTES',
    CLEAN_CLIENTE = 'CLEAN_CLIENTE',
    CLIENTE = 'CLIENTE',
    CLIENTES = 'CLIENTES',
    OPERACOES = 'OPERACOES',
    OPERACAO = 'OPERACAO',
    VENDEDORES = 'VENDEDORES',
    ITEMS = 'ITEMS',
    ADD_ITEM = 'ADD_ITEM',
    TOTAIS = 'TOTAIS',
    INFORMACOES = 'INFORMACOES',
    PRODUTO = 'PRODUTO',
    PRODUTOS = 'PRODUTOS',
    SUB_GRUPO_PRODUTOS = 'SUB_GRUPO_PRODUTOS',
    MINI_CARD_SELECTED = 'MINI_CARD_SELECTED ',
    PAGINATION = 'PAGINATION',
    PEDIDO = 'PEDIDO',
    PEDIDOS = 'PEDIDOS',
    PESQUISA = 'PESQUISA',
    CLEAN_PESQUISA = 'CLEAN_PESQUISA',
    TOTAIS_PESQUISA = 'TOTAIS_PESQUISA',
    TOTAIS_DETALHES_PESQUISA = 'TOTAIS_DETALHES_PESQUISA',
    ITENS_GRADE_PDV = 'ITENS_GRADE_PDV',
    DETALHES_PEDIDO_ITENS = 'DETALHES_PEDIDO_ITENS',
    DETALHES_PEDIDO_PAGAMENTO = 'DETALHES_PEDIDO_PAGAMENTO',
    SEGURANCA_PEDIDO_CREDENCIAIS = 'SEGURANCA_PEDIDO_CREDENCIAIS',
    CLEAN_SEGURANCA_PEDIDO_CREDENCIAIS = 'CLEAN_SEGURANCA_PEDIDO_CREDENCIAIS ',
    INFORMACAO_ENVIO_PEDIDO = 'INFORMACAO_ENVIO_PEDIDO',
    CLEAN_INFORMACAO_ENVIO_PEDIDO = 'CLEAN_INFORMACAO_ENVIO_PEDIDO',
    DETALHES_FORMA_PAGAMENTO = 'DETALHES_FORMA_PAGAMENTO',
    CLEAN_DETALHES_PEDIDO = 'CLEAN_DETALHES_PEDIDO',
    FORMAS_PAGAMENTOS = 'FORMAS_PAGAMENTOS',
    RETIRADAS_CAIXA = 'RETIRADAS_CAIXA',
    ADD_RETIRADA_CAIXA = 'ADD_RETIRADA_CAIXA',
    CLEAN_RETIRADA_CAIXA = 'CLEAN_RETIRADA_CAIXA'
}

export type PedidoVendaAction = {
    idStore: PedidoVendaStore.ID_STORE
    type: PedidoVendaTypes
    cliente?: Cliente
    clientes?: Cliente[]
    operacoes?: Operacao[]
    vendedores?: Vendedor[]
    item?: Item
    itens?: Item[]
    totais?: Totais
    informacoes?: Informacoes
    produto?: Produto
    campo?: any
    value?: any
    produtos?: Produto[]
    miniCardSelected?: miniCardSelected
    subGrupoProdutos?: SubGrupoProdutos[]
    operacao?: Operacao
    pesquisa?: IPesquisa
    pagination?: IPagination
    pedidos?: IPedido[]
    pedido?: IPedido,
    totaisPesquisa?: ITotaisPesquisa
    totaisDetalhesPesquisa?: ITotaisDetalhesPesquisa
    itensGrade?: IItensGradePDV
    detalhesPedidoItens?: IDetalhesPedidoItens[]
    detalhesPedidoPagamento?: IDetalhesPedidoPagamento[]
    segurancaPedidoCredenciais?: ISegurancaPedidoCredenciais
    informacaoEnvioPedido?: IInformacaoEnvioPedido,
    detalhesFormaPagamento?: IDetalhesFormaPagamento[]
    retiradasCaixa?: IRetiradasCaixa[]
    formasPagamentos?: IFormasPagamentos[]
    retiradaCaixa?: IRetiradasCaixa
}

export interface PedidoVendaState {
    itensGrade: IItensGradePDV
    pedidos: IPedido[]
    pedido: IPedido
    detalhesPedidoItens: IDetalhesPedidoItens[]
    detalhesPedidoPagamento: IDetalhesPedidoPagamento[]
    detalhesFormaPagamento: IDetalhesFormaPagamento[]
    segurancaPedidoCredenciais: ISegurancaPedidoCredenciais
    informacaoEnvioPedido: IInformacaoEnvioPedido
    pagination: IPagination,
    totaisPesquisa: ITotaisPesquisa | any
    pesquisa: IPesquisa | any
    totaisDetalhesPesquisa?: ITotaisDetalhesPesquisa | any
    cliente: Cliente
    itens: Item[]
    totais: Totais
    informacoes: Informacoes
    subGrupoProdutos: SubGrupoProdutos[]
    produto: Produto
    produtos: Produto[]
    miniCardSelected: miniCardSelected
    clientes: Cliente[]
    operacoes: Operacao[]
    operacao: Operacao
    vendedores: Vendedor[]
    retiradasCaixa: IRetiradasCaixa[]
    retiradaCaixa: IRetiradasCaixa
    formasPagamentos: IFormasPagamentos[]
}

export interface Item {
    item: number
    codProduto: number
    imagem: string
    numProduto: number
    codBarras: string
    nome: string
    precoVendaAtual: string | number
    quantidade: string | number
    descontoReais: string | number
    descontoPercent: string | number
    subtotal: string | number
    total: string | number,
    flControlaGrade: number
}

export interface Totais {
    quantidadeTotal: number
    quantidadeItens: number
    subtotal: number
    desconto: number
    total: number
}

export interface Cliente {
    codCli: number
    cpfCnpj: string
    nomeRazao: string
    tipoCli: string
}

export interface Operacao {
    codigo: number
    numero: number
    descricao: string
    geraFinanceiro: Number
}

export interface Vendedor {
    codFunc: number,
    nomeVendedor: string,
    cpf: string,
    funcao: string
}

export interface Informacoes {
    numPedido: number,
    codCaixa: number,
    presenca: {
        codigo: number
        descricao: string
    }
    usuario: {
        codUser: number
        descricao: string
    }
    vendedor: Vendedor
}

export interface Produto {
    item: number,
    codProduto: number,
    imagem: string,
    numProduto: number,
    codBarras: string,
    nome: string,
    precoVendaAtual: number,
    quantidade: number,
    descontoReais: number,
    descontoPercent: number,
    subtotal: number,
    total: number,
    flControlaGrade: number,
}

export interface miniCardSelected {
    produto: number,
    subGrupo: number
}

export interface SubGrupoProdutos {
    codSubgrupo: string
    nome: string
}

export interface IPesquisa {
    numPedido?: string | number,
    docFiscal?: string | number,
    cpfCnpj?: string,
    numRazaoSocial?: string | number,
    status?: string | number,
    empresa?: string | number,
    dateOrderInitial?: string | any,
    dateOrderFinally?: string | any,
    page: number,
    pageSize: number
}

export interface IPagination {
    totalPage: number
}

export interface IPedido {
    cod: string | number,
    numPedido: string | number,
    data: string | number,
    docFiscal: string | number,
    empresa: string,
    valorDesconto: string | number,
    nomeFuncionario: string,
    cliente: string,
    caixa: string,
    operacao: string,
    valorSubTotal: string | number,
    total: string | number,
    status: string | number
}

export interface ITotaisPesquisa {
    valor: string | number,
    desconto: string | number,
    total: string | number
}

export interface ITotaisDetalhesPesquisa {
    totalProd: string | number,
    descProd: string | number,
    totalVenda: string | number,
    titulo: string | number,
    juros: string | number,
    multa: string | number,
    descPag: string | number,
    pago: string | number
}


export interface IItensGradePDV {
    codigoProduto: string | number,
    codTipoGrade: string | number,
    grades: IGradePDV[]
}

export interface IGradePDV {
    codGrade: string | number,
    dtHoraAtualização: string | number,
    qtdEstoqueGrade: string | number,
    estoqueAtualizar: string | number,
    descricao: string | number,
    sigla: string | number,
    status: string | number,
}

export interface IDetalhesPedidoItens {
    codProduto: string | number
    codigoBarras: string | number
    controlaGrade: boolean
    dataLancamento: string | number
    descricao: string | number
    grades: IGradePDV[]
    item: string | number
    numero: string | number
    numeroCodBarras: string | number
    numeroPedidoItem: string | number
    quantidade: string | number
    status: string | number
    valorDesconto: string | number
    valorSubTotal: string | number
    valorTotal: string | number
    valorUnitario: string | number
}

export interface IDetalhesPedidoPagamento {
    numeroPedido: string | number,
    quantidadeParcelas: string | number,
    codigoTitulo: string | number,
    codigoTituloMovimento: string | number,
    codigoForma: string | number,
    descricaoForma: string | number,
    parcela: string | number,
    dataVencimento: string | number,
    dataPagamento: string | number,
    valorTitulo: string | number,
    valorJuros: string | number,
    valorMulta: string | number,
    valorTarifa: string | number,
    valorDesconto: string | number,
    valorTotal: string | number,
    valorTotalPago: string | number,
    status: string | number
}

export interface ISegurancaPedidoCredenciais {
    usuario: string,
    senha: string,
    motivo: string
}

export interface IInformacaoEnvioPedido {
    whatsapp: {
        telefone: string | number
    },
    email: {
        endereco: string
    }
}

export interface IDetalhesFormaPagamento {
    formaPagamento: string,
    dataLancamento: string,
    jurosLancamento: number,
    multaLancamento: number,
    tarifaLancamento: number,
    descontoLancamento: number,
    totalPagoLancamento: number,
    valorLancamento: number,
}

export interface IRetiradasCaixa {
    codigoCaixaLancto: number,
    codigoCaixa: number,
    codigoFormaPagamento: number,
    descricaoForma: string,
    operador: string,
    codigoTipoLancamento: number,
    dataLancamento: string,
    valorLancamento: number,
    observacao: string,
    status: number,
    autorizador: string
}

export interface IFormasPagamentos {
    codigoFormaPagamento: number,
    descricaoForma: string,
}