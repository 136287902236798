//State da tela de Pesquisa e cadastro de clientes
var INITIAL_STATE = {
    pesquisa: {
        nomeRazao: '',
        fantasia: '',
        cpfCnpj: '',
        tipo: '',
        ativos: '',
    },
    cliente: {
        codCli: '',
        nomeRazao: '',
        fantasia: '',
        cpfCnpj: '',
        inscest: '',
        rgInscMunic: '',
        orgaoEmissor: '',
        dtEmissao: '',
        email: '',
        email2: '',
        tipoCli: 'F',
        tipoContribuinte: "0",
        dtNascimento: '',
        sexo: 'O',
        flReceberEmail: 'S',
        status: 0,
        nomeEstrangeiro: '',
        documentoEstrangeiro: '',
        emailEstrangeiro: ''
    },
    endereco: {
        codEnd: '',
        idPais: '30',
        cep: '',
        uf: '',
        cidade: '',
        bairro: '',
        logradouro: '',
        numero: '',
        complemento: '',
        referencia: '',
        foneRes: '',
        foneCel: '',
        flPrincipal: '',
        tipoEnd: "R",
        ibge: '',
        gia: '',
        codPostal: ''
    },
    invalido: {
        nomeRazao: false,
        fantasia: false,
        cpfCnpj: false,
        rgInscMunic: false,
        email: false,
        email2: false,
        dtEmissao: false,
        dtNascimento: false,
        cep: false,
        nomeEstrangeiro: false,
        documentoEstrangeiro: false,
        emailEstrangeiro: false,
        idPais: false

    },
    clientes: [],
    enderecos: [],
    inicioCadastro: false,
    proximoCadastro: false,
    verCadastro: false,
    clienteCopia: {},
    enderecoCopia: [],
    Arrpaises: [],
    paises: [],
    initPaises: []
};

export default function clientes(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'CLIENTES':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'CLIENTES':
                    return { ...state, clientes: action.value }
                case 'ENDERECOS':
                    return { ...state, enderecos: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: {
                            nomeRazao: '',
                            fantasia: '',
                            cpfCnpj: '',
                            tipo: '',
                            ativos: '',
                        },
                        clientes: []
                    }
                case 'CLIENTE_COMPLETO':
                    return { ...state, cliente: action.value }
                case 'CLIENTE':
                    return { ...state, cliente: { ...state.cliente, [action.campo]: action.value } }
                case 'ENDERECO_COMPLETO':
                    return { ...state, endereco: action.value }
                case 'ENDERECO':
                    return { ...state, endereco: { ...state.endereco, [action.campo]: action.value } }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'PROXIMO_CADASTRO':
                    return { ...state, proximoCadastro: action.value }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value }
                case 'CLIENTE_COPIA':
                    return { ...state, clienteCopia: state.cliente }
                case 'CLIENTE_COPIA_STATUS':
                    return { ...state, clienteCopia: { ...state.cliente, status: action.value } }
                case 'ENDERECO_COPIA':
                    return { ...state, enderecoCopia: state.endereco }
                case 'CLEAN_CLIENTE':
                    return {
                        ...state,
                        cliente: {
                            ...state.cliente,
                            codEmpresa: '',
                            codCli: '',
                            nomeRazao: '',
                            fantasia: '',
                            cpfCnpj: '',
                            inscest: '',
                            rgInscMunic: '',
                            orgaoEmissor: '',
                            dtEmissao: '',
                            email: '',
                            email2: '',
                            tipoContribuinte: "0",
                            dtNascimento: '',
                            genero: 'O',
                            flReceberEmail: 'S',
                            status: 0,
                            nomeEstrangeiro: '',
                            documentoEstrangeiro: '',
                            emailEstrangeiro: '',

                        },
                    }
                case 'CLEAN_ENDERECO':
                    return {
                        ...state,
                        endereco: {
                            codEnd: '',
                            cep: '',
                            uf: '',
                            cidade: '',
                            bairro: '',
                            logradouro: '',
                            numero: '',
                            complemento: '',
                            referencia: '',
                            foneRes: '',
                            foneCel: '',
                            flPrincipal: '',
                            tipoEnd: "R",
                            ibge: '',
                            gia: '',
                            idPais: '30',
                            codPostal: ''

                        },
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: {
                            nomeRazao: false,
                            fantasia: false,
                            cpfCnpj: false,
                            rgInscMunic: false,
                            email: false,
                            email2: false,
                            dtNascimento: false,
                            dtEmissao: false,
                            cep: false,
                            nomeEstrangeiro: false,
                            documentoEstrangeiro: false,
                            emailEstrangeiro: false,
                            idPais: false
                        },
                    }
                case 'PAISES':
                    return { ...state, paises: action.value }

                case 'INIT_PAISES':
                    return { ...state, initPaises: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}