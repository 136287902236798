import React from "react";

import {
  Row,
  Col,
  FormGroup,
} from "reactstrap";

import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import AppState from "../../../../../store/storeType";
import { setDadosAdicionais } from "../../../../../store/reducers/Movimentacao/SaidaDiversa/actions";

function DadosAdicionais() {
    const dadosAdicionais = useShallowEqualSelector<AppState, string>(state => state.saidaDiversa.saida.dadosAdicionais)
    const invalido = useShallowEqualSelector<AppState, Boolean>(state => state.saidaDiversa.invalido.dadosIniciais.dadosAdicionais)
  
    function handleOnChange(value: string) {
      setDadosAdicionais(value)
    }
  
    return (
      <Row>
        <Col md="12">
          <label>Dados Adicionais</label>
          <FormGroup className={invalido ? "has-danger" : ''} >
            <textarea
              style={{ width: '100%', height: '250px' }}
              className="form-control"
              value={dadosAdicionais}
              onChange={(element: React.ChangeEvent<HTMLTextAreaElement>) => handleOnChange(element.currentTarget.value)}
            />
          </FormGroup>
        </Col>
      </Row>
    )
  }
  
  export default DadosAdicionais;
  
  