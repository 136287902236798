import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import ReactTable from 'react-table'
import Classes from 'classnames'
import { Col, Card, CardHeader, CardBody, CardTitle, Input, Row, Button } from 'reactstrap'
import { InputCpfCnpj, Select } from '../../../components/ErnComponets/ErnComponets'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import useShallowEqualSelector from '../../../Util/Hooks/useShallowEqualSelector';
import AppState from '../../../store/storeType';
import { IPagination, IPedido, IPesquisa, ITotaisPesquisa } from '../../../store/reducers/Movimentacao/PedidoVenda/types';
import * as action from '../../../store/reducers/Movimentacao/PedidoVenda/actions'
import * as controller from '../../../controller/Movimentacao/controllerPedidoVenda'
import * as controllerSaida from '../../../controller/Movimentacao/controllerSaidaDiversa'

import './styles.css'
import { maskMoeda } from '../../../Util/mascaras';
import { statusSelect } from './variables';
import ModalInfoPedidos from './Modals/ModalInfoPedidos';
import useModal from '../../../Util/Hooks/useModal';
import store from '../../../store/stores';
import { alertar } from '../../../Util/alertar';
import history from '../../../Util/history';
import Pagination from '../../../components/Pagination';
import { Empresas } from '../../../store/reducers/Movimentacao/SaidaDiversa/types';
import useResize from '../../../Util/Hooks/useResize'


const PesquisaPDV: React.FC = () => {
    const [modalInfoPedidos, toggleModalInfoPedidos] = useModal(false);
    const pesquisa = useShallowEqualSelector<AppState, IPesquisa>(state => state.pedidoVenda.pesquisa);
    const pedidos = useShallowEqualSelector<AppState, IPedido[]>(state => state.pedidoVenda.pedidos);
    const totaisPesquisa = useShallowEqualSelector<AppState, ITotaisPesquisa>(state => state.pedidoVenda.totaisPesquisa);
    const empresas = useShallowEqualSelector<AppState, Empresas[]>(state => state.saidaDiversa.empresas)
    const [rowSelected, setRowSelected] = useState('')
    const [pageSelect, setPageSelect] = useState(false)
    const [numPedidoSelect, setNumPedidoSelect] = useState(0)
    const [statusPedidoSelect, setStatusPedidoSelect] = useState('')
    const [classBody, setClassBody] = useState('')
    const {width} = useResize()

    //@ts-ignore
    const pagination = useShallowEqualSelector<AppState, IPagination>(state => state.pedidoVenda.pagination);

    const handleSearch = useCallback(async () => {
        //@ts-ignore
        const { notificacao } = store.getState()

        if (pesquisa.dateOrderInitial !== '' && pesquisa.dateOrderFinally !== '') {
            var dataI = pesquisa.dateOrderInitial.split('-')
            var dataF = pesquisa.dateOrderFinally.split('-')

            var newDataI = new Date(Number(dataI[0]), Number(dataI[1]) - 1, Number(dataI[2]))
            var newDataF = new Date(Number(dataF[0]), Number(dataF[1]) - 1, Number(dataF[2]))

            if (newDataI > newDataF) {
                alertar(notificacao, 'br', 'O campo Data Início não pode ser maior que o campo Data Fim', 'warning', 'icon-alert-circle-exc', 2)
                return
            }
        }

        await action.setPesquisa({...pesquisa, page: 1})
        const newPesquisa = store.getState().pedidoVenda.pesquisa
        await controller.getPedidos(newPesquisa)
    }, [pesquisa])

    useEffect(() => {
        if(pesquisa.page !== 1) {
            setPageSelect(false)
        }
    }, [pesquisa.dateOrderFinally, pesquisa.dateOrderInitial])
    useEffect(() => {
        if(pageSelect){
            //@ts-ignore
            const { notificacao } = store.getState()

            if (pesquisa.dateOrderInitial !== '' && pesquisa.dateOrderFinally !== '') {
                var dataI = pesquisa.dateOrderInitial.split('-')
                var dataF = pesquisa.dateOrderFinally.split('-')

                var newDataI = new Date(Number(dataI[0]), Number(dataI[1]) - 1, Number(dataI[2]))
                var newDataF = new Date(Number(dataF[0]), Number(dataF[1]) - 1, Number(dataF[2]))

                if (newDataI > newDataF) {
                    action.setPesquisa({...pesquisa, page: 1})
                    if(pesquisa.page === 1) {
                        alertar(notificacao, 'br', 'O campo Data Início não pode ser maior que o campo Data Fim', 'warning', 'icon-alert-circle-exc', 2)
                    }
                    return
                }
            }
            controller.getPedidos(pesquisa)
        }
    }, [pesquisa.page, pesquisa.pageSize])

    useEffect(() => {
        if(pesquisa.page > 1) {
            action.setPesquisa({...pesquisa, page: 1})
            const newPesquisa = store.getState().pedidoVenda.pesquisa
            controller.getPedidos(newPesquisa)
        }
    }, [pesquisa.dateOrderInitial, pesquisa.dateOrderFinally])

    const reduceSum = useCallback((data: string[], ) => {
        let itensSum = data.reduce((itemValue, value) => {
          return Number(itemValue) + Number(value)
        }, 0)
        return itensSum
      }, [])
    useEffect(() => {
        action.cleanPesquisa()
        controllerSaida.getEmpresasUsuario()
    }, [])

    useEffect(() => {
        if(empresas.length > 0) {
            action.setPesquisa({...pesquisa, empresa: empresas[0].codEmpresaFilial})
        }
    }, [empresas])

    useEffect(() => {
        const totais = pedidos.map(pedido => pedido.valorSubTotal)
        //@ts-ignore
        const total = reduceSum(totais)
        const descontos = pedidos.map(pedido => pedido.valorDesconto)
         //@ts-ignore
        const desconto = reduceSum(descontos)
        action.setTotaisPesquisa({
            ...totaisPesquisa,
            valor: Number(total).toFixed(2),
            desconto: Number(desconto).toFixed(2),
            total: Number(total) - Number(desconto)
        })
    }, [pedidos])

    const handleToggleModal = useCallback((numPedido, status) => {
        setStatusPedidoSelect(status)
        setNumPedidoSelect(numPedido)
        toggleModalInfoPedidos();
    }, [])

    const getTrProps = (state: any, row: any) => {
        return {
            style: {
                height: '50px',
                paddingTop: '0px',
                paddingBottom: '5px',
                //@ts-ignore
                backgroundColor: pedidos[row.index].numeroPedido === rowSelected ? 'rgba(71, 179, 255, 0.41)' : ''
            },
            onClick: (e: any, handleOriginal: any) => {
                //@ts-ignore
                setRowSelected(pedidos[row.index].numeroPedido)
                if (handleOriginal) {
                    handleOriginal()
                }
            },
            onDoubleClick: async () => {
                //@ts-ignore
                const numPedidoSelected = pedidos[row.index].numeroPedido
                //@ts-ignore
                await action.setPedido({ numPedido: numPedidoSelected, status: pedidos[row.index].status})
                await controller.getPedido(numPedidoSelected, pedidos[row.index].nomeFuncionario)
                history.push('/pedidoVenda')
            }
        }
    }

    return (
        <>
            <div id="searchOrder-container" className="content">
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle className="text-center">
                                <h3>Pesquisa de Pedidos</h3>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row className="padding-padrao-row" >
                                <Col md="3" className="padding-padrao-col" >
                                    <label htmlFor="">Empresas</label>
                                    <Select
                                        //@ts-ignore
                                        options={empresas}
                                        select={{ value: "codEmpresaFilial", label: "descricaoEmpresa" }}
                                        isSearchable={true}
                                        autoComplete="off"
                                        value={{ value: pesquisa.empresa }}
                                        onChange={(e: any) => action.setPesquisa({ ...pesquisa, empresa: e.value })}
                                    />
                                </Col>
                                <Col md={width < 840 ? 8 : 6}  className="padding-padrao-col" >
                                    <Row className="padding-padrao-row">
                                        <Col md="4" className="padding-padrao-col">
                                            <label htmlFor="">Nº Pedido</label>
                                            <Input
                                                type="text"
                                                autoComplete="off"
                                                placeholder="Número do pedido"
                                                onChange={(e) => action.setPesquisa({ ...pesquisa, numPedido: e.target.value })}
                                                value={pesquisa.numPedido}
                                            />
                                        </Col>
                                        <Col md="4" className="padding-padrao-col">
                                            <label htmlFor="">Nº Doc.Fiscal</label>
                                            <Input
                                                type="text"
                                                autoComplete="off"
                                                placeholder="Nota / Cupom"
                                                onChange={(e) => action.setPesquisa({ ...pesquisa, docFiscal: e.target.value })}
                                                value={pesquisa.docFiscal}
                                            />
                                        </Col>
                                        <Col md="4" className="padding-padrao-col">
                                            <label htmlFor="">CPF / CNPJ</label>
                                            <InputCpfCnpj
                                                type="text"
                                                autoComplete="off"
                                                placeholder="CPF / CNPJ"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => action.setPesquisa({ ...pesquisa, cpfCnpj: e.target.value })}
                                                value={pesquisa.cpfCnpj}
                                                maxLength="18"
                                                //@ts-ignore
                                                tipo={pesquisa.cpfCnpj.length > 14 ? 'cnpj' : 'cpf'}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={width < 840 ? 12 : 3} className="padding-padrao-col" >
                                    <label htmlFor="">Número / Razão Social</label>
                                    <Input
                                        type="text"
                                        autoComplete="off"
                                        placeholder=" Número ou razão social"
                                        onChange={(e) => action.setPesquisa({ ...pesquisa, numRazaoSocial: e.target.value })}
                                        //@ts-ignore
                                        value={pedidos.length === 1 ? pedidos[0].nomeDestinatario : ''}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row className="padding-padrao-row" >
                                <Col md="4" className="padding-padrao-col" >
                                    <label htmlFor="">Status</label>
                                    <Select
                                        first="Aberto"
                                        //@ts-ignore
                                        options={statusSelect}
                                        select={{ value: 'codStatus', label: 'statusDesc' }}
                                        isSearchable={true}
                                        autoComplete="off"
                                        onChange={(e: any) => action.setPesquisa({ ...pesquisa, status: e.value })}
                                        value={{ value: pesquisa.status }}
                                    />
                                </Col>
                                <Col md="4" className="padding-padrao-col" >
                                    <Row className="padding-padrao-row" >
                                        <Col md="6" className="padding-padrao-col" >
                                            <label htmlFor="">Data Início</label>
                                            <Input
                                                type={'date'}
                                                autoComplete="off"
                                                onChange={(e) =>
                                                    action.setPesquisa({ ...pesquisa, dateOrderInitial: e.target.value })}
                                                value={pesquisa.dateOrderInitial}
                                            />
                                        </Col>
                                        <Col md="6" className="padding-padrao-col" >
                                            <label htmlFor="">Data Final</label>
                                            <Input
                                                type="date"
                                                autoComplete="off"
                                                onChange={(e) =>
                                                    action.setPesquisa({ ...pesquisa, dateOrderFinally: e.target.value })}
                                                value={pesquisa.dateOrderFinally}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="4" className="padding-padrao-row" >
                                    <Row className="padding-padrao-row mt-3"  style={{flexWrap: 'unset'}}  >
                                        <Col md="4" className="padding-padrao-col" >
                                            <Button color='green' style={{ width: '100%' }} >Sair</Button>
                                        </Col>
                                        <Col md="4" className="padding-padrao-col">
                                            <Button color='info' onClick={() => handleSearch()} style={{ width: '100%' }} >Pesquisar</Button>
                                        </Col>
                                        <Col md="4" className="padding-padrao-col">
                                            <Button color='info' onClick={action.cleanPesquisa} style={{ width: '100%' }} >Limpar</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {pedidos.length > 0 && (
                                <>
                                    <Row id="orderTable-container" style={{ margin: 0 }}>
                                        <fieldset className='fieldset-style' style={{ width: '100%' }} >
                                            <legend>Pedidos</legend>
                                            <div>
                                                <ReactTable
                                                    columns={
                                                        [
                                                            {
                                                                Header: 'Status',
                                                                accessor: 'status',
                                                                Cell: props => (
                                                                    <div 
                                                                        className={'status-radius'}
                                                                        style={{
                                                                            backgroundColor: props.value === 'ABERTO' && 'green' ||
                                                                                             props.value === 'NOTA_FISCAL_PENDENTE' && 'rgb(29, 140, 248)' ||
                                                                                             props.value === 'FATURAMENTO_PENDENTE' && 'rgb(16 189 165)' ||
                                                                                             props.value === 'FINALIZADO' && 'rgb(247 106 5)' || ''
                                                                        }} 
                                                                />
                                                                )
                                                            },
                                                            {
                                                                Header: 'Pedido',
                                                                accessor: 'numeroPedido',
                                                            },
                                                            {
                                                                Header: 'Data',
                                                                accessor: 'dataPedido',
                                                            },
                                                            {
                                                                Header: 'Doc. Fiscal',
                                                                accessor: 'docFiscal',
                                                            },
                                                            {
                                                                Header: 'Empresa',
                                                                accessor: 'nomeEmpresa',
                                                            },
                                                            {
                                                                Header: 'Cliente',
                                                                accessor: 'nomeDestinatario',
                                                            },
                                                            {
                                                                Header: 'Funcionário',
                                                                accessor: 'nomeFuncionario',
                                                            },
                                                            {
                                                                Header: 'Caixa',
                                                                accessor: 'caixa',
                                                            },
                                                            {
                                                                Header: 'Operação',
                                                                accessor: 'operacaoDescricao',
                                                            },
                                                            {
                                                                Header: 'Valor',
                                                                accessor: 'valorSubTotal',
                                                                Cell: props => maskMoeda(Number(props.value).toFixed(2)),
                                                            },
                                                            {
                                                                Header: 'Desconto',
                                                                accessor: 'valorDesconto',
                                                                Cell: props => maskMoeda(Number(props.value).toFixed(2)),
                                                            },
                                                            {
                                                                Header: 'Total',
                                                                accessor: 'valorTotal',
                                                                Cell: props => maskMoeda(Number(props.value).toFixed(2)),
                                                            },
                                                            {
                                                                Header: "",
                                                                accessor: "numeroPedido",
                                                                Cell: props => (
                                                                    //@ts-ignore
                                                                    <Icon className="details-orderIcon" icon="calendar" color="white" onClick={() => handleToggleModal(props.value, props.original.status)} />
                                                                )
                                                            }

                                                        ]
                                                    }
                                                    getTrProps={getTrProps}
                                                    data={pedidos}
                                                    minRows={0}
                                                    showPaginationTop={false}
                                                    showPaginationBottom={true}
                                                    className="-striped -highlight"
                                                    filterable={false}
                                                    pageSize={Number(pesquisa.pageSize)}
                                                    NoDataComponent={() => (<></>)}
                                                    PaginationComponent={() => (
                                                        <Pagination  
                                                            nextPage={() => pesquisa.page >= pagination.totalPage ? pesquisa.page : (action.setPesquisa({...pesquisa, page: Number(pesquisa.page) + 1 }), setPageSelect(true) ) }
                                                            previousPage={() => pesquisa.page <= 1 ? pesquisa.page : (action.setPesquisa({...pesquisa, page: Number(pesquisa.page) - 1 }), setPageSelect(true)) }
                                                            page={Number(pesquisa.page)}
                                                            totalPage={pagination.totalPage}
                                                            pageSize={Number(pesquisa.pageSize)}
                                                            //@ts-ignore
                                                            pageSizeSelect={(e: SyntheticEvent) => (action.setPesquisa({...pesquisa, pageSize: e.target.value, page: 1 }), setPageSelect(true)) }
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </fieldset>
                                    </Row>
                                    <Row id="orderTable-infos" >
                                        <div className="orderTable-legends" >
                                            <div className="orderTable-legendsItems">
                                                <Icon icon="circle" size="2x" color="green" />
                                                <span style={{ color: 'green' }} >Aberto</span>
                                            </div>
                                            <div className="orderTable-legendsItems">
                                                <Icon icon="circle" size="2x" color="rgb(247 106 5)" />
                                                <span style={{ color: 'rgb(247 106 5)' }} >Finalizado</span>
                                            </div>
                                            <div className="orderTable-legendsItems">
                                                <Icon icon="circle" size="2x" color="rgb(16 189 165)" />
                                                <span style={{ color: 'rgb(16 189 165)' }} >Pendente de Faturamento</span>
                                            </div>
                                            <div className="orderTable-legendsItems">
                                                <Icon icon="circle" size="2x" color="rgb(29 140 248)" />
                                                <span style={{ color: 'rgb(29 140 248)' }}>Pendente de emissão NF-e</span>
                                            </div>
                                        </div>
                                        <div className="orderTable-values" >
                                            <Col className="padding-padrao-col">
                                                <label htmlFor="">Valor</label>
                                                <Input type="text" value={maskMoeda(totaisPesquisa.valor)} disabled />
                                            </Col>
                                            <Col className="padding-padrao-col">
                                                <label htmlFor="">Desconto</label>
                                                <Input type="text" value={maskMoeda(totaisPesquisa.desconto)} disabled />
                                            </Col>
                                            <Col className="padding-padrao-col">
                                                <label htmlFor="">Total</label>
                                                <Input type="text" style={{ color: 'green' }} value={maskMoeda(Number(totaisPesquisa.total).toFixed(2))} disabled />
                                            </Col>
                                        </div>
                                    </Row>
                                </>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </div>
            { modalInfoPedidos && <ModalInfoPedidos numPedidoSelect={numPedidoSelect} statusPedidoSelect={statusPedidoSelect} isOpen={true} toggle={toggleModalInfoPedidos} />}
        </>
    )
}

export default PesquisaPDV;