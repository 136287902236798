import React from 'react'
import { Button, Col, Input, Row,  } from 'reactstrap'
import useResize from '../../Util/Hooks/useResize'

import './styles.css'

interface IPaginationProps {
    nextPage: Function;
    previousPage: Function;
    page: number;
    totalPage: number;
    pageSizeSelect: Function;
    pageSize: number;
}

const Pagination: React.FC<IPaginationProps> = ({ nextPage, previousPage, page, totalPage, pageSizeSelect, pageSize }) => {
    /**
     * @param nextPage: Function | Este parametro pode receber uma função, ao disparar muda para a proxima página fazendo uma nova requisição;
     * @param previousPage: Function | Este parametro pode receber uma função, ao disparar muda para a página anterior fazendo uma nova requisição;
     * @param page: number | Recebe a página atual;
     * @param totalPage: number | Recebe o total de páginas que o endpoint trás;
     * @param pageSizeSelect: Function | Recebe uma função que ao mudar a opção do select passa uma nova quantidade de registros que deve ser buscada pelo endpoint;
     * @param pageSize: number | Quantidade de registros na página;
     */

    const { width } = useResize()

    return(
        <Row id="paginationComponent" className="padding-padrao-row" >
            <Col md="4" className="padding-padrao-col" style={width < 768 ? {display: 'flex', alignItems: 'center', justifyContent: 'center' } : {}} >
                <Button onClick={() => previousPage()} className={page <= 1 ? 'button-disabled' : '' }  >
                    Anterior
                </Button>
            </Col>
            <Col md="4" className="padding-padrao-col" >
                <Row className="padding-padrao-row amount-page">
                    <Col className="padding-padrao-col" >
                        <Input value={Number(page)} />
                        <span>de</span>
                        <span>{totalPage}</span>
                    </Col>
                    <Col className="padding-padrao-col" >
                        <select 
                            onChange={(e) => pageSizeSelect(e)}
                            value={pageSize}
                            className="select-pageSize"  
                        >
                            <option value="5">5 Linhas</option>
                            <option value="10">10 Linhas</option>
                            <option value="20">20 Linhas</option>
                            <option value="25">25 Linhas</option>
                            <option value="50">50 Linhas</option>
                            <option value="100">100 Linhas</option>
                        </select>
                    </Col>
                </Row>
            </Col>
            <Col 
                md="4"
                className="padding-padrao-col"
                style={width < 768 ? {display: 'flex', alignItems: 'center', justifyContent: 'center' } : { display: 'flex', justifyContent: 'flex-end' }}
            >
                <Button onClick={() => nextPage()} className={page === totalPage ? 'button-disabled' : '' } >
                    Proximo
                </Button>
            </Col>
        </Row>
    )
}

export default Pagination