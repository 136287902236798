import React, { FormEvent, useEffect } from "react";

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  FormGroup,
} from "reactstrap";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import useModal from "../../../../../Util/Hooks/useModal";

import ModalDestinatario from '../../Modals/ModalDestinatario';

import * as controllerSaida from '../../../../../controller/Movimentacao/controllerSaidaDiversa'

import useShallowEqualSelector from "../../../../../Util/Hooks/useShallowEqualSelector";
import AppState from "../../../../../store/storeType";
import { Destinatario } from "../../../../../store/reducers/Movimentacao/SaidaDiversa/types";
import { InputCpfCnpj } from '../../Components/index'
import { setDadosDestinatario, setDestinatariosList } from "../../../../../store/reducers/Movimentacao/SaidaDiversa/actions";

const DadosDestinatario = () => {
  const [modalDestinatario, toggleDestinatario] = useModal(false)

  const destinatario = useShallowEqualSelector<AppState, Destinatario>(state => state.saidaDiversa.saida.destinatario)
  const invalido = useShallowEqualSelector<AppState, Boolean>(state => state.saidaDiversa.invalido.dadosIniciais.destinatario.documento)


  useEffect(() => {

  }, [])

  function handleOnChange(value: string, campo: string) {
    setDadosDestinatario({ ...destinatario, [campo]: value })
  }

  function pesquisaDestinatarios({ documento, nomeRazao, tipo }: any) {
    controllerSaida.getDestinatarios(documento, nomeRazao, tipo);
  }

  function confirmarDestinatario(destinatario: any) {
    controllerSaida.getDestinatario(destinatario, toggleDestinatario);
  }

  function cleanDestinatario() {
    setDestinatariosList([])
  }

  async function handleKeyPress(event: any) {
    if (event.key === 'Enter') {
      toggleDestinatario();
    }
  }

  return (
    <CardBody>
      <Row className="justify-content-center">
        <fieldset style={{ border: 'solid 2px #5E6680', borderRadius: 5, width: '100%' }}>
          <legend style={{ fontSize: '14px', width: 'auto', margin: '0 15px 0 15px', padding: '0 15px 0 15px' }} >
            DADOS DO DESTINATÁRIO
          </legend>

          <Col lg="12" md="12" >

            <Row className="padding-padrao-row">

              <Col className="padding-padrao-col" lg="3" md="4">
                <label>CPF / CNPJ <span className="error">*</span></label>
                <FormGroup className={invalido ? "has-danger" : ''} >
                  <InputCpfCnpj
                    type="text"
                    tipo={destinatario.documento.length <= 14 ? 'cpf' : 'cnpj'}
                    value={destinatario.documento}
                    onChange={(event: FormEvent<HTMLInputElement>) => handleOnChange(event.currentTarget.value, 'documento')}
                    autoComplete="off"
                    maxLength="18"
                    onKeyPress={(event: FormEvent<HTMLInputElement>) => handleKeyPress(event)}
                  />
                  <br></br>
                </FormGroup>
              </Col>

              <Button color="info" onClick={() => toggleDestinatario()} style={{ width: 40, height: 40, marginTop: 25 }}>
                <Icon icon="search" className="fa-lg" />
              </Button>

              <Col  className="padding-padrao-col" lg md sm={12}>
                <label>Nome / Razão Social <span className="error">*</span></label>
                <FormGroup >
                  <Input
                    type="text"
                    value={destinatario.razaoSocial}
                    onChange={e => handleOnChange(e.target.value, 'razaoSocial')}
                    autoComplete="off"
                    disabled={true}
                  />
                  <br></br>
                </FormGroup>
              </Col>

            </Row>

          </Col>
        </fieldset>

      </Row>

      {
        modalDestinatario ?
          <ModalDestinatario
            isOpen={true}
            toggle={toggleDestinatario}
            getDestinatario={pesquisaDestinatarios}
            confirmModal={confirmarDestinatario}
            clean={cleanDestinatario}
          />
          :
          null
      }

    </CardBody>
  )
}

export default DadosDestinatario;