import React from 'react'

import { Col, Row, Button } from 'reactstrap'
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from '../../../../components/ModalBasic/ModalBasic'

import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector'
import * as controller from '../../../../controller/controllerProdutos'
import * as actions from '../../../../store/reducers/Cadastro/Produto/actions'
import AppState from '../../../../store/storeType'
import { Produto } from '../../../../store/reducers/Cadastro/Produto/types'

interface ModalInativarProps {
    isOpen: boolean
    toggle: () => void
    codProduto: number | string
    status: number
}

const ModalInativar: React.FC<ModalInativarProps> = ({ isOpen, toggle, codProduto, status }) => {
    //@ts-ignore
    const { codEmpresa } = useShallowEqualSelector(state => state.login.usuario.empresa)
    const produto = useShallowEqualSelector<AppState, Produto>(state => state.produtos.produto)

    function inativar() {
        if (codProduto !== '') {
            controller.Inativar(codEmpresa, { codProduto, status });
        }
        actions.setProduto({ ...produto, status: status === 1 ? 0 : 1 });
    }

    return (
        <ModalBasic
            isOpen={isOpen}
            toggle={() => toggle()}
            title={status === 0 ? "Inativar" : "Ativar" + " produto"}
        >
            <ModalBasicBody>

                <Col className="text-center">
                    {status === 0 ?
                        <label>Tem certeza que deseja <b>inativar</b> esse produto da sua empresa?</label>
                        : <label>Tem certeza que deseja <b>ativar</b> esse produto em sua empresa?</label>
                    }
                </Col>

            </ModalBasicBody>
            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <Button
                            color="danger"
                            type="button"
                            onClick={() => { toggle() }}
                            style={{ width: '150px' }}
                        >NÃO
                            </Button>
                    </Col>
                    <Col className="text-center">
                        <Button
                            color="success"
                            type="button"
                            onClick={() => {
                                toggle()
                                inativar()
                            }}
                            style={{ width: '150px' }}
                        >SIM
                            </Button>
                    </Col>
                </Row>
            </ModalBasicFooter>

        </ModalBasic >
    )
}
export default ModalInativar