export enum ProdutoStore {
    ID_STORE = 'PRODUTOS'
}

export enum ProdutoTypes {
    PESQUISA = 'PESQUISA',
    PRODUTO = 'PRODUTO',
    PRODUTO_COPIA = 'PRODUTO_COPIA',
    IMAGEM = 'IMAGEM',
    PRODUTOS = 'PRODUTOS',
    GRUPOS = 'GRUPOS',
    SUBGRUPOS_PESQ = 'SUBGRUPOS_PESQ',
    SUBGRUPOS_CAD = 'SUBGRUPOS_CAD',
    FORNECEDORES = 'FORNECEDORES',
    FORNECEDORES_PRODUTO = 'FORNECEDORES_PRODUTO',
    IMAGENS = 'IMAGENS',
    TIPOS_GRADE = 'TIPOS_GRADE',
    SEGUIMENTOS = 'SEGUIMENTOS',
    CESTS = 'CESTS',
    NCMS = 'NCMS',
    SUGESTOES_CESTS = 'SUGESTOES_CESTS',
    SUGESTOES_NCMS = 'SUGESTOES_NCMS',
    CLASSIFICACOES = 'CLASSIFICACOES',
    INVALIDO = 'INVALIDO',
    INICIO_CADASTRO = 'INICIO_CADASTRO',
    PROXIMO_CADASTRO = 'PROXIMO_CADASTRO',
    CLEAN = 'CLEAN',
    VER_CADASTRO = 'VER_CADASTRO',
    ORIGENSPRODUTO = 'ORIGENSPRODUTO'
}

export type ProdutoAction = {
    idStore: ProdutoStore.ID_STORE
    type: ProdutoTypes
    pesquisa?: Pesquisa
    produto?: Produto
    imagem?: Imagem
    produtos?: Produtos[]
    grupos?: Grupo[]
    pesqsubgrupos?: Subgrupo[]
    cadsubgrupos?: Subgrupo[]
    fornecedores?: Fornecedor[]
    fornecedoresProd?: Fornecedor[]
    imagens?: Imagem[]
    tiposGrade?: TipoGrade[]
    seguimentos?: Seguimento[]
    cests?: CEST[]
    cestsSugestoes?: CEST[]
    ncms?: NCM[]
    ncmsSugestoes?: NCM[]
    classificacoes?: Classificacao[]
    inicioCadastro?: boolean
    proximoCadastro?: boolean
    verCadastro?: boolean
    invalido?: Invalido
    newState?: ProdutoState
    origensProduto?: ProdutoState['origensProduto']
}

export interface ProdutoState {
    inicioCadastro: boolean
    proximoCadastro: boolean
    verCadastro: boolean

    produtos: Produtos[]
    grupos: Grupo[]
    pesqsubgrupos: Subgrupo[]
    cadsubgrupos: Subgrupo[]
    fornecedores: Fornecedor[]
    fornecedoresProd: Fornecedor[]
    imagens: Imagem[]
    tiposGrade: TipoGrade[]
    seguimentos: Seguimento[]
    cests: CEST[]
    cestsSugestoes: CEST[]
    ncms: NCM[]
    ncmsSugestoes: NCM[]
    classificacoes: Classificacao[]

    origensProduto: Array<OrigemProduto>,

    invalido: Invalido

    pesquisa: Pesquisa

    produto: Produto

    produtoCopia: Produto

    imagem: Imagem
}

export interface Produto {
    codProduto: number | string
    status: number

    basico: {
        codGrupo: number | string
        grupo: string
        codSubgrupo: number | string
        subgrupo: string
        codBarras: string
        numeroProduto: number | string
        nome: string
        descricao: string

        especificacoes: {
            unidade: number
            largura: number | string
            altura: number | string
            profundidade: number | string
            peso: number | string
            voltagem: number | string
            garantiaFor: number
            codFornecedor: number[] | string[]
            fornecedor: string
            precoCusto: number | string
            precoVenda: number | string
            markup: number | string
            codOrigem: number | string
        }

        comissao: {
            flControlaComissao: number | string
            percentualComissao: number | string
        }

        estoque: {
            flControlaEstoque: number | string
            qtdMinima: number | string
            qtdAtual: number | string
        }

        caracteristicas: {
            flControlaNumSerie: number | string
            flControlaGrade: number | string
            flTributado: number | string
            flItemVenda: number | string
        }
    }
    grades: {
        codTipoGrade: number | string
        grades: Grade[]
    }
    gradesSelected: Grade[]
    dadosTributarios: {
        codSeguimento: number | string
        cest: string
        ncm: string
        descricao: string
        codClassificacao: number | string
        aliquotaMVA: number | string
    }
}

export interface Pesquisa {
    numeroProduto: string
    codGrupo: string
    codSubgrupo: string
    nome: string
    ativos: number
    dtCadastro: string
    ncm: string
    cest: string
    nomeRazao: string
    fornecedores: any[]
    codForne: string
    flags: string[]
    flControlaEstoque: string
    flControlaComissao: string
    flControlaNumSerie: string
    flControlaGrade: string
    flTributado: string
    flItemVenda: string
}

export interface Produtos {
    codProduto: number | string
    numProduto: number | string
    codBarras: string
    nome: string
    codGrupo: number | string
    grupo: string
    codSubgrupo: number | string
    subgrupo: string
    dtCadastro: string
    status: number
}

export interface Grupo {
    codGrupo: string
    nome: string
}

export interface Subgrupo {
    codSubgrupo: string
    nome: string
}

export interface Fornecedor {
    codFor: number | string
    nomeRazao: string
    apelidoFantasia: string
}

export interface Imagem {
    codImagem: number | string
    descricao: string
    imagem: string
    flPrincipal: number
}

export interface TipoGrade {
    codigo: number | string
    descricao: string
}

export interface Grade {
    codGrade: number | string
    codTipoGrade: number | string
    descricao: string
    sigla: string
    descricaoTipo: string
    status: number
}

export interface Seguimento {
    codigo: number
    descricao: string
}

export interface CEST {
    cest: string
    descricao: string
    principal: boolean
}

export interface NCM {
    ncm: string
    descricao: string
    principal: boolean
}

export interface Classificacao {
    codClassificacao: number
    codTributacao: number
    cfopNfe: string
    cfopCf: string
    uf: string
    descricao: string
    status: number
}

export interface Invalido {
    codBarras: boolean,
    nome: boolean,
    descricao: boolean,
    descricaoImagem: false,
}

interface OrigemProduto {
    codOrigem: string,
    descricao: string
}