import store from "../../store/stores"
import api from '../../Util/api';
import axios from 'axios';
import { alertar } from "../../Util/alertar";
import { Pesquisa, Produto, Transportadora, ProdutoCopia, Empresas } from "../../store/reducers/Movimentacao/SaidaDiversa/types";
import { Item } from "../../store/reducers/Movimentacao/PedidoVenda/types";
import { ToNumber, Sum, Round } from "../../Util/numbers";
import * as actions from "../../store/reducers/Movimentacao/SaidaDiversa/actions";
import * as actionFaturamento from '../../store/reducers/Modals/Faturamento/actions'
import notificacao from "../../store/reducers/notificacaoStore";
import { maskMoeda } from "../../Util/mascaras";

const idStore = "SAIDA_DIVERSA";

export function handleNovo() {
    actions.cleanCadastro();
    actions.addProduto([]);
    actions.setModalOperacao(true);
    store.dispatch({ idStore, type: 'WIZARD_STEP', value: 0 })
}

export function handlePesquisar(pesquisa: Pesquisa) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        if (pesquisa.destinatario.codigo === 0) {
            return pesquisa.destinatario.codigo = null
        } else {
            axios.get(api.urlMovimento + `api/v1/Movimento?DataInicio=${pesquisa.dataInicio}&DataFim=${pesquisa.dataFim}&CodigoDestinatario=${pesquisa.destinatario.codigo}&NomeDestinatario=${pesquisa.destinatario.nomeRazao}&TipoDestinatario=${pesquisa.destinatario.tipo}&TipoOperacao=SAI&Tamanho=100&Pagina=1`, {
                headers: { Authorization: bearerAuth }
            }).then(response => {
                const { data } = response.data
                store.dispatch({ idStore, type: 'LIST_SAIDAS', value: data.responsePedidos })
            }).catch(e => {
                if (e.response) {
                    alertar(notificacao, 'br', e.response.data.message, 'warning', 'icon-alert-circle-exc', 2, e)
                } else {
                    alertar(notificacao, 'br', 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
                }
                if (e.request.status === 404)
                    store.dispatch({ idStore, type: 'LIST_SAIDAS', value: [] })
            });
        }
    } catch (error) {
        alertar(notificacao, 'br', "Não foi possível obter as operações", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function handleExcluir(numeroSaida: number, thenFunction: Function) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

        axios.delete(api.urlMovimento + `api/v1/Movimento/${numeroSaida}`, {
            headers: { Authorization: bearerAuth }
        }).then(response => {
            alertar(notificacao, 'br', 'Saída excluída com sucesso', 'success', 'icon-check-2', 4)
            thenFunction()

            const { pesquisa } = store.getState().saidaDiversa
            handlePesquisar(pesquisa)

        }).catch(e => {
            thenFunction()
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 4, e)
        });
    } catch (error) {
        thenFunction()
        alertar(notificacao, 'br', "Não foi possível excluir Sáida Diversa", 'warning', 'icon-alert-circle-exc', 4)
    }
}

export function handleEditar(numeroSaida: number) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

        axios.get(api.urlMovimento + `api/v1/Movimento/${numeroSaida}`, {
            headers: { Authorization: bearerAuth }
        }).then(response => {
            const { data } = response.data;

            updateDados(data)

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 4, e)
        });
    } catch (error) {
        alertar(notificacao, 'br', "Não foi possível editar Saída Diversa", 'warning', 'icon-alert-circle-exc', 4)
    }
}

export function handleModalOperacao(isVisible: boolean) {
    store.dispatch({ idStore, type: 'VISIBLE_MODAL_OPERACAO', value: isVisible })
}

export function pesquisaOperacao(numeroOperacao: number, descricaoOperacao: string) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        store.dispatch({ idStore, type: 'OPERACOES', value: [] })

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        let numOperacao = numeroOperacao === 0 ? '' : numeroOperacao

        axios.get(api.urlCadastro + `api/v1/operacao/operacoes?Numero=${numOperacao}&Descricao=${descricaoOperacao}&Tipo=S&TipoOperacao=SAI`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma Operação Encontrada", 'info', 'icon-bulb-63', 2)
            }

            store.dispatch({ idStore, type: 'OPERACOES', value: response.data.records })
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });

    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as operações", 'warning', 'icon-alert-circle-exc', 2)
    }
}


export async function getTransportadoras({ documento, razaoSocial }: Transportadora) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        actions.setTransportadoraList([])

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        const codTransportadora = await axios.get(api.urlCadastro + `api/v1/transportadora/transportadoras?cpfCnpj=${documento}&nomeRazao=${razaoSocial}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                alertar(notificacao, 'br', "Nenhuma Transportadora Encontrada", 'info', 'icon-bulb-63', 2)
                return false
            }

            actions.setTransportadoraList(response.data.records)

            return response.data.records[0].codTransportadora

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
            return false
        });

        return codTransportadora;
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter as transportadoras", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function getTransportadora(codigoTransportadora: number, thenFunction: Function) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        actions.setTransportadoraList([])

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/transportadora/transportadora?codTransportadora=${codigoTransportadora}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (Object.entries(response.data.records).length === 0)
                return alertar(notificacao, 'br', "Transportadora não encontrada", 'info', 'icon-bulb-63', 2)

            const transportadora = store.getState().saidaDiversa.saida.transportadora

            actions.setDadosTransportadora({ ...transportadora, codTransportadora: response.data.records.codTransportadora, razaoSocial: response.data.records.nomeRazao, documento: response.data.records.cpfCnpj })
            actions.setTransportadoraList([])

            thenFunction()
        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });

    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter a transportadora", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function getDestinatarios(documento: string, nomeRazao: string, tipo: string) {
    //@ts-ignore
    const { notificacao } = store.getState()
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        return axios.get(api.urlCadastro + `api/v1/destinario/destinarios?tipo=${tipo}&cpfCnpj=${documento}&nome=${nomeRazao}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0) {
                actions.setDestinatariosList([])
                return
            }

            actions.setDestinatariosList(response.data.records)

            return response.data.records[0].codigo

        }).catch(e => {
            alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        });

    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível obter os clientes", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function getDestinatario(destinario: any, thenFunction: Function) {

    const objDestinatario = {
        codigo: destinario.codigo,
        razaoSocial: destinario.nomeRazao,
        documento: destinario.documento,
        tipoDestinatario: destinario.tipo
    }

    actions.setDadosDestinatario(objDestinatario);
    actions.setDestinatariosList([]);

    thenFunction();

}

export async function getEmpresasUsuario() {
    try {
        //@ts-ignore
        const { notificacao, saidaDiversa: { pesquisa } } = store.getState()
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        let arrEmpresas: any = []

        return await axios.get(`${api.urlCadastro}api/v1/empresa/empresasUsuario`, {
            headers: { Authorization: basicAuth }
        }).then((response) => {
            if (response.data) {

                response.data.records.forEach((element: any) => {
                    element.codEmpresaFilial = element.codEmpresaFilial.toString()
                    arrEmpresas.push(element)
                });

                actions.setEmpresas(arrEmpresas)
                return
            }
        }).catch(e => {
            if (e.response) {
                alertar(notificacao, 'br', e.response.data.message, 'warning', 'icon-alert-circle-exc', 2)
            } else {
                alertar(notificacao, 'br', "Não foi possível obter as filiais", 'warning', 'icon-alert-circle-exc', 2)
            }
        })

    } catch (error) {
        alertar(notificacao, 'br', "Não foi possível obter as filiais", 'warning', 'icon-alert-circle-exc', 2)
    }
}

type Telas = 'Dados Iniciais' | 'NFE Referencia' | 'Produtos' | 'Resumo / Envio'

export async function Salvar(tela: Telas) {
    //@ts-ignore
    const { notificacao, saidaDiversa: { saida: { cabecaSaida, cabecaSaidaCopia, destinatario, destinatarioCopia, nfeReferencia, produtos, produtosCopia, transportadora, transportadoraCopia, idSaida, dadosAdicionais, dadosAdicionaisCopia }, validaNFeReferencia } } = store.getState();
    try {
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        let funcionario = await axios.get(api.urlCadastro + `api/v1/funcionario/funcionario`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            return response.data.records
        }).catch(error => {
            alertar(notificacao, 'br', "Usuário não possui funcionário vinculado", 'warning', 'icon-alert-circle-exc', 5)
        })

        if (!funcionario)
            return

        if (!cabecaSaida.numeroOperacao) {
            store.dispatch({ idStore, type: 'WIZARD_STEP', value: 0 })
            return alertar(notificacao, 'br', "Operação não informada", 'warning', 'icon-alert-circle-exc', 5);
        }

        if (!cabecaSaida.presenca) {
            store.dispatch({ idStore, type: 'WIZARD_STEP', value: 0 })
            return alertar(notificacao, 'br', "Indicador de Presença não informado", 'warning', 'icon-alert-circle-exc', 5);
        }

        if (!destinatario.codigo) {
            store.dispatch({ idStore, type: 'WIZARD_STEP', value: 0 })
            return alertar(notificacao, 'br', "Destinatário não informado", 'warning', 'icon-alert-circle-exc', 5);
        }

        if (transportadora.modalidade !== 9) {
            if (transportadora.codTransportadora === '') {
                store.dispatch({ idStore, type: 'WIZARD_STEP', value: 0 })
                return alertar(notificacao, 'br', "Informe a Transportadora", 'warning', 'icon-alert-circle-exc', 5);
            }
        }

        if (validaNFeReferencia) {
            if (!nfeReferencia.numero) {
                store.dispatch({ idStore, type: 'WIZARD_STEP', value: 1 })
                return alertar(notificacao, 'br', "Número da Nota de Refêrencia Inválido", 'warning', 'icon-alert-circle-exc', 5);
            }

            if (!nfeReferencia.modelo) {
                store.dispatch({ idStore, type: 'WIZARD_STEP', value: 1 })
                return alertar(notificacao, 'br', "Modelo da Nota de Refêrencia Inválido", 'warning', 'icon-alert-circle-exc', 5);
            }

            if (!nfeReferencia.chave) {
                store.dispatch({ idStore, type: 'WIZARD_STEP', value: 1 })
                return alertar(notificacao, 'br', "Chave da Nota de Refêrencia Inválido", 'warning', 'icon-alert-circle-exc', 5);
            }

            if (!nfeReferencia.cnpjEmissor) {
                store.dispatch({ idStore, type: 'WIZARD_STEP', value: 1 })
                return alertar(notificacao, 'br', "CNPJ do Emissor da Nota de Refêrencia Inválido", 'warning', 'icon-alert-circle-exc', 5);
            }

            if (!nfeReferencia.anoMes) {
                store.dispatch({ idStore, type: 'WIZARD_STEP', value: 1 })
                return alertar(notificacao, 'br', "Mês/Ano da Nota de Refêrencia Inválido", 'warning', 'icon-alert-circle-exc', 5);
            }

            if (!nfeReferencia.uf) {
                store.dispatch({ idStore, type: 'WIZARD_STEP', value: 1 })
                return alertar(notificacao, 'br', "UF da Nota de Refêrencia Inválido", 'warning', 'icon-alert-circle-exc', 5);
            }
        }

        if (produtos.length === 0) {
            store.dispatch({ idStore, type: 'WIZARD_STEP', value: validaNFeReferencia ? 2 : 1 })
            return alertar(notificacao, 'br', "Nenhum Produto informado", 'warning', 'icon-alert-circle-exc', 5);
        }

        if ((JSON.stringify(cabecaSaida) === JSON.stringify(cabecaSaidaCopia))
            && (JSON.stringify(destinatario) === JSON.stringify(destinatarioCopia))
            && (JSON.stringify(produtos) === JSON.stringify(produtosCopia))
            && (JSON.stringify(transportadora) === JSON.stringify(transportadoraCopia))
            && (dadosAdicionais === dadosAdicionaisCopia)
        ) {
            alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2)
            return
        }

        let listProdutos: any[] = []

        let produtosString = JSON.stringify(produtos);
        let produtosJSON: Produto[] = JSON.parse(produtosString)

        let total = 0;

        produtosJSON.forEach(produto => {
            let qtd = produto.quantidade === '' ? 0 : ToNumber(produto.quantidade)
            let valor = produto.valorUnitario === '' ? 0 : ToNumber(Round(produto.valorUnitario, 2))
            let valorTotalItem = valor * qtd
            let gradeList: any[] = []

            if (produto.grades) {
                produto.grades.forEach((element: any) => {
                    if (ToNumber(element.qtdAtualizar) > 0) {
                        gradeList.push({
                            codigoGrade: ToNumber(element.codGrade),
                            quantidade: ToNumber(element.qtdAtualizar)
                        })
                    }
                })
            }

            listProdutos.push({
                numeroPedidoItem: produto.numeroPedidoItem,
                codigoProduto: ToNumber(produto.codProduto),
                percentualDesconto: 0.00,
                quantidade: qtd,
                valorDescontoItem: 0.00,
                valorItem: valor.toFixed(2),
                valorSubTotalItem: valorTotalItem.toFixed(2),
                valorTotalItem: valorTotalItem.toFixed(2),
                dataLancamento: produto.dataLancamento,
                status: produto.status,
                grade: gradeList
            });
            total = Sum([total, valorTotalItem])
        })

        let objTransportadora = {
            codigoTransportadora: transportadora.codTransportadora,
            placa: transportadora.placaVeiculo,
            placaUf: transportadora.uf,
            valorFrete: transportadora.valorFrete,
            quantidade: transportadora.quantidadeVolume,
            volume: transportadora.numeroVolume,
            pesoLiquido: transportadora.pesoLiquido,
            pesoBruto: transportadora.pesoBruto,
            especie: transportadora.especie,
            marca: transportadora.marca
        }

        let valorDesconto = 0

        let objPedido = {
            numeroPedido: idSaida,
            codigoDestinatario: ToNumber(destinatario.codigo),
            tipoDestinatario: destinatario.tipoDestinatario,
            codigoFuncionario: ToNumber(funcionario.codFunc),
            codigoOperacao: ToNumber(cabecaSaida.codigoOperacao),
            valorSubTotal: total.toFixed(2),
            valorTotal: Number(total).toFixed(2),
            tipoRecebimento: 'R',
            tipoMovimentacao: 1,
            presenca: cabecaSaida.presenca,
            modalidadeFrete: transportadora.modalidade,
            valorDesconto: valorDesconto.toFixed(2),
            transportadora: transportadora.modalidade !== 9 ? objTransportadora : null,
            itens: listProdutos,
            informacoesComplementares: dadosAdicionais
        }

        if (idSaida === 0) {
            return axios.post(api.urlMovimento + `api/v1/Movimento`, objPedido, {
                headers: { Authorization: bearerAuth }
            }).then(response => {
                const { data } = response.data

                updateDados(data)
                alertar(notificacao, 'br', 'Saída salva com sucesso', 'success', 'icon-check-2', 2)

                return true

            }).catch(e => {
                if (e.response) {
                    return alertar(notificacao, 'br', e.response.data.message, 'warning', 'icon-alert-circle-exc', 2, e)
                }
                return alertar(notificacao, 'br', "Não foi possível salvar a saída", 'warning', 'icon-alert-circle-exc', 2, e)

            });
        } else {
            return axios.put(api.urlMovimento + `api/v1/Movimento/${idSaida}`, objPedido, {
                headers: { Authorization: bearerAuth }
            }).then(response => {
                const { data } = response.data

                updateDados(data)
                alertar(notificacao, 'br', 'Saída atualizada com sucesso', 'success', 'icon-check-2', 2)

                return true
            }).catch(e => {
                alertar(notificacao, 'br', 'Não foi possível atualizada a Saída', 'warning', 'icon-alert-circle-exc', 2, e)
            });
        }
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível salvar a Saída", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function Finalizar(toogle: Function) {
    //@ts-ignore
    const { notificacao, saidaDiversa: { saida }, modalFaturamento: { totais } } = store.getState()

    try {

        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

        if (saida.idSaida === 0) {
            actions.setPopUp(true)
            return
        }

        if (saida.produtos.length === 0) {
            alertar(notificacao, 'br', "Nenhum produto informado", 'warning', 'icon-alert-circle-exc', 2)
            return
        }

        let funcionario = await axios.get(api.urlCadastro + `api/v1/funcionario/funcionario`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            return response.data.records
        }).catch(error => {
            alertar(notificacao, 'br', "Usuário não possui funcionário vinculado", 'warning', 'icon-alert-circle-exc', 5)
        })

        if (!funcionario) {
            return
        }

        if (saida.cabecaSaida.gerarConta === 1) {
            actionFaturamento.setTotais({ ...totais, subTotal: maskMoeda(saida.valorTotal), descontos: saida.valorDesconto, total: saida.valorTotal })

            actionFaturamento.setInformacoes({
                numeroPedido: ToNumber(saida.idSaida),
                codigoCliente: ToNumber(saida.destinatario.codigo),
                codigoConta: 0,
                codigoGrupoConta: 0,
                codigoSubGrupoConta: 0,
                codigoFuncionario: ToNumber(funcionario.codFunc)
            })

            toogle()
            return
        } else {
            return axios.put(`${api.urlMovimento}api/v1/Movimento/finalizarSaidaDiversas/${saida.idSaida}`, {}, {
                headers: { Authorization: bearerAuth }
            }).then(response => {
                alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
            }).catch(e => {
                if (e.response) {
                    return alertar(notificacao, 'br', !e.response.data.message ? "Saída faturada com sucesso" : e.response.data.message, 'warning', 'icon-alert-circle-exc', 2)
                }
                alertar(notificacao, 'br', 'Não foi possível faturar a saída', 'warning', 'icon-alert-circle-exc', 2)
            })
        }

    } catch (error) {
        alertar(notificacao, 'br', "Usuário não possui funcionário vinculado", 'warning', 'icon-alert-circle-exc', 5)
    }
}

export function GerarNFE() {

}

export const saidaProduto = {
    AdicionarItem: (produto: Produto) => {
        //@ts-ignore
        const { notificacao } = store.getState();

        if (!produto.numero) {
            alertar(notificacao, 'br', "Número / Código de Barras não informado", 'warning', 'icon-alert-circle-exc', 2)
            return 'numero'
        }

        if (!produto.descricao) {
            alertar(notificacao, 'br', "Produto não informado", 'warning', 'icon-alert-circle-exc', 2);
            return 'descricao'
        }

        if (produto.valorUnitario === '0' || produto.valorUnitario === '0.00' || produto.valorUnitario === '0,00') {
            alertar(notificacao, 'br', "Valor Unitário não informado", 'warning', 'icon-alert-circle-exc', 2)
            return 'valorUnitario'
        }

        if (!produto.quantidade) {
            if (produto.quantidade === "0") {
                alertar(notificacao, 'br', "Quantidade com valor zerado", 'warning', 'icon-alert-circle-exc', 2)
                return 'quantidade'
            }
            else {
                alertar(notificacao, 'br', "Quantidade não informado", 'warning', 'icon-alert-circle-exc', 2)
                return 'quantidade'
            }
        }

        const { produtos, valorTotal } = store.getState().saidaDiversa.saida

        let produtosString = JSON.stringify(produtos);
        let produtosJSON: Produto[] = JSON.parse(produtosString)

        const newProduto: Produto = {
            numeroPedidoItem: 0,
            item: 0,
            codProduto: 0,
            descricao: '',
            numero: '',
            quantidade: '',
            valorTotal: '',
            valorUnitario: '',
            dataLancamento: '',
            status: 0,
            flControlaGrade: 0,
            grades: [],
        }

        let total = Sum([ToNumber(valorTotal), ToNumber(produto.valorTotal)])

        actions.setValorTotal('valorSubtotal', Round(total, 2))
        actions.setValorTotal('valorTotal', Round(total, 2))

        produto.valorUnitario = ToNumber(produto.valorUnitario).toFixed(2)

        if (produto.flControlaGrade === 1) {
            if (produto.grades) {
                actions.setGradeSelecionada({ produto })
                actions.setModalProdutoGrades(true)
                return
            }
            return alertar(notificacao, 'br', "Nenhuma grade encontrada para esse produto", 'warning', 'icon-alert-circle-exc', 2)
        }

        if (produto.item === 0) {
            actions.addProduto([...produtosJSON, { ...produto, item: produtosJSON.length + 1, dataLancamento: new Date().toISOString() }])
        } else {
            let index = produtosJSON.findIndex(p => p.item === produto.item)

            if (index < 0) {
                actions.addProduto([...produtosJSON, { ...produto, item: produtosJSON.length + 1, dataLancamento: new Date().toISOString() }])

            } else {
                produtosJSON[index] = produto
                actions.addProduto(produtosJSON)
            }

            actions.setProduto(newProduto);
        }

        return false
    },

    GetProdutos: async (numProduto: number | string, nome = ""): Promise<Item[]> => {
        try {
            const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

            return await axios.get(api.urlCadastro + `api/v1/produto/produtos?numProduto=${numProduto}&nome=${nome}&tipo=P`, {
                headers: { Authorization: basicAuth }
            }).then(response => {
                return response.data.records
            }).catch(e => {
                return []
            })
        } catch (e) {
            return []
        }
    },

    GetProduto: async (codProduto: number): Promise<Item | null> => {
        //@ts-ignore
        const { notificacao } = store.getState()
        try {
            const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

            return await axios.get(api.urlCadastro + `api/v1/produto/produto?codProduto=${codProduto}&status=0`, {
                headers: { Authorization: basicAuth }
            }).then(response => {
                return response.data.records
            }).catch(e => {
                alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
                return null
            })
        } catch (e) {
            alertar(notificacao, 'br', "Não foi possível obte o produto", 'warning', 'icon-alert-circle-exc', 2)
            return null
        }
    },

    GetProdutoGrades: async (codProduto: number): Promise<any | null> => {
        //@ts-ignore
        const { notificacao } = store.getState()
        try {
            const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

            return await axios.get(api.urlCadastro + `api/v1/produto/grades?codProduto=${codProduto}&status=0`, {
                headers: { Authorization: basicAuth }
            }).then(response => {
                actions.setProdutoGrades(response.data.records.grades)
                return response.data.records.grades
            }).catch(e => {
                alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
                return null
            })
        } catch (e) {
            return null
        }
    }
}

function updateDados(data: any) {
    actions.setOperacao({
        codigoOperacao: data.operacao.codigoOperacao,
        numeroOperacao: data.operacao.numeroOperacao,
        descricaoOperacao: data.operacao.descricaoOperacao,
        gerarConta: data.operacao.geraFatura === true ? 1 : 0
    })

    actions.setOperacaoCopia({
        codigoOperacao: data.operacao.codigoOperacao,
        numeroOperacao: data.operacao.numeroOperacao,
        descricaoOperacao: data.operacao.descricaoOperacao,
        gerarConta: data.operacao.geraFatura === true ? 1 : 0
    })

    actions.updatePedido(data.numeroPedido)
    actions.updatePedidoFatura(data.numeroFatura)

    actions.setDadosAdicionais(data.dadosAdicionais)
    actions.setDadosAdicionaisCopia(data.dadosAdicionais)

    if (data.destinatario != null) {
        actions.setDadosDestinatario({ ...data.destinatario, razaoSocial: data.destinatario.nomeRazao })
        actions.setDadosDestinatarioCopia({ ...data.destinatario, razaoSocial: data.destinatario.nomeRazao })
    }

    if (data.transportadora != null) {
        actions.setDadosTransportadora({ ...data.transportadora })
        actions.setDadosTransportadoraCopia({ ...data.transportadora })
    }

    actions.setValorTotal('valorDesconto', data.valorDesconto)
    actions.setValorTotal('valorSubTotal', data.valorSubTotal)
    actions.setValorTotal('valorTotal', data.valorTotal)

    let produtos: Produto[] = []
    let produtosCopia: ProdutoCopia[] = []

    let total = 0;
    data.produtos.forEach((produto: any) => {
        produtos.push({
            numeroPedidoItem: produto.numeroPedidoItem,
            codProduto: produto.codProduto,
            numero: produto.numeroCodBarras,
            descricao: produto.descricao,
            quantidade: produto.quantidade.toString(),
            dataLancamento: produto.dataLancamento,
            item: produto.item,
            valorUnitario: Round(produto.valorUnitario, 2),
            valorTotal: Round(produto.valorTotal, 2),
            status: 0,
            flControlaGrade: produto.controlaGrade === true ? 1 : 0,
            grades: produto.grades,
        })

        produtosCopia.push({
            numeroPedidoItem: produto.numeroPedidoItem,
            codProduto: produto.codProduto,
            numero: produto.numeroCodBarras,
            descricao: produto.descricao,
            quantidade: produto.quantidade.toString(),
            dataLancamento: produto.dataLancamento,
            item: produto.item,
            valorUnitario: Round(produto.valorUnitario, 2),
            valorTotal: Round(produto.valorTotal, 2),
            status: 0,
            flControlaGrade: produto.controlaGrade === true ? 1 : 0,
            grades: produto.grades,
        })

        total = Sum([total, produto.valorUnitario])
    });

    actions.setValorTotal('valorDesconto', (data.valorDesconto).toFixed(2))
    actions.setValorTotal('valorSubTotal', (data.valorSubTotal).toFixed(2))
    actions.setValorTotal('valorTotal', (data.valorTotal).toFixed(2))

    actions.addProduto(produtos)
    actions.addProdutoCopia(produtosCopia)

    actions.setScreen(1)
}