import React, { useState, useEffect } from "react";
import classNames from "classnames";

import {
    Button,
    ButtonGroup,
} from "reactstrap";

import * as action from '../../../store/reducers/Cadastro/AberturaCaixa/actions'
import { shallowEqual, useSelector } from "react-redux";
import * as controllerCaixa from "../../../controller/controllerAberturaCaixas";

const TabsContent = function (props) {
    const screen = useSelector(state => state.aberturaCaixa.screen, shallowEqual);
    const block = useSelector(state => state.aberturaCaixa.block, shallowEqual);
    const [stateScreen, setStateScreen ] = useState(0)
    
    useEffect(() => {
        setStateScreen(screen)

        if (stateScreen === 1)
            controllerCaixa.getCaixaAberto()

    }, [screen])

    return (
        <>
            <ButtonGroup style={{ width: '100%' }}>
                {props.tabs && props.tabs.map((item, index) => {
                    return (
                        <>
                            <Button
                                color={stateScreen === index ? "info" : ""}
                                type="button"
                                data-toggle="tab"
                                className={classNames("btn-gp-padrao", stateScreen === index ? "active" : "")}
                                onClick={() => {  setStateScreen(index); action.setScreen(index)  }}
                                style={{ width: '50%' }}
                                disabled={block}
                            >
                                {item.tabName}
                            </Button>
                        </>
                    )
                })}
            </ButtonGroup>
            <br />
            <br />
            {props.tabs[stateScreen].tabScreen}
        </>
    );
}

export default TabsContent;