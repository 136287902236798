//State da tela de Pesquisa e cadastro de CFOPs
const INITIAL_STATE = {
    pesquisa: {
        enquadramento: '',
        descricao: '',
        ativos: 0,
    },
    enquadramento: {
        codEnquadramento: 0,
        enquadramento: '',
        descricao: '',
        status: 0,
    },
    invalido: {
        enquadramento: false,
        descricao: false,
    },
    enquadramentos: [],
    inicioCadastro: false,
    verCadastro: false,
    enquadramentoCopia: {},
};

export default function enquadramentosIPI(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'ENQUADRAMENTOS_IPI':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'ENQUADRAMENTOS':
                    return { ...state, enquadramentos: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: INITIAL_STATE.pesquisa,
                        enquadramentos: INITIAL_STATE.enquadramentos
                    }
                case 'ENQUADRAMENTO':
                    return { ...state, enquadramento: { ...state.enquadramento, [action.campo]: action.value } }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'VER_CADASTRO':
                    return { ...state, verCadastro: action.value } 
                case 'ENQUADRAMENTO_COPIA':
                    return { ...state, enquadramentoCopia: state.enquadramento }
                case 'CLEAN_ENQUADRAMENTO':
                    return {
                        ...state,
                        enquadramento : INITIAL_STATE.enquadramento
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: INITIAL_STATE.invalido
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}