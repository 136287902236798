import axios from 'axios';
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";

import * as action from '../store/reducers/Cadastro/AberturaCaixa/actions'
import store from '../store/stores';
import { Sum, ToNumber, Round, Subtract } from '../Util/numbers';

export function addLancamento() {
    const { notificacao, aberturaCaixa: { lancamento, lancamentos, abertura } } = store.getState();

    if (abertura.status === "Fechado")
        return alertar(notificacao, 'br', 'Não é possivel realizar lançamentos em um Caixa Fechado', 'warning', 'icon-alert-circle-exc', 5)

    if (ToNumber(lancamento.codFormaPagamento) <= 0) {
        alertar(notificacao, 'br', 'Tipo Lançamento Inválido', 'warning', 'icon-alert-circle-exc', 2)
        return 'codFormaPagamento'
    }
    let valor = lancamento.valor.toString()
    valor = valor.replace('.', '')
    valor = valor.replace(',', '.')
    valor = Number(valor)
    if (valor <= 0) {
        alertar(notificacao, 'br', 'Valor de Lançamento Inválido', 'warning', 'icon-alert-circle-exc', 2)
        return 'valor'
    }

    let objLancamento = JSON.stringify(lancamento);
    objLancamento = JSON.parse(objLancamento);

    objLancamento.valor = Round(valor, 2)
    objLancamento.dataLancamento = new Date().toLocaleString();

    action.setLancamentos([...lancamentos, objLancamento])

    calculaValorTotal(objLancamento.valor)

}

export function removeLancamento(index) {
    const { notificacao, aberturaCaixa: { lancamentos } } = store.getState();
    try {

        let listLancamentos = JSON.stringify(lancamentos);
        listLancamentos = JSON.parse(listLancamentos);

        let itemRemovido = listLancamentos.splice(index, 1);

        if (itemRemovido.length > 0)
            calculaValorTotal(itemRemovido[0].valor, true)

        action.setLancamentos(listLancamentos);

    } catch (error) {
        alertar(notificacao, 'br', 'Não foi possivel deletar lançamento', 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function calculaValorTotal(valorLancado, subtrair = false) {
    const { abertura } = store.getState().aberturaCaixa;

    let valorTotalAtual = 0

    if (subtrair)
        valorTotalAtual = Subtract([ToNumber(abertura.valorTotalLancado), ToNumber(valorLancado)]);
    else
        valorTotalAtual = Sum([ToNumber(abertura.valorTotalLancado), ToNumber(valorLancado)]);

    action.setAbertura({ ...abertura, valorTotalLancado: Round(valorTotalAtual, 2) })
}

export function getTiposLancamento() {
    const { notificacao } = store.getState();
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/formaPgto/formasPgto?status=0`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0)
                return alertar(notificacao, 'br', "Nenhuma forma de Lançamento encontrada", 'warning', 'icon-bulb-63', 2)

            action.setTiposLancamento(response.data.records)

        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel obter as formas de Lançamento", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function getFuncionario() {
    const { notificacao, aberturaCaixa: { abertura, pesquisa } } = store.getState();
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        action.setBlock(false)
        axios.get(api.urlCadastro + `api/v1/funcionario/funcionario`, {
            headers: { Authorization: basicAuth }
        }).then(response => {

            action.setAbertura({
                ...abertura,
                codFuncionario: response.data.records.codFunc,
                nomeFuncionario: response.data.records.nomeFunc
            });

            action.setPesquisa({
                ...pesquisa,
                codigoFuncionario: response.data.records.codFunc,
                nomeFuncionario: response.data.records.nomeFunc
            })

        }).catch(error => {
            alertar(notificacao, 'br', "Usuário não possui funcionário vinculado", 'warning', 'icon-alert-circle-exc', 5)
            action.setBlock(true)
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel obter Funcionário", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export async function getFuncionarios() {
    const { notificacao } = store.getState();
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        await axios.get(api.urlCadastro + `api/v1/funcionario/funcionarios`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.length === 0)
                return alertar(notificacao, 'br', "Nenhum funcionário encontrado", 'warning', 'icon-alert-circle-exc', 2)

            action.setFuncionarios(response.data.records);

        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel obter os Funcionários", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function createCaixa() {
    const { notificacao, aberturaCaixa: { abertura, lancamentos } } = store.getState();
    const codigoCaixa = ToNumber(abertura.codCaixa)
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        let listLancamento = JSON.stringify(lancamentos)
        listLancamento = JSON.parse(listLancamento)

        listLancamento.forEach(lancamento => {
            lancamento.valor = lancamento.valor !== "" ? ToNumber(lancamento.valor) : 0
        });


        axios.post(api.urlCadastro + `api/v1/caixa/create`, {
            codCaixa: codigoCaixa,
            codFuncionario: ToNumber(abertura.codFuncionario),
            dataAbertura: abertura.dataInicio,
            dataFechamento: abertura.dataFim,
            valorTotalLancado: abertura.valorTotalLancado !== "" ? ToNumber(Round(abertura.valorTotalLancado, 2)) : 0,
            lancamentos: listLancamento
        }, {
            headers: { Authorization: basicAuth }
        }).then(response => {

            response.data.records.lancamentos.forEach(lancamento => {
                lancamento.valor = lancamento.valor !== "" ? Round(lancamento.valor, 2) : "0"
            });

            if (codigoCaixa > 0)
                alertar(notificacao, 'br', "Caixa Aberto com Sucesso", 'success', 'icon-alert-circle-exc', 2)
            else
                alertar(notificacao, 'br', "Caixa Atualizado com Sucesso", 'success', 'icon-alert-circle-exc', 2)

            action.setAberturaRetorno(response.data.records);
        }).catch(error => {
            if (error.response)
                alertar(notificacao, 'br', error.response.data.message, 'warning', 'icon-alert-circle-exc', 5)
            else
                alertar(notificacao, 'br', "Não foi possivel obter os Funcionários", 'warning', 'icon-alert-circle-exc', 2)
        })
    } catch (e) {
        if (codigoCaixa > 0)
            alertar(notificacao, 'br', "Não foi abrir caixa", 'warning', 'icon-alert-circle-exc', 2)
        else
            alertar(notificacao, 'br', "Não foi atualizar caixa", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function getCaixas() {
    const { notificacao, aberturaCaixa: { pesquisa } } = store.getState();
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/caixa/caixas?codFuncionario=${pesquisa.codigoFuncionario}&dataInicio=${pesquisa.dataInicio}&dataFim=${pesquisa.dataFim}&tipo=${pesquisa.tipo === null ? "" : pesquisa.tipo}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {

            if (response.data.records.length === 0) {
                action.setCaixas([])
                alertar(notificacao, 'br', "Nenhum Caixa encontrado", 'success', 'icon-alert-circle-exc', 2)
                return
            }
            response.data.records.forEach(caixa => {
                let valorTotal = caixa.valorTotalLancado
                valorTotal = valorTotal.toFixed(2)
                valorTotal = valorTotal.toString()
                caixa.valorTotalLancado = valorTotal
            });

            action.setCaixas(response.data.records);

        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel obter os caixas", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function getCaixa(codCaixa, status) {
    const { notificacao, aberturaCaixa: { pesquisa } } = store.getState();
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/caixa/caixa?codCaixa=${codCaixa}&codFuncionario=${pesquisa.codigoFuncionario}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {
            if (response.data.records.dataAbertura)
                response.data.records.dataAbertura = response.data.records.dataAbertura.substring(0, 10)

            if (response.data.records.dataFechamento)
                response.data.records.dataFechamento = response.data.records.dataFechamento.substring(0, 10)

            action.setAbertura({
                ...response.data.records,
                status: status === 0 ? "Aberto" : "Fechado"
            });
            response.data.records.lancamentos.forEach((lancamento) => {
                let valor = lancamento.valor
                valor = valor.toFixed(2)
                valor = valor.toString()
                lancamento.valor = valor

            })

            action.setLancamentos(response.data.records.lancamentos);

            action.setScreen(1)

        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel obter caixa", 'warning', 'icon-alert-circle-exc', 2)
    }
}

export function getCaixaAberto() {
    const { notificacao, aberturaCaixa: { pesquisa, abertura } } = store.getState();
    try {
        const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));

        axios.get(api.urlCadastro + `api/v1/caixa/caixaAberto?codFuncionario=${pesquisa.codigoFuncionario}`, {
            headers: { Authorization: basicAuth }
        }).then(response => {

            if (response.data.records.dataAbertura)
                response.data.records.dataAbertura = response.data.records.dataAbertura.substring(0, 10)

            if (response.data.records.dataFechamento)
                response.data.records.dataFechamento = response.data.records.dataFechamento.substring(0, 10)

            action.setAbertura({
                ...response.data.records,
                status: "Aberto",
                codFuncionario: abertura.codFuncionario,
                nomeFuncionario: abertura.nomeFuncionario
            });
            response.data.records.lancamentos.forEach((lancamento) => {
                let valor = lancamento.valor
                valor = valor.toFixed(2)
                valor = valor.toString()
                lancamento.valor = valor

            })
            action.setLancamentos(response.data.records.lancamentos);

            action.setScreen(1)

        }).catch(error => {
            if (error.response)
                alertar(notificacao, 'br', error.response.data.message, 'warning', 'icon-alert-circle-exc', 5)
            else
                alertar(notificacao, 'br', "Não foi possivel obter o Caixa", 'warning', 'icon-alert-circle-exc', 2)
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possivel obter o Caixa", 'warning', 'icon-alert-circle-exc', 2)
    }
}