import store from "../../../stores"
import { ProdutoTypes, ProdutoStore, ProdutoAction, Pesquisa, Produto, Imagem, Produtos, Grupo, Subgrupo, Fornecedor, TipoGrade, Seguimento, CEST, NCM, Classificacao, Invalido, ProdutoState } from "./types"
import { INITIAL_STATE } from './index'

export const setPesquisa = (pesquisa: Pesquisa) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.PESQUISA,
    pesquisa
})

export const setProduto = (produto: Produto) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.PRODUTO,
    produto
})

export const setProdutoBasico = (basico: Produto['basico']) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.PRODUTO,
    produto: {
        ...store.getState().produtos.produto,
        basico
    }
})

export const setProdutoGrades = (grades: Produto['grades']) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.PRODUTO,
    produto: {
        ...store.getState().produtos.produto,
        grades
    }
})
//@ts-ignore
export const setProdutoGradesSelected = (gradesSelected) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.PRODUTO,
    produto: {
        ...store.getState().produtos.produto,
        gradesSelected
    }
})

export const setProdutoDadosTributarios = (dadosTributarios: Produto['dadosTributarios']) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.PRODUTO,
    produto: {
        ...store.getState().produtos.produto,
        dadosTributarios
    }
})

export const setProdutoEspecificacoes = (especificacoes: Produto['basico']['especificacoes']) => {
    const produto = store.getState().produtos.produto
    return store.dispatch<ProdutoAction>({
        idStore: ProdutoStore.ID_STORE,
        type: ProdutoTypes.PRODUTO,
        produto: {
            ...produto,
            basico: {
                ...produto.basico,
                especificacoes
            }
        }
    })
}

export const setProdutoComissao = (comissao: Produto['basico']['comissao']) => {
    const produto = store.getState().produtos.produto
    return store.dispatch<ProdutoAction>({
        idStore: ProdutoStore.ID_STORE,
        type: ProdutoTypes.PRODUTO,
        produto: {
            ...produto,
            basico: {
                ...produto.basico,
                comissao
            }
        }
    })
}

export const setProdutoEstoque = (estoque: Produto['basico']['estoque']) => {
    const produto = store.getState().produtos.produto
    return store.dispatch<ProdutoAction>({
        idStore: ProdutoStore.ID_STORE,
        type: ProdutoTypes.PRODUTO,
        produto: {
            ...produto,
            basico: {
                ...produto.basico,
                estoque
            }
        }
    })
}

export const setProdutoCaracteristicas = (caracteristicas: Produto['basico']['caracteristicas']) => {
    const produto = store.getState().produtos.produto
    return store.dispatch<ProdutoAction>({
        idStore: ProdutoStore.ID_STORE,
        type: ProdutoTypes.PRODUTO,
        produto: {
            ...produto,
            basico: {
                ...produto.basico,
                caracteristicas
            }
        }
    })
}

export const setProdutoCopia = () => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.PRODUTO_COPIA,
    produto: store.getState().produtos.produto
})

export const setImagem = (imagem: Imagem) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.IMAGEM,
    imagem
})

export const setProdutos = (produtos: Produtos[]) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.PRODUTOS,
    produtos
})

export const setGrupos = (grupos: Grupo[]) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.GRUPOS,
    grupos
})

export const setPesqSubgrupos = (pesqsubgrupos: Subgrupo[]) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.SUBGRUPOS_PESQ,
    pesqsubgrupos
})

export const setCadSubgrupos = (cadsubgrupos: Subgrupo[]) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.SUBGRUPOS_CAD,
    cadsubgrupos
})

export const setFornecedores = (fornecedores: Fornecedor[]) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.FORNECEDORES,
    fornecedores
})

export const setFornecedoresProd = (fornecedoresProd: Fornecedor[]) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.FORNECEDORES_PRODUTO,
    fornecedoresProd
})

export const setImagens = (imagens: Imagem[]) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.IMAGENS,
    imagens
})

export const setTiposGrade = (tiposGrade: TipoGrade[]) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.TIPOS_GRADE,
    tiposGrade
})

export const setSeguimentos = (seguimentos: Seguimento[]) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.SEGUIMENTOS,
    seguimentos
})

export const setCESTs = (cests: CEST[]) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.CESTS,
    cests
})

export const setNCMs = (ncms: NCM[]) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.NCMS,
    ncms
})

export const setCESTsSugestoes = (cestsSugestoes: CEST[]) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.SUGESTOES_CESTS,
    cestsSugestoes
})

export const setNCMsSugestoes = (ncmsSugestoes: NCM[]) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.SUGESTOES_NCMS,
    ncmsSugestoes
})

export const setClassificacoes = (classificacoes: Classificacao[]) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.CLASSIFICACOES,
    classificacoes
})

export const setInvalido = (invalido: Invalido) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.INVALIDO,
    invalido
})

export const setInicioCadastro = (inicioCadastro: boolean) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.INICIO_CADASTRO,
    inicioCadastro
})

export const setProximoCadastro = (proximoCadastro: boolean) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.PROXIMO_CADASTRO,
    proximoCadastro
})

export const cleanAll = () => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.CLEAN,
    newState: INITIAL_STATE
})

export const cleanPesquisa = () => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.CLEAN,
    newState: {
        ...store.getState().produtos,
        pesquisa: INITIAL_STATE.pesquisa,
        produtos: INITIAL_STATE.produtos
    }
})

export const cleanProduto = () => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.CLEAN,
    newState: {
        ...store.getState().produtos,
        produto: INITIAL_STATE.produto,
        produtoCopia: INITIAL_STATE.produto,
        tiposGrade: INITIAL_STATE.tiposGrade,
        imagem: INITIAL_STATE.imagem,
        imagens: INITIAL_STATE.imagens,
        verCadastro: INITIAL_STATE.verCadastro,
        cadsubgrupos: INITIAL_STATE.cadsubgrupos
    }
})

export const cleanFlag = () => {
    let state = store.getState().produtos
    return store.dispatch<ProdutoAction>({
        idStore: ProdutoStore.ID_STORE,
        type: ProdutoTypes.CLEAN,
        newState: {
            ...state,
            pesquisa: {
                ...state.pesquisa,
                flControlaComissao: '0',
                flControlaEstoque: '0',
                flControlaNumSerie: '0',
                flControlaGrade: '0',
                flTributado: '0',
                flItemVenda: '0',
                flags: [],
            }
        }
    })
}

export const cleanImagem = () => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.CLEAN,
    newState: {
        ...store.getState().produtos,
        imagem: INITIAL_STATE.imagem
    }
})

export const cleanInvalido = () => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.CLEAN,
    newState: {
        ...store.getState().produtos,
        invalido: INITIAL_STATE.invalido
    }
})

export const setVerCadastro = (verCadastro: boolean) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.VER_CADASTRO,
    newState: {
        ...store.getState().produtos, verCadastro: verCadastro
    }

})

export const setOrigensProduto = (origensProduto: ProdutoState['origensProduto']) => store.dispatch<ProdutoAction>({
    idStore: ProdutoStore.ID_STORE,
    type: ProdutoTypes.ORIGENSPRODUTO,
    origensProduto

})