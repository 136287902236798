import React, { useState, useRef, useEffect } from 'react';

import * as controller from "../../../../controller/Movimentacao/controllerPedidoVenda"


import { Row,CardBody  } from "reactstrap";

import { Card } from '../Components';

import useShallowEqualSelector from '../../../../Util/Hooks/useShallowEqualSelector';


import AppState from '../../../../store/storeType';
import { Produto } from '../../../../store/reducers/Movimentacao/PedidoVenda/types';
import MiniCard from '../Components/miniCard';
import { maskMoeda } from '../../../../Util/mascaras';
import useModal from '../../../../Util/Hooks/useModal';
import ModalGrade from '../Modals/ModalGrade';

const ProdutosLista: React.FC = () => {
    const [modalGrade, toogleModalGrade] = useModal(false)
    const [codProduto, setCodProduto] = useState('')

    const produtos = useShallowEqualSelector<AppState, Produto[]>(state => state.pedidoVenda.produtos)

    return (
        <>
            <Card>
                <div style={{height: "100%"}}>
                    <Card style={{height: "100%",   overflow: 'auto'}}>
                        <CardBody>
                            <Row style={{height: "100%" , alignContent: "flex-start"}}>
                                { produtos.map((produto) => {
                                    return(
                                        <>
                                        <MiniCard className="produtoLista-miniCard" setCodProduto={setCodProduto} toogleModalGrade={toogleModalGrade} changeOnClick={false} cor={"info"} valor={`${maskMoeda(produto.precoVendaAtual.toFixed(2))}`} descricao={produto.nome} index={produto.codProduto} />
                                        </>
                                    )
                                }) }

                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </Card>
            { modalGrade && <ModalGrade codProduto={codProduto} isOpen={true} toggle={toogleModalGrade} />}
        </>
    );
}

export default ProdutosLista